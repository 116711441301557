import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DEVICE_FIRMWARES_FIELD, deviceSelector } from 'helpers/selectors';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { addPagination } from 'helpers/paginationHelpers';
import { actionCreators } from 'application/tenant/console/Firmware/utilities';

import FirmwareV1 from './FirmwareV1';

export const mapStateToProps = (state, { match }) => {
  const { deviceId } = match.params;

  const {
    online,
    viewSelector: {
      manufacturerSDK,
      model,
    } = {},
    reported: {
      sf: accept,
    } = {},
  } = deviceSelector(state)(deviceId);

  return {
    online,
    deviceId,
    accept,
    manufacturerSDK,
    model,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    addPagination(
      {
        resourceType: DEVICE_FIRMWARES_FIELD,
        included: true,
      },
      ({ manufacturerSDK, model }) => actionCreators.fetchDeviceFirmwares({
        sort: '-version',
        hidden: false,
        'filter[manufacturer][]': manufacturerSDK,
        'filter[models][]': model,
      }),
    ),
  ),
)(FirmwareV1);
