import React from 'react';
import PropTypes from 'prop-types';

import { StyledRenderBlockedParameter } from './RenderBlockedParameter.style';

const RenderBlockedParameter = ({ value }) => (
  <StyledRenderBlockedParameter>{value}</StyledRenderBlockedParameter>
);

RenderBlockedParameter.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
  ]).isRequired,
};

export default RenderBlockedParameter;
