import constants from 'dispatcherConst';
import { messages } from 'data/notifications/notificationsConst';

const updateSiteSubscriptionContactEmail = args => ({
  type: constants.UPDATE_SITE_REQUEST,
  payload: {
    ...args,
    message: messages.SITE_SUBSCRIPTION_CONTACT_EMAIL_UPDATED,
  },
});

export default updateSiteSubscriptionContactEmail;
