import { compose } from 'redux';
import { connect } from 'react-redux';
import last from 'lodash/last';

import {
  PROJECT_SUITES_FIELD,
  computedProjectSuiteTreePathSelector,
} from 'helpers/selectors';
import { deepEqualMemoizeOne, cleanUpLabel, applyVocabulary } from 'helpers/sharedMethods';
import { PermissionsTreeItem } from '../../../../../../../../../../components';
import { sortPaths } from '../../../../../../../../../../utilities';
import permissionsActionCreators from '../../../../../../../../../../utilities/permissions/permissionsActionCreators';

// eslint-disable-next-line import/no-mutable-exports
let ContextProjectSuitePermissionsTreeItemContainer;

export const makeMapState = () => {
  const memoPaths = deepEqualMemoizeOne();

  return function mapStateToProps(state, { path, resourceId }) {
    const { open, permissionCount, permissionId, ...paths } = computedProjectSuiteTreePathSelector(
      state,
      {
        projectSuiteId: resourceId,
        path,
        scopes: ['tenant'],
      },
    );
    const label = cleanUpLabel(applyVocabulary(last(path.split('.'))));
    const sortedPaths = sortPaths(paths);

    return {
      open,
      label,
      paths: memoPaths(sortedPaths),
      PermissionsTreeItemContainer: ContextProjectSuitePermissionsTreeItemContainer,
    };
  };
};

const mapDispatchToProps = {
  onToggleOpen: (projectSuiteId, path) => permissionsActionCreators.toggleResourceTreeOpen(
    PROJECT_SUITES_FIELD,
    projectSuiteId,
    path,
  ),
};

ContextProjectSuitePermissionsTreeItemContainer = compose(
  connect(makeMapState, mapDispatchToProps),
)(PermissionsTreeItem);

export default ContextProjectSuitePermissionsTreeItemContainer;
