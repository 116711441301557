import isEmpty from 'lodash/isEmpty';

import { GROUP_LABELS, FILTER_FIELDS } from './constants';
import getUniqueResourcesFromNotificationEvents from './getUniqueResourcesFromNotificationEvents';

const transformToCheckboxFilterValues = notificationEvents => {
  if (isEmpty(notificationEvents)) {
    return [];
  }

  const resources = getUniqueResourcesFromNotificationEvents(notificationEvents);

  const transformed = [];

  Object.keys(resources).forEach(type => {
    transformed.push({
      key: GROUP_LABELS[type],
      value: resources[type].map(item => ({
        key: item.key,
        value: item.name,
        type,
        filterField: FILTER_FIELDS[type],
      })),
    });
  });

  return transformed;
};

export default transformToCheckboxFilterValues;
