import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  computedUserDataPermissionsTreeSelector,
} from 'helpers/selectors';
import { PermissionsTree } from '../../../../../../../../../../components';
import { sortPaths } from '../../../../../../../../../../utilities';
import { ContextUserPermissionsTreeItem } from '../PermissionsTreeItem';

export const mapStateToProps = (state, { resourceId }) => {
  const {
    permissionCount,
    ...tree
  } = computedUserDataPermissionsTreeSelector(state, ['tenant'])(resourceId);
  const paths = sortPaths(tree);

  return {
    paths,
    PermissionsTreeItem: ContextUserPermissionsTreeItem,
  };
};

export default compose(
  connect(mapStateToProps),
)(PermissionsTree);
