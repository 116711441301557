import constants from 'dispatcherConst';
import { reducer as formReducer } from 'redux-form';

const reducer = formReducer.plugin({
  selectedActions: (state = {}, action) => {
    switch (action.type) {
      case constants.UPDATE_RGB_VALUES_REQUEST:
        return {
          ...state,
          values: {
            ...state.values,
            [`red${action.payload.actionTypeDeviceIdKey}`]: action.payload.red,
            [`green${action.payload.actionTypeDeviceIdKey}`]: action.payload.green,
            [`blue${action.payload.actionTypeDeviceIdKey}`]: action.payload.blue,
          },
          registeredFields: {
            ...state.registeredFields,
            [`red${action.payload.actionTypeDeviceIdKey}`]: action.payload.red,
            [`green${action.payload.actionTypeDeviceIdKey}`]: action.payload.green,
            [`blue${action.payload.actionTypeDeviceIdKey}`]: action.payload.blue,
          },
        };

      default:
        return state;
    }
  },
  addNewAction: (state = {}, action) => {
    switch (action.type) {
      case constants.UPDATE_RGB_VALUES_NEW_ACTION_REQUEST:
        return {
          ...state,
          values: {
            ...state.values,
            red: action.payload.red,
            green: action.payload.green,
            blue: action.payload.blue,
          },
        };

      default:
        return state;
    }
  },
});

export { reducer };

export default reducer;
