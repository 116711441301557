import { compose } from 'redux';
import { connect } from 'react-redux';

import { LOGICAL_DEVICES_FIELD } from 'helpers/selectors';
import { PermissionsGridItem } from '../../../../components';
import permissionsActionCreators from '../../../../utilities/permissions/permissionsActionCreators';
import { selectors } from '../../../../utilities/permissions';

export const makeMapState = () => (state, { resourceId, path, userId }) => {
  const {
    permissionCount,
    userCount,
    permissionId,
  } = selectors.userPermissionDataSelector(
    state,
    {
      resourceId,
      resourceType: LOGICAL_DEVICES_FIELD,
      userId,
      path,
    },
  );
  const copyUserId = selectors.permissionsDeviceCopyUserIdSelector(
    state,
    {
      deviceId: resourceId,
    },
  );

  return {
    isOn: !!userCount,
    isPartial: permissionCount && permissionCount > userCount,
    permissionId,
    copyUserId,
  };
};

const mapDispatchToProps = {
  onToggleUserPermission: (
    userId,
    resourceId,
    permissionId,
    isOn,
  ) => permissionsActionCreators.toggleUserPermission(
    userId,
    LOGICAL_DEVICES_FIELD,
    resourceId,
    permissionId,
    isOn,
  ),
  onToggleUserHeaderPermission: (
    userId,
    resourceId,
    path,
    isOn,
    isPartial,
  ) => permissionsActionCreators.toggleUserHeaderPermission(
    userId,
    LOGICAL_DEVICES_FIELD,
    resourceId,
    path,
    isOn,
    isPartial,
  ),
};

export default compose(
  connect(makeMapState, mapDispatchToProps),
)(PermissionsGridItem);
