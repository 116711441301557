import constants from 'dispatcherConst';
import { API_DOMAIN } from 'storageConst';

const initialState = () => ({
  apiDomain: localStorage.getItem(API_DOMAIN) || process.env.REACT_APP_API_URL,
  backendVersion: undefined,
});

const reducer = (state = initialState(), action) => {
  switch (action.type) {
    case constants.CHANGE_API_DOMAIN_SUCCESS:
      return { ...state, apiDomain: action.payload.apiDomain };

    case constants.LOGOUT:
      return { ...state, apiDomain: process.env.REACT_APP_API_URL };

    case constants.FETCH_BACKEND_VERSION_SUCCESS:
      return { ...state, backendVersion: action.payload.data };

    case constants.FETCH_SERVICE_VERSIONS_SUCCESS:
      return { ...state, serviceVersions: action.payload.data };

    default:
      return state;
  }
};

export default reducer;
