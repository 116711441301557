import isEmpty from 'lodash/isEmpty';

import validateEndingCriteria from './validateEndingCriteria';

const validateDailyRecurrenceView = ({ recurrenceParams, endingCriteria }) => {
  const {
    everyNDays,
    forNTimes,
    startTimes,
    startDate,
    endTime,
  } = recurrenceParams;
  let errors = {};

  if (!everyNDays) {
    errors.everyNDays = 'Required field';
  }

  if (everyNDays < 1 || everyNDays > 400) {
    errors.everyNDays = 'Value must be between 1 and 400';
  }

  if (isEmpty(startTimes)) {
    errors.startTimes = 'Required field';
  }

  if (!isEmpty(startTimes) && startTimes.length > 600) {
    errors.startTimes = 'Only up to 600 values are allowed';
  }

  if (!startDate) {
    errors.startDate = 'Required field';
  }

  errors = validateEndingCriteria({
    errors,
    startDate,
    forNTimes,
    endTime,
    endingCriteria,
    forNTimesMaxValue: 400,
  });

  return {
    isValid: isEmpty(Object.keys(errors)),
    errors,
  };
};

export default validateDailyRecurrenceView;
