import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';

const mergeTriggersOptions = (deviceTriggers, selectedTriggers) => {
  if (isEmpty(deviceTriggers) && isEmpty(selectedTriggers)) {
    return [];
  }

  if (!isArray(deviceTriggers) || !isArray(selectedTriggers)) {
    return [];
  }

  const ids = new Set(deviceTriggers.map(d => d.value));

  return [
    ...deviceTriggers,
    ...selectedTriggers.filter(item => !ids.has(item.value)),
  ];
};

export default mergeTriggersOptions;
