import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header, Segment, Divider } from 'semantic-ui-react';
import pick from 'lodash/pick';

import { PageHeader } from 'components/core';
import DefinitionList from 'pages/Device/DefinitionList/DefinitionList';
import DefinitionListAccordion from './Settings.style';
import { DomainForm } from './components';

const buildInfoLabels = [
  { text: 'Version', field: 'REACT_APP_VERSION' },
  { text: 'Commit ID', field: 'REACT_APP_COMMIT_ID' },
  { text: 'Repository', field: 'REACT_APP_REPO_NAME' },
  { text: 'Branch name', field: 'REACT_APP_BRANCH' },
  { text: 'Message', field: 'REACT_APP_MESSAGE' },
  { text: 'Build URL', field: 'REACT_APP_BUILD_URL' },
];
const apiConfigLabels = [
  { text: 'Current API server', field: 'apiDomain' },
];
const backendVersionLabels = [
  { text: 'Backend version', field: 'backendVersion' },
];
const backendDefaultVersionLabels = [
  { text: 'Default API version', field: 'defaultVersion' },
];
const backendSupportedVersionsLabels = [
  { text: 'Version', field: 'version' },
  { text: 'Version number', field: 'versionNumber' },
];
const tasksVersionLabels = [
  { text: 'Tasks version', field: 'tasks' },
];
const automatedOperationsLabels = [
  { text: 'Automated operations version', field: 'automatedOperations' },
];
const auditLogServiceLabels = [
  { text: 'Audit log service version', field: 'auditLogService' },
];
const humanizeLogServiceLabels = [
  { text: 'Humanize log service version', field: 'humanizeLogService' },
];

const buildInfo = pick(process.env, buildInfoLabels.map(label => label.field));

class Settings extends Component {

  constructor() {
    super();

    this.handleChangeApiDomain = this.handleChangeApiDomain.bind(this);
  }

  handleChangeApiDomain(values) {
    const { onChangeApiDomain } = this.props;

    return new Promise((resolveForm, rejectForm) => {
      onChangeApiDomain({ values, resolveForm, rejectForm });
    });
  }

  render() {
    const {
      apiDomain,
      backendVersionData: {
        backendVersion,
        API: { defaultVersion, supportedVersions } = {},
      },
      serviceVersions: {
        tasks,
        automatedOperations,
        auditLogService,
        humanizeLogService,
      },
    } = this.props;

    return (
      <div>
        <PageHeader title="Settings" />
        <Header as="h3">Frontend</Header>
        <DomainForm onSubmit={this.handleChangeApiDomain} />
        <Segment>
          <DefinitionList data={{ apiDomain }} labels={apiConfigLabels} />
          <DefinitionList data={buildInfo} labels={buildInfoLabels} />
        </Segment>
        <Header as="h3">Backend</Header>
        <Segment>
          <DefinitionList data={{ backendVersion }} labels={backendVersionLabels} />
          <DefinitionList data={{ defaultVersion }} labels={backendDefaultVersionLabels} />
          <Divider />
          <DefinitionListAccordion
            open
            heading="h4"
            headingText="Supported API versions"
            labels={backendSupportedVersionsLabels}
            pluralDefinitionList={supportedVersions}
          />
        </Segment>
        <Header as="h3">Services</Header>
        <Segment>
          <DefinitionList data={{ tasks }} labels={tasksVersionLabels} />
          <DefinitionList data={{ automatedOperations }} labels={automatedOperationsLabels} />
          <DefinitionList data={{ auditLogService }} labels={auditLogServiceLabels} />
          <DefinitionList data={{ humanizeLogService }} labels={humanizeLogServiceLabels} />
        </Segment>
      </div>
    );
  }

}

Settings.defaultProps = {
  backendVersionData: {},
  serviceVersions: {},
};

Settings.propTypes = {
  backendVersionData: PropTypes.object,
  apiDomain: PropTypes.string.isRequired,
  onChangeApiDomain: PropTypes.func.isRequired,
  serviceVersions: PropTypes.object,
};

export default Settings;
