import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Header } from 'semantic-ui-react';

import { messages } from 'data/notifications/notificationsConst';

import { Button } from 'components/atomic';
import { StyledTempSiteMarker, StyledLoader } from './TempSiteMarker.style';

class TempSiteMarker extends Component {

  constructor() {
    super();

    this.handleUpdateProjectSuiteLocation = this.handleUpdateProjectSuiteLocation.bind(this);
  }

  handleUpdateProjectSuiteLocation() {
    const {
      projectSuiteId,
      longitude,
      latitude,
      onUpdateProjectSuite,
    } = this.props;

    onUpdateProjectSuite({
      values: {
        id: projectSuiteId,
        longitude,
        latitude,
      },
      message: messages.PROJECT_SUITE_LOCATION_UPDATED,
    });
  }

  render() {
    const { longitude, latitude, tempAddress, onCancel } = this.props;

    return (
      <StyledTempSiteMarker>
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Header as="h4">Update Multi-site location?</Header>
              <p><strong>Latitude:</strong> {latitude}</p>
              <p><strong>Longitude:</strong> {longitude}</p>
              <strong>New address: </strong>
              {tempAddress || <StyledLoader size="mini" active inline />}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={8}>
              <Button
                primary
                icon="checkmark"
                onClick={this.handleUpdateProjectSuiteLocation}
                style={{ width: '100%' }}
              />
            </Grid.Column>
            <Grid.Column mobile={8}>
              <Button
                icon="remove"
                onClick={onCancel}
                style={{ width: '100%' }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </StyledTempSiteMarker>
    );
  }

}

TempSiteMarker.defaultProps = {
  projectSuiteId: null,
  longitude: null,
  latitude: null,
  tempAddress: null,
};

TempSiteMarker.propTypes = {
  projectSuiteId: PropTypes.string,
  longitude: PropTypes.number,
  latitude: PropTypes.number,
  tempAddress: PropTypes.string,
  onUpdateProjectSuite: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default TempSiteMarker;
