import styled, { css } from 'styled-components';
import { Loader } from 'semantic-ui-react';

export const StyledMapInformationWrapper = styled.div`
  margin-top: 1em;
  display: flex;
  flex-direction: column;
`;

export const StyledMapInformationItem = styled.div`
  width: 100%;
  margin-bottom: 0.5em;

  ${({ flex }) => flex && css`
    display: flex;
    align-items: center;
  `}
`;

export const StyledLoader = styled(Loader)`
  &.ui.loader {
    margin-left: 1em;
  }
`;

export const StyledLabel = styled.label`
  font-weight: bold;
  margin-right: 8px;
  display: block;
`;

export default StyledLoader;
