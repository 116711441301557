import React from 'react';

import SnapshotsSection from 'components/SnapshotsSection/SnapshotsSectionContainer';

const labels = [
  { text: 'Number', field: 'id', width: 2 },
  { text: 'Label', field: 'name', width: 3 },
  { text: 'Active', field: 'active', width: 7 },
  { text: '', field: '', width: 3 },
];

const PathwaySnapshotsV1 = props => <SnapshotsSection {...props} labels={labels} />;

export default PathwaySnapshotsV1;
