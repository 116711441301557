import { connect } from 'react-redux';
import { deepEqualMemoizeOne } from 'helpers/sharedMethods';

import { deviceSelector } from 'helpers/selectors';
import SLCHardwareV1 from './SLCHardwareV1';

export const makeMapState = () => {
  const defaultTimestamp = 1582900503.113;
  const memoModel = deepEqualMemoizeOne();
  const memoSerial = deepEqualMemoizeOne();
  const memoFirmwareVersion = deepEqualMemoizeOne();
  const memoUptime = deepEqualMemoizeOne();
  const memoCurrentTime = deepEqualMemoizeOne();
  const memoDataStorage = deepEqualMemoizeOne();
  const memoDiskUsedSpace = deepEqualMemoizeOne();
  const defaultDataStorage = { value: 1073741824, timestamp: defaultTimestamp };
  const defaultDiskUsedSpace = { value: 13, timestamp: defaultTimestamp };

  return function mapStateToProps(state, { resourceId }) {
    const device = deviceSelector(state)(resourceId);
    const { online, reportedWithMeta } = device;

    const {
      sw: {
        fw: firmwareVersion,
      } = {},
      hw: {
        md: model,
        sn: serial,
        up: uptime,
        sd: dataStorage = defaultDataStorage,
        sdup: diskUsedSpace = defaultDiskUsedSpace,
      } = {},
      clk: {
        now: currentTime,
      } = {},
    } = reportedWithMeta || {};

    return {
      loading: !reportedWithMeta,
      online,
      model: memoModel(model),
      serial: memoSerial(serial),
      firmwareVersion: memoFirmwareVersion(firmwareVersion),
      uptime: memoUptime(uptime),
      currentTime: memoCurrentTime(currentTime),
      dataStorage: memoDataStorage(dataStorage),
      diskUsedSpace: memoDiskUsedSpace(diskUsedSpace),
    };
  };
};

export default connect(makeMapState)(SLCHardwareV1);
