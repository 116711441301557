import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, change, getFormValues } from 'redux-form';

import formNames from 'components/form/formNames';
import { validateOnSubmit } from 'components/form/formConfigs';
import { frontendPermissions, permissionsPass } from 'gateway';
import selectors from '../../../../../../../../utilities/selectors';
import DuplicatePageConfirmation from './DuplicatePageConfirmation';

export const mapStateToProps = (state, { match }) => {
  const { id: projectId } = match.params;
  const { pages = [] } = selectors.projectControlUISelector(state)(projectId);
  const totalPages = pages.length;
  const selector = getFormValues(formNames.duplicatePage);
  const { paginationIndex } = selector(state) ? selector(state) : { paginationIndex: 0 };

  return {
    totalPages,
    pageIndex: paginationIndex,
    initialValues: {
      paginationIndex: parseInt(totalPages + 1, 10),
    },
  };
};

export const mapDispatchToProps = dispatch => ({
  changePaginationIndex: value => {
    dispatch(change(formNames.duplicatePage, 'paginationIndex', value));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formNames.duplicatePage,
    ...validateOnSubmit,
  }),
  permissionsPass(frontendPermissions.EDIT_CONTROL_PANEL),
)(DuplicatePageConfirmation);
