import { compose } from 'redux';
import { connect } from 'react-redux';

import { selectors } from '../../utilities/user';
import PublicSwitch from './PublicSwitch';

export const mapStateToProps = state => ({
  twoFactorRedirect: (
    selectors.twoFactorNeedsSetupSelector(state)
      || selectors.twoFactorActiveSelector(state)
  ),
});

export default compose(
  connect(mapStateToProps),
)(PublicSwitch);
