import styled from 'styled-components';
import { Accordion } from 'semantic-ui-react';

const StyledAccordion = styled(Accordion)`
  & .title {
    font-weight: 700;
  }

  & .content {
    padding-top: 0!important;
  }
`;

export default StyledAccordion;
