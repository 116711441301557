import { Header } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import filterProps from 'helpers/filterProps';
import InlineEdit from 'components/form/InlineEdit/InlineEditContainer';

const FilteredPropsHeader = filterProps(['isInConfigurationPanel'])(Header);

export const StyledHeader = styled(FilteredPropsHeader)`
  &.ui.header {
    overflow: hidden;

    ${({ isInConfigurationPanel }) => isInConfigurationPanel && css`
      margin-top: 0px;
    `}
  }
`;

const FilteredProps = filterProps(['nameColor'])(InlineEdit);

export const StyledEditPage = styled(FilteredProps)`
  display: inline-block;

  ${({ isInConfigurationPanel }) => isInConfigurationPanel && css`
    border: solid 1px #dedede;
    border-radius: 4px;
    font-size: 14px;

    & div {
      min-height: 10px !important;
    }
    
    &:hover {
      border: solid 1px transparent;
    }
  `}

  & .ui.input {
    color: ${({ nameColor: { r, g, b, a } }) => rgba(r, g, b, a)};
  }
`;

export const StyledPageName = styled.span`
  color: ${({ nameColor: { r, g, b, a } }) => rgba(r, g, b, a)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
`;
