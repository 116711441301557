class JamboxTimelineModel {

  constructor(jamboxTimeline = {}) {
    this.id = jamboxTimeline.id;
    this.description = jamboxTimeline.description;
    this.relayOutput = jamboxTimeline.relayOutput;
    this.repeat = jamboxTimeline.repeat;
    this.startMethod = jamboxTimeline.startMethod;
    this.active = jamboxTimeline.active;
  }

}

export default JamboxTimelineModel;
