import React from 'react';
import PropTypes from 'prop-types';

const CustomBreadcrumb = ({ match, theme }) => {
  const { breadcrumbs = {} } = theme;
  const { url } = match;

  return <span>{breadcrumbs[url]}</span>;
};

CustomBreadcrumb.defaultProps = {
  theme: {},
};

CustomBreadcrumb.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  theme: PropTypes.object,
};

export default CustomBreadcrumb;
