import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { tasksActionCreators } from 'application/tenant/utilities';

import {
  actionCreators as taskSchedulerActionCreators,
  selectors as taskSchedulerSelectors,
} from '../../../../../../utilities';

import DevicesList from './DevicesList';

export const mapStateToProps = (state, { selectedProjectId }) => {
  const allDevices = taskSchedulerSelectors.projectDevicesSelector(state);

  const devices = allDevices.filter(device => (
    device.deviceAssigned !== null
  ));

  return {
    isLoading: taskSchedulerSelectors.isLoadingProjectDevicesSelector(state),
    projectId: selectedProjectId,
    devices,
  };
};

const mapDispatchToProps = {
  onDeviceSelected: taskSchedulerActionCreators.setSelectedDevice,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  chainedLoaderWithSaga(tasksActionCreators.fetchActionTypes),
  chainedLoaderWithSaga(
    ({ projectId }) => taskSchedulerActionCreators.fetchProjectDevices(projectId),
  ),
)(DevicesList);
