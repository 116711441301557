import set from 'lodash/set';
import get from 'lodash/get';
import merge from 'lodash/merge';
import take from 'lodash/take';
import includes from 'lodash/includes';
import isObject from 'lodash/isObject';

import { extractPaths } from 'application/tenant/console/utilities/permissions/permissionsHelpers';

class PermissionsTreeModel {

  constructor(permissions = [], scopes = [], limitedFor = []) {
    let newPermissions = permissions;

    newPermissions = PermissionsTreeModel.filterPermissionsByScopes(newPermissions, scopes);
    newPermissions = PermissionsTreeModel.filterPermissionsByLimitedFor(newPermissions, limitedFor);

    this.permissions = newPermissions;

    const full = extractPaths(this.permissions);
    const pathTree = full.reduce(PermissionsTreeModel.reduceFullPaths, {});
    this.tree = PermissionsTreeModel.addPermissionCount(pathTree);

    this.pathMap = full.reduce(PermissionsTreeModel.reducePathMap, {});
  }

  static filterPermissionsByScopes(permissions, scopes) {
    if (scopes.length === 0) {
      return permissions.filter(({ limitedScope }) => limitedScope.length === 0);
    }

    return permissions.filter(
      ({ limitedScope }) => (
        limitedScope.length === 0
        || limitedScope.some(
          scope => includes(scopes, scope),
        )
      ),
    );
  }

  static filterPermissionsByLimitedFor(permissions, limitedForFilter) {
    if (limitedForFilter.length === 0) {
      return permissions;
    }

    return permissions.filter(
      ({ limitedFor = [] }) => limitedFor.some(limited => includes(limitedForFilter, limited)),
    );
  }

  static reduceFullPaths(tree, { id, tokens }) {
    const fragment = set({}, tokens, { permissionId: id });

    return merge(
      {},
      tree,
      fragment,
    );
  }

  static addPermissionCount(tree) {
    if (!isObject(tree)) {
      return tree;
    }

    const children = Object.keys(tree).reduce(
      PermissionsTreeModel.reduceRecursiveChildren(tree),
      {},
    );

    return {
      ...children,
    };
  }

  static reduceRecursiveChildren(tree) {
    return (acc, key) => {
      const { permissionCount = 0 } = acc;
      const child = tree[key];

      if ('permissionId' in child) {
        return {
          ...acc,
          [key]: child,
          permissionCount: permissionCount + 1,
        };
      }

      const withPermissionCount = PermissionsTreeModel.addPermissionCount(child);

      return {
        ...acc,
        [key]: withPermissionCount,
        permissionCount: permissionCount + withPermissionCount.permissionCount,
      };
    };
  }

  static reducePathMap(map, { tokens, original }) {
    const tokensString = tokens.join('.');
    const originalString = original.join('.');

    return {
      ...map,
      [originalString]: tokensString,
    };
  }

  mergeData(data) {
    return Object.keys(data).reduce(PermissionsTreeModel.reduceDataToMerge(data), this.tree);
  }

  static reduceDataToMerge(data) {
    return (tree, path) => {
      const { ...pathData } = data[path];

      const fragment = set(
        {},
        path,
        pathData,
      );

      const hasPath = !!get(tree, path);

      if (hasPath) {
        return merge(
          {},
          tree,
          fragment,
        );
      }

      return tree;
    };
  }

  mergeUserPermissions(userPermissions) {
    const reduceUserPermissions = PermissionsTreeModel.reduceUserPermissions(this.pathMap);

    return userPermissions.reduce(reduceUserPermissions, this.tree);
  }

  static reduceUserPermissions(pathMap) {
    return (tree, { permissions }) => {
      const mappedPermissions = permissions.map(({ name }) => {
        const tokens = name.split(':');
        const tokensString = tokens.join('.');

        return { name: pathMap[tokensString] };
      });
      const filteredPermissions = mappedPermissions.filter(({ name }) => !!name);

      return filteredPermissions.reduce(
        PermissionsTreeModel.reducePermissions,
        tree,
      );
    };
  }

  static reducePermissions(tree, { name }) {
    const tokens = name.split('.');

    return tokens.reduce(
      PermissionsTreeModel.reduceUserPathTokens,
      tree,
    );
  }

  static reduceUserPathTokens(tree, token, index, tokens) {
    const takeUntil = index + 1;
    const path = take(tokens, takeUntil);
    const { userCount = 0 } = get(tree, path, {});
    const fragment = set(
      {},
      path,
      {
        userCount: userCount + 1,
      },
    );

    return merge(
      {},
      tree,
      fragment,
    );
  }

}

export default PermissionsTreeModel;
