import React from 'react';
import PropTypes from 'prop-types';

import { attachId } from 'helpers/layoutSwitchHelpers';
import { StyledInlineEditTitle } from 'components/elements/StyledInlineEditTitle/StyledInlineEditTitle.style';
import { HeaderWrapper } from 'components/elements';
import TabbedLayout from 'components/TabbedLayout/TabbedLayout';
import { validDisplayName } from 'components/form/formValidators';
import { actionCreators } from 'pages/ProjectSuites/utilities';
import { Button } from 'components/atomic';

import { ProjectSuitePropertyInlineEdit } from './ProjectSuiteSettingsTab/components/ProjectSuiteInformation/components';
import projectSuiteTabs from './projectSuiteTabs';

import { Container } from './ProjectSuite.style';

const tabsWithIds = attachId(projectSuiteTabs);
const controlPanelUrl = 'control_panel';

function ProjectSuite({ name }) {
  return (
    <>
      <HeaderWrapper>
        <Container>
          <StyledInlineEditTitle size="huge">
            {name && (
            <ProjectSuitePropertyInlineEdit
              property="name"
              onChange={actionCreators.updateProjectSuite}
              validator={validDisplayName}
            />
            )}
          </StyledInlineEditTitle>
        </Container>
        <Button forwardedAs="a" href={controlPanelUrl} primary target="_blank">
          Go to Control Panel
        </Button>
      </HeaderWrapper>
      <TabbedLayout panes={tabsWithIds} />
    </>
  );
}

ProjectSuite.defaultProps = {
  name: '',
};

ProjectSuite.propTypes = {
  name: PropTypes.string,
};

export default ProjectSuite;
