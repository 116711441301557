import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'semantic-ui-react';

import { layoutRowsType } from 'data/types';
import TabbedLayoutRow from '../TabbedLayoutRow/TabbedLayoutRow';
import { CompactGrid } from './TabbedLayoutPane.style';

const TabbedLayoutPane = ({ rows, resourceId }) => (
  <Tab.Pane as="div">
    <CompactGrid stackable>
      {rows.map(({ id, columns }) => (
        <TabbedLayoutRow
          key={id}
          columns={columns}
          resourceId={resourceId}
        />
      ))}
    </CompactGrid>
  </Tab.Pane>
);

TabbedLayoutPane.defaultProps = {
  rows: [],
  resourceId: null,
};

TabbedLayoutPane.propTypes = {
  rows: layoutRowsType,
  resourceId: PropTypes.string,
};

export default TabbedLayoutPane;
