import styled from 'styled-components';

import DataTable from 'components/DataTable/DataTableContainer';

const StyledDataTable = styled(DataTable)`
  td {
    font-size: 12px;
  }
`;

export default StyledDataTable;
