import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { actionCreators as controlUIActionCreators } from '../../../../../../utilities';
import { actionCreators as taskSchedulerActionCreators } from '../../../../../../../../../console/site/components/structural/SiteSchedulingTab/components/AutomatedOperations/components/AddAutomatedOperation/utilities';

import ControlUIOverrideControl from './ControlUIOverrideControl';

export const mapStateToProps = (state, { form, match }) => {
  const { id: projectId } = match.params;

  const { values } = state.form[form] || {};

  return {
    projectId,
    values,
    displayCopyPasteButtons: true,
  };
};

const mapDispatchToProps = {
  onUpdateOverrideControls: controlUIActionCreators.updateOverrideControls,
  onResetTaskScheduler: taskSchedulerActionCreators.resetTaskScheduler,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ControlUIOverrideControl);
