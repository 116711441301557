import {
  OCCURRENCE_TYPE,
  OCCURRENCE_TYPE_FROM_NUMBER,
  OCCURRENCE_DAY,
  OCCURRENCE_DAY_FROM_NUMBER,
} from '../../../../constants';

const getOptionsFromCondition = condition => {
  if (!condition) {
    return {};
  }

  const keys = Object.keys(condition);

  if (keys.includes('daysFromMonthStart')) {
    return {
      occurrenceType: OCCURRENCE_TYPE_FROM_NUMBER[condition.daysFromMonthStart],
      occurrenceDay: OCCURRENCE_DAY.DAY,
    };
  }

  if (keys.includes('daysFromMonthEnd')) {
    return {
      occurrenceType: OCCURRENCE_TYPE.LAST,
      occurrenceDay: OCCURRENCE_DAY.DAY,
    };
  }

  if (keys.includes('on')) {
    const { weekday, nthOccurrence } = condition.on;

    return {
      occurrenceType: OCCURRENCE_TYPE_FROM_NUMBER[nthOccurrence],
      occurrenceDay: OCCURRENCE_DAY_FROM_NUMBER[weekday],
    };
  }

  return {};
};

export default getOptionsFromCondition;
