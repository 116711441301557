import styled, { css } from 'styled-components';
import get from 'lodash/get';

import colors from 'themes/colors';
import DataTableRow from 'components/DataTable/DataTableRow/DataTableRow';
import { dayViewTableRowStyle } from './utilities';

export const StyledDayViewTableRow = styled(DataTableRow)`
  ${({
    item: {
      style: {
        enabled,
      },
      isOverridden,
    },
  }) => css`
    ${dayViewTableRowStyle(colors.white, enabled, isOverridden)}
    ${({ theme }) => dayViewTableRowStyle(get(theme, 'palette.defaultOccurrenceColor'), enabled, isOverridden)}
    ${({ item: { style: { color } } }) => dayViewTableRowStyle(color, enabled, isOverridden)}
  `}

  & i.icon {
    position: relative;
    bottom: 0;
  }
`;

export default StyledDayViewTableRow;
