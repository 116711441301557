import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  TIMELINES_FIELD,
  JAMBOX_TIMELINES_FIELD,
  deviceSelector,
} from 'helpers/selectors';
import * as timelinesSectionActionCreators from 'application/tenant/console/device/utilities/actionCreators/timelinesSection';
import onMountUnmount from 'helpers/onMountUnmount';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { addPagination } from 'helpers/paginationHelpers';
import websocketActionCreators from 'data/websockets/websocketsActionCreators';
import JamboxTimelinesSection from './JamboxTimelinesSection';

export const mapStateToProps = (state, { resourceId }) => {
  const { online } = deviceSelector(state)(resourceId);

  return {
    deviceId: resourceId,
    isOnline: online,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    addPagination(
      {
        type: TIMELINES_FIELD,
        included: true,
        componentName: JAMBOX_TIMELINES_FIELD,
      },
      ({ deviceId }) => timelinesSectionActionCreators.fetchTimelines(deviceId, {
        sort: 'id',
      }),
    ),
  ),
  onMountUnmount(
    ({ deviceId }) => websocketActionCreators.subscribeDeviceData(deviceId, 'timelines'),
    ({ deviceId }) => websocketActionCreators.unsubscribeDeviceData(deviceId, 'timelines'),
  ),
)(JamboxTimelinesSection);
