import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { Radio } from 'semantic-ui-react';

export default styled(Radio).attrs(() => ({
  toggle: true,
}))`
  ${({ theme: { palette: { primaryColor } } }) => css`
    &.ui.toggle.checkbox {
      & input~.box:before,
      & input~label:before,
      & input:checked~.box:before,
      & input:checked~label:before {
        background-color: ${lighten(0.3, primaryColor)}!important;
      }

      & input~.box:after,
      & input~label:after {
        background-color: ${lighten(0.4, primaryColor)}!important;
      }

      & input:checked~.box:after,
      & input:checked~label:after {
        background-color: ${primaryColor}!important;
      }
    }
  `}
`;
