import React, { useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Popup } from 'semantic-ui-react';
import { FiAlertCircle } from 'react-icons/fi';
import includes from 'lodash/includes';

import {
  reasons,
  reasonNames,
  connectionErrors,
  CONNECTION_ERROR_MESSAGE,
  PROJECT_EXECUTION_DISABLED_MESSAGE,
} from '../../constants';
import RowIconWrapper from '../RowIconWrapper/RowIconWrapper.style';
import { MultipleIconWrapper } from './components';

const Reason = ({
  multiple,
  reason,
  meta:
    { offset, baseTime, aoName, taskName, uuid } = {}, timezoneId,
}) => {
  const { [reason]: { display = 'Error', icon: Icon = FiAlertCircle } = {} } = reasons;
  const offsetSign = offset > 0 ? '+' : '-';
  const formattedReasonTime = DateTime
    .fromISO(baseTime)
    .setZone(timezoneId)
    .toLocaleString({ ...DateTime.DATETIME_FULL_WITH_SECONDS, hour12: false });

  const getReasonPhrase = useCallback(() => {
    if (reason === reasonNames.OVERRIDDEN_BY_PRIORITY) {
      return (
        <span>
          {display} by ({aoName})
        </span>
      );
    }

    if (reason === reasonNames.INVALID_STATE) {
      return (
        <span>
          The task {taskName} has an action with an invalid status
        </span>
      );
    }

    if (reason === reasonNames.DISABLED_TASK_SCHEDULER) {
      return (
        <span>
          Task scheduler is disabled, could not run the occurrence
        </span>
      );
    }

    if (reason === reasonNames.NOT_FOUND) {
      return (
        <span>
          Task with id: {uuid} was not found
        </span>
      );
    }

    if (reason === reasonNames.OFFLINE_DEVICE_IN_ACTIONS) {
      return (
        <span>
          {taskName} - there is an offline device in one of the actions
        </span>
      );
    }

    if (reason === reasonNames.LIMITED_PROJECT_DEVICE_IN_ACTIONS) {
      return (
        <span>
          Failed due to the Site being in a limited state
        </span>
      );
    }

    if (reason === reasonNames.PROJECT_EXECUTION_DISABLED) {
      return (
        <span>
          {PROJECT_EXECUTION_DISABLED_MESSAGE}
        </span>
      );
    }

    if (includes(connectionErrors, reason)) {
      return (
        <span>
          {CONNECTION_ERROR_MESSAGE}
        </span>
      );
    }

    return (
      <span>
        {display} ({formattedReasonTime})
        {offset !== 0 && (
          <span>
            &nbsp;
            {offsetSign}
            &nbsp;
            {Math.abs(offset)}
            &nbsp;
            minutes
          </span>
        )}
      </span>
    );
  }, [aoName, display, formattedReasonTime, offset, offsetSign, reason, taskName, uuid]);

  if (multiple) {
    return (
      <Fragment>
        <MultipleIconWrapper>
          <Icon />
        </MultipleIconWrapper>
        {getReasonPhrase()}
      </Fragment>
    );
  }

  return (
    <Popup
      trigger={(
        <RowIconWrapper>
          <Icon />
        </RowIconWrapper>
      )}
      content={getReasonPhrase()}
    />
  );
};

Reason.defaultProps = {
  multiple: false,
  reason: null,
  meta: {},
};

Reason.propTypes = {
  multiple: PropTypes.bool,
  reason: PropTypes.string,
  meta: PropTypes.shape({
    offset: PropTypes.number,
    baseTime: PropTypes.string,
    aoName: PropTypes.string,
    taskName: PropTypes.string,
    uuid: PropTypes.string,
  }),
  timezoneId: PropTypes.string.isRequired,
};

export default Reason;
