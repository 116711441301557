import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import formNames from 'components/form/formNames';

import PrioritySelect from './PrioritySelect';

export const mapStateToProps = state => ({
  priority: formValueSelector(formNames.taskScheduler)(state, 'priority'),
  priorityType: formValueSelector(formNames.taskScheduler)(state, 'priorityType'),
});

export default connect(mapStateToProps, null)(PrioritySelect);
