import React from 'react';
import PropTypes from 'prop-types';

import { labelsType } from 'data/types';
import DataTable from 'components/DataTable/DataTableContainer';
import { STATIC_SCENES_FIELD } from 'helpers/selectors';
import RefreshInterface from 'components/RefreshInterface/RefreshInterfaceContainer';

const StaticScenesSection = ({ title, labels, deviceId }) => {
  const refreshUI = (
    <RefreshInterface
      deviceId={deviceId}
      dataId="static_scenes"
    />
  );

  return (
    <DataTable
      header={title}
      labels={labels}
      createElement={refreshUI}
      pagination={{
        type: STATIC_SCENES_FIELD,
        included: true,
      }}
      highlightUpdates
    />
  );
};


StaticScenesSection.defaultProps = {
  title: '',
};

StaticScenesSection.propTypes = {
  labels: labelsType.isRequired,
  title: PropTypes.string,
  deviceId: PropTypes.string.isRequired,
};

export default StaticScenesSection;
