import React, { Fragment } from 'react';
import truncate from 'lodash/truncate';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import pluralize from 'pluralize';
import { DateTime } from 'luxon';

import { CONSTRUCTION_MESSAGE, SITE_SUSPENDED, siteStates } from 'helpers/sharedVariables';
import { PROJECTS_FIELD } from 'helpers/selectors';
import ApiRequestBuilder from 'helpers/ApiRequestBuilder';
import { InConstructionSiteIcon, SuspendedSiteIcon } from './components';

export const terms = {
  MEMBER: 'member',
  OTHER: 'other',
  DEVICE: 'device',
};

export const ealing = { latitude: 51.511990, longitude: -0.304405 };

class ProjectModel {

  constructor(project, user, self = false) {
    this.id = project.id;
    this.name = project.name;
    this.users = project.users;
    this.usersCount = project.usersCount;
    this.logicalDevices = project.logicalDevices;
    this.logicalDevicesCount = project.logicalDevicesCount;
    this.createdAt = project.createdAt;
    this.serverLocation = {
      latitude: project.latitude,
      longitude: project.longitude,
      timezoneId: project.timezoneId,
    };
    this.tempAddress = project.tempAddress;
    this.geoAddress = project.geoAddress;
    this.street = project.street;
    this.city = project.city;
    this.country = project.country;
    this.postcode = project.postcode;
    this.offset = project.offset;
    this.primaryContactPerson = project.primaryContactPerson;
    this.primaryContactEmail = project.primaryContactEmail;
    this.primaryContactPhone = project.primaryContactPhone;
    this.notes = project.notes;
    this.construction = project.construction;
    this.renewalDate = project.renewalDate;
    this.graceEndDate = project.graceEndDate;
    this.constructionEndDate = project.constructionEndDate;
    this.state = project.state;
    this.demo = project.demo;
    this.trialPeriodDays = project.trialPeriodDays;
    this.subscriptionPlanCode = project.subscriptionPlanCode;
    this.projectedSubscriptionPlanCode = project.projectedSubscriptionPlanCode;
    this.projectedRenewalDate = project.projectedRenewalDate;
    this.technicalContactEmail = project.technicalContactEmail;
    this.subscriptionContactEmail = project.subscriptionContactEmail;
    this.commercialContactEmail = project.commercialContactEmail;
    this.points = project.points;
    this.autoRenewYears = project.autoRenewYears;
    this.autoRenewComment = project.autoRenewComment;
    this.dateBlockingStartTime = project.dateBlockingStartTime;
    this.projectSuitesCount = project.projectSuitesCount;

    this.currentUser = self && user;
  }

  get locationLoaded() {
    const { latitude, longitude, timezoneId } = this.serverLocation;

    return !isUndefined(latitude) && !isUndefined(longitude) && !isUndefined(timezoneId);
  }

  get locationSet() {
    const { latitude, longitude } = this.serverLocation;

    return this.locationLoaded && !isNull(latitude) && !isNull(longitude);
  }

  get timezoneSet() {
    const { timezoneId } = this.serverLocation;

    return this.locationLoaded && !isNull(timezoneId);
  }

  get latitude() {
    const { locationSet, serverLocation: { latitude } } = this;

    if (locationSet) {
      return parseFloat(latitude);
    }

    return ealing.latitude;
  }

  get longitude() {
    const { locationSet, serverLocation: { longitude } } = this;

    if (locationSet) {
      return parseFloat(longitude);
    }

    return ealing.longitude;
  }

  get timezoneId() {
    const { timezoneSet, serverLocation: { timezoneId } } = this;

    if (timezoneSet) {
      return timezoneId;
    }

    return null;
  }

  get datetime() {
    if (this.timezoneSet) {
      return DateTime.local().setZone(this.timezoneId);
    }

    return null;
  }

  get datetimeNow() {
    if (this.timezoneSet) {
      return DateTime.local().setZone(this.timezoneId);
    }

    return DateTime.local().setZone('utc');
  }

  get team() {
    const { users, usersCount, currentUser } = this;

    if (users) {
      if (currentUser && users.some(({ id }) => id === currentUser.id)) {
        return this.teamWithCurrentUser();
      }

      return this.teamWithoutCurrentUser();
    }

    return pluralize(terms.MEMBER, usersCount, true);
  }

  teamWithCurrentUser() {
    const { users, currentUser } = this;
    const { length } = users;

    if (length === 1) {
      return 'Only you';
    }

    const usersWithoutCurrentUser = users.filter(({ id }) => id !== currentUser.id);
    const { namedUsers, unnamedUsers } = ProjectModel
      .splitIntoNamedAndUnnamedUsers(usersWithoutCurrentUser);
    const { length: namedUsersCount } = namedUsers;
    const { length: unnamedUsersCount } = unnamedUsers;

    if (!namedUsersCount) {
      return `You and ${pluralize(terms.OTHER, unnamedUsersCount, true)}`;
    }

    const [secondary, ...otherNamedUsers] = namedUsers;
    const secondaryString = ProjectModel.getFullName(secondary);
    const totalOthersCount = otherNamedUsers.length + unnamedUsersCount;

    if (!totalOthersCount) {
      return `You and ${secondaryString}`;
    }

    return `You, ${secondaryString}, and ${pluralize(terms.OTHER, totalOthersCount, true)}`;
  }

  teamWithoutCurrentUser() {
    const { users } = this;

    const { namedUsers, unnamedUsers } = ProjectModel
      .splitIntoNamedAndUnnamedUsers(users);
    const { length: namedUsersCount } = namedUsers;
    const { length: unnamedUsersCount } = unnamedUsers;

    if (!namedUsersCount) {
      return pluralize(terms.MEMBER, unnamedUsersCount, true);
    }

    const [primary, secondary, ...otherNamedUsers] = namedUsers;
    const primaryString = ProjectModel.getFullName(primary);

    if (namedUsersCount === 1) {
      if (unnamedUsersCount) {
        return `${primaryString} and ${pluralize(terms.OTHER, unnamedUsersCount, true)}`;
      }

      return `${primaryString}`;
    }

    const secondaryString = ProjectModel.getFullName(secondary);
    const totalOthersCount = otherNamedUsers.length + unnamedUsersCount;

    if (!totalOthersCount) {
      return `${primaryString} and ${secondaryString}`;
    }

    return `${primaryString}, ${secondaryString}, and ${pluralize(terms.OTHER, totalOthersCount, true)}`;
  }

  static splitIntoNamedAndUnnamedUsers(users) {
    const namedUsers = users
      .filter(({ firstName, lastName }) => firstName && lastName);
    const unnamedUsers = users
      .filter(({ firstName, lastName }) => !firstName && !lastName);

    return { namedUsers, unnamedUsers };
  }

  static getFullName({ firstName, lastName }) {
    return `${firstName} ${lastName}`;
  }

  get devices() {
    const { logicalDevices, logicalDevicesCount } = this;

    if (logicalDevices) {
      const { length } = logicalDevices;

      if (length > 3) {
        return pluralize(terms.DEVICE, length, true);
      }

      return logicalDevices.map(device => device.name).join(', ');
    }

    if (logicalDevicesCount) {
      return pluralize(terms.DEVICE, logicalDevicesCount, true);
    }

    return 'No devices';
  }

  get visibleName() {
    return truncate(this.name, {
      length: 30,
      separator: ' ',
      omission: ' ...',
    });
  }

  get isConstruction() {
    return this.state === siteStates.CONSTRUCTION;
  }

  get isSuspended() {
    return this.state === siteStates.SUSPENDED;
  }

  get isDemo() {
    return this.state === siteStates.DEMO;
  }

  get isGrace() {
    return this.state === siteStates.GRACE;
  }

  get stateLoaded() {
    return !isUndefined(this.state);
  }

  get visibleNameWithState() {
    const name = truncate(this.name, {
      length: 30,
      separator: ' ',
      omission: ' ...',
    });

    return (
      <Fragment>
        {name}
        {this.isConstruction && (<InConstructionSiteIcon message={CONSTRUCTION_MESSAGE} />)}
        {this.isSuspended && (<SuspendedSiteIcon message={SITE_SUSPENDED} />)}
      </Fragment>
    );
  }

  get dateCreated() {
    if (!this.createdAt) {
      return null;
    }

    return DateTime.fromISO(this.createdAt).toLocaleString({
      weekday: 'short',
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  }

  get dateRenewal() {
    if (!this.renewalDate) {
      return 'Not set';
    }

    return DateTime.fromISO(this.renewalDate).toLocaleString({
      weekday: 'short',
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  }

  get showAutoRenewal() {
    if (this.datetimeNow && this.renewalDate) {
      const renewal = DateTime.fromISO(this.renewalDate).toMillis();
      const currentDate = this.datetimeNow.toMillis();

      return renewal > currentDate;
    }

    return false;
  }

  get isInGracePeriod() {
    if (this.datetimeNow && this.renewalDate && this.graceEndDate) {
      const renewal = DateTime.fromISO(this.renewalDate).toMillis();
      const graceEndDate = DateTime.fromISO(this.graceEndDate).toMillis();
      const currentDate = this.datetimeNow.toMillis();

      if (currentDate > renewal && graceEndDate > currentDate) {
        return true;
      }
    }

    return false;
  }

  get isApproachingRenewal() {
    if (this.datetimeNow && this.renewalDate) {
      const currentDateInMillisec = this.datetimeNow.toMillis();
      const approachingDateInMillisec = DateTime.fromISO(this.renewalDate).toMillis();
      const millisecondsInOneDay = 86400000;
      const millisecondsInOneMonth = millisecondsInOneDay * 30;

      const differenceInMillisec = approachingDateInMillisec - currentDateInMillisec;
      if (Math.sign(differenceInMillisec) === 1 && differenceInMillisec < millisecondsInOneMonth) {
        return true;
      }
    }

    return false;
  }

  get showAutoRenewalSuspended() {
    if (this.datetimeNow && this.renewalDate) {
      const renewal = DateTime.fromISO(this.renewalDate).toMillis();
      const currentDate = this.datetimeNow.toMillis();

      return renewal < currentDate;
    }

    return false;
  }

  static requestBody(values) {
    return new ApiRequestBuilder(PROJECTS_FIELD)
      .setAttributes(values);
  }

}

export default ProjectModel;
