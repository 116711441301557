import { connect } from 'react-redux';

import UserModel from 'models/UserModel';
import {
  currentUserSelector,
  settingsApiDomainChangedSelector,
  settingsApiDomainSelector,
  controlPanelContextSelector,
  currentCompanyNotificationsEnabledSelector,
  isPageNavigationOpenSelector,
} from 'helpers/selectors';
import TopBar from './TopBar';

export const mapStateToProps = state => {
  const currentUser = currentUserSelector(state);
  const { id, superadmin } = new UserModel(currentUser || {});

  return {
    currentUserId: id,
    isSuperAdmin: superadmin,
    apiDomain: settingsApiDomainSelector(state),
    isDomainChanged: settingsApiDomainChangedSelector(state),
    inControlPanelContext: controlPanelContextSelector(state),
    notificationsEnabled: currentCompanyNotificationsEnabledSelector(state),
    isPageNavigationOpen: isPageNavigationOpenSelector(state),
  };
};

export default connect(mapStateToProps)(TopBar);
