import endpoints from 'data/api/endpoints';
import { twoFactorStates } from './constants';

const signupSuccessReducer = (state, action) => {
  const {
    [endpoints.SIGN_UP]: {
      meta: {
        two_factor_status: twoFactorStatus,
      },
    },
  } = action.payload.response.meta;

  return {
    ...state,
    recentlyLoggedOut: false,
    twoFactorNeedsSetup: twoFactorStatus === twoFactorStates.NEEDS_SETUP,
    invitationsSuppressed: false,
  };
};

export default signupSuccessReducer;
