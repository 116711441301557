import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import moment from 'moment';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';

import { DatePicker, TimePicker } from 'components/core';

import { SCHEDULER_TYPES } from '../../../../../../../constants';
import { StyledLabel, StyledRow, ErrorMessage } from './OnDateTime.style';

function OnDateTime({
  onChange,
  existingSchedule,
  recurrenceErrors,
}) {
  const [isLoaded, setIsLoaded] = useState(null);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState([]);

  useEffect(() => {
    if (existingSchedule && !isLoaded) {
      const { type, params } = existingSchedule || {};

      if (type.toUpperCase() !== SCHEDULER_TYPES.DATES) {
        const { endTime } = params;

        if (endTime) {
          setDate(moment(endTime));
          setTime([moment.utc(endTime).format('HH:mm:ss')]);
          setIsLoaded(true);
        }
      }
    }

  }, [existingSchedule, isLoaded]);

  useEffect(() => {
    if (!isNull(date) && !isEmpty(time)) {
      const [firstValue] = time;

      onChange({
        endTime: `${date.format('YYYY-MM-DD')}T${firstValue}`,
      });
    }
  }, [onChange, date, time]);

  const handleTimeSelected = useCallback(selectedTime => {
    setTime(selectedTime);
  }, []);

  return (
    <>
      <StyledLabel>
        End date & time
      </StyledLabel>
      <StyledRow noPaddingBottom>
        <Grid.Column computer={6} mobile={8}>
          <DatePicker
            value={date}
            name="end_date"
            min={moment().toDate()}
            onChange={selectedDate => setDate(selectedDate)}
            footer={false}
            popupPosition="left center"
            defaultEmptyMessage="Select a date."
          />
        </Grid.Column>
        <Grid.Column computer={7} mobile={8}>
          <TimePicker
            popupPosition="right center"
            name="end_time"
            selectedTimes={time}
            onChange={handleTimeSelected}
            only={['SIMPLE']}
            defaultEmptyMessage="Select a time."
            single
          />
        </Grid.Column>
      </StyledRow>
      <ErrorMessage>{recurrenceErrors.endTime}</ErrorMessage>
    </>
  );
}

OnDateTime.defaultProps = {
  recurrenceErrors: {},
  existingSchedule: null,
};

OnDateTime.propTypes = {
  recurrenceErrors: PropTypes.object,
  existingSchedule: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default OnDateTime;
