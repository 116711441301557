import { compose } from 'redux';

import extractValue from 'helpers/extractValue/extractValue';
import markLastUpdate from 'helpers/markLastUpdate/markLastUpdate';
import withAutoIncrement from 'helpers/withAutoIncrement/withAutoIncrement';
import formatValue from 'helpers/formatValue/formatValue';
import withPrevious from 'helpers/withPrevious/withPrevious';
import { localDateNormalizer } from '../normalizers';
import DescriptionItem from '../DescriptionItem/DescriptionItem';

const DateDescriptionItem = compose(
  extractValue,
  markLastUpdate,
  withAutoIncrement,
  formatValue(localDateNormalizer),
  withPrevious,
)(DescriptionItem);

export default DateDescriptionItem;
