import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'semantic-ui-react';
import { Field } from 'redux-form';

import {
  FormFieldLabel,
  FormFieldWrapper,
  InputWrapper,
} from 'components/form/FormField/FormField.style';
import SelectField from 'components/form/SelectField/SelectField';
import { StyledLoader } from './SelectSiteAction.style';

const SelectSiteAction = ({ isLoading, availableActions, handleSelect }) => {
  if (isLoading) {
    return (
      <FormGroup inline>
        <StyledLoader active inline />
      </FormGroup>
    );
  }

  return (
    <FormGroup inline>
      <FormFieldWrapper width={16}>
        <FormFieldLabel htmlFor="input">Select a site action</FormFieldLabel>
        <InputWrapper>
          <Field
            name="typeId"
            placeholder="Site actions"
            component={SelectField}
            options={availableActions}
            onChange={handleSelect}
          />
        </InputWrapper>
      </FormFieldWrapper>
    </FormGroup>
  );
};

SelectSiteAction.defaultProps = {
  availableActions: [],
};

SelectSiteAction.propTypes = {
  availableActions: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default SelectSiteAction;
