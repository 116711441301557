import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import { StyledWarningIcon } from './WarningIcon.style';

const WarningIcon = ({ message }) => (
  <Popup
    content={message}
    trigger={(
      <StyledWarningIcon
        color="orange"
        name="exclamation triangle"
        message={message}
        onClick={e => e.stopPropagation()}
      />
    )}
    position="top center"
  />
);

WarningIcon.propTypes = {
  message: PropTypes.string.isRequired,
};

export default WarningIcon;
