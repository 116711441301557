import styled from 'styled-components';
import { Segment, Placeholder } from 'semantic-ui-react';

import { mediaMax } from 'themes/mixins';

export const Container = styled.div`
  ${mediaMax.sm`
    max-height: calc(100vh - 415px) !important;
    overflow: auto !important;
  `};
`;

export const StyledSegment = styled(Segment)`
  height: 50px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px !important;
  margin-top: 4px !important;
  margin-bottom: 8px !important;
  box-shadow: none !important;

  & {
    .ui.placeholder {
      flex: 4;
    } 
  }
`;

export const StyledPlaceholder = styled(Placeholder)`
  margin-left: 8px !important;
`;

export default StyledSegment;
