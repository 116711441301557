import React from 'react';
import PropTypes from 'prop-types';

import ModalForm from 'components/form/ModalForm/ModalForm';

export const SelectTask = () => (
  <p>Here you will select a task</p>
);

const AddOperationModalForm = ({ projectName, ...props }) => {
  const addOperation = `Add Task for schedule on site ${projectName}`;

  return (
    <ModalForm
      header={addOperation}
      trigger="Add Task"
      size="small"
      triggerSize="mini"
      {...props}
    >
      <SelectTask />
    </ModalForm>
  );
};

AddOperationModalForm.defaultProps = {
  projectName: null,
};

AddOperationModalForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  projectName: PropTypes.string,
};

export default AddOperationModalForm;
