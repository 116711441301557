import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const fetchProjectDevices = (projectId, urlParams = {}) => ({
  type: constants.TASK_SCHEDULER_FETCH_PROJECT_DEVICES_REQUEST,
  payload: {
    projectId,
    endpoint: endpoints.LOGICAL_DEVICES_PROJECT(projectId),
    urlParams,
  },
});

export const fetchProjectDevicesSuccess = response => ({
  type: constants.TASK_SCHEDULER_FETCH_PROJECT_DEVICES_SUCCESS,
  payload: {
    response,
  },
});

export const fetchProjectDevicesFailure = error => ({
  type: constants.TASK_SCHEDULER_FETCH_PROJECT_DEVICES_FAILED,
  payload: { error },
});

export default fetchProjectDevices;
