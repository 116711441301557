class ContextModel {

  constructor(context = {}) {
    this.id = context.id;
    this.name = context.name;
    this.displayName = context.displayName;
  }

}

export default ContextModel;
