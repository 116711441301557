import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';

import { complexValueType } from 'data/types';
import TextDescriptionItem
  from 'components/DescriptionList/TextDescriptionItem/TextDescriptionItem';

const PharosSoftwareV1 = props => {
  const { loading } = props;
  if (loading) return null;

  const {
    title,
    firmware,
    bootloader,
  } = props;

  return (
    <Segment>
      <Header as="h2">{title}</Header>
      <TextDescriptionItem
        title="Firmware version"
        value={firmware}
      />
      <TextDescriptionItem
        title="Bootloader version"
        value={bootloader}
      />
    </Segment>
  );
};

PharosSoftwareV1.defaultProps = {
  loading: false,
  firmware: {},
  bootloader: {},
};

PharosSoftwareV1.propTypes = {
  loading: PropTypes.bool,
  firmware: complexValueType(PropTypes.string),
  bootloader: complexValueType(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default PharosSoftwareV1;
