import React from 'react';
import { DateTime } from 'luxon';
import { Label, Popup } from 'semantic-ui-react';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';

import { DEVICE_FIRMWARES_FIELD } from 'helpers/selectors';
import ApiRequestBuilder from 'helpers/ApiRequestBuilder';
import { upperCaseFirstLetterMultiple } from 'helpers/sharedMethods';

import LinkedFiles from 'application/tenant/console/Firmware/components/DeviceFirmwaresTable/components/LinkedFiles/LinkedFiles';
import { StyledDiv, StyledIcon, StyledTextArea } from 'application/tenant/console/Firmware/components/DeviceFirmwaresTable/components/LinkedFiles/LinkedFiles.style';

class DeviceFirmwareModel {

  constructor(deviceFirmware = {}) {
    this.id = deviceFirmware.id;
    this.name = deviceFirmware.name;
    this.description = deviceFirmware.description;
    this.manufacturer = deviceFirmware.manufacturer;
    this.version = deviceFirmware.version;
    this.deviceModels = deviceFirmware.deviceModels;
    this.releaseDate = deviceFirmware.releaseDate;
    this.files = deviceFirmware.files;
    this.hidden = deviceFirmware.hidden;
  }

  get dateRelease() {
    if (!this.releaseDate) {
      return 'Not set';
    }

    return DateTime.fromISO(this.releaseDate).toLocaleString({
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    });
  }

  get displayManufacturer() {
    if (isString(this.manufacturer)) {
      const manufacturerName = this.manufacturer.replace(/_/g, ' ');

      return upperCaseFirstLetterMultiple(manufacturerName);
    }

    return this.manufacturer;
  }

  get displayDeviceModels() {
    if (Array.isArray(this.deviceModels) && !isEmpty(this.deviceModels)) {
      const removeUnderscores = this.deviceModels.map(model => {
        if (isString(model)) {
          return upperCaseFirstLetterMultiple(model.replace(/_/g, ' '));
        }

        return model;
      });

      return removeUnderscores.map((item, index) => {
        const key = `${this.deviceModels[index]}-${index}`;

        return (
          <Label.Group>
            <Label
              htmlFor={`${item}-${index}`}
              key={key}
              as="a"
            >
              {item}
            </Label>
          </Label.Group>
        );
      });
    }

    return this.deviceModels;
  }

  get displayFiles() {
    if (!Array.isArray(this.files)) {
      return null;
    }

    if (this.files.length === 0) {
      return (
        <Label.Group>
          <Label
            htmlFor="files"
            key="files-table"
            as="a"
          >
            0
          </Label>
        </Label.Group>
      );
    }

    return (
      <StyledDiv>
        <Popup
          trigger={(
            <Label.Group>
              <Label
                htmlFor="files"
                key="files-table"
                as="a"
              >
                {this.files.length}
              </Label>
            </Label.Group>
          )}
          position="top center"
          positionFixed
        >
          <LinkedFiles files={this.files} />
        </Popup>
      </StyledDiv>
    );
  }

  get displayCompatibilityNotes() {
    if (isNull(this.description) || isEmpty(this.description)) {
      return (
        <StyledDiv>
          <StyledIcon name="info circle" empty="true" />
        </StyledDiv>
      );
    }

    return (
      <StyledDiv>
        <Popup
          trigger={<StyledIcon name="info circle" />}
          position="top center"
          positionFixed
          hoverable
        >
          <StyledTextArea
            value={this.description}
            readOnly
          />
        </Popup>
      </StyledDiv>
    );
  }

  static extractValues(values) {
    if (isEmpty(values)) {
      return {};
    }

    return {
      name: values.name,
      description: values.description,
      manufacturer: values.manufacturer,
      version: values.version,
      deviceModels: values.deviceModels,
      releaseDate: values.releaseDate,
    };
  }

  static requestBody(values) {
    return new ApiRequestBuilder(DEVICE_FIRMWARES_FIELD)
      .setAttributes(values);
  }

}

export default DeviceFirmwareModel;
