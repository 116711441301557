import constants from 'dispatcherConst';

const updateAutomatedFunctionRemoveSchedule = (
  id, lockVersion, automatedOperationId, passesPermissions,
) => ({
  type: constants.UPDATE_AUTOMATED_FUNCTION_REMOVE_SCHEDULE_REQUEST,
  payload: {
    id,
    lockVersion,
    automatedOperationId,
    passesPermissions,
  },
});

export default updateAutomatedFunctionRemoveSchedule;
