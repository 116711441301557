import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Icon, Popup } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';

import { Button } from 'components/atomic';

import GenerateActionForm from './components/GenerateActionForm/GenerateActionFormContainer';
import { StyledAccordionTitle, StyledAccordionContent, StyledAccordionButtons, ActionTitle } from './ActionItem.style';

function ActionItem({
  index,
  deviceId,
  actionId,
  actionName,
  actionIndex,
  activePanels,
  isValid,
  actionTypes,
  onActionRemove,
  onActionToggle,
  resourceId,
  resourceName,
}) {

  const isActive = useMemo(() => {
    if (isEmpty(activePanels)) {
      return false;
    }

    return activePanels[`${actionId}${resourceId}${actionIndex}`] === true;
  }, [activePanels, actionId, actionIndex, resourceId]);

  const title = useMemo(() => `${actionName} - ${resourceName}`, [actionName, resourceName]);

  const icon = useMemo(() => `chevron ${isActive ? 'down' : 'left'}`, [isActive]);

  const renderPopup = () => {
    if (isValid) {
      return <Icon name="check circle" color="green" />;
    }

    return (
      <Popup
        trigger={<Icon name="exclamation circle" color="red" />}
        content="Invalid configuration"
        position="left center"
        inverted
      />
    );
  };

  const handleDelete = useCallback(() => {
    onActionRemove({
      resourceId,
      actionId,
      actionIndex,
    });
  }, [actionId, actionIndex, resourceId, onActionRemove]);

  const handleToggle = useCallback(() => {
    if (isEmpty(actionTypes[actionId].params)) {
      return;
    }

    onActionToggle({
      resourceId,
      actionId,
      actionIndex,
    });
  }, [actionId, actionIndex, actionTypes, resourceId, onActionToggle]);

  const renderToggleButton = () => {
    if (isEmpty(actionTypes[actionId].params)) {
      return null;
    }

    return (
      <Button
        basic
        size="mini"
        icon={icon}
        className="action-toggle-btn"
        onClick={handleToggle}
      />
    );
  };

  return (
    <>
      <StyledAccordionTitle active={isActive} index={index}>
        <span>
          {renderPopup()}
          <ActionTitle className="action-name" onClick={handleToggle}>
            {title}
          </ActionTitle>
        </span>
        <StyledAccordionButtons>
          {renderToggleButton()}
          <Button className="action-delete-btn" basic size="mini" icon="trash" onClick={handleDelete} />
        </StyledAccordionButtons>
      </StyledAccordionTitle>
      <StyledAccordionContent active={isActive}>
        <GenerateActionForm
          fieldName={`action_${resourceId}_${actionId}_${actionIndex}`}
          logicalDeviceId={deviceId}
          actionType={actionId}
          actionIndex={actionIndex}
          defaultActionName={title}
          resourceId={resourceId}
          resourceName={resourceName}
        />
      </StyledAccordionContent>
    </>
  );
}

ActionItem.defaultProps = {
  activePanels: {},
  actionTypes: {},
  resourceId: null,
  resourceName: null,
  deviceId: null,
};

ActionItem.propTypes = {
  index: PropTypes.number.isRequired,
  deviceId: PropTypes.string,
  actionId: PropTypes.string.isRequired,
  actionName: PropTypes.string.isRequired,
  actionIndex: PropTypes.number.isRequired,
  activePanels: PropTypes.object,
  actionTypes: PropTypes.object,
  isValid: PropTypes.bool.isRequired,
  onActionRemove: PropTypes.func.isRequired,
  onActionToggle: PropTypes.func.isRequired,
  resourceId: PropTypes.string,
  resourceName: PropTypes.string,
};

export default ActionItem;
