import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';

import { complexValueType } from 'data/types';
import TextDescriptionItem
  from 'components/DescriptionList/TextDescriptionItem/TextDescriptionItem';
import LocalDateDescriptionItemIncremented
  from 'components/DescriptionList/LocalDateDescriptionItemIncremented/LocalDateDescriptionItemIncremented';
import BoolDescriptionItem
  from 'components/DescriptionList/BoolDescriptionItem/BoolDescriptionItem';

const SLCClockV1 = props => {
  const {
    title,
    online,
    currentTime,
    offset,
    sntpAddress,
    sntpOn,
    loading,
  } = props;
  const {
    value: offsetValue,
  } = offset;

  if (loading) {
    return null;
  }

  return (
    <Segment>
      <Header as="h2">{title}</Header>
      <BoolDescriptionItem
        title="Sync time with SNTP"
        value={sntpOn}
      />
      <TextDescriptionItem
        title="SNTP Server IP address"
        value={sntpAddress}
      />
      <LocalDateDescriptionItemIncremented
        title="Current date/time"
        value={currentTime}
        isOnline={online}
        offset={offsetValue}
      />
      <TextDescriptionItem
        title="UTC offset"
        value={offset}
      />
    </Segment>
  );
};

SLCClockV1.defaultProps = {
  loading: false,
  online: false,
  currentTime: {},
  offset: {},
  sntpAddress: {},
  sntpOn: {},
};

SLCClockV1.propTypes = {
  loading: PropTypes.bool,
  online: PropTypes.bool,
  currentTime: complexValueType(PropTypes.number),
  offset: complexValueType(PropTypes.number),
  title: PropTypes.string.isRequired,
  sntpAddress: complexValueType(PropTypes.string),
  sntpOn: complexValueType(PropTypes.bool),
};

export default SLCClockV1;
