import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  USERS_FIELD,
  permissionsProjectUsersLinkSelector,
} from 'helpers/selectors';
import { addPagination } from 'helpers/paginationHelpers';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { permissionsActionCreators } from '../../../../../../utilities';
import UserTable from './UserTable';

export const mapStateToProps = (state, { resourceId }) => ({
  usersEndpoint: permissionsProjectUsersLinkSelector(state)(resourceId),
});

export default compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: USERS_FIELD, included: true },
      ({ resourceId, usersEndpoint }) => permissionsActionCreators.fetchProjectUsers(
        resourceId,
        usersEndpoint,
        {
          fields: {
            [USERS_FIELD]: ['firstName', 'lastName', 'email', 'lastLoginTime', 'receivedInvitations'],
          },
          sort: 'firstName',
        },
      ),
    ),
  ),
)(UserTable);
