export const defaultColor = '#FFFFFF';

export const colorList = [
  defaultColor,
  '#DF4953',
  '#EB8A00',
  '#F9AB32',
  '#FCF100',
  '#5AD02D',
  '#77AA00',
  '#4EC6CD',
  '#3FBCF5',
  '#8664BB',
  '#EE95C0',
];

export const priorities = [
  {
    text: 'High',
    value: 4,
  },
  {
    text: 'Above Normal',
    value: 3,
  },
  {
    text: 'Normal',
    value: 2,
  },
  {
    text: 'Below Normal',
    value: 1,
  },
  {
    text: 'Low',
    value: 0,
  },
];

export const modeOptions = [
  {
    key: 'forceExecution',
    value: 'forceExecution',
    text: 'Off',
  },
  {
    key: 'safeTaskExecution',
    value: 'safeTaskExecution',
    text: 'On per Device',
  },
  {
    key: 'safeTaskSchedulerExecution',
    value: 'safeTaskSchedulerExecution',
    text: 'On',
  },
];

export const automatedOperationFormFields = {
  name: 'name',
  description: 'description',
  userTaskId: 'userTaskId',
  selectedTasks: 'taskIds',
  occurrenceColor: 'occurrenceColour',
  enabled: 'enabled',
  priority: 'priority',
  priorityType: 'priorityType',
  mode: 'mode',
  hidden: 'hidden',
};
