import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { AUTOMATED_OPERATIONS_FIELD, TASKS_FIELD, SELECT_TASKS } from 'helpers/selectors';
import withPolling from 'helpers/withPollingSaga/withPollingSagaContainer';
import { addPagination } from 'helpers/paginationHelpers';
import { actionCreators as projectSuiteActionCreators, selectors } from 'pages/ProjectSuites/utilities';

import automatedOperationsActionCreators from './utilities/actionCreators';
import AutomatedOperations from './AutomatedOperations';

const REFRESH_RATE = 60000;

export const mapStateToProps = (state, { match }) => {
  const { id: projectSuiteId } = match.params;
  const superSites = true;
  const existingSelectedProjects = selectors.selectedProjectsSelector(
    state, projectSuiteId, AUTOMATED_OPERATIONS_FIELD,
  ) || [];
  const enableTaskSchedulerEdit = isEmpty(existingSelectedProjects);

  return {
    projectSuiteId,
    superSites,
    enableTaskSchedulerEdit,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: AUTOMATED_OPERATIONS_FIELD, included: true },
      (
        { projectSuiteId, projectsIds },
      ) => automatedOperationsActionCreators.fetchAutomatedOperations(
        projectSuiteId, {
          sort: 'name',
          filter: 'name',
          recipientType: 'projectSuite',
          recipientId: projectSuiteId,
          'projectSuiteIds[]': [projectSuiteId],
          'projectIds[]': [...projectsIds],
        }),
    ),
    addPagination(
      { resourceType: TASKS_FIELD, included: true, componentName: SELECT_TASKS },
      ({ projectSuiteId, projectsIds }) => projectsIds
      && projectSuiteActionCreators.fetchProjectSuiteProjectsTasks({
        recipientType: 'projectSuite',
        recipientId: projectSuiteId,
        'projectSuiteIds[]': [projectSuiteId],
        'projectIds[]': [...projectsIds],
        sort: 'name',
        filter: 'name',
      }),
    ),
  ),
  withPolling(REFRESH_RATE),
)(AutomatedOperations);
