import styled, { css } from 'styled-components';
import { Segment } from 'semantic-ui-react';

export const StyledSegment = styled(Segment)`
  ${({ disabled }) => disabled && css`
    pointer-events: none;
  `}
`;

export default StyledSegment;
