import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { deepEqualMemoizeOne } from 'helpers/sharedMethods';
import { CONTEXTS_FIELD, permissionsStateSelector } from 'helpers/selectors';
import { addPagination } from 'helpers/paginationHelpers';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { permissionsActionCreators, TENANT_CONTEXT_ID } from '../../../../utilities';
import PermissionsContextsTab from './PermissionsContextsTab';

const makeMapState = () => {
  const memoContextIds = deepEqualMemoizeOne();

  return function mapStateToProps(state) {
    const { contexts } = permissionsStateSelector(state);

    return {
      contextIds: memoContextIds(Object.keys(contexts)),
    };
  };
};

export default compose(
  withRouter,
  connect(makeMapState),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: CONTEXTS_FIELD },
      permissionsActionCreators.fetchContexts,
    ),
    () => permissionsActionCreators.addContextId(TENANT_CONTEXT_ID),
  ),
)(PermissionsContextsTab);
