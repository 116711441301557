import omit from 'lodash/omit';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';

function getFormValue(valuesObject) {
  if (!isNil(valuesObject) && isObject(valuesObject)) {
    const formValueObject = omit(valuesObject, ['paramId', 'taskId', 'actionId']);
    const formValue = formValueObject[Object.keys(formValueObject)[0]];
    if (Array.isArray(formValue)) {
      return formValue.join(', ');
    }

    return formValue;
  }

  return null;
}

export default getFormValue;
