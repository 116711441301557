import styled, { css } from 'styled-components';
import { Field } from 'redux-form';

import filterProps from 'helpers/filterProps';

const FilteredProps = filterProps([
  'editMode',
])(Field);

const FilteredProps2 = filterProps([
  'editMode',
])('label');

export const StyledLabel = styled(FilteredProps2)`
  display: flex !important;
  max-width: ${({ editMode }) => (editMode ? '250px' : 'auto')};  
  align-items: center !important;
  justify-content: space-between !important;
  margin: 0 0 .28571429rem 0;
  font-weight: 700;
`;

export const StyledField = styled(FilteredProps)`
  max-width: ${({ editMode }) => (editMode ? '250px' : 'auto')};

  @media only screen and (max-width: 991px) {
    &.ui.selection.dropdown .menu {
      ${({ editMode }) => editMode && css`
        border: 0px;
        position: relative;
      `}
    }
  }
`;

export default StyledField;
