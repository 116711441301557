import FirmwareV1 from 'components/Firmware/FirmwareV1Container';
import DeviceFetcher from 'layouts/DeviceFetcher/DeviceFetcherContainer';
import PharosHardwareTPSV1 from 'layouts/Pharos/components/PharosHardwareTPSV1/PharosHardwareTPSV1Container';
import PharosFilesV1 from 'layouts/Pharos/components/PharosFilesV1/PharosFilesV1';
import PharosSoftwareV1 from 'layouts/Pharos/components/PharosSoftwareV1/PharosSoftwareV1Container';
import PharosNetworkInterfaceTPSV1
from 'layouts/Pharos/components/PharosNetworkInterfaceTPSV1/PharosNetworkInterfaceTPSV1Container';
import PharosProjectTPSV1 from 'layouts/Pharos/components/PharosProjectTPSV1/PharosProjectTPSV1Container';
import PharosResetDeviceV1 from '../components/PharosResetDeviceV1/PharosResetDeviceV1Container';
import PharosFormatDeviceV1 from '../components/PharosFormatDeviceV1/PharosFormatDeviceV1Container';
import PharosBeaconV1 from '../components/PharosBeaconV1/PharosBeaconV1Container';

export default [
  {
    menuItem: 'Overview',
    path: '/overview',
    fetcher: DeviceFetcher,
    rows: [
      {
        columns: [
          {
            computer: 8,
            mobile: 16,
            sections: [
              {
                title: 'Hardware',
                component: PharosHardwareTPSV1,
              },
              {
                title: 'Project ',
                component: PharosProjectTPSV1,
              },
            ],
          },
          {
            computer: 8,
            mobile: 16,
            sections: [
              {
                title: 'Software',
                component: PharosSoftwareV1,
              },
              {
                title: 'Network Interface',
                component: PharosNetworkInterfaceTPSV1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Files',
    path: '/files',
    fetcher: DeviceFetcher,
    rows: [
      {
        columns: [
          {
            sections: [
              {
                title: 'Files',
                component: PharosFilesV1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Firmware',
    path: '/firmware',
    fetcher: DeviceFetcher,
    rows: [
      {
        columns: [
          {
            sections: [
              {
                title: 'Firmware',
                component: FirmwareV1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Advanced',
    path: '/advanced',
    fetcher: DeviceFetcher,
    rows: [
      {
        columns: [
          {
            computer: 8,
            tablet: 8,
            mobile: 16,
            sections: [
              {
                title: 'Beacon device',
                component: PharosBeaconV1,
              },
              {
                title: 'Reset device',
                component: PharosResetDeviceV1,
              },
            ],
          },
          {
            computer: 8,
            tablet: 8,
            mobile: 16,
            sections: [
              {
                title: 'Format device',
                component: PharosFormatDeviceV1,
              },
            ],
          },
        ],
      },
    ],
  },
];
