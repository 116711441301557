import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import TextDescriptionItem
  from 'components/DescriptionList/TextDescriptionItem/TextDescriptionItem';
import { IoModuleTableLimitWrapper } from './IoModuleTableLimitWrapper.style';
import {
  IoModuleStyledArrowCell,
  IoModuleStyledTableCell,
} from '../IoModuleAccordion/IoModuleAccordionStyles.style';

const IoModulesTable = props => {
  const { moduleArray } = props;
  return (
    <Table.Row>
      <IoModuleStyledArrowCell />
      <IoModuleStyledTableCell colSpan="2">
        <IoModuleTableLimitWrapper>
          {moduleArray.map(item => (
            <TextDescriptionItem
              key={item.id}
              title={item.label}
              value={item.value}
            />
          ))}
        </IoModuleTableLimitWrapper>
      </IoModuleStyledTableCell>
    </Table.Row>
  );
};

IoModulesTable.propTypes = {
  moduleArray: PropTypes.array.isRequired,
};

export default IoModulesTable;
