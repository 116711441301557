import { connect } from 'react-redux';
import isNil from 'lodash/isNil';
import has from 'lodash/has';

import { singleProjectSelector, singleProjectSuiteSelector } from 'helpers/selectors';
import RenderParamValue from './RenderParamValue';

export const mapStateToProps = (state, { param, projectId }) => {
  const { key, value } = param;
  let type;
  let resourceName;

  if (key === 'recipientId') {
    type = key;
    const projectSuite = singleProjectSuiteSelector(state, value);

    if (!isNil(projectSuite) && has(projectSuite, 'name')) {
      const { name } = projectSuite;
      resourceName = name;
    }
  }

  if (key === 'resourceId') {
    type = key;
    const project = singleProjectSelector(state, projectId);
    const { visibleName } = project;
    resourceName = visibleName;
  }

  return {
    param,
    type,
    resourceName,
  };
};

export default connect(mapStateToProps)(RenderParamValue);
