import { compose } from 'redux';
import { connect } from 'react-redux';

import { actionCreators } from './utilities';
import PasswordReset from './PasswordReset';

const mapDispatchToProps = {
  onSendPasswordResetToken: actionCreators.sendPasswordResetToken,
};

export default compose(
  connect(null, mapDispatchToProps),
)(PasswordReset);
