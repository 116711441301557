import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { singleProjectSelector } from 'helpers/selectors';
import siteActionCreators from 'application/tenant/console/site/utilities/actionCreators';
import { actionCreators } from '../../utilities';

import SubscriptionStatus from './SubscriptionStatus';

export const mapStateToProps = (state, { match }) => {
  const { id: siteId } = match.params;

  const {
    renewalDate,
    points,
    subscriptionPlanCode,
    projectedSubscriptionPlanCode,
  } = singleProjectSelector(state, siteId);

  return {
    siteId,
    renewalDate,
    subscriptionPlanCode,
    points,
    projectedSubscriptionPlanCode,
  };
};

const mapDispatchToProps = {
  onUpdateSiteConstruction: siteActionCreators.updateSiteConstruction,
  onUpgradeSitePlan: actionCreators.upgradeSitePlan,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(SubscriptionStatus);
