import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const fetchProjectSuiteProjectsStatus = (projectSuiteId, urlParams = {}) => ({
  type: constants.FETCH_PROJECT_SUITE_PROJECTS_STATUS_REQUEST,
  payload: {
    projectSuiteId,
    endpoint: endpoints.PROJECT_SUITE_PROJECTS(projectSuiteId),
    urlParams,
  },
});

export const fetchProjectSuiteProjectsStatusSuccess = response => ({
  type: constants.FETCH_PROJECT_SUITE_PROJECTS_STATUS_SUCCESS,
  payload: {
    response,
  },
});

export const fetchProjectSuiteProjectsStatusFailure = error => ({
  type: constants.FETCH_PROJECT_SUITE_PROJECTS_STATUS_FAILED,
  payload: { error },
});

export default fetchProjectSuiteProjectsStatus;
