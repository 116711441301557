import React from 'react';
import PropTypes from 'prop-types';

import { labelsType } from 'data/types';
import { FIXTURE_GROUPS_FIELD } from 'helpers/selectors';
import DataTable from 'components/DataTable/DataTableContainer';
import RefreshInterface from 'components/RefreshInterface/RefreshInterfaceContainer';

const FixtureGroupsSection = ({ deviceId, title, labels }) => {
  const refreshUI = (
    <RefreshInterface
      deviceId={deviceId}
      dataId="fixture_groups"
    />
  );

  return (
    <DataTable
      header={title}
      labels={labels}
      createElement={refreshUI}
      pagination={{
        type: FIXTURE_GROUPS_FIELD,
        included: true,
      }}
      deviceId={deviceId}
      highlightUpdates
    />
  );
};

FixtureGroupsSection.defaultProps = {
  title: '',
};

FixtureGroupsSection.propTypes = {
  deviceId: PropTypes.string.isRequired,
  labels: labelsType.isRequired,
  title: PropTypes.string,
};

export default FixtureGroupsSection;
