import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import DropdownArrow from 'components/elements/DropdownArrow/DropdownArrow';

const RemoteDeviceRadio = ({ open }) => (
  <Table.Cell>
    <DropdownArrow open={open} />
  </Table.Cell>
);

RemoteDeviceRadio.defaultProps = {
  open: false,
};

RemoteDeviceRadio.propTypes = {
  open: PropTypes.bool,
};

export default RemoteDeviceRadio;
