import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import formNames from 'components/form/formNames';

import VoucherForm from './VoucherForm';

const VoucherFormContainer = reduxForm({
  form: formNames.voucherGeneration,
  enableReinitialize: true,
})(VoucherForm);

export const mapStateToProps = () => ({
  initialValues: {
    subscriptionPlanCode: '',
    subscriptionLength: '',
    description: '',
  },
});

export default connect(mapStateToProps)(VoucherFormContainer);
