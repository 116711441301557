import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  COMPANIES_FIELD,
  companySelector,
  deviceSelector,
  LOGICAL_DEVICES_FIELD,
  PROJECTS_FIELD,
  projectSelector,
  PROJECT_SUITES_FIELD,
  projectSuiteSelector,
} from 'helpers/selectors';
import ResourceBreadcrumb from './ResourceBreadcrumb';

const selectorsMap = {
  [COMPANIES_FIELD]: companySelector,
  [LOGICAL_DEVICES_FIELD]: deviceSelector,
  [PROJECTS_FIELD]: projectSelector,
  [PROJECT_SUITES_FIELD]: projectSuiteSelector,
};

export const mapStateToProps = (state, { match: { url }, type }) => {
  const id = url.split('/').pop();

  return {
    id,
    resource: selectorsMap[type](state)(id),
  };
};

const ResourceBreadcrumbContainer = compose(
  connect(mapStateToProps),
)(ResourceBreadcrumb);

ResourceBreadcrumbContainer.defaultProps = {
  match: {},
};

ResourceBreadcrumbContainer.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
  type: PropTypes.oneOf([
    COMPANIES_FIELD,
    LOGICAL_DEVICES_FIELD,
    PROJECTS_FIELD,
    PROJECT_SUITES_FIELD,
  ]).isRequired,
};

export default ResourceBreadcrumbContainer;
