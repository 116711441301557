import { connect } from 'react-redux';

import deviceActionCreators from 'pages/Project/deviceActionCreators';
import InlineEditDevice from './InlineEditDevice';

const mapDispatchToProps = {
  onEditDeviceName: deviceActionCreators.renameDevice,
};

export default connect(null, mapDispatchToProps)(InlineEditDevice);
