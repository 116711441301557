import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Label, Icon } from 'semantic-ui-react';
import { DateTime } from 'luxon';

import { formatDate } from 'components/core/DatePicker/DatePicker';

import { StyledList, StyledListItem } from '../../SelectedDatesView.style';

function DateList({
  dates,
  onRemoveDate,
  clearable = true,
}) {

  const orderedDates = useMemo(() => {
    if (!dates || isEmpty(dates)) {
      return [];
    }

    return dates.sort().map(date => formatDate(date));
  }, [dates]);

  const handleRemoveDate = useCallback(date => {
    const formattedDate = DateTime.fromFormat(date, 'DDD').toFormat('yyyy-MM-dd');

    onRemoveDate(formattedDate);
  }, [onRemoveDate]);

  const renderRemoveIcon = useCallback(date => {
    if (!clearable) {
      return null;
    }

    return <Icon name="delete" onClick={() => handleRemoveDate(date)} />;
  }, [clearable, handleRemoveDate]);

  if (isEmpty(orderedDates)) {
    return null;
  }

  return (
    <StyledList className="normal-dates-list">
      {orderedDates.map(date => (
        <StyledListItem key={date}>
          <Label htmlFor="text" basic>
            <Icon name="calendar alternate outline" /> {date} {renderRemoveIcon(date)}
          </Label>
        </StyledListItem>
      ))}
    </StyledList>
  );
}

DateList.defaultProps = {
  clearable: true,
  onRemoveDate: null,
};

DateList.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.string).isRequired,
  onRemoveDate: PropTypes.func,
  clearable: PropTypes.bool,
};

export default DateList;
