import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  deviceErrorSelector,
  deviceFetchingSelector,
  LOGICAL_DEVICES_FIELD,
} from 'helpers/selectors';
import chainedLoader from 'helpers/chainedLoader/chainedLoaderContainerWithPagination';
import withPolling from 'helpers/withPolling';
import deviceActionCreators from 'pages/Project/deviceActionCreators';
import Null from 'components/Null/Null';

const mapStateToProps = (state, ownProps) => {
  const { params: { deviceId } } = ownProps.match;

  return {
    deviceId,
    chainedLoaderProps: {
      device: {
        fetching: deviceFetchingSelector(state),
        error: deviceErrorSelector(state),
      },
    },
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withPolling(),
  chainedLoader(
    { name: 'device' },
    ({ deviceId }) => deviceActionCreators.fetchDevice(deviceId, {
      fields: {
        [LOGICAL_DEVICES_FIELD]: [
          'reported',
          'reportedMeta',
        ],
      },
    }),
  ),
)(Null);
