import React from 'react';
import PropTypes from 'prop-types';

import BreadcrumbNavItemWrapper from './BreadcrumbNavItem.style';

const BreadcrumbNavItem = ({ children, innerRef, ...rest }) => (
  <BreadcrumbNavItemWrapper {...rest} innerRef={innerRef}>
    {children}
  </BreadcrumbNavItemWrapper>
);

BreadcrumbNavItem.defaultProps = {
  children: null,
  text: '',
  innerRef: null,
};

BreadcrumbNavItem.propTypes = {
  children: PropTypes.element,
  text: PropTypes.string,
  innerRef: PropTypes.object,
};

export default BreadcrumbNavItem;
