import styled from 'styled-components';

import { injectStyles, media } from 'themes/mixins';
import sidebarConst from 'themes/variables';
import colors from 'themes/colors';
import { Button } from 'components/atomic';

const Container = styled.div`
  border: 1px solid ${colors.white};
  background: ${colors.white};

  ${({ theme: { updateNotificationPanel } }) => injectStyles(updateNotificationPanel)};

  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  display: block;
  padding: .6rem 0;
  box-shadow: 0 1px 2px 0 ${colors.boxShadow};
  opacity: 0.8;
  transition: margin 500ms ease, width 500ms ease;
  ${({ padded }) => padded && media.md`padding-left: ${sidebarConst.width.md};`};
`;

export const StyledButton = styled(Button)`
  background: ${colors.white} !important;
  margin-left: 15px !important;
`;

export default Container;
