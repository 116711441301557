import React from 'react';

const AboveNormal = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="7"
    fill="none"
    viewBox="0 0 14 7"
  >
    <path
      stroke="#F8592F"
      strokeLinecap="round"
      strokeWidth="2"
      d="M1 5.913L7 2l6 3.913"
    />
  </svg>
);

export default AboveNormal;
