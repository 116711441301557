import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import formNames from 'components/form/formNames';
import { allActionsTypeSelector } from 'helpers/selectors';

import { getInitialValuesForSelectedActions } from './utilities';
import SelectedActions from './SelectedActions';

const SelectedActionsContainer = reduxForm({
  form: formNames.selectedActions,
  enableReinitialize: true,
  validate: () => {},
})(SelectedActions);

export const mapStateToProps = (state, { selectedActions, onActionRemove }) => {
  const { syncErrors, values: existingValues } = state.form.selectedActions || {};

  const allActionTypes = allActionsTypeSelector(state);

  return {
    actionErrors: syncErrors,
    onActionRemove,
    actionTypes: allActionTypes,
    initialValues: {
      ...getInitialValuesForSelectedActions(selectedActions, allActionTypes, existingValues),
    },
  };
};

export default connect(mapStateToProps, null)(SelectedActionsContainer);
