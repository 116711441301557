import ProjectSuiteProjectsTab from './ProjectSuiteProjectsTab/ProjectSuiteProjectsTabContainer';
import ProjectSuiteUsersTab from './ProjectSuiteUsersTab/ProjectSuiteUsersTabContainer';
import ProjectSuitePermissionsTabNew from './ProjectSuitePermissionsTabNew/ProjectSuitePermissionsTabContainer';
import ProjectSuiteSettingsTab from './ProjectSuiteSettingsTab/ProjectSuiteSettingsTabContainer';
import ProjectSuiteTasksTab from './ProjectSuiteTasksTab/ProjectSuiteTasksTabContainer';
import ProjectSuiteSchedulingTab from './ProjectSuiteSchedulingTab/ProjectSuiteSchedulingTabContainer';
import ProjectSuiteControlPanelTab from './ProjectSuiteControlPanelTab/ProjectSuiteControlPanelTabContainer';

export default [
  {
    menuItem: 'Sites',
    path: '/sites',
    rows: [
      {
        columns: [
          {
            computer: 16,
            mobile: 16,
            sections: [
              {
                component: ProjectSuiteProjectsTab,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Users',
    path: '/users',
    rows: [
      {
        columns: [
          {
            computer: 16,
            mobile: 16,
            sections: [
              {
                component: ProjectSuiteUsersTab,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Permissions',
    path: '/permissions',
    rows: [
      {
        columns: [
          {
            computer: 16,
            mobile: 16,
            sections: [
              {
                component: ProjectSuitePermissionsTabNew,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Settings',
    path: '/settings',
    rows: [
      {
        columns: [
          {
            computer: 16,
            mobile: 16,
            sections: [
              {
                component: ProjectSuiteSettingsTab,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Tasks',
    path: '/tasks',
    rows: [
      {
        columns: [
          {
            computer: 16,
            mobile: 16,
            sections: [
              {
                component: ProjectSuiteTasksTab,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Scheduling',
    path: '/scheduling',
    rows: [
      {
        columns: [
          {
            computer: 16,
            mobile: 16,
            sections: [
              {
                component: ProjectSuiteSchedulingTab,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Control Panel',
    path: '/control_panel_config',
    rows: [
      {
        columns: [
          {
            computer: 16,
            mobile: 16,
            sections: [
              {
                component: ProjectSuiteControlPanelTab,
              },
            ],
          },
        ],
      },
    ],
  },
];
