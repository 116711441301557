import React from 'react';
import PropTypes from 'prop-types';

import { RenderTimesList } from './components';

const TimeMaskText = ({
  advancedMode,
  showTimes,
  timesList,
  selectedTimes,
}) => advancedMode && showTimes && (
  <RenderTimesList
    maskTimes={timesList}
    selectedTimes={selectedTimes}
  />
);

TimeMaskText.defaultProps = {
  advancedMode: false,
  showTimes: false,
  timesList: [],
  selectedTimes: '',
};

TimeMaskText.propTypes = {
  advancedMode: PropTypes.bool,
  showTimes: PropTypes.bool,
  timesList: PropTypes.array,
  selectedTimes: PropTypes.string,
};

export default TimeMaskText;
