import React from 'react';
import { Input } from 'semantic-ui-react';

const InputField = ({
  meta,
  label,
  omitError,
  formError,
  editMode,
  hideErrorMessage,
  highlightFieldOnError,
  ...rest
}) => (
  <Input id={rest.input.name} className="icon" {...rest} />
);

InputField.propTypes = {
  ...Input.propTypes,
};

export default InputField;
