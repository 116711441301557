import styled from 'styled-components';
import { Select } from 'semantic-ui-react';

import { media, mediaMax } from 'themes/mixins';

export const ResetButtonWrapper = styled.div`
  margin-bottom: 1em;
  
  ${media.md`
    position: fixed;
    bottom: 4em;
    right: 2.8571em;
  `};
  
  .button {
    ${mediaMax.md`width: 100%;`};
  }
`;

export const StyledSelect = styled(Select)`
  width: 100%;
`;
