import moment from 'moment';

import { getOccurrenceMonthText, getOccurrenceTypeText, getOccurrenceDayText } from '.';

const getReadableYearlyCondition = condition => {
  if (!condition) {
    return '';
  }

  const { every, on } = condition;

  if (every) {
    const { day, month } = every;

    let occurrenceTypeText = '';
    const occurrenceMonthText = getOccurrenceMonthText(month);

    if (day > 5) {
      occurrenceTypeText = `${moment.localeData().ordinal(day)}`;
    } else {
      occurrenceTypeText = getOccurrenceTypeText(day);
    }

    return `${occurrenceTypeText} day of ${occurrenceMonthText}`;
  }

  if (on) {
    const { nthOccurrence, weekday, month } = on;

    const dayText = getOccurrenceDayText(weekday);
    const typeText = getOccurrenceTypeText(nthOccurrence);
    const monthText = getOccurrenceMonthText(month);

    return `${typeText} ${dayText} of ${monthText}`;
  }

  return '';
};

export default getReadableYearlyCondition;
