import styled from 'styled-components';
import { Segment } from 'semantic-ui-react';

const StyledSegment = styled(Segment)`
  &.ui.segment {
    margin-top: 0;
  }
`;

export default StyledSegment;
