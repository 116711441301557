import styled from 'styled-components';

import ActionCell from '../ActionCell/ActionCell.style';
import ActionRow from '../ActionRow/ActionRow.style';

export const HoverRow = styled(ActionRow)`
  cursor: pointer;
`;

export const InnerCell = styled(ActionCell)`
  padding-bottom: 0.1em !important;
  padding-top: 0px !important;
`;
