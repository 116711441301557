import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from 'semantic-ui-react';

import TenantBackground from 'components/TenantBackground/TenantBackground.style';

import { VoucherRedemption, LoginForm, PasswordReset } from './components';
import { LoginContainer, StyledLink } from './Login.style';

const Login = ({ onLogin, vouchersEnabled }) => {
  const [isVoucherRedemption, setIsVoucherRedemption] = useState(false);

  const handleLogin = useCallback(async values => new Promise(
    resolveForm => onLogin({ values, resolveForm }),
  ), [onLogin]);

  const renderContent = useCallback(() => {
    if (!isVoucherRedemption) {
      return (
        <LoginContainer>
          <LoginForm onSubmit={handleLogin} />
          <PasswordReset />
          {vouchersEnabled && (
            <StyledLink
              name="voucher-redemption-link"
              onClick={() => setIsVoucherRedemption(true)}
              className="loginLinkStyle"
            >
              Redeem Voucher for a new Site
            </StyledLink>
          )}
        </LoginContainer>
      );
    }

    return (
      <VoucherRedemption onClose={() => setIsVoucherRedemption(false)} />
    );
  }, [isVoucherRedemption, vouchersEnabled, handleLogin]);

  return (
    <Container>
      <TenantBackground />
      <Grid>
        <Grid.Column mobile={16} computer={8} floated="right">
          {renderContent()}
        </Grid.Column>
      </Grid>
    </Container>
  );
};

Login.defaultProps = {
  vouchersEnabled: false,
};

Login.propTypes = {
  onLogin: PropTypes.func.isRequired,
  vouchersEnabled: PropTypes.bool,
};

export default Login;
