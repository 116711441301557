import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  taskSelector,
  singleProjectSelector,
  singleProjectSuiteSelector,
} from 'helpers/selectors';
import formNames from 'components/form/formNames';
import { validateOnSubmit } from 'components/form/formConfigs';
import AddActionModalForm from './AddActionModalForm';

export const mapStateToProps = (state, { taskId, match, superSites }) => {
  const { id: resourceId } = match.params;
  const { name: taskName } = taskSelector(state)(taskId);
  const selector = superSites ? singleProjectSuiteSelector : singleProjectSelector;
  const { name: projectName } = selector(state, resourceId);

  return {
    taskName,
    projectName,
    initialValues: {
      enabled: false,
      testConditions: false,
      enableRed: true,
      enableGreen: true,
      enableBlue: true,
      enableTemperature: true,
      enableIntensity: true,
    },
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  reduxForm({
    form: formNames.addNewAction,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    ...validateOnSubmit,
  }),
)(AddActionModalForm);
