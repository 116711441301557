import styled from 'styled-components';
import { Select } from 'semantic-ui-react';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLabel = styled.span`
  flex: 1;
  flex-basis: 20%;
`;

export const StyledSelect = styled(Select)`
  flex: 1;
  flex-basis: 80%;
`;
