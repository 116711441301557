import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';

import { complexValueType } from 'data/types';
import TextDescriptionItem
  from 'components/DescriptionList/TextDescriptionItem/FormattedTextDescriptionItem';
import BoolDescriptionItem
  from 'components/DescriptionList/BoolDescriptionItem/BoolDescriptionItem';

const PathwayDevicePropertiesV1 = ({
  title,
  loading,
  usage,
  vlan,
}) => !loading && (
  <Segment>
    <Header as="h2">{title}</Header>
    <TextDescriptionItem
      title="Total PoE Usage"
      value={usage}
      format={({ value }) => `${value} mw`}
    />
    <BoolDescriptionItem
      title="VLAN Enabled"
      value={vlan}
    />
  </Segment>
);

PathwayDevicePropertiesV1.defaultProps = {
  loading: true,
  usage: {},
  vlan: {},
};

PathwayDevicePropertiesV1.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  usage: complexValueType(PropTypes.number),
  vlan: complexValueType(PropTypes.bool),
};

export default PathwayDevicePropertiesV1;
