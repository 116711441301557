import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import DropdownArrow from 'components/elements/DropdownArrow/DropdownArrow';
import {
  IoModuleStyledTableRow,
  IoModuleStyledTableCell,
  IoModuleStyledArrowCell,
} from './IoModuleAccordionStyles.style';
import IoModulesTable from '../IoModulesTable/IoModulesTable';

const IoModuleAccordionRow = ({ labels, item }) => {
  const [open, setOpen] = useState(false);
  const { instances, id } = item;

  const handleChange = () => {
    setOpen(!open);
  };

  return (
    <Fragment>
      <IoModuleStyledTableRow
        onClick={handleChange}
      >
        <IoModuleStyledArrowCell>
          <DropdownArrow open={open} />
        </IoModuleStyledArrowCell>
        {labels.map((label, index) => {
          const uniqueKey = `${id}-${index}`;
          const { field } = label;

          return (
            <IoModuleStyledTableCell key={uniqueKey}>
              {item[field]}
            </IoModuleStyledTableCell>
          );
        })}
      </IoModuleStyledTableRow>
      {open && <IoModulesTable moduleArray={instances} />}
    </Fragment>
  );
};

IoModuleAccordionRow.propTypes = {
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    moduleTitle: PropTypes.string.isRequired,
    instances: PropTypes.array.isRequired,
  }).isRequired,
};

export default IoModuleAccordionRow;
