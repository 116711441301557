import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import { PageHeader } from 'components/core';
import { PermissionsSwitch, Gateway, frontendPermissions } from 'gateway';
import { Unauthorized } from 'application/components';
import {
  VoucherTenantSelect,
  VoucherGeneration,
  VoucherValidation,
} from 'application/tenant/console/site/components/structural/SiteSubscriptionTab/components/ManageSubscription/components';

function Vouchers({ isSuperAdmin, canManageVouchers }) {
  const isAllowed = useCallback(permissionChecker => {
    if (isSuperAdmin) {
      return true;
    }

    const isTenantAdmin = permissionChecker(frontendPermissions.TENANT_SA);

    if (isTenantAdmin) {
      return true;
    }

    if (canManageVouchers) {
      return true;
    }

    return false;
  }, [isSuperAdmin, canManageVouchers]);

  return (
    <>
      <PermissionsSwitch>
        <Gateway condition={isAllowed}>
          <>
            <PageHeader title="Vouchers" />
            <Grid>
              <Grid.Row>
                <Grid.Column computer={16} mobile={16}>
                  <VoucherTenantSelect />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column computer={8} mobile={16}>
                  <VoucherGeneration />
                </Grid.Column>
                <Grid.Column computer={8} mobile={16}>
                  <VoucherValidation />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
        </Gateway>
        <Gateway fallback>
          <Unauthorized
            message="You do not have the required permissions to view this page."
            secondMessage=""
          />
        </Gateway>
      </PermissionsSwitch>
    </>
  );
}

export default Vouchers;

Vouchers.defaultProps = {
  isSuperAdmin: false,
  canManageVouchers: false,
};

Vouchers.propTypes = {
  isSuperAdmin: PropTypes.bool,
  canManageVouchers: PropTypes.bool,
};
