import React from 'react';
import PropTypes from 'prop-types';

import Table from 'components/DataTable/Table.style';

import NotificationTypeRows from '../NotificationTypeRows/NotificationTypeRowsContainer';

const NotificationGroup = ({
  notificationTypes,
  config,
  userId,
  notificationsEnabled,
}) => (
  <Table>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Context</Table.HeaderCell>
        <Table.HeaderCell>Name</Table.HeaderCell>
        {!notificationsEnabled && (
          <Table.HeaderCell>Email</Table.HeaderCell>
        )}
        {notificationsEnabled && (
          <>
            <Table.HeaderCell>Instant</Table.HeaderCell>
            <Table.HeaderCell>Daily Digest</Table.HeaderCell>
            <Table.HeaderCell>Weekly Digest</Table.HeaderCell>
          </>
        )}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {notificationTypes.map(notificationType => (
        <NotificationTypeRows
          notificationType={notificationType}
          config={config}
          userId={userId}
          key={notificationType.id}
          notificationsEnabled={notificationsEnabled}
        />
      ))}
    </Table.Body>
  </Table>
);

NotificationGroup.defaultProps = {
  notificationsEnabled: false,
};

NotificationGroup.propTypes = {
  notificationTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    notificationContext: PropTypes.string.isRequired,
  })).isRequired,
  config: PropTypes.shape({
    context: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectors: PropTypes.objectOf(PropTypes.func).isRequired,
    filter: PropTypes.objectOf(PropTypes.string).isRequired,
    fetchResources: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.symbol.isRequired,
      payload: PropTypes.object,
    })).isRequired,
    fetchResourcesSuccess: PropTypes.arrayOf(PropTypes.func).isRequired,
  }).isRequired,
  userId: PropTypes.string.isRequired,
  notificationsEnabled: PropTypes.bool,
};

export default NotificationGroup;
