import { compose } from 'redux';
import { connect } from 'react-redux';

import { permissionsOrRedirect, frontendPermissions } from 'gateway';

import { actionCreators } from '../../utilities';
import AddProjectSuite from './AddProjectSuite';

export const mapDispatchToProps = {
  onAddProjectSuite: actionCreators.addProjectSuite,
};

export default compose(
  connect(null, mapDispatchToProps),
  permissionsOrRedirect(frontendPermissions.PROJECT_SUITE_ADD),
)(AddProjectSuite);
