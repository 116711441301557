const setCumulatedPermissionsAllowedState = ({
  cumulatedPermissions,
  permissionNames,
  recipientId,
  allowedState,
}) => permissionNames.reduce(
  (acc, permissionName) => {
    acc[permissionName] = {
      ...acc[permissionName],
      [recipientId]: allowedState,
    };

    return acc;
  }, cumulatedPermissions);

export default setCumulatedPermissionsAllowedState;
