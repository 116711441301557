import React, { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';

import { supportedTypesType, uploadFileType } from 'data/types';
import { isMac } from 'themes/mixins';
import { Button } from 'components/atomic';
import NewUploadModalForm from './NewUploadModalForm/NewUploadModalFormContainer';

class NewUpload extends Component {

  constructor() {
    super();

    this.handleNewUpload = this.handleNewUpload.bind(this);
  }

  getAcceptedExtensions() {
    const { accept } = this.props;

    if (!accept) {
      return null;
    }

    if (isString(accept)) {
      return accept;
    }

    const isDesktopMac = isMac();

    const fileTypesSet = Object.values(accept).reduce((acc, value) => {
      const { ex: extensionArray } = value;

      extensionArray.forEach(extension => {
        if (isDesktopMac) {
          const lastExtension = extension.split('.').pop();
          acc.add(`.${lastExtension}`);

          return;
        }

        acc.add(`.${extension}`);
      });

      return acc;
    }, new Set());

    return [...fileTypesSet].join(', ');
  }

  getFileTypesMap() {
    const { accept } = this.props;

    if (!accept || isString(accept)) {
      return null;
    }

    return Object.keys(accept).reduce((acc, key) => {
      const { ex: extensionArray, nm } = accept[key];

      extensionArray.forEach(extension => {
        const fileTypes = acc[extension] || [];

        acc[extension] = [
          ...fileTypes,
          { key, text: nm, value: key },
        ];
      });

      return acc;
    }, {});
  }

  handleNewUpload(values) {
    const {
      deviceId,
      resume,
      onPrepareUpload,
      onFileNotFoundResume,
      onClearCompleted,
    } = this.props;

    onClearCompleted();

    return new Promise((resolveForm, rejectForm) => {
      const { accessKeyId, fileKey, fileTypeId } = resume || {};
      const params = {
        values: { ...values, fileTypeId },
        resolveForm,
        rejectForm,
      };

      if (accessKeyId) {
        onFileNotFoundResume({ fileKey, params });
      } else {
        onPrepareUpload({ deviceId, params });
      }
    });
  }

  renderTrigger() {
    const { trigger, disabled } = this.props;

    if (trigger) {
      return cloneElement(trigger, { disabled });
    }

    return (
      <Button primary fluid disabled={disabled}>Upload files</Button>
    );
  }

  render() {
    const { resume } = this.props;

    const accept = this.getAcceptedExtensions();
    const fileTypes = this.getFileTypesMap();

    return (
      <NewUploadModalForm
        onSubmit={this.handleNewUpload}
        trigger={this.renderTrigger()}
        accept={accept}
        resume={resume}
        fileTypes={fileTypes}
      />
    );
  }

}

NewUpload.defaultProps = {
  accept: null,
  resume: null,
  trigger: null,
  deviceId: null,
  disabled: false,
};

NewUpload.propTypes = {
  accept: PropTypes.oneOfType([
    supportedTypesType,
    PropTypes.string,
  ]),
  deviceId: PropTypes.string,
  disabled: PropTypes.bool,
  resume: uploadFileType,
  trigger: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  onFileNotFoundResume: PropTypes.func.isRequired,
  onPrepareUpload: PropTypes.func.isRequired,
  onClearCompleted: PropTypes.func.isRequired,
};

export default NewUpload;
