import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import { ControlUIProjectSuite } from 'application/tenant/components';

function ProjectSuiteControlPanelTab({ projectsIds }) {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          {projectsIds && <ControlUIProjectSuite configure projectsIds={projectsIds} />}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

ProjectSuiteControlPanelTab.defaultProps = {
  projectsIds: null,
};

ProjectSuiteControlPanelTab.propTypes = {
  projectsIds: PropTypes.array,
};

export default ProjectSuiteControlPanelTab;
