import { call, select, put } from 'redux-saga/effects';

import { postPath } from 'data/api/requests';
import { processFinalFormResponse } from 'utilities/form';
import { selectors, actionCreators as applicationUserActionCreators } from '../../user';
import actionCreators from '../actionCreators';

function* submitOneTimePassword(action) {
  const {
    values,
    resolveForm,
    endpoint,
  } = action.payload;
  const twoFactorSecretKey = yield select(selectors.twoFactorSecretKeySelector);
  const bodyWithSecretKey = (
    twoFactorSecretKey
      ? {
        ...values,
        secret_key: twoFactorSecretKey,
      }
      : values
  );

  const { response, error } = yield call(postPath, endpoint, bodyWithSecretKey);

  const params = {
    response,
    error,
    successDisp: actionCreators.submitOneTimePasswordSuccess,
    failDisp: actionCreators.submitOneTimePasswordFailure,
    resolveForm,
  };
  yield call(processFinalFormResponse, params);

  if (response) {
    yield put(applicationUserActionCreators.fetchCurrentUser());
  }
}

export default submitOneTimePassword;
