import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import redirectIfNotFound from 'helpers/redirectIfNotFound';
import { permissionsOrNotFound, frontendPermissions } from 'gateway';
import {
  currentUserIdSelector,
  paginatedUsersSelector,
  tenantChangedSelector,
  USERS_FIELD,
} from 'helpers/selectors';
import { addPagination } from 'helpers/paginationHelpers';
import { actionCreators } from '../../utilities/users';
import Users from './Users';

const mapStateToProps = state => ({
  users: paginatedUsersSelector(state),
  currentUserId: currentUserIdSelector(state),
  tenantNotChanged: !tenantChangedSelector(state),
});

export default compose(
  withRouter,
  connect(mapStateToProps),
  redirectIfNotFound('tenantNotChanged'),
  permissionsOrNotFound(frontendPermissions.TENANT_MANAGER_USERS),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: USERS_FIELD },
      actionCreators.fetchUsers({
        fields: {
          users: [
            'firstName',
            'lastName',
            'email',
            'lastActionTime',
            'lastLoginTime',
            'superadmin',
            'suspended',
          ],
        },
        sort: 'firstName',
        filter: 'name',
      }),
    ),
  ),
)(Users);
