import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Header } from 'semantic-ui-react';
import { DateTime } from 'luxon';

const RenewalDate = ({ renewalDate, format }) => {
  const renderDate = DateTime.fromISO(renewalDate).toUTC().toFormat(format);

  return (
    <Fragment>
      <Grid.Column width={8}>
        <Header as="h5">Renewal date</Header>
      </Grid.Column>
      <Grid.Column width={8}>
        {renderDate}
      </Grid.Column>
    </Fragment>
  );
};

RenewalDate.defaultProps = {
  renewalDate: undefined,
  format: 'dd/MM/y',
};

RenewalDate.propTypes = {
  renewalDate: PropTypes.string,
  format: PropTypes.string,
};

export default RenewalDate;
