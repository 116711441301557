import constants from 'dispatcherConst';

export const setSchedulerType = schedulerType => ({
  type: constants.SET_SCHEDULER_TYPE,
  payload: {
    schedulerType,
  },
});

export default setSchedulerType;
