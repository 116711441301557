import { createSelector } from 'reselect';

import notificationEventsForDropdownSelector from './notificationEventsForDropdownSelector';

const recentNotificationEventsSelector = createSelector(
  notificationEventsForDropdownSelector,
  notificationEvents => {
    const unarchivedItems = notificationEvents.filter(notification => !notification.archivedAt);

    return unarchivedItems;
  },
);

export default recentNotificationEventsSelector;
