import { call } from 'redux-saga/effects';

import { LOGICAL_DEVICES_FIELD } from 'helpers/selectors';
import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';

import actionCreators from '../actionCreators';

function* fetchNotificationEventsForDropdown(action) {
  const { endpoint, urlParams } = action.payload;
  const { response, error } = yield call(getPath, endpoint, {
    urlParams: {
      include: [
        'notifiable',
      ],
      fields: {
        [LOGICAL_DEVICES_FIELD]: [
          'id',
          'name',
        ],
      },
      'filter[archived]': false,
      sort: '-occurredAt',
      ...urlParams,
    },
  });

  const params = {
    response,
    error,
    successDisp: actionCreators.fetchNotificationEventsForDropdownSuccess,
    failDisp: actionCreators.fetchNotificationEventsForDropdownFailure,
  };

  yield call(processCall, params);
}

export default fetchNotificationEventsForDropdown;
