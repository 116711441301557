import { put, select, takeEvery, race, take, delay } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import withValueOverrideActionCreators from './withValueOverrideActionCreators';

export function* handleValueOverride({ payload }) {
  const { id, value: overrideValue, valueSelector, timeout } = payload;
  let success = false;

  for (let i = 0; i < timeout; i += 1) {
    const { reset } = yield race({
      delay: delay(1000),
      reset: take(constants.RESET_VALUE_OVERRIDE),
    });

    if (reset) {
      success = true;
      break;
    }

    const realValue = yield select(valueSelector);

    if (realValue === overrideValue) {
      yield put(withValueOverrideActionCreators.overrideSuccess(id));
      success = true;
      break;
    }
  }

  if (!success) {
    yield put(withValueOverrideActionCreators.overrideFailed(id));
  }
}

export function* withValueOverrideSaga() {
  yield takeEvery(constants.SET_VALUE_OVERRIDE, handleValueOverride);
}

export default withValueOverrideSaga;
