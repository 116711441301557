import constants from 'dispatcherConst';

const chainedLoaderActionCreators = {
  startChain: (id, methods) => ({
    type: constants.START_CHAIN,
    payload: { id, methods },
  }),

  stopChain: id => ({
    type: constants.STOP_CHAIN,
    payload: { id },
  }),

  nextInChain: id => ({
    type: constants.NEXT_IN_CHAIN,
    payload: { id },
  }),

  triggerChain: id => ({
    type: constants.TRIGGER_CHAIN,
    payload: { id },
  }),

  chainCompleted: id => ({
    type: constants.COMPLETED_CHAIN,
    payload: { id },
  }),

  chainInterrupted: id => ({
    type: constants.INTERRUPTED_CHAIN,
    payload: { id },
  }),

  terminateChain: id => ({
    type: constants.TERMINATE_CHAIN,
    payload: { id },
  }),
};

export default chainedLoaderActionCreators;
