import styled, { css } from 'styled-components';
import { Table } from 'semantic-ui-react';

import filterProps from 'helpers/filterProps';

const FilteredProps = filterProps([
  'missing',
])(Table.Cell);

export const StyledTable = styled(Table)`
  &.ui.table {
    min-width: 230px; 
    border-radius: 0px;
  }

  &.ui.table td {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  &.ui.table td {
    &:first-of-type {
      width: 10% !important;
      padding-left: 4px !important;
    }
  }

  &.ui.table th:first-child {
    &:first-of-type {
      padding-left: 4px !important;
    }
  }

  @media only screen and (max-width: 991px) {
    &.ui.table td {
      min-height: auto !important;
    }

    &.ui.table td:not(:nth-of-type(1)) {
      padding-left: 0% !important;
    }
  }

  @media only screen and (max-width: 768px) {
    &.ui.table:not(.unstackable) tr {
      padding-top: 0em;
      padding-bottom: 0em;
    }
  }

`;

export const StyledCell = styled(FilteredProps)`
  ${({ missing }) => missing && css`
    color: #ff0000;
  `}
  border-bottom: 1px !important;
`;

export default StyledTable;
