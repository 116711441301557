import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getDisplayName } from 'helpers/sharedMethods';
import { isPresent } from 'pages/Device/DefinitionList/DefinitionListItem/DefinitionListItemSingle/DefinitionListItemSingle';

const withAutoIncrement = WrappedComponent => {
  class WithAutoIncrement extends Component {

    static displayName = `WithAutoIncrement(${getDisplayName(WrappedComponent)})`;

    static defaultProps = {
      value: null,
    };

    static propTypes = {
      value: PropTypes.number,
    };

    constructor() {
      super();

      this.state = {
        savedValue: null,
        increment: 0,
      };
    }

    componentDidMount() {
      const { isOnline } = this.props;

      if (isOnline) {
        this.startAutoIncrement();
      }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      const { savedValue } = prevState;
      const { value } = nextProps;

      if (value === savedValue) return null;

      return {
        savedValue: value,
        increment: 0,
      };
    }

    componentDidUpdate(prevProps) {
      const { isOnline } = this.props;
      const { isOnline: prevIsOnline } = prevProps;

      if (!prevIsOnline && isOnline) {
        this.startAutoIncrement();
        return;
      }

      if (prevIsOnline && !isOnline) {
        this.stopAutoIncrement();
      }
    }

    componentWillUnmount() {
      this.stopAutoIncrement();
    }

    startAutoIncrement() {
      this.interval = setInterval(
        () => this.setState(prevState => ({ increment: prevState.increment + 1 })),
        1000,
      );
    }

    stopAutoIncrement() {
      clearInterval(this.interval);
    }

    render() {
      const { increment } = this.state;
      const { value } = this.props;
      const actualValue = isPresent(value) ? value + increment : value;

      return <WrappedComponent {...this.props} value={actualValue} />;
    }

  }

  return WithAutoIncrement;
};

export default withAutoIncrement;
