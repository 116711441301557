import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';

const getSiteStateMessage = siteState => {
  if (isNull(siteState)) {
    return null;
  }

  const reasons = {
    construction: 'The Site is in construction mode.',
    suspended: 'The Site is on standby.',
  };
  const message = 'Device features are limited in this mode. Please contact the Site Owner for more information.';
  const reason = reasons[siteState];

  if (isUndefined(reason)) {
    return null;
  }

  return `${reasons[siteState]} ${message}`;
};

export default getSiteStateMessage;
