import { darken, saturate } from 'polished';

import nanoltgLogo from 'assets/logos/nanoltg/navbar-logo.png';
import nanoltgLogoSignInBackground from 'assets/backgrounds/nanoltg/sign-in-background.png';
import { breadcrumbs as commonBreadcrumbs } from 'navigation';
import colors from '../colors';

export default {
  palette: {
    primaryColor: colors.nanoltgGrey,
  },
  logo: {
    src: nanoltgLogo,
    alt: 'nanoltg logo',
  },
  favicons: [
    {
      href: '/favicons/nanoltg/favicon.ico',
      sizes: '16x16',
    },
  ],
  breadcrumbs: commonBreadcrumbs,
  signInBackground: {
    background: `url(${nanoltgLogoSignInBackground})`,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    'background-size': '25vw',
    'background-position': '35px 80vh',
    'background-repeat': 'no-repeat',
  },
  updateNotificationPanel: {
    background: colors.nanoltgGrey,
    color: colors.white,
    'border-color': colors.nanoltgGrey,
  },
  sidebar: {
    '.ui.menu': {
      '.item': {
        color: colors.grey,
      },
    },
    '.ui.menu.vertical': {
      '.active.item': {
        color: colors.nanoltgGrey,
      },
      md: {
        background: colors.concrete,
      },
    },
  },
  breadcrumbNav: {
    '.ui.breadcrumb a': {
      color: colors.nanoltgGrey,
    },
  },
  notifications: {
    '.ui.message': {
      'box-shadow': 'none',
      '&.success': {
        'box-shadow': `0 0 0 2px ${saturate(0.20, darken(0.08, colors.green))} inset`,
        'background-color': colors.white,
        color: saturate(0.20, darken(0.08, colors.green)),
        '& .header': {
          color: saturate(0.20, darken(0.08, colors.green)),
        },
      },
    },
  },
  buttons: {
    '.ui.button': {
      'border-radius': '1.25rem',
      '&.primary': {
        'background-color': colors.nanoltgGrey,
        color: colors.white,
        '&:hover': {
          'background-color': saturate(0.10, darken(0.05, colors.nanoltgGrey)),
        },
        '&:focus': {
          'background-color': saturate(0.20, darken(0.08, colors.nanoltgGrey)),
        },
        '&:active': {
          'background-color': darken(0.1, colors.nanoltgGrey),
        },
        '&.active': {
          'background-color': saturate(0.15, darken(0.05, colors.nanoltgGrey)),
        },
        '&.basic': {
          'background-color': colors.nanoltgGrey,
          color: `${colors.nanoltgGrey} !important`,
          'box-shadow': `0 0 0 1px ${colors.nanoltgGrey} inset!important`,
          '&:hover': {
            'background-color': saturate(0.10, darken(0.05, colors.nanoltgGrey)),
            color: saturate(0.10, darken(0.05, colors.nanoltgGrey)),
          },
          '&:focus': {
            'background-color': saturate(0.20, darken(0.08, colors.nanoltgGrey)),
            color: saturate(0.10, darken(0.05, colors.nanoltgGrey)),
          },
          '&:active': {
            'background-color': darken(0.1, colors.nanoltgGrey),
            color: darken(0.1, colors.nanoltgGrey),
          },
          '&.active': {
            'background-color': saturate(0.15, darken(0.05, colors.nanoltgGrey)),
            color: darken(0.1, colors.nanoltgGrey),
          },
        },
        '&.inverted': {
          'background-color': colors.white,
          color: colors.nanoltgGrey,
          'border-width': '2px',
          'box-shadow': `0 0 0 2px ${colors.nanoltgGrey} inset!important`,
          '&:hover': {
            'background-color': saturate(0.10, darken(0.05, colors.nanoltgGrey)),
            color: colors.white,
          },
          '&:focus': {
            'background-color': saturate(0.20, darken(0.08, colors.nanoltgGrey)),
            color: colors.white,
          },
          '&:active': {
            'background-color': darken(0.1, colors.nanoltgGrey),
            color: colors.white,
          },
          '&.active': {
            'background-color': saturate(0.15, darken(0.05, colors.nanoltgGrey)),
            color: colors.white,
          },
        },
      },
      '&.secondary': {
        'background-color': colors.black,
        color: colors.white,
        '&:hover': {
          'background-color': saturate(0.10, darken(0.05, colors.black)),
        },
        '&:focus': {
          'background-color': saturate(0.20, darken(0.08, colors.black)),
        },
        '&:active': {
          'background-color': darken(0.1, colors.black),
        },
        '&.active': {
          'background-color': saturate(0.15, darken(0.05, colors.black)),
        },
        '&.basic': {
          'background-color': colors.black,
          color: colors.black,
          'border-width': '1px',
          '&:hover': {
            'background-color': saturate(0.10, darken(0.05, colors.black)),
            color: saturate(0.10, darken(0.05, colors.black)),
          },
          '&:focus': {
            'background-color': saturate(0.20, darken(0.08, colors.black)),
            color: saturate(0.10, darken(0.05, colors.black)),
          },
          '&:active': {
            'background-color': darken(0.1, colors.black),
            color: darken(0.1, colors.black),
          },
          '&.active': {
            'background-color': saturate(0.15, darken(0.05, colors.black)),
            color: darken(0.1, colors.black),
          },
        },
        '&.inverted': {
          'background-color': colors.white,
          color: colors.mineShaft,
          'border-width': '2px',
          'box-shadow': `0 0 0 2px ${colors.mineShaft} inset!important`,
          '&:hover': {
            'background-color': saturate(0.10, darken(0.05, colors.mineShaft)),
            color: colors.white,
          },
          '&:focus': {
            'background-color': saturate(0.20, darken(0.08, colors.mineShaft)),
            color: colors.white,
          },
          '&:active': {
            'background-color': darken(0.1, colors.mineShaft),
            color: colors.white,
          },
          '&.active': {
            'background-color': saturate(0.15, darken(0.05, colors.mineShaft)),
            color: colors.white,
          },
        },
      },
    },
  },
  tables: {
    listItems: {
      '.marked': {
        'background-color': saturate(0.20, darken(0.08, colors.nanoltgGrey)),
        '& dt, dd': {
          color: colors.white,
        },
      },
    },
    statusIcons: {
      '.model-device': {
        '&.online': {
          color: colors.green,
        },
        '&.offline': {
          color: colors.red,
        },
        '&.unassociated': {
          color: colors.grey,
        },
      },
      '.model-user': {
        '&.active': {
          color: colors.nanoltgGrey,
        },
        '&.suspended': {
          color: colors.red,
        },
        '&.inactive': {
          color: colors.grey,
        },
        '&.invited': {
          color: colors.azure,
        },
      },
    },
  },
  links: {
    color: colors.nanoltgGrey,
    '&:hover': {
      'text-decoration': 'underline',
    },
  },
  forms: {
    '.ui.form': {
      input: {
        '&:focus': {
          'border-color': colors.nanoltgGrey,
        },
      },
    },
  },
  indeterminateCheckbox: {
    '&:after': {
      'border-left-color': colors.acspecPurpleq,
    },
  },
};
