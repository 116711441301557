import constants from 'dispatcherConst';
import endpoints from '../data/api/endpoints';

const companyActionCreators = {
  fetchCurrentCompany: () => ({
    type: constants.FETCH_CURRENT_COMPANY_REQUEST,
    payload: { endpoint: endpoints.CURRENT_COMPANY },
  }),

  fetchCompanySuccess: response => ({
    type: constants.FETCH_CURRENT_COMPANY_SUCCESS,
    payload: { response },
  }),

  fetchCompanyFailure: error => ({
    type: constants.FETCH_CURRENT_COMPANY_FAILED,
    payload: { error },
  }),
};

export default companyActionCreators;
