import { useState } from 'react';

function usePopup() {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return {
    isOpen,
    onClose: handleClose,
    onToggle: handleToggle,
  };
}

export default usePopup;
