import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getDisplayName } from 'helpers/sharedMethods';

const chainedLoader = ({ name, included = false }, ...methods) => WrappedComponent => (
  class extends Component {

    // eslint-disable-next-line react/static-property-placement
    static displayName = `ChainedLoader(${getDisplayName(WrappedComponent)})`;

    // eslint-disable-next-line react/static-property-placement
    static defaultProps = {
      pollingToggle: false,
      loaderOverride: false,
      loaderFetching: false,
      loaderError: false,
      loaderLink: '',
    };

    // eslint-disable-next-line react/static-property-placement
    static propTypes = {
      pollingToggle: PropTypes.bool,
      loaderOverride: PropTypes.bool,
      loaderFetching: PropTypes.bool,
      loaderError: PropTypes.bool,
      loaderLink: PropTypes.string,
      dispatch: PropTypes.func.isRequired,
    };

    constructor() {
      super();
      this.state = {
        index: 0,
        complete: false,
      };

      this.handleNextLoad = this.handleNextLoad.bind(this);
    }

    componentDidMount() {
      const { loaderLink: link } = this.props;

      if (link !== null) {
        this.handleNextLoad();
      }
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
      const { complete } = this.state;
      const {
        pollingToggle: nextPollingToggle,
        loaderOverride: nextOverride,
        loaderFetching: nextFetching,
        loaderError: nextError,
      } = nextProps;
      const {
        pollingToggle,
        loaderOverride: override,
        loaderFetching: fetching,
      } = this.props;

      const shouldPoll = pollingToggle !== nextPollingToggle;
      const hasOverride = !override && nextOverride;

      if (shouldPoll || hasOverride) {
        this.setState({ index: 0, complete: false });
        return;
      }

      const shouldLoadNext = (fetching && !nextFetching) && !nextError && !complete;
      const { index } = this.state;

      if (shouldLoadNext) {
        this.setState({ index: index + 1, complete: index + 1 === methods.length });
      }
    }

    componentDidUpdate(prevProps, prevState) {
      const {
        loaderLink: link,
        loaderOverride: override,
      } = this.props;
      const {
        loaderLink: prevLink,
        loaderOverride: prevOverride,
      } = prevProps;

      const { complete, index } = this.state;
      const { complete: prevComplete, index: prevIndex } = prevState;

      const initial = !prevLink && link;
      const reset = (prevComplete && !complete) || (!prevOverride && override);
      const next = (prevIndex < index) && (index < methods.length);

      if (initial || reset || next) {
        this.handleNextLoad();
      }
    }

    handleNextLoad() {
      const { dispatch } = this.props;
      const { index } = this.state;
      if (index > methods.length - 1) return;

      const method = methods[index];
      const action = method({ ...this.props, name, included });

      dispatch(action);
    }

    render() {
      const {
        loaderError,
        loaderFetching,
        loaderLink,
        loaderOverride,
        ...rest
      } = this.props;

      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WrappedComponent {...rest} />;
    }

  }
);

export default chainedLoader;
