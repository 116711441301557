import isNil from 'lodash/isNil';
import moment from 'moment';

const formatDate = value => {
  if (isNil(value)) {
    return 'Invalid date';
  }

  const date = moment(value, 'YYYY-MM-DD');
  const day = date.format('D');
  const month = date.format('MMMM');
  const year = date.format('YYYY');
  const final = `${month} ${day}, ${year}`;

  return final;
};

export default formatDate;
