import React from 'react';
import { Placeholder } from 'semantic-ui-react';

import { StyledSegment, StyledIcon } from './ListPlaceholder.style';

function ListPlaceholder() {
  return (
    <div>
      <StyledSegment>
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line length="short" />
          </Placeholder.Header>
        </Placeholder>
        <StyledIcon name="arrow right" color="grey" />
      </StyledSegment>
      <StyledSegment>
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line length="medium" />
          </Placeholder.Header>
        </Placeholder>
        <StyledIcon name="arrow right" color="grey" />
      </StyledSegment>
      <StyledSegment>
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line length="short" />
          </Placeholder.Header>
        </Placeholder>
        <StyledIcon name="arrow right" color="grey" />
      </StyledSegment>
      <StyledSegment>
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line length="very short" />
          </Placeholder.Header>
        </Placeholder>
        <StyledIcon name="arrow right" color="grey" />
      </StyledSegment>
      <StyledSegment>
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line length="short" />
          </Placeholder.Header>
        </Placeholder>
        <StyledIcon name="arrow right" color="grey" />
      </StyledSegment>
      <StyledSegment>
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line length="medium" />
          </Placeholder.Header>
        </Placeholder>
        <StyledIcon name="arrow right" color="grey" />
      </StyledSegment>
      <StyledSegment>
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line length="short" />
          </Placeholder.Header>
        </Placeholder>
        <StyledIcon name="arrow right" color="grey" />
      </StyledSegment>
      <StyledSegment>
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line length="very short" />
          </Placeholder.Header>
        </Placeholder>
        <StyledIcon name="arrow right" color="grey" />
      </StyledSegment>
    </div>
  );
}

export default ListPlaceholder;
