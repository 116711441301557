import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const readAllNotificationEvents = () => ({
  type: constants.READ_ALL_NOTIFICATION_EVENTS,
  payload: {
    endpoint: endpoints.NOTIFICATION_EVENT_READ_ALL,
  },
});

export const readAllNotificationEventsSuccess = response => ({
  type: constants.READ_ALL_NOTIFICATION_EVENTS_SUCCESS,
  payload: {
    response,
  },
});

export const readAllNotificationEventsFailure = error => ({
  type: constants.READ_ALL_NOTIFICATION_EVENTS_FAILED,
  payload: { error },
});

export default readAllNotificationEvents;
