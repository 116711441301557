import React from 'react';
import PropTypes from 'prop-types';
import { Labels, Header } from './AccordionTableHeader.style';

const AccordionTableHeader = ({ columns, element, children }) => (
  <Header>
    <Labels>
      {columns.map(column => (
        <div key={column}>{element[column]}</div>
      ))}
      {children}
    </Labels>
  </Header>
);

AccordionTableHeader.defaultProps = {
  children: null,
};

AccordionTableHeader.propTypes = {
  children: PropTypes.node,
  element: PropTypes.shape().isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AccordionTableHeader;
