import React from 'react';
import PropTypes from 'prop-types';

import formNames from 'components/form/formNames';
import EditAutomatedOperationTasksModalForm from './EditAutomatedOperationTasksModalForm/EditAutomatedOperationTasksModalFormContainer';

const EditAutomatedOperationTasks = ({
  item,
  currentItem,
  onEditAutomatedOperation,
}) => {
  const existingItem = item || currentItem;
  const { id, lockVersion } = existingItem;
  const handleEditAutomatedOperation = values => new Promise((resolveForm, rejectForm) => {
    onEditAutomatedOperation({
      values: {
        ...values,
        id,
        lockVersion,
      },
      resolveForm,
      rejectForm,
    });
  });

  return (
    <EditAutomatedOperationTasksModalForm
      onSubmit={handleEditAutomatedOperation}
      item={existingItem}
      form={`editAutomatedOperationTasks-${id}-${formNames.editAOTasks}`}
      destroyForm
    />
  );
};

EditAutomatedOperationTasks.defaultProps = {
  item: null,
  currentItem: null,
};

EditAutomatedOperationTasks.propTypes = {
  onEditAutomatedOperation: PropTypes.func.isRequired,
  item: PropTypes.object,
  currentItem: PropTypes.object,
};

export default EditAutomatedOperationTasks;
