import constants from 'dispatcherConst';

export const addSelectedAction = selectedAction => ({
  type: constants.ADD_SELECTED_ACTION,
  payload: {
    selectedAction,
  },
});

export default addSelectedAction;
