import { connect } from 'react-redux';

import { actionCreators } from '../../utilities';
import AddOrigin from './AddOrigin';

const mapDispatchToProps = {
  onAddOrigin: actionCreators.addOrigin,
};

export default connect(null, mapDispatchToProps)(AddOrigin);
