import isNil from 'lodash/isNil';
import findKey from 'lodash/findKey';
import flow from 'lodash/flow';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';

import { API_URL } from 'data/api/requests';
import ModelFactory from 'models/ModelFactory';
import UrlParams, { DESCENDING_PREFIX } from 'helpers/UrlParams';
import FlattenApiResource from 'helpers/FlattenApiResource';
import { DEFAULT_PAGE_SIZE, PAGE_FIELD, SORT_FIELD, FILTER_FIELD } from 'helpers/paginationHelpers';
import mergeWith from 'lodash/mergeWith';
import { replaceArray } from 'helpers/sharedMethods';

export const compare = (valueA, valueB) => {
  if (isNil(valueA) && isNil(valueB)) return 0;

  if (isNil(valueA)) return -1;
  if (isNil(valueB)) return 1;

  return `${valueA}`.localeCompare(valueB, undefined, { numeric: true });
};

const getSortValueFromModel = (objData, ctorAdditional, type, sort) => (
  ModelFactory.getModel(type, new FlattenApiResource(objData), ctorAdditional)[sort]
);

export const clientSorting = (urlParams, ctorAdditional) => data => {
  if (!data.length) return data;

  const { sort } = urlParams;
  if (!sort) return data;
  const { type } = data[0];

  const sortWithoutPrefix = (
    sort.replace
      ? sort.replace(DESCENDING_PREFIX, '')
      : sort.field
  );
  const isDescending = (
    sort.startsWith
      ? sort.startsWith(DESCENDING_PREFIX)
      : sort.direction === DESCENDING_PREFIX
  );

  return [...data]
    .sort((a, b) => {
      const sortValueA = getSortValueFromModel(a, ctorAdditional, type, sortWithoutPrefix);
      const sortValueB = getSortValueFromModel(b, ctorAdditional, type, sortWithoutPrefix);

      const result = compare(sortValueA, sortValueB);

      return isDescending ? -result : result;
    });
};

export const clientSortingGroups = (urlParams, ctorAdditional) => data => {
  const { group } = urlParams;

  const grouped = groupBy(data, `attributes.${group}`);
  const sortedGroups = Object.keys(grouped).sort(compare);

  return sortedGroups.reduce((acc, key) => {
    const value = clientSorting(urlParams, ctorAdditional)(grouped[key]);

    return [...acc, ...value];
  }, []);
};

export const clientGroupingAndSorting = (urlParams, ctorAdditional) => data => {
  if (!data.length) return data;

  const { group } = urlParams;

  return group ? clientSortingGroups(urlParams, ctorAdditional)(data)
    : clientSorting(urlParams, ctorAdditional)(data);
};

export const clientPagination = urlParams => data => {
  if (!data.length) return data;

  const { page: { number, size } } = urlParams;
  const numberIndex = number - 1;

  return data.slice(numberIndex * size, number * size);
};

export const maskData = (urlParams, ctorAdditional) => flow(
  clientGroupingAndSorting(urlParams, ctorAdditional),
  clientPagination(urlParams),
);

export const calculatePageNumbers = (data, urlParams) => {
  const { page: { number, size } } = urlParams;

  const first = 1;
  const last = Math.ceil(data.length / size);
  const prev = number > first ? number - 1 : null;
  const next = number < last ? number + 1 : null;

  return {
    first,
    prev,
    next,
    last,
  };
};

export const prepareLinks = (response, urlParams) => {
  const { data, meta } = response;

  const pageNumbers = calculatePageNumbers(data, urlParams);

  const link = findKey(meta);
  const { page: { size }, sort, filter } = urlParams;

  return Object.keys(pageNumbers).reduce((acc, key) => {
    const value = pageNumbers[key];
    if (!value) return acc;

    const searchParams = new URLSearchParams();
    searchParams.set(`${PAGE_FIELD}[number]`, value);
    searchParams.set(`${PAGE_FIELD}[size]`, size);
    searchParams.set(SORT_FIELD, sort);

    if (filter && !isEmpty(filter) && filter[Object.keys(filter)[0]]) {
      const firstKey = Object.keys(filter)[0];

      searchParams.set(FILTER_FIELD, filter[firstKey]);
    }

    acc[key] = `${API_URL}${link}?${searchParams}`;

    return acc;
  }, {});
};

export const prepareResponseData = (response, urlParams, ctorAdditional) => {
  const { data } = response;
  const updatedPiece = {
    data: maskData(urlParams, ctorAdditional)(data),
    links: prepareLinks(response, urlParams),
  };

  return mergeWith({}, response, updatedPiece, replaceArray);
};

export const websocketUrlParams = pagination => {
  const urlParams = new UrlParams({ pagination });

  const { number, size, sort } = urlParams.extractUrlParams({
    number: urlParams.pageParam,
    size: urlParams.pageSizeParam,
    sort: urlParams.sortParam,
  });

  return {
    page: {
      number: Number(number || 1),
      size: Number(size || DEFAULT_PAGE_SIZE),
    },
    sort,
  };
};
