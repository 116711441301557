import { UPDATE_SETTING_FIELD } from 'helpers/selectors';
import ApiRequestBuilder from 'helpers/ApiRequestBuilder';

class SettingModel {

  constructor(data = {}) {
    this.type = data.type;
    this.setting = data.setting;
    this.meta = data.meta;
  }

  static requestBody(values) {
    return new ApiRequestBuilder(UPDATE_SETTING_FIELD)
      .setAttributes(values);
  }

}

export default SettingModel;
