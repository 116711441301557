import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import { StyledTable, StyledWrapperDiv, StyledP } from './RenderDevicesTable.style';
import { DevicesTablePlaceholder } from './components';

const RenderDevicesTable = ({
  devices,
  isLoading,
  location,
  history,
  siteId,
  isProjectSuite,
}) => {
  const { pathname: currentPath } = location;

  const handleClick = id => {
    const pathname = `${currentPath}/${siteId}/devices/${id}/overview`;

    history.push({ pathname });
  };

  return (
    isLoading ? <DevicesTablePlaceholder /> : (
      <StyledWrapperDiv>
        <StyledTable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {devices.map(({ id, visibleName, statusIconForMap }) => (
              <Table.Row key={id}>
                <Table.Cell>{statusIconForMap}</Table.Cell>
                <Table.Cell>
                  <StyledP
                    onClick={isProjectSuite ? null : () => handleClick(id)}
                    isProjectSuite={isProjectSuite}
                  >
                    {visibleName}
                  </StyledP>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </StyledTable>
      </StyledWrapperDiv>
    )
  );
};

RenderDevicesTable.defaultProps = {
  isProjectSuite: false,
};

RenderDevicesTable.propTypes = {
  devices: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  siteId: PropTypes.string.isRequired,
  isProjectSuite: PropTypes.bool,
};

export default RenderDevicesTable;
