import { call } from 'redux-saga/effects';

import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import { actionCreators } from '..';

export function* fetchLogsActionTypes(action) {
  const {
    endpoint,
    resourceType,
  } = action.payload;

  const { response, error } = yield call(getPath, endpoint);

  const params = {
    response,
    error,
    successDisp: actionCreators.fetchLogsActionTypesSuccess,
    failDisp: actionCreators.fetchLogsActionTypesFailure,
    resourceType,

  };
  yield call(processCall, params);
}

export default fetchLogsActionTypes;
