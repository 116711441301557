import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import {
  TASKS_FIELD,
  FETCHING_FILTER_PROJECTS_FIELD,
  FETCHING_FILTER_PROJECT_SUITES_FIELD,
} from 'helpers/selectors';
import { actionCreators as projectSuitesActionCreators } from 'pages/ProjectSuites/utilities';
import { addPagination } from 'helpers/paginationHelpers';
import { tasksActionCreators } from '../../../../../../../utilities';
import { actionCreators } from './utilities';
import ListOfTasks from './ListOfTasks';

export const mapStateToProps = (state, { match, projectsList }) => {
  const { id: projectSuiteId } = match.params;
  const superSites = true;

  return {
    projectSuiteId,
    superSites,
    projectsList,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoaderWithSaga(tasksActionCreators.fetchActionTypes),
  chainedLoaderWithSaga(({ projectSuiteId }) => actionCreators.fetchDevicesProjectSuites(
    projectSuiteId,
    {
      recipientType: 'projectSuite',
      recipientId: projectSuiteId,
      [FETCHING_FILTER_PROJECT_SUITES_FIELD]: [projectSuiteId],
      filter: 'name',
    },
  )),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: TASKS_FIELD, included: true },
      (
        { projectSuiteId, projectsList },
      ) => projectSuitesActionCreators.fetchProjectSuiteProjectsTasks(
        {
          recipientType: 'projectSuite',
          recipientId: projectSuiteId,
          [FETCHING_FILTER_PROJECT_SUITES_FIELD]: [projectSuiteId],
          [FETCHING_FILTER_PROJECTS_FIELD]: [...projectsList],
          sort: 'name',
          filter: 'name',
        },
        projectSuiteId,
      ),
    ),
  ),
)(ListOfTasks);
