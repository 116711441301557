import isNull from 'lodash/isNull';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';

import renderTitle from './renderTitle';
import processParam from './processParam';

const extractTitle = startMethodData => {

  if (isNull(startMethodData) || isEmpty(startMethodData)) {
    return null;
  }

  if (isString(startMethodData)) {
    return renderTitle(startMethodData);
  }

  if (isObject(startMethodData) && startMethodData.type) {
    const { type, params } = startMethodData;

    if (isEmpty(params) || size(params) > 1) {
      return renderTitle(type);
    }

    const [paramKey] = Object.keys(params);
    const paramValue = params[paramKey];
    const paramToRender = processParam(paramValue);
    const title = `${type} ${paramToRender}`;

    return renderTitle(title);
  }

  return null;
};

export default extractTitle;
