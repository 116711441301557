import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  projectSelector,
} from 'helpers/selectors';
import formNames from 'components/form/formNames';
import { validateOnSubmit } from 'components/form/formConfigs';
import AddOperationModalForm from './AddOperationModalForm';

const AddOperationModalFormContainer = reduxForm({
  form: formNames.addNewOperation,
  enableReinitialize: true,
  ...validateOnSubmit,
})(AddOperationModalForm);

const mapStateToProps = (state, { match }) => {
  const { id: projectId } = match.params;
  const { name: projectName } = projectSelector(state)(projectId);

  return {
    projectName,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
)(AddOperationModalFormContainer);
