import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { selectors } from '../../../../../../utilities';
import BackgroundImageUrl from './BackgroundImageUrl';

export const mapStateToProps = (state, { match, pageId }) => {
  const { id: projectId } = match.params;
  const { pages = [] } = selectors.projectControlUISelector(state)(projectId) || {};
  const {
    backgroundImg,
  } = pages.filter(({ index }) => index === pageId)[0] || {};

  return {
    projectId,
    backgroundImg,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, null),
)(BackgroundImageUrl);
