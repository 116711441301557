import styled from 'styled-components';

import { mediaMax } from 'themes/mixins';

const Container = styled.div`
  display: flex;
  align-items: center;

  ${mediaMax.md`
    display: block;
  `};
`;

export default Container;
