import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Modal } from 'semantic-ui-react';

import { Button } from 'components/atomic';
import { StyledAddUsersButton } from './AddUsersModal.style';
import UserTable from './UserTable/UserTableContainer';

class AddUsersModal extends Component {

  constructor() {
    super();

    this.state = { open: false };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
    const { open } = this.state;
    const { resourceId, resourceType } = this.props;
    const trigger = (
      <StyledAddUsersButton onClick={this.handleOpen}>
        Manage users...
      </StyledAddUsersButton>
    );

    return (
      <Modal
        open={open}
        trigger={trigger}
        onClose={this.handleClose}
      >
        <Modal.Content scrolling>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16}>
                {open && (
                  <UserTable
                    resourceId={resourceId}
                    resourceType={resourceType}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            basic
            onClick={this.handleClose}
          >
            Done
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

}

AddUsersModal.propTypes = {
  resourceId: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
};

export default AddUsersModal;
