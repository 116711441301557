import isEmpty from 'lodash/isEmpty';
import pluralize from 'pluralize';

const getTotalActions = (selectedActions, selectedTasks) => {
  if (selectedTasks.length === 0) {
    return selectedActions.length;
  }

  const actionsLength = selectedActions.length;

  const taskActions = selectedTasks.map(task => task.actions || []);
  const taskActionsLength = taskActions.map(actions => actions.length);

  let taskActionsSum = 0;

  if (taskActionsLength.length > 0) {
    taskActionsSum = taskActionsLength.reduce((sum, x) => sum + x);
  }

  return actionsLength + taskActionsSum;
};

const getTotalTasks = (selectedActions, selectedTasks) => {
  if (selectedActions.length > 0) {
    return selectedTasks.length + 1;
  }

  return selectedTasks.length;
};

const getSelectedActionsDescription = (selectedActions = [], selectedTasks = []) => {
  if (isEmpty(selectedActions) && isEmpty(selectedTasks)) {
    return 'No Tasks & Actions selected.';
  }

  const totalActions = getTotalActions(selectedActions, selectedTasks);
  const totalTasks = getTotalTasks(selectedActions, selectedTasks);

  return `${totalActions} ${pluralize('Action', totalActions)} from ${totalTasks} ${pluralize('Task', totalTasks)} selected.`;
};

export default getSelectedActionsDescription;
