import styled, { css } from 'styled-components';
import { Button, Loader } from 'semantic-ui-react';
import get from 'lodash/get';
import isNull from 'lodash/isNull';

import filterProps from 'helpers/filterProps';
import InputField from 'components/form/InputField/InputField';

const FilteredProps = filterProps([
  'buttonColor',
  'isSelected',
])(Button);

export const StyledInput = styled(InputField)`
  visibility: hidden;
  width: 0px;
  height: 0px;
`;

export const StyledButton = styled(FilteredProps)`
  &.ui.button {
    width: 25px;
    height: 25px;
    padding: 0px;
    margin-top: 7px;
    margin-right: 7px;
    margin-bottom: 7px;
    transition: opacity 0.2s ease;
    background: #fff;

    ${({ buttonColor, theme }) => css`
      background: ${buttonColor || get(theme, 'palette.defaultOccurrenceColor')};
      border: ${(isNull(buttonColor) || buttonColor === '#FFFFFF') && 'solid 1px #dededf'};
    `}

    ${({ isSelected }) => isSelected && css`
      border: solid 2px #000000;
      box-shadow: 0 4px 5px rgba(0,0,0,0.30), 0 5px 5px rgba(0,0,0,0.22);
    `}
  }

  &.ui.button:hover {
    opacity: 0.7;
  }
`;

export const StyledLoader = styled(Loader)`
  &.ui.loader:after {
    margin-top: 3px;
    width: 1.5em;
    height: 1.5em;

    ${({ active }) => active && css`
      border-color: #767676 transparent transparent !important;
    `}
  }

  &.ui.loader:before {
    margin-top: 3px;
    width: 1.5em;
    height: 1.5em;

    ${({ active }) => active && css`
      border-color: #eeeeee !important;
    `}
  }

`;

export default StyledInput;
