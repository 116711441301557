import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';
import deepEqual from 'lodash/isEqual';
import identity from 'lodash/identity';

import { deviceSelector } from 'helpers/selectors';
import PharosTemperaturesV1 from './PharosTemperaturesV1';

const makeMapState = () => {
  const memoAmbientTemp = memoizeOne(identity, deepEqual);
  const memoSysTemp = memoizeOne(identity, deepEqual);
  const memoCoreTemp = memoizeOne(identity, deepEqual);
  const memoCaptureCardTemp = memoizeOne(identity, deepEqual);
  const memoGpuTemp = memoizeOne(identity, deepEqual);

  return function mapStateToProps(state, ownProps) {
    const { resourceId } = ownProps;
    const device = deviceSelector(state)(resourceId);
    const { reportedWithMeta } = device;

    const {
      temp: {
        amb: ambientTemp,
        sys: sysTemp,
        core: coreTemp,
        cc: captureCardTemp,
        gpu: gpuTemp,
      } = {},
    } = reportedWithMeta || {};

    return {
      loading: !reportedWithMeta,
      ambientTemp: memoAmbientTemp(ambientTemp),
      sysTemp: memoSysTemp(sysTemp),
      coreTemp: memoCoreTemp(coreTemp),
      captureCardTemp: memoCaptureCardTemp(captureCardTemp),
      gpuTemp: memoGpuTemp(gpuTemp),
    };
  };
};

export default connect(makeMapState())(PharosTemperaturesV1);
