import React from 'react';
import PropTypes from 'prop-types';

import { PROJECTS_FIELD } from 'helpers/selectors';
import DataTable from 'components/DataTable/DataTableContainer';
import { StyledProjectsTableWrapper } from './ProjectsTable.style';
import ProjectsRow from './ProjectsRow/ProjectsRowContainer';

const projectsTableLabels = [
  { text: 'Site', field: 'visibleName', sortBy: 'name' },
];

const ProjectsTable = ({ selectingProjects }) => selectingProjects && (
  <StyledProjectsTableWrapper>
    <DataTable
      header="Sites"
      labels={projectsTableLabels}
      customTableRow={ProjectsRow}
      pagination={{
        type: PROJECTS_FIELD,
        args: { self: true },
      }}
    />
  </StyledProjectsTableWrapper>
);

ProjectsTable.propTypes = {
  selectingProjects: PropTypes.bool.isRequired,
};

export default ProjectsTable;
