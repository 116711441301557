import styled from 'styled-components';
import { FaExclamationTriangle } from 'react-icons/fa';

const StyledSuspendedSiteIcon = styled(FaExclamationTriangle)`
  margin-left: 8px;
`;

export const StyledDiv = styled.div`
  display: inline-block;
`;

export default StyledSuspendedSiteIcon;
