import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';
import pluralize from 'pluralize';

import { Container, TaskItem, StyledLabel } from './TaskList.style';

function TaskList({
  tasks,
  selectedTasks,
  onTaskToggle,
}) {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleTaskToggle = useCallback((e, task) => {
    onTaskToggle(task);
    e.stopPropagation();
  }, [onTaskToggle]);

  const renderActionsLabel = task => {
    const { actions } = task;
    const length = !actions ? 0 : actions.length;

    return (
      <StyledLabel htmlFor="actions">
        {length} {pluralize('Action', length)}
      </StyledLabel>
    );
  };

  const renderTaskName = name => {
    if (name.length > 20) {
      return `${name.substring(0, 20)}...`;
    }

    return name;
  };

  useEffect(() => {
    const [selectedTask] = selectedTasks;

    if (!isLoaded && selectedTask) {
      const element = document.getElementById(selectedTask.id);

      if (element) {
        element.scrollIntoView();
      }
    }

    setIsLoaded(true);
  }, [isLoaded, selectedTasks]);

  return (
    <>
      <Container divided verticalAlign="middle">
        {tasks.map(task => (
          <TaskItem id={task.id} key={task.id} title={task.name}>
            <div>
              <Checkbox
                onChange={e => handleTaskToggle(e, task)}
                checked={selectedTasks.map(s => s.id).includes(task.id)}
              />
              <p>{renderTaskName(task.name)}</p>
            </div>
            {renderActionsLabel(task)}
          </TaskItem>
        ))}
      </Container>
    </>
  );
}

TaskList.defaultProps = {
  tasks: [],
  selectedTasks: [],
};

TaskList.propTypes = {
  tasks: PropTypes.array,
  selectedTasks: PropTypes.arrayOf(PropTypes.object),
  onTaskToggle: PropTypes.func.isRequired,
};

export default TaskList;
