import { Dropdown, Button } from 'semantic-ui-react';
import styled from 'styled-components';

export const StyledDropdown = styled(Dropdown)`
  margin: 0 0 1.5em 0;
`;

export const StyledButton = styled(Button)`
  &.ui.button {
    margin: .10em .25em 0 0.5em;
    max-height: 36px;
  }
`;

export const StyledDiv = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  min-height: 50px;
`;

export default StyledDropdown;
