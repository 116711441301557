import styled from 'styled-components';

import colors from 'themes/colors';
import UploadDataTableButton from 'components/elements/UploadTableRow/UploadTableRow.style';

const MobileUploadItem = styled.div`
  display: flex;
  padding: 0.75em 0!important;
  align-items: center;
`;

MobileUploadItem.Name = styled.span`
  flex: 1;
`;

MobileUploadItem.Status = styled.span`
  margin-left: 1em;
  color: ${colors.grey};
`;

MobileUploadItem.LeftSideControl = styled(UploadDataTableButton)`
  &.ui.icon.button {
    margin-right: 1em;
  }
`;

MobileUploadItem.RightSideControl = styled(UploadDataTableButton)`
  &.ui.icon.button {
    margin-left: 1em;
  }
`;

export default MobileUploadItem;
