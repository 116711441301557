import styled, { css } from 'styled-components';

import { Button } from 'components/atomic';
import { mediaMax } from 'themes/mixins';
import filterProps from 'helpers/filterProps';

const FilteredProps = filterProps([
  'release',
])(Button);

const RunButton = styled(FilteredProps)`
  &.ui.button {
    ${({ release }) => release && css`
      font-size: 13px;
      width: 67px;
      height: 40px;
      padding: 0.5em;
    `}
    ${mediaMax.md`
      width: 100%;
      float: none;
      font-size: 14px;
    `};
  }
`;

export default RunButton;
