import styled, { css } from 'styled-components';
import { Header, Modal } from 'semantic-ui-react';

import { mediaMax } from 'themes/mixins';
import { Button } from 'components/atomic';

export const StyledModal = styled(Modal)`
  ${mediaMax.sm`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100% !important;
    margin: 0 !important;
  `};

  &.ui.modal .scrolling.content {
    ${mediaMax.sm`
      max-height: calc(100vh - 130px) !important;
      overflow: auto;
    `};
  }
`;

export const StyledModalContent = styled(Modal.Content)`
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;
`;

export const StyledModalActions = styled(Modal.Actions)`
  z-index: 999;

  ${mediaMax.sm`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    & .ui.grid {
      margin-bottom: 0 !important;
    }
  `};
`;

export const StyledSubheader = styled(Header.Subheader)`
  margin-top: 0.5em !important;
`;

export const Container = styled.div`
  ${({ isVisible }) => css`
    display: ${isVisible ? 'block' : 'none'};  
  `}
`;

export const StyledNextStepButton = styled(Button)`
  margin-left: 0.5em !important;
`;

export default StyledSubheader;
