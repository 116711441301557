import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';

import { getDisplayName } from 'helpers/sharedMethods';

const isOptional = WrappedComponent => {
  const IsOptional = ({ value, ...rest }) => !isNil(value) && (
    <WrappedComponent value={value} {...rest} />
  );

  IsOptional.displayName = `IsOptional(${getDisplayName(WrappedComponent)})`;

  IsOptional.defaultProps = {
    value: null,
  };

  IsOptional.propTypes = {
    value: PropTypes.any,
  };

  return IsOptional;
};

export default isOptional;
