import { actionTypes } from './constants';

const translateActionsList = list => {
  if (Array.isArray(list)) {
    return list.map(item => {
      if (actionTypes[item.text]) {
        return {
          id: item.id,
          text: actionTypes[item.text].text,
          value: item.value,
        };
      }

      return item;
    });
  }

  return list;
};

export default translateActionsList;
