import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';

import {
  StyledButton,
  StyledContent,
  StyledTrigger,
} from './RecipientLabel.style';

const RecipientLabel = ({
  context,
  resourceId,
  resourceType,
  recipientType,
  limitedScope,
  limitedFor,
  recipient,
  copyRecipientId,
  copyRecipientName,
  onRemoveRecipient,
  onCopyPermissions,
  onPastePermissions,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const canCopy = useMemo(
    () => copyRecipientId === recipient.key,
    [copyRecipientId, recipient],
  );

  const handleHideUser = useCallback(() => {
    onRemoveRecipient(recipient.key);
  }, [recipient, onRemoveRecipient]);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleCopy = useCallback(() => {
    handleClose();
    onCopyPermissions(recipient.key);
  }, [recipient, handleClose, onCopyPermissions]);

  const handlePaste = useCallback(() => {
    handleClose();

    onPastePermissions({
      context,
      resourceId,
      resourceType,
      recipientType,
      limitedScope,
      limitedFor,
      copyRecipientId,
      pasteRecipientId: recipient.key,
    });
  }, [
    context,
    resourceId,
    resourceType,
    recipientType,
    limitedScope,
    limitedFor,
    copyRecipientId,
    recipient,
    handleClose,
    onPastePermissions,
  ]);

  const trigger = useMemo(() => {
    const { text } = recipient || {};

    return (
      <span>
        <StyledTrigger canCopy={canCopy}>
          {text}
        </StyledTrigger>
      </span>
    );
  }, [recipient, canCopy]);

  const title = useMemo(() => {
    const { text, metadata } = recipient || {};
    const { recipientName } = metadata || {};

    return recipientName || text;
  }, [recipient]);

  const copyMessage = useMemo(() => {
    if (canCopy) {
      return 'Cancel copy';
    }

    return 'Copy permissions';
  }, [canCopy]);

  const content = (
    <StyledContent>
      <p>
        <strong>{title}</strong>
      </p>
      <StyledButton primary onClick={handleHideUser}>
        Hide user
      </StyledButton>
      <StyledButton primary onClick={handleCopy}>
        {copyMessage}
      </StyledButton>
      {copyRecipientId && !canCopy && (
        <StyledButton primary onClick={handlePaste}>
          Paste from {copyRecipientName}
        </StyledButton>
      )}
    </StyledContent>
  );

  return (
    <Popup
      trigger={trigger}
      content={content}
      open={isOpen}
      position="bottom left"
      wide
      hoverable
      onOpen={handleOpen}
      onClose={handleClose}
    />
  );
};

RecipientLabel.defaultProps = {
  context: null,
  copyRecipientId: null,
  copyRecipientName: null,
  recipient: {},
  recipientType: 'user',
  limitedScope: [],
  limitedFor: ['user'],
};

RecipientLabel.propTypes = {
  context: PropTypes.string,
  resourceId: PropTypes.string.isRequired,
  resourceType: PropTypes.oneOf([
    'project',
    'project_suite',
    'logical_device',
    'user',
    'tenant',
  ]).isRequired,
  recipientType: PropTypes.string,
  recipient: PropTypes.object,
  limitedScope: PropTypes.arrayOf(
    PropTypes.string,
  ),
  limitedFor: PropTypes.arrayOf(
    PropTypes.string,
  ),
  copyRecipientId: PropTypes.string,
  copyRecipientName: PropTypes.string,
  onRemoveRecipient: PropTypes.func.isRequired,
  onCopyPermissions: PropTypes.func.isRequired,
  onPastePermissions: PropTypes.func.isRequired,
};

export default RecipientLabel;
