import styled from 'styled-components';
import { Segment } from 'semantic-ui-react';

import { media, mediaOnly } from 'themes/mixins';

export const CardWrapper = styled(Segment)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardData = styled.div`
  margin-bottom: 1em;
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  
  ${mediaOnly.xs`
    flex-direction: column;
  `};
`;

export const CardTextContent = styled.div`
  max-width: 500px;
  flex-basis: 250px;
  flex-grow: 1;
  
  ${mediaOnly.xs`
    flex-basis: auto;
  `};
`;

export const CardAction = styled.div`
  display: flex;
  align-items: flex-end;
  
  ${mediaOnly.xs`
    margin-top: 1em;
  `};
  
  ${media.sm`
    margin-left: 1em;
  `};
  
  .ui.button {
    width: 95px;
  
    ${mediaOnly.xs`
      width: 100%;
    `};
  }
`;
