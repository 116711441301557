import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';

import { complexValueType } from 'data/types';
import OptionalTempDescriptionItem
  from 'components/DescriptionList/TempDescriptionItem/OptionalTempDescriptionItem';

const PharosTemperaturesV1 = props => {
  const { loading } = props;
  if (loading) return null;

  const {
    title,
    ambientTemp,
    sysTemp,
    coreTemp,
    captureCardTemp,
    gpuTemp,
  } = props;

  return (
    <Segment>
      <Header as="h2">{title}</Header>
      <OptionalTempDescriptionItem
        title="Ambient"
        value={ambientTemp}
        unit="C"
      />
      <OptionalTempDescriptionItem
        title="System"
        value={sysTemp}
        unit="C"
      />
      <OptionalTempDescriptionItem
        title="Core"
        value={coreTemp}
        unit="C"
      />
      <OptionalTempDescriptionItem
        title="Capture card"
        value={captureCardTemp}
        unit="C"
      />
      <OptionalTempDescriptionItem
        title="GPU"
        value={gpuTemp}
        unit="C"
      />
    </Segment>
  );
};

PharosTemperaturesV1.defaultProps = {
  loading: false,
  ambientTemp: null,
  sysTemp: null,
  coreTemp: null,
  captureCardTemp: null,
  gpuTemp: null,
};

PharosTemperaturesV1.propTypes = {
  loading: PropTypes.bool,
  ambientTemp: complexValueType(PropTypes.number),
  sysTemp: complexValueType(PropTypes.number),
  coreTemp: complexValueType(PropTypes.number),
  captureCardTemp: complexValueType(PropTypes.number),
  gpuTemp: complexValueType(PropTypes.number),
  title: PropTypes.string.isRequired,
};

export default PharosTemperaturesV1;
