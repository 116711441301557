import { takeLatest, select, put } from 'redux-saga/effects';
import constants from 'dispatcherConst';
import chainedLoaderActionCreators from 'helpers/chainedLoader/chainedLoaderActionCreators';
import { paginationChainedLoaderIdSelector } from 'helpers/selectors';

export function* handleRefetch({ payload: { resourceType, componentName } }) {
  const checkResourceType = componentName || resourceType;
  const chainedLoaderId = (yield select(paginationChainedLoaderIdSelector))(checkResourceType);

  if (chainedLoaderId) {
    yield put(chainedLoaderActionCreators.triggerChain(chainedLoaderId));
  }
}

function* withSortingSaga() {
  yield takeLatest(constants.REFETCH, handleRefetch);
}

export default withSortingSaga;
