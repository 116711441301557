import React from 'react';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { Button } from 'components/atomic';

const DisableTwoFactor = ({ onDisableTwoFactor }) => (
  <Grid>
    <Grid.Row>
      <Grid.Column computer={8} mobile={16}>
        <Button negative onClick={onDisableTwoFactor}>
          Disable Two Factor Authentication
        </Button>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

DisableTwoFactor.propTypes = {
  onDisableTwoFactor: PropTypes.func.isRequired,
};

export default DisableTwoFactor;
