import {
  deleteInvitation,
  deleteInvitationSuccess,
  deleteInvitationFailure,
} from './deleteInvitation';
import {
  editProfile,
  editProfileSuccess,
  editProfileFailure,
} from './editProfile';
import {
  fetchUsers,
  fetchUsersSuccess,
  fetchUsersFailure,
} from './fetchUsers';
import {
  resetSecretKey,
  resetSecretKeySuccess,
  resetSecretKeyFailure,
} from './resetSecretKey';
import {
  toggleSuspendUser,
  toggleSuspendUserSuccess,
  toggleSuspendUserFailure,
} from './toggleSuspendUser';
import {
  changePassword,
  changePasswordSuccess,
  changePasswordFailure,
} from './changePassword';
import suppressInvitations from './suppressInvitations';

export default {
  deleteInvitation,
  deleteInvitationSuccess,
  deleteInvitationFailure,
  editProfile,
  editProfileSuccess,
  editProfileFailure,
  fetchUsers,
  fetchUsersSuccess,
  fetchUsersFailure,
  resetSecretKey,
  resetSecretKeySuccess,
  resetSecretKeyFailure,
  toggleSuspendUser,
  toggleSuspendUserSuccess,
  toggleSuspendUserFailure,
  changePassword,
  changePasswordSuccess,
  changePasswordFailure,
  suppressInvitations,
};
