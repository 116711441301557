import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';

import { StyledDateWrapper, StyledLabel } from './SelectDate.style';

const SelectDate = ({ format, label, onDateChange }) => {
  const [date, setDate] = useState(new Date());
  const handleDateChange = value => {
    const dateValue = new Date(value);
    dateValue.setHours(0, 0, 0, 0);

    onDateChange(dateValue);
    setDate(dateValue);
  };

  return (
    <div>
      {label && (<StyledLabel>{label}</StyledLabel>)}
      <StyledDateWrapper>
        <DateTimePicker
          onChange={handleDateChange}
          value={date}
          format={format}
          time={false}
          inputProps={{ readOnly: true }}
        />
      </StyledDateWrapper>
    </div>
  );
};

SelectDate.defaultProps = {
  format: 'DD/MM/y',
  label: null,
};

SelectDate.propTypes = {
  format: PropTypes.string,
  label: PropTypes.string,
  onDateChange: PropTypes.func.isRequired,
};

export default SelectDate;
