import { getOccurrenceTypeText, getOccurrenceDayText } from '.';

const getReadableMonthlyCondition = condition => {
  if (!condition) {
    return '';
  }

  const { daysFromMonthEnd, daysFromMonthStart, on } = condition;

  if (daysFromMonthStart) {
    const text = getOccurrenceTypeText(daysFromMonthStart);

    return `${text} day of the month`;
  }

  if (daysFromMonthEnd) {
    return 'Last day of the month';
  }

  if (on) {
    const { weekday, nthOccurrence } = on;

    const dayText = getOccurrenceDayText(weekday);
    const typeText = getOccurrenceTypeText(nthOccurrence);

    if (nthOccurrence === -1) {
      return `Last ${dayText} of the month`;
    }

    return `${typeText} ${dayText} of the month`;
  }

  return '';
};

export default getReadableMonthlyCondition;
