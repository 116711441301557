import { FORM_ERROR } from 'final-form';

import AsyncErrorParser from 'helpers/AsyncErrorParser';

class FinalFormErrorParser extends AsyncErrorParser {

  wrapAsSubmissionError() {
    return this.processServerResponse();
  }

  processServerResponse() {
    const { _error, ...fieldsErrors } = super.processServerResponse();

    return {
      [FORM_ERROR]: _error,
      ...fieldsErrors,
    };
  }

}

export default FinalFormErrorParser;
