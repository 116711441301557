import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';

import { StyledButton } from './CopyButton.style';

const CopyButton = ({ data, onCopyToClipboard }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(JSON.stringify(data, null, 2));
    onCopyToClipboard();
  };

  return !isEmpty(data) && (
    <StyledButton
      icon
      labelPosition="right"
      size="tiny"
      onClick={handleCopy}
    >
      Copy
      <Icon name="copy" />
    </StyledButton>
  );
};

CopyButton.propTypes = {
  data: PropTypes.array.isRequired,
  onCopyToClipboard: PropTypes.func.isRequired,
};

export default CopyButton;
