import DeviceModel from 'models/DeviceModel';

const projectDevicesSelector = state => {
  const allDevices = state.taskScheduler.devices;

  return allDevices.map(device => new DeviceModel({
    id: device.id,
    ...device.attributes,
  }));
};

export default projectDevicesSelector;
