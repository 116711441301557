import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import { DateTime } from 'luxon';

const getMinDate = minDate => {
  if (isNull(minDate) || isUndefined(minDate)) {
    return undefined;
  }

  const { year, month, day } = DateTime.fromISO(minDate, { zone: 'utc' }).toObject();

  return new Date(year, month - 1, day);
};

export default getMinDate;
