import { connect } from 'react-redux';
import { compose } from 'redux';
import performActionActionCreators from 'pages/Device/performActionActionCreators';
import withValueOverride from 'helpers/withValueOverride/withValueOverrideContainer';
import SnapshotTableRow from './SnapshotTableRow';

const mapStateToProps = (state, { deviceId, item: { id, active } }) => ({
  active: !!active,
  overrideId: `override.snapshot.${deviceId}.${id}.active`,
  overrideKey: 'active',
});

const mapDispatchToProps = {
  onPerformAction: performActionActionCreators.performActionRequest,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withValueOverride,
)(SnapshotTableRow);
