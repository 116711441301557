import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

const editUrlOrigin = (params, companyId) => ({
  type: constants.EDIT_URL_ORIGIN_REQUEST,
  payload: {
    endpoint: endpoints.COMPANY(companyId),
    companyId,
    params,
  },
});

export default editUrlOrigin;
