import React from 'react';

import { Container } from './None.style';

function None() {
  return (
    <Container>
      <p>
        No Schedule configuration will be created. <br />
        Proceed to the next step.
      </p>
    </Container>
  );
}

export default None;
