import { setSelectedDevice } from './setSelectedDevice';
import { addSelectedAction } from './addSelectedAction';
import { removeSelectedAction } from './removeSelectedAction';
import { setSchedulerType } from './setSchedulerType';
import { clearSchedulerParams } from './clearSchedulerParams';
import { addTaskScheduler, addTaskSchedulerSuccess, addTaskSchedulerFailure } from './addTaskScheduler';
import { resetTaskScheduler } from './resetTaskScheduler';
import { addSelectedTask } from './addSelectedTask';
import { setTaskName } from './setTaskName';
import { setRecurrenceParams } from './setRecurrenceParams';
import { setEndingCriteria } from './setEndingCriteria';
import { setRecurrenceErrors } from './setRecurrenceErrors';
import { setTaskSchedulerMode } from './setTaskSchedulerMode';
import { setExistingSchedule } from './setExistingSchedule';
import { fetchProjectDevices, fetchProjectDevicesSuccess, fetchProjectDevicesFailure } from './fetchProjectDevices';
import { setSchedulerName } from './setSchedulerName';
import { setSchedulerDescription } from './setSchedulerDescription';
import { setSelectedProject } from './setSelectedProject';

export default {
  setSelectedDevice,
  addSelectedAction,
  removeSelectedAction,
  setSchedulerType,
  clearSchedulerParams,
  addTaskScheduler,
  addTaskSchedulerSuccess,
  addTaskSchedulerFailure,
  resetTaskScheduler,
  addSelectedTask,
  setTaskName,
  setRecurrenceParams,
  setEndingCriteria,
  setRecurrenceErrors,
  setTaskSchedulerMode,
  setExistingSchedule,
  fetchProjectDevices,
  fetchProjectDevicesSuccess,
  fetchProjectDevicesFailure,
  setSchedulerName,
  setSchedulerDescription,
  setSelectedProject,
};
