import { put, takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import notificationsActionCreators from './notificationsActionCreators';

export function* dispatchSpecificError(action) {
  const { error, requester } = action.payload;
  yield put(notificationsActionCreators.addRequesterError(error, requester));
}

export function* dispatchAddGeneralError(action) {
  const { error = {} } = action.payload;
  yield put(notificationsActionCreators.addGeneralError(error.toArray));
}

export function* dispatchTaskError(action) {
  const { error = {} } = action.payload;
  yield put(notificationsActionCreators.addGeneralError(error.tasksError));
}

export function* dispatchActionExecutionError(action) {
  const { error = {} } = action.payload;
  yield put(notificationsActionCreators.addGeneralError(error.actionsExecutionError));
}

export function* dispatchUserError(action) {
  const { message } = action.payload;
  yield put(notificationsActionCreators.addGeneralError(message));
}

export function* dispatchAddGeneralNotification(action) {
  const { message } = action.payload;
  yield put(notificationsActionCreators.addGeneralNotification(message));
}

export function* dispatchAddInfoNotification(action) {
  const { info } = action.payload;
  yield put(notificationsActionCreators.addInfoNotification(info));
}

export function* dispatchPermissionError(action) {
  const { message } = action.payload;
  yield put(notificationsActionCreators.addGeneralError(message));
}

function* notificationsSaga() {
  yield takeLatest(constants.INVITATION_TOKEN_VERIF_FAILED, dispatchSpecificError);

  yield takeLatest(constants.INVITE_USER_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.ADD_COMPANY_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.ADD_PROJECT_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.ADD_PROJECT_SUITE_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.DELETE_PROJECT_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.DELETE_PROJECT_SUITE_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.RESET_PASSWORD_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.DELETE_INVITATION_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.UPDATE_COMPANY_INFO_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.TOGGLE_SUSPEND_USER_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.RESET_SECRET_KEY_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.CHANGE_PASSWORD_REQUEST_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.REMOVE_USER_FROM_PROJECT_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.ADD_ORIGIN_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.EDIT_ORIGIN_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.DELETE_ORIGIN_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.TRIGGER_FILE_TRANSFER_REQUEST_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.DOWNLOAD_FILE_REQUEST_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.REMOVE_FILE_REQUEST_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.PERFORM_TRIGGERS_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.CHANGE_API_DOMAIN_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.ADD_TASK_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.REMOVE_TASK_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.TRIGGER_TASK_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.TRIGGER_MULTIPLE_TASKS_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.EXECUTE_ACTIONS_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.UPDATE_TASK_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.UPDATE_SITE_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.UPDATE_PROJECT_SUITE_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.ADD_TASK_SCHEDULER_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.ADD_SCHEDULE_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.REMOVE_SCHEDULE_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.REMOVE_AUTOMATED_OPERATION_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.UPDATE_AUTOMATED_OPERATION_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.UPDATE_SCHEDULE_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.DISABLE_TWO_FACTOR_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.REFRESH_OTP_SECRET_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.EDIT_PROFILE_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.PERFORM_SYNC_FIRMWARE_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.REDEEM_VOUCHER_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.GENERATE_VOUCHER_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.UPGRADE_SITE_PLAN_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(
    constants.REMOVE_PROJECT_FROM_PROJECT_SUITE_SUCCESS, dispatchAddGeneralNotification,
  );
  yield takeLatest(
    constants.ADD_PROJECT_TO_PROJECT_SUITE_SUCCESS, dispatchAddGeneralNotification,
  );
  yield takeLatest(
    constants.REMOVE_USER_FROM_PROJECT_SUITE_SUCCESS, dispatchAddGeneralNotification,
  );
  yield takeLatest(constants.COPY_ACTIVITY_LOGS_TO_CLIPBOARD, dispatchAddGeneralNotification);
  yield takeLatest(constants.ADD_DEVICE_FIRMWARE_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.DELETE_DEVICE_FIRMWARE_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(
    constants.LINK_FILE_WITH_DEVICE_FIRMWARE_SUCCESS, dispatchAddGeneralNotification,
  );
  yield takeLatest(constants.EDIT_DEVICE_FIRMWARE_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.TRANSFER_DEVICE_FIRMWARE_SUCCESS, dispatchAddGeneralNotification);
  yield takeLatest(constants.RENEW_SUSPENDED_SITE_SUCCESS, dispatchAddGeneralNotification);

  yield takeLatest(constants.LOGIN_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.INVITE_USER_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.FETCH_USERS_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.ADD_COMPANY_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.ADD_PROJECT_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.FETCH_DEVICE_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.FETCH_PROJECT_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.FETCH_PROJECTS_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.FETCH_COMPANIES_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.FETCH_TRIGGERS_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.FETCH_FIXTURE_GROUPS_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.FETCH_TIMELINES_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.FETCH_STATIC_SCENES_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.FETCH_IO_MODULES_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.REPLACE_DEVICE_TOKEN_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.FETCH_CURRENT_COMPANY_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.CHANGE_PASSWORD_REQUEST_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.UPLOAD_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.UPLOAD_PAUSE_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.UPLOAD_RESUME_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.UPLOAD_CANCEL_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.TRIGGER_FILE_TRANSFER_REQUEST_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.REMOVE_FILE_REQUEST_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.DOWNLOAD_FILE_REQUEST_FAILED, dispatchTaskError);
  yield takeLatest(constants.FETCH_PATCHES_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.FETCH_OUTPUT_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.POLLING_RESOURCE_UPDATE_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.FETCH_LOGS_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.PERMISSIONS_TOGGLE_USER_PERMISSION_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.TOGGLE_SUSPEND_USER_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.RESET_SECRET_KEY_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.UPDATE_COMPANY_INFO_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.ADD_ORIGIN_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.EDIT_ORIGIN_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.DELETE_ORIGIN_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.CHANGE_API_DOMAIN_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.EDIT_PROFILE_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.DELETE_INVITATION_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.ADD_TO_DATA_STORE_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.PERFORM_RESET_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.PERFORM_FORMAT_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.PERFORM_TRIGGERS_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.PERFORM_ACTION_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.TOGGLE_BEACON_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.TOGGLE_WATCHDOG_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.TOGGLE_DISABLE_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.SET_TIMESTAMP_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.SET_LOG_LEVEL_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.UPDATE_DEVICE_SETTING_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.RENAME_DEVICE_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.DELETE_PROJECT_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.REMOVE_PROJECT_FROM_PROJECT_SUITE_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.ADD_PROJECT_TO_PROJECT_SUITE_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.REMOVE_USER_FROM_PROJECT_SUITE_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.REMOVE_USER_FROM_PROJECT_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.ADD_DEVICE_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.REMOVE_DEVICE_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.ADD_TASK_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.FETCH_DEVICE_ACTION_TYPES_LISTS_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.FETCH_CONTROL_UI_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.UPDATE_CONTROL_UI_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.EXECUTE_ACTIONS_FAILED, dispatchActionExecutionError);
  yield takeLatest(constants.UPDATE_SITE_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.REMOVE_TASK_FAILED, dispatchAddGeneralError);
  yield takeLatest(
    constants.PERMISSIONS_TOGGLE_USER_HEADER_PERMISSION_FAILED,
    dispatchAddGeneralError,
  );
  yield takeLatest(
    constants.PERMISSIONS_TOGGLE_CONTEXT_USER_HEADER_PERMISSION_FAILED,
    dispatchAddGeneralError,
  );
  yield takeLatest(constants.ADD_AUTOMATED_OPERATION_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.UPDATE_AUTOMATED_OPERATION_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.REMOVE_AUTOMATED_OPERATION_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.ADD_SCHEDULE_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.UPDATE_SCHEDULE_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.REMOVE_SCHEDULE_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.DISABLE_TWO_FACTOR_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.REFRESH_OTP_SECRET_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.FETCH_FILES_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.FETCH_AUTOMATED_OPERATIONS_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.FETCH_SCHEDULES_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.SUBMIT_ONE_TIME_PASSWORD_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.PERFORM_SYNC_FIRMWARE_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.RESET_PASSWORD_SEND_TOKEN_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.FETCH_PROJECT_SUITE_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.LINK_FILE_WITH_DEVICE_FIRMWARE_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.TRANSFER_DEVICE_FIRMWARE_FAILED, dispatchAddGeneralError);

  yield takeLatest(constants.REMOVE_SCHEDULE_PERMISSION_FAILED, dispatchPermissionError);

  yield takeLatest(constants.INVALID_INPUT_NOTIFICATION, dispatchUserError);

  yield takeLatest(constants.TRIGGER_TASK_FAILED, dispatchTaskError);
  yield takeLatest(constants.UPDATE_TASK_FAILED, dispatchUserError);

  yield takeLatest(constants.FETCH_DEVICE_ACTION_TYPES_LISTS_NO_DATA, dispatchUserError);
  yield takeLatest(constants.ADD_ACTION_INCOMPLETE_FORM, dispatchUserError);
  yield takeLatest(constants.SELECTION_REACHED_MAX, dispatchUserError);

  yield takeLatest(constants.TOGGLE_BEACON_SUCCESS, dispatchAddInfoNotification);
  yield takeLatest(constants.FETCH_PATCHES_FAILED, dispatchAddInfoNotification);
  yield takeLatest(constants.FETCH_LOGS_FAILED, dispatchAddInfoNotification);
  yield takeLatest(constants.FETCH_FIXTURE_GROUPS_FAILED, dispatchAddInfoNotification);
  yield takeLatest(constants.FETCH_TIMELINES_FAILED, dispatchAddInfoNotification);
  yield takeLatest(constants.FETCH_STATIC_SCENES_FAILED, dispatchAddInfoNotification);
  yield takeLatest(constants.FETCH_TRIGGERS_FAILED, dispatchAddInfoNotification);
  yield takeLatest(constants.FETCH_IO_MODULES_FAILED, dispatchAddInfoNotification);
  yield takeLatest(constants.UNABLE_TO_FETCH_LOCATION, dispatchAddInfoNotification);
  yield takeLatest(constants.SCHEDULES_AF_NOTIFICATION, dispatchAddInfoNotification);
  yield takeLatest(constants.REMOVE_TASK_FAILED_LOCK_VERSION, dispatchAddInfoNotification);

  yield takeLatest(constants.DELETE_PROJECT_SUITE_FAILED, dispatchAddGeneralError);

  yield takeLatest(constants.GRANT_MULTIPLE_PERMISSIONS_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.REVOKE_MULTIPLE_PERMISSIONS_FAILED, dispatchAddGeneralError);
  yield takeLatest(constants.PASTE_MULTIPLE_PERMISSIONS_FAILED, dispatchAddGeneralError);

  yield takeLatest(constants.RENEW_SUSPENDED_SITE_FAILED, dispatchUserError);
  yield takeLatest(constants.LOGOUT_ERROR, dispatchUserError);
}

export default notificationsSaga;
