import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import { PROJECT_SUITES_FIELD } from 'helpers/selectors';

export const deleteProjectSuite = projectSuite => ({
  type: constants.DELETE_PROJECT_SUITE_REQUEST,
  payload: {
    projectSuite,
    endpoint: endpoints.PROJECT_SUITE(projectSuite.id),
  },
});

export const deleteProjectSuiteSuccess = response => ({
  type: constants.DELETE_PROJECT_SUITE_SUCCESS,
  payload: {
    response,
    message: messages.PROJECT_SUITE_DELETED(response.projectSuite.name),
    resourceType: PROJECT_SUITES_FIELD,
  },
});

export const deleteProjectSuiteFailure = error => ({
  type: constants.DELETE_PROJECT_SUITE_FAILED,
  payload: { error },
});

export default deleteProjectSuite;
