import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { withProjectSuitePermissions } from 'gateway';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { PROJECTS_FIELD, PROJECT_SUITES_FIELD } from 'helpers/selectors';
import {
  actionCreators as projectSuitesActionCreators,
  selectors as projectSuitesSelectors,
} from 'pages/ProjectSuites/utilities';
import ProjectSuiteTasksTab from './ProjectSuiteTasksTab';

export const mapStateToProps = (state, { match }) => {
  const { id: projectSuiteId } = match.params;
  const projectsList = projectSuitesSelectors.projectsListSelector(state)(projectSuiteId) || [];
  const options = projectsList.map(({ id, name }) => ({ id, text: name, value: id }));
  const projectIdsList = projectsList.map(({ id }) => id);
  const allProjects = { id: 'allSites', text: 'Everything', value: 'allSites' };
  const onlySuperSiteTasks = { id: 'superSiteTasks', text: 'Only Multi-site tasks', value: 'superSiteTasks' };
  if (!isEmpty(options)) {
    options.unshift(allProjects);
  }
  options.unshift(onlySuperSiteTasks);

  return {
    projectSuiteId,
    options,
    projectIdsList,
  };
};

export default compose(
  withRouter,
  withProjectSuitePermissions,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    ({ projectSuiteId }) => projectSuitesActionCreators.fetchProjectSuiteProjectsFilter(
      projectSuiteId, {
        fields: {
          [PROJECTS_FIELD]: [
            'name',
          ],
        },
        sort: 'name',
      }),
  ),
  chainedLoaderWithSaga(
    ({ projectSuiteId }) => projectSuitesActionCreators.fetchProjectSuite(projectSuiteId, {
      fields: {
        [PROJECT_SUITES_FIELD]: [
          'name',
        ],
      },
    }),
  ),
)(ProjectSuiteTasksTab);
