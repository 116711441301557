import React from 'react';
import { BsInbox } from 'react-icons/bs';
import { Container } from './EmptyMessage.style';

function EmptyMessage() {
  return (
    <Container>
      <BsInbox size="2.5em" />
      No notifications to display
    </Container>
  );
}

export default EmptyMessage;
