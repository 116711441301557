import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Message, Divider, FormGroup } from 'semantic-ui-react';

import * as validators from 'components/form/formValidators';
import * as normalizer from 'components/form/formNormalizers';
import TextField from 'components/form/TextField/TextField';
import ModalForm from 'components/form/ModalForm/ModalForm';

const AddDeviceModalForm = props => {
  const { submitting } = props;

  return (
    <ModalForm
      header="Add device"
      trigger="Add device"
      {...props}
    >
      <Message size="tiny" info>
        <p>
          Please note that adding new devices could move this Site into a different pricing plan.
        </p>
      </Message>
      <Divider clearing />
      <FormGroup inline>
        <Field
          name="logicalDeviceName"
          component={TextField}
          type="text"
          loading={submitting}
          validate={validators.validDisplayName}
          normalize={normalizer.trimLeadingWhitespace}
          label="Name"
        />
      </FormGroup>
    </ModalForm>
  );
};

AddDeviceModalForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default AddDeviceModalForm;
