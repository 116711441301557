import React from 'react';
import PropTypes from 'prop-types';

import ThemedProgress from 'components/elements/ThemedProgress/ThemedProgress.style';

const DataTableProgress = ({ field, item }) => (
  <ThemedProgress percent={item[field]} className="micro" />
);

DataTableProgress.defaultProps = {
  field: null,
  item: {},
};

DataTableProgress.propTypes = {
  field: PropTypes.string,
  item: PropTypes.object,
};

export default DataTableProgress;
