import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import {
  SCHEDULES_FIELD,
} from 'helpers/selectors';


export const fetchSchedules = (automatedOperationId, urlParams) => ({
  type: constants.FETCH_SCHEDULES_REQUEST,
  payload: {
    endpoint: endpoints.AUTOMATED_OPERATION_SCHEDULES(automatedOperationId),
    resourceType: SCHEDULES_FIELD,
    automatedOperationId,
    urlParams,
  },
});

export const fetchSchedulesSuccess = response => ({
  type: constants.FETCH_SCHEDULES_SUCCESS,
  payload: {
    response,
    resourceType: SCHEDULES_FIELD,
  },
});

export const fetchSchedulesFailure = error => ({
  type: constants.FETCH_SCHEDULES_FAILED,
  payload: { error },
});
