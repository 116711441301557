import styled from 'styled-components';
import { ChromePicker } from 'react-color';
import { rgba } from 'polished';

export const StyledColorSelectFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledColorBlock = styled.div`
  position: relative;
  top: 5px;
  height: 20px;
  width: 100px;
  border: 1px solid black;
  background-color: ${({ displayColor: { r, g, b, a } }) => rgba(r, g, b, a)};
`;

export const StyledChromePicker = styled(ChromePicker)`
  margin-top: 15px;
`;
