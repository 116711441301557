import socketConstants from 'data/websockets/constants';

const logsAreLoadingSelector = state => {
  const webSocketsKey = `${socketConstants.AUDIT_LOG_CHANNEL}__${socketConstants.AUDIT_LOG}`;
  if (state.websockets[webSocketsKey] && state.websockets[webSocketsKey].loading) {
    return state.websockets[webSocketsKey].loading;
  }

  return false;
};

export default logsAreLoadingSelector;
