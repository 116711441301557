import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { TIMELINES_FIELD } from 'helpers/selectors';
import onMountUnmount from 'helpers/onMountUnmount';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { addPagination } from 'helpers/paginationHelpers';
import websocketActionCreators from 'data/websockets/websocketsActionCreators';
import * as timelinesSectionActionCreators from 'application/tenant/console/device/utilities/actionCreators/timelinesSection';
import TimelinesSection from './TimelinesSection';

const mapStateToProps = (state, { resourceId }) => ({
  deviceId: resourceId,
});

export default compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: TIMELINES_FIELD, included: true },
      ({ deviceId }) => timelinesSectionActionCreators.fetchTimelines(deviceId, {
        sort: 'id',
      }),
    ),
  ),
  onMountUnmount(
    ({ deviceId }) => websocketActionCreators.subscribeDeviceData(deviceId, 'timelines'),
    ({ deviceId }) => websocketActionCreators.unsubscribeDeviceData(deviceId, 'timelines'),
  ),
)(TimelinesSection);
