import React, { useState } from 'react';
import { Input, Header } from 'semantic-ui-react';

import { TASKS_FIELD, SELECT_TASKS } from 'helpers/selectors';
import FormField from 'components/form/FormField/FormField';
import { TaskToken, TaskRow } from './components';
import { StyledTasksTableFieldWrapper, StyledDataTable } from './MultipleTasksTableField.style';

const taskTableFields = [
  { text: 'Name', field: 'name' },
];

const MultipleTasksTableField = ({
  selectedTasks,
  input,
  ...rest
}) => {
  const tasksForState = selectedTasks || [];
  const [stateTasks, setStateTasks] = useState(tasksForState);
  const { onChange } = input;

  const handleSelectTask = ({ id }) => {
    const tasks = [...stateTasks, id];

    setStateTasks(tasks);
    onChange(tasks);
  };

  const removeTask = (e, id) => {
    const indexToRemove = stateTasks.indexOf(id);

    stateTasks.splice(indexToRemove, 1);
    setStateTasks(stateTasks);
    onChange(stateTasks);
  };

  return (
    <FormField {...rest} input={input}>
      <StyledTasksTableFieldWrapper>
        <Header>
          {stateTasks.map(item => (
            <TaskToken
              key={item}
              value={item}
              id={item}
              removeToken={removeTask}
            />
          ))}
        </Header>
        <StyledDataTable
          header="Select Tasks"
          labels={taskTableFields}
          customTableRow={TaskRow}
          pagination={{
            type: TASKS_FIELD,
            included: true,
            componentName: SELECT_TASKS,
          }}
          onSelectTask={handleSelectTask}
          selectedTasks={stateTasks}
        />
      </StyledTasksTableFieldWrapper>
    </FormField>
  );
};

MultipleTasksTableField.propTypes = {
  ...Input.propTypes,
};

export default MultipleTasksTableField;
