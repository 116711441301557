import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';

import SelectField from 'components/form/SelectField/SelectField';

const formSubscribeOpts = {
  submitting: true,
  values: true,
};

class SelectFieldForm extends Component {

  static handleChange(handleSubmit, currentValue) {
    return value => {
      if (value === currentValue) return;

      handleSubmit();
    };
  }

  constructor() {
    super();

    this.renderField = this.renderField.bind(this);
  }

  renderField({ handleSubmit, submitting, values }) {
    const { className, name, onChange, loading, disabled, ...rest } = this.props;
    const currentValue = values[name];

    return (
      <div className={className}>
        <Field
          {...rest}
          name={name}
          component={SelectField}
          disabled={submitting || disabled}
          loading={submitting || loading}
          onChangeCallback={SelectFieldForm.handleChange(handleSubmit, currentValue)}
        />
      </div>
    );
  }

  render() {
    const { name, initialValue, onChange } = this.props;
    const initial = { [name]: initialValue };

    return (
      <Form
        subscription={formSubscribeOpts}
        onSubmit={onChange}
        initialValues={initial}
        render={this.renderField}
      />
    );
  }

}

SelectFieldForm.defaultProps = {
  initialValue: undefined,
};

SelectFieldForm.propTypes = {
  initialValue: PropTypes.any,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SelectFieldForm;
