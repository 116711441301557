import { connect } from 'react-redux';

import { socketDeviceDataSubscriptionStatus } from 'helpers/selectors';
import websocketActionCreators from 'data/websockets/websocketsActionCreators';

import OutputRefreshInterface from './OutputRefreshInterface';

const mapStateToProps = (state, { deviceId, dataId, meta }) => {
  const status = socketDeviceDataSubscriptionStatus(deviceId, 'dmx_output')(state) || {};

  return {
    deviceId,
    dataId,
    meta,
    isSubscribed: !!status.subscribed,
    isSubscribing: !!status.subscribing,
    isRefreshing: !!status.refreshing,
  };
};

const mapDispatchToProps = {
  onRefresh: websocketActionCreators.refreshDeviceData,
  onSubscribe: websocketActionCreators.subscribeDeviceData,
  onUnsubscribe: websocketActionCreators.unsubscribeDeviceData,
};

export default connect(mapStateToProps, mapDispatchToProps)(OutputRefreshInterface);
