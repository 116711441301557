import isNil from 'lodash/isNil';

const computeDropdownOptions = arr => {
  if (!isNil(arr) && Array.isArray(arr)) {
    return arr.map(({ id, name }) => (
      { key: id, text: name, value: id, id }),
    );
  }

  return [];
};

export default computeDropdownOptions;
