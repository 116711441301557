import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

const RenderDispatchedIcon = ({ dispatched }) => {
  if (dispatched) {
    return <Icon name="check" />;
  }

  return <Icon name="remove" />;
};

RenderDispatchedIcon.defaultProps = {
  dispatched: false,
};

RenderDispatchedIcon.propTypes = {
  dispatched: PropTypes.bool,
};

export default RenderDispatchedIcon;
