import { call, put, takeLatest, takeLeading } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import ProjectModel from 'models/ProjectModel';
import UserModel from 'models/UserModel';
import { getPath, postPath, deletePath } from 'data/api/requests';
import { processCall, actionList } from 'helpers/sagaHelper';
import { actionCreators as applicationUserActionCreators } from 'application/utilities/user';
import {
  fetchReverseGeocoding,
  updateSite,
  updateSiteLocationProperties,
} from 'application/tenant/console/site/utilities/saga';
import { fetchOccurrences } from 'application/tenant/console/site/components/structural/SiteSchedulingTab/utilities/saga';
import projectActionCreators from './projectActionCreators';

export function* fetchProjects(action) {
  const { endpoint, urlParams, ...rest } = action.payload;
  const { response, error } = yield call(getPath, endpoint, { urlParams });

  const params = {
    response,
    error,
    successDisp: projectActionCreators.fetchProjectsSuccess,
    failDisp: projectActionCreators.fetchProjectsFailure,
    ...rest,
  };
  yield call(processCall, params);
}

export function* fetchProject(action) {
  const { endpoint, projectId, urlParams, ...rest } = action.payload;
  const { response, error } = yield call(getPath, endpoint, { urlParams });

  const params = {
    response,
    error,
    successDisp: projectActionCreators.fetchProjectSuccess,
    failDisp: projectActionCreators.fetchProjectFailure,
    endpoint,
    id: projectId,
    ...rest,
  };
  yield call(processCall, params);
}

export function* addNewProject(action) {
  const {
    endpoint, params: {
      values, rejectForm, resolveForm,
    },
  } = action.payload;
  const body = ProjectModel.requestBody(values);
  const { response, error } = yield call(postPath, endpoint, body);

  const params = {
    response,
    error,
    resolveForm,
    rejectForm,
    successDisp: projectActionCreators.addProjectSuccess,
    failDisp: projectActionCreators.addProjectFailure,
  };
  yield call(processCall, params);

  if (response) { yield put(applicationUserActionCreators.fetchCurrentUser()); }

}

export function* deleteProject(action) {
  const { endpoint, project } = action.payload;
  let { response, error } = yield call(deletePath, endpoint);
  response = response ? { project } : undefined;
  error = error ? new Error(`Unable to delete project ${project.name}`) : undefined;

  const params = {
    response,
    error,
    successDisp: projectActionCreators.deleteProjectSuccess,
    failDisp: projectActionCreators.deleteProjectFailure,
  };
  yield call(processCall, params);
  if (response) { yield put(applicationUserActionCreators.fetchCurrentUser()); }
}

export function* removeUserFromProject(action) {
  const { endpoint, userId, projectId } = action.payload;

  const body = UserModel.requestBody().setId(userId);
  const { response: apiResponse, error } = yield call(deletePath, endpoint, {
    data: [body.data],
  });
  const response = apiResponse ? { projectId, userId } : undefined;

  const params = {
    response,
    error,
    successDisp: projectActionCreators.removeUserSuccess,
    failDisp: projectActionCreators.removeUserFailure,
  };
  yield call(processCall, params);
}

function* projectSaga() {
  yield takeLatest(constants.FETCH_PROJECTS_REQUEST, fetchProjects);
  yield takeLatest(constants.FETCH_PROJECT_REQUEST, fetchProject);
  yield takeLatest(constants.ADD_PROJECT_REQUEST, addNewProject);
  yield takeLatest(constants.DELETE_PROJECT_REQUEST, deleteProject);
  yield takeLatest(constants.REMOVE_USER_FROM_PROJECT, removeUserFromProject);
  yield takeLatest(constants.FETCH_REVERSE_GEOCODING_REQUEST, fetchReverseGeocoding);
  yield takeLatest(constants.UPDATE_SITE_REQUEST, updateSite);
  yield takeLeading(
    actionList(
      constants.FETCH_PROJECT_SUCCESS,
      constants.UPDATE_SITE_SUCCESS,
    ),
    updateSiteLocationProperties,
  );
  yield takeLatest(constants.FETCH_AUTOMATED_OPERATION_OCCURRENCES, fetchOccurrences);
}

export default projectSaga;
