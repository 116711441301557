import React from 'react';
import PropTypes from 'prop-types';

import TableMenu from 'components/DataTable/TableMenu.style';

import EditTask from './EditTask/EditTaskContainer';
import { DeleteTaskActionLink } from './DeleteTask';
import { RunTaskActionLink } from './RunTask';

const TaskActionLinks = ({ item, ...rest }) => {
  const actionLinks = [DeleteTaskActionLink, RunTaskActionLink, EditTask];

  return (
    <TableMenu.Item>
      {actionLinks.map((ActionLink, index) => {
        const key = `${item.id}-${index}`;
        return (
          <TableMenu.Item key={key}>
            <ActionLink item={item} {...rest} />
          </TableMenu.Item>
        );
      })}
    </TableMenu.Item>
  );
};

TaskActionLinks.defaultProps = {
  item: null,
};

TaskActionLinks.propTypes = {
  item: PropTypes.object,
};

export default TaskActionLinks;
