import { takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import fetchDeviceGroups from './fetchDeviceGroups';

function* saga() {
  yield takeLatest(constants.FETCH_DEVICE_GROUPS_REQUEST, fetchDeviceGroups);
}

export {
  saga,
};

export default saga;
