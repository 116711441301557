import includes from 'lodash/includes';

import userPermissionMatchesName from './userPermissionMatchesName';

const createPermissionRequirementsMatcher = requirements => {
  const {
    name: requiredName,
    resource: requiredResourceType,
    permissionsResourceType: detectedResourceType,
    resourceId: requiredResourceId,
    contextOnly,
    contexts = [],
  } = requirements || {};

  return function permissionsRequirementsMatcher(permissionAttributes) {
    const {
      resourceId,
      resourceType,
      permissions,
    } = permissionAttributes || {};

    const isContextPermission = includes(contexts, resourceId);

    if (!isContextPermission && contextOnly) {
      return false;
    }

    const passesContextOnly = (
      isContextPermission
      && contextOnly
      && (!requiredName && !requiredResourceType)
      && permissions.length > 0
    );

    if (passesContextOnly) {
      return true;
    }

    const resourceIdMatches = resourceId === requiredResourceId;

    if (!isContextPermission && requiredResourceId && !resourceIdMatches) {
      return false;
    }

    const resourceTypeToMatch = requiredResourceType || detectedResourceType;
    const resourceTypeMatches = resourceType === resourceTypeToMatch;

    if (resourceTypeToMatch && !resourceTypeMatches) {
      return false;
    }

    return permissions.some(userPermissionMatchesName(requiredName));
  };
};

export default createPermissionRequirementsMatcher;
