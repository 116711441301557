import { call, put, select, takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import { getPath } from 'data/api/requests';
import { logicalDeviceSelector } from 'helpers/selectors';
import { processCall } from 'helpers/sagaHelper';
import { prepareResponseData } from 'helpers/paginationHelpersClient';
import filesActionCreators from './filesActionCreators';

export function* fetchFiles({ payload }) {
  const { deviceId, endpoint, urlParams, ...rest } = payload;
  const { response, error } = yield call(getPath, endpoint, { urlParams });

  const params = {
    response,
    error,
    successDisp: filesActionCreators.fetchFilesSuccess,
    failDisp: filesActionCreators.fetchFilesFailure,
    ...rest,
  };
  yield call(processCall, params);

  if (response) {
    const device = (yield select(logicalDeviceSelector))(deviceId);
    const enhancedResponse = yield call(prepareResponseData, response, urlParams, device);

    yield put(filesActionCreators.fetchFilesSuccessPaginated(enhancedResponse));
  }
}

function* filesSaga() {
  yield takeLatest(constants.FETCH_FILES_REQUEST, fetchFiles);
}

export default filesSaga;
