import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import selectors from 'pages/ProjectSuites/utilities/selectors';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import actionCreators from 'pages/ProjectSuites/utilities/actionCreators';
import checkMultiSites from './checkMultiSites';

export const mapStateToProps = (state, ownProps) => {
  const { resourceId } = ownProps;
  const assignedToMultiSites = selectors.projectHasParentsSelector(state)(resourceId);

  return {
    assignedToMultiSites,
    projectId: resourceId,
  };
};

export default () => compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    ({ projectId }) => actionCreators.fetchProjectProjectSuiteParents(projectId),
  ),
  checkMultiSites(),
);
