import styled from 'styled-components';
import { Dimmer } from 'semantic-ui-react';
import { rgba } from 'polished';

import colors from 'themes/colors';

export const StyledDayViewWrapper = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center !important;
`;

export const StyledDimmer = styled(Dimmer)`
  &.ui.dimmer {
    background-color: ${rgba(colors.black, 0.6)};
    z-index: 1000;
  }
`;
