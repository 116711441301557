import { connect } from 'react-redux';
import { change } from 'redux-form';

import formNames from 'components/form/formNames';
import { tasksActionCreators } from '../../../../../../../../../../../../../../../../../../utilities';
import SelectLogicalDevice from './SelectLogicalDevice';
import { getLogicalDevices } from './utilities';

export const mapStateToProps = (state, { superSites, siteId }) => {
  const logicalDevicesList = getLogicalDevices(state, siteId, superSites);

  return {
    logicalDevicesList,
  };
};

export const mapDispatchToProps = dispatch => ({
  getActionTypes: value => dispatch(tasksActionCreators.fetchDeviceActionTypesLists(value)),
  resetTypeId: () => {
    dispatch(change(formNames.addNewAction, 'typeId', null));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectLogicalDevice);
