import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import { ChildPanelHeader } from 'components/core/Panel/Panel';

import { TasksActionsNavigation, SelectedActions } from '..';
import { getSelectedActionsDescription } from '../../utilities';
import { HeaderContainer } from './DesktopView.style';

function DesktopView({
  selectedDevice,
  selectedActions,
  selectedTasks,
  visibleActions,
  resourceType,
  onActionSelected,
  onActionRemove,
  onActionToggle,
}) {
  return (
    <Grid.Row>
      <Grid.Column width={6}>
        <HeaderContainer>
          <ChildPanelHeader
            title="Tasks & Actions"
            description="Navigate and select Tasks/Actions"
          />
        </HeaderContainer>
        <TasksActionsNavigation
          resourceType={resourceType}
          selectedDevice={selectedDevice}
          onActionSelected={onActionSelected}
        />
      </Grid.Column>
      <Grid.Column width={10}>
        <ChildPanelHeader
          title="Selected Tasks & Actions"
          description={getSelectedActionsDescription(selectedActions, selectedTasks)}
        />
        <SelectedActions
          visibleActions={visibleActions}
          selectedActions={selectedActions}
          selectedTasks={selectedTasks}
          onActionRemove={onActionRemove}
          onActionToggle={onActionToggle}
        />
      </Grid.Column>
    </Grid.Row>
  );
}

DesktopView.defaultProps = {
  resourceType: 'project',
};

DesktopView.propTypes = {
  selectedDevice: PropTypes.object.isRequired,
  selectedActions: PropTypes.array.isRequired,
  selectedTasks: PropTypes.array.isRequired,
  visibleActions: PropTypes.object.isRequired,
  onActionSelected: PropTypes.func.isRequired,
  onActionRemove: PropTypes.func.isRequired,
  onActionToggle: PropTypes.func.isRequired,
  resourceType: PropTypes.oneOf([
    'project',
    'projectSuite',
  ]),
};

export default DesktopView;
