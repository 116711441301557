import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const fetchAutomatedOperation = (automatedOperationId, urlParams) => ({
  type: constants.FETCH_AUTOMATED_OPERATION_REQUEST,
  payload: {
    endpoint: endpoints.AUTOMATED_OPERATION(automatedOperationId),
    urlParams,
  },
});

export const fetchAutomatedOperationSuccess = response => ({
  type: constants.FETCH_AUTOMATED_OPERATION_SUCCESS,
  payload: { response },
});

export const fetchAutomatedOperationFailure = error => ({
  type: constants.FETCH_AUTOMATED_OPERATION_FAILED,
  payload: { error },
});
