import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { siteActionsListSelector } from 'helpers/selectors';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { tasksActionCreators } from 'application/tenant/utilities';
import { extractActions } from 'application/tenant/console/site/components/structural/SiteSchedulingTab/components/AutomatedOperations/components/AddAutomatedOperation/components/ActionsStep/components/AvailableActions/utilities';
import RenderActionTypes from './RenderActionTypes';

export const mapStateToProps = (state, { match }) => {
  const { id: projectId } = match.params;
  const getSiteActions = siteActionsListSelector(state)(projectId);
  const siteActionList = extractActions(getSiteActions);

  const options = [
    { key: 'device', text: 'Device', value: 'device' },
  ];

  if (Array.isArray(siteActionList) && siteActionList.length > 0) {
    options.push({ key: 'site', text: 'Site', value: 'site' });
  }

  return {
    options,
    projectId,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    ({ projectId }) => tasksActionCreators.fetchSiteActionTypesList(projectId),
  ),
)(RenderActionTypes);
