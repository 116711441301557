import React from 'react';
import PropTypes from 'prop-types';

const Layers = ({ children }) => <div>{children}</div>;

Layers.defaultProps = {
  children: [],
};

Layers.propTypes = {
  children: PropTypes.array,
};

export default Layers;
