import React from 'react';

import { TriggersCondSection } from './components';

const labels = [
  { text: 'Number', field: 'id', width: 2 },
  { text: 'Name', field: 'name', width: 4 },
  { text: 'Description', field: 'description', width: 8 },
  { text: '', field: '', width: 2 },
];

const PharosTriggersV2 = props => (
  <TriggersCondSection
    {...props}
    labels={labels}
    testConditionsUI
  />
);

export default PharosTriggersV2;
