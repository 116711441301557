import styled, { css } from 'styled-components';
import { Progress } from 'semantic-ui-react';
import get from 'lodash/get';

const ThemedProgress = styled(Progress)`
  &.ui.progress {
    & .bar {
      ${({ theme }) => css`
        background-color: ${get(theme, 'palette.primaryColor')};
      `};
      min-width: auto;
    } 
    
    &, & .bar {
      border-radius: 0;
    }
  
    &:last-child {
      margin-bottom: 0;
    }
    
    &.micro .bar {
      height: 2px;
    }
  }
`;

export default ThemedProgress;
