import { connect } from 'react-redux';

import { convertTime } from '../../../TokenButton/utilities';
import RenderTimesList from './RenderTimesList';

export const mapStateToProps = (state, { selectedTimes }) => {
  const timeList = selectedTimes ? selectedTimes.split(',') : [];
  const selectedTimesList = timeList.map(item => convertTime(item));
  const showSelectedTimes = timeList.length > 0;

  return {
    selectedTimesList,
    showSelectedTimes,
  };
};

export default connect(mapStateToProps)(RenderTimesList);
