import styled, { css } from 'styled-components';

export const StyledTable = styled.table`
  margin-top: 24px !important;
`;

export const StyledTableHeaderColumn = styled.th`
  ${({ permissionNameColumn }) => permissionNameColumn && css`
    max-width: 250px !important;

    span {
      visibility: hidden;  
    }
  `}

  ${({ recipientColumn }) => recipientColumn && css`
    width: 50px !important;
    text-align: center !important;
  `}
`;

export const StyledTableColumn = styled.td`  
  padding: 12px 0px;

  ${({ permissionNameColumn }) => permissionNameColumn && css`
    max-width: 250px !important;
  `}

  ${({ recipientColumn }) => recipientColumn && css`
    width: 50px;
    text-align: center !important;
  `}
`;


export default StyledTable;
