import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  CONTEXTS_FIELD,
  permissionsSelectingContextsSelector,
} from 'helpers/selectors';
import { addPagination } from 'helpers/paginationHelpers';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import permissionsActionCreators from '../../../../../../../utilities/permissions/permissionsActionCreators';
import ContextsTable from './ContextsTable';

export const mapStateToProps = state => ({
  selectingContexts: permissionsSelectingContextsSelector(state),
});

export default compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: CONTEXTS_FIELD },
      permissionsActionCreators.fetchContexts,
    ),
  ),
)(ContextsTable);
