import React from 'react';
import { Grid, Form } from 'semantic-ui-react';

import { StyledRow } from '../../ScheduleReview.style';

function NoneRecurrenceReview() {
  return (
    <Form>
      <Grid>
        <StyledRow noPaddingBottom>
          <Grid.Column computer={16} mobile={16}>
            <Form.Group id="recur-every">
              <Form.Field>
                No Schedule configuration will be created for this Task Scheduler.
              </Form.Field>
            </Form.Group>
          </Grid.Column>
        </StyledRow>
      </Grid>
    </Form>
  );
}

export default NoneRecurrenceReview;
