import React from 'react';
import PropTypes from 'prop-types';

import { validEmail } from 'components/form/formValidators';
import Form from 'components/form/Form/Form';
import { Button, StyledRightFormField } from 'components/atomic';
import { StyledPublicHeader } from '../../../../components';
import { EmailField, PasswordField } from './components';

const loginFormFields = {
  email: 'email',
  password: 'password',
};

const LoginForm = ({ handleSubmit, submitting }) => (
  <Form>
    <StyledPublicHeader as="h2">Sign in</StyledPublicHeader>
    <Form.Group>
      <EmailField
        name={loginFormFields.email}
        loading={submitting}
        validate={validEmail}
      />
    </Form.Group>
    <Form.Group>
      <PasswordField name={loginFormFields.password} loading={submitting} />
    </Form.Group>
    <Form.Group>
      <StyledRightFormField>
        <Button
          primary
          onClick={handleSubmit}
          disabled={submitting}
        >
          Sign in
        </Button>
      </StyledRightFormField>
    </Form.Group>
  </Form>
);

LoginForm.defaultProps = {
  submitting: false,
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

export default LoginForm;
