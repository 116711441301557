import { compose } from 'redux';
import { connect } from 'react-redux';

import automatedOperationsActionCreators from '../../../../../../utilities/actionCreators';
import AddOperation from './AddOperation';

const mapDispatchToProps = {
  onAddOperation: automatedOperationsActionCreators.addOperation,
};

export default compose(
  connect(null, mapDispatchToProps),
)(AddOperation);
