import isEmpty from 'lodash/isEmpty';

import constants from 'dispatcherConst';

const initialState = {
  isLoadingVoucher: false,
  isLoadingPlans: false,
  typedVoucher: '',
  selectedVoucher: null,
  voucherError: null,
  voucherForSiteError: null,
  subscriptionPlans: [],
  generatedVoucher: null,
  selectedTenant: null,
  voucherPermissions: [],
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_TYPED_VOUCHER_REQUEST: {
      const { typedVoucher } = action.payload;

      return { ...state, typedVoucher };
    }
    case constants.FETCH_VOUCHER_DETAILS_REQUEST: {
      return { ...state, isLoadingVoucher: true, voucherError: null };
    }
    case constants.FETCH_VOUCHER_DETAILS_SUCCESS: {
      const { response } = action.payload;
      const { id, attributes } = response.data || {};

      return {
        ...state,
        isLoadingVoucher: false,
        voucherError: null,
        selectedVoucher: {
          id,
          ...attributes,
        },
      };
    }
    case constants.FETCH_VOUCHER_DETAILS_FAILED: {
      const { error } = action.payload.error;
      const [voucherError] = error.unprocessable_entity || [];

      return {
        ...state,
        isLoadingVoucher: false,
        voucherError,
      };
    }
    case constants.CLEAR_SELECTED_VOUCHER: {
      return {
        ...state,
        selectedVoucher: null,
        voucherError: null,
        typedVoucher: '',
      };
    }
    case constants.CLEAR_GENERATED_VOUCHER: {
      return {
        ...state,
        generatedVoucher: null,
      };
    }
    case constants.FETCH_SUBSCRIPTION_PLANS_REQUEST: {
      return {
        ...state,
        isLoadingPlans: true,
      };
    }
    case constants.FETCH_SUBSCRIPTION_PLANS_SUCCESS: {
      const { response } = action.payload;
      const { data } = response;

      return {
        ...state,
        isLoadingPlans: false,
        subscriptionPlans: data,
      };
    }
    case constants.FETCH_SUBSCRIPTION_PLANS_FAILED: {
      return {
        ...state,
        isLoadingPlans: false,
        subscriptionPlans: [],
      };
    }
    case constants.REDEEM_VOUCHER_REQUEST: {
      return {
        ...state,
        isLoadingVoucher: true,
      };
    }
    case constants.REDEEM_VOUCHER_SUCCESS: {
      return {
        ...state,
        isLoadingVoucher: false,
        selectedVoucher: null,
        typedVoucher: '',
      };
    }
    case constants.REDEEM_VOUCHER_FAILED: {
      const { error } = action.payload.error || {};

      let voucherError = null;
      let voucherForSiteError = null;

      if (error.unprocessable_entity) {
        [voucherError] = error.unprocessable_entity || [];
      } else if (error.data && !isEmpty(error.data)) {
        const errors = Object.keys(error.data).map(
          key => `${key} ${error.data[key][0]}`,
        );

        [voucherForSiteError] = errors;
      }

      return {
        ...state,
        isLoadingVoucher: false,
        voucherError,
        voucherForSiteError,
      };
    }
    case constants.GENERATE_VOUCHER_REQUEST: {
      return {
        ...state,
        isLoadingVoucher: true,
      };
    }
    case constants.GENERATE_VOUCHER_SUCCESS: {
      const { data } = action.payload.response;
      const [voucher] = data;

      const { id, attributes } = voucher;

      return {
        ...state,
        isLoadingVoucher: false,
        generatedVoucher: {
          id,
          ...attributes,
        },
      };
    }
    case constants.GENERATE_VOUCHER_FAILED: {
      return {
        ...state,
        isLoadingVoucher: false,
      };
    }
    case constants.SET_SELECTED_TENANT_REQUEST: {
      const { selectedTenant } = action.payload;

      return {
        ...state,
        selectedTenant,
      };
    }

    case constants.CLEAR_SELECTED_TENANT_REQUEST: {
      return {
        ...state,
        selectedTenant: null,
      };
    }

    case constants.LOAD_VOUCHER_PERMISSIONS_SUCCESS: {
      const { response } = action.payload;
      const { data } = response;

      return {
        ...state,
        voucherPermissions: data,
      };
    }
    default:
      return state;
  }
};

export default subscriptionReducer;
