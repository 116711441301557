import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import { paths } from 'navigation';
import SignUp from 'pages/SignUp/SignUpContainer';
import TwoFactor from '../twoFactor';
import ResetPassword from '../resetPassword';
import Login from '../login';

const PublicSwitch = ({ twoFactorRedirect }) => {
  if (twoFactorRedirect) {
    return <TwoFactor />;
  }

  return (
    <Switch>
      <Route exact path={paths.index} component={Login} />
      <Route path={paths.passwordReset} component={ResetPassword} />
      <Route path={paths.signUp} component={SignUp} />
    </Switch>
  );
};

PublicSwitch.propTypes = {
  twoFactorRedirect: PropTypes.bool.isRequired,
};

export default PublicSwitch;
