import React from 'react';
import PropTypes from 'prop-types';

import { StyledDropdownArrow } from './DropdownArrow.style';

const DropdownArrow = ({ open }) => <StyledDropdownArrow name="dropdown" open={open} />;

DropdownArrow.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default DropdownArrow;
