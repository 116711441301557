import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';

import UrlParams from 'helpers/UrlParams';
import ConfirmModal from 'components/elements/ConfirmModal/ConfirmModal';
import { StyledButton } from './RemovePage.style';

const RemovePage = ({
  projectId,
  pageId,
  name,
  disabled,
  onDeletePage,
  onPageChange,
  onClearCopy,
}) => {
  const urlParams = new UrlParams();
  const { number } = urlParams.extractUrlParams({
    number: urlParams.pageParam,
  });
  const [page, setPage] = useState(Number(number || 1) - 1);

  const confirmButton = <Button negative primary={false}>Remove Page</Button>;

  const trigger = (
    <StyledButton icon labelPosition="left" fluid disabled={disabled}>
      <Icon name="delete" />
      Remove page
    </StyledButton>
  );

  const onConfirm = () => new Promise((resolveForm, rejectForm) => {
    onDeletePage({
      pageId,
      projectId,
      resolveForm,
      rejectForm,
    });
  }).then(() => {
    onClearCopy();
    onPageChange(null, { activePage: 1 });
    onPageChange(null, { activePage: page || 1 });
  });

  useEffect(() => {
    setPage(pageId);
  }, [pageId]);

  return (
    <ConfirmModal
      trigger={trigger}
      cancelButton="Cancel"
      confirmButton={confirmButton}
      content={(
        <div>
          <p>
            Are you sure you want to remove page <strong>{name}</strong>?
            This action cannot be undone.
          </p>
        </div>
      )}
      header="Confirm page removal"
      onConfirm={onConfirm}
    />
  );
};

RemovePage.defaultProps = {
  name: null,
  disabled: false,
  pageId: 0,
  projectId: null,
};

RemovePage.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  pageId: PropTypes.number,
  projectId: PropTypes.string,
  onDeletePage: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onClearCopy: PropTypes.func.isRequired,
};

export default RemovePage;
