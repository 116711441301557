import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { astroText } from '../../constants';

import { StyledTokenButton } from '../AddSchedule/AddScheduleModalForm/AddScheduleModalForm.style';

const TokenButton = ({ value, removeToken, type }) => {
  const handleClick = e => removeToken(e, value, type);
  const handlePreventClick = e => e.preventDefault();
  const valueToNumber = parseInt(value, 10);
  const addSign = valueToNumber > 0 ? `+${value}` : value;
  const formatValue = valueToNumber === 0 ? '' : addSign;
  const textToDisplay = valueToNumber === 0 ? astroText[type] : '';

  return (
    <StyledTokenButton
      icon
      compact
      labelPosition="right"
      onClick={handlePreventClick}
    >
      {`${textToDisplay} ${formatValue}`}
      <Icon
        name="delete"
        onClick={handleClick}
      />
    </StyledTokenButton>
  );
};

TokenButton.defaultProps = {
  type: '',
};

TokenButton.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  removeToken: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default TokenButton;
