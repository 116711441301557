import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { singleProjectSelector } from 'helpers/selectors';
import siteActionCreators from '../../../../utilities/actionCreators';
import EnableConstructionMode from './EnableConstructionMode';

export const mapStateToProps = (state, { match }) => {
  const { id: siteId } = match.params;
  const { isConstruction, isDemo } = singleProjectSelector(state, siteId);

  return {
    siteId,
    isConstruction,
    isDemo,
  };
};

const mapDispatchToProps = {
  onUpdateSiteConstruction: siteActionCreators.updateSiteConstruction,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(EnableConstructionMode);
