export default class SandboxCreator {

  constructor(sixeyeFirmwareVersion, tabs) {
    this.model = 'Sandbox Device';
    this.sixeyeFirmwareVersion = sixeyeFirmwareVersion;
    this.manufacturerSDK = 'example_manufacturer';
    this.tabs = tabs;
  }

}
