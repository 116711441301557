import React from 'react';
import PropTypes from 'prop-types';
import isNull from 'lodash/isNull';
import { Message, Icon } from 'semantic-ui-react';

const TwoFactorInfo = ({ enabled, disabling, submitting, failed }) => {
  if (isNull(enabled)) {
    return null;
  }

  if (disabling || submitting) {
    const icon = <Icon loading name="sync alternate" />;

    return (
      <Message
        icon={icon}
        header="Updating"
      />
    );
  }

  if (failed) {
    return (
      <Message
        negative
        icon="times"
        header="Setup failed"
        content="Complete the setup by correcting the errors below."
      />
    );
  }

  if (enabled) {
    return (
      <Message
        positive
        icon="check"
        header="Setup complete"
        content="You can still refresh your secret key below."
      />
    );
  }

  return (
    <Message
      negative
      icon="times"
      header="Not enabled"
      content="You can enable Two Factor Authentication using the form below."
    />
  );
};

TwoFactorInfo.defaultProps = {
  enabled: null,
};

TwoFactorInfo.propTypes = {
  enabled: PropTypes.bool,
  disabling: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  failed: PropTypes.bool.isRequired,
};

export default TwoFactorInfo;
