import { PROJECTS_FIELD, LOGICAL_DEVICES_FIELD } from 'helpers/selectors';

const resourceFromMatch = ({ params }) => {
  const { id, deviceId } = params;

  if (id) {
    return {
      resourceId: id,
      resourceType: PROJECTS_FIELD,
      permissionsResourceType: 'project',
    };
  }

  if (deviceId) {
    return {
      resourceId: deviceId,
      resourceType: LOGICAL_DEVICES_FIELD,
      permissionsResourceType: 'logical_device',
    };
  }

  return {};
};

export default resourceFromMatch;
