import { createSelector } from 'reselect';
import build from 'redux-object';

import {
  options,
  CONTROL_UI_FIELD,
  dataSelector,
} from 'helpers/selectors';

const controlUISelector = createSelector(
  dataSelector,
  data => build(data, CONTROL_UI_FIELD, null, options),
);

export default controlUISelector;
