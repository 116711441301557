import { takeLatest, takeEvery } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import fetchControlUI from './fetchControlUI';
import loadPageBackgroundImage from './loadPageBackgroundImage';
import addPage from './addPage';
import renamePage from './renamePage';
import setPageBackgroundImage from './setPageBackgroundImage';
import setPageNameColor from './setPageNameColor';
import deletePage from './deletePage';
import updateButton from './updateButton';
import updateControlUI from './updateControlUI';
import duplicatePage from './duplicatePage';
import removePageBackgroundImage from './removePageBackgroundImage';
import setPageAllowedUserIds from './setPageAllowedUserIds';
import fetchUsers from './fetchUsers';
import updateOverrideControls from './updateOverrideControls';

function* saga() {
  yield takeLatest(constants.FETCH_CONTROL_UI_REQUEST, fetchControlUI);
  yield takeEvery(constants.LOAD_BACKGROUND_IMAGE_CONTROL_UI_PAGE, loadPageBackgroundImage);
  yield takeLatest(constants.ADD_CONTROL_UI_PAGE, addPage);
  yield takeLatest(constants.RENAME_CONTROL_UI_PAGE, renamePage);
  yield takeLatest(constants.SET_BACKGROUND_CONTROL_UI_PAGE, setPageBackgroundImage);
  yield takeLatest(constants.SET_NAME_COLOR_CONTROL_UI_PAGE, setPageNameColor);
  yield takeLatest(constants.DELETE_CONTROL_UI_PAGE, deletePage);
  yield takeLatest(constants.UPDATE_CONTROL_UI_BUTTON, updateButton);
  yield takeLatest(constants.UPDATE_CONTROL_UI_REQUEST, updateControlUI);
  yield takeLatest(constants.DUPLICATE_CONTROL_PANEL_PAGE, duplicatePage);
  yield takeLatest(constants.REMOVE_BACKGROUND_CONTROL_UI_PAGE, removePageBackgroundImage);
  yield takeLatest(constants.CONTROL_UI_FETCH_USERS, fetchUsers);
  yield takeLatest(constants.SET_PAGE_ALLOWED_USER_IDS, setPageAllowedUserIds);
  yield takeLatest(constants.UPDATE_CONTROL_UI_OVERRIDE_CONTROLS, updateOverrideControls);
}

export default saga;
