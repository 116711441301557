import { takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import resetChangePasswordForm from './resetChangePasswordForm';

function* saga() {
  yield takeLatest(constants.CHANGE_PASSWORD_REQUEST_SUCCESS, resetChangePasswordForm);
}

export default saga;
