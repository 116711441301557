import { connect } from 'react-redux';

import performActionActionCreators from 'pages/Device/performActionActionCreators';
import TriggerTableRow from './TriggerTableRow';

const mapDispatchToProps = {
  onTrigger: performActionActionCreators.performTriggers,
};

export default connect(null, mapDispatchToProps)(TriggerTableRow);
