import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';

import * as actionCreators from 'pages/ProjectSuite/ProjectSuiteProjectsTab/components/MultiSiteMapView/utilities/actionCreators';
import MapWrapper from './MapWrapper';
import { selectors } from '../../utilities';

export const mapStateToProps = (state, { match }) => {
  const { id: projectSuiteId } = match.params;
  const isLoading = selectors.isMapDataLoadingSelector(state);
  const isProjectSuite = true;

  return {
    projectSuiteId,
    isLoading,
    isProjectSuite,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoaderWithSaga(({ projectSuiteId }) => actionCreators.fetchChildProjectsForMap(
    projectSuiteId,
    {
      fields: {
        projects: [
          'name',
          'longitude',
          'latitude',
          'onlineDevices',
          'offlineDevices',
          'unassociatedDevices',
          'devicesForUpgrade',
        ],
      },
    },
  )),
)(MapWrapper);
