import React from 'react';
import PropTypes from 'prop-types';

import { StyledSiteMapMarker, StyledIconImage } from './SiteMapMarker.style';

const SiteMapMarker = ({ innerRef }) => (
  <StyledSiteMapMarker ref={innerRef}>
    <StyledIconImage />
  </StyledSiteMapMarker>
);

SiteMapMarker.propTypes = {
  innerRef: PropTypes.object.isRequired,
};

export default SiteMapMarker;
