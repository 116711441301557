import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getDisplayName } from 'helpers/sharedMethods';
import { simpleValueTypes } from 'data/types';

const withPrevious = WrappedComponent => {
  class WithPrevious extends Component {

    static displayName = `WithPrevious(${getDisplayName(WrappedComponent)})`;

    static defaultProps = {
      value: null,
      lastUpdate: null,
    };

    static propTypes = {
      value: PropTypes.oneOfType([
        ...simpleValueTypes,
        PropTypes.element,
      ]),
      lastUpdate: PropTypes.number,
    };

    constructor() {
      super();

      this.state = {
        savedValue: null,
        previousValue: null,
        savedLastUpdate: null,
      };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      const { savedValue, savedLastUpdate } = prevState;
      const { value, lastUpdate } = nextProps;

      if (value === savedValue) return null;
      if (lastUpdate === savedLastUpdate) return null;

      return {
        savedValue: value,
        previousValue: savedValue,
        savedLastUpdate: lastUpdate,
      };
    }

    render() {
      const { previousValue } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          previousValue={previousValue}
        />
      );
    }

  }

  return WithPrevious;
};

export default withPrevious;
