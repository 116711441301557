import { compose } from 'redux';
import { connect } from 'react-redux';

import { allActionsTypeSelector } from 'helpers/selectors';
import { translationLabels as labels } from '../../../../../constants';
import { translateParam, getParameter } from './utilities';
import RenderParameter from './RenderParameter';

export const mapStateToProps = (state, ownProps) => {
  const {
    typeId,
    keyVal,
    lockedParams,
    value,
  } = ownProps;
  const allActions = allActionsTypeSelector(state);
  const labelText = translateParam(typeId, keyVal, allActions, labels);
  const allowEdit = !(lockedParams.includes(keyVal));
  const result = getParameter(value, !allowEdit);

  return {
    labelText,
    allowEdit,
    value: result,
  };
};

export default compose(
  connect(mapStateToProps),
)(RenderParameter);
