import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { validHostname } from 'components/form/formValidators';
import DataTableRow from 'components/DataTable/DataTableRow/DataTableRow';
import DataTableEditable from 'components/DataTable/DataTableEditable/DataTableEditable';
import DataTableEditableToggle from 'components/DataTable/DataTableEditableToggle/DataTableEditableToggle';
import { originFormFields } from '../../constants';

class OriginTableRow extends Component {

  constructor() {
    super();

    this.handleEdit = this.handleEdit.bind(this);
    this.handleEditOrigin = this.handleEditOrigin.bind(this);
    this.handleEditUrlOrigin = this.handleEditUrlOrigin.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleEditUrlOrigin(id, values) {
    const { companyId, onEditUrlOrigin } = this.props;

    return new Promise((resolveForm, rejectForm) => {
      const urlOriginId = values[originFormFields.url] ? id : null;
      const params = {
        values: { urlOriginId },
        resolveForm,
        rejectForm,
      };

      onEditUrlOrigin(params, companyId);
    });
  }

  handleEditOrigin(id, values) {
    const { onEditOrigin } = this.props;

    return new Promise((resolveForm, rejectForm) => {
      const params = {
        values: { id, ...values },
        resolveForm,
        rejectForm,
      };

      onEditOrigin(params);
    });
  }

  handleEdit(id, values) {
    if (originFormFields.url in values) {
      return this.handleEditUrlOrigin(id, values);
    }

    return this.handleEditOrigin(id, values);
  }

  handleSubmit(id) {
    return inlineFormValues => this.handleEdit(id, inlineFormValues);
  }

  render() {
    return (
      <DataTableRow {...this.props}>
        <DataTableEditable
          validator={validHostname}
          onSubmit={this.handleSubmit}
        />
        <DataTableEditableToggle onSubmit={this.handleSubmit} />
      </DataTableRow>
    );
  }

}

OriginTableRow.propTypes = {
  companyId: PropTypes.string.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.bool,
  }).isRequired,
  rowNumber: PropTypes.number.isRequired,
  onEditOrigin: PropTypes.func.isRequired,
  onEditUrlOrigin: PropTypes.func.isRequired,
};

export default OriginTableRow;
