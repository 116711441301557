import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { addPagination } from 'helpers/paginationHelpers';
import { SELECT_TASKS, TASKS_FIELD } from 'helpers/selectors';

import {
  actionCreators as projectSuiteActionCreators,
  selectors as projectSuitesSelectors,
} from 'pages/ProjectSuites/utilities';

import MultipleTasksTableField from './MultipleTasksTableField';

export const mapStateToProps = (state, { match }) => {
  const { id: projectSuiteId } = match.params;

  const projects = projectSuitesSelectors.projectsListSelector(state)(projectSuiteId) || [];
  const projectsIds = projects.map(({ id }) => id);

  return {
    projectSuiteId,
    projectsIds,
  };
};


export default compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: TASKS_FIELD, included: true, componentName: SELECT_TASKS },
      (
        { projectSuiteId, projectsIds },
      ) => projectSuiteActionCreators.fetchProjectSuiteProjectsTasks(
        {
          recipientType: 'projectSuite',
          recipientId: projectSuiteId,
          'projectSuiteIds[]': [projectSuiteId],
          'projectIds[]': [...projectsIds],
          sort: 'name',
        },
      ),
    ),
  ),
)(MultipleTasksTableField);
