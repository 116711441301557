import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal, Icon, Header, Divider, Message } from 'semantic-ui-react';

import { Button } from 'components/atomic';
import { Code, HelpText, StyledButton } from './AddDeviceModal.style';

class AddDeviceModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      copied: false,
    };

    this.handleCopy = this.handleCopy.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleCopy() {
    this.textInput.select();
    document.execCommand('copy');
    this.setState({ copied: true }, this.handleReset);
  }

  handleReset() {
    this.timeout = setTimeout(() => this.setState({ copied: false }), 3000);
  }

  render() {
    const { copied } = this.state;
    const { deviceKey, deviceName, onClearDeviceKey, adding } = this.props;

    return (
      <Modal
        open={!!deviceKey}
        onClose={onClearDeviceKey}
      >
        <Modal.Header>
          {adding ? 'Add device' : 'Replace device'}
        </Modal.Header>
        <Modal.Content>
          <Message size="tiny" info>
            <p>
              Please note that replacing this device with a different model may
              move this Site into a different pricing plan.
            </p>
          </Message>
          <Divider clearing />
          <HelpText>
            {adding ? (
              <p>
                Please upload this key to your device to add it
                to this project. For help with uploading the key,
                please consult the device manufacturer&apos;s documentation.
              </p>
            ) : (
              <p>
                Please upload this key to your device to link it to this project
                as <strong>{deviceName}</strong>. For help with uploading the key, please
                consult the device manufacturer&apos;s documentation.
              </p>
            )}
          </HelpText>
          <Header as="h4">Device key</Header>
          <Code
            readOnly
            rows="5"
            ref={input => { this.textInput = input; }}
            defaultValue={deviceKey}
          />
          <StyledButton
            name="add-device-copy"
            icon
            primary
            labelPosition="right"
            onClick={this.handleCopy}
          >
            {copied ? (
              <Fragment>
                <Icon name="checkmark" />
                Copied!
              </Fragment>
            ) : (
              <Fragment>
                <Icon name="copy" />
                Copy to clipboard
              </Fragment>
            )}
          </StyledButton>
        </Modal.Content>
        <Modal.Actions>
          <Button
            name="add-device-close"
            color="red"
            inverted
            onClick={onClearDeviceKey}
          >
            <Icon name="remove" /> Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

}

AddDeviceModal.defaultProps = {
  deviceKey: null,
  deviceName: null,
  adding: false,
};

AddDeviceModal.propTypes = {
  deviceKey: PropTypes.string,
  deviceName: PropTypes.string,
  onClearDeviceKey: PropTypes.func.isRequired,
  adding: PropTypes.bool,
};

export default AddDeviceModal;
