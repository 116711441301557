import { untouch, touch } from 'redux-form';
import isEqual from 'lodash/isEqual';

const validateTimeMask = {
  touchOnBlur: false,
  onChange: (values, dispatch, { form }, previousValues) => {
    const differentKeys = [];
    const fields = Object.keys({ ...values, ...previousValues });

    fields.forEach(key => {
      if (!isEqual(values[key], previousValues[key])) {
        differentKeys.push(key);
      }
    });

    if (differentKeys.length) {
      const touchFields = ['hours', 'minutes', 'seconds'];
      dispatch(untouch(form, ...differentKeys));
      dispatch(touch(form, ...touchFields));
    }
  },
};

export default validateTimeMask;
