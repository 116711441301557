import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getDisplayName } from 'helpers/sharedMethods';

const withDownloadFile = WrappedComponent => class extends Component {

  static displayName = `WithDownloadFile(${getDisplayName(WrappedComponent)})`;

  static propTypes = {
    deviceId: PropTypes.string.isRequired,
    downloading: PropTypes.bool.isRequired,
    item: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    onDownloadFile: PropTypes.func.isRequired,
  };

  constructor() {
    super();

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const {
      deviceId,
      item: { name: fileName, fileTypeId },
      onDownloadFile,
    } = this.props;

    onDownloadFile(deviceId, { fileName, fileTypeId });
  }

  render() {
    const { downloading } = this.props;

    return (
      <WrappedComponent
        onClick={this.handleClick}
        disabled={downloading}
        title="Download"
      />
    );
  }

};

export default withDownloadFile;
