import React from 'react';
import PropTypes from 'prop-types';

import { StyledMinutesInput } from './AstroInput.style';

const step = 5;
const min = -720;
const max = 720;

const AstroInput = props => {
  const { value, ...rest } = props;

  return (
    <StyledMinutesInput
      {...rest}
      value={value}
      step={step}
      min={min}
      max={max}
    />
  );
};

AstroInput.defaultProps = {
  value: 0,
};

AstroInput.propTypes = {
  value: PropTypes.number,
};

export default AstroInput;
