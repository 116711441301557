import styled from 'styled-components';

import Form from 'components/form/Form/Form';

export const StyledTitle = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
`;

export const StyledFormContainer = styled.div`
  display: inline-flex; 
  width: 100%; 
  justify-content: space-between; 
  align-items: center;
`;

export const StyledForm = styled(Form)`
  width: 100%;
`;

export const StyledInputContainer = styled.div`
  max-width: 200px;
  margin-right: 4px;
`;

export const StyledButtonContainer = styled.div`
  width: 100%;
  text-align: right;
`;

export default StyledForm;
