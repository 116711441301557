import isNull from 'lodash/isNull';
import moment from 'moment';

const convertDate = value => {
  if (!isNull(value)) {
    const date = moment(value, 'YYYY-MM-DD');
    const day = date.format('D');
    const month = date.format('MMMM');
    const year = date.format('YYYY');
    const final = `${month} ${day}, ${year}`;

    return final;
  }

  return null;
};

export default convertDate;
