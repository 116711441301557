import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isNil from 'lodash/isNil';

import { deviceSelector } from 'helpers/selectors';

import { actionCreators, selectors } from './utilities';
import PermissionsTable from './PermissionsTable';

export const mapStateToProps = (state, props) => {
  const {
    projectId,
    logicalDeviceId,
    parentResourceId,
    childResourceIds,
    showHeader,
    context = null,
    limitedScope = [],
    isOpen,
  } = props;

  const { name: deviceName } = deviceSelector(state)(logicalDeviceId) || {};

  return {
    isLoading: selectors.isLoadingSelector(state, logicalDeviceId),
    isOpen: isOpen || selectors.isOpenSelector(state, logicalDeviceId),
    cumulatedPermissions: selectors.cumulatedPermissionsSelector(state, logicalDeviceId),
    permissionsTableRows: selectors.permissionsTableRowsSelector(state, logicalDeviceId),
    recipients: selectors.recipientsSelector(state, projectId),
    selectedRecipients: selectors.selectedRecipientsSelector(state, projectId),
    copyRecipientId: selectors.copyRecipientIdSelector(state, logicalDeviceId),

    showHeader,
    resourceId: logicalDeviceId,
    parentResourceId: parentResourceId || projectId,
    childResourceIds,
    resourceTitle: 'Device',
    resourceName: deviceName,
    resourceDescription: !isNil(context) ? 'All devices' : '',
    resourceType: 'logical_device',
    recipientType: 'user',
    limitedScope,
    limitedFor: ['user'],
    context,
    withRecipientSelect: false,
  };
};

const mapDispatchToProps = {
  onFetchCumulatedPermissions: actionCreators.fetchCumulatedPermissions,
  onGrantPermissions: actionCreators.grantMultiplePermissions,
  onRevokePermissions: actionCreators.revokeMultiplePermissions,
  onCopyPermissions: actionCreators.copyMultiplePermissions,
  onPastePermissions: actionCreators.pasteMultiplePermissions,
  onClearPermissionsTable: actionCreators.clearPermissionsTable,
  onToggleResourceOpen: actionCreators.toggleResourceOpen,
  onChangeRecipients: actionCreators.changePermissionsTableRecipients,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(PermissionsTable);
