import isEmpty from 'lodash/isEmpty';

const validateActionsStep = ({ selectedActions, selectedTasks, taskName, selectedActionsForm }) => {
  const { syncErrors } = selectedActionsForm || {};

  if (isEmpty(selectedActions) && isEmpty(selectedTasks)) {
    return false;
  }

  if (!isEmpty(selectedActions) && !taskName) {
    return false;
  }

  if (!syncErrors) {
    return true;
  }

  let selectedActionHasError = false;

  selectedActions.forEach(action => {
    const errors = Object.keys(syncErrors).find(
      key => key.includes(`${action.actionId}_${action.resourceId}`),
    );

    if (!isEmpty(errors)) {
      selectedActionHasError = true;
    }
  });

  if (selectedActionHasError) {
    return false;
  }

  return true;
};

export default validateActionsStep;
