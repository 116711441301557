import { call } from 'redux-saga/effects';

import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import actionCreators from '../actionCreators';

function* fetchProjectDevices(action) {
  const { endpoint, urlParams, projectId, ...rest } = action.payload;
  const { response, error } = yield call(getPath, endpoint, { urlParams });
  const responseWithProjectId = response && { ...response, projectId };

  const params = {
    response: responseWithProjectId,
    error,
    successDisp: actionCreators.fetchProjectDevicesSuccess,
    failDisp: actionCreators.fetchProjectDevicesFailure,
    ...rest,
  };

  yield call(processCall, params);
}

export default fetchProjectDevices;
