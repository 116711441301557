import styled from 'styled-components';

export const Container = styled.div`
  min-height: 30vh;
  padding: 25px 0;
`;

export const DateBlockingContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledLabel = styled.label``;

export default Container;
