import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';
import { rgba } from 'polished';

import colors from 'themes/colors';
import { media } from 'themes/mixins';

export const StyledUserMenu = styled(Dropdown).attrs({
  button: true,
  className: 'icon',
  floating: true,
  item: true,
  icon: 'user',
})`
  &&&& {
    box-shadow: none;
    margin-right: 0;

    &:hover {
      background: ${rgba(colors.black, 0.05)}!important;
    }

    .icon {
      font-size: 2em;
      text-shadow: 0px 0px 4px black;
      color: white;

      &:before {
        left: 0.75em;
      }
    }

    .text {
      display: none;
      ${media.md`display: initial;`};
    }
  }
`;

export default StyledUserMenu;
