import { compose } from 'redux';
import { connect } from 'react-redux';

import onMountUnmount from 'helpers/onMountUnmount';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { siteActionsListSelector, allActionsTypeSelector } from 'helpers/selectors';
import {
  actionCreators as taskSchedulerActionCreators,
} from 'application/tenant/console/site/components/structural/SiteSchedulingTab/components/AutomatedOperations/components/AddAutomatedOperation/utilities';
import {
  selectors as schedulingSelectors,
  actionCreators,
} from 'application/tenant/console/site/components/structural/SiteSchedulingTab/utilities';
import { tasksActionCreators } from 'application/tenant/utilities';

import {
  extractActions,
  createOptionList,
} from './utilities';
import AvailableActions from './AvailableActions';

export const mapStateToProps = (state, { onActionSelected, selectedSite }) => {
  const { id: projectId } = selectedSite;
  const getSiteActions = siteActionsListSelector(state)(projectId);
  const allActionTypes = allActionsTypeSelector(state);
  const siteActionList = extractActions(getSiteActions);
  const optionList = createOptionList(siteActionList, allActionTypes);

  const project = {
    id: selectedSite.id,
    name: selectedSite.name,
  };

  return {
    project,
    projectId,
    onActionSelected,
    availableActions: optionList,
    isLoading: schedulingSelectors.fetchingAvailableActionsSelector(state),
    selectedDevice: selectedSite,
  };
};


export default compose(
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    ({ projectId }) => tasksActionCreators.fetchSiteActionTypesList(projectId),
  ),
  onMountUnmount(
    ({ projectId }) => actionCreators.addSelectedProjectId(projectId),
    () => actionCreators.clearSelectedProjectId(),
  ),
  onMountUnmount(
    ({ project }) => taskSchedulerActionCreators.setSelectedProject(project),
    () => taskSchedulerActionCreators.setSelectedProject({}),
  ),
)(AvailableActions);
