import { call, put } from 'redux-saga/effects';

import { postPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import { PROJECTS_FIELD, PROJECT_SUITES_FIELD } from 'helpers/selectors';

import * as projectsForMapActionCreators from 'pages/ProjectSuite/ProjectSuiteProjectsTab/components/MultiSiteMapView/utilities/actionCreators';
import actionCreators from '../actionCreators';

function* addProjectToProjectSuite(action) {
  const {
    projectSuiteId,
    projectName,
    projectId,
    endpoint,
  } = action.payload;

  const body = {
    data: [
      {
        id: projectId,
        type: 'projects',
      },
    ],
  };

  let { response, error } = yield call(postPath, endpoint, body);

  response = response ? { projectSuiteId, projectName, projectId } : undefined;

  error = error ? new Error(`Unable to link Site ${projectName} to Multi-site`) : undefined;

  const params = {
    response,
    error,
    successDisp: actionCreators.addProjectToProjectSuiteSuccess,
    failDisp: actionCreators.addProjectToProjectSuiteFailure,
  };

  if (!error) {
    yield put(actionCreators.fetchProjectSuiteProjectsFilter(projectSuiteId, {
      fields: {
        [PROJECTS_FIELD]: [
          'name',
        ],
      },
      sort: 'name',
    }));


    yield put(actionCreators.fetchProjectSuite(projectSuiteId, {
      fields: {
        [PROJECT_SUITES_FIELD]: [
          'name',
          'projectsCount',
        ],
      },
    }));

    yield put(projectsForMapActionCreators.fetchChildProjectsForMap(projectSuiteId, {}));
  }

  yield call(processCall, params);
}

export default addProjectToProjectSuite;
