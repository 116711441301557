import styled, { css } from 'styled-components';
import { Grid } from 'semantic-ui-react';

import filterProps from 'helpers/filterProps';
import { FormFieldLabel } from 'components/form/FormField/FormField.style';
import { Button } from 'components/atomic';

export const StyledLabel = styled(FormFieldLabel)`
  font-weight: 400;
  padding-bottom: 3px !important;
`;

const GridRow = filterProps(['marginBottom'])(Grid.Row);

export const StyledRow = styled(GridRow)`
  ${({ marginBottom }) => marginBottom && css`
    margin-bottom: 3px !important;
  `}
`;

const GridColumn = filterProps(['marginBottom'])(Grid.Column);

export const StyledColumn = styled(GridColumn)`
   ${({ marginBottom }) => marginBottom && css`
    margin-bottom: 8px !important;
  `}
`;

export const StyledButton = styled(Button)`
  margin-top: 16px !important;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
