import constants from 'dispatcherConst';
import { messages } from 'data/notifications/notificationsConst';

const unableToFetchLocation = siteId => ({
  type: constants.UNABLE_TO_FETCH_LOCATION,
  payload: {
    siteId,
    info: messages.ERROR_UNABLE_TO_FETCH_LOCATION,
  },
});

export default unableToFetchLocation;
