import constants from 'dispatcherConst';

export const addSelectedTask = task => ({
  type: constants.ADD_SELECTED_TASK,
  payload: {
    selectedTask: task,
  },
});

export default addSelectedTask;
