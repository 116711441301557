import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Label, Tab, Form } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';

import { Button } from 'components/atomic';
import TimeInput from 'components/core/TimeInput/TimeInput';

import { getTimeIntervals } from '../../utilities';
import { StyledLabel, StyledRow, TimeList } from './RecurringView.style';

export default function RecurringView({ selectedTimes, onTimeChange }) {
  const [interval, setInterval] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const intervals = useMemo(
    () => getTimeIntervals(startTime, endTime, interval),
    [startTime, endTime, interval],
  );

  const handleTimeSelected = () => {
    if (isEmpty(intervals)) {
      return;
    }

    onTimeChange([...new Set([...selectedTimes, ...intervals])]);
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      handleTimeSelected();
    }
  };

  return (
    <Form>
      <Grid as={Tab.Pane}>
        <StyledRow noPaddingBottom>
          <Grid.Column width={16}>
            <Form.Field>
              <StyledLabel htmlFor="input">Interval (Hours/Minutes)</StyledLabel>
              <TimeInput
                name="recurring-view-time-input-interval"
                autoFocus={false}
                value={interval}
                onChange={setInterval}
                onKeyPress={handleKeyPress}
                format="HH:mm"
                maxDetail="minute"
                fullWidth
              />
            </Form.Field>
          </Grid.Column>
        </StyledRow>
        <StyledRow noPaddingBottom>
          <Grid.Column width={8}>
            <Form.Field>
              <StyledLabel htmlFor="input">Starting on</StyledLabel>
              <TimeInput
                name="recurring-view-time-input-start"
                autoFocus={false}
                value={startTime}
                onChange={setStartTime}
                onKeyPress={handleKeyPress}
                fullWidth
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={8}>
            <Form.Field>
              <StyledLabel htmlFor="input">Until</StyledLabel>
              <TimeInput
                name="recurring-view-time-input-end"
                autoFocus={false}
                value={endTime}
                onChange={setEndTime}
                onKeyPress={handleKeyPress}
                fullWidth
              />
            </Form.Field>
          </Grid.Column>
        </StyledRow>
        <Grid.Row>
          <Grid.Column width={16}>
            <Form.Field>
              <StyledLabel htmlFor="input">Preview</StyledLabel>
              <TimeList>
                {intervals.map(time => (
                  <li key={time}>
                    <Label htmlFor="input">{time}</Label>
                  </li>
                ))}
              </TimeList>
            </Form.Field>
            <Button
              primary
              disabled={isEmpty(intervals)}
              onClick={handleTimeSelected}
              className="recurring-submit-button"
            >
              Confirm
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
}

RecurringView.defaultProps = {
  selectedTimes: [],
};

RecurringView.propTypes = {
  selectedTimes: PropTypes.arrayOf(PropTypes.string),
  onTimeChange: PropTypes.func.isRequired,
};
