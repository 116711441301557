import { call } from 'redux-saga/effects';

import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import * as actionCreators from '../actionCreators';

function* fetchChildProjectsForMap(action) {
  const { endpoint, urlParams, projectSuiteId, ...rest } = action.payload;
  const { response, error } = yield call(getPath, endpoint, { urlParams });
  const enhanceResponseWithId = response ? { ...response, projectSuiteId } : response;

  const params = {
    response: enhanceResponseWithId,
    error,
    successDisp: actionCreators.fetchChildProjectsForMapSuccess,
    failDisp: actionCreators.fetchChildProjectsForMapFailure,
    ...rest,
  };

  yield call(processCall, params);
}

export default fetchChildProjectsForMap;
