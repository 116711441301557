import UrlParams from 'helpers/UrlParams';
import { urlDatePagination } from '../constants';
import accountForDeprecatedFormat from './accountForDeprecatedFormat';

const parseDateFromUrl = ({ defaultTime }) => {
  const urlParams = new UrlParams({ pagination: urlDatePagination });
  const { page: urlDate } = urlParams.extractUrlParams({
    page: urlParams.pageParam,
  });

  if (urlDate) {
    return accountForDeprecatedFormat(urlDate, defaultTime.zone);
  }

  return defaultTime;
};

export default parseDateFromUrl;
