import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';

import { Button } from 'components/atomic';
import SettingConfirm from 'components/SettingConfirm/SettingConfirm';

class DeviceTriggerAction extends PureComponent {

  constructor() {
    super();

    this.handleConfirm = throttle(this.handleConfirm.bind(this), 250);
  }

  handleConfirm() {
    const { resourceId, actionType, values, onPerformAction } = this.props;

    onPerformAction({
      deviceId: resourceId,
      actionType,
      values,
    });
  }

  render() {
    const { online, title, confirmationTitle, confirmationMessage } = this.props;
    const trigger = <Button type="button" primary disabled={!online}>{title}</Button>;
    const content = <p>{confirmationMessage}</p>;

    return (
      <SettingConfirm
        trigger={trigger}
        header={confirmationTitle}
        content={content}
        onConfirm={this.handleConfirm}
        size="mini"
      />
    );
  }

}

DeviceTriggerAction.defaultProps = {
  title: '',
  online: false,
  confirmationTitle: '',
  confirmationMessage: 'Are you sure?',
};

DeviceTriggerAction.propTypes = {
  title: PropTypes.string,
  online: PropTypes.bool,
  confirmationTitle: PropTypes.string,
  confirmationMessage: PropTypes.string,
  resourceId: PropTypes.string.isRequired,
  actionType: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  onPerformAction: PropTypes.func.isRequired,
};

export default DeviceTriggerAction;
