import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  CONTEXTS_FIELD,
  permissionsStateSelector,
} from 'helpers/selectors';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { addPagination } from 'helpers/paginationHelpers';
import { TENANT_CONTEXT_ID } from 'application/tenant/console/utilities/permissions/permissionsSaga';
import permissionsActionCreators from 'application/tenant/console/utilities/permissions/permissionsActionCreators';
import withLoadPermissions from './withLoadPermissions';

export const mapStateToProps = state => {
  const { contexts } = permissionsStateSelector(state) || {};
  const contextId = contexts ? Object.keys(contexts)[0] : null;

  return {
    contextId,
  };
};

export default compose(
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    ({ contextId }) => !contextId && permissionsActionCreators.fetchIndexPermissions(),
    addPagination(
      { resourceType: CONTEXTS_FIELD },
      permissionsActionCreators.fetchContexts,
    ),
    () => permissionsActionCreators.addContextId(TENANT_CONTEXT_ID),
  ),
  withLoadPermissions,
);
