import { call, put } from 'redux-saga/effects';

import endpoints from 'data/api/endpoints';
import { postPath } from 'data/api/requests';

import AutomatedOperationModel from 'models/AutomatedOperationModel';

import automatedOperationsActionCreators from '../../../../utilities/actionCreators';
import { RESOURCE_TYPE_PROPERTY_NAME } from '../../constants';
import taskScheduleActionCreators from '../actionCreators';

function* saveAutomatedOperation({
  resourceId,
  resourceType,
  name,
  description,
  enabled,
  hidden,
  occurrenceColour,
  priority,
  priorityType,
  taskIds,
  mode,
}) {
  const propertyName = RESOURCE_TYPE_PROPERTY_NAME[resourceType];

  const params = {
    [propertyName]: resourceId,
    name,
    description,
    enabled,
    hidden,
    occurrenceColour,
    priority,
    priorityType,
    taskIds,
    mode,
  };

  const automatedOperationModel = new AutomatedOperationModel(params);

  const body = AutomatedOperationModel.requestBody(automatedOperationModel);

  const { response, error } = yield call(postPath, endpoints.AUTOMATED_OPERATIONS, body);

  if (response) {
    yield put(automatedOperationsActionCreators.addAutomatedOperationSuccess(response));
  }

  if (error) {
    yield put(taskScheduleActionCreators.addTaskSchedulerFailure({
      type: 'SAVE_AUTOMATED_OPERATION',
      error,
    }));
  }

  const automatedOperation = {
    id: response.data.id,
    ...response.automatedOperations[response.data.id].attributes,
  };

  return automatedOperation;
}

export default saveAutomatedOperation;
