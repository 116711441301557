import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react';

export const Container = styled.div`
  min-height: 30vh;
  padding: 25px 0;
`;

export const StyledLabel = styled.label`
  font-weight: bold;
  font-size: 14px;
  margin: auto 0px;
`;

export const StyledDropdown = styled(Dropdown)`
  height: 36px;
  font-size: 14px;
`;

export const LabelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    margin-left: 8px !important;
  }
`;

export default Container;
