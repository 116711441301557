import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';
import deepEqual from 'lodash/isEqual';
import identity from 'lodash/identity';

import { deviceSelector } from 'helpers/selectors';
import PharosProjectV1 from './PharosProjectV1';

const makeMapState = () => {
  const memoName = memoizeOne(identity, deepEqual);
  const memoFileName = memoizeOne(identity, deepEqual);
  const memoFileSize = memoizeOne(identity, deepEqual);
  const memoProjectId = memoizeOne(identity, deepEqual);
  const memoControllerNumber = memoizeOne(identity, deepEqual);
  const memoControllerName = memoizeOne(identity, deepEqual);
  const memoAuthor = memoizeOne(identity, deepEqual);

  return function mapStateToProps(state, ownProps) {
    const { resourceId } = ownProps;
    const device = deviceSelector(state)(resourceId);
    const { reportedWithMeta } = device;

    const {
      cfg: {
        nm: name,
        fn: fileName,
        sz: fileSize,
        id: projectId,
        ldnum: controllerNumber,
        ldnm: controllerName,
        au: author,
      } = {},
    } = reportedWithMeta || {};

    return {
      loading: !reportedWithMeta,
      name: memoName(name),
      fileName: memoFileName(fileName),
      fileSize: memoFileSize(fileSize),
      projectId: memoProjectId(projectId),
      controllerNumber: memoControllerNumber(controllerNumber),
      controllerName: memoControllerName(controllerName),
      author: memoAuthor(author),
    };
  };
};

export default connect(makeMapState())(PharosProjectV1);
