import styled, { css } from 'styled-components';
import { Popup, Label } from 'semantic-ui-react';
import get from 'lodash/get';

import { defaultBorder, defaultFocus } from 'themes/mixins';

export const StyledPopup = styled(Popup)`
   &&& {
      & .ui .popup {
        ${defaultBorder}
      }
    }
`;

export const StyledLabel = styled(Label)`
  margin-left: 8px !important;
`;

export const Container = styled.span`
  ${({ theme }) => {
    const primaryColor = get(theme, 'palette.primaryColor');

    return css`
      & .rw-widget-container {
        border: none !important;
        box-shadow: none !important;
      }
      & .rw-popup-container {
        position: static !important;
      }
      
      & .rw-select-bordered {
        border-left: none !important;
        vertical-align: middle !important;
      }
      
      & .rw-cell.rw-state-selected {
        background-color: ${primaryColor} !important;;
        border-color: ${primaryColor} !important;;
      }
       
      & .rw-cell.rw-state-focus {
        border-color: ${primaryColor} !important;;
      }
      
      & .rw-state-focus > .rw-widget-container {
        ${defaultFocus} !important;
      }

      & .rw-widget-picker {
        display: none !important;
      }
      
      & .rw-widget-picker input[type="text"] {
        border: none !important;
        display: none !important;
      }
    `;
  }};
`;

export const TriggerContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const EmptyMessage = styled.p`
  margin-left: 8px;
  font-size: 14px;
`;

export default Container;
