import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Modal } from 'semantic-ui-react';

import { uploadsType } from 'data/types';
import DataTable from 'components/DataTable/DataTableContainer';
import DataAccordion from 'components/DataAccordion/DataAccordion';
import UploadsController from 'components/UploadsWidget/UploadsController/UploadsControllerContainer';
import UploadTableRow from 'components/elements/UploadTableRow/UploadTableRow';
import { Button } from 'components/atomic';
import { StyledDesktopHeader, StyledUploadsStats, ActionsContainer } from './UploadsModal.style';

const uploadLabels = [
  { text: '', field: 'playProps', width: 1 },
  { text: 'Device', field: 'device', width: 3 },
  { text: 'File', field: 'shortName', width: 3 },
  { text: 'Status', field: 'status', width: 2 },
  { text: 'Remaining', field: 'remainingTime', width: 1 },
  { text: '', field: 'progress', width: 4 },
  { text: '', field: 'pauseResumeProps', width: 1 },
  { text: '', field: 'buttonProps', width: 1 },
];

const UploadsModal = ({ uploads, onClearCompleted }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      onClearCompleted();
    }
  }, [isOpen, onClearCompleted]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const hasPausedItem = () => uploads.some(
    upload => upload.files.some(file => file.statusFlags.paused),
  );

  const modalContent = () => (
    <Grid>
      {uploads.map(({ project, projectId, files }) => {
        const header = (
          <Fragment>
            {project}
            <StyledUploadsStats project={projectId} />
          </Fragment>
        );

        return (
          <Grid.Row key={project}>
            <Grid.Column mobile={16}>
              <DataAccordion open>
                <DataTable
                  selectField="fileKey"
                  header={header}
                  labels={uploadLabels}
                  data={files}
                  customTableRow={UploadTableRow}
                  pagination={{ type: 'uploads' }}
                />
              </DataAccordion>
            </Grid.Column>
          </Grid.Row>
        );
      })}
    </Grid>
  );

  const modalFooter = () => (
    <Modal.Actions>
      <ActionsContainer>
        <div>
          {hasPausedItem() && (
            <span>
              To resume the Upload, click on the play icon and specify the file path.
            </span>
          )}
        </div>
        <Button primary basic onClick={handleClose}>
          Close
        </Button>
      </ActionsContainer>
    </Modal.Actions>
  );

  return (
    <Modal
      open={isOpen}
      trigger={<UploadsController onClick={handleOpen} />}
      onClose={handleClose}
    >
      <StyledDesktopHeader open />
      <Modal.Content scrolling>
        {modalContent()}
      </Modal.Content>
      {modalFooter()}
    </Modal>
  );
};

UploadsModal.propTypes = {
  uploads: uploadsType.isRequired,
  onClearCompleted: PropTypes.func.isRequired,
};

export default UploadsModal;
