import styled from 'styled-components';
import { Header } from 'semantic-ui-react';

export const StyledHeader = styled(Header)`
  &.ui.header {
    margin-top: 0.6em;
  }
`;

export default StyledHeader;
