const getLeadingZeroElements = containerId => (
  document.getElementById(containerId).querySelectorAll('.react-time-picker__inputGroup__leadingZero')
);

const handleIncorrectZeros = ({ containerId }) => {
  const container = document.getElementById(containerId);

  if (!container) {
    return;
  }

  const hourInput = document.getElementById(containerId).querySelectorAll('input.react-time-picker__inputGroup__hour')[0];
  const minuteInput = document.getElementById(containerId).querySelectorAll('input.react-time-picker__inputGroup__minute')[0];
  const secondInput = document.getElementById(containerId).querySelectorAll('input.react-time-picker__inputGroup__second')[0];

  if (hourInput) {
    hourInput.addEventListener('focusout', () => {
      if (hourInput.value.length === 2) {
        if (getLeadingZeroElements(containerId)[0]) {
          getLeadingZeroElements(containerId)[0].style.display = 'none';
        }

        hourInput.classList.remove('react-time-picker__inputGroup__input--hasLeadingZero');
      } else {
        if (getLeadingZeroElements(containerId)[0]) {
          getLeadingZeroElements(containerId)[0].style.display = 'block';
        }

        hourInput.classList.add('react-time-picker__inputGroup__input--hasLeadingZero');
      }
    });
  }

  if (minuteInput) {
    minuteInput.addEventListener('focusout', () => {
      if (minuteInput.value.length === 2) {
        if (getLeadingZeroElements(containerId)[1]) {
          getLeadingZeroElements(containerId)[1].style.display = 'none';
        }

        minuteInput.classList.remove('react-time-picker__inputGroup__input--hasLeadingZero');
      } else {
        if (getLeadingZeroElements(containerId)[1]) {
          getLeadingZeroElements(containerId)[1].style.display = 'block';
        }

        minuteInput.classList.add('react-time-picker__inputGroup__input--hasLeadingZero');
      }
    });
  }

  if (secondInput) {
    secondInput.addEventListener('focusout', () => {
      if (secondInput.value.length === 2) {
        if (getLeadingZeroElements(containerId)[2]) {
          getLeadingZeroElements(containerId)[2].style.display = 'none';
        }

        secondInput.classList.remove('react-time-picker__inputGroup__input--hasLeadingZero');
      } else {
        if (getLeadingZeroElements(containerId)[2]) {
          getLeadingZeroElements(containerId)[2].style.display = 'block';
        }

        secondInput.classList.remove('react-time-picker__inputGroup__input--hasLeadingZero');
      }
    });
  }

};

export default handleIncorrectZeros;
