import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { formValueSelector } from 'redux-form';

import formNames from 'components/form/formNames';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { singleProjectSelector } from 'helpers/selectors';

import { actionCreators, selectors } from '../../../../utilities';
import VoucherDetails from './VoucherDetails';

export const mapStateToProps = (state, { match }) => {
  const { id: projectId } = match.params;
  const siteFromVoucherForm = formValueSelector(formNames.siteFromVoucher);

  const {
    projectedSubscriptionPlanCode: siteProjectedSubscriptionPlanCode,
  } = singleProjectSelector(state, projectId);

  return {
    projectId,
    siteProjectedSubscriptionPlanCode,
    selectedVoucher: selectors.selectedVoucherSelector(state),
    isLoading: selectors.isLoadingVoucherSelector(state),
    siteName: siteFromVoucherForm(state, 'name'),
    ownerEmail: siteFromVoucherForm(state, 'ownerEmail'),
  };
};

const mapDispatchToProps = {
  onRedeemVoucher: actionCreators.redeemVoucher,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  chainedLoaderWithSaga(() => actionCreators.clearSelectedVoucher()),
)(VoucherDetails);
