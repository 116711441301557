import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Dropdown } from 'semantic-ui-react';

import {
  FormFieldLabel,
  FormFieldWrapper,
  InputWrapper,
} from 'components/form/FormField/FormField.style';

import DeviceActions from '../DeviceActions/DeviceActions';
import SiteActions from '../SiteActions/SiteActionsContainer';
import { actionTypes } from './constants';

const RenderActionTypes = ({
  superSites,
  options,
}) => {
  const [type, setType] = useState(actionTypes.DEVICE_ACTIONS);

  const handleChange = (e, { value }) => {
    setType(value);
  };

  return (
    <>
      <FormGroup inline>
        <FormFieldWrapper width={16}>
          <FormFieldLabel htmlFor="input">Resource</FormFieldLabel>
          <InputWrapper>
            <Dropdown
              placeholder="Select resource"
              selection
              options={options}
              onChange={handleChange}
              value={type}
            />
          </InputWrapper>
        </FormFieldWrapper>
      </FormGroup>
      {(type === actionTypes.DEVICE_ACTIONS) && <DeviceActions superSites={superSites} />}
      {(type === actionTypes.SITE_ACTIONS) && <SiteActions />}
    </>
  );
};

RenderActionTypes.defaultProps = {
  superSites: false,
  options: [],
};

RenderActionTypes.propTypes = {
  superSites: PropTypes.bool,
  options: PropTypes.array,
};

export default RenderActionTypes;
