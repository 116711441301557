import { takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import disableTwoFactor from './disableTwoFactor';

function* saga() {
  yield takeLatest(constants.DISABLE_TWO_FACTOR, disableTwoFactor);
}

export default saga;
