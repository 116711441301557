import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Grid, Header } from 'semantic-ui-react';

import { StyledPublicHeader } from 'application/public/components';
import TenantBackground from 'components/TenantBackground/TenantBackground.style';
import SignUpForm from './SignUpForm/SignUpFormContainer';

export const REQUESTER = 'signUp';

class SignUp extends Component {

  constructor() {
    super();

    this.signUp = this.signUp.bind(this);
  }

  componentDidMount() {
    const { invitationToken, onVerifyToken } = this.props;

    onVerifyToken(invitationToken, REQUESTER);
  }

  signUp(values) {
    const { invitationToken, onSignUp } = this.props;

    return new Promise((resolveForm, rejectForm) => {
      onSignUp(
        { values, resolveForm, rejectForm },
        invitationToken,
      );
    });
  }

  signUpForm() {
    const { verifyingToken, verificationError, email } = this.props;

    if (verifyingToken) {
      return null;
    }

    if (!verificationError) {
      return (
        <SignUpForm onSubmit={this.signUp} initialValues={{ email }} />
      );
    }

    if (verificationError.unauthorized) {
      return (
        <div>
          <p>Your invitation token is not longer valid.</p>
          <p>Please ask the inviter again and use the link from the email.</p>
        </div>
      );
    }

    return verificationError.toArray.map(error => (
      <p key={error}>{error}</p>
    ));
  }

  render() {
    return (
      <Container>
        <TenantBackground />
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} computer={8} floated="right">
              <StyledPublicHeader as="header">
                <Header as="h2">Sign up</Header>
                <p>Please provide all the information</p>
              </StyledPublicHeader>
              {this.signUpForm()}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }

}

SignUp.defaultProps = {
  verificationError: null,
};

SignUp.propTypes = {
  email: PropTypes.string.isRequired,
  invitationToken: PropTypes.string.isRequired,
  verifyingToken: PropTypes.bool.isRequired,
  verificationError: PropTypes.object,
  onVerifyToken: PropTypes.func.isRequired,
  onSignUp: PropTypes.func.isRequired,
};

export default SignUp;
