import { compose } from 'redux';

import { permissionsOrRedirect } from 'gateway';
import { DEVICE_FIRMWARES_FIELD } from 'helpers/selectors';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { addPagination } from 'helpers/paginationHelpers';
import { actionCreators } from '../../utilities';
import DeviceFirmwaresTable from './DeviceFirmwaresTable';

export default compose(
  chainedLoaderWithSaga(
    addPagination(
      {
        resourceType: DEVICE_FIRMWARES_FIELD,
        included: true,
      },
      () => actionCreators.fetchDeviceFirmwares({ sort: '-version', include: 'files' }),
    ),
  ),
  chainedLoaderWithSaga(actionCreators.fetchDeviceFirmwaresModelTree),
  permissionsOrRedirect(),
)(DeviceFirmwaresTable);
