import {
  OCCURRENCE_DAY_FROM_NUMBER,
  OCCURRENCE_DAY_OPTIONS,
} from '../../../../ScheduleStep/components/RecurrenceViews/constants';

const getOccurrenceDayText = day => {
  if (!day) {
    return '';
  }

  const occurrenceDay = OCCURRENCE_DAY_FROM_NUMBER[day];
  const { text } = OCCURRENCE_DAY_OPTIONS.find(
    item => item.key === occurrenceDay,
  );

  return text;
};

export default getOccurrenceDayText;
