import styled from 'styled-components';
import { Label } from 'semantic-ui-react';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PlaceholderContainer = styled.div`
  width: 250px;
`;

export const StyledLabel = styled(Label).attrs({
  htmlFor: 'tab',
})`
  &.ui.label {
    padding: 0 3px 0 3px;
    margin: 0 0 0 4px;
  }
`;

export default PlaceholderContainer;
