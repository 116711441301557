import { createSelector } from 'reselect';
import build from 'redux-object';
import memoize from 'lodash/memoize';

import { options } from 'helpers/selectors';
import childProjectsForMapSelector from './childProjectsForMapSelector';

const childProjectsWithLocationSelector = createSelector(
  childProjectsForMapSelector,
  data => memoize(projectSuiteId => {
    if (data && data[projectSuiteId]) {
      const projects = build(data, projectSuiteId, null, options);

      return Array.isArray(projects) && projects.filter(
        project => project.longitude !== null && project.latitude !== null,
      );
    }

    return [];
  }),
);

export default childProjectsWithLocationSelector;
