import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import {
  COMPANIES_FIELD,
  SHOW_COMPANIES_COMPONENT,
} from 'helpers/selectors';

const companiesActionCreators = {
  changeTenant: (tenant, currentUserId) => ({
    type: constants.CHANGE_TENANT,
    payload: { tenant, currentUserId },
  }),

  fetchCompanies: urlParams => ({
    type: constants.FETCH_COMPANIES_REQUEST,
    payload: {
      endpoint: endpoints.COMPANIES,
      resourceType: COMPANIES_FIELD,
      urlParams,
    },
  }),

  fetchCompaniesSuccess: response => ({
    type: constants.FETCH_COMPANIES_SUCCESS,
    payload: {
      response,
      resourceType: COMPANIES_FIELD,
    },
  }),

  fetchCompaniesFailure: error => ({
    type: constants.FETCH_COMPANIES_FAILED,
    payload: { error },
  }),

  // Single company
  fetchCompany: (companyId, urlParams) => ({
    type: constants.FETCH_COMPANY_REQUEST,
    payload: {
      companyId,
      endpoint: endpoints.COMPANY(companyId),
      urlParams,
    },
  }),

  fetchCompanySuccess: response => ({
    type: constants.FETCH_COMPANY_SUCCESS,
    payload: { response },
  }),

  fetchCompanyFailure: error => ({
    type: constants.FETCH_COMPANY_FAILED,
    payload: { error },
  }),

  addCompany: params => ({
    type: constants.ADD_COMPANY_REQUEST,
    payload: {
      params,
      endpoint: endpoints.COMPANIES,
    },
  }),

  addCompanySuccess: response => ({
    type: constants.ADD_COMPANY_SUCCESS,
    payload: {
      response,
      message: messages.COMPANY_ADDED(response.data.attributes.name),
      resourceType: COMPANIES_FIELD,
      id: response.data.id,
      componentName: SHOW_COMPANIES_COMPONENT,
    },
  }),

  addCompanyFailure: error => ({
    type: constants.ADD_COMPANY_FAILED,
    payload: { error },
  }),

  editCompany: params => ({
    type: constants.UPDATE_COMPANY_INFO_REQUEST,
    payload: {
      endpoint: endpoints.COMPANY(params.values.id),
      params,
    },
  }),

  editCompanySuccess: response => ({
    type: constants.UPDATE_COMPANY_INFO_SUCCESS,
    payload: {
      message: messages.DATA_SAVED,
      response,
      resourceType: COMPANIES_FIELD,
      id: response.data.id,
    },
  }),

  editCompanyFailure: error => ({
    type: constants.UPDATE_COMPANY_INFO_FAILED,
    payload: { error },
  }),
};

export default companiesActionCreators;
