import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Responsive, Segment } from 'semantic-ui-react';

import TopBar from 'components/TopBar/TopBarContainer';

import PWAProvider from 'components/PWAProvider/PWAProvider';
import FaviconsProvider from 'components/FaviconsProvider/FaviconsProvider';
import NotificationStack from 'components/NotificationStack/NotificationStackContainer';
import Footer from 'components/Footer/FooterContainer';
import Nav from 'App.style';
import ApplicationSwitch from 'application/ApplicationSwitch';

import { sizes } from 'themes/mixins';

class App extends Component {

  constructor() {
    super();

    this.handleMenuHide = this.handleMenuHide.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { location: { pathname }, onChangeRoute } = this.props;

    onChangeRoute(pathname);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({
    currentUserId: nextCurrentUserId,
    location: { pathname: nextPathname },
  }) {
    const { currentUserId, location: { pathname }, onChangeRoute } = this.props;

    if (!currentUserId && nextCurrentUserId) {
      window.scrollTo(0, 0);
    }

    if (pathname !== nextPathname) {
      onChangeRoute(nextPathname);
    }
  }

  handleMenuHide() {
    const { onHideMenu } = this.props;

    onHideMenu();
  }

  render() {
    const {
      currentUserId,
      menuVisible,
      inControlPanelContext,
    } = this.props;

    return (
      <main>
        <PWAProvider />
        <FaviconsProvider />
        <NotificationStack />
        <TopBar />
        <Nav narrow={!!currentUserId} control={inControlPanelContext}>
          <Dimmer.Dimmable as={Segment} basic vertical>
            <Responsive
              maxWidth={sizes.md - 1}
              as={Nav.Dimmer}
              active={menuVisible && !!currentUserId}
              onClick={this.handleMenuHide}
            />
            <ApplicationSwitch />
          </Dimmer.Dimmable>
        </Nav>
        <Footer />
      </main>
    );
  }

}

App.defaultProps = {
  currentUserId: null,
};

App.propTypes = {
  currentUserId: PropTypes.string,
  location: PropTypes.object.isRequired,
  menuVisible: PropTypes.bool.isRequired,
  inControlPanelContext: PropTypes.bool.isRequired,
  onChangeRoute: PropTypes.func.isRequired,
  onHideMenu: PropTypes.func.isRequired,
};

export default App;
