import React, { Component, createRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import isFinite from 'lodash/isFinite';
import isUndefined from 'lodash/isUndefined';

import { keyCodes } from 'helpers/sharedVariables';
import {
  ArrowIcon,
  NumberInputActions,
  NumberInputButton,
  NumberInputContainer,
  NumberInputWrapper,
} from 'components/form/NumberInput/NumberInput.style';
import {
  minRangeError,
  maxRangeError,
} from '../../constants';
import {
  MinutesInputElement,
} from './MinutesInput.style';
import {
  renderValue,
  processValueAfterTyping,
  checkKeyboardMinus,
} from './utilities';

class MinutesInput extends Component {

  static preventFocus(e) {
    e.preventDefault();
  }

  constructor() {
    super();

    this.theInput = createRef();
    this.state = {
      cachedValue: null,
      focused: null,
      time: 0,
      minusPressed: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleValueDown = this.handleValueDown.bind(this);
    this.handleValueUp = this.handleValueUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { cachedValue } = prevState;
    const { value } = nextProps;

    if (value === cachedValue) return null;

    return {
      cachedValue: value,
      time: value,
    };
  }

  componentDidMount() {
    const { onChange, value } = this.props;

    onChange(value);
  }

  handleChange(e) {
    const { target: { value } } = e;
    const { minusPressed } = this.state;
    const checkValue = processValueAfterTyping(value);
    const valueToNumber = parseInt(checkValue, 0);

    if (isFinite(valueToNumber)) {
      const valueToUpdate = minusPressed ? -valueToNumber : valueToNumber;
      this.performUpdate(valueToUpdate);
    }

    if (minusPressed && valueToNumber !== 0) {
      this.setState({
        minusPressed: false,
      });
    }
  }

  handleFocus() {
    this.setState({ focused: true });
  }

  handleBlur() {
    this.setState({ focused: null });
  }

  handleButtonClick(difference) {
    const { time, focused } = this.state;
    const { step } = this.props;
    if (focused === null) {
      this.theInput.current.focus();
    }

    const remainder = time % step;
    if (remainder !== 0) {
      const differenceIsPositive = difference > 0;

      let updateTime;
      if (time > 0) {
        const calculateDifference = differenceIsPositive ? step - remainder : -remainder;
        updateTime = time + calculateDifference;
      }

      if (time < 0) {
        const calculateDifference = differenceIsPositive ? -remainder : -(step + remainder);
        updateTime = time + calculateDifference;
      }

      this.performUpdate(updateTime);
    } else {
      const updateTime = time + difference;
      this.performUpdate(updateTime);
    }
  }

  handleValueUp() {
    const { step } = this.props;
    this.handleButtonClick(step);
  }

  handleValueDown() {
    const { step } = this.props;
    this.handleButtonClick(-step);
  }

  handleKeyDown(e) {
    const { step } = this.props;
    const { time, minusPressed } = this.state;
    const checkMinus = checkKeyboardMinus(e.keyCode);

    if (e.keyCode === keyCodes.UP) {
      e.preventDefault();
      this.handleButtonClick(step);
    } else if (e.keyCode === keyCodes.DOWN) {
      e.preventDefault();
      this.handleButtonClick(-step);
    }

    if (e.keyCode === keyCodes.ENTER) {
      e.preventDefault();
    }

    if (checkMinus && time !== 0) {
      this.performUpdate(-time);
    }

    if (checkMinus && time === 0) {
      if (!minusPressed) {
        this.setState({
          minusPressed: true,
        });

        setTimeout(() => {
          this.setState({
            minusPressed: false,
          });
        }, 1000);
      }
    }
  }

  performUpdate(time) {
    const {
      min,
      max,
      onChange,
      invalidAstroInput,
    } = this.props;

    const convertToNumber = Number(time);

    if (!isUndefined(min) && convertToNumber < min) {
      invalidAstroInput(minRangeError);
      return;
    }

    if (!isUndefined(max) && convertToNumber > max) {
      invalidAstroInput(maxRangeError);
      return;
    }

    this.setState({
      time: convertToNumber,
    });

    onChange(time);
  }

  render() {
    const { focused } = this.state;
    const {
      id,
      min,
      max,
      className,
      value,
      suffix,
    } = this.props;
    const isActive = focused !== null;
    const displayValue = renderValue(value);

    return (
      <NumberInputWrapper className={className}>
        <NumberInputContainer active={isActive}>
          <Fragment key={0}>
            <MinutesInputElement
              id={id}
              type="text"
              value={displayValue}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus}
              onKeyDown={this.handleKeyDown}
              max={max}
              min={min}
              innerRef={this.theInput}
            />
            {suffix}
          </Fragment>
          <NumberInputActions>
            <NumberInputButton
              type="button"
              onClick={this.handleValueUp}
              onMouseDown={MinutesInput.preventFocus}
            >
              <ArrowIcon name="keyboard_arrow_up" />
            </NumberInputButton>
            <NumberInputButton
              type="button"
              onClick={this.handleValueDown}
              onMouseDown={MinutesInput.preventFocus}
            >
              <ArrowIcon name="keyboard_arrow_down" />
            </NumberInputButton>
          </NumberInputActions>
        </NumberInputContainer>
      </NumberInputWrapper>
    );
  }

}

MinutesInput.defaultProps = {
  className: '',
  value: 0,
  min: 0,
  max: 100,
  step: 1,
  suffix: 'mins',
};

MinutesInput.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  suffix: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  onChange: PropTypes.func.isRequired,
  invalidAstroInput: PropTypes.func.isRequired,
};

export default MinutesInput;
