import styled, { css } from 'styled-components';
import { List, Segment } from 'semantic-ui-react';

import { mediaMax } from 'themes/mixins';

export const StyledVoucherInputListContent = styled(List.Content)`
  padding: 8px 0;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
`;

export const StyledSegment = styled(Segment)`
  ${mediaMax.md`
    margin-top: 24px !important;
  `};

  ${({ disabled }) => disabled && css`
    pointer-events: none;
  `}
`;
