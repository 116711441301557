import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';

const SecretKey = ({ secretKey }) => (
  <Segment compact inverted secondary>
    {secretKey}
  </Segment>
);

SecretKey.propTypes = {
  secretKey: PropTypes.string.isRequired,
};

export default SecretKey;
