import { call, takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import logsSectionActionCreators from './logsSectionActionCreators';

export function* fetchLogs(action) {
  const { endpoint, urlParams } = action.payload;
  const { response, error } = yield call(getPath, endpoint, { urlParams });

  const params = {
    response,
    error,
    successDisp: logsSectionActionCreators.fetchLogsSuccess,
    failDisp: logsSectionActionCreators.fetchLogsFailure,
  };
  yield call(processCall, params);
}

function* logsSectionSaga() {
  yield takeLatest(constants.FETCH_LOGS_REQUEST, fetchLogs);
}

export default logsSectionSaga;
