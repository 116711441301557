import { call } from 'redux-saga/effects';

import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';

import actionCreators from '../actionCreators';

function* fetchSubscriptionPlans(action) {
  const { endpoint } = action.payload;

  const { response, error } = yield call(getPath, endpoint);

  const params = {
    response,
    error,
    successDisp: actionCreators.fetchSubscriptionPlansSuccess,
    failDisp: actionCreators.fetchSubscriptionPlansFailure,
  };

  yield call(processCall, params);
}

export default fetchSubscriptionPlans;
