import React from 'react';

import { ActionsStep } from 'application/tenant/console/site/components/structural/SiteSchedulingTab/components/AutomatedOperations/components/AddAutomatedOperation/components';

import { ButtonAppearanceStep } from '../components';

const getControlPanelButtonSteps = ({
  form,
  initialValues,
  isActionsStepValid,
  resourceType,
}) => [
  {
    title: 'Step 1',
    description: 'What should happen when this button is clicked?',
    content: () => (
      <ActionsStep
        resourceType={resourceType}
        panelDescription="Select Tasks & Actions to run when this button is clicked."
      />
    ),
    nextStepDisabled: !isActionsStepValid,
  },
  {
    title: 'Step 2',
    description: 'How the button should look on the Control Panel?',
    content: () => (
      <ButtonAppearanceStep
        form={form}
        initialValues={initialValues}
      />
    ),
    nextStepDisabled: false,
  },
];

export default getControlPanelButtonSteps;
