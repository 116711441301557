import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import UserModel from 'models/UserModel';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { addPagination } from 'helpers/paginationHelpers';
import {
  tenantChangedSelector,
  currentUserSelector,
  controlPanelContextSelector,
  PROJECT_SUITES_FIELD,
  projectSuitesSelector,
  currentCompanyVouchersEnabledSelector,
} from 'helpers/selectors';

import { actionCreators as projectSuiteActionCreators } from 'pages/ProjectSuites/utilities';
import {
  actionCreators as vouchersActionCreators,
  selectors as vouchersSelectors,
} from 'application/tenant/console/site/components/structural/SiteSubscriptionTab/utilities';

import SidebarMenu from './SidebarMenu';

export const mapStateToProps = state => {
  const currentUser = currentUserSelector(state);
  const { superadmin } = new UserModel(currentUser || {});

  return {
    visible: state.menu.visible,
    inControlPanelContext: controlPanelContextSelector(state),
    tenantNotChanged: !tenantChangedSelector(state),
    isSuperAdmin: superadmin,
    projectSuites: projectSuitesSelector(state),
    userId: currentUser.id,
    vouchersEnabled: currentCompanyVouchersEnabledSelector(state),
    canManageVouchers: vouchersSelectors.canManageVouchersSelector(state, currentUser.id),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: PROJECT_SUITES_FIELD },
      ({ userId }) => projectSuiteActionCreators.fetchProjectSuites(
        userId,
        {
          fields: { projectSuites: ['name', 'usersCount', 'createdAt'] },
          sort: 'name',
          filter: 'name',
        },
      ),
    ),
  ),
  chainedLoaderWithSaga(
    ({ userId }) => vouchersActionCreators.loadVoucherPermissions(userId),
  ),
)(SidebarMenu);
