import React from 'react';
import PropTypes from 'prop-types';

import { AUTOMATED_OPERATIONS_FIELD } from 'helpers/selectors';
import DataTable from 'components/DataTable/DataTableContainer';

import { AutomatedOperationsAccordionRow, AutomatedOperationActionLinks } from './components';
import AddAutomatedOperationProject from './components/AddAutomatedOperation/AddAutomatedOperationContainer';
import AddAutomatedOperationProjectSuite from './components/AddAutomatedOperation/AddAutomatedOperationProjectSuiteContainer';
import ProjectSuiteContext from './components/ProjectSuiteContext/ProjectSuiteContext';
import { TASK_SCHEDULER_MODE } from './components/AddAutomatedOperation/constants';

const labels = [
  { text: 'Name', field: 'name', width: 6 },
  { text: 'Description', field: 'description', sortable: false, width: 8 },
  { text: 'Enabled', field: 'enabled', sortable: false, width: 4 },
];

const AutomatedOperations = ({ superSites, enableTaskSchedulerEdit }) => {

  const createElement = () => {
    if (superSites) {
      return <AddAutomatedOperationProjectSuite mode={TASK_SCHEDULER_MODE.DEFAULT} />;
    }

    return <AddAutomatedOperationProject mode={TASK_SCHEDULER_MODE.DEFAULT} />;
  };

  return (
    <ProjectSuiteContext.Provider value={superSites}>
      <DataTable
        header="Task Schedulers"
        labels={labels}
        createElement={createElement()}
        actionLinks={AutomatedOperationActionLinks}
        customTableRow={AutomatedOperationsAccordionRow}
        pagination={{
          type: AUTOMATED_OPERATIONS_FIELD,
          included: true,
          args: {
            withFilter: true,
          },
        }}
        enableTaskSchedulerEdit={enableTaskSchedulerEdit}
      />
    </ProjectSuiteContext.Provider>
  );
};

AutomatedOperations.defaultProps = {
  superSites: false,
  enableTaskSchedulerEdit: true,
};

AutomatedOperations.propTypes = {
  superSites: PropTypes.bool,
  enableTaskSchedulerEdit: PropTypes.bool,
};

export default AutomatedOperations;
