import React from 'react';
import PropTypes from 'prop-types';

import TableMenu from 'components/DataTable/TableMenu.style';
import { DeleteOriginActionLink } from './components';

const OriginActionLinks = ({ chosenItem, ...rest }) => {
  const actionLinks = [DeleteOriginActionLink];

  return (
    <TableMenu.Item>
      {actionLinks.map(ActionLink => (
        <TableMenu.Item key={ActionLink.displayName}>
          <ActionLink item={chosenItem} {...rest} />
        </TableMenu.Item>
      ))}
    </TableMenu.Item>
  );
};

OriginActionLinks.defaultProps = {
  chosenItem: null,
};

OriginActionLinks.propTypes = {
  chosenItem: PropTypes.object,
};


export default OriginActionLinks;
