import { TASKS_FIELD } from 'helpers/selectors';
import ApiRequestBuilder from 'helpers/ApiRequestBuilder';
import uuidv4 from 'uuid/v4';

import ActionModel from './ActionModel';

class TaskModel {

  constructor(task = {}) {
    this.id = task.id;
    this.name = task.name;
    this.description = task.description;
    this.projectId = task.projectId;
    this.projectSuiteId = task.projectSuiteId;
    this.lockVersion = task.lockVersion;
    this.actions = (task.actions || []).map(
      action => new ActionModel(action),
    );
  }

  static requestBody(values) {
    return new ApiRequestBuilder(TASKS_FIELD)
      .setAttributes(values);
  }

  static addActionToTask(task, action) {
    const { actions } = task;
    const { calculateId } = this;
    const id = calculateId(actions);
    const actionsOrEmpty = actions || [];
    const actionWithIndex = {
      ...action,
      index: actionsOrEmpty.length,
      id,
    };
    return {
      ...task,
      actions: [
        ...actionsOrEmpty,
        actionWithIndex,
      ],
    };
  }

  static removeActionFromTask(task, index) {
    const clonedTask = Object.assign({}, task);
    clonedTask.data.attributes.actions.splice(index, 1);

    return clonedTask;
  }

  static calculateId() {
    return uuidv4();
  }

}

export default TaskModel;
