import { SCHEDULES_FIELD } from 'helpers/selectors';
import ApiRequestBuilder from 'helpers/ApiRequestBuilder';

class ScheduleModel {

  constructor(schedule = {}) {
    this.id = schedule.id;
    this.automatedOperationId = schedule.automatedOperationId;
    this.name = schedule.name;
    this.type = schedule.type;
    this.description = schedule.description;
    this.params = schedule.params || {};
    this.lockVersion = schedule.lockVersion;
  }

  static requestBody(values) {
    return new ApiRequestBuilder(SCHEDULES_FIELD)
      .setAttributes(values);
  }

}

export default ScheduleModel;
