import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';

const getFormValues = ({ selector, fieldsArray, fieldsObject, state }) => {
  if (isNull(selector) || isEmpty(fieldsArray) || isEmpty(fieldsObject) || isEmpty(state)) {
    return {};
  }

  const reducer = (accumulator, currentValue) => ({
    [currentValue]: selector(state, fieldsObject[currentValue]),
    ...accumulator,
  });

  return fieldsArray.reduce(reducer, {});
};

export default getFormValues;
