import { call } from 'redux-saga/effects';

import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import actionCreators from '../actionCreators';

function* fetchTwoFactorQRCode(action) {
  const { endpoint } = action.payload;

  const { response, error } = yield call(getPath, endpoint);

  const params = {
    response,
    error,
    successDisp: actionCreators.fetchTwoFactorQRCodeSuccess,
    failDisp: actionCreators.fetchTwoFactorQRCodeFailure,
  };
  yield call(processCall, params);
}

export default fetchTwoFactorQRCode;
