import { call, put } from 'redux-saga/effects';

import endpoints from 'data/api/endpoints';
import { putPath } from 'data/api/requests';
import ScheduleModel from 'models/ScheduleModel';

import automatedOperationsActionCreators from '../../../../utilities/actionCreators';
import { updateOccurrencesWithDelay } from '../../../../utilities/saga/utilities';

function* updateSchedule({
  scheduleId,
  automatedOperationId,
  existingSchedule,
  recurrenceParams,
  schedulerName,
  schedulerDescription,
}) {
  const endpoint = endpoints.UPDATE_SCHEDULE(scheduleId);

  const data = new ScheduleModel({
    ...existingSchedule,
    name: schedulerName || existingSchedule.name,
    description: schedulerDescription || existingSchedule.description,
    params: {
      ...recurrenceParams,
    },
  });

  const body = ScheduleModel.requestBody(data);

  const { response, error } = yield call(putPath, endpoint, body);

  if (response) {
    yield put(automatedOperationsActionCreators.updateScheduleSuccess(response));
  }

  if (error) {
    yield put(automatedOperationsActionCreators.updateScheduleFailure({
      type: 'UPDATE_SCHEDULE',
      error,
    }));
  }

  if (response) {
    yield put(automatedOperationsActionCreators.fetchSchedules(automatedOperationId));
    yield call(updateOccurrencesWithDelay, automatedOperationId);
  }
}

export default updateSchedule;
