import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MenuSwitch from './MainMenu.style';

class MainMenu extends Component {

  componentDidMount() {
    const { onHideMenu } = this.props;

    onHideMenu();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ location: { pathname: nextPathname } }) {
    const { location: { pathname }, onHideMenu } = this.props;

    if (pathname !== nextPathname) {
      onHideMenu();
    }
  }

  componentWillUnmount() {
    const { showMenu } = this.props;

    showMenu();
  }

  render() {
    const { showMenu, inControlPanelContext } = this.props;

    if (inControlPanelContext) {
      return null;
    }

    return (
      <MenuSwitch header link onClick={showMenu}>
        <MenuSwitch.Icon />
      </MenuSwitch>
    );
  }

}

MainMenu.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  onHideMenu: PropTypes.func.isRequired,
  showMenu: PropTypes.func.isRequired,
  inControlPanelContext: PropTypes.bool.isRequired,
};

export default MainMenu;
