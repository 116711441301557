import { put, select } from 'redux-saga/effects';

import selectors from '../selectors';
import actionCreators from '../actionCreators';

function* removePageBackgroundImage(action) {
  const { pageId, projectId, backgroundImg, backgroundImgBlob, ...rest } = action.payload;
  const { pages, ...attributes } = (yield select(selectors.projectControlUISelector))(projectId);

  const newPages = pages.map(({ index, ...page }) => {
    if (index === pageId) {
      return {
        index,
        ...page,
        backgroundImg,
        backgroundImgBlob,
      };
    }

    return {
      index,
      ...page,
    };
  });

  yield put(actionCreators.removeBackgroundImageUpload({
    projectId,
    pageId,
  }));

  yield put(actionCreators.updateControlUI({
    pages: newPages,
    ...attributes,
    ...rest,
  }));
}

export default removePageBackgroundImage;
