import { combineReducers } from 'redux';

import constants from 'dispatcherConst';
import { reducer as applicationUserReducer } from 'application/utilities/user';
import menuReducer from 'components/SidebarMenu/menuReducer';
import dataReducer from 'data/dataReducer';
import inviteUserReducer from 'components/InviteUser/inviteUserReducer';
import notificationsReducer from 'data/notifications/notificationsReducer';
import signUpReducer from 'pages/SignUp/signUpReducer';
import projectReducer from 'pages/Projects/projectReducer';
import deviceReducer from 'pages/Project/deviceReducer';
import currentCompanyReducer from 'themes/currentCompanyReducer';
import { reducer as companiesReducer } from 'application/tenant/console/company/utilities';
import { reducer as companyReducer } from 'application/tenant/console/company/Company/utilities';
import { reducer as settingsReducer } from 'application/tenant/console/settings/Settings/utilities';
import paginationReducer from 'data/paginationReducer';
import { reducer as changePasswordReducer } from 'application/tenant/console/profile/Profile/components/ChangePasswordTab/components/ChangePassword/utilities';
import uploadsReducer from 'components/UploadsWidget/uploadsReducer/uploadsReducer';
import filesSectionReducer from 'components/FilesSection/filesSectionReducer';
import { reducer as outputSectionReducer } from 'application/tenant/console/device/components/structural/OutputSection/utilities';
import pollingReducer from 'helpers/withConfigurablePolling/pollingReducer';
import tablesReducer from 'data/tablesReducer';
import logsSectionReducer from 'components/LogsSection/logsSectionReducer';
import withPollingSaga from 'helpers/withPollingSaga/withPollingSagaReducer';
import resourceUpdateReducer from 'helpers/withResourceUpdate/resourceUpdateReducer';
import { permissionsReducer } from 'application/tenant/console/utilities';
import { reducer as usersReducer } from 'application/tenant/console/utilities/users';
import withValueOverrideReducer from 'helpers/withValueOverride/withValueOverrideReducer';
import websocketsReducer from 'data/websockets/websocketsReducer';
import controlPanelReducer from 'pages/ControlPanel/controlPanelReducer';
import { reducer as schedulingReducer } from 'application/tenant/console/site/components/structural/SiteSchedulingTab/utilities';
import fetchingTriggersReducer
from 'application/tenant/console/site/components/structural/ProjectTasksTab/utilities/reducers/fetchingTriggersReducer';
import fetchingTimelinesReducer
from 'application/tenant/console/site/components/structural/ProjectTasksTab/utilities/reducers/fetchingTimelinesReducer';
import fetchingScenesReducer
from 'application/tenant/console/site/components/structural/ProjectTasksTab/utilities/reducers/fetchingScenesReducer';
import fetchingGroupsReducer
from 'application/tenant/console/site/components/structural/ProjectTasksTab/utilities/reducers/fetchingGroupsReducer';
import { reducer as serviceWorkerReducer } from 'components/TopBar/components/ServiceWorkerAlert/utilities';
import { reducer as taskSchedulerReducer } from 'application/tenant/console/site/components/structural/SiteSchedulingTab/components/AutomatedOperations/components/AddAutomatedOperation/utilities';
import { reducer as formReducer } from 'application/tenant/console/site/components/structural/SiteSchedulingTab/components/AutomatedOperations/components/AddAutomatedOperation/components/ActionsStep/components/SelectedActions/components/ActionItem/components/GenerateActionForm/components/ColorUI/utilities/reducers';
import { reducer as projectsForMapReducer } from 'pages/Projects/components/MapView/utilities';
import { reducer as devicesForSiteReducer } from 'pages/Projects/components/MapView/components/MapWrapper/components/Markers/components/Marker/components/MarkerDevicesTable/utilities';
import { reducer as subscriptionReducer } from 'application/tenant/console/site/components/structural/SiteSubscriptionTab/utilities';
import { reducer as controlPanelUploadsReducer } from 'application/tenant/components/structural/ControlUI/components/ControlUIPageCarousel/components/ConfigurationPanel/components/BackgroundImageUrl/components/UploadImageInput/utilities';
import { reducer as controlPanelCopyPasteReducer } from 'application/tenant/components/structural/ControlUI/components/ControlUIPageCarousel/components/ControlUIPage/components/ControlUIButtonGroup/ControlUIButton/components/utilities';
import { reducer as projectsStatusReducer } from 'pages/Projects/components/StatusWidgets/utilities';
import { reducer as projectSuitesReducer } from 'pages/ProjectSuites/utilities';
import { filterProjectSuiteTasksReducer } from 'pages/ProjectSuite/ProjectSuiteTasksTab/utilities/reducer/filterProjectSuiteTasksReducer';
import { reducer as notificationEventsReducer } from 'components/Notifications/utilities';
import projectSuiteIdContextReducer from 'pages/ProjectSuites/utilities/reducer/projectSuiteIdContextReducer';
import { reducer as permissionsTableReducer } from 'components/PermissionsTable/utilities';

const appReducer = combineReducers({
  login: applicationUserReducer,
  controlPanel: controlPanelReducer,
  signUp: signUpReducer,
  menu: menuReducer,
  data: dataReducer,
  inviteUser: inviteUserReducer,
  usersPage: usersReducer,
  notifications: notificationsReducer,
  form: formReducer,
  projects: projectReducer,
  device: deviceReducer,
  file: filesSectionReducer,
  dmxOutput: outputSectionReducer,
  logs: logsSectionReducer,
  currentCompany: currentCompanyReducer,
  companies: companiesReducer,
  company: companyReducer,
  settings: settingsReducer,
  pagination: paginationReducer,
  changePassword: changePasswordReducer,
  uploads: uploadsReducer,
  polling: pollingReducer,
  tables: tablesReducer,
  pollingSaga: withPollingSaga,
  resourceUpdate: resourceUpdateReducer,
  permissions: permissionsReducer,
  override: withValueOverrideReducer,
  websockets: websocketsReducer,
  scheduling: schedulingReducer,
  triggers: fetchingTriggersReducer,
  serviceWorker: serviceWorkerReducer,
  taskScheduler: taskSchedulerReducer,
  timelines: fetchingTimelinesReducer,
  scenes: fetchingScenesReducer,
  groups: fetchingGroupsReducer,
  projectsForMap: projectsForMapReducer,
  devicesForSite: devicesForSiteReducer,
  subscription: subscriptionReducer,
  controlPanelUploads: controlPanelUploadsReducer,
  controlPanelCopyPaste: controlPanelCopyPasteReducer,
  projectsStatus: projectsStatusReducer,
  projectSuites: projectSuitesReducer,
  fetchingFilter: filterProjectSuiteTasksReducer,
  notificationEvents: notificationEventsReducer,
  projectSuiteIdContext: projectSuiteIdContextReducer,
  permissionsTable: permissionsTableReducer,
});

const rootReducer = (state, action) => {
  if (action.type === constants.LOGOUT) {
    Object.values(state.uploads.workers).forEach(worker => {
      worker.terminate();
    });

    const { currentCompany } = state;
    return appReducer({ currentCompany }, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
