import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header } from 'semantic-ui-react';

import { StyledProjectSuitesTabWrapper, StyledGrid } from './PermissionsProjectSuitesTab.style';
import PermissionsProjectSuiteWrapper from './PermissionsProjectSuiteWrapper/PermissionsProjectSuiteWrapperContainer';
import AddProjectSuiteButton from './AddProjectSuiteButton/AddProjectSuiteButtonContainer';
import ProjectSuitesTable from './ProjectSuitesTable/ProjectSuitesTableContainer';

const PermissionsProjectsTab = ({ projectSuiteIds }) => (
  <StyledProjectSuitesTabWrapper>
    <StyledGrid>
      <Grid.Row>
        <Grid.Column>
          <Header as="h1">Multi-site permissions</Header>
          <Header as="h3">Add a Multi-site to view and edit its permissions</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          {projectSuiteIds && projectSuiteIds.map(projectSuiteId => (
            <PermissionsProjectSuiteWrapper key={projectSuiteId} projectSuiteId={projectSuiteId} />
          ))}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <AddProjectSuiteButton />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <ProjectSuitesTable />
        </Grid.Column>
      </Grid.Row>
    </StyledGrid>
  </StyledProjectSuitesTabWrapper>
);

PermissionsProjectsTab.defaultProps = {
  projectSuiteIds: null,
};

PermissionsProjectsTab.propTypes = {
  projectSuiteIds: PropTypes.array,
};

export default PermissionsProjectsTab;
