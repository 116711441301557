import React from 'react';
import PropTypes from 'prop-types';

import ThemedToggle from 'components/elements/ThemedToggle/ThemedToggle.style';
import { StyledLabel, StyledToggle } from './TestConditions.style';

const TestConditions = ({ onToggleState, testConditions }) => (
  <StyledToggle>
    <StyledLabel>Test conditions</StyledLabel>
    <ThemedToggle onClick={onToggleState} checked={testConditions} />
  </StyledToggle>
);

TestConditions.defaultProps = {
  onToggleState: null,
};

TestConditions.propTypes = {
  onToggleState: PropTypes.func,
  testConditions: PropTypes.bool.isRequired,
};

export default TestConditions;
