import { compose } from 'redux';
import { connect } from 'react-redux';

import { frontendPermissions, permissionsPass } from 'gateway';
import automatedOperationsActionCreators from '../../../../../../../utilities/actionCreators';
import withDeleteSchedule from './withDeleteSchedule';

const mapDispatchToProps = {
  onDeleteSchedule: automatedOperationsActionCreators.updateAutomatedFunctionRemoveSchedule,
};

export default compose(
  connect(null, mapDispatchToProps),
  permissionsPass(frontendPermissions.DELETE_SCHEDULE),
  withDeleteSchedule,
);
