import mergeWith from 'lodash/mergeWith';
import has from 'lodash/has';

import constants from 'dispatcherConst';
import { mergeCustomizer } from 'helpers/sharedMethods';

import reduceAutomatedOperationsWithProjectId from './reduceAutomatedOperationsWithProjectId';
import reduceAutomatedOperationsWithProjectSuiteId from './reduceAutomatedOperationsWithProjectSuiteId';

const automatedOperationsDataReducer = (state, action) => {
  switch (action.type) {

    case constants.FETCH_AUTOMATED_OPERATION_SUCCESS:
      return mergeWith({}, state, action.payload.response, mergeCustomizer);

    case constants.FETCH_AUTOMATED_OPERATIONS_SUCCESS: {
      const { response } = action.payload;
      const { automatedOperations: originalAutomatedOperations = {} } = response;

      const values = Object.values(originalAutomatedOperations);

      let automatedOperations = null;

      if (has(response, 'projectId')) {
        const { projectId } = response;

        automatedOperations = values.reduce(
          reduceAutomatedOperationsWithProjectId(projectId), {},
        );

      } else if (has(response, 'projectSuiteId')) {
        const { projectSuiteId } = response;

        automatedOperations = values.reduce(
          reduceAutomatedOperationsWithProjectSuiteId(projectSuiteId), {},
        );
      }

      return mergeWith({}, state, { automatedOperations }, mergeCustomizer);
    }

    default:
      return state;
  }
};

export default automatedOperationsDataReducer;
