import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isUndefined from 'lodash/isUndefined';
import isNil from 'lodash/isNil';

import { LOGICAL_DEVICES_FIELD, deviceSelector } from 'helpers/selectors';
import deviceActionCreators from 'pages/Project/deviceActionCreators';

const useDevice = ({ deviceId, fields = [] }) => {
  const device = useSelector(deviceSelector)(deviceId);
  const dispatch = useDispatch();
  const allFieldsExist = !isNil(device) && fields.every(field => !isUndefined(device[field]));
  const serializedFields = fields.join('.');

  useEffect(() => {
    if (!allFieldsExist) {
      const action = deviceActionCreators.fetchDevice(
        deviceId,
        {
          fields: {
            [LOGICAL_DEVICES_FIELD]: fields,
          },
        },
      );

      if (!isNil(deviceId)) {
        dispatch(action);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFieldsExist, deviceId, serializedFields, dispatch]);

  return device;
};

export default useDevice;
