import { compose } from 'redux';
import { connect } from 'react-redux';
import { reset } from 'redux-form';

import { settingTypes } from 'helpers/sharedVariables';
import {
  deviceErrorSelector,
  deviceFetchingSelector, deviceSelector,
  LOGICAL_DEVICES_FIELD,
  pollingRefreshingSelector,
} from 'helpers/selectors';
import deviceActionCreators from 'pages/Project/deviceActionCreators';
import updateSettingsActionCreators from 'pages/Device/updateSettingsActionCreators';
import withConfigurablePolling
  from 'helpers/withConfigurablePolling/withConfigurablePollingContainer';
import chainedLoader from 'helpers/chainedLoader/chainedLoaderContainer';
import PharosWatchdogV1, { DEVICE_WATCHDOG_REFRESH } from './PharosWatchdogV1';

export const mapStateToProps = (state, ownProps) => {
  const { resourceId } = ownProps;
  const resource = deviceSelector(state)(resourceId);

  return {
    deviceId: resourceId,
    chainedLoaderProps: {
      [DEVICE_WATCHDOG_REFRESH]: {
        fetching: deviceFetchingSelector(state),
        error: deviceErrorSelector(state),
      },
    },
    pollingRefreshing: pollingRefreshingSelector(state, settingTypes.WATCHDOG),
    resource,
  };
};

const mapDispatchToProps = {
  onToggleWatchdog: updateSettingsActionCreators.toggleWatchdog,
  onReset: reset,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withConfigurablePolling(DEVICE_WATCHDOG_REFRESH),
  chainedLoader(
    { name: DEVICE_WATCHDOG_REFRESH },
    ({ deviceId }) => deviceActionCreators.fetchDevice(deviceId, {
      fields: {
        [LOGICAL_DEVICES_FIELD]: [
          'online',
          'reported',
          'reportedMeta',
          'updated',
        ],
      },
    }),
  ),
)(PharosWatchdogV1);
