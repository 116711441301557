import React from 'react';
import PropTypes from 'prop-types';

const withProjectSuitePermissions = WrappedComponent => {
  const WithProjectSuitePermissions = ({ ready, ...rest }) => {
    if (!ready) {
      return null;
    }

    return <WrappedComponent {...rest} />;
  };

  WithProjectSuitePermissions.defaultProps = {
    ready: false,
  };

  WithProjectSuitePermissions.propTypes = {
    ready: PropTypes.bool,
  };

  return WithProjectSuitePermissions;
};

export default withProjectSuitePermissions;
