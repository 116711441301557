import React from 'react';
import PropTypes from 'prop-types';

import { PROJECTS_FIELD } from 'helpers/selectors';
import DataTable from 'components/DataTable/DataTableContainer';
import { PageHeader } from 'components/core';

import { AddProject } from './AddProject';
import ProjectActionLinks from './ProjectActionLinks/ProjectActionLinks';
import { MapView, StatusWidgets } from './components';

const projectsTableLabels = [
  { text: 'Site', field: 'visibleNameWithState', sortBy: 'name' },
  { text: 'Team', field: 'team', sortable: false },
  { text: 'Devices', field: 'devices', sortable: false },
  { text: 'Renewal Date', field: 'dateRenewal', sortBy: 'renewalDate' },
];

const Projects = ({ route, displayMap, statusWidgetsType }) => (
  <div>
    <PageHeader title="Sites" />
    <StatusWidgets type={statusWidgetsType} />
    {displayMap && <MapView />}
    <DataTable
      labels={projectsTableLabels}
      actionLinks={ProjectActionLinks}
      createElement={AddProject}
      route={route}
      pagination={{
        type: PROJECTS_FIELD,
        args: {
          self: true,
          withFilter: true,
        },
      }}
    />
  </div>
);

Projects.defaultProps = {
  displayMap: false,
  statusWidgetsType: 'user',
};

Projects.propTypes = {
  route: PropTypes.string.isRequired,
  displayMap: PropTypes.bool,
  statusWidgetsType: PropTypes.string,
};

export default Projects;
