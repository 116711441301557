import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import NotificationEventModel from 'models/NotificationEventModel';

import { StyledNotificationsLogTableRow } from './NotificationsLogTableRow.style';

const NotificationsLogTableRow = ({
  item: notificationEvent,
  history,
  ...rest
}) => {
  const notificationEventModel = new NotificationEventModel(notificationEvent);

  const handleClick = useCallback(() => {
    const { deviceUrl } = notificationEventModel;

    if (!deviceUrl) {
      return;
    }

    history.push(deviceUrl);
  }, [notificationEventModel, history]);

  return (
    <StyledNotificationsLogTableRow
      {...rest}
      item={notificationEventModel}
      onClick={handleClick}
    />
  );
};

NotificationsLogTableRow.propTypes = {
  item: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(NotificationsLogTableRow);
