import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import { USERS_FIELD } from 'helpers/selectors';

const inviteUserActionCreators = {
  inviteUser: params => ({
    type: constants.INVITE_USER_REQUEST,
    payload: {
      params,
      endpoint: endpoints.INVITE_USER,
    },
  }),

  inviteUserSuccess: response => {
    const { email } = Object.values(response.users)[0].attributes;
    return {
      type: constants.INVITE_USER_SUCCESS,
      payload: {
        response,
        message: messages.USER_INVITED(email),
        resourceType: USERS_FIELD,
        id: response.data.id,
      },
    };
  },

  inviteUserFailure: error => ({
    type: constants.INVITE_USER_FAILED,
    payload: { error },
  }),
};

export default inviteUserActionCreators;
