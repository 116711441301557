import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'semantic-ui-react';

import { FormFieldWrapper } from 'components/form/FormField/FormField.style';
import { StyledFormFieldLabel } from './SectionFormTitle.style';

const SectionFormTitle = ({ title }) => (
  <FormGroup inline>
    <FormFieldWrapper width={16}>
      <StyledFormFieldLabel>{title}</StyledFormFieldLabel>
    </FormFieldWrapper>
  </FormGroup>
);

SectionFormTitle.defaultProps = {
  title: '',
};

SectionFormTitle.propTypes = {
  title: PropTypes.string,
};

export default SectionFormTitle;
