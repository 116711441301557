import { call, select } from 'redux-saga/effects';

import { postPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';

import actionCreators from '../actionCreators';
import selectors from '../selectors';

function* fetchVoucherDetails(action) {
  const { endpoint, voucher } = action.payload;

  const { id: companyId } = (yield select(selectors.selectedTenantSelector)) || {};

  const payload = {
    data: {
      voucherCode: voucher,
      companyId,
    },
  };

  const { response, error } = yield call(postPath, endpoint, payload);

  const params = {
    response,
    error,
    successDisp: actionCreators.fetchVoucherDetailsSuccess,
    failDisp: actionCreators.fetchVoucherDetailsFailure,
  };

  yield call(processCall, params);
}

export default fetchVoucherDetails;
