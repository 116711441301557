import PropTypes from 'prop-types';

import { resourceType } from 'data/types';

const ResourceBreadcrumb = ({ resource, fieldName }) => {
  if (!resource) return null;

  return resource[fieldName];
};

ResourceBreadcrumb.defaultProps = {
  fieldName: 'name',
  resource: null,
};

ResourceBreadcrumb.propTypes = {
  fieldName: PropTypes.string,
  resource: resourceType,
};

export default ResourceBreadcrumb;
