import React from 'react';
import PropTypes from 'prop-types';

import DropdownArrow from 'components/elements/DropdownArrow/DropdownArrow';
import {
  StyledTaskActionLinkCell,
} from '../../../../../../SiteSchedulingTab.style';
import EditAutomatedOperationColour from '../../../EditAutomatedOperationColour/EditAutomatedOperationColourContainer';

const ScheduledTaskRadio = ({
  radio,
  item,
  open,
}) => (
  <StyledTaskActionLinkCell>
    {radio}
    <DropdownArrow open={open} />
    <EditAutomatedOperationColour item={item} />
  </StyledTaskActionLinkCell>
);

ScheduledTaskRadio.defaultProps = {
  open: false,
  item: {
    occurrenceColour: null,
  },
};

ScheduledTaskRadio.propTypes = {
  open: PropTypes.bool,
  item: PropTypes.object,
  radio: PropTypes.element.isRequired,
};

export default ScheduledTaskRadio;
