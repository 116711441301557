import constants from 'dispatcherConst';

const changePermissionsTableRecipients = ({
  recipientIds,
  resourceId,
  parentResourceId,
  childResourceIds,
}) => ({
  type: constants.CHANGE_RECIPIENTS_PERMISSIONS_TABLE_REQUEST,
  payload: {
    recipientIds,
    resourceId,
    parentResourceId,
    childResourceIds,
  },
});

export default changePermissionsTableRecipients;
