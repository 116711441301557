import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';

const performActionActionCreators = {
  performReset: deviceId => ({
    type: constants.PERFORM_RESET_REQUEST,
    payload: {
      endpoint: endpoints.PERFORM_ACTION(deviceId),
    },
  }),

  performResetSuccess: response => ({
    type: constants.PERFORM_RESET_SUCCESS,
    payload: { response },
  }),

  performResetFailure: error => ({
    type: constants.PERFORM_RESET_FAILED,
    payload: { error },
  }),

  performFormat: deviceId => ({
    type: constants.PERFORM_FORMAT_REQUEST,
    payload: {
      endpoint: endpoints.PERFORM_ACTION(deviceId),
    },
  }),

  performFormatSuccess: response => ({
    type: constants.PERFORM_FORMAT_SUCCESS,
    payload: { response },
  }),

  performFormatFailure: error => ({
    type: constants.PERFORM_FORMAT_FAILED,
    payload: { error },
  }),

  performTriggers: (deviceId, params) => ({
    type: constants.PERFORM_TRIGGERS_REQUEST,
    payload: {
      endpoint: endpoints.PERFORM_ACTION(deviceId),
      params,
    },
  }),

  performTriggersCond: (deviceId, params) => ({
    type: constants.PERFORM_TRIGGERS_COND_REQUEST,
    payload: {
      endpoint: endpoints.PERFORM_ACTION(deviceId),
      params,
    },
  }),

  performTriggersSuccess: response => ({
    type: constants.PERFORM_TRIGGERS_SUCCESS,
    payload: {
      response,
      message: messages.TRIGGER_QUEUED,
    },
  }),

  performTriggersFailure: error => ({
    type: constants.PERFORM_TRIGGERS_FAILED,
    payload: { error },
  }),


  performActionRequest: ({ deviceId, ...rest }) => ({
    type: constants.PERFORM_ACTION_REQUEST,
    payload: {
      endpoint: endpoints.PERFORM_ACTION(deviceId),
      ...rest,
    },
  }),

  performActionSuccess: response => ({
    type: constants.PERFORM_ACTION_SUCCESS,
    payload: { response },
  }),

  performActionFailure: error => ({
    type: constants.PERFORM_ACTION_FAILED,
    payload: { error },
  }),

  performSyncFirmwareRequest: ({ deviceId, ...rest }) => ({
    type: constants.PERFORM_SYNC_FIRMWARE_REQUEST,
    payload: {
      endpoint: endpoints.PERFORM_ACTION(deviceId),
      deviceId,
      ...rest,
    },
  }),

  performSyncFirmwareSuccess: response => ({
    type: constants.PERFORM_SYNC_FIRMWARE_SUCCESS,
    payload: {
      response,
      message: messages.PERFORMED_SYNC_FIRMWARE,
    },
  }),

  performSyncFirmwareFailure: error => ({
    type: constants.PERFORM_SYNC_FIRMWARE_FAILED,
    payload: { error },
  }),
};

export default performActionActionCreators;
