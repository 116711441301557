import React from 'react';
import PropTypes from 'prop-types';

import { usePermissionsTable } from '../../utilities';

import {
  StyledTableRow,
  StyledTableColumn,
  StyledPermissionName,
  StyledCheckbox,
} from '../../PermissionsTable.style';

function TableBody({
  rows,
  resourceId,
  parentResourceId,
  resourceType,
  resourceName,
  recipientType,
  context,
  copyRecipientId,
  cumulatedPermissions,
  limitedScope,
  limitedFor,
  onGrantPermissions,
  onRevokePermissions,
}) {

  const {
    isRowVisible,
    isRowOpen,
    RowIcon,
    onToggleRowVisibility,
    onGrantRevokePermissions,
  } = usePermissionsTable({
    resourceId,
    parentResourceId,
    resourceType,
    resourceName,
    recipientType,
    limitedScope,
    limitedFor,
    context,
    cumulatedPermissions,
    onGrantPermissions,
    onRevokePermissions,
  });

  return (
    <tbody>
      {rows.map(row => {
        const {
          name,
          level,
          hasChild,
        } = row;

        const isVisible = isRowVisible(row);
        const isOpen = isRowOpen(row);

        return (
          <StyledTableRow name="permission-table-row" key={row.key} isVisible={isVisible}>
            <StyledTableColumn
              name="permission-name-column"
              level={level}
              onClick={() => onToggleRowVisibility(row)}
              permissionNameColumn
            >
              <StyledPermissionName level={level}>
                <RowIcon hasChild={hasChild} isOpen={isOpen} />
                {name}
              </StyledPermissionName>
            </StyledTableColumn>
            {row.recipients.map(recipient => (
              <StyledTableColumn
                name="recipient-name-column"
                key={recipient.id}
                recipientColumn
              >
                <StyledCheckbox
                  isCopyMode={copyRecipientId === recipient.id}
                  status={recipient.checkboxState}
                  onClick={() => onGrantRevokePermissions(recipient.id, row)}
                />
              </StyledTableColumn>
            ))}
          </StyledTableRow>
        );
      })}
    </tbody>
  );
}

TableBody.defaultProps = {
  rows: [],
  cumulatedPermissions: {},
  context: null,
  copyRecipientId: null,
  limitedScope: [],
  limitedFor: ['user'],
  recipientType: 'user',
  parentResourceId: null,
  resourceName: null,
};

TableBody.propTypes = {
  rows: PropTypes.array,
  resourceId: PropTypes.string.isRequired,
  resourceType: PropTypes.oneOf([
    'project',
    'project_suite',
    'logical_device',
    'user',
    'tenant',
  ]).isRequired,
  copyRecipientId: PropTypes.string,
  context: PropTypes.string,
  cumulatedPermissions: PropTypes.object,
  limitedScope: PropTypes.arrayOf(
    PropTypes.string,
  ),
  limitedFor: PropTypes.arrayOf(
    PropTypes.string,
  ),
  recipientType: PropTypes.string,
  resourceName: PropTypes.string,
  parentResourceId: PropTypes.string,
  onGrantPermissions: PropTypes.func.isRequired,
  onRevokePermissions: PropTypes.func.isRequired,
};

export default TableBody;
