import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  actionCreators as clearCopyActionCreators,
} from 'application/tenant/components/structural/ControlUI/components/ControlUIPageCarousel/components/ControlUIPage/components/ControlUIButtonGroup/ControlUIButton/components/utilities';
import { selectors, actionCreators } from '../../../../../../utilities';
import RemovePage from './RemovePage';

export const mapStateToProps = (state, { match, pageId }) => {
  const { id: projectId } = match.params;
  const { pages = [] } = selectors.projectControlUISelector(state)(projectId) || {};
  const { name } = pages.filter(({ index }) => index === pageId)[0] || {};

  return {
    name,
    projectId,
  };
};

const mapDispatchToProps = {
  onDeletePage: actionCreators.deletePage,
  onClearCopy: clearCopyActionCreators.clearCopy,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(RemovePage);
