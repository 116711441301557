import { PROJECTS_FOR_MAP_FIELD } from 'helpers/selectors';
import constants from 'dispatcherConst';

import gerMarkerType from '../getMarkerType';

const dataReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.FETCH_PROJECTS_FOR_MAP_SUCCESS: {
      const { data } = action.payload.response;
      const projectsForMap = data.reduce((acc, project) => {
        const {
          id,
          attributes: {
            name,
            longitude,
            latitude,
            onlineDevices,
            offlineDevices,
            unassociatedDevices,
            devicesForUpgrade,
          },
        } = project;

        const calculateTypeParams = {
          onlineDevices,
          offlineDevices,
          unassociatedDevices,
          devicesForUpgrade,
        };
        const type = gerMarkerType(calculateTypeParams);

        return {
          ...acc,
          [id]: {
            id,
            attributes: {
              name,
              longitude,
              latitude,
              type,
            },
          },
        };
      }, {});

      return {
        ...state,
        [PROJECTS_FOR_MAP_FIELD]: projectsForMap,
      };
    }

    default:
      return state;
  }
};

export default dataReducer;
