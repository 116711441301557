import { connect } from 'react-redux';

import UserModel from 'models/UserModel';
import { currentUserSelector } from 'helpers/selectors';

import { selectors } from './utilities';
import ServiceWorkerAlert from './ServiceWorkerAlert';

function mapStateToProps(state) {
  const currentUser = currentUserSelector(state);
  const { id } = new UserModel(currentUser || {});

  return {
    currentUserId: id,
    isInitialized: selectors.isInitializedSelector(state),
    isUpdated: selectors.isUpdatedSelector(state),
    registration: selectors.registrationSelector(state),
  };
}

export default connect(mapStateToProps, null)(ServiceWorkerAlert);
