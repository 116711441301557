import { call, put } from 'redux-saga/effects';

import { postPath } from 'data/api/requests';
import TaskModel from 'models/TaskModel';
import tasksActionCreators from 'application/tenant/utilities/tasks/tasksActionCreators';

import { RESOURCE_TYPE_PROPERTY_NAME } from '../../constants';
import taskScheduleActionCreators from '../actionCreators';

function* saveTask({
  endpoint,
  taskName,
  resourceId,
  resourceType,
}) {
  const propertyName = RESOURCE_TYPE_PROPERTY_NAME[resourceType];

  const params = {
    [propertyName]: resourceId,
    name: taskName,
    description: taskName,
  };

  const body = TaskModel.requestBody(params);

  const { response, error } = yield call(postPath, endpoint, body);

  if (response) {
    yield put(tasksActionCreators.addTaskSuccess(response));
  }

  if (error) {
    yield put(taskScheduleActionCreators.addTaskSchedulerFailure({
      type: 'SAVE_TASK',
      error,
    }));

    return null;
  }

  const task = {
    id: response.data.id,
    ...response.tasks[response.data.id].attributes,
  };

  return task;
}

export default saveTask;
