import isNull from 'lodash/isNull';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';

function filterTriggers(value, deviceTriggers) {
  if (!isNull(value) && isArray(deviceTriggers) && !isEmpty(value)) {
    const valueToArray = value.split(', ').map(item => parseInt(item, 10));
    const assignTriggerInfo = valueToArray.map(item => {
      const object = find(deviceTriggers, ['id', item])
        ? find(deviceTriggers, ['id', item])
        : { id: item, name: 'The resource is missing', missingTrigger: true };

      return object;
    });

    return assignTriggerInfo;
  }

  return [];
}

export default filterTriggers;
