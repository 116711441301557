import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const updateControlUI = ({ id, ...args }) => ({
  type: constants.UPDATE_CONTROL_UI_REQUEST,
  payload: {
    id,
    ...args,
    endpoint: endpoints.CONTROL_UI_PAGE(id),
  },
});

export const updateControlUISuccess = response => ({
  type: constants.UPDATE_CONTROL_UI_SUCCESS,
  payload: { response },
});

export const updateControlUIFailure = error => ({
  type: constants.UPDATE_CONTROL_UI_FAILED,
  payload: { error },
});
