import styled, { css } from 'styled-components';
import get from 'lodash/get';

import { mediaMax } from 'themes/mixins';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const PageTypeOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  img {
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    transition: border-color 0.2s;
  }

  ${({ theme, selected }) => selected && css`
    img {
      border: 2px solid ${get(theme, 'palette.primaryColor')} !important;
    }
  `}

  span {
    margin-top: 8px;
  }
`;

export const PageTypeIcon = styled.img`
  max-width: 90%;

  ${({ type }) => type === 'default' && css`
    content: url('/assets/PageTypeOption.png');  
  `}

  ${({ type }) => type === 'buttons_override_controls' && css`
    content: url('/assets/PageTypeOption2.png');  
  `}

  ${({ type }) => type === 'override_controls_buttons' && css`
    content: url('/assets/PageTypeOption1.png');  
  `}

  ${({ type }) => type === 'override_controls' && css`
    content: url('/assets/PageTypeOption3.png');  
  `}
`;

export const PageTypeDescription = styled.span`
 ${mediaMax.sm`
    font-size: 12px;
  `};
`;

export default Container;
