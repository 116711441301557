import { connect } from 'react-redux';

import projectFileUploadActionCreators from './projectFileUploadActionCreators';
import ProjectFileUpload from './ProjectFileUpload';

const mapDispatchToProps = {
  onPrepareUpload: projectFileUploadActionCreators.prepareUpload,
  onFileNotFoundResume: projectFileUploadActionCreators.fileNotFoundResume,
};

export default connect(null, mapDispatchToProps)(ProjectFileUpload);
