import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  deviceSelector,
  FILE_UPLOADS_FIELD,
} from 'helpers/selectors';
import filesActionCreators from 'pages/Device/filesActionCreators';
import withPolling from 'helpers/withPollingSaga/withPollingSagaContainer';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { addPagination } from 'helpers/paginationHelpers';
import FilesSection from './FilesSection';

const REFRESH_RATE = 60000;

export const mapStateToProps = (state, { match }) => {
  const { deviceId } = match.params;

  const {
    online,
    reported: {
      sf: accept,
    } = {},
  } = deviceSelector(state)(deviceId);

  return {
    online,
    deviceId,
    accept,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: FILE_UPLOADS_FIELD, included: true },
      ({ deviceId }) => filesActionCreators.fetchFiles(deviceId, {
        sort: 'name',
      }),
    ),
  ),
  withPolling(REFRESH_RATE),
)(FilesSection);
