import styled, { css } from 'styled-components';
import get from 'lodash/get';
import { Icon } from 'semantic-ui-react';

import { mediaMax } from 'themes/mixins';

export const Container = styled.div`
  padding: 2px;
  height: 50vh;
  overflow: auto;

  ${({ flex }) => flex && css`
    display: flex;
    justify-content: center;
    align-items: center;
  `}

  ${mediaMax.sm`
    max-height: calc(100vh - 415px);
  `};
`;

export const EmptyMessage = styled.p`
 text-align: center;
  font-size: 16px;
`;

export const ActionsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  min-height: 30vh;
  max-height: 50vh; 
  overflow: auto;

  ${mediaMax.sm`
    min-height: unset;
    max-height: unset; 
    overflow: unset;
  `};
`;

export const ActionItem = styled.li`
  ${({ theme }) => css`
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    width: 96%;
    cursor: pointer;
    transition: border-color 0.3s;

    &:focus{
      border-color: ${get(theme, 'palette.primaryColor')} !important;
    }

    &:hover{
      border-color: ${get(theme, 'palette.primaryColor')} !important;
    }

    & + li {
      margin-top: 8px;
    }

    &:last-child {
      margin-bottom: 35px;
    }

    p { 
      margin: 0;
      font-weight: 500;
      font-size: 15px;
    }
  `}
`;

export const StyledIcon = styled(Icon)`
  &.icon {
    height: auto !important;
  }
`;

export default ActionsList;
