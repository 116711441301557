/*
 eslint-disable import/no-webpack-loader-syntax,
  import/no-extraneous-dependencies,
  import/no-unresolved
*/
import fileUploadWorker from 'workerize-loader!./fileUpload.worker';
/*
 eslint-enable import/no-webpack-loader-syntax,
  import/no-extraneous-dependencies,
  import/no-unresolved
*/

export default fileUploadWorker;
