import selectedDeviceSelector from './selectedDeviceSelector';
import selectedActionsSelector from './selectedActionsSelector';
import schedulerTypeSelector from './schedulerTypeSelector';
import schedulerParamsSelector from './schedulerParamsSelector';
import isLoadingSelector from './isLoadingSelector';
import selectedTasksSelector from './selectedTasksSelector';
import isLoadingTasksSelector from './isLoadingTasksSelector';
import taskNameSelector from './taskNameSelector';
import availableTasksSelector from './availableTasksSelector';
import recurrenceParamsSelector from './recurrenceParamsSelector';
import endingCriteriaSelector from './endingCriteriaSelector';
import recurrenceErrorsSelector from './recurrenceErrorsSelector';
import taskSchedulerModeSelector from './taskSchedulerModeSelector';
import existingScheduleSelector from './existingScheduleSelector';
import projectDevicesSelector from './projectDevicesSelector';
import isLoadingProjectDevicesSelector from './isLoadingProjectDevicesSelector';
import schedulerNameSelector from './schedulerNameSelector';
import schedulerDescriptionSelector from './schedulerDescriptionSelector';
import selectedProjectSelector from './selectedProjectSelector';

export default {
  selectedDeviceSelector,
  selectedActionsSelector,
  schedulerTypeSelector,
  schedulerParamsSelector,
  isLoadingSelector,
  selectedTasksSelector,
  isLoadingTasksSelector,
  taskNameSelector,
  availableTasksSelector,
  recurrenceParamsSelector,
  endingCriteriaSelector,
  recurrenceErrorsSelector,
  taskSchedulerModeSelector,
  existingScheduleSelector,
  projectDevicesSelector,
  isLoadingProjectDevicesSelector,
  schedulerNameSelector,
  schedulerDescriptionSelector,
  selectedProjectSelector,
};
