import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

import EditAutomatedOperation from '../EditAutomatedOperation/EditAutomatedOperationContainer';
import { DeleteAutomatedOperationActionLink } from '../DeleteAutomatedOperation';

const processButtonList = (isMultiSite, projectId, name) => {
  if (isMultiSite && !isNil(projectId) && !isEmpty(name)) {
    return [EditAutomatedOperation];
  }

  return [DeleteAutomatedOperationActionLink, EditAutomatedOperation];
};

export default processButtonList;
