import React from 'react';
import { FlatIcon } from 'components/elements';
import duration from 'format-duration';

import { getSearchedCount, elideToLength } from 'helpers/sharedMethods';

export default class UploadModel {

  constructor(
    {
      accessKeyId,
      systemFile,
      fileKey,
      fileTypeId,
      device,
      deviceId,
      progress,
      secondsLeft,
      initialized,
      pausing,
      paused,
      resuming,
      queued,
      completed,
      cancelling,
      fetching,
    },
    allSuspended = false,
  ) {
    this.accessKeyId = accessKeyId;
    this.systemFile = systemFile;
    this.fileKey = fileKey;
    this.fileTypeId = fileTypeId;
    this.device = device;
    this.deviceId = deviceId;
    this.progress = progress * 100;
    this.time = secondsLeft;
    this.statusFlags = {
      initialized,
      pausing,
      paused,
      resuming,
      queued,
      completed,
      cancelling,
      fetching,
    };
    this.name = systemFile.name;

    this.allSuspended = allSuspended;
  }

  get isPaused() {
    const { paused } = this.statusFlags;

    return paused;
  }

  get status() {
    const { paused, queued, completed } = this.statusFlags;
    switch (true) {
      case paused:
        return 'Paused';
      case queued:
        return 'Queued';
      case completed:
        return 'Completed';
      default:
        return 'Uploading';
    }
  }

  get remainingTime() {
    const { time } = this;

    return (time >= 0) ? duration(time * 1000) : 'Unknown';
  }

  get buttonProps() {
    const {
      allSuspended,
      statusFlags: { completed, fetching },
    } = this;

    return {
      disabled: !completed && (allSuspended || fetching),
      icon: this.buttonIcon(),
    };
  }

  get playProps() {
    const {
      allSuspended,
      statusFlags: { completed, fetching, paused, queued },
    } = this;

    return {
      disabled: allSuspended || fetching || completed || (!paused && !queued),
      icon: UploadModel.playIcon(),
    };
  }

  get pauseResumeProps() {
    const {
      allSuspended,
      statusFlags: { completed, fetching },
    } = this;

    return {
      disabled: allSuspended || completed || fetching,
      icon: this.pauseResumeIcon(),
    };
  }

  buttonIcon() {
    const { statusFlags: { completed } } = this;

    switch (true) {
      case completed:
        return <FlatIcon name="check" />;
      default:
        return <FlatIcon name="close" />;
    }
  }

  pauseResumeIcon() {
    const { paused, resuming } = this.statusFlags;

    return paused || resuming
      ? <FlatIcon name="refresh" />
      : <FlatIcon name="pause" />;
  }

  static playIcon() {
    return <FlatIcon name="play_arrow" />;
  }

  static sortExtensionsByImportance(extensionList) {
    const separator = '.';

    return extensionList.sort((ext1, ext2) => (
      getSearchedCount(ext2, separator) - getSearchedCount(ext1, separator)
    ));
  }

  get shortName() {
    return elideToLength(this.name, 15);
  }

}
