import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import DataTableText from 'components/DataTable/DataTableText/DataTableText';
import StatusIcon from 'components/StatusIcon/StatusIcon';
import {
  RemoteDeviceRadio,
  StyledTableRow,
  StyledTableRowAccordion,
  InputOutputTable,
  RemoteDevicesSubTable,
  StyledTableCell,
} from './components';

const RemoteDeviceAccordionRow = props => {
  const { item, onToggleRow, rowToDisplay } = props;
  const {
    id,
    statusIcon,
    showRemoteSubDevices,
    showInputs,
    showOutputs,
  } = item;
  const open = id === rowToDisplay;
  const showInputsOutputs = showInputs || showOutputs;

  return (
    <Fragment>
      <StyledTableRow
        {...props}
        customRadioButton={RemoteDeviceRadio}
        onClick={() => onToggleRow(id)}
        open={open}
      >
        <DataTableText />
        <DataTableText />
        <DataTableText />
        <StatusIcon {...statusIcon} />
      </StyledTableRow>
      {open && (
        <Fragment>
          {showInputsOutputs && (
            <StyledTableRowAccordion>
              <Table.Cell colSpan={5}>
                <InputOutputTable item={item} />
              </Table.Cell>
            </StyledTableRowAccordion>
          )}
          {showRemoteSubDevices && (
            <StyledTableRowAccordion>
              <StyledTableCell colSpan={5}>
                <RemoteDevicesSubTable item={item} />
              </StyledTableCell>
            </StyledTableRowAccordion>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

RemoteDeviceAccordionRow.defaultProps = {
  item: null,
  onToggleRow: null,
  rowToDisplay: null,
};

RemoteDeviceAccordionRow.propTypes = {
  item: PropTypes.object,
  onToggleRow: PropTypes.func,
  rowToDisplay: PropTypes.string,
};

export default RemoteDeviceAccordionRow;
