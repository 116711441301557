import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import { SiteConstructionSettings } from 'application/tenant/console/site/components';
import { SubscriptionStatus, ManageSubscription } from './components';

const SiteSubscriptionTab = ({ vouchersEnabled }) => (
  <Grid>
    <Grid.Row>
      <Grid.Column width={16}>
        <SiteConstructionSettings />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column computer={8} mobile={16}>
        <SubscriptionStatus />
      </Grid.Column>
      <Grid.Column computer={8} mobile={16}>
        {vouchersEnabled && <ManageSubscription /> }
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

SiteSubscriptionTab.defaultProps = {
  vouchersEnabled: false,
};

SiteSubscriptionTab.propTypes = {
  vouchersEnabled: PropTypes.bool,
};

export default SiteSubscriptionTab;
