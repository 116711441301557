import styled from 'styled-components';
import { Menu } from 'semantic-ui-react';

const StyledMenu = styled(Menu)`
  &.ui.vertical.menu {
    width: 100%;
    min-width: 250px;
  }
`;

export default StyledMenu;
