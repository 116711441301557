export const firmwareFormFields = {
  name: 'name',
  manufacturer: 'manufacturer',
  deviceModels: 'deviceModels',
  description: 'description',
  version: 'version',
  dateReleased: 'releaseDate',
  fileDescription: 'fileDescription',
  files: 'systemFiles',
};

export default firmwareFormFields;
