import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import {
  OPERATIONS_FIELD,
} from 'helpers/selectors';

export const removeOperation = id => ({
  type: constants.REMOVE_OPERATION_REQUEST,
  payload: {
    id,
    endpoint: endpoints.OPERATIONS,
  },
});

export const removeOperationSuccess = response => ({
  type: constants.REMOVE_OPERATION_SUCCESS,
  payload: {
    response,
    message: messages.OPERATION_REMOVED,
    resourceType: OPERATIONS_FIELD,
  },
});

export const removeOperationFailure = error => ({
  type: constants.REMOVE_OPERATION_FAILED,
  payload: { error },
});
