import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';

export default styled(Popup).attrs({
  basic: true,
  inverted: true,
})`
  &.ui.popup {
    padding: 0.25em 0.5em;
  }
`;
