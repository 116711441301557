import isNil from 'lodash/isNil';

import { occurrenceStatus } from '../constants';

const checkSuccess = status => {
  if (isNil(status)) {
    return false;
  }

  if (status === occurrenceStatus.SUCCESS || status === occurrenceStatus.PARTIAL_SUCCESS) {
    return true;
  }

  return false;
};

export default checkSuccess;
