import { put, call, takeEvery, takeLatest, select, all, take } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import { getPath, postPath, putPath, deletePath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import ApiRequestBuilder from 'helpers/ApiRequestBuilder';
import endpoints from 'data/api/endpoints';
import notificationTypesActionCreators from './notificationTypesActionCreators';

export function* fetchNotificationsForContext(context, config) {
  const resources = yield select(config.selectors[context]);

  yield all(
    resources.map(resource => put(
      notificationTypesActionCreators.fetchNotifications(
        context, resource.id, { filter: config.filter }),
    )),
  );
}

export function* fetchResourcesWithNotificationsSaga({ payload: { config } }) {
  yield all(config.fetchResources.map(e => put(e)));
  yield all(config.fetchResourcesSuccess.map(e => take(e)));
  yield all(
    config.context.map(context => call(fetchNotificationsForContext, context, config)),
  );
}

export function* fetchNotificationTypesData(action) {
  const { endpoint, urlParams, context } = action.payload;
  const { response, error } = yield call(getPath, endpoint, { urlParams });

  const params = {
    response: { ...response },
    error,
    successDisp: notificationTypesActionCreators.fetchNotificationTypesSuccess(context),
    failDisp: notificationTypesActionCreators.fetchNotificationTypesFailure(context),
  };

  yield call(processCall, params);
}

export function* notificationOptIn(action) {
  const {
    entityId,
    userId,
    notificationTypeId,
    resolveForm,
    rejectForm,
    context,
    frequency,
  } = action.payload.params;

  const attributes = {
    instant: false,
    daily: false,
    weekly: false,
  };

  if (frequency) {
    attributes[frequency] = true;
  }

  const body = new ApiRequestBuilder('notifications')
    .setRelationship('user', { data: { type: 'users', id: userId } })
    .setRelationship('notificationType', { data: { type: 'notificationTypes', id: notificationTypeId } })
    .setRelationship('notifiable', { data: { type: context, id: entityId } })
    .setAttributes(attributes);

  const { response, error } = yield call(postPath, endpoints.NOTIFICATIONS(), body);
  if (error instanceof Error) { error.message = 'No connection to server. Try later.'; }

  const params = {
    response,
    error,
    resolveForm,
    rejectForm,
    successDisp: notificationTypesActionCreators.optInNotificationSuccess,
    failDisp: notificationTypesActionCreators.optInNotificationFailure,
  };
  yield call(processCall, params);

  if (!error) {
    yield put(notificationTypesActionCreators.fetchNotifications(
      context, entityId, { filter: { userId } }));
  }
}

export function* notificationOptOut(action) {
  const {
    id, userId, entityId, resolveForm, rejectForm, notificationTypeId, context,
  } = action.payload.params;

  const { response: apiResponse, error } = yield call(
    deletePath, endpoints.NOTIFICATIONS(id), {});
  const response = (apiResponse) ? { entityId, userId, notificationTypeId } : undefined;

  const params = {
    response,
    error,
    resolveForm,
    rejectForm,
    successDisp: notificationTypesActionCreators.optOutNotificationSuccess(id),
    failDisp: notificationTypesActionCreators.optOutNotificationFailure,
  };
  yield call(processCall, params);

  if (response) {
    yield put(notificationTypesActionCreators.fetchNotifications(
      context, entityId, { filter: { userId } }));
  }
}

export function* notificationEdit(action) {
  const {
    entityId,
    userId,
    resolveForm,
    rejectForm,
    context,
    notification,
    frequency,
  } = action.payload.params;

  const attributes = {
    instant: notification.instant,
    daily: notification.daily,
    weekly: notification.weekly,
  };

  if (attributes[frequency] === null) {
    attributes[frequency] = true;
  } else {
    attributes[frequency] = !attributes[frequency];
  }

  const body = {
    data: {
      type: 'notifications',
      id: notification.id,
      attributes,
    },
  };

  const endpoint = endpoints.NOTIFICATIONS(notification.id);

  const { response, error } = yield call(putPath, endpoint, body);
  if (error instanceof Error) { error.message = 'No connection to server. Try later.'; }

  const params = {
    response,
    error,
    resolveForm,
    rejectForm,
    successDisp: notificationTypesActionCreators.editNotificationSuccess(notification.id),
    failDisp: notificationTypesActionCreators.editNotificationFailure,
  };

  yield call(processCall, params);

  if (!error) {
    yield put(notificationTypesActionCreators.fetchNotifications(
      context, entityId, { filter: { userId } },
    ));
  }
}

export function* fetchNotifications(action) {
  const { urlParams, endpoint } = action.payload;

  const { response, error } = yield call(getPath, endpoint, { urlParams });

  const params = {
    response,
    error,
    successDisp: notificationTypesActionCreators.fetchNotificationsSuccess,
    failDisp: notificationTypesActionCreators.fetchNotificationsFailure,
  };
  yield call(processCall, params);
}

export function* notificationTypesSectionSaga() {
  yield takeEvery(constants.FETCH_NOTIFICATION_TYPES_REQUEST, fetchNotificationTypesData);
  yield takeEvery(constants.OPT_IN_NOTIFICATION_REQUEST, notificationOptIn);
  yield takeEvery(constants.OPT_OUT_NOTIFICATION_REQUEST, notificationOptOut);
  yield takeEvery(constants.FETCH_NOTIFICATIONS, fetchNotifications);
  yield takeLatest(
    constants.FETCH_RESOURCES_WITH_NOTIFICATIONS, fetchResourcesWithNotificationsSaga,
  );
  yield takeLatest(constants.EDIT_NOTIFICATION_REQUEST, notificationEdit);
}

export default notificationTypesSectionSaga;
