import { monthDateRange } from '../../../../../utilities';
import { modifierTypes } from '../../../constants';
import { selectModifier } from '../../../utilities';
import dateTimeToJsDate from './dateTimeToJsDate';

const calculateModifiers = (state, resourceId, resourceTime, active, occurrencesSelector) => {
  if (!active) {
    return {};
  }

  const { start, end } = monthDateRange(active);
  const occurrences = occurrencesSelector(
    state,
    resourceId,
    start,
    end,
  );
  const defaultModifiers = {
    [modifierTypes.ACTIVE]: dateTimeToJsDate(active),
    [modifierTypes.TODAY]: dateTimeToJsDate(resourceTime),
  };

  return occurrences.reduce((acc, occurrence) => {
    const modifierType = selectModifier(occurrence, resourceTime);
    const occurrencesOfType = acc[modifierType] || [];

    return {
      ...acc,
      [modifierType]: [
        ...occurrencesOfType,
        dateTimeToJsDate(occurrence.datetime),
      ],
    };
  }, defaultModifiers);
};

export default calculateModifiers;
