import styled from 'styled-components';

import { FormFieldLabel } from 'components/form/FormField/FormField.style';

export const StyledFormFieldLabel = styled(FormFieldLabel)`
  &&&&&& {
    font-size: 1.4rem;
  }
`;

export default StyledFormFieldLabel;
