import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import DropdownArrow from 'components/elements/DropdownArrow/DropdownArrow';

const LogRadioCell = ({ open }) => (
  <Table.Cell>
    <DropdownArrow open={open} />
  </Table.Cell>
);

LogRadioCell.defaultProps = {
  open: false,
};

LogRadioCell.propTypes = {
  open: PropTypes.bool,
};

export default LogRadioCell;
