import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  LOGICAL_DEVICES_FIELD,
  permissionsLogicalDeviceOpenSelector,
} from 'helpers/selectors';
import { PermissionsGridWrapper } from '../../../../../../../../../../components';
import permissionsActionCreators from '../../../../../../../../../../utilities/permissions/permissionsActionCreators';
import { ContextDevicePermissionsTree } from '../../tree/PermissionsTree';
import { ContextDevicePermissionsGridHeaders } from '../../headers/PermissionsGridHeaders';
import { ContextDevicePermissionsGrid } from '../../grid/PermissionsGrid';

export const mapStateToProps = (state, { deviceId }) => ({
  open: permissionsLogicalDeviceOpenSelector(state, { deviceId }),
  resourceId: deviceId,
  resourceTitle: 'All devices',
  PermissionsTree: ContextDevicePermissionsTree,
  PermissionsGridHeaders: ContextDevicePermissionsGridHeaders,
  PermissionsGrid: ContextDevicePermissionsGrid,
});

const mapDispatchToProps = {
  onToggleOpen: deviceId => permissionsActionCreators.toggleResourceOpen(
    LOGICAL_DEVICES_FIELD,
    deviceId,
  ),
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(PermissionsGridWrapper);
