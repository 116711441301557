import React from 'react';
import PropTypes from 'prop-types';

import { StyledPermissionsGrid } from './PermissionsGrid.style';

const PermissionsGrid = ({
  resourceId,
  paths,
  PermissionsGridRow,
  ...rest
}) => (
  <StyledPermissionsGrid>
    {paths && paths.map(path => (
      <PermissionsGridRow
        key={path}
        resourceId={resourceId}
        path={path}
        {...rest}
      />
    ))}
  </StyledPermissionsGrid>
);

PermissionsGrid.defaultProps = {
  paths: null,
};

PermissionsGrid.propTypes = {
  resourceId: PropTypes.string.isRequired,
  paths: PropTypes.array,
  PermissionsGridRow: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
};

export default PermissionsGrid;
