import styled from 'styled-components';
import { Checkbox } from 'semantic-ui-react';

import { injectStyles } from 'themes/mixins';

const ThemedCheckbox = styled(Checkbox)`
  ${({ theme: { checkboxes } }) => injectStyles(checkboxes)};
`;

export default ThemedCheckbox;
