import { call } from 'redux-saga/effects';

import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import { ERROR_SERVER_CONNECTION } from 'data/notifications/notificationsConst';
import actionCreators from '../actionCreators';

function* fetchUsers(action) {
  const { endpoint, urlParams, ...rest } = action.payload;
  const { response, error } = yield call(getPath, endpoint, { urlParams });

  if (error instanceof Error) {
    error.message = ERROR_SERVER_CONNECTION;
  }

  const params = {
    response,
    error,
    successDisp: actionCreators.fetchUsersSuccess,
    failDisp: actionCreators.fetchUsersFailure,
    ...rest,
  };
  yield call(processCall, params);
}

export default fetchUsers;
