import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';

import { complexValueType } from 'data/types';
import TextDescriptionItem
  from 'components/DescriptionList/TextDescriptionItem/TextDescriptionItem';
import LocalDateDescriptionItem
  from 'components/DescriptionList/LocalDateDescriptionItem/LocalDateDescriptionItem';
import TimeDescriptionItemIncremented
  from 'components/DescriptionList/TimeDescriptionItemIncremented/TimeDescriptionItemIncremented';

const PharosHardwareV1 = props => {
  const { loading } = props;
  if (loading) return null;

  const {
    title,
    online,
    offsetValue,
    model,
    serial,
    totalMemory,
    usedMemory,
    freeMemory,
    dataStorage,
    bootReason,
    lastBoot,
    uptime,
    channelCapacity,
  } = props;

  return (
    <Segment>
      <Header as="h2">{title}</Header>
      <TextDescriptionItem
        title="Product Type"
        value={model}
      />
      <TextDescriptionItem
        title="Channel Capacity"
        value={channelCapacity}
      />
      <TextDescriptionItem
        title="Serial Number"
        value={serial}
      />
      <TextDescriptionItem
        title="Memory Total"
        value={totalMemory}
      />
      <TextDescriptionItem
        title="Memory Used"
        value={usedMemory}
      />
      <TextDescriptionItem
        title="Memory Free"
        value={freeMemory}
      />
      <TextDescriptionItem
        title="Data Storage Size"
        value={dataStorage}
      />
      <TextDescriptionItem
        title="Boot reason"
        value={bootReason}
      />
      <LocalDateDescriptionItem
        title="Last boot"
        value={lastBoot}
        offset={offsetValue}
      />
      <TimeDescriptionItemIncremented
        title="Uptime"
        value={uptime}
        isOnline={online}
      />
    </Segment>
  );
};

PharosHardwareV1.defaultProps = {
  loading: false,
  online: false,
  offsetValue: null,
  model: {},
  serial: {},
  totalMemory: {},
  usedMemory: {},
  freeMemory: {},
  dataStorage: {},
  bootReason: {},
  lastBoot: {},
  channelCapacity: {},
  uptime: {},
};

PharosHardwareV1.propTypes = {
  loading: PropTypes.bool,
  online: PropTypes.bool,
  offsetValue: PropTypes.number,
  model: complexValueType(PropTypes.string),
  serial: complexValueType(PropTypes.string),
  totalMemory: complexValueType(PropTypes.string),
  usedMemory: complexValueType(PropTypes.string),
  freeMemory: complexValueType(PropTypes.string),
  dataStorage: complexValueType(PropTypes.string),
  bootReason: complexValueType(PropTypes.string),
  lastBoot: complexValueType(PropTypes.number),
  channelCapacity: complexValueType(PropTypes.number),
  uptime: complexValueType(PropTypes.number),
  title: PropTypes.string.isRequired,
};

export default PharosHardwareV1;
