export default {
  apiDomain: 'apiDomain',
  addNewAction: 'addNewAction',
  addNewDevice: 'addNewDevice',
  addNewCompany: 'addNewCompany',
  addNewOrigin: 'addNewOrigin',
  addNewProject: 'addNewProject',
  addProjectSuite: 'addProjectSuite',
  addNewTask: 'addNewTask',
  changePassword: 'changePassword',
  deviceName: 'deviceName',
  editAction: 'editAction',
  forgotPassword: 'forgotPassword',
  inlineEdit: 'inlineEdit',
  inlineToggle: 'inlineToggle',
  inviteUser: 'inviteUser',
  newUpload: 'newUpload',
  resetPassword: 'resetPassword',
  signUp: 'signUp',
  controlUiButton: 'controlUiButton',
  controlUiOverrideControls: 'controlUiOverrideControls',
  quickActionOverrideRGB: 'quickActionOverrideRGB',
  addPage: 'addPage',
  pageName: 'pageName',
  pageBackgroundImage: 'pageBackgroundImage',
  pageNameColor: 'pageNameColor',
  changeSiteProperty: 'changeSiteProperty',
  // Layouts
  timeAdjustmentV1: 'timeAdjustmentV1',
  watchdogV1: 'watchdogV1',
  disableV1: 'disableV1',
  deviceSettingForm: (setting, port) => `deviceSettingForm_${setting}_${port}`,
  addNewAutomatedOperation: 'addNewAutomatedOperation',
  addNewSchedule: 'addNewSchedule',
  addNewOperation: 'addNewOperation',
  editAutomatedOperation: 'editAutomatedOperation',
  editSchedule: 'editSchedule',
  editTask: 'editTask',
  editAOProperties: 'editAOProperties',
  editAOColour: 'editAOColour',
  editAOTasks: 'editAOTasks',
  selectedActions: 'selectedActions',
  taskScheduler: 'taskScheduler',
  siteFromVoucher: 'siteFromVoucher',
  duplicatePage: 'duplicatePage',
  changeProjectSuiteProperty: 'changeProjectSuiteProperty',
  voucherGeneration: 'voucherGeneration',
  addDeviceFirmware: 'addDeviceFirmware',
  uploadFirmwareFile: 'uploadFirmwareFile',
  linkFileToFirmware: 'linkFileToFirmware',
};
