import { occurrenceStatus } from 'models/OccurrenceModel/constants';

import { modifierTypes } from '../constants';


const selectModifier = (occurrence, siteTime) => {
  const { datetime, dispatched, status } = occurrence;

  const errorStatus = [occurrenceStatus.VALIDATION_ERROR, occurrenceStatus.FAILURE];
  const successStatus = [occurrenceStatus.SUCCESS, occurrenceStatus.PARTIAL_SUCCESS];

  if (dispatched && errorStatus.includes(status)) {
    return modifierTypes.DISPATCHED_ERROR;
  }

  if (dispatched && successStatus.includes(status)) {
    return modifierTypes.DISPATCHED_SUCCESS;
  }

  if (datetime > siteTime) {
    return modifierTypes.FUTURE;
  }

  return modifierTypes.PAST;
};

export default selectModifier;
