import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const fetchOccurrences = urlParams => ({
  type: constants.FETCH_AUTOMATED_OPERATION_OCCURRENCES,
  payload: {
    endpoint: endpoints.AUTOMATED_OPERATION_OCCURRENCES,
    urlParams,
  },
});

export const fetchOccurrencesSuccess = response => ({
  type: constants.FETCH_AUTOMATED_OPERATION_OCCURRENCES_SUCCESS,
  payload: { response },
});

export const fetchOccurrencesFailure = error => ({
  type: constants.FETCH_AUTOMATED_OPERATION_OCCURRENCES_FAILED,
  payload: { error },
});
