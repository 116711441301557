import { compose } from 'redux';
import { connect } from 'react-redux';

import AddDevice from './AddDevice';
import deviceActionCreators from '../../deviceActionCreators';

const mapDispatchToProps = {
  onAddDevice: deviceActionCreators.addDevice,
};

export default compose(
  connect(null, mapDispatchToProps),
)(AddDevice);
