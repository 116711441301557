import styled, { css } from 'styled-components';
import { Label } from 'semantic-ui-react';
import get from 'lodash/get';

export const EmptyMessage = styled.p`
  margin-left: 8px;
  font-size: 14px;
`;

export const StyledDateLabel = styled(Label)`
  &&& {
    width: 100%;
    border-radius: 0;
    padding-left: 0;
    border: none;
    border-bottom: 1px solid rgba(34, 36, 38, .15);  
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }  
`;

export const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
`;

export const StyledListItem = styled.li`
  margin-right: 8px;
  margin-bottom: 8px;
`;

export const StyledLink = styled.a`
  ${({ theme }) => css`
    color: ${get(theme, 'palette.primaryColor')} !important;
    text-decoration: underline;
    opacity: 1 !important;
    font-weight: 300;

    &:hover {
      text-decoration: underline;
      opacity: 0.8 !important;
    }
  `};
`;

export default EmptyMessage;
