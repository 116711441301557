import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { StyledButton } from './CancelButton.style';

const CancelButton = ({
  onClearCopy,
  buttonClipboard,
}) => {
  const cancelButton = e => {
    e.stopPropagation();
    onClearCopy();
  };

  const displayButton = !isEmpty(buttonClipboard);

  return displayButton && (
    <StyledButton
      name="cancel"
      bordered
      inverted
      color="grey"
      onClick={cancelButton}
      title="Clear Copy"
    />
  );
};

CancelButton.defaultProps = {
  buttonClipboard: {},
};

CancelButton.propTypes = {
  onClearCopy: PropTypes.func.isRequired,
  buttonClipboard: PropTypes.object,
};

export default CancelButton;
