import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';

import * as validators from 'components/form/formValidators';
import { combineTimes } from './index';

export const listToArray = list => {
  if (isNull(list)) {
    return [];
  }

  return (
    Array.isArray(list)
      ? list.filter(item => !isEmpty(item))
      : list.replace(/\s/g, '').split(',')
  ).filter(item => !isEmpty(item));
};

const renderTimes = args => {
  if (isEmpty(args)) {
    return {
      showTimes: false,
      timesList: [],
    };
  }

  const {
    hours: hoursList,
    minutes: minutesList,
    seconds: secondsList,
  } = args;
  const { helpers } = validators;
  const hours = listToArray(hoursList);
  const minutes = listToArray(minutesList);
  const seconds = listToArray(secondsList);
  const finalHours = hours.length === 1 && hours[0] === '*'
    ? Array.from(Array(24).keys())
    : hours;

  const validateHours = validators.validateTimeList(hours, helpers.HOURS);
  const validateMinutes = validators.validateTimeList(minutes, helpers.MINUTES);
  const validateSeconds = validators.validateTimeList(seconds, helpers.SECONDS);
  const validTimes = isEmpty(validateHours) && isEmpty(validateMinutes) && isEmpty(validateSeconds);
  const emptyTimes = isEmpty(hours) || isEmpty(minutes) || isEmpty(seconds);
  const showTimes = validTimes && !emptyTimes;

  const hoursAndMinutesRes = combineTimes(finalHours, minutes);
  const timesList = combineTimes(hoursAndMinutesRes, seconds, true);

  return {
    showTimes,
    timesList,
  };
};

export default renderTimes;
