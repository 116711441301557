import isNil from 'lodash/isNil';

import { markerTypes } from '../../../../../constants';

const getMarkerColor = type => {
  if (isNil(type)) {
    return '#888888';
  }

  if (type === markerTypes.ONLINE) {
    return '#00b900';
  }

  if (type === markerTypes.PARTIAL_ONLINE) {
    return '#ff8000';
  }

  if (type === markerTypes.WARNING) {
    return '#ff8000';
  }

  if (type === markerTypes.OFFLINE) {
    return '#ff0000';
  }

  return '#888888';
};

export default getMarkerColor;
