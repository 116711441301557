import constants from 'dispatcherConst';

const controlPanelActionCreators = {
  enterControlPanelContext: () => ({ type: constants.ENTER_CONTROL_PANEL_CONTEXT }),
  exitControlPanelContext: () => ({ type: constants.EXIT_CONTROL_PANEL_CONTEXT }),
  setPageNavigationOpen: () => ({ type: constants.CONTROL_UI_SET_PAGE_NAVIGATION_OPEN }),
  setPageNavigationClosed: () => ({ type: constants.CONTROL_UI_SET_PAGE_NAVIGATION_CLOSED }),
  togglePageNavigation: () => ({ type: constants.CONTROL_UI_TOGGLE_PAGE_NAVIGATION }),
};

export default controlPanelActionCreators;
