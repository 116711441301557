import constants from 'dispatcherConst';

const setSelectedTenant = selectedTenant => ({
  type: constants.SET_SELECTED_TENANT_REQUEST,
  payload: {
    selectedTenant,
  },
});

export default setSelectedTenant;
