import styled from 'styled-components';
import { Label } from 'semantic-ui-react';

export const StyledDiv = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const StyledLabel = styled(Label)`
  &.ui.label {
    margin-bottom: 5px;
  }
`;

export default StyledDiv;
