import React from 'react';
import PropTypes from 'prop-types';
import { Header, List } from 'semantic-ui-react';
import isNil from 'lodash/isNil';

import { VoucherInput, VoucherDetails } from '..';
import { StyledVoucherInputListContent, StyledSegment } from './VoucherValidation.style';

function VoucherValidation({ selectedTenant }) {
  return (
    <StyledSegment padded disabled={isNil(selectedTenant)}>
      <Header as="h3">
        Validate voucher
      </Header>
      <List divided verticalAlign="middle">
        <List.Item>
          <StyledVoucherInputListContent>
            <p>If you have a voucher please validate it in the box below.</p>
            <VoucherInput />
          </StyledVoucherInputListContent>
          <List.Content>
            <VoucherDetails
              hideProjectedRenewalDate
              hideRedeemButton
            />
          </List.Content>
        </List.Item>
      </List>
    </StyledSegment>
  );
}

VoucherValidation.defaultProps = {
  selectedTenant: null,
};

VoucherValidation.propTypes = {
  selectedTenant: PropTypes.object,
};

export default VoucherValidation;
