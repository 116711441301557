import schedulingSelector from './schedulingSelector';
import schedulesUpdatingSelector from './schedulesUpdatingSelector';
import fetchingAvailableActionsSelector from './fetchingAvailableActionsSelector';
import fetchingOccurrencesSelector from './fetchingOccurrencesSelector';
import showHiddenSelector from './showHiddenSelector';
import selectedProjectIdSelector from './selectedProjectIdSelector';
import showHideMultiSiteOccurrencesSelector from './showHideMultiSiteOccurrencesSelector';

export default {
  schedulingSelector,
  schedulesUpdatingSelector,
  fetchingAvailableActionsSelector,
  fetchingOccurrencesSelector,
  showHiddenSelector,
  selectedProjectIdSelector,
  showHideMultiSiteOccurrencesSelector,
};
