import { put, race, take } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import { actionCreators as otpActionCreators } from '../../../../../../../../../../../../../../../../utilities/oneTimePassword';
import actionCreators from '../actionCreators';

function* refreshOTPSecret(action) {
  const { values, resolveForm } = action.payload;

  yield put(otpActionCreators.submitOneTimePassword({ values, resolveForm }));

  const { success, failure } = yield race({
    success: take(constants.SUBMIT_ONE_TIME_PASSWORD_SUCCESS),
    failure: take(constants.SUBMIT_ONE_TIME_PASSWORD_FAILED),
  });

  if (failure) {
    yield put(actionCreators.refreshOTPSecretFailure());
  }

  if (success) {
    yield put(actionCreators.refreshOTPSecretSuccess());
  }
}

export default refreshOTPSecret;
