import React from 'react';
import PropTypes from 'prop-types';

import SelectedProject from 'application/tenant/console/site/components/structural/SiteSchedulingTab/components/AutomatedOperations/components/AddAutomatedOperation/components/ActionsStep/components/SelectedActions/components/ActionItem/components/GenerateActionForm/components/SelectedProject/SelectedProjectEditContainer';
import InlineEditParameter from '../../../../EditAction/InlineEditParameterContainer';
import RenderBlockedParameter from '../RenderBlockedParameter/RenderBlockedParameter';
import { StyledDiv } from './RenderValue.style';

const RenderValue = ({
  value,
  keyVal,
  typeId,
  allowEdit,
  ...rest
}) => {
  const { projectId } = rest;
  const checkIfResource = (
    typeId === 'grant_execute_permission_v1' || typeId === 'revoke_execute_permission_v1'
  ) && keyVal === 'resourceId';

  if (checkIfResource) {
    return (
      <StyledDiv>
        <SelectedProject projectId={projectId} />
      </StyledDiv>
    );
  }

  if (allowEdit) {
    return (
      <InlineEditParameter
        value={value}
        paramKey={keyVal}
        typeId={typeId}
        {...rest}
      />
    );
  }

  return <RenderBlockedParameter value={value} />;
};

RenderValue.defaultProps = {
  allowEdit: true,
};

RenderValue.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
  ]).isRequired,
  typeId: PropTypes.string.isRequired,
  keyVal: PropTypes.string.isRequired,
  allowEdit: PropTypes.bool,
};

export default RenderValue;
