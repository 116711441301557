import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const fetchCumulatedPermissions = params => ({
  type: constants.FETCH_CUMULATED_PERMISSIONS_REQUEST,
  payload: {
    endpoint: endpoints.CUMULATED_PERMISSIONS,
    ...params,
  },
});

export const fetchCumulatedPermissionsSuccess = response => ({
  type: constants.FETCH_CUMULATED_PERMISSIONS_SUCCESS,
  payload: {
    response,
  },
});

export const fetchCumulatedPermissionsFailure = error => ({
  type: constants.FETCH_CUMULATED_PERMISSIONS_FAILED,
  payload: { error },
});

export default fetchCumulatedPermissions;
