import styled, { css } from 'styled-components';
import { lighten, darken } from 'polished';
import get from 'lodash/get';

import { Button } from 'components/atomic';
import filterProps from 'helpers/filterProps';

const FilteredProps = filterProps(['isOn', 'isPartial', 'canCopy'])(Button);

export const StyledPermissionsGridItem = styled(FilteredProps)`
  &.ui.button {
    border-radius: 10px;
    width: 20px;
    height: 20px;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 15px;
    margin-right: 15px;
    background-color: white;
    border: 1px solid lightgrey;
    padding: 0;
    ${({ isOn, isPartial, theme }) => isOn && css`
      background-color: ${get(theme, 'palette.primaryColor')};
      ${isPartial && `background-color: ${lighten(0.4, get(theme, 'palette.primaryColor'))};`}
    `}
    ${({ canCopy, theme }) => canCopy && css`
      border: ${darken(0.2, get(theme, 'palette.primaryColor'))} solid 2px;
    `}
  }
`;

export default StyledPermissionsGridItem;
