import { call } from 'redux-saga/effects';

import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import actionCreators from '../actionCreators';

function* fetchCurrentUser(action) {
  const { endpoint, ...rest } = action.payload;
  const { response, error } = yield call(getPath, endpoint);

  const params = {
    response,
    error,
    successDisp: actionCreators.fetchCurrentUserSuccess,
    failDisp: actionCreators.fetchCurrentUserFailure,
    ...rest,
  };
  yield call(processCall, params);
}

export default fetchCurrentUser;
