import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';

import { getDisplayName } from 'helpers/sharedMethods';

const chainedLoaderWithSaga = (...methods) => WrappedComponent => {
  const id = uuidv4();

  return class extends Component {

    static displayName = `ChainedLoaderWithSaga(${getDisplayName(WrappedComponent)})`;

    static defaultProps = {
      pollingToggle: false,
    };

    static propTypes = {
      pollingToggle: PropTypes.bool,
      startChain: PropTypes.func.isRequired,
      stopChain: PropTypes.func.isRequired,
      terminateChain: PropTypes.func.isRequired,
    };

    componentDidMount() {
      this.startChain();
    }

    componentDidUpdate({ pollingToggle: nextPollingToggle }) {
      const { pollingToggle } = this.props;

      if (pollingToggle !== nextPollingToggle) {
        this.startChain();
      }
    }

    componentWillUnmount() {
      const { stopChain, terminateChain } = this.props;

      stopChain(id);
      terminateChain(id);
    }

    startChain() {
      const { startChain } = this.props;
      const invokedMethods = methods.map(method => method(this.props));
      const filteredMethods = invokedMethods.filter(method => method);

      if (filteredMethods.length) {
        startChain(id, filteredMethods);
      }
    }

    render() {
      return <WrappedComponent {...this.props} chainedLoaderId={id} />;
    }

  };
};

export default chainedLoaderWithSaga;
