import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const loadVoucherPermissions = userId => ({
  type: constants.LOAD_VOUCHER_PERMISSIONS_REQUEST,
  payload: {
    userId,
    endpoint: endpoints.CUMULATED_PERMISSIONS,
  },
});

export const loadVoucherPermissionsSuccess = response => ({
  type: constants.LOAD_VOUCHER_PERMISSIONS_SUCCESS,
  payload: {
    response,
  },
});

export const loadVoucherPermissionsFailure = error => ({
  type: constants.LOAD_VOUCHER_PERMISSIONS_FAILED,
  payload: { error },
});
