import { compose } from 'redux';
import { connect } from 'react-redux';

import { userSelector } from 'helpers/selectors';
import PermissionsGridItemModal from './PermissionsGridItemModal';

export const makeMapState = () => (state, { userId }) => {
  const { email } = userSelector(state)(userId);

  return {
    email,
  };
};

export default compose(
  connect(makeMapState),
)(PermissionsGridItemModal);
