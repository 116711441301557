import { call, put } from 'redux-saga/effects';

import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import { prepareResponseData } from 'helpers/paginationHelpersClient';
import actionCreators from '../actionCreators';

function* fetchProjectSuiteProjectTasks(action) {
  const { endpoint, urlParams, componentName, ...rest } = action.payload;
  const { response, error } = yield call(getPath, endpoint, { urlParams });

  const responseWithMetadata = error ? undefined : { ...response, componentName };

  const params = {
    response: responseWithMetadata,
    error,
    successDisp: actionCreators.fetchProjectSuiteProjectsTasksSuccess,
    failDisp: actionCreators.fetchProjectSuiteProjectsTasksFailure,
    ...rest,
  };

  yield call(processCall, params);

  if (response) {
    const enhancedResponse = yield call(prepareResponseData, responseWithMetadata, urlParams);

    yield put(actionCreators.fetchProjectSuiteProjectsTasksPaginated(enhancedResponse));
  }
}

export default fetchProjectSuiteProjectTasks;
