import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';

import { complexValueType } from 'data/types';
import {
  BoolRelayDescriptionItem,
  BoolClosureDescriptionItem,
} from './components';

const SLCInterfacesV1 = props => {
  const {
    title,
    loading,
    relayOutputName,
    relayOutputStsValue,
    closureInput1Name,
    closureInput1StsValue,
    closureInput2Name,
    closureInput2StsValue,
  } = props;

  if (loading) {
    return null;
  }

  return (
    <Segment>
      <Header as="h2">{title}</Header>
      <BoolRelayDescriptionItem
        title={relayOutputName}
        value={relayOutputStsValue}
      />
      <BoolClosureDescriptionItem
        title={closureInput1Name}
        value={closureInput1StsValue}
      />
      <BoolClosureDescriptionItem
        title={closureInput2Name}
        value={closureInput2StsValue}
      />
    </Segment>
  );
};

SLCInterfacesV1.defaultProps = {
  title: '',
  loading: false,
  relayOutputName: '',
  relayOutputStsValue: {},
  closureInput1Name: '',
  closureInput1StsValue: {},
  closureInput2Name: '',
  closureInput2StsValue: {},
};

SLCInterfacesV1.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  relayOutputName: PropTypes.string,
  relayOutputStsValue: complexValueType(PropTypes.bool),
  closureInput1Name: PropTypes.string,
  closureInput1StsValue: complexValueType(PropTypes.bool),
  closureInput2Name: PropTypes.string,
  closureInput2StsValue: complexValueType(PropTypes.bool),
};

export default SLCInterfacesV1;
