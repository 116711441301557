import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { withProjectSuitePermissions } from 'gateway';
import { currentUserSelector, PROJECTS_FIELD } from 'helpers/selectors';
import { addPagination } from 'helpers/paginationHelpers';
import {
  actionCreators as projectSuitesActionCreators,
} from 'pages/ProjectSuites/utilities';

import ProjectSuiteSettingsTab from './ProjectSuiteSettingsTab';

export const mapStateToProps = (state, { match }) => {
  const { id: projectSuiteId } = match.params;
  const { id: currentUserId } = currentUserSelector(state);

  return {
    currentUserId,
    projectSuiteId,
  };
};

export default compose(
  withRouter,
  withProjectSuitePermissions,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: PROJECTS_FIELD },
      ({ projectSuiteId }) => projectSuitesActionCreators.fetchProjectSuiteProjects(
        projectSuiteId, {
          fields: {
            [PROJECTS_FIELD]: [
              'name',
              'usersCount',
              'logicalDevicesCount',
              'construction',
              'state',
              'createdAt',
            ],
          },
          sort: 'name',
        }),
    ),
  ),
  chainedLoaderWithSaga(
    ({ projectSuiteId }) => projectSuitesActionCreators.fetchProjectSuiteProjectsFilter(
      projectSuiteId, {
        fields: {
          [PROJECTS_FIELD]: [
            'name',
          ],
        },
        sort: 'name',
      }),
  ),
)(ProjectSuiteSettingsTab);
