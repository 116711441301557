import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import { mediaOnly } from 'themes/mixins';

export const CompactGrid = styled(Grid)`
  &.ui.grid {
    margin: -0.5rem;
  }
  
  &.ui.grid > .row {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  
  &.ui.grid > .row > .column {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  
  &.ui.stackable.grid > .row > .column,
  &.ui.stackable.grid > .row > .wide.column {
    ${mediaOnly.xs`
      padding: 0.5rem 0.5rem!important;
    `};
  }
`;

export default CompactGrid;
