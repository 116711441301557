import FirmwareV1 from 'components/Firmware/FirmwareV1Container';
import DeviceFetcher from 'layouts/DeviceFetcher/DeviceFetcherContainer';
import SLCHardwareV1 from 'layouts/SLC/components/SLCHardwareV1/SLCHardwareV1Container';
import SLCInterfacesV1 from 'layouts/SLC/components/SLCInterfacesV1/SLCInterfacesV1Container';
import SLCClockV1 from 'layouts/SLC/components/SLCClockV1/SLCClockV1Container';
import SLCNetworkInterfaceV1 from 'layouts/SLC/components/SLCNetworkInterfaceV1/SLCNetworkInterfaceV1Container';

export default [
  {
    menuItem: 'Overview',
    path: '/overview',
    fetcher: DeviceFetcher,
    rows: [
      {
        columns: [
          {
            computer: 8,
            mobile: 16,
            sections: [
              {
                title: 'Hardware',
                component: SLCHardwareV1,
              },
              {
                title: 'Clock ',
                component: SLCClockV1,
              },
            ],
          },
          {
            computer: 8,
            mobile: 16,
            sections: [
              {
                title: 'Interfaces',
                component: SLCInterfacesV1,
              },
              {
                title: 'Network Interface',
                component: SLCNetworkInterfaceV1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Firmware',
    path: '/firmware',
    fetcher: DeviceFetcher,
    rows: [
      {
        columns: [
          {
            sections: [
              {
                title: 'Firmware',
                component: FirmwareV1,
              },
            ],
          },
        ],
      },
    ],
  },
];
