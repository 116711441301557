import { connect } from 'react-redux';

import { singleProjectSelector, singleProjectSuiteSelector } from 'helpers/selectors';
import projectActionCreators from 'pages/Projects/projectActionCreators';
import { actionCreators as projectSuiteActionCreators } from 'pages/ProjectSuites/utilities';
import { actionCreators } from '../../../../../../../utilities/users';
import InvitationItem from './InvitationItem';

export const mapStateToProps = (state, { invitation }) => {
  const {
    id: invitationId,
    projectId,
    projectSuiteId,
  } = invitation;

  const { name: resourceName } = projectId
    ? singleProjectSelector(state, projectId)
    : singleProjectSuiteSelector(state, projectSuiteId);

  return {
    invitationId,
    resourceId: projectId || projectSuiteId,
    resourceName,
    resourceType: projectId ? 'project' : 'project_suite',
  };
};

const mapDispatchToProps = {
  onFetchProject: projectActionCreators.fetchProject,
  onFetchProjectSuite: projectSuiteActionCreators.fetchProjectSuite,
  onDeleteInvitation: actionCreators.deleteInvitation,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvitationItem);
