import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormGroup } from 'semantic-ui-react';
import { Button } from 'components/atomic';

import * as validators from 'components/form/formValidators';
import TextField from 'components/form/TextField/TextField';
import ModalForm from 'components/form/ModalForm/ModalForm';
import { taskFormFields } from '../../../constants';

const AddTaskModalForm = ({
  submitting,
  projectName,
  projectSuiteName,
  isSuspended,
  isConstruction,
  superSites,
  ...rest
}) => {
  const name = superSites ? projectSuiteName : projectName;
  const header = `Add Task to ${name}`;
  const disabled = isSuspended || isConstruction;
  const trigger = <Button primary disabled={disabled}>Add Task</Button>;

  return (
    <ModalForm
      header={header}
      trigger={trigger}
      size="small"
      submitting={submitting}
      {...rest}
    >
      <FormGroup inline>
        <Field
          name={taskFormFields.name}
          component={TextField}
          type="text"
          loading={submitting}
          label="Name"
          validate={validators.validDisplayName}
        />
      </FormGroup>
      <FormGroup inline>
        <Field
          name={taskFormFields.description}
          component={TextField}
          type="text"
          loading={submitting}
          label="Description"
        />
      </FormGroup>
    </ModalForm>
  );
};

AddTaskModalForm.defaultProps = {
  projectName: null,
  projectSuiteName: null,
  superSites: false,
};

AddTaskModalForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  projectName: PropTypes.string,
  isSuspended: PropTypes.bool.isRequired,
  isConstruction: PropTypes.bool.isRequired,
  projectSuiteName: PropTypes.string,
  superSites: PropTypes.bool,
};

export default AddTaskModalForm;
