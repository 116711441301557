import React from 'react';

import TimelinesSection from 'components/TimelinesSection/TimelinesSectionContainer';

const labels = [
  { text: 'Number', field: 'id', width: 2 },
  { text: 'Name', field: 'name', width: 3 },
  { text: 'Status', field: 'status', width: 5 },
  { text: 'Time', field: 'formattedTime', width: 3, sortBy: 'time' },
  { text: 'Length', field: 'formattedLength', width: 3, sortBy: 'length' },
];

const PharosStatusV1 = props => <TimelinesSection {...props} labels={labels} />;

export default PharosStatusV1;
