import { reduxForm } from 'redux-form';

import formNames from 'components/form/formNames';
import { validateOnSubmit } from 'components/form/formConfigs';
import PharosWatchdogV1Form from './PharosWatchdogV1Form';

export default reduxForm({
  ...validateOnSubmit,
  form: formNames.watchdogV1,
  enableReinitialize: true,
})(PharosWatchdogV1Form);
