import isEmpty from 'lodash/isEmpty';

const checkIfFileExists = files => {
  if (!Array.isArray(files)) {
    return false;
  }

  if (!isEmpty(files) && files[0].name && files[0].name.length > 3) {
    return true;
  }

  return false;
};

export default checkIfFileExists;
