import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { singleProjectSelector } from 'helpers/selectors';
import RenderWarningIcon from './RenderWarningIcon';

export const mapStateToProps = (state, { match: { params: { id: siteId } } }) => {
  const {
    isGrace,
    isInGracePeriod,
    isApproachingRenewal,
    isDemo,
  } = singleProjectSelector(state, siteId) || {};

  return {
    isInGracePeriod,
    isApproachingRenewal,
    isGrace,
    isDemo,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, null),
)(RenderWarningIcon);
