import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';
import deepEqual from 'lodash/isEqual';
import identity from 'lodash/identity';

import { deviceSelector } from 'helpers/selectors';
import PharosSoftwareV1 from './PharosSoftwareV1';

const makeMapState = () => {
  const memoFirmware = memoizeOne(identity, deepEqual);
  const memoBootloader = memoizeOne(identity, deepEqual);

  return function mapStateToProps(state, ownProps) {
    const { resourceId } = ownProps;
    const device = deviceSelector(state)(resourceId);
    const { reportedWithMeta } = device;

    const {
      sw: {
        fw: firmware,
        bl: bootloader,
      } = {},
    } = reportedWithMeta || {};


    return {
      loading: !reportedWithMeta,
      firmware: memoFirmware(firmware),
      bootloader: memoBootloader(bootloader),
    };
  };
};

export default connect(makeMapState())(PharosSoftwareV1);
