import React from 'react';
import { Placeholder, Checkbox } from 'semantic-ui-react';

import { Container, StyledSegment, StyledPlaceholder } from './TaskListPlaceholder.style';

function TaskListPlaceholder() {
  return (
    <Container>
      <StyledSegment>
        <Checkbox />
        <StyledPlaceholder>
          <Placeholder.Header>
            <Placeholder.Line length="short" />
          </Placeholder.Header>
        </StyledPlaceholder>
      </StyledSegment>
      <StyledSegment>
        <Checkbox />
        <StyledPlaceholder>
          <Placeholder.Header>
            <Placeholder.Line length="medium" />
          </Placeholder.Header>
        </StyledPlaceholder>
      </StyledSegment>
      <StyledSegment>
        <Checkbox />
        <StyledPlaceholder>
          <Placeholder.Header>
            <Placeholder.Line length="short" />
          </Placeholder.Header>
        </StyledPlaceholder>
      </StyledSegment>
      <StyledSegment>
        <Checkbox />
        <StyledPlaceholder>
          <Placeholder.Header>
            <Placeholder.Line length="very short" />
          </Placeholder.Header>
        </StyledPlaceholder>
      </StyledSegment>
      <StyledSegment>
        <Checkbox />
        <StyledPlaceholder>
          <Placeholder.Header>
            <Placeholder.Line length="short" />
          </Placeholder.Header>
        </StyledPlaceholder>
      </StyledSegment>
      <StyledSegment>
        <Checkbox />
        <StyledPlaceholder>
          <Placeholder.Header>
            <Placeholder.Line length="medium" />
          </Placeholder.Header>
        </StyledPlaceholder>
      </StyledSegment>
      <StyledSegment>
        <Checkbox />
        <StyledPlaceholder>
          <Placeholder.Header>
            <Placeholder.Line length="short" />
          </Placeholder.Header>
        </StyledPlaceholder>
      </StyledSegment>
      <StyledSegment>
        <Checkbox />
        <StyledPlaceholder>
          <Placeholder.Header>
            <Placeholder.Line length="very short" />
          </Placeholder.Header>
        </StyledPlaceholder>
      </StyledSegment>
    </Container>
  );
}

export default TaskListPlaceholder;
