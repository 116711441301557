import styled from 'styled-components';

import colors from 'themes/colors';
import DataTableButton from 'components/DataTable/DataTableButton/DataTableButton';

const UploadDataTableButton = styled(DataTableButton)`
  &.ui.icon.button {
    padding: 0;
    background: transparent;
    color: ${colors.darkGrey};
  }
`;

UploadDataTableButton.defaultProps = DataTableButton.defaultProps;
UploadDataTableButton.propTypes = DataTableButton.propTypes;

export default UploadDataTableButton;
