import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Loader, Responsive } from 'semantic-ui-react';

import { sizes } from 'themes/mixins';
import LogModel from 'models/LogModel';
import { Dimmer } from 'components/DataTable/Table.style';
import {
  StyledLogTableHeader,
  StyledHeaderItem,
  StyledLogTable,
} from './LogTable.style';
import LogTableLine from './LogTableLine/LogTableLine';

export const logTableColumns = [
  { id: 'date', header: 'Date', width: 75 },
  { id: 'time', header: 'Time', width: 75 },
  { id: 'category', header: 'Category', width: 80 },
  { id: 'level', header: 'Level', width: 75 },
  { id: 'message', header: 'Message' },
];

export const LinesEnum = Object.freeze({
  FIFTY: 50,
  ONE_HUNDRED: 100,
  TWO_HUNDRED: 200,
});

const LogTable = ({ logs, refreshing }) => {
  if (!logs) return null;

  return (
    <Dimmer.Dimmable dimmed={refreshing}>
      <StyledLogTableHeader>
        <Responsive as={Fragment} minWidth={sizes.md}>
          {logTableColumns.map(({ id, header, width }) => (
            <StyledHeaderItem key={id} width={width}>{header}</StyledHeaderItem>
          ))}
        </Responsive>
      </StyledLogTableHeader>
      <StyledLogTable>
        {logs.map(({ id, display }) => (
          <LogTableLine
            columns={logTableColumns}
            key={id}
            {...display}
          />
        ))}
      </StyledLogTable>

      <Dimmer inverted active={refreshing}>
        <Loader>Refreshing</Loader>
      </Dimmer>
    </Dimmer.Dimmable>
  );
};

LogTable.defaultProps = {
  logs: null,
  refreshing: false,
};

LogTable.propTypes = {
  logs: PropTypes.arrayOf(PropTypes.instanceOf(LogModel)),
  refreshing: PropTypes.bool,
};

export default LogTable;
