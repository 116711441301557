import styled from 'styled-components';
import { media } from 'themes/mixins';

export const Header = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const Labels = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${media.sm`
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    flex-direction: row;
  `};

  & > * {
    flex: 1;
    &:last-child {
      ${media.sm`
        text-align: right;
      `};
    }
  }

  span {
    margin-right: 8px;
    &:last-child {
      margin-right:0;
    }
  }
`;
