import includes from 'lodash/includes';
import XRegExp from 'xregexp';

import { paths } from 'navigation';

const relativeWebRedirection = webRedirection => {
  if (includes(webRedirection, paths.userProjects)) {
    const link = XRegExp(`.+${paths.userProjects}/*(?<siteId>[^/]+)`);
    const match = XRegExp.exec(webRedirection, link);

    if (!match) {
      return paths.userProjects;
    }

    return `${paths.userProjects}/${match.siteId}`;
  }

  return paths.index;
};

export default relativeWebRedirection;
