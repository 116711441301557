import api from 'data/api/endpoints';

export const context = {
  PROJECT: 'project',
  LOGICAL_DEVICE: 'logical_device',
};

export const endpoints = {
  [context.PROJECT]: api.PROJECT_NOTIFICATIONS,
  [context.LOGICAL_DEVICE]: api.LOGICAL_DEVICE_NOTIFICATIONS,
};

export default {
  context,
  endpoints,
};
