import styled from 'styled-components';

const RowIconWrapper = styled.div`
  display: inline-block;

  & svg {
    margin-left: 13px;
  }
`;

export default RowIconWrapper;
