import constants from 'dispatcherConst';

export const subscribeNotificationEventsChannel = tenant => ({
  type: constants.SUBSCRIBE_NOTIFICATION_EVENTS_CHANNEL,
  payload: {
    tenant,
  },
});

export default subscribeNotificationEventsChannel;
