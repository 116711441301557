import styled from 'styled-components';
import { Container } from 'semantic-ui-react';

import { media } from 'themes/mixins';

const NarrowContainer = styled(Container)`
  &.ui.container {
  ${media.md`
    width: auto;
    flex: 1;
    margin-right: 2.8571em!important;
    margin-left: 2.8571em!important;
  `};
  ${media.xl`
    margin-right: 10em!important;
    margin-left: 10em!important;
  `};
  }
`;

export default NarrowContainer;
