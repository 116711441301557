import { call, put, select } from 'redux-saga/effects';

import updateIndexes from 'application/tenant/utilities/tasks/updateIndexes';
import { putPath } from 'data/api/requests';
import endpoints from 'data/api/endpoints';
import { allActionsTypeSelector } from 'helpers/selectors';
import TaskModel from 'models/TaskModel';
import ActionModel from 'models/ActionModel';

import taskScheduleActionCreators from '../actionCreators';

function* saveActions({
  task,
  actions,
  actionsForm,
  resourceType,
}) {
  const actionTypes = yield select(allActionsTypeSelector);
  const currentTask = task;
  const formValues = Object.keys(actionsForm.values);

  let taskCheckFields = new TaskModel(currentTask);

  actions.forEach(action => {
    const actionTypeDeviceName = `_${action.actionId}_${action.resourceId}_${action.index}`;
    const formKeys = formValues.filter(formValue => formValue.includes(actionTypeDeviceName)) || [];

    const actionParams = {
      taskId: task.id,
      actionName: `${action.actionName} - ${action.resourceName}`,
      logicalDeviceId: action.deviceId,
      typeId: action.actionId,
    };

    formKeys.forEach(formKey => {
      const preparedKey = formKey.replace(actionTypeDeviceName, '');

      if (preparedKey && actionsForm.registeredFields && actionsForm.registeredFields[formKey]) {
        actionParams[preparedKey] = actionsForm.values[formKey];
      }
    });

    const preparedAction = ActionModel.formatAction(actionParams, actionTypes);

    taskCheckFields = TaskModel.addActionToTask(taskCheckFields, preparedAction);
  });

  if (resourceType === 'projectSuite') {
    delete taskCheckFields.projectId;
  } else {
    delete taskCheckFields.projectSuiteId;
  }

  const formatTask = TaskModel.requestBody(taskCheckFields);
  const finalTask = updateIndexes(formatTask);

  const { response, error } = yield call(putPath, endpoints.EDIT_TASK(task.id), finalTask);

  if (error) {
    yield put(taskScheduleActionCreators.addTaskSchedulerFailure({
      type: 'SAVE_ACTIONS',
      error,
    }));
  }

  return response;
}

export default saveActions;
