import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import Form from 'components/form/Form/Form';
import { validPassword } from 'components/form/formValidators';
import { Button, StyledRightFormField } from 'components/atomic';
import { NewPasswordField } from './components';

const resetPasswordFormFields = {
  password: 'password',
};

const ResetPasswordForm = ({ handleSubmit, submitting }) => (
  <Form>
    <p>Please enter a new password</p>
    <Grid>
      <Grid.Row verticalAlign="middle">
        <Grid.Column mobile={16}>
          <Form.Group>
            <NewPasswordField
              name={resetPasswordFormFields.password}
              validate={validPassword}
            />
          </Form.Group>
        </Grid.Column>
        <Grid.Column mobile={16}>
          <Form.Group omitError>
            <StyledRightFormField>
              <Button
                primary
                disabled={submitting}
                onClick={handleSubmit}
              >
                Set new password
              </Button>
            </StyledRightFormField>
          </Form.Group>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Form>
);

ResetPasswordForm.defaultProps = {
  submitting: false,
};

ResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

export default ResetPasswordForm;
