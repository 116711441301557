import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

const convertKeyToInt = (values, key) => {
  if (isEmpty(values)) {
    return values;
  }

  if (isEmpty(key) || isNil(key)) {
    return values;
  }

  if (values[key]) {
    const keyValue = parseInt(values[key], 10);

    return { ...values, [key]: keyValue };
  }

  return values;
};

export default convertKeyToInt;
