import React from 'react';
import PropTypes from 'prop-types';

const withLoadProjectSuite = WrappedComponent => {
  const WithLoadProjectSuite = ({ usersEndpoint, ...rest }) => {
    if (!usersEndpoint) {
      return null;
    }

    return <WrappedComponent {...rest} />;
  };

  WithLoadProjectSuite.defaultProps = {
    usersEndpoint: null,
  };

  WithLoadProjectSuite.propTypes = {
    usersEndpoint: PropTypes.string,
  };

  return WithLoadProjectSuite;
};

export default withLoadProjectSuite;
