import styled from 'styled-components';
import { Loader } from 'semantic-ui-react';


export const StyledLoader = styled(Loader)`
  &&&& {
    &:after {
      border-color: #767676 #ddd #ddd;
    }
  }
`;

export default StyledLoader;
