import React from 'react';
import PropTypes from 'prop-types';

import { ORIGINS_FIELD } from 'helpers/selectors';
import DataTable from 'components/DataTable/DataTableContainer';
import { AddOrigin, OriginActionLinks, OriginTableRow } from './components';

const originsLabels = [
  { text: 'Domain', field: 'name' },
  { text: 'URL', field: 'url' },
];

const OriginTable = ({ companyId, origins }) => origins && (
  <DataTable
    header="Origins"
    labels={originsLabels}
    data={origins}
    customTableRow={OriginTableRow}
    actionLinks={OriginActionLinks}
    createElement={AddOrigin}
    pagination={{
      type: ORIGINS_FIELD,
    }}
    companyId={companyId}
  />
);

OriginTable.defaultProps = {
  origins: null,
};

OriginTable.props = {
  companyId: PropTypes.string.isRequired,
  origins: PropTypes.array,
};

export default OriginTable;
