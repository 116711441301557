import { call, put } from 'redux-saga/effects';

import { deletePath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import { actionCreators as applicationUserActionCreators } from '../../../../../utilities/user';
import actionCreators from '../actionCreators';

function* deleteInvitation(action) {
  const { endpoint, invitationId } = action.payload;
  const { response, error } = yield call(deletePath, endpoint);

  const params = {
    response: response && { invitationId },
    error,
    successDisp: actionCreators.deleteInvitationSuccess,
    failDisp: actionCreators.deleteInvitationFailure,
  };
  yield call(processCall, params);

  if (response) {
    yield put(applicationUserActionCreators.fetchCurrentUser());
  }
}

export default deleteInvitation;
