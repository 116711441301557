import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { PROJECT_SUITES_FIELD } from 'helpers/selectors';

export const fetchProjectSuite = (projectSuiteId, urlParams = {}) => ({
  type: constants.FETCH_PROJECT_SUITE_REQUEST,
  payload: {
    projectSuiteId,
    endpoint: endpoints.PROJECT_SUITE(projectSuiteId),
    urlParams,
  },
});

export const fetchProjectSuiteSuccess = response => ({
  type: constants.FETCH_PROJECT_SUITE_SUCCESS,
  payload: {
    response,
    resourceType: PROJECT_SUITES_FIELD,
  },
});

export const fetchProjectSuiteFailure = error => ({
  type: constants.FETCH_PROJECT_SUITE_FAILED,
  payload: { error },
});

export default fetchProjectSuite;
