import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { deepEqualMemoizeOne } from 'helpers/sharedMethods';
import chainedLoader from 'helpers/chainedLoader/chainedLoaderContainerWithPagination';
import { makeChainLoaderPieceSelector } from 'helpers/selectorsCreators';
import {
  COMPANIES_FIELD,
  companiesFetchingSelector,
  companiesErrorSelector,
  currentCompanyFetchingSelector,
  currentCompanyNameSelector,
  currentCompanySubdomainSelector,
  currentUserIdSelector,
  tenantChangedSelector,
  tenantSelector,
  tenantsSelector,
} from 'helpers/selectors';
import { actionCreators as companiesActionCreators } from 'application/tenant/console/company/utilities';
import { sortByText } from './utilities';
import TenantSelect from './TenantSelect';

const companiesLoaderSelector = makeChainLoaderPieceSelector([
  companiesErrorSelector,
  companiesFetchingSelector,
]);

const chainLoaderSelector = createSelector(
  companiesLoaderSelector,
  companiesLoaderProps => ({
    [COMPANIES_FIELD]: companiesLoaderProps,
  }),
);

export const makeMapState = () => {
  const memoisedTenants = deepEqualMemoizeOne();

  return function mapStateToProps(state) {
    const tenants = tenantsSelector(state);
    const sortedTenants = tenants.sort(sortByText);

    return {
      currentCompanyName: currentCompanyNameSelector(state),
      currentCompany: currentCompanySubdomainSelector(state),
      currentUserId: currentUserIdSelector(state),
      fetching: companiesFetchingSelector(state) || currentCompanyFetchingSelector(state),
      tenantChanged: tenantChangedSelector(state),
      tenant: tenantSelector(state),
      tenants: memoisedTenants(sortedTenants),
      chainedLoaderProps: chainLoaderSelector(state),
    };
  };
};

const mapDispatchToProps = {
  onChangeTenant: companiesActionCreators.changeTenant,
};

export default compose(
  connect(makeMapState, mapDispatchToProps),
  chainedLoader(
    { name: COMPANIES_FIELD },
    () => companiesActionCreators.fetchCompanies(
      { fields: { companies: ['name', 'subdomain'] } },
    ),
  ),
)(TenantSelect);
