import { connect } from 'react-redux';
import find from 'lodash/find';

import { schedulesSelector } from 'helpers/selectors';

import automatedOperationsActionCreators from '../../utilities/actionCreators';
import { actionCreators } from '../../utilities';
import ModalTrigger from './ModalTrigger';

export const mapStateToProps = (state, { automatedOperationId, scheduleId }) => {
  const getSchedules = schedulesSelector(state)(automatedOperationId);
  const schedule = getSchedules && find(getSchedules.schedulesList, { id: scheduleId });

  return {
    existingSchedule: schedule,
  };
};

const mapDispatchToProps = {
  onAddAutomatedOperation: automatedOperationsActionCreators.addAutomatedOperation,
  onAddTaskScheduler: actionCreators.addTaskScheduler,
  onResetTaskScheduler: actionCreators.resetTaskScheduler,
  onRecurrenceErrorsChange: actionCreators.setRecurrenceErrors,
  onRecurrenceParamsChange: actionCreators.setRecurrenceParams,
  onSchedulerTypeChange: actionCreators.setSchedulerType,
  onTaskSchedulerModeChange: actionCreators.setTaskSchedulerMode,
  onExistingScheduleChange: actionCreators.setExistingSchedule,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalTrigger);
