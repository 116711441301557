import { call } from 'redux-saga/effects';

import { postPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import { messages } from 'data/notifications/notificationsConst';

import actionCreators from '../actionCreators';

function* transferDeviceFirmware(action) {
  const { endpoint } = action.payload;

  const result = yield call(postPath, endpoint);
  const { response } = result;
  let { error } = result;

  error = error ? new Error(messages.TRANSFER_DEVICE_FIRMWARE_FAILED) : undefined;

  const params = {
    response,
    error,
    successDisp: actionCreators.transferDeviceFirmwareSuccess,
    failDisp: actionCreators.transferDeviceFirmwareFailure,
  };

  yield call(processCall, params);
}

export default transferDeviceFirmware;
