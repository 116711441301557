import React, { Fragment, useMemo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { IoNotificationsOutline, IoClose, IoMailOpenOutline, IoArchiveOutline, IoArrowForward } from 'react-icons/io5';
import { Responsive } from 'semantic-ui-react';

import { sizes } from 'themes/mixins';
import { NotificationItem, EmptyMessage } from './components';
import {
  StyledPopup,
  IconContainer,
  StyledMenu,
  StyledMenuItem,
  HasNotificationsIcon,
  Title,
  Content,
  ViewAllLink,
  Overlay,
  LoadMoreLink,
  ActionsContainer,
  Option,
} from './NotificationsDropdown.style';

function NotificationsDropdown({
  isLoading,
  displayIcon,
  notificationEvents,
  history,
  onArchiveNotification,
  onReadNotification,
  onClearNotificationIcon,
  onFetchNotificationEvents,
  onArchiveAllNotifications,
  onReadAllNotifications,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(1);

  const trigger = useMemo(() => (
    <IconContainer onClick={() => onClearNotificationIcon()}>
      <IoNotificationsOutline size="1.8em" />
      {displayIcon && <HasNotificationsIcon />}
    </IconContainer>
  ), [displayIcon, onClearNotificationIcon]);

  const renderContent = useCallback(() => {
    if (!notificationEvents || isEmpty(notificationEvents)) {
      return <EmptyMessage />;
    }

    return (
      <Content>
        {notificationEvents.map(notification => (
          <NotificationItem
            key={notification.id}
            {...notification}
            onReadNotification={onReadNotification}
            onArchiveNotification={onArchiveNotification}
          />
        ))}
        <LoadMoreLink name="load-more-link" isLoading={isLoading} onClick={() => setPage(page + 1)}>
          {isLoading ? 'Loading...' : 'Load more...'}
        </LoadMoreLink>
      </Content>
    );
  }, [notificationEvents, isLoading, page, onArchiveNotification, onReadNotification]);

  const renderActions = useCallback(() => (
    <ActionsContainer alignRight>
      <Option name="read-all-button" onClick={() => onReadAllNotifications()}>
        Read all
        <IoMailOpenOutline />
      </Option>
      &nbsp;|&nbsp;
      <Option name="archive-all-button" onClick={() => onArchiveAllNotifications()}>
        Archive all
        <IoArchiveOutline />
      </Option>
    </ActionsContainer>
  ), [onArchiveAllNotifications, onReadAllNotifications]);

  const handleViewAll = useCallback(() => {
    history.push('/profile/notification_logs');

    setIsOpen(previous => !previous);
  }, [history]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  useEffect(() => {
    onFetchNotificationEvents({
      'page[size]': 50,
      'page[number]': page,
    });
  }, [onFetchNotificationEvents, page]);

  return (
    <>
      {isOpen && (
        <Responsive maxWidth={sizes.md - 1} as={Fragment}>
          <Overlay />
        </Responsive>
      )}
      <StyledPopup
        name="notifications-dropdown"
        on="click"
        position="bottom right"
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        trigger={trigger}
        content={(
          <StyledMenu fluid vertical>
            <StyledMenuItem header>
              <Title>
                Notifications
              </Title>
              <Responsive maxWidth={sizes.md - 1} as={Fragment}>
                <IoClose size="1.5em" onClick={() => setIsOpen(false)} />
              </Responsive>
            </StyledMenuItem>
            {renderContent()}
            <StyledMenuItem footer>
              {renderActions()}
              <ViewAllLink name="view-all" onClick={handleViewAll}>
                View all <IoArrowForward />
              </ViewAllLink>
            </StyledMenuItem>
          </StyledMenu>
        )}
      />
    </>
  );
}

NotificationsDropdown.defaultProps = {
  isLoading: false,
  notificationEvents: [],
  displayIcon: false,
};

NotificationsDropdown.propTypes = {
  isLoading: PropTypes.bool,
  notificationEvents: PropTypes.array,
  displayIcon: PropTypes.bool,
  history: PropTypes.object.isRequired,
  onArchiveNotification: PropTypes.func.isRequired,
  onReadNotification: PropTypes.func.isRequired,
  onClearNotificationIcon: PropTypes.func.isRequired,
  onArchiveAllNotifications: PropTypes.func.isRequired,
  onReadAllNotifications: PropTypes.func.isRequired,
  onFetchNotificationEvents: PropTypes.func.isRequired,
};

export default NotificationsDropdown;
