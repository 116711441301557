import { useContext, useEffect } from 'react';
import { Zoom } from 'ol/control';
import MapContext from '../MapContext/MapContext';

const FullScreenControl = () => {
  const { map } = useContext(MapContext);

  useEffect(() => {
    if (!map) return undefined;

    const zoomControls = new Zoom({});
    map.controls.push(zoomControls);

    return () => map.controls.remove(zoomControls);
  }, [map]);

  return null;
};

export default FullScreenControl;
