import styled from 'styled-components';

export const MinutesInputElement = styled.input`
  position: relative;
  display: inline;
  height: inherit;
  margin: 0;
  padding: 0!important;

  border: none!important;
  outline: 0;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  touch-action: manipulation;

  margin-left: 0.8em!important;
  margin-right: 0.1em!important;
  text-align: right;
`;

export default MinutesInputElement;
