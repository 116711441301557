import { combineReducers } from 'redux';

import uploadsFilesReducer from './uploadsFilesReducer';
import uploadsStatusReducer from './uploadsStatusReducer';
import uploadsQueueReducer from './uploadsQueueReducer';
import uploadsWorkersReducer from './uploadsWorkersReducer';

const uploadsReducer = combineReducers({
  files: uploadsFilesReducer,
  status: uploadsStatusReducer,
  queue: uploadsQueueReducer,
  workers: uploadsWorkersReducer,
});

export default uploadsReducer;
