import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import { STATIC_SCENES_FIELD } from 'helpers/selectors';

const staticScenesSectionActionCreators = {
  fetchStaticScenes: (deviceId, urlParams = {}) => ({
    type: constants.FETCH_STATIC_SCENES_REQUEST,
    payload: {
      deviceId,
      endpoint: endpoints.STATIC_SCENES(deviceId),
      urlParams,
    },
  }),

  fetchStaticScenesFailure: error => ({
    type: constants.FETCH_STATIC_SCENES_FAILED,
    payload: {
      info: !error.unauthorized && error.notFound && messages.NO_SCENES,
      error: !error.notFound && error,
      resourceType: STATIC_SCENES_FIELD,
    },
  }),

  fetchStaticScenesSuccess: response => ({
    type: constants.FETCH_STATIC_SCENES_SUCCESS,
    payload: { response },
  }),

  fetchStaticScenesSuccessPaginated: response => ({
    type: constants.FETCH_STATIC_SCENES_PAGINATED,
    payload: { response },
  }),
};

export default staticScenesSectionActionCreators;
