import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

export const StyledButton = styled(Icon)`
  position: absolute;
  border-radius: 3px;
  top: 6px;
  right: 0px;

  .ui.button:not(.icon)>&.icon:not(.button):not(.dropdown) {
    height: auto;
  }
`;

export default StyledButton;
