import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { searchParamsConfigSelector } from 'helpers/selectors';
import withPagination from 'helpers/withPagination/withPaginationContainer';
import tablesActionCreators from 'data/tablesActionCreators';
import DataTable from './DataTable';

const mapStateToProps = state => ({
  searchParamsConfig: searchParamsConfigSelector(state),
});

const mapDispatchToProps = {
  storeTableName: tablesActionCreators.storeTableName,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withPagination,
)(DataTable);
