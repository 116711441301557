const getLabelText = (label, value) => {
  const signal = value > 0 ? '+' : '';
  const text = value === 0 ? label : value;
  const suffix = value === 0 ? '' : ' mins';
  const showSignal = value !== 0;

  return `${showSignal ? signal : ''}${text}${suffix}`;
};

export default getLabelText;
