import { call } from 'redux-saga/effects';

import { processFailedCall } from 'helpers/sagaHelper';
import FinalFormErrorParser from './FinalFormErrorParser';

function* processFailure({ resolveForm, ...rest }) {
  const { error } = rest;
  const errors = new FinalFormErrorParser(error);

  if (resolveForm) {
    resolveForm(errors.toFormError);
  }

  yield call(processFailedCall, rest);
}

export default processFailure;
