export const emDashedData = '—';

export const secondsPerHour = 3600;
export const secondsPerDay = 86400;

export const dateTimeFormat = 'ccc, dd LLL y HH:mm:ss (\'UTC\'Z)';
export const durationFormat = 'h\'h\' mm\'m\' ss\'s\'';

export const settingTypes = Object.freeze({
  BEACON: 'beacon',
  WATCHDOG: 'watchdog',
  TIME_SET: 'time_set',
  LOG_LEVEL: 'log',
  DISABLE: 'timed_events_enabled',
});

export const keyCodes = Object.freeze({
  UP: 38,
  DOWN: 40,
  ENTER: 13,
  MINUS: 189,
  MINUS_FF: 173,
  NUMPADSUBTRACT: 109,
});

export const deviceStatus = Object.freeze({
  UNASSOCIATED: 'unassociated',
  ONLINE: 'online',
  OFFLINE: 'offline',
});

export const AWS_REGION = 'eu-west-2';

export const CONSTRUCTION_MESSAGE = 'Site is in construction mode. Site features are limited in this mode. Please contact the Site Owner for more information';
export const SITE_SUSPENDED = 'Site is on standby. Please contact the Site Owner for more information';

export const siteStates = {
  ACTIVE: 'active',
  CONSTRUCTION: 'construction',
  DEMO: 'demo',
  SUSPENDED: 'suspended',
  GRACE: 'grace',
};

export const remoteDeviceStatus = {
  ONLINE: {
    model: 'device',
    name: 'circle',
    status: 'online',
    label: 'Online',
  },
  OFFLINE: {
    model: 'device',
    name: 'circle',
    status: 'offline',
    label: 'Offline',
  },
  INCOMPATIBLE_FIRMWARE: {
    model: 'device',
    name: 'warning sign',
    status: 'unassociated',
    label: 'Incompatible Firmware',
  },
  RELOADING_FIRMWARE: {
    model: 'device',
    name: 'circle',
    status: 'upgradeonline',
    label: 'Reloading Firmware 34%',
  },
};

export const PORTAL_CONTEXT_ID = 'rg-456-j';

export const COMPANIES = 'Companies';
