import React from 'react';
import PropTypes from 'prop-types';

import TableMenu from 'components/DataTable/TableMenu.style';
import { ReInviteUserActionLink } from 'components/ReInviteUser';
import { RemoveUserActionLink } from './RemoveUser';

const UserActionLinks = ({ chosenItem, ...rest }) => {
  const actionLinks = [RemoveUserActionLink, ReInviteUserActionLink];

  return (
    <TableMenu.Item>
      {actionLinks.map(ActionLink => (
        <TableMenu.Item key={ActionLink.displayName}>
          <ActionLink item={chosenItem} {...rest} />
        </TableMenu.Item>
      ))}
    </TableMenu.Item>
  );
};

UserActionLinks.defaultProps = {
  chosenItem: null,
};

UserActionLinks.propTypes = {
  chosenItem: PropTypes.object,
};

export default UserActionLinks;
