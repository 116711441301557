import styled, { css } from 'styled-components';

import { mediaMax } from 'themes/mixins';

export const StyledDiv = styled('div')`
  ${({ bgColor }) => `background-color: ${bgColor}`};
  
  border-radius: 3px;
  width: 58px;
  height: 29px;
  border: solid 1px #dedede;
  margin-bottom: 5px;
  margin-left: 18px;
`;

export const StyledRGBWrapper = styled('div')`
  width: 30%;
  max-width: 130px;

  ${mediaMax.sm`
    width: 150px; 
    margin-bottom: 15px;
    margin-left: 20px;
  `}

  ${({ orientation }) => orientation === 'horizontal' && css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;

    ${mediaMax.sm`
      width: auto; 
      margin-bottom: 0;
      margin-left: 0;
    `}
  `}
`;
