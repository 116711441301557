import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { singleProjectSuiteSelector } from 'helpers/selectors';
import { actionCreators } from 'pages/ProjectSuites/utilities';

import EditNotes from './EditNotes';

export const mapStateToProps = (state, { match }) => {
  const { id: projectSuiteId } = match.params;
  const { notes } = singleProjectSuiteSelector(state, projectSuiteId);

  return {
    projectSuiteId,
    notes,
  };
};

const mapDispatchToProps = {
  onUpdateProjectSuite: actionCreators.updateProjectSuite,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(EditNotes);
