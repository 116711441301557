import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';

import { complexValueType } from 'data/types';
import TextDescriptionItem
  from 'components/DescriptionList/TextDescriptionItem/TextDescriptionItem';
import LocalDateDescriptionItem
  from 'components/DescriptionList/LocalDateDescriptionItem/LocalDateDescriptionItem';
import LocalDateDescriptionItemIncremented
  from 'components/DescriptionList/LocalDateDescriptionItemIncremented/LocalDateDescriptionItemIncremented';

const PharosClockV1 = props => {
  const { loading } = props;
  if (loading) return null;

  const {
    title,
    online,
    currentTime,
    offset,
    sunriseTime,
    sunsetTime,
  } = props;
  const {
    value: offsetValue,
  } = offset;

  return (
    <Segment>
      <Header as="h2">{title}</Header>
      <LocalDateDescriptionItemIncremented
        title="Current date/time"
        value={currentTime}
        isOnline={online}
        offset={offsetValue}
      />
      <TextDescriptionItem
        title="UTC offset"
        value={offset}
      />
      <LocalDateDescriptionItem
        title="Today's sunrise time (calculated)"
        value={sunriseTime}
        offset={offsetValue}
      />
      <LocalDateDescriptionItem
        title="Today's sunset time (calculated)"
        value={sunsetTime}
        offset={offsetValue}
      />
    </Segment>
  );
};

PharosClockV1.defaultProps = {
  loading: false,
  online: false,
  currentTime: {},
  offset: {},
  sunriseTime: {},
  sunsetTime: {},
};

PharosClockV1.propTypes = {
  loading: PropTypes.bool,
  online: PropTypes.bool,
  currentTime: complexValueType(PropTypes.number),
  offset: complexValueType(PropTypes.number),
  sunriseTime: complexValueType(PropTypes.number),
  sunsetTime: complexValueType(PropTypes.number),
  title: PropTypes.string.isRequired,
};

export default PharosClockV1;
