import React from 'react';
import { TextArea } from 'semantic-ui-react';

const InputTextAreaField = ({
  input,
  label,
  required,
  width,
  inline,
  loading,
  error,
  ...rest
}) => (
  <TextArea id={input.name} {...input} {...rest} />
);

InputTextAreaField.propTypes = {
  ...TextArea.propTypes,
};

export default InputTextAreaField;
