import styled, { css } from 'styled-components';
import get from 'lodash/get';
import { Breadcrumb } from 'semantic-ui-react';

export const StyledBreadcrumb = styled(Breadcrumb)`
  ${({ theme }) => css`
    display: block !important;
    margin-bottom: 0.8em !important;

    &.ui.breadcrumb a {
      color: ${get(theme, 'palette.primaryColor')} !important;
    }
  `}
`;

export default StyledBreadcrumb;
