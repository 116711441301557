import React from 'react';
import PropTypes from 'prop-types';

import { COMPANIES_FIELD, SHOW_COMPANIES_COMPONENT } from 'helpers/selectors';
import DataTable from 'components/DataTable/DataTableContainer';

import CompanyTableRow from './components/CompanyRow/CompanyRow';

const labels = [
  { text: 'Company', field: 'name', sortBy: 'name' },
];

const CompaniesTable = ({
  isVisible,
  selectedCompanyIds,
  onCompanySelected,
}) => {
  if (!isVisible) {
    return null;
  }

  const CustomCompanyTableRow = props => (
    <CompanyTableRow
      {...props}
      selectedCompanyIds={selectedCompanyIds}
      onCompanySelected={onCompanySelected}
    />
  );

  return (
    <DataTable
      labels={labels}
      customTableRow={CustomCompanyTableRow}
      route="/companies"
      pagination={{
        type: COMPANIES_FIELD,
        included: true,
        componentName: SHOW_COMPANIES_COMPONENT,
      }}
    />
  );
};

CompaniesTable.defaultProps = {
  isVisible: false,
  selectedCompanyIds: [],
};

CompaniesTable.propTypes = {
  isVisible: PropTypes.bool,
  selectedCompanyIds: PropTypes.array,
  onCompanySelected: PropTypes.func.isRequired,
};

export default CompaniesTable;
