import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Icon } from 'semantic-ui-react';
import { Field } from 'redux-form';
import isNull from 'lodash/isNull';

import { TipIcon } from 'components/atomic';
import {
  FormFieldLabel,
  FormFieldWrapper,
} from 'components/form/FormField/FormField.style';
import TextField from 'components/form/TextField/TextField';
import {
  astroText,
  astroHint,
  validInputError,
} from '../../constants';
import AstroToken from '../AstroToken/AstroToken';
import {
  StyledInputWrapper,
  StyledPlusButton,
  StyledFormGroup,
  StyledAstroInput,
} from '../AddSchedule/AddScheduleModalForm/AddScheduleModalForm.style';

class AstroFieldFormInput extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentOffset: 0,
    };

    this.addAstroEntry = this.addAstroEntry.bind(this);
    this.removeAstroEntry = this.removeAstroEntry.bind(this);
    this.changeCurrentOffset = this.changeCurrentOffset.bind(this);
  }

  addAstroEntry(e) {
    e.preventDefault();
    const {
      changeFormInput,
      astroFieldName,
      astroFieldValue,
      maxSelection,
      invalidAstroInput,
    } = this.props;

    const { currentOffset } = this.state;

    if (astroFieldName && !isNull(currentOffset)) {
      const selectedValuesArray = astroFieldValue ? astroFieldValue.split(',') : [];
      const checkDuplicate = selectedValuesArray.indexOf(currentOffset.toString()) === -1;

      if (checkDuplicate && selectedValuesArray.length < maxSelection) {
        const sortedArray = [...selectedValuesArray, currentOffset]
          .map(item => parseInt(item, 10))
          .sort((a, b) => a - b);
        changeFormInput(astroFieldName, sortedArray.join(','));
      } else {
        invalidAstroInput(validInputError);
      }
    }
  }

  removeAstroEntry(e, value) {
    e.preventDefault();
    const {
      changeFormInput,
      astroFieldName,
      astroFieldValue,
    } = this.props;

    const selectedValuesArray = astroFieldValue ? astroFieldValue.split(',') : [];
    const indexToRemove = selectedValuesArray.indexOf(value);
    selectedValuesArray.splice(indexToRemove, 1);
    changeFormInput(astroFieldName, selectedValuesArray.join(','));
  }

  changeCurrentOffset(value) {
    if (!isNull(value)) {
      this.setState({
        currentOffset: value,
      });
    }
  }

  render() {
    const {
      astroFieldName,
      astroFieldValue,
    } = this.props;
    const { currentOffset } = this.state;
    const tokenArray = astroFieldValue ? astroFieldValue.split(',') : [];

    return (
      <Fragment>
        <FormGroup inline>
          <FormFieldWrapper width={16}>
            <FormFieldLabel htmlFor="input">
              {`${astroText[astroFieldName]} (offset)`}
              <TipIcon message={astroHint} editMode={false} />
            </FormFieldLabel>
            <StyledInputWrapper>
              <StyledAstroInput
                id={astroFieldName}
                type="text"
                onChange={this.changeCurrentOffset}
                value={currentOffset}
              />
              <StyledPlusButton onClick={e => this.addAstroEntry(e)} icon>
                <Icon name="plus" />
              </StyledPlusButton>
              {tokenArray.map(item => (
                <AstroToken
                  key={item}
                  value={item}
                  removeToken={this.removeAstroEntry}
                  type={astroFieldName}
                />
              ))}
            </StyledInputWrapper>
          </FormFieldWrapper>
        </FormGroup>
        <StyledFormGroup inline>
          <Field
            name={astroFieldName}
            component={TextField}
            type="text"
          />
        </StyledFormGroup>
      </Fragment>
    );
  }

}

AstroFieldFormInput.defaultProps = {
  astroFieldName: '',
  astroFieldValue: '',
  maxSelection: 5,
};

AstroFieldFormInput.propTypes = {
  astroFieldName: PropTypes.string,
  astroFieldValue: PropTypes.string,
  changeFormInput: PropTypes.func.isRequired,
  maxSelection: PropTypes.number,
  invalidAstroInput: PropTypes.func.isRequired,
};

export default AstroFieldFormInput;
