import styled, { css } from 'styled-components';
import get from 'lodash/get';

const StyledLink = styled.a`
${({ theme }) => css`
  color: ${get(theme, 'palette.primaryColor')} !important;
  text-decoration: underline;
  opacity: 1 !important;
  font-weight: 300;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    opacity: 0.8 !important;
  }
`};
`;

export default StyledLink;
