import { compose } from 'redux';
import { connect } from 'react-redux';

import { DropdownMultiFieldInput } from 'components/structural';
import { tasksActionCreators } from 'application/tenant/utilities';

const mapDispatchToProps = {
  onMaxSelection: tasksActionCreators.selectionReachedMax,
};

export default compose(connect(null, mapDispatchToProps))(DropdownMultiFieldInput);
