import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';

import formNames from 'components/form/formNames';
import { validateOnSubmit } from 'components/form/formConfigs';
import NewUploadModalForm, { newUploadFormFields } from './NewUploadModalForm';

const NewUploadModalFormContainer = reduxForm({
  form: formNames.newUpload,
  ...validateOnSubmit,
})(NewUploadModalForm);

const mapStateToProps = state => ({
  files: formValueSelector(formNames.newUpload)(state, newUploadFormFields.files),
});

export default connect(mapStateToProps)(NewUploadModalFormContainer);
