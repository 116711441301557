import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  LOGICAL_DEVICES_FIELD,
  deviceSelector,
  permissionsLogicalDeviceOpenSelector,
} from 'helpers/selectors';
import { PermissionsGridWrapper } from '../../../../components';
import permissionsActionCreators from '../../../../utilities/permissions/permissionsActionCreators';
import { DevicePermissionsTree } from '../../tree/PermissionsTree';
import { DevicePermissionsGridHeaders } from '../../headers/PermissionsGridHeaders';
import { DevicePermissionsGrid } from '../../grid/PermissionsGrid';

export const mapStateToProps = (state, { deviceId }) => {
  const device = deviceSelector(state)(deviceId);
  const { name: deviceName } = device;

  return {
    open: permissionsLogicalDeviceOpenSelector(state, { deviceId }),
    resourceId: deviceId,
    resourceTitle: 'Device',
    resourceName: deviceName,
    PermissionsTree: DevicePermissionsTree,
    PermissionsGridHeaders: DevicePermissionsGridHeaders,
    PermissionsGrid: DevicePermissionsGrid,
  };
};

export const mapDispatchToProps = {
  onToggleOpen: deviceId => permissionsActionCreators.toggleResourceOpen(
    LOGICAL_DEVICES_FIELD,
    deviceId,
  ),
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(PermissionsGridWrapper);
