import isNull from 'lodash/isNull';

const convertBytesToGb = value => {
  if (isNull(value)) {
    return 'No data';
  }

  if (value === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(value) / Math.log(k));
  const sizeToDisplay = parseFloat((value / (k ** i)).toFixed(2));

  return `${sizeToDisplay} ${sizes[i]}`;
};

export default convertBytesToGb;
