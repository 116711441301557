import { call, put, race, delay } from 'redux-saga/effects';

import notificationsActionCreators from 'data/notifications/notificationsActionCreators';
import { messages } from 'data/notifications/notificationsConst';
import siteActionCreators from '../actionCreators';

function fetchAddress(url) {
  return fetch(url).then(response => response.json());
}

function* fetchReverseGeocoding(action) {
  const { siteId, latitude, longitude } = action.payload;
  const url = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=jsonv2`;

  const { response, timeout } = yield race({
    response: call(fetchAddress, url),
    timeout: delay(20000),
  });

  if (timeout) {
    yield put(
      notificationsActionCreators.addInfoNotification(messages.ERROR_CANNOT_FETCH_ADDRESS),
    );

    return;
  }

  const address = response.display_name || response.error;

  yield put(siteActionCreators.fetchReverseGeocodingSuccess(siteId, address));
}

export default fetchReverseGeocoding;
