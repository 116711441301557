import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { AutoRenewSuspendedForm } from './components';

const AutoRenewSuspended = ({
  showAutoRenewalSuspended,
  onSiteRenewSuspended,
  siteId,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const handleRenew = useCallback(async values => {
    const params = {
      values,
    };
    setSubmitting(true);

    await new Promise(resolveForm => {
      onSiteRenewSuspended({
        projectId: siteId,
        params: {
          ...params,
          resolveForm,
        },
      });
    });

    setSubmitting(false);
  }, [onSiteRenewSuspended, siteId]);

  return showAutoRenewalSuspended
    && (
      <AutoRenewSuspendedForm
        form="autoRenewSuspendedForm"
        onSubmit={handleRenew}
        submitting={submitting}
      />
    );
};

AutoRenewSuspended.defaultProps = {
  showAutoRenewalSuspended: false,
  onSiteRenewSuspended: () => null,
};

AutoRenewSuspended.propTypes = {
  showAutoRenewalSuspended: PropTypes.bool,
  onSiteRenewSuspended: PropTypes.func,
  siteId: PropTypes.string.isRequired,
};

export default AutoRenewSuspended;
