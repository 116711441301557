import styled from 'styled-components';

import { Button } from 'components/atomic';

export const Code = styled.textarea`
  width: 100%;
  resize: none;
  font-family: monospace;
  word-break: break-all;
  line-height: 1.4;
  outline: none;
  border: none;
  margin-bottom: 1em;
`;

export const HelpText = styled.div`
  margin-bottom: 1em;
`;

export const StyledButton = styled(Button)`
  &.ui.button {
    width: 200px;
  }
`;
