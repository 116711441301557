import { DEVICE_FIRMWARE_FILES_FIELD } from 'helpers/selectors';
import ApiRequestBuilder from 'helpers/ApiRequestBuilder';

class DeviceFirmwareFileModel {

  constructor(deviceFirmware = {}) {
    this.id = deviceFirmware.id;
    this.originalFileName = deviceFirmware.originalFileName;
    this.description = deviceFirmware.description;
    this.s3FilePath = deviceFirmware.s3FilePath;
  }

  static requestBody(values) {
    return new ApiRequestBuilder(DEVICE_FIRMWARE_FILES_FIELD)
      .setAttributes(values);
  }

}

export default DeviceFirmwareFileModel;
