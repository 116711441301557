import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledReloadButton,
  StyledReloadIcon,
} from './ReloadButton.style';

const ReloadButton = ({ isFetching, ...props }) => (
  <StyledReloadButton
    {...props}
    icon={<StyledReloadIcon name={isFetching ? 'close' : 'refresh'} />}
  />
);

ReloadButton.defaultProps = {
  isFetching: false,
};

ReloadButton.propTypes = {
  isFetching: PropTypes.bool,
};

export default ReloadButton;
