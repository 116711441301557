import styled from 'styled-components';
import { Header } from 'semantic-ui-react';

export const StyledHeader = styled(Header)`
  cursor: pointer;
`;

export const StyledPermissionsGridWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const StyledPermissionsGridSidebar = styled.div`
  flex: 0 0 250px;
`;

export const StyledPermissionsGridBody = styled.div`
  flex: 1 0 200px;
  overflow-x: auto;
`;

export const StyledPermissionsDevicesWrapper = styled.div`
  display: block;
`;
