import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';

import { AccordionItem } from 'components/structural';
import { SecretKey, StyledAccordion, StyledSegment } from './components';

const TwoFactorQRViewer = ({ onFetchTwoFactorQRCode, qrUri, secretKey }) => {
  useEffect(() => {
    onFetchTwoFactorQRCode();
  }, [onFetchTwoFactorQRCode]);

  if (!qrUri || !secretKey) {
    return null;
  }

  return (
    <Fragment>
      <QRCode value={qrUri} />
      <StyledSegment basic compact>
        <StyledAccordion>
          <AccordionItem
            title="Reveal secret key"
            component={SecretKey}
            secretKey={secretKey}
          />
        </StyledAccordion>
      </StyledSegment>
    </Fragment>
  );
};

TwoFactorQRViewer.defaultProps = {
  qrUri: null,
  secretKey: null,
};

TwoFactorQRViewer.propTypes = {
  onFetchTwoFactorQRCode: PropTypes.func.isRequired,
  qrUri: PropTypes.string,
  secretKey: PropTypes.string,
};

export default TwoFactorQRViewer;
