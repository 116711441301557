import { createSelector } from 'reselect';
import build from 'redux-object';

import { OUTPUT_FIELD, dataSelector, options } from 'helpers/selectors';
import OutputModel from 'models/OutputModel';

import protocolIdSelector from './protocolIdSelector';
import universeIdSelector from './universeIdSelector';

const outputsSelector = createSelector(
  protocolIdSelector,
  universeIdSelector,
  dataSelector,
  (protocolId, universeId, data) => {
    const outputs = build(data, OUTPUT_FIELD, null, options);

    if (!outputs) {
      return null;
    }

    return outputs
      .filter(output => output.protocolId === protocolId && output.universeId === universeId)
      .map(output => new OutputModel(output));
  },
);

export default outputsSelector;
