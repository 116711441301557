import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  permissionsProjectSuiteActiveUserIdsSelector,
} from 'helpers/selectors';
import { PermissionsGridHeaders } from '../../../../components';
import { ProjectSuiteUserLabel } from '../UserLabel';

export const makeMapState = () => function mapStateToProps(state, { resourceId }) {
  const userIds = permissionsProjectSuiteActiveUserIdsSelector(state, {
    projectSuiteId: resourceId,
  });

  return {
    userIds,
    UserLabel: ProjectSuiteUserLabel,
  };
};

export default compose(
  connect(makeMapState),
)(PermissionsGridHeaders);
