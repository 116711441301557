import constants from 'dispatcherConst';

const initialState = {
  removing: false,
  triggering: false,
  downloading: false,
};

const filesSectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.REMOVE_FILE_REQUEST:
      return { ...state, removing: true };

    case constants.REMOVE_FILE_REQUEST_SUCCESS:
    case constants.REMOVE_FILE_REQUEST_FAILED:
      return { ...state, removing: false };

    case constants.TRIGGER_FILE_TRANSFER_REQUEST:
      return { ...state, triggering: true };

    case constants.TRIGGER_FILE_TRANSFER_REQUEST_SUCCESS:
    case constants.TRIGGER_FILE_TRANSFER_REQUEST_FAILED:
      return { ...state, triggering: false };

    case constants.DOWNLOAD_FILE_REQUEST:
      return { ...state, downloading: true };

    case constants.DOWNLOAD_FILE_REQUEST_SUCCESS:
    case constants.DOWNLOAD_FILE_REQUEST_FAILED:
      return { ...state, downloading: false };

    default:
      return state;
  }
};

export default filesSectionReducer;
