import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';

export const deleteOrigin = (origin, companyId) => ({
  type: constants.DELETE_ORIGIN_REQUEST,
  payload: {
    endpoint: endpoints.ORIGIN(origin.id),
    companyId,
    origin,
  },
});

export const deleteOriginSuccess = response => ({
  type: constants.DELETE_ORIGIN_SUCCESS,
  payload: {
    response,
    message: messages.ORIGIN_DELETED(response.origin.name),
  },
});

export const deleteOriginFailure = error => ({
  type: constants.DELETE_ORIGIN_FAILED,
  payload: { error },
});
