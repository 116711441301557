const byDatetime = ({ datetime: first }, { datetime: second }) => {
  if (+first === +second) {
    return 0;
  }

  if (first < second) {
    return -1;
  }

  return 1;
};

export default byDatetime;
