import styled, { css } from 'styled-components';
import { Grid, Input } from 'semantic-ui-react';

import filterProps from 'helpers/filterProps';
import { mediaMax } from 'themes/mixins';

export const Container = styled.div`
  min-height: 40vh;
  padding: 25px 0;
`;

export const WeekdaysContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 0.2fr);
  gap: 0.4em;

  ${mediaMax.sm`
    grid-template-columns: repeat(3, 0.2fr);
  `};
`;

export const StyledLabel = styled.label`
  font-weight: bold !important;
  margin: auto 0px !important;
  font-size: 14px !important;

  ${({ withCustomPadding }) => withCustomPadding && css`
    padding-bottom: 12px;
    padding-top: -14px;
  `};
`;

const GridRow = filterProps([
  'noPaddingBottom',
])(Grid.Row);

export const StyledRow = styled(GridRow)`
  padding-top: 8px;

  ${({ noPaddingBottom }) => noPaddingBottom && css`
    padding-bottom: 0 !important;
  `};
`;

export const StyledInput = styled(Input)`
  width: 50px !important;
`;

export const ErrorMessage = styled.small`
  font-size: 12px;
  color: #9f3a38;
  display: block;
  margin-top: 4px;
`;

export default Container;
