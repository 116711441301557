import isEmpty from 'lodash/isEmpty';

const getPermissionNames = permissions => {
  if (!permissions || isEmpty(permissions)) {
    return [];
  }

  return permissions
    .map(p => `${p.resource}:${p.permissionName}`)
    .sort();
};

export default getPermissionNames;
