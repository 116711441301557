import React from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from 'semantic-ui-react';

const DataTableActionDropdownItem = ({ title, ...rest }) => (
  <Dropdown.Item {...rest}>{title}</Dropdown.Item>
);

DataTableActionDropdownItem.propTypes = {
  title: PropTypes.string.isRequired,
};

export default DataTableActionDropdownItem;
