import { select } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';

import additionalFiltersSelector from './selectors/additionalFiltersSelector';

export default function* applyNotificationEventsFilters(method) {
  const notificationFilters = (
    yield select(additionalFiltersSelector)
  );

  if (!isEmpty(notificationFilters)) {
    const updatedMethod = { ...method };

    updatedMethod.payload.urlParams = {
      ...updatedMethod.payload.urlParams,
      ...notificationFilters,
    };

    return updatedMethod;
  }

  return method;
}
