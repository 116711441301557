import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AddOperationModalForm from './AddOperationModalForm/AddOperationModalFormContainer';

class AddOperation extends Component {

  constructor() {
    super();

    this.handleAddOperation = this.handleAddOperation.bind(this);
  }

  handleAddOperation(values) {
    const {
      onAddOperation,
    } = this.props;

    return new Promise((resolveForm, rejectForm) => {
      onAddOperation(
        {
          values,
          resolveForm,
          rejectForm,
        },
      );
    });
  }

  render() {
    return (
      <AddOperationModalForm
        onSubmit={this.handleAddAction}
      />
    );
  }

}

AddOperation.propTypes = {
  onAddOperation: PropTypes.func.isRequired,
};

export default AddOperation;
