import isNil from 'lodash/isNil';

import { lang, translationLabels } from '../constants';

const NO_ACTION_LIST = 'No action list available';

function getActionType(type, listObject) {
  if (isNil(type)) {
    return NO_ACTION_LIST;
  }

  if (isNil(listObject)) {
    return NO_ACTION_LIST;
  }

  if (type in listObject) {
    const text = translationLabels[listObject[type].label]
      ? translationLabels[listObject[type].label][lang]
      : listObject[type].label;

    return text;
  }

  return 'Action type not found';
}

export default getActionType;
