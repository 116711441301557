import { snakeCaseToOption } from 'helpers/sharedMethods';

function getParameter(value, transformSnakeCase = false) {
  const valType = typeof value;

  if (valType === 'string' && transformSnakeCase) {
    return snakeCaseToOption(value);
  }

  if (valType === 'string') {
    return value;
  }

  if (valType === 'number') {
    return value;
  }

  if (valType === 'object') {
    return Array.isArray(value)
      ? value.toString().split(',').join(', ')
      : 'undefined';
  }

  if (valType === 'boolean') {
    return value ? 'true' : 'false';
  }

  return 'undefined';
}

export default getParameter;
