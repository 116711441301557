import { call } from 'redux-saga/effects';

import { putPath } from 'data/api/requests';
import OriginModel from 'models/OriginModel';
import { processCall } from 'helpers/sagaHelper';
import actionCreators from '../actionCreators';

function* editOrigin(action) {
  const {
    endpoint,
    params: { values, resolveForm, rejectForm },
  } = action.payload;

  const body = OriginModel.requestBody(values);
  const { response, error } = yield call(putPath, endpoint, body);

  const params = {
    response,
    error,
    successDisp: actionCreators.editOriginSuccess,
    failDisp: actionCreators.editOriginFailure,
    resolveForm,
    rejectForm,
  };
  yield call(processCall, params);
}

export default editOrigin;
