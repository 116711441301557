import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { singleProjectSelector } from 'helpers/selectors';
import SelectedProject from './SelectedProject';

export const mapStateToProps = (state, { projectId }) => {
  const { visibleName } = singleProjectSelector(state, projectId);

  return {
    selectedProjectId: projectId,
    resourceName: visibleName,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
)(SelectedProject);
