import { call, takeLatest, select } from 'redux-saga/effects';
import uuidv4 from 'uuid/v4';

import constants from 'dispatcherConst';
import PerformActionModel from 'models/PerformActionModel';
import { postPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import { payloadToSyncDevicesFirmwareSelector } from 'helpers/selectors';
import performActionActionCreators from './performActionActionCreators';

export const actionTypes = {
  FORMAT: 'format',
  RESET: 'reset',
  TRIGGERS: 'triggers',
  TRIGGERS_COND: 'triggers_cond',
  SYNC_FIRMWARE: 'reload_remote_device_firmware',
};

export function* performFormat(action) {
  const { endpoint } = action.payload;
  const id = yield uuidv4();
  const body = PerformActionModel.requestBody({ id, action: actionTypes.FORMAT });
  const { response, error } = yield call(postPath, endpoint, body);

  const params = {
    response,
    error,
    successDisp: performActionActionCreators.performFormatSuccess,
    failDisp: performActionActionCreators.performFormatFailure,
  };
  yield call(processCall, params);
}

export function* performReset(action) {
  const { endpoint } = action.payload;
  const id = yield uuidv4();
  const body = PerformActionModel.requestBody({ id, action: actionTypes.RESET });
  const { response, error } = yield call(postPath, endpoint, body);

  const params = {
    response,
    error,
    successDisp: performActionActionCreators.performResetSuccess,
    failDisp: performActionActionCreators.performResetFailure,
  };
  yield call(processCall, params);
}

export function* performTriggers(action) {
  const { endpoint, params: { triggerIds } } = action.payload;
  const id = yield uuidv4();
  const body = PerformActionModel.requestBody({
    id,
    action: actionTypes.TRIGGERS,
    meta: {
      trigger_ids: triggerIds,
    },
  });

  const { response, error } = yield call(postPath, endpoint, body);

  const params = {
    response,
    error,
    successDisp: performActionActionCreators.performTriggersSuccess,
    failDisp: performActionActionCreators.performTriggersFailure,
  };
  yield call(processCall, params);
}

export function* performTriggersCond(action) {
  const { endpoint, params: { triggerIds, testConditions } } = action.payload;
  const id = yield uuidv4();
  const body = PerformActionModel.requestBody({
    id,
    action: actionTypes.TRIGGERS_COND,
    meta: {
      triggerIds,
      testConditions,
    },
  });

  const { response, error } = yield call(postPath, endpoint, body);

  const params = {
    response,
    error,
    successDisp: performActionActionCreators.performTriggersSuccess,
    failDisp: performActionActionCreators.performTriggersFailure,
  };
  yield call(processCall, params);
}

export function* performAction(action) {
  const { endpoint, actionType, values, ...rest } = action.payload;
  const id = yield uuidv4();
  const body = PerformActionModel.requestBody({
    id,
    action: actionType,
    meta: values,
  });
  const { response, error } = yield call(postPath, endpoint, body);
  const responseWithMetadata = (
    response
      ? { ...response, values }
      : undefined
  );

  const params = {
    response: responseWithMetadata,
    error,
    successDisp: performActionActionCreators.performActionSuccess,
    failDisp: performActionActionCreators.performActionFailure,
    ...rest,
  };
  yield call(processCall, params);
}

export function* performSyncFirmware(action) {
  const { endpoint, deviceId } = action.payload;

  const syncPayload = (yield select(payloadToSyncDevicesFirmwareSelector, deviceId));

  const values = {
    remoteDevices: syncPayload,
  };
  const body = PerformActionModel.requestBody({
    action: actionTypes.SYNC_FIRMWARE,
    meta: values,
  });

  const { response, error } = yield call(postPath, endpoint, body);
  const responseWithMetadata = (
    response
      ? { ...response, values }
      : undefined
  );

  const params = {
    response: responseWithMetadata,
    error,
    successDisp: performActionActionCreators.performSyncFirmwareSuccess,
    failDisp: performActionActionCreators.performSyncFirmwareFailure,
  };
  yield call(processCall, params);
}

function* performActionSaga() {
  yield takeLatest(constants.PERFORM_FORMAT_REQUEST, performFormat);
  yield takeLatest(constants.PERFORM_RESET_REQUEST, performReset);
  yield takeLatest(constants.PERFORM_TRIGGERS_REQUEST, performTriggers);
  yield takeLatest(constants.PERFORM_TRIGGERS_COND_REQUEST, performTriggersCond);
  yield takeLatest(constants.PERFORM_ACTION_REQUEST, performAction);
  yield takeLatest(constants.PERFORM_SYNC_FIRMWARE_REQUEST, performSyncFirmware);
}

export default performActionSaga;
