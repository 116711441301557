import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import ProjectSuiteContext from '../../../../../ProjectSuiteContext/ProjectSuiteContext';
import AddAction from '../AddAction/AddActionContainer';
import { ActionTableHeaderCell } from './ActionsTableHeader.style';

const ActionsTableHeader = ({ taskId, showButton }) => {
  const superSites = React.useContext(ProjectSuiteContext);

  return (
    <Table.Header>
      <Table.Row>
        <ActionTableHeaderCell>Target</ActionTableHeaderCell>
        <ActionTableHeaderCell>Name</ActionTableHeaderCell>
        <ActionTableHeaderCell>Type</ActionTableHeaderCell>
        <Table.HeaderCell textAlign="right">
          {showButton && <AddAction size="mini" taskId={taskId} superSites={superSites} />}
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

ActionsTableHeader.propTypes = {
  taskId: PropTypes.string.isRequired,
  showButton: PropTypes.bool.isRequired,
};

export default ActionsTableHeader;
