import React from 'react';
import PropTypes from 'prop-types';

import ModalForm from 'components/form/ModalForm/ModalFormV2';
import { RenderActionTypes } from './components';

const AddActionModalForm = ({
  taskName,
  projectName,
  superSites,
  ...rest
}) => {
  const resource = superSites ? 'multi-site' : 'site';
  const addAction = `Add Action to ${taskName} on ${resource} ${projectName}`;

  return (
    <ModalForm
      header={addAction}
      trigger="Add Action"
      size="small"
      triggerSize="mini"
      {...rest}
    >
      <RenderActionTypes superSites={superSites} />
    </ModalForm>
  );
};

AddActionModalForm.defaultProps = {
  superSites: false,
  projectName: '',
};

AddActionModalForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  taskName: PropTypes.string.isRequired,
  projectName: PropTypes.string,
  superSites: PropTypes.bool,
};

export default AddActionModalForm;
