import React from 'react';
import PropTypes from 'prop-types';

import DataTableText from 'components/DataTable/DataTableText/DataTableText';
import { StyledTableRow } from '../../../../AutomatedOperationsAccordionRow.style';

const OperationCustomRow = props => (
  <StyledTableRow
    {...props}
  >
    <DataTableText />
    <DataTableText />
  </StyledTableRow>
);

OperationCustomRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default OperationCustomRow;
