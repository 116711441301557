import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  singleProjectSelector,
  permissionsProjectOpenSelector,
  permissionsProjectLogicalDevicesLinkSelector,
  permissionsProjectLogicalDeviceIdsSelector,
} from 'helpers/selectors';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { permissionsActionCreators } from '../../../../../utilities';
import withLoadProject from './withLoadProject/withLoadProjectContainer';
import PermissionsProjectWrapper from './PermissionsProjectWrapper';

export const mapStateToProps = (state, { projectId }) => {
  const { name: projectName } = singleProjectSelector(state, projectId);
  const logicalDeviceIds = permissionsProjectLogicalDeviceIdsSelector(state, { projectId });

  return {
    projectName,
    projectOpen: permissionsProjectOpenSelector(state, { projectId }),
    logicalDeviceIds,
    projectLogicalDevicesEndpoint: permissionsProjectLogicalDevicesLinkSelector(state)(projectId),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withLoadProject,
  chainedLoaderWithSaga(
    ({ projectId, projectLogicalDevicesEndpoint }) => (
      permissionsActionCreators.fetchProjectLogicalDevices(
        projectId,
        projectLogicalDevicesEndpoint,
        {
          fields: {
            logicalDevices: ['name'],
          },
          sort: 'name',
        },
      )
    ),
  ),
)(PermissionsProjectWrapper);
