import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { singleProjectSuiteSelector } from 'helpers/selectors';
import { deepEqualMemoizeOne } from 'helpers/sharedMethods';

import Map from 'application/tenant/console/site/components/Map/Map';

import ProjectSuiteMapMarker from './ProjectSuiteMapMarker/ProjectSuiteMapMarkerContainer';
import { siteMapCenteringBehaviour, siteMapEmptyClickBehaviour } from './containers';

export const makeMapState = () => {
  const memoMarkers = deepEqualMemoizeOne();

  return function mapStateToProps(state, { match }) {
    const { id: projectSuiteId } = match.params;

    const {
      locationLoaded,
      latitude,
      longitude,
    } = singleProjectSuiteSelector(state, projectSuiteId) || {};

    const markers = [
      {
        id: `marker-${projectSuiteId}`,
        projectSuiteId,
        resourceId: projectSuiteId,
        latitude,
        longitude,
      },
    ];

    return {
      projectSuiteId,
      locationReady: locationLoaded,
      resourceId: projectSuiteId,
      target: `map-${projectSuiteId}`,
      markers: memoMarkers(markers),
      markerComponent: ProjectSuiteMapMarker,
    };
  };
};

export default compose(
  withRouter,
  connect(makeMapState),
  siteMapCenteringBehaviour,
  siteMapEmptyClickBehaviour,
)(Map);
