import styled from 'styled-components';
import { Button, FormGroup } from 'semantic-ui-react';

import TimeField from 'components/form/TimeField/TimeField';
import {
  FormFieldWrapper,
} from 'components/form/FormField/FormField.style';
import AstroInput from '../../AstroInput/AstroInput';

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  height: auto;

  & .rw-widget-input.rw-input {
    display: none;
  }

  & .ui.icon.button, .ui.icon.buttons .button {
    padding: 0.7em 0.9em;
  }

  & .rw-btn.rw-btn-select {
    width: 33px;
    height: 35px;
  }

  & .rw-datetime-picker.rw-widget {
    margin-right: 3px;
  }

  & .rw-datetime-picker.rw-widget .rw-calendar-month .rw-cell {
    font-size: 13px;

    @media only screen and (max-width: 768px) {
      font-size: 10px;
    }
  }

  & .rw-datetime-picker.rw-widget .rw-calendar-grid {
    height: 12em;

    @media only screen and (max-width: 768px) {
      height: 10em;
    }
  }
`;

export const StyledTokenButton = styled(Button)`
  &.ui.icon.button {
    height: 37px;
    font-size: 10px;
    margin-right: 3px;
    margin-bottom: 3px;
  }
`;

export const StyledFormGroup = styled(FormGroup)`
  &.inline.fields {
    display: none;
  }
`;

export const StyledTimeField = styled(TimeField)`
  width: 140px;
  margin-right: 3px;
`;

export const StyledAstroInput = styled(AstroInput)`
  width: 140px;
  margin-right: 3px;
`;

export const StyledPlusButton = styled(Button)`
  &.ui.icon.button {
    height: 37px;
    font-size: 10px;
    margin-right: 3px;
    margin-bottom: 3px;
  }
`;

export const StyledFormFieldWrapper = styled(FormFieldWrapper)`
  align-items: center !important;
`;

export default StyledInputWrapper;
