export default class JamboxCreator {

  constructor(sixeyeFirmwareVersion, tabs) {
    this.model = 'Jambox';
    this.sixeyeFirmwareVersion = sixeyeFirmwareVersion;
    this.manufacturerSDK = 'slc';
    this.tabs = tabs;
  }

}
