import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import { Button } from 'components/atomic';
import { StyledDropdown } from './LinkProjectToProjectSuite.style';

function LinkProjectToProjectSuite({
  isLoading,
  projectSuiteId,
  availableProjects,
  onAddProjectToProjectSuite,
}) {

  const handleProjectSelected = useCallback(project => {
    onAddProjectToProjectSuite({
      projectId: project.id,
      projectName: project.name,
      projectSuiteId,
    });
  }, [projectSuiteId, onAddProjectToProjectSuite]);

  const renderDropdownTrigger = useCallback(() => (
    <Button primary loading={isLoading} disabled={isLoading}>
      Add new link...
    </Button>
  ), [isLoading]);

  return (
    <>
      <StyledDropdown
        icon=""
        floating
        trigger={renderDropdownTrigger()}
      >
        <Dropdown.Menu>
          <Dropdown.Header content="Select a Site to link" />
          {availableProjects && availableProjects.map(project => (
            <Dropdown.Item key={project.id} name="project-item" onClick={() => handleProjectSelected(project)}>
              {project.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </StyledDropdown>
    </>
  );
}

LinkProjectToProjectSuite.defaultProps = {
  projectSuiteId: null,
  availableProjects: [],
  isLoading: false,
};

LinkProjectToProjectSuite.propTypes = {
  projectSuiteId: PropTypes.string,
  availableProjects: PropTypes.array,
  isLoading: PropTypes.bool,
  onAddProjectToProjectSuite: PropTypes.func.isRequired,
};

export default LinkProjectToProjectSuite;
