import styled from 'styled-components';

import NumberInput from '../NumberInput/NumberInput';
import { NumberInputElement } from '../NumberInput/NumberInput.style';

export const StyledNumberInput = styled(NumberInput)`
  & ${NumberInputElement} {
    width: 1.25em!important;
  }
`;

export default StyledNumberInput;
