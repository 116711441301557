import isUndefined from 'lodash/isUndefined';

import UniverseModel from './UniverseModel';

class PatchModel {

  constructor(patch = {}) {
    this.id = !isUndefined(patch.id) ? Number(patch.id) : null;
    this.originalId = patch.id;
    this.protocol = patch.protocol;

    const { universes = [] } = patch;

    this.universes = universes.map(
      universe => new UniverseModel(universe),
    );
  }

  get selectorOption() {
    const { id, protocol } = this;

    return {
      key: String(id),
      value: String(id),
      text: protocol,
    };
  }

}

export default PatchModel;
