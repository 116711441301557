import styled, { css } from 'styled-components';
import { Grid } from 'semantic-ui-react';

import filterProps from 'helpers/filterProps';

export const Container = styled.div`
  min-height: 40vh;
  padding: 25px 0;
`;

export const StyledLabel = styled.label`
  font-weight: bold !important;
  margin: auto 0px !important;
  font-size: 14px !important;

  ${({ withCustomPadding }) => withCustomPadding && css`
    padding-bottom: 12px;
    padding-top: -14px;
  `};
`;

export const StyledColumn = styled(Grid.Column)`
  display: flex;
`;

const GridRow = filterProps([
  'noPaddingBottom',
])(Grid.Row);

export const StyledRow = styled(GridRow)`
  padding-top: 8px;

  ${({ noPaddingBottom }) => noPaddingBottom && css`
    padding-bottom: 0 !important;
  `};
`;

export const ErrorMessage = styled.small`
  font-size: 12px;
  color: #9f3a38;
  display: block;
  margin-top: 4px;
`;

export default Container;
