import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';

import { dateTimeToJsDate, getOccurrenceColors } from './utilities';
import {
  StyledDayPicker,
  DayCell,
  DayNumber,
  DotsContainer,
  Dot,
} from './CalendarView.style';

const CalendarView = ({
  active,
  siteTime,
  modifiers,
  occurrences,
  isLoading,
  onDayClick,
  onMonthChange,
}) => {
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [hideDots, setHideDots] = useState(false);

  const occurrencesColors = useMemo(() => {
    if (isEmpty(occurrences)) {
      return [];
    }

    return getOccurrenceColors(occurrences);
  }, [occurrences]);

  const renderDay = useCallback(day => {
    const date = day.getDate();

    const colors = has(occurrencesColors, date) ? occurrencesColors[date] : [];

    return (
      <DayCell>
        <DayNumber>{date}</DayNumber>
        <DotsContainer>
          {colors.map((color, index) => (
            <Dot
              name="color-dot"
              key={color}
              color={color}
              index={Number(index)}
              length={colors.length}
              hideDots={hideDots}
            />
          ))}
        </DotsContainer>
      </DayCell>
    );
  }, [occurrencesColors, hideDots]);

  const handleMonthChange = useCallback(month => {
    setHideDots(true);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const newTypingTimeout = setTimeout(() => {
      onMonthChange(month, () => {
        setHideDots(false);
      });
    }, 675);

    setTypingTimeout(newTypingTimeout);
  }, [onMonthChange, typingTimeout]);

  if (!siteTime) {
    return null;
  }

  return (
    <StyledDayPicker
      month={dateTimeToJsDate(active)}
      modifiers={modifiers}
      onDayClick={onDayClick}
      onMonthChange={handleMonthChange}
      renderDay={renderDay}
      isLoading={isLoading}
      hideDots={hideDots}
    />
  );
};

CalendarView.defaultProps = {
  active: null,
  siteTime: null,
  occurrences: [],
  isLoading: false,
};

CalendarView.propTypes = {
  active: PropTypes.object,
  occurrences: PropTypes.array,
  siteTime: PropTypes.object,
  modifiers: PropTypes.object.isRequired,
  onDayClick: PropTypes.func.isRequired,
  onMonthChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default CalendarView;
