import styled from 'styled-components';
import { Modal, Button, List, Header } from 'semantic-ui-react';

export const StyledButton = styled(Button)`
  &.ui.button {
    margin-bottom: 10px;
  }
`;

export const StyledLabel = styled.label`
  font-weight: bold;
  margin-bottom: 8px;
  display: block;
`;

export const StyledModalContent = styled(Modal.Content)`
  min-height: 380px;
`;

export const StyledModalActions = styled(Modal.Actions)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledList = styled(List)`
  max-height: 280px;
  overflow: auto;
`;

export const StyledListContent = styled(List.Content)`
  display: flex; 
  align-items: center; 
  justify-content: space-between; 
  padding-top: 4px; 
  padding-bottom: 4px;
`;

export const StyledSubheader = styled(Header.Subheader)`
  margin-top: 8px;
`;

export const StyledHelpTextList = styled.ul`
  padding-left: 0px;
  list-style: none;
`;


export default StyledLabel;
