import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Segment } from 'semantic-ui-react';
import isNil from 'lodash/isNil';

import { frontendPermissions, PermissionsSwitch, Gateway } from 'gateway';
import { TimePicker } from 'components/core';

import { SiteMap } from './containers';
import {
  StyledMapInformationWrapper,
  StyledMapInformationItem,
  StyledLoader,
  StyledLabel,
} from './SiteMapSection.style';

const SiteMapSection = ({
  siteId,
  locationLoaded,
  locationSet,
  latitude,
  longitude,
  geoAddress,
  offset,
  timezoneId,
  currentSiteDate,
  currentSiteTime,
  dateBlockingStartTime,
  onUpdateProjectDateBlockingStartTime,
}) => {
  const informationItems = [
    { title: 'Latitude', value: latitude },
    { title: 'Longitude', value: longitude },
    { title: 'Geo address', value: geoAddress },
    { title: 'UTC offset', value: offset },
    { title: 'Time zone', value: timezoneId },
    { title: 'Site date', value: currentSiteDate },
    { title: 'Site local time', value: currentSiteTime },
  ];

  const handleDateBlockingTimeChange = useCallback(timePickerValues => {
    const [selectedTime] = timePickerValues;

    onUpdateProjectDateBlockingStartTime({
      values: {
        id: siteId,
        dateBlockingStartTime: selectedTime,
      },
    });
  }, [onUpdateProjectDateBlockingStartTime, siteId]);

  const renderItems = useCallback(() => {
    if (!locationLoaded) {
      return null;
    }

    if (!locationSet) {
      return (
        <StyledMapInformationWrapper>
          <StyledMapInformationItem>
            No location set
          </StyledMapInformationItem>
        </StyledMapInformationWrapper>
      );
    }

    return (
      <StyledMapInformationWrapper>
        {informationItems.map(({ title, value }) => (
          <StyledMapInformationItem key={title}>
            <strong>{title}: </strong>
            {!isNil(value) ? value : <StyledLoader size="mini" active inline />}
          </StyledMapInformationItem>
        ))}
        <PermissionsSwitch>
          <Gateway condition={frontendPermissions.SITE_EDIT_DATE_BLOCKING_START_TIME}>
            <StyledMapInformationItem flex>
              <StyledLabel>
                Date blocking start time:
              </StyledLabel>
              <TimePicker
                onChange={handleDateBlockingTimeChange}
                selectedTimes={[dateBlockingStartTime || '00:00:00']}
                only={['SIMPLE']}
                single
              />
            </StyledMapInformationItem>
          </Gateway>
        </PermissionsSwitch>
      </StyledMapInformationWrapper>
    );
  }, [
    informationItems,
    locationLoaded,
    locationSet,
    dateBlockingStartTime,
    handleDateBlockingTimeChange,
  ]);

  return (
    <Segment>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header as="h3">Site location and time</Header>
            <SiteMap />
            {renderItems()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

SiteMapSection.defaultProps = {
  latitude: null,
  longitude: null,
  geoAddress: null,
  offset: null,
  timezoneId: null,
  currentSiteDate: null,
  currentSiteTime: null,
  dateBlockingStartTime: null,
};

SiteMapSection.propTypes = {
  siteId: PropTypes.string.isRequired,
  locationLoaded: PropTypes.bool.isRequired,
  locationSet: PropTypes.bool.isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  geoAddress: PropTypes.string,
  offset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  timezoneId: PropTypes.string,
  currentSiteDate: PropTypes.string,
  currentSiteTime: PropTypes.string,
  dateBlockingStartTime: PropTypes.string,
  onUpdateProjectDateBlockingStartTime: PropTypes.func.isRequired,
};

export default SiteMapSection;
