import React from 'react';

const Low = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="11"
    fill="none"
    viewBox="0 0 14 11"
  >
    <path
      stroke="#5394FB"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M1 1.913l6 3.913 6-3.913M1 6.087L7 10l6-3.913"
    />
  </svg>
);

export default Low;
