import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { withProjectSuitePermissions } from 'gateway';
import {
  currentUserSelector,
  singleProjectSuiteSelector,
  PROJECTS_FIELD,
} from 'helpers/selectors';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import {
  actionCreators as projectSuiteActionCreators,
  selectors as projectSuitesSelectors,
} from 'pages/ProjectSuites/utilities';

import { tasksActionCreators } from 'application/tenant/utilities';

import ProjectSuiteSchedulingTab from './ProjectSuiteSchedulingTab';

const mapDispatchToProps = {
  onClearProjectSuiteIdContext: projectSuiteActionCreators.clearProjectSuiteIdContext,
};

export const mapStateToProps = (state, { match }) => {
  const { id: projectSuiteId } = match.params;
  const { id: currentUserId } = currentUserSelector(state);
  const { locationSet, locationLoaded } = singleProjectSuiteSelector(state, projectSuiteId);

  const projects = projectSuitesSelectors.projectsListSelector(state)(projectSuiteId);
  const projectsIds = projects ? projects.map(({ id }) => id) : null;
  const projectsList = projectSuitesSelectors.projectsListSelector(state)(projectSuiteId) || [];
  const options = projectsList.map(({ id, name }) => ({ id, text: name, value: id }));
  const projectIdsList = projectsList.map(({ id }) => id);
  const allProjects = { id: 'allSites', text: 'Everything', value: 'allSites' };
  const onlySuperSiteTasks = { id: 'allSites', text: 'Only Multi-site task schedulers', value: 'superSiteTasks' };
  const onlySuperSiteOccurrences = { id: 'allSites', text: 'Only Multi-site occurrences', value: 'superSiteTasks' };
  const occurrencesOptions = [...options];
  if (!isEmpty(options)) {
    options.unshift(allProjects);
    occurrencesOptions.unshift(allProjects);
  }
  options.unshift(onlySuperSiteTasks);
  occurrencesOptions.unshift(onlySuperSiteOccurrences);

  return {
    currentUserId,
    projectSuiteId,
    locationSet,
    locationLoaded,
    projectsIds,
    projectIdsList,
    options,
    occurrencesOptions,
  };
};

export default compose(
  withRouter,
  withProjectSuitePermissions,
  connect(mapStateToProps, mapDispatchToProps),
  chainedLoaderWithSaga(tasksActionCreators.fetchActionTypes),
  chainedLoaderWithSaga(
    ({ projectSuiteId }) => projectSuiteActionCreators.fetchProjectSuiteProjectsFilter(
      projectSuiteId, {
        fields: {
          [PROJECTS_FIELD]: [
            'name',
          ],
        },
        sort: 'name',
      }),
    ({ projectSuiteId }) => projectSuiteActionCreators.addProjectSuiteIdContext(projectSuiteId),
  ),
  chainedLoaderWithSaga(
    () => projectSuiteActionCreators.fetchAdminProjectSuites(
      {
        fields: { projectSuites: ['name'] },
        sort: 'name',
      },
    ),
  ),
)(ProjectSuiteSchedulingTab);
