import styled from 'styled-components';

export default styled.div`
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
  align-items: center;

  .ui.header {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
