import { createSelector } from 'reselect';
import memoize from 'lodash/memoize';
import build from 'redux-object';

import UserPermissionModel from 'models/UserPermissionModel';
import { USER_PERMISSIONS_FIELD, options, dataSelector } from 'helpers/selectors';

const userPermissionsSelector = createSelector(
  dataSelector,
  data => memoize(
    userId => {
      const { [USER_PERMISSIONS_FIELD]: userPermissionData } = data;

      if (!userPermissionData) {
        return [];
      }

      const userPermissions = build(userPermissionData, userId, null, options);

      if (!userPermissions) {
        return [];
      }

      return userPermissions.map(
        userPermission => new UserPermissionModel({ ...userPermission, userId }),
      );
    },
  ),
);

export default userPermissionsSelector;
