import 'whatwg-fetch';

import { callPath, headers } from './requests-helpers';

export const API_URL = process.env.REACT_APP_API_URL;

export function getPath(endpoint, modifiers) {
  return callPath(endpoint, { headers: headers() }, modifiers);
}

export function postPath(endpoint, body = {}, modifiers) {
  const options = {
    headers: headers(),
    method: 'POST',
    body: JSON.stringify(body),
  };

  return callPath(endpoint, options, modifiers);
}

export function putPath(endpoint, body = {}) {
  return callPath(endpoint, {
    headers: headers(),
    method: 'PUT',
    body: JSON.stringify(body),
  });
}

export function deletePath(endpoint, body = {}, modifiers) {
  return callPath(endpoint, {
    headers: headers(),
    method: 'DELETE',
    body: JSON.stringify(body),
  },
  modifiers);
}
