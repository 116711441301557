import moment from 'moment';

const getTimeIntervals = (startTime, endTime, interval) => {
  if (!interval || interval === '00:00') {
    return [];
  }

  const intervals = [];
  const dateFormat = 'HH:mm:ss';

  const tmp = moment(interval, dateFormat);
  const dif = tmp - moment().startOf('day');

  const startIntervalTime = moment(startTime, dateFormat);
  const endIntervalTime = moment(startTime, dateFormat);
  const finishTime = moment(endTime, dateFormat);

  while (startIntervalTime <= finishTime) {
    intervals.push(startIntervalTime.format(dateFormat));

    startIntervalTime.add(dif, 'ms');
    endIntervalTime.add(dif, 'ms');
  }

  return intervals;
};

export default getTimeIntervals;
