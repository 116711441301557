import React from 'react';
import truncate from 'lodash/truncate';

import { LOGICAL_DEVICES_FIELD } from 'helpers/selectors';
import ApiRequestBuilder from 'helpers/ApiRequestBuilder';
import { StatusIcon } from './components';

class DeviceModel {

  constructor(device = {}) {
    this.id = device.id;
    this.deviceAssigned = device.deviceAssigned;
    this.manufacturerName = device.manufacturerName;
    this.model = device.model;
    this.name = device.name;
    this.online = device.online;
    this.serialNumber = device.serialNumber;
    this.reported = device.reported;
    this.reportedWithMeta = device.reportedWithMeta;
    this.viewSelector = device.viewSelector;
    this.beacon = device.beacon;
  }

  get statusIcon() {
    const { id, deviceAssigned, online } = this;

    return (
      <StatusIcon deviceId={id} deviceAssigned={deviceAssigned} online={online} />
    );
  }

  get statusIconForMap() {
    const { id, deviceAssigned, online } = this;

    return (
      <StatusIcon deviceId={id} deviceAssigned={deviceAssigned} online={online} disableLabel />
    );
  }

  get visibleName() {
    return truncate(this.name, {
      length: 30,
      separator: ' ',
      omission: ' ...',
    });
  }

  static requestBody(values) {
    return new ApiRequestBuilder(LOGICAL_DEVICES_FIELD)
      .setAttributes(values);
  }

}

export default DeviceModel;
