import triggersFetchingSelector from './triggersFetchingSelector';
import timelinesFetchingSelector from './timelinesFetchingSelector';
import scenesFetchingSelector from './scenesFetchingSelector';
import groupsFetchingSelector from './groupsFetchingSelector';

export default {
  triggersFetchingSelector,
  timelinesFetchingSelector,
  scenesFetchingSelector,
  groupsFetchingSelector,
};
