import React from 'react';
import omit from 'lodash/omit';

import { getDisplayName } from 'helpers/sharedMethods';

const filterPropsHOC = propNames => WrappedComponent => {
  const FilterProps = props => {
    const pickedProps = omit(props, propNames);

    return <WrappedComponent {...pickedProps} />;
  };

  FilterProps.displayName = `FilterProps(${getDisplayName(WrappedComponent)})`;

  return FilterProps;
};

export default filterPropsHOC;
