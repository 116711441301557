import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Segment, Icon } from 'semantic-ui-react';

import { SimpleGateway, frontendPermissions } from 'gateway';
import Collapse from 'components/Collapse/Collapse';

import NotificationGroup from './NotificationGroup/NotificationGroup';
import UsersModal from './UsersModal/UsersModal';
import { StyledHeader, StyledLabel } from './Notifications.style';

const Notifications = ({
  groupedNotificationTypes,
  config,
  userId,
  notificationsEnabled,
  showWarning,
  userName,
  onSelectedUserChange,
  onFetchNotificationTypes,
  onFetchResourcesWithNotification,
}) => {
  useEffect(() => {
    onFetchNotificationTypes();
  }, [onFetchNotificationTypes]);

  useEffect(() => {
    onFetchResourcesWithNotification(config);
  }, [config, onFetchResourcesWithNotification]);

  const handleUserSelected = useCallback(id => {
    onSelectedUserChange(id);
  }, [onSelectedUserChange]);

  const renderWarning = () => {
    if (!showWarning) {
      return null;
    }

    return (
      <div>
        <Icon name="warning circle" color="red" />&nbsp;
        All changes will apply to the following user:&nbsp;
        <StyledLabel>
          {userName}
        </StyledLabel>
      </div>
    );
  };

  return (
    <Segment padded>
      <StyledHeader as="h3">
        Notifications
        <SimpleGateway condition={frontendPermissions.OWNER}>
          {notificationsEnabled && <UsersModal onUserSelected={handleUserSelected} />}
        </SimpleGateway>
      </StyledHeader>
      {renderWarning()}
      {Object.keys(groupedNotificationTypes || {}).map(type => (
        <Collapse title={type} key={type}>
          <NotificationGroup
            notificationTypes={groupedNotificationTypes[type]}
            config={config}
            userId={userId}
            notificationsEnabled={notificationsEnabled}
          />
        </Collapse>
      ))}
    </Segment>
  );
};

Notifications.defaultProps = {
  groupedNotificationTypes: {},
  notificationsEnabled: false,
  showWarning: false,
  userName: null,
};

Notifications.propTypes = {
  groupedNotificationTypes: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    notificationContext: PropTypes.string.isRequired,
  }))),
  config: PropTypes.shape({
    context: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectors: PropTypes.objectOf(PropTypes.func).isRequired,
    filter: PropTypes.objectOf(PropTypes.string).isRequired,
    fetchResources: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.symbol.isRequired,
      payload: PropTypes.object,
    })).isRequired,
    fetchResourcesSuccess: PropTypes.arrayOf(PropTypes.func).isRequired,
  }).isRequired,
  userId: PropTypes.string.isRequired,
  userName: PropTypes.string,
  notificationsEnabled: PropTypes.bool,
  showWarning: PropTypes.bool,
  onSelectedUserChange: PropTypes.func.isRequired,
  onFetchNotificationTypes: PropTypes.func.isRequired,
  onFetchResourcesWithNotification: PropTypes.func.isRequired,
};

export default Notifications;
