import { call, put, all, take } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';
import startCase from 'lodash/startCase';

import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import { postPath, getPath } from 'data/api/requests';
import ScheduleModel from 'models/ScheduleModel';

import automatedOperationsActionCreators from '../../../../utilities/actionCreators';
import { extractScheduleIds, generateLogicalExpression, updateOccurrencesWithDelay } from '../../../../utilities/saga/utilities';
import taskScheduleActionCreators from '../actionCreators';

function* saveSchedules({
  automatedOperation,
  schedulerType,
  recurrenceParams,
  schedulerName,
  schedulerDescription,
}) {
  const automatedFunctionEndpoint = endpoints.AUTOMATED_FUNCTIONS(automatedOperation.id);
  const {
    response: automatedFunctionResponse,
    error: automatedFunctionError,
  } = yield call(getPath, automatedFunctionEndpoint);

  if (automatedFunctionResponse) {
    const {
      data: {
        id: automatedFunctionId,
        attributes: {
          logicalExpression,
          lockVersion,
        },
      },
    } = automatedFunctionResponse;
    const localScheduleIds = [];
    const serverSchedulesIds = yield extractScheduleIds(logicalExpression);

    if (isEqual(localScheduleIds.sort(), serverSchedulesIds.sort())) {
      const formattedSchedulerType = startCase(schedulerType.toLowerCase());

      const defaultSchedulerName = `${formattedSchedulerType} schedule (${automatedOperation.name})`;

      const data = new ScheduleModel({
        automatedOperationId: automatedOperation.id,
        name: schedulerName || defaultSchedulerName,
        description: schedulerDescription || defaultSchedulerName,
        type: schedulerType.toLowerCase(),
        params: {
          ...recurrenceParams,
        },
      });

      const body = ScheduleModel.requestBody(data);

      const { response, error } = yield call(postPath, endpoints.SCHEDULES, body);

      if (error) {
        yield put(taskScheduleActionCreators.addTaskSchedulerFailure({
          type: 'SAVE_SCHEDULES',
          error,
        }));
      }

      if (response) {
        const { data: { id } } = response;
        const scheduleIdsFinal = [...localScheduleIds, id];
        const automatedFunctionString = generateLogicalExpression(scheduleIdsFinal);

        const updateAFParams = {
          id: automatedFunctionId,
          logicalExpression: automatedFunctionString,
          automatedOperationId: automatedOperation.id,
          lockVersion,
          next: 0,
        };

        yield put(automatedOperationsActionCreators.updateAutomatedFunction(updateAFParams));
        yield put(automatedOperationsActionCreators.fetchSchedules(automatedOperation.id));

        yield all([
          take(constants.UPDATE_AUTOMATED_FUNCTION_SUCCESS),
          take(constants.FETCH_SCHEDULES_SUCCESS),
        ]);

        yield call(updateOccurrencesWithDelay, automatedOperation.id);
      }
    }
  }

  if (automatedFunctionError) {
    yield put(
      automatedOperationsActionCreators.schedulesAFNotification(messages.ERROR_AF_NOT_RESPONDING),
    );
  }
}

export default saveSchedules;
