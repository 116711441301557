import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/atomic';
import ConfirmModal from 'components/elements/ConfirmModal/ConfirmModal';
import { StyledPermissionsGridItem } from '../PermissionsGridItem.style';

const PermissionsGridItemModal = ({
  email,
  onToggleUserPermission,
  path,
  isPartial,
  canCopy,
}) => {
  const trigger = (
    <StyledPermissionsGridItem
      isOn
      isPartial={isPartial}
      canCopy={canCopy}
    />
  );

  return (
    <ConfirmModal
      trigger={trigger}
      cancelButton="Cancel"
      confirmButton={<Button negative primary={false}>Revoke permission</Button>}
      content={(
        <div>
          <p>
            Revoking your own <strong>{path}</strong> permission requires
            your email (<strong>{email}</strong>) to be entered for confirmation.

            Removing the <strong>{path}</strong> permission will affect your ability
            to perform other actions in the application.
          </p>
          <p>
            Enter your email to confirm:
          </p>
        </div>
      )}
      header="Confirm revoke permission"
      onConfirm={onToggleUserPermission}
      validationString={email}
      validationMessage="Enter your email to confirm permission revocation"
    />
  );
};

PermissionsGridItemModal.propTypes = {
  email: PropTypes.string.isRequired,
  onToggleUserPermission: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  isPartial: PropTypes.bool.isRequired,
  canCopy: PropTypes.bool.isRequired,
};

export default PermissionsGridItemModal;
