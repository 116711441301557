import React from 'react';

import { StyledSpan } from './Contributors.style';

const Contributors = () => (
  <StyledSpan>
    <span>©</span>
    <a
      href="https://www.openstreetmap.org/copyright"
      rel="noopener noreferrer"
      target="_blank"
    >
      OpenStreetMap
    </a>
    <span> contributors</span>
  </StyledSpan>
);

export default Contributors;
