import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import {
  SCHEDULES_FIELD,
} from 'helpers/selectors';

export const addSchedule = params => ({
  type: constants.ADD_SCHEDULE_REQUEST,
  payload: {
    params,
    endpoint: endpoints.SCHEDULES,
  },
});

export const addScheduleSuccess = response => ({
  type: constants.ADD_SCHEDULE_SUCCESS,
  payload: {
    response,
    message: messages.SCHEDULE_ADDED(response.data.attributes.name),
    resourceType: SCHEDULES_FIELD,
    id: response.data.id,
  },
});

export const addScheduleFailure = error => ({
  type: constants.ADD_SCHEDULE_FAILED,
  payload: { error },
});
