import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import LogsSection from './LogsSection';
import { selectors } from '../utilities';
import { prepareDataForJSONPreview } from './utilities';

export const mapStateToProps = (state, { match, data }) => {
  const { id: projectId } = match.params;
  const isLoading = selectors.logsAreLoadingSelector(state);
  const logsDataExists = selectors.logsDataExistsSelector(state, projectId);
  const dataForJSON = prepareDataForJSONPreview(data);

  return {
    isLoading,
    logsDataExists,
    logsData: data,
    dataForJSON,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
)(LogsSection);
