import replace from 'lodash/replace';
import startsWith from 'lodash/startsWith';

import {
  pageParam,
  pageSizeParam,
  SORT_ASCENDING,
  SORT_DESCENDING,
  FILTER_FIELD,
  sortParam,
  filterParam,
} from 'helpers/paginationHelpers';

export const DESCENDING_PREFIX = '-';

class UrlParams {

  constructor({ links, pagination } = {}) {
    const search = links
      ? new URL(links.first).search
      : window.location.search;

    this.links = links || {};
    this.pagination = pagination;
    this.searchParams = new URLSearchParams(search);
    this.pageParam = pageParam(pagination);
    this.pageSizeParam = pageSizeParam(pagination);
    this.sortParam = sortParam(pagination);
    this.filterParam = filterParam(pagination);
  }

  extractUrlParams(params, link) {
    let { searchParams } = this;

    if (link) {
      const url = new URL(link);
      searchParams = new URLSearchParams(url.search);
    }

    return Object.keys(params).reduce((acc, key) => {
      const param = params[key];

      acc[key] = searchParams.get(param);

      return acc;
    }, {});
  }

  get page() {
    let number = 1;

    const { first, ...restLinks } = this.links;
    const { size } = this.extractUrlParams(
      { size: pageParam({ type: 'size', included: true }) },
      first,
    );

    const { prev, next, last: total } = Object.keys(restLinks).reduce((acc, key) => {
      const { [key]: value } = this.extractUrlParams(
        { [key]: pageParam({ type: 'number', included: true }) },
        restLinks[key],
      );

      acc[key] = value;

      return acc;
    }, {});

    if (next) {
      number = Number(next) - 1;
    } else if (prev) {
      number = Number(prev) + 1;
    }

    return {
      number: Number(number),
      size: Number(size),
      total: Number(total),
    };
  }

  set page(activePage) {
    if (!activePage || activePage === 1) {
      this.searchParams.delete(this.pageParam);
    } else {
      this.searchParams.set(this.pageParam, activePage);
    }
  }

  set size(value) {
    if (!value || value === 10) {
      this.searchParams.delete(this.pageSizeParam);
    } else {
      this.searchParams.set(this.pageSizeParam, value);
    }
  }

  get sort() {
    const { sort } = this.extractUrlParams({
      sort: this.sortParam,
    });

    if (!sort) {
      return null;
    }

    const field = replace(sort, DESCENDING_PREFIX, '');
    if (startsWith(sort, DESCENDING_PREFIX)) {
      return {
        direction: SORT_DESCENDING,
        field,
        prefix: DESCENDING_PREFIX,
      };
    }

    return {
      direction: SORT_ASCENDING,
      field,
      prefix: '',
    };
  }

  set sort(fieldName) {
    if (!fieldName) {
      this.searchParams.delete(this.sortParam);
    } else {
      this.searchParams.set(this.sortParam, fieldName);
    }
  }

  get search() {
    const searchString = this.searchParams.toString();
    const querySign = searchString ? '?' : '';

    return `${querySign}${searchString}`;
  }

  get filter() {
    const { first } = this.links;
    const { args } = this.pagination || {};

    if (first && this.searchParams) {
      const params = {};

      this.searchParams.forEach((value, key) => {
        params[key] = value;
      });

      const filterParamKey = Object.keys(params).find(key => key.includes(FILTER_FIELD));

      if (!filterParamKey) {
        return null;
      }

      const query = params[filterParamKey];
      const field = filterParamKey.replace(FILTER_FIELD, '').replace('[', '').replace(']', '');

      return {
        [field]: query,
      };
    }

    if (!args) {
      return null;
    }

    const { filterField } = args;

    if (!filterField) {
      return null;
    }

    const { filter } = this.extractUrlParams({
      filter: filterParam({
        type: filterField,
        included: true,
      }),
    }, first);

    return {
      [filterField]: filter,
    };
  }

  set filter(query) {
    if (!query) {
      this.searchParams.delete(this.filterParam);
    } else {
      this.searchParams.set(this.filterParam, query);
    }
  }

}

export default UrlParams;
