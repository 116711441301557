import styled from 'styled-components';

export const StyledControlPanelContainer = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;

  .ui.container .ui.breadcrumb {
    display: none;
  }
`;

export default StyledControlPanelContainer;
