export const pageSizeOptions = [
  {
    key: 50,
    text: 50,
    value: 50,
  },
  {
    key: 100,
    text: 100,
    value: 100,
  },
  {
    key: 200,
    text: 200,
    value: 200,
  },
  {
    key: 300,
    text: 300,
    value: 300,
  },
  {
    key: 500,
    text: 500,
    value: 500,
  },
  {
    key: 1000,
    text: 1000,
    value: 1000,
  },
];

export default pageSizeOptions;
