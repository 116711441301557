import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { PROJECTS_FIELD } from 'helpers/selectors';

export const fetchProjectSuiteProjects = (projectSuiteId, urlParams = {}) => ({
  type: constants.FETCH_PROJECT_SUITE_PROJECTS_REQUEST,
  payload: {
    projectSuiteId,
    endpoint: endpoints.PROJECT_SUITE_PROJECTS(projectSuiteId),
    urlParams,
  },
});

export const fetchProjectSuiteProjectsSuccess = response => ({
  type: constants.FETCH_PROJECT_SUITE_PROJECTS_SUCCESS,
  payload: {
    response,
    resourceType: PROJECTS_FIELD,
  },
});

export const fetchProjectSuiteProjectsFailure = error => ({
  type: constants.FETCH_PROJECT_SUITE_PROJECTS_FAILED,
  payload: { error },
});

export default fetchProjectSuiteProjects;
