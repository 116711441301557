import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import pluralize from 'pluralize';

import { SCHEDULER_TYPES } from '../../../../../../../constants';
import { StyledLabel, StyledInput, StyledRow, ErrorMessage } from './AfterNOcurrences.style';

function AfterNOcurrences({
  onChange,
  existingSchedule,
  recurrenceErrors,
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [maxOcurrences, setMaxOcurrences] = useState(1);

  useEffect(() => {
    if (existingSchedule && !isLoaded) {
      const { type, params } = existingSchedule || {};

      if (type.toUpperCase() !== SCHEDULER_TYPES.DATES) {
        const { forNTimes } = params;

        if (forNTimes) {
          setMaxOcurrences(forNTimes);
          setIsLoaded(true);
        }
      }
    }

  }, [existingSchedule, isLoaded]);

  useEffect(() => {
    onChange({
      forNTimes: Number(maxOcurrences),
    });
  }, [onChange, maxOcurrences]);

  const ocurrenceDescription = useMemo(() => pluralize('occurrence', Number(maxOcurrences)), [maxOcurrences]);

  return (
    <>
      <StyledLabel>
        End after
      </StyledLabel>
      <StyledRow noPaddingBottom>
        <Grid.Column computer={6} mobile={8}>
          <StyledInput
            name="max_ocurrences"
            type="number"
            size="mini"
            min="1"
            max="400"
            value={maxOcurrences}
            onChange={e => setMaxOcurrences(e.target.value)}
            label={{
              basic: true,
              content: ocurrenceDescription,
            }}
            labelPosition="right"
            required
          />
        </Grid.Column>
      </StyledRow>
      <ErrorMessage>{recurrenceErrors.forNTimes}</ErrorMessage>
    </>
  );
}

AfterNOcurrences.defaultProps = {
  recurrenceErrors: {},
  existingSchedule: null,
};

AfterNOcurrences.propTypes = {
  recurrenceErrors: PropTypes.object,
  existingSchedule: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default AfterNOcurrences;
