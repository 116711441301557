import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import LocalLogsFilterParams from './LocalLogsFilterParams';
import { selectors } from '../utilities';
import { dropdownSelects } from './constants';

export const mapStateToProps = (state, { match, options }) => {
  const { id: projectId } = match.params;
  const isLoading = selectors.logsAreLoadingSelector(state);
  const logsDataExists = selectors.logsDataExistsSelector(state, projectId);
  const { actionTypes, initiators, recipients, resources } = options;
  const availableOptions = [
    { text: dropdownSelects.ACTION_TYPES, values: actionTypes, filterName: 'actionType' },
    { text: dropdownSelects.INITIATORS, values: initiators, filterName: 'initiator' },
    { text: dropdownSelects.RECIPIENTS, values: recipients, filterName: 'recipient' },
    { text: dropdownSelects.RESOURCES, values: resources, filterName: 'resource' },
  ];

  return {
    isLoading,
    logsDataExists,
    availableOptions,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
)(LocalLogsFilterParams);
