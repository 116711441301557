import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { currentCompanyNotificationsEnabledSelector } from 'helpers/selectors';

import Profile from './Profile';

export const mapStateToProps = state => ({
  notificationsEnabled: currentCompanyNotificationsEnabledSelector(state),
});

export default compose(
  withRouter,
  connect(mapStateToProps, null),
)(Profile);
