import { isEmpty } from 'lodash';
import getPermissionCheckboxState from './getPermissionCheckboxState';

const addRecipientsInfo = ({
  cumulatedPermissions,
  rows,
  recipientIds,
}) => {

  if (isEmpty(cumulatedPermissions) || isEmpty(recipientIds)) {
    return rows;
  }

  const updatedRows = rows.reduce((acc, curr) => {
    const recipients = [];

    recipientIds.forEach(recipientId => {

      recipients.push({
        id: recipientId,
        checkboxState: getPermissionCheckboxState({
          cumulatedPermissions,
          recipientId,
          permissionNames: curr.relatedPermissions,
        }),
      });
    });

    acc.push({
      ...curr,
      recipients,
    });

    return acc;
  }, []);

  return updatedRows;
};

export default addRecipientsInfo;
