import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import { FILE_UPLOADS_FIELD } from 'helpers/selectors';

const filesSectionActionCreators = {
  removeFile: (deviceId, params) => ({
    type: constants.REMOVE_FILE_REQUEST,
    payload: {
      params,
      endpoint: endpoints.DELETE_FILES(deviceId),
    },
  }),

  removeFileSuccess: response => ({
    type: constants.REMOVE_FILE_REQUEST_SUCCESS,
    payload: {
      response,
      resourceType: FILE_UPLOADS_FIELD,
      message: messages.FILE_REMOVED,
    },
  }),

  removeFileFailure: error => ({
    type: constants.REMOVE_FILE_REQUEST_FAILED,
    payload: { error },
  }),

  triggerFileTransfer: (deviceId, params) => ({
    type: constants.TRIGGER_FILE_TRANSFER_REQUEST,
    payload: {
      params,
      endpoint: endpoints.TRIGGER_FILE_TRANSFER(deviceId),
    },
  }),

  triggerFileTransferSuccess: response => ({
    type: constants.TRIGGER_FILE_TRANSFER_REQUEST_SUCCESS,
    payload: {
      response,
      message: messages.TRANSFER_TRIGGERED,
    },
  }),

  triggerFileTransferFailure: error => ({
    type: constants.TRIGGER_FILE_TRANSFER_REQUEST_FAILED,
    payload: { error },
  }),

  downloadFile: (deviceId, params) => ({
    type: constants.DOWNLOAD_FILE_REQUEST,
    payload: {
      params,
      endpoint: endpoints.DOWNLOAD_FILE(deviceId),
    },
  }),

  downloadFileSuccess: response => ({
    type: constants.DOWNLOAD_FILE_REQUEST_SUCCESS,
    payload: {
      response,
      message: messages.DOWNLOAD_STARTED,
    },
  }),

  downloadFileFailure: error => ({
    type: constants.DOWNLOAD_FILE_REQUEST_FAILED,
    payload: { error },
  }),
};

export default filesSectionActionCreators;
