import {
  fetchControlUI,
  fetchControlUISuccess,
  fetchControlUIFailure,
} from './fetchControlUI';
import {
  loadPageBackgroundImage,
  loadPageBackgroundImageSuccess,
} from './loadPageBackgroundImage';
import {
  updateControlUI,
  updateControlUISuccess,
  updateControlUIFailure,
} from './updateControlUI';
import addPage from './addPage';
import renamePage from './renamePage';
import setPageBackgroundImage from './setPageBackgroundImage';
import removePageBackgroundImage from './removePageBackgroundImage';
import setPageNameColor from './setPageNameColor';
import deletePage from './deletePage';
import updateButton from './updateButton';
import duplicatePage from './duplicatePage';
import removeBackgroundImageUpload from './removeBackgroundImageUpload';

import {
  fetchUsers,
  fetchUsersSuccess,
  fetchUsersFailure,
} from './fetchUsers';
import setPageAllowedUserIds from './setPageAllowedUserIds';
import updateOverrideControls from './updateOverrideControls';

export default {
  fetchControlUI,
  fetchControlUISuccess,
  fetchControlUIFailure,
  loadPageBackgroundImage,
  loadPageBackgroundImageSuccess,
  updateControlUI,
  updateControlUISuccess,
  updateControlUIFailure,
  addPage,
  renamePage,
  setPageBackgroundImage,
  removePageBackgroundImage,
  setPageNameColor,
  deletePage,
  updateButton,
  duplicatePage,
  removeBackgroundImageUpload,
  fetchUsers,
  fetchUsersSuccess,
  fetchUsersFailure,
  setPageAllowedUserIds,
  updateOverrideControls,
};
