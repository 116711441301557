import { compose } from 'redux';
import { connect } from 'react-redux';

import { tasksActionCreators } from '../../../../../../../../../../../../../../../../utilities';
import withDeleteAction from './withDeleteAction';

const mapDispatchToProps = {
  onDeleteAction: tasksActionCreators.removeAction,
};

export default compose(
  connect(null, mapDispatchToProps),
  withDeleteAction,
);
