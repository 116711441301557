import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import pluralize from 'pluralize';

import { SelectedDatesView } from 'components/core/DatePicker/components';
import { SelectedTimesView } from 'components/core/TimePicker/components';
import { StyledLabel } from '../../ScheduleReview.style';

function SpecificDatesReview({
  recurrenceParams,
}) {
  const {
    specificDates = [],
    specificTimes = [],
  } = recurrenceParams || {};

  return (
    <Form>
      <Form.Group widths="equal">
        <Form.Field>
          <StyledLabel>
            {pluralize('Date', specificDates.length)}
          </StyledLabel>
          <SelectedDatesView
            selectedDates={specificDates}
            renderLabel={false}
            clearable={false}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <StyledLabel>
            {pluralize('Time', specificTimes.length)}
          </StyledLabel>
          <SelectedTimesView
            selectedTimes={specificTimes}
            renderLabel={false}
            clearable={false}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
}

SpecificDatesReview.propTypes = {
  recurrenceParams: PropTypes.object.isRequired,
};

export default SpecificDatesReview;
