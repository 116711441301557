import {
  OCCURRENCE_TYPE,
  OCCURRENCE_TYPE_NUMBER,
  OCCURRENCE_DAY,
  OCCURRENCE_DAY_NUMBER,
} from '../../../../constants';

const getMonthlyCondition = (occurrenceType, occurrenceDay) => {
  let condition = {};

  if (occurrenceDay === OCCURRENCE_DAY.DAY) {
    if (occurrenceType === OCCURRENCE_TYPE.LAST) {
      condition = {
        daysFromMonthEnd: 1,
      };
    } else {
      condition = {
        daysFromMonthStart: OCCURRENCE_TYPE_NUMBER[occurrenceType],
      };
    }
  } else {
    condition = {
      on: {
        weekday: OCCURRENCE_DAY_NUMBER[occurrenceDay],
        nthOccurrence: OCCURRENCE_TYPE_NUMBER[occurrenceType],
      },
    };
  }

  return condition;
};

export default getMonthlyCondition;
