import { takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import fetchTwoFactorQRCode from './fetchTwoFactorQRCode';

function* saga() {
  yield takeLatest(constants.FETCH_TWO_FACTOR_QR_CODE, fetchTwoFactorQRCode);
}

export default saga;
