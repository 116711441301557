import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';

const updateSettingsActionCreators = {
  toggleBeacon: (deviceId, params) => ({
    type: constants.TOGGLE_BEACON_REQUEST,
    payload: {
      endpoint: endpoints.UPDATE_SETTING(deviceId),
      params,
    },
  }),

  toggleBeaconSuccess: response => ({
    type: constants.TOGGLE_BEACON_SUCCESS,
    payload: {
      response,
      info: messages.WAITING_FOR_CONFIRMATION,
    },
  }),

  toggleBeaconFailure: error => ({
    type: constants.TOGGLE_BEACON_FAILED,
    payload: { error },
  }),

  toggleWatchdog: (deviceId, params) => ({
    type: constants.TOGGLE_WATCHDOG_REQUEST,
    payload: {
      deviceId,
      endpoint: endpoints.UPDATE_SETTING(deviceId),
      params,
    },
  }),

  toggleWatchdogSuccess: response => ({
    type: constants.TOGGLE_WATCHDOG_SUCCESS,
    payload: { response },
  }),

  toggleWatchdogFailure: error => ({
    type: constants.TOGGLE_WATCHDOG_FAILED,
    payload: { error },
  }),

  toggleDisable: (deviceId, params) => ({
    type: constants.TOGGLE_DISABLE_REQUEST,
    payload: {
      deviceId,
      endpoint: endpoints.UPDATE_SETTING(deviceId),
      params,
    },
  }),

  toggleDisableSuccess: response => ({
    type: constants.TOGGLE_DISABLE_SUCCESS,
    payload: { response },
  }),

  toggleDisableFailure: error => ({
    type: constants.TOGGLE_DISABLE_FAILED,
    payload: { error },
  }),

  setTimestamp: (deviceId, params) => ({
    type: constants.SET_TIMESTAMP_REQUEST,
    payload: {
      deviceId,
      endpoint: endpoints.UPDATE_SETTING(deviceId),
      params,
    },
  }),

  setTimestampSuccess: response => ({
    type: constants.SET_TIMESTAMP_SUCCESS,
    payload: { response },
  }),

  setTimestampFailure: error => ({
    type: constants.SET_TIMESTAMP_FAILED,
    payload: { error },
  }),

  setLogLevel: (deviceId, params) => ({
    type: constants.SET_LOG_LEVEL_REQUEST,
    payload: {
      endpoint: endpoints.UPDATE_SETTING(deviceId),
      params,
    },
  }),

  setLogLevelSuccess: response => ({
    type: constants.SET_LOG_LEVEL_SUCCESS,
    payload: { response },
  }),

  setLogLevelFailure: error => ({
    type: constants.SET_LOG_LEVEL_FAILED,
    payload: { error },
  }),

  updateSetting: (deviceId, params) => ({
    type: constants.UPDATE_DEVICE_SETTING_REQUEST,
    payload: {
      endpoint: endpoints.UPDATE_SETTING(deviceId),
      deviceId,
      resolveForm: params.resolveForm,
      rejectForm: params.rejectForm,
      attributes: {
        setting: params.setting,
        meta: {
          interfaceId: params.interfaceId ? params.interfaceId : undefined,
          portId: params.portId ? params.portId : undefined,
          value: params.value,
        },
      },
    },
  }),

  updateSettingSuccess: response => ({
    type: constants.UPDATE_DEVICE_SETTING_SUCCESS,
    payload: { response },
  }),

  updateSettingFailed: error => ({
    type: constants.UPDATE_DEVICE_SETTING_FAILED,
    payload: { error },
  }),
};

export default updateSettingsActionCreators;
