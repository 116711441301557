import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import UrlParams from 'helpers/UrlParams';
import { StyledControlUIWrapper } from './ControlUI.style';
import { ControlUIPageCarousel, ControlUIPageFooter, ProjectSuiteContext } from './components';
import { getPaginationIndex } from './utilities';
import ControlUIPageNavigation from './components/ControlUIPageNavigation/ControlUIPageNavigationContainer';

const ControlUI = ({
  projectId,
  pageIds,
  configure,
  isMultiSite,
  location,
  history,
  onAddPage,
  onDeletePage,
}) => {
  const [page, setPage] = useState(0);
  const [adding, setAdding] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isLoaded) {
      return;
    }

    if (isEmpty(pageIds)) {
      return;
    }

    const urlParams = new UrlParams();
    const { number } = urlParams.extractUrlParams({
      number: urlParams.pageParam,
    });

    if (!number) {
      const [firstPageId] = pageIds;

      urlParams.page = firstPageId + 1;
      const { search } = urlParams;
      history.push({ ...location, search });

      setPage(firstPageId);
      setIsLoaded(true);
    } else {
      const correctPageId = Number(number || 1) - 1;

      urlParams.page = correctPageId + 1;
      const { search } = urlParams;
      history.push({ ...location, search });

      setPage(correctPageId);
      setIsLoaded(true);
    }
  }, [
    pageIds,
    location,
    history,
    isLoaded,
  ]);

  const handlePageChange = useCallback((event, { activePage }) => {
    setPage(activePage - 1);

    const urlParams = new UrlParams();
    urlParams.page = activePage;
    const { search } = urlParams;

    history.push({ ...location, search });
  }, [history, location]);

  const handleAddPage = useCallback(async ({ name, paginationIndex, pageType }) => {
    setAdding(true);

    return new Promise((resolveForm, rejectForm) => {
      onAddPage({
        name,
        projectId,
        paginationIndex,
        pageType,
        resolveForm,
        rejectForm,
      });
    }).then(() => {
      const calculatedPaginationIndex = getPaginationIndex(pageIds.length, paginationIndex);
      setAdding(false);
      handlePageChange(null, { activePage: 1 });
      handlePageChange(null, { activePage: calculatedPaginationIndex });
    });
  }, [onAddPage, projectId, pageIds, handlePageChange]);

  const handleDeletePage = useCallback(async () => {
    const pageId = pageIds[page];

    setDeleting(true);

    return new Promise((resolveForm, rejectForm) => {
      onDeletePage({
        pageId,
        projectId,
        resolveForm,
        rejectForm,
      });
    }).then(() => {
      setDeleting(false);

      handlePageChange(null, { activePage: 1 });
      handlePageChange(null, { activePage: page || 1 });
    });
  }, [onDeletePage, projectId, page, pageIds, handlePageChange]);

  return (
    <ProjectSuiteContext.Provider value={isMultiSite}>
      <StyledControlUIWrapper configure={configure}>
        <ControlUIPageCarousel
          pageId={page}
          pageIds={pageIds}
          configure={configure}
          onPageChange={handlePageChange}
          onAddPage={handleAddPage}
          adding={adding}
        />
        <ControlUIPageFooter
          pageId={page}
          pageIds={pageIds}
          configure={configure}
          adding={adding}
          deleting={deleting}
          onPageChange={handlePageChange}
          onAddPage={handleAddPage}
          onDeletePage={handleDeletePage}
        />
        <ControlUIPageNavigation
          pageIds={pageIds}
          onPageChange={handlePageChange}
        />
      </StyledControlUIWrapper>
    </ProjectSuiteContext.Provider>
  );
};

ControlUI.defaultProps = {
  configure: false,
  pageIds: null,
  history: null,
  location: null,
  isMultiSite: false,
};

ControlUI.propTypes = {
  configure: PropTypes.bool,
  projectId: PropTypes.string.isRequired,
  pageIds: PropTypes.array,
  history: PropTypes.object,
  location: PropTypes.object,
  onAddPage: PropTypes.func.isRequired,
  onDeletePage: PropTypes.func.isRequired,
  isMultiSite: PropTypes.bool,
};

export default ControlUI;
