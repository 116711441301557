import { call } from 'redux-saga/effects';

import { putPath } from 'data/api/requests';
import { processFinalFormResponse } from 'utilities/form';
import UserModel from 'models/UserModel';
import { ERROR_SERVER_CONNECTION } from 'data/notifications/notificationsConst';
import actionCreators from '../actionCreators';

function* toggleSuspendUser(action) {
  const { endpoint, id, suspended, resolveForm } = action.payload;
  const body = UserModel.requestBody({ id, suspended });
  const { response, error } = yield call(putPath, endpoint, body);

  if (error instanceof Error) {
    error.message = ERROR_SERVER_CONNECTION;
  }

  const params = {
    response: response && { ...response, suspended },
    error,
    successDisp: actionCreators.toggleSuspendUserSuccess,
    failDisp: actionCreators.toggleSuspendUserFailure,
    resolveForm,
  };
  yield call(processFinalFormResponse, params);
}

export default toggleSuspendUser;
