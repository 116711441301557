import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { FiUsers } from 'react-icons/fi';
import { Grid, Modal, Icon, Segment, Header, List, Message } from 'semantic-ui-react';

import { frontendPermissions, PermissionsSwitch, Gateway } from 'gateway';
import { USERS_FIELD } from 'helpers/selectors';
import { Button } from 'components/atomic';

import { UsersSelect } from './components';
import {
  StyledButton,
  StyledList,
  StyledListContent,
  StyledSubheader,
  StyledModalContent,
  StyledModalActions,
  StyledHelpTextList,
} from './RestrictAccess.style';

const RestrictAccess = ({
  users,
  pageId,
  resourceId,
  resourceType,
  isMultiSite,
  allowedUserIds,
  onSetAllowedUserIds,
  onFetchUsers,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedUsersIds, setSelectedUsersIds] = useState(() => {
    if (allowedUserIds && !isEmpty(allowedUserIds)) {
      return allowedUserIds;
    }

    return [];
  });

  const resourceTypeName = isMultiSite ? 'Multi-site' : 'Site';

  const HELP_TEXT = {
    LINE_1: `If this list is empty, all ${resourceTypeName} users will be able to access the page.`,
    LINE_2: 'Note that users with permission to edit control panel pages will always be able to view all pages.',
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = useCallback(() => {
    const urlParams = {
      fields: {
        [USERS_FIELD]: [
          'id',
          'firstName',
          'lastName',
          'email',
        ],
      },
    };

    onFetchUsers(resourceId, resourceType, urlParams);

    if (allowedUserIds && !isEmpty(allowedUserIds)) {
      setSelectedUsersIds(allowedUserIds);
    } else {
      setSelectedUsersIds([]);
    }

    setIsOpen(true);
  }, [resourceId, resourceType, allowedUserIds, onFetchUsers]);

  const renderButton = useCallback(passesPermission => (
    <StyledButton
      icon
      fluid
      labelPosition="left"
      disabled={!passesPermission}
      onClick={handleOpen}
    >
      <Icon name="users" />
      Limit Access
    </StyledButton>
  ), [handleOpen]);

  const renderTrigger = useCallback(() => {
    const permission = isMultiSite
      ? frontendPermissions.PROJECT_SUITE_OWNER
      : frontendPermissions.OWNER;

    return (
      <PermissionsSwitch>
        <Gateway condition={permission}>
          {renderButton(true)}
        </Gateway>
        <Gateway fallback>
          {renderButton(false)}
        </Gateway>
      </PermissionsSwitch>
    );
  }, [isMultiSite, renderButton]);

  const handleSubmit = useCallback(
    () => new Promise((resolveForm, rejectForm) => {
      setIsLoading(true);

      onSetAllowedUserIds({
        pageId,
        resourceId,
        allowedUserIds: isEmpty(selectedUsersIds) ? null : selectedUsersIds,
        resolveForm: () => {
          setIsOpen(false);
          setIsLoading(false);
          resolveForm();
        },
        rejectForm: () => {
          setIsOpen(false);
          setIsLoading(false);
          rejectForm();
        },
      });
    }),
    [pageId, resourceId, selectedUsersIds, onSetAllowedUserIds],
  );


  const handleRemove = useCallback(id => {
    setSelectedUsersIds(selectedUsersIds.filter(selectedUserId => selectedUserId !== id));
  }, [selectedUsersIds]);

  const handleAddUser = useCallback(userId => {
    if (!selectedUsersIds.find(selectedUserId => selectedUserId === userId)) {
      setSelectedUsersIds([...selectedUsersIds, userId]);
    }
  }, [selectedUsersIds]);

  const renderEmptyState = useCallback(() => {
    if (!isEmpty(selectedUsersIds)) {
      return null;
    }

    return (
      <Segment name="empty-state" placeholder>
        <Header icon>
          <FiUsers size="2em" /> <br />
          This page is visible to all users
        </Header>
      </Segment>
    );
  }, [selectedUsersIds]);

  const renderUsersList = useCallback(() => {
    if (isEmpty(selectedUsersIds)) {
      return null;
    }

    const orderedUsers = sortBy(users.filter(user => selectedUsersIds.includes(user.id)), ['firstName']);

    return (
      <StyledList divided celled relaxed>
        {orderedUsers.map(user => {
          const { id, firstName, lastName, email } = user;

          return (
            <List.Item key={id}>
              <StyledListContent verticalAlign="middle">
                <div>
                  <List.Header>{`${firstName} ${lastName}`}</List.Header>
                  <List.Description>{email}</List.Description>
                </div>
                <div>
                  <Button onClick={() => handleRemove(user.id)}>
                    <Icon name="trash" style={{ margin: 0, padding: 0 }} />
                  </Button>
                </div>
              </StyledListContent>
            </List.Item>
          );
        })}
      </StyledList>
    );
  }, [selectedUsersIds, users, handleRemove]);

  return (
    <Modal open={isOpen} trigger={renderTrigger()} onClose={handleClose}>
      <Modal.Header>
        <Header as="h3">
          Limit Access
          <StyledSubheader>
            Add users below to limit access to specified users.
          </StyledSubheader>
        </Header>
      </Modal.Header>
      <StyledModalContent>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16}>
              <Message info>
                <StyledHelpTextList>
                  <li>
                    {HELP_TEXT.LINE_1}
                  </li>
                  <li>
                    {HELP_TEXT.LINE_2}
                  </li>
                </StyledHelpTextList>
              </Message>
              <UsersSelect
                users={users}
                selectedUsersIds={selectedUsersIds}
                onChange={user => handleAddUser(user)}
              />
              {renderEmptyState()}
              {renderUsersList()}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </StyledModalContent>
      <StyledModalActions>
        <Button
          basic
          primary
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          name="submit"
          primary
          loading={isLoading}
          disabled={isLoading}
          onClick={handleSubmit}
        >
          Save Changes
        </Button>
      </StyledModalActions>
    </Modal>
  );
};

RestrictAccess.defaultProps = {
  users: [],
  allowedUserIds: null,
  isMultiSite: false,
};

RestrictAccess.propTypes = {
  users: PropTypes.array,
  allowedUserIds: PropTypes.array,
  isMultiSite: PropTypes.bool,
  pageId: PropTypes.number.isRequired,
  resourceId: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
  onSetAllowedUserIds: PropTypes.func.isRequired,
  onFetchUsers: PropTypes.func.isRequired,
};

export default RestrictAccess;
