import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const loadPageBackgroundImage = ({ ...args }) => ({
  type: constants.LOAD_BACKGROUND_IMAGE_CONTROL_UI_PAGE,
  payload: {
    endpoint: endpoints.FILE_UPLOADS_SIGN,
    ...args,
  },
});

export const loadPageBackgroundImageSuccess = ({ ...args }) => ({
  type: constants.LOAD_BACKGROUND_IMAGE_CONTROL_UI_PAGE_SUCCESS,
  payload: { ...args },
});
