import constants from 'dispatcherConst';

const initialState = {
  fetchingPatches: false,
  fetchingOutput: false,
  protocolId: null,
  universeId: null,
};

const outputSectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_PATCHES:
      return { ...state, fetchingPatches: true };

    case constants.FETCH_PATCHES_SUCCESS:
    case constants.FETCH_PATCHES_FAILED:
      return { ...state, fetchingPatches: false };

    case constants.FETCH_OUTPUT:
      return { ...state, fetchingOutput: true };

    case constants.FETCH_OUTPUT_SUCCESS: {
      return { ...state, fetchingOutput: false };
    }

    case constants.FETCH_OUTPUT_FAILED:
      return { ...state, fetchingOutput: false };

    case constants.SET_PROTOCOL: {
      const { protocolId } = action.payload;

      return { ...state, protocolId };
    }

    case constants.SET_UNIVERSE: {
      const { universeId } = action.payload;

      return { ...state, universeId };
    }

    default:
      return state;
  }
};

export default outputSectionReducer;
