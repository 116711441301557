import React, { useState, useCallback, createRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, Form, Button, Input, Icon } from 'semantic-ui-react';
import get from 'lodash/get';

import { convertColorString } from 'helpers/sharedMethods';
import { Panel, ColorPicker } from 'components/core';
import { ChildPanelHeader } from 'components/core/Panel/Panel';
import ThemedToggle from 'components/elements/ThemedToggle/ThemedToggle.style';

import { configurationFormFields } from '../../utilities';

import {
  FormContainer,
  StyledLabel,
  ColorPickerContainer,
  PreviewContainer,
  ButtonPreview,
  HeaderContainer,
  ColorPickerFields,
  EmptySlotPreview,
} from './ButtonAppearanceStep.style';

export default function ButtonAppearanceStep({
  initialValues: {
    [configurationFormFields.text]: initialText,
    [configurationFormFields.buttonColor]: initialButtonColor,
    [configurationFormFields.textColor]: initialTextColor,
    [configurationFormFields.enabled]: initialEnabled,
  },
  form,
  onButtonTextChange,
  onButtonColorChange,
  onButtonTextColorChange,
  onButtonEnabledChange,
  onClearCopy,
}) {
  const previewButtonRef = createRef();

  const [background, setBackground] = useState('light');
  const [useCompanyTheme, setUseCompanyTheme] = useState(false);
  const [buttonText, setButtonText] = useState(initialText || '');
  const [buttonColor, setButtonColor] = useState(initialButtonColor);
  const [textColor, setTextcolor] = useState(initialTextColor);
  const [enabled, setEnabled] = useState(initialEnabled);

  const handleButtonTextChange = useCallback(event => {
    setButtonText(event.target.value);
    onButtonTextChange(form, event.target.value);

  }, [form, onButtonTextChange]);

  const handleBackgroundColorChange = useCallback(selectedColor => {
    setButtonColor(selectedColor);
    onButtonColorChange(form, JSON.stringify(selectedColor));
  }, [form, onButtonColorChange]);

  const handleTextColorChange = useCallback(selectedColor => {
    setTextcolor(selectedColor);
    onButtonTextColorChange(form, JSON.stringify(selectedColor));
  }, [form, onButtonTextColorChange]);

  const handleToggleTheme = useCallback(() => {
    const isUsingCompanyTheme = !useCompanyTheme;

    setUseCompanyTheme(isUsingCompanyTheme);

    let newTextColor = null;
    let newButtonColor = null;

    if (isUsingCompanyTheme) {
      const { theme } = previewButtonRef.current.state;

      newTextColor = convertColorString(get(theme, ['buttons', '.ui.button', '&.primary', 'color']));
      newButtonColor = convertColorString(get(theme, 'palette.primaryColor'));
    } else {
      newTextColor = textColor;
      newButtonColor = buttonColor;
    }

    onButtonTextColorChange(form, JSON.stringify(newTextColor));
    onButtonColorChange(form, JSON.stringify(newButtonColor));
  }, [
    useCompanyTheme,
    previewButtonRef,
    form,
    buttonColor,
    textColor,
    onButtonTextColorChange,
    onButtonColorChange,
  ]);

  const handleButtonEnabledChange = useCallback(() => {
    const isEnabled = !enabled;

    setEnabled(isEnabled);
    onButtonEnabledChange(form, isEnabled);
    onClearCopy();
  }, [form, enabled, onButtonEnabledChange, onClearCopy]);

  return (
    <Panel
      title="Configuration"
      description="Set the required configurations."
    >
      <Grid.Row>
        <Grid.Column mobile={16} computer={6}>
          <ChildPanelHeader title="Appearance" description="Set button text, colour & others." />
          <FormContainer>
            <Form>
              <Form.Group widths="equal">
                <Form.Field name="enabled" label="Enabled" control={ThemedToggle} checked={enabled} onChange={handleButtonEnabledChange} />
              </Form.Group>
              {enabled && (
                <>
                  <Form.Group widths="equal">
                    <Form.Field label="Text" name="button-text" control={Input} placeholder="Button text" value={buttonText} onChange={handleButtonTextChange} />
                  </Form.Group>
                  <ColorPickerFields>
                    <ColorPickerContainer>
                      <StyledLabel>Background colour</StyledLabel>
                      <ColorPicker name="background-color" value={buttonColor} onChange={handleBackgroundColorChange} />
                    </ColorPickerContainer>
                    <ColorPickerContainer>
                      <StyledLabel>Text colour</StyledLabel>
                      <ColorPicker name="text-color" value={textColor} onChange={handleTextColorChange} />
                    </ColorPickerContainer>
                  </ColorPickerFields>
                  <Form.Group widths="equal">
                    <Form.Field label="Match portal theme" control={ThemedToggle} checked={useCompanyTheme} onChange={handleToggleTheme} />
                  </Form.Group>
                </>
              )}
            </Form>
          </FormContainer>
        </Grid.Column>
        <Grid.Column mobile={16} computer={10}>
          <HeaderContainer>
            <ChildPanelHeader
              title="Preview"
              description="Button appearance on the Control Panel."
            />
            <Button.Group basic size="mini">
              <Button active={background === 'light'} onClick={() => setBackground('light')}>Light Background</Button>
              <Button active={background === 'dark'} onClick={() => setBackground('dark')}>Dark Background</Button>
            </Button.Group>
          </HeaderContainer>
          <PreviewContainer backgroundType={background}>
            {enabled && (
              <ButtonPreview
                ref={previewButtonRef}
                buttonColor={buttonColor}
                textColor={textColor}
                useCompanyTheme={useCompanyTheme}
              >
                {buttonText}
              </ButtonPreview>
            )}
            {!enabled && (
              <EmptySlotPreview>
                <Icon name="plus" />
              </EmptySlotPreview>
            )}
          </PreviewContainer>
        </Grid.Column>
      </Grid.Row>
    </Panel>
  );
}

ButtonAppearanceStep.propTypes = {
  initialValues: PropTypes.shape({
    [configurationFormFields.buttonColor]: PropTypes.object,
    [configurationFormFields.textColor]: PropTypes.object,
    [configurationFormFields.taskId]: PropTypes.string,
  }).isRequired,
  form: PropTypes.string.isRequired,
  onButtonTextChange: PropTypes.func.isRequired,
  onButtonColorChange: PropTypes.func.isRequired,
  onButtonTextColorChange: PropTypes.func.isRequired,
  onButtonEnabledChange: PropTypes.func.isRequired,
  onClearCopy: PropTypes.func.isRequired,
};
