import { compose } from 'redux';

import extractValue from 'helpers/extractValue/extractValue';
import markLastUpdate from 'helpers/markLastUpdate/markLastUpdate';
import formatValue from 'helpers/formatValue/formatValue';
import { boolNormalizer } from '../normalizers';
import DescriptionItem from '../DescriptionItem/DescriptionItem';

const BoolDescriptionItem = compose(
  extractValue,
  markLastUpdate,
  formatValue(boolNormalizer),
)(DescriptionItem);

export default BoolDescriptionItem;
