import React from 'react';
import PropTypes from 'prop-types';

import PermissionsGridItemModal from './PermissionsGridItemModal/PermissionsGridItemModalContainer';
import { StyledPermissionsGridItem } from './PermissionsGridItem.style';

const PermissionsGridItem = ({
  path,
  userId,
  resourceId,
  isOn,
  isPartial,
  permissionId,
  needsConfirmation,
  isCurrentUser,
  copyUserId,
  onToggleUserPermission,
  onToggleUserHeaderPermission,
  isProject,
}) => {
  const canCopy = copyUserId === userId && isOn;
  const handleToggleUserPermission = () => (
    permissionId
      ? (
        onToggleUserPermission(userId, resourceId, permissionId, isOn)
      )
      : (
        onToggleUserHeaderPermission(userId, resourceId, path, isOn, isPartial, isProject)
      )
  );

  if (isOn && needsConfirmation && isCurrentUser) {
    return (
      <PermissionsGridItemModal
        path={path}
        userId={userId}
        onToggleUserPermission={handleToggleUserPermission}
        isPartial={isPartial}
        canCopy={canCopy}
      />
    );
  }

  return (
    <StyledPermissionsGridItem
      onClick={handleToggleUserPermission}
      isOn={isOn}
      isPartial={isPartial}
      canCopy={canCopy}
    />
  );
};

PermissionsGridItem.defaultProps = {
  isOn: false,
  isPartial: false,
  permissionId: null,
  needsConfirmation: false,
  isCurrentUser: false,
  copyUserId: null,
  isProject: false,
};

PermissionsGridItem.propTypes = {
  path: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  isOn: PropTypes.bool,
  isPartial: PropTypes.bool,
  permissionId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  needsConfirmation: PropTypes.bool,
  isCurrentUser: PropTypes.bool,
  copyUserId: PropTypes.string,
  onToggleUserPermission: PropTypes.func.isRequired,
  onToggleUserHeaderPermission: PropTypes.func.isRequired,
  isProject: PropTypes.bool,
};

export default PermissionsGridItem;
