import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';

import { layoutPanesType } from 'data/types';
import TabbedLayoutPane from '../TabbedLayoutPane/TabbedLayoutPane';

class TabbedLayoutPanes extends Component {

  renderItems() {
    const { panes, match: { path: matchPath }, resourceId } = this.props;

    return panes.map(({ path, fetcher: Fetcher = null, rows }) => (
      <Route path={`${matchPath}${path}`} key={path}>
        <Fragment>
          {Fetcher && <Fetcher />}
          <TabbedLayoutPane resourceId={resourceId} rows={rows} />
        </Fragment>
      </Route>
    ));
  }

  render() {
    const { panes, match: { path: matchPath } } = this.props;
    if (!panes || !panes.length) return null;

    const { path } = panes[0];

    return (
      <Switch>
        {this.renderItems()}
        {path && <Redirect to={`${matchPath}${path}`} />}
      </Switch>
    );
  }

}

TabbedLayoutPanes.defaultProps = {
  panes: [],
  resourceId: null,
};

TabbedLayoutPanes.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  panes: layoutPanesType,
  resourceId: PropTypes.string,
};

export default TabbedLayoutPanes;
