import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  showSyncFirmwareButtonSelector,
} from 'helpers/selectors';
import performActionActionCreators from 'pages/Device/performActionActionCreators';
import SyncFirmware from './SyncFirmware';

export const mapStateToProps = (state, { match }) => {
  const { deviceId } = match.params;
  const showDeviceSyncFirmware = showSyncFirmwareButtonSelector(state, deviceId);

  return {
    deviceId,
    showDeviceSyncFirmware,
  };
};

const mapDispatchToProps = {
  onSyncFirmware: performActionActionCreators.performSyncFirmwareRequest,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(SyncFirmware);
