import isEmpty from 'lodash/isEmpty';

import getResourceSelectors from './getResourceSelectors';
import filterTriggers from './filterTriggers';
import createTriggersObject from './createTriggersObject';

const getDeviceResource = (state, paramKey, logicalDeviceId, value) => {
  if (!getResourceSelectors(paramKey) || isEmpty(getResourceSelectors(paramKey))) {
    return false;
  }

  const { resourceSelector, loadingSelector, listKey } = getResourceSelectors(paramKey);
  const getDeviceResourceList = resourceSelector(state)(logicalDeviceId);
  const fetchingTriggers = loadingSelector(state);
  const finalList = getDeviceResourceList
    ? getDeviceResourceList[listKey].map(trigger => createTriggersObject(trigger))
    : [];
  const tableDropdownData = !fetchingTriggers ? filterTriggers(value, finalList) : false;

  return tableDropdownData;
};

export default getDeviceResource;
