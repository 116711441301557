import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { singleProjectSuiteSelector, AUTOMATED_OPERATIONS_OCCURRENCES_FIELD } from 'helpers/selectors';
import { permissionsOrRedirect, frontendPermissions } from 'gateway';
import { selectors as projectSuitesSelectors } from 'pages/ProjectSuites/utilities';
import {
  actionCreators as schedulingActionCreators,
  selectors as schedulingSelectors,
} from '../../utilities';

import DayView from './DayView';

export const mapStateToProps = (state, { match }) => {
  const { id: projectSuiteId } = match.params;
  const projectSuiteProjects = projectSuitesSelectors.projectsListSelector(state)(
    projectSuiteId,
  ) || [];
  const { timezoneSet, datetime: siteTime } = singleProjectSuiteSelector(state, projectSuiteId);
  const updating = schedulingSelectors.schedulesUpdatingSelector(state);
  const selectedProjects = projectSuitesSelectors.selectedProjectsSelector(
    state,
    projectSuiteId,
    AUTOMATED_OPERATIONS_OCCURRENCES_FIELD,
  );
  const projectsList = projectSuiteProjects.map(({ id }) => id);

  return {
    resourceId: projectSuiteId,
    resourceTime: siteTime,
    resourceType: 'projectSuite',
    timezoneSet,
    updating,
    superSite: true,
    selectedProjects,
    projectsList,
    showHidden: schedulingSelectors.showHiddenSelector(state),
    hideMultiSiteOccurrences: true,
    onToggleShowHideMultiSiteOccurrences: () => null,
  };
};

const mapDispatchToProps = {
  onFetchOccurrences: schedulingActionCreators.fetchOccurrences,
  onToggleShowHiddenFilter: schedulingActionCreators.toggleShowHiddenOccurrences,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  permissionsOrRedirect(frontendPermissions.PROJECT_SUITE_AUTOMATED_OPERATIONS_OCCURRENCES),
)(DayView);
