import { Button } from 'semantic-ui-react';
import styled, { css, keyframes } from 'styled-components';
import get from 'lodash/get';

import { injectStyles } from 'themes/mixins';
import filterProps from 'helpers/filterProps';

export const pulseKeyframe = keyframes`
  to {
    box-shadow: 0 0 0 10px;
    opacity: 0;
  }
`;

export const pulseEasing = 'cubic-bezier(0.66,0,0,1)';

export const pulseAnimation = css`${pulseKeyframe} 1.25s infinite ${pulseEasing}`;

export default styled(filterProps(['pulse', 'triggerButton'])(Button))`
  ${({ theme: { buttons } }) => injectStyles(buttons)};

  &.ui.button {
    ${({ pulse }) => pulse && css`
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      border-radius: inherit;

      box-shadow: 0 0 0 0;
      opacity: 0.7;
    }

    &.primary:after {
      color: ${({ theme }) => get(theme, 'palette.primaryColor')};
      animation: ${pulseAnimation};
    }
  `}

  ${({ triggerButton }) => triggerButton && css`
    font-size: 1em;
  `}
  }
`;
