import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';

export const toggleSuspendUser = ({ id, suspended, ...rest }) => ({
  type: constants.TOGGLE_SUSPEND_USER,
  payload: {
    endpoint: endpoints.USER(id),
    id,
    suspended: !suspended,
    ...rest,
  },
});

export const toggleSuspendUserSuccess = ({ suspended, ...response }) => ({
  type: constants.TOGGLE_SUSPEND_USER_SUCCESS,
  payload: {
    response,
    message: messages.TOGGLE_SUSPEND_USER(suspended),
  },
});

export const toggleSuspendUserFailure = error => ({
  type: constants.TOGGLE_SUSPEND_USER_FAILED,
  payload: { error },
});
