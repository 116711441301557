import constants from 'dispatcherConst';

const initialState = {
  inControlPanelContext: false,
  isPageNavigationOpen: false,
};

const controlPanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.ENTER_CONTROL_PANEL_CONTEXT:
      return { ...state, inControlPanelContext: true };

    case constants.EXIT_CONTROL_PANEL_CONTEXT:
      return { ...state, inControlPanelContext: false };

    case constants.CONTROL_UI_TOGGLE_PAGE_NAVIGATION:
      return {
        ...state,
        isPageNavigationOpen: !state.isPageNavigationOpen,
      };

    case constants.CONTROL_UI_SET_PAGE_NAVIGATION_OPEN:
      return {
        ...state,
        isPageNavigationOpen: true,
      };

    case constants.CONTROL_UI_SET_PAGE_NAVIGATION_CLOSED:
      return {
        ...state,
        isPageNavigationOpen: false,
      };

    default:
      return state;
  }
};

export default controlPanelReducer;
