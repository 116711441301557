import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import Markers from './Markers';
import { selectors } from '../../../../utilities';

export const mapStateToProps = (state, { match }) => {
  const { id: projectSuiteId } = match.params;
  const markers = selectors.childProjectsWithLocationSelector(state)(projectSuiteId);
  const isProjectSuite = true;

  return {
    markers,
    isProjectSuite,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
)(Markers);
