import styled, { css } from 'styled-components';
import { darken } from 'polished';
import get from 'lodash/get';
import { Button } from 'semantic-ui-react';

import { mediaMax } from 'themes/mixins';
import filterProps from 'helpers/filterProps';

export const FormContainer = styled.div`
  padding: 15px 0;
  height: 30vh;

  ${mediaMax.sm`
    height: auto;
  `};
`;

export const StyledLabel = styled.label`
  display: block;
  margin: 0 0 .28571429rem 0;
  color: rgba(0,0,0,.87);
  font-size: .92857143em;
  font-weight: 700;
  text-transform: none;
`;

export const ColorPickerContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: 1em;
  }
`;

export const PreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85%;
  margin-top: 8px;
  border-radius: 3px;

  ${({ backgroundType }) => backgroundType === 'light' && css`
    background: #FFF;
  `}

  ${({ backgroundType }) => backgroundType === 'dark' && css`
    background: #000;
  `}

  ${mediaMax.sm`
    height: 16vh;
  `};
`;

const FilteredProps = filterProps([
  'buttonColor',
  'textColor',
  'useCompanyTheme',
  'initialValues',
])(Button);

export const ButtonPreview = styled(FilteredProps)`
  width: 240px;
  height: 100px;

  ${({ buttonColor: { r, g, b, a } }) => css`
    background: ${`rgba(${r}, ${g}, ${b}, ${a})`} !important;

    &:hover {
      background: ${darken(0.05, `rgba(${r}, ${g}, ${b}, ${a})`)} !important;
    }
  `}

  ${({ textColor: { r, g, b, a } }) => css`
    color: ${`rgba(${r}, ${g}, ${b}, ${a})`} !important;
  `}

  ${({ useCompanyTheme }) => useCompanyTheme && css`
    color: ${({ theme }) => get(theme, ['buttons', '.ui.button', '&.primary', 'color'])} !important;
    background: ${({ theme }) => get(theme, 'palette.primaryColor')} !important;

    &:hover {
      background: ${({ theme }) => darken(0.05, get(theme, 'palette.primaryColor'))} !important;
    }
  `} 

`;

export const EmptySlotPreview = styled(Button)`
  width: 240px;
  height: 100px;
  background: #FFFFFF !important;
  border: 1px dashed rgba(0,0,0, 0.2) !important;
  cursor: default !important;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mediaMax.sm`
    flex-direction: column;
    align-items: flex-start;

    h4 {
      margin-bottom: 8px !important;
    }
  `};
`;

export const ColorPickerFields = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
`;

export default FormContainer;
