import styled, { css } from 'styled-components';
import { Field } from 'redux-form';

export const StyledField = styled(Field)`
  &&&.ui.input {
    width: 58px;
    margin-left: 5px;
  }

  &.ui.input>input {
    font-size: 10px;
    text-align: center;
  }
`;

export const StyledWrapper = styled('div')`
  display: flex; 
  width: 100%;
  align-items: center; 
  margin-bottom: 3px;
`;

export const StyledLabel = styled('div')`
  width: 10%;
`;

export const StyledInputWrapper = styled('div')`
  width: 50%;

  ${({ labelPlacement }) => labelPlacement === 'left' && css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;  
  `}

  ${({ labelPlacement }) => labelPlacement === 'bottom' && css`
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
  `}
`;

export const StyledCheckboxWrapper = styled('div')`
  padding-left: 5px;
  padding-top: 3px;
`;

export default StyledField;
