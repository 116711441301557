import constants from 'dispatcherConst';
import { REMOTE_DEVICES_FIELD, UNJOINED_REMOTE_DEVICES_FIELD } from 'helpers/selectors';
import { mergeRemoteDevicesResourceToStore } from './utilities';

const remoteDevicesDataReducer = (state, action) => {
  switch (action.type) {

    case constants.FETCH_REMOTE_DEVICES_SUCCESS: {
      return mergeRemoteDevicesResourceToStore(state, action, REMOTE_DEVICES_FIELD);
    }

    case constants.FETCH_UNJOINED_REMOTE_DEVICES_SUCCESS: {
      return mergeRemoteDevicesResourceToStore(state, action, UNJOINED_REMOTE_DEVICES_FIELD);
    }

    default:
      return state;
  }
};

export default remoteDevicesDataReducer;
