import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Segment, Header, Icon } from 'semantic-ui-react';
import isNil from 'lodash/isNil';

import CompaniesSelect from 'components/CompaniesSelect/CompaniesSelectContainer';

import { StyledButton, StyledButtonsContainer } from './VoucherTenantSelect.style';

function VoucherTenantSelect({
  selectedTenant,
  onSetSelectedTenant,
  onClearSelectedTenant,
  onClearSelectedVoucher,
  onClearGeneratedVoucher,
  onResetVoucherGenerationForm,
}) {
  const [placeholderTenant, setPlaceholderTenant] = useState(null);

  const handleCompanyChange = useCallback(company => {
    setPlaceholderTenant(company);
  }, []);

  const handleConfirm = useCallback(() => {
    onSetSelectedTenant(placeholderTenant);
  }, [onSetSelectedTenant, placeholderTenant]);

  const handleClear = useCallback(() => {
    onClearSelectedTenant();
    onClearSelectedVoucher();
    onClearGeneratedVoucher();
    onResetVoucherGenerationForm();

    setPlaceholderTenant(null);
  }, [
    onClearSelectedTenant,
    onClearSelectedVoucher,
    onClearGeneratedVoucher,
    onResetVoucherGenerationForm,
  ]);

  return (
    <Segment padded>
      <Header as="h3">
        Select company
      </Header>
      <p>Generate and validate vouchers for the selected company</p>
      <CompaniesSelect
        disabled={!isNil(selectedTenant)}
        selectedCompanyId={placeholderTenant ? placeholderTenant.id : null}
        onChange={handleCompanyChange}
      />
      <StyledButtonsContainer>
        <StyledButton
          name="confirm-button"
          primary
          disabled={!isNil(selectedTenant)}
          onClick={handleConfirm}
        >
          Confirm
        </StyledButton>
        <StyledButton
          name="clear-button"
          icon
          labelPosition="left"
          disabled={isNil(selectedTenant)}
          onClick={handleClear}
        >
          <Icon name="delete" />
          Clear selected
        </StyledButton>
      </StyledButtonsContainer>
    </Segment>
  );
}

VoucherTenantSelect.defaultProps = {
  selectedTenant: null,
};

VoucherTenantSelect.propTypes = {
  selectedTenant: PropTypes.object,
  onSetSelectedTenant: PropTypes.func.isRequired,
  onClearSelectedTenant: PropTypes.func.isRequired,
  onClearSelectedVoucher: PropTypes.func.isRequired,
  onClearGeneratedVoucher: PropTypes.func.isRequired,
  onResetVoucherGenerationForm: PropTypes.func.isRequired,
};

export default VoucherTenantSelect;
