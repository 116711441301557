import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Label, Icon } from 'semantic-ui-react';
import orderBy from 'lodash/orderBy';

import { getLabelText } from './utilities';
import { TimeList } from '../../AstronomicalView.style';

export default function AstroFormPreview({ label, values, onRemove }) {
  const renderValue = useCallback((type, value) => {
    const labelText = getLabelText(label, value);

    return (
      <>
        {labelText} <Icon name="delete" onClick={() => onRemove(value, type)} />
      </>
    );
  }, [label, onRemove]);

  const renderList = useMemo(() => {
    const orderedValues = orderBy(values, 'value');

    return (
      <TimeList>
        {orderedValues.map(({ type, value }) => (
          <li key={`${type}-${value}`}>
            <Label htmlFor="input">
              {renderValue(type, value)}
            </Label>
          </li>
        ))}
      </TimeList>
    );
  }, [values, renderValue]);

  return (
    <TimeList>
      {renderList}
    </TimeList>
  );
}

AstroFormPreview.propTypes = {
  label: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired,
};
