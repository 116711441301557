import constants from 'dispatcherConst';
import { messages } from 'data/notifications/notificationsConst';

export const logout = () => ({ type: constants.LOGOUT });

export const logoutError = () => ({
  type: constants.LOGOUT_ERROR,
  payload: {
    message: messages.LOGOUT_ERROR_MESSAGE,
  },
});

export default logout;
