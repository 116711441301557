import React, { Component, Fragment, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Confirm, Modal, Input } from 'semantic-ui-react';
import isString from 'lodash/isString';

import ButtonLink from 'components/elements/ButtonLink';
import { Button } from 'components/atomic';
import Form from 'components/form/Form/Form';

class ConfirmModal extends Component {

  constructor() {
    super();

    this.state = {
      open: false,
      complete: false,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleInput = this.handleInput.bind(this);
  }

  handleCancel() {
    const { onCancel } = this.props;

    this.handleClose();
    if (onCancel) onCancel();
  }

  handleConfirm() {
    const { complete } = this.state;
    const { onConfirm, validationString } = this.props;

    if (complete || !validationString) {
      this.handleClose();
      onConfirm();
    }
  }

  handleOpen(e) {
    this.setState({ open: true });
    e.stopPropagation();
  }

  handleClose() {
    this.setState({ open: false, complete: false });
  }

  handleInput(event, { value }) {
    const { validationString } = this.props;

    this.setState({ complete: validationString === value });
  }

  render() {
    const { open, complete } = this.state;
    const {
      trigger,
      cancelButton,
      confirmButton,
      content,
      validationString,
      validationMessage,
      onCancel,
      onConfirm,
      ...rest
    } = this.props;

    const disabled = validationString && !complete;

    return (
      <Confirm
        trigger={
          isString(trigger)
            ? <ButtonLink onClick={this.handleOpen}>{trigger}</ButtonLink>
            : cloneElement(trigger, { onClick: this.handleOpen })
        }
        cancelButton={
          isString(cancelButton)
            ? <Button>{cancelButton}</Button>
            : cloneElement(cancelButton)
        }
        confirmButton={
          isString(confirmButton)
            ? <Button disabled={disabled}>{confirmButton}</Button>
            : cloneElement(confirmButton, { disabled })
        }
        content={(
          <Modal.Content>
            {content}
            {validationString ? (
              <Fragment>
                <br />
                <Form>
                  <Form.Group>
                    <Form.Field
                      control={Input}
                      placeholder={validationMessage}
                      width={10}
                      onChange={this.handleInput}
                    />
                  </Form.Group>
                </Form>
              </Fragment>
            ) : null}
          </Modal.Content>
        )}
        open={open}
        onCancel={this.handleCancel}
        onConfirm={this.handleConfirm}
        onClose={this.handleClose}
        {...rest}
      />
    );
  }

}

ConfirmModal.defaultProps = {
  validationString: null,
  validationMessage: null,
  onCancel: null,
};

ConfirmModal.propTypes = {
  ...Confirm.propTypes,
  validationString: PropTypes.string,
  validationMessage: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmModal;
