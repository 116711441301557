import React from 'react';
import PropTypes from 'prop-types';

import DataTableRow from 'components/DataTable/DataTableRow/DataTableRow';
import DataTableText from 'components/DataTable/DataTableText/DataTableText';
import DataTableIcon from 'components/DataTable/DataTableIcon/DataTableIcon';
import DataTableMultiple from 'components/DataTable/DataTableMultiple/DataTableMultiple';

const CompanyTableRow = props => {
  const { item: { manufacturer } } = props;

  return (
    <DataTableRow {...props}>
      <DataTableText />
      <DataTableText />
      <DataTableMultiple />
      {manufacturer ? <DataTableIcon name="check" /> : null}
    </DataTableRow>
  );
};

CompanyTableRow.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    subdomain: PropTypes.string,
    origins: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.bool,
      }).isRequired,
    ),
    manufacturer: PropTypes.bool,
  }).isRequired,
  active: PropTypes.bool.isRequired,
  rowNumber: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CompanyTableRow;
