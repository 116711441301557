import React from 'react';
import PropTypes from 'prop-types';

import { StyledButton } from './GenerateLogsButton.style';

const GenerateLogsButton = ({
  isButtonDisabled,
  getLogs,
}) => (
  <div>
    <StyledButton disabled={isButtonDisabled} onClick={getLogs}>
      Generate logs
    </StyledButton>
  </div>
);

GenerateLogsButton.propTypes = {
  isButtonDisabled: PropTypes.bool.isRequired,
  getLogs: PropTypes.func.isRequired,
};

export default GenerateLogsButton;
