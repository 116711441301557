import styled, { css } from 'styled-components';
import { Image } from 'semantic-ui-react';

import brandLogo from 'assets/logos/brand-logo.svg';
import { media, mediaMax } from 'themes/mixins';
import filterProps from 'helpers/filterProps';

const FilteredControlPanelWrapperProps = filterProps(['isPageNavigationOpen'])('footer');

export const ControlPanelWrapper = styled(FilteredControlPanelWrapperProps)`
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 190px;
  padding: 14px;
  padding-left: 7px;
  padding-bottom: 7px;
  background-color: rgba(255,255,255,0.2);

  & img {
    position: relative;
    bottom: 4px;
    text-shadow: 0 0 0.2em #FFF;
  }

  ${({ isPageNavigationOpen }) => isPageNavigationOpen && css`
    ${mediaMax.sm`
      display: none;
    `};
  `}
`;

export const FooterWrapper = styled.footer`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  ${media.md`z-index: 102;`};

  > .ui.segment {
    font-size: 0.9286rem;
  }

  ${({ control }) => control && css`
    right: unset;
    width: 230px;
  `}
`;

export const BrandLogoWrapper = styled.span`
  display: inline-flex;
  align-items: baseline;
`;

export const BrandLogoText = styled.span`
  position: relative;
  top: -3px;
`;

export const BrandLogo = styled(Image).attrs(() => ({
  src: brandLogo,
  href: 'https://www.sixeye.net',
  target: '_blank',
  forwardedAs: 'a',
  size: 'tiny',
}))`
  margin-left: 0.5rem;
`;
