/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import View from 'ol/View';
import OLMap from 'ol/Map';
import 'ol/ol.css';
import 'elm-pep';
import { fromLonLat } from 'ol/proj';

import MapContext from '../MapContext/MapContext';
import { StyledDiv } from './Map.style';

const Map = ({ children }) => {
  const mapRef = useRef();
  const [map, setMap] = useState(null);
  const [mapReady, setMapReady] = useState(false);

  useEffect(() => {
    const zoom = 11;
    const center = fromLonLat([-0.12755, 51.507222]);
    const options = {
      view: new View({ zoom, center }),
      layers: [],
      controls: [],
      overlays: [],
    };
    const mapObject = new OLMap(options);

    mapObject.setTarget(mapRef.current);
    setMap(mapObject);
    mapObject.on('rendercomplete', () => setMapReady(true));

    return () => mapObject.setTarget(undefined);
  }, []);

  return (
    <MapContext.Provider value={{ map }}>
      <StyledDiv ref={mapRef}>
        {mapReady && children}
      </StyledDiv>
    </MapContext.Provider>
  );
};

Map.propTypes = {
  children: PropTypes.object.isRequired,
};

export default Map;
