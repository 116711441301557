import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';

export const redeemVoucher = ({
  projectId,
  voucher,
  siteName,
  ownerEmail,
  resolveForm,
  rejectForm,
}) => ({
  type: constants.REDEEM_VOUCHER_REQUEST,
  payload: {
    projectId,
    voucher,
    siteName,
    ownerEmail,
    endpoint: projectId ? endpoints.REDEEM_VOUCHER_PROJECT(projectId) : endpoints.REDEEM_VOUCHER,
    resolveForm,
    rejectForm,
  },
});

export const redeemVoucherSuccess = response => ({
  type: constants.REDEEM_VOUCHER_SUCCESS,
  payload: {
    response,
    message: messages.REDEEM_VOUCHER_SUCCESS,
  },
});

export const redeemVoucherFailure = error => ({
  type: constants.REDEEM_VOUCHER_FAILED,
  payload: { error },
});
