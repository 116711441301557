import React from 'react';
import PropTypes from 'prop-types';

import UnorderedListWrapper from './UnorderedList.style';

const UnorderedList = ({ items, ...rest }) => {
  const renderItems = () => items && items.map(
    item => <li key={item}>{item}</li>,
  );

  return (
    <UnorderedListWrapper {...rest}>
      {renderItems()}
    </UnorderedListWrapper>
  );
};

UnorderedList.defaultProps = {
  items: undefined,
  negative: false,
};

UnorderedList.propTypes = {
  items: PropTypes.array,
  negative: PropTypes.bool,
};

export default UnorderedList;
