import has from 'lodash/has';

const isLoadingSelector = (state, resourceId) => {
  const { isLoading } = state.permissionsTable;

  if (!has(isLoading, resourceId)) {
    return false;
  }

  return isLoading[resourceId];
};

export default isLoadingSelector;
