import React from 'react';
import { Loader } from 'semantic-ui-react';

import { StyledDiv } from './MapPlaceholder.style';

const MapPlaceholder = () => (
  <StyledDiv>
    <Loader active inline="centered" />
  </StyledDiv>
);

export default MapPlaceholder;
