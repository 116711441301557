import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { uploadsType } from 'data/types';
import ScrollPos from 'helpers/ScrollPos/ScrollPos';
import UploadsAccordion from 'components/UploadsWidget/UploadsAccordion/UploadsAccordion';
import StyledMobileUploads from './UploadsMobileController.style';

export const FOOTER_HEIGHT = 50;

class UploadsMobileController extends Component {

  static getFooterWaypoint() {
    const computedWaypoint = document.body.scrollHeight - window.innerHeight - FOOTER_HEIGHT;

    return Math.max(computedWaypoint, 0);
  }

  constructor() {
    super();

    this.state = { open: false };

    this.handleClick = this.handleClick.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate({ onClearCompleted }, { open: nextOpen }) {
    const { open } = this.state;

    if (open && !nextOpen) onClearCompleted();
  }

  handleClick() {
    this.setState(prevState => ({ open: !prevState.open }));
  }

  renderController(position, footerWaypoint) {
    const { open } = this.state;
    const { uploads } = this.props;

    const fixed = position <= footerWaypoint;

    return (
      <StyledMobileUploads
        open={open}
        fixed={fixed}
        uploads={uploads}
        onClick={this.handleClick}
      >
        {uploads.map(({ project, files }) => (
          <UploadsAccordion
            key={project}
            title={project}
            data={files}
          />
        ))}
      </StyledMobileUploads>
    );
  }

  render() {
    return (
      <ScrollPos>
        {position => (
          this.renderController(position, UploadsMobileController.getFooterWaypoint())
        )}
      </ScrollPos>
    );
  }

}

UploadsMobileController.propTypes = {
  // eslint-disable-next-line react/no-typos
  uploads: uploadsType.isRequired,
  onClearCompleted: PropTypes.func.isRequired,
};

export default UploadsMobileController;
