import styled, { css } from 'styled-components';

import DefinitionListItemSingle
  from 'pages/Device/DefinitionList/DefinitionListItem/DefinitionListItemSingle/DefinitionListItemSingle';
import InlineEdit from 'components/form/InlineEdit/InlineEditContainer';

export const NameInlineEdit = styled(InlineEdit)`
  .ui.icon.button {
    padding-left: 1.25em;
    padding-right: 1.25em;
    
    i.icon {
      font-size: 1.25em;
      line-height: 1em;
    }
  }
`;

export const DetailListItem = styled(DefinitionListItemSingle)`
  padding: 0.5em;
  ${({ center }) => center && css`align-items: center;`};
`;
