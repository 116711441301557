import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormGroup } from 'semantic-ui-react';

import ModalForm from 'components/form/ModalForm/ModalForm';
import MultipleTasksTableField from '../../MultipleTasksTableField/MultipleTasksTableFieldContainer';
import MultipleTasksTableFieldProjectSuite from '../../MultipleTasksTableField/MultipleTasksTableFieldProjectSuiteContainer';
import ProjectSuiteContext from '../../ProjectSuiteContext/ProjectSuiteContext';
import { automatedOperationFormFields } from '../../../constants';

const EditAutomatedOperationModalTasksForm = ({
  submitting,
  selectedTasks,
  item: {
    name,
    taskIds,
  },
  change,
  ...rest
}) => {
  const superSites = useContext(ProjectSuiteContext);

  const header = `Edit ${name}`;
  const trigger = 'Edit';

  return (
    <ModalForm
      header={header}
      trigger={trigger}
      size="small"
      submitting={submitting}
      initialValues={{
        taskIds,
      }}
      {...rest}
    >
      <FormGroup inline>
        <Field
          name={automatedOperationFormFields.selectedTasks}
          component={superSites ? MultipleTasksTableFieldProjectSuite : MultipleTasksTableField}
          selectedTasks={taskIds}
        />
      </FormGroup>
    </ModalForm>
  );
};

EditAutomatedOperationModalTasksForm.defaultProps = {
  selectedTasks: null,
  editMode: false,
  change: () => null,
};

EditAutomatedOperationModalTasksForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  selectedTasks: PropTypes.array,
  editMode: PropTypes.bool,
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    taskIds: PropTypes.array.isRequired,
  }).isRequired,
  change: PropTypes.func,
};

export default EditAutomatedOperationModalTasksForm;
