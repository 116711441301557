import { Image, MenuItem } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import colors from 'themes/colors';
import { media, mediaMax, injectStyles } from 'themes/mixins';
import filterProps from 'helpers/filterProps';

const TenantLogo = styled(filterProps('topbar')(MenuItem)).attrs(() => ({
  header: true,
}))`
  &&&& {
    ${({ topbar }) => topbar && css`
      ${media.sm`
        padding-left: 0;
      `};
      ${media.md`
        padding-right: 0;
        margin: auto;
      `};
    `};

    &:hover {
      background: transparent;
    }

    a {
      display: flex;
      align-items: center;

      &:after {
        ${({ theme: { logo } }) => css`
          ${logo && logo.text ? css`content: '${logo.text}'` : css`display: none;`};
        `};

        margin-left: 1em;
        color: ${colors.black};
        font-weight: 400;
      }
    }
  }
`;

TenantLogo.Image = styled(filterProps('topbar')(Image)).attrs(({ theme: { logo } }) => ({
  size: 'mini',
  src: logo && logo.src,
  alt: logo && logo.alt,
}))`
  &&&& {
    max-height: 50px;
    width: auto;
    ${({ theme: { logo } }) => logo && logo.style && injectStyles(logo.style)}

    ${({ theme: { logo } }) => logo && logo.mobileStyle && css`
      ${mediaMax.sm`
        ${injectStyles(logo.mobileStyle)}
      `}
    `}

    ${({ topbar }) => topbar && css`
      max-height: 40px;

      ${media.md`max-height: 50px;`};
    `};
  }
`;

export default TenantLogo;
