import filterPermissions from './filterPermissions';
import transformCumulatedPermissions from './transformCumulatedPermissions';
import transformToTableRows from './transformToTableRows';
import addRecipientsInfo from './addRecipientsInfo';
import getPermissionNames from './getPermissionNames';

const transformFetchCumulatedPermissionsResponse = ({
  allPermissions,
  originalCumulatedPermissions,
  resourceType,
  resourceName,
  recipientIds,
  limitedScope,
  limitedFor,
  context,
}) => {
  const permissions = filterPermissions({
    allPermissions,
    resourceType,
    limitedScope,
    limitedFor,
  });

  const permissionsNames = getPermissionNames(permissions);

  const cumulatedPermissions = transformCumulatedPermissions({
    permissionsNames,
    originalCumulatedPermissions,
    recipientIds,
    resourceType,
    context,
  });

  let rows = transformToTableRows({
    resourceType,
    resourceName,
    cumulatedPermissions,
  });

  rows = addRecipientsInfo({
    cumulatedPermissions,
    rows,
    recipientIds,
  });

  return {
    cumulatedPermissions,
    permissionsTableRows: rows,
  };
};

export default transformFetchCumulatedPermissionsResponse;
