import styled from 'styled-components';
import { Select } from 'semantic-ui-react';

import { mediaMax } from 'themes/mixins';

export const StyledLogTableHeader = styled.div`
  height: 50px;
  padding-left: 1em;
  
  ${mediaMax.sm`
    display: none;
  `};
`;

export const StyledHeaderItem = styled.div`
  ${({ width }) => width && `width: ${width}px;`};
  float: left;
  font-size: 1.2em;
  padding-top: 1em;
`;

export const StyledLinesSelect = styled(Select)`
  &.ui.selection.dropdown {
    width: 100px;
    min-width: 100px;
    float: right;
  }
`;

export const StyledLogTable = styled.div`
  border: 1px solid black;
  min-height: 200px;
  max-height: 400px;
  overflow-y: scroll;
  padding: 10px;
`;

export const StyledLogLevelSelect = styled(Select)`
  margin-top: 1em;
`;
