import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { PermissionsSwitch, Gateway, frontendPermissions } from 'gateway';
import formNames from 'components/form/formNames';
import EditAutomatedOperationModalForm from './EditAutomatedOperationModalForm/EditAutomatedOperationModalFormContainer';

const EditAutomatedOperation = ({
  item,
  currentItem,
  onEditAutomatedOperation,
  mobileVersion,
  isMultiSite,
}) => {
  const permission = useMemo(() => {
    if (isMultiSite) {
      return frontendPermissions.PROJECT_SUITE_AUTOMATED_OPERATIONS_EDIT;
    }

    return frontendPermissions.EDIT_AUTOMATED_OPERATION;
  }, [isMultiSite]);

  const handleEditAutomatedOperation = values => {
    const { id, lockVersion } = item || currentItem;

    return new Promise((resolveForm, rejectForm) => {
      onEditAutomatedOperation({
        values: {
          ...values,
          id,
          lockVersion,
        },
        resolveForm,
        rejectForm,
      });
    });
  };

  const existingItem = item || currentItem;
  const { id } = existingItem;
  const formName = formNames.editAOProperties;

  return (
    <PermissionsSwitch>
      <Gateway condition={permission}>
        <EditAutomatedOperationModalForm
          onSubmit={handleEditAutomatedOperation}
          item={existingItem}
          form={`editAutomatedOperation-${id}-${formName}`}
          mobileVersion={mobileVersion}
          destroyForm
        />
      </Gateway>
    </PermissionsSwitch>
  );
};

EditAutomatedOperation.defaultProps = {
  item: null,
  currentItem: null,
  mobileVersion: false,
  isMultiSite: false,
};

EditAutomatedOperation.propTypes = {
  onEditAutomatedOperation: PropTypes.func.isRequired,
  item: PropTypes.object,
  currentItem: PropTypes.object,
  mobileVersion: PropTypes.bool,
  isMultiSite: PropTypes.bool,
};

export default EditAutomatedOperation;
