import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';
import CheckboxField from 'components/form/CheckboxField/CheckboxField';
import SegmentRow from 'components/SegmentRow/SegmentRow';
import { pathwayDeviceNETValueSelector } from 'helpers/selectors';
import DeviceSettingForm from 'pages/Device/DeviceSettingForm/DeviceSettingFormContainer';
import DeviceTriggerAction from 'pages/Device/DeviceTriggerAction/DeviceTriggerActionContainer';
import consts from 'layouts/Pathway/constants';

const PathwayVIAPortsV1Settings = ({ online, element: { resourceId, id } }) => (
  <Segment>
    <Header as="h4">Settings</Header>
    <SegmentRow>
      <div>PoE Enabled:</div>
      <DeviceSettingForm
        resourceId={resourceId}
        port={id}
        label="PoE Enabled"
        valueSelector={pathwayDeviceNETValueSelector(id, consts.FIELD_POE)}
        setting={consts.SETTING_POE_ENABLED}
        fieldProps={{ component: CheckboxField, type: 'checkbox' }}
        name="poe"
        payload={() => ({ portId: null, interfaceId: parseInt(id, 10) })}
        confirmationTitle="Commit Changes"
        confirmationMessage="This could cause the device to go offine momentarily
        while it resets or cause network outage.
        Do you want to continue?"
      />
    </SegmentRow>
    <SegmentRow>
      <div>Link Active:</div>
      <DeviceSettingForm
        resourceId={resourceId}
        port={id}
        label="Link Active"
        valueSelector={pathwayDeviceNETValueSelector(id, consts.FIELD_LINK)}
        setting={consts.SETTING_IFACE_NET_LINK_ENABLED}
        fieldProps={{ component: CheckboxField, type: 'checkbox' }}
        name="link"
        payload={() => ({ portId: null, interfaceId: parseInt(id, 10) })}
        confirmationTitle="Commit Changes"
        confirmationMessage="This could cause the device to go offine momentarily
        while it resets or cause network outage.
        Do you want to continue?"
      />
    </SegmentRow>
    <SegmentRow>
      <div>PoE Power Cycle:</div>
      <DeviceTriggerAction
        online={online}
        title="Power Cycle Port"
        resourceId={resourceId}
        actionType={consts.ACTION_POE_CYCLE}
        values={{ interfaceId: parseInt(id, 0) }}
        confirmationTitle="Power Cycle PoE Port"
        confirmationMessage="This will power cycled PoE on the selected port and
        may cause network outage.
        Do you want to continue?"
      />
    </SegmentRow>
  </Segment>
);

PathwayVIAPortsV1Settings.defaultProps = {
  online: false,
};

PathwayVIAPortsV1Settings.propTypes = {
  online: PropTypes.bool,
  element: PropTypes.shape({
    resourceId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default PathwayVIAPortsV1Settings;
