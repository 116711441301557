import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';

import {
  currentUserSelector,
  COMPANIES_FIELD,
  SHOW_COMPANIES_COMPONENT,
} from 'helpers/selectors';
import { addPagination } from 'helpers/paginationHelpers';
import redirectIfNotFound from 'helpers/redirectIfNotFound';

import { actionCreators as companyActionCreators } from 'application/tenant/console/company/utilities';
import {
  actionCreators as permissionsTableActionCreators,
  selectors as permissionsTableSelectors,
} from 'components/PermissionsTable/utilities';

import PermissionsCompaniesTab from './PermissionsCompaniesTab';

export const mapStateToProps = state => {
  const companyIds = permissionsTableSelectors.selectedResourceIdsSelector(state, COMPANIES_FIELD);

  return {
    companyIds,
    currentUser: currentUserSelector(state),
  };
};

export const mapDispatchToProps = {
  onAddCompanyId: id => permissionsTableActionCreators.addResourceId(id, COMPANIES_FIELD),
  onRemoveCompanyId: id => permissionsTableActionCreators.removeResourceId(id, COMPANIES_FIELD),
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  redirectIfNotFound('currentUser.superadmin', false),
  chainedLoaderWithSaga(
    addPagination(
      {
        resourceType: COMPANIES_FIELD,
        included: true,
        componentName: SHOW_COMPANIES_COMPONENT,
      },
      companyActionCreators.fetchCompanies({
        fields: {
          companies: [
            'name',
          ],
        },
        sort: 'name',
      }),
    ),
  ),
)(PermissionsCompaniesTab);
