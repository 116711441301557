import mergeWith from 'lodash/mergeWith';

import constants from 'dispatcherConst';
import { mergeCustomizer } from 'helpers/sharedMethods';
import { PROJECT_SUITES_PROJECTS_FILTER_FIELD } from 'helpers/selectors';

export const projectSuiteProjectsFilterReducer = (state, action) => {
  switch (action.type) {
    case constants.FETCH_PROJECT_SUITE_PROJECTS_FILTER_SUCCESS: {
      const {
        response: {
          projectSuiteId,
          data,
        },
      } = action.payload;
      const projects = data.map(({ id, attributes: { name } }) => (
        {
          id,
          name,
        }
      ));
      const projectSuiteProjectsList = {
        [PROJECT_SUITES_PROJECTS_FILTER_FIELD]: {
          [projectSuiteId]: {
            attributes: {
              projectsList: projects || null,
            },
          },
        },
      };

      return mergeWith({}, state, projectSuiteProjectsList, mergeCustomizer);
    }

    default:
      return state;
  }
};

export default projectSuiteProjectsFilterReducer;
