const CP_PAGEABLE_TYPE_PROJECT = 'Project';
const CP_PAGEABLE_TYPE_PROJECT_SUITE = 'ProjectSuite';

const CONTROL_UI_CONTROL_TYPES = {
  BUTTON: 'button',
  OVERRIDE_CONTROLS: 'overrideControls',
};

const CONTROL_UI_PAGE_TYPES = {
  DEFAULT: 'default',
  BUTTONS_OVERRIDE_CONTROLS: 'buttons_override_controls',
  OVERRIDE_CONTROLS_BUTTONS: 'override_controls_buttons',
  OVERRIDE_CONTROLS: 'override_controls',
};

const CONTROL_UI_PAGE_TYPES_LABELS = {
  [CONTROL_UI_PAGE_TYPES.DEFAULT]: '8 Buttons',
  [CONTROL_UI_PAGE_TYPES.BUTTONS_OVERRIDE_CONTROLS]: '4 Buttons + Override Controls',
  [CONTROL_UI_PAGE_TYPES.OVERRIDE_CONTROLS_BUTTONS]: 'Override Controls + 4 Buttons',
  [CONTROL_UI_PAGE_TYPES.OVERRIDE_CONTROLS]: '2 Override Controls',
};

const CONTROL_UI_PAGE_TYPES_OPTIONS = [
  {
    key: CONTROL_UI_PAGE_TYPES.DEFAULT,
    label: CONTROL_UI_PAGE_TYPES_LABELS[CONTROL_UI_PAGE_TYPES.DEFAULT],
  },
  {
    key: CONTROL_UI_PAGE_TYPES.BUTTONS_OVERRIDE_CONTROLS,
    label: CONTROL_UI_PAGE_TYPES_LABELS[CONTROL_UI_PAGE_TYPES.BUTTONS_OVERRIDE_CONTROLS],
  },
  {
    key: CONTROL_UI_PAGE_TYPES.OVERRIDE_CONTROLS_BUTTONS,
    label: CONTROL_UI_PAGE_TYPES_LABELS[CONTROL_UI_PAGE_TYPES.OVERRIDE_CONTROLS_BUTTONS],
  },
  {
    key: CONTROL_UI_PAGE_TYPES.OVERRIDE_CONTROLS,
    label: CONTROL_UI_PAGE_TYPES_LABELS[CONTROL_UI_PAGE_TYPES.OVERRIDE_CONTROLS],
  },
];

export {
  CP_PAGEABLE_TYPE_PROJECT,
  CP_PAGEABLE_TYPE_PROJECT_SUITE,
  CONTROL_UI_CONTROL_TYPES,
  CONTROL_UI_PAGE_TYPES,
  CONTROL_UI_PAGE_TYPES_LABELS,
  CONTROL_UI_PAGE_TYPES_OPTIONS,
};
