import { useEffect } from 'react';
import PropTypes from 'prop-types';

const BackgroundImagePreview = ({
  backgroundImg,
  onLoadPageBackgroundImage,
  projectId,
  pageId,
  lockVersion,
}) => {

  useEffect(() => {
    if (backgroundImg) {
      onLoadPageBackgroundImage({
        pageId,
        projectId,
        url: backgroundImg,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgroundImg, pageId, lockVersion]);

  return null;
};

BackgroundImagePreview.defaultProps = {
  backgroundImg: null,
  backgroundImgBlob: null,
  localURL: null,
  pageId: 0,
  lockVersion: 0,
};

BackgroundImagePreview.propTypes = {
  backgroundImg: PropTypes.string,
  backgroundImgBlob: PropTypes.string,
  localURL: PropTypes.string,
  onLoadPageBackgroundImage: PropTypes.func.isRequired,
  pageId: PropTypes.number,
  projectId: PropTypes.string.isRequired,
  lockVersion: PropTypes.number,
};

export default BackgroundImagePreview;
