import { snakeCaseToOption } from 'helpers/sharedMethods';

function optionsFromArrayOfString(array) {
  if (Array.isArray(array) && array.length > 0) {
    return array.map(item => ({
      key: item,
      text: snakeCaseToOption(item),
      value: item,
    }));
  }

  return {
    key: 'not_found',
    text: 'Undefined Option',
    value: 'not_found',
  };
}

export default optionsFromArrayOfString;
