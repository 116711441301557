import endpoints from 'data/api/endpoints';
import constants from 'dispatcherConst';

export const fetchCurrentUser = () => ({
  type: constants.FETCH_CURRENT_USER,
  payload: { endpoint: endpoints.CURRENT_USER },
});

export const fetchCurrentUserSuccess = response => ({
  type: constants.FETCH_CURRENT_USER_SUCCESS,
  payload: { response },
});

export const fetchCurrentUserFailure = error => ({
  type: constants.FETCH_CURRENT_USER_FAILED,
  payload: { error },
});
