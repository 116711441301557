import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getDisplayName } from 'helpers/sharedMethods';
import UserModel from 'models/UserModel';

const withReInviteUser = WrappedComponent => class extends Component {

  static displayName = `WithReInviteUser(${getDisplayName(WrappedComponent)})`;

  static defaultProps = {
    projectId: undefined,
  }

  static propTypes = {
    inviting: PropTypes.bool.isRequired,
    item: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }).isRequired,
    projectId: PropTypes.string,
    onReInviteUser: PropTypes.func.isRequired,
  }

  constructor() {
    super();

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { item: { email }, projectId, onReInviteUser } = this.props;

    onReInviteUser({ values: { email, projectId } });
  }

  render() {
    const { item: user, projectId, inviting } = this.props;

    const userModel = new UserModel(user);
    const isVisible = !user.suspended
      && (projectId
        ? userModel.hasInvitationToProject(projectId)
        : !user.lastLoginTime
      );

    if (!isVisible) {
      return null;
    }

    return (
      <WrappedComponent
        onClick={this.handleClick}
        disabled={inviting}
        title="Re-invite"
      />
    );
  }

};

export default withReInviteUser;
