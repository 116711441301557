import isString from 'lodash/isString';

export const types = {
  DEBUG: 'DEBUG',
  BETA: 'BETA',
  RC: 'RC',
};

export const typeOrder = [
  types.DEBUG,
  types.BETA,
  types.RC,
];

const isValidVersion = version => {
  if (!version || !isString(version)) {
    return false;
  }

  const valid = [0, 10, 2];
  const [
    validMajor,
    validMinor,
    validPoint,
    validType,
    validTypeIndex,
  ] = valid;
  const [
    majorString,
    minorString,
    pointString,
    typeString,
    typeIndexString,
  ] = version.split('.');
  const major = Number(majorString);

  if (major > validMajor) {
    return true;
  }

  if (validMajor > major) {
    return false;
  }

  const minor = Number(minorString);

  if (minor > validMinor) {
    return true;
  }

  if (validMinor > minor) {
    return false;
  }

  const point = Number(pointString);

  if (point > validPoint) {
    return true;
  }

  if (validPoint > point) {
    return false;
  }

  if (!validType && typeString) {
    return false;
  }

  if (validType && !typeString) {
    return true;
  }

  const validTypePosition = typeOrder.indexOf(validType);
  const typePosition = typeOrder.indexOf(typeString);

  if (validTypePosition > typePosition) {
    return false;
  }

  const typeIndex = Number(typeIndexString);

  if (validTypeIndex > typeIndex) {
    return false;
  }

  return true;
};

export default isValidVersion;
