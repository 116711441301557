import styled, { css } from 'styled-components';

export const StyledGridChannelDisplayItemName = styled.span`
  font-size: 0.8em;
  margin-top: 4px;
`;

export const StyledGridChannelDisplayItemValue = styled.span`
  font-weight: 700;
  margin-top: 2px;
`;

export const outputStatus = Object.freeze({
  UNPATCHED: 'unpatched',
  ACTIVE: 'active',
  OUTPUT_LIVE: 'live',
  PARKED: 'parked',
});

export const StyledGridChannelDisplayItem = styled.div`
  min-width: 50px;
  height: 50px;
  text-align: center;

  & > span {
    display: block;
  }

  ${({ status }) => status === outputStatus.UNPATCHED && css`
    background: #f7f7fe;
    border: 1px solid #969696;
    color: #969696;
  `}

  ${({ status }) => status === outputStatus.ACTIVE && css`
    background: white;
    border: 1px solid #5a5a5a;
    color: #5a5a5a;
  `}

  ${({ status }) => status === outputStatus.OUTPUT_LIVE && css`
    background: #f5f1db;
    border: 1px solid #716141;
    color: #716141;
  `}

  ${({ status }) => status === outputStatus.PARKED && css`
    background: #ebdad9;
    border: 1px solid #a76b67;
    color: #a76b67;
  `}

  ${({ isMarked }) => isMarked && css`
    background: #fcfff5;
    border: 1px solid #a3c293;
    color: #2c662d;
  `};
`;
