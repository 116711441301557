import isNil from 'lodash/isNil';

const calculateColorIntensity = (color, percentage) => {
  if (isNil(color) || isNil(percentage)) {
    return 'rgb(255, 255, 255)';
  }

  const rValue = Math.round((color.r * percentage) / 100);
  const gValue = Math.round((color.g * percentage) / 100);
  const bValue = Math.round((color.b * percentage) / 100);

  return `rgb(${rValue}, ${gValue}, ${bValue})`;
};

export default calculateColorIntensity;
