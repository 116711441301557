import React, { Fragment, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import * as validators from 'components/form/formValidators';
import DataTableEditable from 'components/DataTable/DataTableEditable/DataTableEditable';
import DataTableEditableToggle from 'components/DataTable/DataTableEditableToggle/DataTableEditableToggle';
import ProjectSuiteContext from '../ProjectSuiteContext/ProjectSuiteContext';
import { StyledScheduledTaskRow, SchedulesTableWrapperCell } from './AutomatedOperationsAccordionRow.style';
import {
  ScheduledTaskRadio,
  OperationsTable,
  SchedulesTable,
  DataTableEditableWithLabel,
} from './components';

export const renderNameChild = (condition, handler, labelName) => {
  if (condition) {
    return (
      <DataTableEditableWithLabel
        validator={validators.validDisplayName}
        onSubmit={handler}
        labelName={labelName}
        preventPropagation
      />
    );
  }

  return (
    <DataTableEditable
      validator={validators.validDisplayName}
      onSubmit={handler}
      preventPropagation
    />
  );
};

const AutomatedOperationsAccordionRow = ({
  item,
  onUpdateAutomatedOperation,
  enableTaskSchedulerEdit,
  ...rest
}) => {
  const superSites = useContext(ProjectSuiteContext);
  const { lockVersion } = item;

  const handleToggle = (itemId, values) => new Promise(
    (resolveForm, rejectForm) => {
      onUpdateAutomatedOperation({
        values: {
          id: itemId,
          ...values,
          lockVersion,
        },
        resolveForm,
        rejectForm,
      });
    },
  );

  const [open, setOpen] = useState(false);
  const handleChange = () => {
    setOpen(!open);
  };

  const handleSubmit = itemId => inlineFormValues => handleToggle(itemId, inlineFormValues);

  return (
    <Fragment>
      <StyledScheduledTaskRow
        {...rest}
        item={item}
        onClick={handleChange}
        customRadioButton={ScheduledTaskRadio}
        open={open}
      >
        {renderNameChild(superSites, handleSubmit)}
        <DataTableEditable onSubmit={handleSubmit} preventPropagation />
        <DataTableEditableToggle onSubmit={handleSubmit} preventPropagation />
      </StyledScheduledTaskRow>
      {open
        && (
          <Fragment>
            <Table.Row>
              <Table.Cell>
                &nbsp;
              </Table.Cell>
              <SchedulesTableWrapperCell colSpan="3">
                <SchedulesTable automatedOperationId={item.id} />
              </SchedulesTableWrapperCell>
            </Table.Row>
            <OperationsTable item={item} enableTaskSchedulerEdit={enableTaskSchedulerEdit} />
          </Fragment>
        )
      }
    </Fragment>
  );
};

AutomatedOperationsAccordionRow.defaultProps = {
  enableTaskSchedulerEdit: true,
  labelName: '',
};

AutomatedOperationsAccordionRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    lockVersion: PropTypes.string.isRequired,
  }).isRequired,
  onUpdateAutomatedOperation: PropTypes.func.isRequired,
  enableTaskSchedulerEdit: PropTypes.bool,
  labelName: PropTypes.string,
};

export default AutomatedOperationsAccordionRow;
