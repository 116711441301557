import styled from 'styled-components';

export const StyledLabel = styled.label`
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
`;

export const TimeList = styled.ul`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 350px;
  max-height: 120px;
  overflow: auto;
  margin-top: 5px;

  list-style: none;
  padding-left: 0;
  
  li {
    margin: 3px;
  }
`;

export default StyledLabel;
