import React, { Component } from 'react';
import PropTypes from 'prop-types';
import result from 'lodash/result';

import NoMatch from 'pages/NoMatch/NoMatchContainer';

const redirectIfNotFound = (resourcePropPath, async = true) => WrappedComponent => {
  class RedirectComponent extends Component {

    constructor() {
      super();

      this.state = {
        notFound: false,
      };
    }

    componentDidMount() {
      if (!async) {
        const resource = result(this.props, resourcePropPath);

        if (!resource) {
          this.setState({ notFound: true });
        }
      }
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
      const { fetching: nextFetching } = nextProps;
      const { fetching } = this.props;

      if (fetching !== nextFetching) {
        const resource = result(nextProps, resourcePropPath);

        if (!nextFetching && !resource) {
          this.setState({ notFound: true });
        }
      }
    }

    render() {
      const { fetching, ...rest } = this.props;
      const { notFound } = this.state;

      if (notFound) {
        return <NoMatch />;
      }

      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WrappedComponent {...rest} />;
    }

  }

  RedirectComponent.contextTypes = {
    store: PropTypes.object,
  };

  RedirectComponent.defaultProps = {
    fetching: false,
  };

  RedirectComponent.propTypes = {
    fetching: async ? PropTypes.bool.isRequired : PropTypes.bool,
  };

  return RedirectComponent;
};

export default redirectIfNotFound;
