import React, { useState, useCallback, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import colors from 'themes/colors';
import { elideToLength, convertColorString } from 'helpers/sharedMethods';
import { MultiStepModal } from 'components/core';

import { ProjectSuiteContext } from 'application/tenant/components/structural/ControlUI/components';
import { getControlPanelButtonSteps, configurationFormFields, validateActionsStep } from './utilities';
import { CopyButton, PasteButton, CancelButton } from './components';
import {
  StyledControlUIButton,
  StyledLabelWrapper,
  StyledLabel,
  StyledTaskName,
  StyledIcon,
} from './ControlUIButton.style';

export default function ControlUIButton({
  projectId,
  pageId,
  buttonId,
  form,
  configure,
  enabled,
  text,
  buttonColor,
  textColor,
  values,
  tasks,
  deviceActionsTaskName,
  selectedActionsForm,
  selectedTasks,
  selectedActions,
  onTriggerMultipleTasks,
  onUpdateButton,
  onResetTaskScheduler,
  onTaskSelected,
  displayCopyPasteButtons,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const resourceType = useContext(ProjectSuiteContext) ? 'projectSuite' : 'project';

  const buttonText = useMemo(() => elideToLength(text, 25), [text]);

  const styleProps = {
    configure,
    enabled,
    buttonColor: convertColorString(buttonColor),
    textColor: convertColorString(textColor, colors.black),
  };

  const isActionsStepValid = useMemo(
    () => validateActionsStep({
      taskName: deviceActionsTaskName,
      selectedActions,
      selectedActionsForm,
    }),
    [selectedActions, deviceActionsTaskName, selectedActionsForm],
  );

  const commonProps = {
    form,
    initialValues: {
      [configurationFormFields.text]: text,
      [configurationFormFields.buttonColor]: convertColorString(buttonColor),
      [configurationFormFields.textColor]: convertColorString(textColor, colors.black),
      [configurationFormFields.enabled]: enabled,
    },
    isActionsStepValid,
    resourceType,
  };

  const resetScheduler = useCallback(() => {
    onResetTaskScheduler();
    setCurrentStep(0);
  }, [onResetTaskScheduler]);

  const handleOpen = useCallback(() => {
    resetScheduler();

    if (tasks && !isEmpty(tasks)) {
      tasks.forEach(task => {
        onTaskSelected(task);
      });
    }

    setIsOpen(true);
  }, [resetScheduler, tasks, onTaskSelected]);

  const handleClose = useCallback(() => {
    resetScheduler();
    setIsOpen(false);
  }, [resetScheduler]);

  const handleTriggerTask = useCallback(() => {
    if (!isEmpty(tasks)) {
      const taskIds = tasks.map(task => task.id);

      onTriggerMultipleTasks(taskIds);
    }
  }, [tasks, onTriggerMultipleTasks]);

  const renderTrigger = useCallback(() => {
    if (!enabled) {
      return (
        <StyledControlUIButton {...styleProps}>
          <StyledIcon name="plus" />
          {displayCopyPasteButtons && (
            <>
              <CancelButton
                projectId={projectId}
                pageId={pageId}
                buttonId={buttonId}
              />
              <PasteButton
                projectId={projectId}
                pageId={pageId}
                buttonId={buttonId}
              />
            </>
          )}
        </StyledControlUIButton>
      );
    }

    return (
      <StyledControlUIButton {...styleProps}>
        <StyledLabelWrapper>
          <StyledLabel>{buttonText}</StyledLabel>
          {tasks.map(task => (
            <StyledTaskName key={task.id}>{task.name}</StyledTaskName>
          ))}
          {displayCopyPasteButtons && (
            <>
              <CancelButton
                projectId={projectId}
                pageId={pageId}
                buttonId={buttonId}
              />
              <CopyButton
                projectId={projectId}
                pageId={pageId}
                buttonId={buttonId}
              />
            </>
          )}
        </StyledLabelWrapper>
      </StyledControlUIButton>
    );
  },
  [buttonText, tasks, enabled, styleProps, projectId, pageId, buttonId, displayCopyPasteButtons]);

  const handleSubmit = useCallback(() => {
    setIsLoading(true);

    onUpdateButton({
      projectId,
      pageId,
      buttonId,
      task: isEmpty(selectedActions) ? null : {
        projectId,
        name: deviceActionsTaskName,
        description: deviceActionsTaskName,
      },
      actions: selectedActions,
      actionsForm: selectedActionsForm,
      values: {
        ...values,
        taskId: null,
        taskIds: selectedTasks.map(task => task.id),
      },
      resourceType,
      resolveForm: () => {
        setIsLoading(false);
        handleClose();
      },
      rejectForm: () => {
        setIsLoading(false);
        handleClose();
      },
      form,
    });
  }, [
    projectId,
    buttonId,
    pageId,
    values,
    selectedTasks,
    selectedActions,
    selectedActionsForm,
    deviceActionsTaskName,
    handleClose,
    onUpdateButton,
    resourceType,
    form,
  ]);

  const handlePreviousStep = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const handleNextStep = useCallback(() => {
    if (currentStep === 1) {
      handleSubmit();
    } else {
      setCurrentStep(currentStep + 1);
    }
  }, [currentStep, handleSubmit]);

  const stepsConfig = useMemo(() => getControlPanelButtonSteps(commonProps), [commonProps]);

  if (!configure) {
    return (
      <StyledControlUIButton onClick={handleTriggerTask} {...styleProps}>
        <StyledLabel>{buttonText}</StyledLabel>
      </StyledControlUIButton>
    );
  }

  return (
    <MultiStepModal
      steps={stepsConfig}
      currentStep={currentStep}
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      onPreviousStep={handlePreviousStep}
      onNextStep={handleNextStep}
      trigger={renderTrigger()}
      submitText="Save Changes"
      isSubmitting={isLoading}
    />
  );
}

ControlUIButton.defaultProps = {
  configure: false,
  enabled: false,
  text: null,
  buttonColor: null,
  textColor: null,
  task: null,
  taskId: null,
  taskName: null,
  values: null,
  selectedTasks: [],
  deviceActionsTaskName: '',
  selectedActions: [],
  selectedActionsForm: {},
  displayCopyPasteButtons: false,
};

ControlUIButton.propTypes = {
  projectId: PropTypes.string.isRequired,
  pageId: PropTypes.number.isRequired,
  buttonId: PropTypes.number.isRequired,
  configure: PropTypes.bool,
  enabled: PropTypes.bool,
  text: PropTypes.string,
  buttonColor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  textColor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  task: PropTypes.object,
  taskId: PropTypes.string,
  taskName: PropTypes.string,
  form: PropTypes.string.isRequired,
  values: PropTypes.object,
  onTriggerMultipleTasks: PropTypes.func.isRequired,
  onUpdateButton: PropTypes.func.isRequired,
  onResetTaskScheduler: PropTypes.func.isRequired,
  onTaskSelected: PropTypes.func.isRequired,
  selectedTasks: PropTypes.array,
  tasks: PropTypes.array.isRequired,
  deviceActionsTaskName: PropTypes.string,
  selectedActions: PropTypes.array,
  selectedActionsForm: PropTypes.object,
  displayCopyPasteButtons: PropTypes.bool,
};
