import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { TENANT } from 'storageConst';
import {
  USERS_FIELD,
  PROJECTS_FIELD,
  PROJECT_SUITES_FIELD,
  currentUserSelector,
  permissionsStateSelector,
  projectSuiteResourcePermissionPathsSelector,
} from 'helpers/selectors';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import permissionsActionCreators from 'application/tenant/console/utilities/permissions/permissionsActionCreators';
import { actionCreators as projectSuiteActionCreators } from 'pages/ProjectSuites/utilities';

import { projectSuitePermissionsReady } from '../utilities';
import withLoadPermissions from '../withLoadPermissions/withLoadPermissionsContainer';
import withProjectSuitePermissions from './withProjectSuitePermissions';

export const mapStateToProps = (state, { match }) => {
  const { id: projectSuiteId } = match.params;
  const { id: currentUserId } = currentUserSelector(state);
  const paths = projectSuiteResourcePermissionPathsSelector(state);
  const permissions = permissionsStateSelector(state) || {};

  return {
    currentUserId,
    projectSuiteId,
    paths,
    ready: projectSuitePermissionsReady(permissions, projectSuiteId),
  };
};

export default compose(
  withRouter,
  withLoadPermissions,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    ({
      projectSuiteId,
      ready,
    }) => !ready && projectSuiteActionCreators.fetchProjectSuite(projectSuiteId, {
      fields: {
        [PROJECT_SUITES_FIELD]: [
          'name',
          'latitude',
          'longitude',
          'timezoneId',
          'geoAddress',
          'notes',
          'usersCount',
          'projectsCount',
          'logicalDevicesCount',
          'onlineDevices',
          'offlineDevices',
          'unassociatedDevices',
          'devicesForUpgrade',
          'primaryContactEmail',
          'primaryContactPerson',
          'primaryContactPhone',
          'technicalContactEmail',
          'subscriptionContactEmail',
          'commercialContactEmail',
          'createdAt',
          'updatedAt',
          'dateBlockingStartTime',
          USERS_FIELD,
          PROJECTS_FIELD,
        ],
      },
    }),
    ({ projectSuiteId, paths, ready }) => !ready && (
      permissionsActionCreators.addProjectSuiteId(projectSuiteId, paths)
    ),
    ({
      currentUserId,
      projectSuiteId,
      ready,
    }) => !ready && permissionsActionCreators.fetchUserPermissions(
      currentUserId,
      PROJECT_SUITES_FIELD,
      projectSuiteId,
      TENANT,
    ),
  ),
  withProjectSuitePermissions,
);
