import styled from 'styled-components';

import { mediaMax } from 'themes/mixins';

const SettingsPanel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  ${mediaMax.sm`
    flex-direction: column;
  `};

  & > .ui.segment {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export default SettingsPanel;
