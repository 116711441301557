import { eventChannel, buffers } from 'redux-saga';
import { put, call, take, fork, select, takeEvery, cancel, race, delay } from 'redux-saga/effects';
import { TOKEN } from 'storageConst';
import constants from 'dispatcherConst';
import { parseMessage, stringifyMessage } from 'helpers/websocketHelpers';
import { settingsApiDomainSelector } from 'helpers/selectors';
import websocketActionCreators from './websocketsActionCreators';
import socketConstants from './constants';

export function createEventChannel(socket) {
  return eventChannel(emit => {
    /* eslint-disable no-param-reassign */
    socket.onopen = () => {
      emit({ type: socketConstants.EVENT_OPEN });
    };
    socket.onmessage = ({ data }) => {
      emit({ type: socketConstants.EVENT_MESSAGE, data });
    };
    socket.onclose = data => {
      emit({ type: socketConstants.EVENT_CLOSE, data });
    };
    socket.onerror = data => {
      emit({ type: socketConstants.EVENT_ERROR, data });
    };
    /* eslint-enable no-param-reassign */
    return () => {
      socket.close();
    };
  }, buffers.expanding(10));
}

export function* receiveSocketMessage(socket) {
  const channel = yield call(createEventChannel, socket);
  while (true) {
    const { message, timeout } = yield race({
      message: take(channel),
      timeout: delay(60000),
    });

    if (timeout) {
      yield put(websocketActionCreators.resetConnection());
      break;
    }

    const { type, data } = message || {};

    switch (type) {
      case socketConstants.EVENT_OPEN:
        yield put(websocketActionCreators.open());
        break;
      case socketConstants.EVENT_MESSAGE: {
        const parsedMessage = parseMessage(data);
        if (parsedMessage.type !== 'ping') {
          yield put(websocketActionCreators.received(parsedMessage));
        }
        break;
      }
      case socketConstants.EVENT_CLOSE: {
        yield put(websocketActionCreators.closed(data));
        break;
      }
      case socketConstants.EVENT_ERROR: {
        yield put(websocketActionCreators.error(data));
        break;
      }
      default:
        break;
    }
    if (type === socketConstants.EVENT_CLOSE) {
      break;
    }
  }
}

export function* sendSocketMessage(socket, action) {
  if (socket.readyState !== socketConstants.STATE_CODES.OPEN) {
    return;
  }

  yield socket.send(stringifyMessage(action.payload));
}

export default function* websocketsSaga() {
  const token = localStorage.getItem(TOKEN);
  const apiDomain = yield select(settingsApiDomainSelector);
  const websocketDomain = apiDomain.replace(/https{0,1}/, 'wss');
  const socket = new WebSocket(`${websocketDomain}/websocket`, ['actioncable-v1-json', token]);

  const receiveMessage = yield fork(receiveSocketMessage, socket);
  const sendMessage = yield takeEvery(constants.SOCKET_SEND, sendSocketMessage, socket);

  yield take([
    constants.CHANGE_API_DOMAIN_SUCCESS,
    constants.LOGIN_SUCCESS,
    constants.LOGOUT,
    constants.SOCKET_RESET_CONNECTION,
  ]);
  socket.close();
  yield cancel(receiveMessage);
  yield cancel(sendMessage);
  yield call(websocketsSaga);
}
