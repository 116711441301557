import React, { cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import { Motion, spring, presets } from 'react-motion';

class AnimatedScroll extends Component {

  constructor() {
    super();

    this.state = {
      motionPosition: 0,
      scrollPosition: 0,
      shouldAnimate: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.handleAnimationEnd = this.handleAnimationEnd.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
  }

  handleScroll(value) {
    this.setState({ scrollPosition: value });
  }

  handleAnimationEnd() {
    this.setState({ shouldAnimate: false });
  }

  scrollTo(value) {
    const { scrollPosition } = this.state;
    if (scrollPosition === value) return;

    this.setState({
      motionPosition: value,
      shouldAnimate: true,
    });
  }

  render() {
    const {
      motionPosition,
      scrollPosition,
      shouldAnimate,
    } = this.state;
    const { children, config } = this.props;

    const motionStyle = {
      currentPosition: shouldAnimate
        ? spring(motionPosition, config)
        : scrollPosition,
    };

    return (
      <Motion style={motionStyle} onRest={this.handleAnimationEnd}>
        {({ currentPosition }) => (
          cloneElement(children, {
            scrollTo: this.scrollTo,
            scrollPosition: currentPosition,
            onScroll: this.handleScroll,
          })
        )}
      </Motion>
    );
  }

}

AnimatedScroll.defaultProps = {
  config: presets.gentle,
};

AnimatedScroll.propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes.shape({
    stiffness: PropTypes.number,
    damping: PropTypes.number,
    precision: PropTypes.number,
  }),
};

export default AnimatedScroll;
