import styled from 'styled-components';
import { Divider } from 'semantic-ui-react';

const Connector = styled(Divider).attrs({
  horizontal: true,
})`
  &.ui.divider {
    margin-top: 0;
    margin-bottom: calc(14px + 1em);
  }
`;

export default Connector;
