import forge from 'node-forge';

import hostAndUriFromUrl from './hostAndUriFromUrl';

function buildStringToSign(url, canonicalRequest, datetimeString, awsDatetimeString) {
  const algorithm = 'AWS4-HMAC-SHA256';
  const { host } = hostAndUriFromUrl(url);
  const hostParameters = host.split('.');
  const service = hostParameters[1];
  const region = hostParameters[2];
  const credentialScope = `${datetimeString}/${region}/${service}/aws4_request`;
  const hashedRequest = forge.md.sha256.create().update(canonicalRequest).digest().toHex();
  const stringToSignLines = [
    algorithm,
    awsDatetimeString,
    credentialScope,
    hashedRequest,
  ];

  const stringToSign = stringToSignLines.join('\n');

  return {
    algorithm,
    credentialScope,
    stringToSign,
  };
}

export default buildStringToSign;
