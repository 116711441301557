import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header, Icon } from 'semantic-ui-react';

import layoutConfigs from 'layouts';
import { setTabVisibilityWithMenuItem } from 'helpers/sharedMethods';
import TabbedLayout from 'components/TabbedLayout/TabbedLayout';
import { FIRMWARE_TAB } from './constants';

class LayoutRenderer extends Component {

  getLayoutConfig() {
    const { configName } = this.props;

    return layoutConfigs[configName];
  }

  renderWarning(configName) {
    const { selectionField } = this.props;

    return (
      <Header as="h2">
        <Icon name="warning circle" />
        <Header.Content>
          Warning
          {configName ? (
            <Header.Subheader>
              Missing configuration file for the device. Please contact an administrator.
            </Header.Subheader>
          ) : (
            <Header.Subheader>
              No field named <strong>{selectionField}</strong> found on the device object.
              Please contact an administrator.
            </Header.Subheader>
          )}
        </Header.Content>
      </Header>
    );
  }

  render() {
    const {
      configName,
      resourceId,
      superAdminOrHasFirmwarePermission,
    } = this.props;

    if (!configName) {
      return this.renderWarning();
    }

    const panes = this.getLayoutConfig();
    if (!panes) {
      return this.renderWarning(configName);
    }

    const panesList = setTabVisibilityWithMenuItem(
      panes, FIRMWARE_TAB, superAdminOrHasFirmwarePermission,
    );

    return <TabbedLayout resourceId={resourceId} panes={panesList} />;
  }

}

LayoutRenderer.defaultProps = {
  configName: '',
  selectionField: 'viewSelector',
  superAdminOrHasFirmwarePermission: false,
};

LayoutRenderer.propTypes = {
  configName: PropTypes.string,
  resourceId: PropTypes.string.isRequired,
  selectionField: PropTypes.string,
  superAdminOrHasFirmwarePermission: PropTypes.bool,
};

export default LayoutRenderer;
