import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import DataTableRow from 'components/DataTable/DataTableRow/DataTableRow';
import DataTableText from 'components/DataTable/DataTableText/DataTableText';
import StatusIcon from 'components/StatusIcon/StatusIcon';

const UnjoinedRemoteDeviceCustomRow = props => {
  const { item: { status } } = props;

  return (
    <Fragment>
      <DataTableRow
        {...props}
      >
        <DataTableText />
        <DataTableText />
        <DataTableText />
        <StatusIcon {...status} />
      </DataTableRow>
    </Fragment>
  );
};

UnjoinedRemoteDeviceCustomRow.defaultProps = {
  item: null,
};

UnjoinedRemoteDeviceCustomRow.propTypes = {
  item: PropTypes.object,
};

export default UnjoinedRemoteDeviceCustomRow;
