import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { StyledBreadcrumb } from './CustomBreadcrumb.style';

function CustomBreadcrumb({
  projectSuite,
  project,
  logicalDevice,
  onBreadcrumbNavigation,
}) {
  const { name: projectSuiteName } = projectSuite || {};
  const { name: projectName } = project || {};
  const { name: logicalDeviceName } = logicalDevice || {};

  const handleNavigation = useCallback(view => {
    onBreadcrumbNavigation(view);
  }, [onBreadcrumbNavigation]);

  return (
    <StyledBreadcrumb size="tiny">
      {projectSuite && (
        <StyledBreadcrumb.Section
          name="breadcrumb-project-suite"
          onClick={() => handleNavigation('PROJECT_SUITE')}
          link
        >
          {projectSuiteName}
        </StyledBreadcrumb.Section>
      )}
      {projectSuite && project && (
        <StyledBreadcrumb.Divider
          name="breadcrumb-project-suite-divider"
          icon="right angle"
        />
      )}
      {project && (
        <StyledBreadcrumb.Section
          name="breadcrumb-project"
          onClick={() => handleNavigation('PROJECT')}
          link
        >
          {projectName}
        </StyledBreadcrumb.Section>
      )}
      {!isEmpty(logicalDevice) && (
        <>
          <StyledBreadcrumb.Divider icon="right angle" />
          <StyledBreadcrumb.Section name="breadcrumb-device">{logicalDeviceName}</StyledBreadcrumb.Section>
        </>
      )}
    </StyledBreadcrumb>
  );
}


CustomBreadcrumb.defaultProps = {
  projectSuite: null,
  project: null,
  logicalDevice: {},
};

CustomBreadcrumb.propTypes = {
  projectSuite: PropTypes.object,
  project: PropTypes.object,
  logicalDevice: PropTypes.object,
  onBreadcrumbNavigation: PropTypes.func.isRequired,
};

export default CustomBreadcrumb;
