import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import Notifications from 'components/NotificationTypes/ProjectNotificationsContainer';
import { SiteInformation, SiteMapSection } from 'application/tenant/console/site/components';

const ProjectSettingsTab = ({ projectId }) => (
  <Grid>
    <Grid.Row>
      <Grid.Column mobile={16} computer={8}>
        <SiteInformation />
      </Grid.Column>
      <Grid.Column mobile={16} computer={8}>
        <SiteMapSection />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={16}>
        <Notifications projectId={projectId} />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

ProjectSettingsTab.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default ProjectSettingsTab;
