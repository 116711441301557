import { call, put, takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import { UPLOADS } from 'storageConst';
import uploadsActionCreators from './uploadsActionCreators';
import { initialFileOpts, mergeFileOpts } from './uploadsReducer/uploadsReducerHelpers';

export const EMPTY_JSON = '{}';

export const getRecentUploads = () => {
  const storedUploadsStr = localStorage.getItem(UPLOADS);
  const storedUploads = JSON.parse(storedUploadsStr || EMPTY_JSON);

  return Object.values(storedUploads).reduce((acc, { fileKey, ...rest }) => {
    acc[fileKey] = {
      ...initialFileOpts({ fileKey }),
      ...rest,
      initialized: false,
      paused: true,
      queued: false,
    };

    return acc;
  }, {});
};

export function* updateRecentUploads() {
  const recentUploads = yield call(getRecentUploads);

  yield put(uploadsActionCreators.initializeUploadState(recentUploads));
}

export function mergeUpload(storedUploads, upload) {
  const { accessKeyId, bucket, path, systemFile, ...rest } = upload;
  if (rest.progress === 0 || rest.progress === 1) return storedUploads;

  const storageUpload = { ...rest };
  if (systemFile && systemFile.size > 0) {
    const { name, lastModified, size } = systemFile;
    storageUpload.systemFile = { name, lastModified, size };
  }

  return mergeFileOpts(storedUploads, storageUpload);
}

export function storeUploads(action) {
  const { uploads } = action.payload;
  const storedUploadsStr = localStorage.getItem(UPLOADS);
  const storedUploads = JSON.parse(storedUploadsStr || EMPTY_JSON);

  const newStoredUploads = uploads.reduce(
    (acc, upload) => mergeUpload(acc, upload),
    storedUploads,
  );
  localStorage.setItem(UPLOADS, JSON.stringify(newStoredUploads));
}

export function storeUpload(action) {
  const storedUploadsStr = localStorage.getItem(UPLOADS);
  const storedUploads = JSON.parse(storedUploadsStr || EMPTY_JSON);

  const newStoredUploads = mergeUpload(storedUploads, action.payload);
  localStorage.setItem(UPLOADS, JSON.stringify(newStoredUploads));
}

export function removeUpload(action) {
  const { payload: { fileKey } } = action;

  const storedUploadsStr = localStorage.getItem(UPLOADS);
  const storedUploads = JSON.parse(storedUploadsStr || EMPTY_JSON);
  delete storedUploads[fileKey];

  localStorage.setItem(UPLOADS, JSON.stringify(storedUploads));
}

function* uploadsStorageSaga() {
  yield takeLatest(constants.FETCH_CURRENT_USER_SUCCESS, updateRecentUploads);
  yield takeLatest(constants.UPLOAD_ENQUEUE_AT_END, storeUploads);
  yield takeLatest(constants.UPLOAD_ENQUEUE_AT_START, storeUploads);
  yield takeLatest(constants.UPLOAD_PROGRESS_UPDATE, storeUpload);
  yield takeLatest(constants.UPLOAD_START_SUCCESS, storeUpload);
  yield takeLatest(constants.UPLOAD_CANCEL_SUCCESS, removeUpload);
  yield takeLatest(constants.UPLOAD_SUCCESS, removeUpload);
}

export default uploadsStorageSaga;
