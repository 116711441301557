import { call, put } from 'redux-saga/effects';

import { postPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import projectActionCreators from 'pages/Projects/projectActionCreators';

import actionCreators from '../actionCreators';

function* upgradeSitePlan(action) {
  const { projectId, planCode, endpoint } = action.payload;

  const payload = {
    data: {
      subscriptionPlanCode: planCode,
    },
  };

  const { response, error } = yield call(postPath, endpoint, payload);

  if (!error) {
    if (projectId) {
      yield put(projectActionCreators.fetchProject(projectId));
    }
  }

  const params = {
    response,
    error,
    successDisp: actionCreators.upgradeSitePlanSuccess,
    failDisp: actionCreators.upgradeSitePlanFailure,
  };

  yield call(processCall, params);
}

export default upgradeSitePlan;
