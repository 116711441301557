import {
  deviceTriggersSelector,
  deviceTimelinesSelector,
  deviceScenesSelector,
  deviceGroupsSelector,
} from 'helpers/selectors';
import { selectors } from 'application/tenant/console/site/components/structural/ProjectTasksTab/utilities';
import transformAndMergeTriggersData from './transformAndMergeTriggersData';

const getListOfOptions = (state, uiType, deviceId, userSelectedValues) => {
  let optionList = [];
  const selectorsList = {
    triggerIds: deviceTriggersSelector,
    timelineIds: deviceTimelinesSelector,
    sceneIds: deviceScenesSelector,
    groupIds: deviceGroupsSelector,
  };
  const lists = {
    triggerIds: 'triggersList',
    timelineIds: 'timelinesList',
    sceneIds: 'scenesList',
    groupIds: 'groupsList',
  };
  const loadingSelectors = {
    triggerIds: selectors.fetchingTriggers,
    timelineIds: selectors.fetchingTimelines,
    sceneIds: selectors.fetchingScenes,
    groupIds: selectors.fetchingGroups,
  };

  if (typeof selectorsList[uiType] !== 'function') {
    return {
      optionList,
      loading: false,
    };
  }

  const getResource = selectorsList[uiType](state)(deviceId);
  const resourceList = getResource ? getResource[lists[uiType]] : [];

  optionList = transformAndMergeTriggersData(userSelectedValues, resourceList);
  const loading = loadingSelectors[uiType];

  return {
    optionList,
    loading,
  };
};

export default getListOfOptions;
