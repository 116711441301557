import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';
import deepEqual from 'lodash/isEqual';
import identity from 'lodash/identity';

import { deviceSelector } from 'helpers/selectors';
import PharosHardwareV1 from './PharosHardwareV1';

const makeMapState = () => {
  const memoModel = memoizeOne(identity, deepEqual);
  const memoSerial = memoizeOne(identity, deepEqual);
  const memoTotalMemory = memoizeOne(identity, deepEqual);
  const memoUsedMemory = memoizeOne(identity, deepEqual);
  const memoFreeMemory = memoizeOne(identity, deepEqual);
  const memoDataStorage = memoizeOne(identity, deepEqual);
  const memoBootReason = memoizeOne(identity, deepEqual);
  const memoLastBoot = memoizeOne(identity, deepEqual);
  const memoUptime = memoizeOne(identity, deepEqual);
  const memoChannelCapacity = memoizeOne(identity, deepEqual);

  return function mapStateToProps(state, ownProps) {
    const { resourceId } = ownProps;
    const device = deviceSelector(state)(resourceId);
    const { online, reportedWithMeta } = device;

    const {
      clk: {
        tz: {
          value: offsetValue,
        } = {},
      } = {},
      hw: {
        md: model,
        sn: serial,
        memt: totalMemory,
        memu: usedMemory,
        memf: freeMemory,
        sd: dataStorage,
        br: bootReason,
        bl: lastBoot,
        up: uptime,
      } = {},
      if: {
        dmx: {
          ccmax: channelCapacity,
        } = {},
      } = {},
    } = reportedWithMeta || {};

    return {
      loading: !reportedWithMeta,
      online,
      offsetValue,
      model: memoModel(model),
      serial: memoSerial(serial),
      totalMemory: memoTotalMemory(totalMemory),
      usedMemory: memoUsedMemory(usedMemory),
      freeMemory: memoFreeMemory(freeMemory),
      dataStorage: memoDataStorage(dataStorage),
      bootReason: memoBootReason(bootReason),
      lastBoot: memoLastBoot(lastBoot),
      uptime: memoUptime(uptime),
      channelCapacity: memoChannelCapacity(channelCapacity),
    };
  };
};

export default connect(makeMapState())(PharosHardwareV1);
