import { call } from 'redux-saga/effects';

import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import outputSectionActionCreators from '../actionCreators';

export default function* fetchOutput(action) {
  const {
    endpoint,
    params: {
      values: {
        protocolId,
        universeId,
      },
      refreshRate,
      requestId,
      resolveForm,
    },
  } = action.payload;

  if (!protocolId && !universeId) return;

  const urlParams = {
    protocolId,
    universeId,
    refreshRate,
    requestId,
  };
  const {
    response,
    error,
  } = yield call(getPath, endpoint, { urlParams });

  const params = {
    response,
    error,
    rejectForm: resolveForm,
    resolveForm,
    successDisp: outputSectionActionCreators.fetchOutputSuccess,
    failDisp: outputSectionActionCreators.fetchOutputFailure,
  };
  yield call(processCall, params);
}
