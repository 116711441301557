import React from 'react';

import StyledInConstructionSiteIcon, { StyledDiv } from '../../StyledInConstructionSiteIcon.style';

const StyledInConstructionSite = React.forwardRef((props, ref) => (
  <StyledDiv ref={ref}>
    <StyledInConstructionSiteIcon {...props} />
  </StyledDiv>
));

export default StyledInConstructionSite;
