import isEmpty from 'lodash/isEmpty';

const getFileName = files => {
  if (!Array.isArray(files)) {
    return null;
  }

  if (!isEmpty(files) && files[0].name && files[0].name) {
    return files[0].name;
  }

  return null;
};

export default getFileName;
