import React from 'react';
import PropTypes from 'prop-types';
import Collapse from 'components/Collapse/Collapse';
import AccordionTableHeader from './AccordionTableHeader/AccordionTableHeader';

const AccordionTable = ({ render, data, action: Action, columns }) => (
  <div>
    {data.map(section => (
      <Collapse
        title={(
          <AccordionTableHeader columns={columns} element={section}>
            <Action element={section} />
          </AccordionTableHeader>
        )}
        key={section.id}
      >
        {render(section)}
      </Collapse>
    ))}
  </div>
);

AccordionTable.propTypes = {
  render: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  action: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AccordionTable;
