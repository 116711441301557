import styled from 'styled-components';
import { Table } from 'semantic-ui-react';

export const ActionTableHeaderCell = styled(Table.HeaderCell)`
  width: auto;

  &:nth-of-type(1) {
    width: 23% !important;
  }

  &:nth-of-type(2) {
    width: 30% !important;
  }
`;

export default ActionTableHeaderCell;
