import styled from 'styled-components';

import TextEdit from 'components/form/TextEdit/TextEditContainer';

export const StyledTextEdit = styled(TextEdit)`
  & .ui.input.start {
    width: 100%;
    max-height: 80px;
  }

  & div {
    max-height: 80px;
  }
`;

export default StyledTextEdit;
