import React from 'react';
import PropTypes from 'prop-types';

const UploadsStats = ({ className, completedCount, pausedCount, uploadingCount }) => {
  const labels = [
    { text: 'remaining', value: uploadingCount },
    { text: 'paused', value: pausedCount },
    { text: 'completed', value: completedCount },
  ];

  const labelsMap = labels
    .map(({ text, value }) => (value ? `${value} ${text}` : null))
    .filter(string => string);

  return <span className={className}>{labelsMap.join(', ')}</span>;
};

UploadsStats.defaultProps = {
  className: '',
};

UploadsStats.propTypes = {
  className: PropTypes.string,
  completedCount: PropTypes.number.isRequired,
  pausedCount: PropTypes.number.isRequired,
  uploadingCount: PropTypes.number.isRequired,
};

export default UploadsStats;
