import {
  disableTwoFactor,
  disableTwoFactorSuccess,
  disableTwoFactorFailure,
} from './disableTwoFactor';

export default {
  disableTwoFactor,
  disableTwoFactorSuccess,
  disableTwoFactorFailure,
};
