import styled from 'styled-components';
import { FormField, Input } from 'semantic-ui-react';
import { rgba } from 'polished';
import filterProps from 'helpers/filterProps';
import colors from 'themes/colors';

export const FormFieldWrapper = styled(filterProps(['alignItems'])(FormField))`
  &&&&&& {
    align-items: ${props => props.alignItems || 'center'};
  }
`;

export const FormFieldLabel = styled(FormField).attrs({
  forwardedAs: 'label',
  width: 6,
})`
  &&&&&& {
    padding-left: 0;
    color: ${rgba(colors.black, 0.87)};
    font-size: 1rem;
  }
`;

export const InputWrapper = styled(Input)`
  flex-wrap: wrap;

  &.start {
    justify-content: flex-start;
    text-align: left;
  }

  &.end {
    justify-content: flex-end;
    text-align: right;
  }
`;
