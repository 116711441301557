import { Component } from 'react';
import PropTypes from 'prop-types';

class PageBackgroundImage extends Component {

  componentDidMount() {
    const {
      onLoadPageBackgroundImage,
      pageId,
      projectId,
      backgroundImg,
      configure,
    } = this.props;

    if (backgroundImg && !configure) {
      onLoadPageBackgroundImage({
        pageId,
        projectId,
        url: backgroundImg,
      });
    }
  }

  render() {
    return null;
  }

}

PageBackgroundImage.defaultProps = {
  backgroundImg: null,
};

PageBackgroundImage.propTypes = {
  configure: PropTypes.bool.isRequired,
  pageId: PropTypes.number.isRequired,
  projectId: PropTypes.string.isRequired,
  onLoadPageBackgroundImage: PropTypes.func.isRequired,
  backgroundImg: PropTypes.string,
};

export default PageBackgroundImage;
