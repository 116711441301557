import { connect } from 'react-redux';

import WarningMessage from './WarningMessage';
import { selectors } from '../../../../utilities';

export const mapStateToProps = state => {
  const getSitesWithoutLocation = selectors.projectsWithoutLocationSelector(state);
  const sitesWithoutLocation = getSitesWithoutLocation || 0;

  return {
    sitesWithoutLocation,
  };
};

export default connect(mapStateToProps)(WarningMessage);
