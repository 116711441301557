import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/atomic';
import ConfirmModal from 'components/elements/ConfirmModal/ConfirmModal';
import { getDisplayName } from 'helpers/sharedMethods';

const withDeleteOperation = WrappedComponent => {
  const WithDeleteOperation = props => {
    const {
      item: { name, id },
      onDeleteOperation,
    } = props;

    const trigger = <WrappedComponent title="Delete" />;
    const handleConfirm = () => onDeleteOperation(id);

    return (
      <ConfirmModal
        trigger={trigger}
        cancelButton="Cancel"
        confirmButton={<Button negative primary={false}>Delete Task</Button>}
        content={(
          <div>
            <p>
              Are you sure you want to delete task <strong>{name}</strong>?
              <br />This delete operation cannot be undone.
            </p>
          </div>
        )}
        header="Confirm task deletion"
        onConfirm={handleConfirm}
      />
    );
  };

  WithDeleteOperation.propTypes = {
    onDeleteOperation: PropTypes.func.isRequired,
    item: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
  };

  WithDeleteOperation.displayName = `WithDeleteOperation(${getDisplayName(WrappedComponent)})`;

  return WithDeleteOperation;

};

export default withDeleteOperation;
