import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fromLonLat } from 'ol/proj';

import { getDisplayName } from 'helpers/sharedMethods';

const siteMapCenteringBehaviour = WrappedComponent => {
  class SiteMapCenteringBehaviour extends Component {

    constructor() {
      super();

      this.handleCalculateCentre = this.handleCalculateCentre.bind(this);
    }

    handleCalculateCentre() {
      const { markers } = this.props;

      const { latitude, longitude } = markers[0];
      const center = fromLonLat([longitude, latitude]);
      const zoom = latitude !== 0 && longitude !== 0 ? 14 : 0;

      return {
        center,
        zoom,
      };
    }

    render() {
      return (
        <WrappedComponent
          onCalculateCenter={this.handleCalculateCentre}
          {...this.props}
        />
      );
    }

  }

  SiteMapCenteringBehaviour.propTypes = {
    markers: PropTypes.array.isRequired,
  };

  SiteMapCenteringBehaviour.displayName = (
    `SiteMapCenteringBehaviour(${getDisplayName(WrappedComponent)})`
  );

  return SiteMapCenteringBehaviour;
};

export default siteMapCenteringBehaviour;
