import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { FILE_UPLOADS_FIELD } from 'helpers/selectors';

const filesActionCreators = {
  fetchFiles: (deviceId, urlParams = {}) => ({
    type: constants.FETCH_FILES_REQUEST,
    payload: {
      deviceId,
      resourceType: FILE_UPLOADS_FIELD,
      endpoint: endpoints.FILE_UPLOADS(deviceId),
      urlParams,
    },
  }),
  fetchFilesSuccess: response => ({
    type: constants.FETCH_FILES_SUCCESS,
    payload: { response },
  }),
  fetchFilesFailure: error => ({
    type: constants.FETCH_FILES_FAILED,
    payload: { error },
  }),
  fetchFilesSuccessPaginated: response => ({
    type: constants.FETCH_FILES_PAGINATED,
    payload: { response },
  }),
};

export default filesActionCreators;
