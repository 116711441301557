import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';

import { Button } from 'components/atomic';
import SettingCard from 'components/SettingCard/SettingCard';
import SettingConfirm from 'components/SettingConfirm/SettingConfirm';

class PathwayRebootDeviceV1 extends PureComponent {

  constructor() {
    super();

    this.handleConfirm = throttle(this.handleConfirm.bind(this), 250);
  }

  handleConfirm() {
    const { resourceId, onReset } = this.props;

    onReset(resourceId);
  }

  render() {
    const { online, title } = this.props;

    const trigger = <Button type="button" primary disabled={!online}>Reboot</Button>;
    const confirm = (
      <SettingConfirm
        trigger={trigger}
        header="Reboot device"
        content={(
          <p>
            Rebooting the device will cause it to go offline
            momentarily. Do you want to continue?
          </p>
        )}
        onConfirm={this.handleConfirm}
        size="mini"
      />
    );

    return (
      <SettingCard title={title} trigger={confirm}>
        <p>
          Perform a soft reboot. The device will go offline temporarily
          until the reboot is complete.
        </p>
      </SettingCard>
    );
  }

}

PathwayRebootDeviceV1.defaultProps = {
  title: '',
};

PathwayRebootDeviceV1.propTypes = {
  online: PropTypes.bool.isRequired,
  resourceId: PropTypes.string.isRequired,
  title: PropTypes.string,
  onReset: PropTypes.func.isRequired,
};

export default PathwayRebootDeviceV1;
