import schrederLogo from 'assets/logos/schreder/navbar-logo.png';
import { breadcrumbs as commonBreadcrumbs } from 'navigation';
import schrederSignInBackground from 'assets/backgrounds/schreder/sign-in-background.png';
import colors from 'themes/colors';

const brandGradient = `linear-gradient(
  to right,
  ${colors.flamingo},
  ${colors.alizarinCrimson},
  ${colors.minsk},
  ${colors.cerulean}
)`;
export default {
  palette: {
    primaryColor: colors.schrederPurple,
  },
  logo: {
    src: schrederLogo,
    alt: 'Schreder logo',
    text: '',
    style: {
      'margin-right': 'auto',
      'margin-left': 'auto',
      'max-height': '160px !important',
    },
    mobileStyle: {
      'max-height': '50px !important',
    },
  },
  favicons: [
    {
      href: '//www.schreder.com/sites/default/files/favicon32.ico',
      sizes: '32x32',
    },
  ],
  breadcrumbs: commonBreadcrumbs,
  signInBackground: {
    background: `url(${schrederSignInBackground})`,
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    'background-size': 'cover',
    'background-repeat': 'no-repeat',
    'background-position': '100% 100%',
  },
  sidebar: {
    '.ui.menu': {
      '.item': {
        color: colors.grey,
      },
    },
    '.ui.menu.vertical': {
      '.active.item': {
        color: colors.schrederPurple,
      },
      md: {
        background: colors.concrete,
      },
    },
  },
  updateNotificationPanel: {
    background: colors.schrederPurple,
    color: colors.white,
    'border-color': colors.schrederPurple,
  },
  notifications: {
    '.ui.message': {
      'box-shadow': 'none',
      '&.success': {
        'box-shadow': `0 0 0 2px ${colors.schrederPurple} inset`,
        'background-color': colors.white,
        color: colors.schrederPurple,
      },
      '&.error': {
        'box-shadow': `0 0 0 2px ${colors.cerulean} inset`,
        'background-color': colors.white,
        color: colors.cerulean,
      },
    },
  },
  buttons: {
    '.ui.button': {
      'border-radius': 0,
      '&.primary': {
        transition: '500ms',
        'background-size': '200% auto',
        'background-image': brandGradient,
        '&:disabled': {
          background: `${brandGradient}!important`,
        },
        '&:hover': {
          'background-position': 'right center',
        },
        '&.basic': {
          'box-shadow': `0 0 0 1px ${colors.flamingo} inset!important`,
          '&:disabled': {
            'background-image': 'none!important',
          },
          color: `${colors.flamingo}!important`,
        },
      },
    },
  },
  tables: {
    listItems: {
      '.marked': {
        'background-color': colors.white,
        '& dt, dd': {
          color: colors.schrederPurple,
        },
      },
    },
    statusIcons: {
      '.model-device': {
        '&.online': {
          color: colors.schrederBlueActive,
        },
        '&.offline': {
          color: colors.schrederOrangeInactive,
        },
        '&.unassociated': {
          color: colors.grey,
        },
      },
      '.model-user': {
        '&.active': {
          color: colors.schrederBlueActive,
        },
        '&.suspended': {
          color: colors.red,
        },
        '&.inactive': {
          color: colors.grey,
        },
        '&.invited': {
          color: colors.schrederOrangeInactive,
        },
      },
    },
  },
  links: {
    color: colors.schrederPurple,
    '&:hover': {
      'text-decoration': 'underline',
    },
    '&.loginLinkStyle': {
      md: {
        color: colors.white,
      },
    },
  },
  forms: {
    '.ui.form': {
      input: {
        'border-radius': 0,
        '&:focus': {
          'border-radius': 0,
          'border-color': colors.schrederPurple,
        },
      },
    },
  },
};
