import { compose } from 'redux';

import withUserPermissions from '../withUserPermissions/withUserPermissionsContainer';
import permissionsPass from '../permissionsPass/permissionsPassContainer';
import permissionsOrRedirect from './permissionsOrRedirect';

export default (condition, Redirect = null) => compose(
  withUserPermissions,
  permissionsPass(condition),
  permissionsOrRedirect(Redirect),
);
