import isNil from 'lodash/isNil';

const transformRGBToPercentage = ({ r, g, b }) => {
  if (isNil(r) || isNil(g) || isNil(b)) {
    return {
      r: 100,
      g: 100,
      b: 100,
    };
  }

  const red = parseFloat((r * 100) / 255).toFixed(0);
  const green = parseFloat((g * 100) / 255).toFixed(0);
  const blue = parseFloat((b * 100) / 255).toFixed(0);

  return {
    r: red,
    g: green,
    b: blue,
  };
};

export default transformRGBToPercentage;
