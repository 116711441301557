import styled from 'styled-components';
import { Accordion } from 'semantic-ui-react';

export default styled(Accordion).attrs({
  styled: true,
})`
  margin: 10px 0;

  .title {
    display: flex;
    align-items: center;
  }

  &.ui.styled {
    width: 100%;
  }
`;
