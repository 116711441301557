import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import { yearOptions } from './constants';

const YearsField = ({ input: { onChange, name }, defaultValue }) => {
  useEffect(() => {
    if (defaultValue) {
      onChange(defaultValue);
    }
  }, [defaultValue, onChange]);

  return (
    <Dropdown
      name={name}
      placeholder="Years"
      selection
      options={yearOptions}
      onChange={(e, { value }) => onChange(value)}
      defaultValue={1}
    />
  );
};

YearsField.defaultProps = {
  defaultValue: null,
};

YearsField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  defaultValue: PropTypes.number,
};

export default YearsField;
