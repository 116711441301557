import { compose } from 'redux';
import { connect } from 'react-redux';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { deviceActionsListSelector, allActionsTypeSelector } from 'helpers/selectors';
import { selectors as schedulingSelectors } from 'application/tenant/console/site/components/structural/SiteSchedulingTab/utilities';
import { tasksActionCreators } from 'application/tenant/utilities';

import {
  extractActions,
  createOptionList,
} from './utilities';

import AvailableActions from './AvailableActions';

export const mapStateToProps = (state, { onActionSelected, selectedDevice }) => {
  const { id: deviceId } = selectedDevice;
  const getDeviceAction = deviceActionsListSelector(state)(deviceId);
  const allActionTypes = allActionsTypeSelector(state);

  const siteActionList = extractActions(getDeviceAction);
  const optionList = createOptionList(siteActionList, allActionTypes);

  return {
    deviceId,
    onActionSelected,
    availableActions: optionList,
    isLoading: schedulingSelectors.fetchingAvailableActionsSelector(state),
    selectedDevice,
  };
};


export default compose(
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    ({ deviceId }) => tasksActionCreators.fetchDeviceActionTypesLists(deviceId),
  ),
)(AvailableActions);
