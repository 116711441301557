import React from 'react';
import PropTypes from 'prop-types';

import InlineEditPage from '../../../ControlUIPage/components/InlineEditPage/InlineEditPageContainer';
import PageNameColorPicker from '../../../ControlUIPage/components/PageNameColorPicker/PageNameColorPickerContainer';
import {
  MenuWrapper,
  StyledInputHolder,
} from './PageTitle.style';

const PageTitle = ({ configure, pageId }) => (
  <MenuWrapper>
    <StyledInputHolder>
      <InlineEditPage
        configure={configure}
        pageId={pageId}
        isInConfigurationPanel
      />
    </StyledInputHolder>
    <PageNameColorPicker configure={configure} pageId={pageId} />
  </MenuWrapper>
);

PageTitle.defaultProps = {
  configure: false,
  pageId: 0,
};

PageTitle.propTypes = {
  configure: PropTypes.bool,
  pageId: PropTypes.number,
};

export default PageTitle;
