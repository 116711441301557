import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

const ThemeProvider = ({ children, theme }) => (
  <StyledThemeProvider theme={theme}>
    {children}
  </StyledThemeProvider>
);

ThemeProvider.defaultProps = {
  theme: {},
};

ThemeProvider.propTypes = {
  children: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

export default ThemeProvider;
