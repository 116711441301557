import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/atomic';
import ConfirmModal from 'components/elements/ConfirmModal/ConfirmModal';
import DataTableActionButtonLink from 'components/DataTable/DataTableActionButtonLink/DataTableActionButtonLink';

function RemoveProjectActionLink({
  projectSuiteId,
  item,
  onRemoveProjectFromProjectSuite,
}) {
  const { name } = item;

  const handleConfirm = useCallback(() => {
    const { id: projectId, name: projectName } = item;

    onRemoveProjectFromProjectSuite({
      projectId,
      projectName,
      projectSuiteId,
    });
  }, [onRemoveProjectFromProjectSuite, item, projectSuiteId]);

  return (
    <ConfirmModal
      trigger={<DataTableActionButtonLink title="Unlink from Multi-site" />}
      confirmButton={<Button negative primary={false}>Unlink from Multi-site</Button>}
      content={(
        <div>
          <p>
            Are you sure you want to unlink the Site <strong>{name}</strong> from this Multi-site?
          </p>
          <p>
            Enter the name of the Site to confirm:
          </p>
        </div>
      )}
      header="Unlink from Multi-site"
      cancelButton="Cancel"
      onConfirm={handleConfirm}
      validationString={name}
      validationMessage="Enter the name of the Site to confirm"
    />
  );
}

RemoveProjectActionLink.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onRemoveProjectFromProjectSuite: PropTypes.func.isRequired,
  projectSuiteId: PropTypes.string.isRequired,
};

export default RemoveProjectActionLink;
