import constants from 'dispatcherConst';

const initialState = {};

const uploadsWorkersReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case constants.SET_UPLOAD_WORKER: {
      const { fileKey, worker } = payload;

      return { ...state, [fileKey]: worker };
    }

    case constants.UPLOAD_CANCEL_SUCCESS:
    case constants.UPLOAD_SUCCESS: {
      const { fileKey } = payload;
      const { [fileKey]: deleted, ...newState } = state;

      return newState;
    }

    default:
      return state;
  }
};

export default uploadsWorkersReducer;
