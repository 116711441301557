import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { List, Header, Icon } from 'semantic-ui-react';

import { VoucherInput, VoucherDetails } from 'application/tenant/console/site/components/structural/SiteSubscriptionTab/components/ManageSubscription/components';

import { VoucherAlert } from './components';
import { Container, StyledLink, StyledVoucherInputListContent } from './VoucherRedemption.style';

const VoucherRedemption = ({ onClose }) => {
  const [voucherAlertVisible, setVoucherAlertVisible] = useState(false);

  const handleFinishSiteCreation = useCallback(() => {
    setVoucherAlertVisible(true);
  }, []);

  return (
    <Container>
      {!voucherAlertVisible && (
        <>
          <Header as="h2">
            Redeem Voucher for a new Site
          </Header>
          <List divided verticalAlign="middle">
            <List.Item>
              <StyledVoucherInputListContent>
                <div>
                  <p>If you have a voucher please validate it in the box below.</p>
                </div>
                <VoucherInput />
              </StyledVoucherInputListContent>
              <List.Content>
                <VoucherDetails
                  hideProjectedRenewalDate
                  requireSiteInfo
                  onFinishSiteCreation={handleFinishSiteCreation}
                />
              </List.Content>
            </List.Item>
          </List>
        </>
      )}
      <VoucherAlert isVisible={voucherAlertVisible} />
      <StyledLink name="login-link" onClick={onClose}>
        <Icon name="arrow left" />
        Back to Login
      </StyledLink>
    </Container>
  );
};

VoucherRedemption.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default VoucherRedemption;
