import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const fetchServiceVersions = () => ({
  type: constants.FETCH_SERVICE_VERSIONS,
  payload: { endpoint: endpoints.SERVICE_VERSIONS },
});

export const fetchServiceVersionsSuccess = data => ({
  type: constants.FETCH_SERVICE_VERSIONS_SUCCESS,
  payload: { data },
});

export const fetchServiceVersionsFailure = error => ({
  type: constants.FETCH_SERVICE_VERSIONS_FAILED,
  payload: { error },
});
