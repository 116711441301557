import { createSelector } from 'reselect';
import build from 'redux-object';
import orderBy from 'lodash/orderBy';

import NotificationEventModel from 'models/NotificationEventModel';
import {
  NOTIFICATION_EVENTS_FIELD,
  options,
  dataSelector,
} from 'helpers/selectors';

const notificationEventsSelector = createSelector(
  dataSelector,
  data => {
    const notificationEvents = build(data, NOTIFICATION_EVENTS_FIELD, null, options);

    if (!notificationEvents) {
      return [];
    }

    return orderBy(notificationEvents, ['ocurredAt'], ['desc']).map(
      notificationEvent => (new NotificationEventModel(notificationEvent)),
    );
  },
);

export default notificationEventsSelector;
