import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  USERS_FIELD,
  PROJECTS_FIELD,
  PROJECT_SUITES_FIELD,
  COMPANIES_FIELD,
  LOGICAL_DEVICES_FIELD,
  FILE_UPLOADS_FIELD,
  FIXTURE_GROUPS_FIELD,
  TRIGGERS_FIELD,
  TIMELINES_FIELD,
  STATIC_SCENES_FIELD,
  ZONES_FIELD,
  SNAPSHOTS_FIELD,
  CONTEXTS_FIELD,
  IO_MODULES_INSTANCES_FIELD,
  SHOW_COMPANIES_COMPONENT,
  TASKS_FIELD,
  AUTOMATED_OPERATIONS_FIELD,
  SELECT_TASKS,
  JAMBOX_TIMELINES_FIELD,
  REMOTE_DEVICES_FIELD,
  UNJOINED_REMOTE_DEVICES_FIELD,
  NOTIFICATION_EVENTS_FIELD,
  DEVICE_FIRMWARES_FIELD,
  DEVICE_FIRMWARE_FILES_FIELD,
  paginatedUsersSelector,
  paginatedProjectsSelector,
  paginatedProjectSuitesSelector,
  paginatedCompaniesSelector,
  paginatedLogicalDevicesSelector,
  paginatedTimelinesSelector,
  paginatedStaticScenesSelector,
  paginatedFileUploadsSelector,
  paginatedTriggersSelector,
  paginatedFixtureGroupsSelector,
  pageParamsSelector,
  paginationFetchingSelector,
  paginationRefetchSelector,
  paginatedZonesSelector,
  paginatedSnapshotsSelector,
  paginatedContextsSelector,
  paginatedIoModulesSelector,
  paginatedTasksSelector,
  paginatedAutomatedOperationsSelector,
  paginatedJamboxTimelinesSelector,
  paginatedRemoteDevicesSelector,
  paginatedUnjoinedRemoteDevicesSelector,
  paginatedNotificationEventsSelector,
  paginatedDeviceFirmwaresSelector,
  paginatedFirmwareFilesSelector,
} from 'helpers/selectors';
import paginationActionCreators from 'data/paginationActionCreators';
import withPagination from './withPagination';

const pageSelectorMap = {
  [USERS_FIELD]: paginatedUsersSelector,
  [PROJECTS_FIELD]: paginatedProjectsSelector,
  [PROJECT_SUITES_FIELD]: paginatedProjectSuitesSelector,
  [COMPANIES_FIELD]: paginatedCompaniesSelector,
  [LOGICAL_DEVICES_FIELD]: paginatedLogicalDevicesSelector,
  [FILE_UPLOADS_FIELD]: paginatedFileUploadsSelector,
  [TRIGGERS_FIELD]: paginatedTriggersSelector,
  [TIMELINES_FIELD]: paginatedTimelinesSelector,
  [FIXTURE_GROUPS_FIELD]: paginatedFixtureGroupsSelector,
  [STATIC_SCENES_FIELD]: paginatedStaticScenesSelector,
  [ZONES_FIELD]: paginatedZonesSelector,
  [SNAPSHOTS_FIELD]: paginatedSnapshotsSelector,
  [CONTEXTS_FIELD]: paginatedContextsSelector,
  [IO_MODULES_INSTANCES_FIELD]: paginatedIoModulesSelector,
  [SHOW_COMPANIES_COMPONENT]: paginatedCompaniesSelector,
  [TASKS_FIELD]: paginatedTasksSelector,
  [AUTOMATED_OPERATIONS_FIELD]: paginatedAutomatedOperationsSelector,
  [SELECT_TASKS]: paginatedTasksSelector,
  [JAMBOX_TIMELINES_FIELD]: paginatedJamboxTimelinesSelector,
  [REMOTE_DEVICES_FIELD]: paginatedRemoteDevicesSelector,
  [UNJOINED_REMOTE_DEVICES_FIELD]: paginatedUnjoinedRemoteDevicesSelector,
  [NOTIFICATION_EVENTS_FIELD]: paginatedNotificationEventsSelector,
  [DEVICE_FIRMWARES_FIELD]: paginatedDeviceFirmwaresSelector,
  [DEVICE_FIRMWARE_FILES_FIELD]: paginatedFirmwareFilesSelector,
};

const mapStateToProps = (state, { pagination = {}, data: dataProp }) => {
  const { type, componentName, args } = pagination;
  const argsToPass = { ...args, componentName };

  const getType = componentName || type;

  const pageSelector = pageSelectorMap[getType];
  const data = dataProp !== undefined
    ? dataProp
    : (pageSelector && pageSelector(state, argsToPass));

  return {
    data,
    fetching: paginationFetchingSelector(state)(getType),
    page: pageParamsSelector(state)(getType),
    refetch: paginationRefetchSelector(state)(getType),
    componentName,
  };
};

const mapDispatchToProps = {
  onRefetch: paginationActionCreators.refetch,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withPagination,
);
