import React from 'react';
import PropTypes from 'prop-types';

import { RenderSiteStatusIcon, RenderDevicesTable } from './components';
import { getStatusText } from './utilities';
import { StyledTitle, StyledP } from './MarkerDevicesTable.style';

const MarkerDevicesTable = ({
  name: siteName,
  id: siteId,
  type,
  location,
  history,
  isProjectSuite,
}) => {
  const { pathname: currentPath } = location;
  const pathname = `${currentPath}/${siteId}`;

  const handleTitleClick = isProjectSuite ? null : () => history.push({ pathname });

  return (
    <>
      <StyledTitle
        onClick={handleTitleClick}
        isProjectSuite={isProjectSuite}
      >
        {siteName}
        <RenderSiteStatusIcon type={type} />
      </StyledTitle>
      <StyledP>{getStatusText(type)}</StyledP>
      {type !== null && <RenderDevicesTable siteId={siteId} isProjectSuite={isProjectSuite} />}
    </>
  );
};

MarkerDevicesTable.defaultProps = {
  type: null,
  isProjectSuite: false,
};

MarkerDevicesTable.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
  isProjectSuite: PropTypes.bool,
};

export default MarkerDevicesTable;
