import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  permissionsProjectActiveUserIdsSelector,
} from 'helpers/selectors';
import { PermissionsGridHeaders } from '../../../../components';
import { DeviceUserLabel } from '../UserLabel';

export const makeMapState = () => function mapStateToProps(state, { projectId }) {
  const userIds = permissionsProjectActiveUserIdsSelector(state, { projectId });

  return {
    userIds,
    UserLabel: DeviceUserLabel,
  };
};

export default compose(
  connect(makeMapState),
)(PermissionsGridHeaders);
