import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

import constants from 'dispatcherConst';
import { PermissionsSwitch, Gateway, frontendPermissions } from 'gateway';
import { Unauthorized } from 'application/components';
import { TASKS_FIELD } from 'helpers/selectors';
import ListOfTasks from 'application/tenant/console/site/components/structural/ProjectTasksTab/components/ListOfTasks/ListOfTasksProjectSuiteContainer';

import { SelectSiteTasksDropdown } from './components';

const ProjectSuiteTasksTab = ({ options, projectIdsList }) => {
  const [filter, setFilter] = useState(false);

  return (
    <PermissionsSwitch>
      <Gateway condition={frontendPermissions.PROJECT_SUITE_TASKS_VIEW}>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <SelectSiteTasksDropdown
                setFilter={setFilter}
                filter={filter}
                options={options}
                field={TASKS_FIELD}
                requestType={constants.FETCH_PROJECT_SUITE_PROJECTS_TASKS_REQUEST}
              />
              {!isEmpty(projectIdsList) && <ListOfTasks projectsList={projectIdsList} />}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Gateway>
      <Gateway fallback>
        <Unauthorized message="You do not have the required permissions to view Tasks." />
      </Gateway>
    </PermissionsSwitch>
  );
};

ProjectSuiteTasksTab.defaultProps = {
  options: [],
  projectIdsList: [],
};

ProjectSuiteTasksTab.propTypes = {
  options: PropTypes.array,
  projectIdsList: PropTypes.array,
};

export default ProjectSuiteTasksTab;
