import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

class DropdownMultiField extends Component {

  constructor() {
    super();

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, { value }) {
    const {
      input: { onChange },
      onChangeCallback,
      maxCount,
      onMaxSelection,
    } = this.props;
    const valueLength = value.length;

    if (valueLength <= maxCount) {
      onChange(value);
      if (onChangeCallback) {
        onChangeCallback(value);
      }
    } else if (onMaxSelection) {
      onMaxSelection();
    }
  }

  render() {
    const {
      input,
      meta,
      initialValue,
      onChangeCallback,
      onMaxSelection,
      maxCount,
      ...rest
    } = this.props;
    if (input.value === '') delete input.value;

    return (
      <Dropdown
        {...input}
        {...rest}
        onChange={this.handleChange}
        selectOnBlur={false}
        selectOnNavigation={false}
        fluid
        multiple
        selection
      />
    );
  }

}

DropdownMultiField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default DropdownMultiField;
