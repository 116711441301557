import isEmpty from 'lodash/isEmpty';

const validateReviewStep = taskSchedulerForm => {
  if (!taskSchedulerForm) {
    return false;
  }

  const { syncErrors } = taskSchedulerForm || {};

  if (syncErrors) {
    return false;
  }

  const { name } = taskSchedulerForm.values || {};

  return !isEmpty(name);
};

export default validateReviewStep;
