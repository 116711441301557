import constants from 'dispatcherConst';
import { messages } from 'data/notifications/notificationsConst';

const unableToFetchProjectSuiteLocation = projectSuiteId => ({
  type: constants.PROJECT_SUITE_UNABLE_TO_FETCH_LOCATION,
  payload: {
    projectSuiteId,
    info: messages.ERROR_UNABLE_TO_FETCH_LOCATION,
  },
});

export default unableToFetchProjectSuiteLocation;
