import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Icon } from 'semantic-ui-react';
import { Field } from 'redux-form';
import moment from 'moment';

import FormFeedback from 'components/form/FormFeedback/FormFeedback';
import {
  FormFieldLabel,
  FormFieldWrapper,
} from 'components/form/FormField/FormField.style';
import ModalForm from 'components/form/ModalForm/ModalForm';
import DateField from 'components/form/DateField/DateField';
import TextField from 'components/form/TextField/TextField';
import * as validators from 'components/form/formValidators';
import {
  TokenButton,
  AdvancedTimeMode,
  TimeMaskText,
} from './components';
import AstroFieldFormInput from '../../AstroFieldFormInput/AstroFieldFormInputContainer';
import SectionFormTitle from '../../SectionFormTitle/SectionFormTitle';
import {
  StyledInputWrapper,
  StyledFormGroup,
  StyledTimeField,
  StyledPlusButton,
  StyledFormFieldWrapper,
} from './AddScheduleModalForm.style';
import scheduleFormFields from './constants';

class AddScheduleModalForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      openCalendar: false,
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.removeDate = this.removeDate.bind(this);
    this.addTime = this.addTime.bind(this);
    this.removeTime = this.removeTime.bind(this);
    this.openCalendar = this.openCalendar.bind(this);
  }

  handleDateChange(e, value) {
    const { change } = this.props;
    const { selectedDates } = this.props;
    const datesArray = selectedDates ? selectedDates.split(',') : [];
    const date = moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD');
    const checkDuplicate = datesArray.indexOf(date) === -1;

    if (checkDuplicate) {
      const sortedDates = [...datesArray, date].sort();
      change(scheduleFormFields.selectedDates, sortedDates.join(','));
    }
  }

  removeDate(e, value) {
    e.preventDefault();
    const { change, selectedDates } = this.props;
    const datesArray = selectedDates ? selectedDates.split(',') : [];
    const indexToRemove = datesArray.indexOf(value);
    datesArray.splice(indexToRemove, 1);
    change(scheduleFormFields.selectedDates, datesArray.join(','));
  }

  addTime(e) {
    e.preventDefault();
    const {
      change,
      selectedTimes,
      userTime,
    } = this.props;
    const timesArray = selectedTimes ? selectedTimes.split(',') : [];
    const checkDuplicate = timesArray.indexOf(userTime.toString()) === -1;
    if (checkDuplicate) {
      const sortedTimes = [...timesArray, userTime].map(item => parseInt(item, 10))
        .sort((a, b) => a - b);
      change(scheduleFormFields.selectedTimes, sortedTimes.join(','));
    }
  }

  removeTime(e, value) {
    e.preventDefault();
    const { change, selectedTimes } = this.props;
    const timesArray = selectedTimes ? selectedTimes.split(',') : [];
    const indexToRemove = timesArray.indexOf(value);
    timesArray.splice(indexToRemove, 1);
    change(scheduleFormFields.selectedTimes, timesArray.join(','));
  }

  openCalendar(value) {
    const openCalendar = value ? 'date' : false;
    this.setState({ openCalendar });
  }

  render() {
    const {
      props,
    } = this;
    const {
      projectName,
      selectedDates,
      selectedTimes,
      advancedMode,
      submitting,
      submitFailed,
      invalid,
      hours,
      minutes,
      seconds,
      sunrise,
      sunset,
      change,
      form,
    } = this.props;
    const addSchedule = `Add Schedule on site ${projectName}`;
    const labelText = 'Selected dates';
    const datesArray = selectedDates ? selectedDates.split(',') : [];
    const timesArray = selectedTimes ? selectedTimes.split(',') : [];
    const { openCalendar } = this.state;
    const selectedDatesArgs = {
      submitFailed,
      invalid,
      value: selectedDates,
    };
    const selectedTimesArgs = {
      submitFailed,
      invalid,
      selectedTimes,
      hours,
      minutes,
      seconds,
      advancedMode,
      sunrise,
      sunset,
    };

    const selectedDatesErrors = validators.validSelectedDates(selectedDatesArgs);
    const selectedTimesErrors = validators.validSelectedTimes(selectedTimesArgs);
    const showSelectedDatesError = selectedDatesErrors !== undefined;
    const showSelectedTimesError = selectedTimesErrors !== undefined;

    return (
      <ModalForm
        header={addSchedule}
        trigger="Add Schedule"
        size="small"
        triggerSize="mini"
        {...props}
      >
        <FormGroup inline>
          <Field
            name={scheduleFormFields.name}
            component={TextField}
            type="text"
            label="Schedule name"
            validate={validators.validDisplayName}
            loading={submitting}
          />
        </FormGroup>
        <FormGroup inline>
          <Field
            name={scheduleFormFields.description}
            component={TextField}
            type="text"
            label="Description"
            loading={submitting}
          />
        </FormGroup>
        <FormGroup inline>
          <StyledFormFieldWrapper width={16}>
            <FormFieldLabel htmlFor="input">Add date(s)</FormFieldLabel>
            <StyledInputWrapper>
              <Field
                name={scheduleFormFields.date}
                placeholder="Date"
                component={DateField}
                label={labelText}
                onChange={this.handleDateChange}
                min={moment().subtract(1, 'days').toDate()}
                onSelect={this.openCalendar}
                onToggle={this.openCalendar}
                open={openCalendar}
                footer={false}
                dropUp
              />
              {datesArray.map(item => (
                <TokenButton key={item} value={item} removeToken={this.removeDate} />
              ))}
              <FormFeedback
                visible={showSelectedDatesError}
                items={selectedDatesErrors}
                negative
              />
            </StyledInputWrapper>
          </StyledFormFieldWrapper>
        </FormGroup>
        <StyledFormGroup inline>
          <Field
            name={scheduleFormFields.selectedDates}
            component={TextField}
            type="text"
            label="SelectedDates"
            validate={validators.required}
          />
        </StyledFormGroup>
        <SectionFormTitle title="Add time(s)" />
        <FormGroup inline>
          <FormFieldWrapper width={16}>
            <FormFieldLabel htmlFor="input">Real time</FormFieldLabel>
            <StyledInputWrapper>
              <Field
                name={scheduleFormFields.time}
                component={StyledTimeField}
                id={scheduleFormFields.time}
                type="text"
                label="Select time"
              />
              <StyledPlusButton onClick={this.addTime} icon>
                <Icon name="plus" />
              </StyledPlusButton>
              {timesArray.map(item => (
                <TokenButton key={item} value={item} removeToken={this.removeTime} isTime />
              ))}
              <FormFeedback
                visible={showSelectedTimesError}
                items={selectedTimesErrors}
                negative
              />
            </StyledInputWrapper>
          </FormFieldWrapper>
        </FormGroup>
        <StyledFormGroup inline>
          <Field
            name={scheduleFormFields.selectedTimes}
            component={TextField}
            type="text"
            label="Selected Times"
          />
        </StyledFormGroup>
        <AstroFieldFormInput
          astroFieldName="sunrise"
          form={form}
          changeFormInput={change}
          maxSelection={5}
        />
        <AstroFieldFormInput
          astroFieldName="sunset"
          form={form}
          changeFormInput={change}
          maxSelection={5}
        />
        <AdvancedTimeMode
          advancedMode={advancedMode}
          loading={submitting}
        />
        <TimeMaskText
          advancedMode={advancedMode}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
          selectedTimes={selectedTimes}
        />
      </ModalForm>
    );
  }

}

AddScheduleModalForm.defaultProps = {
  selectedDates: '',
  selectedTimes: '',
  advancedMode: false,
  userTime: null,
  projectName: null,
  hours: null,
  minutes: null,
  seconds: null,
  sunset: null,
  sunrise: null,
};

AddScheduleModalForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  selectedDates: PropTypes.string,
  selectedTimes: PropTypes.string,
  advancedMode: PropTypes.bool,
  submitFailed: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  userTime: PropTypes.number,
  projectName: PropTypes.string,
  form: PropTypes.string.isRequired,
  hours: PropTypes.string,
  minutes: PropTypes.string,
  seconds: PropTypes.string,
  sunset: PropTypes.string,
  sunrise: PropTypes.string,
};

export default AddScheduleModalForm;
