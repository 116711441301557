import {
  submitOneTimePassword,
  submitOneTimePasswordSuccess,
  submitOneTimePasswordFailure,
} from './submitOneTimePassword';

export default {
  submitOneTimePassword,
  submitOneTimePasswordSuccess,
  submitOneTimePasswordFailure,
};
