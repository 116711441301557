import React from 'react';
import PropTypes from 'prop-types';

import PatchModel from 'models/PatchModel';

import { ProtocolDropdown, UniverseDropdown, OutputRefreshInterface } from './components';
import Container from './OutputSectionForm.style';

export default function OutputSectionForm({
  deviceId,
  isLoading,
  patches,
  selectedProtocol,
  selectedUniverse,
  onProcotolChange,
  onUniverseChange,
  onLiveUpdatesChange,
}) {
  const handleProtocolChange = protocol => onProcotolChange(protocol);

  const handleUniverseChange = universe => onUniverseChange(universe);

  return (
    <Container>
      <ProtocolDropdown
        isLoading={isLoading}
        patches={patches}
        selectedProtocol={selectedProtocol}
        onProcotolChange={handleProtocolChange}
      />
      <UniverseDropdown
        isLoading={isLoading}
        patches={patches}
        selectedProtocol={selectedProtocol}
        selectedUniverse={selectedUniverse}
        onUniverseChange={handleUniverseChange}
      />
      <OutputRefreshInterface
        selectedProtocol={selectedProtocol}
        selectedUniverse={selectedUniverse}
        deviceId={deviceId}
        dataId="dmx_output"
        meta={{
          protocolId: selectedProtocol,
          universeId: selectedUniverse,
        }}
        onLiveUpdatesChange={onLiveUpdatesChange}
      />
    </Container>
  );
}

OutputSectionForm.defaultProps = {
  patches: null,
  selectedProtocol: null,
  selectedUniverse: null,
};

OutputSectionForm.propTypes = {
  deviceId: PropTypes.string.isRequired,
  patches: PropTypes.arrayOf(PropTypes.instanceOf(PatchModel)),
  isLoading: PropTypes.bool.isRequired,
  selectedProtocol: PropTypes.number,
  selectedUniverse: PropTypes.number,
  onProcotolChange: PropTypes.func.isRequired,
  onUniverseChange: PropTypes.func.isRequired,
  onLiveUpdatesChange: PropTypes.func.isRequired,
};
