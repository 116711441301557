import styled, { css } from 'styled-components';
import { Header } from 'semantic-ui-react';

export const StyledControlUIWrapper = styled.div`
  ${({ configure }) => !configure && css`
    height: 100%;
  `}
`;

export const StyledControlUIHeader = styled.div`
  margin-bottom: 24px;
`;

export const StyledControlUISubHeader = styled(Header.Subheader)`
  margin-top: 8px !important;
`;

export default StyledControlUIWrapper;
