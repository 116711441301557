import React from 'react';
import PropTypes from 'prop-types';

import { StyledAddProjectSuiteButton } from './AddProjectSuiteButton.style';

const AddProjectButton = ({ selectingProjectSuites, onToggleSelectingProjectSuites }) => (
  <StyledAddProjectSuiteButton onClick={onToggleSelectingProjectSuites}>
    {selectingProjectSuites ? 'Done' : 'Add Multi-site...'}
  </StyledAddProjectSuiteButton>
);

AddProjectButton.propTypes = {
  selectingProjectSuites: PropTypes.bool.isRequired,
  onToggleSelectingProjectSuites: PropTypes.func.isRequired,
};

export default AddProjectButton;
