import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const RenderTimesList = ({
  maskTimes,
  selectedTimesList,
  showSelectedTimes,
}) => (
  <p>The schedule will run at:
    {maskTimes.map(item => ` ${item} `)}
    {showSelectedTimes && (
      <Fragment>
        and hours specified in AddTime(s):
        {selectedTimesList.map(item => ` ${item} `)}
      </Fragment>
    )}
  </p>
);

RenderTimesList.defaultProps = {
  maskTimes: [],
  selectedTimesList: [],
  showSelectedTimes: false,
};

RenderTimesList.propTypes = {
  maskTimes: PropTypes.array,
  selectedTimesList: PropTypes.array,
  showSelectedTimes: PropTypes.bool,
};

export default RenderTimesList;
