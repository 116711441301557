export const formats = {
  BOOLEAN: 'boolean',
  DATE: 'date',
  SECONDS: 'seconds',
};

export default {
  triggers: [
    { text: 'Number', field: 'number', sortable: false, width: 1 },
    { text: 'Name', field: 'name', sortable: false, width: 4 },
    { text: 'Description', field: 'description', sortable: false, width: 9 },
    { text: '', field: '', sortable: false, width: 2 },
  ],
  reported: {
    cfg: [
      { text: 'Name', field: 'nm' },
      { text: 'File name', field: 'fn' },
      { text: 'File size', field: 'sz' },
      { text: 'unique ID of project', field: 'id' },
      { text: 'Author', field: 'au' },
      { text: 'Date/time of last upload', field: 'up', format: formats.DATE },
      { text: 'Logical device number within configuration', field: 'ldnum' },
      { text: 'Logical device name within configuration', field: 'ldnm' },
    ],
    clk: [
      { text: 'Current date/time', field: 'now', format: formats.DATE, autoincrement: true },
      { text: 'GMT offset', field: 'tz' },
      { text: 'NTP server address', field: 'ntp' },
      { text: 'Today\'s sunrise time (calculated)', field: 'sunr', format: formats.DATE },
      { text: 'Today\'s sunset time (calculated)', field: 'suns', format: formats.DATE },
    ],
    hardware: [
      { text: 'Model', field: 'md' },
      { text: 'Serial number', field: 'sn' },
      { text: 'Total memory', field: 'memt' },
      { text: 'Used memory', field: 'memu' },
      { text: 'Free memory', field: 'memf' },
      { text: 'Data storage', field: 'sd' },
      { text: 'Boot reason', field: 'br' },
      { text: 'Last boot', field: 'bl', format: formats.DATE },
      { text: 'Uptime', field: 'up', format: formats.SECONDS, autoincrement: true },
      { text: 'Watchdog on/off', field: 'wd', format: formats.BOOLEAN },
    ],
    log: [
      { text: 'Log level', field: 'lvl' },
    ],
    net: [
      { text: 'Name', field: 'nm' },
      { text: 'MAC', field: 'mac' },
      { text: 'IPv4 address', field: 'ipv4' },
      { text: 'IPv6 address', field: 'ipv6' },
      { text: 'Subnet mask', field: 'sub' },
      { text: 'Default gateway address', field: 'gw' },
      { text: 'Name server addresses', field: 'ns' },
    ],
    software: [
      { text: 'Firmware version', field: 'fw' },
      { text: 'Bootloader version', field: 'bl' },
      { text: 'OS version', field: 'os' },
    ],
  },
};
