import { PERFORM_ACTION_FIELD } from 'helpers/selectors';
import ApiRequestBuilder from 'helpers/ApiRequestBuilder';

class PerformActionModel {

  static requestBody(values) {
    return new ApiRequestBuilder(PERFORM_ACTION_FIELD)
      .setAttributes(values);
  }

}

export default PerformActionModel;
