import React from 'react';

class BreadcrumbModel {

  constructor(path, breadcrumb, props = null) {
    this.path = path;
    this.breadcrumbComponent = breadcrumb;
    this.props = props;
  }

  get breadcrumb() {
    const { breadcrumbComponent: Breadcrumb, props: extraProps } = this;

    if (extraProps) {
      return props => <Breadcrumb {...props} {...extraProps} />;
    }

    return Breadcrumb;
  }

}

export default BreadcrumbModel;
