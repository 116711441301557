import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import { Dimmer, Loader, Message } from 'semantic-ui-react';

import constants from 'dispatcherConst';
import {
  SelectProjectSuitesDropdown,
  SelectSiteTasksDropdown,
} from 'pages/ProjectSuite/ProjectSuiteTasksTab/components';
import { usePrevious } from 'helpers/hooks';
import { AUTOMATED_OPERATIONS_OCCURRENCES_FIELD } from 'helpers/selectors';

import { urlDateFormat, MONTH } from '../../constants';
import { parseDateFromUrl, setUrlDate, monthDateRange } from '../../utilities';
import { DayViewTable, CalendarView } from './components';
import {
  jsDateToDateTime,
  isDateGreaterThanOneYear,
  compareSelectedValues,
} from './utilities';
import { OCCURRENCES_WARNING } from './constants';

import { StyledDayViewWrapper, StyledDimmer } from './DayView.style';

const DayView = ({
  resourceId,
  resourceType,
  resourceTime,
  updating,
  timezoneSet,
  history,
  location,
  onFetchOccurrences,
  superSite,
  options,
  selectedProjects,
  assignedToMultiSites,
  selectedProjectSuites,
  projectsList,
  showHidden,
  onToggleShowHiddenFilter,
  userHasPermissionOrSuperadmin,
  hideMultiSiteOccurrences,
  onToggleShowHideMultiSiteOccurrences,
}) => {
  const [filter, setFilter] = useState(false);
  const [selectedDate, setSelectedDate] = useState(() => {
    if (!timezoneSet) {
      return null;
    }

    if (!resourceTime) {
      return null;
    }

    const initialDate = parseDateFromUrl({ defaultTime: resourceTime });

    return initialDate;
  });

  const previousDate = usePrevious(selectedDate);
  const previousSelectedProjects = usePrevious(selectedProjects);
  const previousSelectedProjectSuites = usePrevious(selectedProjectSuites);
  const previousProjectsList = usePrevious(projectsList);
  const previousShowHidden = usePrevious(showHidden);
  const previousHideMultiSiteOccurrences = usePrevious(hideMultiSiteOccurrences);

  const displayOccurrencesWarning = useMemo(() => {
    if (!selectedDate || !resourceTime) {
      return false;
    }

    const { zone } = resourceTime;

    return isDateGreaterThanOneYear(selectedDate, zone);
  }, [selectedDate, resourceTime]);

  const filterParams = useMemo(() => {
    if (!selectedDate) {
      return null;
    }

    const { start, end } = monthDateRange(selectedDate);

    const params = {
      recipientType: resourceType,
      recipientId: resourceId,
      'filter[dateFrom]': start.toFormat(urlDateFormat),
      'filter[dateTo]': end.toFormat(urlDateFormat),
    };

    if (resourceType === 'project') {
      params['projectIds[]'] = [resourceId];
      if (
        userHasPermissionOrSuperadmin
        && assignedToMultiSites
        && !isEmpty(selectedProjectSuites)
      ) {
        params['projectIds[]'] = [resourceId];
        params['projectSuiteIds[]'] = [...selectedProjectSuites];
      }

      if (!userHasPermissionOrSuperadmin) {
        params['projectIds[]'] = [resourceId];
        params['projectSuiteIds[]'] = hideMultiSiteOccurrences ? '' : '*';
      }
    } else {
      params['projectSuiteIds[]'] = [resourceId];

      if (isNil(selectedProjects)) {
        params['projectIds[]'] = [...projectsList];
      }
    }

    if (!isNil(selectedProjects) && !isEmpty(selectedProjects) && superSite) {
      params['projectIds[]'] = [...selectedProjects];
      params['projectSuiteIds[]'] = [resourceId];
    }

    params['filter[showHidden]'] = showHidden;

    return params;
  }, [
    resourceId,
    resourceType,
    selectedDate,
    selectedProjects,
    superSite,
    selectedProjectSuites,
    assignedToMultiSites,
    projectsList,
    showHidden,
    userHasPermissionOrSuperadmin,
    hideMultiSiteOccurrences,
  ]);

  const handleDateChange = useCallback((newDate, callback) => {
    if (!newDate) {
      return;
    }

    const { zone } = resourceTime;
    const formattedDate = newDate.isValid ? newDate : jsDateToDateTime(newDate, zone);

    setSelectedDate(formattedDate);
    setUrlDate(formattedDate, { history, location });

    if (callback && isFunction(callback)) {
      callback();
    }
  }, [resourceTime, history, location]);

  const handleToggleOccurrences = useCallback(() => {
    onToggleShowHiddenFilter(!showHidden);
  }, [onToggleShowHiddenFilter, showHidden]);

  const handleToggleShowHideMultiSiteOccurrences = useCallback(() => {
    onToggleShowHideMultiSiteOccurrences(!hideMultiSiteOccurrences);
  }, [onToggleShowHideMultiSiteOccurrences, hideMultiSiteOccurrences]);

  useEffect(() => {
    if (!selectedDate) {
      return;
    }

    const isDifferentMonth = !previousDate || !selectedDate.hasSame(previousDate, MONTH);
    const sameProjectSuites = isEqual(
      sortBy(previousSelectedProjectSuites), sortBy(selectedProjectSuites),
    );
    const sameProjects = compareSelectedValues(previousSelectedProjects, selectedProjects);
    const sameProjectsList = compareSelectedValues(previousProjectsList, projectsList);
    const sameOccurrencesFilter = previousShowHidden === showHidden;
    const sameHideMultiSiteOccurrences = previousHideMultiSiteOccurrences
      === hideMultiSiteOccurrences;

    if (
      (selectedDate && isDifferentMonth)
      || !sameProjects
      || !sameProjectSuites
      || !sameProjectsList
      || !sameOccurrencesFilter
      || !sameHideMultiSiteOccurrences
    ) {
      const params = filterParams;

      onFetchOccurrences(params);
    }
  }, [
    selectedDate,
    previousDate,
    filterParams,
    onFetchOccurrences,
    selectedProjects,
    previousSelectedProjects,
    selectedProjectSuites,
    previousSelectedProjectSuites,
    previousProjectsList,
    projectsList,
    showHidden,
    previousShowHidden,
    hideMultiSiteOccurrences,
    previousHideMultiSiteOccurrences,
  ]);

  if (!selectedDate || !resourceTime) {
    return null;
  }

  const localTime = DateTime.local();
  const sameTimezone = resourceTime.offset === localTime.offset;

  return (
    <Dimmer.Dimmable>
      {displayOccurrencesWarning && (
        <Message warning content={OCCURRENCES_WARNING} />
      )}
      {userHasPermissionOrSuperadmin && assignedToMultiSites && !superSite && (
        <SelectProjectSuitesDropdown
          setFilter={setFilter}
          filter={filter}
          options={options}
          field={AUTOMATED_OPERATIONS_OCCURRENCES_FIELD}
          requestType={constants.FETCH_AUTOMATED_OPERATION_OCCURRENCES}
        />
      )}
      {superSite && (
        <SelectSiteTasksDropdown
          setFilter={setFilter}
          filter={filter}
          options={options}
          field={AUTOMATED_OPERATIONS_OCCURRENCES_FIELD}
          requestType={constants.FETCH_AUTOMATED_OPERATION_OCCURRENCES}
        />
      )}
      <StyledDayViewWrapper>
        <DayViewTable
          active={selectedDate}
          sameTimezone={sameTimezone}
          resourceType={resourceType}
          showHidden={showHidden}
          onToggleHiddenOccurrences={handleToggleOccurrences}
          userHasPermissionOrSuperadmin={userHasPermissionOrSuperadmin}
          hideMultiSiteOccurrences={hideMultiSiteOccurrences}
          onToggleShowHideMultiSiteOccurrences={handleToggleShowHideMultiSiteOccurrences}
        />
        <CalendarView
          active={selectedDate}
          onDayClick={handleDateChange}
          onMonthChange={handleDateChange}
          resourceType={resourceType}
        />
      </StyledDayViewWrapper>
      <StyledDimmer active={updating}>
        <Loader size="large">Loading updated events</Loader>
      </StyledDimmer>
    </Dimmer.Dimmable>
  );
};

DayView.defaultProps = {
  resourceTime: null,
  timezoneSet: false,
  resourceType: 'project',
  superSite: false,
  options: [],
  selectedProjects: undefined,
  selectedProjectSuites: [],
  assignedToMultiSites: false,
  projectsList: [],
  showHidden: false,
  userHasPermissionOrSuperadmin: false,
};

DayView.propTypes = {
  resourceId: PropTypes.string.isRequired,
  resourceTime: PropTypes.object,
  timezoneSet: PropTypes.bool,
  onFetchOccurrences: PropTypes.func.isRequired,
  updating: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  resourceType: PropTypes.oneOf([
    'project',
    'projectSuite',
  ]),
  superSite: PropTypes.bool,
  options: PropTypes.array,
  selectedProjects: PropTypes.array,
  selectedProjectSuites: PropTypes.array,
  assignedToMultiSites: PropTypes.bool,
  projectsList: PropTypes.array,
  showHidden: PropTypes.bool,
  onToggleShowHiddenFilter: PropTypes.func.isRequired,
  userHasPermissionOrSuperadmin: PropTypes.bool,
  hideMultiSiteOccurrences: PropTypes.bool.isRequired,
  onToggleShowHideMultiSiteOccurrences: PropTypes.func.isRequired,
};

export default DayView;
