import styled, { css } from 'styled-components';
import { Form, Grid } from 'semantic-ui-react';

import { Button, PortalThemedLinkButton } from 'components/atomic';

export const Container = styled.div`
  padding: 15px 0;
`;

export const DeviceDropdownContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLabel = styled.label`
  margin-right: 8px;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 8px;

  &:not(:first-child) {
    margin-top: 8px;
  }

  ${({ hidden }) => hidden && css`
    display: none;
  `}
`;

export const StyledDropdown = styled(Form.Select)`
  flex: 1;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > button {
    flex: 1 1 auto !important;
    text-align:center !important;
    margin: 5px !important;
  }

  ${({ hidden }) => hidden && css`
    display: none;
  `}
`;

export const StyledButton = styled(Button)`
  &.ui.disabled.button {
    opacity: 0.50 !important;
    box-shadow: 0 0 0 1px rgb(34 36 38 / 15%) inset !important;
  }
`;

export const DateBlockingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

export const StyledMessage = styled.small`
  margin-top: 8px;
  color: #9f3a38;
`;

export const StyledGridRow = styled(Grid.Row)`
  padding-bottom: 0px !important;
`;

export const StyledGridColumn = styled(Grid.Column)`
  margin-top: 8px !important;
`;

export const LinkButton = styled(PortalThemedLinkButton)`
  font-size: 12px;
  padding-top: 12px;
  padding-bottom: 8px;
`;
export default DeviceDropdownContainer;
