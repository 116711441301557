import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const fetchDevicesProjectSuites = (resourceId, urlParams = {}) => ({
  type: constants.FETCH_DEVICES_PROJECT_SUITES_REQUEST,
  payload: {
    resourceId,
    endpoint: endpoints.LOGICAL_DEVICES,
    urlParams,
  },
});

export const fetchDevicesProjectSuitesSuccess = response => ({
  type: constants.FETCH_DEVICES_PROJECT_SUITES_SUCCESS,
  payload: {
    response,
  },
});

export const fetchDevicesProjectSuitesFailure = error => ({
  type: constants.FETCH_DEVICES_PROJECT_SUITES_FAILED,
  payload: { error },
});

export default fetchDevicesProjectSuites;
