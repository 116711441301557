import styled, { css } from 'styled-components';
import { lighten, darken } from 'polished';
import get from 'lodash/get';
import { Header } from 'semantic-ui-react';

import { Button } from 'components/atomic';
import filterProps from 'helpers/filterProps';

export const StyledHeader = styled(Header)`
  cursor: pointer;
`;

export const StyledTable = styled.table`
  &&& {
    border: 0;

    ${({ withSpacing }) => withSpacing && css`
      margin-top: 32px;
    `}
    
    thead tr th {
      text-align: center;
      border: 0;
      background: transparent;
    }

    tbody tr td {
      vertical-align: middle;
      border: 0;
    }
  }  
`;

export const StyledTableRow = styled.tr`  
  ${({ isVisible }) => !isVisible && css`
    display: none !important;
  `}  
`;

export const StyledTableColumn = styled.td`
  font-size: 16px;
  font-weight: 400;
  padding: 12px;
  color: rgba(0,0,0,.6);

  
  ${({ permissionNameColumn }) => permissionNameColumn && css`
    width: 250px;
  `}

  ${({ recipientColumn }) => recipientColumn && css`
    width: 50px;
    text-align: center;
  `}
`;

export const StyledPermissionName = styled.span`
  cursor: pointer;
  
   ${({ level }) => level === 1 && css`
    margin-left: 24px;
  `}

  ${({ level }) => level === 2 && css`
    margin-left: 64px;
  `}

  ${({ level }) => level === 3 && css`
    margin-left: 104px;
  `}
`;

const FilteredProps = filterProps(['status', 'isCopyMode'])(Button);

export const StyledCheckbox = styled(FilteredProps)`
  &.ui.button {
    cursor: pointer;
    border-radius: 10px;
    width: 20px;
    height: 20px;
    background-color: white;
    border: 1px solid lightgrey;
    padding: 0;

    ${({ status, theme }) => status === 'checked' && css`
      background-color: ${get(theme, 'palette.primaryColor')};
    `}

    ${({ status, theme }) => status === 'partial' && css`
      background-color: ${lighten(0.4, get(theme, 'palette.primaryColor'))};
    `}

    ${({ isCopyMode, theme }) => isCopyMode && css`
      border: ${darken(0.2, get(theme, 'palette.primaryColor'))} solid 2px;
    `}
  }
`;

export default StyledPermissionName;
