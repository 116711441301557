import styled, { css } from 'styled-components';
import TimePicker from 'react-time-picker';
import get from 'lodash/get';

export const StyledTimeInput = styled(TimePicker)`
  ${({ theme, fullWidth }) => css`
    &&& {
      &.react-time-picker {
        ${fullWidth && css`
          max-width: none !important; 
          width: 100% !important;
        `}

        input {
          min-width: 0.54em;
          height: 100%;
          position: relative;
          padding: 0 1px;
          border: 0;
          background: none;
          font: inherit;
          box-sizing: content-box;
          -moz-appearance: textfield;

          &:focus {
            background: none;
            border: 2px solid rgb(16, 16, 16);
          }

          &.react-time-picker__inputGroup__input--hasLeadingZero {
            margin-left: -0.54em;
            padding-left: calc(1px + 0.54em);

            &:focus {
              margin-left: -0.74em;
            }
          }
        }
      }

      .react-time-picker__wrapper {
        border-radius: 5px;
        padding: 10px 15px;
        border: 1px solid rgba(34, 36, 38, 0.15);
      }

      .react-time-picker__inputGroup {
        display: flex;
        align-items: center;
      }

      .react-time-picker__inputGroup__input {
        font-size: 1em;
        color: rgba(0, 0, 0, 87);
      }

      .react-time-picker__button {
        svg {
          stroke-width: 1;
          width: 15px;
          height: 15px;

          &:hover {
            stroke: ${get(theme, 'palette.primaryColor')} !important;  
          }
        }
      }

      .react-time-picker__clear-button {
        padding: 0;
        margin-left: 15px;
      }
    }
  `}
`;

export default StyledTimeInput;
