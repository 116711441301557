import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { PermissionsSwitch, Gateway, frontendPermissions } from 'gateway';
import EditTaskModalForm from './EditTaskModalForm/EditTaskModalFormContainer';

class EditTask extends Component {

  constructor() {
    super();

    this.handleEditTask = this.handleEditTask.bind(this);
  }

  handleEditTask(values) {
    const {
      item,
      onEditTask,
    } = this.props;
    const { id, lockVersion } = item;

    return new Promise((resolveForm, rejectForm) => {
      onEditTask({
        id,
        lockVersion,
        ...values,
        resolveForm,
        rejectForm,
      });
    });
  }

  render() {
    const {
      item,
      mobileVersion,
      superSites,
    } = this.props;
    const { id } = item;

    const permission = superSites
      ? frontendPermissions.PROJECT_SUITE_TASKS_EDIT
      : frontendPermissions.EDIT_TASK;

    return (
      <PermissionsSwitch>
        <Gateway condition={permission}>
          <EditTaskModalForm
            onSubmit={this.handleEditTask}
            item={item}
            form={`editTask-${id}`}
            mobileVersion={mobileVersion}
            destroyForm
          />
        </Gateway>
      </PermissionsSwitch>
    );
  }

}

EditTask.defaultProps = {
  item: null,
  mobileVersion: false,
  superSites: false,
};

EditTask.propTypes = {
  onEditTask: PropTypes.func.isRequired,
  item: PropTypes.object,
  mobileVersion: PropTypes.bool,
  superSites: PropTypes.bool,
};

export default EditTask;
