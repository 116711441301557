import styled from 'styled-components';

import colors from 'themes/colors';

export const ScrollableMenuWrapper = styled.div`
  height: 50px;
  margin: 1rem 0;
  overflow: hidden;
`;

export const ScrollableMenu = styled.div`
  height: 70px;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  & .ui.pointing.menu:not(.secondary),
  & .ui.pointing.menu:not(.secondary) {
    border: 0px;
    border-bottom-right-radius: 0px;
    box-shadow: 0 0;
  }

  & .ui.pointing.menu:not(.secondary) .item {
    border-top: solid 1px rgba(34,36,38,.15);
    border-bottom: solid 1px rgba(34,36,38,.15);
  }

  & .ui.pointing.menu:not(.secondary) .item:first-child {
    border-left: solid 1px rgba(34,36,38,.15);
  }
  
  & .ui.secondary.pointing.menu,
  & .ui.secondary.pointing.menu .item:not(.active) {
    border-bottom-color: ${colors.mercury};
  }
`;
