import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isNil from 'lodash/isNil';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { singleProjectSelector } from 'helpers/selectors';

import { actionCreators, selectors } from './utilities';
import PermissionsTable from './PermissionsTable';

export const mapStateToProps = (state, props) => {
  const {
    projectId,
    childResourceIds,
    showHeader,
    context,
    limitedScope,
    isOpen,
  } = props;

  const { name: projectName } = singleProjectSelector(state, projectId);

  return {
    isLoading: selectors.isLoadingSelector(state, projectId),
    isOpen: isOpen || selectors.isOpenSelector(state, projectId),
    cumulatedPermissions: selectors.cumulatedPermissionsSelector(state, projectId),
    permissionsTableRows: selectors.permissionsTableRowsSelector(state, projectId),
    recipients: selectors.recipientsSelector(state, projectId),
    selectedRecipients: selectors.selectedRecipientsSelector(state, projectId),
    copyRecipientId: selectors.copyRecipientIdSelector(state, projectId),

    showHeader,
    resourceId: projectId,
    childResourceIds,
    resourceTitle: 'Site',
    resourceName: projectName,
    resourceDescription: !isNil(context) ? 'All sites' : '',
    resourceType: 'project',
    recipientType: 'user',
    limitedScope: limitedScope || [],
    limitedFor: ['user'],
    context: context || null,
  };
};

const mapDispatchToProps = {
  onFetchCumulatedPermissions: actionCreators.fetchCumulatedPermissions,
  onGrantPermissions: actionCreators.grantMultiplePermissions,
  onRevokePermissions: actionCreators.revokeMultiplePermissions,
  onCopyPermissions: actionCreators.copyMultiplePermissions,
  onPastePermissions: actionCreators.pasteMultiplePermissions,
  onClearPermissionsTable: actionCreators.clearPermissionsTable,
  onToggleResourceOpen: actionCreators.toggleResourceOpen,
  onChangeRecipients: actionCreators.changePermissionsTableRecipients,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  chainedLoaderWithSaga(
    ({
      resourceId,
      resourceType,
      context,
    }) => (
      actionCreators.fetchPermissionsTableRecipients({
        resourceId,
        resourceType,
        context,
      })
    ),
  ),
)(PermissionsTable);
