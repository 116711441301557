import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import onMountUnmount from 'helpers/onMountUnmount';
import websocketActionCreators from 'data/websockets/websocketsActionCreators';
import prepareLogsForTable from 'application/tenant/console/site/components/structural/SiteActivityLoggingTab/components/LogsSection/components/LogsTable/utilities/prepareLogsForTable';
import { translateActionsList } from './components/LogsFilterParams/utilities';
import { actionCreators, selectors } from './components/utilities';
import SiteActivityLoggingTab from './SiteActivityLoggingTab';
import { extractKeyValues } from './utilities';

const AUDIT_LOG = 'audit_log';

export const mapStateToProps = (state, { match }) => {
  const { id: projectId } = match.params;
  const logsData = selectors.logsDataSelector(state, projectId);
  const logsDataForTable = prepareLogsForTable(JSON.parse(logsData));
  const isLoading = selectors.logsAreLoadingSelector(state);
  const logsDataExists = selectors.logsDataExistsSelector(state, projectId);
  const actionTypes = extractKeyValues(logsDataForTable, ['log_data', 'action_type']);
  const initiators = extractKeyValues(logsDataForTable, ['text_data', 'initiator', 'name']);
  const recipients = extractKeyValues(logsDataForTable, ['text_data', 'recipient', 'name']);
  const resources = extractKeyValues(logsDataForTable, ['text_data', 'resource', 'name']);
  const options = {
    actionTypes: translateActionsList(actionTypes),
    initiators,
    recipients,
    resources,
  };

  return {
    logsData: logsDataForTable,
    options,
    isLoading,
    logsDataExists,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  onMountUnmount(
    () => websocketActionCreators.subscribeAuditLogChannel(AUDIT_LOG),
    () => websocketActionCreators.unSubscribeAuditLogChannel(AUDIT_LOG),
  ),
  onMountUnmount(
    ({ match: { params: { id } } }) => actionCreators.addActiveProjectId(id),
    () => actionCreators.clearActiveProjectId(),
  ),
)(SiteActivityLoggingTab);
