import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextArea } from 'semantic-ui-react';
import SparkMD5 from 'spark-md5';

import { StyledTextAreaWrapper, StyledTextArea, StyledValue } from './TextEditField.style';

class TextEditField extends Component {

  constructor() {
    super();

    this.editInput = React.createRef();
    this.handleFocus = this.handleFocus.bind(this);
  }

  handleFocus() {
    const { onFocusCallback } = this.props;

    onFocusCallback(this.editInput.current);
  }

  render() {
    const {
      input,
      readOnly,
      readOnlyValue,
      error,
      onFocusCallback,
      action,
      locked,
      ...rest
    } = this.props;

    const formattedValue = readOnlyValue && readOnlyValue.split('\n').map((segment, index) => {
      const hash = SparkMD5.hash(segment);
      const key = `${index}-${hash}`;

      return (
        <Fragment key={key}>{segment}<br /></Fragment>
      );
    });

    return (
      <StyledTextAreaWrapper>
        <StyledTextArea
          ref={this.editInput}
          {...input}
          {...rest}
          id={input.name}
          readOnly={readOnly}
        />
        {action}
        <StyledValue
          readOnly={readOnly}
          empty={!readOnlyValue}
          onClick={this.handleFocus}
          locked={locked}
        >
          {formattedValue || 'empty'}
        </StyledValue>
      </StyledTextAreaWrapper>
    );
  }

}

TextEditField.defaultProps = {
  error: null,
  readOnlyValue: null,
};

TextEditField.propTypes = {
  ...TextArea.propTypes,
  error: PropTypes.bool,
  readOnly: PropTypes.bool.isRequired,
  readOnlyValue: PropTypes.any,
  onFocusCallback: PropTypes.func.isRequired,
};

export default TextEditField;
