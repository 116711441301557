export default {
  MAILGUN_DOMAINS: '/mailgun_domains',
  // COMPANIES
  COMPANIES: '/companies?include=origins',
  COMPANY: companyId => `/companies/${companyId}`,
  // CURRENT COMPANY
  CURRENT_COMPANY: '/companies/current',
  // PROJECT
  USER_PROJECTS: userId => `/users/${userId}/projects`,
  PROJECT: projectId => `/projects/${projectId}`,
  PROJECTS: '/projects',
  PROJECT_USERS: projectId => `/projects/${projectId}/relationships/users`,
  SITE_AUTOMATED_OPERATION_OCCURRENCES: siteId => `/projects/${siteId}/ao_occurrences`,
  // PROJECT PROJECT SUITES
  PROJECT_PROJECT_SUITES: projectId => `/projects/${projectId}/project_suites`,
  // INVITE USER
  INVITE_USER: '/invite_user',
  INVITATION_DELETE: invitationId => `/invitations/${invitationId}`,
  // TASKS
  TASKS: '/tasks',
  EDIT_TASK: taskId => `/tasks/${taskId}`,
  EXECUTE_TASK: taskId => `/tasks/${taskId}/execute`,
  EXECUTE_ACTIONS: '/actions/execute',
  PROJECT_TASKS: projectId => `/projects/${projectId}/tasks`,
  // ACTION TYPES
  ACTION_TYPES: '/action_types',
  // DEVICE ACTION TYPES LISTS
  DEVICE_ACTION_TYPES_LISTS: logicalDeviceId => `/logical_devices/${logicalDeviceId}/device_action_types_list`,
  // CONTROL UI
  PROJECT_CONTROL_UI: projectId => `/projects/${projectId}/control_panel_page`,
  CONTROL_UI_PAGE: controlId => `/control_panel_pages/${controlId}`,
  // PROJECT FILES
  PROJECT_FILE_UPLOADS: projectId => `/projects/${projectId}/resources_upload`,
  // DEVICE
  CONNECTION_DATA: '/logical_devices/connection_data',
  LOGICAL_DEVICE: deviceId => `/logical_devices/${deviceId}`,
  // DEVICE IO MODULES
  IO_MODULES: deviceId => `/logical_devices/${deviceId}/io_modules`,
  IO_MODULES_INSTANCES: deviceId => `/logical_devices/${deviceId}/io_instances`,
  // TRIGGERS
  LOGICAL_DEVICE_TRIGGERS: deviceId => `/logical_devices/${deviceId}/triggers`,
  // GROUPS
  LOGICAL_DEVICE_FIXTURE_GROUPS: deviceId => `/logical_devices/${deviceId}/fixture_groups`,
  // LOGIN
  LOGIN: '/authenticate',
  CURRENT_USER: '/users/current?include=projects,receivedInvitations.project',
  BACKEND_VERSION: '/version',
  // TWO FACTOR AUTHENTICATION
  TWO_FACTOR_QR_CODE: '/two_factor_secret_key',
  ONE_TIME_PASSWORD: '/two_factor_authenticate',
  // RESET PASSWORD
  SEND_PASSWORD_RESET_TOKEN: '/reset_password_request',
  VERIFY_PASSWORD_RESET_TOKEN: '/verify_reset_password_token',
  RESET_PASSWORD: '/reset_password',
  // CHANGE PASSWORD
  CHANGE_PASSWORD: '/change_password',
  // SIGN UP
  SIGN_UP_VERIFY_TOKEN: '/verify_invitation_token',
  SIGN_UP: '/sign_up_user',
  // USERS
  USERS: '/users',
  USER: userId => `/users/${userId}`,
  // ORIGINS
  ORIGINS: '/origins',
  ORIGIN: originId => `/origins/${originId}`,
  // FILE UPLOADS
  FILE_UPLOADS: deviceId => `/logical_devices/${deviceId}/file_uploads`,
  FILE_UPLOADS_SIGN: '/file_uploads/sign',
  TRIGGER_FILE_TRANSFER: deviceId => `/logical_devices/${deviceId}/file_uploads/transfer`,
  DOWNLOAD_FILE: deviceId => `/logical_devices/${deviceId}/file_uploads/download`,
  DELETE_FILES: deviceId => `/logical_devices/${deviceId}/file_uploads/delete`,
  // STATUS TIMELINES
  TIMELINES: deviceId => `/logical_devices/${deviceId}/timelines`,
  // STATUS STATIC SCENES
  STATIC_SCENES: deviceId => `/logical_devices/${deviceId}/static_scenes`,
  // DMX OUTPUT
  PATCH: deviceId => `/logical_devices/${deviceId}/patch`,
  DMX_OUTPUT: deviceId => `/logical_devices/${deviceId}/dmx_output`,
  // UPDATE SETTINGS
  UPDATE_SETTING: deviceId => `/logical_devices/${deviceId}/update_setting`,
  // POLLING
  RESOURCE_UPDATE: deviceId => `/logical_devices/${deviceId}/resource_update`,
  // LOGS
  LOGS: deviceId => `/logical_devices/${deviceId}/log_messages`,
  // PERFORM ACTION
  PERFORM_ACTION: deviceId => `/logical_devices/${deviceId}/perform_action`,
  // PERMISSIONS
  INDEX_PERMISSIONS: '/permissions',
  INITIATOR_PERMISSIONS: '/permissions/get_resource',
  CONTEXT_PERMISSIONS: '/permissions/get_context',
  GRANT_PERMISSION: '/permissions/grant',
  REVOKE_PERMISSION: '/permissions/revoke',
  SET_PERMISSIONS: '/permissions/set',
  CUMULATED_PERMISSIONS: '/permissions/get_cumulated',
  PERMISSIONS_PROJECT_USERS: projectId => `/projects/${projectId}/users`,
  // NOTIFICATIONS
  NOTIFICATION_TYPES: '/notification_types',
  NOTIFICATION_EVENTS: '/notification_events',
  NOTIFICATION_EVENT_READ: id => `/notification_events/${id}/read`,
  NOTIFICATION_EVENT_READ_ALL: '/notification_events/read_all',
  NOTIFICATION_EVENT_ARCHIVE: id => `/notification_events/${id}/archive`,
  NOTIFICATION_EVENT_ARCHIVE_ALL: '/notification_events/archive_all',
  NOTIFICATIONS: notificationId => `/notifications${(notificationId ? `/${notificationId}` : '')}`,
  PROJECT_NOTIFICATIONS: projectId => `/projects/${projectId}/notifications?include=notifiable,notificationType,user&fields[users]=id&fields[notificationTypes]=id&fields[projects]=id`,
  LOGICAL_DEVICE_NOTIFICATIONS: deviceId => `/logical_devices/${deviceId}/notifications?include=notifiable,notificationType,user&fields[users]=id&fields[notificationTypes]=id&fields[logicalDevices]=id`,
  // STATUS ZONES
  ZONES: deviceId => `/logical_devices/${deviceId}/zones`,
  // STATUS SNAPSHOTS
  SNAPSHOTS: deviceId => `/logical_devices/${deviceId}/snapshots`,
  // REMOTE DEVICES
  REMOTE_DEVICES: deviceId => `/logical_devices/${deviceId}/remote_devices`,
  // UNJOINED REMOTE DEVICES
  UNJOINED_REMOTE_DEVICES: deviceId => `/logical_devices/${deviceId}/unjoined_remote_devices`,
  // AUTOMATED OPERATIONS
  AUTOMATED_OPERATIONS: '/automated_operations',
  UPDATE_AUTOMATED_OPERATION: automatedOperationId => `/automated_operations/${automatedOperationId}`,
  AUTOMATED_OPERATION: automatedOperationId => `/automated_operations/${automatedOperationId}`,
  PROJECT_AUTOMATED_OPERATIONS: projectId => `/projects/${projectId}/automated_operations`,
  // SCHEDULES
  SCHEDULES: '/schedules',
  AUTOMATED_OPERATION_SCHEDULES: automatedOperationId => `/automated_operations/${automatedOperationId}/schedules`,
  AUTOMATED_FUNCTIONS: automatedOperationId => `/automated_operations/${automatedOperationId}/automated_function`,
  DELETE_SCHEDULE: scheduleId => `/schedules/${scheduleId}`,
  AUTOMATED_FUNCTION: automatedFunctionId => `/automated_functions/${automatedFunctionId}`,
  UPDATE_SCHEDULE: scheduleId => `/schedules/${scheduleId}`,
  // OPERATIONS
  OPERATIONS: '/operations',
  // SERVICE VERSIONS
  SERVICE_VERSIONS: '/service_versions',
  // LOGICAL DEVICES FOR PROJECTS
  LOGICAL_DEVICES_PROJECT: projectId => `/projects/${projectId}/logical_devices`,
  // VOUCHERS & SUBSCRIPTION PLANS
  VOUCHER_DETAILS: '/vouchers/details',
  VOUCHER_DETAILS_PROJECT: projectId => `/projects/${projectId}/voucher_details`,
  REDEEM_VOUCHER: '/projects/using_voucher',
  REDEEM_VOUCHER_PROJECT: projectId => `/projects/${projectId}/redeem_voucher`,
  SUBSCRIPTION_PLANS: '/subscription_plans',
  VOUCHER_GENERATE: '/vouchers/generate',
  UPGRADE_SITE_PLAN: projectId => `/projects/${projectId}/change_subscription_plan`,
  // PROJECT SUITES
  PROJECT_SUITES: '/project_suites',
  USER_PROJECT_SUITES: userId => `/users/${userId}/project_suites`,
  PROJECT_SUITE: projectSuiteId => `/project_suites/${projectSuiteId}`,
  PROJECT_SUITE_PROJECTS: projectSuiteId => `/project_suites/${projectSuiteId}/projects`,
  PROJECT_SUITE_ADD_PROJECT: projectSuiteId => `/project_suites/${projectSuiteId}/relationships/projects`,
  PROJECT_SUITE_REMOVE_PROJECT: projectSuiteId => `/project_suites/${projectSuiteId}/relationships/projects`,
  PROJECT_SUITE_USERS: projectSuiteId => `/project_suites/${projectSuiteId}/users`,
  PROJECT_SUITE_REMOVE_USER: projectSuiteId => `/project_suites/${projectSuiteId}/relationships/users`,
  PROJECT_SUITE_CONTROL_UI: projectSuiteId => `/project_suites/${projectSuiteId}/control_panel_page`,
  PROJECT_SUITE_FILE_UPLOADS: projectSuiteId => `/project_suites/${projectSuiteId}/resources_upload`,

  // ACTIVITY LOGGING ENDPOINT
  ACTIVITY_LOGGING_ACTION_TYPES: '/audit_log_action_types',
  AUTOMATED_OPERATION_OCCURRENCES: '/ao_occurrences',

  // LOGICAL DEVICES GENERAL
  LOGICAL_DEVICES: '/logical_devices',

  // SITE ACTION TYPES LISTS
  SITE_ACTION_TYPES_LIST: projectId => `/projects/${projectId}/action_types_list`,

  // DEVICE FIRMWARES
  DEVICE_FIRMWARES: '/device_firmwares',
  DEVICE_FIRMWARES_MODEL_TREE: '/device_firmwares/models_tree',
  DEVICE_FIRMWARE_FILES: '/device_firmware_files',
  DEVICE_FIRMWARE_FILES_SIGN: '/device_firmware_files/sign',
  LINK_FIRMWARE_FILE: deviceFirmwareId => `/device_firmwares/${deviceFirmwareId}/relationships/files`,
  DELETE_DEVICE_FIRMWARE: deviceFirmwareId => `/device_firmwares/${deviceFirmwareId}`,
  EDIT_DEVICE_FIRMWARE: deviceFirmwareId => `/device_firmwares/${deviceFirmwareId}`,
  DEVICE_FIRMWARES_TRANSFER: (deviceFirmwareId, logicalDeviceId) => `/device_firmwares/${deviceFirmwareId}/logical_devices/${logicalDeviceId}/transfer`,

  // RENEW SUSPENDED SITE
  RENEW_SUSPENDED_SITE: projectId => `/projects/${projectId}/renew`,

  // LOGOUT
  LOGOUT: '/log_out',
};
