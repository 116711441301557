import { connect } from 'react-redux';

import uploadsActionCreators from 'components/UploadsWidget/uploadsActionCreators';
import DropzoneField from './DropzoneField';

const mapDispatchToProps = {
  onFilesNotSupported: uploadsActionCreators.filesNotSupported,
};

export default connect(null, mapDispatchToProps)(DropzoneField);
