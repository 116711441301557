import FirmwareV1 from 'components/Firmware/FirmwareV1Container';
import DeviceFetcher from 'layouts/DeviceFetcher/DeviceFetcherContainer';
import PharosHardwareV1 from 'layouts/Pharos/components/PharosHardwareV1/PharosHardwareV1Container';
import PharosTriggersV2 from 'layouts/Pharos/components/PharosTriggersV2/PharosTriggersV2';
import PharosFixtureGroupsV1 from 'layouts/Pharos/components/PharosFixtureGroupsV1/PharosFixtureGroupsV1';
import PharosFilesV1 from 'layouts/Pharos/components/PharosFilesV1/PharosFilesV1';
import PharosOutputV1 from 'layouts/Pharos/components/PharosOutputV1/PharosOutputV1';
import PharosTimelinesV1 from 'layouts/Pharos/components/PharosTimelinesV1/PharosTimelinesV1';
import PharosStaticScenesV1 from 'layouts/Pharos/components/PharosStaticScenesV1/PharosStaticScenesV1';
import PharosClockV1 from 'layouts/Pharos/components/PharosClockV1/PharosClockV1Container';
import PharosConfigurationV1
from 'layouts/Pharos/components/PharosConfigurationV1/PharosConfigurationV1Container';
import PharosSoftwareV1 from 'layouts/Pharos/components/PharosSoftwareV1/PharosSoftwareV1Container';
import PharosNetworkInterfaceV1
from 'layouts/Pharos/components/PharosNetworkInterfaceV1/PharosNetworkInterfaceV1Container';
import PharosNetworkProtocolV1
from 'layouts/Pharos/components/PharosNetworkProtocolV1/PharosNetworkProtocolV1Container';
import PharosProjectV1 from 'layouts/Pharos/components/PharosProjectV1/PharosProjectV1Container';
import PharosLogsV1 from 'layouts/Pharos/components/PharosLogsV1/PharosLogsV1';
import PharosResetDeviceV1 from '../components/PharosResetDeviceV1/PharosResetDeviceV1Container';
import PharosTimeAdjustmentV1 from '../components/PharosTimeAdjustmentV1/PharosTimeAdjustmentV1Container';
import PharosWatchdogV1 from '../components/PharosWatchdogV1/PharosWatchdogV1Container';
import PharosFormatDeviceV1 from '../components/PharosFormatDeviceV1/PharosFormatDeviceV1Container';
import PharosBeaconV1 from '../components/PharosBeaconV1/PharosBeaconV1Container';
import PharosIOModulesV1 from '../components/PharosIOModulesV1/PharosIOModulesV1Container';
import PharosRemoteDevicesV1 from '../components/PharosRemoteDevicesV1/PharosRemoteDevicesV1Container';

export default [
  {
    menuItem: 'Overview',
    path: '/overview',
    fetcher: DeviceFetcher,
    rows: [
      {
        columns: [
          {
            computer: 8,
            mobile: 16,
            sections: [
              {
                title: 'Hardware',
                component: PharosHardwareV1,
              },
              {
                title: 'Project',
                component: PharosProjectV1,
              },
              {
                title: 'Configuration',
                component: PharosConfigurationV1,
              },
            ],
          },
          {
            computer: 8,
            mobile: 16,
            sections: [
              {
                title: 'Software',
                component: PharosSoftwareV1,
              },
              {
                title: 'Clock',
                component: PharosClockV1,
              },
              {
                title: 'Network Interface',
                component: PharosNetworkInterfaceV1,
              },
              {
                title: 'Network Interface 2 (Protocol)',
                component: PharosNetworkProtocolV1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Triggers',
    path: '/triggers',
    rows: [
      {
        columns: [
          {
            computer: 16,
            mobile: 16,
            sections: [
              {
                title: 'Triggers',
                component: PharosTriggersV2,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Status',
    path: '/status',
    rows: [
      {
        columns: [
          {
            sections: [
              {
                title: 'Groups',
                component: PharosFixtureGroupsV1,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            sections: [
              {
                title: 'Timelines',
                component: PharosTimelinesV1,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            sections: [
              {
                title: 'Scenes',
                component: PharosStaticScenesV1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Files',
    path: '/files',
    fetcher: DeviceFetcher,
    rows: [
      {
        columns: [
          {
            sections: [
              {
                title: 'Files',
                component: PharosFilesV1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Firmware',
    path: '/firmware',
    fetcher: DeviceFetcher,
    rows: [
      {
        columns: [
          {
            sections: [
              {
                title: 'Firmware',
                component: FirmwareV1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Log',
    path: '/log',
    fetcher: DeviceFetcher,
    rows: [
      {
        columns: [
          {
            sections: [
              {
                title: 'Log',
                component: PharosLogsV1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'IO',
    path: '/io',
    fetcher: DeviceFetcher,
    rows: [
      {
        columns: [
          {
            sections: [
              {
                title: 'IO Modules',
                component: PharosIOModulesV1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Remote Devices',
    path: '/remote-devices',
    fetcher: DeviceFetcher,
    rows: [
      {
        columns: [
          {
            sections: [
              {
                title: 'Remote Devices',
                component: PharosRemoteDevicesV1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Output',
    path: '/output',
    rows: [
      {
        columns: [
          {
            sections: [
              {
                title: 'Output',
                component: PharosOutputV1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Advanced',
    path: '/advanced',
    fetcher: DeviceFetcher,
    rows: [
      {
        columns: [
          {
            computer: 8,
            tablet: 8,
            mobile: 16,
            sections: [
              {
                title: 'Beacon device',
                component: PharosBeaconV1,
              },
              {
                title: 'Reset device',
                component: PharosResetDeviceV1,
              },
              {
                title: 'Format device',
                component: PharosFormatDeviceV1,
              },
            ],
          },
          {
            computer: 8,
            tablet: 8,
            mobile: 16,
            sections: [
              {
                title: 'Date & time',
                component: PharosTimeAdjustmentV1,
              },
              {
                title: 'Watchdog',
                component: PharosWatchdogV1,
              },
            ],
          },
        ],
      },
    ],
  },
];
