import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  USERS_FIELD,
  PROJECT_SUITES_FIELD,
  permissionsProjectSuiteOpenSelector,
  permissionsProjectSuiteUsersLinkSelector,
} from 'helpers/selectors';
import { addPagination } from 'helpers/paginationHelpers';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { PermissionsGridWrapper } from '../../../../../../../../../../components';
import permissionsActionCreators from '../../../../../../../../../../utilities/permissions/permissionsActionCreators';
import { ContextProjectSuitePermissionsTree } from '../../tree/PermissionsTree';
import { ContextProjectSuitePermissionsGridHeaders } from '../../headers/PermissionsGridHeaders';
import { ContextProjectSuitePermissionsGrid } from '../../grid/PermissionsGrid';

export const mapStateToProps = (state, { projectSuiteId }) => ({
  open: permissionsProjectSuiteOpenSelector(state, { projectSuiteId }),
  usersEndpoint: permissionsProjectSuiteUsersLinkSelector(state)(projectSuiteId),
  resourceId: projectSuiteId,
  resourceTitle: 'All Multi-sites',
  PermissionsTree: ContextProjectSuitePermissionsTree,
  PermissionsGridHeaders: ContextProjectSuitePermissionsGridHeaders,
  PermissionsGrid: ContextProjectSuitePermissionsGrid,
});

const mapDispatchToProps = {
  onToggleOpen: projectSuiteId => permissionsActionCreators.toggleResourceOpen(
    PROJECT_SUITES_FIELD,
    projectSuiteId,
  ),
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: USERS_FIELD, included: true },
      ({ resourceId, usersEndpoint }) => permissionsActionCreators.fetchProjectSuiteUsers(
        resourceId,
        usersEndpoint,
        {
          fields: {
            [USERS_FIELD]: ['firstName', 'lastName', 'email'],
          },
          sort: 'firstName',
        },
      ),
    ),
  ),
)(PermissionsGridWrapper);
