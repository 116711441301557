import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

export const StyledButton = styled(Button)`
  &.ui.button {
    max-width: 250px;
    margin: 10px auto;
  }
`;


export default StyledButton;
