import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { PROJECTS_FIELD, singleProjectSelector, singleProjectSuiteSelector } from 'helpers/selectors';
import {
  actionCreators as projectSuitesActionCreators,
  selectors as projectSuitesSelectors,
} from 'pages/ProjectSuites/utilities';

import {
  selectors as taskSchedulerSelectors,
  actionCreators as taskSchedulerActionCreators,
} from '../../../../utilities';

import TasksActionsNavigation from './TasksActionsNavigation';

export const mapStateToProps = (state, { match, resourceType }) => {
  const { id: resourceId } = match.params;

  const project = resourceType === 'project' ? singleProjectSelector(state, resourceId) : null;
  const projectSuite = resourceType === 'projectSuite' ? singleProjectSuiteSelector(state, resourceId) : null;

  let projects = [];

  if (resourceType === 'projectSuite') {
    projects = projectSuitesSelectors.projectsListSelector(state)(resourceId) || [];
  }

  return {
    project,
    projectSuite,
    projectSuiteId: projectSuite ? projectSuite.id : null,
    projects,
    selectedDevice: taskSchedulerSelectors.selectedDeviceSelector(state),
  };
};

export const mapDispatchToProps = {
  onDeviceSelected: taskSchedulerActionCreators.setSelectedDevice,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  chainedLoaderWithSaga(
    ({ projectSuiteId }) => projectSuiteId
    && projectSuitesActionCreators.fetchProjectSuiteProjectsFilter(
      projectSuiteId, {
        fields: {
          [PROJECTS_FIELD]: [
            'name',
          ],
        },
        sort: 'name',
      }),
  ),
)(TasksActionsNavigation);
