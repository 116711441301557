import { connect } from 'react-redux';

import { deviceDetailsSelector } from 'helpers/selectors';
import deviceActionCreators from 'pages/Project/deviceActionCreators';
import AddDeviceModal from './AddDeviceModal';

const mapStateToProps = state => deviceDetailsSelector(state);

const mapDispatchToProps = {
  onClearDeviceKey: deviceActionCreators.clearDeviceKey,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDeviceModal);
