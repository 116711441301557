import styled, { css } from 'styled-components';
import { Grid } from 'semantic-ui-react';

import filterProps from 'helpers/filterProps';

export const StyledLabel = styled.label`
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
`;

const FilteredPropsGridRow = filterProps([
  'noPaddingBottom',
])(Grid.Row);

export const StyledRow = styled(FilteredPropsGridRow)`
  &&& {
    ${({ noPaddingBottom }) => noPaddingBottom && css`
      padding-bottom: 0 !important;
    `}
  }
`;

export const TimeList = styled.ul`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 350px;
  max-height: 120px;
  overflow: auto;
  margin-top: 5px;

  list-style: none;
  padding-left: 0;
  
  li {
    margin: 3px;
  }  
`;

export default StyledLabel;
