import { connect } from 'react-redux';

import { deviceSelector } from 'helpers/selectors';
import performActionActionCreators from 'pages/Device/performActionActionCreators';
import PathwayRebootDeviceV1 from './PathwayRebootDeviceV1';

export const mapStateToProps = (state, ownProps) => {
  const { resourceId } = ownProps;
  const { online } = deviceSelector(state)(resourceId);

  return {
    online,
  };
};

const mapDispatchToProps = {
  onReset: performActionActionCreators.performReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(PathwayRebootDeviceV1);
