import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';

export const fetchPatches = deviceId => ({
  type: constants.FETCH_PATCHES,
  payload: {
    endpoint: endpoints.PATCH(deviceId),
  },
});

export const fetchPatchesSuccess = response => ({
  type: constants.FETCH_PATCHES_SUCCESS,
  payload: { response },
});

export const fetchPatchesFailure = error => ({
  type: constants.FETCH_PATCHES_FAILED,
  payload: {
    info: error.notFound && messages.NO_PATCHES,
    error: !error.notFound && error,
  },
});
