import { AUTOMATED_FUNCTIONS_FIELD } from 'helpers/selectors';
import ApiRequestBuilder from 'helpers/ApiRequestBuilder';

class ScheduleModel {

  constructor(automatedFunction = {}) {
    this.id = automatedFunction.id;
    this.logicalExpression = automatedFunction.logicalExpression;
    this.lockVersion = automatedFunction.lockVersion;
  }

  static requestBody(values) {
    return new ApiRequestBuilder(AUTOMATED_FUNCTIONS_FIELD)
      .setAttributes(values);
  }

}

export default ScheduleModel;
