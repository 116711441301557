import { connect } from 'react-redux';

import uploadsActionCreators from 'components/UploadsWidget/uploadsActionCreators';
import UploadsContextProvider from './UploadsContextProvider';

const mapDispatchToProps = {
  onForceUpload: uploadsActionCreators.forceUpload,
  onEnqueueAtEnd: uploadsActionCreators.enqueueUploadAtEnd,
  onCancelUpload: uploadsActionCreators.cancelUpload,
  onPauseUpload: uploadsActionCreators.pauseUpload,
  onSuspendUpload: uploadsActionCreators.suspendUpload,
  onContinueUpload: uploadsActionCreators.continueUpload,
};

export default connect(null, mapDispatchToProps)(UploadsContextProvider);
