const twoFactorQRCodeSuccessReducer = (state, action) => {
  const {
    qr_uri: twoFactorQRUri,
    secret_key: twoFactorSecretKey,
  } = action.payload.response.data.attributes;

  return {
    ...state,
    twoFactorQRUri,
    twoFactorSecretKey,
  };
};

export default twoFactorQRCodeSuccessReducer;
