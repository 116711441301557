import React, { cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import { Radio, Table } from 'semantic-ui-react';
import pick from 'lodash/pick';

import DataTableText from 'components/DataTable/DataTableText/DataTableText';

class DataTableRow extends Component {

  constructor() {
    super();

    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  getChild(index) {
    const { children } = this.props;

    if (Array.isArray(children)) {
      return children[index];
    }

    if (index === 0) {
      return children;
    }

    return null;
  }

  handleClick() {
    const { item, onClick } = this.props;
    onClick(item);
  }

  handleChange(e) {
    const { item, onChangeActive } = this.props;
    onChangeActive(item);
    e.stopPropagation();
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleChange(e);
    }
  }

  renderSingleChild(field, index) {
    const { children, item } = this.props;

    if (!children) {
      return <DataTableText item={item} field={field} />;
    }

    const child = this.getChild(index);

    if (!child) {
      return null;
    }

    const childProps = child.props;
    const allowedProps = childProps && Object.keys(childProps);
    const additionalProps = pick(
      {
        ...this.props,
        field,
        children: childProps.children,
        onClick: childProps.onClick,
      },
      allowedProps,
    );

    return cloneElement(child, additionalProps);
  }

  renderRadio() {
    const {
      customRadioButton: CustomRadioButton,
      actionLinks,
      active,
      open,
      item,
    } = this.props;

    const radio = actionLinks && (
      <span
        role="button"
        tabIndex={0}
        onClick={this.handleChange}
        onKeyPress={this.handleKeyPress}
      >
        <Radio checked={active} />
      </span>
    );

    if (CustomRadioButton) {
      return (
        <CustomRadioButton
          radio={radio}
          open={open}
          item={item}
        />
      );
    }

    return (
      <Table.Cell>
        {radio}
      </Table.Cell>
    );
  }

  render() {
    const {
      item: { id },
      labels,
      active,
      className,
      isMarked,
    } = this.props;

    return (
      <Table.Row
        className={className}
        active={active}
        onClick={this.handleClick}
        positive={isMarked}
      >
        {this.renderRadio()}
        {labels.map(({ field, text, width }, index) => (
          <Table.Cell key={`${id}${field}`} width={width} data-content={text}>
            {this.renderSingleChild(field, index)}
          </Table.Cell>
        ))}
      </Table.Row>
    );
  }

}

DataTableRow.defaultProps = {
  className: null,
  actionLinks: null,
  children: null,
  isMarked: null,
  customRadioButton: null,
  open: false,
};

DataTableRow.propTypes = {
  className: PropTypes.string,
  actionLinks: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      width: PropTypes.number,
    }).isRequired,
  ).isRequired,
  item: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  rowNumber: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  onClick: PropTypes.func.isRequired,
  onChangeActive: PropTypes.func.isRequired,
  isMarked: PropTypes.bool,
  customRadioButton: PropTypes.func,
  open: PropTypes.bool,
};

export default DataTableRow;
