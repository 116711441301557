import {
  fetchDevicesProjectSuites,
  fetchDevicesProjectSuitesSuccess,
  fetchDevicesProjectSuitesFailure,
} from './fetchDevicesProjectSuites';

export default {
  fetchDevicesProjectSuites,
  fetchDevicesProjectSuitesSuccess,
  fetchDevicesProjectSuitesFailure,
};
