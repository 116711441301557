import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/atomic';
import ConfirmModal from 'components/elements/ConfirmModal/ConfirmModal';

const UpgradePlanConfirmModal = ({ projectId, planCode, onUpgradeSitePlan }) => {
  const handleConfirm = () => {
    onUpgradeSitePlan({
      projectId,
      planCode,
    });
  };

  const trigger = (
    <Button primary size="mini">
      Upgrade Plan
    </Button>
  );

  const confirmButton = (
    <Button primary>
      Update site plan
    </Button>
  );

  const validationString = 'update site plan';

  return (
    <ConfirmModal
      trigger={trigger}
      cancelButton="Cancel"
      confirmButton={confirmButton}
      content={(
        <div>
          <p>
            Are you sure you want to update the Site plan?
            The Subscription plan will be updated to match the required Site plan at the cost of
            subscription time, so the renewal date will be adjusted.
          </p>
          <p>
            Enter the text <strong>{validationString}</strong> to confirm:
          </p>
        </div>
      )}
      header="Confirm Update Site Plan"
      validationMessage="Enter the correct text to confirm this change"
      validationString={validationString}
      onConfirm={handleConfirm}
    />
  );
};

UpgradePlanConfirmModal.propTypes = {
  projectId: PropTypes.string.isRequired,
  planCode: PropTypes.string.isRequired,
  onUpgradeSitePlan: PropTypes.func.isRequired,
};

export default UpgradePlanConfirmModal;
