import { compose } from 'redux';
import { connect } from 'react-redux';

import { fileDownloadingSelector } from 'helpers/selectors';
import withDownloadFile from './withDownloadFile';
import filesSectionActionCreators from '../../filesSectionActionCreators';

const mapStateToProps = state => ({
  downloading: fileDownloadingSelector(state),
});

const mapDispatchToProps = {
  onDownloadFile: filesSectionActionCreators.downloadFile,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withDownloadFile,
);
