import styled from 'styled-components';
import { Label } from 'semantic-ui-react';
import get from 'lodash/get';

export const StyledTaskInformation = styled.span`
  display: inline-block;
  font-size: 10px;
  margin-right: 25px;
`;

export const StyledLabel = styled(Label)`
  &.ui.label:last-child {
    font-size: 10px;
    font-weight: normal;
    color: white;
    background-color: ${({ theme }) => get(theme, 'palette.primaryColor')};
  }
`;

export default StyledTaskInformation;
