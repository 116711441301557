import styled from 'styled-components';

export const StyledDropdownFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const StyledDropdownField = styled.div`
  min-width: 200px;
`;

export const StyledFilename = styled.span`
  padding-top: 0.6em;
`;
