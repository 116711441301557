import styled, { css } from 'styled-components';
import { Tab } from 'semantic-ui-react';
import get from 'lodash/get';

export const StyledTab = styled(Tab)`
  ${({ theme }) => css`
    &&& {
      .ui.menu {
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        margin-top: auto;
        height: 30px;
        display: flex;
        border: none;
        box-shadow: 0px -3px 5px 0px rgba(0,0,0,0.13);
        width: 100%;        

        a {
          flex: 1;
          font-weight: bold;
          color: rgba(51, 51, 51, 0.20);
          justify-content: center;
          background: #FFF;
          border: none;

          &:first-child {
            border-bottom-left-radius: 8px;
          }

          &:before {
            background: none;
          }

          &.active {
            color: ${get(theme, 'palette.primaryColor')};
            border-top: 2px solid ${get(theme, 'palette.primaryColor')};
          }
        }
      }
    } 
  `};
`;

export default StyledTab;
