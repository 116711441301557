import React from 'react';

import { NotificationsLog } from 'components/Notifications';

const NotificationsLogTab = () => (
  <div>
    <NotificationsLog />
  </div>
);

export default NotificationsLogTab;
