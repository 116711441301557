const reduceRemoteDevicesResourceWithDeviceId = deviceId => (
  remoteDevices,
  { id, attributes },
) => ({
  ...remoteDevices,
  [id]: {
    id,
    attributes: {
      ...attributes,
      deviceId,
    },
  },
});

export default reduceRemoteDevicesResourceWithDeviceId;
