import { compose } from 'redux';
import { connect } from 'react-redux';

import ProjectSuiteModel from 'models/ProjectModel';
import { projectSuiteSelector } from 'helpers/selectors';
import mapMarker from 'application/tenant/console/site/components/Map/mapMarker/mapMarker';
import { actionCreators } from 'pages/ProjectSuites/utilities';

import TempSiteMarker from './TempSiteMarker';

export const mapStateToProps = (state, { projectSuiteId }) => {
  const projectSuite = projectSuiteSelector(state)(projectSuiteId) || {};
  const { tempAddress } = new ProjectSuiteModel(projectSuite);

  return {
    tempAddress,
  };
};

export const mapDispatchToProps = {
  onUpdateProjectSuite: actionCreators.updateProjectSuite,
};

export default compose(
  mapMarker({
    onSelect: () => null,
    onUnselect: () => null,
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(TempSiteMarker);
