import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';

import { complexValueType } from 'data/types';
import TextDescriptionItem
  from 'components/DescriptionList/TextDescriptionItem/TextDescriptionItem';

const PharosNetworkProtocolV1 = props => {
  const { loading } = props;
  if (loading) return null;

  const {
    title,
    ipAddress,
    subnetMask,
    gateway,
  } = props;

  return (
    <Segment>
      <Header as="h2">{title}</Header>
      <TextDescriptionItem
        title="IP Address"
        value={ipAddress}
      />
      <TextDescriptionItem
        title="Subnet Mask"
        value={subnetMask}
      />
      <TextDescriptionItem
        title="Default Gateway"
        value={gateway}
      />
    </Segment>
  );
};

PharosNetworkProtocolV1.defaultProps = {
  loading: false,
  ipAddress: {},
  subnetMask: {},
  gateway: {},
};

PharosNetworkProtocolV1.propTypes = {
  loading: PropTypes.bool,
  ipAddress: complexValueType(PropTypes.string),
  subnetMask: complexValueType(PropTypes.string),
  gateway: complexValueType(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default PharosNetworkProtocolV1;
