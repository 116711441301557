import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from 'semantic-ui-react';

import { Message, Text } from './Unauthorized.style';

const Unauthorized = ({ message, secondMessage }) => (
  <Container>
    <Grid centered>
      <Grid.Row>
        <Grid.Column textAlign="center" mobile={16}>
          <Message>
            <Text>
              {message}
              <br />
              {secondMessage}
            </Text>
          </Message>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
);

Unauthorized.defaultProps = {
  message: 'You do not have the required permissions to view the Control Panel.',
  secondMessage: 'Please contact the Site/Multi-site owner for more information.',
};

Unauthorized.propTypes = {
  message: PropTypes.string,
  secondMessage: PropTypes.string,
};

export default Unauthorized;
