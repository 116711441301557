
import { permissionsLogicalDeviceSelector } from 'helpers/selectors';

const permissionsDeviceCopyUserIdSelector = (state, { deviceId }) => {
  const { copyUserId } = permissionsLogicalDeviceSelector(state)(deviceId);

  return copyUserId;
};

export default permissionsDeviceCopyUserIdSelector;
