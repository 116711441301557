import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const sendPasswordResetToken = params => ({
  type: constants.RESET_PASSWORD_SEND_TOKEN,
  payload: {
    ...params,
    endpoint: endpoints.SEND_PASSWORD_RESET_TOKEN,
  },
});

export const sendPasswordResetTokenSuccess = response => ({
  type: constants.RESET_PASSWORD_SEND_TOKEN_SUCCESS,
  payload: { response },
});

export const sendPasswordResetTokenFailure = (error, requester) => ({
  type: constants.RESET_PASSWORD_SEND_TOKEN_FAILED,
  payload: {
    error,
    requester,
  },
});
