import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Grid, Header } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';

import { ProjectPermissionsWrapper } from 'components/PermissionsTable/wrappers';

import AddProjectButton from '../PermissionsProjectsTab/AddProjectButton/AddProjectButtonContainer';
import ProjectsTable from '../PermissionsProjectsTab/ProjectsTable/ProjectsTableContainer';

import { StyledProjectsTabWrapper, StyledGrid } from '../PermissionsProjectsTab/PermissionsProjectsTab.style';

const PermissionsProjectsTab = ({ projectIds }) => (
  <StyledProjectsTabWrapper>
    <StyledGrid>
      <Grid.Row>
        <Grid.Column>
          <Header as="h1">Site permissions</Header>
          <Header as="h3">Add a site to view and edit its permissions</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          {!isEmpty(projectIds) && projectIds.map(projectId => (
            <Segment key={projectId}>
              <ProjectPermissionsWrapper
                projectId={projectId}
                showHeader
              />
            </Segment>
          ))}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <AddProjectButton />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <ProjectsTable />
        </Grid.Column>
      </Grid.Row>
    </StyledGrid>
  </StyledProjectsTabWrapper>
);

PermissionsProjectsTab.defaultProps = {
  projectIds: null,
};

PermissionsProjectsTab.propTypes = {
  projectIds: PropTypes.array,
};

export default PermissionsProjectsTab;
