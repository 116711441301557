import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';

import FormField from '../FormField/FormField';
import Checkbox from './CheckboxField.style';

class CheckboxField extends Component {

  constructor() {
    super();

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, { checked }) {
    const { input: { onChange } } = this.props;

    onChange(checked);
  }

  render() {
    const {
      input,
      input: {
        value,
        name,
      },
      label,
      ...rest
    } = this.props;
    const { meta, ...checkboxProps } = rest;

    return (
      <FormField {...rest} input={input} omitError>
        <Checkbox
          checked={value}
          name={name}
          label={label}
          onChange={this.handleChange}
          {...checkboxProps}
        />
      </FormField>
    );
  }

}

CheckboxField.propTypes = {
  ...Input.propTypes,
};

export default CheckboxField;
