import React from 'react';
import PropTypes from 'prop-types';

import { TRIGGERS_FIELD } from 'helpers/selectors';
import DataTable from 'components/DataTable/DataTableContainer';
import TriggerTableRow from 'components/elements/TriggerTableRow/TriggerTableRowContainer';

const TriggersSection = ({
  deviceId,
  title,
  labels,
  resource: { online },
}) => (
  <DataTable
    header={title}
    labels={labels}
    customTableRow={TriggerTableRow}
    pagination={{
      type: TRIGGERS_FIELD,
      included: true,
      args: { deviceId },
    }}
    deviceId={deviceId}
    isOnline={online}
  />
);

TriggersSection.defaultProps = {
  title: '',
};

TriggersSection.propTypes = {
  deviceId: PropTypes.string.isRequired,
  title: PropTypes.string,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  resource: PropTypes.shape({
    online: PropTypes.bool.isRequired,
  }).isRequired,
};

export default TriggersSection;
