import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  permissionsProjectsSelector,
  projectResourcePermissionPathsSelector,
} from 'helpers/selectors';
import { permissionsActionCreators } from '../../../../../../utilities';
import ProjectsRow from './ProjectsRow';

export const mapStateToProps = (state, ownProps) => {
  const { item: { id: projectId } } = ownProps;
  const projects = permissionsProjectsSelector(state);
  const highlighted = projects && projectId in projects;

  return {
    highlighted,
    paths: projectResourcePermissionPathsSelector(state),
  };
};

const mapDispatchToProps = {
  onAddProjectId: permissionsActionCreators.addProjectId,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ProjectsRow);
