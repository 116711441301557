import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { NOTIFICATION_EVENTS_FIELD } from 'helpers/selectors';

export const fetchNotificationEvents = (urlParams = {}) => ({
  type: constants.FETCH_NOTIFICATION_EVENTS,
  payload: {
    endpoint: endpoints.NOTIFICATION_EVENTS,
    resourceType: NOTIFICATION_EVENTS_FIELD,
    urlParams,
  },
});

export const fetchNotificationEventsSuccess = response => ({
  type: constants.FETCH_NOTIFICATION_EVENTS_SUCCESS,
  payload: {
    response,
    resourceType: NOTIFICATION_EVENTS_FIELD,
  },
});

export const fetchNotificationEventsFailure = error => ({
  type: constants.FETCH_NOTIFICATION_EVENTS_FAILED,
  payload: { error },
});

export const fetchNotificationEventsPaginated = response => ({
  type: constants.FETCH_NOTIFICATION_EVENTS_PAGINATED,
  payload: { response },
});

export default fetchNotificationEvents;
