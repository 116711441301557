import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';

export const changeApiDomain = params => ({
  type: constants.CHANGE_API_DOMAIN_REQUEST,
  payload: {
    endpoint: endpoints.CURRENT_USER,
    params,
  },
});

export const changeApiDomainSuccess = apiDomain => ({
  type: constants.CHANGE_API_DOMAIN_SUCCESS,
  payload: {
    apiDomain,
    message: messages.SUCCESS_API_DOMAIN,
  },
});

export const changeApiDomainFailure = error => ({
  type: constants.CHANGE_API_DOMAIN_FAILED,
  payload: { error },
});
