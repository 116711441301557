
import { permissionsProjectSelector } from 'helpers/selectors';

const permissionsProjectCopyUserIdSelector = (state, { projectId }) => {
  const { copyUserId } = permissionsProjectSelector(state)(projectId);

  return copyUserId;
};

export default permissionsProjectCopyUserIdSelector;
