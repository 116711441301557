import { css } from 'styled-components';

import contrastColor from './contrastColor';

const dayViewTableRowStyle = (color, enabled, overriden) => {
  if (!color) {
    return null;
  }

  const contrast = contrastColor(color);

  return css`
    .ui.selectable.table tbody tr&,
    .ui.table tbody tr& td.selectable {
      background-color: ${color};
      color: ${contrast};
      opacity: 0.3;

      ${enabled && !overriden && css`
        opacity: 0.8;
      `}

      &:hover {
        background-color: ${color}!important;
        color: ${contrast}!important;
        opacity: 0.6 !important;

        ${enabled && !overriden && css`
          opacity: 1 !important;
        `}
      }
    }
  `;
};

export default dayViewTableRowStyle;
