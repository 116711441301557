import { compose } from 'redux';
import { connect } from 'react-redux';

import projectActionCreators from '../../projectActionCreators';
import withDeleteProject from './withDeleteProject';

const mapDispatchToProps = {
  onDeleteProject: projectActionCreators.deleteProject,
};

export default compose(
  connect(null, mapDispatchToProps),
  withDeleteProject,
);
