import React from 'react';
import PropTypes from 'prop-types';

import { StyledUserTableRow } from './UserTableRow.style';

const UserTableRow = ({
  item,
  selectedUserId,
  onSelectUser,
  ...rest
}) => {
  const handleClick = () => {
    if (item.id === selectedUserId) {
      onSelectUser(null);
      return;
    }

    onSelectUser(item.id);
  };

  return (
    <StyledUserTableRow
      {...rest}
      item={item}
      highlighted={selectedUserId === item.id}
      onClick={handleClick}
    />
  );
};

UserTableRow.defaultProps = {
  selectedUserId: null,
};

UserTableRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  selectedUserId: PropTypes.string,
  onSelectUser: PropTypes.func.isRequired,
};

export default UserTableRow;
