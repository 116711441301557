import styled from 'styled-components';
import { Button, Accordion } from 'semantic-ui-react';

export const StyledAccordionTitle = styled(Accordion.Title)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledButtonGroup = styled(Button.Group)`
  margin-right: 8px !important;
`;

export default StyledButtonGroup;
