import styled, { css } from 'styled-components';
import get from 'lodash/get';

import { defaultBorder, defaultFocus } from 'themes/mixins';

export const DateFieldWrapper = styled.span`
  ${({ theme }) => {
    const primaryColor = get(theme, 'palette.primaryColor');

    return css`
      & .rw-widget-container {
        ${defaultBorder}
      }
      
      & .rw-select-bordered {
        border-left: inherit;
        vertical-align: middle;
      }
      
      & .rw-cell.rw-state-selected {
        background-color: ${primaryColor};
        border-color: ${primaryColor};
      }
       
      & .rw-cell.rw-state-focus {
        border-color: ${primaryColor};
      }
      
      & .rw-state-focus > .rw-widget-container {
        ${defaultFocus};
      }
      
      & .rw-widget-picker input[type="text"] {
        border: none;
      }

      & .rw-open {
        z-index: 100;
      }
    `;
  }};
`;

export default DateFieldWrapper;
