import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    margin-left: 6px;
    margin-right: 6px;
  }
`;

export default Container;
