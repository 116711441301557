import { connect } from 'react-redux';
import transform from 'lodash/transform';
import {
  deviceSelector,
  pathwayDeviceDMXSelector,
} from 'helpers/selectors';
import { upperCaseFirstLetter, portNumberToLetter } from 'helpers/sharedMethods';
import PathwayELinkPathV1 from './PathwayELinkPathV1';

export const mapStateToProps = (state, { resourceId }) => {
  const { online } = deviceSelector(state)(resourceId);
  const dmx = pathwayDeviceDMXSelector(state, resourceId);

  const data = transform(dmx, (result, value, key) => {
    const portLetter = portNumberToLetter(key);

    result.push({
      id: key,
      resourceId,
      nameLabel: `${value.nm} (Port ${portLetter})`,
      dmxLabel: `DMX ${value.dir} ${upperCaseFirstLetter(value.dmxSts)}`,
      rdmEnabled: value.rdm,
    });
    return result;
  }, []);

  return {
    online,
    columns: ['nameLabel', 'dmxLabel'],
    data,
  };
};

export default connect(mapStateToProps)(PathwayELinkPathV1);
