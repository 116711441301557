import {
  OCCURRENCE_TYPE,
  OCCURRENCE_TYPE_NUMBER,
  OCCURRENCE_DAY,
  OCCURRENCE_DAY_NUMBER,
  OCCURRENCE_MONTH_NUMBER,
} from '../../constants';

const getYearlyCondition = ({
  startDate,
  useYearlyConditions,
  yearlyConditions,
}) => {
  const { occurrenceType, occurrenceDay, occurrenceMonth } = yearlyConditions || {};
  let condition = {};

  if (!startDate && !useYearlyConditions) {
    return {};
  }

  if (startDate && !useYearlyConditions) {
    condition = {
      every: {
        day: startDate.date(),
        month: startDate.month() + 1,
      },
    };

    return condition;
  }

  if (occurrenceDay === OCCURRENCE_DAY.DAY) {
    if (occurrenceType === OCCURRENCE_TYPE.LAST) {
      condition = {
        every: {
          day: -1,
          month: OCCURRENCE_MONTH_NUMBER[occurrenceMonth],
        },
      };
    } else {
      condition = {
        every: {
          day: OCCURRENCE_TYPE_NUMBER[occurrenceType],
          month: OCCURRENCE_MONTH_NUMBER[occurrenceMonth],
        },
      };
    }
  } else {
    condition = {
      on: {
        weekday: OCCURRENCE_DAY_NUMBER[occurrenceDay],
        nthOccurrence: OCCURRENCE_TYPE_NUMBER[occurrenceType],
        month: OCCURRENCE_MONTH_NUMBER[occurrenceMonth],
      },
    };
  }

  return condition;
};

export default getYearlyCondition;
