import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import redirectIfNotFound from 'helpers/redirectIfNotFound';
import { currentUserSelector, PROJECT_SUITES_FIELD } from 'helpers/selectors';
import { addPagination } from 'helpers/paginationHelpers';

import { actionCreators } from './utilities';
import Projects from './ProjectSuites';

export const mapStateToProps = state => {
  const { id: userId } = currentUserSelector(state);

  return {
    userId,
    route: '/my-multi-sites',
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  redirectIfNotFound('tenantNotChanged'),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: PROJECT_SUITES_FIELD },
      ({ userId }) => actionCreators.fetchProjectSuites(
        userId,
        {
          fields: { projectSuites: ['name', 'usersCount', 'createdAt'] },
          sort: 'name',
          filter: 'name',
        },
      ),
    ),
  ),
)(Projects);
