import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';

import { complexValueType } from 'data/types';
import TextDescriptionItem
  from 'components/DescriptionList/TextDescriptionItem/OptionalTextDescriptionItem';
import TimeDescriptionItemIncremented
  from 'components/DescriptionList/TimeDescriptionItemIncremented/TimeDescriptionItemIncremented';

const PathwayHardwareV1 = ({
  title,
  loading,
  online,
  modelTitle,
  model,
  label,
  firmwareVersion,
  serial,
  ports,
  partner,
  uptime,
}) => !loading && (
  <Segment>
    <Header as="h2">{title}</Header>
    <TextDescriptionItem
      title={modelTitle}
      value={model}
    />
    <TextDescriptionItem
      title="Device Label"
      value={label}
    />
    <TextDescriptionItem
      title="Firmware Version"
      value={firmwareVersion}
    />
    <TextDescriptionItem
      title="Serial number"
      value={serial}
    />
    <TextDescriptionItem
      title="Ports"
      value={ports}
    />
    <TextDescriptionItem
      title="LLDP Partner Label"
      value={partner}
    />
    <TimeDescriptionItemIncremented
      title="Uptime"
      value={uptime}
      isOnline={online}
    />
  </Segment>
);

PathwayHardwareV1.defaultProps = {
  loading: true,
  online: false,
  model: {},
  label: {},
  firmwareVersion: {},
  serial: {},
  ports: null,
  partner: null,
  uptime: {},
};

PathwayHardwareV1.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  online: PropTypes.bool,
  modelTitle: PropTypes.string.isRequired,
  model: complexValueType(PropTypes.string),
  label: complexValueType(PropTypes.string),
  firmwareVersion: complexValueType(PropTypes.string),
  serial: complexValueType(PropTypes.string),
  ports: PropTypes.number,
  partner: complexValueType(PropTypes.string),
  uptime: complexValueType(PropTypes.number),
};

export default PathwayHardwareV1;
