import React from 'react';

import { UNJOINED_REMOTE_DEVICES_FIELD } from 'helpers/selectors';
import DataTable from 'components/DataTable/DataTableContainer';
import { UnjoinedRemoteDeviceCustomRow } from './components';

export const unjoinedDeviceLabels = [
  { text: 'Address', field: 'visibleAddress', sortable: true, width: 4 },
  { text: 'Type', field: 'type', sortable: true, width: 4 },
  { text: 'Serial', field: 'serial', sortable: true, width: 4 },
  { text: 'Status', field: 'status', sortable: false, width: 4 },
];

const title = 'Unjoined Remote Devices';

const UnjoinedRemoteDevicesTable = () => (
  <DataTable
    header={title}
    labels={unjoinedDeviceLabels}
    customTableRow={UnjoinedRemoteDeviceCustomRow}
    pagination={{
      type: UNJOINED_REMOTE_DEVICES_FIELD,
      included: true,
    }}
  />
);

export default UnjoinedRemoteDevicesTable;
