import React from 'react';
import PropTypes from 'prop-types';

import { StyledDiv } from './FieldWrapper.style';

const FieldWrapper = ({ children }) => (<StyledDiv>{children}</StyledDiv>);

FieldWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FieldWrapper;
