import constants from 'dispatcherConst';
import { NOTIFICATION_EVENTS_FIELD } from 'helpers/selectors';

export const addNotificationEventFromWebsocket = notificationEvent => ({
  type: constants.ADD_NOTIFICATION_EVENT_FROM_WEBSOCKET,
  payload: {
    notificationEvent,
    resourceType: NOTIFICATION_EVENTS_FIELD,
  },
});

export default addNotificationEventFromWebsocket;
