import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isNull from 'lodash/isNull';

import {
  currentUserSelector,
  permissionsStateSelector,
} from 'helpers/selectors';
import { selectors as permissionsSelectors } from 'application/tenant/console/utilities/permissions';
import { deepEqualMemoizeOne } from 'helpers/sharedMethods';
import { resourceFromMatch } from '../utilities';

export const makeMapState = () => {
  const memoUserPermissions = deepEqualMemoizeOne();
  const memoContexts = deepEqualMemoizeOne();

  return function mapStateToProps(state, { match }) {
    const { permissionsResourceType, resourceId } = resourceFromMatch(match);
    const { id: userId, superadmin } = currentUserSelector(state);
    const { contexts = {} } = permissionsStateSelector(state) || {};
    const userPermissions = permissionsSelectors.userPermissionsSelector(state)(userId);
    const permissionsLoading = isNull(userPermissions);
    const resourceUserPermissions = userPermissions && userPermissions.filter(
      ({ resourceId: permissionsResourceId }) => permissionsResourceId === resourceId,
    );
    const resourcePermissionsReady = (
      resourceUserPermissions
      && resourceUserPermissions.length !== 0
    );

    return {
      permissionsResourceType,
      permissionsLoading,
      resourceId,
      resourcePermissionsReady,
      superadmin,
      userPermissions: memoUserPermissions(userPermissions),
      contexts: memoContexts(Object.keys(contexts)),
    };
  };
};

export default compose(
  withRouter,
  connect(makeMapState),
);
