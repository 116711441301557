import {
  FILE_UPLOADS_FIELD,
  FIXTURE_GROUPS_FIELD,
  TRIGGERS_FIELD,
  TIMELINES_FIELD,
  STATIC_SCENES_FIELD,
  ZONES_FIELD,
  SNAPSHOTS_FIELD,
  IO_MODULES_INSTANCES_FIELD,
  TASKS_FIELD,
  AUTOMATED_OPERATIONS_FIELD,
  JAMBOX_TIMELINES_FIELD,
  REMOTE_DEVICES_FIELD,
  UNJOINED_REMOTE_DEVICES_FIELD,
  NOTIFICATION_EVENTS_FIELD,
} from 'helpers/selectors';
import FileModel from './FileModel';
import TriggerModel from './TriggerModel';
import TimelineModel from './TimelineModel';
import StaticSceneModel from './StaticSceneModel';
import FixtureGroupModel from './FixtureGroupModel';
import ZoneModel from './ZoneModel';
import SnapshotModel from './SnapshotModel';
import IoModuleInstanceModel from './IoModuleInstanceModel';
import TaskModel from './TaskModel';
import AutomatedOperationModel from './AutomatedOperationModel';
import JamboxTimelineModel from './JamboxTimelineModel';
import RemoteDeviceModel from './RemoteDeviceModel';
import UnjoinedRemoteDeviceModel from './UnjoinedRemoteDeviceModel';
import NotificationEventModel from './NotificationEventModel';

class ModelFactory {

  static getModel(type, data, ctorAdditional) {
    if (!type) return null;

    switch (type) {
      case FILE_UPLOADS_FIELD:
        return new FileModel(data, ctorAdditional);
      case TRIGGERS_FIELD:
        return new TriggerModel(data, ctorAdditional);
      case TIMELINES_FIELD:
        return new TimelineModel(data, ctorAdditional);
      case FIXTURE_GROUPS_FIELD:
        return new FixtureGroupModel(data, ctorAdditional);
      case STATIC_SCENES_FIELD:
        return new StaticSceneModel(data, ctorAdditional);
      case ZONES_FIELD:
        return new ZoneModel(data, ctorAdditional);
      case SNAPSHOTS_FIELD:
        return new SnapshotModel(data, ctorAdditional);
      case IO_MODULES_INSTANCES_FIELD:
        return new IoModuleInstanceModel(data, ctorAdditional);
      case TASKS_FIELD:
        return new TaskModel(data, ctorAdditional);
      case AUTOMATED_OPERATIONS_FIELD:
        return new AutomatedOperationModel(data, ctorAdditional);
      case JAMBOX_TIMELINES_FIELD:
        return new JamboxTimelineModel(data, ctorAdditional);
      case REMOTE_DEVICES_FIELD:
        return new RemoteDeviceModel(data, ctorAdditional);
      case UNJOINED_REMOTE_DEVICES_FIELD:
        return new UnjoinedRemoteDeviceModel(data, ctorAdditional);
      case NOTIFICATION_EVENTS_FIELD:
        return new NotificationEventModel(data, ctorAdditional);
      default:
        return null;
    }
  }

}

export default ModelFactory;
