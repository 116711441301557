import { compose } from 'redux';
import { connect } from 'react-redux';

import { getChainedLoaderPiece } from 'helpers/sharedMethods';
import chainedLoader from 'helpers/chainedLoader/chainedLoader';

const makeMapState = ({ name }) => function mapStateToProps(state, ownProps) {
  const {
    chainedLoaderProps: {
      [name]: {
        fetching: fetchingProp,
        error: errorProp,
        override: overrideProp,
        link: linkProp,
      } = {},
    } = {},
  } = ownProps;

  return getChainedLoaderPiece(
    fetchingProp,
    errorProp,
    overrideProp,
    linkProp,
  );
};

export default (config, ...methods) => compose(
  connect(makeMapState(config)),
  chainedLoader(config, ...methods),
);
