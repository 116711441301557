import { SCHEDULER_TYPES } from '../../../../constants';

const RECURRENCE_OPTIONS = [
  {
    key: SCHEDULER_TYPES.NONE,
    text: 'None',
    value: SCHEDULER_TYPES.NONE,
  },
  {
    key: SCHEDULER_TYPES.DATES,
    text: 'Specific dates',
    value: SCHEDULER_TYPES.DATES,
  },
  {
    key: SCHEDULER_TYPES.DAILY,
    text: 'Daily',
    value: SCHEDULER_TYPES.DAILY,
  },
  {
    key: SCHEDULER_TYPES.WEEKLY,
    text: 'Weekly',
    value: SCHEDULER_TYPES.WEEKLY,
  },
  {
    key: SCHEDULER_TYPES.MONTHLY,
    text: 'Monthly',
    value: SCHEDULER_TYPES.MONTHLY,
  },
  {
    key: SCHEDULER_TYPES.YEARLY,
    text: 'Yearly',
    value: SCHEDULER_TYPES.YEARLY,
  },
];

const PRIORITIES = [
  {
    text: 'High',
    value: 4,
  },
  {
    text: 'Above Normal',
    value: 3,
  },
  {
    text: 'Normal',
    value: 2,
  },
  {
    text: 'Below Normal',
    value: 1,
  },
  {
    text: 'Low',
    value: 0,
  },
];

const DATE_BLOCKING_HELP = 'Date blocking will prevent any occurrences with a lower priority from running at any time on any date on which this scheduler occurs. Otherwise, if date blocking is off, priority only has an affect on occurrences with exactly the same start time.';

export {
  RECURRENCE_OPTIONS,
  PRIORITIES,
  DATE_BLOCKING_HELP,
};
