import React from 'react';
import PropTypes from 'prop-types';
import isNull from 'lodash/isNull';

import StyledList from './DataTableStartMethod.style';
import {
  extractTitle,
  renderParams,
} from '../../utilities';

const DataTableStartMethod = ({ field, item }) => {
  if (isNull(item) || isNull(field)) {
    return null;
  }

  const value = item[field];

  if (value) {
    const title = extractTitle(value);
    const list = renderParams(value);

    return (
      <StyledList>
        <li>{title}</li>
        {list.map(param => <li key={param}>{param}</li>)}
      </StyledList>
    );
  }

  return null;
};

DataTableStartMethod.defaultProps = {
  field: null,
  item: {},
};

DataTableStartMethod.propTypes = {
  field: PropTypes.string,
  item: PropTypes.object,
};

export default DataTableStartMethod;
