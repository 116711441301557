import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { PermissionsSwitch, Gateway, frontendPermissions } from 'gateway';
import ProjectSuiteContext from 'application/tenant/console/site/components/structural/ProjectTasksTab/components/ListOfTasks/components/ProjectSuiteContext/ProjectSuiteContext';
import AddTaskModalForm from './AddTaskModalForm/AddTaskModalFormContainer';

class AddTask extends Component {

  constructor() {
    super();

    this.handleAddTask = this.handleAddTask.bind(this);
  }

  static contextType = ProjectSuiteContext;

  handleAddTask(values) {
    const { onAddTask, id } = this.props;
    const resourceType = this.context ? 'projectSuiteId' : 'projectId';

    return new Promise((resolveForm, rejectForm) => {
      onAddTask({
        values: {
          ...values,
          [resourceType]: id,
        },
        resolveForm,
        rejectForm,
      });
    });
  }

  render() {
    const { id } = this.props;
    const isMultiSite = this.context;

    const permission = isMultiSite
      ? frontendPermissions.PROJECT_SUITE_TASKS_ADD
      : frontendPermissions.ADD_TASK;

    return (
      <PermissionsSwitch>
        <Gateway condition={permission}>
          <AddTaskModalForm
            onSubmit={this.handleAddTask}
            resourceId={id}
            superSites={this.context}
          />
        </Gateway>
      </PermissionsSwitch>
    );
  }

}

AddTask.propTypes = {
  onAddTask: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default AddTask;
