import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DataTableRow from 'components/DataTable/DataTableRow/DataTableRow';
import DataTableText from 'components/DataTable/DataTableText/DataTableText';
import ThemedToggle from 'components/elements/ThemedToggle/ThemedToggle.style';
import pathwayConstants from '../../../constants';

class ZoneTableRow extends Component {

  static levelToBoolean(level) {
    return !!(level && level > 0);
  }

  constructor() {
    super();

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange() {
    const { deviceId, onPerformAction, item: { id, level } } = this.props;

    onPerformAction({
      deviceId,
      actionType: pathwayConstants.ACTION_SET_ZONE_STATE,
      values: { zoneId: id, value: !level },
    });
  }

  render() {
    const { item: { id, level }, isOnline } = this.props;

    return (
      <DataTableRow {...this.props}>
        <DataTableText />
        <DataTableText />
        <DataTableText />
        <ThemedToggle
          name={`zones.toggle.${id}`}
          checked={!!level}
          disabled={!isOnline}
          onChange={this.handleChange}
        />
      </DataTableRow>
    );
  }

}

ZoneTableRow.defaultProps = {
  ...DataTableRow.defaultProps,
};

ZoneTableRow.propTypes = {
  ...DataTableRow.propTypes,
  deviceId: PropTypes.string.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  isOnline: PropTypes.bool.isRequired,
  onPerformAction: PropTypes.func.isRequired,
};

export default ZoneTableRow;
