import styled from 'styled-components';
import { Loader } from 'semantic-ui-react';

export const StyledTempSiteMarker = styled.div``;

export const StyledLoader = styled(Loader)`
  &.ui.loader {
    margin-left: 1em;
  }
`;
