import isNull from 'lodash/isNull';
import isString from 'lodash/isString';

const processValueAfterTyping = value => {
  if (isNull(value)) {
    return null;
  }

  if (!isString(value)) {
    return null;
  }

  if (value === '+' || value === '-') {
    return 0;
  }

  if (value.substring(0, 2) === '+-') {
    return `-${value.substring(2, 5)}`;
  }

  if (value.substring(0, 2) === '-+') {
    return value.substring(2, 5);
  }

  return value;
};

export default processValueAfterTyping;
