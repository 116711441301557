import React from 'react';
import PropTypes from 'prop-types';

import Form from 'components/form/Form/Form';
import { Button } from 'components/atomic';
import {
  StyledTitle,
  StyledInputContainer,
  StyledFormContainer,
  StyledForm,
  StyledButtonContainer,
} from './AutoRenewSuspendedForm.style';
import { CommentField, YearsField } from './components';

const AutoRenewSuspendedForm = ({
  handleSubmit,
  submitting,
}) => (
  <StyledForm>
    <Form.Group>
      <StyledFormContainer>
        <StyledTitle>Years</StyledTitle>
        <StyledInputContainer>
          <YearsField
            name="years"
            defaultValue={1}
          />
        </StyledInputContainer>
      </StyledFormContainer>
    </Form.Group>
    <Form.Group>
      <CommentField
        name="comment"
      />
    </Form.Group>
    <Form.Group>
      <StyledButtonContainer>
        <Button
          primary
          onClick={handleSubmit}
          disabled={submitting}
        >
          Renew Site
        </Button>
      </StyledButtonContainer>
    </Form.Group>
  </StyledForm>
);

AutoRenewSuspendedForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default AutoRenewSuspendedForm;
