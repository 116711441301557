import { connect } from 'react-redux';

import { colorPickerActionCreators } from '../../utilities/actionCreators';

import ColorPicker from './ColorPicker';

export const mapDispatchToProps = {
  updateRGB: colorPickerActionCreators.updateRGB,
  updateRGBNewAction: colorPickerActionCreators.updateRGBNewAction,
};

export default connect(null, mapDispatchToProps)(ColorPicker);
