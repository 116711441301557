import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import AddDeviceModal from './AddDeviceModal/AddDeviceModalContainer';
import AddDeviceModalForm from './AddDeviceModalForm/AddDeviceModalFormContainer';

class AddDevice extends Component {

  constructor() {
    super();

    this.addDevice = this.addDevice.bind(this);
  }


  addDevice(values) {
    const { onAddDevice, projectId } = this.props;

    return new Promise((resolveForm, rejectForm) => {
      onAddDevice({ values: { ...values, projectId }, resolveForm, rejectForm });
    });
  }

  render() {
    return (
      <Fragment>
        <AddDeviceModal />
        <AddDeviceModalForm onSubmit={this.addDevice} />
      </Fragment>
    );
  }

}

AddDevice.propTypes = {
  projectId: PropTypes.string.isRequired,
  onAddDevice: PropTypes.func.isRequired,
};

export default AddDevice;
