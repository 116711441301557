import constants from 'dispatcherConst';

const addProjectSuiteIdContext = projectSuiteId => ({
  type: constants.ADD_PROJECT_SUITE_ID_CONTEXT,
  payload: {
    projectSuiteId,
  },
});

export default addProjectSuiteIdContext;
