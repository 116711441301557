import isString from 'lodash/isString';

import { actionTypes } from './constants';

const translateAction = str => {
  if (isString(str) && actionTypes[str]) {
    return actionTypes[str].text;
  }

  return str;
};

export default translateAction;
