import styled from 'styled-components';
import { Tab } from 'semantic-ui-react';

export const StyledDiv = styled.div`
  width: 100%;
  height: auto;
  overflow-x: hidden;
  font-size: 11px;
`;

export const StyledDivLoader = styled.div`
  width: 100%; 
  height: 40px;
`;

export const StyledTab = styled(Tab)`
  width: 100%;
`;

export default StyledDiv;
