import { call } from 'redux-saga/effects';

import { postPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';

import actionCreators from '../actionCreators';

function* readAllNotificationEvents(action) {
  const { endpoint, ...rest } = action.payload;

  const { response = {}, error } = yield call(postPath, endpoint);

  const params = {
    response: {
      ...response,
    },
    error,
    successDisp: actionCreators.readAllNotificationEventsSuccess,
    failDisp: actionCreators.readAllNotificationEventsFailure,
    ...rest,
  };

  yield call(processCall, params);
}

export default readAllNotificationEvents;
