import isNil from 'lodash/isNil';

import { occurrenceStatus } from '../constants';

const getIconTooltipMessage = status => {
  if (isNil(status)) {
    return null;
  }

  if (status === occurrenceStatus.SUCCESS) {
    return 'All tasks were successful';
  }

  if (status === occurrenceStatus.PARTIAL_SUCCESS) {
    return 'Only some of tasks were successful';
  }

  if (status === occurrenceStatus.FAILURE) {
    return 'All tasks failed';
  }

  if (status === occurrenceStatus.VALIDATION_ERROR) {
    return 'Task Scheduler disabled or overridden';
  }

  return null;
};

export default getIconTooltipMessage;
