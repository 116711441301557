import React from 'react';
import PropTypes from 'prop-types';

import DefinitionList from 'pages/Device/DefinitionList/DefinitionList';
import { StyledSegment, TableWrapper, StyledTableTitle } from './components';

const InputOutputTable = ({
  item: {
    dataInput,
    labelsInput,
    dataOutput,
    labelsOutput,
    showInputs,
    showOutputs,
  },
}) => (
  <StyledSegment>
    {showInputs && (
      <TableWrapper>
        <StyledTableTitle>Inputs</StyledTableTitle>
        <DefinitionList data={dataInput} labels={labelsInput} />
      </TableWrapper>
    )}
    {showOutputs && (
      <TableWrapper>
        <StyledTableTitle>Outputs</StyledTableTitle>
        <DefinitionList data={dataOutput} labels={labelsOutput} />
      </TableWrapper>
    )}
  </StyledSegment>
);

InputOutputTable.defaultProps = {
  item: {},
};

InputOutputTable.propTypes = {
  item: PropTypes.object,
};

export default InputOutputTable;
