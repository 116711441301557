import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import isNull from 'lodash/isNull';

import {
  StyledInput,
  StyledDropdown,
  StyledDiv,
} from './LocalLogsFilterParams.style';
import { checkArrayLength, computeFiltersObject } from './utilities';

const LocalLogsFilterParams = ({
  isLoading,
  logsDataExists,
  onSearch,
  availableOptions,
  filterParams,
}) => {
  const [searchStr, setSearchStr] = useState('');
  const search = e => {
    const { target: { value } } = e;
    const filterObject = computeFiltersObject(value, filterParams);
    setSearchStr(value);
    onSearch(filterObject);
  };

  const selectActionType = (e, data) => {
    const { value, text } = data;
    const filterObject = computeFiltersObject(value, filterParams, text);
    onSearch(filterObject);
  };

  useEffect(() => {
    if (isNull(filterParams.search)) {
      setSearchStr('');
    }
  }, [filterParams]);

  return logsDataExists && !isLoading && (
    <>
      <Grid.Column width={16}>
        <StyledDiv>
          <StyledInput
            icon="search"
            placeholder="Search..."
            onChange={search}
            value={searchStr}
          />
          {availableOptions.map(({ text, values, filterName }) => checkArrayLength(values)
            && (
              <StyledDropdown
                key={text}
                text={text}
                clearable
                options={values}
                selection
                onChange={selectActionType}
                selectOnBlur={false}
                value={filterParams[filterName]}
              />
            ))}
        </StyledDiv>
      </Grid.Column>
    </>
  );
};

LocalLogsFilterParams.defaultProps = {
  isLoading: false,
  logsDataExists: false,
};

LocalLogsFilterParams.propTypes = {
  isLoading: PropTypes.bool,
  logsDataExists: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
  availableOptions: PropTypes.array.isRequired,
  filterParams: PropTypes.object.isRequired,
};

export default LocalLogsFilterParams;
