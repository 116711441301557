class ActionModel {

  constructor(action = {}) {
    this.id = action.id || undefined;
    this.index = action.index;
    this.name = action.name;
    this.logicalDeviceId = action.logicalDeviceId;
    this.thing_name = action.thing_name;
    this.typeId = action.typeId;
    this.invalid = action.invalid || false;
    this.params = action.params || [];
  }

  static formatAction(action, actionTypes) {
    const {
      actionName,
      typeId,
      logicalDeviceId,
    } = action;
    const PARAMS_KEYS = actionTypes[typeId].params.map(item => item.key);
    const getKeys = Object.keys(action);
    const paramsKeys = getKeys.filter(key => PARAMS_KEYS.includes(key) && action[key] !== null);

    const paramsObject = paramsKeys.map(item => ({ key: item, value: action[item] }));

    const theAction = {
      name: actionName,
      typeId,
      logicalDeviceId,
      params: paramsObject,
    };

    return theAction;
  }

}

export default ActionModel;
