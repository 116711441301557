import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isNil from 'lodash/isNil';

import { PROJECTS_FIELD, singleProjectSuiteSelector } from 'helpers/selectors';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import withPolling from 'helpers/withPolling';
import { addPagination } from 'helpers/paginationHelpers';
import { withProjectSuitePermissions } from 'gateway';
import {
  actionCreators as projectSuitesActionCreators,
} from 'pages/ProjectSuites/utilities';

import ProjectSuiteProjectsTab from './ProjectSuiteProjectsTab';

export const mapStateToProps = (state, { match }) => {
  const { id: projectSuiteId } = match.params;
  const { projectsCount } = singleProjectSuiteSelector(state, projectSuiteId) || {};
  const displayMap = !isNil(projectsCount) && projectsCount > 0;

  return {
    projectSuiteId,
    displayMap,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withProjectSuitePermissions,
  withPolling(),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: PROJECTS_FIELD },
      ({ projectSuiteId }) => projectSuitesActionCreators.fetchProjectSuiteProjects(
        projectSuiteId, {
          fields: {
            [PROJECTS_FIELD]: [
              'name',
              'usersCount',
              'logicalDevicesCount',
              'construction',
              'state',
              'createdAt',
            ],
          },
          sort: 'name',
        }),
    ),
  ),
)(ProjectSuiteProjectsTab);
