import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';

export const changePassword = params => ({
  type: constants.CHANGE_PASSWORD_REQUEST,
  payload: {
    params,
    endpoint: endpoints.CHANGE_PASSWORD,
  },
});

export const changePasswordSuccess = response => ({
  type: constants.CHANGE_PASSWORD_REQUEST_SUCCESS,
  payload: { response, message: messages.PASSWORD_CHANGED },
});

export const changePasswordFailure = error => ({
  type: constants.CHANGE_PASSWORD_REQUEST_FAILED,
  payload: { error },
});
