import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledGridChannelDisplayItemName,
  StyledGridChannelDisplayItemValue,
  StyledGridChannelDisplayItem,
} from './GridChannelDisplayItem.style';

const GridChannelDisplayItem = ({ name, value, status, isMarked }) => (
  <StyledGridChannelDisplayItem isMarked={isMarked} status={status}>
    <StyledGridChannelDisplayItemName>{name}</StyledGridChannelDisplayItemName>
    <StyledGridChannelDisplayItemValue>{value}</StyledGridChannelDisplayItemValue>
  </StyledGridChannelDisplayItem>
);

GridChannelDisplayItem.defaultProps = {
  isMarked: null,
};

GridChannelDisplayItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  isMarked: PropTypes.bool,
};

export default GridChannelDisplayItem;
