import styled from 'styled-components';

const MultipleIconWrapper = styled.div`
  & svg {
    margin-top: 2px;
    margin-left: 10px;
    margin-right: 5px;
  }
`;

export default MultipleIconWrapper;
