import { takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';

import { addTaskScheduler } from './addTaskScheduler';
import fetchProjectDevices from './fetchProjectDevices';

function* saga() {
  yield takeLatest(constants.ADD_TASK_SCHEDULER, addTaskScheduler);
  yield takeLatest(constants.TASK_SCHEDULER_FETCH_PROJECT_DEVICES_REQUEST, fetchProjectDevices);
}

export default saga;
