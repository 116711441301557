import React, { useState } from 'react';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';

import { SelectDate } from 'components/core';
import { initialFilterParams } from '../../utilities/constants';
import { StyledDiv } from './LogsFilterParams.style';
import { processLogsParamsError, dateToISOString } from './utilities';
import {
  LogsParamsError,
  ActionTypesDropdown,
  GenerateLogsButton,
  PageSizeDropdown,
} from './components';

const LogsFilterParams = ({
  onGetLogs,
  projectId,
  actionsList,
  displayActionsListDropdown,
  timezoneId,
  companyId,
  tenant,
  setFilterParams,
  onSetActiveRequestId,
}) => {
  const today = new Date();
  const [dateFrom, setDateFrom] = useState(today);
  const [dateTo, setDateTo] = useState(today);
  const [actionType, setActionType] = useState([]);
  const [pageSize, setPageSize] = useState(100);

  const isButtonDisabled = processLogsParamsError({ dateFrom, dateTo });

  const data = {
    action: 'get_logs',
    dateFrom: dateToISOString(dateFrom),
    dateTo: dateToISOString(dateTo),
    showAdminEntries: true,
    project_id: projectId,
    request_id: uuidv4(),
    action_types: actionType,
    page_size: pageSize,
  };

  if (timezoneId) {
    data.time_zone = timezoneId;
  }

  if (companyId) {
    data.company_id = companyId;
  }

  const getLogs = () => {
    const { request_id: requestId } = data;

    onGetLogs(data, tenant);
    setFilterParams(initialFilterParams);
    onSetActiveRequestId(requestId);
  };

  return (
    <>
      <Grid.Column width={16}>
        <StyledDiv>
          <SelectDate
            label="Date from"
            onDateChange={setDateFrom}
          />
          <SelectDate
            label="Date to"
            onDateChange={setDateTo}
          />
          {displayActionsListDropdown && (
            <ActionTypesDropdown
              actionsList={actionsList}
              setActionType={setActionType}
            />
          )}
          <PageSizeDropdown setPageSize={setPageSize} />
          <GenerateLogsButton
            isButtonDisabled={isButtonDisabled}
            getLogs={getLogs}
          />
        </StyledDiv>
      </Grid.Column>
      <Grid.Column width={16}>
        <LogsParamsError showError={isButtonDisabled} />
      </Grid.Column>
    </>
  );
};

LogsFilterParams.defaultProps = {
  displayActionsListDropdown: false,
  timezoneId: null,
  companyId: null,
  tenant: '',
};

LogsFilterParams.propTypes = {
  projectId: PropTypes.string.isRequired,
  onGetLogs: PropTypes.func.isRequired,
  actionsList: PropTypes.array.isRequired,
  displayActionsListDropdown: PropTypes.bool,
  timezoneId: PropTypes.string,
  companyId: PropTypes.string,
  tenant: PropTypes.string,
  setFilterParams: PropTypes.func.isRequired,
  onSetActiveRequestId: PropTypes.func.isRequired,
};

export default LogsFilterParams;
