import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import StyledIcon from './TipIcon.style';

const TipIcon = ({ message, editMode, position }) => (
  <Popup
    content={message}
    trigger={(
      <StyledIcon
        color="orange"
        name="info circle"
        message={message}
        onClick={e => e.stopPropagation()}
        editMode={editMode}
      />
    )}
    position={position}
  />
);

TipIcon.defaultProps = {
  message: null,
  position: 'top center',
};

TipIcon.propTypes = {
  message: PropTypes.string,
  editMode: PropTypes.bool.isRequired,
  position: PropTypes.string,
};

export default TipIcon;
