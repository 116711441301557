import React, { Fragment } from 'react';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { StyledHeader } from '../../SiteInformation.style';
import { EnableSiteModalForm } from './components';
import RenewalDate from '../RenewalDate/RenewalDate';

const EnableSite = ({
  onUpdateSiteConstruction,
  siteId,
  property,
  renewalDate,
  isConstruction,
  isDemo,
}) => {
  if (!isConstruction && !isDemo) {
    return <RenewalDate renewalDate={renewalDate} />;
  }

  const handleUpdateSiteConstruction = () => {
    const formValue = {
      [property]: false,
    };

    return new Promise((resolveForm, rejectForm) => {
      onUpdateSiteConstruction({
        values: { id: siteId, ...formValue },
        resolveForm,
        rejectForm,
      });
    });
  };
  const form = `editSiteProperty.${property}`;
  const checkDemo = () => {
    if (isDemo) {
      return <span>Demo mode</span>;
    }

    return (
      <EnableSiteModalForm
        form={form}
        name={property}
        onSubmit={handleUpdateSiteConstruction}
      />
    );
  };

  return (
    <Fragment>
      <Grid.Column width={8}>
        <StyledHeader as="h5">Finish construction mode</StyledHeader>
      </Grid.Column>
      <Grid.Column width={8}>
        {checkDemo()}
      </Grid.Column>
    </Fragment>
  );
};

EnableSite.defaultProps = {
  renewalDate: undefined,
};

EnableSite.propTypes = {
  onUpdateSiteConstruction: PropTypes.func.isRequired,
  siteId: PropTypes.string.isRequired,
  property: PropTypes.string.isRequired,
  renewalDate: PropTypes.string,
  isConstruction: PropTypes.bool.isRequired,
  isDemo: PropTypes.bool.isRequired,
};

export default EnableSite;
