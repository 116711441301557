import FirmwareV1 from 'components/Firmware/FirmwareV1Container';
import DeviceFetcher from 'layouts/DeviceFetcher/DeviceFetcherContainer';
import PathwayHardwareV1 from 'layouts/Pathway/components/PathwayHardwareV1/PathwayHardwareV1ELinkContainer';
import PathwayNetworkInterfaceV1 from 'layouts/Pathway/components/PathwayNetworkInterfaceV1/PathwayNetworkInterfaceV1Container';
import PathwayELinkPath from 'layouts/Pathway/components/PathwayELinkPathV1/PathwayELinkPathV1Container';
import PathwayOutputV1 from 'layouts/Pathway/components/PathwayOutputV1/PathwayOutputV1';
import PathwayRebootDeviceV1 from 'layouts/Pathway/components/PathwayRebootDeviceV1/PathwayRebootDeviceV1Container';
import PathwayIdentifyV1 from 'layouts/Pathway/components/PathwayIdentifyV1/PathwayIdentifyV1PathportContainer';

export default [
  {
    menuItem: 'Overview',
    path: '/overview',
    fetcher: DeviceFetcher,
    rows: [
      {
        columns: [
          {
            computer: 8,
            mobile: 16,
            sections: [
              {
                title: 'Hardware',
                component: PathwayHardwareV1,
              },
            ],
          },
          {
            computer: 8,
            mobile: 16,
            sections: [
              {
                title: 'Network interface',
                component: PathwayNetworkInterfaceV1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Path',
    path: '/path',
    fetcher: DeviceFetcher,
    rows: [
      {
        columns: [
          {
            computer: 16,
            mobile: 16,
            sections: [
              {
                title: 'Paths',
                component: PathwayELinkPath,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'DMX Levels',
    path: '/dmx_levels',
    rows: [
      {
        columns: [
          {
            sections: [
              {
                title: 'DMX Levels',
                component: PathwayOutputV1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Firmware',
    path: '/firmware',
    fetcher: DeviceFetcher,
    rows: [
      {
        columns: [
          {
            sections: [
              {
                title: 'Firmware',
                component: FirmwareV1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Advanced',
    path: '/advanced',
    fetcher: DeviceFetcher,
    rows: [
      {
        columns: [
          {
            computer: 8,
            tablet: 8,
            mobile: 16,
            sections: [
              {
                title: 'Reboot device',
                component: PathwayRebootDeviceV1,
              },
            ],
          },
          {
            computer: 8,
            tablet: 8,
            mobile: 16,
            sections: [
              {
                title: 'Identify',
                component: PathwayIdentifyV1,
              },
            ],
          },
        ],
      },
    ],
  },
];
