import styled from 'styled-components';
import { lighten } from 'polished';
import get from 'lodash/get';

import UserTableRow from '../../../../../../../components/structural/UserTableRow/UserTableRow';

export const StyledUserTableRow = styled(UserTableRow)`
  ${({ highlighted, theme }) => highlighted && `
    background-color: ${lighten(0.4, get(theme, 'palette.primaryColor'))};

    .ui.selectable.table tbody tr&:hover {
      background-color: ${lighten(0.3, get(theme, 'palette.primaryColor'))} !important;
    }
  `}
`;

export default StyledUserTableRow;
