import { compose } from 'redux';
import { connect } from 'react-redux';
import last from 'lodash/last';

import {
  PROJECT_SUITES_FIELD,
  singleProjectSuiteSelector,
  computedProjectSuiteTreePathSelector,
} from 'helpers/selectors';
import { deepEqualMemoizeOne, modifySelfWithResourceName } from 'helpers/sharedMethods';
import permissionsActionCreators from '../../../../utilities/permissions/permissionsActionCreators';
import { PermissionsTreeItem } from '../../../../components';
import { sortPaths } from '../../../../utilities';

// eslint-disable-next-line import/no-mutable-exports
let ProjectSuitePermissionsTreeItemContainer;

export const makeMapState = () => {
  const memoPaths = deepEqualMemoizeOne();

  return function mapStateToProps(state, { path, resourceId }) {
    const { visibleName } = singleProjectSuiteSelector(state, resourceId);
    const { open, permissionCount, permissionId, ...paths } = computedProjectSuiteTreePathSelector(
      state,
      {
        projectSuiteId: resourceId,
        path,
      },
    );
    const label = modifySelfWithResourceName(last(path.split('.')), visibleName, 'Multi-site');

    const sortedPaths = sortPaths(paths);

    return {
      open,
      label,
      paths: memoPaths(sortedPaths),
      PermissionsTreeItemContainer: ProjectSuitePermissionsTreeItemContainer,
    };
  };
};

export const mapDispatchToProps = {
  onToggleOpen: (projectSuiteId, path) => permissionsActionCreators.toggleResourceTreeOpen(
    PROJECT_SUITES_FIELD,
    projectSuiteId,
    path,
  ),
};

ProjectSuitePermissionsTreeItemContainer = compose(
  connect(makeMapState, mapDispatchToProps),
)(PermissionsTreeItem);

export default ProjectSuitePermissionsTreeItemContainer;
