const getDefaultValueForParamType = (paramType, param = {}) => {
  if (param.default) {
    return param.default;
  }

  if (param.uiElement === 'releaseSecs') {
    return '2.00';
  }

  if (param.uiElement === 'percentage') {
    return '0.00';
  }

  if (paramType === 'string') {
    return '';
  }

  if (paramType === 'array') {
    return [];
  }

  if (paramType === 'boolean') {
    return false;
  }

  return null;
};

export default getDefaultValueForParamType;
