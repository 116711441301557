import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import tasksActionCreators from 'application/tenant/utilities/tasks/tasksActionCreators';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { TASKS_FIELD, SELECT_TASKS } from 'helpers/selectors';
import { addPagination } from 'helpers/paginationHelpers';

import { actionCreators as projectSuiteActionCreators } from 'pages/ProjectSuites/utilities';

import { actionCreators, selectors } from '../../../../utilities';
import TaskHistory from './TaskHistory';

export const mapStateToProps = (state, {
  match,
  selectedResourceId,
  resourceType = 'project',
}) => {
  const { id: resourceId } = match.params;
  const allTasks = selectors.availableTasksSelector(state) || [];

  return {
    resourceId: selectedResourceId || resourceId,
    resourceType,
    tasks: allTasks.filter(task => task.actions && task.actions.length > 0),
    selectedTasks: selectors.selectedTasksSelector(state),
    isLoadingTasks: selectors.isLoadingTasksSelector(state),
  };
};

const mapDispatchToProps = {
  onTaskSelected: actionCreators.addSelectedTask,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  chainedLoaderWithSaga(
    addPagination(
      {
        resourceType: TASKS_FIELD,
        included: true,
        componentName: SELECT_TASKS,
      },
      ({ resourceId, resourceType }) => (
        resourceType === 'project'
          ? tasksActionCreators.fetchTasks(resourceId, {
            sort: 'name',
          }) : projectSuiteActionCreators.fetchProjectSuiteProjectsTasks(
            {
              recipientType: 'projectSuite',
              recipientId: resourceId,
              'projectSuiteIds[]': [resourceId],
              'projectIds[]': [],
              sort: 'name',
            },
          )
      )
      ,
    ),
  ),
)(TaskHistory);
