import styled, { css } from 'styled-components';
import { Accordion, Icon } from 'semantic-ui-react';

export const StyledAccordion = styled(Accordion)`
  border-radius: 8px !important;
  margin-bottom: 8px !important;
`;

export const StyledAccordionTitle = styled(Accordion.Title)`
  padding: 0 !important;
  height: 100%;
  height: 60px;

  ${({ active }) => active && css`
    border-bottom: 1px solid rgba(34,36,38,.15);
  `}  
`;

export const StyledAccordionContent = styled(Accordion.Content)`
  padding: 0 !important;
`;

export const StyledMapHeader = styled('div')`
  width: 100%;
  height: 60px;
  opacity: 1;
  background-image: url("/assets/sites-map-header-new.png");
  background-repeat: no-repeat;
  background-size: cover;
  transition: height 0.8s;

  ${({ active }) => active && css`
    background-image: none;
  `};
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
`;

export const StyledTitle = styled.span`
  position: relative;
  margin-top: -40px;
  color: rgba(0,0,0,0.87) !important;
  padding-left: 1.5em;
  padding-right: 1.5em;
  font-size: 1.20em;
  font-weight: 700;
  letter-spacing: .02em;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    color: rgba(0,0,0,0.87) !important;
  }
`;

export default StyledAccordion;
