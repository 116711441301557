import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { deepEqualMemoizeOne } from 'helpers/sharedMethods';
import {
  loginCheckedSelector,
  currentUserIdSelector,
  recentlyLoggedOutSelector,
} from 'helpers/selectors';
import TenantSwitch from './TenantSwitch';

const makeMapState = () => {
  const memoLocation = deepEqualMemoizeOne();

  return function mapStateToProps(state, { location }) {
    return {
      location: memoLocation(location),
      loginChecked: loginCheckedSelector(state),
      currentUserId: currentUserIdSelector(state),
      recentlyLoggedOut: recentlyLoggedOutSelector(state),
    };
  };
};

export default compose(
  withRouter,
  connect(makeMapState),
)(TenantSwitch);
