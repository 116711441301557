import styled from 'styled-components';
import colors from 'themes/colors';
import { rgba } from 'polished/lib/index';

import { injectStyles } from 'themes/mixins';
import { InputWrapper } from 'components/form/FormField/FormField.style';

export default styled.dl`
  :nth-child(odd) {
    background-color: ${rgba(colors.black, 0.05)};
  }
  dt, dd {
    color: ${colors.black};
  }
  &.marked {
    background-color: ${colors.positiveBackground};

    & dt, dd {
      color: ${colors.positiveText};
    }
  }

  ${({ theme: { tables: { listItems } = {} } }) => injectStyles(listItems)};

  /* stylelint-disable no-descending-specificity */
  display: flex;
  margin: 0;
  padding: 0 0.5em;
  justify-content: space-between;  
  align-items: center;

  dt {
    width: 30%;
    margin-right: 1em;
    opacity: 0.8;
  }

  dd {
    width: 70%;
    margin: 0;
    text-align: right;
    word-break: break-word;
    
    ${InputWrapper} {
      flex-direction: column;
    }
    
    .ui.input input {
      max-width: 14em;
    }
  }
`;
