import styled from 'styled-components';

import Form from 'components/form/Form/Form';
import { media } from 'themes/mixins';

const StyledRightFormField = styled(Form.Field)`
  .ui.form .fields>&.field {
    width: 100%;
    align-self: flex-end;
    padding-right: 0;

    ${media.md`
      width: auto;
      margin-left: auto;
    `};
  }
`;

export default StyledRightFormField;
