import { fetchOutput, fetchOutputSuccess, fetchOutputFailure } from './fetchOutput';
import { fetchPatches, fetchPatchesSuccess, fetchPatchesFailure } from './fetchPatches';
import { setProtocol } from './setProtocol';
import { setUniverse } from './setUniverse';

export default {
  fetchOutput,
  fetchOutputSuccess,
  fetchOutputFailure,
  fetchPatches,
  fetchPatchesSuccess,
  fetchPatchesFailure,
  setProtocol,
  setUniverse,
};
