import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Grid } from 'semantic-ui-react';

import Form from 'components/form/Form/Form';
import DropdownField from 'components/form/DropdownField/DropdownField';
import TextField from 'components/form/TextField/TextField';
import ModalForm from 'components/form/ModalForm/ModalForm';
import { nonRequiredDomain } from 'components/form/formValidators';
import Connector from './DomainModalForm.style';

export const apiDomainFormFields = {
  domain: 'domain',
  domainSelect: 'domainSelect',
};

const {
  REACT_APP_API_URL: primary,
  REACT_APP_API_URL_SECONDARY: secondary,
} = process.env;

const apiDomains = [primary, secondary]
  .filter(val => val)
  .map(domain => ({ key: domain, text: domain, value: domain }));

const DomainModalForm = ({ isSelectDisabled, ...rest }) => (
  <ModalForm
    header="Set API domain"
    trigger="Set API domain"
    size="mini"
    {...rest}
  >
    <Grid>
      <Grid.Row>
        <Grid.Column mobile={16}>
          <Form.Group>
            <Field
              name={apiDomainFormFields.domainSelect}
              component={DropdownField}
              options={apiDomains}
              disabled={isSelectDisabled}
            />
          </Form.Group>
          <Connector>or</Connector>
        </Grid.Column>
        <Grid.Column mobile={16}>
          <Form.Group>
            <Field
              name={apiDomainFormFields.domain}
              component={TextField}
              placeholder="Enter custom"
              validate={nonRequiredDomain}
            />
          </Form.Group>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </ModalForm>
);

DomainModalForm.propTypes = {
  isSelectDisabled: PropTypes.bool.isRequired,
};

export default DomainModalForm;
