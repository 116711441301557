import isNull from 'lodash/isNull';
import get from 'lodash/get';

const getDeviceIdFromAction = action => {
  if (isNull(action)) {
    return null;
  }

  if (!action.payload) {
    return null;
  }

  const {
    deviceId,
  } = action.payload.response;

  if (deviceId) {
    return deviceId;
  }

  const logicalDeviceId = get(action, 'payload.response.identifier.logicalDeviceId', null);
  if (logicalDeviceId) {
    return logicalDeviceId;
  }

  return null;
};

export default getDeviceIdFromAction;
