import React from 'react';
import PropTypes from 'prop-types';

import { getDisplayName } from 'helpers/sharedMethods';
import { permissionsMatchRequirements } from '../utilities';

const permissionsPass = (condition, prop) => WrappedComponent => {
  const PermissionsPass = ({
    permissionsResourceType,
    permissionsLoading,
    resourceId,
    resourcePermissionsReady,
    superadmin,
    userPermissions,
    contexts,
    ...rest
  }) => {
    if (permissionsLoading || (resourceId && !resourcePermissionsReady)) {
      return null;
    }

    const permissionsMatch = permissionsMatchRequirements({
      condition,
      permissionsResourceType,
      resourceId,
      superadmin,
      userPermissions,
      contexts,
    });

    const props = {
      ...rest,
      resourceId,
      [prop]: permissionsMatch,
    };

    return <WrappedComponent {...props} />;
  };

  PermissionsPass.defaultProps = {
    permissionsResourceType: null,
    permissionsLoading: true,
    resourceId: null,
    resourcePermissionsReady: false,
    superadmin: false,
    contexts: null,
    userPermissions: null,
  };

  PermissionsPass.propTypes = {
    permissionsResourceType: PropTypes.string,
    permissionsLoading: PropTypes.bool,
    resourceId: PropTypes.string,
    resourcePermissionsReady: PropTypes.bool,
    superadmin: PropTypes.bool,
    contexts: PropTypes.array,
    userPermissions: PropTypes.array,
  };

  PermissionsPass.displayName = `PermissionsPass(${getDisplayName(WrappedComponent)})`;

  return PermissionsPass;
};

export default permissionsPass;
