import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import { StyledTokenButton } from '../../AddScheduleModalForm.style';
import { convertDate, convertTime } from './utilities';

const TokenButton = ({ value, removeToken, isTime }) => {
  const handleClick = e => removeToken(e, value);
  const handlePreventClick = e => e.preventDefault();
  const formatValue = isTime ? convertTime(value) : convertDate(value);

  return (
    <StyledTokenButton
      icon
      compact
      labelPosition="right"
      onClick={handlePreventClick}
    >
      {formatValue}
      <Icon
        name="delete"
        onClick={handleClick}
      />
    </StyledTokenButton>
  );
};

TokenButton.defaultProps = {
  isTime: false,
};

TokenButton.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  removeToken: PropTypes.func.isRequired,
  isTime: PropTypes.bool,
};

export default TokenButton;
