import styled from 'styled-components';
import { Table } from 'semantic-ui-react';

const ActionRow = styled(Table.Row)`
  @media only screen and (max-width: 991px) {
    padding: 0px 0px !important;
    border: 0 !important;
  }

  .ui.table.noHover tbody tr,
  .ui.table tbody tr td.selectable {
    border-bottom: solid 0px red !important;
  }

  .ui.table.noHover tbody tr:hover,
  .ui.table tbody tr td.selectable:hover {
    background: rgba(0, 0, 0, 0) !important;
    color: rgba(0, 0, 0, 1) !important;
  }

  .ui.table.noHover {
    border: 0;
    background-color: transparent;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
`;

export default ActionRow;
