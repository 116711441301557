export const colorList = [
  '#FFFFFF',
  '#DF4953',
  '#EB8A00',
  '#F9AB32',
  '#FCF100',
  '#5AD02D',
  '#77AA00',
  '#4EC6CD',
  '#3FBCF5',
  '#8664BB',
  '#EE95C0',
];

export const modeOptions = [
  {
    key: 'forceExecution',
    value: 'forceExecution',
    text: 'Off',
  },
  {
    key: 'safeTaskExecution',
    value: 'safeTaskExecution',
    text: 'On per Device',
  },
  {
    key: 'safeTaskSchedulerExecution',
    value: 'safeTaskSchedulerExecution',
    text: 'On',
  },
];

export const MODE_HELP_TEXT = 'Optionally choose if this Scheduler should not run if Devices are offline during each occurrence.';

export const formFields = {
  name: 'name',
  description: 'description',
  occurrenceColour: 'occurrenceColour',
  enabled: 'enabled',
  mode: 'mode',
  hidden: 'hidden',
};
