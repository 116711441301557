import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';

import formNames from 'components/form/formNames';
import { validateOnSubmit } from 'components/form/formConfigs';
import AddActionModalForm from './LinkFileModalForm';

export default compose(
  withRouter,
  reduxForm({
    form: formNames.linkFileToFirmware,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    ...validateOnSubmit,
  }),
)(AddActionModalForm);
