import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { getDisplayName } from 'helpers/sharedMethods';

const withResetSecretKey = WrappedComponent => {
  const WithResetSecretKey = ({ onResetSecretKey, item: { id } }) => {
    const [resetting, setResetting] = useState(false);
    const handleClick = async () => {
      setResetting(true);

      await new Promise(resolveForm => onResetSecretKey({ id, resolveForm }));

      setResetting(false);
    };

    return (
      <WrappedComponent
        onClick={handleClick}
        disabled={resetting}
        title="Reset secret key"
      />
    );
  };

  WithResetSecretKey.displayName = `WithResetSecretKey(${getDisplayName(WrappedComponent)})`;

  WithResetSecretKey.propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    onResetSecretKey: PropTypes.func.isRequired,
  };

  return WithResetSecretKey;
};

export default withResetSecretKey;
