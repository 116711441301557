import styled from 'styled-components';
import { FaTools } from 'react-icons/fa';

const StyledInConstructionSiteIcon = styled(FaTools)`
  margin-left: 8px;
`;

export const StyledDiv = styled.div`
  display: inline-block;
`;

export default StyledInConstructionSiteIcon;
