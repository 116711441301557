import { compose } from 'redux';
import { connect } from 'react-redux';

import themes from 'themes';
import chainedLoader from 'helpers/chainedLoader/chainedLoaderContainerWithPagination';
import {
  currentCompanyThemeNameSelector,
  currentCompanyFetchingSelector,
  currentCompanyErrorSelector,
} from 'helpers/selectors';
import currentCompanyActionCreators from 'themes/currentCompanyActionCreators';
import ThemeProvider from './ThemeProvider';


const mapStateToProps = state => ({
  theme: themes[currentCompanyThemeNameSelector(state)],
  chainedLoaderProps: {
    currentCompany: {
      fetching: currentCompanyFetchingSelector(state),
      error: currentCompanyErrorSelector(state),
    },
  },
});

export default compose(
  connect(mapStateToProps),
  chainedLoader(
    { name: 'currentCompany' },
    () => currentCompanyActionCreators.fetchCurrentCompany(),
  ),
)(ThemeProvider);
