import toString from 'lodash/toString';

import { getRemoteDeviceStatus } from '../utilities';

class UnjoinedRemoteDeviceModel {

  constructor(device = {}) {
    this.id = device.id && toString(device.id);
    this.address = device.address;
    this.type = device.type;
    this.serial = device.serial;
    this.firmware = device.firmware;
  }

  get visibleAddress() {
    if (parseInt(this.address, 10) === 16) {
      return 'Manual';
    }

    return this.address;
  }

  get status() {
    return getRemoteDeviceStatus(this.firmware);
  }

}

export default UnjoinedRemoteDeviceModel;
