import styled from 'styled-components';

import { Button } from 'components/atomic';

export const StyledPermissionsTree = styled.div`
  margin-top: 45px;
  margin-right: 5px;
`;

export const StyledProjectTitle = styled(Button)`
  &.ui.button {
    text-align: left;
    height: 40px;
    margin-bottom: 5px;
    padding: 12px;
    font-size: 18px;
    width: 100%;
  }
`;
