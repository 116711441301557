import isEmpty from 'lodash/isEmpty';

import { getUniqueResourcesFromNotificationEvents } from '.';

const getDefaultNotificationFilters = notificationEvents => {
  if (isEmpty(notificationEvents)) {
    return {};
  }

  const resources = getUniqueResourcesFromNotificationEvents(notificationEvents);

  const transformed = Object.values(resources)
    .reduce((acc, resourceArray) => {

      resourceArray.forEach(resource => {
        acc[resource.key] = {
          ...resource,
        };
      });

      return acc;

    }, {});

  return transformed;
};

export default getDefaultNotificationFilters;
