import styled from 'styled-components';
import ActionCell from '../../../../ActionCell/ActionCell.style';

export const ParamCell = styled(ActionCell)`
  font-size: 12px;

  &:nth-of-type(1) {
    padding-left: 23px !important;
    width: 23% !important;

    @media only screen and (max-width: 991px) {
      padding-left: 0px !important;
    }
  }

  &:nth-of-type(2) {
    width: 77% !important;
  }

  padding-top: 9px !important;
  padding-bottom: 0.1em !important;
`;

export default ParamCell;
