import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

const OneTimePasswordField = ({ input: { onChange } }) => (
  <Input
    onChange={onChange}
    placeholder="Enter a 6-digit code"
  />
);

OneTimePasswordField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }).isRequired,
};

export default OneTimePasswordField;
