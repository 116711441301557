import { put, select } from 'redux-saga/effects';
import selectors from '../selectors';
import actionCreators from '../actionCreators';

function* updateOverrideControls(action) {
  const {
    projectId,
    pageId,
    controlId,
    values,
    ...rest
  } = action.payload;

  const { pages, ...attributes } = (yield select(selectors.projectControlUISelector))(projectId);

  const newPages = pages.map(({ index, overrideControls, ...page }) => {
    if (index === pageId) {
      const newControls = overrideControls.map(({ index: controlIndex, ...overrideControl }) => {
        if (controlIndex === controlId) {
          return {
            index: controlIndex,
            ...overrideControl,
            ...values,
          };
        }

        return {
          index: controlIndex,
          ...overrideControl,
        };
      });

      return {
        index,
        overrideControls: newControls,
        ...page,
      };
    }

    return {
      index,
      overrideControls,
      ...page,
    };
  });

  yield put(actionCreators.updateControlUI({
    pages: newPages,
    ...attributes,
    ...rest,
  }));
}

export default updateOverrideControls;
