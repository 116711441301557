import React from 'react';
import PropTypes from 'prop-types';

import { ChangePasswordForm } from './components';

const ChangePassword = ({ currentUserId, onChangePassword }) => {
  const handleChangePassword = async values => new Promise(
    (resolveForm, rejectForm) => (
      onChangePassword({ currentUserId, values, resolveForm, rejectForm })
    ),
  );

  return (
    <ChangePasswordForm onSubmit={handleChangePassword} />
  );
};

ChangePassword.propTypes = {
  currentUserId: PropTypes.string.isRequired,
  onChangePassword: PropTypes.func.isRequired,
};

export default ChangePassword;
