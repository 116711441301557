import { compose } from 'redux';
import { connect } from 'react-redux';

import { PROJECT_SUITES_FIELD, currentUserIdSelector } from 'helpers/selectors';
import { permissionNeedsConfirmation } from 'helpers/sharedMethods';
import { PermissionsGridItem } from '../../../../components';
import permissionsActionCreators from '../../../../utilities/permissions/permissionsActionCreators';
import { selectors } from '../../../../utilities/permissions';

export const makeMapState = () => (state, { resourceId, path, userId }) => {
  const currentUserId = currentUserIdSelector(state);
  const {
    permissionCount,
    userCount,
    permissionId,
  } = selectors.userPermissionDataSelector(
    state,
    {
      resourceId,
      resourceType: PROJECT_SUITES_FIELD,
      userId,
      path,
    },
  );
  const copyUserId = selectors.permissionsProjectSuiteCopyUserIdSelector(
    state,
    {
      projectSuiteId: resourceId,
    },
  );

  return {
    isOn: !!userCount,
    isPartial: permissionCount && permissionCount > userCount,
    permissionId,
    needsConfirmation: permissionNeedsConfirmation(path),
    isCurrentUser: currentUserId === userId,
    copyUserId,
  };
};

const mapDispatchToProps = {
  onToggleUserPermission: (
    userId,
    resourceId,
    permissionId,
    isOn,
  ) => permissionsActionCreators.toggleUserPermission(
    userId,
    PROJECT_SUITES_FIELD,
    resourceId,
    permissionId,
    isOn,
  ),
  onToggleUserHeaderPermission: (
    userId,
    resourceId,
    path,
    isOn,
    isPartial,
    isProject,
  ) => permissionsActionCreators.toggleUserHeaderPermission(
    userId,
    PROJECT_SUITES_FIELD,
    resourceId,
    path,
    isOn,
    isPartial,
    isProject,
  ),
};

export default compose(connect(makeMapState, mapDispatchToProps))(PermissionsGridItem);
