import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';

import { uploadsStatusFlagsType } from 'data/types';
import { PortalThemedLinkButton } from 'components/atomic';
import UploadsControlsWrapper from './UploadsControls.style';

class UploadsControls extends PureComponent {

  constructor() {
    super();

    this.handlePause = this.handlePause.bind(this);
    this.handleResume = this.handleResume.bind(this);
  }

  handlePause(e) {
    const { suspendUpload } = this.context;

    e.stopPropagation();
    suspendUpload();
  }

  handleResume(e) {
    const { continueUpload } = this.context;

    e.stopPropagation();
    continueUpload();
  }

  render() {
    const {
      completed,
      fetching,
      initialized,
      paused,
      suspended,
    } = this.props;

    return (
      <UploadsControlsWrapper>
        {!completed && (
          <Fragment>
            <PortalThemedLinkButton
              onClick={this.handleResume}
              disabled={!suspended || fetching || !initialized}
            >
              Continue
            </PortalThemedLinkButton>
            <PortalThemedLinkButton
              onClick={this.handlePause}
              disabled={suspended || fetching || paused || !initialized}
            >
              Suspend
            </PortalThemedLinkButton>
          </Fragment>
        )}
      </UploadsControlsWrapper>
    );
  }

}

UploadsControls.contextTypes = {
  suspendUpload: PropTypes.func.isRequired,
  continueUpload: PropTypes.func.isRequired,
};

UploadsControls.propTypes = {
  ...uploadsStatusFlagsType.isRequired,
};

export default UploadsControls;
