import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import {
  StyledTable,
  StyledCell,
} from './DropdownTable.style';
import { renderId } from './utilities';

const DropdownTable = ({ data }) => (
  <StyledTable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Id</Table.HeaderCell>
        <Table.HeaderCell>Name</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {data && data.map(({ id, name, missingTrigger, type }) => (
        <Table.Row key={`${id}-${name}`}>
          <StyledCell>{renderId(id, type)}</StyledCell>
          <StyledCell missing={missingTrigger}>{name}</StyledCell>
        </Table.Row>
      ))}
    </Table.Body>
  </StyledTable>
);

DropdownTable.defaultProps = {
  data: false,
};

DropdownTable.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.array,
  ]),
};

export default DropdownTable;
