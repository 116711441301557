import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { singleProjectSelector } from 'helpers/selectors';
import siteActionCreators from '../../../../utilities/actionCreators';
import IsDemo from './IsDemo';

export const mapStateToProps = (state, { match }) => {
  const { id: siteId } = match.params;
  const { isDemo } = singleProjectSelector(state, siteId);

  return {
    siteId,
    isDemo,
  };
};

const mapDispatchToProps = {
  onUpdateSiteDemo: siteActionCreators.updateSiteDemo,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(IsDemo);
