import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormGroup } from 'semantic-ui-react';

import * as validator from 'components/form/formValidators';
import TextField from 'components/form/TextField/TextField';
import ModalForm from 'components/form/ModalForm/ModalForm';

const InviteUserModalForm = props => {
  const { submitting } = props;

  return (
    <ModalForm
      header="Invite user"
      trigger="Invite user"
      {...props}
    >
      <FormGroup inline>
        <Field
          icon="mail"
          iconPosition="left"
          name="email"
          component={TextField}
          type="email"
          loading={submitting}
          label="Email"
          validate={validator.nonRequiredEmail}
        />
      </FormGroup>
    </ModalForm>
  );
};

InviteUserModalForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default InviteUserModalForm;
