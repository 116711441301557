import isNil from 'lodash/isNil';

import generateNewPage from './generateNewPage';

const addNewPage = (pages, name, paginationIndex, pageType) => {
  if (isNil(name)) {
    return pages;
  }

  if (isNil(paginationIndex) || (paginationIndex >= pages.length + 1)) {
    const newPage = generateNewPage(name, pages, paginationIndex, pageType);

    return [
      ...pages,
      newPage,
    ];
  }

  const newPages = [...pages];
  newPages.splice(paginationIndex - 1, 0, generateNewPage(name, pages, paginationIndex, pageType));
  return newPages.map(({ index, ...page }, newIndex) => ({
    index: newIndex,
    ...page,
  }));
};

export default addNewPage;
