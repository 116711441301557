import has from 'lodash/has';

const selectedRecipientsSelector = (state, resourceId) => {
  const { selectedRecipients } = state.permissionsTable;

  if (!has(selectedRecipients, resourceId)) {
    return [];
  }

  return selectedRecipients[resourceId];
};

export default selectedRecipientsSelector;
