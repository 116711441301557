import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import tasksActionCreators from 'application/tenant/utilities/tasks/tasksActionCreators';
import { validateOnSubmit } from 'components/form/formConfigs';
import RenderInlineField from './RenderInlineField';
import { convertValueFromString } from './utilities';

const RenderInlineFieldContainer = reduxForm({
  enableReinitialize: true,
  ...validateOnSubmit,
})(RenderInlineField);

export const mapStateToProps = (state, ownProps) => {
  const {
    value: paramValue,
    param,
    taskId,
    actionId,
    paramId,
  } = ownProps;

  const { key, type } = param;
  const value = convertValueFromString(paramValue, type);

  return {
    initialValues: {
      taskId,
      actionId,
      paramId,
      [key]: value,
    },
  };
};

const mapDispatchToProps = {
  onEditAction: tasksActionCreators.editAction,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(RenderInlineFieldContainer);
