import { createSelector } from 'reselect';
import memoize from 'lodash/memoize';

import { permissionsSelector } from 'helpers/selectors';
import { mapResourceType } from 'helpers/sharedMethods';

const resourcePermissionsSelector = createSelector(
  permissionsSelector,
  permissions => memoize(
    resourceType => {
      if (!permissions) {
        return [];
      }

      return permissions.filter(
        ({ resource }) => resource === mapResourceType(resourceType),
      );
    },
  ),
);

export default resourcePermissionsSelector;
