import styled, { css } from 'styled-components';

import { mediaMax } from 'themes/mixins';

export const Container = styled.div`
  padding: 2px;
  height: 40vh;
  overflow: auto;

  ${({ flex }) => flex && css`
    display: flex;
    justify-content: center;
    align-items: center;
  `}

  ${mediaMax.sm`
    height: unset;
    overflow: unset;
  `};
`;

export const EmptyMessage = styled.p`
  text-align: center;
  font-size: 16px;
`;

export default Container;
