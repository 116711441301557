import styled from 'styled-components';

import { mediaMax } from 'themes/mixins';

const StyledContainer = styled.div`
  ${mediaMax.sm`
    max-height: unset;
    overflow: unset;
  `}
`;

export default StyledContainer;
