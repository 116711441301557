import { createSelector } from 'reselect';

import projectsForMapSelector from './projectsForMapSelector';

const projectsWithLocationSelector = createSelector(
  projectsForMapSelector,
  data => Array.isArray(data) && data.filter(
    project => project.longitude !== null && project.latitude !== null,
  ),
);

export default projectsWithLocationSelector;
