import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { MultiStepModal } from 'components/core';

import {
  validateActionsStep,
  validateSchedulerStep,
  validateReviewStep,
  getTaskSchedulerSteps,
} from './utilities';
import { ModalTrigger } from './components';
import { TASK_SCHEDULER_MODE } from './constants';

export default function AddAutomatedOperation({
  mode,
  resourceId,
  resourceType,
  scheduleId,
  automatedOperationId,
  isConstruction,
  isSuspended,
  isLoading,
  taskName,
  selectedActions,
  selectedTasks,
  selectedActionsForm,
  taskSchedulerForm,
  schedulerType,
  recurrenceParams,
  endingCriteria,
  existingSchedule,
  onRecurrenceErrorsChange,
  onAddTaskScheduler,
  onResetTaskScheduler,
}) {

  const [isOpen, setIsOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const lastStep = useMemo(() => {
    switch (mode) {
      case TASK_SCHEDULER_MODE.DEFAULT:
        return 2;
      case TASK_SCHEDULER_MODE.ADD_SCHEDULER:
      case TASK_SCHEDULER_MODE.EDIT_SCHEDULER:
        return 0;
      default:
        return 0;
    }
  }, [mode]);

  const resetScheduler = useCallback(() => {
    onResetTaskScheduler();
    setCurrentStep(0);
  }, [onResetTaskScheduler]);

  const handleOpen = useCallback(() => {
    resetScheduler();
    setIsOpen(true);
  }, [resetScheduler]);

  const handleClose = useCallback(() => {
    resetScheduler();
    setIsOpen(false);
  }, [resetScheduler]);

  const handleToggle = useCallback(() => {
    resetScheduler();
    setIsOpen(!isOpen);
  }, [isOpen, resetScheduler]);

  const isActionsStepValid = useMemo(
    () => validateActionsStep({
      taskName,
      selectedActions,
      selectedTasks,
      selectedActionsForm,
    }),
    [selectedActions, selectedTasks, taskName, selectedActionsForm],
  );

  const isSchedulerStepValid = useMemo(() => {
    onRecurrenceErrorsChange({});

    const { isValid, errors } = validateSchedulerStep({
      schedulerType,
      recurrenceParams,
      endingCriteria,
    });

    onRecurrenceErrorsChange(errors);

    return isValid;
  }, [schedulerType, recurrenceParams, endingCriteria, onRecurrenceErrorsChange]);

  const isReviewStepValid = useMemo(
    () => validateReviewStep(taskSchedulerForm),
    [taskSchedulerForm],
  );

  const getSubmitText = useCallback(() => {
    switch (mode) {
      case TASK_SCHEDULER_MODE.DEFAULT:
        return 'Save Task Scheduler';
      case TASK_SCHEDULER_MODE.ADD_SCHEDULER:
        return 'Save Scheduler';
      case TASK_SCHEDULER_MODE.EDIT_SCHEDULER:
        return 'Save Changes';
      default:
        return 'Submit';
    }
  }, [mode]);

  const handleStepChange = useCallback(step => {
    setCurrentStep(step);
  }, []);

  const handleSubmit = useCallback(() => {
    onAddTaskScheduler({
      resourceId,
      resourceType,
      mode,
      scheduleId,
      existingSchedule,
      automatedOperationId,
      recurrenceParams,
      actions: selectedActions,
      actionsForm: selectedActionsForm,
      tasks: selectedTasks,
      taskName,
      taskSchedulerForm,
      schedulerType,
      callback: () => {
        setIsOpen(false);
        setCurrentStep(0);
      },
    });
  }, [
    resourceId,
    resourceType,
    mode,
    scheduleId,
    existingSchedule,
    automatedOperationId,
    onAddTaskScheduler,
    schedulerType,
    taskName,
    selectedActions,
    selectedTasks,
    selectedActionsForm,
    taskSchedulerForm,
    recurrenceParams,
  ]);

  const handlePreviousStep = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const handleNextStep = useCallback(() => {
    if (currentStep === lastStep) {
      handleSubmit();
    } else {
      setCurrentStep(currentStep + 1);
    }
  }, [currentStep, lastStep, handleSubmit]);

  const commonProps = {
    mode,
    onStepChange: handleStepChange,
    isActionsStepValid,
    isSchedulerStepValid,
    isReviewStepValid,
    resourceType,
  };

  const steps = useMemo(() => getTaskSchedulerSteps(commonProps), [commonProps]);

  const renderModalTrigger = useCallback(() => (
    <ModalTrigger
      mode={mode}
      disabled={isSuspended || isConstruction}
      onClick={handleToggle}
      scheduleId={scheduleId}
      automatedOperationId={automatedOperationId}
      resourceType={resourceType}
    />
  ), [
    mode,
    scheduleId,
    automatedOperationId,
    isSuspended,
    isConstruction,
    handleToggle,
    resourceType,
  ]);

  return (
    <MultiStepModal
      open={isOpen}
      currentStep={currentStep}
      steps={steps}
      trigger={renderModalTrigger()}
      onOpen={handleOpen}
      onClose={handleClose}
      onPreviousStep={handlePreviousStep}
      onNextStep={handleNextStep}
      isSubmitting={isLoading}
      submitText={getSubmitText()}
    />
  );
}

AddAutomatedOperation.defaultProps = {
  mode: TASK_SCHEDULER_MODE.DEFAULT,
  taskSchedulerForm: {},
  selectedActionsForm: {},
  selectedActions: [],
  selectedTasks: [],
  isLoading: false,
  taskName: '',
  scheduleId: null,
  automatedOperationId: null,
  existingSchedule: null,
  resourceType: 'project',
};

AddAutomatedOperation.propTypes = {
  mode: PropTypes.oneOf([
    TASK_SCHEDULER_MODE.DEFAULT,
    TASK_SCHEDULER_MODE.ADD_SCHEDULER,
    TASK_SCHEDULER_MODE.EDIT_SCHEDULER,
  ]),
  resourceId: PropTypes.string.isRequired,
  resourceType: PropTypes.oneOf([
    'project',
    'projectSuite',
  ]),
  selectedActions: PropTypes.array,
  taskName: PropTypes.string,
  selectedTasks: PropTypes.array,
  selectedActionsForm: PropTypes.object,
  taskSchedulerForm: PropTypes.object,
  schedulerType: PropTypes.string.isRequired,
  recurrenceParams: PropTypes.object.isRequired,
  endingCriteria: PropTypes.string.isRequired,
  onRecurrenceErrorsChange: PropTypes.func.isRequired,
  onAddTaskScheduler: PropTypes.func.isRequired,
  onResetTaskScheduler: PropTypes.func.isRequired,
  existingSchedule: PropTypes.object,
  isSuspended: PropTypes.bool.isRequired,
  isConstruction: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  scheduleId: PropTypes.string,
  automatedOperationId: PropTypes.string,
};
