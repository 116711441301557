import { call, put, select, take, takeLatest, takeEvery } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import { getPath, postPath, putPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import { TENANT } from 'storageConst';
import { ERROR_SERVER_CONNECTION } from 'data/notifications/notificationsConst';
import CompanyModel from 'models/CompanyModel';
import { currentCompanySubdomainSelector } from 'helpers/selectors';
import companiesActionCreators from './companiesActionCreators';

export function* fetchCompanies(action) {
  const {
    endpoint, urlParams, nextInChain,
    stopChain, componentName, resourceType,
  } = action.payload;
  const { response, error } = yield call(getPath, endpoint, { urlParams });

  const responseWithMetadata = error ? undefined : { ...response, componentName };

  const params = {
    response: responseWithMetadata,
    error,
    successDisp: companiesActionCreators.fetchCompaniesSuccess,
    failDisp: companiesActionCreators.fetchCompaniesFailure,
    nextInChain,
    stopChain,
    componentName,
    resourceType,
  };
  yield call(processCall, params);
}

export function* fetchCompany(action) {
  const { endpoint, companyId, urlParams } = action.payload;
  const { response, error } = yield call(getPath, endpoint, { urlParams });

  const params = {
    response,
    error,
    successDisp: companiesActionCreators.fetchCompanySuccess,
    failDisp: companiesActionCreators.fetchCompanyFailure,
    endpoint,
    id: companyId,
  };
  yield call(processCall, params);
}

export function* addNewCompany(action) {
  const {
    endpoint, params: {
      values, rejectForm, resolveForm,
    },
  } = action.payload;
  const body = CompanyModel.requestBody(values);
  const { response, error } = yield call(postPath, endpoint, body);
  if (error instanceof Error) { error.message = ERROR_SERVER_CONNECTION; }

  const params = {
    response,
    error,
    successDisp: companiesActionCreators.addCompanySuccess,
    failDisp: companiesActionCreators.addCompanyFailure,
    resolveForm,
    rejectForm,
  };
  yield call(processCall, params);
}

export function* editCompany(action) {
  const {
    endpoint,
    params: { values, resolveForm, rejectForm },
  } = action.payload;
  const body = CompanyModel.requestBody(values);

  const { response, error } = yield call(putPath, endpoint, body);
  if (error instanceof Error) { error.message = ERROR_SERVER_CONNECTION; }

  const params = {
    response,
    error,
    successDisp: companiesActionCreators.editCompanySuccess,
    failDisp: companiesActionCreators.editCompanyFailure,
    resolveForm,
    rejectForm,
  };
  yield call(processCall, params);
}

export function* changeTenant(action) {
  const currentSubdomain = yield select(currentCompanySubdomainSelector);
  const { tenant } = action.payload;

  if (currentSubdomain !== tenant) {
    localStorage.setItem(TENANT, tenant);
  } else {
    localStorage.removeItem(TENANT);
  }
}

export function* updateTenant(action) {
  const currentSubdomain = yield select(currentCompanySubdomainSelector);
  const chosenSubdomain = localStorage.getItem(TENANT);
  const {
    values: { subdomain },
    initialValues: { subdomain: initialSubdomain },
  } = action.payload.params;

  if (!subdomain) {
    return;
  }

  yield take(constants.UPDATE_COMPANY_INFO_SUCCESS);
  if (currentSubdomain === initialSubdomain) {
    const { href } = window.location;

    localStorage.removeItem(TENANT);
    window.location.assign(href.replace(initialSubdomain, subdomain));
  } else if (chosenSubdomain === initialSubdomain) {
    yield put(companiesActionCreators.changeTenant(subdomain));
  }
}

function* companiesSaga() {
  yield takeEvery(constants.FETCH_COMPANIES_REQUEST, fetchCompanies);
  yield takeLatest(constants.FETCH_COMPANY_REQUEST, fetchCompany);
  yield takeLatest(constants.UPDATE_COMPANY_INFO_REQUEST, editCompany);
  yield takeLatest(constants.CHANGE_TENANT, changeTenant);
  yield takeLatest(constants.ADD_COMPANY_REQUEST, addNewCompany);
  yield takeLatest(constants.UPDATE_COMPANY_INFO_REQUEST, updateTenant);
}

export default companiesSaga;
