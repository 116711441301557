import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const fetchTwoFactorQRCode = () => ({
  type: constants.FETCH_TWO_FACTOR_QR_CODE,
  payload: {
    endpoint: endpoints.TWO_FACTOR_QR_CODE,
  },
});

export const fetchTwoFactorQRCodeSuccess = response => ({
  type: constants.FETCH_TWO_FACTOR_QR_CODE_SUCCESS,
  payload: { response },
});

export const fetchTwoFactorQRCodeFailure = error => ({
  type: constants.FETCH_TWO_FACTOR_QR_CODE_FAILED,
  payload: { error },
});
