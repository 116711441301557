import { compose } from 'redux';
import { connect } from 'react-redux';

import { currentUserSelector } from 'helpers/selectors';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { permissionsOrNotFound, frontendPermissions } from 'gateway';
import { permissionsActionCreators } from '../../utilities';
import Permissions from './Permissions';

export const mapStateToProps = state => {
  const { superadmin } = currentUserSelector(state);

  return {
    isSuperAdmin: superadmin,
  };
};

export default compose(
  connect(mapStateToProps),
  permissionsOrNotFound(frontendPermissions.TENANT_MANAGER_PERMISSIONS),
  chainedLoaderWithSaga(permissionsActionCreators.fetchIndexPermissions),
)(Permissions);
