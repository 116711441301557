import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withProjectPermissions } from 'gateway';
import { singleProjectSelector } from 'helpers/selectors';
import ProjectTasksTab from './ProjectTasksTab';

const mapStateToProps = (state, { match }) => {
  const { id: projectId } = match.params;
  const { locationSet } = singleProjectSelector(state, projectId);

  return {
    projectId,
    locationSet,
  };
};

export default compose(
  withRouter,
  withProjectPermissions,
  connect(mapStateToProps),
)(ProjectTasksTab);
