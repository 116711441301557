import { takeLatest, takeEvery } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import deleteInvitation from './deleteInvitation';
import editProfile from './editProfile';
import fetchUsers from './fetchUsers';
import resetSecretKey from './resetSecretKey';
import toggleSuspendUser from './toggleSuspendUser';
import changePassword from './changePassword';

function* saga() {
  yield takeEvery(constants.DELETE_INVITATION_REQUEST, deleteInvitation);
  yield takeLatest(constants.EDIT_PROFILE, editProfile);
  yield takeLatest(constants.FETCH_USERS_REQUEST, fetchUsers);
  yield takeLatest(constants.RESET_SECRET_KEY, resetSecretKey);
  yield takeLatest(constants.TOGGLE_SUSPEND_USER, toggleSuspendUser);
  yield takeLatest(constants.CHANGE_PASSWORD_REQUEST, changePassword);
}

export default saga;
