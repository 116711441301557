import React from 'react';
import PropTypes from 'prop-types';

const withLoadProject = WrappedComponent => {
  const WithLoadProject = ({ usersEndpoint, ...rest }) => {
    if (!usersEndpoint) {
      return null;
    }

    return <WrappedComponent {...rest} />;
  };

  WithLoadProject.defaultProps = {
    usersEndpoint: null,
  };

  WithLoadProject.propTypes = {
    usersEndpoint: PropTypes.string,
  };

  return WithLoadProject;
};

export default withLoadProject;
