import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { isActionValid } from './utilities';
import { ActionItem, ReadOnlyActionItem, TaskName } from './components';
import { Container, EmptyMessage, TaskContainer, TaskNameLabel, StyledAccordion } from './SelectedActions.style';

function SelectedActions({
  selectedActions,
  selectedTasks,
  actionErrors,
  actionTypes,
  visibleActions,
  onActionRemove,
  onActionToggle,
}) {
  const isValid = useCallback(
    (deviceId, actionId, actionIndex) => isActionValid({
      deviceId, actionId, actionIndex, actionErrors,
    }), [actionErrors],
  );

  const handleActionRemove = useCallback(({ resourceId, actionId, actionIndex }) => {
    onActionRemove({ resourceId, actionId, actionIndex });
  }, [onActionRemove]);

  const renderTaskActions = task => {
    const { actions = [] } = task;

    if (isEmpty(actions)) {
      return null;
    }

    return (
      <>
        {task.actions.map((action, index) => (
          <ReadOnlyActionItem
            key={action.id}
            index={index}
            activePanels={visibleActions}
            onActionToggle={onActionToggle}
            actionTypes={actionTypes}
            projectId={task.projectId}
            {...action}
          />
        ))}
      </>
    );
  };

  const renderTasks = () => (
    <>
      {selectedTasks.map(task => (
        <TaskContainer key={task.id}>
          <TaskNameLabel basic>
            {task.name}
          </TaskNameLabel>
          <StyledAccordion
            exclusive={false}
            styled
            fluid
          >
            {renderTaskActions(task)}
          </StyledAccordion>
        </TaskContainer>
      ))}
    </>
  );

  const renderActions = useCallback(() => {
    if (isEmpty(selectedActions)) {
      return null;
    }

    return (
      <>
        <TaskName selectedActions={selectedActions} />
        <StyledAccordion
          exclusive={false}
          styled
          fluid
        >
          {selectedActions.map((action, index) => (
            <ActionItem
              index={index}
              key={`action-${action.resourceId}-${action.actionId}-${action.index}`}
              actionIndex={action.index}
              isValid={isValid(action.resourceId, action.actionId, action.index)}
              activePanels={visibleActions}
              onActionRemove={handleActionRemove}
              onActionToggle={onActionToggle}
              actionTypes={actionTypes}
              {...action}
            />
          ))}
        </StyledAccordion>
      </>
    );
  },
  [
    actionTypes,
    selectedActions,
    visibleActions,
    isValid,
    handleActionRemove,
    onActionToggle,
  ]);

  if (isEmpty(selectedActions) && isEmpty(selectedTasks)) {
    return (
      <Container flex>
        <EmptyMessage>
          No Tasks & Actions selected. <br />
          Specify at least one Task or Action to proceed.
        </EmptyMessage>
      </Container>
    );
  }

  return (
    <Container>
      {renderActions()}
      {renderTasks()}
    </Container>
  );
}

SelectedActions.defaultProps = {
  selectedActions: [],
  selectedTasks: [],
  actionErrors: {},
  actionTypes: {},
};

SelectedActions.propTypes = {
  selectedActions: PropTypes.array,
  selectedTasks: PropTypes.array,
  actionErrors: PropTypes.object,
  actionTypes: PropTypes.object,
  visibleActions: PropTypes.object.isRequired,
  onActionToggle: PropTypes.func.isRequired,
  onActionRemove: PropTypes.func.isRequired,
};

export default SelectedActions;
