import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Responsive } from 'semantic-ui-react';

import { sizes } from 'themes/mixins';
import { Panel } from 'components/core';

import { MobileView, DesktopView } from './components';

function ActionsStep({
  selectedDevice,
  selectedActions,
  selectedTasks,
  selectedProject,
  actionTypes,
  defaultActiveTab,
  panelTitle,
  panelDescription,
  resourceType,
  onAddSelectedAction,
  onRemoveSelectedAction,
}) {
  const [visibleActions, setVisibleActions] = useState({});

  const handleActionSelected = ({ selectedAction }) => {
    const sameActionFilter = params => (
      params.deviceId === selectedDevice.id && params.actionId === selectedAction.id
    );

    const sameActions = selectedActions.filter(sameActionFilter);

    let actionIndex = 0;

    if (!isEmpty(sameActions)) {
      const { index } = sameActions[sameActions.length - 1];

      actionIndex = index + 1;
    }

    onAddSelectedAction({
      ...selectedAction,
      index: actionIndex,
    });

    const actionType = actionTypes[selectedAction.id] || {};
    const actionHasParams = !isEmpty(actionType.params || []);

    const resourceId = selectedDevice.id || selectedProject.id;

    setVisibleActions(previousState => ({
      ...previousState,
      [`${selectedAction.id}${resourceId}${actionIndex}`]: actionHasParams,
    }));
  };

  const handleActionToggle = ({ resourceId, actionId, actionIndex, taskActionId }) => {
    const correctActionId = taskActionId || `${actionId}${resourceId}${actionIndex}`;

    const isActionVisible = visibleActions[correctActionId] === true;

    setVisibleActions(previousState => ({
      ...previousState,
      [correctActionId]: !isActionVisible,
    }));
  };

  const handleActionRemove = ({ resourceId, actionId, actionIndex }) => {
    onRemoveSelectedAction({ resourceId, actionId, actionIndex });
  };

  const commonProps = {
    visibleActions,
    selectedDevice,
    selectedActions,
    selectedTasks,
    defaultActiveTab,
    onActionSelected: handleActionSelected,
    onActionRemove: handleActionRemove,
    onActionToggle: handleActionToggle,
    resourceType,
  };

  return (
    <Panel title={panelTitle} description={panelDescription}>
      <Responsive maxWidth={sizes.md - 1} as={Fragment}>
        <MobileView {...commonProps} />
      </Responsive>
      <Responsive minWidth={sizes.md} as={Fragment}>
        <DesktopView {...commonProps} />
      </Responsive>
    </Panel>
  );
}

ActionsStep.defaultProps = {
  selectedDevice: {},
  actionTypes: {},
  selectedActions: [],
  selectedTasks: [],
  selectedProject: {},
  defaultActiveTab: 'actions',
  panelTitle: 'Tasks & Actions',
  panelDescription: 'Select Tasks & Actions to run when this Scheduler activates.',
  resourceType: 'project',
};

ActionsStep.propTypes = {
  selectedDevice: PropTypes.object,
  actionTypes: PropTypes.object,
  selectedActions: PropTypes.array,
  selectedTasks: PropTypes.array,
  selectedProject: PropTypes.object,
  onAddSelectedAction: PropTypes.func.isRequired,
  onRemoveSelectedAction: PropTypes.func.isRequired,
  defaultActiveTab: PropTypes.string,
  panelTitle: PropTypes.string,
  panelDescription: PropTypes.string,
  resourceType: PropTypes.oneOf([
    'project',
    'projectSuite',
  ]),
};

export default ActionsStep;
