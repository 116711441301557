import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

const onMountUnmountHOC = (onMount, onUnmount) => WrappedComponent => class extends Component {

  componentDidMount() {
    const { props, props: { dispatch } } = this;
    if (onMount) {
      dispatch(onMount(props));
    }
  }

  componentWillUnmount() {
    const { props, props: { dispatch } } = this;
    if (onUnmount) {
      dispatch(onUnmount(props));
    }
  }

  render() {
    return <WrappedComponent {...this.props} />;
  }

};

const mapDispatchToProps = dispatch => ({ dispatch });

export default (...methods) => compose(
  connect(null, mapDispatchToProps),
  onMountUnmountHOC(...methods),
);
