import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isNull from 'lodash/isNull';

import LogModel, { defaultLogLevelSelectOptions } from 'models/LogModel';
import RefreshInterface from 'components/RefreshInterface/RefreshInterfaceContainer';
import SelectFieldForm from 'components/form/fieldForms/SelectFieldForm/SelectFieldForm';
import LogTable from 'components/LogTable/LogTable';
import { SettingsPanel } from 'components/elements';

class LogsSection extends Component {

  constructor() {
    super();

    this.handleSelectLines = this.handleSelectLines.bind(this);
    this.handleSelectLevel = this.handleSelectLevel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { deviceId, lines, onRefresh } = this.props;

    onRefresh(deviceId, 'log_messages', { lines });
  }

  componentDidUpdate({ lines: previousLines }) {
    const { lines } = this.props;

    if (lines !== previousLines && !isNull(previousLines)) {
      this.unsubscribe({ logLines: previousLines });
      this.subscribe({ logLines: lines });
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  subscribe({ logLines }) {
    const { deviceId, onSubscribe } = this.props;

    onSubscribe(deviceId, 'log_messages', { lines: logLines });
  }

  unsubscribe({ logLines } = {}) {
    const { deviceId, lines, onUnsubscribe } = this.props;

    onUnsubscribe(deviceId, 'log_messages', { lines: logLines || lines });
  }

  handleSelectLines(event, { value }) {
    const { onSelectLines } = this.props;

    onSelectLines(value);
  }

  handleSelectLevel(values) {
    const { deviceId, onSelectLevel } = this.props;

    return new Promise(resolveForm => {
      onSelectLevel(deviceId, {
        values,
        resolveForm,
        rejectForm: resolveForm,
      });
    });
  }

  async handleSubmit(values, { reset }) {
    const error = await this.handleSelectLevel(values);

    if (error) {
      reset();
    }
  }

  render() {
    const {
      online,
      deviceId,
      initialLevel,
      offset,
      logs,
      lines,
      refreshing,
      logLevelSelectOptions,
    } = this.props;

    const logsWithOffset = logs && logs.map(log => log.addOffset(offset));

    return (
      <div>
        <SettingsPanel>
          <SelectFieldForm
            name="logLevel"
            placeholder="Log level"
            options={logLevelSelectOptions}
            initialValue={initialLevel}
            onChange={this.handleSubmit}
            disabled={!online}
          />
          <RefreshInterface
            deviceId={deviceId}
            dataId="log_messages"
            meta={{ lines: Number(lines) }}
          />
        </SettingsPanel>
        <LogTable logs={logsWithOffset} refreshing={refreshing} />
      </div>
    );
  }

}

LogsSection.defaultProps = {
  logs: null,
  refreshing: false,
  logLevelSelectOptions: defaultLogLevelSelectOptions,
  initialLevel: null,
  offset: 0,
};

LogsSection.propTypes = {
  online: PropTypes.bool.isRequired,
  deviceId: PropTypes.string.isRequired,
  initialLevel: PropTypes.number,
  offset: PropTypes.number,
  lines: PropTypes.number.isRequired,
  logs: PropTypes.arrayOf(PropTypes.instanceOf(LogModel)),
  refreshing: PropTypes.bool,
  logLevelSelectOptions: PropTypes.array,
  onSelectLines: PropTypes.func.isRequired,
  onSelectLevel: PropTypes.func.isRequired,
  onSubscribe: PropTypes.func.isRequired,
  onUnsubscribe: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
};

export default LogsSection;
