import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { DESCENDING_PREFIX } from 'helpers/UrlParams';
import { SORT_ASCENDING } from 'helpers/paginationHelpers';
import Table from 'components/DataTable/Table.style';
import { labelType, sortType } from 'data/types';

class DataTableHeader extends PureComponent {

  constructor() {
    super();

    this.handleSort = this.handleSort.bind(this);
  }

  handleSort(field) {
    const { sort, onSort } = this.props;
    if (isEmpty(sort)) return () => null;

    const { direction, field: sortField } = sort;
    const prefix = (field === sortField && direction === SORT_ASCENDING)
      ? DESCENDING_PREFIX
      : '';

    return () => onSort(`${prefix}${field}`);
  }

  render() {
    const {
      label: { field, sortable, sortBy = field, text, width },
      sort: { field: sortField, direction },
    } = this.props;

    return (
      <Table.HeaderCell
        sorted={sortField === sortBy ? direction : null}
        onClick={sortable !== false ? this.handleSort(sortBy) : undefined}
        width={width}
      >
        {text}
      </Table.HeaderCell>
    );
  }

}

DataTableHeader.defaultProps = {
  sort: {},
};

DataTableHeader.propTypes = {
  // eslint-disable-next-line react/no-typos
  label: labelType.isRequired,
  sort: sortType,
  onSort: PropTypes.func.isRequired,
};

export default DataTableHeader;
