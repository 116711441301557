import isNil from 'lodash/isNil';

const setTabVisibility = (
  tabsArray,
  tabName,
  condition,
) => {
  if (!Array.isArray(tabsArray)) {
    return null;
  }

  if (isNil(tabName) || isNil(condition)) {
    return tabsArray;
  }

  if (tabName && !condition) {
    return tabsArray.filter(({ name }) => name !== tabName);
  }

  return tabsArray;
};

export default setTabVisibility;
