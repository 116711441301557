import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';

export const fetchDeviceGroups = deviceId => ({
  type: constants.FETCH_DEVICE_GROUPS_REQUEST,
  payload: {
    deviceId,
    endpoint: endpoints.LOGICAL_DEVICE_FIXTURE_GROUPS(deviceId),
  },
});

export const fetchDeviceGroupsSuccess = (response, deviceId) => ({
  type: constants.FETCH_DEVICE_GROUPS_SUCCESS,
  payload: { response, deviceId },
});

export const fetchDeviceGroupsFailure = error => ({
  type: constants.FETCH_DEVICE_GROUPS_FAILED,
  payload: {
    info: !error.unauthorized && error.notFound && messages.NO_FIXTURE_GROUPS,
    error: !error.notFound && error,
  },
});
