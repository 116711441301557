import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';

import { StyledSuspendedSite } from './components';

const SuspendedSiteIcon = ({ message }) => {
  const ref = React.createRef();

  return (
    <Popup
      content={message}
      trigger={(
        <StyledSuspendedSite size={15} color="#818181" ref={ref} />
      )}
      position="bottom right"
    />
  );
};

SuspendedSiteIcon.propTypes = {
  message: PropTypes.string.isRequired,
};

export default SuspendedSiteIcon;
