import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 100%;
`;

export const StyledTitle = styled.div`
  margin-bottom: 10px;
`;

export const StyledFormContainer = styled.div`
  display: flex; 
  width: 100%; 
  justify-content: space-between; 
  align-items: center;
`;

export const StyledFormComment = styled(StyledFormContainer)`
  margin-top: 15px;
`;

export const StyledInputContainer = styled.div`
  max-width: 200px; 
  overflow: hidden;
`;

export default StyledContainer;
