import React, { Component } from 'react';
import PropTypes from 'prop-types';

import formNames from 'components/form/formNames';
import { StyledInlineEditTaskProperty } from './InlineEditTaskProperty.style';

class InlineEditTaskProperty extends Component {

  constructor() {
    super();

    this.handleChangeProperty = this.handleChangeProperty.bind(this);
  }

  handleChangeProperty(values) {
    const {
      id,
      property,
      onChangeProperty,
      taskId,
    } = this.props;

    return new Promise((resolveForm, rejectForm) => {
      onChangeProperty({
        id,
        taskId,
        property,
        values: {
          ...values,
        },
        resolveForm,
        rejectForm,
      });
    });
  }

  render() {
    const {
      id,
      validator,
      property,
      propertyValue,
    } = this.props;
    const form = `${formNames.editTask}.${id}.${property}`;

    return (
      <StyledInlineEditTaskProperty
        name={property}
        type="text"
        value={propertyValue}
        validator={validator}
        onSubmit={this.handleChangeProperty}
        form={form}
        allowCancelOnPristine
        preventPropagation
      />
    );
  }

}

InlineEditTaskProperty.defaultProps = {
  propertyValue: undefined,
  validator: null,
  taskId: '',
};

InlineEditTaskProperty.propTypes = {
  onChangeProperty: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  property: PropTypes.string.isRequired,
  propertyValue: PropTypes.string,
  validator: PropTypes.func,
  taskId: PropTypes.string,
};

export default InlineEditTaskProperty;
