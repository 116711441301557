import React, { Component } from 'react';
import PropTypes from 'prop-types';

import colors from 'themes/colors';
import { validDisplayName } from 'components/form/formValidators';
import formNames from 'components/form/formNames';
import { convertColorString } from 'helpers/sharedMethods';
import { StyledHeader, StyledEditPage, StyledPageName } from './InlineEditPage.style';

class InlineEditPage extends Component {

  constructor() {
    super();

    this.handleEditPageName = this.handleEditPageName.bind(this);
  }

  handleEditPageName({ name }) {
    const { pageId, projectId, onEditPageName } = this.props;

    return new Promise((resolveForm, rejectForm) => {
      onEditPageName({
        pageId,
        projectId,
        name,
        resolveForm,
        rejectForm,
      });
    });
  }

  render() {
    const { configure, name, nameColor, isInConfigurationPanel } = this.props;
    const nameColorObject = convertColorString(nameColor, colors.black);

    return (
      <StyledHeader isInConfigurationPanel>
        {configure
          ? (
            <StyledEditPage
              nameColor={nameColorObject}
              name="name"
              type="text"
              value={name}
              validator={validDisplayName}
              onSubmit={this.handleEditPageName}
              form={formNames.pageName}
              allowCancelOnPristine
              isInConfigurationPanel={isInConfigurationPanel}
            />
          )
          : (
            <StyledPageName nameColor={nameColorObject}>
              {name}
            </StyledPageName>
          )
        }
      </StyledHeader>
    );
  }

}

InlineEditPage.defaultProps = {
  name: undefined,
  nameColor: null,
  isInConfigurationPanel: false,
  pageId: 0,
};

InlineEditPage.propTypes = {
  configure: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
  onEditPageName: PropTypes.func.isRequired,
  pageId: PropTypes.number,
  name: PropTypes.string,
  nameColor: PropTypes.string,
  isInConfigurationPanel: PropTypes.bool,
};

export default InlineEditPage;
