import { takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import { fetchLogsActionTypes } from './fetchLogsActionTypes';

function* saga() {
  yield takeLatest(constants.FETCH_LOGS_ACTION_TYPES_REQUEST, fetchLogsActionTypes);
}

export default saga;
