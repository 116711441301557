import { css, createGlobalStyle } from 'styled-components';
import inRange from 'lodash/inRange';

import { UpdateNotification } from 'components/TopBar/TopBar.style';
import colors from './colors';
import { mediaMax, mediaOnly, iOSVersion } from './mixins';

// eslint-disable-next-line no-unused-expressions
const GlobalStyle = createGlobalStyle`
  body {
    background: ${colors.white};
  }

  *{
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    height:100%;
    margin: 0;
    padding: 0;
    overscroll-behavior: contain;
  }
  
  ${inRange(iOSVersion(), 11, 11.3) && css`
    body.dimmed #root {
      overflow: hidden;
    }
  `};

  body.new-content {
    ${UpdateNotification} {
      display: block;
    }
  }

  main {
    position: relative;
    display: flex;
    min-height: 100%;
    padding-bottom: 9rem;
    flex-direction: column;
  }

  input {
    &::-ms-clear,
    &[type="password"]::-ms-reveal {
      display: none;
    }
    &[type="number"]::-webkit-outer-spin-button,
    &[type="number"]::-webkit-inner-spin-button {
      margin: 0;
      appearance: none;
    }
    &[type="number"] {
      appearance: textfield!important;
    }
  }

  .ui.input.disabled > input[disabled] {
    opacity: 1;
  }

  .ui.button:last-child {
    margin-right: 0;
  }

  .ui.page.modals.dimmer.transition.visible.active {
    ${inRange(iOSVersion(), 11, 11.3) && css`position: absolute;`};
  }

  .ui.modal {
    ${inRange(iOSVersion(), 11, 11.3) && css`position: relative;`};

    &.mini {
      ${mediaOnly.sm`
        width: 44.2708%;
        margin: 0 0 0 -22.1354%;
      `};
    }

    .actions {
      ${mediaMax.sm`
        display: flex;
        flex-direction: column-reverse;
      `};
    }

    .actions > .button {
      ${mediaMax.sm`
        margin-left: 0;
        margin-right: 0;
      `};
    }
  }
`;

export default GlobalStyle;
