import styled from 'styled-components';
import { Segment, Icon } from 'semantic-ui-react';

export const StyledSegment = styled(Segment)`
  height: 43px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px !important;
  margin-top: 4px !important;
  margin-bottom: 8px !important;
  box-shadow: none !important;

  & {
    .ui.placeholder {
      width: 100% !important;
    } 
  }
`;

export const StyledIcon = styled(Icon)`
  &.icon {
    height: auto !important;
  }
`;


export default StyledSegment;
