import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  permissionsProjectActiveUserIdsSelector,
} from 'helpers/selectors';
import { PermissionsGridHeaders } from '../../../../../../../../../../components';
import { ContextProjectSuiteUserLabel } from '../UserLabel';

export const makeMapState = () => function mapStateToProps(state, { resourceId }) {
  const userIds = permissionsProjectActiveUserIdsSelector(state, { projectId: resourceId });

  return {
    userIds,
    UserLabel: ContextProjectSuiteUserLabel,
  };
};

export default compose(
  connect(makeMapState),
)(PermissionsGridHeaders);
