class ControlUIButtonModel {

  constructor(controlUIButton = {}) {
    this.index = controlUIButton.index;
    this.text = controlUIButton.text;
    this.taskId = controlUIButton.taskId;
    this.taskIds = controlUIButton.taskIds;
    this.enabled = controlUIButton.enabled;
    this.buttonColor = controlUIButton.buttonColor;
    this.textColor = controlUIButton.textColor;
  }

}

export default ControlUIButtonModel;
