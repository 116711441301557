import { TENANT } from 'storageConst';

class PermissionModel {

  constructor(permission = {}) {
    this.id = permission.id;
    this.permissionName = permission.permissionName;
    this.description = permission.description;
    this.resource = permission.resource;
    this.limitedScope = permission.limitedScope;
    this.limitedFor = permission.limitedFor;
    this.context = TENANT;
  }

}

export default PermissionModel;
