export default {
  EVENT_CLOSE: 'close',
  EVENT_OPEN: 'open',
  EVENT_MESSAGE: 'message',
  EVENT_ERROR: 'error',
  EVENT_WELCOME: 'welcome',

  COMMAND_SUBSCRIBE: 'subscribe',
  COMMAND_MESSAGE: 'message',
  COMMAND_UNSUBSCRIBE: 'unsubscribe',

  RESPONSE_TYPE_CONFIRM_SUBSCRIPTION: 'confirm_subscription',
  RESPONSE_TYPE_REJECT_SUBSCRIPTION: 'reject_subscription',

  CHANNEL_DEVICE_DATA: 'V1::DeviceDataChannel',

  AUDIT_LOG_CHANNEL: 'V1::AuditLogChannel',

  AUDIT_LOG: 'audit_log',

  LOGS_READY: 'logs_ready',

  CONFIRMATION_GET_LOGS: 'confirmation_get_logs',

  GET_LOGS: 'get_logs',

  NOTIFICATIONS_CHANNEL: 'V1::NotificationEventsChannel',

  STATE_CODES: {
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3,
  },
};
