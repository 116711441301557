import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, propTypes as reduxFormPropTypes } from 'redux-form';
import { FormGroup, Grid } from 'semantic-ui-react';
import SparkMD5 from 'spark-md5';

import { uploadFileType } from 'data/types';
import { nonEmptyFiles, sameFile } from 'components/form/formValidators';
import ModalForm from 'components/form/ModalForm/ModalForm';
import DropzoneField from 'components/form/DropzoneField/DropzoneFieldContainer';

export const projectFileUploadFormFields = {
  file: 'systemFile',
};

class ProjectFileUploadModalForm extends Component {

  constructor() {
    super();

    this.state = { processing: false };

    this.handleFileStatusChange = this.handleFileStatusChange.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ resume: nextResume, change: nextChange }) {
    if (nextResume) {
      const { name, fileTypeId } = nextResume;
      const nameHash = SparkMD5.hash(name);

      nextChange(nameHash, fileTypeId);
    }
  }

  handleFileStatusChange(processing) {
    this.setState({ processing });
  }

  render() {
    const { processing } = this.state;
    const {
      header,
      accept,
      submitting,
      resume,
      trigger,
      form,
    } = this.props;

    return (
      <ModalForm
        header={header}
        trigger={trigger}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...this.props}
        form={form}
        submitting={processing}
        size="small"
      >
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <FormGroup inline>
                <Field
                  name={projectFileUploadFormFields.file}
                  component={DropzoneField}
                  loading={submitting}
                  validate={[nonEmptyFiles, sameFile]}
                  accept={accept}
                  resume={resume}
                  onChangeFileStatus={this.handleFileStatusChange}
                />
              </FormGroup>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ModalForm>
    );
  }

}

ProjectFileUploadModalForm.defaultProps = {
  header: 'Upload file',
  accept: '',
  resume: null,
};

ProjectFileUploadModalForm.propTypes = {
  ...reduxFormPropTypes.isRequired,
  header: PropTypes.string,
  accept: PropTypes.string,
  resume: uploadFileType,
  submitting: PropTypes.bool.isRequired,
  trigger: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  form: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default ProjectFileUploadModalForm;
