import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormGroup, Grid, Label } from 'semantic-ui-react';

import { uploadFileType, fileTypesMapType, systemFilesType } from 'data/types';
import { nonEmptyFiles, sameFile } from 'components/form/formValidators';
import DropzoneField from 'components/form/DropzoneField/DropzoneFieldContainer';
import ModalForm from 'components/form/ModalForm/ModalFormV2';
import TextField from 'components/form/TextField/TextField';
import { firmwareFileFormFields } from './constants';
import { checkIfFileExists, getFileName } from './utilities';

const UploadFirmwareFileModalForm = ({ ...props }) => {
  const uploadFirmwareFile = 'Upload file';
  const accept = '.app';
  const { resume, fileTypes, submitting, files } = props;
  const fileExists = checkIfFileExists(files);
  const fileName = getFileName(files);

  const handleFirmwareFileStatusChange = () => null;

  return (
    <ModalForm
      header={uploadFirmwareFile}
      trigger={uploadFirmwareFile}
      size="small"
      triggerSize="mini"
      {...props}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} computer={16}>
            <FormGroup inline>
              <Field
                name={firmwareFileFormFields.files}
                component={DropzoneField}
                loading={submitting}
                validate={[nonEmptyFiles, sameFile]}
                multiple={false}
                accept={accept}
                resume={resume}
                onChangeFileStatus={handleFirmwareFileStatusChange}
                fileTypes={fileTypes}
              />
            </FormGroup>
          </Grid.Column>
        </Grid.Row>
        {fileExists && (
          <Grid.Row>
            <Grid.Column mobile={16} computer={16}>
              <Label.Group>
                <Label
                  htmlFor="file-upload-name"
                  as="a"
                  size="large"
                >
                  {fileName}
                </Label>
              </Label.Group>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column mobile={16} computer={16}>
            <FormGroup inline>
              <Field
                name={firmwareFileFormFields.description}
                component={TextField}
                type="text"
                loading={submitting}
                label="Description"
              />
            </FormGroup>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ModalForm>
  );
};

UploadFirmwareFileModalForm.defaultProps = {
  accept: '',
  resume: null,
  fileTypes: null,
  files: [],
};

UploadFirmwareFileModalForm.propTypes = {
  accept: PropTypes.string,
  resume: uploadFileType,
  fileTypes: fileTypesMapType,
  files: systemFilesType,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default UploadFirmwareFileModalForm;
