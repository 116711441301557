import React from 'react';
import isBoolean from 'lodash/isBoolean';

import DataTableIcon from 'components/DataTable/DataTableIcon/DataTableIcon';
import { formats } from 'pages/Device/labelsMap';
import { booleanIcons } from '../constants';

const getFormat = (format, value) => {
  if (format === formats.DATE) {
    return (number, substitute) => (
      new Date((substitute || number) * 1000).toUTCString()
    );
  }

  if (format === formats.SECONDS) {
    return (number, substitute) => `${(substitute || number)} s`;
  }

  if (isBoolean(value)) {
    return booleanValue => {
      if (booleanValue) {
        return <DataTableIcon name={booleanIcons.check} />;
      }

      return <DataTableIcon name={booleanIcons.cross} />;
    };
  }

  return item => item;
};

export default getFormat;
