import { connect } from 'react-redux';
import { deepEqualMemoizeOne } from 'helpers/sharedMethods';

import { deviceSelector } from 'helpers/selectors';
import SLCInterfacesV1 from './SLCInterfacesV1';

export const makeMapState = () => {
  const defaultTimestamp = 1582900503.113;
  const memoRelayOutputStsValue = deepEqualMemoizeOne();
  const memoClosureInput1StsValue = deepEqualMemoizeOne();
  const defaultRelayOutputName = 'Relay Output';
  const defaultRelayOutStsValue = { value: true, timestamp: defaultTimestamp };
  const defaultClosureInput1Name = 'Closure Input 1';
  const defaultClosureInput1StsValue = { value: false, timestamp: defaultTimestamp };
  const defaultClosureInput2Name = 'Closure Input 2';
  const defaultClosureInput2StsValue = { value: false, timestamp: defaultTimestamp };

  return function mapStateToProps(state, { resourceId }) {
    const device = deviceSelector(state)(resourceId);
    const { online, reportedWithMeta } = device;

    const {
      cc: {
        out: {
          1: {
            nm: relayOutputName = defaultRelayOutputName,
            sts: relayOutputStsValue = defaultRelayOutStsValue,
          } = {},
        } = {},
        in: {
          1: {
            nm: closureInput1Name = defaultClosureInput1Name,
            sts: closureInputName1StsVaue = defaultClosureInput1StsValue,
          } = {},
          2: {
            nm: closureInput2Name = defaultClosureInput2Name,
            sts: closureInputName2StsVaue = defaultClosureInput2StsValue,
          } = {},
        } = {},
      } = {},
    } = reportedWithMeta || {};

    return {
      loading: !reportedWithMeta,
      online,
      relayOutputName,
      relayOutputStsValue: memoRelayOutputStsValue(relayOutputStsValue),
      closureInput1Name,
      closureInput1StsValue: memoClosureInput1StsValue(closureInputName1StsVaue),
      closureInput2Name,
      closureInput2StsValue: memoClosureInput1StsValue(closureInputName2StsVaue),
    };
  };
};

export default connect(makeMapState)(SLCInterfacesV1);
