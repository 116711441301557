import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';

import { socketDeviceDataSubscriptionStatus } from 'helpers/selectors';
import { deepEqualMemoizeOne } from 'helpers/sharedMethods';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import websocketActionCreators from 'data/websockets/websocketsActionCreators';
import tablesActionCreators from 'data/tablesActionCreators';

import { actionCreators, selectors, getDefaultProtocolUniverse } from './utilities';
import OutputSection from './OutputSection';

export const makeMapState = () => {
  const memoPatches = deepEqualMemoizeOne();
  const memoOutputs = deepEqualMemoizeOne();

  return function mapStateToProps(state, { match, location }) {
    const { deviceId } = match.params;
    const { port } = qs.parse(location.search, { ignoreQueryPrefix: true });

    const patches = selectors.patchesSelector(state);
    const outputs = selectors.outputsSelector(state);
    const patchesFetching = selectors.patchesFetchingSelector(state);
    const outputFetching = selectors.outputFetchingSelector(state);
    const { refreshing, subscribing } = socketDeviceDataSubscriptionStatus(deviceId, 'dmx_output')(state) || {};
    const { defaultProtocolId, defaultUniverseId } = getDefaultProtocolUniverse({ patches });

    return {
      deviceId,
      port,
      protocolId: selectors.protocolIdSelector(state),
      universeId: selectors.universeIdSelector(state),
      defaultProtocolId,
      defaultUniverseId,
      patches: memoPatches(patches),
      outputs: memoOutputs(outputs),
      isFetching: patchesFetching || outputFetching,
      isRefreshing: refreshing,
      isSubscribing: subscribing,
    };
  };
};

const mapDispatchToProps = {
  onRefresh: websocketActionCreators.refreshDeviceData,
  onSubscribe: websocketActionCreators.subscribeDeviceData,
  onUnsubscribe: websocketActionCreators.unsubscribeDeviceData,
  onStoreTableName: tablesActionCreators.storeTableName,
  onProtocolChange: actionCreators.setProtocol,
  onUniverseChange: actionCreators.setUniverse,
};

export default compose(
  withRouter,
  connect(makeMapState, mapDispatchToProps),
  chainedLoaderWithSaga(({ deviceId }) => actionCreators.fetchPatches(deviceId)),
)(OutputSection);
