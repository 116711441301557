import findKey from 'lodash/findKey';

import constants from 'dispatcherConst';
import loginSuccessReducer from './loginSuccessReducer';
import resetPasswordSuccessReducer from './resetPasswordSuccessReducer';
import signupSuccessReducer from './signupSuccessReducer';
import twoFactorQRCodeSuccessReducer from './twoFactorQRCodeSuccessReducer';

const initialState = {
  fetching: false,
  error: false,
  currentUserId: '',
  loginChecked: false,
  loginEmail: '',
  recentlyLoggedOut: false,
  twoFactorNeedsSetup: false,
  twoFactorQRUri: null,
  twoFactorSecretKey: null,
  twoFactorOTPSubmitting: false,
  twoFactorOTPFailed: false,
  twoFactorActive: false,
  twoFactorDisabling: false,
  invitationsSuppressed: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT:
      return { ...state, currentUserId: '', loginChecked: true, recentlyLoggedOut: true };

    case constants.RESET_PASSWORD_SUCCESS:
      return resetPasswordSuccessReducer(state, action);

    case constants.SIGN_UP_REQUEST_SUCCESS:
      return signupSuccessReducer(state, action);

    case constants.LOGIN_SUCCESS:
      return loginSuccessReducer(state, action);

    case constants.FETCH_CURRENT_USER:
      return { ...state, fetching: true, error: false };

    case constants.FETCH_CURRENT_USER_SUCCESS: {
      const currentUserId = findKey(action.payload.response.users);
      return {
        ...state,
        currentUserId,
        loginChecked: true,
        recentlyLoggedOut: false,
        fetching: false,
        error: false,
        twoFactorOTPSubmitting: false,
        twoFactorDisabling: false,
      };
    }

    case constants.FETCH_CURRENT_USER_FAILED:
      return { ...state, loginChecked: true, fetching: false, error: true };

    case constants.FETCH_TWO_FACTOR_QR_CODE_SUCCESS:
      return twoFactorQRCodeSuccessReducer(state, action);

    case constants.SUBMIT_ONE_TIME_PASSWORD:
      return { ...state, twoFactorOTPSubmitting: true };

    case constants.SUBMIT_ONE_TIME_PASSWORD_SUCCESS:
      return { ...state, twoFactorOTPFailed: false, twoFactorOTPSubmitting: false };

    case constants.SUBMIT_ONE_TIME_PASSWORD_FAILED:
      return { ...state, twoFactorOTPFailed: true, twoFactorOTPSubmitting: false };

    case constants.SUPPRESS_INVITATIONS:
      return { ...state, invitationsSuppressed: true };

    case constants.DISABLE_TWO_FACTOR:
      return { ...state, twoFactorDisabling: true };

    case constants.DISABLE_TWO_FACTOR_SUCCESS:
      return { ...state, twoFactorDisabling: false };

    case constants.DISABLE_TWO_FACTOR_FAILED:
      return { ...state, twoFactorDisabling: false };

    default:
      return state;
  }
};

export default reducer;
