import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';

import { Button } from 'components/atomic';
import SettingCard from 'components/SettingCard/SettingCard';
import SettingConfirm from 'components/SettingConfirm/SettingConfirm';

class PharosFormatDeviceV1 extends PureComponent {

  constructor() {
    super();

    this.handleConfirm = throttle(this.handleConfirm.bind(this), 250);
  }

  handleConfirm() {
    const { resourceId, onFormat } = this.props;

    onFormat(resourceId);
  }

  render() {
    const { online, title } = this.props;

    const trigger = <span><Button type="button" primary disabled={!online}>Format</Button></span>;
    const confirm = (
      <SettingConfirm
        trigger={trigger}
        header="Format Device"
        content={(
          <p>
            Formatting the device will cause it to lose all locally
            stored files. The device will go offline momentarily
            while it resets. Do you want to continue?
          </p>
        )}
        onConfirm={this.handleConfirm}
        size="mini"
      />
    );

    return (
      <SettingCard title={title} trigger={confirm}>
        <p>
          Format the internal storage of the device, including
          the Designer project file.
        </p>
      </SettingCard>
    );
  }

}

PharosFormatDeviceV1.defaultProps = {
  title: '',
};

PharosFormatDeviceV1.propTypes = {
  online: PropTypes.bool.isRequired,
  resourceId: PropTypes.string.isRequired,
  title: PropTypes.string,
  onFormat: PropTypes.func.isRequired,
};

export default PharosFormatDeviceV1;
