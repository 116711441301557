import { DisableTwoFactor, SecretKey } from '../components';

const getWizardAccordionItems = twoFactorEnabled => {
  if (twoFactorEnabled) {
    return [
      {
        title: 'Refresh secret key',
        component: SecretKey,
      },
      {
        title: 'Disable Two Factor Authentication',
        component: DisableTwoFactor,
      },
    ];
  }

  return [
    {
      title: 'Set up Two Factor Authentication',
      component: SecretKey,
    },
  ];
};

export default getWizardAccordionItems;
