import React from 'react';
import PropTypes from 'prop-types';

import DataTable from 'components/DataTable/DataTableContainer';
import { ZONES_FIELD } from 'helpers/selectors';
import RefreshInterface from 'components/RefreshInterface/RefreshInterfaceContainer';
import ZoneTableRow from './ZoneTableRow/ZoneTableRowContainer';

const labels = [
  { text: 'Number', field: 'id', width: 2 },
  { text: 'Name', field: 'name', width: 3 },
  { text: 'Level', field: 'formattedLevel', width: 7 },
  { text: '', field: '', width: 3 },
];

const PathwayZonesV1 = ({ deviceId, title, refreshing, isOnline }) => {
  const refreshUI = (
    <RefreshInterface
      deviceId={deviceId}
      dataId="zones"
    />
  );

  return (
    <DataTable
      header={title}
      labels={labels}
      customTableRow={ZoneTableRow}
      createElement={refreshUI}
      pagination={{
        type: ZONES_FIELD,
        included: true,
      }}
      deviceId={deviceId}
      isOnline={isOnline}
      refreshing={refreshing}
      highlightUpdates
    />
  );
};

PathwayZonesV1.defaultProps = {
  title: '',
  refreshing: false,
  isOnline: false,
};

PathwayZonesV1.propTypes = {
  deviceId: PropTypes.string.isRequired,
  refreshing: PropTypes.bool,
  title: PropTypes.string,
  isOnline: PropTypes.bool,
};

export default PathwayZonesV1;
