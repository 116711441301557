import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledSelectField from './SelectField.style';

class SelectField extends Component {

  constructor() {
    super();

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, { value }) {
    const { input, onChangeCallback } = this.props;

    const { onChange } = input || {};

    if (onChange) {
      onChange(value);
    }

    if (onChangeCallback) {
      onChangeCallback(value);
    }
  }

  render() {
    const { input, meta, initialValue, onChangeCallback, ...restProps } = this.props;

    return (
      <StyledSelectField
        {...input}
        {...restProps}
        onChange={this.handleChange}
        selectOnBlur={false}
        selectOnNavigation={false}
      />
    );
  }

}

SelectField.defaultProps = {
  input: null,
  meta: null,
  initialValue: null,
  onChangeCallback: () => null,
};

SelectField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  meta: PropTypes.any,
  initialValue: PropTypes.any,
  onChangeCallback: PropTypes.func,
};

export default SelectField;
