import { emDashedData } from 'helpers/sharedVariables';

class FixtureGroupModel {

  constructor(data = {}) {
    this.id = data.id;
    this.hideId = data.hideId;
    this.name = data.name;
    this.status = data.status || emDashedData;
    this.type = data.type;
  }

  get visibleNumber() {
    const { id, hideId } = this;

    return hideId ? emDashedData : Number(id);
  }

}

export default FixtureGroupModel;
