import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/atomic';

const PageSelectionButtons = ({
  handleChange,
  totalPages,
  pageIndex,
}) => {
  const [localIndex, setLocalIndex] = useState(pageIndex);
  const displayButtons = parseInt(totalPages, 10) > 0;

  const setFirstPage = e => {
    e.preventDefault();

    handleChange(1);
    setLocalIndex(1);
  };

  const setLastPage = e => {
    e.preventDefault();

    handleChange(totalPages + 1);
    setLocalIndex(totalPages + 1);
  };

  useEffect(() => {
    setLocalIndex(pageIndex);
  }, [pageIndex]);

  return displayButtons && (
    <>
      <Button type="button" size="tiny" onClick={setFirstPage} positive={localIndex === 1}>First Page</Button>
      <Button type="button" size="tiny" onClick={setLastPage} positive={localIndex === totalPages + 1}>Last Page</Button>
    </>
  );
};

PageSelectionButtons.defaultProps = {
  totalPages: 0,
  pageIndex: 0,
};

PageSelectionButtons.propTypes = {
  handleChange: PropTypes.func.isRequired,
  totalPages: PropTypes.number,
  pageIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default PageSelectionButtons;
