import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import uploadsActionCreators from 'components/UploadsWidget/uploadsActionCreators';
import UploadsMobileController from './UploadsMobileController';

const mapDispatchToProps = {
  onClearCompleted: uploadsActionCreators.clearCompleted,
};

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(UploadsMobileController);
