import ControlUIButtonModel from 'models/ControlUIButtonModel';
import ControlUIOverrideControlModel from 'models/ControlUIOverrideControlModel';

class ControlUIPageModel {

  constructor(controlUIPage = {}) {
    this.index = controlUIPage.index;
    this.name = controlUIPage.name;
    this.pageType = controlUIPage.pageType || 'default';
    this.backgroundImg = controlUIPage.backgroundImg;
    this.backgroundImgBlob = controlUIPage.backgroundImgBlob;
    this.nameColor = controlUIPage.nameColor;
    this.buttons = (controlUIPage.buttons || []).map(
      button => new ControlUIButtonModel(button),
    );
    this.overrideControls = (controlUIPage.overrideControls || []).map(
      overrideControl => new ControlUIOverrideControlModel(overrideControl),
    );
    this.allowedUserIds = controlUIPage.allowedUserIds;
  }

}

export default ControlUIPageModel;
