import { connect } from 'react-redux';
import { deviceSelector } from 'helpers/selectors';
import PathwayVIALinkStatusV1 from './PathwayVIALinkStatusV1';

export const mapStateToProps = (state, { element: { resourceId } }) => {
  const { online } = deviceSelector(state)(resourceId);

  return {
    online,
  };
};

export default connect(mapStateToProps)(PathwayVIALinkStatusV1);
