import size from 'lodash/size';

const checkArrayLength = arr => {
  if (Array.isArray(arr) && size(arr) > 1) {
    return true;
  }

  return false;
};

export default checkArrayLength;
