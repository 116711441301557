import styled from 'styled-components';

import colors from 'themes/colors';

const FormErrorsWrapper = styled.aside`
  font-size: 1em;
  text-align: left;
  color: ${colors.stiletto};
  padding: 10px;
  margin: 30px 0 20px 0;
  background-color: ${colors.chablis};
  border: 1px ${colors.cavernPink} solid;
  border-radius: .28571429rem;

  li {
    margin-left: -20px;
  }
`;

export default FormErrorsWrapper;
