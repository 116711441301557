import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Responsive } from 'semantic-ui-react';

import { sizes } from 'themes/mixins';
import {
  StyledLogTableLine,
  StyledLogTableLineItem,
  StyledMobileLineHeader,
  StyledMobileLineMessage,
} from './LogTableLine.style';

const LogTableLine = ({ columns, ...rest }) => {
  const { date, time, category, level, message } = rest;
  const mobileLineHeader = `${date} ${time} - ${category} - ${level}`;

  return (
    <Fragment>
      <Responsive as={StyledLogTableLine} minWidth={sizes.md}>
        {columns.map(({ id, width }) => {
          const { [id]: item } = rest;

          return (
            <StyledLogTableLineItem key={id} width={width}>{item}</StyledLogTableLineItem>
          );
        })}
      </Responsive>
      <Responsive as={Fragment} maxWidth={sizes.md - 1}>
        <StyledMobileLineHeader>{mobileLineHeader}</StyledMobileLineHeader>
        <StyledMobileLineMessage>{message}</StyledMobileLineMessage>
      </Responsive>
    </Fragment>
  );
};

LogTableLine.defaultProps = {
  message: null,
};

LogTableLine.propTypes = {
  columns: PropTypes.array.isRequired,
  message: PropTypes.string,
};

export default LogTableLine;
