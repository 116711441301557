import React, { Component } from 'react';

const withPollingSaga = (interval, timeout) => WrappedComponent => {
  class Polling extends Component {

    componentDidMount() {
      const { chainedLoaderId, init } = this.props;

      if (chainedLoaderId) {
        init(chainedLoaderId, interval, timeout);
      } else {
        throw Error('Polling requires chainedloaderwithsaga');
      }
    }

    componentWillUnmount() {
      const { chainedLoaderId, terminate } = this.props;
      terminate(chainedLoaderId);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }

  }

  return Polling;
};

export default withPollingSaga;
