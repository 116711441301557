import { call, put } from 'redux-saga/effects';
import isNull from 'lodash/isNull';

import { postPath } from 'data/api/requests';
import { processFinalFormResponse } from 'utilities/form';
import actionCreators from '../actionCreators';

function* login(action) {
  const { endpoint, params: { values, resolveForm } } = action.payload;

  const { response, error } = yield call(postPath, endpoint, values);
  if (error instanceof Error) {
    error.message = 'No connection to server. Try later.';
  }

  const params = {
    response,
    error,
    resolveForm,
    successDisp: actionCreators.loginSuccess,
    failDisp: actionCreators.loginFailure,
    clearSession: true,
  };
  yield call(processFinalFormResponse, params);

  if (response) {
    const {
      meta: {
        [endpoint]: {
          meta: {
            two_factor_status: twoFactorStatus,
          },
        },
      },
    } = response;

    if (isNull(twoFactorStatus)) {
      yield put(actionCreators.fetchCurrentUser());
    }
  }
}

export default login;
