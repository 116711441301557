import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import {
  getCurrentSelectedValue,
  computeProjectsList,
  computeDropdownSelection,
} from './utilities';
import {
  StyledDropdown,
  StyledButton,
  StyledDiv,
} from './SelectSiteTasksDropdown.style';

const SelectSiteTasksDropdown = ({
  options,
  field,
  requestType,
  resourceId,
  existingSelectedProjects,
  onTriggerProjectSuiteTasksFiltering,
  filterParam,
  dropdownText,
  allSitesList,
}) => {
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [projectsList, setProjectsList] = useState([]);
  const disabled = isEmpty(selectedProjects);
  const displayDropdown = options.length > 1;

  useEffect(() => {
    setSelectedProjects(existingSelectedProjects);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProjectSelect = (e, { value }) => {
    const currentSelectedValue = getCurrentSelectedValue(value, selectedProjects);
    const paramValues = computeProjectsList(currentSelectedValue, options, value);
    const dropdownValues = computeDropdownSelection(currentSelectedValue, value);

    setSelectedProjects(dropdownValues);
    setProjectsList(paramValues);
  };

  const handleTriggerFilter = () => {
    const listOfProjects = isEqual(selectedProjects, ['allSites']) ? allSitesList : projectsList;

    if (projectsList.includes('superSiteTasks')) {
      return onTriggerProjectSuiteTasksFiltering(
        resourceId,
        [],
        filterParam,
        field,
        requestType,
      );
    }

    return onTriggerProjectSuiteTasksFiltering(
      resourceId,
      listOfProjects,
      filterParam,
      field,
      requestType,
    );
  };

  return displayDropdown && (
    <StyledDiv>
      <StyledDropdown
        text={dropdownText}
        clearable
        options={options}
        selection
        selectOnBlur={false}
        onChange={handleProjectSelect}
        value={selectedProjects}
        multiple
        search
      />
      <StyledButton
        onClick={handleTriggerFilter}
        disabled={disabled}
      >
        Filter
      </StyledButton>
    </StyledDiv>
  );
};

SelectSiteTasksDropdown.defaultProps = {
  allSitesList: [],
};

SelectSiteTasksDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  field: PropTypes.string.isRequired,
  requestType: PropTypes.symbol.isRequired,
  existingSelectedProjects: PropTypes.array.isRequired,
  onTriggerProjectSuiteTasksFiltering: PropTypes.func.isRequired,
  resourceId: PropTypes.string.isRequired,
  filterParam: PropTypes.string.isRequired,
  dropdownText: PropTypes.string.isRequired,
  allSitesList: PropTypes.array,
};

export default SelectSiteTasksDropdown;
