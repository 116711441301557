import styled from 'styled-components';

import { mediaMax } from 'themes/mixins';

export const StyledWrapper = styled('div')`
  flex-grow: 1;
  width: 36px;
  display: flex;
  height: 200px;

  ${mediaMax.sm`
    height: 150px;
  `}
`;

export const StyledSliderBar = styled('div')`
  padding: 10px;
  height: 100%;
  width: 10px;
  border-radius: 5px;
  align-self: center;
  background: linear-gradient(to top, rgb(251,132,42) 0%, rgb(255,255,255) 50%, rgb(52, 122, 250) 100%);
`;

export default StyledWrapper;
