import React from 'react';
import { Grid, Segment, Header } from 'semantic-ui-react';

import { TwoFactorInfo, TwoFactorWizard } from './components';

const TwoFactorAuthentication = () => (
  <Segment>
    <Header>Two Factor Authentication</Header>
    <Grid>
      <Grid.Row>
        <Grid.Column computer={8} mobile={16}>
          <TwoFactorInfo />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column computer={16} mobile={16}>
          <TwoFactorWizard />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
);

export default TwoFactorAuthentication;
