import isNull from 'lodash/isNull';
import convertTime from '../../../components/AutomatedOperationsAccordionRow/components/SchedulesTable/components/AddSchedule/AddScheduleModalForm/components/TokenButton/utilities/convertTime';
import stringToArray from './stringToArray';

const prepareSchedulesParams = obj => {

  if (isNull(obj)) {
    return null;
  }

  const {
    advancedMode,
    hours,
    minutes,
    seconds,
    selectedDates,
    selectedTimes,
    sunrise,
    sunset,
  } = obj;


  return {
    specificDates: selectedDates.split(','),
    hours: advancedMode ? stringToArray(hours, true) : [],
    minutes: advancedMode ? stringToArray(minutes) : [],
    seconds: advancedMode ? stringToArray(seconds) : [],
    specificTimes: selectedTimes ? selectedTimes.split(',').map(item => convertTime(item)) : [],
    sunrise: sunrise ? stringToArray(sunrise) : [],
    sunset: sunset ? stringToArray(sunset) : [],
  };
};

export default prepareSchedulesParams;
