import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/atomic';
import ConfirmModal from 'components/elements/ConfirmModal/ConfirmModal';
import ThemedToggle from 'components/elements/ThemedToggle/ThemedToggle.style';

const NotificationsToggle = ({ checked, handleSubmit, input: { onChange } }) => {
  const handleConfirm = () => {
    onChange(!checked);
    handleSubmit();
  };
  const trigger = (
    <ThemedToggle
      checked={Boolean(checked)}
    />
  );
  const confirmation = checked ? 'Disable' : 'Enable';
  const confirmButton = (
    <Button
      negative
      primary={false}
    >
      {confirmation}
      &nbsp;Notifications
    </Button>
  );
  const validationString = `${confirmation} Notifications`;

  return (
    <ConfirmModal
      trigger={trigger}
      cancelButton="Cancel"
      confirmButton={confirmButton}
      content={(
        <div>
          <p>
            Modifying the Notifications Enabled setting will allow the tenant
            to use the latest Notification features.
          </p>
          <p>
            Enter the text <strong>{validationString}</strong> to confirm:
          </p>
        </div>
      )}
      header="Confirm Notifications Enabled setting change"
      validationMessage="Enter the correct text to confirm this change"
      validationString={validationString}
      onConfirm={handleConfirm}
    />
  );
};

NotificationsToggle.defaultProps = {
  checked: false,
};

NotificationsToggle.propTypes = {
  checked: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default NotificationsToggle;
