import constants from 'dispatcherConst';

export const setRecurrenceErrors = errors => ({
  type: constants.SET_RECURRENCE_ERRORS,
  payload: {
    recurrenceErrors: errors,
  },
});

export default setRecurrenceErrors;
