import { call, select, put, take } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';

import selectors from '../selectors';
import actionCreators from '../actionCreators';

import transformFetchCumulatedPermissionsResponse from '../transformFetchCumulatedPermissionsResponse';

function* fetchCumulatedPermissions(action) {
  const {
    endpoint,
    recipientIds,
    recipientType,
    resourceType,
    resourceName,
    resourceId,
    childResourceIds,
    limitedScope,
    limitedFor,
    context,
    ...rest
  } = action.payload;

  const urlParams = {
    'recipientIds[]': recipientIds,
    recipientType,
    resourceType,
  };

  if (context !== 'tenant' && resourceId) {
    urlParams['resourceIds[]'] = resourceId;
  }

  const allPermissionsLoaded = yield select(selectors.allPermissionsLoadedSelector);

  if (!allPermissionsLoaded) {
    yield put(actionCreators.fetchAllPermissions());

    yield take(constants.FETCH_ALL_PERMISSIONS_SUCCESS);
  }

  const allPermissions = yield select(selectors.allPermissionsSelector);

  const { response, error } = yield call(getPath, endpoint, { urlParams });

  let enhancedResponse = response;

  if (!error) {
    const {
      cumulatedPermissions,
      permissionsTableRows,
    } = transformFetchCumulatedPermissionsResponse({
      allPermissions,
      originalCumulatedPermissions: response.data,
      resourceId,
      resourceType,
      resourceName,
      recipientIds,
      recipientType,
      limitedScope,
      limitedFor,
      context,
    });

    enhancedResponse = {
      ...response,
      recipientIds,
      recipientType,
      resourceType,
      resourceId,
      allPermissions,
      newCumulatedPermissions: cumulatedPermissions,
      newPermissionsTableRows: permissionsTableRows,
    };
  }

  const params = {
    response: enhancedResponse,
    error,
    successDisp: actionCreators.fetchCumulatedPermissionsSuccess,
    failDisp: actionCreators.fetchCumulatedPermissionsFailure,
    ...rest,
  };

  yield call(processCall, params);
}

export default fetchCumulatedPermissions;
