import constants from 'dispatcherConst';

import { DEFAULT_SCHEDULER_TYPE, TASK_SCHEDULER_MODE } from '../../constants';
import getDefaultTaskName from '../getDefaultTaskName';

const initialState = {
  selectedDevice: {},
  selectedActions: [],
  selectedTasks: [],
  availableTasks: [],
  existingSchedule: null,
  taskName: '',
  mode: TASK_SCHEDULER_MODE.DEFAULT,
  schedulerType: DEFAULT_SCHEDULER_TYPE,
  endingCriteria: '',
  isLoading: false,
  isLoadingTasks: false,
  recurrenceParams: {},
  recurrenceErrors: {},
  isLoadingDevices: false,
  devices: [],
  schedulerName: null,
  schedulerDescription: null,
  selectedProject: {},
};

const taskSchedulerReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_SELECTED_DEVICE: {
      const { device } = action.payload;

      return { ...state, selectedDevice: device };
    }

    case constants.ADD_SELECTED_ACTION: {
      const { selectedAction } = action.payload;

      const newSelectedActions = [
        ...state.selectedActions,
        {
          deviceId: state.selectedDevice.id,
          deviceName: state.selectedDevice.name,
          projectId: state.selectedProject.id,
          projectName: state.selectedProject.name,
          resourceId: state.selectedDevice.id || state.selectedProject.id,
          resourceName: state.selectedDevice.name || state.selectedProject.name,
          actionId: selectedAction.id,
          actionName: selectedAction.name,
          index: selectedAction.index,
        },
      ];

      return {
        ...state,
        selectedActions: newSelectedActions,
        taskName: getDefaultTaskName(newSelectedActions),
      };
    }

    case constants.REMOVE_SELECTED_ACTION: {
      const { actionId, resourceId, actionIndex } = action.payload;

      const condition = params => (
        params.resourceId === resourceId
        && params.actionId === actionId
        && params.index === actionIndex
      );

      const existingActionIndex = state.selectedActions.findIndex(condition);

      if (existingActionIndex === -1) {
        return state;
      }

      if (state.selectedActions.length === 1) {
        return {
          ...state,
          selectedActions: [],
        };
      }

      const copyItems = state.selectedActions;
      copyItems.splice(existingActionIndex, 1);

      return {
        ...state,
        selectedActions: [
          ...copyItems,
        ],
        taskName: getDefaultTaskName(copyItems),
      };
    }

    case constants.SET_TASK_NAME: {
      const { taskName } = action.payload;

      return {
        ...state,
        taskName,
      };
    }

    case constants.ADD_SELECTED_TASK: {
      const { selectedTask } = action.payload;

      if (state.selectedTasks.find(task => task.id === selectedTask.id)) {
        return {
          ...state,
          selectedTasks: state.selectedTasks.filter(task => task.id !== selectedTask.id),
        };
      }

      return {
        ...state,
        selectedTasks: [
          ...state.selectedTasks,
          selectedTask,
        ],
      };
    }

    case constants.FETCH_TASKS_REQUEST:
    case constants.FETCH_PROJECT_SUITE_PROJECTS_TASKS_REQUEST: {
      return { ...state, isLoadingTasks: true };
    }

    case constants.FETCH_TASKS_SUCCESS:
    case constants.FETCH_PROJECT_SUITE_PROJECTS_TASKS_SUCCESS: {
      const { response } = action.payload;
      const { data } = response;

      const formattedTasks = [];

      data.forEach(item => {
        formattedTasks.push({
          id: item.id,
          ...item.attributes,
        });
      });

      return { ...state, isLoadingTasks: false, availableTasks: formattedTasks };
    }

    case constants.FETCH_TASKS_FAILED:
      return { ...state, isLoadingTasks: false };


    case constants.SET_SCHEDULER_TYPE: {
      const { schedulerType } = action.payload;

      return { ...state, schedulerType };
    }

    case constants.SET_TASK_SCHEDULER_MODE: {
      const { mode } = action.payload;

      return { ...state, mode };
    }

    case constants.SET_EXISTING_SCHEDULE: {
      const { existingSchedule } = action.payload;

      return {
        ...state,
        existingSchedule,
        schedulerName: existingSchedule.name,
        schedulerDescription: existingSchedule.description,
      };
    }

    case constants.SET_ENDING_CRITERIA: {
      const { endingCriteria } = action.payload;

      return {
        ...state,
        endingCriteria,
      };
    }

    case constants.SET_RECURRENCE_PARAMS: {
      const { recurrenceParams } = action.payload;

      return { ...state, recurrenceParams };
    }

    case constants.SET_RECURRENCE_ERRORS: {
      const { recurrenceErrors } = action.payload;

      return { ...state, recurrenceErrors };
    }

    case constants.CLEAR_SCHEDULER_PARAMS: {
      return {
        ...state,
        recurrenceParams: {},
      };
    }

    case constants.ADD_TASK_SCHEDULER:
      return { ...state, isLoading: true };

    case constants.RESET_TASK_SCHEDULER:
    case constants.ADD_TASK_SCHEDULER_SUCCESS:
    case constants.ADD_TASK_SCHEDULER_FAILED:
      return {
        ...state,
        isLoading: false,
        selectedDevice: {},
        selectedTasks: [],
        selectedActions: [],
        taskName: '',
        availableTasks: [],
        schedulerType: DEFAULT_SCHEDULER_TYPE,
        recurrenceParams: {},
        existingSchedule: null,
        isLoadingTasks: false,
        schedulerName: null,
        schedulerDescription: null,
      };

    case constants.TASK_SCHEDULER_FETCH_PROJECT_DEVICES_REQUEST:
      return {
        ...state,
        devices: [],
        isLoadingDevices: true,
      };

    case constants.TASK_SCHEDULER_FETCH_PROJECT_DEVICES_SUCCESS: {
      const { response } = action.payload || {};
      const { data = [] } = response;

      return {
        ...state,
        isLoadingDevices: false,
        devices: data,
      };
    }


    case constants.TASK_SCHEDULER_FETCH_PROJECT_DEVICES_FAILED:
      return {
        ...state,
        devices: [],
        isLoadingDevices: false,
      };

    case constants.SET_SCHEDULER_NAME: {
      const { schedulerName } = action.payload;

      return {
        ...state,
        schedulerName,
      };
    }

    case constants.SET_SCHEDULER_DESCRIPTION: {
      const { schedulerDescription } = action.payload;

      return {
        ...state,
        schedulerDescription,
      };
    }

    case constants.SET_SELECTED_PROJECT: {
      const { project } = action.payload;

      return { ...state, selectedProject: project };
    }

    default:
      return state;
  }

};

export default taskSchedulerReducer;
