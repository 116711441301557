import isEmpty from 'lodash/isEmpty';

export default function getDefaultProtocolUniverse({ patches = [] }) {
  if (!patches || isEmpty(patches)) {
    return {
      protocolId: null,
      universeId: null,
    };
  }

  let defaultProtocol = null;
  let defaultUniverse = null;

  const firstPatch = patches[0];
  const { universes = [] } = firstPatch || {};

  defaultProtocol = firstPatch.id;

  if (!isEmpty(universes)) {
    defaultUniverse = universes[0].id;
  }

  return {
    defaultProtocolId: Number(defaultProtocol),
    defaultUniverseId: Number(defaultUniverse),
  };
}
