import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Header, Grid, Modal, Responsive, Icon } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';

import { sizes } from 'themes/mixins';
import { Button } from 'components/atomic';

import {
  StyledModal,
  StyledModalContent,
  StyledModalActions,
  StyledSubheader,
  Container,
  StyledNextStepButton,
} from './MultiStepModal.style';

export default function MultiStepModal({
  steps,
  currentStep,
  trigger,
  open,
  onOpen,
  onClose,
  onPreviousStep,
  onNextStep,
  submitText,
  isSubmitting,
  ...restProps
}) {
  const renderTitle = useCallback(() => {
    if (!steps) {
      return '';
    }

    const { title } = steps[currentStep];

    return title;
  }, [currentStep, steps]);

  const renderDescription = useCallback(() => {
    if (!steps) {
      return '';
    }

    const { description } = steps[currentStep];

    return description;
  }, [currentStep, steps]);

  const renderContent = useCallback(() => {
    if (!steps) {
      return '';
    }

    return (
      <>
        {steps.map((step, index) => {
          const { title, content } = step;

          return (
            <Container
              key={`multi-step-modal-${title}`}
              isVisible={index === currentStep}
              className="multi-step-modal-content"
            >
              {content()}
            </Container>
          );
        })}
      </>
    );
  }, [steps, currentStep]);

  const renderPreviousStepContent = useCallback(() => (
    <>
      <Responsive maxWidth={sizes.md - 1}>
        <Icon name="arrow left" />
      </Responsive>
      <Responsive minWidth={sizes.md}>
        Previous Step
      </Responsive>
    </>
  ), []);

  const renderNextStepContent = useCallback(() => (
    <>
      <Responsive maxWidth={sizes.md - 1}>
        <Icon name={currentStep === steps.length - 1 ? 'check' : 'arrow right'} />
      </Responsive>
      <Responsive minWidth={sizes.md}>
        {currentStep === steps.length - 1 ? submitText : 'Next Step'}
      </Responsive>
    </>
  ), [currentStep, steps, submitText]);

  return (
    <StyledModal
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      trigger={trigger}
      {...restProps}
    >
      <Modal.Header>
        <Header as="h3">
          {renderTitle()}
          <StyledSubheader>
            {renderDescription()}
          </StyledSubheader>
        </Header>
      </Modal.Header>
      <StyledModalContent scrolling>
        {renderContent()}
      </StyledModalContent>
      <StyledModalActions>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={6} tablet={6} computer={10} textAlign="left">
              <Button
                primary
                basic
                className="multi-step-modal-btn-cancel"
                onClick={onClose}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </Grid.Column>
            <Grid.Column mobile={10} tablet={10} computer={6}>
              {!isEmpty(steps) && steps.length > 1 && (
                <Button
                  content={renderPreviousStepContent()}
                  onClick={onPreviousStep}
                  className="multi-step-modal-btn-previous"
                  disabled={currentStep === 0 || isSubmitting}
                  primary
                  basic
                />
              )}
              <StyledNextStepButton
                content={renderNextStepContent()}
                onClick={onNextStep}
                className="multi-step-modal-btn-next"
                loading={isSubmitting}
                disabled={steps[currentStep].nextStepDisabled || isSubmitting}
                primary
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </StyledModalActions>
    </StyledModal>
  );
}

MultiStepModal.defaultProps = {
  submitText: 'Confirm',
  isSubmitting: false,
};

MultiStepModal.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    content: PropTypes.func.isRequired,
    nextStepDisabled: PropTypes.bool,
  })).isRequired,
  currentStep: PropTypes.number.isRequired,
  trigger: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onPreviousStep: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  isSubmitting: PropTypes.bool,
};
