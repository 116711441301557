import styled from 'styled-components';

export const StyledLogTableLine = styled.div`
  min-height: 2em;
  display: flex;
  flex-wrap: nowrap;
`;

export const StyledLogTableLineItem = styled.div`
  display: flex;
  ${({ width }) => (width ? `width: ${width}px;` : 'flex: 1;')}
`;

export const StyledMobileLineHeader = styled.div`
  float: left;
`;

export const StyledMobileLineMessage = styled.div`
  margin-left: 25px;
  margin-bottom: 10px;
  clear: left;
`;
