import { put, select } from 'redux-saga/effects';

import selectors from '../selectors';
import actionCreators from '../actionCreators';

function* setPageNameColor(action) {
  const { pageId, projectId, values: { nameColor }, ...rest } = action.payload;

  const { pages, ...attributes } = (yield select(selectors.projectControlUISelector))(projectId);

  const newPages = pages.map(({ index, ...page }) => {
    if (index === pageId) {
      return { index, ...page, nameColor };
    }

    return { index, ...page };
  });

  yield put(actionCreators.updateControlUI({
    pages: newPages,
    ...attributes,
    ...rest,
  }));
}

export default setPageNameColor;
