import isEmpty from 'lodash/isEmpty';
import { LOGICAL_DEVICES_FIELD, USERS_FIELD } from 'helpers/selectors';

const projectPermissionsReady = (permissionsState, projectId) => {
  const { links, projects } = permissionsState;

  if (!links) {
    return false;
  }

  const {
    [projectId]: projectLinks,
  } = links;

  if (!projectLinks) {
    return false;
  }

  const {
    [USERS_FIELD]: usersLink,
    [LOGICAL_DEVICES_FIELD]: logicalDevicesLink,
  } = projectLinks;

  const { [projectId]: permissionsInfo } = projects || {};

  const { data } = permissionsInfo || {};

  const linksReady = !!(usersLink && logicalDevicesLink);
  const dataReady = !!data && !isEmpty(data);

  return linksReady && dataReady;
};

export default projectPermissionsReady;
