import { SubmissionError } from 'redux-form';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import has from 'lodash/has';

import { reasons } from 'models/OccurrenceModel/constants';

class AsyncErrorParser {

  constructor(error) {
    this.error = error;
  }

  get notFound() {
    const { errors } = this.error;
    if (!errors || !errors.length) {
      return false;
    }

    return !!errors.find(val => val.status === '404');
  }

  get unauthorized() {
    const { errors } = this.error;
    if (!errors || !errors.length) {
      return false;
    }

    return !!errors.find(val => val.status === '401');
  }

  get toArray() {
    return this.getArrayOfMessages();
  }

  get toFormError() {
    return this.wrapAsSubmissionError();
  }

  get tasksError() {
    return this.getTasksSpecificErrors();
  }

  get actionsExecutionError() {
    return this.getActionExecutionSpecificErrors();
  }

  getArrayOfMessages() {
    if (this.error instanceof Error) {
      return [this.error.message];
    }

    return this.processServerResponse()._error;
  }

  getActionsErrors() {
    if (this.error instanceof Error) {
      return [this.error.message];
    }

    const { errors = [] } = this.error;
    const errorUnavailable = ['Contact the system administrator for more details.'];

    if (errors.length === 0) {
      return errorUnavailable;
    }

    const errorObject = errors[0];
    const errorsPath = 'meta._error[0].actions';
    const doesErrorsExist = get(errorObject, errorsPath);

    if (doesErrorsExist && Array.isArray(errors[0].meta._error[0].actions)) {
      return errors[0].meta._error[0].actions
        .map(({ params }) => params[0]);
    }

    return errorUnavailable;
  }

  getTasksSpecificErrors() {
    if (this.error instanceof Error) {
      return [this.error.message];
    }

    const { errors = [] } = this.error;
    const errorUnavailable = [
      'Undefined error list',
      'Please contact system administrator',
    ];

    if (errors.length === 0) {
      return errorUnavailable;
    }

    const errorObject = errors[0];
    const { title, detail } = errorObject;
    const generalErrorsList = [title, detail];
    const actionParamErrors = this.getActionsErrors();

    const finalErrorList = [
      ...generalErrorsList,
      ...actionParamErrors,
    ];

    return finalErrorList;
  }

  getActionExecutionSpecificErrors() {
    if (this.error instanceof Error) {
      return [this.error.message];
    }

    const { errors = [] } = this.error;
    const errorUnavailable = [
      'Undefined error list',
      'Please contact system administrator',
    ];

    if (errors.length === 0) {
      return errorUnavailable;
    }

    const errorObject = errors[0];
    const { title, detail } = errorObject;

    const generalErrorsList = [];

    generalErrorsList.push(title);

    if (Array.isArray(detail)) {
      detail.forEach(detailItem => {
        const [actionType] = Object.values(detailItem);
        const [reasonName] = actionType;
        const formattedReason = has(reasons, reasonName) ? reasons[reasonName].display : null;

        if (formattedReason) {
          generalErrorsList.push(formattedReason);
        }
      });
    }

    return generalErrorsList;
  }

  wrapAsSubmissionError() {
    if (this.error instanceof Error) {
      return new SubmissionError({ _error: [this.error.message] });
    }

    return new SubmissionError(this.processServerResponse());
  }

  processServerResponse() {
    const { errors = [] } = this.error;

    const formErrors = errors
      .map(val => val.meta || 'Missing meta field')
      .map(val => (typeof val === 'string' ? val : val._error))
      .filter(val => val);

    const fieldsErrors = errors
      .map(val => val.meta)
      .filter(val => val)
      .map(({ _error, ...rest }) => ({ ...rest }));

    return Object.assign(
      {
        _error: formErrors.length ? flatten(formErrors) : null,
      },
      ...fieldsErrors,
    );
  }

}

export default AsyncErrorParser;
