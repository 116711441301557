import styled from 'styled-components';
import { Table } from 'semantic-ui-react';

export const AddActionButtonRowMobile = styled(Table.Row)`
  @media only screen and (min-width: 991px) {
    display: none !important;
  }
`;

export const AddActionButtonCellMobile = styled(Table.Cell)`
  @media only screen and (max-width: 991px) {
    display: block !important;
    position: relative !important;
    transform: translateY(0px) !important;
    width: 100% !important;
    margin-right: 0px !important;
    text-align: right !important;
  }
`;
