import styled from 'styled-components';

const StyledDiv = styled('div')`
  text-align: right;
`;

export {
  StyledDiv,
};

export default StyledDiv;
