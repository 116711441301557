import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Responsive } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { paths } from 'navigation';
import { sizes } from 'themes/mixins';
import { SimpleGateway, frontendPermissions } from 'gateway';
import TenantSelect from 'components/TopBar/TenantSelect/TenantSelectContainer';
import TenantLogo from 'components/TenantLogo/TenantLogo.style';

import { MenuItem, MultiSitesMenuItem, VouchersMenuItem } from './components';
import StyledSidebar from './SidebarMenu.style';

const SidebarMenu = ({
  visible,
  inControlPanelContext,
  tenantNotChanged,
  isSuperAdmin,
  projectSuites,
  canManageVouchers,
}) => (
  <StyledSidebar
    forwardedAs={Menu}
    animation="overlay"
    visible={visible && !inControlPanelContext}
    vertical
  >
    <TenantLogo>
      <Link to="/">
        <TenantLogo.Image test="test" />
      </Link>
    </TenantLogo>
    {tenantNotChanged && <MenuItem to={paths.userProjects} />}
    {tenantNotChanged && (
      <MultiSitesMenuItem
        isSuperAdmin={isSuperAdmin}
        projectSuites={projectSuites}
      />
    )}
    <SimpleGateway condition={frontendPermissions.TENANT_MANAGER_USERS}>
      <MenuItem to={paths.users} />
    </SimpleGateway>
    <SimpleGateway condition={frontendPermissions.TENANT_MANAGER_PERMISSIONS}>
      <MenuItem to={paths.permissions} />
    </SimpleGateway>
    <SimpleGateway condition={frontendPermissions.TENANT_MANAGER_SITES}>
      <MenuItem to={paths.projects} />
      <MenuItem to={paths.projectSuites} />
    </SimpleGateway>
    <VouchersMenuItem
      isSuperAdmin={isSuperAdmin}
      canManageVouchers={canManageVouchers}
    />
    <SimpleGateway>
      <MenuItem to={paths.companies} />
      <MenuItem to={paths.settings} />
      <Responsive maxWidth={sizes.md - 1} as={Menu.Item}>
        <TenantSelect />
      </Responsive>
    </SimpleGateway>
    {isSuperAdmin && (
      <SimpleGateway>
        <MenuItem to={paths.firmware} />
      </SimpleGateway>
    )}
  </StyledSidebar>
);

SidebarMenu.defaultProps = {
  isSuperAdmin: false,
  projectSuites: [],
  canManageVouchers: false,
};

SidebarMenu.propTypes = {
  tenantNotChanged: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  inControlPanelContext: PropTypes.bool.isRequired,
  isSuperAdmin: PropTypes.bool,
  projectSuites: PropTypes.array,
  canManageVouchers: PropTypes.bool,
};

export default SidebarMenu;
