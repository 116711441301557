const STEP = 1;
const MIN = 0;
const MAX = 100;
const INITIAL_TEMP_VALUE = 50;
const INITIAL_INTENSITY_VALUE = 100;

export {
  STEP,
  MIN,
  MAX,
  INITIAL_TEMP_VALUE,
  INITIAL_INTENSITY_VALUE,
};
