import UrlParams from 'helpers/UrlParams';
import { urlDateFormat, urlDatePagination } from '../constants';

const setUrlDate = (date, { history, location }) => {
  const urlParams = new UrlParams({ pagination: urlDatePagination });
  urlParams.page = date.toFormat(urlDateFormat);
  const { search } = urlParams;

  history.push({ ...location, search });
};

export default setUrlDate;
