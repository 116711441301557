import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import { ChildPanelHeader, PanelSeparator } from 'components/core/Panel/Panel';

import { SCHEDULER_TYPE_CONFIG } from '../../constants';
import { ConfigurationPanel } from '..';

function DesktopView({
  schedulerType,
  resourceType,
  onSchedulerTypeSelect,
  onClearSchedulerParams,
}) {

  const selectedSchedulerType = useMemo(() => {
    if (!schedulerType) {
      return null;
    }

    return SCHEDULER_TYPE_CONFIG[schedulerType];
  }, [schedulerType]);

  return (
    <Grid.Row>
      <Grid.Column width={6}>
        <ChildPanelHeader
          title="Configuration"
          description="Set the required configurations."
        />
        <ConfigurationPanel
          schedulerType={schedulerType}
          resourceType={resourceType}
          onSchedulerTypeSelect={onSchedulerTypeSelect}
          onClearSchedulerParams={onClearSchedulerParams}
        />
      </Grid.Column>
      <Grid.Column width={10}>
        {selectedSchedulerType.renderHeader && (
          <>
            <ChildPanelHeader
              title={selectedSchedulerType.title}
              description={selectedSchedulerType.description}
            />
            <PanelSeparator />
          </>
        )}
        {selectedSchedulerType.content()}
      </Grid.Column>
    </Grid.Row>
  );
}

DesktopView.defaultProps = {
  resourceType: 'project',
};

DesktopView.propTypes = {
  schedulerType: PropTypes.string.isRequired,
  resourceType: PropTypes.oneOf([
    'project',
    'projectSuite',
  ]),
  onSchedulerTypeSelect: PropTypes.func.isRequired,
  onClearSchedulerParams: PropTypes.func.isRequired,
};

export default DesktopView;
