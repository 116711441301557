import styled from 'styled-components';

export const ActionsList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding-inline-start: 0;
`;

export const ActionItem = styled.li`
  padding: 8px 12px;
  border-radius: 5px;
  border: 1px solid #d4d4d5;
  margin-bottom: 15px;
  font-weight: 700;
  color: rgba(0,0,0,.4);
  flex: 1;
`;

export default ActionItem;
