import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const fetchVoucherDetails = ({ projectId, voucher }) => ({
  type: constants.FETCH_VOUCHER_DETAILS_REQUEST,
  payload: {
    projectId,
    voucher,
    endpoint: projectId ? endpoints.VOUCHER_DETAILS_PROJECT(projectId) : endpoints.VOUCHER_DETAILS,
  },
});

export const fetchVoucherDetailsSuccess = response => ({
  type: constants.FETCH_VOUCHER_DETAILS_SUCCESS,
  payload: { response },
});

export const fetchVoucherDetailsFailure = error => ({
  type: constants.FETCH_VOUCHER_DETAILS_FAILED,
  payload: { error },
});
