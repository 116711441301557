import pickBy from 'lodash/pickBy';
import constants from 'dispatcherConst';

export const initialState = {};

const withValueOverrideReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.SET_VALUE_OVERRIDE: {
      return {
        ...state,
        [payload.id]: {
          ...payload,
        },
      };
    }
    case constants.VALUE_OVERRIDE_FAILED:
    case constants.VALUE_OVERRIDE_SUCCESS: {
      const { [payload.id]: _, ...rest } = state;
      return rest;
    }
    case constants.RESET_VALUE_OVERRIDE: {
      return {
        ...pickBy(state, (value, key) => key.indexOf(payload.id) === -1),
      };
    }
    default:
      return state;
  }
};

export default withValueOverrideReducer;
