import { compose } from 'redux';
import { connect } from 'react-redux';

import { deepEqualMemoizeOne } from 'helpers/sharedMethods';
import {
  computedProjectTreePathSelector,
  permissionsProjectActiveUserIdsSelector,
} from 'helpers/selectors';
import { PermissionsGridRow } from '../../../../../../../../../../components';
import { sortPaths } from '../../../../../../../../../../utilities';
import { ContextProjectPermissionsGridItem } from '../PermissionsGridItem';

// eslint-disable-next-line import/no-mutable-exports
let ContextProjectPermissionsGridRowContainer;

export const makeMapState = () => {
  const memoPaths = deepEqualMemoizeOne();

  return function mapStateToProps(state, { path, resourceId }) {
    const { open, permissionCount, permissionId, ...paths } = computedProjectTreePathSelector(
      state,
      {
        projectId: resourceId,
        path,
        scopes: ['tenant'],
      },
    );
    const userIds = permissionsProjectActiveUserIdsSelector(state, { projectId: resourceId });
    const sortedPaths = sortPaths(paths);

    return {
      open,
      userIds,
      paths: memoPaths(sortedPaths),
      PermissionsGridItem: ContextProjectPermissionsGridItem,
      PermissionsGridRowContainer: ContextProjectPermissionsGridRowContainer,
    };
  };
};

ContextProjectPermissionsGridRowContainer = compose(
  connect(makeMapState),
)(PermissionsGridRow);

export default ContextProjectPermissionsGridRowContainer;
