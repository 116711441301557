import styled from 'styled-components';
import { Table } from 'semantic-ui-react';

export const ActionTableWrapperCell = styled(Table.Cell)`
  padding-left: 0px !important;

  @media only screen and (max-width: 991px) {
    padding-left: 10px !important;
  }
`;

export default ActionTableWrapperCell;
