import constants from 'dispatcherConst';

const initialState = {
  visible: true,
};

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SHOW_SIDEBAR:
      return { ...state, visible: true };

    case constants.HIDE_SIDEBAR:
      return { ...state, visible: false };

    default:
      return state;
  }
};

export default menuReducer;
