import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import { FaExclamationTriangle } from 'react-icons/fa';

const SuspendedIcon = ({ message, color }) => (
  <Popup
    content={message}
    trigger={(
      <FaExclamationTriangle size={30} color={color} />
    )}
    position="bottom right"
  />
);

SuspendedIcon.defaultProps = {
  color: '#818181',
};

SuspendedIcon.propTypes = {
  message: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default SuspendedIcon;
