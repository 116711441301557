import styled, { css } from 'styled-components';
import { List, Icon } from 'semantic-ui-react';
import get from 'lodash/get';

import { mediaMax } from 'themes/mixins';

export const FloatingCard = styled.div`
  top: 0;
  position: fixed;
  right: -500px;
  width: 500px;
  height: 100%;
  background-color: #fff;
  z-index: 103 !important;
  transition: right .4s;
  box-shadow: 0 0 0 1px rgb(89 105 129 / 10%), 
              0 3px 20px 0 rgb(89 105 129 / 30%), 
              0 1px 2px 0 rgb(0 0 0 / 5%);

  ${mediaMax.sm`
    margin: 0;
    width: 100vw;
    right:-100vw;
    height: auto;
    max-height: 70vh !important;
    top: unset;
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
    bottom: -70vh;
    transition: bottom .4s !important;
    overflow-y: auto;
  `};

  ${({ isVisible }) => isVisible && css`
    transform: translateX(0);
    right: 0 !important;
    transition: right .4s;

    ${mediaMax.sm`
      transition: bottom .4s;
      bottom: 0 !important;
    `};
  `}
`;

export const StyledHeader = styled.div`
  padding: 1em 1em;
  font-weight: 700;
  border-bottom: 1px solid rgba(34,36,38,.15);
  position: sticky;
  top: 0;
  background-color: #fff;
  
  & {
    h4 {
      font-size: 17px;
    }
  }

  ${mediaMax.sm`
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
  `};
`;

export const Body = styled.div`
  height: calc(100% - 52px);
  overflow: auto;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: calc(-100vw);
  bottom: 0;  
  z-index: 102 !important;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.7s;

  ${({ isVisible }) => isVisible && css`
    background-color: rgba(0, 0, 0, 0.5);
    right: 0 !important;
    left: 0;
    transition: background-color 0.7s;
  `}
`;

export const StyledListItem = styled(List.Item)`
  padding: 1em 1em !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const StyledPageInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & {
    h5 {
      margin: 0;
      margin-right: 24px;
    }

    small {
      font-size: 12px;
      color: rgba(0,0,0,.6);
    }     
  }
`;

export const StyledListContent = styled(List.Content)`
  display: flex !important;
  justify-content: space-between;
  align-items: center;
`;

export const StyledIcon = styled(Icon)`
  ${({ theme }) => css`
    &.icon {
      height: auto !important;
      color: ${get(theme, 'palette.primaryColor')} !important;
    }
  `}
`;
