import React from 'react';
import { Input } from 'semantic-ui-react';
import FormField from 'components/form/FormField/FormField';
import MailgunDomainSelect from 'components/MailgunDomainSelect/MailgunDomainSelect';

const MailgunDomainField = ({ input, ...rest }) => (
  <FormField {...rest} input={input} alignItems="center">
    <MailgunDomainSelect
      {...input}
      input={{ fluid: true }}
    />
  </FormField>
);
MailgunDomainField.propTypes = {
  ...Input.propTypes,
};

export default MailgunDomainField;
