import { compose } from 'redux';
import { connect } from 'react-redux';

import { actionCreators } from './utilities';
import DisableTwoFactor from './DisableTwoFactor';

const mapDispatchToProps = {
  onDisableTwoFactor: actionCreators.disableTwoFactor,
};

export default compose(
  connect(null, mapDispatchToProps),
)(DisableTwoFactor);
