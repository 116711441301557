import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import set from 'lodash/fp/set';
import omit from 'lodash/omit';

import {
  removeRelationship,
  mergeCustomizer,
} from 'helpers/sharedMethods';
import constants from 'dispatcherConst';
import {
  ORIGINS_FIELD,
  PROJECTS_FIELD,
  PROJECT_SUITES_FIELD,
  USERS_FIELD,
  LOGICAL_DEVICES_FIELD,
  LOGS_FIELD,
  DEVICE_ACTION_TYPES_LISTS_FIELD,
  DEVICE_TRIGGERS_FIELD,
  SCHEDULES_FIELD,
  AUTOMATED_OPERATIONS_OCCURRENCES_FIELD,
  DEVICE_TIMELINES_FIELD,
  DEVICE_SCENES_FIELD,
  DEVICE_GROUPS_FIELD,
  PROJECT_FILE_UPLOAD_FIELD,
  TASKS_FIELD,
  SITE_ACTION_TYPES_LIST_FIELD,
  MANUFACTURER_MODEL_TREE_FIELD,
} from 'helpers/selectors';
import pathwayConstants from 'layouts/Pathway/constants';
import { setZoneState } from 'layouts/Pathway/pathwayHelpers';
import { reducers as controlUIReducers } from 'application/tenant/components/structural/ControlUI/utilities';
import { dataReducer as mapReducers } from 'pages/Projects/components/MapView/utilities';
import { dataReducer as devicesForSiteReducers } from 'pages/Projects/components/MapView/components/MapWrapper/components/Markers/components/Marker/components/MarkerDevicesTable/utilities';
import { logsWebsocketsReducer } from 'application/tenant/console/site/components/structural/SiteActivityLoggingTab/components/utilities';
import { projectSuiteProjectsFilterReducer } from 'pages/ProjectSuite/ProjectSuiteTasksTab/utilities/reducer';
import { projectProjectSuiteParentsReducer } from 'pages/ProjectSuite/ProjectSuiteTasksTab/utilities/reducer/projectProjectSuiteParentsReducer';
import { reducer as notificationEventsReducer } from 'components/Notifications/utilities';
import multiSiteMapDataReducer from 'pages/ProjectSuite/ProjectSuiteProjectsTab/components/MultiSiteMapView/utilities/reducers/multiSiteMapDataReducer';
import deviceFirmwaresReducer from 'application/tenant/console/Firmware/utilities/reducers/deviceFirmwaresReducer';
import {
  userPermissionsDataReducer,
  automatedOperationsDataReducer,
  remoteDevicesDataReducer,
} from './utilities';

const initialState = {
  meta: {},
  users: {},
  projects: {},
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {

    case constants.CHANGE_TENANT: {
      const { currentUserId } = action.payload;
      const currentUser = state.users[currentUserId];

      if (!currentUser) {
        return state;
      }

      return {
        ...state,
        users: { [currentUserId]: currentUser },
        projects: {},
        logicalDevices: {},
      };
    }

    case constants.EDIT_ORIGIN_SUCCESS:
    case constants.UPDATE_COMPANY_INFO_SUCCESS:
    case constants.FETCH_DEVICE_SUCCESS:
    case constants.FETCH_PROJECTS_SUCCESS:
    case constants.FETCH_COMPANIES_SUCCESS:
    case constants.FETCH_COMPANY_SUCCESS:
    case constants.ADD_COMPANY_SUCCESS:
    case constants.ADD_ORIGIN_SUCCESS:
    case constants.FETCH_PROJECT_SUCCESS:
    case constants.FETCH_PROJECT_SUITE_SUCCESS:
    case constants.INVITE_USER_SUCCESS:
    case constants.FETCH_USERS_SUCCESS:
    case constants.FETCH_FILES_SUCCESS:
    case constants.FETCH_TRIGGERS_SUCCESS:
    case constants.FETCH_FIXTURE_GROUPS_SUCCESS:
    case constants.FETCH_TIMELINES_SUCCESS:
    case constants.FETCH_ZONES_SUCCESS:
    case constants.FETCH_SNAPSHOTS_SUCCESS:
    case constants.FETCH_STATIC_SCENES_SUCCESS:
    case constants.FETCH_PATCHES_SUCCESS:
    case constants.FETCH_IO_MODULES_SUCCESS:
    case constants.FETCH_IO_MODULES_INSTANCES_SUCCESS:
    case constants.TOGGLE_BEACON_SUCCESS:
    case constants.TOGGLE_WATCHDOG_SUCCESS:
    case constants.TOGGLE_DISABLE_SUCCESS:
    case constants.SET_TIMESTAMP_SUCCESS:
    case constants.FETCH_INDEX_PERMISSIONS_SUCCESS:
    case constants.PERMISSIONS_FETCH_PROJECT_USERS_SUCCESS:
    case constants.PERMISSIONS_FETCH_PROJECT_LOGICAL_DEVICES_SUCCESS:
    case constants.PERMISSIONS_FETCH_PROJECT_SUITE_USERS_SUCCESS:
    case constants.ADD_TO_DATA_STORE:
    case constants.FETCH_NOTIFICATION_TYPES_SUCCESS:
    case constants.FETCH_NOTIFICATIONS_SUCCESS:
    case constants.FETCH_CONTEXTS_SUCCESS:
    case constants.FETCH_TASKS_SUCCESS:
    case constants.FETCH_ACTION_TYPES_SUCCESS:
    case constants.UPLOAD_PROJECT_PREPARE_SUCCESS:
    case constants.UPDATE_SITE_SUCCESS:
    case constants.UPDATE_PROJECT_SUITE_SUCCESS:
    case constants.TOGGLE_SUSPEND_USER_SUCCESS:
    case constants.FETCH_AND_SAVE_LOGICAL_DEVICES_SUCCESS:
    case constants.FETCH_PROJECT_SUITES_SUCCESS:
    case constants.FETCH_PROJECT_SUITE_PROJECTS_SUCCESS:
    case constants.FETCH_PROJECT_SUITE_USERS_SUCCESS:
    case constants.FETCH_LOGS_ACTION_TYPES_SUCCESS:
    case constants.FETCH_PROJECT_SUITE_PROJECTS_TASKS_SUCCESS:
    case constants.FETCH_DEVICES_PROJECT_SUITES_SUCCESS:
    case constants.FETCH_NOTIFICATION_EVENTS_SUCCESS:
    case constants.FETCH_FIRMWARE_FILES_SUCCESS:
    case constants.EDIT_DEVICE_FIRMWARE_SUCCESS:
      return mergeWith({}, state, action.payload.response, mergeCustomizer);

    case constants.FETCH_SITE_DEVICES_SUCCESS: {
      return devicesForSiteReducers(state, action);
    }

    case constants.FETCH_PROJECTS_FOR_MAP_SUCCESS: {
      return mapReducers(state, action);
    }

    case constants.FETCH_OUTPUT_SUCCESS: {
      const { dmxOutput = {}, identifier = {} } = action.payload.response;
      const { protocolId, universeId } = identifier.meta || {};

      Object.keys(dmxOutput).forEach(key => {
        dmxOutput[key].attributes.protocolId = protocolId;
        dmxOutput[key].attributes.universeId = universeId;
      });

      return mergeWith({}, state, { dmxOutput }, mergeCustomizer);
    }

    case constants.FETCH_AUTOMATED_OPERATIONS_SUCCESS:
    case constants.FETCH_AUTOMATED_OPERATION_SUCCESS:
      return automatedOperationsDataReducer(state, action);

    case constants.FETCH_AUTOMATED_OPERATION_OCCURRENCES_SUCCESS: {
      const { data, resourceId, resourceType } = action.payload.response;

      const propertyName = resourceType === 'project' ? 'siteId' : 'projectSuiteId';

      const automatedOperationOccurrences = data.reduce((acc, occurrence) => {
        const { id, attributes } = occurrence;

        return {
          ...acc,
          [id]: {
            id,
            attributes: {
              ...attributes,
              [propertyName]: resourceId,
            },
          },
        };
      }, {});

      return {
        ...state,
        [AUTOMATED_OPERATIONS_OCCURRENCES_FIELD]: automatedOperationOccurrences,
      };
    }

    case constants.FETCH_LOGS_SUCCESS:
      return set(LOGS_FIELD, action.payload.response[LOGS_FIELD], state);

    case constants.FETCH_RESOURCE_USER_PERMISSIONS_SUCCESS:
    case constants.FETCH_CONTEXT_USER_PERMISSIONS_SUCCESS:
    case constants.PERMISSIONS_TOGGLE_USER_PERMISSION_SUCCESS:
    case constants.PERMISSIONS_TOGGLE_USER_HEADER_PERMISSION_SUCCESS:
    case constants.PERMISSIONS_TOGGLE_CONTEXT_USER_HEADER_PERMISSION_SUCCESS:
    case constants.PERMISSIONS_RESOURCE_PASTE_SUCCESS:
      return userPermissionsDataReducer(state, action);

    case constants.OPT_OUT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: omit(state.notifications, action.payload.notificationId),
      };

    case constants.FETCH_CURRENT_USER_SUCCESS: {
      const [userId] = Object.keys(action.payload.response.users);
      const newState = merge({}, state);
      delete newState.users[userId];
      return mergeWith({}, newState, action.payload.response, mergeCustomizer);
    }

    case constants.DELETE_PROJECT_SUCCESS: {
      const { projectId } = action.payload.response;
      const newState = { ...state };

      const { projects, users } = newState;
      delete projects[projectId];

      const { currentUserId } = action.extra;
      removeRelationship(users[currentUserId], projectId, PROJECTS_FIELD);

      return newState;
    }

    case constants.REMOVE_USER_FROM_PROJECT_SUCCESS: {
      const { projectId, userId } = action.payload.response;
      const newState = { ...state };
      removeRelationship(newState.projects[projectId], userId, USERS_FIELD);

      return newState;
    }

    case constants.UPDATE_DEVICE_REPORTED: {
      const { deviceId, path, value } = action.payload;
      const modifiedState = set(
        `${LOGICAL_DEVICES_FIELD}.${deviceId}.attributes.reported.${path}`,
        value,
        state,
      );

      return mergeWith({}, state, modifiedState, mergeCustomizer);
    }

    case constants.DELETE_INVITATION_SUCCESS: {
      const { invitationId } = action.payload.response;
      const newState = { ...state };
      const { inviteeId } = newState.invitations[invitationId].attributes;
      removeRelationship(newState.users[inviteeId], invitationId, 'receivedInvitations');

      return newState;
    }

    case constants.DELETE_ORIGIN_SUCCESS: {
      const { companyId, origin: { id: originId } } = action.payload.response;
      const newState = { ...state };

      const { companies, origins } = newState;
      delete origins[originId];

      removeRelationship(companies[companyId], originId, ORIGINS_FIELD);

      return newState;
    }

    case constants.PERFORM_ACTION_SUCCESS: {
      const {
        response: {
          data: {
            attributes: {
              action: actionType,
            },
          },
        },
      } = action.payload;

      if (actionType === pathwayConstants.ACTION_SET_ZONE_STATE) {
        return setZoneState(state, action);
      }

      return state;
    }

    case constants.RESOURCE_NOT_FOUND: {
      const { id, type } = action.payload;
      const newState = { ...state };

      const typeData = newState[type];
      if (typeData) {
        delete typeData[id];
      }

      const { currentUserId } = action.extra;
      removeRelationship(newState.users[currentUserId], id, type);

      return newState;
    }

    case constants.CHANGE_ROUTE: {
      const { patch, logMessages, dmxOutput, ...rest } = state;
      return rest;
    }

    case constants.FETCH_DEVICE_ACTION_TYPES_LISTS_SUCCESS: {
      const {
        response: {
          logicalDeviceId,
        },
      } = action.payload;
      const actionTypesPerDevice = {
        [DEVICE_ACTION_TYPES_LISTS_FIELD]: {
          [logicalDeviceId]: action.payload.response.data,
        },
      };

      return mergeWith({}, state, actionTypesPerDevice, mergeCustomizer);
    }

    case constants.FETCH_DEVICE_TRIGGERS_SUCCESS: {
      const {
        response: {
          deviceId,
          data,
        },
      } = action.payload;
      const triggers = data.map(trigger => ({ id: trigger.id, name: trigger.attributes.name }));
      const triggersPerDevice = {
        [DEVICE_TRIGGERS_FIELD]: {
          [deviceId]: {
            attributes: {
              triggersList: triggers,
            },
          },
        },
      };

      return mergeWith({}, state, triggersPerDevice, mergeCustomizer);
    }

    case constants.FETCH_DEVICE_TIMELINES_SUCCESS: {
      const {
        response: {
          deviceId,
          data,
        },
      } = action.payload;
      const timelines = data.map(timeline => ({ id: timeline.id, name: timeline.attributes.name }));
      const timelinesPerDevice = {
        [DEVICE_TIMELINES_FIELD]: {
          [deviceId]: {
            attributes: {
              timelinesList: timelines,
            },
          },
        },
      };

      return mergeWith({}, state, timelinesPerDevice, mergeCustomizer);
    }

    case constants.FETCH_DEVICE_SCENES_SUCCESS: {
      const {
        response: {
          deviceId,
          data,
        },
      } = action.payload;
      const scenes = data.map(scene => ({ id: scene.id, name: scene.attributes.name }));
      const scenesPerDevice = {
        [DEVICE_SCENES_FIELD]: {
          [deviceId]: {
            attributes: {
              scenesList: scenes,
            },
          },
        },
      };

      return mergeWith({}, state, scenesPerDevice, mergeCustomizer);
    }

    case constants.FETCH_DEVICE_GROUPS_SUCCESS: {
      const {
        response: {
          deviceId,
          data,
        },
      } = action.payload;
      const groups = data.map(scene => ({
        id: scene.id, name: scene.attributes.name, type: scene.attributes.type,
      }));
      const groupsPerDevice = {
        [DEVICE_GROUPS_FIELD]: {
          [deviceId]: {
            attributes: {
              groupsList: groups,
            },
          },
        },
      };

      return mergeWith({}, state, groupsPerDevice, mergeCustomizer);
    }

    case constants.FETCH_REVERSE_GEOCODING_REQUEST: {
      const { siteId } = action.payload;

      return merge(
        {},
        state,
        {
          [PROJECTS_FIELD]: {
            [siteId]: {
              attributes: {
                tempAddress: null,
              },
            },
          },
        },
      );
    }

    case constants.FETCH_REVERSE_GEOCODING_SUCCESS: {
      const { siteId, address } = action.payload;

      return merge(
        {},
        state,
        {
          [PROJECTS_FIELD]: {
            [siteId]: {
              attributes: {
                tempAddress: address,
              },
            },
          },
        },
      );
    }

    case constants.FETCH_PROJECT_SUITE_REVERSE_GEOCODING_SUCCESS: {
      const { projectSuiteId, address } = action.payload;

      return merge(
        {},
        state,
        {
          [PROJECT_SUITES_FIELD]: {
            [projectSuiteId]: {
              attributes: {
                tempAddress: address,
              },
            },
          },
        },
      );
    }

    case constants.UNABLE_TO_FETCH_LOCATION: {
      const { siteId } = action.payload;
      const {
        [PROJECTS_FIELD]: {
          [siteId]: {
            attributes: {
              geoAddress,
              offset,
              timezoneId,
            },
          },
        },
      } = state;

      return merge(
        {},
        state,
        {
          [PROJECTS_FIELD]: {
            [siteId]: {
              attributes: {
                geoAddress: geoAddress || 'Unable to fetch street address',
                offset: offset || 'Unable to fetch UTC offset',
                timezoneId: timezoneId || 'Unable to fetch time zone name',
              },
            },
          },
        },
      );
    }

    case constants.PROJECT_SUITE_UNABLE_TO_FETCH_LOCATION: {
      const { projectSuiteId } = action.payload;
      const {
        [PROJECT_SUITES_FIELD]: {
          [projectSuiteId]: {
            attributes: {
              geoAddress,
              offset,
              timezoneId,
            },
          },
        },
      } = state;

      return merge(
        {},
        state,
        {
          [PROJECT_SUITES_FIELD]: {
            [projectSuiteId]: {
              attributes: {
                geoAddress: geoAddress || 'Unable to fetch street address',
                offset: offset || 'Unable to fetch UTC offset',
                timezoneId: timezoneId || 'Unable to fetch time zone name',
              },
            },
          },
        },
      );
    }

    case constants.FETCH_SCHEDULES_SUCCESS: {
      const {
        response: {
          automatedOperationId,
          data,
        },
      } = action.payload;
      const schedules = data.map(schedule => {
        const {
          id,
          attributes: {
            name,
            type,
            description,
            lockVersion,
            params,
          },
        } = schedule;

        return {
          id,
          name,
          type,
          description,
          lockVersion,
          params,
        };
      });
      const schedulesPerAutomatedOperation = {
        [SCHEDULES_FIELD]: {
          [automatedOperationId]: {
            attributes: {
              schedulesList: schedules,
            },
          },
        },
      };

      return mergeWith({}, state, schedulesPerAutomatedOperation, mergeCustomizer);
    }

    case constants.FETCH_AUTOMATED_FUNCTIONS_SUCCESS: {
      const {
        response: {
          automatedOperationId,
          data,
        },
      } = action.payload;
      const automatedFunctionsPerAutomatedOperation = {
        [SCHEDULES_FIELD]: {
          [automatedOperationId]: {
            attributes: {
              automatedFunctionId: data ? data.id : null,
            },
          },
        },
      };

      return mergeWith({}, state, automatedFunctionsPerAutomatedOperation, mergeCustomizer);
    }

    case constants.FETCH_CONTROL_UI_SUCCESS:
    case constants.UPDATE_CONTROL_UI_SUCCESS:
    case constants.LOAD_BACKGROUND_IMAGE_CONTROL_UI_PAGE_SUCCESS:
      return controlUIReducers.dataReducer(state, action);

    case constants.FETCH_REMOTE_DEVICES_SUCCESS:
    case constants.FETCH_UNJOINED_REMOTE_DEVICES_SUCCESS:
      return remoteDevicesDataReducer(state, action);

    case constants.REMOVE_BACKGROUND_IMAGE_UPLOAD_CONTROL_UI_PAGE: {
      const { projectId, pageId } = action.payload;
      const resourceUploadId = `backgroundImage-${projectId}-${pageId}`;

      const removeBackgroundImageUploadControlUiPage = {
        [PROJECT_FILE_UPLOAD_FIELD]: {
          [resourceUploadId]: {
            attributes: {
              files: [{ bucket: undefined, path: undefined }],
            },
          },
        },
      };

      return mergeWith({}, state, removeBackgroundImageUploadControlUiPage, mergeCustomizer);
    }

    case constants.ADD_ACTIVE_PROJECT_ID:
    case constants.CLEAR_ACTIVE_PROJECT_ID:
    case constants.SOCKET_RECEIVED:
    case constants.ADD_ACTIVE_REQUEST_ID:
      return logsWebsocketsReducer(state, action);

    case constants.FETCH_PROJECT_SUITE_PROJECTS_FILTER_SUCCESS:
      return projectSuiteProjectsFilterReducer(state, action);

    case constants.PROJECT_SUITE_CONTROL_PANEL_TASK_ADDED: {
      const { task } = action.payload;
      const { id } = task;

      const newTask = {
        [TASKS_FIELD]: {
          [id]: {
            id,
            attributes: {
              ...task,
            },
          },
        },
      };

      return mergeWith({}, state, newTask, mergeCustomizer);
    }

    case constants.FETCH_PROJECT_PROJECT_SUITE_PARENTS_SUCCESS:
      return projectProjectSuiteParentsReducer(state, action);

    case constants.READ_NOTIFICATION_EVENT_SUCCESS:
    case constants.ARCHIVE_NOTIFICATION_EVENT_SUCCESS:
    case constants.ADD_NOTIFICATION_EVENT_FROM_WEBSOCKET:
      return notificationEventsReducer(state, action);

    case constants.FETCH_NOTIFICATION_EVENTS_FOR_DROPDOWN_SUCCESS:
      return notificationEventsReducer(state, action);

    case constants.READ_ALL_NOTIFICATION_EVENTS_SUCCESS:
    case constants.ARCHIVE_ALL_NOTIFICATION_EVENTS_SUCCESS:
      return notificationEventsReducer(state, action);

    case constants.FETCH_CHILD_PROJECTS_FOR_MAP_SUCCESS: {
      return multiSiteMapDataReducer(state, action);
    }

    case constants.FETCH_SITE_ACTION_TYPES_LIST_SUCCESS: {
      const {
        response: {
          projectId,
        },
      } = action.payload;
      const actionTypesPerProject = {
        [SITE_ACTION_TYPES_LIST_FIELD]: {
          [projectId]: action.payload.response.data,
        },
      };

      return mergeWith({}, state, actionTypesPerProject, mergeCustomizer);
    }

    case constants.CONTROL_UI_FETCH_USERS_SUCCESS: {
      const { data, resourceId } = action.payload.response;

      return {
        ...state,
        controlPanelUsers: {
          [resourceId]: data,
        },
      };
    }

    case constants.FETCH_DEVICE_FIRMWARES_MODEL_TREE_SUCCESS: {
      const manufacturers = {
        [MANUFACTURER_MODEL_TREE_FIELD]: action.payload.response.data,
      };

      return mergeWith({}, state, manufacturers, mergeCustomizer);
    }

    case constants.FETCH_DEVICE_FIRMWARES_SUCCESS: {
      return deviceFirmwaresReducer(state, action);
    }

    case constants.RENEW_SUSPENDED_SITE_SUCCESS: {
      const { id, attributes = {} } = action.payload.response.data;
      const stateAttributes = state[PROJECTS_FIELD][id].attributes;
      const {
        newRenewalDate = stateAttributes.renewalDate,
        newSubscriptionPlanCode = stateAttributes.subscriptionPlanCode,
      } = attributes;

      return {
        ...state,
        [PROJECTS_FIELD]: {
          ...state[PROJECTS_FIELD],
          [id]: {
            ...state[PROJECTS_FIELD][id],
            attributes: {
              ...state[PROJECTS_FIELD][id].attributes,
              ...attributes,
              renewalDate: newRenewalDate,
              subscriptionPlanCode: newSubscriptionPlanCode,
            },
          },
        },
      };
    }

    default:
      return state;
  }
};

export default dataReducer;
