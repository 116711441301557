import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment, Header } from 'semantic-ui-react';

import { isValidSitePoints } from 'components/form/formValidators';

import SitePropertyInlineEdit from '../SitePropertyInlineEdit/SitePropertyInlineEditContainer';
import siteActionCreators from '../../utilities/actionCreators';

import { StyledHeader, StyledColumn } from './SiteConstructionSettings.style';
import { InlineEditDate, IsDemo, EnableConstructionMode } from './components';

const SiteConstructionSettings = ({ graceEndDateMin, formattedConstructionEndDate }) => (
  <Segment>
    <Header as="h3">Super Admin</Header>
    <Grid stackable>
      <Grid.Row stretched>
        <Grid.Column width={5}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <StyledHeader as="h5">Construction end date</StyledHeader>
              </Grid.Column>
              <StyledColumn width={8}>
                {formattedConstructionEndDate}
              </StyledColumn>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column width={7}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <StyledHeader as="h5">Grace end date</StyledHeader>
              </Grid.Column>
              <Grid.Column width={8}>
                <InlineEditDate property="graceEndDate" minDate={graceEndDateMin} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column width={4}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <StyledHeader as="h5">Is Demo</StyledHeader>
              </Grid.Column>
              <Grid.Column width={8}>
                <IsDemo property="demo" />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={5}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <StyledHeader as="h5">Renewal date</StyledHeader>
              </Grid.Column>
              <Grid.Column width={8}>
                <InlineEditDate property="renewalDate" />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={5}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <StyledHeader as="h5">Site Points</StyledHeader>
              </Grid.Column>
              <Grid.Column width={8}>
                <SitePropertyInlineEdit
                  property="points"
                  validator={isValidSitePoints}
                  onChange={siteActionCreators.updateSitePoints}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column width={4}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <StyledHeader as="h5">Subscription Plan</StyledHeader>
              </Grid.Column>
              <Grid.Column width={8}>
                <SitePropertyInlineEdit
                  property="subscriptionPlanCode"
                  onChange={siteActionCreators.updateSiteSubscriptionPlanCode}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column width={5}>
          <Grid>
            <Grid.Row>
              <EnableConstructionMode property="construction" />
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
);

SiteConstructionSettings.defaultProps = {
  graceEndDateMin: undefined,
  formattedConstructionEndDate: undefined,
};

SiteConstructionSettings.propTypes = {
  graceEndDateMin: PropTypes.string,
  formattedConstructionEndDate: PropTypes.string,
};

export default SiteConstructionSettings;
