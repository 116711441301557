import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  PROJECTS_FIELD,
  LOGICAL_DEVICES_FIELD,
  USERS_FIELD,
  PROJECT_SUITES_FIELD,
  TENANT_FIELD,
  currentUserSelector,
  permissionsStateSelector,
} from 'helpers/selectors';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import permissionsActionCreators from 'application/tenant/console/utilities/permissions/permissionsActionCreators';
import { withLoadPermissions } from 'gateway';
import Null from 'components/Null/Null';

export const mapStateToProps = state => {
  const { id: currentUserId } = currentUserSelector(state);
  const { contexts } = permissionsStateSelector(state) || {};
  const contextId = contexts ? Object.keys(contexts)[0] : null;

  return {
    currentUserId,
    contextId,
  };
};

export default compose(
  withLoadPermissions,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    ({ currentUserId, contextId }) => permissionsActionCreators.fetchContextUserPermissions(
      currentUserId,
      PROJECTS_FIELD,
      contextId,
    ),
    ({ currentUserId, contextId }) => permissionsActionCreators.fetchContextUserPermissions(
      currentUserId,
      PROJECT_SUITES_FIELD,
      contextId,
    ),
    ({ currentUserId, contextId }) => permissionsActionCreators.fetchContextUserPermissions(
      currentUserId,
      LOGICAL_DEVICES_FIELD,
      contextId,
    ),
    ({ currentUserId, contextId }) => permissionsActionCreators.fetchContextUserPermissions(
      currentUserId,
      USERS_FIELD,
      contextId,
    ),
    ({ currentUserId, contextId }) => permissionsActionCreators.fetchContextUserPermissions(
      currentUserId,
      TENANT_FIELD,
      contextId,
    ),
  ),
)(Null);
