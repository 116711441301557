import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'semantic-ui-react';

import ModalForm from 'components/form/ModalForm/ModalFormV2';
import {
  FormFieldLabel,
  FormFieldWrapper,
} from 'components/form/FormField/FormField.style';
import { StyledButton } from './LinkFileModalForm.style';

const LinkFileModalForm = ({ ...rest }) => {
  const linkAction = 'Link file to device firmware';

  return (
    <ModalForm
      header={linkAction}
      trigger={(
        <StyledButton
          content="Link"
          icon="linkify"
          labelPosition="right"
          size="mini"
        />
      )}
      size="small"
      triggerSize="mini"
      {...rest}
    >
      <FormGroup inline>
        <FormFieldWrapper width={16}>
          <FormFieldLabel htmlFor="input">Link file...</FormFieldLabel>
        </FormFieldWrapper>
      </FormGroup>
    </ModalForm>
  );
};

LinkFileModalForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default LinkFileModalForm;
