const reduceAutomatedOperationsWithProjectId = projectId => (
  automatedOperations,
  { id, attributes },
) => ({
  ...automatedOperations,
  [id]: {
    id,
    attributes: {
      ...attributes,
      projectId,
    },
  },
});

export default reduceAutomatedOperationsWithProjectId;
