import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';

export const StyledGrid = styled(Grid)`
  &.ui.grid {
    width: 100%;
  }
`;

export const StyledProjectsTabWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
