import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import groupBy from 'lodash/groupBy';

import {
  LOGICAL_DEVICES_FIELD,
  PROJECTS_FIELD,
} from 'helpers/selectors';

import { FILTER_FIELDS } from './constants';

const getUniqueResourcesFromNotificationEvents = notificationEvents => {
  if (isEmpty(notificationEvents)) {
    return [];
  }

  let resources = notificationEvents.reduce((acc, curr) => {
    const device = {
      type: LOGICAL_DEVICES_FIELD,
      key: curr.notifiable?.id,
      name: curr.notifiable?.name,
      filterField: FILTER_FIELDS[LOGICAL_DEVICES_FIELD],
    };

    const project = {
      type: PROJECTS_FIELD,
      key: curr.project?.id,
      name: curr.project?.name,
      filterField: FILTER_FIELDS[PROJECTS_FIELD],
    };

    acc.push(device, project);

    return acc;
  }, []);

  resources = groupBy(uniqBy(resources, 'key'), 'type');

  return resources;
};

export default getUniqueResourcesFromNotificationEvents;
