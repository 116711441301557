import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

import { PermissionsSwitch, Gateway, frontendPermissions } from 'gateway';
import constants from 'dispatcherConst';
import { AUTOMATED_OPERATIONS_FIELD } from 'helpers/selectors';
import AutomatedOperations from 'application/tenant/console/site/components/structural/SiteSchedulingTab/components/AutomatedOperations/AutomatedOperationsProjectSuiteContainer';
import DayView from 'application/tenant/console/site/components/structural/SiteSchedulingTab/components/DayView/DayViewProjectSuiteContainer';
import { SelectSiteTasksDropdown } from 'pages/ProjectSuite/ProjectSuiteTasksTab/components';

import { StyledDivider } from './ProjectSuiteSchedulingTab.style';

const ProjectSuiteSchedulingTab = ({
  projectSuiteId,
  locationSet,
  locationLoaded,
  projectsIds,
  options,
  occurrencesOptions,
  onClearProjectSuiteIdContext,
}) => {
  const [filter, setFilter] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => onClearProjectSuiteIdContext(), []);

  if (!locationLoaded) {
    return null;
  }

  if (!locationSet) {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <p>You must set the multi-site
              location in <Link to={`/my-multi-sites/${projectSuiteId}/settings`}>Settings</Link> before
              you can schedule tasks.
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  return (
    <Grid>
      <Grid.Row>
        <PermissionsSwitch>
          <Gateway condition={frontendPermissions.PROJECT_SUITE_AUTOMATED_OPERATIONS_OCCURRENCES}>
            <Grid.Column>
              <DayView options={occurrencesOptions} />
            </Grid.Column>
          </Gateway>
        </PermissionsSwitch>
      </Grid.Row>
      <Grid.Row>
        <PermissionsSwitch>
          <Gateway condition={frontendPermissions.PROJECT_SUITE_AUTOMATED_OPERATIONS_VIEW}>
            <Grid.Column mobile={16} computer={16}>
              <StyledDivider />
              <SelectSiteTasksDropdown
                setFilter={setFilter}
                filter={filter}
                options={options}
                field={AUTOMATED_OPERATIONS_FIELD}
                requestType={constants.FETCH_AUTOMATED_OPERATIONS_REQUEST}
              />
              {projectsIds && <AutomatedOperations projectsIds={projectsIds} /> }
            </Grid.Column>
          </Gateway>
        </PermissionsSwitch>
      </Grid.Row>
    </Grid>

  );
};

ProjectSuiteSchedulingTab.defaultProps = {
  projectsIds: null,
  options: [],
  occurrencesOptions: [],
};

ProjectSuiteSchedulingTab.propTypes = {
  projectSuiteId: PropTypes.string.isRequired,
  locationSet: PropTypes.bool.isRequired,
  locationLoaded: PropTypes.bool.isRequired,
  projectsIds: PropTypes.array,
  options: PropTypes.array,
  occurrencesOptions: PropTypes.array,
  onClearProjectSuiteIdContext: PropTypes.func.isRequired,
};

export default ProjectSuiteSchedulingTab;
