import { compose } from 'redux';
import { connect } from 'react-redux';

import { getChainedLoaderPiece } from 'helpers/sharedMethods';
import { makePaginationParamSelector } from 'helpers/selectorsCreators';
import chainedLoader from 'helpers/chainedLoader/chainedLoader';

const makeMapState = ({ included, name }) => {
  const fetchingSelector = makePaginationParamSelector('fetching');
  const errorSelector = makePaginationParamSelector('error');
  const overrideSelector = makePaginationParamSelector('refetch');
  const linkSelector = makePaginationParamSelector('link');

  return function mapStateToProps(state, ownProps) {
    const fetching = fetchingSelector(state, name);
    const error = errorSelector(state, name);
    const override = overrideSelector(state, name);
    const link = included && linkSelector(state, name);

    const {
      chainedLoaderProps: {
        [name]: {
          fetching: fetchingProp,
          error: errorProp,
          override: overrideProp,
          link: linkProp,
        } = {},
      } = {},
    } = ownProps;

    return getChainedLoaderPiece(
      fetchingProp || fetching,
      errorProp || error,
      overrideProp || override,
      linkProp || link,
    );
  };
};

export default (config, ...methods) => compose(
  connect(makeMapState(config)),
  chainedLoader(config, ...methods),
);
