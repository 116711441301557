import styled from 'styled-components';

export const StyledLabel = styled.label`
  font-weight: 700;
  display: block;

  &:not(:first-child) {
    margin-top: 8px;
  }
`;

export default StyledLabel;
