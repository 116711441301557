import React from 'react';
import PropTypes from 'prop-types';
import ConfirmModal from 'components/elements/ConfirmModal/ConfirmModal';

import { Button } from 'components/atomic';
import { StyledButton } from './components';

const SyncFirmware = props => {
  const {
    deviceId,
    onSyncFirmware,
    showDeviceSyncFirmware,
  } = props;

  if (!showDeviceSyncFirmware) {
    return null;
  }

  const trigger = <StyledButton primary>Sync Firmware</StyledButton>;

  const handleConfirm = () => {
    onSyncFirmware({ deviceId });
  };

  return (
    <ConfirmModal
      trigger={trigger}
      cancelButton="Cancel"
      confirmButton={<Button primary>Sync</Button>}
      content={(
        <div>
          <p>
            Syncing remote devices firmware will cause the remote devices to go
            offline temporarily until the sync is complete.
            <br />Are you sure you want to continue?
          </p>
        </div>
      )}
      header="Confirm Firmware Sync"
      onConfirm={handleConfirm}
    />
  );
};

SyncFirmware.propTypes = {
  deviceId: PropTypes.string.isRequired,
  onSyncFirmware: PropTypes.func.isRequired,
  showDeviceSyncFirmware: PropTypes.bool.isRequired,
};

export default SyncFirmware;
