import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import {
  REMOTE_DEVICES_FIELD,
} from 'helpers/selectors';


export const fetchRemoteDevices = deviceId => ({
  type: constants.FETCH_REMOTE_DEVICES_REQUEST,
  payload: {
    endpoint: endpoints.REMOTE_DEVICES(deviceId),
    resourceType: REMOTE_DEVICES_FIELD,
    deviceId,
  },
});

export const fetchRemoteDevicesSuccess = response => ({
  type: constants.FETCH_REMOTE_DEVICES_SUCCESS,
  payload: {
    response,
    resourceType: REMOTE_DEVICES_FIELD,
  },
});

export const fetchRemoteDevicesFailure = error => ({
  type: constants.FETCH_REMOTE_DEVICES_FAILED,
  payload: { error },
});
