import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { SCHEDULER_TYPES } from '../../../../constants';
import {
  NoneRecurrenceReview,
  SpecificDatesReview,
  DailyRecurrenceReview,
  WeeklyRecurrenceReview,
  MonthlyRecurrenceReview,
  YearlyRecurrenceReview,
} from './components';
import { StyledHeader, StyledLabel, StyledLink, StyledContent } from '..';

function ScheduleReview({
  schedulerType,
  recurrenceParams,
  onStepChange,
}) {
  const handleClick = () => {
    onStepChange(1);
  };

  const renderSchedulerReview = useCallback(() => {
    const commonProps = {
      schedulerType,
      recurrenceParams,
    };

    switch (schedulerType) {
      case SCHEDULER_TYPES.NONE:
        return <NoneRecurrenceReview />;
      case SCHEDULER_TYPES.DATES:
        return <SpecificDatesReview {...commonProps} />;
      case SCHEDULER_TYPES.DAILY:
        return <DailyRecurrenceReview {...commonProps} />;
      case SCHEDULER_TYPES.WEEKLY:
        return <WeeklyRecurrenceReview {...commonProps} />;
      case SCHEDULER_TYPES.MONTHLY:
        return <MonthlyRecurrenceReview {...commonProps} />;
      case SCHEDULER_TYPES.YEARLY:
        return <YearlyRecurrenceReview {...commonProps} />;
      default:
        return null;
    }
  }, [schedulerType, recurrenceParams]);

  return (
    <>
      <StyledHeader>
        <StyledLabel>
          Schedule configuration
        </StyledLabel>
        <StyledLink className="btn-link-edit-configuration" onClick={handleClick}>
          Edit Configuration
        </StyledLink>
      </StyledHeader>
      <StyledContent>
        {renderSchedulerReview()}
      </StyledContent>
    </>
  );
}

ScheduleReview.propTypes = {
  schedulerType: PropTypes.string.isRequired,
  recurrenceParams: PropTypes.object.isRequired,
  onStepChange: PropTypes.func.isRequired,
};

export default ScheduleReview;
