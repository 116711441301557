import isEmpty from 'lodash/isEmpty';

import UserModel from 'models/UserModel';

const transformUsersResponseToRecipients = response => {
  if (!response) {
    return [];
  }

  const { data } = response;

  if (isEmpty(data)) {
    return [];
  }

  const users = data.map(user => new UserModel({
    id: user.id,
    ...user.attributes,
  }));

  return users.map(user => ({
    key: user.id,
    text: user.userLabel || user.firstName,
    value: user.id,
    metadata: {
      recipientName: user.fullName,
      recipientTerm: 'user',
      recipientLongName: `${user.fullName} (${user.email})`,
      recipientShortName: user.userLabel,
    },
  }));
};

export default transformUsersResponseToRecipients;
