import endpoints from 'data/api/endpoints';
import constants from 'dispatcherConst';

export const login = params => ({
  type: constants.LOGIN,
  payload: {
    params,
    endpoint: endpoints.LOGIN,
  },
});

export const loginSuccess = response => ({
  type: constants.LOGIN_SUCCESS,
  payload: { response },
});

export const loginFailure = error => ({
  type: constants.LOGIN_FAILED,
  payload: { error },
});
