import { put } from 'redux-saga/effects';

import socketConstants from 'data/websockets/constants';
import websocketActionCreators from 'data/websockets/websocketsActionCreators';

function* subscribeNotificationEventsChannel(action) {
  const { type } = action.payload;

  if (type && type === socketConstants.EVENT_WELCOME) {
    yield put(websocketActionCreators.subscribeNotificationsChannel());
  }
}

export default subscribeNotificationEventsChannel;
