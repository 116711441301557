import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Transition } from 'semantic-ui-react';

import { uploadFileType } from 'data/types';
import NarrowContainer from 'components/NarrowContainer/NarrowContainer.style';
import StyledAccordion from './UploadsAccordion.style';
import UploadsAccordionItem from './UploadsAccordionItem/UploadsAccordionItem';
import MobileUploadItem from './UploadsAccordionItem/UploadsAccordionItem.style';

class UploadsAccordion extends Component {

  constructor() {
    super();

    this.state = { visible: false };

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.timeout = setTimeout(
      () => { this.setState({ visible: true }); },
      500,
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleClick(e) {
    this.setState(({ visible }) => ({ visible: !visible }));
    e.stopPropagation();
  }

  render() {
    const { visible } = this.state;
    const { title, data } = this.props;

    return (
      <StyledAccordion>
        <NarrowContainer>
          <Accordion.Title
            as={MobileUploadItem}
            onClick={this.handleClick}
          >
            <MobileUploadItem.Name>{title}</MobileUploadItem.Name>
          </Accordion.Title>
          <Transition animation="slide down" duration={300} visible={visible}>
            <Accordion.Content>
              {data.map(item => (
                <UploadsAccordionItem key={item.fileKey} item={item} />
              ))}
            </Accordion.Content>
          </Transition>
        </NarrowContainer>
      </StyledAccordion>
    );
  }

}

UploadsAccordion.propTypes = {
  data: PropTypes.arrayOf(uploadFileType).isRequired,
  title: PropTypes.string.isRequired,
};

export default UploadsAccordion;
