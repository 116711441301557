import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';

const computeSelectedProjects = (selectedProjects, options) => {
  if (isNil(selectedProjects)) {
    return ['allSites'];
  }

  if (Array.isArray(selectedProjects) && isEmpty(selectedProjects)) {
    return ['superSiteTasks'];
  }

  const extractedProjects = options
    .filter(({ value }) => value !== 'allSites')
    .filter(({ value }) => value !== 'superSiteTasks')
    .map(({ value }) => value);

  if (isEqual(sortBy(extractedProjects), sortBy(selectedProjects))) {
    return ['allSites'];
  }

  return selectedProjects;
};

export default computeSelectedProjects;
