import { call } from 'redux-saga/effects';

import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';

import actionCreators from '../actionCreators';

function* fetchProjectProjectSuiteParents(action) {
  const { endpoint, urlParams, projectId, ...rest } = action.payload;
  const { response, error } = yield call(getPath, endpoint, { urlParams });
  const enhancedResponse = response ? { projectId, ...response } : response;

  const params = {
    response: enhancedResponse,
    error,
    successDisp: actionCreators.fetchProjectProjectSuiteParentsSuccess,
    failDisp: actionCreators.fetchProjectProjectSuiteParentsFailure,
    ...rest,
  };

  yield call(processCall, params);
}

export default fetchProjectProjectSuiteParents;
