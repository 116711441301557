import { call, put, select, takeLatest } from 'redux-saga/effects';
import SparkMD5 from 'spark-md5';
import isEmpty from 'lodash/isEmpty';

import constants from 'dispatcherConst';
import { messages } from 'data/notifications/notificationsConst';
import { uploadsFileSelector, uploadsFilesSelector } from 'helpers/uploadsSelectors';
import { postPath } from 'data/api/requests';
import uploadsActionCreators from 'components/UploadsWidget/uploadsActionCreators';
import { processCall } from 'helpers/sagaHelper';
import notificationsActionCreators from 'data/notifications/notificationsActionCreators';
import newUploadActionCreators from './newUploadActionCreators';

export function* resumeUploadAfterFileNotFound({ payload }) {
  const {
    fileKey,
    params: {
      values: { systemFiles },
      resolveForm,
      rejectForm,
    },
  } = payload;

  const upload = (yield select(uploadsFileSelector))(fileKey);

  const params = {
    response: [{ ...upload, systemFile: systemFiles[0] }],
    resolveForm,
    rejectForm,
    successDisp: uploadsActionCreators.enqueueUploadAtStart,
  };

  yield call(processCall, params);
}

export function generateUploadRequestBody(systemFiles, fileTypes) {
  const data = systemFiles.map(({ name: fileName }, index) => {
    const nameHash = SparkMD5.hash(fileName);
    const fileTypeId = fileTypes[nameHash];

    return {
      id: String(index),
      fileName,
      fileTypeId,
    };
  });

  return { data };
}

export function* prepareUpload({ payload }) {
  const {
    endpoint,
    params: {
      values,
      resolveForm,
      rejectForm,
    },
  } = payload;

  const { systemFiles, fileTypeId, ...fileTypes } = values;

  if (isEmpty(fileTypes) && fileTypeId) {
    systemFiles.forEach(systemFile => {
      fileTypes[SparkMD5.hash(systemFile.name)] = fileTypeId;
    });
  }

  const body = yield call(generateUploadRequestBody, systemFiles, fileTypes);

  const { response: apiResponse, error } = yield call(postPath, endpoint, body);
  const response = apiResponse
    ? { ...apiResponse, systemFiles, fileTypes }
    : null;

  const params = {
    response,
    error,
    resolveForm,
    rejectForm,
    successDisp: newUploadActionCreators.prepareUploadSuccess,
    failDisp: notificationsActionCreators.addGeneralError,
  };

  yield call(processCall, params);
}

export function attachSystemFile(file, systemFiles) {
  const { id } = file;
  const systemFile = systemFiles[id];

  return { ...file, systemFile };
}

export function attachFileType(file, fileTypes) {
  const { systemFile } = file;
  const nameHash = SparkMD5.hash(systemFile.name);
  const fileTypeId = fileTypes[nameHash];

  return { ...file, fileTypeId };
}

export function attachAttributes(file, awsAttributes) {
  const { bucket, path, systemFile } = file;
  const {
    accessKeyId,
    logicalDeviceId,
    logicalDeviceName,
    projectName,
    projectId,
  } = awsAttributes;

  return {
    fileKey: `${bucket}/${path}`,
    accessKeyId,
    bucket,
    path,
    systemFile,
    project: projectName,
    projectId,
    device: logicalDeviceName,
    deviceId: logicalDeviceId,
  };
}

export function* filterOutRunningUploads(allUploads) {
  const stateFiles = yield select(uploadsFilesSelector);

  return allUploads.reduce((acc, upload) => {
    const { fileKey } = upload;
    const { accessKeyId } = stateFiles[fileKey] || {};

    if (accessKeyId) {
      const { running } = acc;
      const { systemFile: { name } } = upload;
      running.push(name);

      return acc;
    }

    const { created } = acc;
    created.push(upload);

    return acc;
  }, { running: [], created: [] });
}

export function* prepareUploadSuccess(action) {
  const {
    response: {
      data: {
        attributes: {
          files,
          ...restAttributes
        },
      },
      systemFiles,
      fileTypes,
    },
  } = action.payload;

  const allUploads = files
    .map(file => attachSystemFile(file, systemFiles))
    .map(file => attachAttributes(file, restAttributes));

  const { running, created } = yield call(filterOutRunningUploads, allUploads);

  if (running.length) {
    yield put(
      notificationsActionCreators.addInfoNotification(messages.UPLOAD_OMITTED(running)),
    );
  }

  const createdUploads = created.map(file => attachFileType(file, fileTypes));

  yield put(uploadsActionCreators.enqueueUploadAtEnd(createdUploads));
}

function* newUploadSaga() {
  yield takeLatest(constants.UPLOAD_PREPARE_REQUEST, prepareUpload);
  yield takeLatest(constants.UPLOAD_PREPARE_SUCCESS, prepareUploadSuccess);
  yield takeLatest(constants.UPLOAD_FILE_NOT_FOUND_RESUME, resumeUploadAfterFileNotFound);
}

export default newUploadSaga;
