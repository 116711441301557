import { put, select } from 'redux-saga/effects';

import selectors from '../selectors';
import actionCreators from '../actionCreators';

function* setPageBackgroundImage(action) {
  const { pageId, projectId, backgroundImg, ...rest } = action.payload;
  const { pages, ...attributes } = (yield select(selectors.projectControlUISelector))(projectId);

  const newPages = pages.map(({ index, ...page }) => {
    if (index === pageId) {
      return {
        index,
        ...page,
        backgroundImg,
      };
    }

    return {
      index,
      ...page,
    };
  });

  yield put(actionCreators.updateControlUI({
    pages: newPages,
    ...attributes,
    ...rest,
  }));
}

export default setPageBackgroundImage;
