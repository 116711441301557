import styled from 'styled-components';
import { Table, Divider } from 'semantic-ui-react';

const StyledDivider = styled(Divider)`
  &.ui.divider {
    margin-top: 40px;
  }
`;

export const StyledTaskRow = styled(Table.Row)`
  cursor: pointer;
`;

export const StyledTaskActionLinkCell = styled(Table.Cell)`
  min-width: 60px !important;
  width: 5% !important;
`;

export const StyledTaskContentCell = styled(Table.Cell)`
  @media only screen and (max-width: 991px) {
    padding-left: 10px !important;
  }
`;

export const StyledTaskName = styled.span`
  display: inline-block;
  margin-right: 10px;
`;

export default StyledDivider;
