import React from 'react';
import PropTypes from 'prop-types';
import { BsCircle, BsCircleFill } from 'react-icons/bs';

import { Menu } from 'semantic-ui-react';
import { MenuItemWrapper } from './components';
import { renderPriorityIcon } from './utilities';

const MenuItem = ({
  activeItem,
  handleClick,
  handleKeyPress,
  value,
  text,
  iconName,
}) => {
  const priorityIcon = renderPriorityIcon(iconName);

  return (
    <Menu.Item
      name="high"
      active={activeItem === value}
      onClick={() => handleClick(value)}
      onKeyPress={e => handleKeyPress(e, value)}
      tabIndex="0"
    >
      <MenuItemWrapper>
        {activeItem === value ? <BsCircleFill /> : <BsCircle />}
        <span>{text}</span>
        {priorityIcon}
      </MenuItemWrapper>
    </Menu.Item>
  );
};

MenuItem.defaultProps = {
  iconName: null,
};

MenuItem.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  activeItem: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  handleClick: PropTypes.func.isRequired,
  handleKeyPress: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  iconName: PropTypes.string,
};

export default MenuItem;
