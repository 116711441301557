import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { permissionsOrRedirect, frontendPermissions } from 'gateway';
import { projectsSelector } from 'helpers/selectors';

import {
  actionCreators as projectSuiteActionCreators,
  selectors as projectSuiteSelectors,
} from 'pages/ProjectSuites/utilities';

import LinkProjectToProjectSuite from './LinkProjectToProjectSuite';

export const mapStateToProps = (state, { match }) => {
  const { id: projectSuiteId } = match.params;

  const userProjects = projectsSelector(state) || [];
  const projectSuiteProjects = projectSuiteSelectors.projectsListSelector(state)(projectSuiteId);
  const projectSuiteProjectsIds = !isEmpty(projectSuiteProjects)
    ? projectSuiteProjects.map(({ id }) => id)
    : [];

  const availableProjects = userProjects.filter(
    project => !projectSuiteProjectsIds.includes(project.id),
  );

  return {
    projectSuiteId,
    availableProjects,
    isLoading: projectSuiteSelectors.isLinkingProjectSelector(state),
  };
};

const mapDispatchToProps = {
  onAddProjectToProjectSuite: projectSuiteActionCreators.addProjectToProjectSuite,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  permissionsOrRedirect(frontendPermissions.PROJECT_SUITE_OWNER),
)(LinkProjectToProjectSuite);
