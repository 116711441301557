export const labels = [
  { text: 'Description', field: 'description', width: 6 },
  { text: 'Start Method', field: 'startMethod', width: 4 },
  { text: 'Relay Output', field: 'relayOutput', width: 2 },
  { text: 'Repeat', field: 'repeat', width: 2 },
  { text: '', field: '', width: 2 },
];

export const TOGGLE_TIMELINE = 'toggle_timeline';

export default labels;
