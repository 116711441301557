import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import take from 'lodash/take';
import isEqual from 'lodash/isEqual';
import includes from 'lodash/includes';
import identity from 'lodash/identity';

import { removeApiUrl, mergeCustomizer } from 'helpers/sharedMethods';
import {
  USERS_FIELD,
  PROJECTS_FIELD,
  LOGICAL_DEVICES_FIELD,
  CONTEXTS_FIELD,
  PROJECT_SUITES_FIELD,
} from 'helpers/selectors';

export function addTopLevel(topLevel, firstToken) {
  if (!includes(topLevel, firstToken)) {
    return [
      ...topLevel,
      firstToken,
    ];
  }

  return topLevel;
}

export function reduceTopLevel(
  {
    topLevel = [],
    permissionsByTopLevel = {},
  },
  {
    id,
    tokens,
  },
) {
  const [firstToken, ...restOfTokens] = tokens;
  const { [firstToken]: topLevelPermissions = [] } = permissionsByTopLevel;

  return {
    topLevel: addTopLevel(topLevel, firstToken),
    permissionsByTopLevel: {
      ...permissionsByTopLevel,
      [firstToken]: [
        ...topLevelPermissions,
        {
          id,
          tokens: restOfTokens,
        },
      ],
    },
  };
}

export function prependRootIfExists(root) {
  if (root) {
    return ({
      id,
      tokens,
      original,
    }) => ({
      id,
      tokens: [root, ...tokens],
      original: [root, ...original],
    });
  }

  return identity;
}

// eslint-disable-next-line import/no-mutable-exports
let pathsByUniqueTopLevel;

export function reducePermissionsByTopLevel(root, permissionsByTopLevel) {
  return (paths, topLevel) => {
    const prependRoot = prependRootIfExists(root);
    const permissions = permissionsByTopLevel[topLevel];

    if (permissions.length === 1) {
      const { id, tokens } = permissions[0];

      if (tokens.length === 0) {
        return [
          ...paths,
          prependRoot({ id, tokens: [topLevel], original: [topLevel] }),
        ];
      }

      const tail = tokens.join('_');
      const path = `${topLevel}_${tail}`;

      return [
        ...paths,
        prependRoot({ id, tokens: [path], original: [topLevel, ...tokens] }),
      ];
    }

    const childPaths = pathsByUniqueTopLevel({ root: topLevel, permissions });
    const childPathsWithRoot = childPaths.map(prependRoot);

    return [
      ...paths,
      ...childPathsWithRoot,
    ];
  };
}

pathsByUniqueTopLevel = ({ root, permissions }) => {
  if (permissions.length === 0) {
    return [];
  }

  const { topLevel, permissionsByTopLevel } = permissions.reduce(reduceTopLevel, {});
  const reduceTopLevelPermissions = reducePermissionsByTopLevel(root, permissionsByTopLevel);

  return topLevel.reduce(reduceTopLevelPermissions, []);
};

export {
  pathsByUniqueTopLevel,
};

export function mapIdsAndTokens({ id, permissionName }) {
  return { id, tokens: permissionName.split(':') };
}

export function extractPaths(permissions) {
  const withIdsAndTokens = permissions.map(mapIdsAndTokens);

  return pathsByUniqueTopLevel({ permissions: withIdsAndTokens });
}

export function reduceTokens(paths, token, index, tokens) {
  const takeUntil = index + 1;
  const partialTokens = take(tokens, takeUntil);
  const matchingPaths = paths.filter(path => isEqual(path, partialTokens));

  if (matchingPaths.length === 0) {
    return [
      ...paths,
      partialTokens,
    ];
  }

  return paths;
}

export function reducePaths(paths, { tokens }) {
  return tokens.reduce(reduceTokens, paths);
}

export function extractPartialPaths(permissions) {
  const paths = extractPaths(permissions);

  return paths.reduce(reducePaths, []);
}

export function extractResourceLinks(data, resourceType) {
  const { relationships: { [resourceType]: resources } } = data;

  if (resources) {
    const { links: { related: relatedResources } } = resources;

    return removeApiUrl(relatedResources);
  }

  return undefined;
}

export function reduceLinks(receivedResources) {
  return (links, resourceId) => {
    const data = receivedResources[resourceId];
    if ('relationships' in data) {
      return {
        ...links,
        [resourceId]: {
          [USERS_FIELD]: extractResourceLinks(data, USERS_FIELD),
          [LOGICAL_DEVICES_FIELD]: extractResourceLinks(data, LOGICAL_DEVICES_FIELD),
          [PROJECTS_FIELD]: extractResourceLinks(data, PROJECTS_FIELD),
        },
      };
    }

    return links;
  };
}

export function extractLinks(state, action, resourceType) {
  const { response } = action.payload;

  if (!(resourceType in response)) {
    return state;
  }

  const { [resourceType]: receivedResources } = response;
  const links = Object.keys(receivedResources).reduce(reduceLinks(receivedResources), {});

  return merge(
    {},
    state,
    { links },
  );
}

export function setUserLoaded(state, action) {
  const { response: { resourceId, resourceType, userId } } = action.payload;

  const resourceTypes = {
    project: PROJECTS_FIELD,
    project_suite: PROJECT_SUITES_FIELD,
    logical_device: LOGICAL_DEVICES_FIELD,
    user: USERS_FIELD,
  };

  const convertedResourceType = resourceTypes[resourceType];

  return merge(
    {},
    state,
    {
      [convertedResourceType]: {
        [resourceId]: {
          usersLoaded: {
            [userId]: true,
          },
        },
      },
    },
  );
}

export function addResource(state, { resourceId, paths, resourceType, initiallyOpen }) {
  const { [resourceType]: resources } = state;

  return {
    ...state,
    selectingProjects: false,
    selectingContexts: false,
    [resourceType]: {
      ...resources,
      [resourceId]: {
        data: paths.reduce((data, tokens) => ({
          ...data,
          [tokens.join('.')]: {
            open: false,
          },
        }), {}),
        open: initiallyOpen,
        activeUserIds: [],
        usersLoaded: {},
      },
    },
  };
}

export function addContext(state, action) {
  const {
    contextId,
    contextUsersLink,
    projectPaths,
    logicalDevicePaths,
    userPaths,
    projectSuitePaths,
  } = action.payload;
  const { [CONTEXTS_FIELD]: contexts } = state;

  if (contextId in contexts) {
    return state;
  }

  return merge(
    {},
    state,
    {
      [CONTEXTS_FIELD]: {
        [contextId]: {
          open: true,
        },
      },
      links: {
        [contextId]: {
          users: contextUsersLink,
        },
      },
    },
    addResource(
      state,
      {
        resourceId: contextId,
        paths: projectPaths,
        resourceType: PROJECTS_FIELD,
        initiallyOpen: true,
      },
    ),
    addResource(
      state,
      {
        resourceId: contextId,
        paths: projectSuitePaths,
        resourceType: PROJECT_SUITES_FIELD,
        initiallyOpen: true,
      },
    ),
    addResource(
      state,
      {
        resourceId: contextId,
        paths: logicalDevicePaths,
        resourceType: LOGICAL_DEVICES_FIELD,
        initiallyOpen: true,
      },
    ),
    addResource(
      state,
      {
        resourceId: contextId,
        paths: userPaths,
        resourceType: USERS_FIELD,
        initiallyOpen: true,
      },
    ),
  );
}

export function addProject(state, action) {
  const { projectId, paths } = action.payload;
  const { [PROJECTS_FIELD]: projects } = state;

  if (projectId in projects) {
    return state;
  }

  return addResource(
    state,
    {
      resourceId: projectId,
      paths,
      resourceType: PROJECTS_FIELD,
      initiallyOpen: true,
    },
  );
}

export function addProjectSuite(state, action) {
  const { projectSuiteId, paths } = action.payload;
  const { [PROJECT_SUITES_FIELD]: projectSuites } = state;

  if (projectSuiteId in projectSuites) {
    return state;
  }

  return addResource(
    state,
    {
      resourceId: projectSuiteId,
      paths,
      resourceType: PROJECT_SUITES_FIELD,
      initiallyOpen: true,
    },
  );
}

export function toggleTreeOpen(state, resourceType, resourceId, path) {
  const { [resourceType]: { [resourceId]: { data: { [path]: { open } } } } } = state;

  return merge(
    {},
    state,
    {
      [resourceType]: {
        [resourceId]: {
          data: {
            [path]: {
              open: !open,
            },
          },
        },
      },
    },
  );
}

export function addUserId(state, action) {
  const { resourceType, resourceId, userId } = action.payload;
  const {
    [resourceType]: {
      [resourceId]: {
        activeUserIds: currentUserIds,
      },
    },
  } = state;

  if (includes(currentUserIds, userId)) {
    return state;
  }

  const limitToFive = currentUserIds.slice(-4);

  return merge(
    {},
    state,
    {
      [resourceType]: {
        [resourceId]: {
          activeUserIds: [
            ...limitToFive,
            userId,
          ],
        },
      },
    },
  );
}

export function removeUserId(state, action) {
  const { resourceType, resourceId, userId } = action.payload;
  const {
    [resourceType]: {
      [resourceId]: {
        activeUserIds: currentUserIds,
        copyUserId: currentCopyUserId,
      },
    },
  } = state;

  if (!includes(currentUserIds, userId)) {
    return state;
  }

  const activeUserIds = currentUserIds.filter(currentUserId => currentUserId !== userId);

  return mergeWith(
    {},
    state,
    {
      [resourceType]: {
        [resourceId]: {
          activeUserIds,
          copyUserId: currentCopyUserId === userId ? null : currentCopyUserId,
        },
      },
    },
    mergeCustomizer,
  );
}

export const resourceCopy = (state, action) => {
  const { resourceType, resourceId, userId } = action.payload;
  const {
    [resourceType]: {
      [resourceId]: {
        copyUserId: currentUserId,
        ...resource
      },
      ...resources
    },
  } = state;

  return {
    ...state,
    [resourceType]: {
      ...resources,
      [resourceId]: {
        ...resource,
        copyUserId: currentUserId === userId ? null : userId,
      },
    },
  };
};

export function addLogicalDevice(state, logicalDevice, logicalDevicePaths) {
  return addResource(
    state,
    {
      resourceId: logicalDevice.id,
      paths: logicalDevicePaths,
      resourceType: LOGICAL_DEVICES_FIELD,
      initiallyOpen: false,
    },
  );
}

export function reduceLogicalDevices(logicalDevices, logicalDevicePaths) {
  return (state, logicalDeviceId) => addLogicalDevice(
    state,
    logicalDevices[logicalDeviceId],
    logicalDevicePaths,
  );
}

export function addLogicalDevices(state, action) {
  const {
    response: {
      logicalDevices = {},
      projectId,
      logicalDevicePaths,
    },
  } = action.payload;

  return merge(
    {},
    state,
    {
      projects: {
        [projectId]: {
          logicalDeviceIds: Object.keys(logicalDevices),
        },
      },
    },
    Object.keys(logicalDevices).reduce(
      reduceLogicalDevices(logicalDevices, logicalDevicePaths),
      state,
    ),
  );
}

export const extractPermissionNames = ({
  userPermissions,
  permissionResourceId,
  permissionResourceType,
}) => {
  const resourceUserPermission = userPermissions.filter(
    ({ resourceId, resourceType }) => (
      resourceId === permissionResourceId
      && resourceType === permissionResourceType
    ),
  )[0];
  const { id, permissions } = resourceUserPermission;
  const permissionNames = permissions.map(
    ({ name }) => `${permissionResourceType}:${name}`,
  );

  return { id, permissionNames };
};

export const mergeUserPermissions = ({
  permissionResourceType,
  resourceId: permissionResourceId,
  userPermissions,
  copyUserPermissions,
}) => {
  const nameAndId = { permissionResourceId, permissionResourceType };
  const { id, permissionNames } = extractPermissionNames({
    userPermissions,
    ...nameAndId,
  });
  const { permissionNames: copyPermissionNames } = extractPermissionNames({
    userPermissions: copyUserPermissions,
    ...nameAndId,
  });
  const permissions = copyPermissionNames.reduce(
    (names, name) => {
      if (!includes(names, name)) {
        return [
          ...names,
          name,
        ];
      }

      return names;
    },
    permissionNames,
  );

  return { id, permissions };
};

export const replaceUserPermissions = ({
  permissionResourceType,
  resourceId: permissionResourceId,
  userPermissions,
  copyUserPermissions,
}) => {
  const nameAndId = { permissionResourceId, permissionResourceType };
  const { id } = extractPermissionNames({
    userPermissions,
    ...nameAndId,
  });
  const { permissionNames: permissions } = extractPermissionNames({
    userPermissions: copyUserPermissions,
    ...nameAndId,
  });

  return { id, permissions };
};

const permissionsHelpers = {

};

export default permissionsHelpers;
