export default class ElinkCreator {

  constructor(sixeyeFirmwareVersion, tabs) {
    this.model = 'eLink';
    this.sixeyeFirmwareVersion = sixeyeFirmwareVersion;
    this.manufacturerSDK = 'pathway';
    this.tabs = tabs;
  }

}
