import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { uploadFileType } from 'data/types';
import { FlatIcon } from 'components/elements';
import MobileUploadItem from './UploadsAccordionItem.style';

class UploadsAccordionItem extends Component {

  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
    this.handleForce = this.handleForce.bind(this);
    this.handlePause = this.handlePause.bind(this);
    this.handleResume = this.handleResume.bind(this);
  }

  handleCancel(e) {
    const { cancelUpload } = this.context;
    const { item } = this.props;

    e.stopPropagation();
    cancelUpload(item);
  }

  handleForce(e) {
    const { forceUpload } = this.context;
    const { item } = this.props;

    e.stopPropagation();
    forceUpload(item);
  }

  handlePause(e) {
    const { pauseUpload } = this.context;
    const { item } = this.props;

    e.stopPropagation();
    pauseUpload(item);
  }

  handleResume(e) {
    const { resumeUpload } = this.context;
    const { item } = this.props;

    e.stopPropagation();
    resumeUpload(item);
  }

  render() {
    const {
      item: {
        shortName,
        progress,
        status,
        statusFlags: { paused },
        buttonProps,
        pauseResumeProps,
        playProps,
      },
      item,
    } = this.props;

    const { renderControl } = this.context;

    return (
      <MobileUploadItem>
        {renderControl(
          item,
          <MobileUploadItem.LeftSideControl onClick={this.handleForce} />,
          <MobileUploadItem.LeftSideControl icon={<FlatIcon name="play_arrow" />} />,
          playProps,
        )}
        <MobileUploadItem.Name>{shortName}</MobileUploadItem.Name>
        <MobileUploadItem.Status>
          {!paused ? `${progress.toFixed(2)}%` : status}
        </MobileUploadItem.Status>
        {renderControl(
          item,
          <MobileUploadItem.RightSideControl
            onClick={paused ? this.handleResume : this.handlePause}
          />,
          <MobileUploadItem.RightSideControl icon={<FlatIcon name="refresh" />} />,
          pauseResumeProps,
        )}
        <MobileUploadItem.RightSideControl
          {...buttonProps}
          onClick={this.handleCancel}
        />
      </MobileUploadItem>
    );
  }

}

UploadsAccordionItem.contextTypes = {
  cancelUpload: PropTypes.func.isRequired,
  forceUpload: PropTypes.func.isRequired,
  pauseUpload: PropTypes.func.isRequired,
  renderControl: PropTypes.func.isRequired,
  resumeUpload: PropTypes.func.isRequired,
};

UploadsAccordionItem.propTypes = {
  // eslint-disable-next-line react/no-typos
  item: uploadFileType.isRequired,
};

export default UploadsAccordionItem;
