/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import isString from 'lodash/isString';

import Form from 'components/form/Form/Form';
import { Button } from 'components/atomic';

class ModalForm extends Component {

  constructor() {
    super();

    this.state = { modalOpen: false };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  UNSAFE_componentWillReceiveProps({
    submitSucceeded,
    reset,
    form,
    destroy,
    destroyForm,
  }) {
    if (submitSucceeded) {
      reset();
      this.setState({ modalOpen: false });
      if (destroyForm) {
        destroy(form);
      }
    }
  }

  handleOpen(e) {
    const { initialValues, initialize } = this.props;

    e.stopPropagation();
    initialize(initialValues);
    this.setState({ modalOpen: true });
  }

  handleClose() {
    this.setState({ modalOpen: false });
    const { form, destroy, destroyForm } = this.props;
    if (destroyForm) {
      destroy(form);
    }
  }

  render() {
    const { modalOpen } = this.state;
    const {
      children,
      handleSubmit,
      header,
      size,
      submitting,
      trigger,
      triggerSize,
      closeOnDimmerClick,
    } = this.props;

    const modalTrigger = isString(trigger)
      ? (
        <span onClick={this.handleOpen} onKeyPress={this.handleOpen} role="button" tabIndex="0">
          <Button primary triggerButton size={triggerSize || 'small'}>{trigger}</Button>
        </span>
      )
      : (
        <span onClick={this.handleOpen} onKeyPress={this.handleOpen} role="button" tabIndex="0">
          <trigger.type {...trigger.props} onClick={this.handleOpen} />
        </span>
      );

    return (
      <Modal
        open={modalOpen}
        size={size || 'mini'}
        onSubmit={handleSubmit}
        onClose={this.handleClose}
        trigger={modalTrigger}
        closeOnDimmerClick={closeOnDimmerClick}
        onClick={e => e.stopPropagation()}
      >
        <Modal.Header>{header}</Modal.Header>
        <Modal.Content>
          <Form id="modal-form">
            {children}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            name="cancel"
            type="button"
            primary
            basic
            onClick={this.handleClose}
            disabled={submitting}
          >
            Cancel
          </Button>
          <Button primary type="submit" form="modal-form" disabled={submitting}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

}

ModalForm.defaultProps = {
  destroyForm: false,
};

ModalForm.propTypes = {
  ...Modal.propTypes,
  children: PropTypes.node.isRequired,
  header: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  trigger: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  destroyForm: PropTypes.bool,
};

export default ModalForm;
