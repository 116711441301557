import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { deepEqualMemoizeOne } from 'helpers/sharedMethods';
import { currentUserSelector } from 'helpers/selectors';
import { selectors as applicationUserSelectors } from '../../../../../utilities/user';
import { actionCreators } from '../../../utilities/users';
import ReceivedInvitationsModal from './ReceivedInvitationsModal';

export const makeMapState = () => {
  const memoInvitations = deepEqualMemoizeOne();

  return function mapStateToProps(state, { match: { params: { id: siteId } } }) {
    const invitationsSuppressed = applicationUserSelectors.invitationsSuppressedSelector(state);
    const {
      id: currentUserId,
      receivedInvitations,
    } = currentUserSelector(state);
    const invitations = (
      siteId
        ? receivedInvitations.filter(
          ({ projectId }) => projectId && projectId !== siteId,
        )
        : receivedInvitations
    );

    return {
      invitationsSuppressed,
      currentUserId,
      siteId,
      invitations: memoInvitations(invitations),
    };
  };
};

const mapDispatchToProps = {
  onDeleteInvitation: actionCreators.deleteInvitation,
  onSuppressInvitations: actionCreators.suppressInvitations,
};

export default compose(
  withRouter,
  connect(makeMapState, mapDispatchToProps),
)(ReceivedInvitationsModal);
