import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';

import formNames from 'components/form/formNames';
import { companiesAvailableThemesSelector } from 'helpers/selectors';
import { validateOnSubmit } from 'components/form/formConfigs';
import { companyFormFields } from '../../../../../constants';
import AddCompanyModalForm from './AddCompanyModalForm';

const AddCompanyModalFormContainer = reduxForm({
  form: formNames.addNewCompany,
  ...validateOnSubmit,
})(AddCompanyModalForm);

const mapStateToProps = state => ({
  isManufacturer: formValueSelector(formNames.addNewCompany)(state, companyFormFields.manufacturer),
  availableThemes: companiesAvailableThemesSelector(state),
});

export default connect(mapStateToProps)(AddCompanyModalFormContainer);
