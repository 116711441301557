import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, Segment, Radio } from 'semantic-ui-react';

import Tooltip from 'components/elements/Tooltip';
import { PollingForm, RefreshButton } from './PollingInterface.style';

export const RateEnum = Object.freeze({
  OFF: 'off',
});

class PollingInterface extends PureComponent {

  static getStatus(uiValue) {
    return {
      value: String(uiValue) === RateEnum.OFF ? 0 : uiValue,
      uiValue,
    };
  }

  constructor() {
    super();

    this.handleChange = this.handleChange.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleRefreshCancel = this.handleRefreshCancel.bind(this);
  }

  componentDidMount() {
    const {
      loader,
      refreshOnMount,
      timeout,
      parameters,
      resourceId,
      refreshLoader,
      initRefreshing,
    } = this.props;

    initRefreshing({
      loader,
      refreshRate: this.getRefreshRate(),
      initRefresh: refreshOnMount,
      refreshLoader,
      timeout,
      parameters,
      resourceId,
    });
  }

  componentDidUpdate(prevProps) {
    const { isOnline: prevIsOnline } = prevProps;
    const { isOnline, loader, setRefreshRate } = this.props;

    if (prevIsOnline && !isOnline) {
      setRefreshRate(loader, PollingInterface.getStatus(RateEnum.OFF));
    }
  }

  getRefreshRate() {
    const {
      initial,
      value,
      isOnline,
      uiValue,
    } = this.props;

    if (!isOnline) {
      return PollingInterface.getStatus(RateEnum.OFF);
    }
    if (value === null) {
      return PollingInterface.getStatus(initial);
    }
    return PollingInterface.getStatus(uiValue);
  }

  handleChange(e, { value: uiValue }) {
    const { setRefreshRate, loader, refreshing } = this.props;

    if (refreshing) {
      this.handleRefreshCancel();
    }
    setRefreshRate(loader, PollingInterface.getStatus(uiValue));
  }

  handleRefresh() {
    const {
      loader,
      parameters,
      refreshLoader,
      timeout,
      resourceUpdate,
      startRefreshing,
      resourceId,
    } = this.props;

    resourceUpdate(loader, refreshLoader, {
      timeout,
      parameters,
      resourceId,
    });
    startRefreshing(loader);
  }

  handleRefreshCancel() {
    const {
      loader,
      refreshLoader,
      setRefreshRate,
      stopRefreshing,
    } = this.props;

    setRefreshRate(refreshLoader, { value: 0 });
    stopRefreshing(loader);
  }

  render() {
    const { uiValue, refreshing, rates, isOnline, hideManualRefresh } = this.props;

    const disabled = !isOnline || (String(uiValue) !== RateEnum.OFF);
    const trigger = (
      <div>
        <RefreshButton
          disabled={disabled}
          onClick={refreshing ? this.handleRefreshCancel : this.handleRefresh}
          isFetching={refreshing}
        />
      </div>
    );

    return (
      <Segment compact basic>
        <PollingForm>
          <Form.Field>
            Refresh rate in seconds:
          </Form.Field>
          <Form.Group inline>
            {rates.map(rate => (
              <Form.Field key={rate}>
                <Radio
                  label={rate}
                  name={String(rate)}
                  value={rate}
                  checked={uiValue === rate}
                  disabled={!isOnline}
                  onChange={this.handleChange}
                />
              </Form.Field>
            ))}
            {!hideManualRefresh && (
              <Form.Field>
                {!disabled ? trigger : (
                  <Tooltip
                    trigger={trigger}
                    content="Manual refresh enabled when automatic refresh is off"
                    position="top right"
                  />
                )}
              </Form.Field>
            )}
          </Form.Group>
        </PollingForm>
      </Segment>
    );
  }

}

PollingInterface.defaultProps = {
  parameters: {},
  refreshing: false,
  timeout: 20,
  uiValue: null,
  value: null,
  refreshOnMount: true,
  hideManualRefresh: false,
};

PollingInterface.propTypes = {
  initial: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  isOnline: PropTypes.bool.isRequired,
  loader: PropTypes.string.isRequired,
  parameters: PropTypes.object,
  rates: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  refreshing: PropTypes.bool,
  refreshLoader: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  timeout: PropTypes.number,
  uiValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  value: PropTypes.number,
  hideManualRefresh: PropTypes.bool,
  refreshOnMount: PropTypes.bool,
  resourceUpdate: PropTypes.func.isRequired,
  setRefreshRate: PropTypes.func.isRequired,
  startRefreshing: PropTypes.func.isRequired,
  stopRefreshing: PropTypes.func.isRequired,
  initRefreshing: PropTypes.func.isRequired,
};

export default PollingInterface;
