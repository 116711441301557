import { takeEvery, takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import addOrigin from './addOrigin';
import deleteOrigin from './deleteOrigin';
import editOrigin from './editOrigin';
import editUrlOrigin from './editUrlOrigin';

function* saga() {
  yield takeLatest(constants.ADD_ORIGIN_REQUEST, addOrigin);
  yield takeLatest(constants.EDIT_ORIGIN_REQUEST, editOrigin);
  yield takeEvery(constants.EDIT_URL_ORIGIN_REQUEST, editUrlOrigin);
  yield takeLatest(constants.DELETE_ORIGIN_REQUEST, deleteOrigin);
}

export default saga;
