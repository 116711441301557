export const MISSING_DATA = 'Argument \'data\' was not provided.';
export const MISSING_ATTRIBUTES = 'Argument \'data\' is not a valid JSON API object - missing \'attributes\'.';

class FlattenApiResource {

  constructor(data) {
    if (!data) {
      throw Error(MISSING_DATA);
    }

    const { attributes } = data;
    if (!attributes) {
      throw Error(MISSING_ATTRIBUTES);
    }

    this.id = data.id;
    Object.keys(attributes).forEach(key => {
      this[key] = attributes[key];
    });
  }

}

export default FlattenApiResource;
