import styled, { css } from 'styled-components';
import { Grid } from 'semantic-ui-react';

import filterProps from 'helpers/filterProps';

const FilteredPropsRow = filterProps([
  'noPaddingBottom',
])(Grid.Row);

const FilteredPropsColumn = filterProps([
  'hasError',
])(Grid.Column);

export const StyledRow = styled(FilteredPropsRow)`
  &&& {
    ${({ noPaddingBottom }) => noPaddingBottom && css`
      padding-bottom: 0 !important;
    `}
  }
`;

export const StyledLabel = styled.label`
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
`;

export const StyledColumn = styled(FilteredPropsColumn)`
  &&& {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

    ${({ hasError }) => !hasError && css`
      align-items: flex-end !important;
    `}
  }
`;

export default StyledColumn;
