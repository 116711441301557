import devicesForSiteSelector from 'pages/Projects/components/MapView/components/MapWrapper/components/Markers/components/Marker/components/MarkerDevicesTable/utilities/selectors/devicesForSiteSelector';

import {
  selectors as taskSchedulerSelectors,
} from 'application/tenant/console/site/components/structural/SiteSchedulingTab/components/AutomatedOperations/components/AddAutomatedOperation/utilities';

const getLogicalDevices = (state, siteId, superSites) => {
  if (superSites) {
    const siteDevices = devicesForSiteSelector(state)(siteId);
    const devicesList = siteDevices && siteDevices.devicesList;

    if (Array.isArray(devicesList)) {
      return devicesList.filter(device => (device.deviceAssigned !== null));
    }

    return [];
  }

  const allDevices = taskSchedulerSelectors.projectDevicesSelector(state);

  return allDevices.filter(device => (device.deviceAssigned !== null));
};

export default getLogicalDevices;
