import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';

import { StyledInlineEditTitle } from 'components/elements/StyledInlineEditTitle/StyledInlineEditTitle.style';
import { HeaderWrapper } from 'components/elements';
import { attachId } from 'helpers/layoutSwitchHelpers';
import TabbedLayout from 'components/TabbedLayout/TabbedLayout';

import { StyledVersionInfo } from './components/StyledVersionInfo/StyledVersionInfo.style';
import profileTabs from './profileTabs';

const tabsWithIds = attachId(profileTabs);

const Profile = ({ location, notificationsEnabled }) => {
  const { pathname } = location;

  const fixedVersionNumber = useMemo(
    () => pathname && !pathname.includes('notification_logs'),
    [pathname],
  );

  const tabPanes = useMemo(() => {
    if (!notificationsEnabled) {
      return tabsWithIds.filter(tab => tab.path !== '/notification_logs');
    }

    return tabsWithIds;
  }, [notificationsEnabled]);

  return (
    <Fragment>
      <HeaderWrapper>
        <StyledInlineEditTitle as="h1" size="medium">
          Profile
        </StyledInlineEditTitle>
      </HeaderWrapper>
      <TabbedLayout panes={tabPanes} />
      <StyledVersionInfo fixedVersionNumber={fixedVersionNumber}>
        ver {process.env.REACT_APP_VERSION}
      </StyledVersionInfo>
    </Fragment>
  );
};

Profile.defaultProps = {
  notificationsEnabled: false,
};

Profile.propTypes = {
  location: PropTypes.object.isRequired,
  notificationsEnabled: PropTypes.bool,
};

export default Profile;
