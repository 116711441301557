import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, FormInput, Icon } from 'semantic-ui-react';

import { PermissionsSwitch, Gateway, frontendPermissions } from 'gateway';
import { Button, TipIcon } from 'components/atomic';
import ThemedToggle from 'components/elements/ThemedToggle/ThemedToggle.style';

import { SCHEDULER_TYPES, TASK_SCHEDULER_MODE } from '../../../../constants';
import { PRIORITIES, RECURRENCE_OPTIONS, DATE_BLOCKING_HELP } from './constants';
import {
  Container,
  StyledLabel,
  ButtonsContainer,
  DateBlockingContainer,
  StyledButton,
  StyledMessage,
  StyledGridRow,
  StyledGridColumn,
  LinkButton,
} from './ConfigurationPanel.style';

function ConfigurationPanel({
  priority,
  priorityType,
  schedulerType,
  schedulerName,
  schedulerDescription,
  taskSchedulerMode,
  resourceType,
  onPriorityChange,
  onPriorityTypeChange,
  onSchedulerTypeChange,
  onClearSchedulerParams,
  onSchedulerNameChange,
  onSchedulerDescriptionChange,
}) {
  const [showOptionalFields, setShowOptionalFields] = useState(() => {
    if (taskSchedulerMode === TASK_SCHEDULER_MODE.EDIT_SCHEDULER) {
      return true;
    }

    return false;
  });

  const recurrenceOptions = useMemo(() => {
    if (taskSchedulerMode !== TASK_SCHEDULER_MODE.DEFAULT) {
      return RECURRENCE_OPTIONS.filter(
        recurrenceOption => recurrenceOption.key !== SCHEDULER_TYPES.NONE,
      );
    }

    return RECURRENCE_OPTIONS;
  }, [taskSchedulerMode]);

  const handlePriorityChange = useCallback(value => {
    onPriorityChange(value);
  }, [onPriorityChange]);

  const handleDateBlockingChange = useCallback((event, { checked }) => {
    const type = checked ? 'dates' : 'times';

    onPriorityTypeChange(type);
  }, [onPriorityTypeChange]);

  const handleSchedulerTypeChange = useCallback(selectedSchedulerType => {
    if (selectedSchedulerType !== schedulerType) {
      onSchedulerTypeChange(selectedSchedulerType);
      onClearSchedulerParams();
    }
  }, [schedulerType, onSchedulerTypeChange, onClearSchedulerParams]);

  const isOptionDisabled = useCallback(key => {
    if (taskSchedulerMode === TASK_SCHEDULER_MODE.EDIT_SCHEDULER) {
      if (key === schedulerType) {
        return false;
      }

      return true;
    }

    return false;
  }, [taskSchedulerMode, schedulerType]);

  const permission = useMemo(() => {
    if (resourceType === 'projectSuite') {
      return frontendPermissions.PROJECT_SUITE_SCHEDULES_ADD;
    }

    return frontendPermissions.ADD_SCHEDULE;
  }, [resourceType]);

  const handleShowOptionalFields = useCallback(() => {
    setShowOptionalFields(!showOptionalFields);
  }, [showOptionalFields]);

  const icon = useMemo(() => {
    if (showOptionalFields) {
      return 'arrow up';
    }

    return 'arrow down';
  }, [showOptionalFields]);

  const renderRecurrenceOptions = disableAll => {
    const showPermissionWarning = disableAll
    && taskSchedulerMode !== TASK_SCHEDULER_MODE.EDIT_SCHEDULER;

    return (
      <>
        <ButtonsContainer>
          {recurrenceOptions.map(recurrenceOption => (
            <StyledButton
              key={recurrenceOption.key}
              basic={schedulerType !== recurrenceOption.key}
              size="tiny"
              name="btn-recurrence"
              disabled={disableAll || isOptionDisabled(recurrenceOption.key)}
              onClick={() => handleSchedulerTypeChange(recurrenceOption.key)}
            >
              {recurrenceOption.text}
            </StyledButton>
          ))}
          {showPermissionWarning && (
            <StyledMessage name="permission-warning" className="text-danger">
              You do not have permission to add a Schedule.
            </StyledMessage>
          )}
        </ButtonsContainer>
      </>
    );
  };

  return (
    <Container>
      <StyledLabel>Recurrence</StyledLabel>
      <PermissionsSwitch>
        <Gateway condition={permission}>
          {renderRecurrenceOptions(false)}
        </Gateway>
        <Gateway fallback>
          {renderRecurrenceOptions(true)}
        </Gateway>
      </PermissionsSwitch>
      {taskSchedulerMode === TASK_SCHEDULER_MODE.DEFAULT && (
        <>
          <StyledLabel>Priority</StyledLabel>
          <ButtonsContainer>
            {PRIORITIES.map(priorityItem => (
              <Button
                key={priorityItem.text}
                basic={priority !== priorityItem.value}
                size="tiny"
                name="btn-priority"
                onClick={() => handlePriorityChange(priorityItem.value)}
              >
                {priorityItem.text}
              </Button>
            ))}
          </ButtonsContainer>
        </>
      )}
      {taskSchedulerMode === TASK_SCHEDULER_MODE.DEFAULT && (
        <DateBlockingContainer>
          <div>
            <StyledLabel>
              Date blocking
            </StyledLabel>
            <TipIcon
              floated="right"
              message={DATE_BLOCKING_HELP}
              position="bottom center"
              editMode={false}
            />
          </div>
          <ThemedToggle
            className="date-blocking-toggle"
            checked={priorityType === 'dates'}
            onChange={handleDateBlockingChange}
          />
        </DateBlockingContainer>
      )}
      <PermissionsSwitch>
        <Gateway condition={permission}>
          <>
            <LinkButton onClick={handleShowOptionalFields}>
              Set scheduler name & description (optional)&nbsp;&nbsp;
              <Icon name={icon} />
            </LinkButton>
            {showOptionalFields && (
              <Grid>
                <StyledGridRow>
                  <StyledGridColumn mobile={16} computer={16}>
                    <StyledLabel>
                      Scheduler Name
                    </StyledLabel>
                    <FormInput
                      value={schedulerName}
                      placeholder="Name"
                      name="schedulerName"
                      type="text"
                      onChange={({ target }) => onSchedulerNameChange(target.value)}
                      fluid
                    />
                  </StyledGridColumn>
                  <StyledGridColumn mobile={16} computer={16}>
                    <StyledLabel>
                      Scheduler Description
                    </StyledLabel>
                    <FormInput
                      value={schedulerDescription}
                      placeholder="Description"
                      name="schedulerDescription"
                      type="text"
                      onChange={({ target }) => onSchedulerDescriptionChange(target.value)}
                      fluid
                    />
                  </StyledGridColumn>
                </StyledGridRow>
              </Grid>
            )}
          </>
        </Gateway>
      </PermissionsSwitch>
    </Container>
  );
}

ConfigurationPanel.defaultProps = {
  priority: 2,
  priorityType: 'times',
  resourceType: 'project',
  schedulerName: null,
  schedulerDescription: null,
};

ConfigurationPanel.propTypes = {
  priority: PropTypes.number,
  priorityType: PropTypes.string,
  taskSchedulerMode: PropTypes.string.isRequired,
  schedulerType: PropTypes.string.isRequired,
  resourceType: PropTypes.oneOf([
    'project',
    'projectSuite',
  ]),
  schedulerName: PropTypes.string,
  schedulerDescription: PropTypes.string,
  onPriorityChange: PropTypes.func.isRequired,
  onPriorityTypeChange: PropTypes.func.isRequired,
  onSchedulerTypeChange: PropTypes.func.isRequired,
  onClearSchedulerParams: PropTypes.func.isRequired,
  onSchedulerNameChange: PropTypes.func.isRequired,
  onSchedulerDescriptionChange: PropTypes.func.isRequired,
};

export default ConfigurationPanel;
