import { race, take, put, takeEvery, select, delay } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import chainedLoaderActionCreators from 'helpers/chainedLoader/chainedLoaderActionCreators';
import { actionWithIdSelector } from 'helpers/sagaHelper';


export function* startPolling(action) {
  const { id, interval } = action.payload;

  while (true) {
    const { stop, terminate } = yield race({
      stop: take(actionWithIdSelector(constants.POLLING_STOP_V2, id)),
      terminate: take(actionWithIdSelector(constants.POLLING_TERMINATE_V2, id)),
      next: delay(interval),
    });

    if (terminate) {
      break;
    }

    if (stop) {
      const { terminate2 } = yield race({
        start: take(actionWithIdSelector(constants.POLLING_START_V2, id)),
        terminate2: take(actionWithIdSelector(constants.POLLING_TERMINATE_V2, id)),
      });
      if (terminate2) {
        break;
      }
    }

    const pollingActive = yield select(state => state.pollingSaga[id].refreshing);
    if (pollingActive) yield put(chainedLoaderActionCreators.triggerChain(id));
  }
}

export function* pollingSaga() {
  yield takeEvery(constants.POLLING_INIT_V2, startPolling);
}

export default pollingSaga;
