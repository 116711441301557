import constants from 'dispatcherConst';

export const removeSelectedAction = ({ resourceId, actionId, actionIndex }) => ({
  type: constants.REMOVE_SELECTED_ACTION,
  payload: {
    resourceId,
    actionId,
    actionIndex,
  },
});

export default removeSelectedAction;
