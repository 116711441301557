import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { actionCreators, selectors } from '../../../../utilities';
import VoucherInput from './VoucherInput';

export const mapStateToProps = (state, { match }) => {
  const { id: projectId } = match.params;

  return {
    projectId,
    isLoading: selectors.isLoadingVoucherSelector(state),
    typedVoucher: selectors.typedVoucherSelector(state),
    selectedVoucher: selectors.selectedVoucherSelector(state),
    voucherError: selectors.voucherErrorSelector(state),
  };
};

const mapDispatchToProps = {
  onFetchVoucherDetails: actionCreators.fetchVoucherDetails,
  onClearSelectedVoucher: actionCreators.clearSelectedVoucher,
  onVoucherTyped: actionCreators.setTypedVoucher,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(VoucherInput);
