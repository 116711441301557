import { API_DOMAIN, TENANT, TOKEN, UPLOADS } from 'storageConst';

function logout() {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(TENANT);
  localStorage.removeItem(API_DOMAIN);
  localStorage.removeItem(UPLOADS);
}

export default logout;
