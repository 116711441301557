import isDate from 'lodash/isDate';
import { DateTime } from 'luxon';

const dateToISOString = value => {
  if (isDate(value)) {
    return DateTime.fromJSDate(value).toISODate();
  }

  return null;
};

export default dateToISOString;
