import React from 'react';
import { fieldPropTypes } from 'redux-form';
import { FormField } from 'semantic-ui-react';

import { getDisplayName } from 'helpers/sharedMethods';
import FormFeedback from 'components/form/FormFeedback/FormFeedback';

const withFormField = WrappedComponent => {
  const WithFormField = ({ ...rest }) => {
    const { meta: { touched, error } = {} } = rest;
    const isInvalid = Boolean(touched && error);

    return (
      <FormField error={isInvalid}>
        <WrappedComponent {...rest} />

        <FormFeedback visible={isInvalid} items={error} negative />
      </FormField>
    );
  };

  WithFormField.displayName = `WithFormField(${getDisplayName(WrappedComponent)})`;

  WithFormField.propTypes = {
    ...fieldPropTypes,
  };

  return WithFormField;
};

export default withFormField;
