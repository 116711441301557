import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';

export const resetSecretKey = ({ id, ...rest }) => ({
  type: constants.RESET_SECRET_KEY,
  payload: {
    endpoint: endpoints.USER(id),
    id,
    ...rest,
  },
});

export const resetSecretKeySuccess = response => ({
  type: constants.RESET_SECRET_KEY_SUCCESS,
  payload: {
    response,
    message: messages.RESET_SECRET_KEY,
  },
});

export const resetSecretKeyFailure = error => ({
  type: constants.RESET_SECRET_KEY_FAILED,
  payload: { error },
});
