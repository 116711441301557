import styled from 'styled-components';
import { Header } from 'semantic-ui-react';

export const StyledInlineEditTitle = styled(Header)`
  height: 40px;
  display: flex;
  align-items: center;
  
  span {
    font-size: 1.02em;
    padding-left: 0 !important;
    font-weight: 700;
  }
`;

export default StyledInlineEditTitle;
