import { compose } from 'redux';
import { connect } from 'react-redux';

import { actionCreators } from '../../../../../../utilities/users';
import withResetSecretKey from './withResetSecretKey';

const mapDispatchToProps = {
  onResetSecretKey: actionCreators.resetSecretKey,
};

export default compose(
  connect(null, mapDispatchToProps),
  withResetSecretKey,
);
