import styled, { css } from 'styled-components';
import { Select } from 'semantic-ui-react';

const StyledSelectField = styled(Select)`
  ${({ loading }) => loading && css`
    pointer-events: none;
  `};
`;

export default StyledSelectField;
