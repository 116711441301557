import React from 'react';
import PropTypes from 'prop-types';

import DataTableRow from 'components/DataTable/DataTableRow/DataTableRow';
import DataTableText from 'components/DataTable/DataTableText/DataTableText';
import DataTableEditableToggle from 'components/DataTable/DataTableEditableToggle/DataTableEditableToggle';
import { StyledTextEdit } from './DeviceFirmwareCustomRow.style';

const DeviceFirmwareCustomRow = props => {
  const {
    item,
    onEditDeviceFirmware,
  } = props;
  const { id } = item;

  const handleEditText = values => new Promise((resolveForm, rejectForm) => {
    onEditDeviceFirmware({
      values: {
        ...values,
        id,
      },
      resolveForm,
      rejectForm,
    });
  });

  const handleSubmit = () => new Promise((resolveForm, rejectForm) => {
    onEditDeviceFirmware({
      values: {
        hidden: !item.hidden,
        id,
      },
      resolveForm,
      rejectForm,
    });
  });

  return (
    <DataTableRow {...props}>
      <DataTableText />
      <DataTableText />
      <DataTableText />
      <StyledTextEdit
        name="description"
        type="text"
        value={item.description}
        onSubmit={handleEditText}
        form={`form-description-${item.id}`}
        allowCancelOnPristine
      />
      <DataTableText />
      <DataTableEditableToggle
        field="hidden"
        item={item}
        onSubmit={() => handleSubmit}
      />
    </DataTableRow>
  );
};

DeviceFirmwareCustomRow.defaultProps = {
  field: null,
  item: {},
  onEditDeviceFirmware: () => null,
};

DeviceFirmwareCustomRow.propTypes = {
  field: PropTypes.string,
  item: PropTypes.object,
  onEditDeviceFirmware: PropTypes.func,
};

export default DeviceFirmwareCustomRow;
