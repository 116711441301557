import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withTheme } from 'styled-components';
import merge from 'lodash/merge';
import { defaultManifest, splashScreensConfig } from './PWAConsts';
import manifestGenerator from './PWAHelpers';

const PWAProvider = ({ theme: { manifest } }) => {

  const manifestData = merge(defaultManifest, manifest);
  const manifestURL = manifestGenerator(manifestData);

  return (
    <Helmet>
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="white" />
      <link rel="manifest" href={manifestURL} />
      <meta name="theme-color" content={manifestData.theme_color} />

      {manifestData.icons.map(icon => (
        <link rel="apple-touch-icon" sizes={icon.sizes} href={icon.src} key={icon.sizes} />
      ))}

      {splashScreensConfig.map(splash => {
        const splashImage = manifestData.splash_screens.find(sc => sc.sizes === splash.size);
        if (!splashImage) return null;
        return (<link rel="apple-touch-startup-image" href={splashImage.src} media={splash.query} key={splash.size} />);
      })}
    </Helmet>
  );
};

PWAProvider.defaultProps = {
  theme: {},
};

PWAProvider.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(PWAProvider);
