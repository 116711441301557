import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';

const compareSelectedValues = (previousSelected, selected) => {
  if (Array.isArray(previousSelected) && Array.isArray(selected)) {
    return isEqual(
      sortBy(previousSelected), sortBy(selected),
    );
  }

  if (!Array.isArray(previousSelected) && Array.isArray(selected)) {
    return false;
  }

  return true;
};

export default compareSelectedValues;
