import { compose } from 'redux';
import { connect } from 'react-redux';
import last from 'lodash/last';

import {
  PROJECTS_FIELD,
  computedProjectTreePathSelector,
} from 'helpers/selectors';
import { deepEqualMemoizeOne, cleanUpLabel, applyVocabulary } from 'helpers/sharedMethods';
import { PermissionsTreeItem } from '../../../../../../../../../../components';
import { sortPaths } from '../../../../../../../../../../utilities';
import permissionsActionCreators from '../../../../../../../../../../utilities/permissions/permissionsActionCreators';

// eslint-disable-next-line import/no-mutable-exports
let ContextProjectPermissionsTreeItemContainer;

export const makeMapState = () => {
  const memoPaths = deepEqualMemoizeOne();

  return function mapStateToProps(state, { path, resourceId }) {
    const { open, permissionCount, permissionId, ...paths } = computedProjectTreePathSelector(
      state,
      {
        projectId: resourceId,
        path,
        scopes: ['tenant'],
      },
    );
    const label = cleanUpLabel(applyVocabulary(last(path.split('.'))));
    const sortedPaths = sortPaths(paths);

    return {
      open,
      label,
      paths: memoPaths(sortedPaths),
      PermissionsTreeItemContainer: ContextProjectPermissionsTreeItemContainer,
    };
  };
};

const mapDispatchToProps = {
  onToggleOpen: (projectId, path) => permissionsActionCreators.toggleResourceTreeOpen(
    PROJECTS_FIELD,
    projectId,
    path,
  ),
};

ContextProjectPermissionsTreeItemContainer = compose(
  connect(makeMapState, mapDispatchToProps),
)(PermissionsTreeItem);

export default ContextProjectPermissionsTreeItemContainer;
