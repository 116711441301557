import {
  PermissionsProjectsTabNew,
  PermissionsProjectSuitesTabNew,
  PermissionsContextsTabNew,
  PermissionsCompaniesTab,
} from '../components';

export default [
  {
    menuItem: 'Sites',
    path: '/sites',
    rows: [
      {
        columns: [
          {
            computer: 16,
            mobile: 16,
            sections: [
              {
                component: PermissionsProjectsTabNew,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Multi-sites',
    path: '/multi-sites',
    rows: [
      {
        columns: [
          {
            computer: 16,
            mobile: 16,
            sections: [
              {
                component: PermissionsProjectSuitesTabNew,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Companies',
    path: '/companies',
    rows: [
      {
        columns: [
          {
            computer: 16,
            mobile: 16,
            sections: [
              {
                component: PermissionsCompaniesTab,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Portal',
    path: '/portal',
    rows: [
      {
        columns: [
          {
            computer: 16,
            mobile: 16,
            sections: [
              {
                component: PermissionsContextsTabNew,
              },
            ],
          },
        ],
      },
    ],
  },
];
