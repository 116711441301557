import constants from 'dispatcherConst';

const initialState = {
  isInitialized: false,
  isUpdated: false,
  registration: null,
};

const serviceWorkerReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SERVICE_WORKER_INIT:
      return {
        ...state,
        isInitialized: !state.isInitialized,
      };

    case constants.SERVICE_WORKER_UPDATE:
      return {
        ...state,
        isUpdated: !state.isUpdated,
        registration: action.payload,
      };

    default:
      return state;
  }
};

export default serviceWorkerReducer;
