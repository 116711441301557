import styled, { css } from 'styled-components';
import { Table, Divider } from 'semantic-ui-react';
import get from 'lodash/get';
import isNull from 'lodash/isNull';

import filterProps from 'helpers/filterProps';

const FilteredProps = filterProps([
  'occurrenceColour',
])('div');

const StyledDivider = styled(Divider)`
  &.ui.divider {
    margin-top: 40px;
  }
`;

export const StyledTaskRow = styled(Table.Row)`
  cursor: pointer;

  @media only screen and (max-width: 991px) {
    padding-left: 75px !important;
  }
`;

export const StyledTaskActionLinkCell = styled(Table.Cell)`
  min-width: 80px !important;
  width: 5% !important;
`;

export const StyledTaskContentCell = styled(Table.Cell)`
  padding-left: 0px !important;

  @media only screen and (max-width: 991px) {
    padding-left: 10px !important;
  }
`;

export const StyledTaskName = styled.span`
  display: inline-block;
  margin-right: 10px;
`;

export const StyledSquare = styled(FilteredProps)`
  display: inline-block;
  border-radius: 3px;
  width: 14px;
  height: 14px;

  ${({ occurrenceColour, theme }) => css`
    background: ${occurrenceColour || get(theme, 'palette.defaultOccurrenceColor')}
    ${(isNull(occurrenceColour) || occurrenceColour === '#FFFFFF') && 'border: solid 1px #dededf'}
  `}

  &:hover {
    outline: 1px solid #000000;
    outline-offset: 3px;
    outline-style: dashed;
  }
`;

export default StyledDivider;
