import { compose } from 'redux';
import { connect } from 'react-redux';

import { deepEqualMemoizeOne } from 'helpers/sharedMethods';
import {
  computedLogicalDeviceTreePathSelector,
  permissionsProjectActiveUserIdsSelector,
} from 'helpers/selectors';
import { PermissionsGridRow } from '../../../../../../../../../../components';
import { sortPaths } from '../../../../../../../../../../utilities';
import { ContextDevicePermissionsGridItem } from '../PermissionsGridItem';

// eslint-disable-next-line import/no-mutable-exports
let ContextDevicePermissionsGridRowContainer;

export const makeMapState = () => {
  const memoPaths = deepEqualMemoizeOne();

  return function mapStateToProps(state, { path, projectId, resourceId }) {
    const { open, permissionCount, permissionId, ...paths } = computedLogicalDeviceTreePathSelector(
      state,
      {
        projectId,
        logicalDeviceId: resourceId,
        path,
        scopes: ['tenant'],
      },
    );
    const userIds = permissionsProjectActiveUserIdsSelector(
      state,
      {
        projectId: resourceId,
      },
    );
    const sortedPaths = sortPaths(paths);

    return {
      open,
      userIds,
      paths: memoPaths(sortedPaths),
      PermissionsGridItem: ContextDevicePermissionsGridItem,
      PermissionsGridRowContainer: ContextDevicePermissionsGridRowContainer,
    };
  };
};

ContextDevicePermissionsGridRowContainer = compose(
  connect(makeMapState),
)(PermissionsGridRow);

export default ContextDevicePermissionsGridRowContainer;
