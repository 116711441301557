import styled from 'styled-components';
import get from 'lodash/get';
import { Button } from 'semantic-ui-react';

export const StyledButton = styled(Button)`
  &.ui.button {
    color: ${({ theme }) => get(theme, ['buttons', '.ui.button', '&.primary', 'color'])} !important;
    background: ${({ theme }) => get(theme, 'palette.primaryColor')} !important;
  }
`;

export default StyledButton;
