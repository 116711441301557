import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

import { Button } from 'components/atomic';

export const StyledPermissionsTreeItem = styled.div`
  min-height: 40px;
`;

export const StyledHeader = styled(Button)`
  &.ui.button {
    border-radius: 0;
    height: 40px;
    margin-bottom: 5px;
    padding: 12px;
    font-size: 16px;
    width: 100%;
    text-align: left;
    display: flex;
    background-color: white;
    font-weight: 400;
  }
`;

export const StyledTitle = styled.span`
  flex: 1;
`;

export const StyledAdditionalButton = styled(Icon)`
  &.plus.icon {
    flex: 0;
    ${({ selecting }) => selecting && 'transform: rotate(45);'}
  }
`;

export const StyledChildrenWrapper = styled.div`
  position: relative;
  width: calc(100% - 12px);
  left: 12px;
  padding-left: 8px;
  border-left: 1px solid lightgrey;
`;
