import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { frontendPermissions, permissionsPass } from 'gateway';
import { singleProjectSuiteSelector } from 'helpers/selectors';

import ProjectSuitePropertyInlineEdit from './ProjectSuitePropertyInlineEdit';

export const mapStateToProps = (state, { match, property }) => {
  const { id: projectSuiteId } = match.params;
  const { [property]: propertyValue } = singleProjectSuiteSelector(state, projectSuiteId);

  return {
    projectSuiteId,
    propertyValue,
  };
};

export const mapDispatchToProps = (dispatch, { onChange }) => ({
  onChangeProperty: args => dispatch(onChange(args)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  permissionsPass(frontendPermissions.PROJECT_SUITE_EDIT),
)(ProjectSuitePropertyInlineEdit);
