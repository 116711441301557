import { Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import get from 'lodash/get';

import { injectStyles } from 'themes/mixins';

export const StyledIconWrapper = styled.div`
  display: inline-block;
`;

export const StyledLabel = styled.span`
  position: relative;
  top: 1px;
`;

export const StyledTooltip = styled(Popup).attrs({
  basic: true,
  inverted: true,
})`
  &.ui.popup {
    padding: 0.25em;
  }
`;

export const StyledIcon = styled(Icon).attrs({
  size: 'large',
})`
  ${({ theme }) => injectStyles(get(theme, 'tables.statusIcons'))}
`;
