import { compose } from 'redux';
import { connect } from 'react-redux';

import { deepEqualMemoizeOne } from 'helpers/sharedMethods';
import {
  computedUserTreePathSelector,
  permissionsProjectActiveUserIdsSelector,
} from 'helpers/selectors';
import { PermissionsGridRow } from '../../../../../../../../../../components';
import { sortPaths } from '../../../../../../../../../../utilities';
import { ContextUserPermissionsGridItem } from '../PermissionsGridItem';

// eslint-disable-next-line import/no-mutable-exports
let ContextUserPermissionsGridRowContainer;

export const makeMapState = () => {
  const memoPaths = deepEqualMemoizeOne();

  return function mapStateToProps(state, { path, projectId, resourceId }) {
    const { open, permissionCount, permissionId, ...paths } = computedUserTreePathSelector(
      state,
      {
        projectId,
        userId: resourceId,
        path,
        scopes: ['tenant'],
      },
    );
    const userIds = permissionsProjectActiveUserIdsSelector(
      state,
      {
        projectId: resourceId,
      },
    );
    const sortedPaths = sortPaths(paths);

    return {
      open,
      userIds,
      paths: memoPaths(sortedPaths),
      PermissionsGridItem: ContextUserPermissionsGridItem,
      PermissionsGridRowContainer: ContextUserPermissionsGridRowContainer,
    };
  };
};

ContextUserPermissionsGridRowContainer = compose(
  connect(makeMapState),
)(PermissionsGridRow);

export default ContextUserPermissionsGridRowContainer;
