import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import { TipIcon } from 'components/atomic';

import {
  ENDING_CRITERIA,
  ENDING_CRITERIA_OPTIONS,
  ENDING_CRITERIA_VIEWS,
  ENDING_CRITERIA_HELP,
} from './constants';
import { SCHEDULER_TYPES } from '../../../../../constants';

import { StyledLabel, StyledDropdown, LabelContainer } from './EndingCriteria.style';

function EndingCriteria({
  existingSchedule,
  recurrenceErrors,
  onChange,
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [type, setType] = useState(ENDING_CRITERIA.NEVER_ENDS);
  const [params, setParams] = useState({});

  const handleParamsChange = useCallback(values => {
    setParams(values);
  }, []);

  const handleTypeChange = useCallback((e, { value }) => {
    setParams({});
    setType(value);
  }, []);

  useEffect(() => {
    if (existingSchedule && !isLoaded) {
      const { type: existingType, params: existingParams } = existingSchedule || {};

      if (existingType.toUpperCase() !== SCHEDULER_TYPES.DATES) {
        const { forNTimes, endTime } = existingParams;

        if (forNTimes) {
          setType(ENDING_CRITERIA.AFTER_N_OCCURRENCES);
        } else if (endTime) {
          setType(ENDING_CRITERIA.ON_DATE_TIME);
        } else {
          setType(ENDING_CRITERIA.NEVER_ENDS);
        }

        setIsLoaded(true);
      }
    }
  }, [isLoaded, existingSchedule]);

  useEffect(() => {
    onChange({
      type,
      params,
    });
  }, [onChange, type, params]);

  const renderEndingCriteriaParamsView = useCallback(() => ENDING_CRITERIA_VIEWS[type]({
    existingSchedule,
    recurrenceErrors,
    onChange: handleParamsChange,
  }), [type, existingSchedule, recurrenceErrors, handleParamsChange]);

  return (
    <>
      <LabelContainer>
        <StyledLabel>
          Ending criteria
        </StyledLabel>
        <TipIcon
          floated="right"
          message={ENDING_CRITERIA_HELP}
          position="bottom center"
          editMode={false}
      />
      </LabelContainer>
      <Grid.Row>
        <Grid.Column computer={6} mobile={16}>
          <StyledDropdown
            name="ending_criteria_dropdown"
            value={type}
            options={ENDING_CRITERIA_OPTIONS}
            onChange={handleTypeChange}
            fluid
            selection
          />
        </Grid.Column>
      </Grid.Row>
      {renderEndingCriteriaParamsView()}
    </>
  );
}

EndingCriteria.defaultProps = {
  recurrenceErrors: {},
  existingSchedule: null,
};

EndingCriteria.propTypes = {
  recurrenceErrors: PropTypes.object,
  existingSchedule: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default EndingCriteria;
