import constants from 'dispatcherConst';
import { identifierToString } from 'helpers/websocketHelpers';
import socketConstants from 'data/websockets/constants';

const websocketsReducer = (state = {}, action) => {
  switch (action.type) {

    case constants.SOCKET_SUBSCRIBE: {
      const { identifier } = action.payload;
      const identifierString = identifierToString(identifier);

      return {
        ...state,
        [identifierString]: {
          ...state[identifierString],
          subscribing: true,
        },
      };
    }

    case constants.SOCKET_SUBSCRIBE_SUCCESS: {
      const { identifier } = action.payload;
      const identifierString = identifierToString(identifier);

      return {
        ...state,
        [identifierString]: {
          ...state[identifierString],
          subscribed: true,
          subscribing: false,
        },
      };
    }

    case constants.SOCKET_SUBSCRIBE_FAILED:
    case constants.SOCKET_UNSUBSCRIBE: {
      const { identifier } = action.payload;
      const identifierString = identifierToString(identifier);

      return {
        ...state,
        [identifierString]: {
          ...state[identifierString],
          subscribed: false,
          subscribing: false,
        },
      };
    }

    case constants.SOCKET_REFRESH: {
      const { identifier } = action.payload;
      const identifierString = identifierToString(identifier);

      return {
        ...state,
        [identifierString]: {
          ...state[identifierString],
          refreshing: true,
        },
      };
    }

    case constants.SOCKET_REFRESH_DONE: {
      const { identifier } = action.payload;
      const identifierString = identifierToString(identifier);

      return {
        ...state,
        [identifierString]: {
          ...state[identifierString],
          refreshing: false,
        },
      };
    }

    case constants.SOCKET_RECEIVED: {
      const { identifier, message } = action.payload;

      if (identifier && message && message.type === socketConstants.CONFIRMATION_GET_LOGS) {
        const identifierString = identifierToString(identifier);

        return {
          ...state,
          [identifierString]: {
            ...state[identifierString],
            loading: true,
          },
        };
      }

      if (identifier && message && message.type === socketConstants.LOGS_READY) {
        const identifierString = identifierToString(identifier);

        return {
          ...state,
          [identifierString]: {
            ...state[identifierString],
            loading: false,
          },
        };
      }

      return {
        ...state,
      };
    }

    case constants.SOCKET_SEND: {
      const { identifier, command, data } = action.payload;

      if (identifier && command && data && data.action === socketConstants.GET_LOGS) {

        const identifierString = identifierToString(identifier);

        return {
          ...state,
          [identifierString]: {
            ...state[identifierString],
            loading: true,
          },
        };
      }

      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

export default websocketsReducer;
