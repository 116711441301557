import { Input } from 'semantic-ui-react';
import styled from 'styled-components';

import colors from 'themes/colors';

const iconWidth = '2em';

export const StyledInput = styled(Input)`
  &.ui.input input {
    width: 100%;
    padding: 5px 0;
  }

  &.ui.left.icon.input {
    input {
      padding-right: 3em!important;
      border-radius: .28571429rem!important;
      padding-left: 0.5em!important;
    }

    input:read-only {
      width: 0;
      padding: 0!important;
      border: none;
    }

    & .ui.icon.primary.button {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      padding: 0 5px;
    }

    > i.icon {
      width: ${iconWidth};
    }
  }
`;

export const StyledValue = styled.span`
  display: ${({ readOnly }) => (readOnly ? 'inline-block' : 'none')};
  font-style: ${({ empty }) => (empty ? 'italic' : 'normal')};
  padding: 5px 0.5em;
  border: 1px dashed transparent;
  margin-top: 1px;

  &:hover {
    border-color: ${({ locked }) => !locked && colors.black};
  }
`;
