import { compose } from 'redux';
import { connect } from 'react-redux';

import { currentUserSelector } from 'helpers/selectors';
import SecretKey from './SecretKey';

const mapStateToProps = state => {
  const { twoFactorEnabled } = currentUserSelector(state);

  return {
    twoFactorEnabled,
  };
};

export default compose(
  connect(mapStateToProps),
)(SecretKey);
