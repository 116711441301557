import React from 'react';

import ConfirmModal from 'components/elements/ConfirmModal/ConfirmModal';
import { FixedWidthButton } from './SettingConfirm.style';

const SettingConfirm = props => (
  <ConfirmModal
    {...props}
    confirmButton={<FixedWidthButton primary>Yes</FixedWidthButton>}
    cancelButton={<FixedWidthButton primary basic>Cancel</FixedWidthButton>}
  />
);

SettingConfirm.propTypes = {
  ...ConfirmModal.propTypes,
};

export default SettingConfirm;
