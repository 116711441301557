import styled, { css } from 'styled-components';
import { Popup, Menu } from 'semantic-ui-react';
import get from 'lodash/get';

import { mediaMax } from 'themes/mixins';
import filterProps from 'helpers/filterProps';

export const StyledPopup = styled(Popup)`
  &&& {
    padding: 0;

    &.ui.popup {
      min-width: 400px;
    }

    ${mediaMax.sm`
      min-width: unset !important;
      max-width: unset !important;
      transform: none !important;
      top: unset !important;
      bottom: 0 !important;
      right: 0 !important;
      position: fixed !important;

      &::before {
        display: none !important;
      }
    `};
  }
`;

export const StyledMenu = styled(Menu)`
  &&& {
    box-shadow: none;
    border: none;

    &.ui.vertical.menu .item {
      display: flex !important;
      justify-content: flex-start;
      align-items: center;
    }
  }
`;

const MenuItem = filterProps(
  ['header', 'line', 'footer'],
)(Menu.Item);

export const StyledMenuItem = styled(MenuItem)`
  ${({ header }) => header && css`
    box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%) !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    border-bottom: 1px solid rgba(34,36,38,.15);

    svg {
     &:hover {
       cursor: pointer;
     } 
    }
  `}

  ${({ footer }) => footer && css`
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  `}
`;

export const ViewAllLink = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-left: 8px;
  }

  ${({ theme }) => css`
    cursor: pointer;
    color: ${get(theme, 'palette.primaryColor')} !important;
    text-align: center;

    &:hover {
      text-decoration: underline;
    }
  `}
`;

export const LoadMoreLink = styled.div`
  width: 100%;
  border-top: 1px solid rgba(34,36,38,.15);
  padding: 12px 0;

  ${({ theme }) => css`
    cursor: pointer;
    color: ${get(theme, 'palette.primaryColor')} !important;
    text-align: center;

    &:hover {
      text-decoration: underline;
    }
  `}

  ${({ isLoading }) => isLoading && css`
    pointer-events: none;
    color: rgba(0, 0, 0, 0.4) !important;
    cursor: no-drop;
  `}

`;

export const Title = styled.h4`
  margin: 0;
  font-size: 17px;
`;

export const IconContainer = styled.div`
  cursor: pointer;
  padding: 8px;
  border-radius: 6px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);

    svg {
      color: unset !important;
    }
  }

  svg {
    color: #9F9F9F !important;
  }
`;

export const HasNotificationsIcon = styled.div`
  width: 12px;
  height: 12px;
  background-color: #D0021B;
  border-radius: 50%;
  position: absolute;
  top: 34px;
  right: 22px;
  border: 2px solid white;
  margin: 0;
  padding: 0;  
`;

export const Content = styled.div`
  max-height: 60vh;
  overflow: auto !important;

  ${mediaMax.sm`
    max-height: 70vh;
    overflow: auto;
  `};
`;

export const Overlay = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(0,0,0,0.6);
  overflow: hidden;
  z-index: 1;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Option = styled.small`
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: rgba(0,0,0,.6) !important;
  
  svg {
    margin-left: 4px;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export default StyledPopup;
