import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/atomic';
import ConfirmModal from 'components/elements/ConfirmModal/ConfirmModal';
import { getDisplayName } from 'helpers/sharedMethods';

const withDeleteSchedule = WrappedComponent => {
  const WithDeleteSchedule = props => {
    const {
      item: { id, name, lockVersion },
      automatedOperationId,
      onDeleteSchedule,
      passesPermissions,
      disableDelete,
      toggleDeleteButtons,
    } = props;

    const trigger = <WrappedComponent onClick={toggleDeleteButtons} disabled={disableDelete} title="Delete" />;
    const handleConfirm = () => {
      toggleDeleteButtons();
      onDeleteSchedule(
        id,
        lockVersion,
        automatedOperationId,
        passesPermissions,
      );
    };

    return (
      <ConfirmModal
        trigger={trigger}
        cancelButton="Cancel"
        confirmButton={<Button negative primary={false}>Delete Schedule</Button>}
        content={(
          <div>
            <p>
              Are you sure you want to delete schedule <strong>{name}</strong>?
              <br />This delete operation cannot be undone.
            </p>
          </div>
        )}
        header="Confirm schedule deletion"
        onConfirm={handleConfirm}
      />
    );
  };

  WithDeleteSchedule.defaultProps = {
    passesPermissions: false,
    toggleDeleteButtons: null,
  };

  WithDeleteSchedule.propTypes = {
    onDeleteSchedule: PropTypes.func.isRequired,
    item: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      lockVersion: PropTypes.string.isRequired,
    }).isRequired,
    automatedOperationId: PropTypes.string.isRequired,
    passesPermissions: PropTypes.bool,
    toggleDeleteButtons: PropTypes.func,
    disableDelete: PropTypes.bool.isRequired,
  };

  WithDeleteSchedule.displayName = `WithDeleteSchedule(${getDisplayName(WrappedComponent)})`;

  return WithDeleteSchedule;

};

export default withDeleteSchedule;
