import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Grid } from 'semantic-ui-react';

import Form from 'components/form/Form/Form';
import * as validator from 'components/form/formValidators';
import TextField from 'components/form/TextField/TextField';
import FormErrors from 'components/form/FormErrors/FormErrors';
import { Button, StyledRightFormField } from 'components/atomic';

const SignUpForm = ({ handleSubmit, error, submitting }) => (
  <Form onSubmit={handleSubmit} noValidate>
    <FormErrors error={error} />

    <Grid>
      <Grid.Row verticalAlign="middle">
        <Grid.Column mobile={16}>
          <Form.Group>
            <Field
              name="firstName"
              iconPosition="left"
              icon="user"
              loading={submitting}
              component={TextField}
              type="text"
              validate={validator.validName2AndSpaces}
              placeholder="First name"
            />
          </Form.Group>
        </Grid.Column>
        <Grid.Column mobile={16}>
          <Form.Group>
            <Field
              name="lastName"
              iconPosition="left"
              icon="user"
              loading={submitting}
              component={TextField}
              type="text"
              validate={validator.validName2AndSpaces}
              placeholder="Last name"
            />
          </Form.Group>
        </Grid.Column>
        <Grid.Column mobile={16}>
          <Form.Group>
            <Field
              name="phoneNumber"
              icon="phone"
              iconPosition="left"
              loading={submitting}
              component={TextField}
              type="tel"
              placeholder="Phone number"
            />
          </Form.Group>
        </Grid.Column>
        <Grid.Column mobile={16}>
          <Form.Group>
            <Field
              name="email"
              icon="mail"
              iconPosition="left"
              disabled
              loading={submitting}
              component={TextField}
              type="email"
              placeholder="Email address"
            />
          </Form.Group>
        </Grid.Column>
        <Grid.Column mobile={16}>
          <Form.Group>
            <Field
              name="password"
              iconPosition="left"
              icon="privacy"
              loading={submitting}
              component={TextField}
              type="password"
              validate={validator.validPassword}
              placeholder="Password"
            />
          </Form.Group>
        </Grid.Column>
        <Grid.Column mobile={16} textAlign="right">
          <Form.Group omitError>
            <StyledRightFormField>
              <Button primary type="submit" disabled={submitting}>
                Sign up
              </Button>
            </StyledRightFormField>
          </Form.Group>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Form>
);

SignUpForm.defaultProps = {
  submitting: false,
  error: undefined,
};

SignUpForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.array,
  submitting: PropTypes.bool,
};

export default SignUpForm;
