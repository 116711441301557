import React from 'react';
import PropTypes from 'prop-types';

import NarrowContainer from 'components/NarrowContainer/NarrowContainer.style';

import Container, { StyledButton } from './ServiceWorkerAlert.style';

export default function ServiceWorkerAlert({
  currentUserId,
  isUpdated,
  registration,
}) {
  const updateServiceWorker = () => {
    if (!registration) {
      return;
    }

    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', e => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });

      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  };

  if (!isUpdated) {
    return null;
  }

  return (
    <Container padded={!!currentUserId}>
      <NarrowContainer>
        A new version of the app is available.
        <StyledButton onClick={updateServiceWorker} size="mini">
          Update!
        </StyledButton>
      </NarrowContainer>
    </Container>
  );
}

ServiceWorkerAlert.defaultProps = {
  currentUserId: null,
  registration: null,
};

ServiceWorkerAlert.propTypes = {
  currentUserId: PropTypes.string,
  isUpdated: PropTypes.bool.isRequired,
  registration: PropTypes.object,
};
