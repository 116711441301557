import has from 'lodash/has';

const isOpenSelector = (state, resourceId) => {
  const { isOpen } = state.permissionsTable;

  if (!has(isOpen, resourceId)) {
    return null;
  }

  return isOpen[resourceId];
};

export default isOpenSelector;
