import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import { PROJECTS_FIELD } from 'helpers/selectors';
import { AddUsersModal } from '../../../../../../../components';
import {
  ContextProjectPermissionsGridWrapper,
  ContextDevicePermissionsGridWrapper,
  ContextUserPermissionsGridWrapper,
  ContextProjectSuitePermissionsGridWrapper,
} from './containers/wrapper/PermissionsGridWrapper';

const PermissionsContextWrapper = ({ contextId }) => (
  <Grid>
    <Grid.Row>
      <Grid.Column>
        <AddUsersModal
          resourceId={contextId}
          resourceType={PROJECTS_FIELD}
        />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>
        <ContextProjectPermissionsGridWrapper
          projectId={contextId}
          header="h3"
        />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>
        <ContextProjectSuitePermissionsGridWrapper
          projectSuiteId={contextId}
          projectId={contextId}
          header="h3"
        />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>
        <ContextDevicePermissionsGridWrapper
          deviceId={contextId}
          projectId={contextId}
          header="h3"
        />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>
        <ContextUserPermissionsGridWrapper
          wrapperUserId={contextId}
          projectId={contextId}
          header="h3"
        />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

PermissionsContextWrapper.propTypes = {
  contextId: PropTypes.string.isRequired,
};

export default PermissionsContextWrapper;
