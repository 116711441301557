import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const submitOneTimePassword = ({ values, resolveForm }) => ({
  type: constants.SUBMIT_ONE_TIME_PASSWORD,
  payload: {
    values,
    resolveForm,
    endpoint: endpoints.ONE_TIME_PASSWORD,
  },
});

export const submitOneTimePasswordSuccess = response => ({
  type: constants.SUBMIT_ONE_TIME_PASSWORD_SUCCESS,
  payload: { response },
});

export const submitOneTimePasswordFailure = error => ({
  type: constants.SUBMIT_ONE_TIME_PASSWORD_FAILED,
  payload: { error },
});
