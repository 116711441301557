import styled from 'styled-components';

export const StyledDateWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 145px;
  justify-content: space-between;
  align-items: center;
  height: auto;

  @media only screen and (max-width: 396px) {
    width: 120px;
  }

  & .rw-widget-input.rw-input {
    display: none;
  }

  & .ui.icon.button, .ui.icon.buttons .button {
    padding: 0.7em 0.9em;
  }

  & .rw-btn.rw-btn-select {
    width: 28px;
    height: 30px;
  }

  & .rw-popup-container {
    @media only screen and (max-width: 1028px) {
      left: -216px;
    }

    @media only screen and (max-width: 396px) {
      left: -116px;
    }
  }
`;

export const StyledPlaceholder = styled.div`
  margin-top: 6px;
`;

export default StyledDateWrapper;
