import { compose } from 'redux';
import { connect } from 'react-redux';

import performActionActionCreators from 'pages/Device/performActionActionCreators';
import ZoneTableRow from './ZoneTableRow';

const mapDispatchToProps = {
  onPerformAction: performActionActionCreators.performActionRequest,
};

export default compose(
  connect(null, mapDispatchToProps),
)(ZoneTableRow);
