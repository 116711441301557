import React from 'react';
import PropTypes from 'prop-types';

import { RemoteDeviceSubTableCustomRow, StyledDataTable } from './components';

const labels = [
  { text: 'Serial Number', field: 'serial', width: 8 },
  { text: 'Status', field: 'status', width: 8 },
];

const RemoteDevicesSubTable = ({ item: { devices } }) => (
  <StyledDataTable
    labels={labels}
    data={devices}
    customTableRow={RemoteDeviceSubTableCustomRow}
    pagination={{}}
  />
);

RemoteDevicesSubTable.defaultProps = {
  item: {},
};

RemoteDevicesSubTable.propTypes = {
  item: PropTypes.object,
};

export default RemoteDevicesSubTable;
