const getCumulatedPermissionsWithRecipientsMap = ({
  originalCumulatedPermissions,
  resourceType,
  context,
}) => {
  const keys = Object.keys(originalCumulatedPermissions);

  const transformed = keys.reduce((acc, permissionName) => {
    acc[permissionName] = {};

    const { resources = [] } = originalCumulatedPermissions[permissionName];

    let foundResource = null;
    let resourceFilter = null;

    if (context === 'tenant') {
      resourceFilter = resource => resource.resource_type === resourceType
      && resource.resource_id === '*';

    } else {
      resourceFilter = resource => resource.resource_type === resourceType;
    }

    foundResource = resources.find(resourceFilter);
    const { recipients = [] } = foundResource || {};

    recipients.forEach(recipient => {
      acc[permissionName][recipient.recipient_id] = recipient.allowed;
    });

    return acc;
  }, {});

  return transformed;
};

export default getCumulatedPermissionsWithRecipientsMap;
