import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import { ZONES_FIELD } from 'helpers/selectors';

const ZonesSectionActionCreators = {
  fetchZones: (deviceId, urlParams = {}) => ({
    type: constants.FETCH_ZONES_REQUEST,
    payload: {
      deviceId,
      endpoint: endpoints.ZONES(deviceId),
      urlParams,
    },
  }),

  fetchZonesFailure: error => ({
    type: constants.FETCH_ZONES_FAILED,
    payload: {
      info: !error.unauthorized && error.notFound && messages.NO_ZONES,
      error: !error.notFound && error,
      resourceType: ZONES_FIELD,
    },
  }),

  fetchZonesSuccess: response => ({
    type: constants.FETCH_ZONES_SUCCESS,
    payload: { response },
  }),

  fetchZonesSuccessPaginated: response => ({
    type: constants.FETCH_ZONES_PAGINATED,
    payload: { response },
  }),
};

export default ZonesSectionActionCreators;
