import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import Accordion from './Accordion.style';

class Collapse extends Component {

  state = {
    collapsed: true,
  }

  handleClick = () => {
    this.setState(state => ({ collapsed: !state.collapsed }));
  }

  render() {
    const { title, children } = this.props;
    const { collapsed } = this.state;
    return (
      <Accordion styled>
        <Accordion.Title active={!collapsed} index={0} onClick={this.handleClick}>
          <Icon name="dropdown" />
          {title}
        </Accordion.Title>
        <Accordion.Content active={!collapsed}>
          {!collapsed && children}
        </Accordion.Content>
      </Accordion>
    );
  }

}

Collapse.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default Collapse;
