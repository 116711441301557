import isNil from 'lodash/isNil';

const computeFilterKeyValue = (value, filterParams, key) => {
  if (isNil(value) || value.length === 0) {
    return {
      ...filterParams,
      [key]: null,
    };
  }

  if (value.length > 0) {
    return {
      ...filterParams,
      [key]: value,
    };
  }

  return { ...filterParams };
};

export default computeFilterKeyValue;
