export default class LumenboxCreator {

  constructor(sixeyeFirmwareVersion, tabs) {
    this.model = 'Lumenbox';
    this.sixeyeFirmwareVersion = sixeyeFirmwareVersion;
    this.manufacturerSDK = 'lumenpulse';
    this.tabs = tabs;
  }

}
