import endpoints from 'data/api/endpoints';

import { CP_PAGEABLE_TYPE_PROJECT, CP_PAGEABLE_TYPE_PROJECT_SUITE } from '../constants';

const getControlUIEndpoint = (cpPageableId, cpPageableType = CP_PAGEABLE_TYPE_PROJECT) => {
  switch (cpPageableType) {
    case CP_PAGEABLE_TYPE_PROJECT:
      return endpoints.PROJECT_CONTROL_UI(cpPageableId);
    case CP_PAGEABLE_TYPE_PROJECT_SUITE:
      return endpoints.PROJECT_SUITE_CONTROL_UI(cpPageableId);
    default:
      return endpoints.PROJECT_CONTROL_UI(cpPageableId);
  }
};

export default getControlUIEndpoint;
