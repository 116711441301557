import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSwipe from 'react-swipe';
import isEmpty from 'lodash/isEmpty';
import { Grid } from 'semantic-ui-react';

import {
  ControlUIPage,
  ControlUIEmptyMessage,
  ControlUILoading,
  ConfigurationPanel,
  EnableControlPanel,
} from './components';
import { StyledSwipe } from './ControlUIPageCarousel.style';

const swipeStyle = configure => {
  if (configure) {
    return ReactSwipe.defaultProps.style;
  }

  return {
    container: {
      height: '100%',
    },
    wrapper: {
      height: '100%',
    },
  };
};

class ControlUIPageCarousel extends Component {

  constructor() {
    super();

    this.node = null;
  }

  componentDidUpdate(prevProps) {
    const { pageId, pageIds } = this.props;

    if (!this.node) {
      return;
    }

    if (prevProps.pageId === pageId) {
      return;
    }

    if (Math.abs(prevProps.pageId - pageId) > 1) {
      const pageIndex = pageIds.findIndex(id => id === pageId);

      this.node.slide(pageIndex, 300);
      return;
    }

    if (pageId > prevProps.pageId) {
      this.node.next();
      return;
    }

    if (pageId < prevProps.pageId) {
      this.node.prev();
    }

  }

  render() {
    const {
      configure,
      pageId,
      pageIds,
      pagesLoading,
      onPageChange,
      adding,
      onAddPage,
    } = this.props;
    const style = swipeStyle(configure);
    const total = pageIds.length;

    if (pagesLoading) {
      return <ControlUILoading />;
    }

    if (!pageIds || isEmpty(pageIds)) {
      return (
        <>
          <ControlUIEmptyMessage />
          <EnableControlPanel adding={adding} onAddPage={onAddPage} />
        </>
      );
    }

    if (configure) {
      return (
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} computer={4}>
              <ConfigurationPanel
                configure={configure}
                pageId={pageId}
                onPageChange={onPageChange}
                adding={adding}
                onAddPage={onAddPage}
                total={total}
              />
            </Grid.Column>
            <Grid.Column mobile={16} computer={12}>
              <StyledSwipe configure={configure}>
                <ReactSwipe
                  className="carousel"
                  childCount={pageIds.length}
                  style={style}
                  swipeOptions={{
                    continuous: false,
                    disableScroll: true,
                  }}
                  ref={node => { this.node = node; }}
                >
                  {pageIds.map(pageIndex => (
                    <ControlUIPage
                      key={pageIndex}
                      configure={configure}
                      pageId={pageIndex}
                      currentPageId={pageId}
                    />
                  ))}
                </ReactSwipe>
              </StyledSwipe>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    }

    return (
      <StyledSwipe configure={configure}>
        <ReactSwipe
          className="carousel"
          childCount={pageIds.length}
          style={style}
          swipeOptions={{
            continuous: false,
            disableScroll: true,
          }}
          ref={node => { this.node = node; }}
        >
          {pageIds.map(pageIndex => (
            <ControlUIPage
              key={pageIndex}
              configure={configure}
              pageId={pageIndex}
              currentPageId={pageId}
            />
          ))}
        </ReactSwipe>
      </StyledSwipe>
    );
  }

}

ControlUIPageCarousel.defaultProps = {
  pageId: undefined,
  adding: false,
};

ControlUIPageCarousel.propTypes = {
  configure: PropTypes.bool.isRequired,
  pageIds: PropTypes.array.isRequired,
  pageId: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  pagesLoading: PropTypes.bool.isRequired,
  onAddPage: PropTypes.func.isRequired,
  adding: PropTypes.bool,
};

export default ControlUIPageCarousel;
