import Raven from 'raven-js';
import RavenConsolePlugin from 'raven-js/plugins/console';

import { reduce } from 'helpers/DataWithSizeThreshold';
import { asciiStringSizeInKB } from 'helpers/sharedMethods';

const sentryDataSizeThresholdInKB = 27;
const dataThresholdProportions = {
  RESPONSE: 10,
  DATA: 75,
  USER: 15,
};

const { NODE_ENV } = process.env;
if (NODE_ENV !== 'development') {
  const {
    REACT_APP_ENV,
    REACT_APP_SENTRY_URL,
    REACT_APP_REPO_NAME,
    REACT_APP_COMMIT_ID,
    REACT_APP_BRANCH,
    REACT_APP_VERSION,
  } = process.env;

  Raven
    .config(REACT_APP_SENTRY_URL, {
      release: REACT_APP_VERSION || REACT_APP_COMMIT_ID,
      environment: REACT_APP_ENV,
      tags: {
        repo: REACT_APP_REPO_NAME,
        branch: REACT_APP_BRANCH,
      },
      dataCallback: ({
        extra: {
          lastAction: {
            payload: {
              response,
              ...restOfPayload
            } = {},
            ...restOfAction
          } = {},
          state: {
            data,
            ...restOfState
          } = {},
          ...restOfExtra
        },
        user: {
          email,
          id,
          lastActionTime,
          lastLoginTime,
          ...restOfUser
        },
        ...restOfData
      }) => {
        const fixedSentryDataSize = asciiStringSizeInKB(restOfData)
          + asciiStringSizeInKB(restOfExtra)
          + asciiStringSizeInKB(restOfAction)
          + asciiStringSizeInKB(restOfPayload)
          + asciiStringSizeInKB(restOfState)
          + asciiStringSizeInKB({
            email,
            id,
            lastActionTime,
            lastLoginTime,
          });

        const remainingAllowance = sentryDataSizeThresholdInKB - fixedSentryDataSize;

        const lastActionSentryPayload = {
          ...restOfAction,
          payload: {
            ...restOfPayload,
            response: reduce(response, remainingAllowance, dataThresholdProportions.RESPONSE),
          },
        };

        const stateSentryPayload = {
          ...restOfState,
          data: reduce(data, remainingAllowance, dataThresholdProportions.DATA),
        };

        const userSentryPayload = {
          email,
          id,
          lastActionTime,
          lastLoginTime,
          ...reduce(restOfUser, remainingAllowance, dataThresholdProportions.USER),
        };

        return {
          extra: {
            lastAction: lastActionSentryPayload,
            state: stateSentryPayload,
            ...restOfExtra,
          },
          user: userSentryPayload,
          ...restOfData,
        };
      },
    })
    .addPlugin(RavenConsolePlugin)
    .install();
}
