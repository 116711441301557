import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { frontendPermissions, permissionsPass } from 'gateway';
import { singleProjectSelector } from 'helpers/selectors';
import SitePropertyInlineEdit from './SitePropertyInlineEdit';

export const mapStateToProps = (state, { match, property }) => {
  const { id: siteId } = match.params;
  const { [property]: propertyValue } = singleProjectSelector(state, siteId);

  return {
    siteId,
    propertyValue,
  };
};

export const mapDispatchToProps = (dispatch, { onChange }) => ({
  onChangeProperty: args => dispatch(onChange(args)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  permissionsPass(frontendPermissions.EDIT_PROJECT),
)(SitePropertyInlineEdit);
