import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { ChildPanelHeader } from '..';

import { StyledTab } from './TabbedPanel.style';

function TabbedPanel({ tabs, defaultActiveIndex }) {
  if (isEmpty(tabs)) {
    return null;
  }

  const panes = tabs.map(({ title, description, content }) => ({
    menuItem: title,
    render: () => (
      <div key={title}>
        <ChildPanelHeader
          title={title}
          description={description}
          withPadding
        />
        {content}
      </div>
    ),
  }));

  return (
    <StyledTab
      menu={{ attached: 'bottom' }}
      panes={panes}
      defaultActiveIndex={defaultActiveIndex}
    />
  );
}

TabbedPanel.defaultProps = {
  tabs: [],
  defaultActiveIndex: 0,
};

TabbedPanel.propTypes = {
  tabs: PropTypes.array,
  defaultActiveIndex: PropTypes.number,
};

export default TabbedPanel;
