import { call, put, select } from 'redux-saga/effects';

import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import { uploadsFilesSelector } from 'helpers/uploadsSelectors';
import notificationsActionCreators from 'data/notifications/notificationsActionCreators';
import uploadsActionCreators from 'components/UploadsWidget/uploadsActionCreators';

export function* filterOutRunningUploads({ fileKey }) {
  const { [fileKey]: runningUpload } = yield select(uploadsFilesSelector);

  if (runningUpload) {
    return true;
  }

  return false;
}

function* uploadFirmwareFileSuccess(action) {
  const {
    params: {
      data: {
        attributes: {
          s3FilePath,
        },
      },
      endpoint,
      systemFiles,
      meta: {
        [endpoint]: {
          meta: {
            awsAccessKey,
            awsBucketName,
          },
        },
      },
    },
  } = action.payload;

  const fileWithAttributes = {
    fileKey: `${awsBucketName}/${s3FilePath}`,
    accessKeyId: awsAccessKey,
    bucket: awsBucketName,
    path: s3FilePath,
    systemFile: systemFiles[0],
    device: 'N/A',
    project: 'N/A',
  };

  const running = yield call(filterOutRunningUploads, fileWithAttributes);
  if (running) {
    yield put(
      notificationsActionCreators.addInfoNotification(
        messages.UPLOAD_OMITTED([fileWithAttributes]),
      ),
    );
  }

  yield put(uploadsActionCreators.enqueueUploadAtEnd([{
    ...fileWithAttributes,
    fileTypeId: 'deviceFirmwareFiles',
    setSignerUrl: endpoints.DEVICE_FIRMWARE_FILES_SIGN,
  }]));
}

export default uploadFirmwareFileSuccess;
