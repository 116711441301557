import React from 'react';
import PropTypes from 'prop-types';

import * as validators from 'components/form/formValidators';
import { TipIcon, FieldWrapper } from 'components/atomic';

import { DropdownMultiFieldInput } from './containers';
import { StyledField, StyledLabel } from './DropdownMultiActionField.style';

const DropdownMultiActionField = ({
  name,
  label,
  uiType,
  optionList,
  maxCount,
  editMode,
  tip,
  tipText,
  loading,
}) => {
  const options = [];
  const displayLabel = optionList.length === 0 ? 'No options available' : label;
  const labelText = editMode ? '' : displayLabel;
  const maxCountValue = maxCount || 10000;

  switch (uiType) {
    case 'triggerIds':
      options.push(...optionList);
      break;
    case 'timelineIds':
      options.push(...optionList);
      break;
    case 'sceneIds':
      options.push(...optionList);
      break;
    case 'groupIds':
      options.push(...optionList);
      break;
    default:
      options.push({
        key: 'not_found',
        text: 'Error. Options not found',
        value: 'not_found',
      });
  }

  const renderTipIcon = () => {
    if (!tip) {
      return null;
    }

    return <TipIcon message={tipText} editMode={editMode} position="left center" />;
  };

  return (
    <FieldWrapper>
      <StyledLabel editMode={editMode} htmlFor="input">{labelText} {renderTipIcon()}</StyledLabel>
      <StyledField
        name={name}
        placeholder={displayLabel}
        component={DropdownMultiFieldInput}
        options={options}
        loading={loading}
        maxCount={maxCountValue}
        validate={validators.requiredArray}
        editMode={editMode}
      />
    </FieldWrapper>
  );
};

DropdownMultiActionField.defaultProps = {
  editMode: false,
  tip: null,
  loading: false,
  tipText: null,
};

DropdownMultiActionField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  uiType: PropTypes.string.isRequired,
  optionList: PropTypes.array.isRequired,
  maxCount: PropTypes.number.isRequired,
  editMode: PropTypes.bool,
  tip: PropTypes.string,
  tipText: PropTypes.string,
  loading: PropTypes.bool,
};

export default DropdownMultiActionField;
