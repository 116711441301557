import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import { Grid, Segment, Header } from 'semantic-ui-react';
import { RiUserSearchLine } from 'react-icons/ri';

import { usePrevious } from 'helpers/hooks';
import { DropdownArrow } from 'components/elements';
import {
  TablePlaceholder,
  TableHeader,
  TableBody,
  RecipientSelect,
} from './components';

import {
  StyledHeader,
  StyledTable,
} from './PermissionsTable.style';

function PermissionsTableWrapper({
  isLoading,
  isOpen,
  cumulatedPermissions,
  permissionsTableRows,
  recipients,
  selectedRecipients,
  copyRecipientId,
  resourceId,
  parentResourceId,
  childResourceIds,
  resourceType,
  limitedScope,
  limitedFor,
  context,
  recipientType,
  withRecipientSelect,
  header,
  showHeader,
  resourceTitle,
  resourceName,
  resourceDescription,
  onFetchCumulatedPermissions,
  onGrantPermissions,
  onRevokePermissions,
  onCopyPermissions,
  onPastePermissions,
  onClearPermissionsTable,
  onToggleResourceOpen,
  onChangeRecipients,
}) {
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const previousSelectedRecipients = usePrevious(selectedRecipients);

  useEffect(() => {
    if (!isFirstLoad) {
      return;
    }

    if (selectedRecipients && !isEmpty(selectedRecipients) && !isNull(isOpen)) {
      onFetchCumulatedPermissions({
        recipientIds: selectedRecipients,
        recipientType,
        resourceType,
        resourceName,
        resourceId,
        limitedScope,
        limitedFor,
        context,
      });

      setIsFirstLoad(false);
    }
  }, [
    isOpen,
    isFirstLoad,
    recipientType,
    resourceId,
    resourceType,
    resourceName,
    limitedScope,
    limitedFor,
    context,
    selectedRecipients,
    onFetchCumulatedPermissions,
  ]);

  const handleRecipientsChanged = useCallback(newSelectedRecipients => {
    if (isEmpty(newSelectedRecipients)) {
      onClearPermissionsTable({
        resourceId,
        parentResourceId,
        childResourceIds,
      });

      return;
    }

    onChangeRecipients({
      recipientIds: newSelectedRecipients,
      resourceId,
      parentResourceId,
      childResourceIds,
    });
  }, [
    resourceId,
    parentResourceId,
    childResourceIds,
    onClearPermissionsTable,
    onChangeRecipients,
  ]);

  useEffect(() => {
    if (!selectedRecipients || isEmpty(selectedRecipients)) {
      return;
    }

    if (selectedRecipients !== previousSelectedRecipients && !isNull(isOpen)) {
      onFetchCumulatedPermissions({
        recipientIds: selectedRecipients,
        recipientType,
        resourceType,
        resourceName,
        resourceId,
        limitedScope,
        limitedFor,
        context,
      });
    }
  }, [
    isOpen,
    selectedRecipients,
    previousSelectedRecipients,
    context,
    limitedFor,
    limitedScope,
    recipientType,
    resourceId,
    resourceType,
    resourceName,
    onFetchCumulatedPermissions,
  ]);

  const handleCopyPermissions = useCallback(recipientId => {
    onCopyPermissions(recipientId, resourceId);
  }, [onCopyPermissions, resourceId]);

  const handlePastePermissions = useCallback(params => {
    onPastePermissions({
      ...params,
      parentResourceId,
    });
  }, [parentResourceId, onPastePermissions]);

  const commonProps = useMemo(() => ({
    context,
    resourceId,
    parentResourceId,
    childResourceIds,
    resourceType,
    resourceName,
    limitedScope,
    limitedFor,
    copyRecipientId,
  }), [
    resourceId,
    parentResourceId,
    childResourceIds,
    resourceType,
    resourceName,
    limitedScope,
    limitedFor,
    context,
    copyRecipientId,
  ]);

  const formattedTitle = useMemo(
    () => `${resourceTitle}${resourceName ? ': ' : ''}${resourceName}`,
    [resourceTitle, resourceName],
  );

  return (
    <Grid>
      {showHeader && (
        <Grid.Row>
          <Grid.Column>
            <StyledHeader
              as={header}
              onClick={() => onToggleResourceOpen(resourceId)}
            >
              <DropdownArrow open={isNull(isOpen) ? false : isOpen} />
              {formattedTitle}
            </StyledHeader>
          </Grid.Column>
        </Grid.Row>
      )}
      {(isOpen || !showHeader) && (
        <>
          {withRecipientSelect && (
            <Grid.Row>
              <Grid.Column>
                <RecipientSelect
                  recipients={recipients}
                  selectedRecipients={selectedRecipients}
                  onChangeRecipients={handleRecipientsChanged}
                  recipientType={recipientType}
                />
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column>
              {isLoading && (
                <TablePlaceholder />
              )}
              {!isLoading && (
                <>
                  <StyledHeader as="h3">
                    {resourceDescription}
                  </StyledHeader>
                  {isEmpty(selectedRecipients) && (
                    <Segment placeholder>
                      <Header icon>
                        <RiUserSearchLine size="2em" /> <br />
                        No recipients selected
                      </Header>
                    </Segment>
                  )}
                  <StyledTable withSpacing={!!resourceDescription} width="auto">
                    <TableHeader
                      recipientType={recipientType}
                      recipients={recipients}
                      selectedRecipients={selectedRecipients}
                      onChangeRecipients={handleRecipientsChanged}
                      onCopyPermissions={handleCopyPermissions}
                      onPastePermissions={handlePastePermissions}
                      {...commonProps}
                    />
                    <TableBody
                      rows={permissionsTableRows}
                      cumulatedPermissions={cumulatedPermissions}
                      onGrantPermissions={onGrantPermissions}
                      onRevokePermissions={onRevokePermissions}
                      {...commonProps}
                    />
                  </StyledTable>
                </>
              )}
            </Grid.Column>
          </Grid.Row>
        </>
      )}
    </Grid>
  );
}

PermissionsTableWrapper.defaultProps = {
  isLoading: false,
  cumulatedPermissions: {},
  limitedScope: [],
  limitedFor: ['user'],
  recipientType: 'user',
  recipients: [],
  selectedRecipients: [],
  permissionsTableRows: [],
  parentResourceId: null,
  childResourceIds: null,
  context: null,
  copyRecipientId: null,
  withRecipientSelect: true,
  isOpen: false,
  showHeader: false,
  resourceTitle: null,
  resourceName: null,
  resourceDescription: '',
  header: 'h2',
};

PermissionsTableWrapper.propTypes = {
  isLoading: PropTypes.bool,
  context: PropTypes.string,
  resourceId: PropTypes.string.isRequired,
  resourceType: PropTypes.oneOf([
    'project',
    'project_suite',
    'logical_device',
    'user',
    'tenant',
  ]).isRequired,
  limitedScope: PropTypes.arrayOf(
    PropTypes.string,
  ),
  limitedFor: PropTypes.arrayOf(
    PropTypes.string,
  ),
  resourceTitle: PropTypes.string,
  resourceName: PropTypes.string,
  resourceDescription: PropTypes.string,
  header: PropTypes.string,
  copyRecipientId: PropTypes.string,
  recipientType: PropTypes.string,
  cumulatedPermissions: PropTypes.object,
  permissionsTableRows: PropTypes.array,
  recipients: PropTypes.array,
  selectedRecipients: PropTypes.array,
  parentResourceId: PropTypes.string,
  childResourceIds: PropTypes.array,
  isOpen: PropTypes.bool,
  withRecipientSelect: PropTypes.bool,
  showHeader: PropTypes.bool,
  onToggleResourceOpen: PropTypes.func.isRequired,
  onFetchCumulatedPermissions: PropTypes.func.isRequired,
  onGrantPermissions: PropTypes.func.isRequired,
  onRevokePermissions: PropTypes.func.isRequired,
  onCopyPermissions: PropTypes.func.isRequired,
  onPastePermissions: PropTypes.func.isRequired,
  onClearPermissionsTable: PropTypes.func.isRequired,
  onChangeRecipients: PropTypes.func.isRequired,
};

export default PermissionsTableWrapper;
