import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { singleProjectSelector, AUTOMATED_OPERATIONS_OCCURRENCES_FIELD } from 'helpers/selectors';
import { selectors as projectSuitesSelectors } from 'pages/ProjectSuites/utilities';
import { permissionsOrRedirect, frontendPermissions } from 'gateway';
import { PORTAL_CONTEXT_ID } from 'helpers/sharedVariables';
import { checkMultiSites, userHasPermissionByTypeOrSuperadmin } from './components';
import {
  actionCreators as schedulingActionCreators,
  selectors as schedulingSelectors,
} from '../../utilities';
import DayView from './DayView';

export const mapStateToProps = (state, { match }) => {
  const { id: siteId } = match.params;
  const { timezoneSet, datetime: siteTime } = singleProjectSelector(state, siteId);
  const updating = schedulingSelectors.schedulesUpdatingSelector(state);
  const selectedProjectSuites = projectSuitesSelectors.selectedProjectSuitesSelector(
    state, siteId, AUTOMATED_OPERATIONS_OCCURRENCES_FIELD,
  ) || [];
  const hideMultiSiteOccurrences = schedulingSelectors.showHideMultiSiteOccurrencesSelector(state);

  return {
    resourceId: siteId,
    resourceTime: siteTime,
    resourceType: 'project',
    timezoneSet,
    updating,
    selectedProjectSuites,
    showHidden: schedulingSelectors.showHiddenSelector(state),
    hideMultiSiteOccurrences,
  };
};

const mapDispatchToProps = {
  onFetchOccurrences: schedulingActionCreators.fetchOccurrences,
  onToggleShowHiddenFilter: schedulingActionCreators.toggleShowHiddenOccurrences,
  onToggleShowHideMultiSiteOccurrences: schedulingActionCreators.toggleShowHideMultiSiteOccurrences,
};

export default compose(
  withRouter,
  checkMultiSites(),
  connect(mapStateToProps, mapDispatchToProps),
  permissionsOrRedirect(frontendPermissions.VIEW_OCCURRENCES),
  userHasPermissionByTypeOrSuperadmin(
    frontendPermissions.RESOURCE_VIEW_ALL,
    'project_suite',
    PORTAL_CONTEXT_ID,
  ),
)(DayView);
