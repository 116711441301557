import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { Button, Form } from 'semantic-ui-react';

import { mediaMax } from 'themes/mixins';
import filterProps from 'helpers/filterProps';

export const FormContainer = styled.div`
  padding: 15px 0;

  ${mediaMax.sm`
    height: auto;
  `};
`;

export const StyledLabel = styled.label`
  display: block;
  margin: 0 0 .28571429rem 0;
  color: rgba(0,0,0,.87);
  font-size: .92857143em;
  font-weight: 700;
  text-transform: none;
`;

export const ColorPickerContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: 1em;
  }
`;

export const PreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85%;
  margin-top: 8px;
  border-radius: 3px;

  ${({ backgroundType }) => backgroundType === 'light' && css`
    background: #FFF;
  `}

  ${({ backgroundType }) => backgroundType === 'dark' && css`
    background: #000;
  `}

  ${mediaMax.sm`
    padding-top: 32px;
    padding-bottom: 32px;
    height: auto;
  `};
`;

export const EmptySlotPreview = styled(Button)`
  width: 80%;
  height: 250px;
  background: #FFFFFF !important;
  border: 1px dashed rgba(0,0,0, 0.2) !important;
  cursor: default !important;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mediaMax.sm`
    flex-direction: column;
    align-items: flex-start;

    h4 {
      margin-bottom: 8px !important;
    }
  `};
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const StyledFormField = styled(Form.Field)`
  margin-bottom: 0 !important;
`;

export const ControlsPreview = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LabelPreview = styled.h3`
  font-weight: 700;
  margin-bottom: 24px;

  ${({ labelTextColor: { r, g, b, a } }) => css`
    color: ${`rgba(${r}, ${g}, ${b}, ${a})`} !important;
  `}
`;

export const ColorWheelPreview = styled.div`
  width: 185px;
  height: 185px;
  border-radius: 50%;
  background-color: lightgrey;
`;

export const SlidersPreview = styled.div`
  width: 16px;
  height: 185px;
  background-color: lightgrey;
  border-radius: 4px;
  margin-left: 24px;
`;

const FilteredProps = filterProps([
  'bgColor',
  'textColor',
  'initialValues',
])(Button);

export const RunButtonPreview = styled(FilteredProps)`
  width: auto;
  height: 45px;

  ${({ bgColor: { r, g, b, a } }) => css`
    background: ${`rgba(${r}, ${g}, ${b}, ${a})`} !important;

    &:hover {
      background: ${darken(0.05, `rgba(${r}, ${g}, ${b}, ${a})`)} !important;
    }
  `}

  ${({ textColor: { r, g, b, a } }) => css`
    color: ${`rgba(${r}, ${g}, ${b}, ${a})`} !important;
  `}
`;

export default FormContainer;
