class IoModuleModel {

  constructor(ioModule = {}) {
    this.id = ioModule.id;
    this.label = ioModule.label;
  }

}

export default IoModuleModel;
