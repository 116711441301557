import { takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import fetchCurrentUser from './fetchCurrentUser';
import login from './login';
import logout from './logout';
import logoutDelete from './logoutDelete';

function* saga() {
  yield takeLatest(constants.FETCH_CURRENT_USER, fetchCurrentUser);
  yield takeLatest(constants.LOGIN, login);
  yield takeLatest(constants.LOGOUT, logoutDelete);
  yield takeLatest(constants.LOGOUT, logout);
}

export default saga;
