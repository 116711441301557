import { takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import fetchChildProjectsForMap from './fetchChildProjectsForMap';

function* saga() {
  yield takeLatest(constants.FETCH_CHILD_PROJECTS_FOR_MAP_REQUEST, fetchChildProjectsForMap);
}

export {
  saga,
};

export default saga;
