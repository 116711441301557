import { connect } from 'react-redux';
import {
  socketDeviceDataSubscriptionStatus,
} from 'helpers/selectors';
import websocketActionCreators from 'data/websockets/websocketsActionCreators';
import RefreshInterface from './RefreshInterface';

const mapStateToProps = (state, { deviceId, dataId }) => {
  const status = socketDeviceDataSubscriptionStatus(deviceId, dataId)(state) || {};

  return {
    isSubscribed: !!status.subscribed,
    isRefreshing: !!status.refreshing,
  };
};

const mapDispatchToProps = (dispatch, { deviceId, dataId, meta }) => ({
  onSubscribe: () => dispatch(websocketActionCreators.subscribeDeviceData(deviceId, dataId, meta)),
  onUnsubscribe: () => dispatch(
    websocketActionCreators.unsubscribeDeviceData(deviceId, dataId, meta),
  ),
  onRefresh: () => dispatch(websocketActionCreators.refreshDeviceData(deviceId, dataId, meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RefreshInterface);
