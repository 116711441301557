import { darken, saturate } from 'polished';

import sixEyeLogo from 'assets/logos/default/navbar-logo.svg';

import { breadcrumbs as commonBreadcrumbs } from 'navigation';
import colors from './colors';
import etcGreenTheme from './tenants/etcGreen';
import pharosBlueTheme from './tenants/pharosBlue';
import schrederOrangeTheme from './tenants/schrederOrange';
import pathwayRubyTheme from './tenants/pathwayRuby';
import tsldOrangeTheme from './tenants/tsldOrange';
import slOrangeTheme from './tenants/slOrange';
import alGreenTheme from './tenants/alGreen';
import bzBlueTheme from './tenants/bzBlue';
import lsYellowTheme from './tenants/lsYellow';
import msRedTheme from './tenants/msRed';
import stBlueTheme from './tenants/stBlue';
import spBlueTheme from './tenants/spBlue';
import lmGreyTheme from './tenants/lmGrey';
import arYellowTheme from './tenants/arYellow';
import acGreenTheme from './tenants/acGreen';
import mlGreenTheme from './tenants/mlGreen';
import aelGreenTheme from './tenants/aelGreen';
import sysPurpleTheme from './tenants/sysPurple';
import botPinkTheme from './tenants/botPink';
import lumGreenTheme from './tenants/lumGreen';
import eclipseBlueTheme from './tenants/eclipseBlue';
import smartltgGreenTheme from './tenants/smartltgGreen';
import achjapanRedTheme from './tenants/achjapanRed';
import cwlightingTheme from './tenants/cwlightingBlue';
import lumenCloudTheme from './tenants/lumenCloud';
import livingProjectsRedTheme from './tenants/livingProjectsRed';
import egeaBlueTheme from './tenants/egeaBlue';
import unbrandedTheme from './tenants/unbranded';
import lcsGreenTheme from './tenants/lcsGreen';
import mzlightBlueTheme from './tenants/mzlightBlue';
import acspecPurpleTheme from './tenants/acspecPurple';
import luxLightBlackTheme from './tenants/luxLightBlack';
import pixelumenlabAquaTheme from './tenants/pixelumenlabAqua';
import purelightGreenTheme from './tenants/purelightGreen';
import etcBlueTheme from './tenants/etcBlue';
import grandStageRedTheme from './tenants/grandStageRed';
import fluviusBlueTheme from './tenants/fluviusBlue';
import iotdeploymentBlueTheme from './tenants/iotdeploymentBlue';
import portlightingGreenTheme from './tenants/portlightingGreen';
import westviewBlueTheme from './tenants/westviewBlue';
import nanoltgGreyTheme from './tenants/nanoltgGrey';
import gslOrangeTheme from './tenants/gslOrange';

export default {
  etcGreen: etcGreenTheme,
  pharosBlue: pharosBlueTheme,
  schrederOrange: schrederOrangeTheme,
  pathwayRuby: pathwayRubyTheme,
  tsldOrange: tsldOrangeTheme,
  slOrange: slOrangeTheme,
  alGreen: alGreenTheme,
  bzBlue: bzBlueTheme,
  lsYellow: lsYellowTheme,
  msRed: msRedTheme,
  stBlue: stBlueTheme,
  spBlue: spBlueTheme,
  lmGrey: lmGreyTheme,
  arYellow: arYellowTheme,
  acGreen: acGreenTheme,
  mlGreen: mlGreenTheme,
  aelGreen: aelGreenTheme,
  sysPurple: sysPurpleTheme,
  botPink: botPinkTheme,
  lumGreen: lumGreenTheme,
  eclipseBlue: eclipseBlueTheme,
  smartltgGreen: smartltgGreenTheme,
  achjapanRed: achjapanRedTheme,
  cwlightingBlue: cwlightingTheme,
  lumenCloudBlue: lumenCloudTheme,
  livingProjectsRed: livingProjectsRedTheme,
  egeaBlue: egeaBlueTheme,
  unbranded: unbrandedTheme,
  lcsGreen: lcsGreenTheme,
  mzlightBlue: mzlightBlueTheme,
  acspecPurple: acspecPurpleTheme,
  luxLightBlack: luxLightBlackTheme,
  pixelumenlabAqua: pixelumenlabAquaTheme,
  purelightGreen: purelightGreenTheme,
  etcBlue: etcBlueTheme,
  grandStageRed: grandStageRedTheme,
  fluviusBlue: fluviusBlueTheme,
  iotdeploymentBlue: iotdeploymentBlueTheme,
  portlightingGreen: portlightingGreenTheme,
  westviewBlue: westviewBlueTheme,
  nanoltgGrey: nanoltgGreyTheme,
  gslOrange: gslOrangeTheme,
  defaultTheme: {
    palette: {
      primaryColor: colors.carrot,
      defaultOccurrenceColor: colors.white,
    },
    logo: {
      src: sixEyeLogo,
      alt: 'SixEye logo',
      text: 'SixEye',
    },
    favicons: [
      {
        href: '/favicons/default/favicon-16.png',
        sizes: '16x16',
      },
      {
        href: '/favicons/default/favicon-32.png',
        sizes: '32x32',
      },
    ],
    breadcrumbs: commonBreadcrumbs,
    updateNotificationPanel: {
      background: colors.carrot,
      color: colors.white,
      'border-color': colors.carrot,
    },
    sidebar: {
      '.ui.menu': {
        '.item': {
          color: colors.grey,
        },
      },
      '.ui.menu.vertical': {
        '.active.item': {
          color: colors.carrot,
        },
        md: {
          background: colors.concrete,
        },
      },
    },
    tables: {
      statusIcons: {
        '.model-device': {
          '&.online': {
            color: colors.mountainMeadow,
          },
          '&.offline': {
            color: colors.red,
          },
          '&.unassociated': {
            color: colors.tango,
          },
          '&.upgradeoffline': {
            color: colors.red,
          },
          '&.upgradeonline': {
            color: colors.carrot,
          },
        },
        '.model-user': {
          '&.suspended': {
            color: colors.red,
          },
          '&.invited': {
            color: colors.carrot,
          },
          '&.active': {
            color: colors.mountainMeadow,
          },
          '&.inactive': {
            color: colors.grey,
          },
        },
      },
    },
    buttons: {
      '.ui.button': {
        '&.primary': {
          'background-color': colors.carrot,
          color: colors.white,
          '&:hover': {
            'background-color': saturate(0.10, darken(0.05, colors.carrot)),
          },
          '&:focus': {
            'background-color': saturate(0.20, darken(0.08, colors.carrot)),
          },
          '&:active': {
            'background-color': darken(0.1, colors.carrot),
          },
          '&.active': {
            'background-color': saturate(0.15, darken(0.05, colors.carrot)),
          },
          '&.basic': {
            'background-color': colors.carrot,
            color: `${colors.carrot} !important`,
            'box-shadow': `0 0 0 1px ${colors.carrot} inset!important`,
            '&:hover': {
              'background-color': saturate(0.10, darken(0.05, colors.carrot)),
              color: saturate(0.10, darken(0.05, colors.carrot)),
            },
            '&:focus': {
              'background-color': saturate(0.20, darken(0.08, colors.carrot)),
              color: saturate(0.10, darken(0.05, colors.carrot)),
            },
            '&:active': {
              'background-color': darken(0.1, colors.carrot),
              color: darken(0.1, colors.carrot),
            },
            '&.active': {
              'background-color': saturate(0.15, darken(0.05, colors.carrot)),
              color: darken(0.1, colors.carrot),
            },
          },
        },
      },
    },
    links: {
      color: colors.carrot,
      '&:hover': {
        'text-decoration': 'underline',
      },
    },
    indeterminateCheckbox: {
      '&:after': {
        'border-left-color': colors.carrot,
      },
    },
  },
};
