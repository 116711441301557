function updateIndexes(task) {
  const {
    data: {
      attributes: {
        actions,
        ...attributes
      },
      ...data
    },
  } = task;

  const newActions = actions.map((item, index) => ({
    ...item,
    index: parseInt(index, 10),
  }));

  return {
    ...task,
    data: {
      ...data,
      attributes: {
        ...attributes,
        actions: newActions,
      },
    },
  };
}

export default updateIndexes;
