import styled from 'styled-components';
import { Table } from 'semantic-ui-react';

export const IoModuleStyledTableRow = styled(Table.Row)`
  cursor: pointer;
`;

export const IoModuleStyledTableCell = styled(Table.Cell)`
  padding-left: 10px !important;
`;

export const IoModuleStyledArrowCell = styled(Table.Cell)`
  width: 5% !important;

  @media only screen and (min-width: 1000px) {
    width: 0.5% !important;
  }
`;

export default IoModuleStyledTableRow;
