import isEmpty from 'lodash/isEmpty';

const prepareDataForJSONPreview = data => {
  if (!isEmpty(data) && Array.isArray(data)) {
    return data.map(item => ({ ...item, displayHTML: null }));
  }

  return data;
};

export default prepareDataForJSONPreview;
