import { put, select } from 'redux-saga/effects';

import selectors from '../selectors';
import actionCreators from '../actionCreators';
import { addNewPage } from './utilities';

function* addPage(action) {
  const { name, projectId, paginationIndex, pageType, ...rest } = action.payload;

  const {
    id,
    pages,
    ...attributes
  } = (yield select(selectors.projectControlUISelector))(projectId);

  const newPages = addNewPage(pages, name, paginationIndex, pageType);

  yield put(actionCreators.updateControlUI({
    id,
    pages: newPages,
    ...attributes,
    ...rest,
  }));
}

export default addPage;
