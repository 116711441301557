import constants from 'dispatcherConst';
import { uploadDequeue, uploadEnqueueAtEnd, uploadEnqueueAtStart } from './uploadsReducerHelpers';

const initialState = [];

const uploadsQueueReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case constants.UPLOAD_ENQUEUE_AT_START:
      return uploadEnqueueAtStart(state, payload);

    case constants.UPLOAD_ENQUEUE_AT_END:
      return uploadEnqueueAtEnd(state, payload);

    case constants.UPLOAD_START_SUCCESS:
    case constants.UPLOAD_PAUSE_SUCCESS:
    case constants.UPLOAD_CANCEL_SUCCESS:
    case constants.UPLOAD_FILE_NOT_FOUND:
    case constants.UPLOAD_SUCCESS:
      return uploadDequeue(state, payload);

    default:
      return state;
  }
};

export default uploadsQueueReducer;
