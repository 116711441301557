import React from 'react';

import { DEVICE_FIRMWARE_FILES_FIELD } from 'helpers/selectors';
import DataTable from 'components/DataTable/DataTableContainer';
import UploadFirmwareFile from '../UploadFirmwareFile/UploadFirmwareFileContainer';
import { FirmwareFileCustomRow } from './components';

const labels = [
  { text: 'Original file name', field: 'originalFileName' },
  { text: 'Description', field: 'description' },
  { text: 'Link with Firmware', field: '' },
];

const FirmwareFilesTable = () => (
  <DataTable
    header="Firmware files"
    labels={labels}
    createElement={UploadFirmwareFile}
    pagination={{
      type: DEVICE_FIRMWARE_FILES_FIELD,
      included: true,
    }}
    customTableRow={FirmwareFileCustomRow}
  />
);

export default FirmwareFilesTable;
