export const statusLabels = {
  UNASSOCIATED: {
    label: 'Unassociated',
    name: 'warning sign',
    status: 'unassociated',
  },
  ONLINE: {
    label: 'Online',
    name: 'circle',
    status: 'online',
  },
  OFFLINE: {
    label: 'Offline',
    name: 'circle',
    status: 'offline',
  },
  LOADING: {
    label: 'Loading',
    name: 'sync',
    status: 'loading',
    loading: true,
  },
  UPGRADE_ONLINE: {
    label: 'Online - upgrade required',
    name: 'circle',
    status: 'upgradeonline',
  },
  UPGRADE_OFFLINE: {
    label: 'Upgrade required',
    name: 'close',
    status: 'upgradeoffline',
  },
};

export default statusLabels;
