import { connect } from 'react-redux';

import { userEntityNotificationSelector } from 'helpers/selectors';
import notificationTypesActionCreators from 'components/NotificationTypes/notificationTypesActionCreators';
import NotificationToggle from './NotificationToggle';

export const mapStateToProps = (state, { userId, entityId, notificationTypeId, frequency }) => {
  const notification = userEntityNotificationSelector(state)(
    userId, entityId, notificationTypeId,
  );

  return {
    notification,
    status: notification ? notification.id : null,
    frequency,
  };
};

const mapDispatchToProps = {
  optInNotification: notificationTypesActionCreators.optInNotification,
  optOutNotification: notificationTypesActionCreators.optOutNotification,
  onEditNotification: notificationTypesActionCreators.editNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationToggle);
