import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import UserModel from 'models/UserModel';
import { currentUserSelector } from 'helpers/selectors';
import { selectors } from 'application/tenant/console/site/components/structural/SiteSubscriptionTab/utilities';

import Vouchers from './Vouchers';

export const mapStateToProps = state => {
  const currentUser = currentUserSelector(state);
  const { id, superadmin } = new UserModel(currentUser || {});

  return {
    isSuperAdmin: superadmin,
    canManageVouchers: selectors.canManageVouchersSelector(state, id),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
)(Vouchers);
