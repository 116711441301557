import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';

export const fetchDeviceTimelines = deviceId => ({
  type: constants.FETCH_DEVICE_TIMELINES_REQUEST,
  payload: {
    deviceId,
    endpoint: endpoints.TIMELINES(deviceId),
  },
});

export const fetchDeviceTimelinesSuccess = (response, deviceId) => ({
  type: constants.FETCH_DEVICE_TIMELINES_SUCCESS,
  payload: { response, deviceId },
});

export const fetchDeviceTimelinesFailure = error => ({
  type: constants.FETCH_DEVICE_TIMELINES_FAILED,
  payload: {
    info: !error.unauthorized && error.notFound && messages.NO_TIMELINES,
    error: !error.notFound && error,
  },
});
