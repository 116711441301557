import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';

export const transferDeviceFirmware = (deviceFirmwareId, logicalDeviceId) => ({
  type: constants.TRANSFER_DEVICE_FIRMWARE_REQUEST,
  payload: {
    endpoint: endpoints.DEVICE_FIRMWARES_TRANSFER(deviceFirmwareId, logicalDeviceId),
  },
});

export const transferDeviceFirmwareSuccess = response => ({
  type: constants.TRANSFER_DEVICE_FIRMWARE_SUCCESS,
  payload: {
    response,
    message: messages.TRANSFER_DEVICE_FIRMWARE_SUCCESS,
  },
});

export const transferDeviceFirmwareFailure = error => ({
  type: constants.TRANSFER_DEVICE_FIRMWARE_FAILED,
  payload: {
    error,
    message: messages.TRANSFER_DEVICE_FIRMWARE_FAILED,
  },
});

export default transferDeviceFirmware;
