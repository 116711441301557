import {
  addSchedule,
  addScheduleSuccess,
  addScheduleFailure,
} from './addSchedule';
import {
  addAutomatedOperation,
  addAutomatedOperationSuccess,
  addAutomatedOperationFailure,
} from './addAutomatedOperation';
import {
  addOperation,
  addOperationSuccess,
  addOperationFailure,
} from './addOperation';
import {
  removeAutomatedOperation,
  removeAutomatedOperationSuccess,
  removeAutomatedOperationFailure,
} from './removeAutomatedOperation';
import {
  removeOperation,
  removeOperationSuccess,
  removeOperationFailure,
} from './removeOperation';
import {
  removeSchedule,
  removeScheduleSuccess,
  removeScheduleFailure,
} from './removeSchedule';
import {
  fetchAutomatedOperation,
  fetchAutomatedOperationSuccess,
  fetchAutomatedOperationFailure,
} from './fetchAutomatedOperation';
import {
  fetchAutomatedOperations,
  fetchAutomatedOperationsSuccess,
  fetchAutomatedOperationsFailure,
} from './fetchAutomatedOperations';
import fetchAutomatedOperationsPaginated
  from './fetchAutomatedOperationsPaginated';
import {
  fetchSchedules,
  fetchSchedulesSuccess,
  fetchSchedulesFailure,
} from './fetchSchedules';
import {
  fetchAutomatedFunctions,
  fetchAutomatedFunctionsSuccess,
  fetchAutomatedFunctionsFailure,
} from './fetchAutomatedFunctions';
import {
  updateAutomatedFunction,
  updateAutomatedFunctionSuccess,
  updateAutomatedFunctionFailure,
} from './updateAutomatedFunction';
import {
  updateAutomatedOperation,
  updateAutomatedOperationSuccess,
  updateAutomatedOperationFailure,
} from './updateAutomatedOperation';
import {
  updateSchedule,
  updateScheduleSuccess,
  updateScheduleFailure,
} from './updateSchedule';

import {
  syncAutomatedFunction,
  syncAutomatedFunctionSuccess,
  syncAutomatedFunctionFailure,
} from './syncAutomatedFunction';

import updateAutomatedFunctionRemoveSchedule from './updateAutomatedFunctionRemoveSchedule';
import removeSchedulePermissionFailed from './removeSchedulePermissionFailed';
import invalidInputNotification from './invalidInputNotification';
import schedulesAFNotification from './schedulesAFNotification';

export default {
  addSchedule,
  addScheduleSuccess,
  addScheduleFailure,
  addAutomatedOperation,
  addAutomatedOperationSuccess,
  addAutomatedOperationFailure,
  addOperation,
  addOperationSuccess,
  addOperationFailure,
  removeAutomatedOperation,
  removeAutomatedOperationSuccess,
  removeAutomatedOperationFailure,
  removeOperation,
  removeOperationSuccess,
  removeOperationFailure,
  removeSchedule,
  updateAutomatedFunctionRemoveSchedule,
  removeScheduleSuccess,
  removeScheduleFailure,
  fetchAutomatedOperation,
  fetchAutomatedOperationSuccess,
  fetchAutomatedOperationFailure,
  fetchAutomatedOperations,
  fetchAutomatedOperationsSuccess,
  fetchAutomatedOperationsFailure,
  fetchAutomatedOperationsPaginated,
  fetchSchedules,
  fetchSchedulesSuccess,
  fetchSchedulesFailure,
  fetchAutomatedFunctions,
  fetchAutomatedFunctionsSuccess,
  fetchAutomatedFunctionsFailure,
  updateAutomatedFunction,
  updateAutomatedFunctionSuccess,
  updateAutomatedFunctionFailure,
  updateAutomatedOperation,
  updateAutomatedOperationSuccess,
  updateAutomatedOperationFailure,
  updateSchedule,
  updateScheduleSuccess,
  updateScheduleFailure,
  removeSchedulePermissionFailed,
  invalidInputNotification,
  schedulesAFNotification,
  syncAutomatedFunction,
  syncAutomatedFunctionSuccess,
  syncAutomatedFunctionFailure,
};
