import isEmpty from 'lodash/isEmpty';

import { USERS_FIELD } from 'helpers/selectors';

const projectSuitePermissionsReady = (permissionsState, projectSuiteId) => {
  const { links, projectSuites } = permissionsState;

  if (!links) {
    return false;
  }

  const {
    [projectSuiteId]: projectSuiteLinks,
  } = links;

  if (!projectSuiteLinks) {
    return false;
  }

  const {
    [USERS_FIELD]: usersLink,
  } = projectSuiteLinks;

  const { [projectSuiteId]: permissionsInfo } = projectSuites || {};

  const { data } = permissionsInfo || {};

  const linksReady = !!usersLink;
  const dataReady = !!data && !isEmpty(data);

  return linksReady && dataReady;
};

export default projectSuitePermissionsReady;
