import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import RefreshInterface from 'components/RefreshInterface/RefreshInterfaceContainer';
import { SyncFirmware } from './components';

const RefreshUI = ({ deviceId }) => (
  <Fragment>
    <SyncFirmware />
    <RefreshInterface
      deviceId={deviceId}
      dataId="remote_devices"
    />
  </Fragment>
);

RefreshUI.propTypes = {
  deviceId: PropTypes.string.isRequired,
};

export default RefreshUI;
