import has from 'lodash/has';
import sortBy from 'lodash/sortBy';

const COLOR_ORDER = [
  '#D0021B',
  '#EB8A00',
  '#F9AB32',
  '#FCF100',
  '#5AD02D',
  '#FFFFFF',
  '#21BA45',
  '#4EC6CD',
  '#3FBCF5',
  '#8664BB',
  '#EE95C0',
];

const getOccurrenceColors = occurrences => {
  const colors = {};

  occurrences.reduce((acc, occurrence) => {
    const { datetime, aoColour } = occurrence;

    if (!aoColour) {
      return acc;
    }

    const { day } = datetime.toObject();

    if (!has(acc, day)) {
      acc[day] = [aoColour];
    } else {
      acc[day] = [...new Set([...acc[day], aoColour])];
    }

    acc[day] = sortBy(acc[day], o => COLOR_ORDER.indexOf(o));

    return acc;
  }, colors);

  return colors;
};

export default getOccurrenceColors;
