import React from 'react';
import PropTypes from 'prop-types';

import Form from 'components/form/Form/Form';
import { Button } from 'components/atomic';
import { oneTimePasswordFormFields } from './constants';
import { OneTimePasswordField } from './components';

const OneTimePasswordForm = ({ handleSubmit }) => (
  <Form>
    <Form.Group>
      <OneTimePasswordField name={oneTimePasswordFormFields.oneTimePassword} />
      <Form.Field>
        <Button
          primary
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Form.Field>
    </Form.Group>
  </Form>
);

OneTimePasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default OneTimePasswordForm;
