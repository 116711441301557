import { compose } from 'redux';

import extractValue from 'helpers/extractValue/extractValue';
import markLastUpdate from 'helpers/markLastUpdate/markLastUpdate';
import withPrevious from 'helpers/withPrevious/withPrevious';
import DescriptionItem from '../DescriptionItem/DescriptionItem';

const TextDescriptionItem = compose(
  extractValue,
  markLastUpdate,
  withPrevious,
)(DescriptionItem);

export default TextDescriptionItem;
