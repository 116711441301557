import styled from 'styled-components';
import { Grid, Form } from 'semantic-ui-react';

import { mediaMax } from 'themes/mixins';

export const StyledForm = styled(Form)`
  &&& {
    label {
      font-size: 13px !important;
      font-weight: 700;
      display: inline-block;
      margin: 0 0 .28571429rem 0 !important;
    }
  }
`;

export const StyledGridRow = styled(Grid.Row)`
  padding-bottom: 0px !important;
`;

export const StyledGridColumn = styled(Grid.Column)`
  ${mediaMax.sm`
    padding-top: 1em !important;
  `};
`;

export const StyledModeLabel = styled.label`
  padding-right: 8px !important;
`;

export default StyledForm;
