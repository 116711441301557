import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  p {
    font-size: 16px;
    text-align: center;
  }
`;

export default Container;
