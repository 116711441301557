import isUndefined from 'lodash/isUndefined';

class UniverseModel {

  constructor(universe = {}) {
    this.id = !isUndefined(universe.id) ? Number(universe.id) : null;
    this.originalId = universe.id;
    this.name = universe.name;
  }

  get selectorOption() {
    const { id, name } = this;

    return {
      key: String(id),
      value: String(id),
      text: name,
    };
  }

}

export default UniverseModel;
