import isEmpty from 'lodash/isEmpty';
import { filterTriggers } from 'application/tenant/console/site/components/structural/ProjectTasksTab/components/ListOfTasks/components/TaskAccordionRow/components/ActionsTable/components/ActionsTableRow/components/EditAction/utilities';
import createDropdownOptionForTriggers from './createDropdownOptionForTriggers';
import mergeTriggersOptions from './mergeTriggersOptions';

const transformAndMergeTriggersData = (userSelectedTriggers, deviceTriggers) => {
  if (isEmpty(userSelectedTriggers) && isEmpty(deviceTriggers)) {
    return [];
  }

  const userSelectedTriggersOptions = filterTriggers(userSelectedTriggers, deviceTriggers)
    .map(trigger => createDropdownOptionForTriggers(trigger));
  const allDeviceTriggersOptions = deviceTriggers
    .map(trigger => createDropdownOptionForTriggers(trigger));

  return mergeTriggersOptions(allDeviceTriggersOptions,
    userSelectedTriggersOptions);
};

export default transformAndMergeTriggersData;
