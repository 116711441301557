export default {
  ACTION_RDM_DISCOVER: 'rdm_discover',
  ACTION_POE_CYCLE: 'interface_poe_power_cycle',
  ACTION_SET_ZONE_STATE: 'set_zone_state',
  ACTION_FIRE_SNAPSHOT: 'fire_snapshot',

  SETTING_DMX_STATUS: 'port_dmx_status',
  SETTING_BACKGROUND_DISCOVERY: 'port_rdm_background_discovery_enabled',
  SETTING_RDM_ENABLED: 'port_rdm_enabled',
  SETTING_POE_ENABLED: 'interface_poe_enabled',
  SETTING_IFACE_NET_LINK_ENABLED: 'interface_net_link_enabled',

  FIELD_PATH_NAME: 'patchNm',
  FIELD_DMX_STATUS: 'dmxSts',
  FIELD_RDM: 'rdm',
  FIELD_RDM_BACKGROUND: 'rdmBgDcvr',

  FIELD_POE: 'poe',
  FIELD_LINK: 'link',
  FIELD_UPTIME: 'up',
  FIELD_LINK_STATUS: 'sts',
  FIELD_BANDWIDTH_USAGE: 'bwU',
  FIELD_POE_USAGE: 'poeU',
  FIELD_LLDP_NAME: 'lldpNm',
  FIELD_VLAN_NAME: 'vlanNm',
};
