import React from 'react';
import PropTypes from 'prop-types';

import { PROJECTS_FIELD } from 'helpers/selectors';
import DataTable from 'components/DataTable/DataTableContainer';

import { ActionLinks, LinkProjectToProjectSuite } from '..';

function ProjectsTable({
  header,
  description,
  tableLabels,
  showCreateButton,
  showActionLinks,
}) {
  return (
    <DataTable
      header={header}
      description={description}
      labels={tableLabels}
      actionLinks={showActionLinks ? ActionLinks : null}
      createElement={showCreateButton ? LinkProjectToProjectSuite : null}
      pagination={{
        type: PROJECTS_FIELD,
        args: {
          self: true,
        },
      }}
    />
  );
}

ProjectsTable.defaultProps = {
  header: '',
  description: '',
  tableLabels: [
    { text: 'Site', field: 'visibleNameWithState', sortBy: 'name' },
    { text: 'Team', field: 'team', sortable: false },
    { text: 'Devices', field: 'devices', sortable: false },
    { text: 'Created', field: 'dateCreated', sortBy: 'createdAt' },
  ],
  showCreateButton: false,
  showActionLinks: false,
};

ProjectsTable.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  tableLabels: PropTypes.array,
  showCreateButton: PropTypes.bool,
  showActionLinks: PropTypes.bool,
};

export default ProjectsTable;
