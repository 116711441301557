import React from 'react';
import PropTypes from 'prop-types';
import { Loader, Tab } from 'semantic-ui-react';

import { LogsTable, CopyButton } from './components';
import { StyledDiv, StyledDivLoader, StyledTab } from './LogsSection.style';

const LoadingLogs = ({
  isLoading,
  logsDataExists,
  logsData,
  dataForJSON,
}) => {
  const panes = [
    {
      menuItem: 'Preview',
      render: () => <Tab.Pane><LogsTable logsData={logsData} /></Tab.Pane>,
    },
    {
      menuItem: 'Raw',
      render: () => (
        <Tab.Pane>
          <StyledDiv>
            <CopyButton data={dataForJSON} />
            <pre>
              {JSON.stringify(dataForJSON, null, 2)}
            </pre>
          </StyledDiv>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
      {isLoading && (
        <StyledDivLoader>
          <Loader active inline="centered" />
        </StyledDivLoader>
      )}
      {logsDataExists && !isLoading && <StyledTab panes={panes} />}
    </>
  );
};

LoadingLogs.defaultProps = {
  isLoading: false,
  logsDataExists: false,
  logsData: [],
  dataForJSON: [],
};

LoadingLogs.propTypes = {
  isLoading: PropTypes.bool,
  logsDataExists: PropTypes.bool,
  logsData: PropTypes.array,
  dataForJSON: PropTypes.array,
};

export default LoadingLogs;
