import { takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';

import fetchPatches from './fetchPatches';
import fetchOutput from './fetchOutput';

function* outputSectionSaga() {
  yield takeLatest(constants.FETCH_PATCHES, fetchPatches);
  yield takeLatest(constants.FETCH_OUTPUT, fetchOutput);
}

export default outputSectionSaga;
