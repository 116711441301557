import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { DEVICE_FIRMWARE_FILES_FIELD } from 'helpers/selectors';

export const fetchFirmwareFiles = urlParams => ({
  type: constants.FETCH_FIRMWARE_FILES_REQUEST,
  payload: {
    endpoint: endpoints.DEVICE_FIRMWARE_FILES,
    resourceType: DEVICE_FIRMWARE_FILES_FIELD,
    urlParams,
  },
});

export const fetchFirmwareFilesSuccess = response => ({
  type: constants.FETCH_FIRMWARE_FILES_SUCCESS,
  payload: {
    response,
    resourceType: DEVICE_FIRMWARE_FILES_FIELD,
  },
});

export const fetchFirmwareFilesFailure = error => ({
  type: constants.FETCH_FIRMWARE_FILES_FAILED,
  payload: { error },
});

export default fetchFirmwareFiles;
