import { takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import pasteButton from './pasteButton';

function* saga() {
  yield takeLatest(constants.PASTE_BUTTON, pasteButton);
}

export default saga;
