import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  LOGICAL_DEVICES_FIELD,
  makeUserSelector,
  permissionsLogicalDeviceUserLoadedSelector,
} from 'helpers/selectors';
import UserModel from 'models/UserModel';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { UserLabel } from '../../../../../../../../../../components';
import permissionsActionCreators from '../../../../../../../../../../utilities/permissions/permissionsActionCreators';
import { selectors } from '../../../../../../../../../../utilities/permissions';

export const makeMapState = () => {
  const localUserSelector = makeUserSelector();

  return (state, { resourceId, userId }) => {
    const user = localUserSelector(state)(userId);
    const userModel = new UserModel(user);
    const userLoaded = permissionsLogicalDeviceUserLoadedSelector(
      state,
      {
        deviceId: resourceId,
        userId,
      },
    );
    const copyUserId = selectors.permissionsDeviceCopyUserIdSelector(
      state,
      {
        deviceId: resourceId,
      },
    );
    const copyUser = localUserSelector(state)(copyUserId) || {};
    const { fullNameOrEmail: copyUserName } = new UserModel(copyUser);

    return {
      resourceType: LOGICAL_DEVICES_FIELD,
      userFirstName: userModel.firstName,
      userFullName: userModel.fullName,
      userEmail: userModel.email,
      userLabelText: userModel.userLabel,
      userLoaded,
      copyUserId,
      copyUserName,
    };
  };
};

const mapDispatchToProps = {
  onRemoveUserId: permissionsActionCreators.resourceRemoveUserId,
  onCopy: permissionsActionCreators.resourceCopy,
  onPaste: args => permissionsActionCreators.resourcePaste({ ...args, isContext: true }),
};

export default compose(
  connect(makeMapState, mapDispatchToProps),
  chainedLoaderWithSaga(
    ({
      userId,
      resourceId,
      userLoaded,
    }) => !userLoaded && permissionsActionCreators.fetchContextUserPermissions(
      userId,
      LOGICAL_DEVICES_FIELD,
      resourceId,
    ),
  ),
)(UserLabel);
