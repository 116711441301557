import { filesize } from 'filesize';
import get from 'lodash/get';
import { DateTime } from 'luxon';

class FileModel {

  // eslint-disable-next-line default-param-last
  constructor(file = {}, device) {
    this.id = file.id;
    this.name = file.fileName;
    this.fileTypeId = file.fileType;
    this.lastModified = file.lastModified;
    this.size = file.size;

    this.device = device;
  }

  get fileType() {
    const { device, fileTypeId } = this;
    if (!device) return '';

    const fileTypes = get(device, 'reported.sf', null);
    if (!fileTypes) return '';

    return fileTypes[fileTypeId].nm;
  }

  get readableSize() {
    const fileSize = filesize(this.size, {
      exponent: 2,
      output: 'object',
    });

    return fileSize.value;
  }

  get uploadedAt() {
    return DateTime
      .fromMillis(this.lastModified * 1000)
      .toFormat('HH:mm dd/MM/yy');
  }

}

export default FileModel;
