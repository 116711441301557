import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import DateField from 'components/form/DateField/DateField';

const EditDate = ({ onSubmit, name, minDate }) => (
  <form>
    <Field
      name={name}
      id={name}
      placeholder="Date"
      component={DateField}
      onChange={onSubmit}
      min={minDate}
    />
  </form>
);

EditDate.defaultProps = {
  minDate: undefined,
};

EditDate.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  minDate: PropTypes.object,
};

export default EditDate;
