import filterPermissions from './filterPermissions';
import getPermissionNames from './getPermissionNames';
import getAllPermissionsWithRecipientsMap from './getAllPermissionsWithRecipientsMap';
import getFinalCumulatedPermissionsMap from './getFinalCumulatedPermissionsMap';
import transformToTableRows from './transformToTableRows';
import addRecipientsInfo from './addRecipientsInfo';

const transformGrantRevokePermissionsResponse = ({
  allPermissions,
  originalCumulatedPermissions,
  resourceType,
  resourceName,
  recipientIds,
  recipientType,
  limitedScope,
  limitedFor,
}) => {
  const permissions = filterPermissions({
    allPermissions,
    resourceType,
    recipientType,
    limitedScope,
    limitedFor,
  });

  const permissionsNames = getPermissionNames(permissions);

  const allPermissionsMap = getAllPermissionsWithRecipientsMap({
    permissionsNames,
    recipientIds,
  });

  const cumulatedPermissions = getFinalCumulatedPermissionsMap({
    allPermissionsMap,
    cumulatedPermissionsMap: originalCumulatedPermissions,
    recipientIds,
  });

  let rows = transformToTableRows({
    resourceType,
    resourceName,
    cumulatedPermissions,
  });

  rows = addRecipientsInfo({
    cumulatedPermissions,
    rows,
    recipientIds,
  });

  return {
    cumulatedPermissions,
    permissionsTableRows: rows,
  };
};

export default transformGrantRevokePermissionsResponse;
