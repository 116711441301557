import { CONTROL_UI_CONTROL_TYPES } from 'application/tenant/components/structural/ControlUI/constants';

const updatePages = ({
  pages,
  pageId,
  buttonId,
  buttonContent,
  controlType = 'button',
}) => {
  const { BUTTON, OVERRIDE_CONTROLS } = CONTROL_UI_CONTROL_TYPES;

  if (Array.isArray(pages)) {
    const newPages = [...pages];
    const pageToUpdate = pages[pageId];

    const { buttons, overrideControls = [] } = pageToUpdate;

    if (controlType === BUTTON) {
      const newButtons = [...buttons];

      const buttonWithUpdatedIndex = { ...buttonContent, index: buttonId };
      newButtons.splice(buttonId, 1, buttonWithUpdatedIndex);
      const pageWithUpdatedButtons = { ...pageToUpdate, buttons: newButtons };
      newPages.splice(pageId, 1, pageWithUpdatedButtons);

      return newPages;
    }

    if (controlType === OVERRIDE_CONTROLS) {
      const newOverrideControls = [...overrideControls];

      const buttonWithUpdatedIndex = { ...buttonContent, index: buttonId };
      newOverrideControls.splice(buttonId, 1, buttonWithUpdatedIndex);
      const pageWithUpdatedButtons = { ...pageToUpdate, overrideControls: newOverrideControls };
      newPages.splice(pageId, 1, pageWithUpdatedButtons);

      return newPages;
    }

  }

  return null;
};

export default updatePages;
