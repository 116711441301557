import isPlainObject from 'lodash/isObject';

import { remoteDeviceStatus } from 'helpers/sharedVariables';

const getRemoteDeviceStatus = firmware => {
  if (!isPlainObject(firmware)) {
    return null;
  }

  if (firmware.correct) {
    return remoteDeviceStatus.ONLINE;
  }

  if (firmware.reloading) {
    const { RELOADING_FIRMWARE } = remoteDeviceStatus;

    return { ...RELOADING_FIRMWARE, label: `Reloading Firmware ${firmware.reload_progress}%` };
  }

  return remoteDeviceStatus.INCOMPATIBLE_FIRMWARE;

};

export default getRemoteDeviceStatus;
