import { compose } from 'redux';
import { connect } from 'react-redux';

import { deepEqualMemoizeOne } from 'helpers/sharedMethods';
import {
  computedLogicalDeviceDataPermissionsTreeSelector,
} from 'helpers/selectors';
import { PermissionsGrid } from '../../../../components';
import { sortPaths } from '../../../../utilities';
import { DevicePermissionsGridRow } from '../PermissionsGridRow';

export const makeMapState = () => {
  const memoPaths = deepEqualMemoizeOne();

  return function mapStateToProps(state, { resourceId }) {
    const {
      permissionCount,
      ...tree
    } = computedLogicalDeviceDataPermissionsTreeSelector(state)(resourceId);
    const paths = sortPaths(tree);

    return {
      paths: memoPaths(paths),
      PermissionsGridRow: DevicePermissionsGridRow,
    };
  };
};

export default compose(
  connect(makeMapState),
)(PermissionsGrid);
