import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

export default styled(Button).attrs({
  className: 'basic tableToolbarButton',
})`
  &&&&& {
    font-size: 13px;
    box-shadow: 0 0!important;
    background: transparent;
    padding: 0;
  }
`;
