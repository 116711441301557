import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const verifyPasswordResetToken = params => ({
  type: constants.RESET_PASSWORD_VERIFY_TOKEN,
  payload: {
    ...params,
    endpoint: endpoints.VERIFY_PASSWORD_RESET_TOKEN,
  },
});

export const verifyPasswordResetTokenSuccess = response => ({
  type: constants.RESET_PASSWORD_VERIFY_TOKEN_SUCCESS,
  payload: { response },
});
