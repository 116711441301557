import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { singleProjectSuiteSelector, singleProjectSelector } from 'helpers/selectors';
import { tasksActionCreators } from '../../../../../../../../../../utilities';
import withDeleteTask from './withDeleteTask';

export const mapStateToProps = (state, { match, superSites }) => {
  const { id: resourceId } = match.params;
  const selector = superSites ? singleProjectSuiteSelector : singleProjectSelector;
  const { name: resourceName } = selector(state, resourceId);

  return {
    resourceId,
    resourceName,
    resourceType: superSites ? 'projectSuite' : 'project',
  };
};

const mapDispatchToProps = {
  onDeleteTask: tasksActionCreators.removeTask,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withDeleteTask,
);
