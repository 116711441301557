import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  USERS_FIELD,
  PROJECT_SUITES_FIELD,
  permissionsProjectSuiteUsersLinkSelector,
} from 'helpers/selectors';
import { addPagination } from 'helpers/paginationHelpers';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { permissionsActionCreators } from '../../../../../../utilities';

import UserTable from './UserTable';

export const mapStateToProps = (state, { resourceId }) => ({
  usersEndpoint: permissionsProjectSuiteUsersLinkSelector(state)(resourceId),
  resourceId,
  resourceType: PROJECT_SUITES_FIELD,
});

export default compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: USERS_FIELD, included: true },
      ({ resourceId, usersEndpoint }) => permissionsActionCreators.fetchProjectSuiteUsers(
        resourceId,
        usersEndpoint,
        {
          fields: {
            [USERS_FIELD]: ['firstName', 'lastName', 'email', 'lastLoginTime', 'receivedInvitations'],
          },
          sort: 'firstName',
        },
      ),
    ),
  ),
)(UserTable);
