function convertValueFromString(paramValue, type) {
  let value;

  switch (type) {
    case 'boolean':
      value = paramValue === 'true';
      break;
    case 'string':
      value = paramValue;
      break;
    case 'integer':
      value = parseInt(paramValue, 10);
      break;
    case 'array':
      value = paramValue.split(',').map(item => parseInt(item, 10)).sort((a, b) => a - b);
      break;
    default:
      value = paramValue;
  }

  return value;
}

export default convertValueFromString;
