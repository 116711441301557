import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import {
  StatusWidgets,
  ProjectsTable,
  MultiSiteMapView,
} from './components';

const ProjectSuiteProjectsTab = ({ displayMap }) => (
  <Grid>
    <StatusWidgets />
    {displayMap && (
      <Grid.Row>
        <Grid.Column>
          <MultiSiteMapView />
        </Grid.Column>
      </Grid.Row>
    )}
    <Grid.Row>
      <Grid.Column>
        <ProjectsTable />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

ProjectSuiteProjectsTab.defaultProps = {
  displayMap: false,
};

ProjectSuiteProjectsTab.propTypes = {
  displayMap: PropTypes.bool,
};

export default ProjectSuiteProjectsTab;
