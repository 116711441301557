import isNil from 'lodash/isNil';

import { computeDropdownOptions } from 'helpers/sharedMethods';

const getDeviceModels = (formValues, manufacturers) => {
  if (isNil(manufacturers)) {
    return [];
  }

  if (!isNil(formValues) && formValues.manufacturer) {
    const models = manufacturers.filter(obj => obj.id === formValues.manufacturer);

    return computeDropdownOptions(models[0].models);
  }

  return [];
};

export default getDeviceModels;
