import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  USERS_FIELD,
  PROJECT_SUITES_FIELD,
  singleProjectSuiteSelector,
  permissionsProjectSuiteOpenSelector,
  permissionsProjectSuiteUsersLinkSelector,
} from 'helpers/selectors';
import { addPagination } from 'helpers/paginationHelpers';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { PermissionsGridWrapper } from '../../../../components';
import permissionsActionCreators from '../../../../utilities/permissions/permissionsActionCreators';

import { ProjectSuitePermissionsTree } from '../../tree/PermissionsTree';
import { ProjectSuitePermissionsGridHeaders } from '../../headers/PermissionsGridHeaders';
import { ProjectSuitePermissionsGrid } from '../../grid/PermissionsGrid';
import { ProjectSuiteAddUsersModal } from '../../users/AddUsersModal';

export const mapStateToProps = (state, { projectSuiteId }) => {
  const { name: projectSuiteName } = singleProjectSuiteSelector(state, projectSuiteId);

  return {
    open: permissionsProjectSuiteOpenSelector(state, { projectSuiteId }),
    usersEndpoint: permissionsProjectSuiteUsersLinkSelector(state)(projectSuiteId),
    resourceId: projectSuiteId,
    resourceType: PROJECT_SUITES_FIELD,
    resourceTitle: 'Multi-site',
    resourceName: projectSuiteName,
    PermissionsTree: ProjectSuitePermissionsTree,
    PermissionsGridHeaders: ProjectSuitePermissionsGridHeaders,
    PermissionsGrid: ProjectSuitePermissionsGrid,
    AddUsersButton: ProjectSuiteAddUsersModal,
    isProject: true,
  };
};

export const mapDispatchToProps = {
  onToggleOpen: projectSuiteId => permissionsActionCreators.toggleResourceOpen(
    PROJECT_SUITES_FIELD,
    projectSuiteId,
  ),
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: USERS_FIELD, included: true },
      ({ resourceId, usersEndpoint }) => permissionsActionCreators.fetchProjectSuiteUsers(
        resourceId,
        usersEndpoint,
        {
          fields: {
            [USERS_FIELD]: ['firstName', 'lastName', 'email'],
          },
          sort: 'firstName',
        },
      ),
    ),
  ),
)(PermissionsGridWrapper);
