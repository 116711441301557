import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { isValidVersion } from 'utilities';
import { currentCompanyEmailSelector } from 'helpers/selectors';
import { hooks as deviceHooks } from 'application/tenant/console/utilities/device';
import { renderIconLabel } from '../../utilities';
import { statusLabels } from '../../constants';
import DeviceStatusIcon from '../DeviceStatusIcon/DeviceStatusIcon';

const VersionStatusIcon = ({ deviceId, online, disableLabel }) => {
  const companyEmail = useSelector(currentCompanyEmailSelector);
  const device = deviceHooks.useDevice({ deviceId, fields: ['reported'] });
  const { reported } = device;

  if (!reported) {
    return <DeviceStatusIcon {...renderIconLabel(statusLabels.LOADING, disableLabel)} />;
  }

  const { libsixeyeVersion } = reported;

  if (!libsixeyeVersion || !isValidVersion(libsixeyeVersion)) {
    const getTooltip = () => {
      if (companyEmail) {
        return `Please contact ${companyEmail}`;
      }

      return null;
    };

    if (online) {
      return (
        <DeviceStatusIcon
          {...renderIconLabel(statusLabels.UPGRADE_ONLINE, disableLabel)}
          tooltip={getTooltip()}
        />
      );
    }

    return (
      <DeviceStatusIcon
        {...renderIconLabel(statusLabels.UPGRADE_OFFLINE, disableLabel)}
        tooltip={getTooltip()}
      />
    );
  }

  if (online) {
    return <DeviceStatusIcon {...renderIconLabel(statusLabels.ONLINE, disableLabel)} />;
  }

  return <DeviceStatusIcon {...renderIconLabel(statusLabels.OFFLINE, disableLabel)} />;
};

VersionStatusIcon.defaultProps = {
  disableLabel: false,
};

VersionStatusIcon.propTypes = {
  deviceId: PropTypes.string.isRequired,
  online: PropTypes.bool.isRequired,
  disableLabel: PropTypes.bool,
};

export default VersionStatusIcon;
