import React, { Component } from 'react';
import PropTypes from 'prop-types';

import formNames from 'components/form/formNames';
import { StyledSitePropertyInlineEdit } from './SitePropertyInlineEdit.style';

class SitePropertyInlineEdit extends Component {

  constructor() {
    super();

    this.handleChangeProperty = this.handleChangeProperty.bind(this);
  }

  handleChangeProperty(values) {
    const { siteId, onChangeProperty } = this.props;

    return new Promise((resolveForm, rejectForm) => {
      onChangeProperty({
        values: { id: siteId, ...values },
        resolveForm,
        rejectForm,
      });
    });
  }

  render() {
    const { validator, property, propertyValue, passesPermissions } = this.props;
    const form = `${formNames.changeSiteProperty}.${property}`;

    return (
      <StyledSitePropertyInlineEdit
        name={property}
        type="text"
        value={propertyValue}
        validator={validator}
        onSubmit={this.handleChangeProperty}
        form={form}
        locked={!passesPermissions}
        allowCancelOnPristine
      />
    );
  }

}

SitePropertyInlineEdit.defaultProps = {
  propertyValue: undefined,
  validator: null,
};

SitePropertyInlineEdit.propTypes = {
  siteId: PropTypes.string.isRequired,
  onChangeProperty: PropTypes.func.isRequired,
  property: PropTypes.string.isRequired,
  passesPermissions: PropTypes.bool.isRequired,
  propertyValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  validator: PropTypes.func,
};

export default SitePropertyInlineEdit;
