import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import { StatusCard } from 'components/core';

import { transformToSiteStatus } from 'pages/Projects/components/StatusWidgets/utilities';

function StatusWidgets({ isLoading, projectsStatus }) {

  const siteStatus = useMemo(() => {
    if (!projectsStatus) {
      return {};
    }

    return transformToSiteStatus(projectsStatus);
  }, [projectsStatus]);


  return (
    <Grid.Row>
      <Grid.Column computer={5} mobile={16}>
        <StatusCard
          title="Site Status"
          total={siteStatus.total}
          items={siteStatus.items}
          isLoading={isLoading}
        />
      </Grid.Column>
    </Grid.Row>
  );
}

StatusWidgets.defaultProps = {
  isLoading: false,
  projectsStatus: [],
};

StatusWidgets.propTypes = {
  isLoading: PropTypes.bool,
  projectsStatus: PropTypes.array,
};

export default StatusWidgets;
