import { call, select, put } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';

import { automatedOperationSelector } from 'helpers/selectors';

import { TASK_SCHEDULER_MODE, SCHEDULER_TYPES } from '../../constants';
import taskScheduleActionCreators from '../actionCreators';
import taskScheduleSelectors from '../selectors';
import saveTask from './saveTask';
import saveActions from './saveActions';
import saveAutomatedOperation from './saveAutomatedOperation';
import saveSchedules from './saveSchedules';
import addSchedule from './addSchedule';
import updateSchedule from './updateSchedule';

export function* addTaskScheduler(action) {
  const {
    resourceId,
    resourceType,
    mode,
    scheduleId,
    automatedOperationId,
    existingSchedule,
    actions,
    actionsForm,
    tasks,
    taskName,
    taskSchedulerForm,
    schedulerType,
    recurrenceParams,
    tasksEndpoint,
    callback,
  } = action.payload;

  const schedulerName = (yield select(taskScheduleSelectors.schedulerNameSelector));
  const schedulerDescription = (yield select(taskScheduleSelectors.schedulerDescriptionSelector));

  if (mode === TASK_SCHEDULER_MODE.EDIT_SCHEDULER) {
    const updateScheduleParams = {
      scheduleId,
      automatedOperationId,
      existingSchedule,
      recurrenceParams,
      schedulerName,
      schedulerDescription,
    };

    yield call(updateSchedule, updateScheduleParams);

    if (callback) {
      callback();
    }
  } else if (mode === TASK_SCHEDULER_MODE.ADD_SCHEDULER) {
    const existingAO = (yield select(automatedOperationSelector))(automatedOperationId);

    const addScheduleParams = {
      mode,
      automatedOperation: existingAO,
      schedulerType,
      recurrenceParams,
      schedulerName,
      schedulerDescription,
    };

    yield call(addSchedule, addScheduleParams);

    if (callback) {
      callback();
    }
  } else if (TASK_SCHEDULER_MODE.DEFAULT) {
    const taskIds = tasks.map(task => task.id) || [];

    if (!isEmpty(actions)) {
      const saveTaskParams = {
        resourceId,
        resourceType,
        endpoint: tasksEndpoint,
        taskName,
      };
      const task = yield call(saveTask, saveTaskParams);

      taskIds.push(task.id);

      const saveActionsParams = {
        task,
        actions,
        actionsForm,
        resourceType,
      };

      yield call(saveActions, saveActionsParams);
    }

    const saveAutomatedOperationParams = {
      ...taskSchedulerForm.values,
      resourceId,
      resourceType,
      taskIds,
    };
    const automatedOperation = yield call(saveAutomatedOperation, saveAutomatedOperationParams);

    if (schedulerType !== SCHEDULER_TYPES.NONE) {
      const saveSchedulesParams = {
        automatedOperation,
        schedulerType,
        recurrenceParams,
        resourceId,
        resourceType,
        schedulerName,
        schedulerDescription,
      };

      yield call(saveSchedules, saveSchedulesParams);
    }

    yield put(taskScheduleActionCreators.addTaskSchedulerSuccess(automatedOperation));

    if (callback) {
      callback();
    }
  }
}

export default addTaskScheduler;
