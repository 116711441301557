import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/atomic';
import SettingCard from 'components/SettingCard/SettingCard';

const PharosBeaconV1Form = ({
  resourceBeacon,
  resourceOnline,
  title,
  submitting,
  handleSubmit,
}) => {
  const trigger = (
    <Button
      type="submit"
      primary
      pulse={resourceBeacon}
      disabled={!resourceOnline || submitting}
    >
      Beacon
    </Button>
  );

  return (
    <SettingCard
      title={title}
      trigger={trigger}
      as="form"
      onSubmit={handleSubmit}
    >
      <p>
        All status LEDs on the controller will flash.
      </p>
    </SettingCard>
  );
};

PharosBeaconV1Form.defaultProps = {
  title: '',
};

PharosBeaconV1Form.propTypes = {
  resourceBeacon: PropTypes.bool.isRequired,
  resourceOnline: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  title: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
};

export default PharosBeaconV1Form;
