import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createSelector } from 'reselect';

import { withProjectPermissionsDevice } from 'gateway';
import redirectIfNotFound from 'helpers/redirectIfNotFound';
import chainedLoader from 'helpers/chainedLoader/chainedLoaderContainerWithPagination';
import withPolling from 'helpers/withPolling';
import withConfigurablePolling from 'helpers/withConfigurablePolling/withConfigurablePollingContainer';
import { makeChainLoaderPieceSelector } from 'helpers/selectorsCreators';
import {
  deviceFetchingSelector,
  projectFetchingSelector,
  deviceErrorSelector,
  projectErrorSelector,
  DEVICE_REFRESH,
  deviceSelector,
  LOGICAL_DEVICES_FIELD,
  singleProjectSelector,
  projectLogicalDevicesSelector,
  currentUserSelector,
} from 'helpers/selectors';
import filterProps from 'helpers/filterProps';
import deviceActionCreators from '../Project/deviceActionCreators';
import projectActionCreators from '../Projects/projectActionCreators';
import Device from './Device';

const projectLoaderSelector = makeChainLoaderPieceSelector([
  projectErrorSelector,
  projectFetchingSelector,
]);
const deviceLoaderSelector = makeChainLoaderPieceSelector([
  deviceErrorSelector,
  deviceFetchingSelector,
]);

const chainLoaderSelector = createSelector(
  [
    projectLoaderSelector,
    deviceLoaderSelector,
  ],
  (projectLoaderProps, deviceLoaderProps) => ({
    project: projectLoaderProps,
    device: deviceLoaderProps,
    [DEVICE_REFRESH]: deviceLoaderProps,
  }),
);

export const mapStateToProps = (state, { match }) => {
  const { params: { deviceId, projectId } } = match;

  const project = singleProjectSelector(state, projectId);
  const projectDevices = projectLogicalDevicesSelector(project);
  const projectDevice = projectDevices.find(d => d.id === deviceId);
  const {
    name: deviceName,
    statusIcon: deviceStatus,
    manufacturerName,
    model,
    online,
  } = deviceSelector(state)(deviceId) || {};
  const { isConstruction, isSuspended, state: siteState, stateLoaded } = project;
  const { id: userId } = currentUserSelector(state);

  return {
    deviceId,
    projectId,
    deviceName: projectDevice ? deviceName : null,
    deviceStatus,
    manufacturerName,
    model,
    isConstruction,
    isSuspended,
    siteState,
    stateLoaded,
    fetching: deviceFetchingSelector(state) || projectFetchingSelector(state),
    chainedLoaderProps: chainLoaderSelector(state),
    online,
    userId,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withProjectPermissionsDevice,
  chainedLoader(
    { name: 'project' },
    ({ projectId }) => projectActionCreators.fetchProject(projectId, {
      include: [
        LOGICAL_DEVICES_FIELD,
      ],
      fields: {
        [LOGICAL_DEVICES_FIELD]: [
          'name',
        ],
      },
    }),
  ),
  redirectIfNotFound('deviceName'),
  chainedLoader(
    { name: 'device' },
    ({ deviceId }) => deviceActionCreators.fetchDevice(deviceId, {
      fields: {
        [LOGICAL_DEVICES_FIELD]: [
          'deviceAssigned',
          'name',
          'model',
          'manufacturerName',
          'online',
          'viewSelector',
        ],
      },
    }),
  ),
  withPolling(),
  chainedLoader(
    { name: 'device' },
    ({ deviceId }) => deviceActionCreators.fetchDevice(deviceId, {
      fields: {
        [LOGICAL_DEVICES_FIELD]: [
          'beacon',
          'deviceAssigned',
          'online',
        ],
      },
    }),
  ),
  withConfigurablePolling(DEVICE_REFRESH),
  chainedLoader(
    { name: DEVICE_REFRESH },
    ({ deviceId }) => deviceActionCreators.fetchDevice(deviceId, {
      fields: {
        [LOGICAL_DEVICES_FIELD]: [
          'updated',
        ],
      },
    }),
  ),
  filterProps([
    'chainedLoaderProps',
    'pollingToggle',
    'pollingValue',
  ]),
)(Device);
