export default {
  ADD_PROJECT: { name: 'self:add', resource: 'project' },
  EDIT_PROJECT: { name: 'self:edit:all', resource: 'project' },
  ADD_DEVICE: 'device:add',
  VIEW_CONTROL_PANEL: 'control_panel:view',
  EDIT_CONTROL_PANEL: 'control_panel:edit',
  ADD_PAGE_CONTROL_PANEL: [
    { name: 'control_panel:view' },
    { name: 'control_panel:edit' },
  ],
  VIEW_OCCURRENCES: 'automated_operation:occurrence:view',
  ADD_SCHEDULE: 'automated_operation:schedule:add',
  EDIT_SCHEDULE: 'automated_operation:schedule:edit',
  DELETE_SCHEDULE: 'automated_operation:schedule:delete',
  TENANT_MANAGER_SITES: { name: 'owner', resource: 'project', contextOnly: true },
  TENANT_MANAGER_USERS: { name: 'self:view:all', resource: 'user', contextOnly: true },
  TENANT_MANAGER_PERMISSIONS: { contextOnly: true },
  ADD_AUTOMATED_OPERATION: { name: 'automated_operation:add', resource: 'project' },
  EDIT_AUTOMATED_OPERATION: { name: 'automated_operation:edit', resource: 'project' },
  DELETE_AUTOMATED_OPERATION: { name: 'automated_operation:delete', resource: 'project' },
  ADD_TASK: { name: 'task:add' },
  DELETE_TASK: { name: 'task:delete' },
  EDIT_TASK: { name: 'task:edit' },
  EXECUTE_TASK: { name: 'task:execute' },
  OWNER: 'owner',
  AUTO_RENEW: [
    { name: 'self:auto_renew', resource: 'project' },
    { name: 'self:edit:all', resource: 'project' },
  ],
  SITE_EDIT_DATE_BLOCKING_START_TIME: [
    { name: 'owner', resource: 'project' },
    { name: 'self:edit:all', resource: 'project' },
  ],
  PROJECT_SUITE_OWNER: { name: 'owner', resource: 'project_suite' },
  PROJECT_SUITE_ADD: { name: 'self:add', resource: 'project_suite' },
  PROJECT_SUITE_DELETE: { name: 'self:delete', resource: 'project_suite' },
  PROJECT_SUITE_EDIT: { name: 'self:edit:all', resource: 'project_suite' },
  PROJECT_SUITE_USERS_ADD: { name: 'user:add', resource: 'project_suite' },
  PROJECT_SUITE_USERS_DELETE: { name: 'user:delete', resource: 'project_suite' },
  PROJECT_SUITE_TASKS_ADD: { name: 'task:add', resource: 'project_suite' },
  PROJECT_SUITE_TASKS_DELETE: { name: 'task:delete', resource: 'project_suite' },
  PROJECT_SUITE_TASKS_EDIT: { name: 'task:edit', resource: 'project_suite' },
  PROJECT_SUITE_TASKS_VIEW: { name: 'task:view', resource: 'project_suite' },
  PROJECT_SUITE_TASKS_EXECUTE: { name: 'task:execute', resource: 'project_suite' },
  PROJECT_SUITE_AUTOMATED_OPERATIONS_VIEW: { name: 'automated_operation:view', resource: 'project_suite' },
  PROJECT_SUITE_AUTOMATED_OPERATIONS_ADD: { name: 'automated_operation:add', resource: 'project_suite' },
  PROJECT_SUITE_AUTOMATED_OPERATIONS_EDIT: { name: 'automated_operation:edit', resource: 'project_suite' },
  PROJECT_SUITE_AUTOMATED_OPERATIONS_DELETE: { name: 'automated_operation:delete', resource: 'project_suite' },
  PROJECT_SUITE_AUTOMATED_OPERATIONS_OCCURRENCES: { name: 'automated_operation:occurrence:view', resource: 'project_suite' },
  PROJECT_SUITE_SCHEDULES_ADD: { name: 'automated_operation:schedule:add', resource: 'project_suite' },
  PROJECT_SUITE_SCHEDULES_DELETE: { name: 'automated_operation:schedule:delete', resource: 'project_suite' },
  PROJECT_SUITE_SCHEDULES_EDIT: { name: 'automated_operation:schedule:edit', resource: 'project_suite' },
  PROJECT_SUITE_SCHEDULES_VIEW: { name: 'automated_operation:schedule:view', resource: 'project_suite' },
  PROJECT_SUITE_CONTROL_PANEL_VIEW: { name: 'control_panel:view', resource: 'project_suite' },
  PROJECT_SUITE_CONTROL_PANEL_EDIT: { name: 'control_panel:edit', resource: 'project_suite' },
  PROJECT_SUITE_CONTROL_PANEL_ADD_PAGE: [
    { name: 'control_panel:view', resource: 'project_suite' },
    { name: 'control_panel:edit', resource: 'project_suite' },
  ],
  PROJECT_SUITE_EDIT_DATE_BLOCKING_START_TIME: [
    { name: 'owner', resource: 'project_suite' },
    { name: 'self:edit:all', resource: 'project_suite' },
  ],
  TENANT_SA: 'self:admin',
  TENANT_VOUCHERS: 'tenant:self:vouchers',
  PROJECT_DEVICE_FIRMWARE: 'device:firmware',
  RESOURCE_VIEW_ALL: 'self:view:all',
  AUTO_RENEW_SUSPENDED: [
    { name: 'self:auto_renew', resource: 'project' },
    { name: 'self:edit:all', resource: 'project' },
    { name: 'owner', resource: 'project' },
  ],
};
