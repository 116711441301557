import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { extractTimePart } from 'helpers/sharedMethods';
import AddScheduleModalForm from './AddScheduleModalForm/AddScheduleModalFormContainer';

class AddSchedule extends Component {

  constructor() {
    super();

    this.handleAddSchedule = this.handleAddSchedule.bind(this);
  }

  async handleAddSchedule(values) {
    const {
      onAddSchedule,
      automatedOperationId,
    } = this.props;

    await new Promise((resolveForm, rejectForm) => {
      onAddSchedule(
        {
          values: {
            ...values,
            automatedOperationId,
          },
          resolveForm,
          rejectForm,
        },
      );
    });
  }

  render() {
    const currentTime = extractTimePart(43200);
    return (
      <AddScheduleModalForm
        initialValues={{
          time: currentTime,
          hours: '',
          minutes: '',
          seconds: '',
        }}
        onSubmit={this.handleAddSchedule}
      />
    );
  }

}

AddSchedule.propTypes = {
  onAddSchedule: PropTypes.func.isRequired,
  automatedOperationId: PropTypes.string.isRequired,
};

export default AddSchedule;
