import styled, { css } from 'styled-components';
import { Button, Menu } from 'semantic-ui-react';

export const StyledButton = styled(Button)`
  &.ui.button {
    margin-bottom: 10px;
  }
`;

export const StyledMenuItem = styled(Menu.Item)`
  ${({ disabled }) => disabled && css`
    pointer-events: none;
  `}
`;

export default StyledButton;
