import { compose } from 'redux';
import { connect } from 'react-redux';

import { firmwareFileSelector } from 'helpers/selectors';
import LinkedFilesRow from './LinkedFilesRow';

export const mapStateToProps = (state, { firmwareFileId }) => {
  const {
    originalFileName = '',
    description = '',
  } = firmwareFileSelector(state)(firmwareFileId);

  return {
    name: originalFileName,
    description,
  };
};

export default compose(
  connect(mapStateToProps),
)(LinkedFilesRow);
