import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { formatTitle } from './utilities';

import {
  StyledSegment,
  StyledHeader,
  ItemsContainer,
  TitleContainer,
  StyledTitle,
  StyledProgress,
} from './StatusCard.style';

function StatusCard({ title, total, items, isLoading }) {
  const getPercent = useCallback(item => (item.amount / total) * 100, [total]);
  const formattedTitle = useCallback(itemTitle => formatTitle(itemTitle), []);

  return (
    <StyledSegment loading={isLoading} padded>
      <StyledHeader as="h5">
        {title}
      </StyledHeader>
      <ItemsContainer>
        {items.map(item => (
          <div key={item.title}>
            <TitleContainer>
              <StyledTitle>
                {formattedTitle(item.title)}
              </StyledTitle>
              <small>
                {item.amount}
              </small>
            </TitleContainer>
            <StyledProgress
              percent={getPercent(item)}
              size="tiny"
              barColor={item.barColor}
            />
          </div>
        ))}
      </ItemsContainer>
    </StyledSegment>
  );
}

StatusCard.defaultProps = {
  isLoading: false,
};

StatusCard.propTypes = {
  isLoading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
};

export default StatusCard;
