import { connect } from 'react-redux';

import AddCompany from './AddCompany';
import { actionCreators } from '../../../utilities';

const mapDispatchToProps = {
  onAddCompany: actionCreators.addCompany,
};

export default connect(null, mapDispatchToProps)(AddCompany);
