import { compose } from 'redux';
import { connect } from 'react-redux';

import { actionCreators } from '../../../../../../utilities/users';
import withToggleUserSuspension from './withToggleUserSuspension';

const mapDispatchToProps = {
  onToggleSuspendUser: actionCreators.toggleSuspendUser,
};

export default compose(
  connect(null, mapDispatchToProps),
  withToggleUserSuspension,
);
