import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  permissionsContextsSelector,
} from 'helpers/selectors';
import permissionsActionCreators from '../../../../../../../../utilities/permissions/permissionsActionCreators';
import ContextsRow from './ContextsRow';

export const mapStateToProps = (state, ownProps) => {
  const { item: { id: contextId } } = ownProps;
  const contexts = permissionsContextsSelector(state);
  const highlighted = contexts && contextId in contexts;

  return {
    highlighted,
  };
};

const mapDispatchToProps = {
  onAddContextId: permissionsActionCreators.addContextId,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ContextsRow);
