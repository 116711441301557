import { takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import fetchDeviceTimelines from './fetchDeviceTimelines';

function* saga() {
  yield takeLatest(constants.FETCH_DEVICE_TIMELINES_REQUEST, fetchDeviceTimelines);
}

export {
  saga,
};

export default saga;
