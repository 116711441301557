import has from 'lodash/has';
import { put, select } from 'redux-saga/effects';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

import {
  singleProjectSelector,
  singleProjectSuiteSelector,
  automatedOperationSelector,
  projectSuiteIdContextSelector,
  AUTOMATED_OPERATIONS_OCCURRENCES_FIELD,
} from 'helpers/selectors';
import { selectors } from 'pages/ProjectSuites/utilities';
import {
  parseDateFromUrl,
  actionCreators as schedulingActionCreators,
  monthDateRange,
} from '../../../../../utilities';
import { urlDateFormat } from '../../../../../constants';

function* updateOccurrencesWithDelay(automatedOperationId) {
  yield put(schedulingActionCreators.updateOccurrences());

  let siteId = null;
  let superSiteId = null;
  let resourceType = 'project';

  const automatedOperation = (yield select(automatedOperationSelector))(automatedOperationId);
  const projectSuiteIdContext = (yield select(projectSuiteIdContextSelector));

  if (has(automatedOperation, 'projectId') && !isNil(automatedOperation.projectId) && isNil(projectSuiteIdContext)) {
    siteId = automatedOperation.projectId;
    resourceType = 'project';
  } else if (has(automatedOperation, 'projectSuiteId') && !isNil(automatedOperation.projectSuiteId)) {
    superSiteId = projectSuiteIdContext;
    resourceType = 'projectSuite';
  }

  const resourceId = siteId || superSiteId;
  const singleResourceSelector = siteId ? singleProjectSelector : singleProjectSuiteSelector;

  const { datetime: resourceTime } = yield select(singleResourceSelector, resourceId);
  const urlDate = parseDateFromUrl({ defaultTime: resourceTime });
  const { start, end } = monthDateRange(urlDate);

  const params = {
    recipientType: resourceType,
    recipientId: resourceId,
    'filter[dateFrom]': start.toFormat(urlDateFormat),
    'filter[dateTo]': end.toFormat(urlDateFormat),
  };

  if (resourceType === 'project') {
    params['projectIds[]'] = [resourceId];
    const selectedProjectSuites = (yield select(selectors.existingProjectSuitesSelector))(
      resourceId, AUTOMATED_OPERATIONS_OCCURRENCES_FIELD,
    );

    if (Array.isArray(selectedProjectSuites) && !isEmpty(selectedProjectSuites)) {
      params['projectSuiteIds[]'] = [...selectedProjectSuites];
    }
  } else {
    const selectedProjects = (yield select(selectors.existingProjectsSelector))(
      resourceId, AUTOMATED_OPERATIONS_OCCURRENCES_FIELD,
    );
    const allProjects = (yield select(selectors.projectSuiteProjectsListSelector))(resourceId);

    params['projectSuiteIds[]'] = [resourceId];

    if (!isNil(selectedProjects) && Array.isArray(selectedProjects)) {
      params['projectIds[]'] = [...selectedProjects];
    }

    if (isNil(selectedProjects)) {
      params['projectIds[]'] = [...allProjects];
    }
  }

  yield put(schedulingActionCreators.fetchOccurrences(params));
}

export default updateOccurrencesWithDelay;
