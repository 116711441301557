import React from 'react';
import PropTypes from 'prop-types';

const Controls = ({ children }) => <div>{children}</div>;

Controls.defaultProps = {
  children: [],
};

Controls.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
};

export default Controls;
