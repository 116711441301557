import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import {
  TaskHistory,
  AvailableActions,
  AvailableGroups,
  SiteAvailableActions,
} from '..';
import {
  CustomBreadcrumb,
  ProjectsList,
  DevicesList,
  ButtonGroup,
} from './components';

const options = [
  { key: 1, text: 'Site tasks', value: 'site_tasks' },
  { key: 2, text: 'Site devices', value: 'site_devices' },
  { key: 3, text: 'Site actions', value: 'site_actions' },
];

const multiSiteOptions = [
  { key: 1, text: 'Multi-site child sites', value: 'multi_site_child_sites' },
  { key: 2, text: 'Multi-site tasks', value: 'multi_site_tasks' },
];

function TasksActionsNavigation({
  project,
  projects,
  projectSuite,
  selectedDevice,
  onActionSelected,
  onDeviceSelected,
  resourceType,
  mode,
  tabs,
  defaultViewType,
  selectedGroups,
  onGroupSelected,
}) {
  const [selectedProject, setSelectedProject] = useState(() => {
    if (project) {
      return project;
    }

    return null;
  });

  const [viewType, setViewType] = useState(() => {
    if (defaultViewType) {
      return defaultViewType;
    }

    if (resourceType === 'project') {
      return 'site_tasks';
    }

    return 'multi_site_child_sites';
  });

  const handleViewTypeChange = useCallback((e, { value }) => {
    setViewType(value);
  }, []);

  const handleBreadcrumbNavigation = useCallback(view => {
    switch (view) {
      case 'PROJECT_SUITE':
        if (mode === 'ACTION_SELECTION') {
          setViewType('multi_site_child_sites');
          setSelectedProject(null);
          onDeviceSelected({});
        }

        if (mode === 'GROUP_SELECTION') {
          setViewType('multi_site_child_sites');
          setSelectedProject(null);
          onDeviceSelected({});
        }

        break;
      case 'PROJECT':
        if (mode === 'ACTION_SELECTION') {
          setViewType('site_tasks');
          onDeviceSelected({});
        }

        if (mode === 'GROUP_SELECTION') {
          setViewType('site_devices');
          onDeviceSelected({});
        }

        break;

      default:
        break;
    }
  }, [onDeviceSelected, mode]);

  const handleProjectSelected = useCallback(newProject => {
    setSelectedProject(newProject);

    if (mode === 'GROUP_SELECTION') {
      setViewType('site_devices');
    } else {
      setViewType('site_tasks');
    }

  }, [mode]);

  const dropdown = useMemo(() => {
    if (!selectedProject) {
      const filteredOptions = !isNil(tabs)
        ? multiSiteOptions.filter(option => tabs.includes(option.value))
        : multiSiteOptions;

      return (
        <ButtonGroup
          value={viewType}
          options={filteredOptions}
          onChange={handleViewTypeChange}
        />
      );
    }

    if (selectedProject && isEmpty(selectedDevice)) {
      const filteredOptions = !isNil(tabs)
        ? options.filter(option => tabs.includes(option.value))
        : options;

      return (
        <ButtonGroup
          value={viewType}
          options={filteredOptions}
          onChange={handleViewTypeChange}
        />
      );
    }

    return null;
  }, [handleViewTypeChange, selectedDevice, selectedProject, viewType, tabs]);

  const activeView = useMemo(() => {
    if (!selectedProject && viewType === 'multi_site_child_sites') {
      return (
        <ProjectsList
          projects={projects}
          onProjectSelect={handleProjectSelected}
        />
      );
    }

    if (!selectedProject && viewType === 'multi_site_tasks') {
      return (
        <TaskHistory
          selectedResourceId={projectSuite ? projectSuite.id : null}
          resourceType="projectSuite"
        />
      );
    }

    if (viewType === 'site_tasks') {
      return (
        <TaskHistory
          selectedResourceId={selectedProject ? selectedProject.id : null}
        />
      );
    }

    if (viewType === 'site_devices' && isEmpty(selectedDevice)) {
      return (
        <DevicesList
          selectedProjectId={selectedProject.id}
        />
      );
    }

    if (mode === 'GROUP_SELECTION' && viewType === 'site_devices' && !isEmpty(selectedDevice)) {
      return (
        <AvailableGroups
          resourceType={resourceType}
          selectedDevice={selectedDevice}
          selectedGroups={selectedGroups}
          onGroupSelected={onGroupSelected}
        />
      );
    }

    if (viewType === 'site_devices' && !isEmpty(selectedDevice)) {
      return (
        <AvailableActions
          resourceType={resourceType}
          selectedDevice={selectedDevice}
          onActionSelected={onActionSelected}
        />
      );
    }

    if (viewType === 'site_actions') {
      return (
        <SiteAvailableActions
          resourceType={resourceType}
          selectedSite={selectedProject}
          onActionSelected={onActionSelected}
        />
      );
    }

    return null;
  }, [
    projects,
    selectedProject,
    selectedDevice,
    projectSuite,
    viewType,
    onActionSelected,
    handleProjectSelected,
    resourceType,
    selectedGroups,
    mode,
    onGroupSelected,
  ]);

  return (
    <>
      <CustomBreadcrumb
        projectSuite={projectSuite}
        project={selectedProject}
        logicalDevice={selectedDevice}
        onBreadcrumbNavigation={handleBreadcrumbNavigation}
      />
      {dropdown}
      {activeView}
    </>
  );
}

TasksActionsNavigation.defaultProps = {
  project: null,
  projects: [],
  projectSuite: null,
  resourceType: 'project',
  mode: 'ACTION_SELECTION',
  tabs: null,
  defaultViewType: null,
  selectedGroups: [],
  onGroupSelected: null,
};

TasksActionsNavigation.propTypes = {
  project: PropTypes.object,
  projects: PropTypes.array,
  projectSuite: PropTypes.object,
  selectedDevice: PropTypes.object.isRequired,
  selectedGroups: PropTypes.array,
  onActionSelected: PropTypes.func.isRequired,
  onGroupSelected: PropTypes.func,
  onDeviceSelected: PropTypes.func.isRequired,
  resourceType: PropTypes.string,
  defaultViewType: PropTypes.string,
  tabs: PropTypes.array,
  mode: PropTypes.oneOf(['ACTION_SELECTION', 'GROUP_SELECTION']),
};

export default TasksActionsNavigation;
