import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/atomic';
import pluralize from 'pluralize';

import pathwayConstants from 'layouts/Pathway/constants';

const PathwayPathportPortsV1Discover = ({
  element: { resourceId, id },
  online,
  rdmActive,
  rdmDevices,
  onPerformAction,
}) => {
  if (!rdmActive) return <div />;

  const handleClick = event => {
    event.stopPropagation();

    onPerformAction({
      deviceId: resourceId,
      actionType: pathwayConstants.ACTION_RDM_DISCOVER,
      values: { portId: parseInt(id, 0) },
    });
  };

  return (
    <div>
      <span>{`${rdmDevices} RDM ${pluralize('Device', rdmDevices)}`}</span>
      <Button primary disabled={!online} onClick={handleClick}>Discover</Button>
    </div>
  );
};

PathwayPathportPortsV1Discover.propTypes = {
  element: PropTypes.shape({
    resourceId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  online: PropTypes.bool.isRequired,
  rdmActive: PropTypes.bool.isRequired,
  rdmDevices: PropTypes.number.isRequired,
  onPerformAction: PropTypes.func.isRequired,
};

export default PathwayPathportPortsV1Discover;
