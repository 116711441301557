import { connect } from 'react-redux';

import { actionCreators, selectors } from '../../../../../utilities';
import Monthly from './Monthly';

export const mapStateToProps = state => ({
  recurrenceErrors: selectors.recurrenceErrorsSelector(state),
  recurrenceParams: selectors.recurrenceParamsSelector(state),
  existingSchedule: selectors.existingScheduleSelector(state),
});

export const mapDispatchToProps = dispatch => ({
  onRecurrenceParamsChange: value => dispatch(actionCreators.setRecurrenceParams(value)),
  onEndingCriteriaChange: value => dispatch(actionCreators.setEndingCriteria(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Monthly);
