import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const fetchProjectsStatus = (userId, type, urlParams) => ({
  type: constants.FETCH_PROJECTS_STATUS_REQUEST,
  payload: {
    userId,
    endpoint: type === 'user' ? endpoints.USER_PROJECTS(userId) : endpoints.PROJECTS,
    urlParams,
  },
});

export const fetchProjectsStatusSuccess = response => ({
  type: constants.FETCH_PROJECTS_STATUS_SUCCESS,
  payload: {
    response,
  },
});

export const fetchProjectsStatusFailure = error => ({
  type: constants.FETCH_PROJECTS_STATUS_FAILED,
  payload: { error },
});

export default fetchProjectsStatus;
