import { connect } from 'react-redux';
import { compose } from 'redux';

import { valueOverrideSelector } from 'helpers/selectors';
import withValueOverride from './withValueOverride';

export const mapStateToProps = (state, { overrideId }) => {
  const override = valueOverrideSelector(state)(overrideId);
  if (!override) return {};
  return { override };
};

export default compose(
  connect(mapStateToProps),
  withValueOverride,
);
