import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Tab, Form } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';

import { Button } from 'components/atomic';

import { AstroFormRow, AstroFormPreview } from './components';
import { StyledLabel, StyledRow, StyledMessage } from './AstronomicalView.style';

export default function AstronomicalView({ selectedTimes, onTimeChange, onRemoveTimeCallback }) {
  const [values, setValues] = useState(() => selectedTimes.map(time => {
    const [type, value] = time.split('_');

    return {
      type,
      value: Number(value),
    };
  }));

  const sunriseValues = useMemo(() => values.filter(item => item.type === 'sunrise'), [values]);
  const sunsetValues = useMemo(() => values.filter(item => item.type === 'sunset'), [values]);

  const handleAdd = useCallback((value, type) => {
    const index = values.findIndex(item => item.type === type && item.value === value);

    if (index === -1) {
      const canAddItems = (type === 'sunrise' && sunriseValues.length < 5) || (type === 'sunset' && sunsetValues.length < 5);

      if (canAddItems) {
        setValues(previousState => [...previousState, { type, value }]);
      }
    }
  }, [sunriseValues.length, sunsetValues.length, values]);

  const handleRemove = useCallback((value, type) => {
    const index = values.findIndex(item => item.type === type && item.value === value);
    const newState = Array.from(values);

    newState.splice(index, 1);

    setValues(newState);

    if (onRemoveTimeCallback) {
      onRemoveTimeCallback(value, type);
    }
  }, [values, onRemoveTimeCallback]);

  const handleTimeSelected = useCallback(() => {
    if (isEmpty(values)) {
      return;
    }

    const formattedSunriseValues = sunriseValues.map(item => `sunrise_${item.value}`);
    const formattedSunsetValues = sunsetValues.map(item => `sunset_${item.value}`);

    onTimeChange(
      [...new Set([...selectedTimes, ...formattedSunriseValues, ...formattedSunsetValues])],
    );
  }, [selectedTimes, sunriseValues, sunsetValues, values, onTimeChange]);

  const renderErrorMessage = useCallback(items => {
    if (items.length >= 5) {
      return (
        <StyledMessage>Maximum limit of 5 values reached.</StyledMessage>
      );
    }

    return null;
  }, []);

  return (
    <Form onSubmit={handleTimeSelected}>
      <Grid as={Tab.Pane}>
        <AstroFormRow name="astro-sunrise-row" label="Sunrise (Offset)" type="sunrise" onAdd={handleAdd} />
        {renderErrorMessage(sunriseValues)}
        <AstroFormRow name="astro-sunset-row" label="Sunset (Offset)" type="sunset" onAdd={handleAdd} />
        {renderErrorMessage(sunsetValues)}
        <StyledRow>
          <Grid.Column width={16}>
            <Form.Field>
              <StyledLabel htmlFor="input">Preview</StyledLabel>
              <AstroFormPreview
                name="preview-sunrise"
                label="Sunrise"
                values={sunriseValues}
                onRemove={handleRemove}
              />
              <AstroFormPreview
                name="preview-sunset"
                label="Sunset"
                values={sunsetValues}
                onRemove={handleRemove}
              />
            </Form.Field>
            <Button primary disabled={isEmpty(values)} type="submit">
              Confirm
            </Button>
          </Grid.Column>
        </StyledRow>
      </Grid>
    </Form>
  );
}

AstronomicalView.defaultProps = {
  selectedTimes: [],
  onRemoveTimeCallback: null,
};

AstronomicalView.propTypes = {
  selectedTimes: PropTypes.arrayOf(PropTypes.string),
  onTimeChange: PropTypes.func.isRequired,
  onRemoveTimeCallback: PropTypes.func,
};
