import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import AccordionTable from 'components/AccordionTable/AccordionTable';
import PathwayPathportPortsV1Discover from '../PathwayPathportPortsV1Discover/PathwayPathportPortsV1DiscoverContainer';
import PathwayPathportPortsV1Output from './PathwayPathportPortsV1Output';
import PathwayPathportPortsV1RDM from './PathwayPathportPortsV1RDM';

const Ports = ({ online, columns, data }) => (
  <AccordionTable
    columns={columns}
    action={PathwayPathportPortsV1Discover}
    data={data}
    render={element => (
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} computer={8}>
            <PathwayPathportPortsV1Output online={online} element={element} />
          </Grid.Column>
          <Grid.Column mobile={16} computer={8}>
            <PathwayPathportPortsV1RDM online={online} element={element} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )}
  />
);

Ports.propTypes = {
  online: PropTypes.bool.isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default Ports;
