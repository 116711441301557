import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  permissionsProjectSuitesSelector,
  projectSuiteResourcePermissionPathsSelector,
} from 'helpers/selectors';
import { permissionsActionCreators } from '../../../../../../utilities';

import ProjectSuitesRow from './ProjectSuitesRow';

export const mapStateToProps = (state, ownProps) => {
  const { item: { id: projectSuiteId } } = ownProps;
  const projectSuites = permissionsProjectSuitesSelector(state);
  const highlighted = projectSuites && projectSuiteId in projectSuites;

  return {
    highlighted,
    paths: projectSuiteResourcePermissionPathsSelector(state),
  };
};

const mapDispatchToProps = {
  onAddProjectSuiteId: permissionsActionCreators.addProjectSuiteId,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ProjectSuitesRow);
