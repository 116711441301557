import { call, put } from 'redux-saga/effects';
import merge from 'lodash/merge';

import { extractResourceType } from 'helpers/sharedMethods';
import { TENANT } from 'storageConst';
import dataActionCreators from 'data/dataActionCreators';
import AsyncErrorParser from './AsyncErrorParser';

export function* processSuccessfulCall({
  resolveForm,
  successDisp,
  nextInChain,
  response,
  resourceType,
}) {
  if (resolveForm) { resolveForm(); } // redux-form submit validation
  if (successDisp) { yield put(successDisp(response, resourceType)); }
  if (nextInChain) { yield put(nextInChain); }
}

export function* processFailedCall({
  rejectForm,
  failDisp,
  stopChain,
  error,
  requester,
  endpoint,
  id,
  clearSession,
  componentName,
  resourceType,
}) {
  if (clearSession) { localStorage.clear(); }

  const errors = new AsyncErrorParser(error);

  if (rejectForm) { rejectForm(errors.toFormError); } // redux-form submit validation
  if (failDisp) {
    if (errors.notFound) {
      const type = extractResourceType(endpoint);
      yield put(dataActionCreators.resourceNotFound(id, type));
    }

    const failAction = failDisp(errors, requester);
    const enhancedPayload = {
      payload: {
        resourceType,
        componentName,
      },
    };
    const enhancedFailAction = merge(failAction, enhancedPayload);

    yield put(enhancedFailAction);
  }

  if (stopChain) { yield put(stopChain); }
}

export function* processCall(params) {
  const { response } = params;

  if (response) {
    yield call(processSuccessfulCall, params);
  } else {
    yield call(processFailedCall, params);
  }
}

export const actionWithIdSelector = (type, id) => action => (
  action.type === type && action.payload.id === id
);

export const actionWithResponseDataIdSelector = (type, id) => action => (
  action.type === type && action.payload.response.data.id === id
);

export function addTenantToIdentifier(action) {
  if (!action.payload) {
    return action;
  }

  const { identifier } = action.payload;
  const tenant = localStorage.getItem(TENANT) || '';

  const enhancedAction = {
    ...action,
    payload: {
      ...action.payload,
      identifier: {
        ...identifier,
        tenant,
      },
    },
  };

  return enhancedAction;
}

export function actionList(...actions) {
  return ({ type }) => actions.indexOf(type) !== -1;
}
