import { compose } from 'redux';
import { reduxForm, getFormValues, change, formValueSelector } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import isNil from 'lodash/isNil';

import formNames from 'components/form/formNames';
import { validateOnSubmit } from 'components/form/formConfigs';
import { computeDropdownOptions } from 'helpers/sharedMethods';
import { selectors } from '../../../../utilities';
import { getDeviceModels } from './utilities';
import AddDeviceFirmwareModalForm from './AddDeviceFirmwareModalForm';
import { firmwareFormFields } from './constants';

export const mapStateToProps = state => {
  let selectedDeviceModels = null;
  const manufacturers = selectors.manufacturersSelector(state) || [];
  const manufacturersOptions = computeDropdownOptions(manufacturers);
  const selector = getFormValues(formNames.addDeviceFirmware);
  const formValues = selector(state);
  const deviceTypeOptions = getDeviceModels(formValues, manufacturers);
  if (!isNil(formValues) && formValues.deviceModels) {
    const { deviceModels } = formValues;
    selectedDeviceModels = deviceModels;
  }

  const files = formValueSelector(formNames.addDeviceFirmware)(state, firmwareFormFields.files);

  return {
    files,
    manufacturersOptions,
    deviceTypeOptions,
    selectedDeviceModels,
  };
};

export const mapDispatchToProps = dispatch => ({
  resetFieldValue: field => {
    dispatch(change(formNames.addDeviceFirmware, field, undefined));
  },
  changeFieldValue: (field, value) => {
    dispatch(change(formNames.addDeviceFirmware, field, value));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formNames.addDeviceFirmware,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    ...validateOnSubmit,
  }),
)(AddDeviceFirmwareModalForm);
