import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

const CommentField = ({ input: { onChange, name } }) => (
  <Input
    onChange={onChange}
    name={name}
    placeholder="Your comment"
    type="text"
  />
);

CommentField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
};

export default CommentField;
