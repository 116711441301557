import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

import { mediaMax } from 'themes/mixins';

export const StyledDayViewTable = styled.div`
  flex: 1;
  flex-basis: fit-content;
`;

export const StyledDescription = styled.p`
  margin: 0;
  display: inline;

  ${mediaMax.sm`
    font-size: 13px;
  `};
`;

export const StyledButtonGroup = styled(Button.Group)`
  margin-left: auto !important;
`;

export default StyledDayViewTable;
