import { Icon, Menu } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { injectStyles, media } from 'themes/mixins';
import sidebarConst from 'themes/variables';
import colors from 'themes/colors';
import filterProps from 'helpers/filterProps';

export const StyledTopBar = styled(
  filterProps(['simple', 'padded', 'control', 'isPageNavigationOpen'])(Menu),
).attrs(() => ({
  fixed: 'top',
  borderless: true,
}))`
  &.ui.menu {
    ${({ simple }) => simple && css`
      border: 0;
      box-shadow: none;
    `};
    ${media.md`
      min-height: 4.571em;
      ${({ padded }) => padded && css`padding-left: ${sidebarConst.width.md};`};
    `};
    ${({ control }) => control && 'background-color: transparent;'}

    ${({ isPageNavigationOpen }) => isPageNavigationOpen && css`
      z-index: 0 !important;
    `};

  }
`;

StyledTopBar.Icon = styled(Icon)`
  i&.icon {
    height: 0.85714286em;
  }
`;

export const UpdateNotification = styled.div`
  /* border is required due to Chrome visual glitch */
  border: 1px solid ${colors.white};
  background: ${colors.white};

  ${({ theme: { updateNotificationPanel } }) => injectStyles(updateNotificationPanel)};

  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  display: none;
  padding: 1rem 0;
  box-shadow: 0 1px 2px 0 ${colors.boxShadow};
  opacity: 0.8;
  transition: margin 500ms ease, width 500ms ease;
  ${({ padded }) => padded && media.md`padding-left: ${sidebarConst.width.md};`};
`;
