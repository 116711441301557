import React from 'react';
import PropTypes from 'prop-types';

import TableMenu from 'components/DataTable/TableMenu.style';

import { RemoveUserActionLink } from './components';

const ActionLinks = ({ chosenItem, ...rest }) => (
  <TableMenu.Item>
    <TableMenu.Item>
      <RemoveUserActionLink item={chosenItem} {...rest} />
    </TableMenu.Item>
  </TableMenu.Item>
);

ActionLinks.defaultProps = {
  chosenItem: null,
};

ActionLinks.propTypes = {
  chosenItem: PropTypes.object,
};

export default ActionLinks;
