export const companyFormFields = {
  name: 'name',
  registeredName: 'registeredName',
  email: 'email',
  tenantService: 'tenantService',
  subdomain: 'subdomain',
  themeName: 'themeName',
  deviceMapping: 'deviceMapping',
  manufacturer: 'manufacturer',
  mailgunDomain: 'mailgunDomain',
  twoFactorEnabled: 'twoFactorEnabled',
  trialPeriodDays: 'trialPeriodDays',
  gracePeriodDays: 'gracePeriodDays',
  renewalReminderDays: 'renewalReminderDays',
  vouchersEnabled: 'vouchersEnabled',
  renewalReminderDaysTwo: 'renewalReminderDaysTwo',
  renewalDate: 'renewalDate',
  notificationsEnabled: 'notificationsEnabled',
};

export default companyFormFields;
