import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

export const StyledDropdownArrow = styled(Icon)`
  &.dropdown.icon {
    vertical-align: unset;
    flex: 0;
    transform: rotate(-90deg);
    ${({ open }) => open && 'transform: none;'}
    transition: transform 0.2s;
  }

  .ui.button>&.icon:not(.button) {
    margin-top: 0.1em;
  }
`;

export default StyledDropdownArrow;
