import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import WarningMessage from './WarningMessage';
import { selectors } from '../../../../utilities';

export const mapStateToProps = (state, { match }) => {
  const { id: projectSuiteId } = match.params;
  const getSitesWithoutLocation = selectors.childProjectsWithoutLocationSelector(state)(
    projectSuiteId,
  );
  const sitesWithoutLocation = getSitesWithoutLocation || 0;

  return {
    sitesWithoutLocation,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
)(WarningMessage);
