import isNil from 'lodash/isNil';
import has from 'lodash/has';

const convertObjectToRGB = color => {
  if (isNil(color) || !(has(color, 'r') && has(color, 'g') && has(color, 'b'))) {
    return null;
  }

  return `rgb(${color.r}, ${color.g}, ${color.b})`;
};

export default convertObjectToRGB;
