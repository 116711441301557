/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';

function VoucherWarning({ sitePlanCode, voucherPlanCode }) {
  if (!sitePlanCode || sitePlanCode === 'Construction') {
    return null;
  }

  if (voucherPlanCode > sitePlanCode) {
    return (
      <Message info>
        <Message.Header>Information</Message.Header>
        <p>
          This voucher's subscription plan exceeds the required Site plan. <br />
          Projected renewal date includes any current subscription between now and the renewal date,
          adjusted to the voucher's subscription plan.
        </p>
      </Message>
    );
  }

  if (voucherPlanCode < sitePlanCode) {
    return (
      <Message negative>
        <Message.Header>Warning</Message.Header>
        <p>
          This voucher's subscription plan is lower than the required Site Plan.
          Projected subscription plan will match the required Site plan.
          <br />
          The projected renewal date reflects this voucher's subscription plan and length,
          adjusted to match the required Site plan.
        </p>
      </Message>
    );
  }

  return null;
}

VoucherWarning.defaultProps = {
  sitePlanCode: null,
};

VoucherWarning.propTypes = {
  sitePlanCode: PropTypes.string,
  voucherPlanCode: PropTypes.string.isRequired,
};

export default VoucherWarning;
