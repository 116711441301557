import constants from 'dispatcherConst';

export const setSchedulerName = schedulerName => ({
  type: constants.SET_SCHEDULER_NAME,
  payload: {
    schedulerName,
  },
});

export default setSchedulerName;
