import styled, { css } from 'styled-components';
import { lighten, rgba } from 'polished';

import { mediaMax } from 'themes/mixins';

import { Button } from 'components/atomic';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: center;
`;

export const RgbContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SlidersAndInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  ${mediaMax.sm`
    margin-left: 8px;
  `}
`;

export const SlidersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-left: 24px;
`;

export const Title = styled.h3`
  margin-bottom: 32px;

  ${({ labelTextColor: { r, g, b, a } }) => css`
    color: ${rgba(r, g, b, a)};
  `}
`;

export const StyledColorPreview = styled.div`
  width: 25px;
  height: 25px;
  padding: 0px;
  border-radius: 4px;
  margin-right: 7px;
  margin-bottom: 7px;
  transition: opacity 0.2s ease;
  background-color: red;
  cursor: pointer;

  ${({ color }) => color && css`
    background-color: ${color};
  `}
`;

export const StyledColorPreviewWrapper = styled.div`
  width: 31px;
  height: 31px;
  border: solid 1px #dedede;
  border-radius: 4px;
  padding: 2px;

  &:hover {
    border: dashed 1px #000000;
    border-radius: 0px;
  }
`;

export const StyledRunButton = styled(Button)`
  ${({ runButtonTextColor: { r, g, b, a } }) => css`
    color: ${rgba(r, g, b, a)} !important;
  `}

  ${({ runButtonBgColor: { r, g, b, a } }) => css`
    background-color: ${rgba(r, g, b, a)} !important;

    &:hover {
      background-color: ${lighten(0.1, rgba(r, g, b, a))} !important;
    }
  `}
`;

export const Spacing = styled.div`
  height: 24px;
`;

export default Container;
