import { connect } from 'react-redux';
import { getFormValues, change, initialize } from 'redux-form';

import { actionTypeSelector } from 'helpers/selectors';
import formNames from 'components/form/formNames';
import GenerateActionForm from 'application/tenant/console/site/components/structural/SiteSchedulingTab/components/AutomatedOperations/components/AddAutomatedOperation/components/ActionsStep/components/SelectedActions/components/ActionItem/components/GenerateActionForm/GenerateActionForm';

export const mapStateToProps = (state, { actionType }) => {
  const action = actionTypeSelector(state)(actionType);
  const selector = getFormValues(formNames.addNewAction);

  return {
    action,
    actionTypeDeviceIdKey: '',
    formValues: selector(state),
  };
};

export const mapDispatchToProps = dispatch => ({
  resetFieldValue: field => {
    dispatch(change(formNames.addNewAction, field, null));
  },
  initialize: initialValues => {
    dispatch(initialize(formNames.addNewAction, initialValues, true));
  },
  changeFieldValue: (field, value) => {
    dispatch(change(formNames.addNewAction, field, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GenerateActionForm);
