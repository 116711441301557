import { compose } from 'redux';

import { permissionsOrRedirect } from 'gateway';
import { DEVICE_FIRMWARE_FILES_FIELD } from 'helpers/selectors';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { addPagination } from 'helpers/paginationHelpers';
import { actionCreators } from '../../utilities';
import FirmwareFilesTable from './FirmwareFilesTable';

export default compose(
  chainedLoaderWithSaga(
    addPagination(
      {
        resourceType: DEVICE_FIRMWARE_FILES_FIELD,
        included: true,
      },
      () => actionCreators.fetchFirmwareFiles(),
    ),
  ),
  permissionsOrRedirect(),
)(FirmwareFilesTable);
