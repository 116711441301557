import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Responsive } from 'semantic-ui-react';

import { sizes } from 'themes/mixins';
import NavLink from 'components/NavLink/NavLinkContainer';
import BreadcrumbNavItem from './BreadcrumbNavItem/BreadcrumbNavItemContainer';
import { ThemedBreadcrumb, ThemedBreadcrumbWrapper } from './BreadcrumbNav.style';

export const MIN_COUNT = 2;

class BreadcrumbNav extends Component {

  renderDesktop() {
    const { breadcrumbs } = this.props;
    const { length } = breadcrumbs;

    if (length < MIN_COUNT) return null;

    return breadcrumbs.slice(0, -1).map((breadcrumb, index) => {
      const { key, match, breadcrumb: breadcrumbElement } = breadcrumb;
      const isDividerVisible = index !== length - MIN_COUNT;

      if (!match) {
        return null;
      }

      const { url } = match;

      return (
        <Fragment key={key}>
          <BreadcrumbNavItem
            as={NavLink}
            to={url}
          >
            {breadcrumbElement}
          </BreadcrumbNavItem>
          {isDividerVisible && <Breadcrumb.Divider icon="right angle" />}
        </Fragment>
      );
    });
  }

  renderMobile() {
    const { breadcrumbs } = this.props;
    const { length } = breadcrumbs;

    if (length < MIN_COUNT) return null;

    const stepBackBreadcrumb = breadcrumbs[length - MIN_COUNT];
    const { match, breadcrumb } = stepBackBreadcrumb;

    if (!match) {
      return null;
    }

    const { url } = match;

    return (
      <Fragment>
        <Breadcrumb.Divider icon="right angle" />
        <BreadcrumbNavItem
          as={NavLink}
          to={url}
        >
          {breadcrumb}
        </BreadcrumbNavItem>
      </Fragment>
    );
  }

  render() {
    return (
      <ThemedBreadcrumbWrapper>
        <ThemedBreadcrumb size="large">
          <Responsive as={Fragment} maxWidth={sizes.sm - 1}>
            {this.renderMobile()}
          </Responsive>
          <Responsive as={Fragment} minWidth={sizes.sm}>
            {this.renderDesktop()}
          </Responsive>
        </ThemedBreadcrumb>
      </ThemedBreadcrumbWrapper>
    );
  }

}

BreadcrumbNav.defaultProps = {
  breadcrumbs: [],
};

BreadcrumbNav.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.object.isRequired,
  ),
};

export default BreadcrumbNav;
