import constants from 'dispatcherConst';
import { messages } from 'data/notifications/notificationsConst';

export const refreshOTPSecret = payload => ({
  type: constants.REFRESH_OTP_SECRET,
  payload,
});

export const refreshOTPSecretSuccess = response => ({
  type: constants.REFRESH_OTP_SECRET_SUCCESS,
  payload: {
    response,
    message: messages.REFRESH_OTP_SECRET,
  },
});

export const refreshOTPSecretFailure = error => ({
  type: constants.REFRESH_OTP_SECRET_FAILED,
  payload: { error },
});
