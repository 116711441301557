import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import {
  TASKS_FIELD,
  ACTION_TYPES_FIELD,
  DEVICE_ACTION_TYPES_LISTS_FIELD,
  SITE_ACTION_TYPES_LIST_FIELD,
} from 'helpers/selectors';

const tasksActionCreators = {
  fetchTasks: (projectId, urlParams) => ({
    type: constants.FETCH_TASKS_REQUEST,
    payload: {
      endpoint: endpoints.PROJECT_TASKS(projectId),
      resourceType: TASKS_FIELD,
      projectId,
      urlParams,
    },
  }),

  fetchTasksSuccess: response => ({
    type: constants.FETCH_TASKS_SUCCESS,
    payload: {
      response,
      resourceType: TASKS_FIELD,
    },
  }),

  fetchTasksFailure: error => ({
    type: constants.FETCH_TASKS_FAILED,
    payload: { error },
  }),

  fetchTasksPaginated: response => ({
    type: constants.FETCH_TASKS_PAGINATED,
    payload: { response },
  }),

  addTask: params => ({
    type: constants.ADD_TASK_REQUEST,
    payload: {
      params,
      endpoint: endpoints.TASKS,
    },
  }),

  addTaskSuccess: response => ({
    type: constants.ADD_TASK_SUCCESS,
    payload: {
      response,
      message: messages.TASK_ADDED(response.data.attributes.name),
      resourceType: TASKS_FIELD,
      id: response.data.id,
    },
  }),

  addTaskFailure: error => ({
    type: constants.ADD_TASK_FAILED,
    payload: { error },
  }),

  fetchActionTypes: () => ({
    type: constants.FETCH_ACTION_TYPES_REQUEST,
    payload: {
      endpoint: endpoints.ACTION_TYPES,
      resourceType: ACTION_TYPES_FIELD,
    },
  }),

  fetchActionTypesSuccess: response => ({
    type: constants.FETCH_ACTION_TYPES_SUCCESS,
    payload: {
      response,
      resourceType: ACTION_TYPES_FIELD,
    },
  }),

  fetchActionTypesFailure: error => ({
    type: constants.FETCH_ACTION_TYPES_FAILED,
    payload: { error },
  }),

  addAction: params => ({
    type: constants.ADD_ACTION_REQUEST,
    payload: {
      params,
      endpoint: endpoints.EDIT_TASK(params.values.taskId),
    },
  }),

  addActionSuccess: response => ({
    type: constants.ADD_ACTION_SUCCESS,
    payload: {
      response,
      message: messages.ACTION_ADDED,
      resourceType: TASKS_FIELD,
      id: response.data.id,
    },
  }),

  addActionFailure: error => ({
    type: constants.ADD_ACTION_FAILED,
    payload: { error },
  }),

  addActionIncompleteForm: () => ({
    type: constants.ADD_ACTION_INCOMPLETE_FORM,
    payload: {
      message: messages.ACTION_EMPTY_FORM,
    },
  }),

  executeActions: ({
    resourceId,
    resourceType,
    actions,
  }) => ({
    type: constants.EXECUTE_ACTIONS_REQUEST,
    payload: {
      resourceId,
      resourceType,
      actions,
      endpoint: endpoints.EXECUTE_ACTIONS,
    },
  }),

  executeActionsSuccess: response => ({
    type: constants.EXECUTE_ACTIONS_SUCCESS,
    payload: {
      response,
      message: messages.ACTIONS_DISPATCHED,
    },
  }),

  executeActionsFailure: error => ({
    type: constants.EXECUTE_ACTIONS_FAILED,
    payload: {
      error,
      message: messages.ACTIONS_DISPATCHED_FAILED,
    },
  }),

  fetchDeviceActionTypesLists: logicalDeviceId => ({
    type: constants.FETCH_DEVICE_ACTION_TYPES_LISTS_REQUEST,
    payload: {
      endpoint: endpoints.DEVICE_ACTION_TYPES_LISTS(logicalDeviceId),
      resourceType: DEVICE_ACTION_TYPES_LISTS_FIELD,
      logicalDeviceId,
    },
  }),

  fetchDeviceActionTypesListsSuccess: (response, logicalDeviceId) => ({
    type: constants.FETCH_DEVICE_ACTION_TYPES_LISTS_SUCCESS,
    payload: {
      response,
      logicalDeviceId,
      resourceType: DEVICE_ACTION_TYPES_LISTS_FIELD,
    },
  }),

  fetchDeviceActionTypesListsFailure: error => ({
    type: constants.FETCH_DEVICE_ACTION_TYPES_LISTS_FAILED,
    payload: { error },
  }),

  fetchDeviceActionTypesListsNoData: logicalDeviceId => ({
    type: constants.FETCH_DEVICE_ACTION_TYPES_LISTS_NO_DATA,
    payload: {
      message: messages.NO_ACTIONS_FOR_DEVICE(logicalDeviceId),
    },
  }),

  removeTask: ({
    taskId,
    lockVersion,
    projectName,
    resourceId,
    resourceType,
    taskProjectId,
  }) => ({
    type: constants.REMOVE_TASK_REQUEST,
    payload: {
      taskId,
      endpoint: endpoints.EDIT_TASK(taskId),
      lockVersion,
      projectName,
      resourceId,
      resourceType,
      taskProjectId,
    },
  }),

  removeTaskSuccess: response => ({
    type: constants.REMOVE_TASK_SUCCESS,
    payload: {
      response,
      resourceType: TASKS_FIELD,
      message: messages.TASK_REMOVED(response.projectName),
    },
  }),

  removeTaskFailure: error => ({
    type: constants.REMOVE_TASK_FAILED,
    payload: { error },
  }),

  removeTaskFailureLockVersion: () => ({
    type: constants.REMOVE_TASK_FAILED_LOCK_VERSION,
    payload: {
      info: messages.ERROR_TASK_LOCK_VERSION_CHANGED,
    },
  }),

  removeAction: (taskId, index, taskName) => ({
    type: constants.REMOVE_ACTION_REQUEST,
    payload: {
      taskId,
      endpoint: endpoints.EDIT_TASK(taskId),
      index,
      taskName,
    },
  }),

  removeActionSuccess: response => ({
    type: constants.REMOVE_ACTION_SUCCESS,
    payload: {
      response,
      resourceType: TASKS_FIELD,
      message: messages.ACTION_REMOVED(response.taskName),
    },
  }),

  removeActionFailure: error => ({
    type: constants.REMOVE_ACTION_FAILED,
    payload: { error },
  }),

  triggerTask: taskId => ({
    type: constants.TRIGGER_TASK_REQUEST,
    payload: {
      endpoint: endpoints.EXECUTE_TASK(taskId),
    },
  }),

  triggerTaskSuccess: response => ({
    type: constants.TRIGGER_TASK_SUCCESS,
    payload: {
      response,
      message: messages.TASK_DISPATCHED,
    },
  }),

  triggerTaskFailure: error => ({
    type: constants.TRIGGER_TASK_FAILED,
    payload: { error },
  }),

  triggerMultipleTasks: taskIds => ({
    type: constants.TRIGGER_MULTIPLE_TASKS_REQUEST,
    payload: {
      endpoints: taskIds.map(taskId => endpoints.EXECUTE_TASK(taskId)),
    },
  }),

  triggerMultipleTasksSuccess: response => ({
    type: constants.TRIGGER_MULTIPLE_TASKS_SUCCESS,
    payload: {
      response,
      message: messages.TASKS_DISPATCHED,
    },
  }),

  triggerMultipleTasksFailure: error => ({
    type: constants.TRIGGER_MULTIPLE_TASKS_FAILED,
    payload: { error },
  }),

  selectionReachedMax: () => ({
    type: constants.SELECTION_REACHED_MAX,
    payload: {
      message: messages.SELECTION_REACHED_MAX,
    },
  }),

  editAction: params => ({
    type: constants.EDIT_ACTION_REQUEST,
    payload: {
      params,
      endpoint: endpoints.EDIT_TASK(params.values.taskId),
    },
  }),

  editActionSuccess: response => ({
    type: constants.EDIT_ACTION_SUCCESS,
    payload: {
      response,
      message: messages.ACTION_UPDATED,
      resourceType: TASKS_FIELD,
      id: response.data.id,
    },
  }),

  editActionFailure: error => ({
    type: constants.EDIT_ACTION_FAILED,
    payload: { error },
  }),

  updateTask: ({ id, message, ...args }) => ({
    type: constants.UPDATE_TASK_REQUEST,
    payload: {
      id,
      ...args,
      message,
      endpoint: endpoints.EDIT_TASK(id),
    },
  }),

  updateTaskSuccess: response => ({
    type: constants.UPDATE_TASK_SUCCESS,
    payload: {
      response,
      message: response.message,
      resourceType: TASKS_FIELD,
    },
  }),

  updateTaskFailure: () => ({
    type: constants.UPDATE_TASK_FAILED,
    payload: {
      message: messages.TASK_TEMPORARY_ERROR,
    },
  }),

  editTask: ({ id, lockVersion, ...args }) => ({
    type: constants.EDIT_TASK_REQUEST,
    payload: {
      id,
      lockVersion,
      ...args,
      endpoint: endpoints.EDIT_TASK(id),
    },
  }),

  updateTaskProperty: ({ id, property, ...args }) => ({
    type: constants.UPDATE_TASK_PROPERTY_REQUEST,
    payload: {
      id,
      property,
      ...args,
      message: messages.TASK_UPDATED,
      endpoint: endpoints.EDIT_TASK(id),
    },
  }),

  updateActionProperty: ({ id, property, ...args }) => ({
    type: constants.UPDATE_ACTION_PROPERTY_REQUEST,
    payload: {
      id,
      property,
      ...args,
      message: messages.TASK_UPDATED,
      endpoint: endpoints.EDIT_TASK(id),
    },
  }),

  fetchSiteActionTypesList: projectId => ({
    type: constants.FETCH_SITE_ACTION_TYPES_LIST_REQUEST,
    payload: {
      endpoint: endpoints.SITE_ACTION_TYPES_LIST(projectId),
      resourceType: SITE_ACTION_TYPES_LIST_FIELD,
      projectId,
    },
  }),

  fetchSiteActionTypesListSuccess: (response, projectId) => ({
    type: constants.FETCH_SITE_ACTION_TYPES_LIST_SUCCESS,
    payload: {
      response,
      projectId,
      resourceType: SITE_ACTION_TYPES_LIST_FIELD,
    },
  }),

  fetchSiteActionTypesListFailure: error => ({
    type: constants.FETCH_SITE_ACTION_TYPES_LIST_FAILED,
    payload: { error },
  }),
};

export default tasksActionCreators;
