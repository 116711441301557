import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const archiveAllNotificationEvents = () => ({
  type: constants.ARCHIVE_ALL_NOTIFICATION_EVENTS,
  payload: {
    endpoint: endpoints.NOTIFICATION_EVENT_ARCHIVE_ALL,
  },
});

export const archiveAllNotificationEventsSuccess = response => ({
  type: constants.ARCHIVE_ALL_NOTIFICATION_EVENTS_SUCCESS,
  payload: {
    response,
  },
});

export const archiveAllNotificationEventsFailure = error => ({
  type: constants.ARCHIVE_ALL_NOTIFICATION_EVENTS_FAILED,
  payload: { error },
});

export default archiveAllNotificationEvents;
