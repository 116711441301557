import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import isString from 'lodash/isString';

import Form from 'components/form/Form/Form';
import { Button } from 'components/atomic';

const ModalFormV2 = ({
  children,
  handleSubmit,
  header,
  size,
  submitting,
  trigger,
  triggerSize,
  closeOnDimmerClick,
  initialValues,
  initialize,
  form,
  destroy,
  destroyForm,
  submitSucceeded,
  reset,
}) => {

  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = e => {
    e.stopPropagation();
    initialize(initialValues);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    if (destroyForm) {
      destroy(form);
    }
  };

  useEffect(() => {
    if (submitSucceeded) {
      reset();
      setModalOpen(false);
      if (destroyForm) {
        destroy(form);
      }
    }
  }, [submitSucceeded, reset, form, destroy, destroyForm]);

  const modalTrigger = isString(trigger)
    ? (<div><Button primary onClick={handleOpen} size={triggerSize || 'small'}>{trigger}</Button></div>)
    : (<div><trigger.type {...trigger.props} onClick={handleOpen} /></div>);

  return (
    <Modal
      open={modalOpen}
      size={size || 'mini'}
      onSubmit={handleSubmit}
      onClose={handleClose}
      trigger={modalTrigger}
      closeOnDimmerClick={closeOnDimmerClick}
      onClick={e => e.stopPropagation()}
    >
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <Form id="modal-form">
          {children}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          name="cancel"
          type="button"
          primary
          basic
          onClick={handleClose}
          disabled={submitting}
        >
          Cancel
        </Button>
        <Button primary type="submit" form="modal-form" disabled={submitting}>
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ModalFormV2.defaultProps = {
  destroyForm: false,
};

ModalFormV2.propTypes = {
  ...Modal.propTypes,
  children: PropTypes.node.isRequired,
  header: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  trigger: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  destroyForm: PropTypes.bool,
};

export default ModalFormV2;
