import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

const StyledButton = styled(Button)`
  float: right;
`;

export {
  StyledButton,
};

export default StyledButton;
