import jsDateToDateTime from './jsDateToDateTime';

const isDateGreaterThanOneYear = (selectedDate, zone) => {
  if (!selectedDate) {
    return false;
  }

  const now = jsDateToDateTime(new Date(), zone);
  let { days } = now.diff(selectedDate, ['days']).toObject();

  days = days < 0 ? days * -1 : days;

  if (days <= 365) {
    return false;
  }

  return true;
};

export default isDateGreaterThanOneYear;
