import styled from 'styled-components';
import { Divider } from 'semantic-ui-react';

export const StyledDivider = styled(Divider)`
  &.ui.divider {
    margin-top: 40px;
  }
`;

export default StyledDivider;
