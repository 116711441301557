import styled from 'styled-components';
import { Field } from 'redux-form';
import { FormGroup } from 'semantic-ui-react';

export const StyledField = styled(Field)`
  padding-left: 4px;
`;

export const StyledFormGroup = styled(FormGroup)`
  & .ui.negative.message p {
    padding-left: 4px;
  }
`;

export const StyledParagraph = styled.p`
  margin-bottom: 20px !important;
`;
