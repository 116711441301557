import {
  DEVICE_REFRESH,
  LOGICAL_DEVICES_FIELD,
} from 'helpers/selectors';
import endpoints from 'data/api/endpoints';

export const updatersConfig = {
  [DEVICE_REFRESH]: resourceId => ({
    urlParams: {
      fields: {
        [LOGICAL_DEVICES_FIELD]: [
          'updated',
        ],
      },
    },
    endpoint: endpoints.LOGICAL_DEVICE(resourceId),
  }),
};

export default updatersConfig;
