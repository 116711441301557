import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import isNil from 'lodash/isNil';

import formNames from 'components/form/formNames';
import { validateOnSubmit } from 'components/form/formConfigs';
import { deviceSelector } from 'helpers/selectors';
import PharosTimeAdjustmentV1Form from './PharosTimeAdjustmentV1Form';

const PharosTimeAdjustmentV1FormContainer = reduxForm({
  ...validateOnSubmit,
  form: formNames.timeAdjustmentV1,
  enableReinitialize: true,
})(PharosTimeAdjustmentV1Form);

export const mapStateToProps = (state, ownProps) => {
  const { resourceId } = ownProps;
  const {
    online,
    reportedWithMeta,
    reportedWithMeta: {
      clk: {
        now: currentTime,
        tz: offset,
      } = {},
    } = {},
  } = deviceSelector(state)(resourceId);

  return {
    shouldDisplay: !isNil(reportedWithMeta),
    resourceOnline: online,
    resourceCurrentTime: currentTime,
    resourceOffset: offset,
  };
};

export default connect(mapStateToProps)(PharosTimeAdjustmentV1FormContainer);
