import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';

import { LOGICAL_DEVICES_FIELD } from 'helpers/selectors';
import { StyledInlineEditTitle } from 'components/elements/StyledInlineEditTitle/StyledInlineEditTitle.style';
import { HeaderWrapper } from 'components/elements';
import LayoutRenderer from 'layouts/LayoutRenderer/LayoutRendererContainer';
import { SixEyeVersionBanner } from 'application/tenant/console/device/components/atomic';
import InlineEditDevice from './InlineEditDevice/InlineEditDeviceContainer';
import { getSiteStateMessage } from './utilities';
import ManufacturerLabel, { StyledDiv } from './Device.style';

const Device = ({
  deviceId,
  deviceName,
  deviceStatus,
  model,
  manufacturerName,
  isConstruction,
  isSuspended,
  siteState,
  stateLoaded,
  online,
}) => {
  if (!deviceName) {
    return null;
  }

  const renderLayoutBasedOnSiteState = () => {
    if (!stateLoaded) {
      return null;
    }

    if (isConstruction || isSuspended) {
      return <Header as="h4">{getSiteStateMessage(siteState)}</Header>;
    }

    return (
      <LayoutRenderer
        resourceType={LOGICAL_DEVICES_FIELD}
        resourceId={deviceId}
      />
    );
  };

  return (
    <Fragment>
      <HeaderWrapper>
        <StyledInlineEditTitle as="h1" size="medium">
          <StyledDiv>Device:</StyledDiv>
          <InlineEditDevice
            deviceName={deviceName}
            deviceId={deviceId}
          />
        </StyledInlineEditTitle>
        <Header as="h3">{deviceStatus}</Header>
      </HeaderWrapper>
      <ManufacturerLabel>{model} - {manufacturerName}</ManufacturerLabel>
      <SixEyeVersionBanner deviceId={deviceId} online={online} />
      {renderLayoutBasedOnSiteState()}
    </Fragment>
  );
};

Device.defaultProps = {
  deviceName: null,
  deviceStatus: null,
  model: null,
  manufacturerName: null,
  isConstruction: null,
  isSuspended: null,
  siteState: null,
  stateLoaded: null,
  online: false,
};

Device.propTypes = {
  deviceId: PropTypes.string.isRequired,
  deviceName: PropTypes.string,
  deviceStatus: PropTypes.node,
  manufacturerName: PropTypes.string,
  model: PropTypes.string,
  isConstruction: PropTypes.bool,
  isSuspended: PropTypes.bool,
  stateLoaded: PropTypes.bool,
  siteState: PropTypes.string,
  online: PropTypes.bool,
};

export default Device;
