import styled from 'styled-components';
import { lighten } from 'polished';
import get from 'lodash/get';

import DataTableRow from 'components/DataTable/DataTableRow/DataTableRow';

export const StyledContextsRow = styled(DataTableRow)`
  ${({ highlighted, theme }) => highlighted && `
    background-color: ${lighten(0.4, get(theme, 'palette.primaryColor'))};
  `}
`;

export default StyledContextsRow;
