import has from 'lodash/has';

const selectedResourceIdsSelector = (state, resourceType) => {
  const { selectedResourceIds } = state.permissionsTable;

  if (!has(selectedResourceIds, resourceType)) {
    return [];
  }

  return selectedResourceIds[resourceType];
};

export default selectedResourceIdsSelector;
