export const actionTypes = {
  'created:af': {
    text: 'Task Scheduler Function created',
    hidden: true,
    superadminOnly: true,
  },
  'created:ao': {
    text: 'Task Scheduler created',
    hidden: false,
    superadminOnly: false,
  },
  'created:schedule': {
    text: 'Schedule created',
    hidden: false,
    superadminOnly: false,
  },
  'deleted:af': {
    text: 'Task Scheduler Function deleted',
    hidden: true,
    superadminOnly: true,
  },
  'deleted:ao': {
    text: 'Task Scheduler deleted',
    hidden: false,
    superadminOnly: false,
  },
  'deleted:schedule': {
    text: 'Schedule deleted',
    hidden: false,
    superadminOnly: false,
  },
  'soft_deleted:ao': {
    text: 'Task Scheduler deleted',
    hidden: true,
    superadminOnly: false,
  },
  'updated:af': {
    text: 'Task Scheduler Function updated',
    hidden: true,
    superadminOnly: true,
  },
  'updated:ao': {
    text: 'Task Scheduler updated',
    hidden: false,
    superadminOnly: false,
  },
  'updated:schedule': {
    text: 'Schedule updated',
    hidden: false,
    superadminOnly: false,
  },
  'accepted:invitation': {
    text: 'Invitation accepted',
    hidden: false,
    superadminOnly: false,
  },
  'authenticated:user': {
    text: 'User authenticated',
    hidden: false,
    superadminOnly: false,
  },
  'changed_password:user': {
    text: 'User changed password',
    hidden: true,
    superadminOnly: false,
  },
  'changed_subscription_plan:project': {
    text: 'Site subscription plan converted',
    hidden: true,
    superadminOnly: false,
  },
  'create_relationship:project_suite': {
    text: 'Multi-site linked resource',
    hidden: true,
    superadminOnly: false,
  },
  'created:company': {
    text: 'Company created',
    hidden: true,
    superadminOnly: false,
  },
  'created:control_panel_page': {
    text: 'Control Panel Page created',
    hidden: true,
    superadminOnly: false,
  },
  'created:file_upload': {
    text: 'File uploaded',
    hidden: false,
    superadminOnly: false,
  },
  'created:logical_device': {
    text: 'Device added',
    hidden: false,
    superadminOnly: false,
  },
  'created:notification': {
    text: 'Notification created',
    hidden: false,
    superadminOnly: false,
  },
  'created:origin': {
    text: 'Origin created',
    hidden: true,
    superadminOnly: false,
  },
  'created:project': {
    text: 'Site created',
    hidden: false,
    superadminOnly: false,
  },
  'created:project_suite': {
    text: 'created:project_suite',
    hidden: true,
    superadminOnly: false,
  },
  'deleted:company': {
    text: 'Deleted company',
    hidden: true,
    superadminOnly: false,
  },
  'deleted:control_panel_page': {
    text: 'Control Panel Page deleted',
    hidden: true,
    superadminOnly: false,
  },
  'deleted:file_upload': {
    text: 'File deleted',
    hidden: false,
    superadminOnly: false,
  },
  'deleted:logical_device': {
    text: 'Device deleted',
    hidden: false,
    superadminOnly: false,
  },
  'deleted:notification': {
    text: 'Notification deleted',
    hidden: false,
    superadminOnly: false,
  },
  'deleted:origin': {
    text: 'Origin deleted',
    hidden: true,
    superadminOnly: false,
  },
  'deleted:project': {
    text: 'Site deleted',
    hidden: true,
    superadminOnly: false,
  },
  'deleted:project_suite': {
    text: 'Multi-site deleted',
    hidden: true,
    superadminOnly: false,
  },
  'destroy_relationship:project': {
    text: 'Site unlinked resource',
    hidden: false,
    superadminOnly: false,
  },
  'destroy_relationship:project_suite': {
    text: 'Multi-site unlinked resource',
    hidden: true,
    superadminOnly: false,
  },
  'destroy_relationship:user': {
    text: 'User unlinked resource',
    hidden: true,
    superadminOnly: false,
  },
  'generated:voucher': {
    text: 'Vouchers generated',
    hidden: true,
    superadminOnly: false,
  },
  'granted:permission': {
    text: 'Permission granted',
    hidden: false,
    superadminOnly: false,
  },
  'invalidated:voucher': {
    text: 'Voucher invalidated',
    hidden: true,
    superadminOnly: false,
  },
  'invalidated_batch:voucher': {
    text: 'Voucher Batch invalidated',
    hidden: true,
    superadminOnly: false,
  },
  'invited_user:user_inviter': {
    text: 'User invited',
    hidden: false,
    superadminOnly: false,
  },
  'leave_construction:project': {
    text: 'Site activated',
    hidden: false,
    superadminOnly: false,
  },
  'patched:device_data': {
    text: 'Patched device data',
    hidden: true,
    superadminOnly: false,
  },
  'perform_device_action:device_data': {
    text: 'Performed device action',
    hidden: false,
    superadminOnly: false,
  },
  'redeemed_voucher:project': {
    text: 'Voucher redeemed',
    hidden: false,
    superadminOnly: false,
  },
  'replaced:logical_device': {
    text: 'Device replaced',
    hidden: false,
    superadminOnly: false,
  },
  'requested_reset_password:user': {
    text: 'User requested password reset',
    hidden: true,
    superadminOnly: false,
  },
  'resource_updated:device_data': {
    text: 'Device updated',
    hidden: true,
    superadminOnly: false,
  },
  'resources_upload:project': {
    text: 'Control Panel file uploaded',
    hidden: false,
    superadminOnly: false,
  },
  'revoked:permission': {
    text: 'Permission revoked',
    hidden: false,
    superadminOnly: false,
  },
  'revoked_invitation:user_inviter': {
    text: 'User Invite deleted',
    hidden: true,
    superadminOnly: false,
  },
  'set:permission': {
    text: 'Permission set',
    hidden: false,
    superadminOnly: false,
  },
  'signed_up_user:user_inviter': {
    text: 'Signed up as new user',
    hidden: false,
    superadminOnly: false,
  },
  'suspended:user': {
    text: 'User suspended',
    hidden: true,
    superadminOnly: false,
  },
  'transfer:file_upload': {
    text: 'File transferred',
    hidden: false,
    superadminOnly: false,
  },
  'unsuspended:user': {
    text: 'User unsuspended',
    hidden: true,
    superadminOnly: false,
  },
  'update_device_setting:device_data': {
    text: 'Device settings updated',
    hidden: false,
    superadminOnly: false,
  },
  'updated:company': {
    text: 'Portal updated',
    hidden: true,
    superadminOnly: false,
  },
  'updated:control_panel_page': {
    text: 'Control Panel updated',
    hidden: false,
    superadminOnly: false,
  },
  'updated:logical_device': {
    text: 'Device attributes updated',
    hidden: false,
    superadminOnly: false,
  },
  'updated:origin': {
    text: 'Origin updated',
    hidden: true,
    superadminOnly: false,
  },
  'updated:project': {
    text: 'Site updated',
    hidden: true,
    superadminOnly: false,
  },
  'updated:project_suite': {
    text: 'Multi-site updated',
    hidden: true,
    superadminOnly: false,
  },
  'updated:user': {
    text: 'User updated',
    hidden: true,
    superadminOnly: false,
  },
  'updated_2fa_secret:user': {
    text: 'User 2FA updated',
    hidden: true,
    superadminOnly: false,
  },
  'created:task': {
    text: 'Task created',
    hidden: false,
    superadminOnly: false,
  },
  'deleted:task': {
    text: 'Task deleted',
    hidden: false,
    superadminOnly: false,
  },
  'deleted:tasks': {
    text: 'Tasks deleted',
    hidden: true,
    superadminOnly: false,
  },
  'executed:task': {
    text: 'Task run',
    hidden: false,
    superadminOnly: false,
  },
  'updated:task': {
    text: 'Task updated',
    hidden: false,
    superadminOnly: false,
  },
  'created_using_voucher:project': {
    text: 'Site created using voucher',
    hidden: true,
    superadminOnly: false,
  },
  'download:file_upload': {
    text: 'File downloaded',
    hidden: false,
    superadminOnly: false,
  },
  'revert_legacy_order:control_panel_page': {
    text: 'Page order reverted',
    hidden: true,
    superadminOnly: false,
  },
  'downloaded:file_upload': {
    text: 'File downloaded',
    hidden: false,
    superadminOnly: false,
  },
  'transferred:file_upload': {
    text: 'File transferred',
    hidden: false,
    superadminOnly: false,
  },
  'updated:notification': {
    text: 'Notification settings updated',
    hidden: false,
    superadminOnly: false,
  },
  'deleting:ao': {
    text: 'Task Scheduler is deleting',
    hidden: false,
    superadminOnly: false,
  },
  'changed_company:project': {
    text: 'Site moved to a different company',
    hidden: true,
    superadminOnly: true,
  },
  'disconnected:device': {
    text: 'Device disconnected',
    hidden: false,
    superadminOnly: false,
  },
  'connected:device': {
    text: 'Device connected',
    hidden: false,
    superadminOnly: false,
  },
};

export default actionTypes;
