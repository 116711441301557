import { compose } from 'redux';

import extractValue from 'helpers/extractValue/extractValue';
import markLastUpdate from 'helpers/markLastUpdate/markLastUpdate';
import formatValue from 'helpers/formatValue/formatValue';
import withPrevious from 'helpers/withPrevious/withPrevious';
import DescriptionItem from 'components/DescriptionList/DescriptionItem/DescriptionItem';
import { displayPercentage } from '../../utilities';

const UsedDiskSpaceDescriptionItem = compose(
  extractValue,
  markLastUpdate,
  formatValue(displayPercentage),
  withPrevious,
)(DescriptionItem);

export default UsedDiskSpaceDescriptionItem;
