import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';

import { validateOnSubmit } from 'components/form/formConfigs';
import EditAutomatedOperationColourModalForm from './EditAutomatedOperationColourModalForm';

const EditAutomatedOperationModalFormContainer = reduxForm({
  enableReinitialize: true,
  ...validateOnSubmit,
})(EditAutomatedOperationColourModalForm);

export default compose(
  withRouter,
)(EditAutomatedOperationModalFormContainer);
