import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import { Icon } from 'semantic-ui-react';

import { Button } from 'components/atomic';

import { Container, StyledInput, ErrorMessage } from './VoucherInput.style';

function VoucherInput({
  projectId,
  isLoading,
  typedVoucher,
  selectedVoucher,
  voucherError,
  onVoucherTyped,
  onFetchVoucherDetails,
  onClearSelectedVoucher,
}) {

  const icon = useMemo(() => {
    if (isLoading) {
      return '';
    }

    if (selectedVoucher) {
      return 'delete';
    }

    return 'check';
  }, [isLoading, selectedVoucher]);

  const handleSubmit = useCallback(() => {
    if (!typedVoucher) {
      return;
    }

    if (selectedVoucher) {
      onVoucherTyped('');
      onClearSelectedVoucher();
      return;
    }

    onFetchVoucherDetails({
      projectId,
      voucher: typedVoucher,
    });
  }, [
    projectId,
    typedVoucher,
    selectedVoucher,
    onVoucherTyped,
    onFetchVoucherDetails,
    onClearSelectedVoucher,
  ]);

  const handleChange = useCallback(e => {
    const { value } = e.target;

    onVoucherTyped(value);
  }, [onVoucherTyped]);

  const handleKeyPress = useCallback(e => {
    if (e.key === 'Enter' && typedVoucher) {
      onFetchVoucherDetails({
        projectId,
        voucher: typedVoucher,
      });
    }
  }, [projectId, typedVoucher, onFetchVoucherDetails]);

  const actionButton = useMemo(() => (
    <Button
      primary
      loading={isLoading}
      disabled={isLoading}
      onClick={handleSubmit}
      name="voucher-action-button"
    >
      <Icon name={icon} />
    </Button>
  ), [icon, isLoading, handleSubmit]);

  return (
    <Container>
      <StyledInput
        name="voucher-input"
        placeholder="Voucher"
        action={actionButton}
        disabled={!isNil(selectedVoucher)}
        value={typedVoucher}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
      />
      {voucherError && (<ErrorMessage>{voucherError}</ErrorMessage>)}
    </Container>
  );
}

VoucherInput.defaultProps = {
  selectedVoucher: null,
  voucherError: null,
  projectId: null,
};

VoucherInput.propTypes = {
  projectId: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  typedVoucher: PropTypes.string.isRequired,
  voucherError: PropTypes.string,
  selectedVoucher: PropTypes.object,
  onFetchVoucherDetails: PropTypes.func.isRequired,
  onClearSelectedVoucher: PropTypes.func.isRequired,
  onVoucherTyped: PropTypes.func.isRequired,
};

export default VoucherInput;
