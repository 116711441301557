import styled from 'styled-components';
import { Image } from 'semantic-ui-react';

export const StyledSiteMapMarker = styled.div`
  position: absolute;
  background-color: white;
  filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
  padding: 6px;
  border-radius: 15px;
  border: 1px solid #cccccc;
  bottom: 8px;
  left: -15px;
  min-width: 30px;
  min-height: 30px;

  &:after, &:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-top-color: white;
    border-top-width: 10px;
    border-right-width: 5px;
    border-bottom-width: 0px;
    border-left-width: 5px;
    left: 19px;
    top: 27px;
    margin-left: -10px;
  }

  &:before {
    border-top-color: #cccccc;
    border-top-width: 11px;
    border-right-width: 6px;
    border-bottom-width: 0px;
    border-left-width: 6px;
    left: 19px;
    top: 27px;
    margin-left: -11px;
  }
`;

export const StyledIconImage = styled(Image).attrs(({ theme: { favicons } }) => ({
  size: 'mini',
  src: favicons && favicons.slice(-1)[0].href,
}))`
  &&&& {
    width: auto;
    border-radius: 7px;
  }
`;
