import {
  LOGICAL_DEVICES_FIELD,
  PROJECTS_FIELD,
  PROJECT_SUITES_FIELD,
} from 'helpers/selectors';

export const GROUP_LABELS = {
  [LOGICAL_DEVICES_FIELD]: 'Devices',
  [PROJECTS_FIELD]: 'Sites',
  [PROJECT_SUITES_FIELD]: 'Multi-sites',
};

export const FILTER_FIELDS = {
  [LOGICAL_DEVICES_FIELD]: 'filter[logicalDeviceId]',
  [PROJECTS_FIELD]: 'filter[projectId]',
  [PROJECT_SUITES_FIELD]: 'filter[projectSuiteId]',
};
