import styled from 'styled-components';
import get from 'lodash/get';

import DataTableRow from 'components/DataTable/DataTableRow/DataTableRow';

export const StyledTableRow = styled(DataTableRow)`
  cursor: pointer;
  
  td:nth-child(3) {
    font-size: 12px;
    color: ${({ theme }) => get(theme, 'palette.primaryColor')} !important;
  }
`;


export default StyledTableRow;
