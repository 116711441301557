import cloneDeep from 'lodash/cloneDeep';
import isDate from 'lodash/isDate';
import isArray from 'lodash/isArray';
import isNull from 'lodash/isNull';
import isNumber from 'lodash/isNumber';
import isUndefined from 'lodash/isUndefined';
import isString from 'lodash/isString';
import remove from 'lodash/remove';
import result from 'lodash/result';
import capitalize from 'lodash/capitalize';
import escapeRegExp from 'lodash/escapeRegExp';
import deepEqual from 'lodash/isEqual';
import identity from 'lodash/identity';
import includes from 'lodash/includes';
import { DateTime } from 'luxon';
import memoizeOne from 'memoize-one';
import { parseToRgb } from 'polished';
import isNil from 'lodash/isNil';
import flatten from 'lodash/flatten';

import { API_DOMAIN } from 'storageConst';
import colors from 'themes/colors';
import {
  PROJECTS_FIELD,
  PROJECT_SUITES_FIELD,
  LOGICAL_DEVICES_FIELD,
  USERS_FIELD,
  TENANT_FIELD,
} from 'helpers/selectors';
import { secondsPerDay, deviceStatus, AWS_REGION } from './sharedVariables';

export function extractTimePart(timestamp) {
  const fallback = Math.floor(Date.now() / 1000);

  return (timestamp !== null ? timestamp : fallback) % secondsPerDay;
}

export function mergeTimestamp(metaObject, value, { path = '' } = {}) {
  if (isNull(value) || isUndefined(value)) {
    return value;
  }

  const resultItem = cloneDeep(value);

  const isObject = value.constructor === Object;
  if (isObject) {
    Object.keys(resultItem).forEach(key => {
      const tempPath = path ? `${path}.${key}` : key;

      resultItem[key] = mergeTimestamp(metaObject, value[key], {
        path: tempPath,
      });
    });
  } else if (Array.isArray(value)) {
    return resultItem.map((element, index) => (
      mergeTimestamp(metaObject, element, {
        path: `${path}[${index}]`,
      })
    ));
  } else {
    return {
      value: resultItem,
      timestamp: result(metaObject, `${path}.timestamp`),
    };
  }

  return resultItem;
}

export function extractResourceType(endpoint) {
  if (!endpoint) {
    return null;
  }

  const types = [
    'projects',
    'users',
    'companies',
    'logical_devices',
  ];

  const matched = endpoint.match(
    new RegExp(`^\\/(${types.join('|')})(\\/|\\?)*`, 'gi'),
  );
  if (!matched || !matched.length) {
    return null;
  }

  const type = matched[0].replace(new RegExp('(\\/|\\?)', 'g'), '');

  return type.replace(new RegExp('_\\w', 'g'), matches => (
    matches[1].toUpperCase()
  ));
}

export function removeRelationship(source, id, resourceType) {
  const relationships = source && source.relationships[resourceType];

  if (relationships) {
    const { data } = relationships;
    remove(data, el => el.id === id);
  }
}

export function getInlineFormName(suffix) {
  return `inlineEdit_${suffix}`;
}

export function getScrollPos() {
  const { pageXOffset, pageYOffset } = window;

  const supportPageOffset = pageXOffset !== undefined;
  if (supportPageOffset) {
    return {
      x: pageXOffset,
      y: pageYOffset,
    };
  }

  const isCSS1Compat = document.compatMode === 'CSS1Compat';
  return {
    x: isCSS1Compat
      ? document.documentElement.scrollLeft
      : document.body.scrollLeft,
    y: isCSS1Compat
      ? document.documentElement.scrollTop
      : document.body.scrollTop,
  };
}

export function isUUID(value) {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(value);
}

export function asciiStringSizeInKB(value = {}) {
  return JSON.stringify(value).length / 1024;
}

export function percentageOf(total, value) {
  return total * (value / 100);
}

export function extractFileExtension(systemFile) {
  return `.${systemFile.name.split('.').pop()}`;
}

export function getSearchedCount(text, searched) {
  return text.split(searched).length - 1;
}

export const DISPLAY_NAME_FALLBACK = 'Component';

export function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName
    || WrappedComponent.name
    || DISPLAY_NAME_FALLBACK;
}

export function replaceArray(objValue, srcValue) {
  if (isArray(objValue) || isArray(srcValue)) {
    return srcValue;
  }

  return undefined;
}

export function matchExtension(fileName, supportedExtensions, { ignoreCase } = {}) {
  return supportedExtensions.find(type => {
    const parsedType = ignoreCase ? type.toLowerCase() : type;
    const parsedFilename = ignoreCase ? fileName.toLowerCase() : fileName;

    const regex = new RegExp(`${escapeRegExp(`.${parsedType}`)}$`);

    return regex.test(parsedFilename);
  });
}

export function celsiusToFahrenheit(degrees) {
  if (!isNumber(degrees)) return null;

  return degrees * 1.8 + 32;
}

export function upperCaseFirstLetter(text = '') {
  return text.charAt(0).toUpperCase() + text.substring(1);
}

export function upperCaseFirstLetterMultiple(text = '', separator) {
  return text.split(separator).map(upperCaseFirstLetter).join(' ');
}

export function getChainedLoaderPiece(fetching, error, override, link) {
  return {
    loaderFetching: fetching,
    loaderError: error,
    loaderOverride: override,
    loaderLink: link,
  };
}

export function timezoneFromOffset(offset) {
  const plus = offset >= 0 ? '+' : '';
  return `utc${plus}${offset}`;
}

export function enforceDateTime(date, offset) {
  if (isDate(date)) {
    return DateTime.fromJSDate(date, { zone: timezoneFromOffset(offset) });
  }

  if (isNumber(date)) {
    return DateTime.fromMillis(date, { zone: timezoneFromOffset(offset) });
  }

  return null;
}

export function elideToLength(string, length) {
  if (!isString(string)) {
    return string;
  }

  if (string.length <= length) {
    return string;
  }

  const finalLength = 6;
  const ellipsisLength = 3;
  const initialLength = length - ellipsisLength - finalLength;

  const initialSubstring = string.substring(0, initialLength);
  const finalSubstring = string.substring(string.length - finalLength);

  return `${initialSubstring}...${finalSubstring}`;
}

export function removeApiUrl(string) {
  const apiUrl = localStorage.getItem(API_DOMAIN) || process.env.REACT_APP_API_URL;

  return string.replace(apiUrl, '');
}

export function firstCharacterUpperCase(string) {
  if (!isString(string) || string.length === 0) {
    return '';
  }

  return string[0].toUpperCase();
}

export function reduceVocabulary(final, { pattern, key }) {
  return final.replace(pattern, key);
}

export function applyVocabulary(text) {
  const vocabulary = [
    { pattern: /logical[\W|_]*(device)/gi, key: '$1' },
    { pattern: /project/gi, key: 'site' },
    { pattern: /automated_operation/gi, key: 'task scheduler' },
    { pattern: /occurrence/gi, key: 'calendar event' },
  ];

  return vocabulary.reduce(reduceVocabulary, text);
}

export function capitalizeFirstLetter(string) {
  const [firstLetter, ...restOfLetters] = string;
  const capitalizedFirstLetter = capitalize(firstLetter);
  const letters = [capitalizedFirstLetter, ...restOfLetters];

  return letters.join('');
}

export function cleanUpLabel(label) {
  const splitByUnderscore = label.split('_');
  const [firstWord, ...rest] = splitByUnderscore;
  const capitalizedFirstWord = capitalizeFirstLetter(firstWord);
  const words = [capitalizedFirstWord, ...rest];

  return words.join(' ');
}

export function modifySelfWithResourceName(label, name, resource) {
  if (label === 'self' && name) {
    return `${resource}: ${name}`;
  }

  return cleanUpLabel(applyVocabulary(label));
}

export function deepEqualMemoizeOne() {
  return memoizeOne(identity, deepEqual);
}

export function linkStatus(online, value) {
  if (!online) {
    return deviceStatus.UNASSOCIATED;
  }

  if (!value) {
    return deviceStatus.OFFLINE;
  }

  return deviceStatus.ONLINE;
}

export function mapResourceType(resourceType) {
  const resourceTypeMap = {
    [PROJECTS_FIELD]: 'project',
    [PROJECT_SUITES_FIELD]: 'project_suite',
    [LOGICAL_DEVICES_FIELD]: 'logical_device',
    [USERS_FIELD]: 'user',
    [TENANT_FIELD]: 'tenant',
  };

  return resourceTypeMap[resourceType];
}

export function permissionNeedsConfirmation(path) {
  const paths = [
    'owner',
  ];

  return includes(paths, path);
}

export function mergeCustomizer(destination, source) {
  if (Array.isArray(destination)) {
    return source;
  }

  return undefined;
}

export function portNumberToLetter(portNumber) {
  const CHAR_CODE = 96;
  const port = parseInt(portNumber, 10);

  return port
    ? String.fromCharCode(CHAR_CODE + port).toUpperCase()
    : '0';
}

export function buildS3URL(bucket, path) {
  if (!bucket || !path) {
    return null;
  }

  return `https://${bucket}.s3.${AWS_REGION}.amazonaws.com/${path}`;
}

export function buildObjectURL(file) {
  if (!file) {
    return null;
  }

  return URL.createObjectURL(file);
}

export function convertColorString(value, defaultColor = colors.white) {
  const colorValue = value || defaultColor;

  if (!isString(colorValue)) {
    return colorValue;
  }

  if (colorValue.startsWith('#')) {
    const { red, green, blue, alpha = 1 } = parseToRgb(colorValue);

    return { r: red, g: green, b: blue, a: alpha };
  }

  return JSON.parse(colorValue);
}

export function snakeCaseToOption(dropdownOptionString) {
  if (!isString(dropdownOptionString)) {
    return dropdownOptionString;
  }

  return upperCaseFirstLetter(
    dropdownOptionString.split('_').join(' '),
  );
}

export function extractFirstLetterAndUpperCase(string) {
  if (!isString(string)) {
    return string;
  }

  return string.charAt(0).toUpperCase();
}

export function computePaths(string, acc, path) {
  if (string === 'multi-sites') {
    acc[path] = 'Multi-sites';

    return acc;
  }

  if (string === 'my-multi-sites') {
    acc[path] = 'My Multi-sites';

    return acc;
  }

  acc[path] = upperCaseFirstLetterMultiple(string, '-');

  return acc;
}

export const computeDropdownOptions = arr => {
  if (!isNil(arr) && Array.isArray(arr)) {
    return arr.map(({ id, name }) => (
      { key: id, text: name, value: id, id }),
    );
  }

  return [];
};

export const checkPermissionByType = (type, permissionsArray, condition) => {
  if (isNil(type) || isNil(permissionsArray)) {
    return false;
  }

  if (Array.isArray(permissionsArray)) {
    return flatten(
      permissionsArray
        .filter(
          ({ resourceType }) => resourceType === type,
        )
        .map(({ permissions }) => permissions.map(({ name }) => name)),
    ).includes(condition);
  }

  return false;
};

export const setTabVisibilityWithMenuItem = (
  tabsArray,
  tabName,
  condition,
) => {
  if (!Array.isArray(tabsArray)) {
    return null;
  }

  if (isNil(tabName) || isNil(condition)) {
    return tabsArray;
  }

  if (tabName && !condition) {
    return tabsArray.filter(({ menuItem }) => menuItem !== tabName);
  }

  return tabsArray;
};

export const checkPermissionByTypeAndResource = (type, id, permissionsArray, condition) => {
  if (isNil(type) || isNil(id) || isNil(permissionsArray)) {
    return false;
  }

  if (Array.isArray(permissionsArray)) {
    return flatten(
      permissionsArray
        .filter(
          ({ resourceType, resourceId }) => resourceType === type && resourceId === id,
        )
        .map(({ permissions }) => permissions.map(({ name }) => name)),
    ).includes(condition);
  }

  return false;
};
