import styled, { css } from 'styled-components';
import { List } from 'semantic-ui-react';

import { mediaMax } from 'themes/mixins';

export const Container = styled.div`
  ${mediaMax.md`
    margin-top: 24px;
  `};
`;

export const StyledListContent = styled(List.Content)`
  padding: 8px 0;
  display: flex;
  justify-content: space-between;

  ${({ noPaddingTop }) => noPaddingTop && css`
    padding-top: 0px;
  `}

  ${({ noPaddingBottom }) => noPaddingBottom && css`
    padding-bottom: 0px;
  `}
`;

export const StyledVoucherInputListContent = styled(List.Content)`
  padding: 8px 0;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
`;
