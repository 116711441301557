import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

const CurrentPasswordField = ({ input: { onChange } }) => (
  <Input
    onChange={onChange}
    type="password"
    placeholder="Enter your current password"
  />
);

CurrentPasswordField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }).isRequired,
};

export default CurrentPasswordField;
