import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { PermissionsSwitch, Gateway, frontendPermissions } from 'gateway';
import { Button } from 'components/atomic';
import ConfirmModal from 'components/elements/ConfirmModal/ConfirmModal';
import { getDisplayName } from 'helpers/sharedMethods';

const withDeleteTask = WrappedComponent => {

  const WithDeleteTask = props => {
    const {
      item: {
        name,
        id,
        lockVersion,
        projectId: taskProjectId,
      },
      resourceName,
      onDeleteTask,
      resourceType,
      superSites,
      resourceId,
    } = props;

    const trigger = <WrappedComponent title="Delete" />;
    const handleConfirm = () => onDeleteTask({
      taskId: id,
      lockVersion,
      projectName: resourceName,
      resourceType,
      resourceId,
      taskProjectId,
    });

    const permission = useMemo(() => {
      if (superSites) {
        return frontendPermissions.PROJECT_SUITE_TASKS_DELETE;
      }

      return frontendPermissions.DELETE_TASK;
    }, [superSites]);

    return (
      <PermissionsSwitch>
        <Gateway condition={permission}>
          <ConfirmModal
            trigger={trigger}
            cancelButton="Cancel"
            confirmButton={<Button negative primary={false}>Delete Task</Button>}
            content={(
              <div>
                <p>
                  Are you sure you want to delete task <strong>{name}</strong>?
                  This action cannot be undone.
                </p>
                <p>
                  Deleting this Task will also remove it from any
                  Task Schedulers or Control Panel buttons it is associated with.
                </p>
              </div>
            )}
            header="Confirm task deletion"
            onConfirm={handleConfirm}
          />
        </Gateway>
      </PermissionsSwitch>
    );
  };

  WithDeleteTask.defaultProps = {
    item: PropTypes.shape({
      id: '',
      name: '',
      lockVersion: '',
      projectId: '',
    }),
    resourceName: '',
    resourceType: 'project',
    superSites: false,
  };

  WithDeleteTask.propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      lockVersion: PropTypes.string,
      projectId: PropTypes.string,
    }),
    onDeleteTask: PropTypes.func.isRequired,
    resourceId: PropTypes.string.isRequired,
    resourceName: PropTypes.string,
    resourceType: PropTypes.string,
    superSites: PropTypes.bool,
  };

  WithDeleteTask.displayName = `WithDeleteTask(${getDisplayName(WrappedComponent)})`;

  return WithDeleteTask;

};

export default withDeleteTask;
