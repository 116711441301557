import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withProjectPermissions } from 'gateway';

import ProjectPermissionsTab from './ProjectPermissionsTab';

const mapStateToProps = (state, { match }) => {
  const { id: projectId } = match.params;

  return {
    projectId,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, null),
  withProjectPermissions,
)(ProjectPermissionsTab);
