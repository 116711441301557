import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';

import { ProjectsTable } from 'pages/ProjectSuite/ProjectSuiteProjectsTab/components';

const tableLabels = [
  { text: 'Site', field: 'visibleNameWithState', sortBy: 'name' },
  { text: 'Team', field: 'team', sortable: false },
  { text: 'Devices', field: 'devices', sortable: false },
];

function LinkedProjects() {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <Segment>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <ProjectsTable
                    header="Linked Sites"
                    tableLabels={tableLabels}
                    showCreateButton
                    showActionLinks
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default LinkedProjects;
