import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import { DateTime } from 'luxon';

const renderConstructionEndDate = (constructionEndDate, format = 'dd/MM/y') => {
  if (isUndefined(constructionEndDate) || isNull(constructionEndDate)) {
    return 'Not set';
  }

  return DateTime.fromISO(constructionEndDate).toUTC().toFormat(format);
};

export default renderConstructionEndDate;
