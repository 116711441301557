import getAllPermissionsWithRecipientsMap from './getAllPermissionsWithRecipientsMap';
import getCumulatedPermissionsWithRecipientsMap from './getCumulatedPermissionsWithRecipientsMap';
import getFinalCumulatedPermissionsMap from './getFinalCumulatedPermissionsMap';

const transformCumulatedPermissions = ({
  permissionsNames,
  originalCumulatedPermissions,
  recipientIds,
  resourceType,
  context,
}) => {
  const allPermissionsMap = getAllPermissionsWithRecipientsMap({
    permissionsNames,
    recipientIds,
  });

  const cumulatedPermissionsMap = getCumulatedPermissionsWithRecipientsMap({
    originalCumulatedPermissions,
    resourceType,
    context,
  });

  const finalCumulatedPermissionsMap = getFinalCumulatedPermissionsMap({
    allPermissionsMap,
    cumulatedPermissionsMap,
    recipientIds,
  });

  return finalCumulatedPermissionsMap;
};

export default transformCumulatedPermissions;
