import isNull from 'lodash/isNull';

const stringToArray = (str, hours = false) => {
  if (isNull(str)) {
    return null;
  }

  if (hours && str === '*') {
    return ['*'];
  }

  if (hours && Array.isArray(str) && str[0] === '*') {
    return ['*'];
  }

  const toString = str.toString();
  return toString ? toString.split(',').map(item => parseInt(item, 10)) : [];
};

export default stringToArray;
