import { call, fork, put, race, select, take, takeLatest } from 'redux-saga/effects';

import appStore from 'appStore';
import constants from 'dispatcherConst';
import { TOKEN, API_DOMAIN } from 'storageConst';
import { API_URL } from 'data/api/requests';
import {
  uploadsFileSelector,
  uploadsFirstQueuedSelector,
  uploadsLimitSelector,
  uploadsRunningSelector,
  uploadsWorkerSelector,
} from 'helpers/uploadsSelectors';
import fileUploadWorker from './fileUploadWorkerImport';
import uploadsActionCreators from './uploadsActionCreators';
import { MAX_UPLOAD_NUMBER } from './uploadsReducer/uploadsStatusReducer';

export function onMessage({ data }) {
  appStore.dispatch(data);
}

export function* getWorkerInstance(fileKey) {
  let instance = (yield select(uploadsWorkerSelector))(fileKey);
  if (instance) return instance;

  instance = yield call(fileUploadWorker);
  yield put(uploadsActionCreators.setUploadWorker(fileKey, instance));

  instance.onmessage = onMessage;

  return instance;
}

export function* startUploading() {
  const maxConcurrentUploads = yield select(uploadsLimitSelector);
  if (maxConcurrentUploads === 0) return;

  const running = yield select(uploadsRunningSelector);
  if (running) return;

  const firstQueued = yield select(uploadsFirstQueuedSelector);
  if (!firstQueued) return;

  const { fileKey, initialized, systemFile } = firstQueued;
  yield put(uploadsActionCreators.setUploadFetchStatus(fileKey, true));

  const worker = yield call(getWorkerInstance, fileKey);

  yield put(uploadsActionCreators.resumeUpload(fileKey));

  if (systemFile.size === 0) {
    yield put(uploadsActionCreators.fileNotFound(fileKey));
  } else if (!initialized) {
    const token = localStorage.getItem(TOKEN);
    const apiUrl = localStorage.getItem(API_DOMAIN) || API_URL;

    yield fork(worker.upload, firstQueued, token, apiUrl);
  } else {
    yield fork(worker.resume, fileKey);
  }

  yield take(constants.UPLOAD_START_SUCCESS);
  yield put(uploadsActionCreators.setUploadFetchStatus(fileKey, false));
}

export function* cancelRequest(action) {
  const { fileKey } = action.payload;

  const upload = (yield select(uploadsFileSelector))(fileKey);
  const { completed, initialized } = upload;
  if (completed) return;

  yield put(uploadsActionCreators.setUploadFetchStatus(fileKey, true));

  if (!initialized) {
    yield put(uploadsActionCreators.cancelUploadSuccess(fileKey));
  } else {
    const worker = yield call(getWorkerInstance, fileKey);
    yield call(worker.cancel, fileKey);
  }

  yield take(constants.UPLOAD_CANCEL_FAILED);
  yield put(uploadsActionCreators.setUploadFetchStatus(fileKey, false));
}

export function* pauseRequest(action) {
  const { fileKey } = action.payload;

  const upload = (yield select(uploadsFileSelector))(fileKey);
  const { completed, initialized, paused, queued } = upload;
  if (completed || paused) return;

  yield put(uploadsActionCreators.setUploadFetchStatus(fileKey, true));

  if (!initialized || queued) {
    yield put(uploadsActionCreators.pauseUploadSuccess(fileKey));
  } else {
    const worker = yield call(getWorkerInstance, fileKey);

    yield race({
      fulfilled: call(worker.pause, fileKey),
      cancelled: take(constants.UPLOAD_SUCCESS),
    });
  }

  yield put(uploadsActionCreators.setUploadFetchStatus(fileKey, false));
}

export function* forceUpload(action) {
  yield put(uploadsActionCreators.setUploadLimit(0));

  const { fileKey } = action.payload;

  const upload = (yield select(uploadsFileSelector))(fileKey);
  const { systemFile } = upload;

  const running = yield select(uploadsRunningSelector);
  if (!running) {
    yield put(uploadsActionCreators.enqueueUploadAtStart([{ fileKey, systemFile }]));
  } else {
    const { fileKey: fileKeyRunning, systemFile: systemFileRunning } = running;

    yield put(uploadsActionCreators.pauseUpload(fileKeyRunning));
    yield take(constants.UPLOAD_PAUSE_SUCCESS);
    yield put(uploadsActionCreators.enqueueUploadAtStart([
      { fileKey, systemFile },
      { fileKey: fileKeyRunning, systemFile: systemFileRunning },
    ]));
  }

  yield put(uploadsActionCreators.setUploadLimit(MAX_UPLOAD_NUMBER));
  yield put(uploadsActionCreators.enqueueUploadAtStart([]));
}

export function* suspendRequest() {
  yield put(uploadsActionCreators.setUploadLimit(0));

  const running = yield select(uploadsRunningSelector);
  if (!running) return;

  const { fileKey } = running;
  yield put(uploadsActionCreators.pauseUpload(fileKey));

  const { fulfilled } = yield race({
    fulfilled: take(constants.UPLOAD_PAUSE_SUCCESS),
    cancelled: take(constants.UPLOAD_SUCCESS),
  });

  yield put(uploadsActionCreators.setUploadSuspensionStatus(true));
  if (fulfilled) {
    yield put(uploadsActionCreators.enqueueUploadAtStart([running]));
  }
}

export function* continueRequest() {
  yield put(uploadsActionCreators.setUploadLimit(MAX_UPLOAD_NUMBER));

  yield put(uploadsActionCreators.enqueueUploadAtStart([]));
  yield put(uploadsActionCreators.setUploadSuspensionStatus(false));
}

export function saveNewToken(action) {
  const { token } = action.payload;

  localStorage.setItem(TOKEN, token);
}

function* uploadsManagerSaga() {
  yield takeLatest(constants.UPLOAD_ENQUEUE_AT_START, startUploading);
  yield takeLatest(constants.UPLOAD_ENQUEUE_AT_END, startUploading);
  yield takeLatest(constants.UPLOAD_CANCEL_SUCCESS, startUploading);
  yield takeLatest(constants.UPLOAD_PAUSE_SUCCESS, startUploading);
  yield takeLatest(constants.UPLOAD_SUCCESS, startUploading);

  yield takeLatest(constants.UPLOAD_CANCEL_REQUEST, cancelRequest);
  yield takeLatest(constants.UPLOAD_PAUSE_REQUEST, pauseRequest);

  yield takeLatest(constants.UPLOAD_FORCE, forceUpload);

  yield takeLatest(constants.UPLOAD_SUSPEND, suspendRequest);
  yield takeLatest(constants.UPLOAD_CONTINUE, continueRequest);

  yield takeLatest(constants.UPLOAD_SAVE_NEW_TOKEN, saveNewToken);
}

export default uploadsManagerSaga;
