import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { permissionsOrRedirect, frontendPermissions } from 'gateway';
import { deepEqualMemoizeOne } from 'helpers/sharedMethods';
import { TASKS_FIELD, SELECT_TASKS } from 'helpers/selectors';
import { addPagination } from 'helpers/paginationHelpers';
import { Unauthorized } from 'application/components';
import { actionCreators as projectSuiteActionCreators } from 'pages/ProjectSuites/utilities';

import { CP_PAGEABLE_TYPE_PROJECT_SUITE } from './constants';
import { selectors, actionCreators } from './utilities';
import ControlUI from './ControlUI';

export const makeMapState = () => {
  const memoPageIds = deepEqualMemoizeOne();

  return function mapStateToProps(state, { match, projectsIds }) {
    const { id: projectSuiteId } = match.params;
    const { pages = [] } = selectors.projectControlUISelector(state)(projectSuiteId) || {};
    const pageIds = pages.map(({ index }) => index);
    const isMultiSite = true;

    return {
      pageIds: memoPageIds(pageIds),
      projectSuiteId,
      projectsIds,
      projectId: projectSuiteId,
      cpPageableType: CP_PAGEABLE_TYPE_PROJECT_SUITE,
      isMultiSite,
    };
  };
};

const mapDispatchToProps = {
  onAddPage: actionCreators.addPage,
  onDeletePage: actionCreators.deletePage,
};

export default compose(
  withRouter,
  connect(makeMapState, mapDispatchToProps),
  chainedLoaderWithSaga(
    ({
      projectId,
      cpPageableType,
    }) => actionCreators.fetchControlUI(projectId, cpPageableType),
    addPagination(
      { resourceType: TASKS_FIELD, included: true, componentName: SELECT_TASKS },
      ({ projectSuiteId, projectsIds }) => projectsIds
      && projectSuiteActionCreators.fetchProjectSuiteProjectsTasks({
        recipientType: 'projectSuite',
        recipientId: projectSuiteId,
        'projectSuiteIds[]': [projectSuiteId],
        'projectIds[]': [...projectsIds],
        sort: 'name',
        filter: 'name',
      }),
    ),
  ),
  permissionsOrRedirect(frontendPermissions.PROJECT_SUITE_CONTROL_PANEL_VIEW, Unauthorized),
)(ControlUI);
