import React from 'react';
import PropTypes from 'prop-types';

import { USERS_FIELD } from 'helpers/selectors';
import DataTable from 'components/DataTable/DataTableContainer';
import InviteUser from 'components/InviteUser/InviteUserContainer';
import { PageHeader } from 'components/core';
import { UserTableRow } from '../../components';
import { UserActionLinks } from './components';

const usersTableLabels = [
  { text: 'First name', field: 'firstName' },
  { text: 'Last name', field: 'lastName' },
  { text: 'Email', field: 'email' },
  { text: 'Status', field: 'statusIcon', sortable: false },
];

const Users = ({ users, currentUserId, tenantNotChanged }) => {
  const mappedUsers = users
    .filter(({ id }) => id !== currentUserId || tenantNotChanged);

  return (
    <>
      <PageHeader title="Users" />
      <DataTable
        labels={usersTableLabels}
        data={mappedUsers}
        actionLinks={UserActionLinks}
        createElement={InviteUser}
        customTableRow={UserTableRow}
        pagination={{
          type: USERS_FIELD,
          args: {
            withFilter: true,
          },
        }}
      />
    </>
  );
};

Users.propTypes = {
  currentUserId: PropTypes.string.isRequired,
  tenantNotChanged: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
};

export default Users;
