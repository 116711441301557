import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { GroupsListPlaceholder } from './components';
import { List, ListItem, StyledIcon, Container, EmptyMessage } from './AvailableGroups.style';

function AvailableGroups({
  isLoading,
  selectedDevice,
  availableGroups,
  selectedGroups,
  onGroupSelected,
}) {

  const formattedGroups = useMemo(() => {
    if (isEmpty(availableGroups)) {
      return [];
    }

    return availableGroups.map(group => ({
      id: group.id,
      name: group.name,
    }));

  }, [availableGroups]);

  if (!selectedDevice) {
    return (
      <Container flex>
        <EmptyMessage>
          You must select a device.
        </EmptyMessage>
      </Container>
    );
  }

  if (isLoading) {
    return <GroupsListPlaceholder />;
  }

  if (isEmpty(availableGroups)) {
    return (
      <Container flex>
        <EmptyMessage>
          No Groups found for the selected device.
        </EmptyMessage>
      </Container>
    );
  }

  const handleSelectedGroup = id => {
    const key = `${selectedDevice.id}_${id}`;
    const selectedGroup = availableGroups.find(group => group.id === id);

    onGroupSelected({
      key,
      logicalDevice: {
        id: selectedDevice.id,
        name: selectedDevice.name,
      },
      group: {
        id: selectedGroup.id,
        name: selectedGroup.name,
      },
    });
  };

  const handleClick = id => {
    handleSelectedGroup(id);
  };

  const handleKeyPress = (e, id) => {
    if (e.key === 'Enter') {
      handleSelectedGroup(id);
    }
  };

  return (
    <List divided verticalAlign="middle">
      {formattedGroups.map(({ id, name }, index) => {
        const key = `${selectedDevice.id}_${id}`;

        return (
          <ListItem
            key={id}
            tabIndex={index + 1}
            onKeyPress={e => handleKeyPress(e, id)}
            onClick={() => handleClick(id)}
            disabled={selectedGroups.some(group => group.key === key)}
          >
            <p>{name}</p>
            <StyledIcon name="arrow right" color="grey" />
          </ListItem>
        );
      })}
    </List>
  );
}

AvailableGroups.defaultProps = {
  isLoading: false,
  selectedDevice: null,
  availableGroups: [],
  selectedGroups: [],
};

AvailableGroups.propTypes = {
  isLoading: PropTypes.bool,
  selectedDevice: PropTypes.object,
  availableGroups: PropTypes.array,
  selectedGroups: PropTypes.array,
  onGroupSelected: PropTypes.func.isRequired,
};

export default AvailableGroups;
