import constants from 'dispatcherConst';

const colorPickerActionCreators = {
  updateRGB: (red, green, blue, actionTypeDeviceIdKey) => ({
    type: constants.UPDATE_RGB_VALUES_REQUEST,
    payload: {
      red,
      green,
      blue,
      actionTypeDeviceIdKey,
    },
  }),

  updateRGBNewAction: (red, green, blue, actionTypeDeviceIdKey) => ({
    type: constants.UPDATE_RGB_VALUES_NEW_ACTION_REQUEST,
    payload: {
      red,
      green,
      blue,
      actionTypeDeviceIdKey,
    },
  }),
};

export { colorPickerActionCreators };

export default colorPickerActionCreators;
