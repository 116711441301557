import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { taskSelector } from 'helpers/selectors';
import { tasksActionCreators } from '../../../../../../../../../../utilities';
import { actionCreators as controlUIActionCreators, getUniqueTaskIds } from '../../../../../../../utilities';
import { actionCreators as taskSchedulerActionCreators, selectors as taskSchedulerSelectors } from '../../../../../../../../../../console/site/components/structural/SiteSchedulingTab/components/AutomatedOperations/components/AddAutomatedOperation/utilities';
import ControlUIButton from './ControlUIButton';

export const mapStateToProps = (state, { form, match, taskId, taskIds = [] }) => {
  const { id: projectId } = match.params;

  const allTaskIds = getUniqueTaskIds(taskId, taskIds);
  const tasks = [];

  allTaskIds.forEach(id => {
    const task = taskSelector(state)(id) || {};

    if (!isEmpty(task)) {
      tasks.push(task);
    }
  });

  const { values } = state.form[form] || {};

  return {
    projectId,
    tasks,
    values,
    deviceActionsTaskName: taskSchedulerSelectors.taskNameSelector(state),
    selectedTasks: taskSchedulerSelectors.selectedTasksSelector(state),
    selectedActions: taskSchedulerSelectors.selectedActionsSelector(state),
    selectedActionsForm: state.form.selectedActions,
    displayCopyPasteButtons: true,
  };
};

const mapDispatchToProps = {
  onTriggerTask: tasksActionCreators.triggerTask,
  onTriggerMultipleTasks: tasksActionCreators.triggerMultipleTasks,
  onUpdateButton: controlUIActionCreators.updateButton,
  onResetTaskScheduler: taskSchedulerActionCreators.resetTaskScheduler,
  onTaskSelected: taskSchedulerActionCreators.addSelectedTask,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ControlUIButton);
