import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from 'semantic-ui-react';

import { MessageDivider } from 'components/elements';
import NarrowContainer from 'components/NarrowContainer/NarrowContainer.style';
import Message from './NoMatch.style';

class NoMatch extends Component {

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ tenant: nextTenant }) {
    const { tenant, history: { location, replace } } = this.props;

    if (tenant !== nextTenant) {
      replace(location.pathname);
    }
  }

  render() {
    const { currentUser } = this.props;
    const ContainerType = currentUser ? NarrowContainer : Container;

    return (
      <ContainerType>
        <Grid centered>
          <Grid.Row>
            <Grid.Column textAlign="center" mobile={16}>
              <Message>
                <Message.H1>404</Message.H1>
                <Message.H2>Ooops, page not found</Message.H2>
                <MessageDivider />
                <Message.Text>Sorry, the requested page does not exist.</Message.Text>
              </Message>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ContainerType>
    );
  }

}

NoMatch.defaultProps = {
  currentUser: null,
  tenant: null,
};

NoMatch.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  tenant: PropTypes.string,
  history: PropTypes.shape({
    location: PropTypes.object.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

export default NoMatch;
