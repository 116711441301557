import { connect } from 'react-redux';

import { actionCreators } from '../../../../utilities';

import DeviceFirmwaresActionLinks from './DeviceFirmwaresActionLinks';

const mapDispatchToProps = {
  onDeleteDeviceFirmware: actionCreators.deleteDeviceFirmware,
};

export default connect(null, mapDispatchToProps)(DeviceFirmwaresActionLinks);
