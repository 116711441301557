import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fieldPropTypes } from 'redux-form';

import FormField from 'components/form/FormField/FormField';
import ThemedToggle from 'components/elements/ThemedToggle/ThemedToggle.style';

class ToggleField extends Component {

  constructor() {
    super();

    this.handleChange = this.handleChange.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      initialValue: nextInitialValue,
      input: { value: nextValue },
    } = nextProps;
    const {
      onChangeSubmit,
      input: { value },
    } = this.props;

    if (
      onChangeSubmit
      && value !== nextValue
      && nextInitialValue !== nextValue
    ) {
      onChangeSubmit();
    }
  }

  handleChange(event, { checked }) {
    const { input: { onChange }, preventPropagation, onChangeCallback } = this.props;

    onChange(checked);

    if (onChangeCallback) {
      onChangeCallback(checked);
    }

    if (preventPropagation) {
      event.stopPropagation();
    }
  }

  render() {
    const {
      input: { name, value },
      label,
      meta: { submitting },
      hideRadioLabel,
    } = this.props;

    const labelProp = !hideRadioLabel ? label : '';

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <FormField {...this.props} omitError>
        <ThemedToggle
          label={labelProp}
          name={name}
          checked={!!value}
          disabled={submitting}
          onChange={this.handleChange}
        />
      </FormField>
    );
  }

}

ToggleField.defaultProps = {
  initialValue: null,
  onChangeSubmit: null,
  preventPropagation: false,
  hideRadioLabel: false,
  onChangeCallback: () => null,
};

ToggleField.propTypes = {
  ...fieldPropTypes,
  initialValue: PropTypes.bool,
  onChangeSubmit: PropTypes.func,
  preventPropagation: PropTypes.bool,
  hideRadioLabel: PropTypes.bool,
  onChangeCallback: PropTypes.func,
};

export default ToggleField;
