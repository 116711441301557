import {
  OCCURRENCE_MONTH_FROM_NUMBER,
  OCCURRENCE_MONTH_OPTIONS,
} from '../../../../ScheduleStep/components/RecurrenceViews/constants';

const getOccurrenceMonthText = month => {
  if (!month) {
    return '';
  }

  const occurrenceMonth = OCCURRENCE_MONTH_FROM_NUMBER[month];
  const { text } = OCCURRENCE_MONTH_OPTIONS.find(
    item => item.key === occurrenceMonth,
  );

  return text;
};

export default getOccurrenceMonthText;
