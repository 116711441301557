import styled from 'styled-components';
import { Table } from 'semantic-ui-react';

export const StyledTable = styled(Table)`
  &.ui.table {
    width: 240px; 
    border-radius: 0px;
    font-size: 11px;
  }

  &.ui.table th {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  &.ui.table td {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  &.ui.table td:first-of-type {
    width: 50px;
  }
`;

export const StyledWrapperDiv = styled.div`
  width: 258px;
  max-height: 102px; 
  overflow-y: auto;
  overflow-x: hidden;
`;

export const StyledP = styled.p`
  display: inline-block;
  cursor: pointer;
  cursor: ${({ isProjectSuite }) => isProjectSuite && 'auto;'}
`;
