import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import { sortType } from 'data/types';
import UrlParams from 'helpers/UrlParams';

const withSorting = WrappedComponent => (class extends Component {

  // eslint-disable-next-line react/static-property-placement
  static displayName = 'WithSorting';

  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
    pagination: PropTypes.shape({
      type: PropTypes.string,
      included: PropTypes.bool,
    }),
    sort: sortType,
    onRefetch: PropTypes.func.isRequired,
  };

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    pagination: { type: '' },
    sort: null,
  };

  constructor() {
    super();

    this.handleSort = this.handleSort.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({
    sort: nextSort,
  }) {
    const {
      pagination,
      sort,
    } = this.props;

    if (!sort && nextSort) {
      const urlParams = new UrlParams({ pagination });
      const { sort: sortParams } = urlParams;

      if (!isEqual(nextSort, sortParams)) {
        const { field, prefix } = nextSort;
        this.handleSort(`${prefix}${field}`, 'replace');
      }
    }
  }

  handleSort(fieldName, modificationType = 'push') {
    const { history, location, pagination } = this.props;

    const urlParams = new UrlParams({ pagination });
    urlParams.sort = fieldName;
    const { search } = urlParams;

    history[modificationType]({ ...location, search });

    const {
      // eslint-disable-next-line react/prop-types
      pagination: { type, componentName },
      onRefetch,
    } = this.props;

    const { sort: sortParams } = urlParams;

    const getType = componentName || type;
    onRefetch(getType, { sort: sortParams });
  }

  render() {
    const { sort } = this.props;

    return (
      <WrappedComponent
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...this.props}
        onSort={this.handleSort}
        sort={sort || {}}
      />
    );
  }

});

export default withSorting;
