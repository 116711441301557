import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import {
  TASKS_FIELD,
  LOGICAL_DEVICES_FIELD,
  singleProjectSelector,
} from 'helpers/selectors';
import withPolling from 'helpers/withPollingSaga/withPollingSagaContainer';
import { addPagination } from 'helpers/paginationHelpers';
import paginationActionCreators from 'data/paginationActionCreators';
import projectActionCreators from 'pages/Projects/projectActionCreators';
import {
  actionCreators as taskSchedulerActionCreators,
} from 'application/tenant/console/site/components/structural/SiteSchedulingTab/components/AutomatedOperations/components/AddAutomatedOperation/utilities';

import { tasksActionCreators } from '../../../../../../../utilities';
import ListOfTasks from './ListOfTasks';

const REFRESH_RATE = 60000;

export const mapStateToProps = (state, { match }) => {
  const { id: projectId } = match.params;
  const { isConstruction, isSuspended } = singleProjectSelector(state, projectId);

  return {
    projectId,
    isConstruction,
    isSuspended,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoaderWithSaga(tasksActionCreators.fetchActionTypes),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: TASKS_FIELD, included: true },
      ({ projectId }) => tasksActionCreators.fetchTasks(projectId, {
        sort: 'name',
        filter: 'name',
      }),
    ),
  ),
  withPolling(REFRESH_RATE),
  chainedLoaderWithSaga(
    ({ projectId }) => projectActionCreators.fetchProject(projectId, {
      include: [
        LOGICAL_DEVICES_FIELD,
      ],
      fields: {
        [LOGICAL_DEVICES_FIELD]: [
          'name',
        ],
      },
    },
    ),
    addPagination(
      {
        resourceType: LOGICAL_DEVICES_FIELD,
      },
      paginationActionCreators.fetchPageResource({ sort: 'name' }),
    ),
  ),
  chainedLoaderWithSaga(
    ({ projectId }) => taskSchedulerActionCreators.fetchProjectDevices(projectId),
  ),
)(ListOfTasks);
