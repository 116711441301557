import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import { frontendPermissions, PermissionsSwitch, Gateway } from 'gateway';
import ProjectSuiteContext from '../../../ProjectSuiteContext/ProjectSuiteContext';
import { AddPage } from '../ConfigurationPanel/components';
import { StyledButton } from './EnableControlPanel.style';

const EnableControlPanel = ({
  onAddPage,
  adding,
  isConstruction,
  isSuspended,
}) => {
  const isMultiSite = useContext(ProjectSuiteContext);

  const disabled = isConstruction || isSuspended;

  const permission = useMemo(() => {
    if (isMultiSite) {
      return frontendPermissions.PROJECT_SUITE_CONTROL_PANEL_ADD_PAGE;
    }

    return frontendPermissions.ADD_PAGE_CONTROL_PANEL;
  }, [isMultiSite]);

  const trigger = (
    <StyledButton disabled={disabled} icon labelPosition="left" fluid>
      <Icon name="plus" />
      Enable Control Panel
    </StyledButton>
  );

  return (
    <PermissionsSwitch>
      <Gateway condition={permission}>
        <AddPage
          onSubmit={onAddPage}
          submitting={adding}
          total={0}
          trigger={trigger}
        />
      </Gateway>
    </PermissionsSwitch>
  );
};

EnableControlPanel.defaultProps = {
  adding: false,
};

EnableControlPanel.propTypes = {
  onAddPage: PropTypes.func.isRequired,
  adding: PropTypes.bool,
  isConstruction: PropTypes.bool.isRequired,
  isSuspended: PropTypes.bool.isRequired,
};

export default EnableControlPanel;
