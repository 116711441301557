import React from 'react';
import PropTypes from 'prop-types';

import { ERROR_LEVEL, SUCCESS_LEVEL, INFO_LEVEL } from 'data/notifications/notificationsConst';
import StyledMessage from './Notification.style';

const Notification = ({
  isPlural,
  level,
  message,
  title,
}) => (
  <StyledMessage
    icon="close"
    success={level === SUCCESS_LEVEL}
    error={level === ERROR_LEVEL}
    info={level === INFO_LEVEL}
    header={title}
    content={!isPlural && message}
    list={isPlural ? message : null}
  />
);

Notification.defaultProps = {
  isPlural: false,
};

Notification.propTypes = {
  level: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]).isRequired,
  title: PropTypes.string.isRequired,
  isPlural: PropTypes.bool,
};

export default Notification;
