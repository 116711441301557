import styled, { css } from 'styled-components';
import { Button } from 'semantic-ui-react';
import { lighten, rgba } from 'polished';
import { IoIosAdd } from 'react-icons/io';

import filterProps from 'helpers/filterProps';
import { mediaMax } from 'themes/mixins';

const FilteredProps = filterProps([
  'configure',
  'enabled',
  'buttonColor',
  'textColor',
  'initialValues',
])(Button);

export const StyledControlUIButton = styled(FilteredProps)`
  &.ui.button {
    position: relative;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16em;

    opacity: 0;
    pointer-events: none;

    ${({ enabled }) => enabled && css`
      opacity: 1;
      pointer-events: auto;

      ${({ buttonColor: { r, g, b, a } }) => css`
        background-color: ${rgba(r, g, b, a)};

        &:hover {
          background-color: ${lighten(0.1, rgba(r, g, b, a))};
        }
      `}

      ${({ textColor: { r, g, b, a } }) => css`
        color: ${rgba(r, g, b, a)};
      `}
    `}

    ${({ configure }) => configure && css`
      height: 12em;
      opacity: 1;
      pointer-events: auto;

      ${({ enabled }) => !enabled && css`
        background-color: transparent;
        border: 1px dashed darkgrey;
        color: darkgrey;

        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      `}
    `}

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: "";
      float: left;
      padding-top: 100%;
    }

    ${mediaMax.sm`
      height: 120px;
    `};
  }
`;

export const StyledLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  max-width: 90%;
  overflow: hidden;
`;

export const StyledLabel = styled.p`
  font-size: 1.3em;

  ${mediaMax.sm`
    font-size: 1em;
  `}
`;

export const StyledTaskName = styled.span`
  margin-top: 5px;
  font-size: 1em;
`;

export const StyledIcon = styled(IoIosAdd)`
  .ui.button:not(.icon)>&.icon:not(.button):not(.dropdown) {
    margin: 0;
    height: 0;
    font-size: 3em;
  }
`;
