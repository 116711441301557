import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';

import AddActionModalForm from './AddActionModalForm/AddActionModalFormContainer';

class AddAction extends Component {

  constructor() {
    super();

    this.handleAddAction = this.handleAddAction.bind(this);
  }

  handleAddAction(values) {
    const {
      onAddAction,
      onIncompleteForm,
      taskId,
    } = this.props;

    const checkForm = !isNil(values.typeId);

    if (checkForm) {
      return new Promise((resolveForm, rejectForm) => {
        onAddAction(
          {
            values: {
              ...values,
              taskId,
            },
            resolveForm,
            rejectForm,
          },
        );
      });
    }

    return onIncompleteForm();
  }

  render() {
    const { taskId, superSites } = this.props;

    return (
      <AddActionModalForm
        onSubmit={this.handleAddAction}
        taskId={taskId}
        superSites={superSites}
      />
    );
  }

}

AddAction.defaultProps = {
  superSites: false,
};

AddAction.propTypes = {
  onAddAction: PropTypes.func.isRequired,
  onIncompleteForm: PropTypes.func.isRequired,
  taskId: PropTypes.string.isRequired,
  superSites: PropTypes.bool,
};

export default AddAction;
