import styled from 'styled-components';

export const StyledPermissionsGridRow = styled.div`
  display: block;
  flex: 1 0 100%;
`;

export const StyledItemWrapper = styled.div`
  display: flex;
`;

export const StyledChildrenWrapper = styled.div`
  display: block;
`;
