import React from 'react';
import { Grid, Header, Segment } from 'semantic-ui-react';

import { messages } from 'data/notifications/notificationsConst';
import { validDisplayName, validEmail } from 'components/form/formValidators';
import { actionCreators } from 'pages/ProjectSuites/utilities';

import { ProjectSuitePropertyInlineEdit, EditNotes } from './components';
import { StyledHeader } from './ProjectSuiteInformation.style';

const ProjectSuiteInformation = () => (
  <Grid>
    <Grid.Row>
      <Grid.Column width={16}>
        <Segment>
          <Header as="h3">Multi-site information</Header>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <StyledHeader as="h5">Primary contact</StyledHeader>
              </Grid.Column>
              <Grid.Column width={8}>
                <ProjectSuitePropertyInlineEdit
                  property="primaryContactPerson"
                  onChange={actionCreators.updateProjectSuite}
                  validator={validDisplayName}
                  message={messages.PROJECT_SUITE_PRIMARY_CONTACT_UPDATED}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <StyledHeader as="h5">Primary contact phone</StyledHeader>
              </Grid.Column>
              <Grid.Column width={8}>
                <ProjectSuitePropertyInlineEdit
                  property="primaryContactPhone"
                  onChange={actionCreators.updateProjectSuite}
                  message={messages.PROJECT_SUITE_PRIMARY_CONTACT_TELEPHONE_UPDATED}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <StyledHeader as="h5">Primary contact email</StyledHeader>
              </Grid.Column>
              <Grid.Column width={8}>
                <ProjectSuitePropertyInlineEdit
                  property="primaryContactEmail"
                  onChange={actionCreators.updateProjectSuite}
                  validator={validEmail}
                  message={messages.PROJECT_SUITE_PRIMARY_CONTACT_EMAIL_UPDATED}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <StyledHeader as="h5">Commercial contact email</StyledHeader>
              </Grid.Column>
              <Grid.Column width={8}>
                <ProjectSuitePropertyInlineEdit
                  property="commercialContactEmail"
                  onChange={actionCreators.updateProjectSuite}
                  validator={validEmail}
                  message={messages.PROJECT_SUITE_COMMERCIAL_CONTACT_EMAIL_UPDATED}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <StyledHeader as="h5">Subscription contact email</StyledHeader>
              </Grid.Column>
              <Grid.Column width={8}>
                <ProjectSuitePropertyInlineEdit
                  property="subscriptionContactEmail"
                  onChange={actionCreators.updateProjectSuite}
                  validator={validEmail}
                  message={messages.PROJECT_SUITE_SUBSCRIPTION_CONTACT_EMAIL_UPDATED}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <StyledHeader as="h5">Technical contact email</StyledHeader>
              </Grid.Column>
              <Grid.Column width={8}>
                <ProjectSuitePropertyInlineEdit
                  property="technicalContactEmail"
                  onChange={actionCreators.updateProjectSuite}
                  validator={validEmail}
                  message={messages.PROJECT_SUITE_TECHNICAL_CONTACT_EMAIL_UPDATED}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={16}>
        <Segment>
          <Header as="h3">Notes</Header>
          <EditNotes />
        </Segment>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export default ProjectSuiteInformation;
