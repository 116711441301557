export default class PathwayCreator {

  constructor(model, sixeyeFirmwareVersion, tabs) {
    this.model = model;
    this.sixeyeFirmwareVersion = sixeyeFirmwareVersion;
    this.manufacturerSDK = 'pathway';
    this.tabs = tabs;
  }

}
