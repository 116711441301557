import isNull from 'lodash/isNull';

import { keyCodes } from 'helpers/sharedVariables';

const checkKeyboardMinus = code => {
  if (isNull(code)) {
    return false;
  }

  if (code === keyCodes.MINUS) {
    return true;
  }

  if (code === keyCodes.MINUS_FF) {
    return true;
  }

  if (code === keyCodes.NUMPADSUBTRACT) {
    return true;
  }

  return false;
};

export default checkKeyboardMinus;
