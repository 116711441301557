import { createSelector } from 'reselect';

import { tenantsSelector } from 'helpers/selectors';

const tenantSelector = createSelector(
  tenantsSelector,
  companies => subdomain => (companies && companies.find(item => item.value === subdomain)) || [],
);

export default tenantSelector;
