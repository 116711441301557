import React from 'react';
import PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';

import { CardAction, CardContent, CardData, CardTextContent, CardWrapper } from './SettingCard.style';

const SettingCard = ({ as, children, title, trigger, onSubmit, readOnlyData }) => (
  <CardWrapper forwardedAs={as} onSubmit={onSubmit}>
    <Header as="h2">{title}</Header>
    {readOnlyData && (
      <CardData>
        <span />
        {readOnlyData}
      </CardData>
    )}
    <CardContent>
      <CardTextContent>
        {children}
      </CardTextContent>
      <CardAction>
        {trigger}
      </CardAction>
    </CardContent>
  </CardWrapper>
);

SettingCard.defaultProps = {
  as: null,
  readOnlyData: null,
  title: '',
  onSubmit: null,
};

SettingCard.propTypes = {
  as: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.object,
  ]),
  children: PropTypes.node.isRequired,
  readOnlyData: PropTypes.node,
  title: PropTypes.string,
  trigger: PropTypes.node.isRequired,
  onSubmit: PropTypes.func,
};

export default SettingCard;
