import React from 'react';
import PropTypes from 'prop-types';

import DataTableText from 'components/DataTable/DataTableText/DataTableText';
import { selectModifier } from '../../../../utilities';
import { StyledDayViewTableRow } from './DayViewTableRow.style';

const DayViewTableRow = ({ resourceTime, item, ...rest }) => {
  const modifierType = selectModifier(item, resourceTime);

  return (
    <StyledDayViewTableRow {...rest} item={item} className={modifierType}>
      <DataTableText />
      <DataTableText />
      <DataTableText />
      <DataTableText />
    </StyledDayViewTableRow>
  );
};

DayViewTableRow.propTypes = {
  resourceTime: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
};

export default DayViewTableRow;
