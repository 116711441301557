import styled, { css } from 'styled-components';
import get from 'lodash/get';
import { Popup, Menu, Checkbox } from 'semantic-ui-react';

import filterProps from 'helpers/filterProps';

export const StyledPopup = styled(Popup)`
  &&& {
    padding: 0;

    &.ui.popup {
      min-width: 230px;
    }
  }
`;

export const StyledMenu = styled(Menu)`
  &&& {
    box-shadow: none;
    border: none;
    margin: 0 !important;

    &.ui.vertical.menu .item {
      display: flex !important;
      justify-content: flex-start;
      align-items: center;
    }
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  ${({
    theme: {
      palette: {
        primaryColor,
      },
    },
  }) => css`
    &.ui.checkbox {
      & input:checked~.box:after,
      & input:checked~label:after {
        content: '' !important;
        width: 9px !important;
        height: 9px !important;
        border-radius: 2px !important;
        top: 4px !important;
        left: 4px !important;
        background-color: ${primaryColor} !important;
      }
    }
  `}

  ${({ type }) => type === 'radio' && css`
    &.ui.checkbox {
        & input~.box:before,
        & input~label:before {
          border-radius: 10px !important;
        }

        & input:checked~.box:before,
        & input:checked~label:before {
          border-radius: 10px !important;
        }

        & input:checked~.box:after,
        & input:checked~label:after {
          border-radius: 5px !important;
        }
      }
    `}
`;

const MenuItem = filterProps(
  ['header', 'line', 'footer'],
)(Menu.Item);

export const StyledMenuItem = styled(MenuItem)`
  ${({ header }) => header && css`
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding-left: 14px !important;
    padding-top: 14px !important;
    padding-bottom: 7px !important;

    small {
      display: none;
    }

    &:hover {
   
      small {
        display: none;
      }
    }
  `}

  ${({ line }) => line && css`
    padding-left: 28px !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  `}

  ${({ footer }) => footer && css`
    border-top: 1px solid #e6e6e6 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 8px !important;
  `}
`;

export const GroupName = styled.h4`
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
`;

export const ApplyLink = styled.span`
  ${({ theme }) => css`
    cursor: pointer;
    color: ${get(theme, 'palette.primaryColor')} !important;
    text-align: center;

    &:hover {
      text-decoration: underline;
    }
  `}
`;

export const Title = styled.h4`
  margin-bottom: 5px;
  font-size: 17px;
`;

export const EmptyMessage = styled.div`
  &&& {
    padding: 42px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: rgba(0,0,0,.6);

    svg {
      margin-bottom: 4px;
    }
  }
`;

export default StyledPopup;
