import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import { DEVICE_FIRMWARES_FIELD } from 'helpers/selectors';

export const deleteDeviceFirmware = deviceFirmware => ({
  type: constants.DELETE_DEVICE_FIRMWARE_REQUEST,
  payload: {
    deviceFirmware,
    endpoint: endpoints.DELETE_DEVICE_FIRMWARE(deviceFirmware.id),
  },
});

export const deleteDeviceFirmwareSuccess = response => ({
  type: constants.DELETE_DEVICE_FIRMWARE_SUCCESS,
  payload: {
    response,
    message: messages.DEVICE_FIRMWARE_DELETED(response.deviceFirmware.name),
    resourceType: DEVICE_FIRMWARES_FIELD,
  },
});

export const deleteDeviceFirmwareFailure = error => ({
  type: constants.DELETE_DEVICE_FIRMWARE_FAILED,
  payload: { error },
});

export default deleteDeviceFirmware;
