import styled, { css } from 'styled-components';
import { Icon, TextArea } from 'semantic-ui-react';
import get from 'lodash/get';

export const StyledDiv = styled.div`
  width: 30px;
  text-align: center;
`;

export const StyledIcon = styled(Icon)`
  color: ${({ theme }) => get(theme, 'palette.primaryColor')};
  cursor: pointer;

  ${({ empty }) => empty && css`color: #d3d3d3;`}
`;

export const StyledTextArea = styled(TextArea)`
  width: 250px;
  height: 100px;
`;

export default StyledDiv;
