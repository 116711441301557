import { createSelector } from 'reselect';
import memoize from 'lodash/memoize';

import ControlUIModel from 'models/ControlUIModel';
import controlUISelector from './controlUISelector';

const projectControlUISelector = createSelector(
  controlUISelector,
  controlUIs => memoize(
    projectId => {
      if (!controlUIs) {
        return null;
      }

      const filteredControlUIs = controlUIs.filter(
        ({ cpPageableId: controlUIprojectId }) => controlUIprojectId === projectId,
      );

      if (!filteredControlUIs.length) {
        return null;
      }

      return new ControlUIModel(filteredControlUIs[0]);
    },
  ),
);

export default projectControlUISelector;
