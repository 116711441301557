import styled from 'styled-components';
import { Divider } from 'semantic-ui-react';

export const StyledDivider = styled(Divider)`
  &.ui.divider {
    margin: 3.5rem 0 1.5rem 0;
  }
`;

export default StyledDivider;
