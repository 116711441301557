import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  PROJECT_SUITES_FIELD,
  USERS_FIELD,
  permissionsSelectingProjectSuitesSelector,
} from 'helpers/selectors';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { addPagination } from 'helpers/paginationHelpers';
import { actionCreators as projectSuiteActionCreators } from 'pages/ProjectSuites/utilities';

import ProjectSuitesTable from './ProjectSuitesTable';

export const mapStateToProps = state => ({
  selectingProjectSuites: permissionsSelectingProjectSuitesSelector(state),
});

export default compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: PROJECT_SUITES_FIELD },
      () => projectSuiteActionCreators.fetchAdminProjectSuites(
        {
          fields: {
            [PROJECT_SUITES_FIELD]: ['name', USERS_FIELD],
          },
          sort: 'name',
        },
      ),
    ),
  ),
)(ProjectSuitesTable);
