import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  deviceFetchingSelector,
  deviceErrorSelector,
  LOGICAL_DEVICES_FIELD, deviceSelector,
} from 'helpers/selectors';
import deviceActionCreators from 'pages/Project/deviceActionCreators';
import updateSettingsActionCreators from 'pages/Device/updateSettingsActionCreators';
import withConfigurablePolling
  from 'helpers/withConfigurablePolling/withConfigurablePollingContainer';
import chainedLoader from 'helpers/chainedLoader/chainedLoaderContainer';
import PharosBeaconV1, { DEVICE_BEACON_REFRESH } from './PharosBeaconV1';

const mapStateToProps = (state, ownProps) => {
  const { resourceId } = ownProps;
  const {
    beacon: resourceBeacon,
    online: resourceOnline,
  } = deviceSelector(state)(resourceId);

  return {
    chainedLoaderProps: {
      [DEVICE_BEACON_REFRESH]: {
        fetching: deviceFetchingSelector(state),
        error: deviceErrorSelector(state),
      },
    },
    resourceBeacon,
    resourceOnline,
  };
};

const mapDispatchToProps = {
  onToggleBeacon: updateSettingsActionCreators.toggleBeacon,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withConfigurablePolling(DEVICE_BEACON_REFRESH),
  chainedLoader(
    { name: DEVICE_BEACON_REFRESH },
    ({ resourceId }) => deviceActionCreators.fetchDevice(resourceId, {
      fields: {
        [LOGICAL_DEVICES_FIELD]: [
          'beacon',
          'updated',
        ],
      },
    }),
  ),
)(PharosBeaconV1);
