import React, { useState } from 'react';
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi';

import {
  StyledAccordion,
  StyledAccordionTitle,
  StyledAccordionContent,
  StyledMapHeader,
  StyledTitle,
} from './MapView.style';
import { MapWrapper } from './components';

const MapView = () => {
  const [active, setActive] = useState(false);
  const [checkIfClicked, setCheckIfClicked] = useState(false);

  const handleClick = () => {
    setActive(!active);
    setCheckIfClicked(true);
  };

  return (
    <StyledAccordion fluid styled>
      <StyledAccordionTitle
        active={active}
        index={0}
        onClick={handleClick}
      >
        <StyledMapHeader active={active} />
        <StyledTitle active={active}>
          Sites Map View
          {active ? <FiMinimize2 /> : <FiMaximize2 />}
        </StyledTitle>
      </StyledAccordionTitle>
      {checkIfClicked && (
        <StyledAccordionContent active={active}>
          <MapWrapper />
        </StyledAccordionContent>
      )}
    </StyledAccordion>
  );
};

export default MapView;
