import { compose } from 'redux';

import extractValue from 'helpers/extractValue/extractValue';
import markLastUpdate from 'helpers/markLastUpdate/markLastUpdate';
import formatValue from 'helpers/formatValue/formatValue';
import DescriptionItem from 'components/DescriptionList/DescriptionItem/DescriptionItem';
import { boolClosureNormalizer } from '../../utilities';

const BoolDescriptionItem = compose(
  extractValue,
  markLastUpdate,
  formatValue(boolClosureNormalizer),
)(DescriptionItem);

export default BoolDescriptionItem;
