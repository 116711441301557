import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { UploadFirmwareFileModalForm } from './components';

const UploadFirmwareFile = ({ onFirmwareFileUpload }) => {
  const handleFirmwareFileUpload = useCallback(
    values => new Promise(
      (resolveForm, rejectForm) => {
        onFirmwareFileUpload({
          values,
          resolveForm,
          rejectForm,
        });
      }),
    [onFirmwareFileUpload],
  );

  return (
    <UploadFirmwareFileModalForm onSubmit={handleFirmwareFileUpload} />
  );
};

UploadFirmwareFile.propTypes = {
  onFirmwareFileUpload: PropTypes.func.isRequired,
};

export default UploadFirmwareFile;
