import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { actionCreators } from '../../../../../../../../utilities';
import RemoveBackgroundImage from './RemoveBackgroundImage';

export const mapStateToProps = (state, { match }) => {
  const { id: projectId } = match.params;

  return {
    projectId,
  };
};

const mapDispatchToProps = {
  onRemovePageBackgroundImage: actionCreators.removePageBackgroundImage,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(RemoveBackgroundImage);
