import React from 'react';
import PropTypes from 'prop-types';

import formNames from 'components/form/formNames';

import { StyledProjectSuitePropertyInlineEdit } from './ProjectSuitePropertyInlineEdit.style';

function ProjectSuitePropertyInlineEdit({
  projectSuiteId,
  validator,
  property,
  propertyValue,
  passesPermissions,
  onChangeProperty,
  message,
}) {
  const form = `${formNames.changeProjectSuiteProperty}.${property}`;

  const handleChangeProperty = values => new Promise(
    (resolveForm, rejectForm) => {
      onChangeProperty({
        values: {
          id: projectSuiteId,
          ...values,
        },
        message,
        resolveForm,
        rejectForm,
      });
    });

  return (
    <StyledProjectSuitePropertyInlineEdit
      name={property}
      type="text"
      value={propertyValue}
      validator={validator}
      onSubmit={handleChangeProperty}
      form={form}
      locked={!passesPermissions}
      allowCancelOnPristine
    />
  );
}

ProjectSuitePropertyInlineEdit.defaultProps = {
  propertyValue: undefined,
  validator: null,
  message: null,
};

ProjectSuitePropertyInlineEdit.propTypes = {
  projectSuiteId: PropTypes.string.isRequired,
  onChangeProperty: PropTypes.func.isRequired,
  property: PropTypes.string.isRequired,
  passesPermissions: PropTypes.bool.isRequired,
  propertyValue: PropTypes.string,
  validator: PropTypes.func,
  message: PropTypes.string,
};

export default ProjectSuitePropertyInlineEdit;
