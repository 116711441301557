import React from 'react';

import StatusIcon from 'components/StatusIcon/StatusIcon';

const DeviceStatusIcon = props => (
  <StatusIcon
    model="device"
    {...props}
  />
);

export default DeviceStatusIcon;
