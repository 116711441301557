import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { taskSelector } from 'helpers/selectors';
import TaskToken from './TaskToken';

export const mapStateToProps = (state, { id: taskId }) => {
  const { name } = taskSelector(state)(taskId) || {};

  return {
    name,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
)(TaskToken);
