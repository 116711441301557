import isString from 'lodash/isString';

const removeBraces = string => {
  if (isString(string)) {
    return string.replace(/[{}]/g, '');
  }

  return string;
};

export default removeBraces;
