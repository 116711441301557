import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import get from 'lodash/get';

import DataTableRow from 'components/DataTable/DataTableRow/DataTableRow';

export const StyledUserTableRow = styled(DataTableRow)`
  cursor: pointer;

  ${({ highlighted, theme }) => highlighted && css`  
    background-color: ${lighten(0.4, get(theme, 'palette.primaryColor'))};

    .ui.selectable.table tbody tr&:hover {
      background-color: ${lighten(0.3, get(theme, 'palette.primaryColor'))} !important;
    }
  `}
`;

export default StyledUserTableRow;
