import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';
import deepEqual from 'lodash/isEqual';
import identity from 'lodash/identity';

import { deviceSelector } from 'helpers/selectors';
import PharosClockV1 from './PharosClockV1';

const makeMapState = () => {
  const memoCurrentTime = memoizeOne(identity, deepEqual);
  const memoOffset = memoizeOne(identity, deepEqual);
  const memoSunriseTime = memoizeOne(identity, deepEqual);
  const memoSunsetTime = memoizeOne(identity, deepEqual);

  return function mapStateToProps(state, ownProps) {
    const { resourceId } = ownProps;
    const device = deviceSelector(state)(resourceId);
    const { online, reportedWithMeta } = device;

    const {
      clk: {
        now: currentTime,
        tz: offset = {},
        tz: {
          value: offsetValue,
        } = {},
        astro: {
          sunr: sunriseTime,
          suns: sunsetTime,
        } = {},
      } = {},
    } = reportedWithMeta || {};

    return {
      loading: !reportedWithMeta,
      online,
      offsetValue,
      currentTime: memoCurrentTime(currentTime),
      offset: memoOffset(offset),
      sunriseTime: memoSunriseTime(sunriseTime),
      sunsetTime: memoSunsetTime(sunsetTime),
    };
  };
};

export default connect(makeMapState())(PharosClockV1);
