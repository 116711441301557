import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { checkIfOnlyHasCommas } from './utilities';
import { Container, StyledLabel, StyledInput } from './DataTableFilter.style';

const DataTableFilter = ({ filter, onFilter }) => {
  const [isTyping, setIsTyping] = useState('');
  const [searchCriteria, setSearchCriteria] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(0);

  useEffect(() => {
    if (filter && !isTyping) {
      const key = Object.keys(filter)[0];

      setSearchCriteria(filter[key]);
    }
  }, [filter, isTyping]);

  const delayedOnFilter = query => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const newTypingTimeout = setTimeout(() => {
      onFilter(query);
    }, 475);

    setTypingTimeout(newTypingTimeout);
  };

  const handleInputChange = e => {
    const searchValue = e.target.value;

    setIsTyping(true);
    setSearchCriteria(searchValue);

    if (!checkIfOnlyHasCommas(searchValue)) {
      delayedOnFilter(searchValue.trim());
    }
  };

  return (
    <Container>
      <StyledLabel>Filter:</StyledLabel>
      <StyledInput
        icon="search"
        onChange={handleInputChange}
        value={searchCriteria}
        type="text"
        placeholder="Search..."
      />
    </Container>
  );
};

DataTableFilter.defaultProps = {
  filter: null,
};

DataTableFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
  filter: PropTypes.object,
};

export default DataTableFilter;
