import { compose } from 'redux';
import { connect } from 'react-redux';

import { deviceFetchingSelector } from 'helpers/selectors';
import deviceActionCreators from '../../../deviceActionCreators';
import withRemoveDevice from './withRemoveDevice';

const mapStateToProps = state => ({
  fetching: deviceFetchingSelector(state),
});

const mapDispatchToProps = {
  onRemoveOnlineDevice: deviceActionCreators.removeOnlineDevice,
  onRemoveDevice: deviceActionCreators.removeDevice,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRemoveDevice,
);
