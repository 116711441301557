import { connect } from 'react-redux';
import NotificationTypeRows from './NotificationTypeRows';

export const mapStateToProps = (state, { notificationType, config }) => {
  const selector = config.selectors[notificationType.notificationContext];

  return {
    entities: selector(state),
  };
};

export default connect(mapStateToProps)(NotificationTypeRows);
