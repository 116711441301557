import React, { useState } from 'react';
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi';

import MapWrapperProjectSuite from 'pages/Projects/components/MapView/components/MapWrapper/MapWrapperProjectSuiteContainer';
import {
  StyledAccordion,
  StyledAccordionTitle,
  StyledAccordionContent,
  StyledMapHeader,
  StyledTitle,
} from 'pages/Projects/components/MapView/MapView.style';

const MultiSiteMapView = () => {
  const [active, setActive] = useState(false);
  const [checkIfClicked, setCheckIfClicked] = useState(false);

  const handleClick = () => {
    setActive(!active);
    setCheckIfClicked(true);
  };

  return (
    <StyledAccordion fluid styled>
      <StyledAccordionTitle
        active={active}
        index={0}
        onClick={handleClick}
      >
        <StyledMapHeader active={active} />
        <StyledTitle active={active}>
          Child Sites Map View
          {active ? <FiMinimize2 /> : <FiMaximize2 />}
        </StyledTitle>
      </StyledAccordionTitle>
      {checkIfClicked && (
        <StyledAccordionContent active={active}>
          <MapWrapperProjectSuite />
        </StyledAccordionContent>
      )}
    </StyledAccordion>
  );
};

export default MultiSiteMapView;
