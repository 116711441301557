import constants from 'dispatcherConst';

const initialState = {
  isLoading: false,
  projectsStatus: [],
  isLinkingProject: false,
  fetching: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_PROJECT_SUITE_PROJECTS_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
        projectsStatus: [],
      };
    case constants.FETCH_PROJECT_SUITE_PROJECTS_STATUS_SUCCESS: {
      const { response } = action.payload;
      const { data } = response;

      return {
        ...state,
        projectsStatus: data.map(({ id, attributes }) => ({
          id,
          ...attributes,
        })),
        isLoading: false,
      };
    }
    case constants.FETCH_PROJECT_SUITE_PROJECTS_STATUS_FAILED:
      return { ...state, isLoading: false };
    case constants.REMOVE_PROJECT_FROM_PROJECT_SUITE_SUCCESS: {
      const { response } = action.payload;
      const { projectId } = response || {};

      const newProjectsStatus = state.projectsStatus.filter(project => project.id !== projectId);

      return { ...state, projectsStatus: newProjectsStatus };
    }
    case constants.ADD_PROJECT_TO_PROJECT_SUITE_REQUEST: {
      return {
        ...state,
        isLinkingProject: true,
      };
    }
    case constants.ADD_PROJECT_TO_PROJECT_SUITE_SUCCESS:
    case constants.ADD_PROJECT_TO_PROJECT_SUITE_FAILED:
      return {
        ...state,
        isLinkingProject: false,
      };

    case constants.FETCH_PROJECT_SUITE_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case constants.FETCH_PROJECT_SUITE_SUCCESS:
      return {
        ...state,
        fetching: false,
      };

    case constants.FETCH_PROJECT_SUITE_FAILED:
      return {
        ...state,
        fetching: false,
      };

    default:
      return state;
  }
};

export default reducer;
