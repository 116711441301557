import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import Raven from 'raven-js';
import createRavenMiddleware from 'raven-for-redux';

import rootReducer from 'rootReducer';
import paginationSaga from 'data/paginationSaga';
import { saga as applicationUserSaga } from 'application/utilities/user';
import { saga as twoFactorQRSaga } from 'application/components/structural/TwoFactorQRViewer/utilities';
import projectSaga from 'pages/Projects/projectSaga';
import { tasksSaga } from 'application/tenant/utilities';
import { saga as passwordResetSaga } from 'application/public/login/Login/components/PasswordReset/utilities';
import { saga as resetPasswordSaga } from 'application/public/resetPassword/ResetPassword/utilities';
import inviteUserSaga from 'components/InviteUser/inviteUserSaga';
import notificationsSaga from 'data/notifications/notificationsSaga';
import signUpSaga from 'pages/SignUp/signUpSaga';
import deviceSaga from 'pages/Project/deviceSaga';
import currentCompanySaga from 'themes/currentCompanySaga';
import { saga as companiesSaga } from 'application/tenant/console/company/utilities';
import triggersSaga from 'pages/Device/triggersSaga';
import { saga as originsSaga } from 'application/tenant/console/company/Company/components/OriginTable/utilities';
import uploadsStorageSaga from 'components/UploadsWidget/uploadsStorageSaga';
import uploadsManagerSaga from 'components/UploadsWidget/uploadsManagerSaga';
import newUploadSaga from 'components/NewUpload/newUploadSaga';
import filesSaga from 'pages/Device/filesSaga';
import filesSectionSaga from 'components/FilesSection/filesSectionSaga';
import { saga as timelinesSectionSaga } from 'application/tenant/console/device/utilities/sagas/timelinesSection';
import staticScenesSectionSaga from 'components/StaticScenesSection/staticScenesSectionSaga';
import fixtureGroupsSaga from 'components/FixtureGroupsSection/fixtureGroupsSaga';
import updateSettingsSaga from 'pages/Device/updateSettingsSaga';
import { saga as outputSectionSaga } from 'application/tenant/console/device/components/structural/OutputSection/utilities';
import pollingSaga from 'helpers/withConfigurablePolling/pollingSaga';
import logsSectionSaga from 'components/LogsSection/logsSectionSaga';
import performActionSaga from 'pages/Device/performActionSaga';
import { currentUserSelector, currentUserIdSelector } from 'helpers/selectors';
import chainedLoaderSaga from 'helpers/chainedLoader/chainedLoaderSaga';
import pollingSaga2 from 'helpers/withPollingSaga/pollingSaga';
import resourceUpdateSaga from 'helpers/withResourceUpdate/resourceUpdateSaga';
import withSortingSaga from 'helpers/withSorting/withSortingSaga';
import { permissionsSaga } from 'application/tenant/console/utilities';
import { saga as usersSaga } from 'application/tenant/console/utilities/users';
import notificationTypesSaga from 'components/NotificationTypes/notificationTypesSaga';
import zonesSaga from 'layouts/Pathway/components/PathwayZonesV1/zonesSaga';
import SnapshotsSaga from 'components/SnapshotsSection/SnapshotsSectionSaga';
import valueOverrideSaga from 'helpers/withValueOverride/withValueOverrideSaga';
import websocketsSaga from 'data/websockets/websocketsSaga';
import deviceDataChannelSaga from 'data/websockets/deviceDataChannelSaga';
import channelsSaga from 'data/websockets/channelsSaga';
import { saga as controlUISaga } from 'application/tenant/components/structural/ControlUI/utilities';
import projectFileUploadSaga from 'components/ProjectFileUpload/projectFileUploadSaga';
import automatedOperationsSaga from 'application/tenant/console/site/components/structural/SiteSchedulingTab/components/AutomatedOperations/utilities/saga/automatedOperationsSaga';
import { saga as oneTimePasswordSaga } from 'application/utilities/oneTimePassword';
import { saga as changePasswordSaga } from 'application/tenant/console/profile/Profile/components/ChangePasswordTab/components/ChangePassword/utilities';
import { saga as twoFactorWizardSaga } from 'application/tenant/console/profile/Profile/components/TwoFactorAuthenticationTab/components/TwoFactorAuthentication/components/TwoFactorWizard/utilities';
import { saga as disableTwoFactorSaga } from 'application/tenant/console/profile/Profile/components/TwoFactorAuthenticationTab/components/TwoFactorAuthentication/components/TwoFactorWizard/components/DisableTwoFactor/utilities';
import { saga as refreshOTPSecretSaga } from 'application/tenant/console/profile/Profile/components/TwoFactorAuthenticationTab/components/TwoFactorAuthentication/components/TwoFactorWizard/components/SecretKey/components/RefreshSecretKey/utilities';
import { saga as settingsSaga } from 'application/tenant/console/settings/Settings/utilities';
import { saga as remoteDevicesSaga } from 'layouts/Pharos/components/PharosRemoteDevicesV1/utilities';
import { saga as taskSchedulerSaga } from 'application/tenant/console/site/components/structural/SiteSchedulingTab/components/AutomatedOperations/components/AddAutomatedOperation/utilities';
import { saga as deviceTimelines } from 'application/tenant/console/device/utilities/sagas/deviceTimelines';
import { saga as deviceScenes } from 'application/tenant/console/device/utilities/sagas/deviceScenes';
import { saga as deviceGroups } from 'application/tenant/console/device/utilities/sagas/deviceGroups';
import { saga as projectsForMapSaga } from 'pages/Projects/components/MapView/utilities/saga';
import { saga as devicesForSiteSaga } from 'pages/Projects/components/MapView/components/MapWrapper/components/Markers/components/Marker/components/MarkerDevicesTable/utilities/saga';
import { saga as subscriptionSaga } from 'application/tenant/console/site/components/structural/SiteSubscriptionTab/utilities';
import { saga as copyPasteButtonsControlPanelUi } from 'application/tenant/components/structural/ControlUI/components/ControlUIPageCarousel/components/ControlUIPage/components/ControlUIButtonGroup/ControlUIButton/components/utilities';
import { saga as projectsStatusSaga } from 'pages/Projects/components/StatusWidgets/utilities/saga';
import { saga as projectSuitesSaga } from 'pages/ProjectSuites/utilities';
import { saga as logsActionTypesSaga } from 'application/tenant/console/site/components/structural/SiteActivityLoggingTab/components/LogsFilterParams/utilities';
import { saga as logicalDevicesProjectSuitesSaga } from 'application/tenant/console/site/components/structural/ProjectTasksTab/components/ListOfTasks/utilities';
import { saga as notificationEventsSaga } from 'components/Notifications/utilities';
import { saga as childProjectsForMapSaga } from 'pages/ProjectSuite/ProjectSuiteProjectsTab/components/MultiSiteMapView/utilities/saga';
import { saga as permissionsTableSaga } from 'components/PermissionsTable/utilities';
import { saga as deviceFirmwaresSaga } from 'application/tenant/console/Firmware/utilities';
import { saga as renewSuspendedSiteSaga } from 'application/tenant/console/site/components/structural/SiteSubscriptionTab/components/SubscriptionStatus/components/AutoRenewSuspended/utilities';

const sagaMiddleware = createSagaMiddleware();

const addExtraField = store => next => action => {
  const state = store.getState();
  const currentUserId = currentUserIdSelector(state);

  next({ ...action, extra: { currentUserId } });
};

/* eslint-disable no-underscore-dangle */
const composeEnhancers = typeof window === 'object'
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    actionSanitizer: action => ({
      ...action,
      type: action.type.toString(),
    }),
    serialize: true,
  }) : compose;
/* eslint-enable */

const enhancer = composeEnhancers(applyMiddleware(
  addExtraField,
  logger,
  sagaMiddleware,
  createRavenMiddleware(Raven, {
    getUserContext: state => currentUserSelector(state),
  }),
));

const appStore = createStore(
  rootReducer,
  enhancer,
);

sagaMiddleware.run(paginationSaga);
sagaMiddleware.run(applicationUserSaga);
sagaMiddleware.run(twoFactorQRSaga);
sagaMiddleware.run(projectSaga);
sagaMiddleware.run(passwordResetSaga);
sagaMiddleware.run(resetPasswordSaga);
sagaMiddleware.run(usersSaga);
sagaMiddleware.run(inviteUserSaga);
sagaMiddleware.run(notificationsSaga);
sagaMiddleware.run(signUpSaga);
sagaMiddleware.run(deviceSaga);
sagaMiddleware.run(currentCompanySaga);
sagaMiddleware.run(companiesSaga);
sagaMiddleware.run(triggersSaga);
sagaMiddleware.run(settingsSaga);
sagaMiddleware.run(originsSaga);
sagaMiddleware.run(newUploadSaga);
sagaMiddleware.run(uploadsStorageSaga);
sagaMiddleware.run(uploadsManagerSaga);
sagaMiddleware.run(filesSaga);
sagaMiddleware.run(filesSectionSaga);
sagaMiddleware.run(timelinesSectionSaga);
sagaMiddleware.run(staticScenesSectionSaga);
sagaMiddleware.run(fixtureGroupsSaga);
sagaMiddleware.run(updateSettingsSaga);
sagaMiddleware.run(outputSectionSaga);
sagaMiddleware.run(pollingSaga);
sagaMiddleware.run(logsSectionSaga);
sagaMiddleware.run(performActionSaga);
sagaMiddleware.run(chainedLoaderSaga);
sagaMiddleware.run(pollingSaga2);
sagaMiddleware.run(resourceUpdateSaga);
sagaMiddleware.run(withSortingSaga);
sagaMiddleware.run(permissionsSaga);
sagaMiddleware.run(notificationTypesSaga);
sagaMiddleware.run(zonesSaga);
sagaMiddleware.run(SnapshotsSaga);
sagaMiddleware.run(valueOverrideSaga);
sagaMiddleware.run(websocketsSaga);
sagaMiddleware.run(deviceDataChannelSaga);
sagaMiddleware.run(channelsSaga);
sagaMiddleware.run(tasksSaga);
sagaMiddleware.run(controlUISaga);
sagaMiddleware.run(projectFileUploadSaga);
sagaMiddleware.run(automatedOperationsSaga);
sagaMiddleware.run(oneTimePasswordSaga);
sagaMiddleware.run(changePasswordSaga);
sagaMiddleware.run(twoFactorWizardSaga);
sagaMiddleware.run(disableTwoFactorSaga);
sagaMiddleware.run(refreshOTPSecretSaga);
sagaMiddleware.run(settingsSaga);
sagaMiddleware.run(remoteDevicesSaga);
sagaMiddleware.run(taskSchedulerSaga);
sagaMiddleware.run(deviceTimelines);
sagaMiddleware.run(deviceScenes);
sagaMiddleware.run(deviceGroups);
sagaMiddleware.run(projectsForMapSaga);
sagaMiddleware.run(devicesForSiteSaga);
sagaMiddleware.run(subscriptionSaga);
sagaMiddleware.run(copyPasteButtonsControlPanelUi);
sagaMiddleware.run(projectsStatusSaga);
sagaMiddleware.run(projectSuitesSaga);
sagaMiddleware.run(logsActionTypesSaga);
sagaMiddleware.run(logicalDevicesProjectSuitesSaga);
sagaMiddleware.run(notificationEventsSaga);
sagaMiddleware.run(childProjectsForMapSaga);
sagaMiddleware.run(permissionsTableSaga);
sagaMiddleware.run(deviceFirmwaresSaga);
sagaMiddleware.run(renewSuspendedSiteSaga);

export default appStore;
