import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { MANUFACTURER_MODEL_TREE_FIELD } from 'helpers/selectors';

export const fetchDeviceFirmwaresModelTree = () => ({
  type: constants.FETCH_DEVICE_FIRMWARES_MODEL_TREE_REQUEST,
  payload: {
    endpoint: endpoints.DEVICE_FIRMWARES_MODEL_TREE,
    resourceType: MANUFACTURER_MODEL_TREE_FIELD,
  },
});

export const fetchDeviceFirmwaresModelTreeSuccess = response => ({
  type: constants.FETCH_DEVICE_FIRMWARES_MODEL_TREE_SUCCESS,
  payload: {
    response,
    resourceType: MANUFACTURER_MODEL_TREE_FIELD,
  },
});

export const fetchDeviceFirmwaresModelTreeFailure = error => ({
  type: constants.FETCH_DEVICE_FIRMWARES_MODEL_TREE_FAILED,
  payload: { error },
});

export default fetchDeviceFirmwaresModelTree;
