import React from 'react';
import PropTypes from 'prop-types';

import TableMenu from 'components/DataTable/TableMenu.style';

import { DeleteDeviceFirmwareActionLink } from './components';

const DeviceFirmwaresActionLinks = ({ chosenItem, ...rest }) => (
  <TableMenu.Item>
    <TableMenu.Item>
      <DeleteDeviceFirmwareActionLink item={chosenItem} {...rest} />
    </TableMenu.Item>
  </TableMenu.Item>
);

DeviceFirmwaresActionLinks.defaultProps = {
  chosenItem: null,
};

DeviceFirmwaresActionLinks.propTypes = {
  chosenItem: PropTypes.object,
};

export default DeviceFirmwaresActionLinks;
