import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DateTime } from 'luxon';

import { singleProjectSelector } from 'helpers/selectors';
import actionCreators from '../../utilities/actionCreators';

import SiteMapSection from './SiteMapSection';

export const mapStateToProps = (state, { match }) => {
  const { id: siteId } = match.params;
  const {
    locationLoaded,
    locationSet,
    latitude,
    longitude,
    geoAddress,
    offset,
    timezoneId,
    dateBlockingStartTime,
  } = singleProjectSelector(state, siteId) || {};
  const currentSiteDate = DateTime.utc().setZone(timezoneId).toLocaleString({ locale: 'en-gb' });
  const currentSiteTime = DateTime.utc().setZone(timezoneId).toLocaleString(DateTime.TIME_SIMPLE);

  return {
    siteId,
    locationLoaded,
    locationSet,
    latitude,
    longitude,
    geoAddress,
    offset,
    timezoneId,
    currentSiteDate: timezoneId ? currentSiteDate : null,
    currentSiteTime: timezoneId ? currentSiteTime : null,
    dateBlockingStartTime,
  };
};

const mapDispatchToProps = {
  onUpdateProjectDateBlockingStartTime: actionCreators.updateSiteDateBlockingStartTime,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(SiteMapSection);
