import { call } from 'redux-saga/effects';

import { postPath } from 'data/api/requests';
import { processFinalFormResponse } from 'utilities/form';
import ApiRequestBuilder from 'helpers/ApiRequestBuilder';
import { RESET_PASSWORD_REQUEST } from 'helpers/selectors';
import actionCreators from '../actionCreators';

function* sendPasswordResetToken(action) {
  const { endpoint, values, resolveForm } = action.payload;
  const body = new ApiRequestBuilder(RESET_PASSWORD_REQUEST)
    .setId(Date.now().toString())
    .setAttributes(values);
  const { response, error } = yield call(postPath, endpoint, body);

  if (error instanceof Error) {
    error.message = 'No connection to server. Try later.';
  }

  const params = {
    response,
    error,
    resolveForm,
    successDisp: actionCreators.sendPasswordResetTokenSuccess,
    failDisp: actionCreators.sendPasswordResetTokenFailure,
  };
  yield call(processFinalFormResponse, params);
}

export default sendPasswordResetToken;
