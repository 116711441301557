import { compose } from 'redux';
import { connect } from 'react-redux';

import { fileTriggeringSelector } from 'helpers/selectors';
import withTransferFile from './withTransferFile';
import filesSectionActionCreators from '../../filesSectionActionCreators';

const mapStateToProps = state => ({
  triggering: fileTriggeringSelector(state),
});

const mapDispatchToProps = {
  onTriggerFileTransfer: filesSectionActionCreators.triggerFileTransfer,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTransferFile,
);
