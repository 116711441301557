import { call, takeLatest, put } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import { TOKEN } from 'storageConst';
import endpoints from 'data/api/endpoints';
import { postPath, getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import ApiRequestBuilder from 'helpers/ApiRequestBuilder';
import { SIGN_UP_USER } from 'helpers/selectors';
import { actionCreators as applicationUserActionCreators } from 'application/utilities/user';
import signUpActionCreators from './signUpActionCreators';

export function* signUpRequest(action) {
  const { endpoint, invitationToken, params: { values, rejectForm, resolveForm } } = action.payload;
  localStorage.setItem(TOKEN, invitationToken);

  const body = new ApiRequestBuilder(SIGN_UP_USER)
    .setId(Date.now().toString())
    .setAttributes(values);

  const { response, error } = yield call(postPath, endpoint, body);
  if (error instanceof Error) { error.message = 'No connection to server. Try later.'; }

  const params = {
    response,
    error,
    resolveForm,
    rejectForm,
    successDisp: signUpActionCreators.signUpRequestSuccess,
  };
  yield call(processCall, params);
}

export function* checkRedirection(action) {
  const {
    [endpoints.SIGN_UP]: {
      meta: {
        webRedirection,
      },
    },
  } = action.payload.response.meta;

  if (webRedirection) {
    yield put(applicationUserActionCreators.fetchCurrentUser());

    yield put(signUpActionCreators.signUpRedirection(webRedirection));
  }
}

export function* verifyInvitationToken(action) {
  const { endpoint, invitationToken, requester } = action.payload;
  localStorage.setItem(TOKEN, invitationToken);
  const { response, error } = yield call(getPath, endpoint);
  if (error instanceof Error) { error.message = 'No connection to server. Try later.'; }

  const params = {
    response,
    error,
    requester,
    successDisp: signUpActionCreators.invitationTokenVerificationSuccess,
    failDisp: signUpActionCreators.invitationTokenVerificationFailure,
  };
  yield call(processCall, params);
}

function* signUpSaga() {
  yield takeLatest(constants.SIGN_UP_REQUEST, signUpRequest);
  yield takeLatest(constants.SIGN_UP_REQUEST_SUCCESS, checkRedirection);
  yield takeLatest(constants.INVITATION_TOKEN_VERIF, verifyInvitationToken);
}

export default signUpSaga;
