const getPaginationIndex = (length, paginationIndex) => {
  if (paginationIndex > length + 1) {
    return length + 1;
  }

  if (paginationIndex <= 0) {
    return length + 1;
  }

  return paginationIndex;
};

export default getPaginationIndex;
