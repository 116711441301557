import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';

import { frontendPermissions } from 'gateway';

/**
 * A selector that returns whether the current user can manage vouchers.
 * It verifies the loaded vouchers permissions, and checks if the user has the
 * `tenant:self:vouchers` permission set at least for one company.
 * @param {object} state - Current redux state.
 * @param {string} userId - Id of the current user.
 * @returns {boolean} Whether the current user can manage vouchers.
 */
const canManageVouchersSelector = (state, userId) => {
  const permissions = state.subscription.voucherPermissions;

  if (isEmpty(permissions)) {
    return false;
  }

  if (!has(permissions, frontendPermissions.TENANT_VOUCHERS)) {
    return false;
  }

  const permission = permissions[frontendPermissions.TENANT_VOUCHERS];
  const { resources } = permission;

  const resourcesWithUserId = resources.filter(
    resource => resource.recipients
      .map(recipient => recipient.recipient_id)
      .includes(userId),
  );

  if (isEmpty(resourcesWithUserId)) {
    return false;
  }

  let isAllowed = false;

  resourcesWithUserId.forEach(resource => {
    const recipientWithPermission = resource.recipients
      .find(r => r.recipient_id === userId
        && r.allowed);

    if (recipientWithPermission) {
      isAllowed = true;
    }
  });

  return isAllowed;
};

export default canManageVouchersSelector;
