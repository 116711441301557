import { connect } from 'react-redux';

import { actionCreators } from '../../../../utilities';
import DeviceFirmwareCustomRow from './DeviceFirmwareCustomRow';

const mapDispatchToProps = {
  onEditDeviceFirmware: actionCreators.editDeviceFirmware,
};

export default connect(null, mapDispatchToProps)(DeviceFirmwareCustomRow);
