import mergeWith from 'lodash/mergeWith';

import constants from 'dispatcherConst';
import { mergeCustomizer } from 'helpers/sharedMethods';
import { ACTIVITY_LOGS_FIELD, ACTIVE_PROJECT_FIELD, ACTIVE_REQUEST_ID_FIELD } from 'helpers/selectors';
import socketConstants from 'data/websockets/constants';

const logsWebsocketsReducer = (state, action) => {
  switch (action.type) {
    case constants.SOCKET_RECEIVED: {
      const { identifier } = action.payload;
      const { message } = action.payload;

      if (identifier && message && message.type === socketConstants.LOGS_READY) {
        const projectId = state[ACTIVE_PROJECT_FIELD];
        const activeRequestId = state[ACTIVE_REQUEST_ID_FIELD];
        const { request_id: requestId } = message;

        if (projectId && activeRequestId === requestId) {
          const { logs } = message;
          const logsState = {
            [ACTIVITY_LOGS_FIELD]: {
              [projectId]: { logs },
            },
          };

          return mergeWith({}, state, logsState, mergeCustomizer);
        }

        return state;
      }

      return state;
    }

    case constants.ADD_ACTIVE_PROJECT_ID: {
      const { projectId } = action.payload;

      if (projectId) {
        const logsState = {
          [ACTIVE_PROJECT_FIELD]: projectId,
        };

        return mergeWith({}, state, logsState, mergeCustomizer);
      }

      return state;
    }

    case constants.CLEAR_ACTIVE_PROJECT_ID: {

      const logsState = {
        [ACTIVE_PROJECT_FIELD]: null,
      };

      return mergeWith({}, state, logsState, mergeCustomizer);
    }

    case constants.ADD_ACTIVE_REQUEST_ID: {
      const { requestId } = action.payload;

      if (requestId) {
        const logsState = {
          [ACTIVE_REQUEST_ID_FIELD]: requestId,
        };

        return mergeWith({}, state, logsState, mergeCustomizer);
      }

      return state;
    }

    default:
      return state;
  }
};

export default logsWebsocketsReducer;
