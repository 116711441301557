import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';

import { validateOnSubmit } from 'components/form/formConfigs';
import PageNameColorPickerModalForm from './PageNameColorPickerModalForm';

export default compose(
  withRouter,
  reduxForm({ ...validateOnSubmit }),
)(PageNameColorPickerModalForm);
