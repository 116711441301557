import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import { extractTimePart } from 'helpers/sharedMethods';

const deviceTimeWithOffset = (value, offset) => {
  const date = DateTime.fromMillis(value * 1000);

  if (isNil(offset)) {
    return value;
  }

  if (offset < 0) {
    const timestampWithOffset = date.minus({ hours: Math.abs(offset) }).toMillis() / 1000;

    return Math.abs(extractTimePart(timestampWithOffset));
  }

  return extractTimePart(date.plus({ hours: offset }).toMillis() / 1000);
};

export default deviceTimeWithOffset;
