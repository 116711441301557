import React from 'react';
import PropTypes from 'prop-types';

import UnorderedList from 'components/UnorderedList/UnorderedList';
import FormErrorsWrapper from './FormErrors.style';

const FormErrors = ({ errors }) => errors && (
  <FormErrorsWrapper>
    <UnorderedList items={errors} />
  </FormErrorsWrapper>
);

FormErrors.defaultProps = {
  errors: null,
};

FormErrors.propTypes = {
  errors: PropTypes.array,
};

export default FormErrors;
