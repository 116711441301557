import { createSelector } from 'reselect';
import build from 'redux-object';
import memoize from 'lodash/memoize';

import { DEVICES_FOR_SITE_FIELD, dataSelector, options } from 'helpers/selectors';

const devicesForSiteSelector = createSelector(
  dataSelector,
  data => memoize(
    projectId => build(data, DEVICES_FOR_SITE_FIELD, projectId || '', options),
  ),
);

export default devicesForSiteSelector;
