import isNil from 'lodash/isNil';

import updatePages from './updatePages';

const updateControlPanelObject = ({
  projectControlUI,
  pageId,
  buttonId,
  buttonContent,
  controlType = 'button',
}) => {
  if (isNil(projectControlUI) || isNil(buttonContent)) {
    return null;
  }

  const {
    id,
    pages,
    ...attributes
  } = projectControlUI;
  const newPages = updatePages({
    pages,
    pageId,
    buttonId,
    buttonContent,
    controlType,
  });

  return {
    id,
    pages: newPages,
    ...attributes,
  };
};

export default updateControlPanelObject;
