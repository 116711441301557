import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const deleteInvitation = invitationId => ({
  type: constants.DELETE_INVITATION_REQUEST,
  payload: {
    invitationId,
    endpoint: endpoints.INVITATION_DELETE(invitationId),
  },
});

export const deleteInvitationSuccess = response => ({
  type: constants.DELETE_INVITATION_SUCCESS,
  payload: { response },
});

export const deleteInvitationFailure = error => ({
  type: constants.DELETE_INVITATION_FAILED,
  payload: { error },
});
