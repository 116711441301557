export const defaultManifest = {
  short_name: 'SixEye',
  name: 'SixEye Console Web Client',
  icons: [
    {
      src: 'favicon.ico',
      sizes: '192x192',
      type: 'image/png',
    },
  ],
  start_url: '.',
  display: 'standalone',
  theme_color: '#000000',
  background_color: '#ffffff',
  splash_screens: [],
};

export const splashScreensConfig = [
  //  iPhone 5 (640px x 1136px)
  { query: '(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)', size: '640x1136' },
  //  iPhone 8, 7, 6s, 6 (750px x 1334px)
  { query: '(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)', size: '750x1334' },
  //  iPhone 8 Plus, 7 Plus, 6s Plus, 6 Plus (1242px x 2208px)
  { query: '(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3)', size: '1242x2208' },
  //  iPhone X, Xs (1125px x 2436px)
  { query: '(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)', size: '1125x2436' },
  //  iPhone Xs Max (1242px x 2688px)
  { query: '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)', size: '1242x2688' },
  //  iPhone Xr (828px x 1792px)
  { query: '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)', size: '828x1792' },
  //  iPad Mini, Air (1536px x 2048px)
  { query: '(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)', size: '1536x2048' },
  //  iPad Pro 10.5' (1668px x 2224px)
  { query: '(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)', size: '1668x2224' },
  //  iPad Pro 11” (1668px x 2388px)
  { query: '(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2)', size: '1668x2388' },
  //  iPad Pro 12.9' (2048px x 2732px)
  { query: '(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)', size: '2048x2732' },
];
