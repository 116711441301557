import { call } from 'redux-saga/effects';

import { deletePath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';

import actionCreators from '../actionCreators';

function* removeUserFromProjectSuite(action) {
  const { userId, projectSuiteId, userEmail, endpoint } = action.payload;

  const payload = {
    data: [
      {
        id: userId,
        type: 'users',
      },
    ],
  };

  let { response, error } = yield call(deletePath, endpoint, payload);

  response = response ? { projectSuiteId, userEmail, userId } : undefined;

  error = error ? new Error(`Unable to remove user ${userEmail} from Multi-site`) : undefined;

  const params = {
    response,
    error,
    successDisp: actionCreators.removeUserFromProjectSuiteSuccess,
    failDisp: actionCreators.removeUserFromProjectSuiteFailure,
  };

  yield call(processCall, params);
}

export default removeUserFromProjectSuite;
