import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import { USERS_FIELD } from 'helpers/selectors';

export const removeUserFromProjectSuite = ({
  userId,
  userEmail,
  projectSuiteId,
}) => ({
  type: constants.REMOVE_USER_FROM_PROJECT_SUITE_REQUEST,
  payload: {
    userId,
    userEmail,
    projectSuiteId,
    endpoint: endpoints.PROJECT_SUITE_REMOVE_USER(projectSuiteId),
  },
});

export const removeUserFromProjectSuiteSuccess = response => ({
  type: constants.REMOVE_USER_FROM_PROJECT_SUITE_SUCCESS,
  payload: {
    response,
    message: messages.USER_REMOVED_FROM_PROJECT_SUITE(response.userEmail),
    resourceType: USERS_FIELD,
  },
});

export const removeUserFromProjectSuiteFailure = error => ({
  type: constants.REMOVE_USER_FROM_PROJECT_SUITE_FAILED,
  payload: { error },
});

export default removeUserFromProjectSuite;
