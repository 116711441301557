import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

const getColorPickerValues = formValues => {
  if (isNil(formValues) || isEmpty(formValues)) {
    return {
      intensity: 100,
      temperature: 50,
      red: 0,
      green: 0,
      blue: 0,
    };
  }

  const { intensity, temperature, red, blue, green } = formValues;

  if (isNil(red) && isNil(green) && isNil(blue)) {
    return {
      intensity,
      temperature,
      red: 100,
      green: 100,
      blue: 100,
    };
  }

  if (red === '0.00' && green === '0.00' && blue === '0.00') {
    return {
      intensity,
      temperature,
      red: 100,
      green: 100,
      blue: 100,
    };
  }

  return {
    intensity,
    temperature,
    red,
    green,
    blue,
  };
};

export default getColorPickerValues;
