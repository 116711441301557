
import { permissionsUserSelector } from 'helpers/selectors';

const permissionsUserCopyUserIdSelector = (state, { userId }) => {
  const { copyUserId } = permissionsUserSelector(state)(userId);

  return copyUserId;
};

export default permissionsUserCopyUserIdSelector;
