import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { PermissionsSwitch, Gateway, frontendPermissions } from 'gateway';
import { paths } from 'navigation';

import { MenuItem } from '..';

const VouchersMenuItem = ({ isSuperAdmin, canManageVouchers }) => {
  const isMenuVisible = useCallback(permissionChecker => {
    if (isSuperAdmin) {
      return true;
    }

    const isTenantAdmin = permissionChecker(frontendPermissions.TENANT_SA);

    if (isTenantAdmin) {
      return true;
    }

    if (canManageVouchers) {
      return true;
    }

    return false;
  }, [isSuperAdmin, canManageVouchers]);

  return (
    <PermissionsSwitch>
      <Gateway condition={isMenuVisible}>
        <MenuItem to={paths.vouchers} />
      </Gateway>
    </PermissionsSwitch>
  );
};

export default VouchersMenuItem;

VouchersMenuItem.defaultProps = {
  isSuperAdmin: false,
  canManageVouchers: false,
};

VouchersMenuItem.propTypes = {
  isSuperAdmin: PropTypes.bool,
  canManageVouchers: PropTypes.bool,
};
