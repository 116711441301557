import React, { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import isNull from 'lodash/isNull';
import classNames from 'classnames';

import { Tooltip } from 'components/elements';
import StyledDefinitionListItemSingle from './DefinitionListItemSingle.style';
import { getFormat } from './utilities';

export const isPresent = value => !isNull(value) && value !== '';

class DefinitionListItemSingle extends Component {

  static incrementCallback({ increment }, { value }) {
    return {
      previousValue: value,
      increment: increment + 1,
    };
  }

  constructor({ value, autoincrement }) {
    super();

    this.state = {
      previousValue: null,
      isMarked: false,
      increment: autoincrement ? value : undefined,
    };

    this.handleUnmark = this.handleUnmark.bind(this);
  }

  componentDidMount() {
    this.setIntervalIfAutoincrementAndOnline(this.props);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value: nextValue } = nextProps;
    const { value, autoincrement } = this.props;

    if (value !== nextValue) {
      this.setState({
        previousValue: value,
        isMarked: true,
        increment: autoincrement ? nextValue : undefined,
      },
      this.handleUnmark);
    }

    this.setIntervalIfAutoincrementAndOnline(nextProps);
  }

  componentWillUnmount() {
    this.clearTimeout();
    this.clearInterval();
  }

  setIntervalIfAutoincrementAndOnline({ isOnline, autoincrement }) {
    if (!autoincrement) return;

    if (isOnline) {
      if (!this.interval) {
        this.setInterval();
      }

      return;
    }

    this.clearInterval();
  }

  setInterval() {
    this.interval = setInterval(
      () => this.setState(DefinitionListItemSingle.incrementCallback),
      1000,
    );
  }

  formatValues() {
    const { previousValue, increment } = this.state;
    const { value, format } = this.props;
    const formatMethod = getFormat(format, value);

    return {
      value: isPresent(value) ? formatMethod(value, increment) : value,
      previousValue: isPresent(previousValue) ? formatMethod(previousValue) : previousValue,
    };
  }

  // eslint-disable-next-line react/sort-comp
  handleUnmark() {
    this.timeout = setTimeout(
      () => this.setState({ isMarked: false }),
      5000,
    );
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = undefined;
  }

  clearTimeout() {
    clearTimeout(this.timeout);
    this.timeout = undefined;
  }

  render() {
    const { isMarked } = this.state;
    const { className, title, lastUpdate, as, valueField } = this.props;
    const { value, previousValue } = this.formatValues();

    const listItemSingle = (
      <StyledDefinitionListItemSingle
        className={classNames(className, { marked: isMarked })}
      >
        <dt>{title}</dt>
        <dd>
          {as
            ? cloneElement(as, { [valueField]: value || null })
            : isPresent(value) && value}
        </dd>
      </StyledDefinitionListItemSingle>
    );

    if (lastUpdate) {
      const from = isPresent(previousValue) ? `from ${previousValue} ` : '';
      const lastUpdateUTC = new Date(lastUpdate * 1000).toUTCString();
      const tooltipMessage = `${from}at ${lastUpdateUTC}`;

      return (
        <Tooltip
          trigger={listItemSingle}
          content={tooltipMessage}
          position="top right"
          wide
        />
      );
    }

    return listItemSingle;
  }

}

DefinitionListItemSingle.defaultProps = {
  className: '',
  lastUpdate: null,
  value: null,
  format: null,
  autoincrement: false,
  isOnline: false,
  as: null,
  valueField: 'value',
};

DefinitionListItemSingle.propTypes = {
  className: PropTypes.string,
  lastUpdate: PropTypes.number,
  title: PropTypes.string.isRequired,
  format: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
  autoincrement: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  isOnline: PropTypes.bool,
  as: PropTypes.element,
  valueField: PropTypes.string,
};

export default DefinitionListItemSingle;
