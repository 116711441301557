import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import { PROJECTS_FIELD } from 'helpers/selectors';

export const removeProjectFromProjectSuite = ({
  projectId,
  projectName,
  projectSuiteId,
}) => ({
  type: constants.REMOVE_PROJECT_FROM_PROJECT_SUITE_REQUEST,
  payload: {
    projectId,
    projectName,
    projectSuiteId,
    endpoint: endpoints.PROJECT_SUITE_REMOVE_PROJECT(projectSuiteId),
  },
});

export const removeProjectFromProjectSuiteSuccess = response => ({
  type: constants.REMOVE_PROJECT_FROM_PROJECT_SUITE_SUCCESS,
  payload: {
    response,
    message: messages.PROJECT_REMOVED_FROM_PROJECT_SUITE(response.projectName),
    resourceType: PROJECTS_FIELD,
  },
});

export const removeProjectFromProjectSuiteFailure = error => ({
  type: constants.REMOVE_PROJECT_FROM_PROJECT_SUITE_FAILED,
  payload: { error },
});

export default removeProjectFromProjectSuite;
