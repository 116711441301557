import { connect } from 'react-redux';

import { deviceSelector } from 'helpers/selectors';
import performActionActionCreators from 'pages/Device/performActionActionCreators';
import PharosFormatDeviceV1 from './PharosFormatDeviceV1';

export const mapStateToProps = (state, ownProps) => {
  const { resourceId } = ownProps;
  const { online } = deviceSelector(state)(resourceId);

  return {
    online,
  };
};

const mapDispatchToProps = {
  onFormat: performActionActionCreators.performFormat,
};

export default connect(mapStateToProps, mapDispatchToProps)(PharosFormatDeviceV1);
