import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toLonLat } from 'ol/proj';

import { getDisplayName } from 'helpers/sharedMethods';
import TempSiteMarker from './TempSiteMarker/TempSiteMarkerContainer';

const siteMapEmptyClickBehaviour = WrappedComponent => {
  class SiteMapEmptyClickBehaviour extends Component {

    constructor(props) {
      super(props);

      const { projectSuiteId } = props;

      this.handleEmptyClick = this.handleEmptyClick.bind(this);
      this.handleCancel = this.handleCancel.bind(this);
      this.state = {
        marker: {
          id: 'temp-site-marker',
          resourceId: projectSuiteId,
          component: TempSiteMarker,
          onCancel: this.handleCancel,
          hidden: true,
          longitude: 0,
          latitude: 0,
        },
      };
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps({ markers }) {
      const { longitude, latitude } = markers[0];
      const { markers: prevMarkers } = this.props;
      const {
        longitude: prevLongitude,
        latitude: prevLatitude,
      } = prevMarkers[0];
      const { marker } = this.state;

      if (longitude !== prevLongitude || latitude !== prevLatitude) {
        this.setState({
          marker: {
            ...marker,
            hidden: true,
          },
        });
      }
    }

    handleEmptyClick(map, pixel) {
      const { marker } = this.state;
      const { projectSuiteId, onFetchReverseGeocoding, passesPermissions } = this.props;
      const coordinate = map.getCoordinateFromPixel(pixel);
      const [longitude, latitude] = toLonLat(coordinate);

      if (passesPermissions) {
        onFetchReverseGeocoding(projectSuiteId, latitude, longitude);

        this.setState({
          marker: {
            ...marker,
            projectSuiteId,
            longitude,
            latitude,
            initiallyOpen: true,
            hidden: false,
          },
        });
      }
    }

    handleCancel() {
      const { marker } = this.state;

      this.setState({
        marker: {
          ...marker,
          hidden: true,
        },
      });
    }

    render() {
      const { marker } = this.state;
      const { markers } = this.props;
      const siteMarker = markers[0];
      const modifiedSiteMarker = { ...siteMarker, hidden: !marker.hidden };
      const modifiedMarkers = [modifiedSiteMarker, marker];

      return (
        <WrappedComponent
          onEmptyClick={this.handleEmptyClick}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...this.props}
          markers={modifiedMarkers}
        />
      );
    }

  }

  SiteMapEmptyClickBehaviour.propTypes = {
    projectSuiteId: PropTypes.string.isRequired,
    markers: PropTypes.array.isRequired,
    onFetchReverseGeocoding: PropTypes.func.isRequired,
    passesPermissions: PropTypes.bool.isRequired,
  };

  SiteMapEmptyClickBehaviour.displayName = (
    `SiteMapEmptyClickBehaviour(${getDisplayName(WrappedComponent)})`
  );

  return SiteMapEmptyClickBehaviour;
};

export default siteMapEmptyClickBehaviour;
