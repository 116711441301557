import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
} from 'semantic-ui-react';
import { Field } from 'redux-form';

import {
  FormFieldLabel,
  FormFieldWrapper,
  InputWrapper,
} from 'components/form/FormField/FormField.style';
import SelectField from 'components/form/SelectField/SelectField';

const SelectSiteDropdown = ({ options, getSiteDevices, selectSite }) => {
  const isLoading = options.length === 0;

  const handleChange = value => {
    getSiteDevices(value);
    selectSite(value);
  };

  return (
    <FormGroup inline>
      <FormFieldWrapper width={16}>
        <FormFieldLabel htmlFor="input">Select site</FormFieldLabel>
        <InputWrapper>
          <Field
            name="projectId"
            placeholder="Sites list"
            component={SelectField}
            options={options}
            loading={isLoading}
            onChange={handleChange}
          />
        </InputWrapper>
      </FormFieldWrapper>
    </FormGroup>
  );
};

SelectSiteDropdown.defaultProps = {
  options: [],
};

SelectSiteDropdown.propTypes = {
  options: PropTypes.array,
  getSiteDevices: PropTypes.func.isRequired,
  selectSite: PropTypes.func.isRequired,
};

export default SelectSiteDropdown;
