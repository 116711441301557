import React from 'react';
import PropTypes from 'prop-types';

import { StyledButton, StyledIcon } from './RefreshButton.style';

const RefreshButton = ({ resourceId, reloadMapData }) => {
  const handleClick = () => reloadMapData(resourceId);

  return (
    <StyledButton
      icon
      onClick={handleClick}
    >
      <StyledIcon name="sync" size="small" />
    </StyledButton>
  );
};

RefreshButton.propTypes = {
  resourceId: PropTypes.string.isRequired,
  reloadMapData: PropTypes.func.isRequired,
};

export default RefreshButton;
