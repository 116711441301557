import { createSelector } from 'reselect';
import memoize from 'lodash/memoize';
import build from 'redux-object';

import {
  PROJECT_SUITES_PROJECTS_FILTER_FIELD,
  dataSelector,
  options,
} from 'helpers/selectors';

const projectsListSelector = createSelector(
  dataSelector,
  data => memoize(
    projectSuiteId => {
      const result = build(data, PROJECT_SUITES_PROJECTS_FILTER_FIELD, projectSuiteId || '', options);

      return result && result.projectsList;
    },
  ),
);

export default projectsListSelector;
