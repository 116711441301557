import { css } from 'styled-components';
import isObject from 'lodash/isObject';
import at from 'lodash/at';

import colors from 'themes/colors';
import { rgba } from 'polished';

export const sizes = {
  xs: 320,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1920,
};

export const pxToEm = (valuePx, base = 16) => valuePx / base;

export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${pxToEm(sizes[label])}em) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export const mediaMax = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${pxToEm(sizes[label] - 1)}em) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export const mediaOnly = Object.keys(sizes).reduce((acc, label, index, array) => {
  const nextLabel = array[index + 1];

  acc[label] = (index === array.length - 1)
    ? media[label]
    : (...args) => css`
    @media (min-width: ${pxToEm(sizes[label])}em)
    and (max-width: ${pxToEm(sizes[nextLabel] - 1)}em) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export const portrait = (...args) => css`
  @media (orientation: portrait) {
    ${css(...args)}
  }
`;

export const landscape = (...args) => css`
  @media (orientation: landscape) {
    ${css(...args)}
  }
`;

export const injectStyles = (stylesConfig, fallback = null) => {
  if (!stylesConfig) {
    return fallback;
  }

  const sizeNames = Object.keys(sizes);

  const parseToCss = (stylesPart, isParent) => {
    let resultPart = '';

    Object.keys(stylesPart).forEach(key => {
      if (isObject(stylesPart[key])) {
        if (isParent) {
          resultPart += '&';
        }

        const parsed = parseToCss(stylesPart[key]);
        if (sizeNames.includes(key)) {
          resultPart += (media[key]`${parsed}`).join('');
        } else {
          resultPart += `${key}{`;
          resultPart += parsed;
          resultPart += '}';
        }
      } else {
        resultPart += `${key}: ${stylesPart[key]};`;
      }
    });

    return resultPart;
  };

  return parseToCss(stylesConfig, true);
};

const appleOSVersion = pattern => {
  const match = (navigator.appVersion).match(pattern);
  if (!match) return false;

  const version = at(match, [1, 2]).map(part => parseInt(part, 10));

  return parseFloat(version.join('.'));
};

// There is some iOS in Windows Phone...
// https://msdn.microsoft.com/en-us/library/hh869301(v=vs.85).aspx
export const iOSVersion = () => (
  window.MSStream ? false : appleOSVersion(/OS (\d+)_(\d+)_?(\d+)?/)
);

export const isMac = () => (navigator.platform ? (navigator.platform.toUpperCase().indexOf('MAC') >= 0) : false);

export const defaultFocus = css`
  border-color: ${colors.focus};
  box-shadow: 0 0 0 0 ${rgba(colors.shark, 0.35)} inset;
`;

export const defaultBorder = css`
  border: 1px solid ${rgba(colors.shark, 0.15)};
  border-radius: .28571429rem;
`;
