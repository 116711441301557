import React from 'react';
import PropTypes from 'prop-types';

import TableMenu from 'components/DataTable/TableMenu.style';

import { DeleteProjectSuiteActionLink } from './components';

const ProjectActionLinks = ({ chosenItem, ...rest }) => (
  <TableMenu.Item>
    <TableMenu.Item>
      <DeleteProjectSuiteActionLink item={chosenItem} {...rest} />
    </TableMenu.Item>
  </TableMenu.Item>
);

ProjectActionLinks.defaultProps = {
  chosenItem: null,
};

ProjectActionLinks.propTypes = {
  chosenItem: PropTypes.object,
};

export default ProjectActionLinks;
