import { ERROR_LEVEL, SUCCESS_LEVEL, INFO_LEVEL } from 'data/notifications/notificationsConst';

class NotificationModel {

  constructor(notification = {}) {
    this.id = notification.id;
    this.duration = notification.duration;
    this.level = notification.level;
    this.message = notification.message;
  }

  get title() {
    switch (this.level) {
      case ERROR_LEVEL:
        return 'Error';
      case SUCCESS_LEVEL:
        return 'Success';
      case INFO_LEVEL:
        return 'Information';
      default:
        return '';
    }
  }

  get isPlural() {
    const { message } = this;

    return message instanceof Array && message.length > 1;
  }

}

export default NotificationModel;
