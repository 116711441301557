import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Grid, Form } from 'semantic-ui-react';

import * as validators from 'components/form/formValidators';
import TextField from 'components/form/TextField/TextField';
import SelectField from 'components/form/SelectField/SelectField';
import { FormFieldLabel } from 'components/form/FormField/FormField.style';

import {
  StyledLabel,
  StyledRow,
  StyledColumn,
  StyledButton,
} from './VoucherForm.style';

function SiteDetailsForm({
  invalid,
  onSubmit,
  isLoading,
  plans,
}) {
  const subscriptionLengthOptions = useMemo(() => [
    { text: '1 year', value: '1' },
    { text: '2 years', value: '2' },
    { text: '3 years', value: '3' },
    { text: '5 years', value: '5' },
  ], []);

  const subscriptionPlanOptions = useMemo(() => {
    const validPlans = plans.filter(plan => plan.id !== 'Construction');

    return validPlans.map(plan => ({
      text: plan.id,
      value: plan.id,
    }));
  }, [plans]);

  return (
    <Form>
      <Grid>
        <StyledRow>
          <StyledColumn marginBottom width={8}>
            <FormFieldLabel htmlFor="subscriptionPlanCode">
              Plan
            </FormFieldLabel>
            <Field
              name="subscriptionPlanCode"
              placeholder="Subscription plan"
              component={SelectField}
              options={subscriptionPlanOptions}
              loading={isLoading}
              validate={validators.required}
              required
              fluid
            />
          </StyledColumn>
          <StyledColumn width={8}>
            <FormFieldLabel htmlFor="input">
              Length
            </FormFieldLabel>
            <Field
              name="subscriptionLength"
              placeholder="Subscription length"
              component={SelectField}
              options={subscriptionLengthOptions}
              loading={isLoading}
              validate={validators.required}
              required
              fluid
            />
          </StyledColumn>
          <StyledColumn marginBottom width={16}>
            <StyledLabel htmlFor="input">
              Reference
            </StyledLabel>
            <Field
              name="description"
              type="text"
              placeholder="Reference text"
              fluid
              component={TextField}
              autoComplete="off"
              validate={validators.required}
              required
            />
          </StyledColumn>
          <StyledColumn width={16}>
            <StyledButton
              disabled={invalid || isLoading}
              loading={isLoading}
              primary
              onClick={onSubmit}
            >
              Generate
            </StyledButton>
          </StyledColumn>
        </StyledRow>
      </Grid>
    </Form>
  );
}

SiteDetailsForm.defaultProps = {
  plans: [],
};

SiteDetailsForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  plans: PropTypes.array,
};

export default SiteDetailsForm;
