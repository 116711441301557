import React from 'react';
import PropTypes from 'prop-types';
import { Form, Segment, Radio } from 'semantic-ui-react';
import Tooltip from 'components/elements/Tooltip';
import { RefreshForm, RefreshButton } from './RefreshForm.style';

export default function RefreshInterface({
  isSubscribed,
  isRefreshing,
  onSubscribe,
  onUnsubscribe,
  onRefresh,
}) {
  const handleChange = (e, { value }) => {
    if (value === 'on') {
      onSubscribe();
    } else {
      onUnsubscribe();
    }
  };

  const handleRefresh = () => onRefresh();

  const refreshButton = (
    <span>
      <RefreshButton
        name="refresh-button"
        disabled={isSubscribed}
        onClick={handleRefresh}
        isFetching={false}
        loading={isRefreshing}
      />
    </span>
  );

  const tooltip = (
    <Tooltip
      trigger={refreshButton}
      content="Manual refresh enabled when automatic refresh is off"
      position="top right"
      name="refresh-tooltip"
    />
  );

  return (
    <Segment compact basic>
      <RefreshForm>
        <Form.Field>
          Refresh:
        </Form.Field>
        <Form.Group inline>
          <Form.Field>
            <Radio
              label="on"
              name="on"
              value="on"
              checked={isSubscribed && !isRefreshing}
              disabled={isRefreshing}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label="off"
              name="off"
              value="off"
              checked={!isSubscribed || isRefreshing}
              disabled={isRefreshing}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            {!isSubscribed ? refreshButton : tooltip}
          </Form.Field>
        </Form.Group>
      </RefreshForm>
    </Segment>
  );
}

RefreshInterface.propTypes = {
  isSubscribed: PropTypes.bool.isRequired,
  isRefreshing: PropTypes.bool.isRequired,
  onSubscribe: PropTypes.func.isRequired,
  onUnsubscribe: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
};
