import React from 'react';
import PropTypes from 'prop-types';

import { LOGICAL_DEVICES_FIELD } from 'helpers/selectors';
import DataTable from 'components/DataTable/DataTableContainer';
import DeviceActionLinks from './DeviceActionLinks/DeviceActionLinks';
import AddDevice from './AddDevice/AddDeviceContainer';

const devicesTableLabels = [
  { text: 'Name', field: 'name' },
  { text: 'Manufacturer', field: 'manufacturerName', sortable: false },
  { text: 'Model', field: 'model', sortable: false },
  { text: 'Serial#', field: 'serialNumber', sortable: false },
  { text: 'Status', field: 'statusIcon', sortable: false },
];

const ProjectDevicesTab = ({ projectId, projectURL }) => (
  <DataTable
    labels={devicesTableLabels}
    actionLinks={DeviceActionLinks}
    createElement={AddDevice}
    projectId={projectId}
    route={projectURL}
    pagination={{ type: LOGICAL_DEVICES_FIELD }}
  />
);

ProjectDevicesTab.propTypes = {
  projectURL: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default ProjectDevicesTab;
