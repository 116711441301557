import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { simpleValueTypes } from 'data/types';
import StyledDefinitionListItemSingle
  from 'pages/Device/DefinitionList/DefinitionListItem/DefinitionListItemSingle/DefinitionListItemSingle.style';
import { isPresent }
  from 'pages/Device/DefinitionList/DefinitionListItem/DefinitionListItemSingle/DefinitionListItemSingle';
import Tooltip from 'components/elements/Tooltip';

const DescriptionItem = ({
  className,
  title,
  value,
  previousValue,
  lastUpdate,
  isMarked,
}) => {
  const itemClass = classNames(className, { marked: isMarked });

  const rawItem = (
    <StyledDefinitionListItemSingle className={itemClass}>
      <dt>{title}</dt>
      <dd>{value}</dd>
    </StyledDefinitionListItemSingle>
  );

  if (lastUpdate) {
    const from = isPresent(previousValue) ? `from ${previousValue} ` : '';
    const lastUpdateUTC = new Date(lastUpdate * 1000).toUTCString();
    const tooltipMessage = `${from}at ${lastUpdateUTC}`;

    return (
      <Tooltip
        trigger={rawItem}
        content={tooltipMessage}
        position="top right"
        wide
      />
    );
  }

  return rawItem;
};

DescriptionItem.defaultProps = {
  className: '',
  isMarked: false,
  lastUpdate: null,
  previousValue: null,
  value: null,
};

DescriptionItem.propTypes = {
  className: PropTypes.string,
  isMarked: PropTypes.bool,
  lastUpdate: PropTypes.number,
  previousValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    ...simpleValueTypes,
    PropTypes.element,
  ]),
};

export default DescriptionItem;
