import { connect } from 'react-redux';

import { actionCreators } from './utilities';
import ResetPassword from './ResetPassword';

const mapStateToProps = (state, { match: { params: { passwordResetToken } } }) => ({
  passwordResetToken,
});

const mapDispatchToProps = {
  onVerifyPasswordResetToken: actionCreators.verifyPasswordResetToken,
  onResetPassword: actionCreators.resetPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
