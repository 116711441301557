import findKey from 'lodash/findKey';

import constants from 'dispatcherConst';

const initialState = {
  id: undefined,
  name: undefined,
  email: undefined,
  themeName: undefined,
  subdomain: undefined,
  vouchersEnabled: false,
  notificationsEnabled: false,
  fetching: false,
  error: false,
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_CURRENT_COMPANY_REQUEST:
      return {
        ...state,
        name: undefined,
        email: undefined,
        themeName: undefined,
        subdomain: undefined,
        vouchersEnabled: false,
        notificationsEnabled: false,
        fetching: true,
        error: false,
      };

    case constants.FETCH_CURRENT_COMPANY_SUCCESS: {
      const { companies } = action.payload.response;
      const currentCompanyId = findKey(companies);
      const {
        name,
        email,
        themeName,
        subdomain,
        vouchersEnabled,
        notificationsEnabled,
      } = companies[currentCompanyId].attributes;

      return {
        ...state,
        id: currentCompanyId,
        email,
        name,
        themeName,
        subdomain,
        vouchersEnabled,
        notificationsEnabled,
        fetching: false,
        error: false,
      };
    }

    case constants.FETCH_CURRENT_COMPANY_FAILED:
      return { ...state, fetching: false, error: true };

    default:
      return state;
  }
};

export default companyReducer;
