import { createSelector } from 'reselect';

import schedulingSelector from './schedulingSelector';

const selectedProjectIdSelector = createSelector(
  schedulingSelector,
  scheduling => scheduling.projectId,
);

export default selectedProjectIdSelector;
