import { connect } from 'react-redux';
import { getFormValues, change, registerField } from 'redux-form';

import { actionTypeSelector } from 'helpers/selectors';
import formNames from 'components/form/formNames';
import { formatFormValues } from './utilities';
import GenerateActionForm from './GenerateActionForm';

export const mapStateToProps = (state, { actionType, actionIndex, resourceId }) => {
  const action = actionTypeSelector(state)(actionType);
  const selector = getFormValues(formNames.selectedActions);

  return {
    action,
    actionTypeDeviceIdKey: `_${actionType}_${resourceId}_${actionIndex}`,
    formValues: formatFormValues(selector(state), actionType, actionIndex),
    scheduleMode: true,
  };
};

export const mapDispatchToProps = dispatch => ({
  resetFieldValue: field => {
    dispatch(change(formNames.selectedActions, field, null));
  },
  changeFieldValue: (field, value) => {
    dispatch(change(formNames.selectedActions, field, value));
  },
  registerField: field => {
    dispatch(registerField(formNames.selectedActions, field, 'Field'));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GenerateActionForm);
