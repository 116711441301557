import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import AstroFieldFormInput from './AstroFieldFormInput';
import automatedOperationsActionCreators from '../../../../../../utilities/actionCreators';

export const mapStateToProps = (state, { form, astroFieldName }) => {
  const selector = formValueSelector(form);

  return {
    astroFieldValue: selector(state, astroFieldName),
  };
};

const mapDispatchToProps = {
  invalidAstroInput: automatedOperationsActionCreators.invalidInputNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(AstroFieldFormInput);
