import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';

import { FormFieldLabel, FormFieldWrapper, InputWrapper } from './FormField.style';
import FormFeedback from '../FormFeedback/FormFeedback';

const FormField = ({
  children,
  input: { name },
  meta: { touched, error, submitError },
  label,
  formError,
  omitError,
  justify,
  readOnly,
  alignItems,
  hideErrorMessage,
  highlightFieldOnError,
}) => {
  const isErrorVisible = touched && (!!error || !!formError || !!submitError);
  const computedError = !omitError ? isErrorVisible : null;
  const highlightField = highlightFieldOnError ? !!error : computedError;

  return (
    <FormFieldWrapper
      width={16}
      error={computedError}
      alignItems={alignItems}
    >
      {label && (
        <FormFieldLabel htmlFor={name}>
          {label}
        </FormFieldLabel>
      )}
      <InputWrapper className={justify}>
        {cloneElement(children, { error: highlightField })}
        {!omitError && !readOnly && (
          <FormFeedback
            visible={isErrorVisible}
            items={error || formError || submitError}
            negative
            hideErrorMessage={hideErrorMessage}
          />
        )}
      </InputWrapper>
    </FormFieldWrapper>
  );
};


FormField.defaultProps = {
  label: null,
  formError: null,
  omitError: false,
  justify: 'start',
  readOnly: false,
  alignItems: 'center',
  hideErrorMessage: false,
  highlightFieldOnError: false,
};

FormField.propTypes = {
  alignItems: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.string,
      ),
      PropTypes.string,
    ]),
    submitError: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.string,
      ),
      PropTypes.string,
    ]),
  }).isRequired,
  label: PropTypes.string,
  formError: PropTypes.arrayOf(
    PropTypes.string,
  ),
  omitError: PropTypes.bool,
  justify: PropTypes.oneOf([
    'start',
    'end',
  ]),
  readOnly: PropTypes.bool,
  hideErrorMessage: PropTypes.bool,
  highlightFieldOnError: PropTypes.bool,
};

export default FormField;
