import get from 'lodash/get';

import computedResourceUserPermissionsTreeSelector from './computedResourceUserPermissionsTreeSelector';

const userPermissionDataSelector = (state, { path, ...rest }) => {
  const tree = computedResourceUserPermissionsTreeSelector(state)(rest);
  const { permissionCount, userCount, permissionId } = get(tree, path, {});

  return {
    permissionCount,
    userCount,
    permissionId,
  };
};

export default userPermissionDataSelector;
