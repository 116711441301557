import React from 'react';

import { DEVICE_FIRMWARES_FIELD } from 'helpers/selectors';
import DataTable from 'components/DataTable/DataTableContainer';
import AddDeviceFirmware from '../AddDeviceFirmware/AddDeviceFirmwareContainer';
import {
  DeviceFirmwaresActionLinks,
  DeviceFirmwareCustomRow,
} from './components';

const labels = [
  { text: 'Version', field: 'version' },
  { text: 'Date release', field: 'dateRelease', sortable: false },
  { text: 'Compatible device', field: 'displayDeviceModels' },
  { text: 'Compatibility notes', field: 'description', width: 6 },
  { text: 'Files', field: 'displayFiles' },
  { text: 'Hidden', field: 'hidden' },
];

const DeviceFirmwaresTable = () => (
  <DataTable
    header="Device firmwares"
    labels={labels}
    actionLinks={DeviceFirmwaresActionLinks}
    customTableRow={DeviceFirmwareCustomRow}
    createElement={AddDeviceFirmware}
    pagination={{
      type: DEVICE_FIRMWARES_FIELD,
      included: true,
    }}
  />
);

export default DeviceFirmwaresTable;
