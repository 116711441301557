import { delay, all, put, take, race, takeEvery, call } from 'redux-saga/effects';
import constants from 'dispatcherConst';
import { actionWithIdentifierSelector, websocketActionSelector } from 'helpers/selectors';
import { addTenantToIdentifier } from 'helpers/sagaHelper';
import websocketActionCreators from './websocketsActionCreators';
import socketConstants from './constants';

export function* confirmOrRejectSubscription(identifier) {
  const confirmSelector = websocketActionSelector({
    identifier,
    type: constants.SOCKET_RECEIVED,
    messageType: socketConstants.RESPONSE_TYPE_CONFIRM_SUBSCRIPTION,
  });
  const rejectSelector = websocketActionSelector({
    identifier,
    type: constants.SOCKET_RECEIVED,
    messageType: socketConstants.RESPONSE_TYPE_REJECT_SUBSCRIPTION,
  });

  const result = yield race({
    confirm: take(confirmSelector),
    reject: take(rejectSelector),
  });

  return result;
}

export function* subscribeAndUpdate(identifier) {
  const result = yield all({
    subscription: call(confirmOrRejectSubscription, identifier),
    update: take(actionWithIdentifierSelector(constants.SOCKET_RECEIVED, identifier)),
  });

  return result;
}

export function* subscribeChannel(action) {
  const { payload } = addTenantToIdentifier(action);
  const { identifier } = payload;

  yield put(websocketActionCreators.send(payload));

  const { subscribe: { subscription: { confirm } = {} } = {} } = yield race({
    subscribe: call(subscribeAndUpdate, identifier),
    delay: delay(3000),
  });

  if (confirm) {
    yield put(websocketActionCreators.subscribeSuccess({ identifier }));
  } else {
    yield put(websocketActionCreators.subscribeFailed({ identifier }));
  }
}

export function* unsubscribeChannel(action) {
  const { payload } = addTenantToIdentifier(action);

  yield put(websocketActionCreators.send(payload));
}

export function* refreshChannel(action) {
  const { payload: { identifier, meta } } = addTenantToIdentifier(action);

  yield put(websocketActionCreators.subscribe(identifier, meta));

  const { subscribe } = yield race({
    subscribe: take(actionWithIdentifierSelector(constants.SOCKET_SUBSCRIBE_SUCCESS, identifier)),
    failed: take(actionWithIdentifierSelector(constants.SOCKET_SUBSCRIBE_FAILED, identifier)),
  });

  if (subscribe) {
    const { update, timeout } = yield race({
      update: take(actionWithIdentifierSelector(constants.SOCKET_RECEIVED, identifier)),
      timeout: delay(10000),
      subscribe: take(actionWithIdentifierSelector(constants.SOCKET_SUBSCRIBE, identifier)),
      unsubscribe: take(actionWithIdentifierSelector(constants.SOCKET_UNSUBSCRIBE, identifier)),
    });

    if (update || timeout) {
      yield put(websocketActionCreators.unsubscribe(identifier));
    }
  }

  yield put(websocketActionCreators.refreshDone(identifier));
}

export default function* channelsSaga() {
  yield takeEvery(constants.SOCKET_SUBSCRIBE, subscribeChannel);
  yield takeEvery(constants.SOCKET_UNSUBSCRIBE, unsubscribeChannel);
  yield takeEvery(constants.SOCKET_REFRESH, refreshChannel);
}
