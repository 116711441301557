import { compose } from 'redux';
import { connect } from 'react-redux';

import { selectors as applicationUserSelectors } from '../../../utilities/user';
import { actionCreators } from './utilities';
import TwoFactorQRViewer from './TwoFactorQRViewer';

const mapStateToProps = state => ({
  qrUri: applicationUserSelectors.twoFactorQRUriSelector(state),
  secretKey: applicationUserSelectors.twoFactorSecretKeySelector(state),
});

const mapDispatchToProps = {
  onFetchTwoFactorQRCode: actionCreators.fetchTwoFactorQRCode,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(TwoFactorQRViewer);
