import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';

import { complexValueType } from 'data/types';
import TextDescriptionItem
  from 'components/DescriptionList/TextDescriptionItem/TextDescriptionItem';
import LocalDateDescriptionItemIncremented
  from 'components/DescriptionList/LocalDateDescriptionItemIncremented/LocalDateDescriptionItemIncremented';

const PathwayClockV1 = props => {
  const { loading } = props;
  if (loading) return null;

  const {
    title,
    online,
    currentTime,
    offset,
    offsetValue,
  } = props;

  return (
    <Segment>
      <Header as="h2">{title}</Header>
      <LocalDateDescriptionItemIncremented
        title="Current date/time"
        value={currentTime}
        isOnline={online}
        offset={offsetValue}
      />
      <TextDescriptionItem
        title="Time zone"
        value={offset}
      />
    </Segment>
  );
};

PathwayClockV1.defaultProps = {
  loading: false,
  online: false,
  currentTime: {},
  offset: {},
  offsetValue: 0,
};

PathwayClockV1.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  online: PropTypes.bool,
  currentTime: complexValueType(PropTypes.number),
  offset: complexValueType(PropTypes.number),
  offsetValue: PropTypes.number,
};

export default PathwayClockV1;
