import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { frontendPermissions, permissionsPass } from 'gateway';
import { singleProjectSelector } from 'helpers/selectors';
import siteActionCreators from '../../../../utilities/actionCreators';
import EditSiteNotes from './EditSiteNotes';

export const mapStateToProps = (state, { match }) => {
  const { id: siteId } = match.params;
  const { notes } = singleProjectSelector(state, siteId);

  return {
    siteId,
    notes,
  };
};

const mapDispatchToProps = {
  onUpdateSiteNotes: siteActionCreators.updateSiteNotes,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  permissionsPass(frontendPermissions.EDIT_PROJECT),
)(EditSiteNotes);
