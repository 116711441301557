import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';

import constants from 'dispatcherConst';
import { mergeCustomizer } from 'helpers/sharedMethods';
import { CONTROL_UI_FIELD } from 'helpers/selectors';

const dataReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.FETCH_CONTROL_UI_SUCCESS:
      return mergeWith({}, state, action.payload.response, mergeCustomizer);

    case constants.UPDATE_CONTROL_UI_SUCCESS: {
      const {
        data: {
          id: controlUIID,
        },
        [CONTROL_UI_FIELD]: controlPanelPages,
      } = action.payload.response;
      const { [controlUIID]: controlUI } = controlPanelPages;
      const { attributes, attributes: { pages: originalPages } } = controlUI;
      const pages = originalPages.map(page => ({
        ...page,
        backgroundImgBlob: null,
      }));

      return {
        ...state,
        [CONTROL_UI_FIELD]: {
          ...controlPanelPages,
          [controlUIID]: {
            ...controlUI,
            attributes: {
              ...attributes,
              pages,
            },
          },
        },
      };
    }

    case constants.LOAD_BACKGROUND_IMAGE_CONTROL_UI_PAGE_SUCCESS: {
      const { projectId, pageId, fileURL } = action.payload;
      const { [CONTROL_UI_FIELD]: controlPanelPages } = state;
      const controlUIID = Object.keys(controlPanelPages).filter(
        key => controlPanelPages[key].attributes.cpPageableId === projectId,
      )[0];
      const controlUI = controlPanelPages[controlUIID];
      const { attributes, attributes: { pages } } = controlUI;

      const newPages = pages.map(({ index, ...page }) => {
        if (index === pageId) {
          return {
            index,
            ...page,
            backgroundImgBlob: fileURL,
          };
        }

        return {
          index,
          ...page,
        };
      });

      return merge(
        {},
        state,
        {
          [CONTROL_UI_FIELD]: {
            ...controlPanelPages,
            [controlUIID]: {
              ...controlUI,
              attributes: {
                ...attributes,
                pages: newPages,
              },
            },
          },
        },
      );
    }

    default:
      return state;
  }
};

export default dataReducer;
