import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import includes from 'lodash/includes';

import { paths, pathSets } from 'navigation';
import NoMatch from 'pages/NoMatch/NoMatchContainer';
import PublicSwitch from '../public/PublicSwitch';
import TenantSwitch from '../tenant/TenantSwitch';

const ApplicationSwitch = ({ tenant, currentUserId, recentlyLoggedOut }) => {
  const tenantRoutes = pathSets.tenant.map(
    path => (
      <Route
        exact={includes(pathSets.public, path)}
        path={path}
        component={TenantSwitch}
        key={`${tenant}-${path}`}
      />
    ),
  );

  if (currentUserId) {
    return (
      <Switch>
        {tenantRoutes}
      </Switch>
    );
  }

  return (
    <Switch>
      {pathSets.public.map(
        path => <Route exact path={path} component={PublicSwitch} key={path} />,
      )}
      {tenantRoutes}
      <Route
        render={
          () => {
            if (recentlyLoggedOut) {
              return <Redirect to={{ pathname: paths.index, state: {} }} />;
            }

            return <NoMatch />;
          }
        }
      />
    </Switch>
  );
};

ApplicationSwitch.defaultProps = {
  tenant: null,
  currentUserId: null,
  recentlyLoggedOut: false,
};

ApplicationSwitch.propTypes = {
  tenant: PropTypes.string,
  currentUserId: PropTypes.string,
  recentlyLoggedOut: PropTypes.bool,
};

export default ApplicationSwitch;
