import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { CHILD_PROJECTS_FOR_MAP_FIELD } from 'helpers/selectors';

export const fetchChildProjectsForMap = (projectSuiteId, urlParams) => ({
  type: constants.FETCH_CHILD_PROJECTS_FOR_MAP_REQUEST,
  payload: {
    projectSuiteId,
    endpoint: endpoints.PROJECT_SUITE_PROJECTS(projectSuiteId),
    resourceType: CHILD_PROJECTS_FOR_MAP_FIELD,
    urlParams,
  },
});

export const fetchChildProjectsForMapSuccess = response => ({
  type: constants.FETCH_CHILD_PROJECTS_FOR_MAP_SUCCESS,
  payload: {
    response,
    resourceType: CHILD_PROJECTS_FOR_MAP_FIELD,
  },
});

export const fetchChildProjectsForMapFailure = error => ({
  type: constants.FETCH_CHILD_PROJECTS_FOR_MAP_FAILED,
  payload: { error },
});

export default fetchChildProjectsForMap;
