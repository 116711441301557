import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const archiveNotificationEvent = id => ({
  type: constants.ARCHIVE_NOTIFICATION_EVENT,
  payload: {
    endpoint: endpoints.NOTIFICATION_EVENT_ARCHIVE(id),
    id,
  },
});

export const archiveNotificationEventSuccess = response => ({
  type: constants.ARCHIVE_NOTIFICATION_EVENT_SUCCESS,
  payload: {
    response,
  },
});

export const archiveNotificationEventFailure = error => ({
  type: constants.ARCHIVE_NOTIFICATION_EVENT_FAILED,
  payload: { error },
});

export default archiveNotificationEvent;
