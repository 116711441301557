import has from 'lodash/has';

const getFinalCumulatedPermissionsMap = ({
  allPermissionsMap,
  cumulatedPermissionsMap,
  recipientIds,
}) => {
  const keys = Object.keys(allPermissionsMap);

  const finalCumulatedPermissionsMap = keys.reduce((acc, permissionName) => {
    acc[permissionName] = {};

    recipientIds.forEach(recipientId => {
      const path = `${permissionName}.${recipientId}`;

      if (!has(cumulatedPermissionsMap, path)) {
        acc[permissionName][recipientId] = false;
      } else {
        acc[permissionName][recipientId] = cumulatedPermissionsMap[permissionName][recipientId];
      }

      return acc;
    });

    return acc;
  }, {});

  return finalCumulatedPermissionsMap;
};

export default getFinalCumulatedPermissionsMap;
