import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';

const logsSectionActionCreators = {
  fetchLogs: (deviceId, urlParams = {}) => ({
    type: constants.FETCH_LOGS_REQUEST,
    payload: {
      endpoint: endpoints.LOGS(deviceId),
      urlParams,
    },
  }),

  fetchLogsSuccess: response => ({
    type: constants.FETCH_LOGS_SUCCESS,
    payload: { response },
  }),

  fetchLogsFailure: error => ({
    type: constants.FETCH_LOGS_FAILED,
    payload: {
      info: error.notFound && messages.NO_LOGS,
      error: !error.notFound && error,
    },
  }),

  selectLines: lines => ({
    type: constants.LOGS_SELECT_LINES,
    payload: { lines },
  }),
};

export default logsSectionActionCreators;
