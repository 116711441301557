import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  permissionsStateSelector,
} from 'helpers/selectors';
import { permissionsActionCreators } from '../../../../../utilities';
import AddProjectButton from './AddProjectSuiteButton';

const mapStateToProps = state => {
  const { selectingProjectSuites } = permissionsStateSelector(state);

  return {
    selectingProjectSuites,
  };
};

const mapDispatchToProps = {
  onToggleSelectingProjectSuites: permissionsActionCreators.toggleSelectingProjectSuites,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(AddProjectButton);
