import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DataTableRow from 'components/DataTable/DataTableRow/DataTableRow';
import DataTableText from 'components/DataTable/DataTableText/DataTableText';
import {
  DataTableBoolean,
  DataTableCheckbox,
  DataTableStartMethod,
  RunButton,
} from './components';
import {
  TOGGLE_TIMELINE,
} from '../../../../constants';

class JamboxTimelineRow extends Component {

  constructor() {
    super();

    this.state = {
      disabled: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.resetButtonTimeout = null;
  }

  componentWillUnmount() {
    if (this.resetButtonTimeout) {
      clearTimeout(this.resetButtonTimeout);
    }
  }

  handleClick() {
    const {
      onToggleTimeline,
      resourceId: deviceId,
      item: {
        id,
      },
    } = this.props;

    onToggleTimeline({
      deviceId,
      actionType: TOGGLE_TIMELINE,
      values: { timelineId: id },
    });

    this.setState({ disabled: true });
    this.resetButtonTimeout = setTimeout(() => this.setState({ disabled: false }), 300);
  }

  render() {
    const {
      isOnline,
      item: {
        active,
      },
    } = this.props;
    const { disabled } = this.state;

    return (
      <DataTableRow {...this.props}>
        <DataTableText />
        <DataTableStartMethod />
        <DataTableBoolean />
        <DataTableCheckbox />
        <RunButton
          primary
          floated="right"
          disabled={!isOnline || disabled}
          onClick={this.handleClick}
          release={active}
        >
          {active ? 'Release' : 'Run'}
        </RunButton>
      </DataTableRow>
    );
  }

}

JamboxTimelineRow.defaultProps = {
  ...DataTableRow.defaultProps,
};

JamboxTimelineRow.propTypes = {
  ...DataTableRow.propTypes,
  resourceId: PropTypes.string.isRequired,
  item: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  isOnline: PropTypes.bool.isRequired,
  onToggleTimeline: PropTypes.func.isRequired,
};

export default JamboxTimelineRow;
