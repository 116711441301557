import React from 'react';
import PropTypes from 'prop-types';

import { StyledUserTableRow } from './UserTableRow.style';

const UserTableRow = ({
  item,
  highlighted,
  resourceId,
  onAddUserId,
  onRemoveUserId,
  ...rest
}) => {
  const handleClickMethod = highlighted ? onRemoveUserId : onAddUserId;
  const handleClick = () => handleClickMethod(resourceId, item.id);

  return (
    <StyledUserTableRow
      {...rest}
      highlighted={highlighted}
      item={item}
      onClick={handleClick}
    />
  );
};

UserTableRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  highlighted: PropTypes.bool.isRequired,
  resourceId: PropTypes.string.isRequired,
  onAddUserId: PropTypes.func.isRequired,
  onRemoveUserId: PropTypes.func.isRequired,
};

export default UserTableRow;
