import React, { Fragment, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

import PatchModel from 'models/PatchModel';
import OutputModel from 'models/OutputModel';

import { GridChannelDisplay, OutputSectionForm } from './components';

export default function OutputSection({
  deviceId,
  port,
  protocolId,
  universeId,
  defaultProtocolId,
  defaultUniverseId,
  patches,
  outputs,
  isFetching,
  isRefreshing,
  isSubscribing,
  onRefresh,
  onSubscribe,
  onUnsubscribe,
  onStoreTableName,
  onProtocolChange,
  onUniverseChange,
}) {
  const [liveUpdates, setLiveUpdates] = useState(false);

  const handleSubscribe = useCallback((newProtocolId, newUniverseId) => {
    const payload = {
      protocolId: newProtocolId,
      universeId: newUniverseId,
    };

    onSubscribe(deviceId, 'dmx_output', payload);
  }, [onSubscribe, deviceId]);

  const handleRefresh = useCallback((newProtocolId, newUniverseId) => {
    const payload = {
      protocolId: newProtocolId,
      universeId: newUniverseId,
    };

    onRefresh(deviceId, 'dmx_output', payload);
  }, [onRefresh, deviceId]);

  const handleUnsubscribe = useCallback((newProtocolId, newUniverseId) => {
    const payload = {
      protocolId: newProtocolId,
      universeId: newUniverseId,
    };

    onUnsubscribe(deviceId, 'dmx_output', payload);
  }, [onUnsubscribe, deviceId]);

  const handleLiveUpdatesChange = useCallback(({ isEnabled }) => {
    setLiveUpdates(isEnabled);
  }, []);

  useEffect(() => {
    if (!isEmpty(patches)) {
      const parsedUniverseId = !isNil(port) ? Number(port) : defaultUniverseId;

      handleRefresh(defaultProtocolId, parsedUniverseId);
      onProtocolChange(defaultProtocolId);
      onUniverseChange(parsedUniverseId);
    }
  }, [defaultProtocolId, defaultUniverseId, patches, port, handleRefresh, onProtocolChange,
    onUniverseChange]);

  useEffect(() => () => {
    if (!isNil(protocolId) && !isNil(universeId)) {
      handleUnsubscribe(protocolId, universeId);
    }
  }, [handleUnsubscribe, protocolId, universeId]);

  const handleProtocolChange = useCallback(newProtocolId => {
    if (isNil(newProtocolId)) {
      return;
    }

    const numberProtocol = Number(newProtocolId);

    if (liveUpdates) {
      handleSubscribe(numberProtocol, defaultUniverseId);
    } else {
      handleRefresh(numberProtocol, defaultUniverseId);
    }

    onProtocolChange(numberProtocol);
    onUniverseChange(defaultUniverseId);
  }, [liveUpdates, handleSubscribe, handleRefresh, defaultUniverseId, onProtocolChange,
    onUniverseChange]);

  const handleUniverseChange = useCallback(newUniverseId => {
    if (isNil(newUniverseId)) {
      return;
    }

    const numberUniverse = Number(newUniverseId);

    if (liveUpdates) {
      handleSubscribe(protocolId, numberUniverse);
    } else {
      handleRefresh(protocolId, numberUniverse);
    }

    onUniverseChange(numberUniverse);
  }, [liveUpdates, protocolId, handleSubscribe, handleRefresh, onUniverseChange]);

  if (!isFetching && (!patches || isEmpty(patches))) {
    return (
      <p>
        The device hasn’t published any patch information.
        When the device has published its patches, the channel output levels may be viewed here.
      </p>
    );
  }

  return (
    <Fragment>
      <OutputSectionForm
        deviceId={deviceId}
        patches={patches}
        isLoading={isFetching || isSubscribing}
        selectedProtocol={protocolId}
        selectedUniverse={universeId}
        onProcotolChange={handleProtocolChange}
        onUniverseChange={handleUniverseChange}
        onLiveUpdatesChange={handleLiveUpdatesChange}
      />
      <GridChannelDisplay
        header="DMX Output"
        channels={outputs}
        isRefreshing={isRefreshing}
        onStoreTableName={onStoreTableName}
        highlightUpdates
      />
    </Fragment>
  );
}

OutputSection.defaultProps = {
  outputs: null,
  isFetching: false,
  isRefreshing: false,
  isSubscribing: false,
  patches: null,
  protocolId: null,
  universeId: null,
  defaultProtocolId: null,
  defaultUniverseId: null,
  port: null,
};

OutputSection.propTypes = {
  deviceId: PropTypes.string.isRequired,
  patches: PropTypes.arrayOf(PropTypes.instanceOf(PatchModel)),
  outputs: PropTypes.arrayOf(PropTypes.instanceOf(OutputModel)),
  isFetching: PropTypes.bool,
  isRefreshing: PropTypes.bool,
  isSubscribing: PropTypes.bool,
  protocolId: PropTypes.number,
  port: PropTypes.string,
  universeId: PropTypes.number,
  defaultProtocolId: PropTypes.number,
  defaultUniverseId: PropTypes.number,
  onRefresh: PropTypes.func.isRequired,
  onSubscribe: PropTypes.func.isRequired,
  onUnsubscribe: PropTypes.func.isRequired,
  onStoreTableName: PropTypes.func.isRequired,
  onProtocolChange: PropTypes.func.isRequired,
  onUniverseChange: PropTypes.func.isRequired,
};
