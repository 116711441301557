import styled from 'styled-components';

const MenuItemWrapper = styled('div')`
  display: inline-flex;
  width: 95%;
  align-items: center;
  justify-content: space-between;

  span {
    float: left;
    flex: 2 1 auto;
    margin-left: 10px;
  }
`;

export default MenuItemWrapper;
