import { connect } from 'react-redux';
import isObject from 'lodash/isObject';

import { taskSelector } from 'helpers/selectors';
import OperationsTable from './OperationsTable';

export const mapStateToProps = (state, ownProps) => {
  const { item: currentItem } = ownProps;
  const { item: { taskIds } } = ownProps;

  const operationsData = taskIds.map(item => {
    const { name, description } = taskSelector(state)(item) || {};
    return {
      id: item,
      name,
      description,
      action: 'Delete',
    };
  });

  const hideHeader = isObject(operationsData) && operationsData.length === 0;

  return {
    operationsData,
    currentItem,
    hideHeader,
  };
};

export default connect(mapStateToProps)(OperationsTable);
