import React from 'react';
import PropTypes from 'prop-types';

import { ButtonLink } from 'components/elements';

const DataTableActionButtonLink = ({ title, ...rest }) => (
  <ButtonLink {...rest}>{title}</ButtonLink>
);

DataTableActionButtonLink.propTypes = {
  title: PropTypes.string.isRequired,
};

export default DataTableActionButtonLink;
