import React from 'react';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';

import { StyledStrong, StyledSpan } from '../LogsTable.style';

const replaceWordsOutputHTML = (string, obj) => {

  if (isString(string) && !isEmpty(obj)) {
    return string.split(' ').map(item => {
      if (obj[item]) {
        return <StyledStrong key={`${obj[item]}-${item}`}>{obj[item]}</StyledStrong>;
      }

      return <StyledSpan key={`${obj[item]}-${item}`}>{item}</StyledSpan>;
    });
  }

  return string;
};

export default replaceWordsOutputHTML;
