import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';

class URLParameterConverter {

  constructor(params) {
    this.params = isObject(params) ? params : {};
  }

  get toString() {
    if (isEmpty(this.params)) {
      return '';
    }

    return this.convertParams();
  }

  convertParams(params) {
    const paramsToConvert = params || this.params;

    return Object.entries(paramsToConvert)
      .filter(([key, value]) => {
        if (value === '') return false;

        return key && !isNil(value);
      })
      .map(([key, value]) => this.convertKeyValuePair({ key, value }))
      .join('&');
  }

  convertKeyValuePair({ key, value }) {

    if (Array.isArray(value)) {

      if (key.search('\\[]') !== -1) {
        return URLParameterConverter.encodeKeyValuePairForBracketsFormat({ key, value });
      }

      return URLParameterConverter.encodeKeyValuePair({ key, value: value.join(',') });
    }

    if (isObject(value)) {
      return this.convertParams(
        URLParameterConverter.prependParentKeyToChildObjectKeys({ key, value }),
      );
    }

    return URLParameterConverter.encodeKeyValuePair({ key, value });
  }

  static encodeKeyValuePair({ key, value }) {
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  }

  static encodeKeyValuePairForBracketsFormat({ key, value }) {
    if (key.search('\\[]' !== -1) && Array.isArray(value)) {
      return value
        .map(id => `${encodeURIComponent(key)}=${encodeURIComponent(id)}`)
        .join('&');
    }

    return null;
  }

  static prependParentKeyToChildObjectKeys({ key, value }) {
    return Object.entries(value)
      .reduce((acc, [_key, _value]) => {
        const valueToString = Array.isArray(_value) ? _value.join(',') : _value;
        acc[`${key}[${_key}]`] = valueToString;

        return acc;
      }, {});
  }

}

export default URLParameterConverter;
