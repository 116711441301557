import styled from 'styled-components';
import { Form } from 'semantic-ui-react';
import ReloadButton from 'components/ReloadButton/ReloadButton';

export const RefreshForm = styled(Form)`
  &.ui.form {
    .fields.inline {
      margin-bottom: 0;
    }
    
    .fields.inline .field:last-of-type {
      padding-right: 0;
    }
  }
`;


export const RefreshButton = styled(ReloadButton)`
  &.ui.button {
    padding: 0;
  }

  &.ui.button.disabled {
    pointer-events: all!important;
  }

`;
