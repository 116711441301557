import styled, { css } from 'styled-components';
import { Header } from 'semantic-ui-react';
import { rgba } from 'polished';

import { Button } from 'components/atomic';
import { mediaMax } from 'themes/mixins';
import filterProps from 'helpers/filterProps';

export const StyledControlUIPage = styled.div`
  position: relative;
  float: left;
  min-height: 60vh;
  padding: 1.5em 1.5em;
  border-radius: .28571429rem;

  ${({ active }) => active && css`
    border: 1px solid rgba(34,36,38,.15);
  `}

  ${({ configure }) => !configure && css`
    padding-top: 70px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 70px;
    height: 100%;
    border: none;
    border-radius: 0;
  `}

  ${({ configure }) => configure && css`
    ${mediaMax.md`
      margin-top: 16px;
    `};
  `}
`;

export const StyledControlUIPageButtonOuterWrapper = styled.div`
  ${({ configure }) => configure && css`
    width: 98%;
  `}
  height: calc(100% - 37px); /* account for title */
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE 10+ */

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledControlUIPageButtonWrapper = styled.div`
  margin-top: 24px;
  display: grid;
  grid-gap: 2em;

  /* TODO: Get Columns and Rows from user input */
  grid-template-columns: ${'repeat(4, minmax(0, 1fr))'};
  grid-template-rows: ${Array.from({ length: 3 }).join(' auto')};
  
  ${mediaMax.md`
    grid-gap: 1em !important;
    grid-template-columns: ${'repeat(2, minmax(0, 1fr))'} !important;
  `};

  ${({ pageType }) => ['buttons_override_controls', 'override_controls_buttons'].includes(pageType) && css`
    flex-basis: 50%;
    grid-template-columns: ${'repeat(2, minmax(0, 1fr))'} !important;

    ${mediaMax.sm`
      flex-basis: 100%;
      margin-bottom: 32px;
    `};
  `}
`;

export const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${mediaMax.sm`
    flex-wrap: wrap;
  `};
`;

export const StyledControlsContainer = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2em;

  ${({ pageType }) => pageType === 'override_controls' && css`
    flex-direction: row;
    flex-basis: 100%;
    height: 100%;
    padding-bottom: 32px;

    ${mediaMax.sm`
      flex-direction: column;
    `};
  `}

  ${mediaMax.sm`
    flex-basis: 100%;
  `};
  
`;

export const StyledControlUIBackgroundImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: -1;
  display: none;
  border-radius: .28571429rem;

  ${({ isLoaded }) => isLoaded && css`
    display: block;
  `}
`;

const FilteredPageTitleProps = filterProps(['pageTitleColor'])(Header);

export const StyledControlUIPageTitle = styled(FilteredPageTitleProps)`
  color: ${({ pageTitleColor: { r, g, b, a } }) => rgba(r, g, b, a)} !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  &&& {
    i {  
      margin: 0 !important;
    }
  }
`;
