import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { singleProjectSelector } from 'helpers/selectors';
import { deepEqualMemoizeOne } from 'helpers/sharedMethods';
import Map from '../../../Map/Map';
import SiteMapMarker from './SiteMapMarker/SiteMapMarkerContainer';
import { siteMapCenteringBehaviour, siteMapEmptyClickBehaviour } from './containers';

export const makeMapState = () => {
  const memoMarkers = deepEqualMemoizeOne();

  return function mapStateToProps(state, { match }) {
    const { id: siteId } = match.params;
    const { locationLoaded, latitude, longitude } = singleProjectSelector(state, siteId) || {};
    const markers = [
      {
        id: `marker-${siteId}`,
        siteId,
        resourceId: siteId,
        latitude,
        longitude,
      },
    ];

    return {
      siteId,
      locationReady: locationLoaded,
      resourceId: siteId,
      target: `map-${siteId}`,
      markers: memoMarkers(markers),
      markerComponent: SiteMapMarker,
    };
  };
};

export default compose(
  withRouter,
  connect(makeMapState),
  siteMapCenteringBehaviour,
  siteMapEmptyClickBehaviour,
)(Map);
