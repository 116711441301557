import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import UserModel from 'models/UserModel';
import { currentUserSelector } from 'helpers/selectors';
import { actionCreators } from 'application/utilities/user';
import UserMenu from './UserMenu';

const mapStateToProps = state => {
  const currentUser = currentUserSelector(state);
  const { displayName } = new UserModel(currentUser || {});

  return {
    displayName,
  };
};

const mapDispatchToProps = {
  logout: actionCreators.logout,
};

export default compose(
  withTheme,
  connect(mapStateToProps, mapDispatchToProps),
)(UserMenu);
