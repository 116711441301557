import styled from 'styled-components';
import SelectField from 'components/form/SelectField/SelectField';

import { mediaMax } from 'themes/mixins';

const StyledUniverseDropdown = styled(SelectField)`
  margin-right: auto;

  ${mediaMax.md`
    margin-bottom: 1em;
    width: 100%;
  `};
`;

export default StyledUniverseDropdown;
