import styled from 'styled-components';

import { Button } from 'components/atomic';

export const StyledAddProjectButton = styled(Button)`
  &.ui.button {
    display: block;
  }
`;

export default StyledAddProjectButton;
