import { reduxForm } from 'redux-form';

import formNames from 'components/form/formNames';
import { validateOnSubmit } from 'components/form/formConfigs';
import PathwayDisableClockEventsV1Form from './PathwayDisableClockEventsV1Form';

export default reduxForm({
  ...validateOnSubmit,
  form: formNames.watchdogV1,
  enableReinitialize: true,
})(PathwayDisableClockEventsV1Form);
