import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'semantic-ui-react';

import tasksActionCreators from 'application/tenant/utilities/tasks/tasksActionCreators';
import DropdownArrow from 'components/elements/DropdownArrow/DropdownArrow';
import {
  StyledTaskRow,
  StyledTaskActionLinkCell,
  StyledTaskContentCell,
  StyledTaskName,
} from '../../../../ProjectTasksTab.style';
import {
  TaskInformation,
  ActionsTable,
  InlineEditTaskProperty,
} from './components';

const TaskAccordionRow = ({
  item,
  onChangeActive,
  labels,
  actionLinks,
  active,
}) => {
  const [open, setOpen] = useState(false);
  const handleChange = e => {
    setOpen(!open);
    e.stopPropagation();
  };
  const selectItem = e => {
    e.stopPropagation();
    onChangeActive(item);
  };
  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      handleChange(e);
    }
  };
  const {
    id,
  } = item;
  const radio = (
    <span
      role="button"
      tabIndex={0}
      onClick={e => selectItem(e)}
      onKeyPress={handleKeyPress}
    >
      <Radio checked={active} />
    </span>
  );

  return (
    <Fragment>
      <StyledTaskRow
        onClick={handleChange}
      >
        <StyledTaskActionLinkCell>
          {actionLinks && radio}
          <DropdownArrow open={open} />
        </StyledTaskActionLinkCell>
        {labels.map(label => (
          <StyledTaskContentCell key={`${id}-${label.field}`}>
            <StyledTaskName>
              <InlineEditTaskProperty
                id={item.id}
                property={label.field}
                propertyValue={item[label.field]}
                onChange={tasksActionCreators.updateTaskProperty}
              />
            </StyledTaskName>
            <TaskInformation
              item={item}
              label={label}
            />
          </StyledTaskContentCell>
        ))}
      </StyledTaskRow>
      {open && <ActionsTable item={item} />}
    </Fragment>
  );
};

TaskAccordionRow.defaultProps = {
  actionLinks: null,
};

TaskAccordionRow.propTypes = {
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  actionLinks: PropTypes.func,
  active: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    actions: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    projectSuiteId: PropTypes.string,
  }).isRequired,
  onChangeActive: PropTypes.func.isRequired,
};

export default TaskAccordionRow;
