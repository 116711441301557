import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { OUTPUT_FIELD } from 'helpers/selectors';

export const fetchOutput = (deviceId, params) => ({
  type: constants.FETCH_OUTPUT,
  payload: {
    endpoint: endpoints.DMX_OUTPUT(deviceId),
    params,
  },
});

export const fetchOutputSuccess = response => ({
  type: constants.FETCH_OUTPUT_SUCCESS,
  payload: { response },
});

export const fetchOutputFailure = error => ({
  type: constants.FETCH_OUTPUT_FAILED,
  payload: {
    error,
    resourceType: OUTPUT_FIELD,
  },
});
