import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

const pollingActionCreators = {
  initRefreshing: ({
    loader,
    refreshRate,
    initRefresh,
    timeout,
    parameters,
    resourceId,
    refreshLoader,
  }) => ({
    type: constants.POLLING_INIT,
    payload: {
      loader,
      refreshRate,
      initRefresh,
      timeout,
      parameters,
      resourceId,
      refreshLoader,
    },
  }),
  setRefreshRate: (loader, { value, uiValue }) => ({
    type: constants.POLLING_SET_REFRESH_RATE,
    payload: {
      loader,
      status: { value, uiValue },
    },
  }),
  startRefreshing: loader => ({
    type: constants.POLLING_REFRESHING_START,
    payload: { loader },
  }),
  stopRefreshing: loader => ({
    type: constants.POLLING_REFRESHING_STOP,
    payload: { loader },
  }),
  resourceUpdate: (loader, refreshLoader, {
    timeout,
    parameters,
    resourceId,
  }) => ({
    type: constants.POLLING_RESOURCE_UPDATE_REQUEST,
    payload: {
      endpoint: endpoints.RESOURCE_UPDATE(resourceId),
      loader,
      refreshLoader,
      timeout,
      parameters,
    },
  }),
  resourceUpdateSuccess: response => ({
    type: constants.POLLING_RESOURCE_UPDATE_SUCCESS,
    payload: { response },
  }),
  resourceUpdateFailure: error => ({
    type: constants.POLLING_RESOURCE_UPDATE_FAILED,
    payload: { error },
  }),
  fieldUpdate: (loader, refreshLoader, {
    timeout,
    currentValue,
  }) => ({
    type: constants.POLLING_FIELD_UPDATE,
    payload: {
      loader,
      refreshLoader,
      timeout,
      currentValue,
    },
  }),
  timeout: loader => ({
    type: constants.POLLING_TIMEOUT,
    payload: { loader },
  }),
};

export default pollingActionCreators;
