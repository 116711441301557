import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import isNil from 'lodash/isNil';

import { isValidTimeText } from 'components/form/formValidators';
import TextField from 'components/form/TextField/TextField';
import { TipIcon, FieldWrapper } from 'components/atomic';
import { StyledLabel } from './TimeTextActionField.style';
import { normalizeTime, formatTime } from './utilities';

const TimeTextActionField = ({
  submitting,
  name,
  label,
  tip,
  editMode,
  lteq,
  gteq,
}) => {
  const [checkDecimal, setCheckDecimal] = useState(2);
  const normalize = normalizeTime(setCheckDecimal);
  const format = formatTime(checkDecimal, setCheckDecimal);
  const labelText = editMode ? '' : label;

  const validator = useCallback(fieldValue => {
    if (!isNil(gteq) && !isNil(lteq)) {
      const funcRange = isValidTimeText(gteq, lteq);

      return funcRange(fieldValue);
    }

    return undefined;
  }, [gteq, lteq]);

  const renderTipIcon = () => {
    if (!tip) {
      return null;
    }

    return <TipIcon message={tip} editMode={editMode} position="left center" />;
  };

  return (
    <FieldWrapper>
      <StyledLabel htmlFor="input">{labelText} {renderTipIcon()}</StyledLabel>
      <Field
        name={name}
        component={TextField}
        type="text"
        loading={submitting}
        validate={validator}
        editMode={editMode}
        format={format}
        normalize={normalize}
      />
    </FieldWrapper>
  );
};

TimeTextActionField.defaultProps = {
  submitting: false,
  tip: null,
  editMode: false,
  gteq: null,
  lteq: null,
};

TimeTextActionField.propTypes = {
  submitting: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tip: PropTypes.string,
  editMode: PropTypes.bool,
  gteq: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  lteq: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default TimeTextActionField;
