import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { PROJECTS_FIELD, projectSuiteSelector } from 'helpers/selectors';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { withProjectSuitePermissions, permissionsOrNotFound, frontendPermissions } from 'gateway';

import {
  actionCreators as projectSuiteActionCreators,
  selectors as projectSuitesSelectors,
} from 'pages/ProjectSuites/utilities';

import controlPanelActionCreators from './controlPanelActionCreators';
import ControlPanel from './ControlPanel';

export const mapStateToProps = (state, { match }) => {
  const { id: projectSuiteId } = match.params;
  const { name: projectSuiteName } = projectSuiteSelector(state)(projectSuiteId) || {};
  const isMultiSite = true;

  const projects = projectSuitesSelectors.projectsListSelector(state)(projectSuiteId);
  const projectsIds = projects ? projects.map(({ id }) => id) : null;

  return {
    projectSuiteId,
    projectSuiteName,
    projectsIds,
    isMultiSite,
  };
};

const mapDispatchToProps = {
  onEnterControlPanelContext: controlPanelActionCreators.enterControlPanelContext,
  onExitControlPanelContext: controlPanelActionCreators.exitControlPanelContext,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withProjectSuitePermissions,
  permissionsOrNotFound(frontendPermissions.PROJECT_SUITE_CONTROL_PANEL_VIEW),
  chainedLoaderWithSaga(
    ({ projectSuiteId }) => projectSuiteActionCreators.fetchProjectSuiteProjectsFilter(
      projectSuiteId, {
        fields: {
          [PROJECTS_FIELD]: [
            'name',
          ],
        },
        sort: 'name',
      }),
  ),
)(ControlPanel);
