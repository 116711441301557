import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import automatedOperationsActionCreators from '../../utilities/actionCreators';
import EditAutomatedOperationColour from './EditAutomatedOperationColour';

const mapDispatchToProps = {
  onEditAutomatedOperation: automatedOperationsActionCreators.updateAutomatedOperation,
};

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(EditAutomatedOperationColour);
