import isNull from 'lodash/isNull';

const addTableColumns = (array, columns) => {
  if (!isNull(array) && !isNull(columns) && Array.isArray(array)) {
    return array.map(item => ({ ...item, ...columns }));
  }

  return [];
};

export default addTableColumns;
