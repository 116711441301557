import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getDisplayName } from 'helpers/sharedMethods';

const withReplaceDevice = WrappedComponent => class extends Component {

  static displayName = `WithReplaceDevice(${getDisplayName(WrappedComponent)})`;

  static propTypes = {
    fetching: PropTypes.bool.isRequired,
    item: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    projectId: PropTypes.string.isRequired,
    onReplaceDeviceToken: PropTypes.func.isRequired,
  };

  constructor() {
    super();

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const {
      item: { id: logicalDeviceId, name: logicalDeviceName },
      projectId,
      onReplaceDeviceToken,
    } = this.props;

    onReplaceDeviceToken({
      values: { logicalDeviceId, logicalDeviceName, projectId, replacement: true },
    });
  }

  render() {
    const { fetching } = this.props;

    return (
      <WrappedComponent
        onClick={this.handleClick}
        disabled={fetching}
        title="Replace"
      />
    );
  }

};

export default withReplaceDevice;
