import styled, { css } from 'styled-components';

import { Button } from 'components/atomic';

export const StyledColorPickerTrigger = styled(Button)`
  &.ui.button {
    margin-bottom: 10px;
  }
`;

export const StyledColorPreview = styled.div`
  width: 25px;
  height: 25px;
  padding: 0px;
  border-radius: 4px;
  margin-right: 7px;
  margin-bottom: 7px;
  transition: opacity 0.2s ease;
  background-color: red;
  cursor: pointer;

  ${({ color }) => color && css`
    background-color: ${color};
  `}
`;

export const StyledColorPreviewWrapper = styled.div`
  width: 31px;
  height: 31px;
  border: solid 1px #dedede;
  border-radius: 4px;
  padding: 2px;

  &:hover {
    border: dashed 1px #000000;
    border-radius: 0px;
  }
`;

export default StyledColorPickerTrigger;
