import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { currentUserSelector, tenantSelector } from 'helpers/selectors';
import NoMatch from './NoMatch';

const mapStateToProps = state => ({
  currentUser: currentUserSelector(state),
  tenant: tenantSelector(state),
});

export default withRouter(
  connect(mapStateToProps)(NoMatch),
);
