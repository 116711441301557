import { call } from 'redux-saga/effects';

import { postPath } from 'data/api/requests';
import { processFinalFormResponse } from 'utilities/form';

import actionCreators from '../actionCreators';

function* renewSuspendedSite(action) {
  const {
    endpoint,
    params: {
      values,
      resolveForm,
    },
  } = action.payload;
  const body = {
    data: { ...values },
  };

  const { response, error } = yield call(postPath, endpoint, body);

  const params = {
    response,
    error,
    resolveForm,
    successDisp: actionCreators.renewSuspendedSiteSuccess,
    failDisp: actionCreators.renewSuspendedSiteFailure,
  };

  yield call(processFinalFormResponse, params);
}

export default renewSuspendedSite;
