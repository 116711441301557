import styled from 'styled-components';
import { Grid, Dropdown } from 'semantic-ui-react';

import { mediaMax } from 'themes/mixins';

export const StyledLabel = styled.label`
  font-weight: bold;
  margin: auto 0px;
  font-size: 14px;
`;

export const StyledRow = styled(Grid.Row)`
  padding-top: 8px;
`;

export const DropdownContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  label {
    font-weight: bold;
    margin-right: 8px;
    font-size: 14px;

    ${mediaMax.sm`
      display: none;
    `};
  }
`;

export const StyledOccurrenceTypeDropdown = styled(Dropdown)`
  height: 36px;
  font-size: 14px;
  width: 100px !important;
  margin-right: 8px;
`;

export const StyledOccurrenceDayDropdown = styled(Dropdown)`
  height: 36px;
  font-size: 14px;
  width: 145px !important;
  margin-right: 8px;
`;


export default DropdownContainer;
