import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { supportedTypesType, uploadFileType } from 'data/types';
import ProjectFileUploadModalForm from './ProjectFileUploadModalForm/ProjectFileUploadModalFormContainer';

class ProjectFileUpload extends Component {

  constructor() {
    super();

    this.handleNewUpload = this.handleNewUpload.bind(this);
  }

  handleNewUpload({ systemFile }) {
    const {
      projectId,
      resume,
      fileIdentifier,
      fileTypeId,
      onPrepareUpload,
      onFileNotFoundResume,
      resourceType,
    } = this.props;

    return new Promise((resolveForm, rejectForm) => {
      const { accessKeyId, fileKey } = resume || {};
      const params = {
        systemFile: systemFile[0],
        fileIdentifier,
        fileTypeId,
        resolveForm,
        rejectForm,
      };

      if (accessKeyId) {
        onFileNotFoundResume({ fileKey, params });
      } else {
        onPrepareUpload({ projectId, resourceType, params });
      }
    });
  }

  render() {
    const { accept, resume, trigger, form } = this.props;

    return (
      <ProjectFileUploadModalForm
        onSubmit={this.handleNewUpload}
        trigger={trigger}
        accept={accept}
        resume={resume}
        form={form}
      />
    );
  }

}

ProjectFileUpload.defaultProps = {
  resume: null,
  resourceType: 'project',
};

ProjectFileUpload.propTypes = {
  accept: PropTypes.oneOfType([
    supportedTypesType,
    PropTypes.string,
  ]).isRequired,
  projectId: PropTypes.string.isRequired,
  resume: uploadFileType,
  fileIdentifier: PropTypes.string.isRequired,
  fileTypeId: PropTypes.string.isRequired,
  trigger: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  form: PropTypes.string.isRequired,
  onFileNotFoundResume: PropTypes.func.isRequired,
  onPrepareUpload: PropTypes.func.isRequired,
  resourceType: PropTypes.oneOf(['project', 'projectSuite']),
};

export default ProjectFileUpload;
