import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const readNotificationEvent = id => ({
  type: constants.READ_NOTIFICATION_EVENT,
  payload: {
    endpoint: endpoints.NOTIFICATION_EVENT_READ(id),
    id,
  },
});

export const readNotificationEventSuccess = response => ({
  type: constants.READ_NOTIFICATION_EVENT_SUCCESS,
  payload: {
    response,
  },
});

export const readNotificationEventFailure = error => ({
  type: constants.READ_NOTIFICATION_EVENT_FAILED,
  payload: { error },
});

export default readNotificationEvent;
