import { call, select, put, take } from 'redux-saga/effects';

import { postPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';

import dispatcherConst from 'dispatcherConst';
import actionCreators from '../actionCreators';
import selectors from '../selectors';

const getGrantedPermissionNames = (cumulatedPermissions, recipientId) => {
  if (!cumulatedPermissions) {
    return [];
  }

  const names = Object.keys(cumulatedPermissions).reduce((acc, curr) => {
    if (cumulatedPermissions[curr][recipientId]) {
      acc.push(curr);
    }

    return acc;
  }, []);

  return names;
};

function* pasteMultiplePermissions(action) {
  const {
    endpoint,
    context,
    resourceId,
    parentResourceId,
    resourceType,
    copyRecipientId,
    pasteRecipientId,
    recipientType,
    limitedScope,
    limitedFor,
  } = action.payload;

  const selectedRecipients = yield select(
    selectors.selectedRecipientsSelector,
    parentResourceId || resourceId,
  );
  const cumulatedPermissions = yield select(selectors.cumulatedPermissionsSelector, resourceId);

  const grantedPermissions = getGrantedPermissionNames(cumulatedPermissions, copyRecipientId);

  const body = {
    permissions: grantedPermissions,
    recipientId: pasteRecipientId,
    resourceId,
    resourceType,
    recipientType,
  };

  if (context === 'tenant') {
    body.context = context;

    delete body.resourceId;
  }

  const { response, error } = yield call(postPath, endpoint, body);

  if (response) {
    yield put(actionCreators.fetchCumulatedPermissions({
      recipientIds: selectedRecipients,
      recipientType,
      resourceType,
      resourceId,
      limitedScope,
      limitedFor,
      context,
    }));

    yield take(dispatcherConst.FETCH_CUMULATED_PERMISSIONS_SUCCESS);
  }

  const params = {
    response,
    error,
    successDisp: actionCreators.pasteMultiplePermissionsSuccess,
    failDisp: actionCreators.pasteMultiplePermissionsFailure,
  };

  yield call(processCall, params);
}

export default pasteMultiplePermissions;
