import React from 'react';
import PropTypes from 'prop-types';

import { setTabVisibilityWithMenuItem } from 'helpers/sharedMethods';
import { COMPANIES } from 'helpers/sharedVariables';
import { attachId } from 'helpers/layoutSwitchHelpers';
import TabbedLayout from 'components/TabbedLayout/TabbedLayout';
import permissionsTabs from './permissionsTabs';

const tabsWithIds = attachId(permissionsTabs);

const Permissions = ({ isSuperAdmin }) => {
  const panesList = setTabVisibilityWithMenuItem(
    tabsWithIds, COMPANIES, isSuperAdmin,
  );

  return <TabbedLayout panes={panesList} />;
};

Permissions.defaultProps = {
  isSuperAdmin: false,
};

Permissions.propTypes = {
  isSuperAdmin: PropTypes.bool,
};

export default Permissions;
