class OutputModel {

  constructor(output = {}) {
    this.id = output.id;
    this.level = output.level;
    this.status = output.status;
    this.protocolId = output.protocolId;
    this.universeId = output.universeId;
  }

  get name() {
    const { id } = this;

    return `${id}`;
  }

  get value() {
    const { level } = this;

    return level;
  }

}

export default OutputModel;
