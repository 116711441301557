import { compose } from 'redux';
import { connect } from 'react-redux';

import chainedLoaderActionCreators from './chainedLoaderActionCreators';
import chainedLoaderWithSaga from './chainedLoaderWithSaga';

const mapDispatchToProps = {
  ...chainedLoaderActionCreators,
};

export default (...methods) => compose(
  connect(null, mapDispatchToProps),
  chainedLoaderWithSaga(...methods),
);
