import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';

import PatchModel from 'models/PatchModel';

import StyledUniverseDropdown from './UniverseDropdown.style';

export default function UniverseDropdown({
  isLoading,
  patches,
  selectedProtocol,
  selectedUniverse,
  onUniverseChange,
}) {
  const { universes } = find(patches, ({ id }) => id === selectedProtocol) || {};

  const handleChange = protocol => onUniverseChange(protocol);

  return (
    <StyledUniverseDropdown
      name="universe"
      placeholder="Select protocol"
      options={universes ? universes.map(u => u.selectorOption) : []}
      loading={isLoading}
      onChangeCallback={handleChange}
      value={String(selectedUniverse)}
    />
  );
}

UniverseDropdown.defaultProps = {
  patches: null,
  selectedProtocol: null,
  selectedUniverse: null,
};

UniverseDropdown.propTypes = {
  patches: PropTypes.arrayOf(PropTypes.instanceOf(PatchModel)),
  isLoading: PropTypes.bool.isRequired,
  selectedProtocol: PropTypes.number,
  selectedUniverse: PropTypes.number,
  onUniverseChange: PropTypes.func.isRequired,
};
