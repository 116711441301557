import constants from 'dispatcherConst';

const initialState = {
  removing: false,
  fetching: false,
  error: false,
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.REMOVE_USER_FROM_PROJECT:
      return { ...state, removing: true };

    case constants.REMOVE_USER_FROM_PROJECT_SUCCESS:
      return { ...state, removing: false, error: false };

    case constants.REMOVE_USER_FROM_PROJECT_FAILED:
      return { ...state, removing: false, error: true };

    case constants.FETCH_PROJECT_REQUEST:
    case constants.FETCH_PROJECTS_REQUEST:
    case constants.UPDATE_SITE_REQUEST:
      return { ...state, fetching: true, error: false };

    case constants.FETCH_PROJECT_SUCCESS:
    case constants.FETCH_PROJECTS_SUCCESS:
    case constants.UPDATE_SITE_SUCCESS:
      return { ...state, fetching: false, error: false };

    case constants.FETCH_PROJECT_FAILED:
    case constants.FETCH_PROJECTS_FAILED:
    case constants.UPDATE_SITE_FAILED:
      return { ...state, fetching: false, error: true };

    default:
      return state;
  }
};

export default projectReducer;
