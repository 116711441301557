import React, { cloneElement, useCallback } from 'react';
import PropTypes from 'prop-types';

import ModalForm from 'components/form/ModalForm/ModalForm';
import Form from 'components/form/Form/Form';
import TextField from 'components/form/TextField/TextField';
import { validatePaginationIndex } from 'components/form/formValidators';
import { PageSelectionButtons } from './components';
import { StyledField } from './DuplicatePageConfirm.style';

const DuplicatePageConfirmation = ({
  trigger,
  totalPages,
  pageIndex,
  changePaginationIndex,
  ...rest
}) => {
  const paginationValidation = useCallback(validatePaginationIndex(totalPages), [totalPages]);

  const handleInputChange = value => {
    if (value === '') {
      changePaginationIndex('');
    } else {
      changePaginationIndex(parseInt(value, 10));
    }
  };

  const updatePageIndex = value => {
    changePaginationIndex(parseInt(value, 10));
  };

  return (
    <ModalForm
      trigger={cloneElement(trigger)}
      header="Duplicate page"
      size="tiny"
      {...rest}
    >
      <strong>Insert a duplicate of this page at page number:</strong>
      <Form.Group>
        <StyledField
          name="paginationIndex"
          component={TextField}
          type="number"
          validate={paginationValidation}
          normalize={handleInputChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Field>
          <PageSelectionButtons
            handleChange={updatePageIndex}
            totalPages={totalPages}
            pageIndex={pageIndex}
          />
        </Form.Field>
      </Form.Group>
    </ModalForm>
  );
};

DuplicatePageConfirmation.defaultProps = {
  totalPages: 0,
  pageIndex: 0,
};

DuplicatePageConfirmation.propTypes = {
  pageIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  totalPages: PropTypes.number,
  changePaginationIndex: PropTypes.func.isRequired,
  trigger: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
};

export default DuplicatePageConfirmation;
