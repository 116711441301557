/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { StyledValueWrapper } from './InlineEditParameter.style';
import RenderInlineField from './RenderInlineFieldContainer';
import { RenderEditableValue } from './components';
import { getFormValue } from './utilities';

class InlineEditParameter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
    this.showField = this.showField.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  UNSAFE_componentWillReceiveProps({ value: nextValue }) {
    const { value } = this.props;

    if (value !== nextValue) {
      this.setState({ editing: false });
      document.activeElement.blur();
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { editing } = this.state;
    const { editing: nextEditing } = nextState;

    if (!editing && nextEditing) {
      setTimeout(() => {
        document.addEventListener('click', this.handleClickOutside, false);
      }, 0);
    } else if (editing && !nextEditing) {
      setTimeout(() => {
        document.removeEventListener('click', this.handleClickOutside, false);
      }, 0);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, false);
  }

  handleClickOutside({ target }) {
    const { handleCancel } = this;
    const { activeElement } = document;
    if (this.node && !this.node.contains(target) && !this.node.contains(activeElement)) {
      handleCancel();
    }
  }

  async handleSubmit(values) {
    const { onEditAction, value } = this.props;
    const formValue = getFormValue(values);

    await new Promise((resolveForm, rejectForm) => {
      onEditAction({
        values,
        resolveForm,
        rejectForm,
      });
    });

    if (value === formValue) {
      this.handleCancel();
    }
  }

  handleCancel() {
    this.setState({ editing: false });
  }

  showField() {
    const { editing } = this.state;

    this.setState({
      editing: !editing,
    });
  }

  render() {
    const {
      value,
      param,
      logicalDeviceId,
      actionId,
      taskId,
      paramId,
      tableDropdownData,
      projectId,
    } = this.props;
    const { editing } = this.state;

    return (
      <div ref={node => { this.node = node; }}>
        {editing
          ? (
            <RenderInlineField
              onSubmit={this.handleSubmit}
              value={value}
              param={param}
              logicalDeviceId={logicalDeviceId}
              actionId={actionId}
              paramId={paramId}
              taskId={taskId}
              form={`editAction-${actionId}-${param.key}`}
              projectId={projectId}
            />
          )
          : (
            <StyledValueWrapper onClick={this.showField} role="button">
              <RenderEditableValue
                tableDropdownData={tableDropdownData}
                value={value}
                uiElement={param.uiElement}
                projectId={projectId}
              />
            </StyledValueWrapper>
          )}
      </div>
    );
  }

}

InlineEditParameter.defaultProps = {
  value: null,
  projectId: '',
  logicalDeviceId: null,
};

InlineEditParameter.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  param: PropTypes.object.isRequired,
  logicalDeviceId: PropTypes.string,
  actionId: PropTypes.number.isRequired,
  taskId: PropTypes.string.isRequired,
  paramId: PropTypes.number.isRequired,
  tableDropdownData: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
  ]).isRequired,
  onEditAction: PropTypes.func.isRequired,
  projectId: PropTypes.string,
};

export default InlineEditParameter;
