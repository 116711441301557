import styled from 'styled-components';
import { Label } from 'semantic-ui-react';

import { mediaMax } from 'themes/mixins';

import filterProps from 'helpers/filterProps';

const FilteredProps = filterProps([
  'isSelected',
  'isPreviewing',
])(Label);

export const Container = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  min-height: 30vh;
  max-height: 50vh;
  overflow: auto;

  ${mediaMax.sm`
    max-height: calc(100vh - 415px);
  `};
`;

export const StyledLabel = styled(FilteredProps)``;

export const TaskItem = styled.li`
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  width: 96%;

  & + li {
    margin-top: 8px;
  }

  &:last-child {
    margin-bottom: 35px;
  }

  div {
    display: flex;
    align-items: center;
  }

  p { 
    margin: 0;
    margin-left: 8px;
    font-weight: 500;
    font-size: 15px;
  }
`;
