import styled, { css } from 'styled-components';
import { Grid, Header, Tab } from 'semantic-ui-react';
import get from 'lodash/get';

export const StyledGrid = styled(Grid)`
  border-radius: 5px;
`;

export const StyledHeader = styled(Header)`
  font-size: 17px !important;
`;

export const StyledColumn = styled(Grid.Column)`
  padding: 1em !important;
`;

export const StyledSubheader = styled(Header.Subheader)`
  margin-top: 0.2em !important;
`;

export const StyledSeparator = styled.hr`
  margin-left: -1em !important;
  margin-right: -1em !important;
  height: 1px;
  background-color: rgba(0,0,0,0.2) !important;
  border: none;
`;

export const EmptyMessage = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5em;
`;

export const StyledHeaderRow = styled(Grid.Row)`
  box-shadow: none !important;
`;

export const StyledHeaderColumn = styled(Grid.Column)`
  box-shadow: none !important;
`;

export const StoryContainerSample = styled.div`
  margin: 25px;
  max-width: 900px;
`;

export const StoryContentSample = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
`;

export const StyledTab = styled(Tab)`
  ${({ theme }) => css`
    &&& {
      .ui.menu {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        margin-top: auto;
        height: 30px;
        display: flex;
        border: none;
        box-shadow: 0px -3px 5px 0px rgba(0,0,0,0.13);
        width: 100%;        

        a {
          flex: 1;
          font-weight: bold;
          color: rgba(51, 51, 51, 0.20);
          justify-content: center;
          background: #FFF;
          border: none;

          &:first-child {
            border-bottom-left-radius: 8px;
          }

          &:before {
            background: none;
          }

          &.active {
            color: ${get(theme, 'palette.primaryColor')};
            border-top: 2px solid ${get(theme, 'palette.primaryColor')};
          }
        }
      }
    } 
  `}; 

`;

export default StyledGrid;
