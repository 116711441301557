import React from 'react';
import PropTypes from 'prop-types';

import { permissionsMatchRequirements } from '../utilities';

const PermissionsSwitch = ({
  children,
  permissionsResourceType,
  permissionsLoading,
  resourceId,
  superadmin,
  contexts,
  userPermissions,
}) => {
  if (permissionsLoading) {
    return null;
  }

  let element;
  let match;

  React.Children.forEach(children, child => {
    if (!match && React.isValidElement(child)) {
      const { condition, fallback } = child.props;
      const requirementsMatch = permissionsMatchRequirements({
        condition,
        permissionsResourceType,
        resourceId,
        superadmin,
        contexts,
        userPermissions,
      });

      match = requirementsMatch || fallback;
      element = child;
    }
  });

  return match ? React.cloneElement(element) : null;
};

PermissionsSwitch.defaultProps = {
  permissionsResourceType: null,
  permissionsLoading: false,
  resourceId: null,
  superadmin: false,
  contexts: null,
  userPermissions: null,
};

PermissionsSwitch.propTypes = {
  permissionsResourceType: PropTypes.string,
  permissionsLoading: PropTypes.bool,
  resourceId: PropTypes.string,
  superadmin: PropTypes.bool,
  contexts: PropTypes.array,
  userPermissions: PropTypes.array,
};

export default PermissionsSwitch;
