import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { fromLonLat } from 'ol/proj';
import Overlay from 'ol/Overlay';
import truncate from 'lodash/truncate';

import MapContext from '../../../MapContext/MapContext';
import {
  StyledMarker,
  StyledWarningMarker,
  StyledExclamationTriangle,
  StyledPopup,
} from './Marker.style';
import { getMarkerColor } from './utilities';
import { MarkerDevicesTable } from './components';
import { markerTypes } from '../../../../constants';

const Marker = ({
  id,
  lon,
  lat,
  type,
  name,
  isProjectSuite,
}) => {
  const markerColor = getMarkerColor(type);
  const truncatedName = truncate(name, {
    length: 15,
    separator: ' ',
    omission: ' ...',
  });
  const markerRef = React.createRef();
  const { map } = React.useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    const coordinates = fromLonLat([lon, lat]);
    const overlay = new Overlay({
      element: markerRef.current,
    });

    map.addOverlay(overlay);
    overlay.setPosition(coordinates);
  }, [map, markerRef, lon, lat]);

  if (type === markerTypes.WARNING) {
    return (
      <StyledPopup
        on="click"
        trigger={(
          <StyledWarningMarker
            ref={markerRef}
            color={markerColor}
          >
            <StyledExclamationTriangle size={10} />
            {name}
          </StyledWarningMarker>
        )}
        position="right center"
        name={name}
      >
        <MarkerDevicesTable
          name={name}
          type={type}
          id={id}
          isProjectSuite={isProjectSuite}
        />
      </StyledPopup>
    );
  }

  return (
    <StyledPopup
      on="click"
      trigger={(
        <StyledMarker
          ref={markerRef}
          color={markerColor}
        >
          {truncatedName}
        </StyledMarker>
      )}
      position="right center"
      name={name}
      type={type}
    >
      <MarkerDevicesTable
        name={name}
        type={type}
        id={id}
        isProjectSuite={isProjectSuite}
      />
    </StyledPopup>
  );
};

Marker.defaultProps = {
  type: null,
  isProjectSuite: false,
};

Marker.propTypes = {
  id: PropTypes.string.isRequired,
  lon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  lat: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  isProjectSuite: PropTypes.bool,
};

export default Marker;
