import styled from 'styled-components';

import { Segment } from 'semantic-ui-react';

export const IoModuleTableLimitWrapper = styled(Segment)`
  width: 60%;

  @media only screen and (max-width: 991px) {
    width: 100%;
  }
`;

export default IoModuleTableLimitWrapper;
