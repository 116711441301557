import uuidv4 from 'uuid/v4';
import isObject from 'lodash/isObject';
import isFunction from 'lodash/isFunction';

export const NAME_SEPARATOR = ',';

export function getConfigName(params = {}) {
  return Object.entries(params)
    .map(([key, value]) => `${key}:${value}`)
    .sort((a, b) => a.localeCompare(b))
    .join(NAME_SEPARATOR);
}

export function attachId(value) {
  if (Array.isArray(value)) {
    return value.map(item => attachId(item));
  }

  if (isFunction(value)) {
    return value;
  }

  if (isObject(value)) {
    const id = value.id || uuidv4();

    return Object.keys(value).reduce((acc, key) => {
      acc[key] = attachId(value[key]);

      return acc;
    }, { id });
  }

  return value;
}

export function attachConfig(acc, layoutConfig) {
  const { tabs, ...params } = layoutConfig;
  const name = getConfigName(params);

  acc[name] = attachId(tabs);

  return acc;
}

export function extractObjectsFromArray(acc, deviceConfigurationObject) {
  return acc.concat(deviceConfigurationObject);
}
