import constants from 'dispatcherConst';

export const setNotificationEventFilters = ({ notificationFilters, additionalFilters }) => ({
  type: constants.SET_NOTIFICATION_EVENT_FILTERS,
  payload: {
    notificationFilters,
    additionalFilters,
  },
});

export default setNotificationEventFilters;
