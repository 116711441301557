const OCCURRENCE_TYPE = {
  FIRST: 'FIRST',
  SECOND: 'SECOND',
  THIRD: 'THIRD',
  FOURTH: 'FOURTH',
  FIFTH: 'FIFTH',
  LAST: 'LAST',
};

const OCCURRENCE_TYPE_OPTIONS = [
  {
    key: OCCURRENCE_TYPE.FIRST,
    value: OCCURRENCE_TYPE.FIRST,
    text: 'First',
  },
  {
    key: OCCURRENCE_TYPE.SECOND,
    value: OCCURRENCE_TYPE.SECOND,
    text: 'Second',
  },
  {
    key: OCCURRENCE_TYPE.THIRD,
    value: OCCURRENCE_TYPE.THIRD,
    text: 'Third',
  },
  {
    key: OCCURRENCE_TYPE.FOURTH,
    value: OCCURRENCE_TYPE.FOURTH,
    text: 'Fourth',
  },
  {
    key: OCCURRENCE_TYPE.FIFTH,
    value: OCCURRENCE_TYPE.FIFTH,
    text: 'Fifth',
  },
  {
    key: OCCURRENCE_TYPE.LAST,
    value: OCCURRENCE_TYPE.LAST,
    text: 'Last',
  },
];

const OCCURRENCE_TYPE_NUMBER = {
  [OCCURRENCE_TYPE.FIRST]: 1,
  [OCCURRENCE_TYPE.SECOND]: 2,
  [OCCURRENCE_TYPE.THIRD]: 3,
  [OCCURRENCE_TYPE.FOURTH]: 4,
  [OCCURRENCE_TYPE.FIFTH]: 5,
  [OCCURRENCE_TYPE.LAST]: -1,
};

const OCCURRENCE_TYPE_FROM_NUMBER = {
  1: OCCURRENCE_TYPE.FIRST,
  2: OCCURRENCE_TYPE.SECOND,
  3: OCCURRENCE_TYPE.THIRD,
  4: OCCURRENCE_TYPE.FOURTH,
  5: OCCURRENCE_TYPE.FIFTH,
  '-1': OCCURRENCE_TYPE.LAST,
};

const OCCURRENCE_DAY = {
  DAY: 'DAY',
  SUNDAY: 'SUNDAY',
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
};

const OCCURRENCE_DAY_OPTIONS = [
  {
    key: OCCURRENCE_DAY.DAY,
    value: OCCURRENCE_DAY.DAY,
    text: 'Day',
  },
  {
    key: OCCURRENCE_DAY.SUNDAY,
    value: OCCURRENCE_DAY.SUNDAY,
    text: 'Sunday',
  },
  {
    key: OCCURRENCE_DAY.MONDAY,
    value: OCCURRENCE_DAY.MONDAY,
    text: 'Monday',
  },
  {
    key: OCCURRENCE_DAY.TUESDAY,
    value: OCCURRENCE_DAY.TUESDAY,
    text: 'Tuesday',
  },
  {
    key: OCCURRENCE_DAY.WEDNESDAY,
    value: OCCURRENCE_DAY.WEDNESDAY,
    text: 'Wednesday',
  },
  {
    key: OCCURRENCE_DAY.THURSDAY,
    value: OCCURRENCE_DAY.THURSDAY,
    text: 'Thursday',
  },
  {
    key: OCCURRENCE_DAY.FRIDAY,
    value: OCCURRENCE_DAY.FRIDAY,
    text: 'Friday',
  },
  {
    key: OCCURRENCE_DAY.SATURDAY,
    value: OCCURRENCE_DAY.SATURDAY,
    text: 'Saturday',
  },
];

const OCCURRENCE_DAY_NUMBER = {
  [OCCURRENCE_DAY.MONDAY]: 1,
  [OCCURRENCE_DAY.TUESDAY]: 2,
  [OCCURRENCE_DAY.WEDNESDAY]: 3,
  [OCCURRENCE_DAY.THURSDAY]: 4,
  [OCCURRENCE_DAY.FRIDAY]: 5,
  [OCCURRENCE_DAY.SATURDAY]: 6,
  [OCCURRENCE_DAY.SUNDAY]: 7,
};

const OCCURRENCE_DAY_FROM_NUMBER = {
  1: OCCURRENCE_DAY.MONDAY,
  2: OCCURRENCE_DAY.TUESDAY,
  3: OCCURRENCE_DAY.WEDNESDAY,
  4: OCCURRENCE_DAY.THURSDAY,
  5: OCCURRENCE_DAY.FRIDAY,
  6: OCCURRENCE_DAY.SATURDAY,
  7: OCCURRENCE_DAY.SUNDAY,
};

const OCCURRENCE_MONTH = {
  JANUARY: 'JANUARY',
  FEBRUARY: 'FEBRUARY',
  MARCH: 'MARCH',
  APRIL: 'APRIL',
  MAY: 'MAY',
  JUNE: 'JUNE',
  JULY: 'JULY',
  AUGUST: 'AUGUST',
  SEPTEMBER: 'SEPTEMBER',
  OCTOBER: 'OCTOBER',
  NOVEMBER: 'NOVEMBER',
  DECEMBER: 'DECEMBER',
};

const OCCURRENCE_MONTH_OPTIONS = [
  {
    key: OCCURRENCE_MONTH.JANUARY,
    value: OCCURRENCE_MONTH.JANUARY,
    text: 'January',
  },
  {
    key: OCCURRENCE_MONTH.FEBRUARY,
    value: OCCURRENCE_MONTH.FEBRUARY,
    text: 'February',
  },
  {
    key: OCCURRENCE_MONTH.MARCH,
    value: OCCURRENCE_MONTH.MARCH,
    text: 'March',
  },
  {
    key: OCCURRENCE_MONTH.APRIL,
    value: OCCURRENCE_MONTH.APRIL,
    text: 'April',
  },
  {
    key: OCCURRENCE_MONTH.MAY,
    value: OCCURRENCE_MONTH.MAY,
    text: 'May',
  },
  {
    key: OCCURRENCE_MONTH.JUNE,
    value: OCCURRENCE_MONTH.JUNE,
    text: 'June',
  },
  {
    key: OCCURRENCE_MONTH.JULY,
    value: OCCURRENCE_MONTH.JULY,
    text: 'July',
  },
  {
    key: OCCURRENCE_MONTH.AUGUST,
    value: OCCURRENCE_MONTH.AUGUST,
    text: 'August',
  },
  {
    key: OCCURRENCE_MONTH.SEPTEMBER,
    value: OCCURRENCE_MONTH.SEPTEMBER,
    text: 'September',
  },
  {
    key: OCCURRENCE_MONTH.OCTOBER,
    value: OCCURRENCE_MONTH.OCTOBER,
    text: 'October',
  },
  {
    key: OCCURRENCE_MONTH.NOVEMBER,
    value: OCCURRENCE_MONTH.NOVEMBER,
    text: 'November',
  },
  {
    key: OCCURRENCE_MONTH.DECEMBER,
    value: OCCURRENCE_MONTH.DECEMBER,
    text: 'December',
  },
];

const OCCURRENCE_MONTH_NUMBER = {
  [OCCURRENCE_MONTH.JANUARY]: 1,
  [OCCURRENCE_MONTH.FEBRUARY]: 2,
  [OCCURRENCE_MONTH.MARCH]: 3,
  [OCCURRENCE_MONTH.APRIL]: 4,
  [OCCURRENCE_MONTH.MAY]: 5,
  [OCCURRENCE_MONTH.JUNE]: 6,
  [OCCURRENCE_MONTH.JULY]: 7,
  [OCCURRENCE_MONTH.AUGUST]: 8,
  [OCCURRENCE_MONTH.SEPTEMBER]: 9,
  [OCCURRENCE_MONTH.OCTOBER]: 10,
  [OCCURRENCE_MONTH.NOVEMBER]: 11,
  [OCCURRENCE_MONTH.DECEMBER]: 12,
};

const OCCURRENCE_MONTH_FROM_NUMBER = {
  1: OCCURRENCE_MONTH.JANUARY,
  2: OCCURRENCE_MONTH.FEBRUARY,
  3: OCCURRENCE_MONTH.MARCH,
  4: OCCURRENCE_MONTH.APRIL,
  5: OCCURRENCE_MONTH.MAY,
  6: OCCURRENCE_MONTH.JUNE,
  7: OCCURRENCE_MONTH.JULY,
  8: OCCURRENCE_MONTH.AUGUST,
  9: OCCURRENCE_MONTH.SEPTEMBER,
  10: OCCURRENCE_MONTH.OCTOBER,
  11: OCCURRENCE_MONTH.NOVEMBER,
  12: OCCURRENCE_MONTH.DECEMBER,
};

export {
  OCCURRENCE_TYPE,
  OCCURRENCE_TYPE_OPTIONS,
  OCCURRENCE_TYPE_NUMBER,
  OCCURRENCE_TYPE_FROM_NUMBER,
  OCCURRENCE_DAY,
  OCCURRENCE_DAY_OPTIONS,
  OCCURRENCE_DAY_NUMBER,
  OCCURRENCE_DAY_FROM_NUMBER,
  OCCURRENCE_MONTH,
  OCCURRENCE_MONTH_OPTIONS,
  OCCURRENCE_MONTH_NUMBER,
  OCCURRENCE_MONTH_FROM_NUMBER,
};
