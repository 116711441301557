import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import { Field } from 'redux-form';
import { Popup } from 'semantic-ui-react';

import { isValidRange } from 'components/form/formValidators';
import TextField from 'components/form/TextField/TextField';
import CheckboxField from 'components/form/CheckboxField/CheckboxField';
import {
  StyledField,
  StyledWrapper,
  StyledLabel,
  StyledInputWrapper,
  StyledCheckboxWrapper,
} from './InputFormField.style';

const InputFormField = ({
  submitting,
  name,
  enableName,
  editMode,
  value,
  gteq,
  lteq,
  label,
  labelPlacement,
  hideCheckbox,
  hideLabel,
}) => {
  const validator = useCallback(fieldValue => {
    if (!isNil(gteq) && !isNil(lteq)) {
      const funcRange = isValidRange(gteq, lteq);

      return funcRange(fieldValue);
    }

    return undefined;
  }, [gteq, lteq]);

  return (
    <StyledWrapper>
      {!hideLabel && labelPlacement === 'left' && (
        <StyledLabel>{label}</StyledLabel>
      )}
      <StyledInputWrapper labelPlacement={labelPlacement}>
        <StyledField
          name={name}
          component={TextField}
          type="text"
          loading={submitting}
          validate={validator}
          value={value}
          editMode={editMode}
          placeholder={label}
          hideErrorMessage
          highlightFieldOnError
        />
        {!hideCheckbox && (
          <Popup
            content="Unchecked colours will be ignored"
            trigger={(
              <StyledCheckboxWrapper>
                <Field
                  name={enableName}
                  component={CheckboxField}
                />
              </StyledCheckboxWrapper>
            )}
          />
        )}
        {!hideLabel && labelPlacement === 'bottom' && (
          <StyledLabel>{label}</StyledLabel>
        )}
      </StyledInputWrapper>

    </StyledWrapper>
  );
};

InputFormField.defaultProps = {
  submitting: false,
  editMode: false,
  value: null,
  gteq: null,
  lteq: null,
  label: null,
  labelPlacement: 'left',
  hideCheckbox: false,
  hideLabel: false,
};

InputFormField.propTypes = {
  submitting: PropTypes.bool,
  name: PropTypes.string.isRequired,
  editMode: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  gteq: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  lteq: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.string,
  enableName: PropTypes.string.isRequired,
  labelPlacement: PropTypes.oneOf(['left', 'bottom']),
  hideCheckbox: PropTypes.bool,
  hideLabel: PropTypes.bool,
};

export default InputFormField;
