import { call, select } from 'redux-saga/effects';

import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import dayViewActionCreators from '../actionCreators';
import showHiddenSelector from '../selectors/showHiddenSelector';

function* fetchOccurrences(action) {
  const { endpoint, urlParams, ...rest } = action.payload;
  const { recipientType, recipientId } = urlParams;

  const showHidden = (yield select(showHiddenSelector));

  const newUrlParams = {
    ...urlParams,
    'filter[showHidden]': showHidden,
  };

  const { response, error } = yield call(getPath, endpoint, { urlParams: newUrlParams });

  const responseWithResourceId = response && {
    ...response,
    resourceId: recipientId,
    resourceType: recipientType,
  };

  const params = {
    response: responseWithResourceId,
    error,
    successDisp: dayViewActionCreators.fetchOccurrencesSuccess,
    failDisp: dayViewActionCreators.fetchOccurrencesFailure,
    ...rest,
  };

  yield call(processCall, params);
}

export default fetchOccurrences;
