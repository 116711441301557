import styled from 'styled-components';

const PanelSeparator = styled.hr`
  margin-left: -1em !important;
  margin-right: -1em !important;
  height: 1px;
  background-color: rgba(0,0,0,0.2) !important;
  border: none;
`;

export default PanelSeparator;
