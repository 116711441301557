import {
  fetchProjectsStatus,
  fetchProjectsStatusSuccess,
  fetchProjectsStatusFailure,
} from './fetchProjectsStatus';

export default {
  fetchProjectsStatus,
  fetchProjectsStatusSuccess,
  fetchProjectsStatusFailure,
};
