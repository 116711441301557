import styled, { css } from 'styled-components';
import { Grid } from 'semantic-ui-react';

import { mediaMax } from 'themes/mixins';
import filterProps from 'helpers/filterProps';

const FilteredProps = filterProps([
  'noPaddingTop',
  'noPaddingBottom',
])(Grid.Row);

export const StyledLabel = styled.label``;

export const StyledRow = styled(FilteredProps)`

  ${({ noPaddingTop }) => noPaddingTop && css`
    padding-top: 0 !important;
  `}

  ${({ noPaddingBottom }) => noPaddingBottom && css`
    padding-bottom: 0 !important;
  `}

`;

export const StyledColumn = styled(Grid.Column)`
   ${mediaMax.sm`
    margin-top: 1em !important;
    margin-bottom: 1em !important;
  `};
`;


export default StyledLabel;
