export const ERROR_LEVEL = 'error';
export const SUCCESS_LEVEL = 'success';
export const INFO_LEVEL = 'info';

export const ERROR_SERVER_CONNECTION = 'No connection to server. Try later.';
export const USER_SUSPENDED = 'User suspended';
export const USER_ACTIVATED = 'User suspension removed';
export const SOME_FILES_NOT_SUPPORTED = (
  'Some of your file(s) cannot be uploaded - they\'re not supported by this device'
);
export const ALL_FILES_NOT_SUPPORTED = (
  'None of your file(s) can be uploaded - they\'re not supported by this device'
);

export const messages = {
  DATA_SAVED: 'Data saved',
  TRIGGER_QUEUED: 'Trigger has been queued successfully',
  USER_REMOVED: 'User removed from site',
  NEW_PASSWORD: 'You are now logged-in using your new password',
  PASSWORD_CHANGED: 'Password changed successfully',
  COMPANY_ADDED: companyName => `Company ${companyName} added`,
  USER_INVITED: email => `An invitation has been sent to ${email}`,
  DEVICE_ADDED: deviceName => `Device ${deviceName} added`,
  DEVICE_RENAMED: 'Device name has been updated successfully',
  DEVICE_REMOVED: 'Device removed from site',
  PROJECT_ADDED: projectName => `Site ${projectName} added`,
  PROJECT_SUITE_ADDED: projectSuiteName => `Multi-site ${projectSuiteName} added`,
  SITE_NAME_UPDATED: 'Site name has been updated successfully',
  ORIGIN_ADDED: originName => `Origin ${originName} added`,
  PROJECT_DELETED: projectName => `Site ${projectName} has been deleted`,
  PROJECT_SUITE_DELETED: projectSuiteName => `Multi-site ${projectSuiteName} has been deleted`,
  TASK_ADDED: taskName => `Task ${taskName} added`,
  TASK_REMOVED: projectName => `The task was removed from resource ${projectName}`,
  ACTION_REMOVED: taskName => `The action was removed from task ${taskName}`,
  ACTION_ADDED: 'The action has been added',
  ACTION_EMPTY_FORM: 'Please complete all fields before submitting!',
  ORIGIN_DELETED: originName => `Origin ${originName} has been deleted`,
  TOGGLE_SUSPEND_USER: suspended => {
    if (suspended) {
      return USER_SUSPENDED;
    }

    return USER_ACTIVATED;
  },
  RELOADING: 'Reloading data. Recently updated or added resource may not be visible due to data display settings.',
  ERROR_DELETE_ORIGIN: originName => `Unable to delete origin ${originName}`,
  ERROR_API_DOMAIN: 'Given API domain is incorrect',
  SUCCESS_API_DOMAIN: 'API domain changed successfully',
  UPLOAD_OMITTED: systemFiles => `Upload for these files was omitted:
    ${systemFiles.join(', ')}. Already in progress.`,
  TRANSFER_TRIGGERED: 'The transfer has been started successfully',
  DOWNLOAD_STARTED: 'The download has started successfully',
  FILES_NOT_SUPPORTED: supportedCount => (supportedCount
    ? SOME_FILES_NOT_SUPPORTED
    : ALL_FILES_NOT_SUPPORTED
  ),
  ERROR_FETCH_DEVICE: 'Unable to fetch device data',
  TABLE_UPDATE_SWITCH_OFF: tableName => `Updates for table ${tableName} switched off.`,
  WAITING_FOR_CONFIRMATION: 'Request was sent, waiting for confirmation.',
  FILE_REMOVED: 'File was removed successfully',
  REMOVE_ONLINE_DEVICE: deviceName => `Cannot remove device ${deviceName} while it is online.`,
  NO_PATCHES: 'The device has not published any patches yet.',
  NO_LOGS: 'The device has not published any logs yet.',
  NO_FIXTURE_GROUPS: 'The device has not published any fixture groups yet.',
  NO_TIMELINES: 'The device has not published any timelines yet.',
  NO_SCENES: 'The device has not published any static scenes yet.',
  NO_TRIGGERS: 'The device has not published any triggers yet.',
  NO_SNAPSHOTS: 'The device has not published any snapshots yet.',
  NO_ZONES: 'The device has not published any zones yet.',
  NO_IO_MODULES: 'The device does not have the IO module list initialized.',
  NO_ACTIONS_FOR_DEVICE: logicalDeviceId => `No actions available for this device.
    Please contact your system administrator with this logical device id: ${logicalDeviceId}`,
  TASK_DISPATCHED: 'Task dispatched',
  TASKS_DISPATCHED: 'All Tasks dispatched',
  ACTIONS_DISPATCHED: 'Actions executed successfully.',
  ACTIONS_DISPATCHED_FAILED: 'Unable to execute actions.',
  SELECTION_REACHED_MAX: 'You have reached the maximum allowed selections.',
  CANNOT_LOAD_BACKGROUND_IMAGE: 'Cannot load background image',
  ACTION_UPDATED: 'The action was updated',
  SITE_LOCATION_UPDATED: 'Site location has been updated',
  ERROR_CANNOT_FETCH_ADDRESS: 'Cannot fetch street address',
  ERROR_SITE_UPDATE_FAILED: 'Unable to update site',
  ERROR_UNABLE_TO_FETCH_LOCATION: 'Unable to fetch some location information',
  SITE_PRIMARY_CONTACT_UPDATED: 'Site primary contact updated successfully',
  SITE_PRIMARY_CONTACT_EMAIL_UPDATED: 'Site primary contact email updated successfully',
  SITE_PRIMARY_CONTACT_TELEPHONE_UPDATED: 'Site primary contact phone updated successfully',
  SITE_NOTES_UPDATED: 'Site notes updated successfully',
  SITE_STREET_UPDATED: 'Site street updated successfully',
  SITE_CITY_UPDATED: 'Site city updated successfully',
  SITE_COUNTRY_UPDATED: 'Site country updated successfully',
  SITE_POSTCODE_UPDATED: 'Site postcode updated successfully',
  SITE_DATE_UPDATED: 'Site date updated successfully',
  SITE_DEMO_UPDATED: 'Site demo attribute updated successfully',
  SITE_CONSTRUCTION_UPDATED: 'Site was enabled successfully',
  SITE_COMMERCIAL_CONTACT_EMAIL_UPDATED: 'Site commercial contact email updated successfully',
  SITE_SUBSCRIPTION_CONTACT_EMAIL_UPDATED: 'Site subscription contact email updated successfully',
  SITE_TECHNICAL_CONTACT_EMAIL_UPDATED: 'Site technical contact email updated successfully',
  SITE_SUBSCRIPTION_PLAN_CODE_UPDATED: 'Site subscription plan code updated successfully',
  SITE_POINTS_UPDATED: 'Site points updated successfully',
  SITE_AUTO_RENEWAL_YEARS_UPDATED: 'Site auto renewal years updated successfully',
  SITE_AUTO_RENEW_COMMENT_UPDATED: 'Site auto renew comment updated successfully',
  SITE_DATE_BLOCKING_START_TIME_UPDATED: 'Site date blocking start time updated successfully',
  AUTOMATED_OPERATION_ADDED: name => `Task scheduler ${name} added`,
  SCHEDULE_ADDED: name => `Schedule ${name} added`,
  OPERATION_ADDED: 'Operation added',
  AUTOMATED_OPERATION_REMOVED: 'The task scheduler was removed',
  SCHEDULE_REMOVED: 'The schedule was removed',
  OPERATION_REMOVED: 'The operation was removed',
  AUTOMATED_OPERATION_UPDATED: 'The task scheduler was updated',
  SCHEDULE_UPDATED: 'The schedule was updated',
  REMOVE_SCHEDULE_PERMISSION_FAILED: 'You are not authorized to perform this action.',
  TASK_UPDATED: 'The task was updated',
  TASK_TEMPORARY_ERROR: 'This operation cannot be performed',
  TWO_FACTOR_DISABLED: 'Two Factor Authentication has been disabled.',
  REFRESH_OTP_SECRET: 'Two Factor Authentication has been updated.',
  RESET_SECRET_KEY: 'User\'s secret key has been reset.',
  ERROR_SCHEDULES_UPDATED: 'Another user has updated the schedules from this task scheduler in the meantime. You can try to submit your changes again, or cancel and review the changes first.',
  ERROR_SCHEDULE_NOT_SAVED: 'There was an error. Your schedule could not be saved. Please try again or contact the portal administrator.',
  ERROR_AF_NOT_RESPONDING: 'There was an error. The server is not responding. Please contact the portal administrator.',
  ERROR_SCHEDULES_CHANGED_CANCEL: 'Another user has changed the schedules from this task scheduler in the meantime. The update was cancelled.',
  PERFORMED_SYNC_FIRMWARE: 'The firmware reload has been started',
  REDEEM_VOUCHER_SUCCESS: 'Voucher activated successfully',
  GENERATE_VOUCHER_SUCCESS: 'Voucher created successfully',
  UPGRADE_SITE_PLAN_SUCCESS: 'Site plan upgraded successfully',
  ERROR_TASK_LOCK_VERSION_CHANGED: 'Another user has changed this task in the meantime. You can try to submit your changes again, or cancel and review the changes first.',
  PROJECT_REMOVED_FROM_PROJECT_SUITE: projectName => `Site ${projectName} has been unlinked from the Multi-site`,
  PROJECT_ADDED_TO_PROJECT_SUITE: projectName => `Site ${projectName} has been linked to the Multi-site`,
  USER_REMOVED_FROM_PROJECT_SUITE: userName => `User ${userName} has been removed from the Multi-site`,
  PROJECT_SUITE_NOTES_UPDATED: 'Multi-site notes updated successfully',
  PROJECT_SUITE_PRIMARY_CONTACT_UPDATED: 'Multi-site primary contact updated successfully',
  PROJECT_SUITE_PRIMARY_CONTACT_EMAIL_UPDATED: 'Multi-site primary contact email updated successfully',
  PROJECT_SUITE_PRIMARY_CONTACT_TELEPHONE_UPDATED: 'Multi-site primary contact phone updated successfully',
  PROJECT_SUITE_COMMERCIAL_CONTACT_EMAIL_UPDATED: 'Multi-site commercial contact email updated successfully',
  PROJECT_SUITE_SUBSCRIPTION_CONTACT_EMAIL_UPDATED: 'Multi-site subscription contact email updated successfully',
  PROJECT_SUITE_TECHNICAL_CONTACT_EMAIL_UPDATED: 'Multi-site technical contact email updated successfully',
  PROJECT_SUITE_LOCATION_UPDATED: 'Multi-site location has been updated',
  PROJECT_SUITE_DATE_BLOCKING_START_TIME_UPDATED: 'Multi-site date blocking start time updated successfully',
  DEVICE_FIRMWARE_ADDED: deviceFirmwareName => `Device firmware ${deviceFirmwareName} added`,
  DEVICE_FIRMWARE_FILE_UPLOADED: 'The firmware file was uploaded',
  DEVICE_FIRMWARE_DELETED: deviceFirmwareName => `The Device Firmware ${deviceFirmwareName} has been deleted`,
  FILE_LINKED_WITH_FIRMWARE_SUCCESS: 'The file was linked with the device firmware',
  FILE_LINKED_WITH_FIRMWARE_FAILED: 'The file could not be linked with the device firmware',
  DEVICE_FIRMWARE_UPDATED: deviceFirmwareName => `The Device Firmware ${deviceFirmwareName} has been updated`,
  TRANSFER_DEVICE_FIRMWARE_SUCCESS: 'The device firmware was transferred successfully',
  TRANSFER_DEVICE_FIRMWARE_FAILED: 'The device firmware was not transferred',
  SITE_RENEWAL_SUCCESS: 'Site renewal years updated successfully',
  SITE_RENEWAL_FAILED: 'Site renewal years did not update',
  LOGOUT_ERROR_MESSAGE: 'No connection to server. Try later.',
};
