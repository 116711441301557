import constants from 'dispatcherConst';
import { messages } from 'data/notifications/notificationsConst';

const updateSitePrimaryContact = args => ({
  type: constants.UPDATE_SITE_REQUEST,
  payload: {
    ...args,
    message: messages.SITE_PRIMARY_CONTACT_UPDATED,
  },
});

export default updateSitePrimaryContact;
