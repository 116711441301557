import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  ZONES_FIELD,
  pollingRefreshingSelector,
  deviceSelector,
} from 'helpers/selectors';
import onMountUnmount from 'helpers/onMountUnmount';
import websocketActionCreators from 'data/websockets/websocketsActionCreators';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { addPagination } from 'helpers/paginationHelpers';
import zonesActionCreators from './zonesActionCreators';
import PathwayZonesV1 from './PathwayZonesV1';

export const mapStateToProps = (state, { match }) => {
  const { deviceId } = match.params;
  const { online } = deviceSelector(state)(deviceId);

  return {
    deviceId,
    refreshing: pollingRefreshingSelector(state, ZONES_FIELD),
    isOnline: online,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: ZONES_FIELD, included: true },
      ({ deviceId }) => zonesActionCreators.fetchZones(deviceId, {
        sort: 'id',
      }),
    ),
  ),
  onMountUnmount(
    ({ deviceId }) => websocketActionCreators.subscribeDeviceData(deviceId, ZONES_FIELD),
    ({ deviceId }) => websocketActionCreators.unsubscribeDeviceData(deviceId, ZONES_FIELD),
  ),
)(PathwayZonesV1);
