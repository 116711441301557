import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/atomic';
import Checkbox from 'components/form/CheckboxField/CheckboxField.style';
import DataTableRow from 'components/DataTable/DataTableRow/DataTableRow';
import DataTableText from 'components/DataTable/DataTableText/DataTableText';
import pathwayConstants from 'layouts/Pathway/constants';

class SnapshotTableRow extends Component {

  constructor() {
    super();

    this.state = {
      disabled: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { deviceId, item: { id }, onPerformAction } = this.props;
    onPerformAction({
      deviceId,
      actionType: pathwayConstants.ACTION_FIRE_SNAPSHOT,
      values: { snapshotId: id },
    });
    this.setState({ disabled: true },
      () => setTimeout(
        () => this.setState({ disabled: false }),
        300,
      ),
    );
  }

  render() {
    const { disabled } = this.state;
    const { isOnline, active } = this.props;

    return (
      <DataTableRow {...this.props}>
        <DataTableText />
        <DataTableText />
        <Checkbox checked={active} disabled />
        <Button
          primary
          onClick={this.handleClick}
          disabled={!isOnline || disabled}
        >
          {(active ? 'Deactivate' : 'Activate')}
        </Button>
      </DataTableRow>
    );
  }

}

SnapshotTableRow.defaultProps = {
  ...DataTableRow.defaultProps,
};

SnapshotTableRow.propTypes = {
  ...DataTableRow.propTypes,
  deviceId: PropTypes.string.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  isOnline: PropTypes.bool.isRequired,
  onPerformAction: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
};

export default SnapshotTableRow;
