import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { FETCHING_FILTER_PROJECTS_FIELD } from 'helpers/selectors';
import {
  selectors as projectSuitesSelectors,
} from 'pages/ProjectSuites/utilities';
import {
  triggerProjectSuiteTasksFiltering,
} from '../../utilities/actionCreators/filterProjectSuiteTasks';
import SelectProjectsDropdown from './SelectProjectsDropdown';

export const mapStateToProps = (state, { field, match }) => {
  const { id: resourceId } = match.params;
  const existingSelectedProjects = projectSuitesSelectors.selectedProjectsSelector(
    state, resourceId, field,
  ) || [];
  const filterParam = FETCHING_FILTER_PROJECTS_FIELD;
  const dropdownText = 'Filter by sites';

  return {
    existingSelectedProjects,
    resourceId,
    filterParam,
    dropdownText,
  };
};

const mapDispatchToProps = {
  onTriggerProjectSuiteTasksFiltering: triggerProjectSuiteTasksFiltering,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(SelectProjectsDropdown);
