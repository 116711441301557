import { takeLatest, takeLeading } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import { actionList } from 'helpers/sagaHelper';

import fetchProjectSuites from './fetchProjectSuites';
import deleteProjectSuite from './deleteProjectSuite';
import addProjectSuite from './addProjectSuite';
import fetchProjectSuite from './fetchProjectSuite';
import fetchProjectSuiteProjects from './fetchProjectSuiteProjects';
import removeProjectFromProjectSuite from './removeProjectFromProjectSuite';
import fetchProjectSuiteProjectsStatus from './fetchProjectSuiteProjectsStatus';
import fetchProjectSuiteUsers from './fetchProjectSuiteUsers';
import removeUserFromProjectSuite from './removeUserFromProjectSuite';
import updateProjectSuite from './updateProjectSuite';
import fetchProjectSuiteReverseGeocoding from './fetchProjectSuiteReverseGeocoding';
import updateProjectSuiteLocationProperties from './updateProjectSuiteLocationProperties';
import fetchProjectSuiteProjectsTasks from './fetchProjectSuiteProjectsTasks';
import fetchProjectSuiteProjectsFilter from './fetchProjectSuiteProjectsFilter';
import triggerProjectSuiteTasksFiltering from './triggerProjectSuiteTasksFiltering';
import addProjectToProjectSuite from './addProjectToProjectSuite';
import fetchProjectProjectSuiteParents from './fetchProjectProjectSuiteParents';

function* saga() {
  yield takeLatest(constants.FETCH_PROJECT_SUITES_REQUEST, fetchProjectSuites);
  yield takeLatest(constants.DELETE_PROJECT_SUITE_REQUEST, deleteProjectSuite);
  yield takeLatest(constants.ADD_PROJECT_SUITE_REQUEST, addProjectSuite);
  yield takeLatest(constants.FETCH_PROJECT_SUITE_REQUEST, fetchProjectSuite);
  yield takeLatest(constants.FETCH_PROJECT_SUITE_PROJECTS_REQUEST, fetchProjectSuiteProjects);
  yield takeLatest(
    constants.REMOVE_PROJECT_FROM_PROJECT_SUITE_REQUEST, removeProjectFromProjectSuite,
  );
  yield takeLatest(
    constants.FETCH_PROJECT_SUITE_PROJECTS_STATUS_REQUEST, fetchProjectSuiteProjectsStatus,
  );
  yield takeLatest(constants.FETCH_PROJECT_SUITE_USERS_REQUEST, fetchProjectSuiteUsers);
  yield takeLatest(constants.REMOVE_USER_FROM_PROJECT_SUITE_REQUEST, removeUserFromProjectSuite);
  yield takeLatest(constants.UPDATE_PROJECT_SUITE_REQUEST, updateProjectSuite);
  yield takeLatest(
    constants.FETCH_PROJECT_SUITE_PROJECTS_TASKS_REQUEST, fetchProjectSuiteProjectsTasks,
  );
  yield takeLatest(
    constants.FETCH_PROJECT_SUITE_REVERSE_GEOCODING_REQUEST, fetchProjectSuiteReverseGeocoding,
  );
  yield takeLeading(
    actionList(
      constants.FETCH_PROJECT_SUITE_SUCCESS,
      constants.UPDATE_PROJECT_SUITE_SUCCESS,
    ),
    updateProjectSuiteLocationProperties,
  );
  yield takeLatest(
    constants.FETCH_PROJECT_SUITE_PROJECTS_FILTER_REQUEST, fetchProjectSuiteProjectsFilter,
  );
  yield takeLatest(
    constants.TRIGGER_PROJECT_SUITE_TASKS_FILTERING, triggerProjectSuiteTasksFiltering,
  );
  yield takeLatest(constants.ADD_PROJECT_TO_PROJECT_SUITE_REQUEST, addProjectToProjectSuite);
  yield takeLatest(
    constants.FETCH_PROJECT_PROJECT_SUITE_PARENTS_REQUEST, fetchProjectProjectSuiteParents,
  );
}

export default saga;
