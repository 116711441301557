import { DateTime } from 'luxon';

import hostAndUriFromUrl from './hostAndUriFromUrl';
import emptyStringHash from './emptyStringHash';

function buildCanonicalRequest(url) {
  const method = 'GET';
  const { host, uri } = hostAndUriFromUrl(url);
  const datetime = DateTime.utc();
  const datetimeString = datetime.toFormat('yyyyMMdd');
  const hoursString = datetime.toFormat('HHmmss');
  const awsDatetimeString = `${datetimeString}T${hoursString}Z`;
  const headers = [
    'content-type:text/plain',
    `host:${host}`,
    `x-amz-date:${awsDatetimeString}`,
  ];
  const canonicalHeaders = headers.map(line => `${line}\n`).join('');
  const signedHeaders = 'content-type;host;x-amz-date';
  const canonicalRequestLines = [
    method,
    uri,
    '', // query string
    canonicalHeaders,
    signedHeaders,
    emptyStringHash(), // empty hash for empty payload
  ];

  const canonicalRequest = canonicalRequestLines.join('\n');

  return {
    signedHeaders,
    canonicalRequest,
    datetimeString,
    awsDatetimeString,
  };
}

export default buildCanonicalRequest;
