import React from 'react';
import PropTypes from 'prop-types';

import Form from 'components/form/Form/Form';
import { validPassword } from 'components/form/formValidators';
import { Field } from 'redux-form';
import { FormGroup, Header, Grid, Segment } from 'semantic-ui-react';
import { Button } from 'components/atomic';
import TextField from 'components/form/TextField/TextField';

export const passwordFormFields = {
  currentPassword: 'currentPassword',
  newPassword: 'newPassword',
};

const ChangePasswordForm = ({ submitting, handleSubmit }) => (
  <Grid>
    <Grid.Row>
      <Grid.Column mobile={16} computer={8}>
        <Segment>
          <Header as="h3">Change password</Header>
          <Form onSubmit={handleSubmit}>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} computer={16}>
                  <FormGroup>
                    <Field
                      name={passwordFormFields.currentPassword}
                      component={TextField}
                      label="Current password"
                      loading={submitting}
                      type="password"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      name={passwordFormFields.newPassword}
                      component={TextField}
                      label="New password"
                      loading={submitting}
                      type="password"
                      validate={validPassword}
                    />
                  </FormGroup>
                </Grid.Column>
                <Grid.Column mobile={16} computer={16}>
                  <Button disabled={submitting} primary type="submit" floated="right">
                    Submit
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

ChangePasswordForm.propTypes = {
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default ChangePasswordForm;
