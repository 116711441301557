import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Segment, Header, List } from 'semantic-ui-react';
import pluralize from 'pluralize';

import { SimpleGateway, frontendPermissions } from 'gateway';
import { TipIcon } from 'components/atomic';
import { EnableSiteModalForm } from '../../../../SiteInformation/components/EnableSite/components';
import { AutoRenewYearsForm, UpgradePlanConfirmModal, AutoRenewSuspended } from './components';
import { StyledListContent, ListLabel } from './SubscriptionStatus.style';

function SubscriptionStatus({
  siteId,
  renewalDate,
  subscriptionPlanCode,
  points,
  projectedSubscriptionPlanCode,
  onUpdateSiteConstruction,
  onUpgradeSitePlan,
}) {

  const handleEnableSite = useCallback(
    () => new Promise((resolveForm, rejectForm) => {
      onUpdateSiteConstruction({
        values: { id: siteId, construction: false },
        resolveForm,
        rejectForm,
      });
    }), [siteId, onUpdateSiteConstruction],
  );

  const formattedSitePlan = useMemo(() => {
    if (!projectedSubscriptionPlanCode) {
      return '--';
    }

    return `${projectedSubscriptionPlanCode} (${points} ${pluralize('point', points)})`;
  }, [projectedSubscriptionPlanCode, points]);

  const formattedSubscriptionPlan = useMemo(() => {
    if (!subscriptionPlanCode) {
      return 'No Subscription found.';
    }

    return subscriptionPlanCode;
  }, [subscriptionPlanCode]);

  const formattedRenewalDate = useMemo(() => {
    if (!subscriptionPlanCode) {
      return 'No Subscription found.';
    }

    if (renewalDate) {
      return DateTime.fromISO(renewalDate).toUTC().toFormat('dd/MM/y');
    }

    return (
      <EnableSiteModalForm
        form="editSiteProperty.construction"
        name="construction"
        onSubmit={handleEnableSite}
      />
    );
  }, [subscriptionPlanCode, renewalDate, handleEnableSite]);

  const upgradePlanButton = useMemo(() => {
    if (!subscriptionPlanCode || subscriptionPlanCode === 'Construction') {
      return null;
    }

    if (projectedSubscriptionPlanCode > subscriptionPlanCode) {
      return (
        <SimpleGateway condition={frontendPermissions.OWNER}>
          <UpgradePlanConfirmModal
            projectId={siteId}
            planCode={projectedSubscriptionPlanCode}
            onUpgradeSitePlan={onUpgradeSitePlan}
          />
          &nbsp;&nbsp;
        </SimpleGateway>
      );
    }

    return null;
  }, [projectedSubscriptionPlanCode, subscriptionPlanCode, siteId, onUpgradeSitePlan]);

  return (
    <Segment>
      <Header as="h3">Subscription Status</Header>
      <List divided verticalAlign="middle">
        <List.Item>
          <StyledListContent>
            <div>
              <ListLabel>Required Site Plan</ListLabel>
              <TipIcon
                floated="right"
                message="Plan required for this Site, determined by the total points of associated devices."
                position="bottom center"
                editMode={false}
              />
            </div>
            <div>
              {formattedSitePlan}
            </div>
          </StyledListContent>
        </List.Item>
        <List.Item>
          <StyledListContent>
            <div>
              <ListLabel>Subscription Plan</ListLabel>
              <TipIcon
                floated="right"
                message="The active plan for this Site, which should equal or exceed the required Site plan. Change this by purchasing a subscription."
                position="bottom center"
                editMode={false}
              />
            </div>
            <div>
              {upgradePlanButton}
              {formattedSubscriptionPlan}
            </div>
          </StyledListContent>
        </List.Item>
        <List.Item>
          <StyledListContent>
            Renewal Date
            <div>
              {formattedRenewalDate}
            </div>
          </StyledListContent>
        </List.Item>
        <List.Item>
          <StyledListContent>
            Site Id
            <div>
              {siteId}
            </div>
          </StyledListContent>
        </List.Item>
        <List.Item>
          <StyledListContent noPaddingBottom>
            <AutoRenewYearsForm />
            <AutoRenewSuspended />
          </StyledListContent>
        </List.Item>
      </List>
    </Segment>
  );
}

SubscriptionStatus.defaultProps = {
  renewalDate: null,
  points: 0,
  subscriptionPlanCode: null,
  projectedSubscriptionPlanCode: null,
};

SubscriptionStatus.propTypes = {
  siteId: PropTypes.string.isRequired,
  renewalDate: PropTypes.string,
  points: PropTypes.number,
  subscriptionPlanCode: PropTypes.string,
  projectedSubscriptionPlanCode: PropTypes.string,
  onUpdateSiteConstruction: PropTypes.func.isRequired,
  onUpgradeSitePlan: PropTypes.func.isRequired,
};

export default SubscriptionStatus;
