import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormGroup } from 'semantic-ui-react';

import ModalForm from 'components/form/ModalForm/ModalForm';
import { automatedOperationFormFields, colorList } from '../../../constants';
import {
  StyledSquare,
} from '../../../../../SiteSchedulingTab.style';
import SelectColor from '../../SelectColor/SelectColor';

const EditAutomatedOperationColourModalForm = ({
  submitting,
  change,
  item: {
    name,
    occurrenceColour,
  },
  ...rest
}) => {
  const header = `Edit ${name}`;
  const trigger = <StyledSquare occurrenceColour={occurrenceColour} />;

  return (
    <ModalForm
      header={header}
      trigger={trigger}
      size="small"
      submitting={submitting}
      {...rest}
    >
      <FormGroup
        inline
      >
        <Field
          name={automatedOperationFormFields.occurrenceColor}
          component={SelectColor}
          type="text"
          loading={submitting}
          label="Future Occurrence Colour"
          colorList={colorList}
          existingColor={occurrenceColour}
        />
      </FormGroup>
    </ModalForm>
  );
};

EditAutomatedOperationColourModalForm.defaultProps = {
  change: () => null,
};

EditAutomatedOperationColourModalForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  change: PropTypes.func,
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    occurrenceColour: PropTypes.string,
  }).isRequired,
};

export default EditAutomatedOperationColourModalForm;
