import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import pluralize from 'pluralize';

import { StyledDiv, StyledIcon } from './WarningMessage.style';

const WarningMessage = ({ sitesWithoutLocation }) => {
  if (sitesWithoutLocation > 0) {
    return (
      <Popup
        on="click"
        trigger={(
          <StyledDiv>
            <StyledIcon name="warning sign" size="large" />
          </StyledDiv>
        )}
        position="right center"
      >
        There {pluralize('are', sitesWithoutLocation)} <strong>{sitesWithoutLocation} {pluralize('site', sitesWithoutLocation)}</strong> without location.
      </Popup>
    );
  }

  return null;
};

WarningMessage.defaultProps = {
  sitesWithoutLocation: 0,
};

WarningMessage.propTypes = {
  sitesWithoutLocation: PropTypes.number,
};

export default WarningMessage;
