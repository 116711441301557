import has from 'lodash/has';

const cumulatedPermissionsSelector = (state, resourceId) => {
  const { cumulatedPermissions = {} } = state.permissionsTable || {};

  if (!has(cumulatedPermissions, resourceId)) {
    return null;
  }

  return cumulatedPermissions[resourceId];
};

export default cumulatedPermissionsSelector;
