import isEmpty from 'lodash/isEmpty';

import validateEndingCriteria from './validateEndingCriteria';

const validateWeeklyRecurrenceView = ({ recurrenceParams, endingCriteria }) => {
  const {
    everyNWeeks,
    days,
    forNTimes,
    startTimes,
    startDate,
    endTime,
  } = recurrenceParams;
  let errors = {};

  if (!everyNWeeks) {
    errors.everyNWeeks = 'Required field';
  }

  if (everyNWeeks < 1 || everyNWeeks > 100) {
    errors.everyNWeeks = 'Value must be between 1 and 100';
  }

  if (isEmpty(startTimes)) {
    errors.startTimes = 'Required field';
  }

  if (!isEmpty(startTimes) && startTimes.length > 600) {
    errors.startTimes = 'Only up to 600 values are allowed';
  }

  if (!startDate) {
    errors.startDate = 'Required field';
  }

  if (isEmpty(days)) {
    errors.days = 'You must select at least one day';
  }

  errors = validateEndingCriteria({
    errors,
    startDate,
    forNTimes,
    endTime,
    endingCriteria,
    forNTimesMaxValue: 100,
  });

  return {
    isValid: isEmpty(Object.keys(errors)),
    errors,
  };
};

export default validateWeeklyRecurrenceView;
