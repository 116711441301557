import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';

import { complexValueType } from 'data/types';
import TextDescriptionItem
  from 'components/DescriptionList/TextDescriptionItem/TextDescriptionItem';
import LocalDateDescriptionItem
  from 'components/DescriptionList/LocalDateDescriptionItem/LocalDateDescriptionItem';

const PharosProjectTPSV1 = props => {
  const { loading } = props;
  if (loading) return null;

  const {
    title,
    name,
    fileName,
    fileSize,
    author,
    uploadedOn,
    offsetValue,
  } = props;

  return (
    <Segment>
      <Header as="h2">{title}</Header>
      <TextDescriptionItem
        title="File name"
        value={fileName}
      />
      <TextDescriptionItem
        title="Name"
        value={name}
      />
      <TextDescriptionItem
        title="Author"
        value={author}
      />
      <LocalDateDescriptionItem
        title="Uploaded on"
        value={uploadedOn}
        offset={offsetValue}
      />
      <TextDescriptionItem
        title="File size"
        value={fileSize}
      />
    </Segment>
  );
};

PharosProjectTPSV1.defaultProps = {
  loading: false,
  name: {},
  fileName: {},
  fileSize: {},
  author: {},
  offsetValue: null,
  uploadedOn: {},
};

PharosProjectTPSV1.propTypes = {
  loading: PropTypes.bool,
  name: complexValueType(PropTypes.string),
  fileName: complexValueType(PropTypes.string),
  fileSize: complexValueType(PropTypes.string),
  author: complexValueType(PropTypes.string),
  title: PropTypes.string.isRequired,
  offsetValue: PropTypes.number,
  uploadedOn: complexValueType(PropTypes.number),
};

export default PharosProjectTPSV1;
