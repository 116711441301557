import { call } from 'redux-saga/effects';

import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import {
  fetchServiceVersionsSuccess,
  fetchServiceVersionsFailure,
} from '../actionCreators/fetchServiceVersions';

function* fetchServiceVersions(action) {
  const { endpoint } = action.payload;

  const { response, error } = yield call(getPath, endpoint);

  const params = {
    response,
    error,
    successDisp: fetchServiceVersionsSuccess,
    failDisp: fetchServiceVersionsFailure,
  };

  yield call(processCall, params);
}

export default fetchServiceVersions;
