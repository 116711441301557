import constants from 'dispatcherConst';
import get from 'lodash/get';
import merge from 'lodash/merge';
import mapValues from 'lodash/fp/mapValues';

import {
  LOGS_FIELD,
  ZONES_FIELD,
  SNAPSHOTS_FIELD,
  OUTPUT_FIELD,
  IO_MODULES_INSTANCES_FIELD,
} from 'helpers/selectors';
import {
  markAsRefreshing,
  resetCounter,
  resetCounters,
  updateCounters,
  markUpdatedAndUpdateCounters,
  clearRequestIdIfUpdated,
} from './pollingHelpers';

export const initialState = {
  updating: [],
};

const pollingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.CHANGE_ROUTE: {
      return mapValues(item => {
        if (Array.isArray(item)) return item;

        return ({
          ...item,
          initialized: false,
        });
      }, state);
    }

    case constants.POLLING_SET_REFRESH_RATE: {
      const { loader, status } = payload;

      return merge({}, state, {
        [loader]: { ...status, initialized: true },
      });
    }

    case constants.POLLING_REFRESHING_START: {
      const { loader } = payload;
      const { updating } = state;

      const isPresent = updating.find(name => name === loader);
      if (isPresent) return state;

      return {
        ...state,
        [loader]: markAsRefreshing(state[loader]),
      };
    }

    case constants.POLLING_REFRESHING_STOP: {
      const { loader } = payload;
      const { updating } = state;

      return {
        ...state,
        updating: updating.filter(name => name !== loader),
        [loader]: resetCounter(state[loader]),
      };
    }

    case constants.POLLING_RESOURCE_UPDATE_REQUEST: {
      const { loader, refreshLoader, timeout } = payload;

      return merge({}, state, {
        [loader]: {
          refreshLoader,
          timeout,
        },
      });
    }

    case constants.POLLING_RESOURCE_UPDATE_SUCCESS: {
      const { updating } = state;
      const {
        id: requestId,
        attributes: { dataType },
      } = payload.response.data;

      return merge({}, state, {
        updating: [...updating, dataType],
        [dataType]: { requestId },
      });
    }

    case constants.POLLING_FIELD_UPDATE: {
      const { updating } = state;
      const { loader, refreshLoader, timeout, currentValue } = payload;

      return merge({}, state, {
        updating: [...updating, loader],
        [loader]: {
          ...markAsRefreshing(state[loader]),
          refreshLoader,
          timeout,
          currentValue,
        },
      });
    }

    case constants.FETCH_DEVICE_FAILED: {
      return resetCounters(state);
    }

    case constants.FETCH_DEVICE_SUCCESS: {
      const updated = get(payload, 'response.data.attributes.updated');
      if (!updated) return state;

      if (!updated.length) {
        return updateCounters(state);
      }

      return markUpdatedAndUpdateCounters(state, updated);
    }

    case constants.FETCH_LOGS_SUCCESS:
      return clearRequestIdIfUpdated(state, LOGS_FIELD);

    case constants.FETCH_ZONES_SUCCESS:
      return clearRequestIdIfUpdated(state, ZONES_FIELD);

    case constants.FETCH_SNAPSHOTS_SUCCESS:
      return clearRequestIdIfUpdated(state, SNAPSHOTS_FIELD);

    case constants.FETCH_OUTPUT_SUCCESS:
      return clearRequestIdIfUpdated(state, OUTPUT_FIELD);

    case constants.FETCH_IO_MODULES_INSTANCES_SUCCESS:
      return clearRequestIdIfUpdated(state, IO_MODULES_INSTANCES_FIELD);

    default:
      return state;
  }
};

export default pollingReducer;
