import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import TextEditFormField from './TextEditFormField/TextEditFormField';
import { TextEditWrapper, StyledActionButton } from './TextEdit.style';

class TextEdit extends Component {

  constructor() {
    super();

    this.state = { editing: false };

    this.handleFocus = this.handleFocus.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ value: nextValue }) {
    const { value } = this.props;

    if (value !== nextValue) {
      this.setState({ editing: false });
      document.activeElement.blur();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { editing } = this.state;
    const { editing: nextEditing } = nextState;

    if (!editing && nextEditing) {
      document.addEventListener('click', this.handleClickOutside, false);
    } else if (editing && !nextEditing) {
      document.removeEventListener('click', this.handleClickOutside, false);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, false);
  }

  handleFocus({ focus }) {
    const { editing } = this.state;
    const { submitting, initialize, name, value, locked } = this.props;

    if (!submitting && !editing && !locked) {
      initialize({ [name]: value });
      this.setState({ editing: true }, () => focus());
    }
  }

  handleCancel() {
    const { reset } = this.props;

    this.setState({ editing: false });
    reset();
  }

  handleClickOutside({ target }) {
    const { handleCancel } = this;

    if (!this.node.contains(target)) {
      handleCancel();
    }
  }

  handleKeyDown({ nativeEvent: { keyCode } }) {
    const { handleCancel } = this;

    if (keyCode === 27) {
      handleCancel();
    }
  }

  handleSubmit(e) {
    const { submitting, pristine, allowCancelOnPristine } = this.props;
    const { handleCancel } = this;

    if (submitting || pristine) {
      e.preventDefault();
    }

    if (allowCancelOnPristine && pristine) {
      handleCancel();
    }
  }

  render() {
    const { editing } = this.state;
    const {
      className,
      handleSubmit,
      submitting,
      value,
      name,
      validator,
      type,
      locked,
    } = this.props;

    const action = editing ? (
      <StyledActionButton
        primary
        icon="checkmark"
        type="submit"
        onClick={this.handleSubmit}
        disabled={submitting}
      />
    ) : null;

    return (
      <div ref={node => { this.node = node; }} className={className}>
        <TextEditWrapper>
          <form onSubmit={handleSubmit}>
            <Field
              name={name}
              component={TextEditFormField}
              action={action}
              validate={validator}
              type={type}
              onKeyDown={this.handleKeyDown}
              readOnly={!editing}
              readOnlyValue={value}
              onFocusCallback={this.handleFocus}
              disabled={!editing}
              locked={locked}
            />
          </form>
        </TextEditWrapper>
      </div>
    );
  }

}

TextEdit.defaultProps = {
  className: '',
  type: undefined,
  validator: null,
  value: null,
  allowCancelOnPristine: false,
  locked: false,
};

TextEdit.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  type: PropTypes.string,
  validator: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
  value: PropTypes.any,
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  allowCancelOnPristine: PropTypes.bool,
  locked: PropTypes.bool,
};

export default TextEdit;
