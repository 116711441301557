import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { FIXTURE_GROUPS_FIELD } from 'helpers/selectors';
import onMountUnmount from 'helpers/onMountUnmount';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { addPagination } from 'helpers/paginationHelpers';
import websocketActionCreators from 'data/websockets/websocketsActionCreators';
import fixtureGroupsActionCreators from './fixtureGroupsActionCreators';
import FixtureGroupsSection from './FixtureGroupsSection';

const mapStateToProps = (state, { resourceId }) => ({
  deviceId: resourceId,
});

export default compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: FIXTURE_GROUPS_FIELD, included: true },
      ({ deviceId }) => fixtureGroupsActionCreators.fetchGroups(deviceId, {
        sort: 'id',
        groups: 'type',
      }),
    ),
  ),
  onMountUnmount(
    ({ deviceId }) => websocketActionCreators.subscribeDeviceData(deviceId, 'fixture_groups'),
    ({ deviceId }) => websocketActionCreators.unsubscribeDeviceData(deviceId, 'fixture_groups'),
  ),
)(FixtureGroupsSection);
