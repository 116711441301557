import { call, race, take } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import { putPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import CompanyModel from 'models/CompanyModel';
import actionCreators from '../actionCreators';

function* editUrlOrigin(action) {
  const {
    endpoint,
    params: { values, resolveForm, rejectForm },
    companyId,
  } = action.payload;

  const body = CompanyModel.requestBody()
    .setId(companyId)
    .setAttributes(values);

  const { fulfilled, cancelled } = yield race({
    fulfilled: call(putPath, endpoint, body),
    cancelled: take(constants.EDIT_URL_ORIGIN_REQUEST),
  });

  if (cancelled) {
    yield call(rejectForm);
    return;
  }

  const { response, error } = fulfilled;
  const params = {
    response,
    error,
    successDisp: actionCreators.editOriginSuccess,
    failDisp: actionCreators.editOriginFailure,
    resolveForm,
    rejectForm,
  };
  yield call(processCall, params);
}

export default editUrlOrigin;
