import constants from 'dispatcherConst';
import { messages } from 'data/notifications/notificationsConst';

export const updateSiteSuccess = ({ message, ...response }) => ({
  type: constants.UPDATE_SITE_SUCCESS,
  payload: { response, message },
});

export const updateSiteFailure = error => ({
  type: constants.UPDATE_SITE_FAILED,
  payload: {
    error,
    message: messages.ERROR_SITE_UPDATE_FAILED,
  },
});
