import styled from 'styled-components';
import { Table } from 'semantic-ui-react';

export const ParamHeaderCell = styled(Table.HeaderCell)`
  width: auto;
  cursor: default !important;
  border-bottom: solid 1px #e6e6e6 !important;

  &:nth-of-type(1) {
    padding-left: 23px !important;
    width: 23% !important;

    @media only screen and (max-width: 991px) {
      padding-left: 0px !important;
    }
  }

  &:nth-of-type(2) {
    width: 25% !important;
  }
`;

export const Message = styled('div')`
  margin-left: 23px;
  font-size: 12px;
`;
