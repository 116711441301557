import styled from 'styled-components';

import { injectStyles } from 'themes/mixins';

const withThemedControl = WrappedComponent => styled(WrappedComponent)`
  ${({ theme: { links } }) => injectStyles(links)};

  &[disabled] {
    opacity: 0.7;
    pointer-events: none;
    cursor: default;
  }
`;

export default withThemedControl;
