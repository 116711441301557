import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
} from 'semantic-ui-react';
import { Field } from 'redux-form';

import {
  FormFieldLabel,
  FormFieldWrapper,
  InputWrapper,
} from 'components/form/FormField/FormField.style';
import SelectField from 'components/form/SelectField/SelectField';

class SelectLogicalDevice extends Component {

  constructor(props) {
    super(props);

    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(e, value) {
    const { getActionTypes, selectDevice, resetTypeId } = this.props;

    getActionTypes(value);
    selectDevice(value);
    resetTypeId();
  }

  render() {
    const { logicalDevicesList } = this.props;
    const { handleSelect } = this;

    const dropDownOptions = logicalDevicesList.map(item => (
      { key: item.id, text: item.name, value: item.id }));

    return (
      <FormGroup inline>
        <FormFieldWrapper width={16}>
          <FormFieldLabel htmlFor="input">Select a device</FormFieldLabel>
          <InputWrapper>
            <Field
              name="logicalDeviceId"
              placeholder="Device"
              component={SelectField}
              onChange={handleSelect}
              options={dropDownOptions}
            />
          </InputWrapper>
        </FormFieldWrapper>
      </FormGroup>
    );
  }

}

SelectLogicalDevice.defaultProps = {
  logicalDevicesList: [],
};

SelectLogicalDevice.propTypes = {
  logicalDevicesList: PropTypes.array,
  getActionTypes: PropTypes.func.isRequired,
  selectDevice: PropTypes.func.isRequired,
  resetTypeId: PropTypes.func.isRequired,
};

export default SelectLogicalDevice;
