import styled from 'styled-components';

import { Button } from 'components/atomic';

export const TextEditWrapper = styled.div`
  & .ui.input.start {
    width: 100%;
    max-height: 200px;
  }
`;

export const StyledActionButton = styled(Button)`
  &.ui.icon.primary.button {
    position: absolute;
    right: -4px;
    bottom: 0px;
  }
`;
