import styled from 'styled-components';
import { Button, Icon } from 'semantic-ui-react';

export const StyledButton = styled(Button)`
  &.ui.button {
    position: absolute;
    top: 5em;
    left: 0.6em;
    width: 10px;
    background-color: #7a98bc;
    color: #ffffff;
    border: solid 3px rgba(255, 255, 255, .5);
    background-clip: padding-box;
    cursor: default;

    &:hover {
      background-color: #003c88;
      opacity: 0.7;
    }
  }
`;

export const StyledIcon = styled(Icon)`
  position: absolute; 
  top: 6px; 
  left: 4px;
`;
