import React from 'react';
import PropTypes from 'prop-types';

const withProjectPermissions = WrappedComponent => {
  const WithProjectPermissions = ({ ready, ...rest }) => {
    if (!ready) {
      return null;
    }

    return <WrappedComponent {...rest} />;
  };

  WithProjectPermissions.defaultProps = {
    ready: false,
  };

  WithProjectPermissions.propTypes = {
    ready: PropTypes.bool,
  };

  return WithProjectPermissions;
};

export default withProjectPermissions;
