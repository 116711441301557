import { reduxForm } from 'redux-form';

import formNames from 'components/form/formNames';
import { validateOnSubmit } from 'components/form/formConfigs';
import AddProjectModalForm from './AddProjectModalForm';

export default reduxForm({
  form: formNames.addNewProject,
  ...validateOnSubmit,
})(AddProjectModalForm);
