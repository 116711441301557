import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { isValidVersion, isValidFileTransferVersion, isPharosOrEtc } from 'utilities';
import { currentCompanyNameSelector, currentCompanyEmailSelector } from 'helpers/selectors';
import { hooks as deviceHooks } from '../../../../utilities/device';
import StyledSixEyeVersionBanner from './SixEyeVersionBanner.style';

const SixEyeVersionBanner = ({ deviceId, online }) => {
  const name = useSelector(currentCompanyNameSelector);
  const email = useSelector(currentCompanyEmailSelector);
  const device = deviceHooks.useDevice({ deviceId, fields: ['reported'] });
  const { reported } = device;

  if (!reported) {
    return null;
  }

  const { libsixeyeVersion } = reported;

  const companyName = name || 'SixEye';
  const beforeReconnect = online ? '' : ' before the device can reconnect';
  let message = '';

  if (!isValidVersion(libsixeyeVersion)) {
    message = `The firmware on this device is no longer compatible with ${companyName} and must be updated${beforeReconnect}.`;
  } else if (isPharosOrEtc(device) && !isValidFileTransferVersion(libsixeyeVersion, device)) {
    message = 'This device\'s firmware is no longer compatible with File Transfer. Please upgrade to Designer firmware 2.7.9 if File Transfer is required.';
  } else {
    return null;
  }

  return (
    <StyledSixEyeVersionBanner>
      <p>
        {message}
        <br />
        {email && 'For more information please contact '}
        {email && <a href={`mailto:${email}`}>{email}</a>}
      </p>
    </StyledSixEyeVersionBanner>
  );
};

SixEyeVersionBanner.propTypes = {
  deviceId: PropTypes.string.isRequired,
  online: PropTypes.bool.isRequired,
};

export default SixEyeVersionBanner;
