import React, { useState, useEffect } from 'react';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { Accordion } from 'semantic-ui-react';

import { AccordionItem } from 'components/structural';
import { getWizardAccordionItems } from './utilities';

const TwoFactorWizard = ({ enabled, submitting, disabling, failed }) => {
  const [active, setActive] = useState();
  useEffect(() => {
    setActive();
  }, [enabled]);
  useEffect(() => {
    const loading = submitting || disabling;
    if (!loading && !failed) {
      setActive();
    }
  }, [submitting, disabling, failed]);

  if (isNull(enabled)) {
    return null;
  }

  const accordionItems = getWizardAccordionItems(enabled);

  return (
    <Accordion>
      {accordionItems.map((itemProps, index) => (
        <AccordionItem
          key={itemProps.title}
          {...itemProps}
          index={index}
          active={active}
          onClick={setActive}
        />
      ))}
    </Accordion>
  );
};

TwoFactorWizard.defaultProps = {
  enabled: null,
};

TwoFactorWizard.propTypes = {
  enabled: PropTypes.bool,
  submitting: PropTypes.bool.isRequired,
  disabling: PropTypes.bool.isRequired,
  failed: PropTypes.bool.isRequired,
};

export default TwoFactorWizard;
