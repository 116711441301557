import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import uuidv4 from 'uuid/v4';

import { complexValueType } from 'data/types';
import formNames from 'components/form/formNames';
import { formFields } from './PharosWatchdogV1Form/PharosWatchdogV1Form';
import PharosWatchdogV1Form from './PharosWatchdogV1Form/PharosWatchdogV1FormContainer';

export const DEVICE_WATCHDOG_REFRESH = uuidv4();

class PharosWatchdogV1 extends Component {

  constructor() {
    super();

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { pollingRefreshing, onReset } = this.props;
    const { pollingRefreshing: prevPollingRefreshing } = prevProps;

    if (prevPollingRefreshing && !pollingRefreshing) {
      onReset(formNames.watchdogV1);
    }
  }

  handleSubmit(values) {
    const { resource: { id }, onToggleWatchdog } = this.props;

    return new Promise((resolveForm, rejectForm) => {
      onToggleWatchdog(id, { values, resolveForm, rejectForm });
    });
  }

  render() {
    const { resource, title, pollingRefreshing } = this.props;
    const { reportedWithMeta } = resource;
    if (!reportedWithMeta) return null;

    const watchdogValue = get(reportedWithMeta, 'hw.wd.value');

    return (
      <PharosWatchdogV1Form
        onSubmit={this.handleSubmit}
        initialValues={{
          [formFields.watchdog]: watchdogValue,
        }}
        title={title}
        resource={resource}
        pollingRefreshing={pollingRefreshing}
      />
    );
  }

}


PharosWatchdogV1.propTypes = {
  resource: PropTypes.shape({
    id: PropTypes.string.isRequired,
    hw: PropTypes.shape({
      wd: complexValueType(PropTypes.bool),
    }),
  }).isRequired,
  title: PropTypes.string.isRequired,
  pollingRefreshing: PropTypes.bool.isRequired,
  onToggleWatchdog: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

export default PharosWatchdogV1;
