import constants from 'dispatcherConst';

export const initialState = {};

const tablesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.STORE_TABLE_NAME: {
      const { resourceType, name } = payload;

      return {
        ...state,
        [resourceType]: name,
      };
    }

    case constants.CHANGE_ROUTE: {
      return {};
    }

    default:
      return state;
  }
};

export default tablesReducer;
