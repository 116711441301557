import { Duration } from 'luxon';
import isNil from 'lodash/isNil';

import { emDashedData, durationFormat } from 'helpers/sharedVariables';

class TimelineModel {

  constructor(data = {}) {
    this.id = data.id;
    this.name = data.name;
    this.status = data.status || emDashedData;
    this.time = data.time;
    this.type = data.type;
    this.length = data.length;
  }

  get visibleStatus() {
    const { status } = this;

    return String(status) || emDashedData;
  }

  get formattedTime() {
    const { time } = this;

    return !isNil(time)
      ? Duration.fromMillis(time * 1000).toFormat(durationFormat)
      : emDashedData;
  }

  get formattedLength() {
    const { length } = this;

    return !isNil(length)
      ? Duration.fromMillis(length * 1000).toFormat(durationFormat)
      : emDashedData;
  }

}

export default TimelineModel;
