import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { deepEqualMemoizeOne } from 'helpers/sharedMethods';
import { permissionsStateSelector } from 'helpers/selectors';

import PermissionsProjectsTab from './PermissionsProjectsTab';

export const makeMapState = () => {
  const memoProjectIds = deepEqualMemoizeOne();

  return function mapStateToProps(state) {
    const { projects, contexts } = permissionsStateSelector(state);

    const nonContextProjects = Object.keys(projects).filter(
      projectId => !(projectId in contexts),
    );

    return {
      projectIds: memoProjectIds(nonContextProjects),
    };
  };
};

export default compose(
  withRouter,
  connect(makeMapState),
)(PermissionsProjectsTab);
