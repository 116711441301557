import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Container, StyledInput } from './TaskName.style';

function TaskName({ taskName, onTaskNameChange }) {
  const handleChange = useCallback(event => {
    onTaskNameChange(event.target.value);
  }, [onTaskNameChange]);

  return (
    <Container basic>
      <StyledInput
        placeholder="Please, type a name for this task"
        value={taskName}
        onChange={handleChange}
        className="task-name-input"
      />
    </Container>
  );
}

TaskName.defaultProps = {
  taskName: '',
};

TaskName.propTypes = {
  taskName: PropTypes.string,
  onTaskNameChange: PropTypes.func.isRequired,
};

export default TaskName;
