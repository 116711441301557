import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';

import constants from 'dispatcherConst';
import { messages } from 'data/notifications/notificationsConst';
import { taskSelector, allActionsTypeSelector } from 'helpers/selectors';
import { getPath, postPath, putPath, deletePath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import TaskModel from 'models/TaskModel';
import ActionModel from 'models/ActionModel';
import { prepareResponseData } from 'helpers/paginationHelpersClient';
import updateIndexes from './updateIndexes';
import tasksActionCreators from './tasksActionCreators';

export function* fetchTasks(action) {
  const {
    endpoint, urlParams, nextInChain,
    stopChain, resourceType, componentName,
  } = action.payload;

  const { response, error } = yield call(getPath, endpoint, { urlParams });
  const responseWithMetadata = error ? undefined : { ...response, componentName };

  const params = {
    response: responseWithMetadata,
    error,
    successDisp: tasksActionCreators.fetchTasksSuccess,
    failDisp: tasksActionCreators.fetchTasksFailure,
    nextInChain,
    stopChain,
    resourceType,

  };
  yield call(processCall, params);

  if (response) {
    const enhancedResponse = yield call(prepareResponseData, responseWithMetadata, urlParams);

    yield put(tasksActionCreators.fetchTasksPaginated(enhancedResponse));
  }
}

export function* addNewTask(action) {
  const {
    endpoint, params: {
      values, rejectForm, resolveForm,
    },
  } = action.payload;
  const body = TaskModel.requestBody(values);

  const { response, error } = yield call(postPath, endpoint, body);

  const params = {
    response,
    error,
    resolveForm,
    rejectForm,
    successDisp: tasksActionCreators.addTaskSuccess,
    failDisp: tasksActionCreators.addTaskFailure,
  };
  yield call(processCall, params);

}

export function* addNewAction(action) {
  const {
    endpoint,
    params: {
      values, resolveForm, rejectForm,
    },
    ...rest
  } = action.payload;

  const { taskId } = values;
  const actionTypes = (yield select(allActionsTypeSelector));
  const preparedAction = ActionModel.formatAction(values, actionTypes);
  const currentTask = (yield select(taskSelector))(taskId);
  const taskCheckFields = new TaskModel(currentTask);
  const taskWithAction = TaskModel.addActionToTask(taskCheckFields, preparedAction);
  const formatTask = TaskModel.requestBody(taskWithAction);
  const finalTask = updateIndexes(formatTask);

  yield put(tasksActionCreators.updateTask({
    id: taskId,
    task: finalTask,
    resolveForm,
    rejectForm,
    message: messages.ACTION_ADDED,
    ...rest,
  }));
}

export function* fetchActionTypes(action) {
  const {
    endpoint, nextInChain,
    stopChain, resourceType, logicalDeviceId,
  } = action.payload;

  const { response, error } = yield call(getPath, endpoint);

  const params = {
    response,
    error,
    successDisp: tasksActionCreators.fetchActionTypesSuccess,
    failDisp: tasksActionCreators.fetchActionTypesFailure,
    nextInChain,
    stopChain,
    resourceType,
    logicalDeviceId,
  };
  yield call(processCall, params);
}

export function* fetchDeviceActionTypesLists(action) {
  const {
    endpoint, nextInChain,
    stopChain, resourceType, logicalDeviceId,
  } = action.payload;

  const { response, error } = yield call(getPath, endpoint);
  const enhancedResponse = { ...response, logicalDeviceId };

  if ((response && response.data === null) || response === undefined) {
    yield put(tasksActionCreators.fetchDeviceActionTypesListsNoData(logicalDeviceId));
  }

  const params = {
    response: enhancedResponse,
    error,
    successDisp: tasksActionCreators.fetchDeviceActionTypesListsSuccess,
    failDisp: tasksActionCreators.fetchDeviceActionTypesListsFailure,
    nextInChain,
    stopChain,
    resourceType,
    logicalDeviceId,
  };
  yield call(processCall, params);
}

export function* removeTask(action) {
  const {
    taskId,
    endpoint,
    lockVersion,
    projectName,
    projectId,
    resourceType,
    resourceId,
    taskProjectId,
  } = action.payload;

  const urlParams = {
    lockVersion,
  };

  const { response, error } = yield call(deletePath, endpoint, {}, { urlParams });

  if (error) {
    const { errors } = error || {};
    const [firstError] = errors || [];

    const { code } = firstError || {};

    if (code === '412') {
      yield put(tasksActionCreators.removeTaskFailureLockVersion());
      return;
    }
  }

  const enhancedResponse = response ? {
    ...response,
    projectId,
    projectName,
    taskId,
    resourceType,
    resourceId,
    taskProjectId,
  } : response;

  const params = {
    response: enhancedResponse,
    error,
    successDisp: tasksActionCreators.removeTaskSuccess,
    failDisp: tasksActionCreators.removeTaskFailure,
  };
  yield call(processCall, params);
}

export function* removeAction(action) {
  const {
    taskId,
    index,
    taskName,
    endpoint,
    ...rest
  } = action.payload;

  const currentTask = (yield select(taskSelector))(taskId);
  const taskCheckFields = new TaskModel(currentTask);
  const formatTask = TaskModel.requestBody(taskCheckFields);

  const taskWithActionRemoved = TaskModel.removeActionFromTask(formatTask, index);
  const finalTask = updateIndexes(taskWithActionRemoved);

  yield put(tasksActionCreators.updateTask({
    id: taskId,
    task: finalTask,
    message: messages.ACTION_REMOVED(taskName),
    ...rest,
  }));
}

export function* editAction(action) {
  const {
    params: {
      values,
      ...rest
    },
  } = action.payload;

  const {
    taskId,
    actionId,
    paramId,
    ...formParam
  } = values;

  const currentTask = (yield select(taskSelector))(taskId);
  const taskCheckFields = new TaskModel(currentTask);
  const clonedTask = cloneDeep(taskCheckFields);
  const {
    actions:
      {
        [actionId]:
          { params: actionParams },
      },
  } = clonedTask;

  actionParams[paramId].value = formParam[Object.keys(formParam)[0]];
  const finalTask = TaskModel.requestBody(clonedTask);

  yield put(tasksActionCreators.updateTask({
    id: taskId,
    task: finalTask,
    message: messages.ACTION_UPDATED,
    ...rest,
  }));
}

export function* updateTask(action) {
  const {
    id,
    task,
    endpoint,
    message,
    resolveForm,
    rejectForm,
    ...rest
  } = action.payload;

  const { response, error } = yield call(putPath, endpoint, task);
  const enhancedResponse = response ? { ...response, message } : response;

  const params = {
    response: enhancedResponse,
    error,
    resolveForm,
    rejectForm,
    successDisp: tasksActionCreators.updateTaskSuccess,
    failDisp: tasksActionCreators.updateTaskFailure,
    ...rest,
  };
  yield call(processCall, params);
}

export function* triggerTask(action) {
  const { endpoint } = action.payload;
  const { response, error } = yield call(postPath, endpoint);

  const params = {
    response,
    error,
    successDisp: tasksActionCreators.triggerTaskSuccess,
    failDisp: tasksActionCreators.triggerTaskFailure,
  };
  yield call(processCall, params);
}

export function* triggerMultipleTasks(action) {
  const { endpoints } = action.payload;

  const tasksCalls = endpoints.map(endpoint => call(postPath, endpoint));

  const [result] = yield all(tasksCalls);

  const { response, error } = result;

  const params = {
    response,
    error,
    successDisp: tasksActionCreators.triggerMultipleTasksSuccess,
    failDisp: tasksActionCreators.triggerMultipleTasksFailure,
  };

  yield call(processCall, params);
}

export function* editTask(action) {
  const {
    id,
    name,
    description,
    lockVersion,
    ...rest
  } = action.payload;

  const currentTask = (yield select(taskSelector))(id);
  const updatedTask = {
    ...currentTask,
    name,
    description,
    lockVersion,
  };
  const taskCheckFields = new TaskModel(updatedTask);
  const formattedTask = TaskModel.requestBody(taskCheckFields);

  yield put(tasksActionCreators.updateTask({
    id,
    task: formattedTask,
    message: messages.ACTION_UPDATED,
    ...rest,
  }));
}

export function* updateTaskProperty(action) {
  const {
    id,
    property,
    values,
    ...rest
  } = action.payload;
  const { [property]: propertyValue } = values;

  const currentTask = (yield select(taskSelector))(id);
  const updatedTask = {
    ...currentTask,
    [property]: propertyValue,
  };
  const taskCheckFields = new TaskModel(updatedTask);
  const formattedTask = TaskModel.requestBody(taskCheckFields);

  yield put(tasksActionCreators.updateTask({
    id,
    task: formattedTask,
    message: messages.TASK_UPDATED,
    ...rest,
  }));
}

export function* updateActionProperty(action) {
  const {
    id,
    taskId,
    property,
    values,
    ...rest
  } = action.payload;
  const { [property]: propertyValue } = values;

  const currentTask = (yield select(taskSelector))(taskId);
  const taskCheckFields = new TaskModel(currentTask);
  const clonedTask = cloneDeep(taskCheckFields);
  const {
    actions:
      {
        [id]: actionToUpdate,
      },
  } = clonedTask;

  actionToUpdate[property] = propertyValue;
  const finalTask = TaskModel.requestBody(clonedTask);

  yield put(tasksActionCreators.updateTask({
    id: taskId,
    task: finalTask,
    message: messages.TASK_UPDATED,
    ...rest,
  }));
}

export function* fetchSiteActionTypesList(action) {
  const {
    endpoint, nextInChain,
    stopChain, resourceType, projectId,
  } = action.payload;

  const { response, error } = yield call(getPath, endpoint);
  const enhancedResponse = { ...response, projectId };

  const params = {
    response: enhancedResponse,
    error,
    successDisp: tasksActionCreators.fetchSiteActionTypesListSuccess,
    failDisp: tasksActionCreators.fetchSiteActionTypesListFailure,
    nextInChain,
    stopChain,
    resourceType,
    projectId,
  };
  yield call(processCall, params);
}

export function* executeActions(action) {
  const {
    resourceId,
    resourceType,
    actions,
    endpoint,
  } = action.payload;

  const body = {
    data: {
      attributes: {
        projectId: null,
        projectSuiteId: null,
        actions,
      },
    },
  };

  if (resourceType === 'project') {
    body.data.attributes.projectId = resourceId;
  } else if (resourceType === 'projectSuite') {
    body.data.attributes.projectSuiteId = resourceId;
  }

  const { response, error } = yield call(postPath, endpoint, body);

  const params = {
    response,
    error,
    successDisp: tasksActionCreators.executeActionsSuccess,
    failDisp: tasksActionCreators.executeActionsFailure,
  };

  yield call(processCall, params);
}

function* tasksSaga() {
  yield takeLatest(constants.FETCH_TASKS_REQUEST, fetchTasks);
  yield takeLatest(constants.FETCH_ACTION_TYPES_REQUEST, fetchActionTypes);
  yield takeLatest(constants.FETCH_DEVICE_ACTION_TYPES_LISTS_REQUEST, fetchDeviceActionTypesLists);
  yield takeLatest(constants.ADD_TASK_REQUEST, addNewTask);
  yield takeLatest(constants.ADD_ACTION_REQUEST, addNewAction);
  yield takeLatest(constants.REMOVE_TASK_REQUEST, removeTask);
  yield takeLatest(constants.REMOVE_ACTION_REQUEST, removeAction);
  yield takeLatest(constants.EDIT_ACTION_REQUEST, editAction);
  yield takeLatest(constants.UPDATE_TASK_REQUEST, updateTask);
  yield takeLatest(constants.TRIGGER_TASK_REQUEST, triggerTask);
  yield takeLatest(constants.TRIGGER_MULTIPLE_TASKS_REQUEST, triggerMultipleTasks);
  yield takeLatest(constants.EDIT_TASK_REQUEST, editTask);
  yield takeLatest(constants.UPDATE_TASK_PROPERTY_REQUEST, updateTaskProperty);
  yield takeLatest(constants.UPDATE_ACTION_PROPERTY_REQUEST, updateActionProperty);
  yield takeLatest(constants.FETCH_SITE_ACTION_TYPES_LIST_REQUEST, fetchSiteActionTypesList);
  yield takeLatest(constants.EXECUTE_ACTIONS_REQUEST, executeActions);
}

export default tasksSaga;
