import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { PROJECT_PROJECT_SUITE_PARENTS_FIELD } from 'helpers/selectors';

export const fetchProjectProjectSuiteParents = (projectId, urlParams = {}) => ({
  type: constants.FETCH_PROJECT_PROJECT_SUITE_PARENTS_REQUEST,
  payload: {
    projectId,
    endpoint: endpoints.PROJECT_PROJECT_SUITES(projectId),
    urlParams,
  },
});

export const fetchProjectProjectSuiteParentsSuccess = response => ({
  type: constants.FETCH_PROJECT_PROJECT_SUITE_PARENTS_SUCCESS,
  payload: {
    response,
    resourceType: PROJECT_PROJECT_SUITE_PARENTS_FIELD,
  },
});

export const fetchProjectProjectSuiteParentsFailure = error => ({
  type: constants.FETCH_PROJECT_PROJECT_SUITE_PARENTS_FAILED,
  payload: { error },
});

export default fetchProjectProjectSuiteParents;
