import React from 'react';
import PropTypes from 'prop-types';
import { fieldPropTypes } from 'redux-form';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { DateFieldWrapper } from './DateField.style';

const DateField = ({ input: { value, onChange }, format, ...rest }) => (
  <DateFieldWrapper>
    <DateTimePicker
      onChange={onChange}
      value={value ? new Date(value) : null}
      format={format}
      time={false}
      inputProps={{ readOnly: true }}
      {...rest}
    />
  </DateFieldWrapper>
);

DateField.defaultProps = {
  format: 'DD/MM/YYYY',
};

DateField.propTypes = {
  ...fieldPropTypes,
  format: PropTypes.string,
};

export default DateField;
