import React from 'react';
import { Input } from 'semantic-ui-react';

import SelectField from '../SelectField/SelectField';
import FormField from '../FormField/FormField';

const SelectFieldInput = props => (
  <FormField {...props}>
    <SelectField {...props} />
  </FormField>
);

SelectFieldInput.propTypes = {
  ...Input.propTypes,
};

export default SelectFieldInput;
