/* eslint-disable no-param-reassign */
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import words from 'lodash/words';

import {
  cleanUpLabel,
  applyVocabulary,
  modifySelfWithResourceName,
} from 'helpers/sharedMethods';

import {
  pathsByUniqueTopLevel,
} from 'application/tenant/console/utilities/permissions/permissionsHelpers';

export const RESOURCE_TYPE_LABELS = {
  project: 'Site',
  project_suite: 'Multi-site',
  logical_device: 'Device',
  tenant: 'Company',
};

const isTopLevelPermission = index => index === 0;

const getTokensFromPermissionName = (permissionName, resourceType) => {
  let formattedPermissionName = permissionName.replace(`${resourceType}:`, '');

  formattedPermissionName = formattedPermissionName.replaceAll(':', '.');

  return formattedPermissionName.split('.');
};

const sortPermissionNames = (cumulatedPermissions, resourceType) => {
  const permissions = Object.keys(cumulatedPermissions).map(name => {
    const cleanName = name.replace(`${resourceType}:`, '');

    return {
      original_name: name,
      short_name: cleanName,
      tokens: cleanName.split(':'),
    };
  });

  const formattedPaths = pathsByUniqueTopLevel({ permissions });

  const another = formattedPaths.map(item => {
    const translatedTokens = item.tokens.map(token => {
      const translated = cleanUpLabel(applyVocabulary(token));

      // Adds a whitespace for tokens that have only one word, when sorting it will come first.
      return words(translated).length === 1 ? `${translated} ` : translated;
    });

    return {
      original_name: `${resourceType}:${item.original.join(':')}`,
      translated_name: translatedTokens.join(':'),
    };
  });

  return sortBy(another, 'translated_name').map(item => item.original_name);
};

const getTopLevelPermissionRow = ({
  permissionNames,
  resourceType,
  tokens,
  token,
  index,
  resourceName,
  resourceTypeLabel,
}) => {
  const childPermissions = permissionNames.filter(name => name.startsWith(`${resourceType}:${token}`));
  const hasChild = !isEmpty(childPermissions) && childPermissions.length > 1;

  let correctToken = token;

  if (!hasChild) {
    correctToken = tokens.join('_');
  }

  const row = {
    key: correctToken,
    name: modifySelfWithResourceName(correctToken, resourceName, resourceTypeLabel),
    relatedPermissions: childPermissions,
    level: index,
    parentName: null,
    hasChild,
  };

  return row;
};

const transformToTableRows = ({
  resourceType,
  resourceName,
  cumulatedPermissions,
}) => {
  const resourceTypeLabel = !resourceName ? 'Self' : RESOURCE_TYPE_LABELS[resourceType];
  const tableRows = [];

  const tokenAlreadyIncluded = token => tableRows.find(row => row.key === token);

  const permissionNames = sortPermissionNames(cumulatedPermissions, resourceType);

  permissionNames.forEach(permissionName => {
    const tokens = getTokensFromPermissionName(permissionName, resourceType);

    tokens.forEach((token, index) => {
      if (isTopLevelPermission(index)) {
        const row = getTopLevelPermissionRow({
          permissionNames,
          resourceType,
          tokens,
          token,
          index,
          resourceName,
          resourceTypeLabel,
        });

        if (!tokenAlreadyIncluded(token)) {
          tableRows.push(row);
        }
      } else {
        const currentAndParentToken = tokens.slice(0, index + 1).join(':');
        const childPermissions = permissionNames.filter(key => key.startsWith(`${resourceType}:${currentAndParentToken}`));
        const hasChild = !isEmpty(childPermissions) && childPermissions.length > 1;
        const remainingTokens = tokens.slice(index + 1, tokens.length);

        const parentName = tokens.slice(0, index).join(':');
        const correctToken = `${parentName}:${token}`;

        let displayToken = token;

        if (!hasChild && remainingTokens.length === 1) {
          const [lastToken] = remainingTokens;

          displayToken = `${token}_${lastToken}`;
        }

        if (!tokenAlreadyIncluded(correctToken)) {
          tableRows.push({
            key: correctToken,
            name: modifySelfWithResourceName(displayToken, resourceName, resourceTypeLabel),
            relatedPermissions: childPermissions,
            level: index,
            parentName,
            hasChild,
          });
        }
      }
    });
  });

  return tableRows;
};

export default transformToTableRows;
