import constants from 'dispatcherConst';

export const copyToClipboard = () => ({
  type: constants.COPY_ACTIVITY_LOGS_TO_CLIPBOARD,
  payload: {
    message: 'The logs were copied to clipboard',
  },
});

export default copyToClipboard;
