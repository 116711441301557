import React from 'react';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { TASKS_FIELD } from 'helpers/selectors';
import DataTable from 'components/DataTable/DataTableContainer';
import {
  AddTask,
  TaskAccordionRow,
  TaskActionLinks,
  TaskActionLinksProjectSuites,
  ProjectSuiteContext,
} from './components';

const labels = [
  { text: 'Name', field: 'name' },
  { text: 'Description', field: 'description', sortable: false },
];

const ListOfTasks = ({ superSites }) => (
  <Grid>
    <Grid.Row>
      <Grid.Column>
        <ProjectSuiteContext.Provider value={superSites}>
          <DataTable
            header="Tasks"
            labels={labels}
            customTableRow={TaskAccordionRow}
            createElement={AddTask}
            actionLinks={superSites ? TaskActionLinksProjectSuites : TaskActionLinks}
            pagination={{
              type: TASKS_FIELD,
              included: true,
              args: {
                withFilter: true,
              },
            }}
          />
        </ProjectSuiteContext.Provider>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

ListOfTasks.defaultProps = {
  superSites: false,
};

ListOfTasks.propTypes = {
  superSites: PropTypes.bool,
};

export default ListOfTasks;
