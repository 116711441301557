import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

const projectFileUploadActionCreators = {
  fileNotFoundResume: ({ fileKey, params }) => ({
    type: constants.UPLOAD_PROJECT_FILE_NOT_FOUND_RESUME,
    payload: { fileKey, params },
  }),

  prepareUpload: ({ projectId, params, resourceType = 'project' }) => ({
    type: constants.UPLOAD_PROJECT_PREPARE_REQUEST,
    payload: {
      projectId,
      params,
      endpoint: resourceType === 'project' ? endpoints.PROJECT_FILE_UPLOADS(projectId) : endpoints.PROJECT_SUITE_FILE_UPLOADS(projectId),
      resourceType,
    },
  }),

  prepareUploadSuccess: response => ({
    type: constants.UPLOAD_PROJECT_PREPARE_SUCCESS,
    payload: { response },
  }),
};

export default projectFileUploadActionCreators;
