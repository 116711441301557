import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { PROJECT_SUITES_FIELD } from 'helpers/selectors';

export const fetchProjectSuites = (userId, urlParams) => ({
  type: constants.FETCH_PROJECT_SUITES_REQUEST,
  payload: {
    userId,
    endpoint: endpoints.USER_PROJECT_SUITES(userId),
    resourceType: PROJECT_SUITES_FIELD,
    urlParams,
  },
});

export const fetchAdminProjectSuites = urlParams => ({
  type: constants.FETCH_PROJECT_SUITES_REQUEST,
  payload: {
    endpoint: endpoints.PROJECT_SUITES,
    resourceType: PROJECT_SUITES_FIELD,
    urlParams,
  },
});

export const fetchProjectSuitesSuccess = response => ({
  type: constants.FETCH_PROJECT_SUITES_SUCCESS,
  payload: {
    response,
    resourceType: PROJECT_SUITES_FIELD,
  },
});

export const fetchProjectSuitesFailure = error => ({
  type: constants.FETCH_PROJECT_SUITES_FAILED,
  payload: { error },
});

export default fetchProjectSuites;
