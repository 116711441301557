import { put, select } from 'redux-saga/effects';

import { actionCreators as buttonsActionCreators } from 'application/tenant/components/structural/ControlUI/components/ControlUIPageCarousel/components/ControlUIPage/components/ControlUIButtonGroup/ControlUIButton/components/utilities';
import selectors from '../selectors';
import actionCreators from '../actionCreators';

function* deletePage(action) {
  const { pageId, projectId, ...rest } = action.payload;

  const { pages, ...attributes } = (yield select(selectors.projectControlUISelector))(projectId);
  const pagesWithoutPage = pages.filter(({ index }) => index !== pageId);
  const pagesWithUpdatedIndexes = pagesWithoutPage.map(({ index, ...page }, newIndex) => ({
    index: newIndex,
    ...page,
  }));

  if (Array.isArray(pagesWithUpdatedIndexes) && pagesWithUpdatedIndexes.length === 0) {
    yield put(buttonsActionCreators.clearCopy());
  }

  yield put(actionCreators.updateControlUI({
    pages: pagesWithUpdatedIndexes,
    ...attributes,
    ...rest,
  }));
}

export default deletePage;
