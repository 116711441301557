import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const pasteMultiplePermissions = (data = {}) => ({
  type: constants.PASTE_MULTIPLE_PERMISSIONS_REQUEST,
  payload: {
    endpoint: endpoints.SET_PERMISSIONS,
    ...data,
  },
});

export const pasteMultiplePermissionsSuccess = response => ({
  type: constants.PASTE_MULTIPLE_PERMISSIONS_SUCCESS,
  payload: {
    response,
  },
});

export const pasteMultiplePermissionsFailure = error => ({
  type: constants.PASTE_MULTIPLE_PERMISSIONS_FAILED,
  payload: {
    error,
  },
});

export default pasteMultiplePermissions;
