import isNil from 'lodash/isNull';

const deviceTimeWithoutOffset = (timeWithOffset, offset) => {
  const secondsInDay = 86400;
  const offsetValue = Math.abs(offset) * 60 * 60;

  if (isNil(timeWithOffset)) {
    return null;
  }

  if (offset < 0) {
    return (timeWithOffset + offsetValue) % secondsInDay;
  }

  if (offset > 0) {
    const newTime = timeWithOffset - offsetValue;
    if (newTime < 0) {
      return secondsInDay - Math.abs(newTime);
    }

    return newTime;
  }

  return timeWithOffset;
};

export default deviceTimeWithoutOffset;
