import React from 'react';
import { Loader } from 'semantic-ui-react';

import { StyledDiv } from './DevicesTablePlaceholder.style';

const DevicesTablePlaceholder = () => (
  <StyledDiv>
    <Loader active inline="centered" />
  </StyledDiv>
);

export default DevicesTablePlaceholder;
