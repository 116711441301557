import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { permissionsOrRedirect, frontendPermissions } from 'gateway';
import { selectors, actionCreators } from '../../../../../../utilities';
import PageNameColorPicker from './PageNameColorPicker';

export const mapStateToProps = (state, { match, pageId }) => {
  const { id: projectId } = match.params;
  const { pages = [] } = selectors.projectControlUISelector(state)(projectId) || {};
  const { nameColor } = pages.filter(({ index }) => index === pageId)[0] || {};

  return {
    projectId,
    nameColor,
  };
};

const mapDispatchToProps = {
  onChangeColor: actionCreators.setPageNameColor,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  permissionsOrRedirect(frontendPermissions.EDIT_CONTROL_PANEL),
)(PageNameColorPicker);
