import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormGroup, Grid, Form } from 'semantic-ui-react';

import { validAlphaNumericAndSpace, validatePaginationIndex } from 'components/form/formValidators';
import ModalForm from 'components/form/ModalForm/ModalForm';
import TextField from 'components/form/TextField/TextField';
import { PageSelectionButtons } from '../DuplicatePage/components/DuplicatePageConfirmation/components';

import { PageTypeSelect } from './components';
import { StyledLabel } from './AddPage.style';

export const addPageFormFields = {
  name: 'name',
  paginationIndex: 'paginationIndex',
};

const AddPage = ({
  total,
  onChangePaginationIndex,
  onChangePageType,
  pageType,
  pageIndex,
  trigger,
  ...rest
}) => {
  const updatePageIndex = value => {
    onChangePaginationIndex(value);
  };

  const paginationValidation = useCallback(validatePaginationIndex(total), [total]);

  return (
    <ModalForm
      trigger={trigger}
      header="Add a control panel page"
      size="tiny"
      {...rest}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} computer={16}>
            <FormGroup>
              <Field
                name={addPageFormFields.name}
                component={TextField}
                label="Page title"
                placeholder="Set a title for the new page"
                validate={validAlphaNumericAndSpace}
              />
            </FormGroup>
          </Grid.Column>
          <Grid.Column mobile={16} computer={16}>
            <StyledLabel>Page type</StyledLabel>
            <PageTypeSelect
              value={pageType}
              onChange={newPageType => onChangePageType(newPageType)}
            />
          </Grid.Column>
          <Grid.Column mobile={16} computer={16}>
            <FormGroup>
              <Field
                name={addPageFormFields.paginationIndex}
                component={TextField}
                label="Insert at page number:"
                type="number"
                value={total}
                validate={paginationValidation}
              />
            </FormGroup>
            <FormGroup>
              <Form.Field>
                <PageSelectionButtons
                  handleChange={updatePageIndex}
                  totalPages={total}
                  pageIndex={pageIndex}
                />
              </Form.Field>
            </FormGroup>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ModalForm>
  );
};

AddPage.defaultProps = {
  total: 0,
  pageIndex: 0,
  pageType: 'default',
};

AddPage.propTypes = {
  pageIndex: PropTypes.number,
  pageType: PropTypes.string,
  total: PropTypes.number,
  onChangePaginationIndex: PropTypes.func.isRequired,
  onChangePageType: PropTypes.func.isRequired,
  trigger: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
};

export default AddPage;
