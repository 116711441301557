import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import { LinkedFilesRow } from './components';

const LinkedFiles = ({ files }) => (
  <Table celled>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>File name</Table.HeaderCell>
        <Table.HeaderCell>Description</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {files.map(file => (
        <LinkedFilesRow
          key={file.id}
          firmwareFileId={file.id}
        />
      ))}
    </Table.Body>
  </Table>
);

LinkedFiles.propTypes = {
  files: PropTypes.array.isRequired,
};

export default LinkedFiles;
