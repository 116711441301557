import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import ProjectSuitePermissionsTable from 'components/PermissionsTable/PermissionsTableProjectSuiteContainer';

const ProjectSuitePermissionsTab = ({ projectSuiteId }) => (
  <Grid>
    <Grid.Row>
      <Grid.Column>
        <ProjectSuitePermissionsTable projectSuiteId={projectSuiteId} isOpen />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

ProjectSuitePermissionsTab.propTypes = {
  projectSuiteId: PropTypes.string.isRequired,
};

export default ProjectSuitePermissionsTab;
