import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import ThemedToggle from 'components/elements/ThemedToggle/ThemedToggle.style';

function NotificationToggle({
  entityId,
  userId,
  status,
  context,
  notification,
  notificationTypeId,
  frequency,
  optInNotification,
  onEditNotification,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleEditNotification = useCallback(async () => new Promise(
    (resolveForm, rejectForm) => {
      const params = {
        context,
        notificationTypeId,
        userId,
        entityId,
        id: status,
        notification,
        frequency,
        resolveForm,
        rejectForm,
      };

      if (!notification) {
        optInNotification(params);
      } else {
        onEditNotification(params);
      }
    }), [
    context,
    entityId,
    frequency,
    notification,
    notificationTypeId,
    status,
    userId,
    onEditNotification,
    optInNotification,
  ]);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);

    await handleEditNotification();

    setIsLoading(false);
  }, [handleEditNotification]);

  return (
    <ThemedToggle
      name={`toggle.${notificationTypeId}.${entityId}.${frequency}`}
      checked={notification ? notification[frequency] : false}
      disabled={isLoading}
      onChange={handleSubmit}
    />
  );
}

NotificationToggle.defaultProps = {
  status: null,
  notification: null,
  frequency: 'instant',
};

NotificationToggle.propTypes = {
  optInNotification: PropTypes.func.isRequired,
  entityId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  status: PropTypes.string,
  context: PropTypes.string.isRequired,
  notificationTypeId: PropTypes.string.isRequired,
  notification: PropTypes.object,
  frequency: PropTypes.oneOf(['instant', 'daily', 'weekly']),
  onEditNotification: PropTypes.func.isRequired,
};

export default NotificationToggle;
