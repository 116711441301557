import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import CompanyModel from 'models/CompanyModel';

import { StyledRow } from './CompanyRow.style';

const CompanyRow = ({
  item: company,
  item: { id },
  selectedCompanyIds,
  onCompanySelected,
  ...rest
}) => {
  const handleCompanySelected = useCallback(() => {
    onCompanySelected(id);
  }, [id, onCompanySelected]);

  const companyModel = new CompanyModel(company);

  return (
    <StyledRow
      item={companyModel}
      {...rest}
      onClick={handleCompanySelected}
      highlighted={selectedCompanyIds.includes(id)}
    />
  );
};

CompanyRow.propTypes = {
  onCompanySelected: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  selectedCompanyIds: PropTypes.array.isRequired,
};

export default CompanyRow;
