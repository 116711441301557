export default class DeviceTestRunnerCreator {

  constructor(sixeyeFirmwareVersion, tabs) {
    this.model = 'Device Test Runner';
    this.sixeyeFirmwareVersion = sixeyeFirmwareVersion;
    this.manufacturerSDK = 'pharos';
    this.tabs = tabs;
  }

}
