import { connect } from 'react-redux';

import { selectors } from '../../../../../utilities';
import EndingCriteria from './EndingCriteria';

export const mapStateToProps = state => ({
  recurrenceErrors: selectors.recurrenceErrorsSelector(state),
  existingSchedule: selectors.existingScheduleSelector(state),
});

export default connect(mapStateToProps, null)(EndingCriteria);
