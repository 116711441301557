import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Modal } from 'semantic-ui-react';

import { Button } from 'components/atomic';
import { InvitationList } from './components';

const ReceivedInvitationsModal = ({
  invitationsSuppressed,
  currentUserId,
  invitations,
  onDeleteInvitation,
  onSuppressInvitations,
}) => {
  const [open, setOpen] = useState(true);

  if (invitationsSuppressed || !currentUserId || !invitations || !invitations.length) {
    return null;
  }

  const handleClose = () => setOpen(false);
  const handleAcceptAll = () => {
    handleClose();

    invitations.forEach(({ id }) => onDeleteInvitation(id));
  };
  const handleSuppressInvitations = () => {
    handleClose();
    onSuppressInvitations();
  };

  return (
    <Modal
      open={open}
      onClose={handleSuppressInvitations}
    >
      <Modal.Header>Received invitations</Modal.Header>
      <Modal.Content>
        <InvitationList invitations={invitations} />
      </Modal.Content>
      <Modal.Actions>
        <Button
          name="invitations-accept-all"
          color="green"
          inverted
          onClick={handleAcceptAll}
        >
          <Icon name="check" /> Accept all
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ReceivedInvitationsModal.defaultProps = {
  currentUserId: null,
  invitations: null,
};

ReceivedInvitationsModal.propTypes = {
  invitationsSuppressed: PropTypes.bool.isRequired,
  currentUserId: PropTypes.string,
  invitations: PropTypes.array,
  onDeleteInvitation: PropTypes.func.isRequired,
  onSuppressInvitations: PropTypes.func.isRequired,
};

export default ReceivedInvitationsModal;
