import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import {
  AUTOMATED_OPERATIONS_FIELD,
} from 'helpers/selectors';

export const removeAutomatedOperation = (id, lockVersion) => ({
  type: constants.REMOVE_AUTOMATED_OPERATION_REQUEST,
  payload: {
    id,
    lockVersion,
    endpoint: endpoints.UPDATE_AUTOMATED_OPERATION(id),
  },
});

export const removeAutomatedOperationSuccess = response => ({
  type: constants.REMOVE_AUTOMATED_OPERATION_SUCCESS,
  payload: {
    response,
    message: messages.AUTOMATED_OPERATION_REMOVED,
    resourceType: AUTOMATED_OPERATIONS_FIELD,
  },
});

export const removeAutomatedOperationFailure = error => ({
  type: constants.REMOVE_AUTOMATED_OPERATION_FAILED,
  payload: { error },
});
