import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormGroup, Grid } from 'semantic-ui-react';

import { validHostname } from 'components/form/formValidators';
import ModalForm from 'components/form/ModalForm/ModalForm';
import TextField from 'components/form/TextField/TextField';
import CheckboxField from 'components/form/CheckboxField/CheckboxField';
import { originFormFields } from '../../../../constants';

const AddOriginModalForm = props => {
  const { submitting } = props;

  return (
    <ModalForm
      header="Create origin"
      trigger="Create origin"
      size="mini"
      {...props}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16}>
            <FormGroup inline>
              <Field
                name={originFormFields.name}
                component={TextField}
                type="text"
                loading={submitting}
                label="Name"
                validate={validHostname}
              />
            </FormGroup>
          </Grid.Column>
          <Grid.Column mobile={16}>
            <FormGroup inline>
              <Field
                name={originFormFields.url}
                component={CheckboxField}
                type="checkbox"
                loading={submitting}
                label="Mark as URL"
                justify="end"
              />
            </FormGroup>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ModalForm>
  );
};

AddOriginModalForm.propTypes = {
  submitting: PropTypes.bool.isRequired,
};

export default AddOriginModalForm;
