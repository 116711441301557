import isNull from 'lodash/isNull';

const convertTime = value => {
  if (!isNull(value)) {
    const totalSec = Number(value);
    const h = Math.floor(totalSec / 3600);
    const m = Math.floor((totalSec % 3600) / 60);
    const s = Math.floor(totalSec % 3600 % 60);
    const hours = h < 10 ? `0${h}` : h;
    const minutes = m < 10 ? `0${m}` : m;
    const seconds = s < 10 ? `0${s}` : s;

    return `${hours}:${minutes}:${seconds}`;
  }

  return null;
};

export default convertTime;
