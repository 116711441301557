import { takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import refreshOTPSecret from './refreshOTPSecret';

function* saga() {
  yield takeLatest(constants.REFRESH_OTP_SECRET, refreshOTPSecret);
}

export default saga;
