import { compose } from 'redux';
import { connect } from 'react-redux';
import includes from 'lodash/includes';

import {
  PROJECTS_FIELD,
  permissionsProjectActiveUserIdsSelector,
} from 'helpers/selectors';
import { permissionsActionCreators } from '../../../../../../../utilities';
import UserTableRow from './UserTableRow';

export const mapStateToProps = (state, { item: { id: userId }, resourceId }) => {
  const userIds = permissionsProjectActiveUserIdsSelector(state, { projectId: resourceId });
  const highlighted = includes(userIds, userId);

  return {
    highlighted,
  };
};

export const mapDispatchToProps = {
  onAddUserId: (resourceId, userId) => permissionsActionCreators.resourceAddUserId(
    PROJECTS_FIELD,
    resourceId,
    userId,
  ),
  onRemoveUserId: (resourceId, userId) => permissionsActionCreators.resourceRemoveUserId(
    PROJECTS_FIELD,
    resourceId,
    userId,
  ),
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(UserTableRow);
