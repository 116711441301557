import styled, { css } from 'styled-components';

import { mediaMax, portrait } from 'themes/mixins';

export const StyledControlUIButtonGroup = styled.div`
  display: flex;
  flex-basis: calc(25% - 6vh);
  max-width: calc(25% - 6vh);
  flex-direction: column;
  margin-right: 8vh;

  ${({ configure }) => configure && css`
    ${mediaMax.sm`
      max-width: 100%;
      max-height: calc(25% - 6vh);
      flex-direction: row-reverse;
      margin-right: 0;
    `}
  `}

  ${({ configure }) => !configure && css`
    ${portrait`
      max-width: 100%;
      max-height: calc(25% - 6vh);
      flex-direction: row-reverse;
      margin-right: 0;
    `}
  `}

  &:last-child {
    margin-right: 0;
  }
`;

export default StyledControlUIButtonGroup;
