import isEmpty from 'lodash/isEmpty';

const formatFormValues = (formValues, actionType, actionIndex) => {
  if (isEmpty(formValues) || isEmpty(actionType)) {
    return null;
  }

  return Object.entries(formValues)
    .filter(([key]) => key.includes(actionType) && key.endsWith(String(actionIndex)))
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key.split('_')[0]]: value,
      }), {},
    );
};

export default formatFormValues;
