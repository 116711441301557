import { createSelector } from 'reselect';
import build from 'redux-object';
import memoize from 'lodash/memoize';

import {
  options,
  PROJECT_FILE_UPLOAD_FIELD,
  dataSelector,
} from 'helpers/selectors';

export const resourceUploadsSelector = createSelector(
  dataSelector,
  data => build(data, PROJECT_FILE_UPLOAD_FIELD, null, options),
);

export const resourceUploadSelector = createSelector(
  dataSelector,
  data => memoize(
    fileIdentifier => build(data, PROJECT_FILE_UPLOAD_FIELD, fileIdentifier || '', options),
  ),
);
