import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { USERS_FIELD } from 'helpers/selectors';

export const fetchProjectSuiteUsers = (projectSuiteId, urlParams = {}) => ({
  type: constants.FETCH_PROJECT_SUITE_USERS_REQUEST,
  payload: {
    projectSuiteId,
    endpoint: endpoints.PROJECT_SUITE_USERS(projectSuiteId),
    urlParams,
  },
});

export const fetchProjectSuiteUsersSuccess = response => ({
  type: constants.FETCH_PROJECT_SUITE_USERS_SUCCESS,
  payload: {
    response,
    resourceType: USERS_FIELD,
  },
});

export const fetchProjectSuiteUsersFailure = error => ({
  type: constants.FETCH_PROJECT_SUITE_USERS_FAILED,
  payload: { error },
});

export default fetchProjectSuiteUsers;
