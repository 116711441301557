import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';

import { layoutPanesType } from 'data/types';
import NavLink from 'components/NavLink/NavLinkContainer';
// eslint-disable-next-line import/no-named-as-default
import TabbedLayoutScrollableMenu from '../TabbedLayoutScrollableMenu/TabbedLayoutScrollableMenu';

class TabbedLayoutMenu extends Component {

  static countOutsideRect(parent, child) {
    const childRect = child.getBoundingClientRect();
    const parentRect = parent.getBoundingClientRect();

    const rightViolation = childRect.right - parentRect.right;
    if (rightViolation > 0) return rightViolation;

    const leftViolation = childRect.left - parentRect.left;
    if (leftViolation < 0) return leftViolation;

    return 0;
  }

  constructor() {
    super();

    this.wrapperRef = createRef();

    this.handleClick = this.handleClick.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    this.scrollToActiveItem();
  }

  handleClick(e) {
    const { scrollTo } = this.props;

    const child = e.target;
    const parent = this.wrapperRef.current;

    const difference = TabbedLayoutMenu.countOutsideRect(parent, child);
    scrollTo(parent.scrollLeft + difference);
  }

  handleScroll() {
    const { onScroll } = this.props;

    onScroll(this.wrapperRef.current.scrollLeft);
  }

  scrollToActiveItem() {
    const { current } = this.wrapperRef;
    if (!current) return;

    const items = current.querySelectorAll('.item.active');
    if (items.length !== 1) return;

    const activeItem = items[0];
    this.handleClick({ target: activeItem });
  }

  renderItems() {
    const { panes, match: { url: matchUrl } } = this.props;

    return panes.map(({ menuItem, path }) => (
      <Menu.Item
        key={path}
        as={NavLink}
        to={`${matchUrl}${path}`}
        onClick={this.handleClick}
      >
        {menuItem}
      </Menu.Item>
    ));
  }

  render() {
    const { menu, scrollPosition } = this.props;

    return (
      <TabbedLayoutScrollableMenu
        ref={this.wrapperRef}
        scrollLeft={scrollPosition}
        onScroll={this.handleScroll}
      >
        <Menu {...menu}>
          {this.renderItems()}
        </Menu>
      </TabbedLayoutScrollableMenu>
    );
  }

}

TabbedLayoutMenu.defaultProps = {
  menu: {},
  panes: [],
};

TabbedLayoutMenu.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  menu: PropTypes.object,
  panes: layoutPanesType,
  scrollPosition: PropTypes.number.isRequired,
  onScroll: PropTypes.func.isRequired,
  scrollTo: PropTypes.func.isRequired,
};

export default TabbedLayoutMenu;
