import {
  OCCURRENCE_TYPE_FROM_NUMBER,
  OCCURRENCE_TYPE_OPTIONS,
} from '../../../../ScheduleStep/components/RecurrenceViews/constants';

const getOccurrenceTypeText = type => {
  if (!type) {
    return '';
  }

  const occurrenceType = OCCURRENCE_TYPE_FROM_NUMBER[type];
  const { text } = OCCURRENCE_TYPE_OPTIONS.find(
    item => item.key === occurrenceType,
  );

  return text;
};

export default getOccurrenceTypeText;
