import React from 'react';
import PropTypes from 'prop-types';

import InlineEdit from 'components/form/InlineEdit/InlineEditContainer';
import { StyledAttributeDescription } from './AttributeDescription.style';

const AttributeDescription = ({ id, isEditable, onEditProfile, name, value, ...rest }) => {
  if (!isEditable) {
    return (
      <StyledAttributeDescription>
        {value}
      </StyledAttributeDescription>
    );
  }

  const handleEditProfile = async attributes => new Promise((resolveForm, rejectForm) => (
    onEditProfile({ attributes, resolveForm, rejectForm })
  ));

  return (
    <InlineEdit
      form={name}
      name={name}
      onSubmit={handleEditProfile}
      value={value}
      {...rest}
    />
  );
};

AttributeDescription.defaultProps = {
  isEditable: true,
  value: null,
};

AttributeDescription.propTypes = {
  id: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  onEditProfile: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default AttributeDescription;
