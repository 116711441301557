import React from 'react';
import PropTypes from 'prop-types';

import DropdownTable from '../DropdownTable/DropdownTable';
import { renderValue } from '../../utilities';
import { uiElementTypes } from '../../constants';
import { MultiSiteName } from './components';

const RenderEditableValue = ({
  tableDropdownData,
  value,
  uiElement,
  projectId,
}) => {
  if (tableDropdownData) {
    return <DropdownTable data={tableDropdownData} />;
  }

  if (uiElement === uiElementTypes.PROJECT_SUITE_DROPDOWN) {
    return (
      <MultiSiteName
        value={value}
        projectId={projectId}
      />
    );
  }

  return renderValue(value, uiElement);
};

RenderEditableValue.defaultProps = {
  value: null,
  uiElement: null,
  projectId: null,
  tableDropdownData: undefined,
};

RenderEditableValue.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  tableDropdownData: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
  ]),
  uiElement: PropTypes.string,
  projectId: PropTypes.string,
};

export default RenderEditableValue;
