import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, change, getFormValues } from 'redux-form';
import { withRouter } from 'react-router-dom';

import { singleProjectSelector } from 'helpers/selectors';
import formNames from 'components/form/formNames';
import { validateOnSubmit } from 'components/form/formConfigs';
import AddPage from './AddPage';

export const mapStateToProps = (state, { match, total }) => {
  const { id: projectId } = match.params;
  const { isConstruction, isSuspended } = singleProjectSelector(state, projectId);
  const selector = getFormValues(formNames.addPage);
  const { paginationIndex, pageType } = selector(state)
    ? selector(state)
    : {
      paginationIndex: 0,
      pageType: 'default',
    };

  return {
    isConstruction,
    isSuspended,
    pageIndex: parseInt(paginationIndex, 10),
    pageType,
    initialValues: {
      paginationIndex: parseInt(total + 1, 10),
      pageType,
    },
  };
};

export const mapDispatchToProps = dispatch => ({
  onChangePaginationIndex: value => {
    dispatch(change(formNames.addPage, 'paginationIndex', value));
  },
  onChangePageType: value => {
    dispatch(change(formNames.addPage, 'pageType', value));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formNames.addPage,
    ...validateOnSubmit,
  }),
)(AddPage);
