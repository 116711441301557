import React from 'react';
import PropTypes from 'prop-types';


import formNames from 'components/form/formNames';
import { StyledControlUIButtonGroup } from './ControlUIButtonGroup.style';
import ControlUIButton from './ControlUIButton/ControlUIButtonContainer';

const ControlUIButtonGroup = ({ pageId, configure, buttons }) => (
  <StyledControlUIButtonGroup configure={configure}>
    {buttons.map(({ index, ...button }) => (
      <ControlUIButton
        key={index}
        form={`${formNames.controlUiButton}_${pageId}_${index}`}
        pageId={pageId}
        buttonId={index}
        configure={configure}
        {...button}
      />
    ))}
  </StyledControlUIButtonGroup>
);

ControlUIButtonGroup.defaultProps = {
  configure: false,
  buttons: [],
};

ControlUIButtonGroup.propTypes = {
  group: PropTypes.number.isRequired,
  pageId: PropTypes.number.isRequired,
  configure: PropTypes.bool,
  buttons: PropTypes.array,
};

export default ControlUIButtonGroup;
