import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { selectors, actionCreators } from '../../../../../../utilities';

import RestrictAccess from './RestrictAccess';

export const mapStateToProps = (state, { match, pageId, isMultiSite }) => {
  const { id: resourceId } = match.params || {};
  const { pages = [] } = selectors.projectControlUISelector(state)(resourceId) || {};
  const { allowedUserIds = [] } = pages.filter(({ index }) => index === pageId)[0] || {};

  return {
    users: selectors.controlPanelUsersSelector(state, resourceId),
    resourceId,
    resourceType: isMultiSite ? 'project_suite' : 'project',
    isMultiSite,
    pageId,
    allowedUserIds,
  };
};

const mapDispatchToProps = {
  onSetAllowedUserIds: actionCreators.setPageAllowedUserIds,
  onFetchUsers: actionCreators.fetchUsers,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(RestrictAccess);
