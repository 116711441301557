import createPermissionRequirementsMatcher from './createPermissionRequirementsMatcher';

const createUserPermissionMatcher = permissionAttributes => {
  const {
    userPermissions,
    ...rest
  } = permissionAttributes;

  return function userPermissionMatcher(requirements) {
    const matchesRequirements = createPermissionRequirementsMatcher({ ...requirements, ...rest });

    return userPermissions.some(matchesRequirements);
  };
};

export default createUserPermissionMatcher;
