import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import { LOGICAL_DEVICES_FIELD } from 'helpers/selectors';

const deviceActionCreators = {
  fetchDevice: (deviceId, urlParams) => ({
    type: constants.FETCH_DEVICE_REQUEST,
    payload: {
      deviceId,
      endpoint: endpoints.LOGICAL_DEVICE(deviceId),
      urlParams,
    },
  }),

  fetchDeviceSuccess: response => ({
    type: constants.FETCH_DEVICE_SUCCESS,
    payload: { response },
  }),

  fetchDeviceFailure: error => ({
    type: constants.FETCH_DEVICE_FAILED,
    payload: { error },
  }),

  addDevice: params => ({
    type: constants.ADD_DEVICE_REQUEST,
    payload: {
      params,
      endpoint: endpoints.CONNECTION_DATA,
    },
  }),

  addDeviceSuccess: response => ({
    type: constants.ADD_DEVICE_SUCCESS,
    payload: {
      response,
      resourceType: LOGICAL_DEVICES_FIELD,
    },
  }),

  addDeviceFailure: error => ({
    type: constants.ADD_DEVICE_FAILED,
    payload: { error },
  }),

  clearDeviceKey: () => ({
    type: constants.CLEAR_DEVICE_KEY,
  }),

  replaceDeviceToken: params => ({
    type: constants.REPLACE_DEVICE_TOKEN_REQUEST,
    payload: {
      params,
      endpoint: endpoints.CONNECTION_DATA,
    },
  }),

  replaceDeviceTokenSuccess: response => ({
    type: constants.REPLACE_DEVICE_TOKEN_SUCCESS,
    payload: { response },
  }),

  replaceDeviceTokenFailure: error => ({
    type: constants.REPLACE_DEVICE_TOKEN_FAILED,
    payload: { error },
  }),

  updateDeviceReported: (deviceId, path, value) => ({
    type: constants.UPDATE_DEVICE_REPORTED,
    payload: { deviceId, path, value },
  }),

  removeDevice: deviceId => ({
    type: constants.REMOVE_DEVICE_REQUEST,
    payload: {
      deviceId,
      endpoint: endpoints.LOGICAL_DEVICE(deviceId),
    },
  }),

  removeOnlineDevice: deviceName => ({
    type: constants.ADD_GENERAL_ERROR,
    payload: {
      error: messages.REMOVE_ONLINE_DEVICE(deviceName),
    },
  }),

  removeDeviceSuccess: response => ({
    type: constants.REMOVE_DEVICE_SUCCESS,
    payload: {
      response,
      resourceType: LOGICAL_DEVICES_FIELD,
      message: messages.DEVICE_REMOVED,
    },
  }),

  removeDeviceFailure: error => ({
    type: constants.REMOVE_DEVICE_FAILED,
    payload: { error },
  }),

  renameDevice: params => ({
    type: constants.RENAME_DEVICE,
    payload: {
      params,
      endpoint: endpoints.LOGICAL_DEVICE(params.values.id),
    },
  }),

  renameDeviceSuccess: () => ({
    type: constants.ADD_GENERAL_NOTIFICATION,
    payload: {
      message: messages.DEVICE_RENAMED,
    },
  }),

  renameDeviceFailure: error => ({
    type: constants.RENAME_DEVICE_FAILED,
    payload: { error },
  }),

  fetchIoModules: id => ({
    type: constants.FETCH_IO_MODULES,
    payload: {
      id,
      endpoint: endpoints.IO_MODULES(id),
    },
  }),

  fetchIoModulesSuccess: response => ({
    type: constants.FETCH_IO_MODULES_SUCCESS,
    payload: { response },
  }),

  fetchIoModulesFailure: error => ({
    type: constants.FETCH_IO_MODULES_FAILED,
    payload: {
      info: !error.unauthorized && error.notFound && messages.NO_IO_MODULES,
      error: !error.notFound && error,
    },
  }),

  fetchIoModulesInstances: (deviceId, urlParams) => ({
    type: constants.FETCH_IO_MODULES_INSTANCES,
    payload: {
      deviceId,
      endpoint: endpoints.IO_MODULES_INSTANCES(deviceId),
      urlParams,
    },
  }),

  fetchIoModulesInstancesSuccess: response => ({
    type: constants.FETCH_IO_MODULES_INSTANCES_SUCCESS,
    payload: { response },
  }),

  fetchIoModulesInstancesFailure: error => ({
    type: constants.FETCH_IO_MODULES_INSTANCES_FAILED,
    payload: {
      info: !error.unauthorized && error.notFound && messages.NO_IO_MODULES,
      error: !error.notFound && error,
    },
  }),

  fetchIoModulesInstancesPaginated: response => ({
    type: constants.FETCH_IO_MODULES_INSTANCES_PAGINATED,
    payload: { response },
  }),
};

export default deviceActionCreators;
