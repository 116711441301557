import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { STATIC_SCENES_FIELD } from 'helpers/selectors';
import onMountUnmount from 'helpers/onMountUnmount';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { addPagination } from 'helpers/paginationHelpers';
import websocketActionCreators from 'data/websockets/websocketsActionCreators';
import staticScenesSectionActionCreators from './staticScenesSectionActionCreators';
import StaticScenesSection from './StaticScenesSection';

const mapStateToProps = (state, { resourceId }) => ({
  deviceId: resourceId,
});

export default compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: STATIC_SCENES_FIELD, included: true },
      ({ deviceId }) => staticScenesSectionActionCreators.fetchStaticScenes(deviceId, {
        sort: 'id',
      }),
    ),
  ),
  onMountUnmount(
    ({ deviceId }) => websocketActionCreators.subscribeDeviceData(deviceId, 'static_scenes'),
    ({ deviceId }) => websocketActionCreators.unsubscribeDeviceData(deviceId, 'static_scenes'),
  ),
)(StaticScenesSection);
