import { call } from 'redux-saga/effects';

import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import * as actionCreators from '../../actionCreators/deviceGroups';

function* fetchDeviceGroups({ payload }) {
  const { deviceId, endpoint, ...rest } = payload;
  const { response, error } = yield call(getPath, endpoint);
  const enhancedResponse = response ? { ...response, deviceId } : response;

  const params = {
    response: enhancedResponse,
    error,
    successDisp: actionCreators.fetchDeviceGroupsSuccess,
    failDisp: actionCreators.fetchDeviceGroupsFailure,
    ...rest,
  };
  yield call(processCall, params);
}

export default fetchDeviceGroups;
