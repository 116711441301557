/* eslint-disable camelcase */

import { attachConfig, extractObjectsFromArray } from 'helpers/layoutSwitchHelpers';
import * as DeviceTestRunner from './Pharos/DeviceTestRunner';
import * as LPC from './Pharos/LPC';
import * as LPCV2 from './Pharos/LPCV2';
import * as LPCX from './Pharos/LPCX';
import * as LPCXV2 from './Pharos/LPCXV2';
import * as LPCXrev1 from './Pharos/LPCXrev1';
import * as TPC from './Pharos/TPC';
import * as TPCV2 from './Pharos/TPCV2';
import * as TPSV1 from './Pharos/TPSV1';
import * as VLC from './Pharos/VLC';
import * as VLCV2 from './Pharos/VLCV2';
import * as VLCPlus from './Pharos/VLCPlus';
import * as VLCPlusV2 from './Pharos/VLCPlusV2';
import * as Atlas from './ETC/Atlas';
import * as AtlasV2 from './ETC/AtlasV2';
import * as AtlasPro from './ETC/AtlasPro';
import * as AtlasProV2 from './ETC/AtlasProV2';
import * as MSC from './ETC/MSC';
import * as MSCV2 from './ETC/MSCV2';
import * as MSCX from './ETC/MSCX';
import * as MSCXV2 from './ETC/MSCXV2';
import * as MSCXrev1 from './ETC/MSCXrev1';
import * as MTPC from './ETC/MTPC';
import * as MTPCV2 from './ETC/MTPCV2';
import * as MTPSV1 from './ETC/MTPSV1';
import * as Sandbox from './Example/Sandbox';
import * as Pathport6824 from './Pathway/Pathport6824';
import * as PathwayVIA8 from './Pathway/PathwayVIA8';
import * as PathwayVIA12 from './Pathway/PathwayVIA12';
import * as PathwayVIA12_with_PoE from './Pathway/PathwayVIA12_with_PoE';
import * as PathwayVIA12TE_with_PoE from './Pathway/PathwayVIA12TE_with_PoE';
import * as PathwayVIA16 from './Pathway/PathwayVIA16';
import * as VignetteClock from './Pathway/VignetteClock';
import * as PathportQuattro from './Pathway/PathportQuattro';
import * as PathportOcto from './Pathway/PathportOcto';
import * as Cognito from './Pathway/Cognito2';
import * as Choreo7TS from './Pathway/Choreo7TS';
import * as ChoreoeDIN from './Pathway/ChoreoeDIN';
import * as Jambox from './SLC/Jambox';
import * as Lumenbox from './SLC/Lumenbox';
import * as ELink from './Pathway/ELink';
import * as PathwayVIA24 from './Pathway/PathwayVIA24';
import * as TPS5V1 from './Pharos/TPS5V1';
import * as MTSV1 from './ETC/MTSV1';
import * as MTS5V1 from './ETC/MTS5V1';

export default [
  DeviceTestRunner,
  LPC,
  LPCV2,
  LPCX,
  LPCXV2,
  LPCXrev1,
  TPC,
  TPCV2,
  TPSV1,
  VLC,
  VLCV2,
  VLCPlus,
  VLCPlusV2,
  Atlas,
  AtlasV2,
  AtlasPro,
  AtlasProV2,
  MSC,
  MSCV2,
  MSCX,
  MSCXV2,
  MSCXrev1,
  MTPC,
  MTPCV2,
  MTPSV1,
  Sandbox,
  Pathport6824,
  PathwayVIA8,
  PathwayVIA12,
  PathwayVIA12_with_PoE,
  PathwayVIA12TE_with_PoE,
  PathwayVIA16,
  VignetteClock,
  PathportQuattro,
  PathportOcto,
  Cognito,
  Choreo7TS,
  ChoreoeDIN,
  Jambox,
  Lumenbox,
  ELink,
  PathwayVIA24,
  TPS5V1,
  MTSV1,
  MTS5V1,
]
  .map(Object.values)
  .reduce(extractObjectsFromArray, [])
  .reduce(attachConfig, {});
