import React from 'react';
import PropTypes from 'prop-types';

import { StyledButton } from '../../EnableConstructionMode.style';

const EnableButton = ({ onSubmit }) => {
  const submitForm = e => {
    e.preventDefault();

    return onSubmit();
  };

  return (
    <StyledButton primary onClick={submitForm}>
      Enable
    </StyledButton>
  );
};

EnableButton.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default EnableButton;
