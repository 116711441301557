import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/atomic';
import ConfirmModal from 'components/elements/ConfirmModal/ConfirmModal';
import ThemedToggle from 'components/elements/ThemedToggle/ThemedToggle.style';

const TwoFactorToggle = ({ checked, handleSubmit, input: { onChange } }) => {
  const handleConfirm = () => {
    onChange(!checked);
    handleSubmit();
  };
  const trigger = (
    <ThemedToggle
      checked={Boolean(checked)}
    />
  );
  const confirmation = checked ? 'Disable' : 'Enable';
  const confirmButton = (
    <Button
      negative
      primary={false}
    >
      {confirmation}
      &nbsp;Two Factor Authentication
    </Button>
  );
  const validationString = 'Two Factor Authentication';

  return (
    <ConfirmModal
      trigger={trigger}
      cancelButton="Cancel"
      confirmButton={confirmButton}
      content={(
        <div>
          <p>
            Modifying the Two Factor Authentication setting
            can affect the security of user accounts for this tenant.
          </p>
          <p>
            Enter the text <strong>{validationString}</strong> to confirm:
          </p>
        </div>
      )}
      header="Confirm Two Factor Authentication setting change"
      onConfirm={handleConfirm}
      validationString={validationString}
      validationMessage="Enter the correct text to confirm this change"
    />
  );
};

TwoFactorToggle.defaultProps = {
  checked: false,
};

TwoFactorToggle.propTypes = {
  checked: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default TwoFactorToggle;
