import constants from 'dispatcherConst';

const setTypedVoucher = typedVoucher => ({
  type: constants.SET_TYPED_VOUCHER_REQUEST,
  payload: {
    typedVoucher,
  },
});

export default setTypedVoucher;
