import styled from 'styled-components';

export const StyledDiv = styled.div`
  margin-bottom: 7px;

  &&& .ui.input {
    width: 100%;
    min-width: 200px;
  }
`;

export default StyledDiv;
