import { call, put } from 'redux-saga/effects';

import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import { prepareResponseData } from 'helpers/paginationHelpersClient';

import actionCreators from '../actionCreators';

function* fetchNotificationEvents(action) {
  const { endpoint, urlParams, ...rest } = action.payload;

  const modifiedUrlParams = {
    ...urlParams,
  };

  delete modifiedUrlParams.page;

  const { response, error } = yield call(getPath, endpoint, {
    urlParams: modifiedUrlParams,
  });

  const params = {
    response,
    error,
    successDisp: actionCreators.fetchNotificationEventsSuccess,
    failDisp: actionCreators.fetchNotificationEventsFailure,
    ...rest,
  };

  yield call(processCall, params);

  if (response) {
    const enhancedResponse = yield call(prepareResponseData, response, urlParams);

    yield put(
      actionCreators.fetchNotificationEventsPaginated(enhancedResponse),
    );
  }
}

export default fetchNotificationEvents;
