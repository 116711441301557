import merge from 'lodash/merge';

import constants from 'dispatcherConst';
import { paths } from 'navigation';
import {
  handleResourceEdit,
  handleResourceFetch,
  handleResourceFetchError,
  handleResourceFetchSuccess,
} from 'helpers/paginationHelpers';

const paginationReducer = (state = {}, action) => {
  switch (action.type) {

    case constants.CHANGE_ROUTE:
      return { ...state, pathname: action.payload.pathname };

    case constants.REFETCH: {
      const {
        resourceType,
        urlParams: { page: { number, size } = {}, sort } = {},
        componentName,
      } = action.payload;
      const { pathname } = state;
      const resourceTypeForState = componentName || resourceType;

      return merge({}, state, {
        [pathname]: {
          [resourceTypeForState]: {
            fetching: true,
            page: { number, size },
            refetch: true,
            sort,
          },
        },
      });
    }

    case constants.CLEAR_ACTIVE_RESOURCE: {
      const { resourceType } = action.payload;
      const { pathname } = state;

      return merge({}, state, {
        [pathname]: {
          [resourceType]: {
            active: null,
          },
        },
      });
    }

    case constants.CHANGE_TENANT: {
      const { pathname, [pathname]: { companies } = {} } = state;

      if (pathname === paths.companies) {
        return {
          pathname,
          [pathname]: { ...state[pathname], companies },
        };
      }

      return {
        pathname,
        [pathname]: { companies },
      };
    }

    // Request
    case constants.FETCH_RESOURCE_PAGE_REQUEST:
    case constants.FETCH_COMPANIES_REQUEST:
    case constants.FETCH_PROJECTS_REQUEST:
    case constants.FETCH_PROJECT_SUITES_REQUEST:
    case constants.FETCH_USERS_REQUEST:
    case constants.PERMISSIONS_FETCH_PROJECT_USERS_REQUEST:
    case constants.FETCH_FILES_REQUEST:
    case constants.FETCH_TRIGGERS_REQUEST:
    case constants.FETCH_TIMELINES_REQUEST:
    case constants.FETCH_FIXTURE_GROUPS_REQUEST:
    case constants.FETCH_STATIC_SCENES_REQUEST:
    case constants.FETCH_NOTIFICATION_TYPES_REQUEST:
    case constants.FETCH_ZONES_REQUEST:
    case constants.FETCH_SNAPSHOTS_REQUEST:
    case constants.FETCH_CONTEXTS_REQUEST:
    case constants.FETCH_IO_MODULES_INSTANCES:
    case constants.FETCH_TASKS_REQUEST:
    case constants.FETCH_AUTOMATED_OPERATIONS_REQUEST:
    case constants.FETCH_REMOTE_DEVICES_REQUEST:
    case constants.FETCH_UNJOINED_REMOTE_DEVICES_REQUEST:
    case constants.FETCH_PROJECT_SUITE_PROJECTS_REQUEST:
    case constants.FETCH_PROJECT_SUITE_USERS_REQUEST:
    case constants.FETCH_PROJECT_SUITE_PROJECTS_TASKS_REQUEST:
    case constants.FETCH_NOTIFICATION_EVENTS:
    case constants.FETCH_DEVICE_FIRMWARES_REQUEST:
    case constants.FETCH_FIRMWARE_FILES_REQUEST:
      return handleResourceFetch(state, action);

    // Error
    case constants.FETCH_COMPANIES_FAILED:
    case constants.FETCH_IO_MODULES_FAILED:
    case constants.FETCH_IO_MODULES_INSTANCES_FAILED:
    case constants.FETCH_PROJECTS_FAILED:
    case constants.FETCH_PROJECT_SUITES_FAILED:
    case constants.FETCH_USERS_FAILED:
    case constants.PERMISSIONS_FETCH_PROJECT_USERS_FAILED:
    case constants.FETCH_FILES_FAILED:
    case constants.FETCH_TRIGGERS_FAILED:
    case constants.FETCH_TIMELINES_FAILED:
    case constants.FETCH_FIXTURE_GROUPS_FAILED:
    case constants.FETCH_STATIC_SCENES_FAILED:
    case constants.FETCH_ZONES_FAILED:
    case constants.FETCH_SNAPSHOTS_FAILED:
    case constants.FETCH_CONTEXTS_FAILED:
    case constants.FETCH_TASKS_FAILED:
    case constants.FETCH_AUTOMATED_OPERATIONS_FAILED:
    case constants.FETCH_REMOTE_DEVICES_FAILED:
    case constants.FETCH_UNJOINED_REMOTE_DEVICES_FAILED:
    case constants.FETCH_PROJECT_SUITE_PROJECTS_FAILED:
    case constants.FETCH_PROJECT_SUITE_USERS_FAILED:
    case constants.FETCH_PROJECT_SUITE_PROJECTS_TASKS_FAILED:
    case constants.FETCH_DEVICE_FIRMWARES_FAILED:
    case constants.FETCH_FIRMWARE_FILES_FAILED:
      return handleResourceFetchError(state, action);

    // Success
    case constants.FETCH_CURRENT_USER_SUCCESS:
    case constants.FETCH_PROJECT_SUCCESS:
    case constants.FETCH_PROJECT_SUITE_SUCCESS:
    case constants.FETCH_COMPANIES_SUCCESS:
    case constants.FETCH_PROJECTS_SUCCESS:
    case constants.FETCH_PROJECT_SUITES_SUCCESS:
    case constants.FETCH_PROJECT_SUITE_PROJECTS_SUCCESS:
    case constants.FETCH_USERS_SUCCESS:
    case constants.PERMISSIONS_FETCH_PROJECT_USERS_SUCCESS:
    case constants.PERMISSIONS_FETCH_PROJECT_SUITE_USERS_SUCCESS:
    case constants.FETCH_FILES_PAGINATED:
    case constants.FETCH_TRIGGERS_PAGINATED:
    case constants.FETCH_TIMELINES_PAGINATED:
    case constants.FETCH_FIXTURE_GROUPS_PAGINATED:
    case constants.FETCH_STATIC_SCENES_PAGINATED:
    case constants.FETCH_NOTIFICATION_TYPES_PAGINATED:
    case constants.ADD_TO_DATA_STORE:
    case constants.FETCH_ZONES_PAGINATED:
    case constants.FETCH_SNAPSHOTS_PAGINATED:
    case constants.FETCH_CONTEXTS_PAGINATED:
    case constants.FETCH_IO_MODULES_SUCCESS:
    case constants.FETCH_IO_MODULES_INSTANCES_PAGINATED:
    case constants.FETCH_TASKS_PAGINATED:
    case constants.FETCH_AUTOMATED_OPERATIONS_PAGINATED:
    case constants.FETCH_REMOTE_DEVICES_PAGINATED:
    case constants.FETCH_UNJOINED_REMOTE_DEVICES_PAGINATED:
    case constants.FETCH_PROJECT_SUITE_USERS_SUCCESS:
    case constants.FETCH_PROJECT_SUITE_PROJECTS_TASKS_PAGINATED:
    case constants.FETCH_NOTIFICATION_EVENTS_PAGINATED:
    case constants.FETCH_DEVICE_FIRMWARES_SUCCESS:
    case constants.FETCH_FIRMWARE_FILES_SUCCESS:
      return handleResourceFetchSuccess(state, action);

    case constants.ADD_COMPANY_SUCCESS:
    case constants.ADD_TASK_SUCCESS:
    case constants.INVITE_USER_SUCCESS:
    case constants.UPDATE_COMPANY_INFO_SUCCESS:
    case constants.DELETE_PROJECT_SUCCESS:
    case constants.ADD_PROJECT_SUCCESS:
    case constants.ADD_PROJECT_SUITE_SUCCESS:
    case constants.ADD_DEVICE_SUCCESS:
    case constants.REMOVE_USER_FROM_PROJECT_SUCCESS:
    case constants.REMOVE_DEVICE_SUCCESS:
    case constants.UPLOAD_SUCCESS:
    case constants.REMOVE_TASK_SUCCESS:
    case constants.UPDATE_TASK_SUCCESS:
    case constants.ADD_AUTOMATED_OPERATION_SUCCESS:
    case constants.ADD_TASK_SCHEDULER_SUCCESS:
    case constants.REMOVE_AUTOMATED_OPERATION_SUCCESS:
    case constants.UPDATE_AUTOMATED_OPERATION_SUCCESS:
    case constants.REMOVE_PROJECT_FROM_PROJECT_SUITE_SUCCESS:
    case constants.REMOVE_USER_FROM_PROJECT_SUITE_SUCCESS:
    case constants.ADD_PROJECT_TO_PROJECT_SUITE_SUCCESS:
    case constants.ADD_NOTIFICATION_EVENT_FROM_WEBSOCKET:
    case constants.ADD_DEVICE_FIRMWARE_SUCCESS:
      return handleResourceEdit(state, action);

    default:
      return state;
  }
};

export default paginationReducer;
