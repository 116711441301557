import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InviteUserModalForm from './InviteUserModalForm/InviteUserModalFormContainer';

class InviteUser extends Component {

  constructor() {
    super();

    this.inviteUser = this.inviteUser.bind(this);
  }

  inviteUser(values) {
    const { projectId, projectSuiteId, onInviteUser } = this.props;

    return new Promise((resolveForm, rejectForm) => {
      onInviteUser({
        values: {
          ...values,
          projectId,
          projectSuiteId,
        },
        resolveForm,
        rejectForm,
      });
    });
  }

  render() {
    return (
      <InviteUserModalForm onSubmit={this.inviteUser} />
    );
  }

}

InviteUser.defaultProps = {
  projectId: undefined,
  projectSuiteId: undefined,
};

InviteUser.propTypes = {
  projectId: PropTypes.string,
  projectSuiteId: PropTypes.string,
  onInviteUser: PropTypes.func.isRequired,
};

export default InviteUser;
