import isNull from 'lodash/isNull';
import { snakeCaseToOption } from 'helpers/sharedMethods';

const renderValue = (value, key) => {
  if (isNull(value)) {
    return null;
  }

  if (value === 'true' || value === 'false') {
    return value;
  }

  if (key === 'timeMillisec') {
    return `${parseInt(value, 10) / 1000} sec`;
  }

  return snakeCaseToOption(value);
};

export default renderValue;
