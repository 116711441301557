import constants from 'dispatcherConst';
import socketConstants from './constants';

const websocketsActionCreators = {
  open: () => ({
    type: constants.SOCKET_OPEN,
  }),

  closed: data => ({
    type: constants.SOCKET_CLOSED,
    payload: {
      data,
    },
  }),

  resetConnection: () => ({
    type: constants.SOCKET_RESET_CONNECTION,
  }),

  error: data => ({
    type: constants.SOCKET_ERROR,
    payload: {
      data,
    },
  }),

  received: payload => ({
    type: constants.SOCKET_RECEIVED,
    payload,
  }),

  send: payload => ({
    type: constants.SOCKET_SEND,
    payload,
  }),

  subscribe: identifier => ({
    type: constants.SOCKET_SUBSCRIBE,
    payload: {
      command: socketConstants.COMMAND_SUBSCRIBE,
      identifier,
    },
  }),

  subscribeSuccess: message => ({
    type: constants.SOCKET_SUBSCRIBE_SUCCESS,
    payload: message,
  }),

  subscribeFailed: identifier => ({
    type: constants.SOCKET_SUBSCRIBE_FAILED,
    payload: {
      identifier,
      error: 'Socket subscription failed',
    },
  }),

  unsubscribe: identifier => ({
    type: constants.SOCKET_UNSUBSCRIBE,
    payload: {
      command: socketConstants.COMMAND_UNSUBSCRIBE,
      identifier,
    },
  }),

  refreshDone: identifier => ({
    type: constants.SOCKET_REFRESH_DONE,
    payload: {
      identifier,
    },
  }),

  subscribeDeviceData: (logicalDeviceId, data, meta = {}) => ({
    type: constants.SOCKET_SUBSCRIBE,
    payload: {
      command: socketConstants.COMMAND_SUBSCRIBE,
      identifier: {
        channel: socketConstants.CHANNEL_DEVICE_DATA,
        logicalDeviceId,
        data,
        meta,
      },
    },
  }),

  unsubscribeDeviceData: (logicalDeviceId, data, meta = {}) => ({
    type: constants.SOCKET_UNSUBSCRIBE,
    payload: {
      command: socketConstants.COMMAND_UNSUBSCRIBE,
      identifier: {
        channel: socketConstants.CHANNEL_DEVICE_DATA,
        logicalDeviceId,
        data,
        meta,
      },
    },
  }),

  refreshDeviceData: (logicalDeviceId, data, meta = {}) => ({
    type: constants.SOCKET_REFRESH,
    payload: {
      identifier: {
        channel: socketConstants.CHANNEL_DEVICE_DATA,
        logicalDeviceId,
        data,
        meta,
      },
    },
  }),

  subscribeAuditLogChannel: (data, meta = {}) => ({
    type: constants.SOCKET_SUBSCRIBE,
    payload: {
      command: socketConstants.COMMAND_SUBSCRIBE,
      identifier: {
        channel: socketConstants.AUDIT_LOG_CHANNEL,
        tenant: '',
        data,
        meta,
      },
    },
  }),

  getLogsAuditLogChannel: (data, tenant, meta = {}) => ({
    type: constants.SOCKET_SEND,
    payload: {
      command: socketConstants.COMMAND_MESSAGE,
      identifier: {
        channel: socketConstants.AUDIT_LOG_CHANNEL,
        tenant,
        meta,
        data: socketConstants.AUDIT_LOG,
      },
      data,
    },
  }),

  unSubscribeAuditLogChannel: (data, meta = {}) => ({
    type: constants.SOCKET_UNSUBSCRIBE,
    payload: {
      command: socketConstants.COMMAND_UNSUBSCRIBE,
      identifier: {
        channel: socketConstants.AUDIT_LOG_CHANNEL,
        tenant: '',
        data,
        meta,
      },
    },
  }),

  subscribeNotificationsChannel: (tenant = '') => ({
    type: constants.SOCKET_SUBSCRIBE,
    payload: {
      command: socketConstants.COMMAND_SUBSCRIBE,
      identifier: {
        channel: socketConstants.NOTIFICATIONS_CHANNEL,
        tenant,
      },
    },
  }),
  unSubscribeNotificationsChannel: () => ({
    type: constants.SOCKET_UNSUBSCRIBE,
    payload: {
      command: socketConstants.COMMAND_UNSUBSCRIBE,
      identifier: {
        channel: socketConstants.NOTIFICATIONS_CHANNEL,
        tenant: '',
      },
    },
  }),
};

export default websocketsActionCreators;
