import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import {
  AUTOMATED_OPERATIONS_FIELD,
} from 'helpers/selectors';

export const addAutomatedOperation = params => ({
  type: constants.ADD_AUTOMATED_OPERATION_REQUEST,
  payload: {
    params,
    endpoint: endpoints.AUTOMATED_OPERATIONS,
  },
});

export const addAutomatedOperationSuccess = response => ({
  type: constants.ADD_AUTOMATED_OPERATION_SUCCESS,
  payload: {
    response,
    message: messages.AUTOMATED_OPERATION_ADDED(response.data.attributes.name),
    resourceType: AUTOMATED_OPERATIONS_FIELD,
    id: response.data.id,
  },
});

export const addAutomatedOperationFailure = error => ({
  type: constants.ADD_AUTOMATED_OPERATION_FAILED,
  payload: { error },
});
