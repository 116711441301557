import { connect } from 'react-redux';
import pluralize from 'pluralize';
import isString from 'lodash/isString';

import { projectSelector } from 'helpers/selectors';
import { searchInvalidActions, invalidTaskMessage } from '../../utilities';
import TaskInformation from './TaskInformation';

export const mapStateToProps = (state, { item, label }) => {
  const { invalidActions, singleAction } = searchInvalidActions(item);
  const numberOfActions = item.actions.length;
  const actionsText = `${pluralize('Action', numberOfActions, true)}`;
  const message = invalidTaskMessage(singleAction);
  const showInformation = label && label.field === 'name';
  const isSuperSiteTask = item && item.projectSuiteId && isString(item.projectSuiteId);
  const { name: projectName } = projectSelector(state)(item.projectId) || {};

  return {
    actionsText,
    invalidActions,
    message,
    showInformation,
    isSuperSiteTask,
    projectName,
  };
};

export default connect(mapStateToProps)(TaskInformation);
