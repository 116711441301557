import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { deepEqualMemoizeOne } from 'helpers/sharedMethods';
import {
  singleProjectSelector,
  singleProjectSuiteSelector,
  siteAutomatedOperationsOccurrencesDateRangeSelector,
  superSiteAutomatedOperationsOccurrencesDateRangeSelector,
} from 'helpers/selectors';
import { byDatetime, checkPositiveInteger } from './utilities';
import DayViewTable from './DayViewTable';

export const makeMapState = () => {
  const memoOccurrences = deepEqualMemoizeOne();

  return function mapStateToProps(state, { match, active, resourceType }) {
    const SINGLE_RESOURCE_SELECTOR = {
      project: singleProjectSelector,
      projectSuite: singleProjectSuiteSelector,
    };

    const OCCURRENCES_SELECTOR = {
      project: siteAutomatedOperationsOccurrencesDateRangeSelector,
      projectSuite: superSiteAutomatedOperationsOccurrencesDateRangeSelector,
    };

    const { id: resourceId } = match.params;

    const resourceSelector = SINGLE_RESOURCE_SELECTOR[resourceType];
    const occurrencesSelector = OCCURRENCES_SELECTOR[resourceType];

    const {
      datetime: resourceTime,
      projectSuitesCount = undefined,
    } = resourceSelector(state, resourceId);
    const occurrences = occurrencesSelector(state, resourceId, active);
    const sortedOccurrences = occurrences.sort(byDatetime);

    return {
      siteHasMultiSite: checkPositiveInteger(projectSuitesCount),
      resourceTime,
      occurrences: memoOccurrences(sortedOccurrences),
    };
  };
};

export default compose(
  withRouter,
  connect(makeMapState),
)(DayViewTable);
