import isNil from 'lodash/isNil';

import { CONTROL_UI_CONTROL_TYPES } from 'application/tenant/components/structural/ControlUI/constants';

const getButtonContent = (controlUIModel, pageId, buttonId, controlType = 'button') => {
  if (isNil(controlUIModel)) {
    return null;
  }

  const { BUTTON, OVERRIDE_CONTROLS } = CONTROL_UI_CONTROL_TYPES;

  if (Array.isArray(controlUIModel.pages)) {
    const { pages } = controlUIModel;
    const page = pages[pageId];
    const { buttons, overrideControls } = page;

    if (controlType === BUTTON && Array.isArray(buttons)) {
      return buttons[buttonId];
    }

    if (controlType === OVERRIDE_CONTROLS && Array.isArray(overrideControls)) {
      return overrideControls[buttonId];
    }

    return null;
  }

  return null;
};

export default getButtonContent;
