import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DataTableRow from 'components/DataTable/DataTableRow/DataTableRow';
import DataTableText from 'components/DataTable/DataTableText/DataTableText';
import FireTriggerButton from './TriggerTableRow.style';

class TriggerTableRow extends Component {

  constructor() {
    super();

    this.state = {
      disabled: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { deviceId, item, onTrigger, testConditions } = this.props;
    onTrigger(deviceId, { triggerIds: [item.id], testConditions });
    this.setState({ disabled: true },
      () => setTimeout(
        () => this.setState({ disabled: false }),
        300,
      ),
    );
  }

  render() {
    const { disabled } = this.state;
    const { isOnline } = this.props;

    return (
      <DataTableRow {...this.props}>
        <DataTableText />
        <DataTableText />
        <DataTableText />
        <FireTriggerButton
          primary
          basic
          floated="right"
          onClick={this.handleClick}
          disabled={!isOnline || disabled}
        >
          Fire
        </FireTriggerButton>
      </DataTableRow>
    );
  }

}

TriggerTableRow.defaultProps = {
  ...DataTableRow.defaultProps,
  testConditions: false,
};

TriggerTableRow.propTypes = {
  ...DataTableRow.propTypes,
  deviceId: PropTypes.string.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  isOnline: PropTypes.bool.isRequired,
  onTrigger: PropTypes.func.isRequired,
  testConditions: PropTypes.bool,
};

export default TriggerTableRow;
