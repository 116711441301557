import { put, call, takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import { prepareResponseData } from 'helpers/paginationHelpersClient';
import zonesActionCreators from './zonesActionCreators';

export function* fetchZones(action) {
  const { endpoint, urlParams, resourceType, ...rest } = action.payload;
  const { response, error } = yield call(getPath, endpoint, { urlParams });

  const params = {
    response,
    error,
    successDisp: zonesActionCreators.fetchZonesSuccess,
    failDisp: zonesActionCreators.fetchZonesFailure,
    resourceType,
    ...rest,
  };
  yield call(processCall, params);

  if (response) {
    const enhancedResponse = yield call(prepareResponseData, response, urlParams);
    yield put(zonesActionCreators.fetchZonesSuccessPaginated(enhancedResponse));
  }
}

function* ZonesSectionSaga() {
  yield takeLatest(constants.FETCH_ZONES_REQUEST, fetchZones);
}

export default ZonesSectionSaga;
