import isNull from 'lodash/isNull';

import {
  deviceTriggersSelector,
  deviceTimelinesSelector,
  deviceScenesSelector,
  deviceGroupsSelector,
} from 'helpers/selectors';
import { selectors } from 'application/tenant/console/site/components/structural/ProjectTasksTab/utilities';

const getResourceSelectors = paramKey => {
  if (isNull(paramKey)) {
    return {};
  }

  const selectorsList = {
    triggerIds: deviceTriggersSelector,
    timelineIds: deviceTimelinesSelector,
    sceneIds: deviceScenesSelector,
    groupIds: deviceGroupsSelector,
  };
  const lists = {
    triggerIds: 'triggersList',
    timelineIds: 'timelinesList',
    sceneIds: 'scenesList',
    groupIds: 'groupsList',
  };
  const loadingSelectors = {
    triggerIds: selectors.triggersFetchingSelector,
    timelineIds: selectors.timelinesFetchingSelector,
    sceneIds: selectors.scenesFetchingSelector,
    groupIds: selectors.groupsFetchingSelector,
  };

  if (typeof selectorsList[paramKey] !== 'function') {
    return {};
  }

  if (typeof loadingSelectors[paramKey] !== 'function') {
    return {};
  }

  if (!lists[paramKey]) {
    return {};
  }

  return {
    resourceSelector: selectorsList[paramKey],
    loadingSelector: loadingSelectors[paramKey],
    listKey: lists[paramKey],
  };

};

export default getResourceSelectors;
