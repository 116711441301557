import FirmwareV1 from 'components/Firmware/FirmwareV1Container';
import DeviceFetcher from 'layouts/DeviceFetcher/DeviceFetcherContainer';
import PathwayHardwareV1 from 'layouts/Pathway/components/PathwayHardwareV1/PathwayHardwareV1ChoreoContainer';
import PathwayNetworkInterfaceV1 from 'layouts/Pathway/components/PathwayNetworkInterfaceV1/PathwayNetworkInterfaceV1Container';
import PathwayClockV1 from 'layouts/Pathway/components/PathwayClockV1/PathwayClockV1Container';
import PathwayFilesV1 from 'layouts/Pathway/components/PathwayFilesV1/PathwayFilesV1';
import PathwayLogsV1 from 'layouts/Pathway/components/PathwayLogsV1/PathwayLogsV1';
import PathwayOutputV1 from 'layouts/Pathway/components/PathwayOutputV1/PathwayOutputV1';
import PathwayRebootDeviceV1 from 'layouts/Pathway/components/PathwayRebootDeviceV1/PathwayRebootDeviceV1Container';
import PathwayIdentifyV1 from 'layouts/Pathway/components/PathwayIdentifyV1/PathwayIdentifyV1VignetteClockContainer';
import PathwayDisableClockEventsV1 from 'layouts/Pathway/components/PathwayDisableClockEventsV1/PathwayDisableClockEventsV1Container';

export default [
  {
    menuItem: 'Overview',
    path: '/overview',
    fetcher: DeviceFetcher,
    rows: [
      {
        columns: [
          {
            computer: 8,
            mobile: 16,
            sections: [
              {
                title: 'Hardware',
                component: PathwayHardwareV1,
              },
            ],
          },
          {
            computer: 8,
            mobile: 16,
            sections: [
              {
                title: 'Network interface',
                component: PathwayNetworkInterfaceV1,
              },
              {
                title: 'Clock',
                component: PathwayClockV1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Files',
    path: '/files',
    fetcher: DeviceFetcher,
    rows: [
      {
        columns: [
          {
            sections: [
              {
                title: 'Files',
                component: PathwayFilesV1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Firmware',
    path: '/firmware',
    fetcher: DeviceFetcher,
    rows: [
      {
        columns: [
          {
            sections: [
              {
                title: 'Firmware',
                component: FirmwareV1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Log',
    path: '/log',
    rows: [
      {
        columns: [
          {
            sections: [
              {
                title: 'Log',
                component: PathwayLogsV1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Output',
    path: '/output',
    rows: [
      {
        columns: [
          {
            sections: [
              {
                title: 'Output',
                component: PathwayOutputV1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Advanced',
    path: '/advanced',
    fetcher: () => null,
    rows: [
      {
        columns: [
          {
            computer: 8,
            tablet: 8,
            mobile: 16,
            sections: [
              {
                title: 'Reboot device',
                component: PathwayRebootDeviceV1,
              },
              {
                title: 'Disable Clock Events',
                component: PathwayDisableClockEventsV1,
              },
            ],
          },
          {
            computer: 8,
            tablet: 8,
            mobile: 16,
            sections: [
              {
                title: 'Identify',
                component: PathwayIdentifyV1,
              },
            ],
          },
        ],
      },
    ],
  },
];
