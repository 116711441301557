import styled from 'styled-components';
import { TextArea } from 'semantic-ui-react';

import colors from 'themes/colors';

export const StyledTextAreaWrapper = styled.div`
  width: 100%;
  height: 200px;
`;

export const StyledTextArea = styled(TextArea)`
  width: 100%;
  height: 100%;
  padding: 8px 0.5em;
  font-family: sans-serif;
  font-weight: 400;
  font-size: 1em;
  border-radius: .28571429rem;

  display: block;
  display: ${({ readOnly }) => readOnly && 'none'};
`;

export const StyledValue = styled.div`
  height: 100%;
  overflow-y: scroll;

  display: none;
  display: ${({ readOnly }) => readOnly && 'block'};

  line-height: 1.15;
  font-family: sans-serif;
  font-style: ${({ empty }) => empty && 'italic'};
  font-size: 1em;

  padding: 8px 0.5em;
  border: 1px dashed transparent;

  &:hover {
    border-color: ${({ locked }) => !locked && colors.black};
  }
`;
