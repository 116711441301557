import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'semantic-ui-react';
import { Field } from 'redux-form';

import { FormFieldWrapper, InputWrapper } from 'components/form/FormField/FormField.style';
import ToggleFieldInput from 'components/form/ToggleFieldInput/ToggleFieldInput';
import { StyledToggleWrapper, StyledLabel } from './ToggleActionField.style';

const ToggleField = ({
  name,
  label,
  editMode,
  change,
}) => (
  <StyledToggleWrapper editMode={editMode}>
    <FormGroup inline>
      <FormFieldWrapper width={16}>
        {!editMode && <StyledLabel htmlFor="input">{label}</StyledLabel>}
        <InputWrapper>
          <Field
            name={name}
            component={ToggleFieldInput}
            onChangeCallback={change}
          />
        </InputWrapper>
      </FormFieldWrapper>
    </FormGroup>
  </StyledToggleWrapper>
);

ToggleField.defaultProps = {
  editMode: false,
  change: null,
};

ToggleField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  editMode: PropTypes.bool,
  change: PropTypes.func,
};

export default ToggleField;
