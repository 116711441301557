import styled from 'styled-components';

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d4d4d5;
  margin-bottom: 15px;
`;

export default StyledHeader;
