import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Accordion, Button } from 'semantic-ui-react';

import { ChildPanelHeader } from 'components/core/Panel/Panel';

import { SCHEDULER_TYPE_CONFIG } from '../../constants';
import { ConfigurationPanel } from '..';
import { StyledAccordionTitle } from './MobileView.style';

function MobileView({
  schedulerType,
  resourceType,
  onSchedulerTypeSelect,
  onClearSchedulerParams,
}) {
  const [activePanel, setActivePanel] = useState(0);

  const selectedSchedulerType = useMemo(() => {
    if (!schedulerType) {
      return {};
    }

    return SCHEDULER_TYPE_CONFIG[schedulerType];
  }, [schedulerType]);

  const handlePanelToggle = useCallback(index => {
    const newActivePanel = activePanel === index ? -1 : index;

    setActivePanel(newActivePanel);
  }, [activePanel]);

  return (
    <Grid.Row>
      <Grid.Column width={16}>
        <Accordion exclusive>
          <StyledAccordionTitle
            active={activePanel === 0}
            index={0}
            forwardedAs={Grid.Column}
            width={16}
          >
            <ChildPanelHeader
              title="Configuration"
              description="Set the required configurations."
              forwardedAs={Accordion.Title}
            />
            <Button
              circular
              icon={`chevron ${activePanel === 0 ? 'down' : 'left'}`}
              onClick={() => handlePanelToggle(0)}
              basic
              name="btn-open-panel"
              size="mini"
            />
          </StyledAccordionTitle>
          <Accordion.Content active={activePanel === 0}>
            <ConfigurationPanel
              schedulerType={schedulerType}
              resourceType={resourceType}
              onSchedulerTypeSelect={onSchedulerTypeSelect}
              onClearSchedulerParams={onClearSchedulerParams}
            />
          </Accordion.Content>
          <StyledAccordionTitle
            active={activePanel === 1}
            index={1}
            forwardedAs={Grid.Column}
            width={16}
          >
            <ChildPanelHeader
              title={selectedSchedulerType.title}
              description={selectedSchedulerType.description}
            />
            <Button
              circular
              icon={`chevron ${activePanel === 1 ? 'down' : 'left'}`}
              onClick={() => handlePanelToggle(1)}
              basic
              name="btn-open-panel"
              size="mini"
            />
          </StyledAccordionTitle>
          <Accordion.Content active={activePanel === 1}>
            {selectedSchedulerType.content()}
          </Accordion.Content>
        </Accordion>
      </Grid.Column>
    </Grid.Row>
  );
}

MobileView.defaultProps = {
  resourceType: 'project',
};

MobileView.propTypes = {
  schedulerType: PropTypes.string.isRequired,
  resourceType: PropTypes.oneOf([
    'project',
    'projectSuite',
  ]),
  onSchedulerTypeSelect: PropTypes.func.isRequired,
  onClearSchedulerParams: PropTypes.func.isRequired,
};
export default MobileView;
