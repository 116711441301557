import React from 'react';
import PropTypes from 'prop-types';

import formNames from 'components/form/formNames';
import EditAutomatedOperationColourModalForm from './EditAutomatedOperationColourModalForm/EditAutomatedOperationColourModalFormContainer';

const EditAutomatedOperationColour = ({
  item,
  currentItem,
  onEditAutomatedOperation,
}) => {
  const handleEditAutomatedOperation = values => {
    const { id, lockVersion } = item || currentItem;

    return new Promise((resolveForm, rejectForm) => {
      onEditAutomatedOperation({
        values: {
          ...values,
          id,
          lockVersion,
        },
        resolveForm,
        rejectForm,
      });
    });
  };

  const existingItem = item || currentItem;
  const { id } = existingItem;
  const formName = formNames.editAOColour;

  return (
    <EditAutomatedOperationColourModalForm
      onSubmit={handleEditAutomatedOperation}
      item={existingItem}
      form={`editAutomatedOperation-${id}-${formName}`}
      destroyForm
    />
  );
};

EditAutomatedOperationColour.defaultProps = {
  item: null,
  currentItem: null,
};

EditAutomatedOperationColour.propTypes = {
  onEditAutomatedOperation: PropTypes.func.isRequired,
  item: PropTypes.object,
  currentItem: PropTypes.object,
};

export default EditAutomatedOperationColour;
