import { call } from 'redux-saga/effects';

import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';

import endpoints from 'data/api/endpoints';
import actionCreators from '../actionCreators';

import transformUsersResponseToRecipients from '../transformUsersResponseToRecipients';

const RESOURCE_TYPE = {
  PROJECT: 'project',
  PROJECT_SUITE: 'project_suite',
  TENANT: 'tenant',
};

const CONFIGURATION = {
  [RESOURCE_TYPE.PROJECT]: ({ resourceId }) => ({
    endpoint: endpoints.PERMISSIONS_PROJECT_USERS(resourceId),
    urlParams: {
      sort: 'firstName',
      fields: {
        users: ['firstName', 'lastName', 'email'],
      },
    },
    transform: transformUsersResponseToRecipients,
  }),
  [RESOURCE_TYPE.PROJECT_SUITE]: ({ resourceId }) => ({
    endpoint: endpoints.PROJECT_SUITE_USERS(resourceId),
    urlParams: {
      sort: 'firstName',
      fields: {
        users: ['firstName', 'lastName', 'email'],
      },
    },
    transform: transformUsersResponseToRecipients,
  }),
  [RESOURCE_TYPE.TENANT]: () => ({
    endpoint: endpoints.USERS,
    urlParams: {
      sort: 'firstName',
      fields: {
        users: ['firstName', 'lastName', 'email'],
      },
    },
    transform: transformUsersResponseToRecipients,
  }),
};

function* fetchPermissionsTableRecipients(action) {
  const { resourceId, resourceType, context, ...rest } = action.payload;

  const config = context ? CONFIGURATION[context] : CONFIGURATION[resourceType];

  const { endpoint, urlParams, transform } = config({ resourceId });

  const { response, error } = yield call(getPath, endpoint, { urlParams });

  let enhancedResponse = null;

  if (!error) {
    enhancedResponse = {
      ...response,
      resourceId,
      resourceType,
      context,
      recipients: transform(response),
    };
  }

  const params = {
    response: enhancedResponse,
    error,
    successDisp: actionCreators.fetchPermissionsTableRecipientsSuccess,
    failDisp: actionCreators.fetchPermissionsTableRecipientsFailure,
    ...rest,
  };

  yield call(processCall, params);
}

export default fetchPermissionsTableRecipients;
