import styled from 'styled-components';

import withThemedControl from '../../procedural/withThemedControl/withThemedControl.style';

const ThemedLinkButton = styled(withThemedControl('button'))`
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;
`;

export default ThemedLinkButton;
