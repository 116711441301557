import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { NOTIFICATION_EVENTS_FIELD } from 'helpers/selectors';

export const fetchNotificationEventsForDropdown = (urlParams = {}) => ({
  type: constants.FETCH_NOTIFICATION_EVENTS_FOR_DROPDOWN,
  payload: {
    endpoint: endpoints.NOTIFICATION_EVENTS,
    resourceType: NOTIFICATION_EVENTS_FIELD,
    urlParams,
  },
});

export const fetchNotificationEventsForDropdownSuccess = response => ({
  type: constants.FETCH_NOTIFICATION_EVENTS_FOR_DROPDOWN_SUCCESS,
  payload: {
    response,
    resourceType: NOTIFICATION_EVENTS_FIELD,
  },
});

export const fetchNotificationEventsForDropdownFailure = error => ({
  type: constants.FETCH_NOTIFICATION_EVENTS_FOR_DROPDOWN_FAILED,
  payload: { error },
});

export default fetchNotificationEventsForDropdown;
