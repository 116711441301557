import constants from 'dispatcherConst';

const initialState = {
  deviceKey: null,
  deviceName: null,
  fetching: false,
  adding: false,
  error: false,
};

const addDeviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.ADD_DEVICE_REQUEST:
      return { ...state, adding: true };

    case constants.CLEAR_DEVICE_KEY:
      return { ...state, deviceKey: null, deviceName: null };

    case constants.REPLACE_DEVICE_TOKEN_REQUEST:
      return {
        ...state,
        deviceName: action.payload.params.values.logicalDeviceName,
        fetching: true,
        adding: false,
      };

    case constants.REPLACE_DEVICE_TOKEN_SUCCESS:
    case constants.ADD_DEVICE_SUCCESS: {
      const {
        deviceToken,
        cognitoUsername,
        cognitoPassword,
        userPoolId,
      } = action.payload.response.meta;
      const deviceKey = `${deviceToken}.${cognitoUsername}.${cognitoPassword}.${userPoolId}`;

      return { ...state, deviceKey, fetching: false };
    }

    case constants.REPLACE_DEVICE_TOKEN_FAILED:
      return { ...state, fetching: false };

    case constants.RENAME_DEVICE:
    case constants.FETCH_DEVICE_REQUEST:
      return { ...state, fetching: true };

    case constants.RENAME_DEVICE_SUCCESS:
    case constants.FETCH_DEVICE_SUCCESS:
      return { ...state, fetching: false, error: false };

    case constants.RENAME_DEVICE_FAILED:
    case constants.FETCH_DEVICE_FAILED:
      return { ...state, fetching: false, error: true };

    default:
      return state;
  }
};

export default addDeviceReducer;
