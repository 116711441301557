import { call, put } from 'redux-saga/effects';

import ProjectSuiteModel from 'models/ProjectSuiteModel';
import { postPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import { actionCreators as applicationUserActionCreators } from 'application/utilities/user';

import actionCreators from '../actionCreators';

function* addProjectSuite(action) {
  const {
    endpoint,
    params: {
      values,
      rejectForm,
      resolveForm,
    },
  } = action.payload;

  const body = ProjectSuiteModel.requestBody(values);

  const { response, error } = yield call(postPath, endpoint, body);

  const params = {
    response,
    error,
    resolveForm,
    rejectForm,
    successDisp: actionCreators.addProjectSuiteSuccess,
    failDisp: actionCreators.addProjectSuiteFailure,
  };

  yield call(processCall, params);

  if (response) {
    yield put(applicationUserActionCreators.fetchCurrentUser());
  }
}

export default addProjectSuite;
