import updateOccurrences from './updateOccurrences';

import {
  fetchOccurrences,
  fetchOccurrencesSuccess,
  fetchOccurrencesFailure,
} from './fetchOccurrences';

import toggleShowHiddenOccurrences from './toggleShowHiddenOccurrences';
import { addSelectedProjectId } from './addSelectedProjectId';
import { clearSelectedProjectId } from './clearSelectedProjectId';
import toggleShowHideMultiSiteOccurrences from './toggleShowHideMultiSiteOccurrences';

export default {
  updateOccurrences,
  fetchOccurrences,
  fetchOccurrencesSuccess,
  fetchOccurrencesFailure,
  toggleShowHiddenOccurrences,
  addSelectedProjectId,
  clearSelectedProjectId,
  toggleShowHideMultiSiteOccurrences,
};
