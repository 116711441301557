import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';

const MESSAGE = 'The Site has been created and an invite has been sent to the first user\'s email.';

function VoucherAlert({ isVisible }) {
  if (!isVisible) {
    return null;
  }

  return (
    <Message success>
      <Message.Header>Success</Message.Header>
      <p>
        {MESSAGE} <br />
        If this is your account please check your inbox to continue.
      </p>
    </Message>
  );
}

VoucherAlert.defaultProps = {
  isVisible: false,
};

VoucherAlert.propTypes = {
  isVisible: PropTypes.bool,
};

export default VoucherAlert;
