import isNil from 'lodash/isNil';

const formatTime = checkState => value => {
  if (isNil(value)) {
    return 0;
  }

  if (checkState === -1) {
    return parseFloat(value) / 1000;
  }

  if (checkState === 0) {
    return `${parseFloat(value) / 1000}.`;
  }

  if (checkState === 1) {
    return (parseFloat(value) / 1000).toFixed(1);
  }

  return (parseFloat(value) / 1000).toFixed(2);
};

export default formatTime;
