import { call } from 'redux-saga/effects';

import endpoints from 'data/api/endpoints';
import { putPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import ProjectSuiteModel from 'models/ProjectSuiteModel';

import actionCreators from '../actionCreators';

function* updateProjectSuite(action) {
  const {
    params: {
      values: { id: projectSuiteId },
      values,
      message,
      rejectForm,
      resolveForm,
    },
  } = action.payload;
  const endpoint = endpoints.PROJECT_SUITE(projectSuiteId);

  const body = ProjectSuiteModel.requestBody(values);

  const { response, error } = yield call(putPath, endpoint, body);

  const responseWithMessage = response && { ...response, message };

  const params = {
    response: responseWithMessage,
    error,
    resolveForm,
    rejectForm,
    successDisp: actionCreators.updateProjectSuiteSuccess,
    failDisp: actionCreators.updateProjectSuiteFailure,
  };

  yield call(processCall, params);
}

export default updateProjectSuite;
