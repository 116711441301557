import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { AddCompanyModalForm } from './components';

class AddCompany extends Component {

  constructor() {
    super();

    this.handleAddCompany = this.handleAddCompany.bind(this);
  }

  handleAddCompany(values) {
    const { onAddCompany } = this.props;

    return new Promise((resolveForm, rejectForm) => {
      onAddCompany({ values, resolveForm, rejectForm });
    });
  }

  render() {
    return (
      <AddCompanyModalForm onSubmit={this.handleAddCompany} />
    );
  }

}

AddCompany.propTypes = {
  onAddCompany: PropTypes.func.isRequired,
};

export default AddCompany;
