import { takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import verifyPasswordResetToken from './verifyPasswordResetToken';
import resetPassword from './resetPassword';

function* saga() {
  yield takeLatest(constants.RESET_PASSWORD_VERIFY_TOKEN, verifyPasswordResetToken);
  yield takeLatest(constants.RESET_PASSWORD, resetPassword);
}

export default saga;
