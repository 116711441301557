import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';

export const StyledDropdown = styled(Dropdown)`
  margin: 2em 1.5em 0 0;
`;

export const StyledLabel = styled.span`
  position: absolute;
  font-weight: bold;
`;
