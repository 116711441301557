import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  PROJECTS_FIELD,
  LOGICAL_DEVICES_FIELD,
  permissionsProjectLogicalDeviceIdsSelector,
} from 'helpers/selectors';
import endpoints from 'data/api/endpoints';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import projectActionCreators from 'pages/Projects/projectActionCreators';
import permissionsActionCreators from 'application/tenant/console/utilities/permissions/permissionsActionCreators';

import {
  selectors as newPermissionsSelectors,
} from 'components/PermissionsTable/utilities';

import ProjectPermissionsWrapper from './ProjectPermissionsWrapper';

export const mapStateToProps = (state, { projectId, forceOpen }) => {
  const logicalDeviceIds = permissionsProjectLogicalDeviceIdsSelector(state, { projectId });
  const logicalDevicesEndpoint = endpoints.LOGICAL_DEVICES_PROJECT(projectId);

  const isOpen = forceOpen || newPermissionsSelectors.isOpenSelector(state, projectId);

  return {
    isOpen,
    projectId,
    logicalDeviceIds,
    logicalDevicesEndpoint,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, null),
  chainedLoaderWithSaga(
    ({ projectId }) => projectActionCreators.fetchProject(projectId, {
      fields: {
        [PROJECTS_FIELD]: [
          'name',
          'state',
          LOGICAL_DEVICES_FIELD,
        ],
      },
    }),
    ({ projectId, logicalDevicesEndpoint }) => permissionsActionCreators.fetchProjectLogicalDevices(
      projectId,
      logicalDevicesEndpoint,
      {
        fields: {
          logicalDevices: ['name'],
        },
        sort: 'name',
      },
    ),
  ),

)(ProjectPermissionsWrapper);
