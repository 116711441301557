import styled from 'styled-components';

import { Button } from 'components/atomic';

const StyledButton = styled(Button)`
  &.ui.button {
    flex: 1;
    width: 100%;
    margin-bottom: 0.5em;
  }
`;

export default StyledButton;
