import constants from 'dispatcherConst';

const initialState = {
  fetching: false,
};

const fetchingGroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_DEVICE_GROUPS_REQUEST:
      return { ...state, fetching: true };

    case constants.FETCH_DEVICE_GROUPS_SUCCESS:
      return { ...state, fetching: false };

    case constants.FETCH_DEVICE_GROUPS_FAILED:
      return { ...state, fetching: false };

    default:
      return state;
  }
};

export default fetchingGroupsReducer;
