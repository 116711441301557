import { compose } from 'redux';
import { connect } from 'react-redux';

import { currentUserSelector } from 'helpers/selectors';
import { actionCreators } from './utilities';
import OneTimePassword from '../OneTimePassword/OneTimePassword';

const mapStateToProps = state => {
  const { twoFactorEnabled } = currentUserSelector(state);

  return {
    twoFactorEnabled,
  };
};

const mapDispatchToProps = {
  onSubmit: actionCreators.refreshOTPSecret,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(OneTimePassword);
