import isNil from 'lodash/isNil';

import { occurrenceStatus } from '../constants';

const checkFailure = status => {
  if (isNil(status)) {
    return false;
  }

  if (status === occurrenceStatus.FAILURE || status === occurrenceStatus.VALIDATION_ERROR) {
    return true;
  }

  return false;
};

export default checkFailure;
