import constants from 'dispatcherConst';

const initialState = { isUploading: false };

const controlPanelUploadsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.UPLOAD_PROJECT_PREPARE_REQUEST: {
      return {
        isUploading: true,
      };
    }

    case constants.UPLOAD_SUCCESS: {
      return {
        isUploading: false,
      };
    }

    case constants.UPLOAD_FAILED: {
      return {
        isUploading: false,
      };
    }

    default:
      return state;
  }
};

export default controlPanelUploadsReducer;
