import styled, { css } from 'styled-components';
import { Field } from 'redux-form';

import filterProps from 'helpers/filterProps';

const FilteredProps = filterProps([
  'editMode',
])(Field);

export const StyledField = styled(FilteredProps)`
  &.ui.dropdown {
    width: ${({ editMode }) => (editMode ? '200px' : 'auto')};  
  }
  @media only screen and (max-width: 991px) {
    &.ui.dropdown {
      width: ${({ editMode }) => (editMode ? '150px' : 'auto')};  
    }  
    &.ui.selection.dropdown .menu {
      ${({ editMode }) => editMode && css`
        border: 0px;
        position: relative;
      `}
    }
  }
`;

export const StyledLabel = styled.label`
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  margin: 0 0 .28571429rem 0;
  font-weight: 700;
`;

export default StyledField;
