import pick from 'lodash/pick';
import keys from 'lodash/keys';
import isNil from 'lodash/isNil';

const renderIconLabel = (obj, condition) => {
  if (isNil(condition)) {
    return obj;
  }

  const model = {
    name: null,
    status: null,
  };

  if (condition) {
    return pick(obj, keys(model));
  }

  return obj;
};

export default renderIconLabel;
