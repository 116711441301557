import constants from 'dispatcherConst';

export const initialState = {};

const handleLoaderFailure = (state, id) => {
  const currentLoader = state[id] || {};

  const timeoutCounter = currentLoader.timeoutCounter + 1;
  const { timeout } = currentLoader;
  const refreshing = (timeoutCounter < timeout);
  const interval = (refreshing) ? currentLoader.interval : 0;

  return {
    ...state,
    [id]: {
      ...state[id],
      timeoutCounter,
      refreshing,
      interval,
    },
  };
};

const handleLoaderSuccess = (state, id) => ({
  ...state,
  [id]: {
    ...state[id],
    timeoutCounter: 0,
  },
});

const pollingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.POLLING_INIT_V2: {
      return {
        ...state,
        [payload.id]: {
          ...payload,
          refreshing: true,
          timeoutCounter: 0,
        },
      };
    }

    case constants.POLLING_START_V2: {
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          refreshing: true,
          timeoutCounter: 0,
        },
      };
    }

    case constants.POLLING_STOP_V2: {
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          refreshing: false,
          timeoutCounter: 0,
        },
      };
    }

    case constants.POLLING_TERMINATE_V2: {
      const { [payload.id]: deleted, ...rest } = state;
      return rest;
    }

    case constants.POLLING_SET_INTERVAL_V2: {
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          interval: payload.interval,
          refreshing: (payload.interval === 0) ? false : state[payload.id].refreshing,
        },
      };
    }

    case constants.INTERRUPTED_CHAIN: {
      return handleLoaderFailure(state, payload.id);
    }

    case constants.COMPLETED_CHAIN: {
      return handleLoaderSuccess(state, payload.id);
    }

    default:
      return state;
  }
};

export default pollingReducer;
