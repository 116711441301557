import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const Gateway = ({ render, component: Component, children, ...rest }) => {
  if (render) {
    return render();
  }

  if (Component) {
    return <Component {...rest} />;
  }

  return (
    <Fragment>
      {children}
    </Fragment>
  );
};

Gateway.defaultProps = {
  render: null,
  component: null,
  children: null,
  condition: null,
  fallback: false,
};

Gateway.propTypes = {
  render: PropTypes.func,
  component: PropTypes.func,
  children: PropTypes.node,
  condition: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
    PropTypes.func,
  ]),
  fallback: PropTypes.bool,
};

export default Gateway;
