import { put, select, take, race, delay } from 'redux-saga/effects';
import isNull from 'lodash/isNull';

import constants from 'dispatcherConst';
import { singleProjectSelector } from 'helpers/selectors';
import projectActionCreators from 'pages/Projects/projectActionCreators';
import siteActionCreators from '../actionCreators';

export const progressiveTimings = [1, 5, 10, 30];

function* updateSiteLocationProperties(action) {
  const { response: { data: { id: siteId } } } = action.payload;

  let error;
  let locationSet;
  let geoAddress;
  let offset;
  let timezoneId;
  let count = 0;

  while (true) {
    ({
      locationSet,
      geoAddress,
      offset,
      timezoneId,
    } = yield select(singleProjectSelector, siteId));
    const shouldPoll = locationSet && (
      isNull(geoAddress)
      || isNull(offset)
      || isNull(timezoneId)
    );

    if (!shouldPoll) {
      break;
    }

    if (error) {
      yield put(siteActionCreators.unableToFetchLocation(siteId));
      break;
    }

    yield delay(progressiveTimings[count] * 1000);
    yield put(projectActionCreators.fetchProject(siteId));

    ({ error } = yield race({
      error: take(constants.FETCH_PROJECT_FAILED),
      success: take(constants.FETCH_PROJECT_SUCCESS),
    }));

    count += 1;
    error = error || count === progressiveTimings.length;
  }
}

export default updateSiteLocationProperties;
