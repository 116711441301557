import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'semantic-ui-react';
import { Field } from 'redux-form';

import * as validators from 'components/form/formValidators';
import TextField from 'components/form/TextField/TextField';

const ActionName = ({ submitting }) => (
  <FormGroup inline>
    <Field
      name="actionName"
      component={TextField}
      type="text"
      loading={submitting}
      label="Action Name"
      validate={validators.validActionName}
    />
  </FormGroup>
);

ActionName.defaultProps = {
  submitting: false,
};

ActionName.propTypes = {
  submitting: PropTypes.bool,
};

export default ActionName;
