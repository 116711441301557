import constants from 'dispatcherConst';

const projectSuiteIdContextReducer = (state = null, action) => {
  switch (action.type) {
    case constants.ADD_PROJECT_SUITE_ID_CONTEXT: {
      const { projectSuiteId } = action.payload;

      return projectSuiteId;
    }

    case constants.CLEAR_PROJECT_SUITE_ID_CONTEXT: {
      return null;
    }

    default:
      return state;
  }
};

export default projectSuiteIdContextReducer;
