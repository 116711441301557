import { compose } from 'redux';
import { connect } from 'react-redux';

import { frontendPermissions, permissionsPass } from 'gateway';
import siteActionCreators from '../../../../../../utilities/actionCreators';
import siteMapEmptyClickBehaviour from './siteMapEmptyClickBehaviour';

const mapDispatchToProps = {
  onFetchReverseGeocoding: siteActionCreators.fetchReverseGeocoding,
};

export default compose(
  connect(null, mapDispatchToProps),
  permissionsPass(frontendPermissions.EDIT_PROJECT),
  siteMapEmptyClickBehaviour,
);
