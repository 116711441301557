import { markerTypes } from '../../../../../../../constants';

const getStatusText = type => {
  if (type === markerTypes.ONLINE) {
    return 'All devices are online.';
  }

  if (type === markerTypes.PARTIAL_ONLINE) {
    return 'Some devices are online, others are offline.';
  }

  if (type === markerTypes.OFFLINE) {
    return 'All devices are offline.';
  }

  if (type === markerTypes.WARNING) {
    return 'Some devices are unassociated or need firmware upgrades.';
  }

  return 'This site has no devices reported.';
};

export default getStatusText;
