import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import isNil from 'lodash/isNil';

import { serverSideRegex, isValidRange } from 'components/form/formValidators';
import TextField from 'components/form/TextField/TextField';
import { TipIcon, FieldWrapper } from 'components/atomic';
import { StyledLabel } from './StringActionField.style';

const StringActionField = ({
  submitting,
  name,
  label,
  tip,
  tipText,
  regex,
  editMode,
  value,
  gteq,
  lteq,
}) => {
  const validator = useCallback(fieldValue => {
    if (regex) {
      const func = serverSideRegex(regex);

      return func(fieldValue);
    }

    if (!isNil(gteq) && !isNil(lteq)) {
      const funcRange = isValidRange(gteq, lteq);

      return funcRange(fieldValue);
    }

    return undefined;
  }, [regex, gteq, lteq]);

  const labelText = editMode ? '' : label;

  const renderTipIcon = () => {
    if (!tip) {
      return null;
    }

    return <TipIcon message={tipText} editMode={editMode} position="left center" />;
  };

  return (
    <FieldWrapper>
      <StyledLabel htmlFor="input">{labelText} {renderTipIcon()}</StyledLabel>
      <Field
        name={name}
        component={TextField}
        type="text"
        loading={submitting}
        validate={validator}
        value={value}
        editMode={editMode}
        autoFocus
      />
    </FieldWrapper>
  );
};

StringActionField.defaultProps = {
  submitting: false,
  tip: null,
  tipText: null,
  regex: undefined,
  editMode: false,
  value: null,
  gteq: null,
  lteq: null,
};

StringActionField.propTypes = {
  submitting: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tip: PropTypes.string,
  regex: PropTypes.string,
  editMode: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  tipText: PropTypes.string,
  gteq: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  lteq: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default StringActionField;
