import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { buildS3URL, buildObjectURL } from 'helpers/sharedMethods';
import { resourceUploadSelector } from 'components/ProjectFileUpload/projectFileUploadSelectors';
import { selectors, actionCreators } from '../../../../../../../../utilities';
import { getFileNameFromPath } from './utilities';
import UploadImageInput from './UploadImageInput';
import { isUploading } from './utilities/selectors';

export const makeMapState = () => function mapStateToProps(state, { match, pageId }) {
  const { id: projectId } = match.params;
  const fileIdentifier = `backgroundImage-${projectId}-${pageId}`;
  const { pages = [] } = selectors.projectControlUISelector(state)(projectId) || {};
  const {
    backgroundImg,
  } = pages.filter(({ index }) => index === pageId)[0] || {};

  const {
    files,
    systemFile,
  } = resourceUploadSelector(state)(fileIdentifier) || {};
  const { bucket = undefined, path = undefined } = files ? files[0] : {};
  const s3URL = buildS3URL(bucket, path);
  const localURL = buildObjectURL(systemFile);
  const fileName = getFileNameFromPath(backgroundImg);
  const fileIsUploading = isUploading(state);

  return {
    projectId,
    fileIdentifier,
    s3URL,
    localURL,
    fileName,
    fileIsUploading,
  };
};

const mapDispatchToProps = {
  onSetPageBackgroundImage: actionCreators.setPageBackgroundImage,
  onLoadPageBackgroundImage: actionCreators.loadPageBackgroundImage,
};

export default compose(
  withRouter,
  connect(makeMapState, mapDispatchToProps),
)(UploadImageInput);
