import { connect } from 'react-redux';

import { deepEqualMemoizeOne } from 'helpers/sharedMethods';
import { deviceSelector } from 'helpers/selectors';
import PathwayHardwareV1 from './PathwayHardwareV1';

export const makeMapState = () => {
  const memoModel = deepEqualMemoizeOne();
  const memoLabel = deepEqualMemoizeOne();
  const memoFirmwareVersion = deepEqualMemoizeOne();
  const memoSerial = deepEqualMemoizeOne();
  const memoPorts = deepEqualMemoizeOne();
  const memoPartner = deepEqualMemoizeOne();
  const memoUptime = deepEqualMemoizeOne();

  return function mapStateToProps(state, ownProps) {
    const { resourceId } = ownProps;
    const device = deviceSelector(state)(resourceId);
    const { online, reportedWithMeta } = device;

    const {
      hw: {
        md: model,
        sn: serial,
        up: uptime,
      } = {},
      set: {
        nm: label,
      } = {},
      sw: {
        fw: firmwareVersion,
      } = {},
      pathway: {
        lldpNm: partner,
        dmxP: ports = {},
      } = {},
    } = reportedWithMeta || {};

    return {
      loading: !reportedWithMeta,
      online,
      modelTitle: 'Pathport model',
      model: memoModel(model),
      label: memoLabel(label),
      firmwareVersion: memoFirmwareVersion(firmwareVersion),
      serial: memoSerial(serial),
      ports: memoPorts(Object.keys(ports).length),
      partner: memoPartner(partner),
      uptime: memoUptime(uptime),
    };
  };
};

export default connect(makeMapState)(PathwayHardwareV1);
