import constants from 'dispatcherConst';

export const setSelectedDevice = device => ({
  type: constants.SET_SELECTED_DEVICE,
  payload: {
    device,
  },
});

export default setSelectedDevice;
