import styled from 'styled-components';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Popup } from 'semantic-ui-react';

export const StyledMarker = styled.div`
  position: absolute;
  background-color: ${({ color }) => color};
  filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
  padding: 3px;
  border-radius: 15px;
  border: 1px solid ${({ color }) => color};
  bottom: 8px;
  left: -15px;
  min-width: 30px;
  min-height: 20px;
  color: #ffffff;
  font-size: 10px;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;

  &:after, &:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-top-color: ${({ color }) => color};
    border-top-width: 10px;
    border-right-width: 5px;
    border-bottom-width: 0px;
    border-left-width: 5px;
    left: 19px;
    top: 27px;
    margin-left: -10px;
  }

  &:before {
    border-top-color: ${({ color }) => color};
    border-top-width: 11px;
    border-right-width: 6px;
    border-bottom-width: 0px;
    border-left-width: 6px;
    left: 19px;
    margin-left: -11px;
  }
`;

export const StyledWarningMarker = styled.div`
  position: absolute;
  background-color: ${({ color }) => color};
  filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
  padding: 3px;
  border-radius: 15px;
  border: 1px solid ${({ color }) => color};
  bottom: 8px;
  left: -15px;
  min-width: 30px;
  min-height: 20px;
  color: white;
  text-align: center;
  font-size: 10px;
  text-indent: 13px;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;

  &:after, &:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-top-color: ${({ color }) => color};
    border-top-width: 10px;
    border-right-width: 5px;
    border-bottom-width: 0px;
    border-left-width: 5px;
    left: 19px;
    top: 27px;
    margin-left: -10px;
  }

  &:before {
    border-top-color: ${({ color }) => color};
    border-top-width: 11px;
    border-right-width: 6px;
    border-bottom-width: 0px;
    border-left-width: 6px;
    left: 19px;
    margin-left: -11px;
  }
`;

export const StyledExclamationTriangle = styled(FaExclamationTriangle)`
  display: inline-block;
  position: absolute;
  top: 7px;
  left: 5px;

  @-moz-document {
    top: 8px;
  }
`;

export const StyledPopup = styled(Popup)`
  height: 170px;
  height: ${({ type }) => type === null && '65px;'}
`;

export default StyledMarker;
