import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const LinkedFilesRow = ({ name, description }) => (
  <Table.Row>
    <Table.Cell>{name}</Table.Cell>
    <Table.Cell>{description}</Table.Cell>
  </Table.Row>
);

LinkedFilesRow.defaultProps = {
  name: '',
  description: '',
};

LinkedFilesRow.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
};

export default LinkedFilesRow;
