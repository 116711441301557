import React, { Fragment } from 'react';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { StyledHeader } from './EnableConstructionMode.style';
import { EnableButtonForm } from './components';

const EnableConstructionMode = ({
  onUpdateSiteConstruction,
  siteId,
  property,
  isConstruction,
  isDemo,
}) => {
  if (isConstruction || isDemo) {
    return null;
  }

  const handleUpdateSiteConstruction = () => {
    const formValue = {
      [property]: true,
    };

    return new Promise((resolveForm, rejectForm) => {
      onUpdateSiteConstruction({
        values: { id: siteId, ...formValue },
        resolveForm,
        rejectForm,
      });
    });
  };
  const form = `editSiteProperty.${property}`;

  return (
    <Fragment>
      <Grid.Column width={8}>
        <StyledHeader as="h5">Construction mode</StyledHeader>
      </Grid.Column>
      <Grid.Column width={8}>
        <EnableButtonForm
          name={property}
          form={form}
          onSubmit={handleUpdateSiteConstruction}
          initialValues={{
            [property]: true,
          }}
        />
      </Grid.Column>
    </Fragment>
  );
};

EnableConstructionMode.propTypes = {
  onUpdateSiteConstruction: PropTypes.func.isRequired,
  siteId: PropTypes.string.isRequired,
  property: PropTypes.string.isRequired,
  isDemo: PropTypes.bool.isRequired,
  isConstruction: PropTypes.bool.isRequired,
};

export default EnableConstructionMode;
