import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledPermissionsGridHeaders,
} from './PermissionsGridHeaders.style';

const PermissionsGridHeaders = ({ resourceId, userIds, UserLabel, ...rest }) => (
  <StyledPermissionsGridHeaders>
    {userIds && userIds.map(userId => (
      <UserLabel
        key={userId}
        userId={userId}
        resourceId={resourceId}
        {...rest}
      />
    ))}
  </StyledPermissionsGridHeaders>
);

PermissionsGridHeaders.defaultProps = {
  userIds: null,
};

PermissionsGridHeaders.propTypes = {
  resourceId: PropTypes.string.isRequired,
  userIds: PropTypes.array,
  UserLabel: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
};

export default PermissionsGridHeaders;
