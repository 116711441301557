import { takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import fetchProjectsForMap from './fetchProjectsForMap';

function* saga() {
  yield takeLatest(constants.FETCH_PROJECTS_FOR_MAP_REQUEST, fetchProjectsForMap);
}

export {
  saga,
};

export default saga;
