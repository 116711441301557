import React from 'react';
import PropTypes from 'prop-types';
import StatusIcon from 'components/StatusIcon/StatusIcon';
import DeviceSettingValue from 'pages/Device/DeviceSettingValue/DeviceSettingValueContainer';
import { pathwayDeviceNETValueSelector } from 'helpers/selectors';
import { linkStatus } from 'helpers/sharedMethods';
import TimeIncremented from 'components/elements/TimeIncremented/TimeIncremented';

const PathwayVIALinkStatus = ({ online, element: { resourceId, id } }) => (
  <React.Fragment>
    <DeviceSettingValue
      resourceId={resourceId}
      selector={pathwayDeviceNETValueSelector(id, 'up')}
      render={value => (
        <TimeIncremented
          value={value}
          render={val => (<div>Duration: {val}</div>)}
          isOnline={false}
        />)
      }
    />
    <DeviceSettingValue
      resourceId={resourceId}
      selector={pathwayDeviceNETValueSelector(id, 'link')}
      render={value => (
        <div>
          Link:
          <StatusIcon
            model="device"
            name="circle"
            status={linkStatus(online, value)}
          />
        </div>
      )}
    />
  </React.Fragment>
);

PathwayVIALinkStatus.defaultProps = {
  online: false,
};

PathwayVIALinkStatus.propTypes = {
  online: PropTypes.bool,
  element: PropTypes.shape({
    resourceId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default PathwayVIALinkStatus;
