import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { StyledDropdown, StyledLabel } from './ActionTypesDropdown.style';

const ActionTypesDropdown = ({ actionsList, setActionType }) => {
  const [searchQueryState, setSearchQueryState] = useState('');

  const handleActionTypes = (e, { value }) => setActionType(value);
  const handleSearchChange = (e, { searchQuery }) => setSearchQueryState(searchQuery);

  return (
    <div>
      <StyledLabel>Limit actions</StyledLabel>
      <StyledDropdown
        text="Action types"
        clearable
        options={actionsList}
        selection
        selectOnBlur={false}
        multiple
        onChange={handleActionTypes}
        search
        onSearchChange={handleSearchChange}
        searchQuery={searchQueryState}
      />
    </div>
  );
};

ActionTypesDropdown.propTypes = {
  actionsList: PropTypes.array.isRequired,
  setActionType: PropTypes.func.isRequired,
};

export default ActionTypesDropdown;
