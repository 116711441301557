import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  pollingRequestIdSelector,
  pollingUpdatedSelector,
  pollingValueSelector,
} from 'helpers/selectors';
import withConfigurablePolling from './withConfigurablePolling';

const mapStateToProps = loader => state => ({
  pollingUpdated: pollingUpdatedSelector(state, loader),
  pollingValue: pollingValueSelector(state, loader),
  pollingRequestId: pollingRequestIdSelector(state, loader),
});

export default loader => compose(
  connect(mapStateToProps(loader)),
  withConfigurablePolling(loader),
);
