import { compose } from 'redux';
import { connect } from 'react-redux';

import { companySelector } from 'helpers/selectors';
import { deepEqualMemoizeOne } from 'helpers/sharedMethods';
import OriginTable from './OriginTable';

export const makeMapState = () => {
  const memoOrigins = deepEqualMemoizeOne();

  return function mapStateToProps(state, { companyId }) {
    const { origins } = companySelector(state)(companyId) || {};

    return {
      origins: memoOrigins(origins),
    };
  };
};

export default compose(
  connect(makeMapState),
)(OriginTable);
