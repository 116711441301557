import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';

import menuActionCreators from 'components/TopBar/MainMenu/menuActionCreators';
import MenuItem from './MenuItem';

const mapDispatchToProps = {
  onHideMenu: menuActionCreators.hideMenu,
};

export default compose(
  withRouter,
  withTheme,
  connect(null, mapDispatchToProps),
)(MenuItem);
