import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Dropdown, FormGroup } from 'semantic-ui-react';

import * as validators from 'components/form/formValidators';
import TextField from 'components/form/TextField/TextField';
import ModalForm from 'components/form/ModalForm/ModalForm';
import { taskFormFields } from '../../../../constants';
import { StyledButtonLink } from './EditTaskModalForm.style';

const EditTaskModalForm = ({
  submitting,
  mobileVersion,
  item,
  ...rest
}) => {
  const header = `Edit task ${item.name}`;
  const trigger = mobileVersion
    ? <Dropdown.Item>Edit</Dropdown.Item>
    : <StyledButtonLink>Edit</StyledButtonLink>;

  return (
    <ModalForm
      header={header}
      trigger={trigger}
      size="small"
      submitting={submitting}
      {...rest}
    >
      <FormGroup inline>
        <Field
          name={taskFormFields.name}
          component={TextField}
          type="text"
          loading={submitting}
          label="Name"
          validate={validators.validDisplayName}
        />
      </FormGroup>
      <FormGroup inline>
        <Field
          name={taskFormFields.description}
          component={TextField}
          type="text"
          loading={submitting}
          label="Description"
        />
      </FormGroup>
    </ModalForm>
  );
};

EditTaskModalForm.defaultProps = {
  mobileVersion: false,
  item: null,
};

EditTaskModalForm.propTypes = {
  submitting: PropTypes.bool.isRequired,
  mobileVersion: PropTypes.bool,
  item: PropTypes.object,
};

export default EditTaskModalForm;
