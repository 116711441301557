import mergeWith from 'lodash/mergeWith';

import constants from 'dispatcherConst';
import { mergeCustomizer } from 'helpers/sharedMethods';

const deviceFirmwaresReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.FETCH_DEVICE_FIRMWARES_SUCCESS: {
      const { response } = action.payload;
      const { data } = action.payload.response;

      const reducer = (newArray, currentValue) => {
        const {
          attributes,
          relationships: {
            files: { data: filesData },
          },
        } = currentValue;

        const updatedValue = {
          ...currentValue,
          attributes: {
            ...attributes,
            files: filesData,
          },
        };

        return [...newArray, updatedValue];
      };

      const updatedData = Array.isArray(data) ? data.reduce(reducer, []) : data;
      const updatedResponse = {
        ...response,
        data: updatedData,
      };

      return mergeWith({}, state, updatedResponse, mergeCustomizer);
    }

    default:
      return state;
  }
};

export default deviceFirmwaresReducer;
