import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import ControlUIPageModel from 'models/ControlUIPageModel';

const getPageAndDuplicate = (pagesList, pageIndex, paginationIndex) => {
  if (isNil(pagesList) || isEmpty(pagesList)) {
    return [];
  }

  if (isNil(pageIndex)) {
    return pagesList;
  }

  const getPage = pagesList.filter(({ index }) => index === pageIndex)[0];

  if (isNil(paginationIndex) || (paginationIndex >= pagesList.length + 1)) {
    const calculateIndex = pagesList.length;
    const duplicatedPage = new ControlUIPageModel({ ...getPage, index: calculateIndex });

    return [
      ...pagesList,
      duplicatedPage,
    ];
  }

  const newPagesList = [...pagesList];
  newPagesList.splice(paginationIndex - 1, 0, getPage);
  const pagesWithUpdatedIndexes = newPagesList.map(({ index, ...page }, newIndex) => ({
    index: newIndex,
    ...page,
  }));

  return pagesWithUpdatedIndexes;
};

export default getPageAndDuplicate;
