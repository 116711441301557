import { connect } from 'react-redux';

import { actionCreators } from '../../utilities';

import ProjectSuitesActionLinks from './ProjectSuitesActionLinks';

const mapDispatchToProps = {
  onDeleteProjectSuite: actionCreators.deleteProjectSuite,
};

export default connect(null, mapDispatchToProps)(ProjectSuitesActionLinks);
