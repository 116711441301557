import React from 'react';
import PropTypes from 'prop-types';

import StyledList from './DataTableMultiple.style';

const DatTableMultiple = ({ field, item }) => {
  const list = item[field] || [];

  return (
    <StyledList>
      {list.map(({ name }) => <li key={name}>{name}</li>)}
    </StyledList>
  );
};

DatTableMultiple.defaultProps = {
  field: null,
  item: {},
};

DatTableMultiple.propTypes = {
  field: PropTypes.string,
  item: PropTypes.object,
};

export default DatTableMultiple;
