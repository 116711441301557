import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

import FormField from 'components/form/FormField/FormField';
import {
  StyledInput,
  StyledButton,
  StyledLoader,
} from './SelectColor.style';

class SelectColor extends Component {

  constructor() {
    super();

    this.state = {
      chosenColor: '',
    };
    this.selectColor = this.selectColor.bind(this);
  }

  componentDidMount() {
    const { existingColor, input: { onChange } } = this.props;

    onChange(existingColor);
    this.setState({
      chosenColor: existingColor,
    });
  }

  selectColor(e, color) {
    e.preventDefault();
    const { input: { onChange } } = this.props;

    this.setState({
      chosenColor: color,
    });
    onChange(color);
  }

  render() {
    const { chosenColor } = this.state;
    const { existingColor, colorList, loading, ...rest } = this.props;

    return (
      <FormField
        {...this.props}
      >
        <Fragment>
          <StyledInput
            onClick={this.selectColor}
            {...rest}
          />
          {colorList.map(item => (
            <StyledButton
              key={item}
              buttonColor={item}
              isSelected={(existingColor === null && item === '#FFFFFF') || item === chosenColor}
              onClick={e => this.selectColor(e, item)}
            />
          ))}
          <StyledLoader active={loading} inline />
        </Fragment>
      </FormField>
    );
  }

}

SelectColor.defaultProps = {
  colorList: ['#ff7b7b', '#ffff00'],
  existingColor: '',
};

SelectColor.propTypes = {
  colorList: PropTypes.arrayOf(PropTypes.string),
  existingColor: PropTypes.string,
  ...Input.propTypes,
};

export default SelectColor;
