import React, { useState, useCallback, useEffect } from 'react';
import { Input } from 'semantic-ui-react';

import ThemedToggle from 'components/elements/ThemedToggle/ThemedToggle.style';
import FormField from 'components/form/FormField/FormField';

import { StyledInput } from './PriorityType.style';

function PriorityType({ input, ...restProps }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedPriorityType, setSelectedPriorityType] = useState(null);

  useEffect(() => {
    const { value } = input;

    if (value && !isLoaded) {
      setSelectedPriorityType(value);
      setIsLoaded(true);
    }
  }, [input, isLoaded]);

  const handleChange = useCallback((event, { checked }) => {
    const { onChange } = input;

    const actualPropertyType = checked ? 'dates' : 'times';

    setSelectedPriorityType(actualPropertyType);
    onChange(actualPropertyType);
  }, [input]);

  return (
    <FormField input={input} {...restProps}>
      <>
        <StyledInput onClick={handleChange} input={input} {...restProps} />
        <ThemedToggle name="priority-type-toggle" checked={selectedPriorityType === 'dates'} onChange={handleChange} />
      </>
    </FormField>
  );
}

PriorityType.propTypes = {
  ...Input.propTypes,
};

export default PriorityType;
