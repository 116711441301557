import React from 'react';
import PropTypes from 'prop-types';

import { labelsType } from 'data/types';
import DeviceModel from 'models/DeviceModel';
import DataTable from 'components/DataTable/DataTableContainer';
import { SNAPSHOTS_FIELD } from 'helpers/selectors';
import RefreshInterface from 'components/RefreshInterface/RefreshInterfaceContainer';
import SnapshotTableRow from './SnapshotTableRow/SnapshotTableRowContainer';


const SnapshotsSection = ({ deviceId, resource: { online }, title, labels, refreshing }) => {
  const refreshUI = (
    <RefreshInterface
      deviceId={deviceId}
      dataId="snapshots"
    />
  );

  return (
    <DataTable
      header={title}
      labels={labels}
      customTableRow={SnapshotTableRow}
      createElement={refreshUI}
      pagination={{
        type: SNAPSHOTS_FIELD,
        included: true,
      }}
      deviceId={deviceId}
      isOnline={online}
      refreshing={refreshing}
    />
  );
};

SnapshotsSection.defaultProps = {
  title: '',
  refreshing: false,
};

SnapshotsSection.propTypes = {
  deviceId: PropTypes.string.isRequired,
  labels: labelsType.isRequired,
  refreshing: PropTypes.bool,
  resource: PropTypes.instanceOf(DeviceModel).isRequired,
  title: PropTypes.string,
};

export default SnapshotsSection;
