import styled from 'styled-components';
import { Input } from 'semantic-ui-react';

export const StyledInput = styled(Input)`
  &.ui.input>input {
    cursor: pointer;
  }

  &.ui.input>input:hover {
    border: dashed 1px #000000;
    border-radius: 0px;
  }
`;

export default StyledInput;
