import React, {
  Component,
  Children,
  cloneElement,
  Fragment,
} from 'react';
import PropTypes from 'prop-types';
import { Accordion, Icon } from 'semantic-ui-react';

class DataAccordion extends Component {

  constructor(props) {
    super(props);

    const { open } = props;

    this.state = { activeIndex: open ? 0 : -1 };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e, titleProps) {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;
    const { children, headerAs } = this.props;

    return (
      <Accordion fluid>
        {Children.map(children, (child, index) => {
          const isActive = activeIndex === index;
          const { props: { header, withCount }, props } = child;
          const dataToCount = props[withCount];
          const count = dataToCount ? dataToCount.length : 0;

          return (
            <Fragment>
              <Accordion.Title
                as={headerAs}
                className="ui"
                active={isActive}
                index={index}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />
                {header}
                {withCount ? ` (${count})` : null}
              </Accordion.Title>
              <Accordion.Content active={isActive}>
                {cloneElement(child, { header: null })}
              </Accordion.Content>
            </Fragment>
          );
        })}
      </Accordion>
    );
  }

}

DataAccordion.defaultProps = {
  open: false,
  headerAs: 'h2',
};

DataAccordion.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node.isRequired,
  headerAs: PropTypes.string,
};

export default DataAccordion;
