import React from 'react';

import ThemedLinkButton from '../ThemedLinkButton/ThemedLinkButton.style';

const PortalThemedLinkButton = props => (
  <ThemedLinkButton
    as="button"
    className="loginLinkStyle"
    {...props}
  />
);

export default PortalThemedLinkButton;
