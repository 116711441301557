import React from 'react';
import PropTypes from 'prop-types';

import { PermissionsSwitch, Gateway, frontendPermissions } from 'gateway';
import { Button } from 'components/atomic';
import ConfirmModal from 'components/elements/ConfirmModal/ConfirmModal';
import { getDisplayName } from 'helpers/sharedMethods';

const withRunTask = WrappedComponent => {

  const WithRunTask = props => {
    const {
      item: { name, id },
      onRunTask,
      superSites,
    } = props;

    const trigger = <WrappedComponent title="Run" />;
    const handleConfirm = () => onRunTask(id);

    const permission = superSites
      ? frontendPermissions.PROJECT_SUITE_TASKS_EXECUTE
      : frontendPermissions.EXECUTE_TASK;

    return (
      <PermissionsSwitch>
        <Gateway condition={permission}>
          <ConfirmModal
            trigger={trigger}
            cancelButton="Cancel"
            confirmButton={<Button className="ui green button" primary={false}>Run Task</Button>}
            content={(
              <div>
                <p>
                  Run the task <strong>{name}</strong>?
                </p>
              </div>
            )}
            header="Run the task"
            onConfirm={handleConfirm}
          />
        </Gateway>
      </PermissionsSwitch>
    );
  };

  WithRunTask.defaultProps = {
    superSites: false,
  };

  WithRunTask.propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
    }).isRequired,
    onRunTask: PropTypes.func.isRequired,
    superSites: PropTypes.bool,
  };

  WithRunTask.displayName = `WithRunTask(${getDisplayName(WrappedComponent)})`;

  return WithRunTask;

};

export default withRunTask;
