import isString from 'lodash/isString';

const camelCaseToString = str => {
  if (isString(str)) {
    const text = str.replace(/([A-Z])/g, ' $1');

    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  return str;
};

export default camelCaseToString;
