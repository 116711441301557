import { Message } from 'semantic-ui-react';
import styled from 'styled-components';

import { injectStyles } from 'themes/mixins';

const StyledMessage = styled(Message)`
  ${({ theme: { notifications } }) => injectStyles(notifications)};
`;

export default StyledMessage;
