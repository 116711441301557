import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { paginationType } from 'data/types';
import { getDisplayName } from 'helpers/sharedMethods';
import UrlParams from 'helpers/UrlParams';
import { DEFAULT_PAGE_SIZE } from 'helpers/paginationHelpers';
import PaginationControls from 'components/PaginationControls/PaginationControls';

const withPagination = WrappedComponent => class extends Component {

  static displayName = `WithPagination(${getDisplayName(WrappedComponent)})`;

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    fetching: PropTypes.bool,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
    page: PropTypes.shape({
      number: PropTypes.number,
      size: PropTypes.number,
      total: PropTypes.number,
    }),
    pagination: paginationType,
    refetch: PropTypes.bool,
    onRefetch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    fetching: false,
    page: {},
    pagination: null,
    refetch: false,
  };

  constructor() {
    super();

    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {
      refetch: prevRefetch,
      fetching: prevFetching,
      location: { search: prevSearch },
    } = prevProps;
    const {
      refetch,
      fetching,
      location: { search },
      page: { number, size, total },
      pagination: { type },
      pagination,
      onRefetch,
      componentName,
    } = this.props;

    if (prevFetching && !fetching && (number > total)) {
      this.handlePageChange(null, { activePage: total }, true);

      setTimeout(() => {
        onRefetch(type, { page: { number: total } }, componentName);
      }, 0);
    }

    if (!fetching && refetch && refetch !== prevRefetch) {
      onRefetch(type);
    }

    if (prevSearch !== search) {
      const urlParams = new UrlParams({ pagination });
      const { activePage, pageSize } = urlParams.extractUrlParams({
        activePage: urlParams.pageParam,
        pageSize: urlParams.pageSizeParam,
      });

      const activePageNumber = Number(activePage || 1);
      if (number && number !== activePageNumber) {
        onRefetch(type, { page: { number: activePageNumber } }, componentName);
      }

      const pageSizeNumber = Number(pageSize || DEFAULT_PAGE_SIZE);
      if (size && size !== pageSizeNumber) {
        onRefetch(type, { page: { size: pageSizeNumber } }, componentName);
      }
    }
  }

  handlePageChange(e, { activePage }, forceRefetch) {
    const {
      page: { number },
      pagination,
      history,
      location,
    } = this.props;

    const isEqual = activePage === number;
    if (isEqual && !forceRefetch) return;

    const urlParams = new UrlParams({ pagination });
    urlParams.page = activePage;
    const { search } = urlParams;

    history.push({ ...location, search });
  }

  handlePageSizeChange(pageSize) {
    return event => {
      event.preventDefault();

      const {
        page: { size },
        pagination,
        history,
        location,
      } = this.props;
      const isEqual = pageSize === size;
      if (isEqual) return;

      const urlParams = new UrlParams({ pagination });
      urlParams.size = pageSize;
      const { search } = urlParams;

      history.push({ ...location, search });
    };
  }

  render() {
    const {
      fetching,
      page,
      refetch,
      ...rest
    } = this.props;

    return (
      <Fragment>
        <WrappedComponent {...rest} />
        <PaginationControls
          {...page}
          onPageSizeChange={this.handlePageSizeChange}
          onPageChange={this.handlePageChange}
        />
      </Fragment>
    );
  }

};

export default withPagination;
