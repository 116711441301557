import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { USERS_FIELD } from 'helpers/selectors';
import withPolling from 'helpers/withPolling';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { addPagination } from 'helpers/paginationHelpers';
import paginationActionCreators from 'data/paginationActionCreators';
import projectActionCreators from '../../Projects/projectActionCreators';
import ProjectUsersTab from './ProjectUsersTab';

const mapStateToProps = (state, { match }) => {
  const { id: projectId } = match.params;

  return {
    projectId,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withPolling(),
  chainedLoaderWithSaga(
    ({ projectId }) => projectActionCreators.fetchProject(projectId, {
      fields: { projects: ['name', 'state', USERS_FIELD] },
    }),
    addPagination(
      { resourceType: USERS_FIELD },
      paginationActionCreators.fetchPageResource({
        fields: {
          users: [
            'firstName',
            'lastName',
            'email',
            'lastActionTime',
            'lastLoginTime',
            'superadmin',
            'suspended',
            'receivedInvitations',
          ],
          invitations: ['id', 'projectId'],
        },
        include: ['receivedInvitations'],
        sort: 'firstName',
        filter: 'name',
      }),
    ),
  ),
)(ProjectUsersTab);
