import React from 'react';
import PropTypes from 'prop-types';

import InlineEdit from 'components/form/InlineEdit/InlineEditContainer';

const DataTableEditable = ({
  field,
  item: { id },
  item,
  validator,
  type,
  onSubmit,
  preventPropagation,
}) => (
  <InlineEdit
    name={field}
    value={item[field]}
    onSubmit={onSubmit(id)}
    validator={validator}
    type={type}
    form={`inlineEdit_${field}_${id}`}
    preventPropagation={preventPropagation}
  />
);

DataTableEditable.defaultProps = {
  field: null,
  item: {},
  type: undefined,
  validator: null,
  preventPropagation: false,
};

DataTableEditable.propTypes = {
  field: PropTypes.string,
  item: PropTypes.object,
  validator: PropTypes.func,
  type: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  preventPropagation: PropTypes.bool,
};

export default DataTableEditable;
