import { call, put } from 'redux-saga/effects';

import { API_DOMAIN } from 'storageConst';
import { fetchBackendVersion } from '../actionCreators/fetchBackendVersion';

export function changeApiDomain(action) {
  const { apiDomain } = action.payload;

  if (apiDomain === process.env.REACT_APP_API_URL) {
    localStorage.removeItem(API_DOMAIN);
  } else {
    localStorage.setItem(API_DOMAIN, apiDomain);
  }
}

function* handleChangeApiDomain(action) {
  yield call(changeApiDomain, action);
  yield put(fetchBackendVersion());
}

export default handleChangeApiDomain;
