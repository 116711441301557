import React from 'react';
import PropTypes from 'prop-types';
import { fieldPropTypes } from 'redux-form';
import { FormGroup } from 'semantic-ui-react';

import { getDisplayName } from 'helpers/sharedMethods';

const withLabel = WrappedComponent => {
  const WithLabel = ({ id, label, ...rest }) => (
    <FormGroup inline>
      {label && <label htmlFor={id}>{label}</label>}
      <WrappedComponent id={id} {...rest} />
    </FormGroup>
  );

  WithLabel.displayName = `WithLabel(${getDisplayName(WrappedComponent)})`;

  WithLabel.defaultProps = {
    label: null,
  };

  WithLabel.propTypes = {
    ...fieldPropTypes,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
  };

  return WithLabel;
};

export default withLabel;
