import React from 'react';
import PropTypes from 'prop-types';

const FlatIcon = ({ name, className }) => (
  <i className={`icon-${name} ${className}`} />
);

FlatIcon.defaultProps = {
  className: '',
};

FlatIcon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default FlatIcon;
