import React from 'react';
import PropTypes from 'prop-types';

import { StyledSiteMapMarker, StyledIconImage } from './ProjectSuiteMapMarker.style';

const ProjectSuiteMapMarker = ({ innerRef }) => (
  <StyledSiteMapMarker ref={innerRef}>
    <StyledIconImage />
  </StyledSiteMapMarker>
);

ProjectSuiteMapMarker.propTypes = {
  innerRef: PropTypes.object.isRequired,
};

export default ProjectSuiteMapMarker;
