import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import * as actionCreators from './utilities/actionCreators';
import { selectors } from './utilities';

import MarkerDevicesTable from './MarkerDevicesTable';

export const mapStateToProps = state => {
  const isLoading = selectors.areSiteDevicesLoadingSelector(state);

  return {
    isLoading,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoaderWithSaga(({ id: projectId, type }) => type && actionCreators.fetchSiteDevices(
    projectId,
    {},
  )),
)(MarkerDevicesTable);
