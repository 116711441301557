import styled, { css } from 'styled-components';
import { Header } from 'semantic-ui-react';

import filterProps from 'helpers/filterProps';

const HeaderFilteredProps = filterProps([
  'withPadding',
])(Header);

export const StyledHeader = styled(HeaderFilteredProps)`
  ${({ withPadding }) => withPadding && css`
    padding: 1em !important;
  `}

  margin-bottom: 0px !important;
`;

export const StyledSubheader = styled(Header.Subheader)`
  margin-top: 0.2em !important;
`;

export default StyledSubheader;
