import React, { Component } from 'react';

import { getDisplayName } from 'helpers/sharedMethods';

const withPropWatcher = ({ comparator, timeout = 5000 }) => WrappedComponent => {
  class PropWatcher extends Component {

    // eslint-disable-next-line react/static-property-placement
    static displayName = `withPropWatcher(${getDisplayName(WrappedComponent)})`;

    constructor() {
      super();

      this.state = { isMarked: false };
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
      if (comparator(this.props, nextProps)) {
        this.handleMark();
      }
    }

    componentWillUnmount() {
      clearTimeout(this.timeout);
    }

    handleMark() {
      this.setState({
        isMarked: true,
      },
      this.handleUnmark,
      );
    }

    handleUnmark() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(
        () => this.setState({ isMarked: false }),
        timeout,
      );
    }

    render() {
      const { isMarked } = this.state;

      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WrappedComponent {...this.props} isMarked={isMarked} />;
    }

  }

  return PropWatcher;
};

export default withPropWatcher;
