import { ORIGINS_FIELD } from 'helpers/selectors';
import ApiRequestBuilder from 'helpers/ApiRequestBuilder';

class OriginModel {

  constructor({ id, name }, urlOriginId) {
    this.id = id;
    this.name = name;

    this.url = id === urlOriginId;
  }

  static requestBody(values) {
    return new ApiRequestBuilder(ORIGINS_FIELD)
      .setAttributes(values);
  }

}

export default OriginModel;
