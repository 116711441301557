import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { TaskList, TaskListPlaceholder } from './components';
import { Container, EmptyMessage } from './TaskHistory.style';

function TaskHistory({
  tasks,
  selectedTasks,
  isLoadingTasks,
  onTaskSelected,
}) {

  const handleTaskToggle = useCallback(task => {
    onTaskSelected(task);
  }, [onTaskSelected]);

  if (isLoadingTasks) {
    return <TaskListPlaceholder />;
  }

  if (isEmpty(tasks)) {
    return (
      <Container flex>
        <EmptyMessage>
          No Tasks were found for this site.
        </EmptyMessage>
      </Container>
    );
  }

  return (
    <TaskList
      tasks={tasks}
      selectedTasks={selectedTasks}
      onTaskToggle={handleTaskToggle}
    />
  );
}

TaskHistory.defaultProps = {
  tasks: [],
  selectedTasks: [],
  isLoadingTasks: false,
};

TaskHistory.propTypes = {
  tasks: PropTypes.array,
  selectedTasks: PropTypes.arrayOf(PropTypes.object),
  isLoadingTasks: PropTypes.bool,
  onTaskSelected: PropTypes.func.isRequired,
};

export default TaskHistory;
