import constants from 'dispatcherConst';

export const setUniverse = universeId => ({
  type: constants.SET_UNIVERSE,
  payload: {
    universeId,
  },
});

export default setUniverse;
