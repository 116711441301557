import { actionTypes } from './constants';

const actionsListToDropdownOptions = (list, superadmin) => {
  if (Array.isArray(list)) {
    let filterList = [];

    if (!superadmin) {
      filterList = list.filter(item => actionTypes[item] && actionTypes[item].hidden !== true);
    }

    if (superadmin) {
      filterList = list.filter(
        item => (actionTypes[item] && actionTypes[item].hidden !== true)
        || (actionTypes[item] && actionTypes[item].superadminOnly === true),
      );
    }

    return filterList.map(item => ({
      key: item,
      text: actionTypes[item].text,
      value: item,
    }));
  }

  return [];
};

export default actionsListToDropdownOptions;
