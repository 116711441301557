import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DateTime } from 'luxon';

import { singleProjectSuiteSelector } from 'helpers/selectors';
import { actionCreators } from 'pages/ProjectSuites/utilities';

import ProjectSuiteMapSection from './ProjectSuiteMapSection';

export const mapStateToProps = (state, { match }) => {
  const { id: projectSuiteId } = match.params;
  const {
    locationLoaded,
    locationSet,
    latitude,
    longitude,
    geoAddress,
    offset,
    timezoneId,
    dateBlockingStartTime,
  } = singleProjectSuiteSelector(state, projectSuiteId) || {};
  const date = DateTime.utc().setZone(timezoneId);

  const currentProjectSuiteDate = date.toLocaleString({ locale: 'en-gb' });
  const currentProjectSuiteTime = date.toLocaleString(DateTime.TIME_SIMPLE);

  return {
    projectSuiteId,
    locationLoaded,
    locationSet,
    latitude,
    longitude,
    geoAddress,
    offset,
    timezoneId,
    currentProjectSuiteDate: timezoneId ? currentProjectSuiteDate : null,
    currentProjectSuiteTime: timezoneId ? currentProjectSuiteTime : null,
    dateBlockingStartTime,
  };
};

const mapDispatchToProps = {
  onUpdateProjectSuite: actionCreators.updateProjectSuite,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ProjectSuiteMapSection);
