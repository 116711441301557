import styled from 'styled-components';
import { Button } from 'components/atomic';
import { FlatIcon } from 'components/elements';

export const StyledReloadButton = styled(Button)`
  background: transparent !important;
`;

export const StyledReloadIcon = styled(FlatIcon)`
  font-size: 1.5em;
`;
