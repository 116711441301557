import { connect } from 'react-redux';

import { actionCreators } from '../utilities';
import { copiedButtonSelector } from '../utilities/selectors';
import CancelButton from './CancelButton';

export const mapDispatchToProps = {
  onClearCopy: actionCreators.clearCopy,
};

export const mapStateToProps = state => ({
  buttonClipboard: copiedButtonSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(CancelButton);
