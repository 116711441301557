import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';
import { pathwayDeviceNETValueSelector } from 'helpers/selectors';
import DeviceSettingValue from 'pages/Device/DeviceSettingValue/DeviceSettingValueContainer';
import TextDescriptionItem
  from 'components/DescriptionList/TextDescriptionItem/TextDescriptionItem';
import TimeDescriptionItemIncremented
  from 'components/DescriptionList/TimeDescriptionItemIncremented/TimeDescriptionItemIncremented';
import consts from 'layouts/Pathway/constants';

const PathwayVIAPortsV1Status = ({ element: { resourceId, id, isPoeCapable } }) => {
  const rows = [
    { key: consts.FIELD_LINK_STATUS, label: 'Link Status' },
    { key: consts.FIELD_BANDWIDTH_USAGE, label: 'Bandwidth Usage' },
    { key: consts.FIELD_POE_USAGE, label: 'PoE usage mW', isHidden: !isPoeCapable },
    { key: consts.FIELD_LLDP_NAME, label: 'LLDP Partner Label' },
    { key: consts.FIELD_VLAN_NAME, label: 'VLAN' },
  ];

  return (
    <Segment>
      <Header as="h4">Status</Header>
      <DeviceSettingValue
        resourceId={resourceId}
        selector={pathwayDeviceNETValueSelector(id, consts.FIELD_UPTIME)}
        render={value => (
          <TimeDescriptionItemIncremented
            title="Link Duration"
            value={value}
            isOnline={false}
          />
        )}
      />
      {rows.filter(r => !r.isHidden).map(row => (
        <DeviceSettingValue
          key={row.key}
          resourceId={resourceId}
          selector={pathwayDeviceNETValueSelector(id, row.key)}
          render={value => (
            <TextDescriptionItem
              title={row.label}
              value={value}
            />
          )}
        />
      ))}
    </Segment>
  );
};

PathwayVIAPortsV1Status.propTypes = {
  element: PropTypes.shape({
    resourceId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    isPoeCapable: PropTypes.bool.isRequired,
  }).isRequired,
};

export default PathwayVIAPortsV1Status;
