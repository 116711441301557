import { connect } from 'react-redux';

import { uploadsNotFoundSelector } from 'helpers/uploadsSelectors';
import UploadsFileNotFound from './UploadsFileNotFound';

const mapStateToProps = state => ({
  notFound: uploadsNotFoundSelector(state),
});

export default connect(mapStateToProps)(UploadsFileNotFound);
