import { takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import resetOTPSecret from './resetOTPSecret';

function* saga() {
  yield takeLatest(constants.RESET_OTP_SECRET, resetOTPSecret);
}

export default saga;
