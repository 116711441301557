import constants from 'dispatcherConst';

const removePageBackgroundImage = args => ({
  type: constants.REMOVE_BACKGROUND_CONTROL_UI_PAGE,
  payload: {
    ...args,
    backgroundImg: null,
    backgroundImgBlob: null,
  },
});

export default removePageBackgroundImage;
