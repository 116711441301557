import isObject from 'lodash/isObject';

import translateAction from '../../../../../../../../LogsFilterParams/utilities/translateAction';
import { filterOptions } from '../../../../../../../../../utilities/constants';

const generateLabelsData = item => {
  const fields = [
    filterOptions.INITIATOR,
    filterOptions.RECIPIENT,
    filterOptions.CREATED_AT,
    filterOptions.ACTION_TYPE,
  ];

  if (isObject(item) && item.initiator) {
    return fields.map(val => {
      if (item[val] && val === filterOptions.ACTION_TYPE) {
        return {
          [val]: translateAction(item[val]),
        };
      }

      if (item[val]) {
        return {
          [val]: item[val],
        };
      }

      return false;
    });
  }

  return [];
};

export default generateLabelsData;
