import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { TASKS_FIELD } from 'helpers/selectors';

export const fetchProjectSuiteProjectsTasks = (urlParams = {}, resourceId = null) => ({
  type: constants.FETCH_PROJECT_SUITE_PROJECTS_TASKS_REQUEST,
  payload: {
    endpoint: endpoints.TASKS,
    urlParams,
    resourceId,
  },
});

export const fetchProjectSuiteProjectsTasksSuccess = response => ({
  type: constants.FETCH_PROJECT_SUITE_PROJECTS_TASKS_SUCCESS,
  payload: {
    response,
    resourceType: TASKS_FIELD,
  },
});

export const fetchProjectSuiteProjectsTasksFailure = error => ({
  type: constants.FETCH_PROJECT_SUITE_PROJECTS_TASKS_FAILED,
  payload: { error },
});

export const fetchProjectSuiteProjectsTasksPaginated = response => ({
  type: constants.FETCH_PROJECT_SUITE_PROJECTS_TASKS_PAGINATED,
  payload: { response },
});

export default fetchProjectSuiteProjectsTasks;
