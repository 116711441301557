import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import * as actionCreators from 'pages/ProjectSuite/ProjectSuiteProjectsTab/components/MultiSiteMapView/utilities/actionCreators';
import RefreshButton from './RefreshButton';

export const mapStateToProps = (state, { match }) => {
  const { id: projectSuiteId } = match.params;

  return {
    resourceId: projectSuiteId,
  };
};

const mapDispatchToProps = {
  reloadMapData: projectSuiteId => actionCreators.fetchChildProjectsForMap(
    projectSuiteId,
    {
      fields: {
        projects: [
          'name',
          'longitude',
          'latitude',
          'onlineDevices',
          'offlineDevices',
          'unassociatedDevices',
          'devicesForUpgrade',
        ],
      },
    },
  ),
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(RefreshButton);
