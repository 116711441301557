import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  deviceSelector,
  logsLinesSelector,
  logsSelector,
} from 'helpers/selectors';
import websocketActionCreators from 'data/websockets/websocketsActionCreators';
import updateSettingsActionCreators from 'pages/Device/updateSettingsActionCreators';
import logsSectionActionCreators from './logsSectionActionCreators';
import LogsSection from './LogsSection';

export const mapStateToProps = (state, ownProps) => {
  const { deviceId } = ownProps.match.params;
  const { online, reportedWithMeta } = deviceSelector(state)(deviceId);

  const {
    log: {
      lvl: {
        value: initialLevel,
      } = {},
    } = {},
    clk: {
      tz: {
        value: offset,
      } = {},
    } = {},
  } = reportedWithMeta || {};

  return {
    online,
    deviceId,
    initialLevel,
    offset,
    lines: logsLinesSelector(state),
    logs: logsSelector(state),
  };
};

const mapDispatchToProps = {
  onSelectLines: logsSectionActionCreators.selectLines,
  onSelectLevel: updateSettingsActionCreators.setLogLevel,
  onUnsubscribe: websocketActionCreators.unsubscribeDeviceData,
  onSubscribe: websocketActionCreators.subscribeDeviceData,
  onRefresh: websocketActionCreators.refreshDeviceData,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(LogsSection);
