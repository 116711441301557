import isLoadingSelector from './isLoadingSelector';
import isOpenSelector from './isOpenSelector';
import allPermissionsSelector from './allPermissionsSelector';
import cumulatedPermissionsSelector from './cumulatedPermissionsSelector';
import recipientsSelector from './recipientsSelector';
import selectedRecipientsSelector from './selectedRecipientsSelector';
import allPermissionsLoadedSelector from './allPermissionsLoadedSelector';
import permissionsTableRowsSelector from './permissionsTableRowsSelector';
import copyRecipientIdSelector from './copyRecipientIdSelector';
import selectedResourceIdsSelector from './selectedResourceIdsSelector';

export default {
  isLoadingSelector,
  isOpenSelector,
  allPermissionsSelector,
  cumulatedPermissionsSelector,
  recipientsSelector,
  selectedRecipientsSelector,
  allPermissionsLoadedSelector,
  permissionsTableRowsSelector,
  copyRecipientIdSelector,
  selectedResourceIdsSelector,
};
