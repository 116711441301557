import { call } from 'redux-saga/effects';

import { deletePath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';

import actionCreators from '../actionCreators';

function* deleteDeviceFirmware(action) {
  const { endpoint, deviceFirmware } = action.payload;

  let { response, error } = yield call(deletePath, endpoint);

  response = response ? { deviceFirmwareId: deviceFirmware.id, deviceFirmware } : undefined;
  error = error ? new Error(`Unable to delete Device Firmware ${deviceFirmware.name}`) : undefined;

  const params = {
    response,
    error,
    successDisp: actionCreators.deleteDeviceFirmwareSuccess,
    failDisp: actionCreators.deleteDeviceFirmwareFailure,
  };

  yield call(processCall, params);
}

export default deleteDeviceFirmware;
