import {
  addDeviceFirmware,
  addDeviceFirmwareSuccess,
  addDeviceFirmwareFailure,
} from './addDeviceFirmware';

import {
  fetchDeviceFirmwares,
  fetchDeviceFirmwaresSuccess,
  fetchDeviceFirmwaresFailure,
} from './fetchDeviceFirmwares';

import {
  fetchDeviceFirmwaresModelTree,
  fetchDeviceFirmwaresModelTreeSuccess,
  fetchDeviceFirmwaresModelTreeFailure,
} from './fetchDeviceFirmwaresModelTree';

import {
  uploadFirmwareFile,
  uploadFirmwareFileSuccess,
  uploadFirmwareFileFailure,
  uploadFirmwareFileAfterDevice,
} from './uploadFirmwareFile';

import {
  fetchFirmwareFiles,
  fetchFirmwareFilesSuccess,
  fetchFirmwareFilesFailure,
} from './fetchFirmwareFiles';

import {
  deleteDeviceFirmware,
  deleteDeviceFirmwareSuccess,
  deleteDeviceFirmwareFailure,
} from './deleteDeviceFirmware';

import {
  linkFileWithDeviceFirmware,
  linkFileWithDeviceFirmwareSuccess,
  linkFileWithDeviceFirmwareFailure,
} from './linkFileWithDeviceFirmware';

import {
  editDeviceFirmware,
  editDeviceFirmwareSuccess,
  editDeviceFirmwareFailure,
} from './editDeviceFirmware';

import {
  transferDeviceFirmware,
  transferDeviceFirmwareSuccess,
  transferDeviceFirmwareFailure,
} from './transferDeviceFirmware';

export default {
  addDeviceFirmware,
  addDeviceFirmwareSuccess,
  addDeviceFirmwareFailure,
  fetchDeviceFirmwares,
  fetchDeviceFirmwaresSuccess,
  fetchDeviceFirmwaresFailure,
  fetchDeviceFirmwaresModelTree,
  fetchDeviceFirmwaresModelTreeSuccess,
  fetchDeviceFirmwaresModelTreeFailure,
  uploadFirmwareFile,
  uploadFirmwareFileSuccess,
  uploadFirmwareFileFailure,
  fetchFirmwareFiles,
  fetchFirmwareFilesSuccess,
  fetchFirmwareFilesFailure,
  uploadFirmwareFileAfterDevice,
  deleteDeviceFirmware,
  deleteDeviceFirmwareSuccess,
  deleteDeviceFirmwareFailure,
  linkFileWithDeviceFirmware,
  linkFileWithDeviceFirmwareSuccess,
  linkFileWithDeviceFirmwareFailure,
  editDeviceFirmware,
  editDeviceFirmwareSuccess,
  editDeviceFirmwareFailure,
  transferDeviceFirmware,
  transferDeviceFirmwareSuccess,
  transferDeviceFirmwareFailure,
};
