import constants from 'dispatcherConst';
import themes from 'themes';

const initialState = {
  fetching: false,
  error: false,
  availableThemes: Object.keys(themes).map(theme => ({
    key: theme,
    text: theme,
    value: theme,
  })),
};

const companiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_COMPANIES_REQUEST:
      return { ...state, fetching: true, error: false };

    case constants.FETCH_COMPANIES_SUCCESS:
      return { ...state, fetching: false, error: false };

    case constants.FETCH_COMPANIES_FAILED:
      return { ...state, fetching: false, error: true };

    case constants.CHANGE_TENANT:
      return { ...state, tenant: action.payload.tenant };

    default:
      return state;
  }
};

export default companiesReducer;
