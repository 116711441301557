import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import {
  SCHEDULES_FIELD,
} from 'helpers/selectors';

export const updateSchedule = params => ({
  type: constants.UPDATE_SCHEDULE_REQUEST,
  payload: {
    params,
    endpoint: endpoints.UPDATE_SCHEDULE(params.values.id),
  },
});

export const updateScheduleSuccess = response => ({
  type: constants.UPDATE_SCHEDULE_SUCCESS,
  payload: {
    response,
    message: messages.SCHEDULE_UPDATED,
    resourceType: SCHEDULES_FIELD,
    id: response.data.id,
  },
});

export const updateScheduleFailure = error => ({
  type: constants.UPDATE_SCHEDULE_FAILED,
  payload: { error },
});
