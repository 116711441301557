import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

const PasswordField = ({ input: { onChange } }) => (
  <Input
    onChange={onChange}
    placeholder="Password"
    type="password"
  />
);

PasswordField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }).isRequired,
};

export default PasswordField;
