import React from 'react';

import { Button } from 'components/atomic';
import { RenderWarningIcon } from './components';
import { StyledDiv } from './ControlPanelButton.style';

const controlPanelUrl = 'control_panel';

function ControlPanelButton() {
  return (
    <StyledDiv>
      <RenderWarningIcon />
      <Button forwardedAs="a" href={controlPanelUrl} primary target="_blank">
        Go to Control Panel
      </Button>
    </StyledDiv>
  );
}

export default ControlPanelButton;
