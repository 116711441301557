import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import DeviceModel from 'models/DeviceModel';
import { selectors } from '../../utilities';
import RenderDevicesTable from './RenderDevicesTable';

export const mapStateToProps = (state, { siteId }) => {
  const isLoading = selectors.areSiteDevicesLoadingSelector(state);
  const getDevicesForSite = selectors.devicesForSiteSelector(state)(siteId);
  const list = getDevicesForSite || { devicesList: [] };
  const devicesWithStatus = list.devicesList.map(device => new DeviceModel(device));

  return {
    isLoading,
    devices: devicesWithStatus,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
)(RenderDevicesTable);
