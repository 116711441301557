import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

import { paths } from 'navigation';
import NoMatch from 'pages/NoMatch/NoMatchContainer';
import UploadsWidget from 'components/UploadsWidget/UploadsWidgetContainer';
import SidebarMenuContainer from 'components/SidebarMenu/SidebarMenuContainer';
import NarrowContainer from 'components/NarrowContainer/NarrowContainer.style';
import BreadcrumbNav from 'components/BreadcrumbNav/BreadcrumbNavContainer';
import AdminProjectsContainer from 'pages/Projects/AdminProjectsContainer';
import ProjectsContainer from 'pages/Projects/ProjectsContainer';
import ProjectContainer from 'pages/Project/ProjectContainer';
import AdminProjectSuitesContainer from 'pages/ProjectSuites/AdminProjectSuitesContainer';
import ProjectSuitesContainer from 'pages/ProjectSuites/ProjectSuitesContainer';
import ProjectSuiteContainer from 'pages/ProjectSuite/ProjectSuiteContainer';
import Users from 'application/tenant/console/user';
import Device from 'pages/Device/DeviceContainer';
import Settings from 'application/tenant/console/settings/Settings/SettingsContainer';
import Permissions from 'application/tenant/console/permissions';
import { Profile } from '../profile';
import { Company, Companies, Vouchers } from '../company';
import { ReceivedInvitationsModal } from './components';
import Firmware from '../Firmware/Firmware';

const ConsoleSwitch = ({ webRedirection }) => (
  <Fragment>
    <UploadsWidget />
    <SidebarMenuContainer />
    <ReceivedInvitationsModal />
    <NarrowContainer>
      <BreadcrumbNav />
      <Switch>
        <Route path={paths.projectDevice} component={Device} />
        <Route path={paths.userProjectDevice} component={Device} />
        <Route path={paths.project} component={ProjectContainer} />
        <Route path={paths.userProject} component={ProjectContainer} />
        <Route path={paths.projects} component={AdminProjectsContainer} />
        <Route path={paths.projectSuite} component={ProjectSuiteContainer} />
        <Route path={paths.userProjectSuite} component={ProjectSuiteContainer} />
        <Route path={paths.userProjectSuites} component={ProjectSuitesContainer} />
        <Route path={paths.projectSuites} component={AdminProjectSuitesContainer} />
        <Route path={paths.userProjects} component={ProjectsContainer} />
        <Route path={paths.permissions} component={Permissions} />
        <Route path={paths.profile} component={Profile} />
        <Route path={paths.users} component={Users} />
        <Route path={paths.company} component={Company} />
        <Route path={paths.companies} component={Companies} />
        <Route path={paths.settings} component={Settings} />
        <Route path={paths.vouchers} component={Vouchers} />
        <Route path={paths.firmware} component={Firmware} />
        {webRedirection && (
          <Route path={paths.signUp} render={() => <Redirect to={webRedirection} />} />
        )}
        <Route component={NoMatch} />
      </Switch>
    </NarrowContainer>
  </Fragment>
);

ConsoleSwitch.defaultProps = {
  webRedirection: null,
};

ConsoleSwitch.propTypes = {
  webRedirection: PropTypes.string,
};

export default ConsoleSwitch;
