import styled, { css } from 'styled-components';

export const StyledVersionInfo = styled.div`
  margin-top: 16px;

  ${({ fixedVersionNumber }) => fixedVersionNumber && css`
    position: fixed;
    bottom: 13px;
  `}
`;

export default StyledVersionInfo;
