import { call, put } from 'redux-saga/effects';

import { processCall } from 'helpers/sagaHelper';
import { getPath } from 'data/api/requests';
import { prepareResponseData } from 'helpers/paginationHelpersClient';
import remoteDevicesActionCreators from '../actionCreators';

function* fetchUnjoinedRemoteDevices(action) {
  const { endpoint, urlParams, deviceId, ...rest } = action.payload;
  const { response, error } = yield call(getPath, endpoint, { urlParams });

  const enhanceResponseWithId = response ? { ...response, deviceId } : response;

  const params = {
    response: enhanceResponseWithId,
    error,
    successDisp: remoteDevicesActionCreators.fetchUnjoinedRemoteDevicesSuccess,
    failDisp: remoteDevicesActionCreators.fetchUnjoinedRemoteDevicesFailure,
    ...rest,
  };
  yield call(processCall, params);

  if (enhanceResponseWithId) {
    const enhancedResponse = yield call(prepareResponseData, enhanceResponseWithId, urlParams);

    yield put(remoteDevicesActionCreators
      .fetchUnjoinedRemoteDevicesPaginated(enhancedResponse));
  }
}

export default fetchUnjoinedRemoteDevices;
