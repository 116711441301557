function searchInvalidActions(task) {
  if (task && task.actions && Array.isArray(task.actions)) {
    const invalidActions = task.actions.filter(action => action.invalid === true);
    if (invalidActions.length > 0) {
      const singleAction = invalidActions.length === 1;
      return {
        invalidActions: true,
        singleAction,
      };
    }
  }

  return {
    invalidActions: false,
    singleAction: false,
  };
}

export default searchInvalidActions;
