import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/atomic';
import ConfirmModal from 'components/elements/ConfirmModal/ConfirmModal';
import DataTableActionButtonLink from 'components/DataTable/DataTableActionButtonLink/DataTableActionButtonLink';

function RemoveUserActionLink({
  item,
  projectSuiteId,
  onRemoveUserFromProjectSuite,
}) {
  const { email } = item;

  const handleConfirm = useCallback(() => {
    const { id: userId, email: userEmail } = item;

    onRemoveUserFromProjectSuite({
      userId,
      userEmail,
      projectSuiteId,
    });
  }, [onRemoveUserFromProjectSuite, item, projectSuiteId]);

  return (
    <ConfirmModal
      trigger={<DataTableActionButtonLink title="Remove" />}
      confirmButton={<Button negative primary={false}>Remove User</Button>}
      content={(
        <div>
          <p>
            Are you sure you want to remove the user <strong>{email}</strong>?
            This action cannot be undone.
          </p>
          <p>
            Enter the email of the user to confirm:
          </p>
        </div>
      )}
      header="Confirm user removal"
      cancelButton="Cancel"
      onConfirm={handleConfirm}
      validationString={email}
      validationMessage="Enter the email of the user to be removed"
    />
  );
}

RemoveUserActionLink.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  onRemoveUserFromProjectSuite: PropTypes.func.isRequired,
  projectSuiteId: PropTypes.string.isRequired,
};

export default RemoveUserActionLink;
