import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import has from 'lodash/has';

import extractDefaultValuesFromParams from './extractDefaultValuesFromParams';

const setInitialValues = (
  stateToUpdate,
  formValues,
  changeFieldValue,
  actionTypeDeviceIdKey,
  params,
) => {
  if (isEmpty(stateToUpdate) || isEmpty(formValues)) {
    return;
  }

  const defaultValues = extractDefaultValuesFromParams(params);
  const updatedFieldsKeysArray = Object.keys(stateToUpdate[Object.keys(stateToUpdate)[0]]);
  updatedFieldsKeysArray.forEach(key => {
    const checkUpdatedKeyCondition = (stateToUpdate[Object.keys(stateToUpdate)[0]][key] === true)
    && has(defaultValues, key);

    if (isNil(formValues[key]) && checkUpdatedKeyCondition) {
      changeFieldValue(`${key}${actionTypeDeviceIdKey}`, defaultValues[key]);
    }
  });
};

export default setInitialValues;
