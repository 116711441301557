import React from 'react';

import { USERS_FIELD } from 'helpers/selectors';
import ApiRequestBuilder from 'helpers/ApiRequestBuilder';
import { firstCharacterUpperCase } from 'helpers/sharedMethods';
import StatusIcon from '../components/StatusIcon/StatusIcon';

export const userStatus = {
  SUSPENDED: { label: 'suspended', verbose: 'Suspended' },
  INVITED: { label: 'invited', verbose: 'Invited' },
  ACTIVE: { label: 'active', verbose: 'Active' },
  INACTIVE: { label: 'inactive', verbose: 'Inactive' },
};

const userStatusIconMap = {
  [userStatus.SUSPENDED.label]: 'circle',
  [userStatus.INVITED.label]: 'circle',
  [userStatus.ACTIVE.label]: 'circle',
  [userStatus.INACTIVE.label]: 'circle',
};

class UserModel {

  constructor(user = {}) {
    this.id = user.id;
    this.firstName = user.firstName || '';
    this.lastName = user.lastName || '';
    this.email = user.email;
    this.lastLoginTime = user.lastLoginTime;
    this.superadmin = user.superadmin;
    this.suspended = user.suspended;
    this.receivedInvitations = user.receivedInvitations;

    this.setStatus();
  }

  get initials() {
    const { firstName, lastName } = this;

    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  }

  get fullName() {
    const { firstName, lastName } = this;

    return `${firstName} ${lastName}`;
  }

  get fullNameOrEmail() {
    const { firstName, lastName, email } = this;

    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }

    return email;
  }

  get displayName() {
    const { firstName, roleForPanel } = this;

    return `${firstName} ${roleForPanel}`;
  }

  get roleForPanel() {
    const { superadmin } = this;

    return superadmin ? '(Superadmin)' : '';
  }

  get role() {
    const { superadmin } = this;

    if (superadmin) {
      return 'Superadmin';
    }

    return 'N/A';
  }

  get statusIcon() {
    const { status } = this;
    const icon = userStatusIconMap[status.label];

    return (
      <StatusIcon
        label={status.verbose}
        model="user"
        name={icon}
        status={status.label}
      />
    );
  }

  get userLabel() {
    const { firstName, lastName, email } = this;

    if (firstName && lastName) {
      const firstCharacter = firstCharacterUpperCase(firstName);
      const secondCharacter = firstCharacterUpperCase(lastName);

      return `${firstCharacter}${secondCharacter}`;
    }

    return `@${firstCharacterUpperCase(email)}`;
  }

  setStatus(projectId, projectSuiteId) {
    const { suspended, lastLoginTime } = this;

    if (suspended) {
      this.status = userStatus.SUSPENDED;
    } else if (
      this.hasInvitationToProject(projectId)
      || this.hasInvitationToProjectSuite(projectSuiteId)
    ) {
      this.status = userStatus.INVITED;
    } else if (lastLoginTime) {
      this.status = userStatus.ACTIVE;
    } else {
      this.status = userStatus.INACTIVE;
    }

    return this;
  }

  hasInvitationToProject(projectId) {
    const { receivedInvitations } = this;

    if (!projectId || !UserModel.checkArray(receivedInvitations)) {
      return false;
    }

    return receivedInvitations.findIndex(inv => inv.projectId === projectId) > -1;
  }

  hasInvitationToProjectSuite(projectSuiteId) {
    const { receivedInvitations } = this;

    if (!projectSuiteId || !UserModel.checkArray(receivedInvitations)) {
      return false;
    }

    return receivedInvitations.findIndex(inv => inv.projectSuiteId === projectSuiteId) > -1;
  }

  static checkArray(array) {
    return !!(Array.isArray(array) && array.length > 0);
  }

  static requestBody(values) {
    return new ApiRequestBuilder(USERS_FIELD)
      .setAttributes(values);
  }

}

export default UserModel;
