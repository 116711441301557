import { call } from 'redux-saga/effects';

import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';

import actionCreators from '../actionCreators';

function* fetchUsers(action) {
  const { resourceId, endpoint, urlParams, ...rest } = action.payload;

  const { response, error } = yield call(getPath, endpoint, { urlParams });

  let enhancedResponse = response;

  if (!error) {
    enhancedResponse = {
      ...response,
      resourceId,
    };
  }

  const params = {
    response: enhancedResponse,
    error,
    successDisp: actionCreators.fetchUsersSuccess,
    failDisp: actionCreators.fetchUsersFailure,
    ...rest,
  };

  yield call(processCall, params);
}

export default fetchUsers;
