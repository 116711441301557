import {
  fetchRemoteDevices,
  fetchRemoteDevicesSuccess,
  fetchRemoteDevicesFailure,
} from './fetchRemoteDevices';
import {
  fetchUnjoinedRemoteDevices,
  fetchUnjoinedRemoteDevicesSuccess,
  fetchUnjoinedRemoteDevicesFailure,
} from './fetchUnjoinedRemoteDevices';
import fetchRemoteDevicesPaginated from './fetchRemoteDevicesPaginated';
import fetchUnjoinedRemoteDevicesPaginated from './fetchUnjoinedRemoteDevicesPaginated';

export default {
  fetchRemoteDevices,
  fetchRemoteDevicesSuccess,
  fetchRemoteDevicesFailure,
  fetchRemoteDevicesPaginated,
  fetchUnjoinedRemoteDevices,
  fetchUnjoinedRemoteDevicesSuccess,
  fetchUnjoinedRemoteDevicesFailure,
  fetchUnjoinedRemoteDevicesPaginated,
};
