import { Sidebar } from 'semantic-ui-react';
import styled from 'styled-components';

import { injectStyles, media } from 'themes/mixins';
import sidebarConst from 'themes/variables';

const sidebarWidth = sidebarConst.width;

const StyledSidebar = styled(Sidebar)`  
  ${({ theme: { sidebar } }) => injectStyles(sidebar)};
  &.ui.left.sidebar {
    position: fixed;
    width: ${sidebarWidth.xs};
    max-height: 100vh;
    height: 100vh!important;
    
    ${media.md`width: ${sidebarWidth.md};`};
  }
  
  &.ui.menu {
    border: none;
    font-size: 1.25rem;
  }
  
  &.ui.menu.vertical {
    display: flex;
    box-shadow: none;
    
    .active.item {
      background: transparent;
    }
  }
  
  &.ui.menu.vertical.sidebar {
    > .item::before {
      display: none!important;
    }
  }
`;

export default StyledSidebar;
