import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import { PROJECTS_FIELD } from 'helpers/selectors';
import { DropdownArrow } from 'components/elements';
import {
  StyledHeader,
  StyledPermissionsGridWrapper,
  StyledPermissionsGridSidebar,
  StyledPermissionsGridBody,
} from './PermissionsGridWrapper.style';

const PermissionsGridWrapper = ({
  open,
  header,
  showHeader,
  resourceId,
  resourceType,
  resourceTitle,
  resourceName,
  PermissionsTree,
  PermissionsGridHeaders,
  PermissionsGrid,
  AddUsersButton,
  onToggleOpen,
  ...rest
}) => (
  <Grid>
    {showHeader && (
      <Grid.Row>
        <Grid.Column>
          <StyledHeader
            as={header}
            onClick={() => onToggleOpen(resourceId)}
          >
            <DropdownArrow open={open} />
            {resourceTitle}{resourceName ? ': ' : ''}{resourceName}
          </StyledHeader>
        </Grid.Column>
      </Grid.Row>
    )}
    {(open || !showHeader) && (
      <Fragment>
        {AddUsersButton && (
          <Grid.Row>
            <Grid.Column>
              <AddUsersButton
                resourceId={resourceId}
                resourceType={resourceType}
              />
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column>
            <StyledPermissionsGridWrapper>
              <StyledPermissionsGridSidebar>
                <PermissionsTree resourceId={resourceId} {...rest} />
              </StyledPermissionsGridSidebar>
              <StyledPermissionsGridBody>
                <PermissionsGridHeaders resourceId={resourceId} {...rest} />
                <PermissionsGrid resourceId={resourceId} {...rest} />
              </StyledPermissionsGridBody>
            </StyledPermissionsGridWrapper>
          </Grid.Column>
        </Grid.Row>
      </Fragment>
    )}
  </Grid>
);

PermissionsGridWrapper.defaultProps = {
  header: 'h2',
  showHeader: true,
  resourceName: null,
  AddUsersButton: null,
  resourceType: PROJECTS_FIELD,
};

PermissionsGridWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  header: PropTypes.string,
  showHeader: PropTypes.bool,
  resourceId: PropTypes.string.isRequired,
  resourceType: PropTypes.string,
  resourceTitle: PropTypes.string.isRequired,
  resourceName: PropTypes.string,
  PermissionsTree: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  PermissionsGridHeaders: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  PermissionsGrid: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  AddUsersButton: PropTypes.func,
  onToggleOpen: PropTypes.func.isRequired,
};

export default PermissionsGridWrapper;
