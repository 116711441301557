import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { complexValueType } from 'data/types';
import { Button } from 'components/atomic';
import { SimpleForm } from 'components/form/Form/Form';
import SettingCard from 'components/SettingCard/SettingCard';
import UTCDateDescriptionItemIncremented
  from 'components/DescriptionList/UTCDateDescriptionItemIncremented/UTCDateDescriptionItemIncremented';
import TextDescriptionItem
  from 'components/DescriptionList/TextDescriptionItem/TextDescriptionItem';
import { StyledDateField, StyledTimeField } from './PharosTimeAdjustmentV1Form.style';

export const formFields = {
  date: 'date',
  time: 'time',
};

const PharosTimeAdjustmentV1Form = ({
  title,
  handleSubmit,
  pristine,
  submitting,
  shouldDisplay,
  resourceOnline,
  resourceCurrentTime,
  resourceOffset,
}) => {
  if (!shouldDisplay) return null;

  const { value } = resourceOffset;

  const resourceData = (
    <Fragment>
      <UTCDateDescriptionItemIncremented
        title="Current date/time"
        value={resourceCurrentTime}
        isOnline={resourceOnline}
        offset={value}
      />
      <TextDescriptionItem
        title="UTC offset"
        value={resourceOffset}
      />
    </Fragment>
  );
  const trigger = (
    <Button
      type="submit"
      primary
      disabled={pristine || submitting || !resourceOnline}
      onClick={handleSubmit}
    >
      Commit
    </Button>
  );

  return (
    <SettingCard
      title={title}
      as={SimpleForm}
      trigger={trigger}
      readOnlyData={resourceData}
    >
      <Field
        component={StyledDateField}
        id={formFields.date}
        name={formFields.date}
        disabled={!resourceOnline}
        label="Date"
      />
      <Field
        component={StyledTimeField}
        id={formFields.time}
        name={formFields.time}
        disabled={!resourceOnline}
        label="Time"
      />
    </SettingCard>
  );
};

PharosTimeAdjustmentV1Form.defaultProps = {
  resourceOnline: false,
  resourceCurrentTime: null,
  resourceOffset: null,
};

PharosTimeAdjustmentV1Form.propTypes = {
  shouldDisplay: PropTypes.bool.isRequired,
  resourceOnline: PropTypes.bool,
  resourceCurrentTime: complexValueType(PropTypes.number),
  resourceOffset: complexValueType(PropTypes.number),
  title: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default PharosTimeAdjustmentV1Form;
