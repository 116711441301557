import { compose } from 'redux';
import { connect } from 'react-redux';

import { controlPanelContextSelector, isPageNavigationOpenSelector } from 'helpers/selectors';
import Footer from './Footer';

export const mapStateToProps = state => ({
  inControlPanelContext: controlPanelContextSelector(state),
  isPageNavigationOpen: isPageNavigationOpenSelector(state),
});

export default compose(
  connect(mapStateToProps),
)(Footer);
