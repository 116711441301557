import constants from 'dispatcherConst';

const notificationsActionCreators = {
  addGeneralError: error => ({
    type: constants.ADD_GENERAL_ERROR,
    payload: { error },
  }),

  clearRequesterErrors: requester => ({
    type: constants.CLEAR_REQUESTER_ERRORS,
    payload: { requester },
  }),

  addRequesterError: (errors, requester) => ({
    type: constants.ADD_REQUESTER_ERROR,
    payload: { errors, requester },
  }),

  addGeneralNotification: message => ({
    type: constants.ADD_GENERAL_NOTIFICATION,
    payload: { message },
  }),

  addInfoNotification: message => ({
    type: constants.ADD_INFO_NOTIFICATION,
    payload: { message },
  }),
};

export default notificationsActionCreators;
