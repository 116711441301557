import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/atomic';
import SettingCard from 'components/SettingCard/SettingCard';

const PathwayIdentifyV1Form = ({
  resourceBeacon,
  resourceOnline,
  title,
  deviceTitle,
  submitting,
  handleSubmit,
}) => {
  const trigger = (
    <Button
      type="submit"
      primary
      pulse={resourceBeacon}
      disabled={!resourceOnline || submitting}
    >
      Identify
    </Button>
  );

  return (
    <SettingCard
      title={title}
      trigger={trigger}
      as="form"
      onSubmit={handleSubmit}
    >
      <p>
        Causes {deviceTitle} to commence Identify behaviour (flashing LCD backlight, Identify LED)
      </p>
    </SettingCard>
  );
};

PathwayIdentifyV1Form.defaultProps = {
  title: '',
};

PathwayIdentifyV1Form.propTypes = {
  resourceBeacon: PropTypes.bool.isRequired,
  resourceOnline: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  deviceTitle: PropTypes.string.isRequired,
  title: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
};

export default PathwayIdentifyV1Form;
