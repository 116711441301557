import constants from 'dispatcherConst';

export const setFetchingTasksFilterToTrue = (
  resourceId, requestType, resourceType, filterParam,
) => ({
  type: constants.SET_EXTRA_FILTERS_TRUE,
  payload: {
    resourceId,
    requestType,
    resourceType,
    filterParam,
  },
});

export const setFetchingTasksFilterToFalse = () => ({
  type: constants.SET_EXTRA_FILTERS_FALSE,
  payload: {},
});

export const setProjectIdsToFilter = (resourceId, path, projectIds, requestType) => ({
  type: constants.SET_RESOURCE_IDS_TO_FILTER,
  payload: {
    resourceId,
    path,
    projectIds,
    requestType,
  },
});

export const triggerProjectSuiteTasksFiltering = (
  resourceId, projectIds, filterParam, field, requestType,
) => ({
  type: constants.TRIGGER_PROJECT_SUITE_TASKS_FILTERING,
  payload: {
    resourceId,
    projectIds,
    filterParam,
    field,
    requestType,
  },
});

export default setFetchingTasksFilterToTrue;
