import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Label, Tab, Form } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';

import { Button, TipIcon } from 'components/atomic';

import { renderTimes } from '../../utilities';
import { StyledLabel, TimeList } from './TimeMaskView.style';

const tips = {
  timeMask: '',
  hours: 'Enter comma-separated hours in the range 0 to 23. You may use * to match every hour.',
  minutes: 'Enter up to 12 comma-separated minutes in the range 0 to 59.',
  seconds: 'Enter up to 2 comma-separated seconds in the range 0 to 59.',
};

export default function TimeMaskView({ selectedTimes, onTimeChange }) {
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [seconds, setSeconds] = useState('');
  const [errors, setErrors] = useState({});

  const intervals = useMemo(() => {
    const {
      showTimes,
      errors: validationErrors,
      timesList,
    } = renderTimes({ hours, minutes, seconds });

    if (!showTimes) {
      setErrors(validationErrors);
      return [];
    }

    setErrors({});

    return timesList;
  }, [hours, minutes, seconds]);

  const renderTipIcon = type => {
    const message = tips[type];

    return (
      <TipIcon floated="right" message={message} editMode={false} />
    );
  };

  const handleTimeSelected = e => {
    e.preventDefault();

    if (isEmpty(intervals)) {
      return;
    }

    onTimeChange([...new Set([...selectedTimes, ...intervals])]);
  };

  return (
    <Grid as={Tab.Pane}>
      <Grid.Column width={16}>
        <Form>
          <Form.Field>
            <StyledLabel htmlFor="input">Hours {renderTipIcon('hours')}</StyledLabel>
            <input
              name="time-mask-hours"
              inputMode="text"
              placeholder="0, 12, 16, 23"
              value={hours}
              onChange={e => setHours(e.target.value)}
            />
            <small name="time-mask-hours-error">{errors.hours}</small>
          </Form.Field>
          <Form.Field>
            <StyledLabel htmlFor="input">Minutes {renderTipIcon('minutes')}</StyledLabel>
            <input
              name="time-mask-minutes"
              inputMode="text"
              placeholder="0, 15, 20, 25"
              value={minutes}
              onChange={e => setMinutes(e.target.value)}
            />
            <small name="time-mask-minutes-error">{errors.minutes}</small>
          </Form.Field>
          <Form.Field>
            <StyledLabel htmlFor="input">Seconds {renderTipIcon('seconds')}</StyledLabel>
            <input
              name="time-mask-seconds"
              inputMode="text"
              placeholder="0, 30"
              value={seconds}
              onChange={e => setSeconds(e.target.value)}
            />
            <small name="time-mask-seconds-error">{errors.seconds}</small>
          </Form.Field>
          <Form.Field>
            <StyledLabel htmlFor="input">Preview</StyledLabel>
            <TimeList>
              {intervals.map(time => (
                <li key={time}>
                  <Label htmlFor="input">{time}</Label>
                </li>
              ))}
            </TimeList>
          </Form.Field>
          <Button
            primary
            disabled={isEmpty(intervals)}
            onClick={handleTimeSelected}
            className="time-mask-submit-button"
          >
            Confirm
          </Button>
        </Form>
      </Grid.Column>
    </Grid>
  );
}

TimeMaskView.defaultProps = {
  selectedTimes: [],
};

TimeMaskView.propTypes = {
  selectedTimes: PropTypes.arrayOf(PropTypes.string),
  onTimeChange: PropTypes.func.isRequired,
};
