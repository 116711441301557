import { call } from 'redux-saga/effects';

import { processSuccessfulCall } from 'helpers/sagaHelper';
import processFailure from './processFailure';

function* processFinalFormResponse(params) {
  const { response, error } = params;

  if (response) {
    yield call(processSuccessfulCall, params);
  }

  if (error) {
    yield call(processFailure, params);
  }
}

export default processFinalFormResponse;
