import PropTypes from 'prop-types';
import isBoolean from 'lodash/isBoolean';
import isNull from 'lodash/isEmpty';

const DataTableBoolean = ({ field, item }) => {
  if (isNull(item) || isNull(field)) {
    return null;
  }

  const value = item[field];

  if (isBoolean(value)) {
    if (value) {
      return 'On';
    }

    return 'Off';
  }

  return null;
};

DataTableBoolean.defaultProps = {
  field: null,
  item: {},
};

DataTableBoolean.propTypes = {
  field: PropTypes.string,
  item: PropTypes.object,
};

export default DataTableBoolean;
