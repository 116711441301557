import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import ActionCell from '../../../ActionCell/ActionCell.style';
import ActionRow from '../../../ActionRow/ActionRow.style';
import RenderParameter from './RenderParameter/RenderParameterContainer';
import { ParamHeaderCell, Message } from './ActionParameters.style';

const ActionParameters = ({
  params,
  typeId,
  logicalDeviceId,
  taskId,
  actionId,
  lockedParams,
  projectId,
}) => {
  const { length } = params;
  return (
    <ActionRow>
      <ActionCell colSpan="4">
        {length > 0
          ? (
            <Table className="noHover">
              <Table.Header>
                <Table.Row>
                  <ParamHeaderCell>Parameter</ParamHeaderCell>
                  <ParamHeaderCell>Value</ParamHeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {params.map(({ key, value }, index) => (
                  <RenderParameter
                    key={key}
                    keyVal={key}
                    value={value}
                    typeId={typeId}
                    logicalDeviceId={logicalDeviceId}
                    taskId={taskId}
                    actionId={actionId}
                    paramId={index}
                    lockedParams={lockedParams}
                    projectId={projectId}
                  />
                ))}
              </Table.Body>
            </Table>
          )
          : <Message>No parameters</Message>
        }
      </ActionCell>
    </ActionRow>
  );
};

ActionParameters.defaultProps = {
  lockedParams: [],
  logicalDeviceId: null,
  projectId: '',
};

ActionParameters.propTypes = {
  params: PropTypes.array.isRequired,
  typeId: PropTypes.string.isRequired,
  logicalDeviceId: PropTypes.string,
  taskId: PropTypes.string.isRequired,
  actionId: PropTypes.number.isRequired,
  lockedParams: PropTypes.array,
  projectId: PropTypes.string,
};

export default ActionParameters;
