import { call, put } from 'redux-saga/effects';

import { deletePath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import { PROJECTS_FIELD, PROJECT_SUITES_FIELD } from 'helpers/selectors';

import * as projectsForMapActionCreators from 'pages/ProjectSuite/ProjectSuiteProjectsTab/components/MultiSiteMapView/utilities/actionCreators';
import actionCreators from '../actionCreators';

function* removeProjectFromProjectSuite(action) {
  const { projectId, projectSuiteId, projectName, endpoint } = action.payload;

  const payload = {
    data: [
      {
        id: projectId,
        type: 'projects',
      },
    ],
  };

  let { response, error } = yield call(deletePath, endpoint, payload);

  response = response ? { projectSuiteId, projectName, projectId } : undefined;

  error = error ? new Error(`Unable to remove Site ${projectName} from Multi-site`) : undefined;

  const params = {
    response,
    error,
    successDisp: actionCreators.removeProjectFromProjectSuiteSuccess,
    failDisp: actionCreators.removeProjectFromProjectSuiteFailure,
  };

  if (!error) {
    yield put(actionCreators.fetchProjectSuiteProjectsFilter(projectSuiteId, {
      fields: {
        [PROJECTS_FIELD]: [
          'name',
        ],
      },
      sort: 'name',
    }));

    yield put(actionCreators.fetchProjectSuite(projectSuiteId, {
      fields: {
        [PROJECT_SUITES_FIELD]: [
          'name',
          'projectsCount',
        ],
      },
    }));

    yield put(projectsForMapActionCreators.fetchChildProjectsForMap(projectSuiteId, {}));
  }

  yield call(processCall, params);
}

export default removeProjectFromProjectSuite;
