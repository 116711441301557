import styled from 'styled-components';

import { Button } from 'components/atomic';
import { mediaMax } from 'themes/mixins';

const FireTriggerButton = styled(Button)`
  &.ui.button {
    ${mediaMax.md`
      width: 100%;
      float: none;
    `};
  }
`;

export default FireTriggerButton;
