import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import {
  AUTOMATED_OPERATIONS_FIELD,
  FETCHING_FILTER_PROJECTS_FIELD,
} from 'helpers/selectors';
import withPolling from 'helpers/withPollingSaga/withPollingSagaContainer';
import { addPagination } from 'helpers/paginationHelpers';
import AutomatedOperations from './AutomatedOperations';
import automatedOperationsActionCreators from './utilities/actionCreators';

const REFRESH_RATE = 60000;

export const mapStateToProps = (state, { match }) => {
  const { id: projectId } = match.params;

  return {
    projectId,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: AUTOMATED_OPERATIONS_FIELD, included: true },
      ({ projectId }) => automatedOperationsActionCreators.fetchAutomatedOperations(projectId, {
        sort: 'name',
        filter: 'name',
        recipientType: 'project',
        recipientId: projectId,
        [FETCHING_FILTER_PROJECTS_FIELD]: [projectId],
      }),
    ),
  ),
  withPolling(REFRESH_RATE),
)(AutomatedOperations);
