import flow from 'lodash/flow';

export const updateSingleCounter = statePiece => {
  const { timeoutCounter = 0 } = statePiece;

  return { ...statePiece, timeoutCounter: timeoutCounter + 1 };
};

export const resetCounter = statePiece => ({
  ...statePiece,
  refreshing: false,
  timeoutCounter: 0,
});

export const markAsUpdated = statePiece => ({
  ...statePiece,
  updated: true,
});

export const markAsRefreshing = statePiece => ({
  ...statePiece,
  refreshing: true,
  updated: false,
});

export const clearRequestId = statePiece => ({
  ...statePiece,
  requestId: null,
  updated: false,
});

export const updateCounters = state => {
  const { updating } = state;
  if (!updating.length) return state;

  return updating.reduce((acc, resourceType) => {
    acc[resourceType] = updateSingleCounter(acc[resourceType]);

    return acc;
  }, { ...state });
};

export const markUpdatedAndUpdateCounters = (state, updated) => {
  const { updating } = state;

  return updating.reduce((acc, resourceType) => {
    const resourceInfo = acc[resourceType];
    const { requestId } = resourceInfo;

    const isPresent = updated.indexOf(requestId) !== -1;
    if (isPresent) {
      acc[resourceType] = flow(
        resetCounter,
        markAsUpdated,
      )(resourceInfo);
    } else {
      acc[resourceType] = updateSingleCounter(resourceInfo);
    }

    return acc;
  }, { ...state });
};

export const resetCounters = state => {
  const { updating } = state;

  return updating.reduce((acc, resourceType) => {
    acc[resourceType] = flow(
      resetCounter,
      clearRequestId,
    )(acc[resourceType]);

    return acc;
  }, { ...state });
};

export const clearRequestIdIfUpdated = (state, resourceType) => {
  const { [resourceType]: resourceInfo } = state;

  if (!resourceInfo) return state;

  const { updated } = resourceInfo;

  if (!updated) return state;

  return {
    ...state,
    [resourceType]: clearRequestId(resourceInfo),
  };
};
