import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';
import deepEqual from 'lodash/isEqual';
import identity from 'lodash/identity';

import { deviceSelector } from 'helpers/selectors';
import PharosNetworkInterfaceTPSV1 from './PharosNetworkInterfaceTPSV1';

export const makeMapState = () => {
  const memoIpAddress = memoizeOne(identity, deepEqual);
  const memoSubnetMask = memoizeOne(identity, deepEqual);
  const memoGateway = memoizeOne(identity, deepEqual);

  return function mapStateToProps(state, ownProps) {
    const { resourceId } = ownProps;
    const device = deviceSelector(state)(resourceId);
    const { reportedWithMeta } = device;

    const {
      net: {
        mgmt: {
          ipv4: ipAddress,
          sub: subnetMask,
          gw: gateway,
        } = {},
      } = {},
    } = reportedWithMeta || {};

    return {
      loading: !reportedWithMeta,
      ipAddress: memoIpAddress(ipAddress),
      subnetMask: memoSubnetMask(subnetMask),
      gateway: memoGateway(gateway),
    };
  };
};

export default connect(makeMapState())(PharosNetworkInterfaceTPSV1);
