import findKey from 'lodash/findKey';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import { currentCompanySubdomainSelector } from 'helpers/selectors';
import themeProviderActionCreators from './currentCompanyActionCreators';

export function* fetchCurrentCompany(action) {
  const { endpoint } = action.payload;
  const { response, error } = yield call(getPath, endpoint);

  const params = {
    response,
    error,
    successDisp: themeProviderActionCreators.fetchCompanySuccess,
    failDisp: themeProviderActionCreators.fetchCompanyFailure,
  };
  yield call(processCall, params);
}

export function updateCompanyTitle(action) {
  const { companies } = action.payload.response;
  const currentCompanyId = findKey(companies);
  const { name, subdomain } = companies[currentCompanyId].attributes;

  // update document title
  if (subdomain === 'public') {
    document.title = 'SixEye';
  } else {
    document.title = name;
  }
}

export function* updateCurrentCompany(action) {
  const currentSubdomain = yield select(currentCompanySubdomainSelector);
  const { initialValues: { subdomain: initialSubdomain } } = action.payload.params;

  if (currentSubdomain !== initialSubdomain) {
    return;
  }

  const { payload: { response } } = yield take(constants.UPDATE_COMPANY_INFO_SUCCESS);
  yield put(themeProviderActionCreators.fetchCompanySuccess(response));
}

function* currentCompanySaga() {
  yield takeLatest(constants.FETCH_CURRENT_COMPANY_REQUEST, fetchCurrentCompany);
  yield takeLatest(constants.FETCH_CURRENT_COMPANY_SUCCESS, updateCompanyTitle);
  yield takeLatest(constants.UPDATE_COMPANY_INFO_REQUEST, updateCurrentCompany);
}

export default currentCompanySaga;
