import isUndefined from 'lodash/isUndefined';
import { DateTime } from 'luxon';

export const logLevels = {
  0: 'Critical',
  1: 'Terse',
  2: 'Normal',
  3: 'Extended',
  4: 'Verbose',
  5: 'Debug',
};

export const defaultLogLevelSelectOptions = Object.keys(logLevels)
  .sort((a, b) => a - b)
  .map(levelKey => {
    const { [levelKey]: level } = logLevels;

    return {
      key: levelKey,
      value: Number(levelKey),
      text: level,
    };
  });

export const logDefaults = {
  category: 'No category',
  level: 'No level',
};

class LogModel {

  constructor(log = {}) {
    this.id = log.id;
    this.category = log.category;
    this.message = log.message;
    this.severityLevel = log.severityLevel;
    this.timestamp = log.timestamp;
  }

  addOffset(offset) {
    this.offset = offset;
    return this;
  }

  get display() {
    const {
      category = logDefaults.category,
      message,
      severityLevel,
      timestamp,
    } = this;
    const { [severityLevel]: level = logDefaults.level } = logLevels;

    const datetime = DateTime.fromMillis(timestamp * 1000);
    const offsetDatetime = (
      isUndefined(this.offset)
        ? datetime
        : datetime.setZone(this.offset * 60)
    );

    return {
      category,
      message,
      level,
      date: offsetDatetime.toFormat('dd/MM/yy'),
      time: offsetDatetime.toFormat('HH:mm:ss'),
    };
  }

}

export default LogModel;
