import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { PROJECTS_FOR_MAP_FIELD } from 'helpers/selectors';

export const fetchProjectsForMap = (userId, urlParams) => ({
  type: constants.FETCH_PROJECTS_FOR_MAP_REQUEST,
  payload: {
    userId,
    endpoint: endpoints.USER_PROJECTS(userId),
    resourceType: PROJECTS_FOR_MAP_FIELD,
    urlParams,
  },
});

export const fetchProjectsForMapSuccess = response => ({
  type: constants.FETCH_PROJECTS_FOR_MAP_SUCCESS,
  payload: {
    response,
    resourceType: PROJECTS_FOR_MAP_FIELD,
  },
});

export const fetchProjectsForMapFailure = error => ({
  type: constants.FETCH_PROJECTS_FOR_MAP_FAILED,
  payload: { error },
});

export default fetchProjectsForMap;
