export const filterOptions = {
  SEARCH: 'search',
  ACTION_TYPE: 'actionType',
  INITIATOR: 'initiator',
  RECIPIENT: 'recipient',
  RESOURCE: 'resource',
  CREATED_AT: 'createdAt',
};

export const initialFilterParams = {
  search: null,
  initiator: null,
  actionType: null,
  recipient: null,
  resource: null,
};

export default filterOptions;
