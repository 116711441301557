import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { permissionsOrNotFound, frontendPermissions } from 'gateway';
import {
  currentCompanyFetchingSelector,
  projectFetchingSelector,
  tenantChangedSelector,
  currentUserSelector,
  PROJECTS_FIELD,
} from 'helpers/selectors';
import { addPagination } from 'helpers/paginationHelpers';
import { actionCreators as copyPasteActionCreators } from 'application/tenant/components/structural/ControlUI/components/ControlUIPageCarousel/components/ControlUIPage/components/ControlUIButtonGroup/ControlUIButton/components/utilities';
import Projects from 'pages/Projects/Projects';
import projectActionCreators from 'pages/Projects/projectActionCreators';

export const mapStateToProps = state => ({
  currentUser: currentUserSelector(state),
  route: '/sites',
  fetching: currentCompanyFetchingSelector(state) || projectFetchingSelector(state),
  tenantNotChanged: !tenantChangedSelector(state),
  displayMap: false,
  statusWidgetsType: 'admin',
});

export default compose(
  withRouter,
  connect(mapStateToProps),
  permissionsOrNotFound(frontendPermissions.TENANT_MANAGER_SITES),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: PROJECTS_FIELD },
      () => projectActionCreators.fetchAdminProjects(
        {
          fields: { [PROJECTS_FIELD]: ['name', 'usersCount', 'logicalDevicesCount', 'construction', 'state', 'createdAt', 'renewalDate'] },
          sort: 'name',
          filter: 'name',
        },
      ),
    ),
  ),
  chainedLoaderWithSaga(() => copyPasteActionCreators.clearCopy),
)(Projects);
