import { takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import fetchTimelines from './fetchTimelines';
import paginateTimelines from './paginateTimelines';

function* saga() {
  yield takeLatest(constants.FETCH_TIMELINES_REQUEST, fetchTimelines);
  yield takeLatest(constants.FETCH_TIMELINES_SUCCESS, paginateTimelines);
}

export {
  saga,
};

export default saga;
