import { call } from 'redux-saga/effects';

import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';

import actionCreators from '../actionCreators';

function* fetchProjectSuite(action) {
  const { endpoint, projectSuiteId, urlParams, ...rest } = action.payload;
  const { response, error } = yield call(getPath, endpoint, { urlParams });

  const params = {
    response,
    error,
    successDisp: actionCreators.fetchProjectSuiteSuccess,
    failDisp: actionCreators.fetchProjectSuiteFailure,
    endpoint,
    id: projectSuiteId,
    ...rest,
  };

  yield call(processCall, params);
}

export default fetchProjectSuite;
