import React from 'react';
import PropTypes from 'prop-types';

import RefreshInterface from 'components/RefreshInterface/RefreshInterface';

export default function OutputRefreshInterface({
  deviceId,
  dataId,
  meta,
  selectedProtocol,
  selectedUniverse,
  isSubscribing,
  isSubscribed,
  isRefreshing,
  onSubscribe,
  onUnsubscribe,
  onRefresh,
  onLiveUpdatesChange,
}) {
  const handleSubscribe = () => {
    onLiveUpdatesChange({ isEnabled: true });
    onSubscribe(deviceId, dataId, meta);
  };

  const handleUnsubscribe = () => {
    onLiveUpdatesChange({ isEnabled: false });
    onUnsubscribe(deviceId, dataId, meta);
  };

  const handleRefresh = () => {
    const payload = {
      protocolId: selectedProtocol,
      universeId: selectedUniverse,
    };

    onRefresh(deviceId, dataId, payload);
  };

  return (
    <RefreshInterface
      deviceId={deviceId}
      dataId={dataId}
      meta={meta}
      isSubscribed={isSubscribed}
      isRefreshing={isRefreshing || isSubscribing}
      onSubscribe={handleSubscribe}
      onUnsubscribe={handleUnsubscribe}
      onRefresh={handleRefresh}
    />
  );
}

OutputRefreshInterface.defaultProps = {
  selectedProtocol: null,
  selectedUniverse: null,
};

OutputRefreshInterface.propTypes = {
  deviceId: PropTypes.string.isRequired,
  dataId: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  selectedProtocol: PropTypes.number,
  selectedUniverse: PropTypes.number,
  isSubscribing: PropTypes.bool.isRequired,
  isSubscribed: PropTypes.bool.isRequired,
  isRefreshing: PropTypes.bool.isRequired,
  onSubscribe: PropTypes.func.isRequired,
  onUnsubscribe: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onLiveUpdatesChange: PropTypes.func.isRequired,
};
