import { isObject, mapValues, pick } from 'lodash';

import { isUUID, asciiStringSizeInKB, percentageOf } from 'helpers/sharedMethods';

export const messages = {
  ABOVE_THRESHOLD: thresholdInKB => ({
    warning: `The size of this data is above the threshold of ${thresholdInKB}KB`,
  }),
  UUID_VALUE_REMOVED: 'Value removed',
  OTHER_VALUES_REMOVED: 'Other object values removed',
};

class DataWithSizeThreshold {

  constructor(originalData, thresholdInKB) {
    this.originalData = originalData;
    this.thresholdInKB = thresholdInKB;
  }

  get reducedData() {
    if (this.dataSizeAboveThreshold(this.originalData)) {
      return this.dataAfterAttemptedReduction();
    }

    return this.originalData;
  }

  dataAfterAttemptedReduction() {
    const reducedData = this.reduce();

    if (this.dataSizeAboveThreshold(reducedData)) {
      return this.aboveThresholdMessage();
    }

    return reducedData;
  }

  reduce() {
    return DataWithSizeThreshold.recursivelyReduceData(this.originalData);
  }

  dataSizeAboveThreshold(data) {
    if (!data) {
      return false;
    }

    return asciiStringSizeInKB(data) > this.thresholdInKB;
  }

  aboveThresholdMessage() {
    return messages.ABOVE_THRESHOLD(this.thresholdInKB);
  }

  static recursivelyReduceData(data, dataKey) {
    if (Array.isArray(data)) {
      return data.map(value => DataWithSizeThreshold.recursivelyReduceData(value));
    }

    if (isObject(data)) {
      if (isUUID(dataKey)) {
        return messages.UUID_VALUE_REMOVED;
      }

      if ('id' in data) {
        return { ...pick(data, ['id', 'type']), warning: messages.OTHER_VALUES_REMOVED };
      }

      return mapValues(
        data,
        (value, key) => DataWithSizeThreshold.recursivelyReduceData(value, key),
      );
    }

    return data;
  }

}

export default DataWithSizeThreshold;

export function reduce(data, allowance, percentage) {
  return new DataWithSizeThreshold(data, percentageOf(allowance, percentage)).reducedData;
}
