import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import { ChildPanelHeader, PanelSeparator, TabbedPanel } from './components';
import {
  StyledGrid,
  StyledHeaderRow,
  StyledHeaderColumn,
  StyledHeader,
  StyledSubheader,
} from './Panel.style';

export default function Panel({
  title,
  description,
  headerSideContent,
  children,
  withHeader,
}) {
  const renderHeader = () => (
    <StyledHeaderRow name="panel-header">
      <Grid.Column width={headerSideContent ? 10 : 16}>
        <StyledHeader as="h3">
          {title}
          <StyledSubheader>
            {description}
          </StyledSubheader>
        </StyledHeader>
      </Grid.Column>
      {headerSideContent && (
        <StyledHeaderColumn width={6}>
          {headerSideContent}
        </StyledHeaderColumn>
      )}
    </StyledHeaderRow>
  );

  return (
    <StyledGrid celled>
      {withHeader && renderHeader()}
      {children}
    </StyledGrid>
  );
}

export { ChildPanelHeader, PanelSeparator, TabbedPanel };

Panel.defaultProps = {
  headerSideContent: null,
  children: null,
  withHeader: true,
};

Panel.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
  ]).isRequired,
  headerSideContent: PropTypes.node,
  children: PropTypes.node,
  withHeader: PropTypes.bool,
};
