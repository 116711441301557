import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import redirectIfNotFound from 'helpers/redirectIfNotFound';
import {
  currentUserSelector,
  currentCompanyFetchingSelector,
  projectFetchingSelector,
  tenantChangedSelector,
  PROJECTS_FIELD,
} from 'helpers/selectors';
import { addPagination } from 'helpers/paginationHelpers';
import { actionCreators as copyPasteActionCreators } from 'application/tenant/components/structural/ControlUI/components/ControlUIPageCarousel/components/ControlUIPage/components/ControlUIButtonGroup/ControlUIButton/components/utilities';
import Projects from './Projects';
import projectActionCreators from './projectActionCreators';

const mapStateToProps = state => {
  const { id: userId } = currentUserSelector(state);

  return {
    userId,
    route: '/my-sites',
    fetching: currentCompanyFetchingSelector(state) || projectFetchingSelector(state),
    tenantNotChanged: !tenantChangedSelector(state),
    displayMap: true,
    statusWidgetsType: 'user',
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  redirectIfNotFound('tenantNotChanged'),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: PROJECTS_FIELD },
      ({ userId }) => projectActionCreators.fetchProjects(
        userId,
        {
          fields: { projects: ['name', 'usersCount', 'logicalDevicesCount', 'construction', 'state', 'createdAt', 'renewalDate'] },
          sort: 'name',
          filter: 'name',
        },
      ),
    ),
  ),
  chainedLoaderWithSaga(() => copyPasteActionCreators.clearCopy),
)(Projects);
