import React from 'react';
import PropTypes from 'prop-types';

import { StyledWrapper, StyledSliderBar } from './RenderTemperatureTrack.style';

const RenderTemperatureTrack = ({ props, children }) => {
  const { onMouseDown, onTouchStart, style, ref } = props;

  return (
    <StyledWrapper
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      style={{ ...style }}
      tabIndex={0}
      role="button"
    >
      <StyledSliderBar ref={ref}>
        {children}
      </StyledSliderBar>
    </StyledWrapper>
  );
};

RenderTemperatureTrack.defaultProps = {
  onMouseDown: () => null,
  onTouchStart: () => null,
  style: {},
  ref: {},
};

RenderTemperatureTrack.propTypes = {
  props: PropTypes.object.isRequired,
  onMouseDown: PropTypes.func,
  onTouchStart: PropTypes.func,
  style: PropTypes.object,
  ref: PropTypes.object,
  children: PropTypes.array.isRequired,
};

export default RenderTemperatureTrack;
