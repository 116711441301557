import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { Dimmer } from 'semantic-ui-react';

import colors from 'themes/colors';
import { media } from 'themes/mixins';
import sidebarConst from 'themes/variables';

const contentMargin = '70px';
const Nav = styled.div`
  min-height: 400px;
  margin-top: ${contentMargin};

  ${media.md`margin-top: 30vh;`};

  ${({ narrow }) => narrow && css`
    margin-top: ${contentMargin};

    ${media.md`
      margin-top: ${contentMargin};
      padding-left: ${sidebarConst.width.md};
    `};
  `};

  ${({ control }) => control && css`
    min-height: 0;
    background-color: transparent;
  `}
`;

Nav.Dimmer = styled(Dimmer).attrs({
  page: true,
})`
  &.ui.dimmer {
    z-index: 101;
    max-height: 100vh;
    height: 100vh;
    background-color: ${rgba(colors.black, 0.6)};
  }
`;

export default Nav;
