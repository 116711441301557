import { createSelector } from 'reselect';
import memoize from 'lodash/memoize';
import build from 'redux-object';

import {
  PROJECT_PROJECT_SUITE_PARENTS_FIELD,
  dataSelector,
  options,
} from 'helpers/selectors';

const projectsListSelector = createSelector(
  dataSelector,
  data => memoize(
    projectId => {
      const result = build(data, PROJECT_PROJECT_SUITE_PARENTS_FIELD, projectId || '', options);

      return result && result.projectSuiteParentsList;
    },
  ),
);

export default projectsListSelector;
