import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { currentUserSelector } from 'helpers/selectors';
import { selectors as permissionsSelectors } from 'application/tenant/console/utilities/permissions';
import userHasPermissionByTypeOrSuperadmin from './userHasPermissionByTypeOrSuperadmin';

export const mapStateToProps = state => {
  const { id: userId, superadmin } = currentUserSelector(state);
  const userPermissions = permissionsSelectors.userPermissionsSelector(state)(userId);

  return {
    userPermissions,
    superadmin,
  };
};

export default (permission, permissionType, resourceId) => compose(
  withRouter,
  connect(mapStateToProps),
  userHasPermissionByTypeOrSuperadmin(permission, permissionType, resourceId),
);
