import React, { Fragment } from 'react';

import { Normal, BelowNormal, AboveNormal, High, Low } from '../components';

const renderPriorityIcon = iconName => {
  if (iconName === 'normal') {
    return <Normal />;
  }

  if (iconName === 'belowNormal') {
    return <BelowNormal />;
  }

  if (iconName === 'aboveNormal') {
    return <AboveNormal />;
  }

  if (iconName === 'high') {
    return <High />;
  }

  if (iconName === 'low') {
    return <Low />;
  }

  return <Fragment />;
};

export default renderPriorityIcon;
