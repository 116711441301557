import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import { PROJECT_SUITES_FIELD } from 'helpers/selectors';

export const addProjectSuite = params => ({
  type: constants.ADD_PROJECT_SUITE_REQUEST,
  payload: {
    params,
    endpoint: endpoints.PROJECT_SUITES,
  },
});

export const addProjectSuiteSuccess = response => ({
  type: constants.ADD_PROJECT_SUITE_SUCCESS,
  payload: {
    response,
    message: messages.PROJECT_SUITE_ADDED(response.data.attributes.name),
    resourceType: PROJECT_SUITES_FIELD,
    id: response.data.id,
  },
});

export const addProjectSuiteFailure = error => ({
  type: constants.ADD_PROJECT_SUITE_FAILED,
  payload: { error },
});

export default addProjectSuite;
