import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  singleProjectSuiteSelector,
  permissionsProjectSuiteOpenSelector,
} from 'helpers/selectors';

import withLoadProjectSuite from './withLoadProjectSuite/withLoadProjectSuiteContainer';
import PermissionsProjectSuiteWrapper from './PermissionsProjectSuiteWrapper';

export const mapStateToProps = (state, { projectSuiteId }) => {
  const { name: projectSuiteName } = singleProjectSuiteSelector(state, projectSuiteId);

  return {
    projectSuiteName,
    projectSuiteOpen: permissionsProjectSuiteOpenSelector(state, { projectSuiteId }),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withLoadProjectSuite,
)(PermissionsProjectSuiteWrapper);
