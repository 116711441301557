import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import isInteger from 'lodash/isInteger';

import { isValidAutoRenewComment } from 'components/form/formValidators';
import siteActionCreators from 'application/tenant/console/site/utilities/actionCreators';
import SitePropertyInlineEdit from 'application/tenant/console/site/components/SitePropertyInlineEdit/SitePropertyInlineEditContainer';
import ThemedToggle from 'components/elements/ThemedToggle/ThemedToggle.style';
import {
  StyledContainer,
  StyledTitle,
  StyledFormContainer,
  StyledFormComment,
  StyledInputContainer,
} from './AutoRenewYearsForm.style';
import { options } from './constants';

const AutoRenewYearsForm = ({
  onUpdateSiteAutoRenewYears,
  autoRenewYears,
  toggleEnabled,
  siteId,
  showAutoRenewal,
}) => {
  const [years, setYears] = useState(0);

  const updateAutoRenewYears = value => new Promise(() => {
    onUpdateSiteAutoRenewYears({ values: { id: siteId, autoRenewYears: value } });
  });

  const handleToggle = (e, { checked }) => {
    if (checked) {
      return updateAutoRenewYears(1);
    }

    return updateAutoRenewYears(0);
  };

  const handleChange = (e, { value }) => updateAutoRenewYears(value);

  useEffect(() => {
    if (isInteger(autoRenewYears) && autoRenewYears >= 0) {
      setYears(autoRenewYears);
    }
  }, [autoRenewYears]);

  return showAutoRenewal && (
    <StyledContainer>
      <StyledTitle>Auto renew years</StyledTitle>
      <StyledFormContainer>
        <ThemedToggle onChange={handleToggle} checked={toggleEnabled} />
        <Dropdown
          placeholder="Years"
          selection
          options={options}
          disabled={!toggleEnabled}
          value={years}
          onChange={handleChange}
        />
      </StyledFormContainer>
      <StyledFormComment>
        <StyledTitle>Comment</StyledTitle>
        <StyledInputContainer>
          <SitePropertyInlineEdit
            property="autoRenewComment"
            validator={isValidAutoRenewComment}
            onChange={siteActionCreators.updateSiteAutoRenewComment}
          />
        </StyledInputContainer>
      </StyledFormComment>
    </StyledContainer>
  );
};

AutoRenewYearsForm.defaultProps = {
  autoRenewYears: 0,
};

AutoRenewYearsForm.propTypes = {
  siteId: PropTypes.string.isRequired,
  autoRenewYears: PropTypes.number,
  toggleEnabled: PropTypes.bool.isRequired,
  onUpdateSiteAutoRenewYears: PropTypes.func.isRequired,
  showAutoRenewal: PropTypes.bool.isRequired,
};

export default AutoRenewYearsForm;
