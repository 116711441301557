import {
  AttributesTab,
  ChangePasswordTab,
  TwoFactorAuthenticationTab,
  NotificationsLogTab,
} from './components';

export default [
  {
    menuItem: 'Details',
    path: '/details',
    rows: [
      {
        columns: [
          {
            computer: 16,
            mobile: 16,
            sections: [
              {
                component: AttributesTab,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Change Password',
    path: '/change_password',
    rows: [
      {
        columns: [
          {
            computer: 16,
            mobile: 16,
            sections: [
              {
                component: ChangePasswordTab,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Two Factor Authentication',
    path: '/two_factor_authentication',
    rows: [
      {
        columns: [
          {
            computer: 16,
            mobile: 16,
            sections: [
              {
                component: TwoFactorAuthenticationTab,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Notifications',
    path: '/notification_logs',
    rows: [
      {
        columns: [
          {
            computer: 16,
            mobile: 16,
            sections: [
              {
                component: NotificationsLogTab,
              },
            ],
          },
        ],
      },
    ],
  },
];
