import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { TENANT } from 'storageConst';
import {
  PROJECTS_FIELD,
  USERS_FIELD,
  LOGICAL_DEVICES_FIELD,
  currentUserSelector,
  permissionsStateSelector,
  projectResourcePermissionPathsSelector,
} from 'helpers/selectors';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import permissionsActionCreators from 'application/tenant/console/utilities/permissions/permissionsActionCreators';
import projectActionCreators from 'pages/Projects/projectActionCreators';
import withLoadPermissions from '../withLoadPermissions/withLoadPermissionsContainer';
import { projectPermissionsReady } from '../utilities';
import withProjectPermissions from './withProjectPermissions';

export const mapStateToProps = (state, { match }) => {
  const { id: projectId } = match.params;
  const { id: currentUserId } = currentUserSelector(state);
  const paths = projectResourcePermissionPathsSelector(state);
  const permissions = permissionsStateSelector(state) || {};

  return {
    currentUserId,
    projectId,
    paths,
    ready: projectPermissionsReady(permissions, projectId),
  };
};

export default compose(
  withRouter,
  withLoadPermissions,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    ({ projectId, ready }) => !ready && projectActionCreators.fetchProject(projectId, {
      fields: {
        [PROJECTS_FIELD]: [
          'name',
          'state',
          'subscriptionPlanCode',
          'points',
          'renewalDate',
          'graceEndDate',
          'projectedSubscriptionPlanCode',
          'constructionEndDate',
          'autoRenewYears',
          'autoRenewComment',
          'dateBlockingStartTime',
          USERS_FIELD,
          LOGICAL_DEVICES_FIELD,
        ],
      },
    }),
    ({ projectId, paths, ready }) => !ready && (
      permissionsActionCreators.addProjectId(projectId, paths)
    ),
    ({
      currentUserId,
      projectId,
      ready,
    }) => !ready && permissionsActionCreators.fetchUserPermissions(
      currentUserId,
      PROJECTS_FIELD,
      projectId,
      TENANT,
    ),
  ),
  withProjectPermissions,
);
