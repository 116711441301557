import styled, { css } from 'styled-components';
import { Input } from 'semantic-ui-react';

export const Container = styled.div`
  margin-top: 8px;
`;

export const StyledInput = styled(Input)`
  width: 100%;

  ${({ disabled }) => disabled && css`
    opacity: 1 !important;
  `}

  &&& {
    i.icon {
      margin: 0 !important;
    }

    ${({ disabled }) => disabled && css`
      input {
        opacity: 0.45 !important;
      }
    `}
  }
`;

export const ErrorMessage = styled.small`
  color: #9f3a38;
`;

export default Container;
