import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Grid, Header } from 'semantic-ui-react';

import TenantBackground from 'components/TenantBackground/TenantBackground.style';
import { StyledPublicHeader } from '../../components';
import { ResetPasswordForm } from './components';

const ResetPassword = ({
  passwordResetToken,
  onVerifyPasswordResetToken,
  onResetPassword,
}) => {
  const [error, setError] = useState(null);
  const [verifying, setVerifying] = useState(true);
  useEffect(() => {
    const verify = async () => {
      const errors = await new Promise(resolveForm => (
        onVerifyPasswordResetToken({ passwordResetToken, resolveForm })
      ));

      setError(errors);
      setVerifying(false);
    };

    verify();
  }, [onVerifyPasswordResetToken, passwordResetToken]);
  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = async values => {
    setSubmitting(true);

    return new Promise(
      resolveForm => onResetPassword({ passwordResetToken, values, resolveForm }),
    );
  };

  const layout = () => {
    if (verifying) {
      return (
        <div>
          <p>Verifying...</p>
        </div>
      );
    }

    if (!error) {
      return (
        <Fragment>
          <ResetPasswordForm
            onSubmit={handleSubmit}
            submitting={submitting}
          />
        </Fragment>
      );
    }

    if (error.unauthorized) {
      return (
        <div>
          <p>Your reset password token is not longer valid.</p>
          <p>
            Please reset your password again and use the link from the email.
          </p>
        </div>
      );
    }

    return error.toArray.map(item => (
      <p key={item}>{item}</p>
    ));
  };

  return (
    <Container>
      <TenantBackground />
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} computer={8} floated="right">
            <StyledPublicHeader as="header">
              <Header as="h2">Reset password</Header>
              {layout()}
            </StyledPublicHeader>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

ResetPassword.propTypes = {
  passwordResetToken: PropTypes.string.isRequired,
  onVerifyPasswordResetToken: PropTypes.func.isRequired,
  onResetPassword: PropTypes.func.isRequired,
};

export default ResetPassword;
