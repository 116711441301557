import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import isNil from 'lodash/isNil';

import ProjectSuiteContext from '../../../ProjectSuiteContext/ProjectSuiteContext';
import { ActionsTableHeader, AddActionButtonMobile, ActionsTableRow } from './components';
import { ActionTableWrapperCell } from './ActionsTable.style';

const ActionsTable = ({ item: taskItem }) => {
  const superSites = useContext(ProjectSuiteContext);
  const {
    actions,
    id: taskId,
    name: taskName,
    projectSuiteId,
    projectId,
  } = taskItem;
  const showButton = !superSites || (superSites && !isNil(projectSuiteId));

  return (
    <>
      <Table.Row>
        <Table.Cell>
          &nbsp;
        </Table.Cell>
        <ActionTableWrapperCell colSpan="2">
          <Table basic="very">
            <ActionsTableHeader taskId={taskId} superSites={superSites} showButton={showButton} />
            <Table.Body>
              {showButton && <AddActionButtonMobile taskId={taskId} superSites={superSites} />}
              {actions.map(action => (
                <ActionsTableRow
                  key={`${action.index}`}
                  item={action}
                  taskId={taskId}
                  taskName={taskName}
                  projectId={projectId}
                />
              ))}
            </Table.Body>
          </Table>
        </ActionTableWrapperCell>
      </Table.Row>
    </>
  );
};

ActionsTable.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    actions: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    projectSuiteId: PropTypes.string,
    projectId: PropTypes.string,
  }).isRequired,
};

export default ActionsTable;
