class TriggerModel {

  constructor(trigger = {}) {
    this.id = trigger.id;
    this.name = trigger.name;
    this.description = trigger.description;
  }

}

export default TriggerModel;
