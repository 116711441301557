import { emDashedData } from 'helpers/sharedVariables';

class StaticSceneModel {

  constructor(data = {}) {
    this.id = data.id;
    this.name = data.name;
    this.status = data.status || emDashedData;
    this.type = data.type;
  }

}

export default StaticSceneModel;
