import { useState } from 'react';

function useTabs() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (e, { activeIndex }) => {
    setActiveTab(activeIndex);
  };

  return {
    activeTab,
    onTabChange: handleTabChange,
  };
}

export default useTabs;
