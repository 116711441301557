import isNull from 'lodash/isNull';

const checkIfFetchResource = (actionType, paramKey, conditions) => {
  if (isNull(actionType) || isNull(paramKey) || !Array.isArray(conditions)) {
    return false;
  }

  if (
    conditions.find(condition => condition === actionType)
    && conditions.find(condition => condition === paramKey)
  ) {
    return true;
  }

  return false;
};

export default checkIfFetchResource;
