export const modifierTypes = {
  ACTIVE: 'active',
  TODAY: 'today',
  FUTURE: 'future',
  DISPATCHED: 'dispatched',
  PAST: 'past',
  DISPATCHED_SUCCESS: 'dispatched_success',
  DISPATCHED_ERROR: 'dispatched_error',
};

export const OCCURRENCES_WARNING = 'Occurrences are displayed for a maximum of 400 days beyond the Start Date of a Task Scheduler with new occurrences added every 30 days.';

export default modifierTypes;
