import { connect } from 'react-redux';

import { selectors } from '../../../../utilities';
import ActionsReview from './ActionsReview';

export const mapStateToProps = state => ({
  selectedActions: selectors.selectedActionsSelector(state),
  selectedTasks: selectors.selectedTasksSelector(state),
});

export default connect(mapStateToProps, null)(ActionsReview);
