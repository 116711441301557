import { connect } from 'react-redux';

import { actionCreators } from 'pages/ProjectSuites/utilities';

import ActionLinks from './ActionLinks';

export const mapStateToProps = (state, { match }) => {
  const { id: projectSuiteId } = match.params;

  return {
    projectSuiteId,
  };
};

const mapDispatchToProps = {
  onRemoveProjectFromProjectSuite: actionCreators.removeProjectFromProjectSuite,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionLinks);
