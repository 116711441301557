export const FILE_NAME = 'name';
export const FILE_SIZE = 'size';
export const FILE_LAST_MODIFIED = 'lastModified';

class FileComparator {

  constructor(file1, file2) {
    this.file1 = file1;
    this.file2 = file2;

    this.comparedKeys = new Set();
  }

  compareName() {
    this.comparedKeys.add(FILE_NAME);

    return this;
  }

  compareModifiedDate() {
    this.comparedKeys.add(FILE_LAST_MODIFIED);

    return this;
  }

  compareSize() {
    this.comparedKeys.add(FILE_SIZE);

    return this;
  }

  finalize() {
    const { comparedKeys, file1, file2 } = this;

    let result = true;
    comparedKeys.forEach(key => {
      if (!result) return;

      if (file1[key] !== file2[key]) {
        result = false;
      }
    });

    return result;
  }

}

export default FileComparator;
