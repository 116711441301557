import constants from 'dispatcherConst';

export const setTaskName = taskName => ({
  type: constants.SET_TASK_NAME,
  payload: {
    taskName,
  },
});

export default setTaskName;
