import { put, select, call } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import saveTask from 'application/tenant/console/site/components/structural/SiteSchedulingTab/components/AutomatedOperations/components/AddAutomatedOperation/utilities/saga/saveTask';
import saveActions from 'application/tenant/console/site/components/structural/SiteSchedulingTab/components/AutomatedOperations/components/AddAutomatedOperation/utilities/saga/saveActions';

import selectors from '../selectors';
import actionCreators from '../actionCreators';

function* updateButton(action) {
  const {
    projectId,
    pageId,
    buttonId,
    task,
    actions,
    actionsForm,
    values,
    resourceType = 'project',
    ...rest
  } = action.payload;
  const { taskIds } = values;

  const { pages, ...attributes } = (yield select(selectors.projectControlUISelector))(projectId);

  if (task) {
    const saveTaskParams = {
      endpoint: endpoints.TASKS,
      taskName: task.name,
      resourceId: projectId,
      resourceType,
    };

    const createdTask = yield call(saveTask, saveTaskParams);

    taskIds.push(createdTask.id);

    const saveActionsParams = {
      task: createdTask,
      actions,
      actionsForm,
      resourceType,
    };

    yield call(saveActions, saveActionsParams);

    if (resourceType === 'projectSuite') {
      yield put({
        type: constants.PROJECT_SUITE_CONTROL_PANEL_TASK_ADDED,
        payload: {
          task: createdTask,
        },
      });
    }
  }

  const newPages = pages.map(({ index, buttons, ...page }) => {
    if (index === pageId) {
      const newButtons = buttons.map(({ index: buttonIndex, ...button }) => {
        if (buttonIndex === buttonId) {
          return {
            index: buttonIndex,
            ...button,
            ...values,
          };
        }

        return {
          index: buttonIndex,
          ...button,
        };
      });

      return {
        index,
        ...page,
        buttons: newButtons,
      };
    }

    return {
      index,
      buttons,
      ...page,
    };
  });

  yield put(actionCreators.updateControlUI({
    pages: newPages,
    ...attributes,
    ...rest,
  }));
}

export default updateButton;
