import { compose } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { searchParamsSelector } from 'helpers/selectors';
import filterProps from 'helpers/filterProps';

const mapStateToProps = (state, { to }) => ({
  to: {
    pathname: to,
    search: searchParamsSelector(to)(state),
  },
});

export default compose(
  connect(mapStateToProps),
  filterProps('dispatch'),
)(NavLink);
