import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { singleProjectSelector, singleProjectSuiteSelector } from 'helpers/selectors';
import formNames from 'components/form/formNames';
import { validateOnSubmit } from 'components/form/formConfigs';
import AddTaskModalForm from './AddTaskModalForm';

const AddTaskModalFormContainer = reduxForm({
  form: formNames.addNewTask,
  ...validateOnSubmit,
})(AddTaskModalForm);

export const mapStateToProps = (state, ownProps) => {
  const { resourceId } = ownProps;
  const {
    name: projectName,
    isSuspended,
    isConstruction,
  } = singleProjectSelector(state, resourceId) || {};
  const {
    name: projectSuiteName,
  } = singleProjectSuiteSelector(state, resourceId) || {};

  return {
    projectName,
    projectSuiteName,
    isSuspended,
    isConstruction,
  };
};

export default connect(mapStateToProps)(AddTaskModalFormContainer);
