import camelCase from 'lodash/camelCase';
import findKey from 'lodash/findKey';
import isFunction from 'lodash/isFunction';
import lastItem from 'lodash/last';
import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import isObject from 'lodash/isObject';

import UrlParams from 'helpers/UrlParams';
import { replaceArray, removeApiUrl } from 'helpers/sharedMethods';

export const PAGE_FIELD = 'page';
export const PAGE_SIZE_FIELD = 'pageSize';
export const SORT_FIELD = 'sort';
export const FILTER_FIELD = 'filter';

export const SORT_ASCENDING = 'ascending';
export const SORT_DESCENDING = 'descending';

export const DEFAULT_PAGE_SIZE = 10;
export const AVAILABLE_PAGE_SIZES = [DEFAULT_PAGE_SIZE, 20, 50, 100];

export const searchParam = paramName => ({ type, included = false, componentName = null } = {}) => {
  if (componentName) {
    return `${paramName}[${componentName}]`;
  }

  return included ? `${paramName}[${type}]` : paramName;
};

export const pageParam = searchParam(PAGE_FIELD);
export const pageSizeParam = searchParam(PAGE_SIZE_FIELD);
export const sortParam = searchParam(SORT_FIELD);
export const filterParam = searchParam(FILTER_FIELD);

export const extractUrlParamsFromLinks = links => {
  if (!links) return {};

  const { page, sort, filter } = new UrlParams({ links });

  return { page, sort, filter };
};

export const handleArrayResponse = (state, action) => {
  const { response: { data, links, meta, componentName } } = action.payload;
  const { pathname } = state;

  const link = findKey(meta);
  const checkResourceType = camelCase(lastItem(link.split('/')));
  if (!checkResourceType && !componentName) return {};

  const resourceType = componentName || checkResourceType;

  const { page, sort, filter } = extractUrlParamsFromLinks(links);

  const updatedStatePiece = {
    [resourceType]: {
      data: data && data.map(o => o.id),
      error: false,
      fetching: false,
      link,
      page,
      sort,
      filter,
      refetch: false,
    },
  };

  return mergeWith(
    {},
    state[pathname],
    updatedStatePiece,
    replaceArray,
  );
};

export const handleObjectResponse = (state, action) => {
  const { response: { data: { relationships } } } = action.payload;
  const { pathname } = state;

  if (!relationships) {
    return null;
  }

  return Object.keys(relationships).reduce((acc, key) => {
    const { [key]: { links: { related } } } = relationships;

    if (!!acc && key in acc) {
      return acc;
    }

    return merge({}, acc, {
      [key]: {
        link: removeApiUrl(related),
        refetch: true,
        included: true,
      },
    });
  }, state[pathname]);
};

export const handleResourceFetch = (state, action) => {
  const {
    urlParams: { page: { number } = {}, sort, filter },
    resourceType,
    included,
    chainedLoaderId,
    componentName,
  } = action.payload;
  const { pathname } = state;

  const urlParams = new UrlParams({
    pagination: {
      type: resourceType,
      included,
      args: {
        filterField: filter && Object.keys(filter)[0],
      },
    },
  });
  urlParams.page = number;
  urlParams.sort = sort;

  const { search } = urlParams;

  const resourceTypeForState = componentName || resourceType;

  return merge({}, state, {
    [pathname]: {
      search,
      [resourceTypeForState]: {
        error: false,
        fetching: true,
        refetch: false,
        chainedLoaderId,
      },
    },
  });
};

export const handleResourceFetchError = (state, action) => {
  const { payload: { componentName, resourceType } } = action;
  const resourceTypeForState = componentName || resourceType;
  const { pathname } = state;

  return merge({}, state, {
    [pathname]: {
      [resourceTypeForState]: {
        error: true,
        fetching: false,
        refetch: false,
      },
    },
  });
};

export const handleResourceFetchSuccess = (state, action) => {
  const { pathname } = state;

  if (!pathname) {
    return { ...state };
  }

  const { response: { data } } = action.payload;

  let stateSlice;
  if (Array.isArray(data)) {
    // eslint-disable-next-line no-use-before-define
    stateSlice = lib.handleArrayResponse(state, action);
  } else if (isObject(data)) {
    // eslint-disable-next-line no-use-before-define
    stateSlice = lib.handleObjectResponse(state, action);
  }

  const stateToReturn = stateSlice === null ? { ...state } : { ...state, [pathname]: stateSlice };

  return stateToReturn;
};

export const handleResourceEdit = (state, action) => {
  const { pathname } = state;
  const { payload: { resourceType, id } } = action;

  return merge({}, state, {
    [pathname]: {
      [resourceType]: {
        refetch: true,
        active: id,
      },
    },
  });
};

export const enhanceWithParams = action => ({ name, included, ...rest }) => {
  // Names must match props from withConfigurablePollingContainer HOC
  const { pollingValue, pollingRequestId } = rest;
  const pagination = { type: name, included };

  const urlParams = new UrlParams({ pagination });
  const { number, size, sort, filter } = urlParams.extractUrlParams({
    number: urlParams.pageParam,
    size: urlParams.pageSizeParam,
    sort: urlParams.sortParam,
    filter: urlParams.filterParam,
  });

  const invokedAction = isFunction(action) ? action(rest) : action;
  const { sort: defaultSort = null } = invokedAction.payload.urlParams;

  return merge({}, invokedAction, {
    payload: {
      urlParams: {
        page: {
          number: Number(number || 1),
          size: Number(size || DEFAULT_PAGE_SIZE),
        },
        sort: sort || defaultSort,
        refreshRate: pollingValue,
        requestId: pollingRequestId,
        filter,
      },
      included,
      resourceType: name,
    },
  });
};

export const addPagination = ({ resourceType, included, componentName }, action) => props => {
  const { pollingValue, pollingRequestId } = props;
  const pagination = { type: resourceType, included, componentName };

  return () => {
    const urlParams = new UrlParams({ pagination });
    const { number, size, sort, filter } = urlParams.extractUrlParams({
      number: urlParams.pageParam,
      size: urlParams.pageSizeParam,
      sort: urlParams.sortParam,
      filter: urlParams.filterParam,
    });

    const invokedAction = isFunction(action) ? action(props) : action;

    const {
      urlParams: {
        sort: defaultSort = null,
        filter: filterField = null,
      } = {},
    } = invokedAction.payload;

    return merge({}, invokedAction, {
      payload: {
        urlParams: {
          page: {
            number: Number(number || 1),
            size: Number(size || DEFAULT_PAGE_SIZE),
          },
          sort: sort || defaultSort,
          filter: filterField ? {
            [filterField]: filter,
          } : null,
          refreshRate: pollingValue,
          requestId: pollingRequestId,
        },
        included,
        resourceType,
        componentName,
      },
    });
  };
};

// For testing purposes
// Allows mocking inside handleResourceFetchSuccess()
export const lib = {
  handleArrayResponse,
  handleObjectResponse,
};
