import { call } from 'redux-saga/effects';

import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';

import actionCreators from '../actionCreators';

function* fetchProjectSuiteProjectsStatus(action) {
  const { endpoint, urlParams, ...rest } = action.payload;
  const { response, error } = yield call(getPath, endpoint, { urlParams });

  const params = {
    response,
    error,
    successDisp: actionCreators.fetchProjectSuiteProjectsStatusSuccess,
    failDisp: actionCreators.fetchProjectSuiteProjectsStatusFailure,
    ...rest,
  };

  yield call(processCall, params);
}

export default fetchProjectSuiteProjectsStatus;
