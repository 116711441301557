import React from 'react';
import PropTypes from 'prop-types';

import { layoutSectionType } from 'data/types';

const TabbedLayoutSection = ({ resourceId, title, component: Section }) => (
  <Section resourceId={resourceId} title={title} />
);

TabbedLayoutSection.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  title: '',
  resourceId: null,
};

TabbedLayoutSection.propTypes = {
  ...layoutSectionType,
  resourceId: PropTypes.string,
};

export default TabbedLayoutSection;
