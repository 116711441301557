import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import sortBy from 'lodash/sortBy';

import { StyledLabel } from './UsersSelect.style';

function UsersSelect({ users, selectedUsersIds, onChange }) {
  const options = useMemo(() => {
    if (!users) {
      return [];
    }

    let availableUsers = [];

    availableUsers = users.filter(user => !selectedUsersIds.includes(user.id));

    availableUsers = sortBy(availableUsers, ['firstName']);

    return availableUsers.map(user => ({
      key: user.id,
      text: user.fullNameOrEmail,
      value: user.id,
    }));
  }, [users, selectedUsersIds]);

  const handleChange = useCallback((e, { value }) => {
    const [id] = value;

    onChange(id);
  }, [onChange]);

  return (
    <>
      <StyledLabel>
        Select users
      </StyledLabel>
      <Dropdown
        placeholder="Select users"
        labeled
        value={[]}
        options={options}
        onChange={handleChange}
        fluid
        selection
        search
        selectOnBlur={false}
        closeOnChange
        multiple
      />
    </>
  );
}

UsersSelect.defaultProps = {
  users: [],
  selectedUsersIds: [],
};

UsersSelect.propTypes = {
  users: PropTypes.array,
  selectedUsersIds: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

export default UsersSelect;
