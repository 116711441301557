import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withProjectPermissions } from 'gateway';
import { TENANT } from 'storageConst';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import permissionsActionCreators from 'application/tenant/console/utilities/permissions/permissionsActionCreators';
import { PROJECTS_FIELD, currentUserSelector } from 'helpers/selectors';
import ProjectSettingsTab from './ProjectSettingsTab';

export const mapStateToProps = (state, { match }) => {
  const { id: projectId } = match.params;
  const { id: currentUserId } = currentUserSelector(state);

  return {
    currentUserId,
    projectId,
  };
};

export default compose(
  withRouter,
  withProjectPermissions,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    ({
      currentUserId,
      projectId,
    }) => permissionsActionCreators.fetchUserPermissions(
      currentUserId,
      PROJECTS_FIELD,
      projectId,
      TENANT,
    ),
  ),
)(ProjectSettingsTab);
