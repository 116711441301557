import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment } from 'semantic-ui-react';

import {
  LogsFilterParams,
  LogsSection,
  LocalLogsFilterParams,
  RenderAppliedFilters,
} from './components';
import { StyledRow, StyledRowBorder } from './SiteActivityLoggingTab.style';
import { filterLocalLogs } from './utilities';
import { initialFilterParams } from './utilities/constants';

const SiteActivityLoggingTab = ({
  logsData,
  options,
  isLoading,
  logsDataExists,
}) => {
  const [filterParams, setFilterParams] = useState(initialFilterParams);
  const filteredData = filterLocalLogs(filterParams, logsData);

  return (
    <Segment>
      <Grid stackable>
        <Grid.Row>
          <LogsFilterParams
            setFilterParams={setFilterParams}
          />
        </Grid.Row>
        <StyledRowBorder
          isLoading={isLoading}
          logsDataExists={logsDataExists}
        >
          <LocalLogsFilterParams
            onSearch={setFilterParams}
            options={options}
            filterParams={filterParams}
          />
        </StyledRowBorder>
        <StyledRow>
          <RenderAppliedFilters
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
        </StyledRow>
        <Grid.Row>
          <LogsSection data={filteredData} />
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

SiteActivityLoggingTab.defaultProps = {
  isLoading: false,
  logsDataExists: false,
};

SiteActivityLoggingTab.propTypes = {
  isLoading: PropTypes.bool,
  logsDataExists: PropTypes.bool,
  logsData: PropTypes.array.isRequired,
  options: PropTypes.object.isRequired,
};

export default SiteActivityLoggingTab;
