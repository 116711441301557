import React from 'react';

import StyledSuspendedSiteIcon, { StyledDiv } from '../../StyledSuspendedSiteIcon.style';

const StyledSuspendedSite = React.forwardRef((props, ref) => (
  <StyledDiv ref={ref}>
    <StyledSuspendedSiteIcon {...props} />
  </StyledDiv>
));

export default StyledSuspendedSite;
