import React from 'react';
import { OnDateTime, AfterNOcurrences } from './components';

const ENDING_CRITERIA_HELP = 'Select when the Scheduler should end. \'Never ends\' occurrences will only display for 400 days.';

const ENDING_CRITERIA = {
  ON_DATE_TIME: 'ON_DATE_TIME',
  AFTER_N_OCCURRENCES: 'AFTER_N_OCCURRENCES',
  NEVER_ENDS: 'NEVER_ENDS',
};

const ENDING_CRITERIA_OPTIONS = [
  {
    key: ENDING_CRITERIA.NEVER_ENDS,
    value: ENDING_CRITERIA.NEVER_ENDS,
    text: 'Never ends',
  },
  {
    key: ENDING_CRITERIA.ON_DATE_TIME,
    value: ENDING_CRITERIA.ON_DATE_TIME,
    text: 'On a date & time',
  },
  {
    key: ENDING_CRITERIA.AFTER_N_OCCURRENCES,
    value: ENDING_CRITERIA.AFTER_N_OCCURRENCES,
    text: 'After n occurrences',
  },
];

const ENDING_CRITERIA_VIEWS = {
  NEVER_ENDS: () => null,
  ON_DATE_TIME: props => <OnDateTime {...props} />,
  AFTER_N_OCCURRENCES: props => <AfterNOcurrences {...props} />,
};

export {
  ENDING_CRITERIA_HELP,
  ENDING_CRITERIA,
  ENDING_CRITERIA_OPTIONS,
  ENDING_CRITERIA_VIEWS,
};
