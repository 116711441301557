import { connect } from 'react-redux';

import {
  deviceActionsListSelector,
  allActionsTypeSelector,
} from 'helpers/selectors';
import { createDropdownOptionForActions } from './utilities';
import SelectDeviceAction from './SelectDeviceAction';

export const mapStateToProps = (state, ownProps) => {
  const { selectDeviceAction, logicalDeviceId } = ownProps;
  const getDeviceAction = deviceActionsListSelector(state)(logicalDeviceId);
  const allActionTypes = allActionsTypeSelector(state);

  const deviceActionList = getDeviceAction
    ? getDeviceAction.actions
    : null;

  const optionList = deviceActionList
    ? deviceActionList
      .filter(item => allActionTypes[item])
      .map(item => createDropdownOptionForActions(item, allActionTypes))
    : [];

  return {
    selectDeviceAction,
    optionList,
  };
};

export default connect(mapStateToProps)(SelectDeviceAction);
