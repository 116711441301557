import has from 'lodash/has';

const permissionsTableRowsSelector = (state, resourceId) => {
  const { permissionsTableRows } = state.permissionsTable;

  if (!has(permissionsTableRows, resourceId)) {
    return [];
  }

  return permissionsTableRows[resourceId];
};

export default permissionsTableRowsSelector;
