import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';

const triggersActionCreators = {
  fetchTriggers: (deviceId, urlParams = {}) => ({
    type: constants.FETCH_TRIGGERS_REQUEST,
    payload: {
      deviceId,
      endpoint: endpoints.LOGICAL_DEVICE_TRIGGERS(deviceId),
      urlParams,
    },
  }),

  fetchTriggersSuccess: response => ({
    type: constants.FETCH_TRIGGERS_SUCCESS,
    payload: { response },
  }),

  fetchTriggersFailure: error => ({
    type: constants.FETCH_TRIGGERS_FAILED,
    payload: {
      info: !error.unauthorized && error.notFound && messages.NO_TRIGGERS,
      error: !error.notFound && error,
    },
  }),

  fetchTriggersSuccessPaginated: response => ({
    type: constants.FETCH_TRIGGERS_PAGINATED,
    payload: { response },
  }),

  fetchDeviceTriggers: deviceId => ({
    type: constants.FETCH_DEVICE_TRIGGERS_REQUEST,
    payload: {
      deviceId,
      endpoint: endpoints.LOGICAL_DEVICE_TRIGGERS(deviceId),
    },
  }),

  fetchDeviceTriggersSuccess: (response, deviceId) => ({
    type: constants.FETCH_DEVICE_TRIGGERS_SUCCESS,
    payload: { response, deviceId },
  }),

  fetchDeviceTriggersFailure: error => ({
    type: constants.FETCH_DEVICE_TRIGGERS_FAILED,
    payload: {
      info: !error.unauthorized && error.notFound && messages.NO_TRIGGERS,
      error: !error.notFound && error,
    },
  }),
};

export default triggersActionCreators;
