import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const updateAutomatedFunction = params => ({
  type: constants.UPDATE_AUTOMATED_FUNCTION_REQUEST,
  payload: {
    params,
    endpoint: endpoints.AUTOMATED_FUNCTION(params.id),
  },
});

export const updateAutomatedFunctionSuccess = response => ({
  type: constants.UPDATE_AUTOMATED_FUNCTION_SUCCESS,
  payload: {
    response,
    message: response.message,
  },
});

export const updateAutomatedFunctionFailure = error => ({
  type: constants.UPDATE_AUTOMATED_FUNCTION_FAILED,
  payload: { error },
});
