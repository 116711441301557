import merge from 'lodash/merge';

import constants from 'dispatcherConst';
import {
  CONTEXTS_FIELD,
  PROJECTS_FIELD,
  PROJECT_SUITES_FIELD,
} from 'helpers/selectors';
import {
  extractLinks,
  setUserLoaded,
  addContext,
  addProject,
  addProjectSuite,
  toggleTreeOpen,
  addUserId,
  removeUserId,
  resourceCopy,
  addLogicalDevices,
} from './permissionsHelpers';

export const initialState = {
  permissionsLoaded: false,
  selectingContexts: false,
  selectingProjects: false,
  selectingProjectSuites: false,
  projects: {},
  projectSuites: {},
  logicalDevices: {},
  contexts: {},
  links: {},
};

const permissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CHANGE_TENANT:
      return initialState;

    case constants.FETCH_INDEX_PERMISSIONS_SUCCESS:
      return { ...state, permissionsLoaded: true };

    case constants.PERMISSIONS_TOGGLE_RESOURCE_OPEN: {
      const { resourceType, resourceId } = action.payload;
      const { [resourceType]: { [resourceId]: { open } } } = state;

      return merge(
        {},
        state,
        {
          [resourceType]: {
            [resourceId]: {
              open: !open,
            },
          },
        },
      );
    }

    case constants.PERMISSIONS_TOGGLE_RESOURCE_TREE_OPEN: {
      const { resourceType, resourceId, path } = action.payload;

      return toggleTreeOpen(state, resourceType, resourceId, path);
    }

    case constants.PERMISSIONS_TOGGLE_RESOURCE_TREE_ALL_BRANCHES_OPEN:
      return state;

    case constants.PERMISSIONS_RESOURCE_ADD_USER_ID:
      return addUserId(state, action);

    case constants.PERMISSIONS_RESOURCE_REMOVE_USER_ID:
      return removeUserId(state, action);

    case constants.PERMISSIONS_RESOURCE_COPY:
      return resourceCopy(state, action);

    case constants.FETCH_CONTEXTS_SUCCESS:
      return extractLinks(state, action, CONTEXTS_FIELD);

    case constants.PERMISSIONS_TOGGLE_SELECTING_CONTEXTS: {
      const { selectingContexts } = state;

      return { ...state, selectingContexts: !selectingContexts };
    }

    case constants.PERMISSIONS_ADD_CONTEXT_ID_WITH_PATHS:
      return addContext(state, action);

    case constants.FETCH_PROJECTS_SUCCESS:
    case constants.FETCH_PROJECT_SUCCESS:
    case constants.FETCH_PROJECT_SUITE_PROJECTS_SUCCESS:
      return extractLinks(state, action, PROJECTS_FIELD);

    case constants.FETCH_PROJECT_SUITES_SUCCESS:
    case constants.FETCH_PROJECT_SUITE_SUCCESS:
      return extractLinks(state, action, PROJECT_SUITES_FIELD);

    case constants.PERMISSIONS_FETCH_PROJECT_USERS_SUCCESS: {
      const { response: { projectId, data } } = action.payload;
      const {
        projects: {
          [projectId]: {
            defaultUsersLoaded,
          },
        },
      } = state;

      if (defaultUsersLoaded) {
        return state;
      }

      const userIds = data.map(({ id }) => id);
      const activeUserIds = userIds.slice(0, 5);

      return merge(
        {},
        state,
        {
          projects: {
            [projectId]: {
              activeUserIds,
              defaultUsersLoaded: true,
            },
          },
        },
      );
    }

    case constants.PERMISSIONS_FETCH_PROJECT_SUITE_USERS_SUCCESS: {
      const { response: { projectSuiteId, data } } = action.payload;

      const {
        projectSuites: {
          [projectSuiteId]: {
            defaultUsersLoaded,
          },
        },
      } = state;

      if (defaultUsersLoaded) {
        return state;
      }

      const userIds = data.map(({ id }) => id);
      const activeUserIds = userIds.slice(0, 5);

      return merge(
        {},
        state,
        {
          projectSuites: {
            [projectSuiteId]: {
              activeUserIds,
              defaultUsersLoaded: true,
            },
          },
        },
      );
    }

    case constants.PERMISSIONS_TOGGLE_SELECTING_PROJECTS: {
      const { selectingProjects } = state;

      return { ...state, selectingProjects: !selectingProjects };
    }

    case constants.PERMISSIONS_TOGGLE_SELECTING_PROJECT_SUITES: {
      const { selectingProjectSuites } = state;

      return { ...state, selectingProjectSuites: !selectingProjectSuites };
    }

    case constants.PERMISSIONS_ADD_PROJECT_ID:
      return addProject(state, action);

    case constants.PERMISSIONS_ADD_PROJECT_SUITE_ID:
      return addProjectSuite(state, action);

    case constants.FETCH_RESOURCE_USER_PERMISSIONS_SUCCESS:
    case constants.FETCH_CONTEXT_USER_PERMISSIONS_SUCCESS:
      return setUserLoaded(state, action);

    case constants.PERMISSIONS_FETCH_PROJECT_LOGICAL_DEVICES_SUCCESS:
      return addLogicalDevices(state, action);

    default:
      return state;
  }
};

export default permissionsReducer;
