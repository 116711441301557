import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { PermissionsSwitch, Gateway, frontendPermissions } from 'gateway';
import { Button } from 'components/atomic';
import ConfirmModal from 'components/elements/ConfirmModal/ConfirmModal';
import { getDisplayName } from 'helpers/sharedMethods';

const withDeleteAutomatedOperation = WrappedComponent => {

  const WithDeleteAutomatedOperation = props => {
    const {
      item: { name, id, lockVersion },
      onDeleteAutomatedOperation,
      isMultiSite,
    } = props;

    const trigger = <WrappedComponent title="Delete" />;
    const handleConfirm = () => onDeleteAutomatedOperation(id, lockVersion);

    const permission = useMemo(() => {
      if (isMultiSite) {
        return frontendPermissions.PROJECT_SUITE_AUTOMATED_OPERATIONS_DELETE;
      }

      return frontendPermissions.DELETE_AUTOMATED_OPERATION;
    }, [isMultiSite]);

    return (
      <PermissionsSwitch>
        <Gateway condition={permission}>
          <ConfirmModal
            trigger={trigger}
            cancelButton="Cancel"
            confirmButton={<Button negative primary={false}>Delete task scheduler</Button>}
            content={(
              <div>
                <p>
                  Are you sure you want to delete task scheduler <strong>{name}</strong>?
                  This action cannot be undone.
                </p>
              </div>
            )}
            header="Confirm task scheduler deletion"
            onConfirm={handleConfirm}
          />
        </Gateway>
      </PermissionsSwitch>
    );
  };

  WithDeleteAutomatedOperation.defaultProps = {
    isMultiSite: false,
  };

  WithDeleteAutomatedOperation.propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      lockVersion: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }).isRequired,
    onDeleteAutomatedOperation: PropTypes.func.isRequired,
    isMultiSite: PropTypes.bool,
  };

  WithDeleteAutomatedOperation.displayName = `WithDeleteAutomatedOperation(${getDisplayName(WrappedComponent)})`;

  return WithDeleteAutomatedOperation;

};

export default withDeleteAutomatedOperation;
