import {
  fetchServiceVersions,
  fetchServiceVersionsSuccess,
  fetchServiceVersionsFailure,
} from './fetchServiceVersions';

import {
  changeApiDomain,
  changeApiDomainSuccess,
  changeApiDomainFailure,
} from './changeApiDomain';

import {
  fetchBackendVersion,
  fetchBackendVersionSuccess,
  fetchBackendVersionFailure,
} from './fetchBackendVersion';

export default {
  fetchServiceVersions,
  fetchServiceVersionsSuccess,
  fetchServiceVersionsFailure,
  changeApiDomain,
  changeApiDomainSuccess,
  changeApiDomainFailure,
  fetchBackendVersion,
  fetchBackendVersionSuccess,
  fetchBackendVersionFailure,
};
