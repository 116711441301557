import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';

import formNames from 'components/form/formNames';
import { validateOnSubmit } from 'components/form/formConfigs';
import { settingsApiDomainSelector } from 'helpers/selectors';
import DomainModalForm, { apiDomainFormFields } from './DomainModalForm';

const DomainModalFormContainer = reduxForm({
  enableReinitialize: true,
  form: formNames.apiDomain,
  ...validateOnSubmit,
})(DomainModalForm);

export const getInitialValues = state => {
  const {
    REACT_APP_API_URL: primary,
    REACT_APP_API_URL_SECONDARY: secondary,
  } = process.env;
  const apiDomain = settingsApiDomainSelector(state);

  if ([primary, secondary].includes(apiDomain)) {
    return { domainSelect: apiDomain };
  }

  return {
    domain: apiDomain,
    domainSelect: primary,
  };
};

const mapStateToProps = state => ({
  isSelectDisabled: !!(
    formValueSelector(formNames.apiDomain)(state, apiDomainFormFields.domain)
  ),
  initialValues: getInitialValues(state),
});

export default connect(mapStateToProps)(DomainModalFormContainer);
