import React from 'react';
import PropTypes from 'prop-types';

import { CONTEXTS_FIELD } from 'helpers/selectors';
import DataTable from 'components/DataTable/DataTableContainer';
import { StyledContextsTableWrapper } from './ContextsTable.style';
import ContextsRow from './ContextsRow/ContextsRowContainer';

const contextsTableLabels = [
  { text: 'Context', field: 'displayName', sortBy: 'displayName' },
];

const ContextsTable = ({ selectingContexts }) => selectingContexts && (
  <StyledContextsTableWrapper>
    <DataTable
      header="Contexts"
      labels={contextsTableLabels}
      customTableRow={ContextsRow}
      pagination={{
        type: CONTEXTS_FIELD,
      }}
    />
  </StyledContextsTableWrapper>
);

ContextsTable.propTypes = {
  selectingContexts: PropTypes.bool.isRequired,
};

export default ContextsTable;
