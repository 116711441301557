import FirmwareV1 from 'components/Firmware/FirmwareV1Container';
import DeviceFetcher from 'layouts/DeviceFetcher/DeviceFetcherContainer';
import PathwayHardwareV1 from 'layouts/Pathway/components/PathwayHardwareV1/PathwayHardwareV1VIAContainer';
import PathwayNetworkInterfaceV1 from 'layouts/Pathway/components/PathwayNetworkInterfaceV1/PathwayNetworkInterfaceV1Container';
import PathwayDevicePropertiesV1 from 'layouts/Pathway/components/PathwayDevicePropertiesV1/PathwayDevicePropertiesV1Container';
import PathwayLogsV1 from 'layouts/Pathway/components/PathwayLogsV1/PathwayLogsV1';
import PathwayFilesV1 from 'layouts/Pathway/components/PathwayFilesV1/PathwayFilesV1';
import PathwayRebootDeviceV1 from 'layouts/Pathway/components/PathwayRebootDeviceV1/PathwayRebootDeviceV1Container';
import PathwayIdentifyV1 from 'layouts/Pathway/components/PathwayIdentifyV1/PathwayIdentifyV1VIAContainer';

import { PathwayVIA12withPoePortsV1 } from './VIA/VIA12withPoe';

export default [
  {
    menuItem: 'Overview',
    path: '/overview',
    fetcher: DeviceFetcher,
    rows: [
      {
        columns: [
          {
            computer: 8,
            mobile: 16,
            sections: [
              {
                title: 'Hardware',
                component: PathwayHardwareV1,
              },
              {
                title: 'Device properties',
                component: PathwayDevicePropertiesV1,
              },
            ],
          },
          {
            computer: 8,
            mobile: 16,
            sections: [
              {
                title: 'Management network interface',
                component: PathwayNetworkInterfaceV1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Ports',
    path: '/ports',
    fetcher: DeviceFetcher,
    rows: [
      {
        columns: [
          {
            computer: 16,
            mobile: 16,
            sections: [
              {
                title: 'Ports',
                component: PathwayVIA12withPoePortsV1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Files',
    path: '/files',
    fetcher: DeviceFetcher,
    rows: [
      {
        columns: [
          {
            sections: [
              {
                title: 'Files',
                component: PathwayFilesV1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Firmware',
    path: '/firmware',
    fetcher: DeviceFetcher,
    rows: [
      {
        columns: [
          {
            sections: [
              {
                title: 'Firmware',
                component: FirmwareV1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Log',
    path: '/log',
    fetcher: () => null,
    rows: [
      {
        columns: [
          {
            sections: [
              {
                title: 'Log',
                component: PathwayLogsV1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Advanced',
    path: '/advanced',
    fetcher: () => null,
    rows: [
      {
        columns: [
          {
            computer: 8,
            tablet: 8,
            mobile: 16,
            sections: [
              {
                title: 'Reboot device',
                component: PathwayRebootDeviceV1,
              },
            ],
          },
          {
            computer: 8,
            tablet: 8,
            mobile: 16,
            sections: [
              {
                title: 'Identify',
                component: PathwayIdentifyV1,
              },
            ],
          },
        ],
      },
    ],
  },
];
