import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import projectActionCreators from 'pages/Projects/projectActionCreators';
import { currentUserIdSelector } from 'helpers/selectors';
import withRemoveUser from './withRemoveUser';

const mapStateToProps = state => ({
  currentUserId: currentUserIdSelector(state),
});

const mapDispatchToProps = {
  onRemoveUserFromProject: projectActionCreators.removeUser,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withRemoveUser,
);
