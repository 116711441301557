import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NotificationSystem from 'react-notification-system';

import NotificationModel from 'models/NotificationModel';
import Notification from './Notification/Notification';

const notificationSystemStyle = {
  Containers: {
    tr: {
      top: '80px',
    },
  },
  NotificationItem: {
    DefaultStyle: {
      border: 'none',
      borderRadius: 0,
      height: 'auto',
      boxShadow: 'none',
      padding: 0,
      backgroundColor: 'transparent',
    },
  },
};

class NotificationStack extends Component {

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ notification: nextNotification = {} }) {
    const { id: nextId } = nextNotification;
    const { notification: { id } = {} } = this.props;

    if (nextId && id !== nextId) {
      const {
        duration: autoDismiss,
        level,
        message,
        title,
        isPlural,
      } = new NotificationModel(nextNotification);

      this.notificationSystem.addNotification({
        autoDismiss,
        level,
        children: (
          <Notification
            level={level}
            message={message}
            title={title}
            isPlural={isPlural}
          />
        ),
      });
    }
  }

  render() {
    return (
      <NotificationSystem
        ref={node => { this.notificationSystem = node; }}
        style={notificationSystemStyle}
      />
    );
  }

}

NotificationStack.defaultProps = {
  notification: undefined,
};

NotificationStack.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string.isRequired,
    message: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]).isRequired,
    level: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
  }),
};

export default NotificationStack;
