import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';

import { InvitationItem } from './components';

const InvitationList = ({ invitations }) => (
  <List verticalAlign="middle" divided>
    {invitations.map(item => <InvitationItem key={item.id} invitation={item} />)}
  </List>
);

InvitationList.propTypes = {
  invitations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default InvitationList;
