import isNil from 'lodash/isNil';

const getFileNameFromPath = str => {
  if (isNil(str)) {
    return undefined;
  }

  return str.split('\\').pop().split('/').pop();
};

export default getFileNameFromPath;
