import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

const ResetPasswordEmailField = ({ input: { onChange, name }, error }) => (
  <Input
    error={error}
    onChange={onChange}
    name={name}
    placeholder="Enter your email"
    type="email"
  />
);

ResetPasswordEmailField.defaultProps = {
  error: false,
};

ResetPasswordEmailField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  error: PropTypes.bool,
};

export default ResetPasswordEmailField;
