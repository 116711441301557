import styled from 'styled-components';
import { Segment } from 'semantic-ui-react';

import { mediaMax } from 'themes/mixins';

const StyledSegment = styled(Segment)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-width: 500px;
  width: 60%;
  left: 45px;

  ${mediaMax.md`
    min-width: 200px;
    width: 100%;
    left: 0;
  `};
`;

export default StyledSegment;
