import { call, takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import { FILE_UPLOADS_FIELD } from 'helpers/selectors';
import { deletePath, postPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import filesSectionActionCreators from './filesSectionActionCreators';
import { openDownloadUrl } from './utilities';

export function* triggerFileTransfer(action) {
  const {
    endpoint,
    params: { fileName, fileTypeId },
  } = action.payload;

  const body = {
    data: [
      {
        type: FILE_UPLOADS_FIELD,
        attributes: {
          fileName,
          fileTypeId,
        },
      },
    ],
  };

  const { response, error } = yield call(postPath, endpoint, body);

  const params = {
    response,
    error,
    successDisp: filesSectionActionCreators.triggerFileTransferSuccess,
    failDisp: filesSectionActionCreators.triggerFileTransferFailure,
  };
  yield call(processCall, params);
}

export function* removeFile(action) {
  const {
    endpoint,
    params: { fileName, fileTypeId },
  } = action.payload;

  const body = {
    data: [
      {
        fileName,
        fileTypeId,
      },
    ],
  };

  const { response, error } = yield call(deletePath, endpoint, body);

  const params = {
    response,
    error,
    successDisp: filesSectionActionCreators.removeFileSuccess,
    failDisp: filesSectionActionCreators.removeFileFailure,
  };
  yield call(processCall, params);
}

export function* downloadFile(action) {
  const {
    endpoint,
    params: { fileName, fileTypeId },
  } = action.payload;

  const body = {
    data: {
      fileName,
      fileTypeId,
    },
  };

  const { response, error } = yield call(postPath, endpoint, body);

  if (response) {
    yield call(openDownloadUrl, response);
  }

  const params = {
    response,
    error,
    successDisp: filesSectionActionCreators.downloadFileSuccess,
    failDisp: filesSectionActionCreators.downloadFileFailure,
  };
  yield call(processCall, params);
}

function* filesSectionSaga() {
  yield takeLatest(constants.TRIGGER_FILE_TRANSFER_REQUEST, triggerFileTransfer);
  yield takeLatest(constants.REMOVE_FILE_REQUEST, removeFile);
  yield takeLatest(constants.DOWNLOAD_FILE_REQUEST, downloadFile);
}

export default filesSectionSaga;
