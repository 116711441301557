import constants from 'dispatcherConst';

const removeBackgroundImageUpload = ({ projectId, pageId }) => ({
  type: constants.REMOVE_BACKGROUND_IMAGE_UPLOAD_CONTROL_UI_PAGE,
  payload: {
    projectId,
    pageId,
  },
});

export default removeBackgroundImageUpload;
