import { compose } from 'redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  projectSelector,
} from 'helpers/selectors';
import formNames from 'components/form/formNames';
import * as validators from 'components/form/formValidators';
import {
  validateTimeMask,
  getFormValues,
} from '../../../utilities';
import AddScheduleModalForm from './AddScheduleModalForm';
import scheduleFormFields from './constants';
import { scheduleFieldsArray } from '../../../constants';

const AddScheduleModalFormContainer = reduxForm({
  form: formNames.addNewSchedule,
  enableReinitialize: true,
  validate: validators.syncValidation,
  ...validateTimeMask,
})(AddScheduleModalForm);

export const mapStateToProps = (state, { match }) => {
  const { id: projectId } = match.params;
  const { name: projectName } = projectSelector(state)(projectId);
  const selector = formValueSelector(formNames.addNewSchedule);

  const formFieldsValues = getFormValues({
    selector,
    fieldsArray: scheduleFieldsArray,
    fieldsObject: scheduleFormFields,
    state,
  });

  return {
    projectName,
    userTime: selector(state, scheduleFormFields.time),
    ...formFieldsValues,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
)(AddScheduleModalFormContainer);
