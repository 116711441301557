export const actionTypes = {
  SIMPLE_TRIGGERS_V1: 'simple_triggers_v1',
  SIMPLE_TRIGGERS_COND_V1: 'simple_triggers_cond_v1',
  SIMPLE_TRIGGERS_V2: 'simple_triggers_v2',
  PAUSE_TIMELINES_V1: 'pause_timelines_v1',
  RELEASE_TIMELINES_V1: 'release_timelines_v1',
  RESUME_TIMELINES_V1: 'resume_timelines_v1',
  SET_TIMELINES_POSITION_V1: 'set_timelines_position_v1',
  SET_TIMELINES_RATE_V1: 'set_timelines_rate_v1',
  START_TIMELINES_V1: 'start_timelines_v1',
  TOGGLE_TIMELINES_V1: 'toggle_timelines_v1',
  CLEAR_RGB_OVERRRIDE_V1: 'clear_rgb_override_v1',
  DECREASE_GROUPS_INTENSITY_V1: 'decrease_groups_intensity_v1',
  INCREASE_GROUPS_INTENSITY_V1: 'increase_groups_intensity_v1',
  SET_GROUPS_INTENSITY_V1: 'set_groups_intensity_v1',
  SET_RGB_OVERRIDE_V1: 'set_rgb_override_v1',
  RELEASE_SCENES_V1: 'release_scenes_v1',
  START_SCENES_V1: 'start_scenes_v1',
  TOGGLE_SCENE_V1: 'toggle_scenes_v1',
};

export const paramKeys = {
  TRIGGER_IDS: 'triggerIds',
  TIMELINE_IDS: 'timelineIds',
  GROUP_IDS: 'groupIds',
  SCENE_IDS: 'sceneIds',
};

export const uiElementTypes = {
  INPUT: 'input',
  TOGGLE_BUTTON: 'toggleButton',
  DROPDOWN: 'dropdown',
  DROPDOWN_MULTI: 'dropdown-multi',
  PERCENTAGE: 'percentage',
  TIME: 'timeMillisec',
  NOT_RENDER: 'notRender',
  SELECTED_PROJECT: 'selectedProject',
  PROJECT_SUITE_DROPDOWN: 'projectSuiteDropdown',
};

export const fetchTriggersActionTypes = [
  actionTypes.SIMPLE_TRIGGERS_V1,
  actionTypes.SIMPLE_TRIGGERS_V2,
  actionTypes.SIMPLE_TRIGGERS_COND_V1,
  paramKeys.TRIGGER_IDS,
];

export const fetchTimelinesActionTypes = [
  actionTypes.PAUSE_TIMELINES_V1,
  actionTypes.RELEASE_TIMELINES_V1,
  actionTypes.RESUME_TIMELINES_V1,
  actionTypes.SET_TIMELINES_POSITION_V1,
  actionTypes.SET_TIMELINES_RATE_V1,
  actionTypes.START_TIMELINES_V1,
  actionTypes.TOGGLE_TIMELINES_V1,
  paramKeys.TIMELINE_IDS,
];

export const fetchGroupsActionTypes = [
  actionTypes.CLEAR_RGB_OVERRRIDE_V1,
  actionTypes.DECREASE_GROUPS_INTENSITY_V1,
  actionTypes.INCREASE_GROUPS_INTENSITY_V1,
  actionTypes.SET_GROUPS_INTENSITY_V1,
  actionTypes.SET_RGB_OVERRIDE_V1,
  paramKeys.GROUP_IDS,
];

export const fetchScenesActionTypes = [
  actionTypes.RELEASE_SCENES_V1,
  actionTypes.START_SCENES_V1,
  actionTypes.TOGGLE_SCENE_V1,
  paramKeys.SCENE_IDS,
];

export const fetchConditionsArray = [
  ...fetchTriggersActionTypes,
  ...fetchTimelinesActionTypes,
  ...fetchGroupsActionTypes,
  ...fetchScenesActionTypes,
];
