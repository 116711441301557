import React from 'react';

import { TASK_SCHEDULER_MODE } from '../constants';
import { ActionsStep, ScheduleStep, ReviewStep } from '../components';

const getTaskSchedulerSteps = ({
  mode,
  resourceType,
  isActionsStepValid,
  isSchedulerStepValid,
  isReviewStepValid,
  onStepChange,
}) => {
  switch (mode) {
    case TASK_SCHEDULER_MODE.DEFAULT:
      return [
        {
          title: 'Step 1',
          description: 'What should happen when this Scheduler activates?',
          content: () => (
            <ActionsStep resourceType={resourceType} />
          ),
          nextStepDisabled: !isActionsStepValid,
        },
        {
          title: 'Step 2',
          description: 'When will this Scheduler run?',
          content: () => (
            <ScheduleStep resourceType={resourceType} />
          ),
          nextStepDisabled: !isSchedulerStepValid,
        },
        {
          title: 'Step 3 - Review & Confirm',
          description: 'Review Tasks & Actions and Schedule configuration.',
          content: () => (
            <ReviewStep onStepChange={onStepChange} />
          ),
          nextStepDisabled: !isReviewStepValid,
        },
      ];
    case TASK_SCHEDULER_MODE.ADD_SCHEDULER:
      return [
        {
          title: 'Add Scheduler',
          description: 'When will this Scheduler run?',
          content: () => (
            <ScheduleStep resourceType={resourceType} />
          ),
          nextStepDisabled: !isSchedulerStepValid,
        },
      ];
    case TASK_SCHEDULER_MODE.EDIT_SCHEDULER:
      return [
        {
          title: 'Edit Scheduler',
          description: 'When will this Scheduler run?',
          content: () => (
            <ScheduleStep resourceType={resourceType} />
          ),
          nextStepDisabled: !isSchedulerStepValid,
        },
      ];
    default:
      return [];
  }
};

export default getTaskSchedulerSteps;
