import React from 'react';
import PropTypes from 'prop-types';

import ProjectSuiteModel from 'models/ProjectSuiteModel';
import { StyledProjectSuitesRow } from './ProjectSuitesRow.style';

const ProjectsRow = ({
  item: projectSuite,
  item: { id },
  paths,
  onAddProjectSuiteId,
  ...rest
}) => {
  const handleAddProjectSuiteId = () => onAddProjectSuiteId(id, paths);
  const projectSuiteModel = new ProjectSuiteModel(projectSuite);

  return (
    <StyledProjectSuitesRow item={projectSuiteModel} {...rest} onClick={handleAddProjectSuiteId} />
  );
};

ProjectsRow.propTypes = {
  onAddProjectSuiteId: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  paths: PropTypes.array.isRequired,
};

export default ProjectsRow;
