import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

import { filterOptions } from './constants';

const filterFunctionForLogs = (item, obj) => {
  let conditionSearch = false;
  let conditionInitiator = false;
  let conditionActionType = false;
  let conditionRecipient = false;
  let conditionResource = false;
  const regex = new RegExp(obj.search, 'i');

  if (isEmpty(obj)) {
    return true;
  }

  Object.keys(obj).forEach(key => {
    if (key === filterOptions.SEARCH) {
      conditionSearch = item.displayText.search(regex) !== -1;
    }

    if (key === filterOptions.ACTION_TYPE) {
      conditionActionType = item.log_data?.action_type === obj[key];
    }

    if (key === filterOptions.INITIATOR) {
      conditionInitiator = item.text_data?.initiator?.name === obj[key];
    }

    if (key === filterOptions.RECIPIENT) {
      conditionRecipient = item.text_data?.recipient?.name === obj[key];
    }

    if (key === filterOptions.RESOURCE) {
      conditionResource = item.text_data?.resource?.name === obj[key];
    }

    if (isNil(obj[key]) && key === filterOptions.SEARCH) {
      conditionSearch = true;
    }

    if (isNil(obj[key]) && key === filterOptions.INITIATOR) {
      conditionInitiator = true;
    }

    if (isNil(obj[key]) && key === filterOptions.ACTION_TYPE) {
      conditionActionType = true;
    }

    if (isNil(obj[key]) && key === filterOptions.RECIPIENT) {
      conditionRecipient = true;
    }

    if (isNil(obj[key]) && key === filterOptions.RESOURCE) {
      conditionResource = true;
    }
  });

  return conditionSearch
    && conditionInitiator
    && conditionActionType
    && conditionRecipient
    && conditionResource;
};

export default filterFunctionForLogs;
