import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { TRIGGERS_FIELD } from 'helpers/selectors';
import DataTable from 'components/DataTable/DataTableContainer';
import { TestConditions, TriggerCondTableRow } from './components';

const TriggersCondSection = ({
  deviceId,
  title,
  labels,
  resource: { online },
}) => {
  const [testConditions, setTestConditions] = useState(false);

  const onToggleState = () => {
    setTestConditions(!testConditions);
  };

  return (
    <DataTable
      header={title}
      labels={labels}
      customTableRow={TriggerCondTableRow}
      createElement={TestConditions}
      pagination={{
        type: TRIGGERS_FIELD,
        included: true,
        args: { deviceId },
      }}
      deviceId={deviceId}
      isOnline={online}
      testConditions={testConditions}
      onToggleState={onToggleState}
      fixed
    />
  );
};

TriggersCondSection.defaultProps = {
  title: '',
};

TriggersCondSection.propTypes = {
  deviceId: PropTypes.string.isRequired,
  title: PropTypes.string,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  resource: PropTypes.shape({
    online: PropTypes.bool.isRequired,
  }).isRequired,
};

export default TriggersCondSection;
