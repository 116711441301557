import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';

export const StyledColumn = styled(Grid.Column)`
  max-width: 200px;
`;

export const StyledDiv = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export default StyledColumn;
