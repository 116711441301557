import React from 'react';
import { Grid, Header } from 'semantic-ui-react';

import PortalPermissionsWrapper from 'components/PermissionsTable/wrappers/PortalPermissionsWrapper';

function PermissionsContextsTab() {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Header as="h1">Portal permissions</Header>
          <Header as="h3">View and edit permissions for the Portal</Header>
        </Grid.Column>
      </Grid.Row>
      <PortalPermissionsWrapper />
    </Grid>
  );
}

export default PermissionsContextsTab;
