import { compose } from 'redux';
import { connect } from 'react-redux';
import { reset } from 'redux-form';

import { settingTypes } from 'helpers/sharedVariables';
import {
  deviceFetchingSelector,
  deviceErrorSelector,
  deviceSelector,
  LOGICAL_DEVICES_FIELD,
  pollingRefreshingSelector,
} from 'helpers/selectors';
import deviceActionCreators from 'pages/Project/deviceActionCreators';
import updateSettingsActionCreators from 'pages/Device/updateSettingsActionCreators';
import withConfigurablePolling
  from 'helpers/withConfigurablePolling/withConfigurablePollingContainer';
import chainedLoader from 'helpers/chainedLoader/chainedLoaderContainer';
import PathwayDisableClockEventsV1, { DEVICE_DISABLE_REFRESH } from './PathwayDisableClockEventsV1';

export const mapStateToProps = (state, { resourceId }) => {
  const device = deviceSelector(state)(resourceId);
  const { online, reportedWithMeta } = device;
  const {
    set: {
      timedEv: {
        value: timedEventsEnabled,
      } = {},
    } = {},
  } = reportedWithMeta || {};

  return {
    loading: !reportedWithMeta,
    online,
    timedEventsEnabled,
    pollingRefreshing: pollingRefreshingSelector(state, settingTypes.DISABLE),
    chainedLoaderProps: {
      [DEVICE_DISABLE_REFRESH]: {
        fetching: deviceFetchingSelector(state),
        error: deviceErrorSelector(state),
      },
    },
  };
};

const mapDispatchToProps = {
  onToggleDisable: updateSettingsActionCreators.toggleDisable,
  onReset: reset,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withConfigurablePolling(DEVICE_DISABLE_REFRESH),
  chainedLoader(
    { name: DEVICE_DISABLE_REFRESH },
    ({ resourceId }) => deviceActionCreators.fetchDevice(resourceId, {
      fields: {
        [LOGICAL_DEVICES_FIELD]: [
          'online',
          'reported',
          'reportedMeta',
          'updated',
        ],
      },
    }),
  ),
)(PathwayDisableClockEventsV1);
