import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getDisplayName } from 'helpers/sharedMethods';

const withConfigurablePolling = () => WrappedComponent => class extends Component {

  static displayName = `WithConfigurablePolling(${getDisplayName(WrappedComponent)})`;

  static propTypes = {
    pollingValue: PropTypes.number.isRequired,
    pollingUpdated: PropTypes.bool.isRequired,
  };

  constructor({ pollingUpdated }) {
    super();

    this.state = {
      toggle: false,
      cacheUpdated: pollingUpdated,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { toggle, cacheUpdated } = prevState;
    const { pollingUpdated } = nextProps;

    if (cacheUpdated === pollingUpdated) return null;

    if (!cacheUpdated && pollingUpdated) {
      return {
        cacheUpdated: pollingUpdated,
        toggle: !toggle,
      };
    }

    return {
      cacheUpdated: pollingUpdated,
    };
  }

  componentDidMount() {
    const { pollingValue } = this.props;

    if (pollingValue > 0) {
      this.toggleState(pollingValue);
    }
  }

  componentDidUpdate(prevProps) {
    const { pollingValue: prevPollingValue } = prevProps;
    const { pollingValue } = this.props;

    if (prevPollingValue === pollingValue) return;

    this.stopPolling();
    if (pollingValue > 0) {
      this.toggleState(pollingValue);
    }
  }

  componentWillUnmount() {
    this.stopPolling();
  }

  stopPolling() {
    clearTimeout(this.timeout);
  }

  toggleState(rate) {
    this.setState(({ toggle }) => ({ toggle: !toggle }));
    this.timeout = setTimeout(() => this.toggleState(rate), rate * 1000);
  }

  render() {
    const { toggle } = this.state;

    return (
      <WrappedComponent
        {...this.props}
        pollingToggle={toggle}
      />
    );
  }

};

export default withConfigurablePolling;
