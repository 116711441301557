import { takeEvery, takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';

import fetchNotificationEvents from './fetchNotificationEvents';
import readNotificationEvent from './readNotificationEvent';
import archiveNotificationEvent from './archiveNotificationEvent';
import addNotificationEventFromWebsocket from './addNotificationEventFromWebsocket';
import subscribeNotificationEventsChannel from './subscribeNotificationEventsChannel';
import fetchNotificationEventsForDropdown from './fetchNotificationEventsForDropdown';
import triggerNotificationEventFilters from './triggerNotificationEventFilters';
import readAllNotificationEvents from './readAllNotificationEvents';
import archiveAllNotificationEvents from './archiveAllNotificationEvents';

function* saga() {
  yield takeLatest(
    constants.FETCH_NOTIFICATION_EVENTS_FOR_DROPDOWN, fetchNotificationEventsForDropdown,
  );
  yield takeLatest(constants.FETCH_NOTIFICATION_EVENTS, fetchNotificationEvents);
  yield takeLatest(constants.READ_NOTIFICATION_EVENT, readNotificationEvent);
  yield takeLatest(constants.ARCHIVE_NOTIFICATION_EVENT, archiveNotificationEvent);
  yield takeEvery(constants.SOCKET_RECEIVED, addNotificationEventFromWebsocket);
  yield takeEvery(constants.SOCKET_RECEIVED, subscribeNotificationEventsChannel);
  yield takeLatest(
    constants.TRIGGER_NOTIFICATION_EVENTS_FILTERING, triggerNotificationEventFilters,
  );
  yield takeLatest(constants.READ_ALL_NOTIFICATION_EVENTS, readAllNotificationEvents);
  yield takeLatest(constants.ARCHIVE_ALL_NOTIFICATION_EVENTS, archiveAllNotificationEvents);
}

export default saga;
