import constants from 'dispatcherConst';
import { messages } from 'data/notifications/notificationsConst';

export const disableTwoFactor = id => ({
  type: constants.DISABLE_TWO_FACTOR,
  payload: { id },
});

export const disableTwoFactorSuccess = response => ({
  type: constants.DISABLE_TWO_FACTOR_SUCCESS,
  payload: {
    response,
    message: messages.TWO_FACTOR_DISABLED,
  },
});

export const disableTwoFactorFailure = error => ({
  type: constants.DISABLE_TWO_FACTOR_FAILED,
  payload: { error },
});
