import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import has from 'lodash/has';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import { FcEmptyFilter } from 'react-icons/fc';

import {
  StyledPopup,
  StyledMenu,
  StyledCheckbox,
  StyledMenuItem,
  GroupName,
  ApplyLink,
  EmptyMessage,
} from './CheckboxFilter.style';

function CheckboxFilter({
  title,
  items,
  defaultSelectedItems,
  selectedItems,
  trigger,
  onChange,
  type,
  ...restProps
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(null);

  const handleItemSelected = useCallback((item, groupKey) => {
    let newSelectedFilters = null;

    if (type === 'checkbox') {
      if (has(selectedFilters, item.key)) {
        newSelectedFilters = omit(selectedFilters, item.key);
      } else {
        newSelectedFilters = {
          ...selectedFilters,
          [item.key]: {
            ...item,
          },
        };
      }

      setSelectedFilters(newSelectedFilters);
      return;
    }

    if (selectedFilters) {
      if (has(selectedFilters, item.key)) {
        newSelectedFilters = omit(selectedFilters, item.key);

        setSelectedFilters(newSelectedFilters);

        return;
      }

      const validSelectedFilters = Object.values(selectedFilters).filter(
        filter => filter.groupKey !== groupKey,
      );

      newSelectedFilters = {
        [item.key]: {
          groupKey,
          ...item,
        },
      };

      validSelectedFilters.forEach(filter => {
        newSelectedFilters[filter.key] = filter;
      });
    } else {
      newSelectedFilters = {
        [item.key]: {
          groupKey,
          ...item,
        },
      };
    }

    setSelectedFilters(newSelectedFilters);
  }, [selectedFilters, type]);

  const renderTitle = useCallback(() => {
    if (!title) {
      return null;
    }

    return (
      <StyledMenuItem header>
        <GroupName>
          {title}
        </GroupName>
      </StyledMenuItem>
    );
  }, [title]);

  const renderGroupName = useCallback(item => {
    if (item && !Array.isArray(item.value)) {
      return null;
    }

    if (isEmpty(item.value)) {
      return null;
    }

    return (
      <StyledMenuItem key={`header-${item.key}`} header>
        <GroupName>
          {item.key}
        </GroupName>
      </StyledMenuItem>
    );
  }, []);

  const renderBody = useCallback(item => {
    if (Array.isArray(item.value)) {
      return (
        <>
          {item.value.map(childItem => (
            <StyledMenuItem key={childItem.key} line>
              <StyledCheckbox
                type={type}
                label={childItem.value}
                checked={has(selectedFilters, childItem.key)}
                onClick={() => handleItemSelected(childItem, item.key)}
              />
            </StyledMenuItem>
          ))}
        </>
      );
    }

    return (
      <StyledMenuItem key={item.key} line>
        <StyledCheckbox
          type={type}
          label={item.value}
          checked={has(selectedFilters, item.key)}
          onClick={() => handleItemSelected(item, null)}
        />
      </StyledMenuItem>
    );
  }, [handleItemSelected, selectedFilters, type]);

  const handleApply = useCallback(() => {
    onChange(selectedFilters);
    setIsOpen(previous => !previous);
  }, [selectedFilters, onChange]);

  const renderFooter = useCallback(() => {
    if (isEmpty(items)) {
      return null;
    }

    return (
      <StyledMenuItem footer>
        <ApplyLink name="view-all" onClick={() => handleApply()}>
          Apply
        </ApplyLink>
      </StyledMenuItem>
    );
  }, [items, handleApply]);

  const EmptyState = () => (
    <EmptyMessage>
      <FcEmptyFilter size="1.8em" />
      No filters available
    </EmptyMessage>
  );

  return (
    <StyledPopup
      on="click"
      position="bottom left"
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      trigger={trigger}
      content={(
        <StyledMenu secondary fluid vertical>
          {renderTitle()}
          {isEmpty(items) && <EmptyState />}
          {items.map(item => (
            <div key={item.key}>
              {renderGroupName(item)}
              {renderBody(item)}
            </div>
          ))}
          {renderFooter()}
        </StyledMenu>
      )}
      {...restProps}
    />
  );
}

CheckboxFilter.defaultProps = {
  title: null,
  items: [],
  selectedItems: {},
  defaultSelectedItems: {},
  type: 'checkbox',
};

CheckboxFilter.propTypes = {
  type: PropTypes.oneOf(['checkbox', 'radio']),
  title: PropTypes.string,
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      value: PropTypes.string,
    })),
    PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      value: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
      })),
    })),
  ]),
  selectedItems: PropTypes.object,
  defaultSelectedItems: PropTypes.object,
  trigger: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
};


export default CheckboxFilter;
