import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';

import logsActionTypesSelector from './logsActionTypesSelector';

const actionTypesListSelector = createSelector(
  logsActionTypesSelector,
  data => (!isEmpty(data) && data[0].actionTypes) || [],
);

export default actionTypesListSelector;
