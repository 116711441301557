import { put } from 'redux-saga/effects';
import { reset } from 'redux-form';

import formNames from 'components/form/formNames';

function* resetChangePasswordForm() {
  yield put(reset(formNames.changePassword));
}

export default resetChangePasswordForm;
