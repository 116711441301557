import constants from 'dispatcherConst';

const schedulesAFNotification = message => ({
  type: constants.SCHEDULES_AF_NOTIFICATION,
  payload: {
    info: message,
  },
});

export default schedulesAFNotification;
