import { connect } from 'react-redux';

import { actionCreators } from '../utilities';
import CopyButton from './CopyButton';

export const mapDispatchToProps = {
  onCopyButton: actionCreators.copyButton,
};

export default connect(null, mapDispatchToProps)(CopyButton);
