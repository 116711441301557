import constants from 'dispatcherConst';

export const fetchPermissionsTableRecipients = ({
  resourceId,
  resourceType,
  context,
}) => ({
  type: constants.FETCH_PERMISSIONS_RECIPIENTS_REQUEST,
  payload: {
    resourceId,
    resourceType,
    context,
  },
});

export const fetchPermissionsTableRecipientsSuccess = response => ({
  type: constants.FETCH_PERMISSIONS_RECIPIENTS_SUCCESS,
  payload: {
    response,
  },
});

export const fetchPermissionsTableRecipientsFailure = error => ({
  type: constants.FETCH_PERMISSIONS_RECIPIENTS_FAILED,
  payload: { error },
});

export default fetchPermissionsTableRecipients;
