
import { connect } from 'react-redux';
import { deviceSelector } from 'helpers/selectors';
import performActionActionCreators from 'pages/Device/performActionActionCreators';
import DeviceTriggerAction from './DeviceTriggerAction';

export const mapStateToProps = (state, { resourceId }) => {
  const { online } = deviceSelector(state)(resourceId);

  return {
    isOnline: online,
  };
};

const mapDispatchToProps = {
  onPerformAction: performActionActionCreators.performActionRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceTriggerAction);
