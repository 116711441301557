import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import CheckboxField from 'components/form/CheckboxField/CheckboxField';
import { Button } from 'components/atomic';
import SettingConfirm from 'components/SettingConfirm/SettingConfirm';

const DeviceSettingForm = ({
  isOnline,
  handleSubmit,
  label,
  fieldProps,
  name,
  confirmationMessage,
  confirmationTitle,
  dirty,
}) => (
  <React.Fragment>
    <Field
      component={CheckboxField}
      disabled={!isOnline}
      name={name}
      {...fieldProps}
    />
    <SettingConfirm
      trigger={
        <Button type="submit" primary disabled={!dirty}>Commit</Button>
      }
      header={confirmationTitle || `Update ${label}`}
      content={(
        <p>
          {confirmationMessage}
        </p>
      )}
      onConfirm={handleSubmit}
      size="mini"
    />
  </React.Fragment>
);

DeviceSettingForm.defaultProps = {
  fieldProps: {},
  confirmationTitle: null,
  confirmationMessage: 'Are you sure?',
};

DeviceSettingForm.propTypes = {
  isOnline: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  fieldProps: PropTypes.object,
  name: PropTypes.string.isRequired,
  confirmationTitle: PropTypes.string,
  confirmationMessage: PropTypes.string,
  dirty: PropTypes.bool.isRequired,
};

export default DeviceSettingForm;
