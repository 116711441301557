import { connect } from 'react-redux';
import find from 'lodash/find';

import { schedulesSelector } from 'helpers/selectors';

import { actionCreators, selectors } from '../../utilities';
import ScheduleStep from './ScheduleStep';

export const mapStateToProps = (state, { automatedOperationId, scheduleId }) => {
  const getSchedules = schedulesSelector(state)(automatedOperationId);
  const schedule = getSchedules && find(getSchedules.schedulesList, { id: scheduleId });

  return {
    schedulerType: selectors.schedulerTypeSelector(state),
    existingSchedule: schedule,
  };
};

const mapDispatchToProps = {
  onSchedulerTypeSelect: actionCreators.setSchedulerType,
  onClearSchedulerParams: actionCreators.clearSchedulerParams,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleStep);
