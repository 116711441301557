import React from 'react';
import PropTypes from 'prop-types';
import isBoolean from 'lodash/isBoolean';
import { Checkbox } from 'semantic-ui-react';
import isNull from 'lodash/isNull';

const DataTableCheckbox = ({ field, item }) => {
  if (isNull(item) || isNull(field)) {
    return null;
  }

  const value = item[field];

  if (isBoolean(value)) {
    return <Checkbox defaultChecked={value} readOnly />;
  }

  return null;
};

DataTableCheckbox.defaultProps = {
  field: null,
  item: {},
};

DataTableCheckbox.propTypes = {
  field: PropTypes.string,
  item: PropTypes.object,
};

export default DataTableCheckbox;
