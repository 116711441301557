import { OCCURRENCE_TYPE_FROM_NUMBER, OCCURRENCE_DAY, OCCURRENCE_DAY_FROM_NUMBER, OCCURRENCE_MONTH_FROM_NUMBER } from '../../constants';

const getOptionsFromCondition = condition => {
  if (!condition) {
    return {};
  }

  const keys = Object.keys(condition);

  if (keys.includes('on')) {
    const { weekday, nthOccurrence, month } = condition.on;

    return {
      occurrenceType: OCCURRENCE_TYPE_FROM_NUMBER[nthOccurrence],
      occurrenceDay: OCCURRENCE_DAY_FROM_NUMBER[weekday],
      occurrenceMonth: OCCURRENCE_MONTH_FROM_NUMBER[month],
    };
  }

  if (keys.includes('every')) {
    const { day, month } = condition.every;

    if (day > 5) {
      return {};
    }

    return {
      occurrenceType: OCCURRENCE_TYPE_FROM_NUMBER[day],
      occurrenceDay: OCCURRENCE_DAY.DAY,
      occurrenceMonth: OCCURRENCE_MONTH_FROM_NUMBER[month],
    };
  }

  return {};
};

export default getOptionsFromCondition;
