import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { AddProjectSuiteModalForm } from './components';

function AddProjectSuite({ onAddProjectSuite }) {

  const handleAddProjectSuite = useCallback(
    values => new Promise(
      (resolveForm, rejectForm) => {
        onAddProjectSuite({
          values,
          resolveForm,
          rejectForm,
        });
      }),
    [onAddProjectSuite],
  );

  return (
    <AddProjectSuiteModalForm onSubmit={handleAddProjectSuite} />
  );
}

AddProjectSuite.propTypes = {
  onAddProjectSuite: PropTypes.func.isRequired,
};

export default AddProjectSuite;
