const getOverrideRgbActions = (keyName, groups, rgbFormValues) => {
  const actions = [];

  const groupedByDevice = groups.reduce((acc, group) => {
    const { logicalDevice, group: { id: groupId } } = group;
    const { id: deviceId } = logicalDevice;

    if (!acc[deviceId]) {
      acc[deviceId] = {
        deviceId,
        deviceName: logicalDevice.name,
        groups: [],
      };
    }

    acc[deviceId].groups.push(groupId);

    return acc;
  }, {});

  Object.keys(groupedByDevice).forEach((deviceId, index) => {
    const { groups: deviceGroups } = groupedByDevice[deviceId];

    const params = [
      {
        key: 'target',
        value: 'group',
      },
      {
        key: 'fadeMilliSecs',
        value: '2000',
      },
      {
        key: 'path',
        value: 'default',
      },
      {
        key: 'groupIds',
        value: deviceGroups,
      },
    ];

    Object.entries(rgbFormValues).forEach(entry => {
      const [key, value] = entry;

      params.push({
        key: key.replace(keyName, ''),
        value: value || '0',
      });
    });

    actions.push({
      index,
      typeId: 'set_rgb_override_v1',
      logicalDeviceId: deviceId,
      params,
    });
  });

  return actions;
};

export default getOverrideRgbActions;
