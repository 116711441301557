import { createSelector } from 'reselect';
import build from 'redux-object';
import memoize from 'lodash/memoize';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import transform from 'lodash/transform';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { DateTime } from 'luxon';
import toString from 'lodash/toString';
import findIndex from 'lodash/findIndex';

import socketConstants from 'data/websockets/constants';
import constants from 'dispatcherConst';
import { mergeTimestamp } from 'helpers/sharedMethods';
import { extractPartialPaths } from 'application/tenant/console/utilities/permissions/permissionsHelpers';
import CompanyModel from 'models/CompanyModel';
import DeviceModel from 'models/DeviceModel';
import ProjectModel from 'models/ProjectModel';
import UserModel from 'models/UserModel';
import FileModel from 'models/FileModel';
import TriggerModel from 'models/TriggerModel';
import IoModuleModel from 'models/IoModuleModel';
import IoModuleInstanceModel from 'models/IoModuleInstanceModel';
import TimelineModel from 'models/TimelineModel';
import FixtureGroupModel from 'models/FixtureGroupModel';
import StaticSceneModel from 'models/StaticSceneModel';
import LogModel from 'models/LogModel';
import PermissionModel from 'models/PermissionModel';
import ZoneModel from 'models/ZoneModel';
import SnapshotModel from 'models/SnapshotModel';
import PermissionsTreeModel from 'models/PermissionsTreeModel';
import ContextModel from 'models/ContextModel';
import TaskModel from 'models/TaskModel';
import AutomatedOperationModel from 'models/AutomatedOperationModel';
import OccurrenceModel from 'models/OccurrenceModel';
import JamboxTimelineModel from 'models/JamboxTimelineModel';
import RemoteDeviceModel from 'models/RemoteDeviceModel';
import UnjoinedRemoteDeviceModel from 'models/UnjoinedRemoteDeviceModel';
import ProjectSuiteModel from 'models/ProjectSuiteModel';
import NotificationEventModel from 'models/NotificationEventModel';
import DeviceFirmwareModel from 'models/DeviceFirmwareModel';
import DeviceFirmwareFileModel from 'models/DeviceFirmwareFileModel';

export const options = {
  ignoreLinks: true,
};

export const DEVICE_REFRESH = 'deviceRefreshPollingLoader';
export const USERS_FIELD = 'users';
export const PROJECTS_FIELD = 'projects';
export const PROJECT_SUITES_FIELD = 'projectSuites';
export const COMPANIES_FIELD = 'companies';
export const LOGICAL_DEVICES_FIELD = 'logicalDevices';
export const ORIGINS_FIELD = 'origins';
export const FILE_UPLOADS_FIELD = 'fileUploads';
export const PATCH_FIELD = 'patch';
export const OUTPUT_FIELD = 'dmxOutput';
export const TRIGGERS_FIELD = 'triggers';
export const FIXTURE_GROUPS_FIELD = 'fixtureGroups';
export const TIMELINES_FIELD = 'timelines';
export const STATIC_SCENES_FIELD = 'staticScenes';
export const LOGS_FIELD = 'logMessages';
export const RESET_PASSWORD_REQUEST = 'resetPasswordRequest';
export const RESET_PASSWORD_ACTION = 'resetPasswordAction';
export const SIGN_UP_USER = 'signUpUser';
export const INVITE_USER = 'inviteUser';
export const UPDATE_SETTING_FIELD = 'updateSetting';
export const PERFORM_ACTION_FIELD = 'performAction';
export const PERMISSIONS_FIELD = 'permissions';
export const USER_PERMISSIONS_FIELD = 'userPermissions';
export const NOTIFICATION_TYPES_FIELD = 'notificationTypes';
export const NOTIFICATIONS_FIELD = 'notifications';
export const NOTIFICATION_EVENTS_FIELD = 'notificationEvents';
export const ZONES_FIELD = 'zones';
export const SNAPSHOTS_FIELD = 'snapshots';
export const CONTEXTS_FIELD = 'contexts';
export const IO_MODULES_FIELD = 'ioModules';
export const IO_MODULES_INSTANCES_FIELD = 'ioInstances';
export const SHOW_COMPANIES_COMPONENT = 'showCompanies';
export const TASKS_FIELD = 'tasks';
export const ACTION_TYPES_FIELD = 'actionTypes';
export const DEVICE_ACTION_TYPES_LISTS_FIELD = 'deviceActionTypesLists';
export const CONTROL_UI_FIELD = 'controlPanelPages';
export const PROJECT_FILE_UPLOAD_FIELD = 'resourceUploads';
export const DEVICE_TRIGGERS_FIELD = 'deviceTriggers';
export const AUTOMATED_OPERATIONS_FIELD = 'automatedOperations';
export const SCHEDULES_FIELD = 'schedules';
export const OPERATIONS_FIELD = 'operations';
export const AUTOMATED_FUNCTIONS_FIELD = 'automatedFunctions';
export const AUTOMATED_OPERATIONS_OCCURRENCES_FIELD = 'aoOccurrences';
export const SELECT_TASKS = 'selectTasks';
export const JAMBOX_TIMELINES_FIELD = 'jamboxTimelines';
export const REMOTE_DEVICES_FIELD = 'remoteDevices';
export const UNJOINED_REMOTE_DEVICES_FIELD = 'unjoinedRemoteDevices';
export const DEVICE_TIMELINES_FIELD = 'deviceTimelines';
export const DEVICE_SCENES_FIELD = 'deviceScenes';
export const DEVICE_GROUPS_FIELD = 'deviceGroups';
export const PROJECTS_FOR_MAP_FIELD = 'projectsForMap';
export const DEVICES_FOR_SITE_FIELD = 'devicesForSite';
export const CONTROL_PANEL_UPLOADS = 'controlPanelUploads';
export const CONTROL_PANEL_COPY_PASTE_FIELD = 'controlPanelCopyPaste';
export const ACTIVITY_LOGS_FIELD = 'activityLogs';
export const WEBSOCKETS_FIELD = 'websockets';
export const ACTIVE_PROJECT_FIELD = 'activeProject';
export const LOGS_ACTION_TYPES_FIELD = 'actionTypesLists';
export const ACTIVE_REQUEST_ID_FIELD = 'activeRequestId';
export const PROJECT_SUITES_PROJECTS_FILTER_FIELD = 'projectSuitesProjectsFilter';
export const FETCHING_FILTER_FIELD = 'fetchingFilter';
export const FETCHING_FILTER_PROJECTS_FIELD = 'projectIds[]';
export const FETCHING_FILTER_PROJECT_SUITES_FIELD = 'projectSuiteIds[]';
export const PROJECT_PROJECT_SUITE_PARENTS_FIELD = 'projectProjectSuiteParents';
export const TENANT_FIELD = 'tenant';
export const NOTIFICATION_EVENTS_DROPDOWN_FIELD = 'notificationEventsDropdown';
export const CHILD_PROJECTS_FOR_MAP_FIELD = 'childProjectsForMap';
export const SITE_ACTION_TYPES_LIST_FIELD = 'siteActionTypesLists';
export const DEVICE_FIRMWARES_FIELD = 'deviceFirmwares';
export const MANUFACTURER_MODEL_TREE_FIELD = 'manufacturer';
export const DEVICE_FIRMWARE_FILES_FIELD = 'deviceFirmwareFiles';

export const argsSelector = (state, args) => args;

export const currentUserIdSelector = state => state.login.currentUserId;

export const currentUserFetchingSelector = state => state.login.fetching;

export const currentUserErrorSelector = state => state.login.error;

export const loginCheckedSelector = state => state.login.loginChecked;

export const recentlyLoggedOutSelector = state => state.login.recentlyLoggedOut;

export const menuVisibleSelector = state => state.menu.visible;

export const controlPanelContextSelector = state => state.controlPanel.inControlPanelContext;

export const isPageNavigationOpenSelector = state => state.controlPanel.isPageNavigationOpen;

export const projectSuiteIdContextSelector = state => state.projectSuiteIdContext;

export const dataSelector = state => state.data;

export const usersSelector = createSelector(
  dataSelector,
  data => build(data, USERS_FIELD, null, options),
);

export const ioModulesSelector = createSelector(
  dataSelector,
  data => build(data, IO_MODULES_FIELD, null, options) || [],
);

export const userSelector = createSelector(
  dataSelector,
  data => memoize(
    userId => build(data, USERS_FIELD, userId || '', options),
  ),
);

export const makeUserSelector = () => userSelector;

export const currentUserSelector = createSelector(
  [
    dataSelector,
    currentUserIdSelector,
  ],
  (data, currentUserId) => build(data, USERS_FIELD, currentUserId || '', options),
);

export const userProjectsSelector = memoize(
  (user, fallback = []) => (user && user[PROJECTS_FIELD]) || fallback,
);

export const changePasswordSelector = state => state.changePassword;

export const projectsSelector = createSelector(
  dataSelector,
  data => build(data, PROJECTS_FIELD, null, options),
);

export const projectSuitesSelector = createSelector(
  dataSelector,
  data => build(data, PROJECT_SUITES_FIELD, null, options),
);

export const projectSelector = createSelector(
  dataSelector,
  data => memoize(
    projectId => build(data, PROJECTS_FIELD, projectId || '', options),
  ),
);

export const singleProjectSelector = (state, id) => {
  const project = projectSelector(state)(id) || {};
  const currentUser = currentUserSelector(state);

  return new ProjectModel(project, currentUser, true);
};

export const projectSuiteSelector = createSelector(
  dataSelector,
  data => memoize(
    projectSuiteId => build(data, PROJECT_SUITES_FIELD, projectSuiteId || '', options),
  ),
);

export const deviceFirmwareSelector = createSelector(
  dataSelector,
  data => memoize(
    deviceFirmwareId => build(data, DEVICE_FIRMWARES_FIELD, deviceFirmwareId || '', options),
  ),
);

export const firmwareFileSelector = createSelector(
  dataSelector,
  data => memoize(
    firmwareFileId => build(data, DEVICE_FIRMWARE_FILES_FIELD, firmwareFileId || '', options),
  ),
);

export const notificationEventSelector = createSelector(
  dataSelector,
  data => memoize(
    notificationEventId => build(data, NOTIFICATION_EVENTS_FIELD, notificationEventId || '', options),
  ),
);

export const singleProjectSuiteSelector = (state, id) => {
  const projectSuite = projectSuiteSelector(state)(id) || {};
  const currentUser = currentUserSelector(state);

  return new ProjectSuiteModel(projectSuite, currentUser, true);
};

export const tasksSelector = createSelector(
  dataSelector,
  data => build(data, TASKS_FIELD, null, options),
);

export const taskSelector = createSelector(
  dataSelector,
  data => memoize(
    taskId => build(data, TASKS_FIELD, taskId || '', options),
  ),
);

export const deviceActionsListSelector = createSelector(
  dataSelector,
  data => memoize(
    logicalDeviceId => build(data, DEVICE_ACTION_TYPES_LISTS_FIELD, logicalDeviceId || '', options),
  ),
);

export const logicalDeviceSelector = createSelector(
  dataSelector,
  data => memoize(
    logicalDeviceId => build(data, LOGICAL_DEVICES_FIELD, logicalDeviceId || '', options),
  ),
);

export const singleLogicalDeviceSelector = (state, id) => {
  const logicalDevice = logicalDeviceSelector(state)(id);

  return new DeviceModel(logicalDevice);
};

export const companySelector = createSelector(
  dataSelector,
  data => memoize(
    companyId => {
      const company = build(data, COMPANIES_FIELD, companyId || '', options);
      if (!company) return null;

      return new CompanyModel(company);
    },
  ),
);

export const fileUploadSelector = createSelector(
  dataSelector,
  data => memoize(
    fileUploadId => build(data, FILE_UPLOADS_FIELD, fileUploadId || '', options),
  ),
);

export const triggerSelector = createSelector(
  dataSelector,
  data => memoize(
    triggerId => build(data, TRIGGERS_FIELD, String(triggerId) || '', options),
  ),
);

export const ioModuleSelector = createSelector(
  dataSelector,
  data => memoize(
    ioModuleId => build(data, IO_MODULES_FIELD, String(ioModuleId) || '', options),
  ),
);

export const ioModuleInstanceSelector = createSelector(
  dataSelector,
  data => memoize(
    ioModuleInstanceId => build(data, IO_MODULES_INSTANCES_FIELD, String(ioModuleInstanceId) || '', options),
  ),
);

export const timelineSelector = createSelector(
  dataSelector,
  data => memoize(
    timelineId => build(data, TIMELINES_FIELD, String(timelineId) || '', options),
  ),
);

export const fixtureGroupSelector = createSelector(
  dataSelector,
  data => memoize(
    groupId => build(data, FIXTURE_GROUPS_FIELD, String(groupId) || '', options),
  ),
);

export const staticSceneSelector = createSelector(
  dataSelector,
  data => memoize(
    staticSceneId => build(data, STATIC_SCENES_FIELD, String(staticSceneId) || '', options),
  ),
);

export const zoneSelector = createSelector(
  dataSelector,
  data => memoize(
    zoneId => build(data, ZONES_FIELD, String(zoneId) || '', options),
  ),
);

export const snapshotSelector = createSelector(
  dataSelector,
  data => memoize(
    snapshotId => build(data, SNAPSHOTS_FIELD, String(snapshotId) || '', options),
  ),
);

export const contextSelector = createSelector(
  dataSelector,
  data => memoize(
    contextId => build(data, CONTEXTS_FIELD, String(contextId) || '', options),
  ),
);

export const logsFetchingSelector = state => state.logs.fetching;

export const logsLinesSelector = state => state.logs.lines;

export const logsSelector = createSelector(
  dataSelector,
  data => {
    const logs = build(data, LOGS_FIELD, null, options);
    if (!logs) {
      return null;
    }

    return logs.map(log => new LogModel(log));
  },
);

export const companyFetchingSelector = state => state.company.fetching;

export const companyErrorSelector = state => state.company.error;

export const projectFetchingSelector = state => state.projects.fetching;

export const projectSuiteFetchingSelector = state => state.projectSuites.fetching;

export const projectErrorSelector = state => state.projects.error;

export const userRemovingSelector = state => state.projects.removing;

export const userInvitingSelector = state => state.inviteUser.inviting;

export const userFetchingSelector = state => state.usersPage.fetching;

export const userErrorSelector = state => state.usersPage.error;

export const userInviteSuccessSelector = state => state.inviteUser.success;

export const projectUsersSelector = memoize(
  (project, fallback = []) => (project && project[USERS_FIELD]) || fallback,
);

export const projectLogicalDevicesSelector = memoize(
  (project, fallback = []) => (project && project[LOGICAL_DEVICES_FIELD]) || fallback,
);

export const currentCompanyFetchingSelector = state => state.currentCompany.fetching;

export const currentCompanyErrorSelector = state => state.currentCompany.error;

export const currentCompanyIdSelector = state => state.currentCompany.id;

export const currentCompanyNameSelector = state => state.currentCompany.name;

export const currentCompanyEmailSelector = state => state.currentCompany.email;

export const currentCompanySubdomainSelector = state => state.currentCompany.subdomain;

export const currentCompanyThemeNameSelector = state => state.currentCompany.themeName;

export const currentCompanyVouchersEnabledSelector = state => state.currentCompany.vouchersEnabled;

export const currentCompanyNotificationsEnabledSelector = state => state
  .currentCompany
  .notificationsEnabled;

export const companiesSelector = createSelector(
  dataSelector,
  data => {
    const companies = build(data, COMPANIES_FIELD, null, options);
    if (!companies) {
      return null;
    }

    return companies.map(company => (new CompanyModel(company)));
  },
);

export const companiesFetchingSelector = state => state.companies.fetching;

export const companiesErrorSelector = state => state.companies.error;

export const companiesAvailableThemesSelector = state => state.companies.availableThemes;

export const tenantSelector = state => state.companies.tenant;

export const tenantsSelector = createSelector(
  companiesSelector,
  companies => {
    if (!companies) {
      return [];
    }

    return companies.map(
      ({ id, name, subdomain }) => ({ key: id, text: name, value: subdomain }),
    );
  },
);

export const tenantChangedSelector = createSelector(
  currentCompanySubdomainSelector,
  tenantSelector,
  currentUserSelector,
  (currentCompany, tenant, currentUser) => {
    if (
      (!currentUser || !currentUser.superadmin)
      || !currentCompany
      || !tenant
    ) {
      return false;
    }

    return currentCompany !== tenant;
  },
);

export const deviceFetchingSelector = state => state.device.fetching;

export const deviceErrorSelector = state => state.device.error;

export const deviceDetailsSelector = state => ({
  deviceKey: state.device.deviceKey,
  deviceName: state.device.deviceName,
  adding: state.device.adding,
});

export const fileRemovingSelector = state => state.file.removing;

export const fileTriggeringSelector = state => state.file.triggering;

export const fileDownloadingSelector = state => state.file.downloading;

export const signUpVerifyingSelector = state => state.signUp.verifying;

export const signUpWebRedirectionSelector = state => state.signUp.webRedirection;

export const errorNotificationsSelector = state => state.notifications.error;

export const successNotificationsSelector = state => state.notifications.success;

export const infoNotificationsSelector = state => state.notifications.info;

export const specificErrorsSelector = (state, error) => (
  state.notifications.specificErrors[error]
);

export const deviceSelector = createSelector(
  dataSelector,
  data => memoize(
    deviceId => {
      const device = build(data, LOGICAL_DEVICES_FIELD, deviceId || '', options);
      if (!device) {
        return null;
      }

      const { reported, reportedMeta } = device;
      return new DeviceModel({
        ...device,
        reportedWithMeta: mergeTimestamp(reportedMeta, reported),
      });
    },
  ),
);

export const settingsApiDomainSelector = state => state.settings.apiDomain;

export const settingsApiDomainChangedSelector = state => (
  settingsApiDomainSelector(state) !== process.env.REACT_APP_API_URL
);

export const settingsBackendVersionSelector = state => state.settings.backendVersion;

export const paginationSelector = state => state.pagination;

export const pathnameSelector = createSelector(
  paginationSelector,
  pagination => pagination.pathname,
);

export const pageParamsSelector = createSelector(
  paginationSelector,
  pathnameSelector,
  (pagination, pathname) => memoize(
    resourceType => (get(pagination[pathname], `${resourceType}.page`)),
  ),
);

const resourcePageParamsSelector = (paramName, fallback = null) => (
  (pagination, pathname) => memoize(
    resourceType => get(pagination[pathname], `${resourceType}.${paramName}`) || fallback,
  )
);

export const paginationLinkSelector = createSelector(
  paginationSelector,
  pathnameSelector,
  resourcePageParamsSelector('link'),
);

export const paginationRefetchSelector = createSelector(
  paginationSelector,
  pathnameSelector,
  resourcePageParamsSelector('refetch'),
);

export const paginationFetchingSelector = createSelector(
  paginationSelector,
  pathnameSelector,
  resourcePageParamsSelector('fetching'),
);

export const paginationErrorSelector = createSelector(
  paginationSelector,
  pathnameSelector,
  resourcePageParamsSelector('error'),
);

export const paginationSortFieldSelector = createSelector(
  paginationSelector,
  pathnameSelector,
  resourcePageParamsSelector('sort'),
);

export const paginationFilterFieldSelector = createSelector(
  paginationSelector,
  pathnameSelector,
  resourcePageParamsSelector('filter'),
);

export const paginatedResourceSelector = createSelector(
  paginationSelector,
  pathnameSelector,
  resourcePageParamsSelector('data', []),
);

export const paginationActiveSelector = createSelector(
  paginationSelector,
  pathnameSelector,
  resourcePageParamsSelector('active'),
);

export const paginationChainedLoaderIdSelector = createSelector(
  paginationSelector,
  pathnameSelector,
  resourcePageParamsSelector('chainedLoaderId'),
);

export const paginatedUsersSelector = state => {
  const data = paginatedResourceSelector(state)(USERS_FIELD);
  const resource = id => userSelector(state)(id);

  return data.map(id => new UserModel(resource(id)));
};

export const notificationTypesSelector = createSelector(
  dataSelector,
  data => build(data, NOTIFICATION_TYPES_FIELD, null, options),
);

export const notificationsSelector = createSelector(
  dataSelector,
  data => build(data, NOTIFICATIONS_FIELD, null, options),
);

export const notificationTypeSelector = createSelector(
  notificationTypesSelector,
  notificationTypes => memoize(
    id => notificationTypes.filter(nt => nt.id === id)[0],
  ),
);

export const userEntityNotificationSelector = createSelector(
  notificationsSelector,
  notifications => memoize(
    (userId, entityId, notificationTypeId) => {
      if (!notifications) return null;
      const notification = notifications.find(n => (
        n.notifiable.id === entityId
        && n.user.id === userId
        && n.notificationType.id === notificationTypeId
      ));

      return notification || null;
    },
    (userId, entityId, notificationTypeId) => `${userId}.${entityId}.${notificationTypeId}`,
  ),
);

export const groupedSortedNotificationTypesSelector = createSelector(
  notificationTypesSelector,
  notificationTypes => memoize(
    context => {
      if (!notificationTypes) return null;
      const filteredNotificationTypes = notificationTypes.filter(nt => (
        context.includes(nt.notificationContext)));
      const sortedFilteredNotificationTypes = sortBy(filteredNotificationTypes, nt => (
        context.indexOf(nt.notificationContext)));
      return groupBy(sortedFilteredNotificationTypes, nt => nt.name);
    },
  ),
);

export const paginatedNotificationTypesSelector = state => {
  const data = paginatedResourceSelector(state)(NOTIFICATION_TYPES_FIELD);
  const resource = id => notificationTypeSelector(state)(id);

  return data.map(id => resource(id));
};

export const searchParamsConfigSelector = createSelector(
  paginationSelector,
  ({ pathname, ...rest }) => Object.entries(rest).reduce((acc, [key, value]) => {
    acc[key] = value && value.search;

    return acc;
  }, {}),
);

export const searchParamsSelector = pathname => createSelector(
  searchParamsConfigSelector,
  config => config[pathname],
);

export const paginatedProjectsSelector = (state, { self }) => {
  const data = paginatedResourceSelector(state)(PROJECTS_FIELD);
  const currentUser = currentUserSelector(state);
  const resource = id => projectSelector(state)(id) || {};

  return data.map(id => new ProjectModel(resource(id), currentUser, self));
};

export const paginatedProjectSuitesSelector = (state, { self }) => {
  const data = paginatedResourceSelector(state)(PROJECT_SUITES_FIELD);
  const currentUser = currentUserSelector(state);
  const resource = id => projectSuiteSelector(state)(id) || {};

  return data.map(id => new ProjectSuiteModel(resource(id), currentUser, self));
};

export const paginatedDeviceFirmwaresSelector = (state, { self }) => {
  const data = paginatedResourceSelector(state)(DEVICE_FIRMWARES_FIELD);
  const currentUser = currentUserSelector(state);
  const resource = id => deviceFirmwareSelector(state)(id) || {};

  return data.map(id => new DeviceFirmwareModel(resource(id), currentUser, self));
};

export const paginatedFirmwareFilesSelector = (state, { self }) => {
  const data = paginatedResourceSelector(state)(DEVICE_FIRMWARE_FILES_FIELD);
  const currentUser = currentUserSelector(state);
  const resource = id => firmwareFileSelector(state)(id) || {};

  return data.map(id => new DeviceFirmwareFileModel(resource(id), currentUser, self));
};

export const paginatedNotificationEventsSelector = (state, { self }) => {
  const data = paginatedResourceSelector(state)(NOTIFICATION_EVENTS_FIELD);
  const currentUser = currentUserSelector(state);
  const resource = id => notificationEventSelector(state)(id) || {};

  return data.map(id => new NotificationEventModel(resource(id), currentUser, self));
};

export const paginatedLogicalDevicesSelector = state => {
  const data = paginatedResourceSelector(state)(LOGICAL_DEVICES_FIELD);
  const resource = id => logicalDeviceSelector(state)(id);

  return data.map(id => new DeviceModel(resource(id)));
};

export const paginatedCompaniesSelector = (state, { componentName } = {}) => {
  const resourceType = componentName || COMPANIES_FIELD;
  const data = paginatedResourceSelector(state)(resourceType);
  const resource = id => companySelector(state)(id);

  return data.map(id => resource(id));
};

export const paginatedFileUploadsSelector = (state, { deviceId }) => {
  const data = paginatedResourceSelector(state)(FILE_UPLOADS_FIELD);
  const resource = id => fileUploadSelector(state)(id);

  const device = logicalDeviceSelector(state)(deviceId);

  return data.map(id => new FileModel(resource(id), device));
};

export const paginatedTriggersSelector = state => {
  const data = paginatedResourceSelector(state)(TRIGGERS_FIELD);
  const resource = id => triggerSelector(state)(id);

  return data.map(id => new TriggerModel(resource(id)));
};

export const paginatedIoModulesSelector = state => {
  const dataModules = ioModulesSelector(state);
  const dataInstances = paginatedResourceSelector(state)(IO_MODULES_INSTANCES_FIELD);
  const resource = id => ioModuleSelector(state)(id);
  const resourceInstance = id => ioModuleInstanceSelector(state)(id);
  const allModules = dataModules.map(obj => new IoModuleModel(resource(obj.id)));

  return dataInstances.map(id => {
    const ioModuleInstance = new IoModuleInstanceModel(resourceInstance(id));
    ioModuleInstance.addMatchedModuleTitle(allModules);

    return ioModuleInstance;
  });
};

export const paginatedTimelinesSelector = state => {
  const data = paginatedResourceSelector(state)(TIMELINES_FIELD);
  const resource = id => timelineSelector(state)(id);

  return data.map(id => new TimelineModel(resource(id)));
};

export const paginatedFixtureGroupsSelector = state => {
  const data = paginatedResourceSelector(state)(FIXTURE_GROUPS_FIELD);
  const resource = id => fixtureGroupSelector(state)(id);

  return data.map(id => new FixtureGroupModel(resource(id)));
};

export const paginatedStaticScenesSelector = state => {
  const data = paginatedResourceSelector(state)(STATIC_SCENES_FIELD);
  const resource = id => staticSceneSelector(state)(id);

  return data.map(id => new StaticSceneModel(resource(id)));
};

export const paginatedZonesSelector = state => {
  const data = paginatedResourceSelector(state)(ZONES_FIELD);
  const resource = id => zoneSelector(state)(id);

  return data.map(id => new ZoneModel(resource(id)));
};

export const paginatedSnapshotsSelector = state => {
  const data = paginatedResourceSelector(state)(SNAPSHOTS_FIELD);
  const resource = id => snapshotSelector(state)(id);

  return data.map(id => new SnapshotModel(resource(id)));
};

export const paginatedContextsSelector = state => {
  const data = paginatedResourceSelector(state)(CONTEXTS_FIELD);
  const resource = id => contextSelector(state)(id);

  return data.map(id => new ContextModel(resource(id)));
};

export const paginatedTasksSelector = (state, { componentName } = {}) => {
  const resourceType = componentName || TASKS_FIELD;
  const data = paginatedResourceSelector(state)(resourceType);
  const resource = id => taskSelector(state)(id);

  return data.map(id => new TaskModel(resource(id)));
};

export const pollingSelector = state => state.polling;

export const pollingUpdatingSelector = createSelector(
  pollingSelector,
  polling => polling.updating,
);

const pollingParamsSelector = (paramName, fallback = null) => (polling, loader) => (
  get(polling[loader], paramName, fallback)
);

export const pollingValueSelector = createSelector(
  pollingSelector,
  argsSelector,
  pollingParamsSelector('value', 0),
);

export const pollingValueNullFallbackSelector = createSelector(
  pollingSelector,
  argsSelector,
  pollingParamsSelector('value'),
);

export const pollingUiValueSelector = createSelector(
  pollingSelector,
  argsSelector,
  pollingParamsSelector('uiValue'),
);

export const pollingRefreshingSelector = createSelector(
  pollingSelector,
  argsSelector,
  pollingParamsSelector('refreshing', false),
);

export const pollingRefreshLoaderSelector = createSelector(
  pollingSelector,
  argsSelector,
  pollingParamsSelector('refreshLoader'),
);

export const pollingTimeoutSelector = createSelector(
  pollingSelector,
  argsSelector,
  pollingParamsSelector('timeout', 0),
);

export const pollingTimeoutCounterSelector = createSelector(
  pollingSelector,
  argsSelector,
  pollingParamsSelector('timeoutCounter', 0),
);

export const pollingUpdatedSelector = createSelector(
  pollingSelector,
  argsSelector,
  pollingParamsSelector('updated', false),
);

export const pollingRequestIdSelector = createSelector(
  pollingSelector,
  argsSelector,
  pollingParamsSelector('requestId'),
);

export const pollingCurrentValueSelector = createSelector(
  pollingSelector,
  argsSelector,
  pollingParamsSelector('currentValue'),
);

export const pollingInitializedSelector = createSelector(
  pollingSelector,
  argsSelector,
  pollingParamsSelector('initialized', false),
);

export const tablesSelector = state => state.tables;

export const tableNameSelector = createSelector(
  tablesSelector,
  argsSelector,
  (tables, { resourceType }) => tables[resourceType],
);

export const pollingSagaSelector = state => state.pollingSaga;

export const pollingSagaIntervalSelector = createSelector(
  pollingSagaSelector,
  argsSelector,
  (pollingSaga, chainedLoaderId) => (
    pollingSaga[chainedLoaderId] && pollingSaga[chainedLoaderId].interval
  ),
);

export const resourceUpdateSelector = state => state.resourceUpdate;

export const resourceUpdateLoaderSelector = createSelector(
  resourceUpdateSelector,
  argsSelector,
  (loaders, chainedLoaderId) => loaders[chainedLoaderId] || {},
);

export const resourceUpdateLoaderRefreshingSelector = createSelector(
  resourceUpdateLoaderSelector,
  loader => !!loader.refreshing,
);

export const resourceUpdateLoadersSelector = ({ resourceUpdate }) => {
  const result = {};

  Object.keys(resourceUpdate).forEach(chainedLoaderId => {
    const loader = resourceUpdate[chainedLoaderId];
    const { updateLoader, resourceId } = loader;

    result[updateLoader] = result[updateLoader] || {};
    result[updateLoader][resourceId] = result[updateLoader][resourceId] || [];

    result[updateLoader][resourceId].push(loader);
  });

  return result;
};

export const resourceUpdateResourceLoaderSelector = (
  updateLoader,
  resourceId,
) => state => get(
  resourceUpdateLoadersSelector(state),
  `${updateLoader}.${resourceId}`,
  [],
);

export const resourceUpdateActiveResourcesSelector = state => (
  transform(resourceUpdateLoadersSelector(state), (result, value, updateLoader) => {
    Object.keys(value).forEach(resourceId => {
      result.push({ updateLoader, resourceId });
    });
  }, [])
);

export const permissionsStateSelector = state => state.permissions;

export const permissionsStatePermissionsLoadedSelector = createSelector(
  permissionsStateSelector,
  permissions => permissions.permissionsLoaded,
);

export const permissionsSelectingProjectsSelector = createSelector(
  permissionsStateSelector,
  permissions => permissions.selectingProjects,
);

export const permissionsSelectingProjectSuitesSelector = createSelector(
  permissionsStateSelector,
  permissions => permissions.selectingProjectSuites,
);

export const permissionsSelectingContextsSelector = createSelector(
  permissionsStateSelector,
  permissions => permissions.selectingContexts,
);

export const permissionsContextsSelector = createSelector(
  permissionsStateSelector,
  permissions => permissions.contexts,
);

export const permissionsContextSelector = createSelector(
  permissionsContextsSelector,
  contexts => memoize(
    contextId => contexts[contextId],
  ),
);

export const permissionsContextOpenSelector = (state, { contextId }) => {
  const { open } = permissionsContextSelector(state)(contextId);

  return open;
};

export const permissionsProjectsSelector = createSelector(
  permissionsStateSelector,
  permissions => permissions.projects,
);

export const permissionsProjectSelector = createSelector(
  permissionsProjectsSelector,
  projects => memoize(
    projectId => projects[projectId],
  ),
);

export const permissionsProjectSuitesSelector = createSelector(
  permissionsStateSelector,
  permissions => permissions.projectSuites,
);

export const permissionsProjectSuiteSelector = createSelector(
  permissionsProjectSuitesSelector,
  projectSuites => memoize(
    projectSuiteId => projectSuites[projectSuiteId],
  ),
);

export const permissionsProjectOpenSelector = (state, { projectId }) => {
  const { open } = permissionsProjectSelector(state)(projectId);

  return open;
};

export const permissionsProjectSuiteOpenSelector = (state, { projectSuiteId }) => {
  const { open } = permissionsProjectSuiteSelector(state)(projectSuiteId);

  return open;
};

export const permissionsProjectActiveUserIdsSelector = (state, { projectId }) => {
  const { activeUserIds } = permissionsProjectSelector(state)(projectId);

  return activeUserIds;
};

export const permissionsProjectSuiteActiveUserIdsSelector = (state, { projectSuiteId }) => {
  const { activeUserIds } = permissionsProjectSuiteSelector(state)(projectSuiteId);

  return activeUserIds;
};

export const permissionsProjectUserLoadedSelector = (state, { projectId, userId }) => {
  const { usersLoaded } = permissionsProjectSelector(state)(projectId);

  return usersLoaded[userId];
};

export const permissionsProjectSuiteUserLoadedSelector = (state, { projectSuiteId, userId }) => {
  const { usersLoaded } = permissionsProjectSuiteSelector(state)(projectSuiteId);

  return usersLoaded[userId];
};

export const permissionsProjectUsersLinkSelector = createSelector(
  permissionsStateSelector,
  permissions => memoize(
    projectId => permissions.links[projectId].users,
  ),
);

export const permissionsProjectSuiteUsersLinkSelector = createSelector(
  permissionsStateSelector,
  permissions => memoize(
    projectSuiteId => permissions.links[projectSuiteId].users,
  ),
);

export const permissionsProjectLogicalDeviceIdsSelector = (state, { projectId }) => {
  const permissionsProject = permissionsProjectSelector(state)(projectId);

  if (!permissionsProject) {
    return null;
  }

  return permissionsProject.logicalDeviceIds;
};

export const permissionsProjectLogicalDevicesLinkSelector = createSelector(
  permissionsStateSelector,
  permissions => memoize(
    projectId => permissions.links[projectId].logicalDevices,
  ),
);

export const permissionsLogicalDevicesSelector = createSelector(
  permissionsStateSelector,
  permissions => permissions.logicalDevices,
);

export const permissionsLogicalDeviceSelector = createSelector(
  permissionsLogicalDevicesSelector,
  logicalDevices => memoize(
    deviceId => logicalDevices[deviceId],
  ),
);

export const permissionsLogicalDeviceOpenSelector = (state, { deviceId }) => {
  const { open } = permissionsLogicalDeviceSelector(state)(deviceId);

  return open;
};

export const permissionsLogicalDeviceActiveUserIdsSelector = (state, { deviceId }) => {
  const { activeUserIds } = permissionsLogicalDeviceSelector(state)(deviceId);

  return activeUserIds;
};

export const permissionsLogicalDeviceUserLoadedSelector = (state, { deviceId, userId }) => {
  const { usersLoaded } = permissionsLogicalDeviceSelector(state)(deviceId);

  return usersLoaded[userId];
};

export const permissionsUsersSelector = createSelector(
  permissionsStateSelector,
  permissions => permissions.users,
);

export const permissionsUserSelector = createSelector(
  permissionsUsersSelector,
  users => memoize(
    userId => users[userId],
  ),
);

export const permissionsUserOpenSelector = (state, { wrapperUserId }) => {
  const { open } = permissionsUserSelector(state)(wrapperUserId);

  return open;
};

export const permissionsUserUserLoadedSelector = (state, { labelUserId, userId }) => {
  const { usersLoaded } = permissionsUserSelector(state)(labelUserId);

  return usersLoaded[userId];
};

export const permissionSelector = createSelector(
  dataSelector,
  data => memoize(
    permissionId => {
      const permission = build(data, PERMISSIONS_FIELD, permissionId || '', options);
      if (!permission) return null;

      return new PermissionModel(permission);
    },
  ),
);

export const permissionsSelector = createSelector(
  dataSelector,
  data => {
    const permissions = build(data, PERMISSIONS_FIELD, null, options);
    if (!permissions) return null;

    return permissions.map(permission => new PermissionModel(permission));
  },
);

// Project Permissions Selectors
export const projectResourcePermissionsSelector = createSelector(
  permissionsSelector,
  permissions => permissions && permissions.filter(({ resource }) => resource === 'project'),
);

export const projectResourcePermissionPathsSelector = createSelector(
  projectResourcePermissionsSelector,
  permissions => {
    if (!permissions) {
      return null;
    }

    return extractPartialPaths(permissions);
  },
);

export const computedProjectPermissionsTreeSelector = createSelector(
  projectResourcePermissionsSelector,
  permissions => memoize(
    scopes => new PermissionsTreeModel(permissions, scopes, ['user']),
  ),
);

export const computedProjectDataPermissionsTreeSelector = (state, scopes) => {
  const tree = computedProjectPermissionsTreeSelector(state)(scopes);
  const permissionsState = permissionsStateSelector(state);

  return memoize(
    projectId => {
      const { data } = permissionsState.projects[projectId];

      return tree.mergeData(data);
    },
  );
};

export const computedProjectTreePathSelector = (state, { projectId, path, scopes }) => {
  const tree = computedProjectDataPermissionsTreeSelector(state, scopes)(projectId);

  return get(tree, path, {});
};


// Project Suite Permissions Selectors

export const projectSuiteResourcePermissionsSelector = createSelector(
  permissionsSelector,
  permissions => permissions && permissions.filter(({ resource }) => resource === 'project_suite'),
);

export const projectSuiteResourcePermissionPathsSelector = createSelector(
  projectSuiteResourcePermissionsSelector,
  permissions => {
    if (!permissions) {
      return null;
    }

    return extractPartialPaths(permissions);
  },
);

export const computedProjectSuitePermissionsTreeSelector = createSelector(
  projectSuiteResourcePermissionsSelector,
  permissions => memoize(
    scopes => new PermissionsTreeModel(permissions, scopes),
  ),
);

export const computedProjectSuiteDataPermissionsTreeSelector = (state, scopes) => {
  const tree = computedProjectSuitePermissionsTreeSelector(state)(scopes);
  const permissionsState = permissionsStateSelector(state);

  return memoize(
    projectSuiteId => {
      const { data } = permissionsState.projectSuites[projectSuiteId];

      return tree.mergeData(data);
    },
  );
};

export const computedProjectSuiteTreePathSelector = (state, { projectSuiteId, path, scopes }) => {
  const tree = computedProjectSuiteDataPermissionsTreeSelector(state, scopes)(projectSuiteId);

  return get(tree, path, {});
};

// End Project Suites Permissions

export const logicalDeviceResourcePermissionsSelector = createSelector(
  permissionsSelector,
  permissions => permissions.filter(({ resource }) => resource === 'logical_device'),
);

export const logicalDeviceResourcePermissionIdsSelector = createSelector(
  logicalDeviceResourcePermissionsSelector,
  permissions => permissions.map(({ id }) => id),
);

export const logicalDeviceResourcePermissionNamesSelector = createSelector(
  logicalDeviceResourcePermissionsSelector,
  permissions => permissions.map(({ permissionName }) => permissionName),
);

export const logicalDeviceResourcePermissionPathsSelector = createSelector(
  logicalDeviceResourcePermissionsSelector,
  permissions => {
    if (!permissions) {
      return null;
    }

    return extractPartialPaths(permissions);
  },
);

export const computedLogicalDevicePermissionsTreeSelector = createSelector(
  logicalDeviceResourcePermissionsSelector,
  permissions => memoize(
    scopes => new PermissionsTreeModel(permissions, scopes),
  ),
);

export const computedLogicalDeviceDataPermissionsTreeSelector = (state, scopes) => {
  const tree = computedLogicalDevicePermissionsTreeSelector(state)(scopes);
  const permissionsState = permissionsStateSelector(state);

  return memoize(
    logicalDeviceId => {
      const { data } = permissionsState.logicalDevices[logicalDeviceId];

      return tree.mergeData(data);
    },
  );
};

export const computedLogicalDeviceTreePathSelector = (state, { logicalDeviceId, path, scopes }) => {
  const tree = computedLogicalDeviceDataPermissionsTreeSelector(state, scopes)(logicalDeviceId);

  return get(tree, path, {});
};

export const userResourcePermissionsSelector = createSelector(
  permissionsSelector,
  permissions => permissions.filter(({ resource }) => resource === 'user'),
);

export const userResourcePermissionPathsSelector = createSelector(
  userResourcePermissionsSelector,
  permissions => {
    if (!permissions) {
      return null;
    }

    return extractPartialPaths(permissions);
  },
);

export const computedUserPermissionsTreeSelector = createSelector(
  userResourcePermissionsSelector,
  permissions => memoize(
    scopes => new PermissionsTreeModel(permissions, scopes),
  ),
);

export const computedUserDataPermissionsTreeSelector = (state, scopes) => {
  const tree = computedUserPermissionsTreeSelector(state)(scopes);
  const permissionsState = permissionsStateSelector(state);

  return memoize(
    userId => {
      const { data } = permissionsState.users[userId];

      return tree.mergeData(data);
    },
  );
};

export const computedUserTreePathSelector = (state, { userId, path, scopes }) => {
  const tree = computedUserDataPermissionsTreeSelector(state, scopes)(userId);

  return get(tree, path, {});
};

export const pathwayDeviceDMXSelector = (state, deviceId) => get(logicalDeviceSelector(state)(deviceId), 'reported.pathway.dmxP', {});

export const pathwayDeviceDMXValueSelector = (portId, key) => (
  (state, deviceId) => get(pathwayDeviceDMXSelector(state, deviceId), `[${portId}].${key}`, null)
);

export const pathwayDeviceNETSelector = (state, deviceId) => get(logicalDeviceSelector(state)(deviceId), 'reported.pathway.netP', {});

export const pathwayDeviceNETValueSelector = (portId, key) => (
  (state, deviceId) => get(pathwayDeviceNETSelector(state, deviceId), `[${portId}].${key}`, null)
);

export const overrideSelector = state => state.override;

export const valueOverrideSelector = createSelector(
  overrideSelector,
  override => memoize(
    overrideId => override[overrideId],
  ),
);

export const socketDeviceDataMessageSelector = action => (
  action.type === constants.SOCKET_RECEIVED
  && action.payload.type !== socketConstants.RESPONSE_TYPE_CONFIRM_SUBSCRIPTION
  && action.payload.type !== socketConstants.RESPONSE_TYPE_REJECT_SUBSCRIPTION
  && !!action.payload.identifier
  && action.payload.identifier.channel === socketConstants.CHANNEL_DEVICE_DATA
);

export const socketDeviceDataSubscriptionStatus = (logicalDeviceId, dataId) => state => (
  state.websockets[`${socketConstants.CHANNEL_DEVICE_DATA}_${logicalDeviceId}_${dataId}`]
);

export const actionWithIdentifierSelector = (type, identifier) => action => (
  action.type === type
  && !!action.payload.identifier
  && isEqual(action.payload.identifier, identifier)
);

export const websocketActionSelector = ({ type, messageType, identifier }) => action => (
  action.type === type
  && (!messageType || action.payload.type === messageType)
  && !!action.payload.identifier
  && isEqual(action.payload.identifier, identifier)
);

export const deviceTriggersSelector = createSelector(
  dataSelector,
  data => memoize(
    logicalDeviceId => build(data, DEVICE_TRIGGERS_FIELD, logicalDeviceId || '', options),
  ),
);

export const deviceTimelinesSelector = createSelector(
  dataSelector,
  data => memoize(
    logicalDeviceId => build(data, DEVICE_TIMELINES_FIELD, logicalDeviceId || '', options),
  ),
);

export const deviceScenesSelector = createSelector(
  dataSelector,
  data => memoize(
    logicalDeviceId => build(data, DEVICE_SCENES_FIELD, logicalDeviceId || '', options),
  ),
);

export const deviceGroupsSelector = createSelector(
  dataSelector,
  data => memoize(
    logicalDeviceId => build(data, DEVICE_GROUPS_FIELD, logicalDeviceId || '', options),
  ),
);

export const actionTypeSelector = createSelector(
  dataSelector,
  data => memoize(
    actionType => build(data, ACTION_TYPES_FIELD, actionType || '', options),
  ),
);

export const allActionsTypeSelector = createSelector(
  dataSelector,
  data => data[ACTION_TYPES_FIELD] && (Object.keys(data[ACTION_TYPES_FIELD]).map(
    key => build(data, ACTION_TYPES_FIELD, key || '', options),
  ).reduce((obj, item) => {
    const newObj = Object.assign({}, obj);
    newObj[item.id] = item;
    return newObj;
  }, {})
  ),
);

export const automatedOperationsSelector = createSelector(
  dataSelector,
  data => build(data, AUTOMATED_OPERATIONS_FIELD, null, options),
);

export const automatedOperationSelector = createSelector(
  dataSelector,
  data => memoize(
    automatedOperationId => build(data, AUTOMATED_OPERATIONS_FIELD, automatedOperationId || '', options),
  ),
);

export const paginatedAutomatedOperationsSelector = state => {
  const data = paginatedResourceSelector(state)(AUTOMATED_OPERATIONS_FIELD);
  const resource = id => automatedOperationSelector(state)(id);

  return data.map(id => new AutomatedOperationModel(resource(id)));
};

export const schedulesSelector = createSelector(
  dataSelector,
  data => memoize(
    automatedOperationId => build(data, SCHEDULES_FIELD, automatedOperationId || '', options),
  ),
);

export const automatedOperationsOccurrencesSelector = createSelector(
  dataSelector,
  data => build(data, AUTOMATED_OPERATIONS_OCCURRENCES_FIELD, null, options),
);

export const siteAutomatedOperationsOccurrencesSelector = createSelector(
  automatedOperationsOccurrencesSelector,
  projectsSelector,
  (data, sitesData) => memoize(
    siteId => {
      if (!siteId || !data) {
        return null;
      }

      const site = sitesData.filter(({ id }) => id === siteId)[0];

      if (!site) {
        return null;
      }

      const occurrences = data.filter(({ siteId: occurrenceSiteId }) => (
        occurrenceSiteId === siteId
      ));

      const occurrencesWithOffset = occurrences.map(occurrence => ({
        ...occurrence,
        offset: site.offset,
        timezoneId: site.timezoneId,
      }));

      return occurrencesWithOffset;
    },
  ),
);

export const siteAutomatedOperationsOccurrencesDateRangeSelector = (state, siteId, start, end) => {
  const data = siteAutomatedOperationsOccurrencesSelector(state)(siteId);

  if (!data) {
    return [];
  }

  const filteredOccurrences = data.filter(({ dateTime }) => {
    const occurrenceDate = DateTime.fromISO(dateTime, { zone: start.zone });

    if (!end) {
      return start.hasSame(occurrenceDate, 'day');
    }

    return start <= occurrenceDate && end >= occurrenceDate;
  });

  return filteredOccurrences.map(occurrence => new OccurrenceModel(occurrence));
};

export const superSiteAutomatedOperationsOccurrencesSelector = createSelector(
  automatedOperationsOccurrencesSelector,
  projectSuitesSelector,
  (data, superSitesData) => memoize(
    superSiteId => {
      if (!superSiteId || !data) {
        return null;
      }

      const projectSuite = superSitesData.filter(({ id }) => id === superSiteId)[0];

      if (!projectSuite) {
        return null;
      }

      const occurrences = data.filter(({ projectSuiteId: occurrenceSuperSiteId }) => (
        occurrenceSuperSiteId === superSiteId
      ));

      const occurrencesWithOffset = occurrences.map(occurrence => ({
        ...occurrence,
        offset: projectSuite.offset,
        timezoneId: projectSuite.timezoneId,
      }));

      return occurrencesWithOffset;
    },
  ),
);

export const superSiteAutomatedOperationsOccurrencesDateRangeSelector = (
  state,
  projectSuiteId,
  start,
  end,
) => {
  const data = superSiteAutomatedOperationsOccurrencesSelector(state)(projectSuiteId);

  if (!data) {
    return [];
  }

  const filteredOccurrences = data.filter(({ dateTime }) => {
    const occurrenceDate = DateTime.fromISO(dateTime, { zone: start.zone });

    if (!end) {
      return start.hasSame(occurrenceDate, 'day');
    }

    return start <= occurrenceDate && end >= occurrenceDate;
  });

  return filteredOccurrences.map(occurrence => new OccurrenceModel(occurrence));
};

export const jamboxTimelineSelector = createSelector(
  dataSelector,
  data => memoize(
    jamboxTimelineId => build(data, JAMBOX_TIMELINES_FIELD, String(jamboxTimelineId) || '', options),
  ),
);

export const paginatedJamboxTimelinesSelector = state => {
  const data = paginatedResourceSelector(state)(JAMBOX_TIMELINES_FIELD);
  const resource = id => jamboxTimelineSelector(state)(id);

  return data.map(id => new JamboxTimelineModel(resource(id)));
};

export const settingsServiceVersionsSelector = state => state.settings.serviceVersions;

export const remoteDevicesSelector = createSelector(
  dataSelector,
  data => build(data, REMOTE_DEVICES_FIELD, null, options),
);

export const remoteDeviceSelector = createSelector(
  dataSelector,
  data => memoize(
    remoteDeviceId => build(data, REMOTE_DEVICES_FIELD, toString(remoteDeviceId) || '', options),
  ),
);

export const paginatedRemoteDevicesSelector = state => {
  const data = paginatedResourceSelector(state)(REMOTE_DEVICES_FIELD);
  const resource = id => remoteDeviceSelector(state)(id);

  return data.map(id => new RemoteDeviceModel(resource(id)));
};

export const filterRemoteDevicesSelector = createSelector(
  remoteDevicesSelector,
  data => memoize(
    deviceId => data && data.filter(device => device.deviceId === deviceId),
  ),
);

export const showSyncFirmwareButtonSelector = (state, deviceId) => {
  const data = filterRemoteDevicesSelector(state)(deviceId);

  if (!Array.isArray(data) || isEmpty(data)) {
    return false;
  }

  return data.some(item => item.devices && item.devices
    .some(device => device.firmware.correct === false),
  );
};

export const remoteDevicesWithIncompatibleFirmwareSelector = (state, deviceId) => {
  const data = filterRemoteDevicesSelector(state)(deviceId);

  if (!Array.isArray(data) || isEmpty(data)) {
    return [];
  }

  return data.filter(item => item.devices && item.devices
    .some(device => device.firmware.correct === false),
  );
};

export const payloadToSyncDevicesFirmwareSelector = (state, deviceId) => {
  const data = remoteDevicesWithIncompatibleFirmwareSelector(state, deviceId);
  const reducer = (acc, currentValue) => {
    const { devices } = currentValue;
    const serialNumbers = Array.isArray(devices) && devices.map(item => item.serial);
    const remoteDevice = { remoteDeviceType: currentValue.type, serialNumbers };
    const findSameDeviceTypeIndex = findIndex(acc, { remoteDeviceType: currentValue.type });

    if (findSameDeviceTypeIndex !== -1) {
      acc[findSameDeviceTypeIndex].serialNumbers.push(...serialNumbers);

      return [...acc];
    }

    return [...acc, remoteDevice];
  };

  if (!Array.isArray(data) || isEmpty(data)) {
    return [];
  }

  return data.reduce(reducer, []);
};

export const unjoinedRemoteDevicesSelector = createSelector(
  dataSelector,
  data => build(data, UNJOINED_REMOTE_DEVICES_FIELD, null, options),
);

export const unjoinedRemoteDeviceSelector = createSelector(
  dataSelector,
  data => memoize(
    remoteDeviceId => build(data, UNJOINED_REMOTE_DEVICES_FIELD, remoteDeviceId || '', options),
  ),
);

export const paginatedUnjoinedRemoteDevicesSelector = state => {
  const data = paginatedResourceSelector(state)(UNJOINED_REMOTE_DEVICES_FIELD);
  const resource = id => unjoinedRemoteDeviceSelector(state)(id);

  return data.map(id => new UnjoinedRemoteDeviceModel(resource(id)));
};

export const filterIsEnabledSelector = state => (
  resourceType, resourceId,
) => state.fetchingFilter[resourceId]
  && state.fetchingFilter[resourceId][`resourceType-${resourceType}`];

export const extraFilterParamSelector = state => state.fetchingFilter.filterParam;

export const resourceFilterSelector = state => (
  resourceType, filterParam, resourceId,
) => state.fetchingFilter[resourceId]
  && state.fetchingFilter[resourceId][`${resourceType}-${filterParam}`];

export const requestTypeFilterSelector = state => (
  resourceType, resourceId,
) => state.fetchingFilter[resourceId]
  && state.fetchingFilter[resourceId][`requestType-${resourceType}`];

export const siteActionsListSelector = createSelector(
  dataSelector,
  data => memoize(
    projectId => build(data, SITE_ACTION_TYPES_LIST_FIELD, projectId || '', options),
  ),
);
