import constants from 'dispatcherConst';

const initialState = {
  fetching: false,
  error: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_USERS_REQUEST:
      return { ...state, fetching: true, error: false };

    case constants.FETCH_USERS_SUCCESS:
      return { ...state, fetching: false, error: false };

    case constants.FETCH_USERS_FAILED:
      return { ...state, fetching: false, error: true };

    default:
      return state;
  }
};

export default reducer;
