import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { actionCreators as applicationUserActionCreators } from 'application/utilities/user';
import menuActionCreators from 'components/TopBar/MainMenu/menuActionCreators';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import {
  currentUserIdSelector,
  loginCheckedSelector,
  menuVisibleSelector,
  tenantSelector,
  recentlyLoggedOutSelector,
  controlPanelContextSelector,
} from 'helpers/selectors';
import paginationActionCreators from 'data/paginationActionCreators';
import withLoader from 'helpers/withLoader';
import App from './App';

const mapStateToProps = state => ({
  currentUserId: currentUserIdSelector(state),
  tenant: tenantSelector(state),
  menuVisible: menuVisibleSelector(state),
  loginChecked: loginCheckedSelector(state),
  recentlyLoggedOut: recentlyLoggedOutSelector(state),
  inControlPanelContext: controlPanelContextSelector(state),
});

const mapDispatchToProps = {
  onChangeRoute: paginationActionCreators.changeRoute,
  onHideMenu: menuActionCreators.hideMenu,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  chainedLoaderWithSaga(applicationUserActionCreators.fetchCurrentUser),
  withLoader,
)(App);
