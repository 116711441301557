function createTriggersObject({ id, name, type }) {
  if (id && name) {
    return {
      id,
      name,
      type,
    };
  }

  return {
    id: '0',
    name: 'not found',
  };
}

export default createTriggersObject;
