import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import { StyledLabel } from './RecipientSelect.style';

const CONSTANTS = {
  user: {
    label: 'Select users',
    placeholder: 'Select users',
  },
};

function RecipientSelect({
  isLoading,
  recipients,
  selectedRecipients,
  recipientType,
  onChangeRecipients,
}) {
  const options = useMemo(() => recipients.map(recipient => {
    const { metadata } = recipient || {};
    const { text, recipientLongName } = metadata || {};

    return {
      ...recipient,
      text: recipientLongName || text,
    };
  }), [recipients]);

  const handleChange = useCallback((e, { value }) => {
    onChangeRecipients(value);
  }, [onChangeRecipients]);

  return (
    <>
      <StyledLabel>
        {CONSTANTS[recipientType].label}
      </StyledLabel>
      <Dropdown
        name="permissions-table-recipient-select"
        labeled
        loading={isLoading}
        placeholder={CONSTANTS[recipientType].placeholder}
        value={selectedRecipients}
        options={options}
        onChange={handleChange}
        fluid
        multiple
        selection
        search
        clearable
      />
    </>
  );
}

RecipientSelect.defaultProps = {
  recipientType: 'user',
  recipients: [],
  selectedRecipients: [],
  isLoading: false,
};

RecipientSelect.propTypes = {
  recipientType: PropTypes.string,
  recipients: PropTypes.array,
  selectedRecipients: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool,
  onChangeRecipients: PropTypes.func.isRequired,
};

export default RecipientSelect;
