import isNull from 'lodash/isNull';
import isNumber from 'lodash/isNumber';

const renderValue = value => {
  if (isNull(value) || !isNumber(value)) {
    return 0;
  }

  if (value > 0) {
    return `+${value}`;
  }

  return value.toString();
};

export default renderValue;
