const reduceAutomatedOperationsWithProjectSuiteId = projectSuiteId => (
  automatedOperations,
  { id, attributes },
) => ({
  ...automatedOperations,
  [id]: {
    id,
    attributes: {
      ...attributes,
      projectSuiteId,
    },
  },
});

export default reduceAutomatedOperationsWithProjectSuiteId;
