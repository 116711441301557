import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, propTypes as reduxFormPropTypes } from 'redux-form';
import { FormGroup, Grid } from 'semantic-ui-react';
import SparkMD5 from 'spark-md5';

import { fileTypesMapType, systemFilesType, uploadFileType } from 'data/types';
import UploadModel from 'models/UploadModel';
import { matchExtension, elideToLength } from 'helpers/sharedMethods';
import { nonEmptyFiles, required, sameFile } from 'components/form/formValidators';
import ModalForm from 'components/form/ModalForm/ModalForm';
import DropzoneField from 'components/form/DropzoneField/DropzoneFieldContainer';
import DropdownField from 'components/form/DropdownField/DropdownField';
import {
  StyledDropdownFieldWrapper,
  StyledDropdownField,
  StyledFilename,
} from './NewUploadModalForm.style';

export const newUploadFormFields = {
  files: 'systemFiles',
};

class NewUploadModalForm extends Component {

  constructor() {
    super();

    this.state = { processing: false };

    this.handleFileStatusChange = this.handleFileStatusChange.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ resume: nextResume, change: nextChange }) {
    if (nextResume && !nextResume.isPaused) {
      const { name, fileTypeId } = nextResume;
      const nameHash = SparkMD5.hash(name);

      nextChange(nameHash, fileTypeId);
    }
  }

  handleFileStatusChange(processing) {
    this.setState({ processing });
  }

  getTypesForFile(name) {
    const { fileTypes } = this.props;

    const extensions = Object.keys(fileTypes);
    const sortedExtensions = UploadModel.sortExtensionsByImportance(extensions);
    const extension = matchExtension(name, sortedExtensions, { ignoreCase: true });

    return fileTypes[extension];
  }

  render() {
    const { processing } = this.state;
    const { accept, files, fileTypes, submitting, resume, trigger } = this.props;
    const { accessKeyId } = resume || {};
    const isFileTypeSelectVisible = !accessKeyId && Boolean(fileTypes);

    return (
      <ModalForm
        header="Upload files"
        trigger={trigger}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...this.props}
        submitting={processing}
        size="small"
      >
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <FormGroup inline>
                <Field
                  name={newUploadFormFields.files}
                  component={DropzoneField}
                  loading={submitting}
                  validate={[nonEmptyFiles, sameFile]}
                  multiple={!resume}
                  accept={accept}
                  resume={resume}
                  onChangeFileStatus={this.handleFileStatusChange}
                  fileTypes={fileTypes}
                />
              </FormGroup>
              {files.map(({ name }) => {
                const nameHash = SparkMD5.hash(name);

                return (
                  <FormGroup key={name} inline>
                    <StyledDropdownFieldWrapper>
                      <StyledFilename>{elideToLength(name, 50)}</StyledFilename>
                      {isFileTypeSelectVisible && (
                        <StyledDropdownField>
                          <Field
                            name={nameHash}
                            component={DropdownField}
                            loading={submitting}
                            validate={required}
                            options={this.getTypesForFile(name)}
                          />
                        </StyledDropdownField>
                      )}
                    </StyledDropdownFieldWrapper>
                  </FormGroup>
                );
              })}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ModalForm>
    );
  }

}

NewUploadModalForm.defaultProps = {
  accept: '',
  files: [],
  fileTypes: null,
  resume: null,
};

NewUploadModalForm.propTypes = {
  ...reduxFormPropTypes.isRequired,
  accept: PropTypes.string,
  files: systemFilesType,
  fileTypes: fileTypesMapType,
  resume: uploadFileType,
  submitting: PropTypes.bool.isRequired,
  trigger: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default NewUploadModalForm;
