import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import formNames from 'components/form/formNames';

import { selectors } from '../../../../utilities';
import { colorList } from './constants';
import IdentificationReview from './IdentificationReview';

const IdentificationReviewFormContainer = reduxForm({
  form: formNames.taskScheduler,
  enableReinitialize: true,
})(IdentificationReview);

export const mapStateToProps = state => {
  const taskName = selectors.taskNameSelector(state);
  const selectedActions = selectors.selectedActionsSelector(state);
  const selectedTasks = selectors.selectedTasksSelector(state);
  const [firstTask] = selectedTasks || [];
  const { name: firstTaskName } = firstTask || {};

  const defaultName = taskName || firstTaskName;

  return {
    selectedDevice: selectors.selectedDeviceSelector(state),
    selectedActions,
    initialValues: {
      name: defaultName,
      description: '',
      enabled: true,
      hidden: false,
      occurrenceColour: colorList[0],
      priority: 2,
      priorityType: 'times',
      mode: 'forceExecution',
    },
  };
};

export default connect(mapStateToProps)(IdentificationReviewFormContainer);
