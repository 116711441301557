import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import { PROJECTS_FIELD } from 'helpers/selectors';

export const addProjectToProjectSuite = ({ projectId, projectName, projectSuiteId }) => ({
  type: constants.ADD_PROJECT_TO_PROJECT_SUITE_REQUEST,
  payload: {
    projectId,
    projectName,
    projectSuiteId,
    endpoint: endpoints.PROJECT_SUITE_ADD_PROJECT(projectSuiteId),
  },
});

export const addProjectToProjectSuiteSuccess = response => ({
  type: constants.ADD_PROJECT_TO_PROJECT_SUITE_SUCCESS,
  payload: {
    response,
    message: messages.PROJECT_ADDED_TO_PROJECT_SUITE(response.projectName),
    resourceType: PROJECTS_FIELD,
  },
});

export const addProjectToProjectSuiteFailure = error => ({
  type: constants.ADD_PROJECT_TO_PROJECT_SUITE_FAILED,
  payload: { error },
});

export default addProjectToProjectSuite;
