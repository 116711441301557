import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledIcon,
  StyledLabel,
  StyledTooltip,
  StyledIconWrapper,
} from './StatusIcon.style';

const StatusIcon = ({ label, model, name, status, tooltip, loading }) => {
  const icon = (
    <StyledIconWrapper>
      <StyledIcon name={name} className={`model-${model} ${status}`} loading={loading} />
      {label && <StyledLabel>{label}</StyledLabel>}
    </StyledIconWrapper>
  );

  if (!tooltip) {
    return icon;
  }

  return <StyledTooltip trigger={icon} content={tooltip} />;
};

StatusIcon.defaultProps = {
  label: undefined,
  tooltip: null,
  loading: false,
};

StatusIcon.propTypes = {
  label: PropTypes.string,
  model: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  loading: PropTypes.bool,
};

export default StatusIcon;
