import { compose } from 'redux';
import { connect } from 'react-redux';

import automatedOperationsActionCreators from '../../../../../../../utilities/actionCreators';
import withDeleteOperation from './withDeleteOperation';

const mapDispatchToProps = {
  onDeleteOperation: automatedOperationsActionCreators.removeOperation,
};

export default compose(
  connect(null, mapDispatchToProps),
  withDeleteOperation,
);
