import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';
import SelectField from 'components/form/SelectField/SelectField';
import { Button } from 'components/atomic';
import SegmentRow from 'components/SegmentRow/SegmentRow';
import pathwayConstants from 'layouts/Pathway/constants';
import { pathwayDeviceDMXValueSelector } from 'helpers/selectors';
import DeviceSettingForm from 'pages/Device/DeviceSettingForm/DeviceSettingFormContainer';
import DeviceSettingValue from 'pages/Device/DeviceSettingValue/DeviceSettingValueContainer';
import { Link } from 'react-router-dom';

const DMXStatusOptions = {
  component: SelectField,
  options: [
    { key: 'enabled', value: 'enabled', text: 'Enabled' },
    { key: 'disabled', value: 'disabled', text: 'Disabled' },
    { key: 'hold', value: 'hold', text: 'Hold' },
  ],
};

const PathwayPathportPortsV1Output = ({ online, element: { resourceId, id } }) => (
  <Segment>
    <Header as="h4">Port</Header>
    <SegmentRow>
      <div>Patch name:</div>
      <DeviceSettingValue
        resourceId={resourceId}
        selector={pathwayDeviceDMXValueSelector(id, pathwayConstants.FIELD_PATH_NAME)}
      />
      <Button
        primary
        as={Link}
        disabled={!online}
        to={`./dmx_levels?port=${id}`}
      >
        Show output
      </Button>
    </SegmentRow>
    <SegmentRow>
      <div>DMX Status:</div>
      <DeviceSettingForm
        online={online}
        resourceId={resourceId}
        port={id}
        label="DMX Status"
        valueSelector={pathwayDeviceDMXValueSelector(id, pathwayConstants.FIELD_DMX_STATUS)}
        setting={pathwayConstants.SETTING_DMX_STATUS}
        fieldProps={DMXStatusOptions}
        name="dmxStatus"
      />
    </SegmentRow>
  </Segment>
);

PathwayPathportPortsV1Output.propTypes = {
  online: PropTypes.bool.isRequired,
  element: PropTypes.shape({
    resourceId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default PathwayPathportPortsV1Output;
