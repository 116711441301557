import { compose } from 'redux';

import extractValue from 'helpers/extractValue/extractValue';
import markLastUpdate from 'helpers/markLastUpdate/markLastUpdate';
import withAutoIncrement from 'helpers/withAutoIncrement/withAutoIncrement';
import formatValue from 'helpers/formatValue/formatValue';
import withPrevious from 'helpers/withPrevious/withPrevious';
import { timeNormalizer } from 'components/DescriptionList/normalizers';

const TimeIncremented = compose(
  extractValue,
  markLastUpdate,
  withAutoIncrement,
  formatValue(timeNormalizer),
  withPrevious,
)(({ render, value }) => render(value));

export default TimeIncremented;
