import React from 'react';
import { Input } from 'semantic-ui-react';

import FormField from 'components/form/FormField/FormField';
import DropdownMultiField from '../DropdownMultiField/DropdownMultiField';

const DropdownMultiFieldInput = props => (
  <FormField {...props}>
    <DropdownMultiField {...props} />
  </FormField>
);

DropdownMultiFieldInput.propTypes = {
  ...Input.propTypes,
};

export default DropdownMultiFieldInput;
