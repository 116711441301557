import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

import { Button } from 'components/atomic';

export const StyledAddUsersButton = styled(Button)`
  display: block;
`;

export const StyledModalActions = styled(Modal.Actions)`
  display: flex;
  justify-content: space-between;
`;

export default StyledAddUsersButton;
