export default class CognitoCreator {

  constructor(sixeyeFirmwareVersion, tabs) {
    this.model = 'Cognito2';
    this.sixeyeFirmwareVersion = sixeyeFirmwareVersion;
    this.manufacturerSDK = 'pathway';
    this.tabs = tabs;
  }

}
