import React from 'react';
import PropTypes from 'prop-types';

import { getDisplayName } from 'helpers/sharedMethods';
import { simpleValueTypes } from 'data/types';
import { isPresent } from 'pages/Device/DefinitionList/DefinitionListItem/DefinitionListItemSingle/DefinitionListItemSingle';

const formatValue = formatter => WrappedComponent => {
  const FormatValue = props => {
    const { value, ...rest } = props;
    const formattedValue = isPresent(value) ? formatter(value, rest) : value;

    return <WrappedComponent {...props} value={formattedValue} />;
  };

  FormatValue.displayName = `WithFormat(${getDisplayName(WrappedComponent)})`;

  FormatValue.defaultProps = {
    value: null,
  };

  FormatValue.propTypes = {
    value: PropTypes.oneOfType(
      simpleValueTypes,
    ),
  };

  return FormatValue;
};

export default formatValue;
