import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
} from 'semantic-ui-react';
import { Field } from 'redux-form';

import {
  FormFieldLabel,
  FormFieldWrapper,
  InputWrapper,
} from 'components/form/FormField/FormField.style';
import SelectField from 'components/form/SelectField/SelectField';

class SelectDeviceAction extends Component {

  constructor(props) {
    super(props);

    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(e, value) {
    const { selectDeviceAction } = this.props;

    selectDeviceAction(value);
  }

  render() {
    const { handleSelect } = this;
    const { optionList } = this.props;
    const isLoading = optionList.length === 0;

    return (
      <FormGroup inline>
        <FormFieldWrapper width={16}>
          <FormFieldLabel htmlFor="input">Select Device Action</FormFieldLabel>
          <InputWrapper>
            <Field
              name="typeId"
              placeholder="Device Actions"
              component={SelectField}
              onChange={handleSelect}
              options={optionList}
              loading={isLoading}
            />
          </InputWrapper>
        </FormFieldWrapper>
      </FormGroup>
    );
  }

}

SelectDeviceAction.propTypes = {
  selectDeviceAction: PropTypes.func.isRequired,
  optionList: PropTypes.array.isRequired,
};

export default SelectDeviceAction;
