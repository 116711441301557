import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import camelCase from 'lodash/camelCase';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';

import { translationLabels } from 'application/tenant/console/site/components/structural/ProjectTasksTab/components/ListOfTasks/components/TaskAccordionRow/components/ActionsTable/constants';
import {
  computeActionTypesState,
  resetFieldFormValues,
  updateInitialValues,
  formPropsAreEqual,
  setInitialValues,
} from './utilities';
import {
  DropdownActionField,
  DropdownMultiActionField,
  StringActionField,
  ToggleActionField,
  PercentageActionField,
  TimeTextActionField,
  ColorUI,
  SelectedProject,
  SelectMultiSite,
} from './components';

const GenerateActionForm = React.memo(({
  action,
  actionType,
  logicalDeviceId,
  formValues,
  resetFieldValue,
  actionTypeDeviceIdKey,
  initialize,
  changeFieldValue,
  scheduleMode,
  resourceId,
  resourceName,
  registerField,
}) => {
  const { params } = action;

  const [fieldsState, setFieldsState] = useState({});

  useEffect(() => {
    const stateToUpdate = computeActionTypesState(action, formValues);

    if (!isEqual(fieldsState, stateToUpdate)) {
      if (!isNull(initialize)) {
        updateInitialValues(action, initialize);
      }

      setFieldsState(stateToUpdate);
      resetFieldFormValues(stateToUpdate, formValues, resetFieldValue, actionTypeDeviceIdKey);
      setInitialValues(stateToUpdate, formValues, changeFieldValue, actionTypeDeviceIdKey, params);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, formValues]);

  const getFieldState = (state, typeOfAction, key) => state[camelCase(typeOfAction)][key];

  const checkIfRendered = (typeOfAction, key) => {
    if (fieldsState && fieldsState[camelCase(typeOfAction)]) {
      return getFieldState(fieldsState, typeOfAction, key);
    }

    return true;
  };

  const renderFormField = param => {
    const lang = 'en';
    const { label, tip } = param;
    const labelText = translationLabels[label] ? translationLabels[label][lang] : param.label;
    const tipText = translationLabels[tip] ? translationLabels[tip][lang] : param.label;
    const { uiElement } = param;
    const props = {
      key: `${param.key}${actionTypeDeviceIdKey}`,
      name: `${param.key}${actionTypeDeviceIdKey}`,
    };

    switch (uiElement) {
      case 'input':
        return checkIfRendered(actionType, param.key) && (
          <StringActionField
            {...props}
            label={labelText}
            tip={tip}
            tipText={tipText}
            regex={param.regex}
            gteq={param.gteq}
            lteq={param.lteq}
          />
        );

      case 'toggleButton':
        return (
          <ToggleActionField
            {...props}
            label={labelText}
          />
        );

      case 'dropdown':
        return checkIfRendered(actionType, param.key) && (
          <DropdownActionField
            {...props}
            label={labelText}
            uiType={param.uiType}
            list={param.list}
            gteq={param.gteq}
            lteq={param.lteq}
          />
        );

      case 'dropdown-multi':
        return checkIfRendered(actionType, param.key) && (
          <DropdownMultiActionField
            {...props}
            label={labelText}
            uiType={param.uiType}
            maxCount={param.maxCount}
            logicalDeviceId={logicalDeviceId}
            tip={tip}
            tipText={tipText}
          />
        );

      case 'releaseSecs':
        return checkIfRendered(actionType, param.key) && (
          <PercentageActionField
            {...props}
            label={labelText}
            tip={tipText}
          />
        );

      case 'percentage':
        return checkIfRendered(actionType, param.key) && (
          <PercentageActionField
            {...props}
            label={labelText}
            tip={tipText}
            isPercentage
            gteq={param.gteq}
            lteq={param.lteq}
          />
        );

      case 'timeMillisec':
        return checkIfRendered(actionType, param.key) && (
          <TimeTextActionField
            {...props}
            label={labelText}
            tip={tipText}
            gteq={param.gteq}
            lteq={param.lteq}
          />
        );

      case 'enableColourPicker':
        return (
          <ColorUI
            key={`${param.key}${actionTypeDeviceIdKey}`}
            formValues={formValues}
            changeFieldValue={changeFieldValue}
            actionTypeDeviceIdKey={actionTypeDeviceIdKey}
            scheduleMode={scheduleMode}
          />
        );

      case 'selectedProject':
        return checkIfRendered(actionType, param.key) && (
          <SelectedProject
            {...props}
            label={labelText}
            tip={tip}
            tipText={tipText}
            regex={param.regex}
            gteq={param.gteq}
            lteq={param.lteq}
            changeFieldValue={changeFieldValue}
            actionTypeDeviceIdKey={actionTypeDeviceIdKey}
            resourceId={resourceId}
            resourceName={resourceName}
            registerField={registerField}
          />
        );

      case 'projectSuiteDropdown':
        return checkIfRendered(actionType, param.key) && (
          <SelectMultiSite
            {...props}
            label={labelText}
            tip={tip}
            tipText={tipText}
            regex={param.regex}
            gteq={param.gteq}
            lteq={param.lteq}
            changeFieldValue={changeFieldValue}
            actionTypeDeviceIdKey={actionTypeDeviceIdKey}
            resourceId={resourceId}
            registerField={registerField}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div>
      {(!!actionType.length && params) && (
        <Fragment>
          {params.map(field => renderFormField(field))}
        </Fragment>
      )}
      {params.length === 0 && (<span>This action has no parameters.</span>)}
    </div>
  );
}, formPropsAreEqual);

GenerateActionForm.defaultProps = {
  action: null,
  formValues: {},
  resetFieldValue: null,
  actionTypeDeviceIdKey: null,
  initialize: null,
  changeFieldValue: null,
  scheduleMode: false,
  resourceId: null,
  resourceName: null,
  registerField: () => null,
  logicalDeviceId: null,
};

GenerateActionForm.propTypes = {
  actionType: PropTypes.string.isRequired,
  logicalDeviceId: PropTypes.string,
  action: PropTypes.object,
  formValues: PropTypes.object,
  resetFieldValue: PropTypes.func,
  actionTypeDeviceIdKey: PropTypes.string,
  initialize: PropTypes.func,
  changeFieldValue: PropTypes.func,
  scheduleMode: PropTypes.bool,
  resourceId: PropTypes.string,
  resourceName: PropTypes.string,
  registerField: PropTypes.func,
};

export default GenerateActionForm;
