import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { FiSunrise, FiSunset } from 'react-icons/fi';
import { Label, Icon } from 'semantic-ui-react';

import { getAstroLabelText } from '../../../../utilities';

import { StyledList, StyledListItem } from '../../SelectedTimesView.style';

function TimeList({
  times,
  onRemoveTime,
  clearable = true,
}) {

  const orderedTimes = useMemo(() => {
    if (!isEmpty(times)) {
      const normalTimes = times.filter(time => !time.includes('_'));

      return normalTimes.sort();
    }

    return [];
  }, [times]);

  const orderedSunriseValues = useMemo(() => {
    if (isEmpty(times)) {
      return [];
    }

    const sunriseTimes = times.filter(time => time.includes('sunrise'));

    if (isEmpty(sunriseTimes)) {
      return [];
    }

    return sunriseTimes.slice().sort((a, b) => a - b);
  }, [times]);

  const orderedSunsetValues = useMemo(() => {
    if (isEmpty(times)) {
      return [];
    }

    const sunsetTimes = times.filter(time => time.includes('sunset'));

    if (isEmpty(sunsetTimes)) {
      return [];
    }

    return sunsetTimes.slice().sort((a, b) => a - b);
  }, [times]);

  const renderRemoveIcon = useCallback(time => {
    if (!clearable) {
      return null;
    }

    return <Icon name="delete" onClick={() => onRemoveTime(time)} />;
  }, [clearable, onRemoveTime]);

  const getAstroLabel = useCallback(astroTime => {
    const [type, value] = astroTime.split('_');

    return getAstroLabelText({ type, value: Number(value) });
  }, []);

  if (isEmpty(orderedTimes) && isEmpty(orderedSunriseValues) && isEmpty(orderedSunsetValues)) {
    return null;
  }

  return (
    <>
      <StyledList className="normal-times-list">
        {orderedTimes.map(time => (
          <StyledListItem key={time}>
            <Label htmlFor="text" basic>
              <Icon name="clock outline" /> {time} {renderRemoveIcon(time)}
            </Label>
          </StyledListItem>
        ))}
      </StyledList>
      <StyledList className="sunrise-times-list">
        {orderedSunriseValues.map(time => (
          <StyledListItem key={`sunrise-${time}`}>
            <Label htmlFor="text" basic>
              <FiSunrise />&nbsp;{getAstroLabel(time)} {renderRemoveIcon(time)}
            </Label>
          </StyledListItem>
        ))}
      </StyledList>
      <StyledList className="sunset-times-list">
        {orderedSunsetValues.map(time => (
          <StyledListItem key={`sunset-${time}`}>
            <Label htmlFor="text" basic>
              <FiSunset />&nbsp;{getAstroLabel(time)} {renderRemoveIcon(time)}
            </Label>
          </StyledListItem>
        ))}
      </StyledList>
    </>
  );
}

TimeList.defaultProps = {
  clearable: true,
  onRemoveTime: null,
  times: [],
};

TimeList.propTypes = {
  times: PropTypes.array,
  onRemoveTime: PropTypes.func,
  clearable: PropTypes.bool,
};

export default TimeList;
