import { put, call } from 'redux-saga/effects';

import { TIMELINES_FIELD } from 'helpers/selectors';
import { prepareResponseData, websocketUrlParams } from 'helpers/paginationHelpersClient';
import * as actionCreators from '../../actionCreators/timelinesSection';

function* paginateTimelines({ payload: { response } }) {
  const { componentName } = response;
  const resourceType = componentName || TIMELINES_FIELD;
  const urlParams = websocketUrlParams({
    type: resourceType,
    included: true,
  });

  const enhancedResponse = yield call(prepareResponseData, response, urlParams);
  yield put(actionCreators.fetchTimelinesSuccessPaginated(enhancedResponse));
}

export default paginateTimelines;
