import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Menu, Responsive } from 'semantic-ui-react';

import { sizes } from 'themes/mixins';
import NavLink from 'components/NavLink/NavLinkContainer';

export default function MenuItem({
  to,
  theme,
  onHideMenu,
}) {
  const { breadcrumbs = {} } = theme;
  const commonProps = {
    to,
    as: NavLink,
    activeClassName: 'active',
  };

  return (
    <Fragment>
      <Responsive maxWidth={sizes.md - 1}>
        <Menu.Item
          {...commonProps}
          onClick={onHideMenu}
        >
          {breadcrumbs[to]}
        </Menu.Item>
      </Responsive>
      <Responsive minWidth={sizes.md}>
        <Menu.Item {...commonProps}>
          {breadcrumbs[to]}
        </Menu.Item>
      </Responsive>
    </Fragment>
  );
}

MenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  onHideMenu: PropTypes.func.isRequired,
};
