import React, { useEffect, useRef, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';

import formNames from 'components/form/formNames';
import { SimpleGateway, frontendPermissions } from 'gateway';
import ProjectFileUpload from 'components/ProjectFileUpload/ProjectFileUploadContainer';
import { ProjectSuiteContext } from 'application/tenant/components/structural/ControlUI/components';

import { getDecodedFileName } from './utilities';
import { StyledInput } from './UploadImageInput.style';

const UploadImageInput = ({
  fileName,
  pageId,
  projectId,
  fileIdentifier,
  onSetPageBackgroundImage,
  s3URL,
  fileIsUploading,
}) => {
  const resourceType = useContext(ProjectSuiteContext) ? 'projectSuite' : 'project';
  const permission = useContext(ProjectSuiteContext)
    ? frontendPermissions.PROJECT_SUITE_CONTROL_PANEL_EDIT
    : frontendPermissions.EDIT_CONTROL_PANEL;

  const didMount = useRef(false);

  const decodedFileName = useMemo(
    () => getDecodedFileName(fileName),
    [fileName],
  );

  const trigger = (
    <StyledInput
      value={decodedFileName}
      fluid
      readOnly
      placeholder="Click to upload"
    />
  );

  useEffect(() => {
    if (didMount.current && fileIsUploading && !isNil(s3URL)) {
      onSetPageBackgroundImage({
        pageId,
        projectId,
        backgroundImg: s3URL,
      });
    } else {
      didMount.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s3URL]);

  return (
    <SimpleGateway condition={permission}>
      <ProjectFileUpload
        trigger={trigger}
        form={`${formNames.pageBackgroundImage}.${pageId}`}
        accept=".png, .gif, .jpeg, .jpg"
        projectId={projectId}
        fileIdentifier={fileIdentifier}
        fileTypeId="control_panel_background"
        resourceType={resourceType}
      />
    </SimpleGateway>
  );
};

UploadImageInput.defaultProps = {
  fileName: '',
  s3URL: null,
  pageId: 0,
  fileIsUploading: false,
};

UploadImageInput.propTypes = {
  fileName: PropTypes.string,
  pageId: PropTypes.number,
  projectId: PropTypes.string.isRequired,
  fileIdentifier: PropTypes.string.isRequired,
  onSetPageBackgroundImage: PropTypes.func.isRequired,
  s3URL: PropTypes.string,
  fileIsUploading: PropTypes.bool,
};

export default UploadImageInput;
