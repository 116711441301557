import React from 'react';
import PropTypes from 'prop-types';

import { StyledButton } from './CopyButton.style';

const CopyButton = ({
  projectId,
  pageId,
  buttonId,
  onCopyButton,
  controlType,
}) => {
  const copyButton = e => {
    e.stopPropagation();
    onCopyButton({
      projectId,
      pageId,
      buttonId,
      controlType,
    });
  };

  return (
    <StyledButton
      name="copy"
      bordered
      inverted
      color="grey"
      onClick={copyButton}
      title="Copy"
    />
  );
};

CopyButton.defaultProps = {
  controlType: 'button',
};

CopyButton.propTypes = {
  projectId: PropTypes.string.isRequired,
  pageId: PropTypes.number.isRequired,
  buttonId: PropTypes.number.isRequired,
  onCopyButton: PropTypes.func.isRequired,
  controlType: PropTypes.string,
};

export default CopyButton;
