import { connect } from 'react-redux';
import isNil from 'lodash/isNil';

import { deviceSelector } from 'helpers/selectors';
import updateSettingsActionCreators from 'pages/Device/updateSettingsActionCreators';
import PharosTimeAdjustmentV1 from './PharosTimeAdjustmentV1';

export const mapStateToProps = (state, ownProps) => {
  const { resourceId } = ownProps;
  const {
    reportedWithMeta: {
      clk: {
        now: currentTime,
        tz: offset,
      } = {},
    } = {},
  } = deviceSelector(state)(resourceId);

  return {
    shouldDisplay: !isNil(currentTime),
    resourceCurrentTime: currentTime,
    resourceOffset: offset,
  };
};

const mapDispatchToProps = {
  onSetTimestamp: updateSettingsActionCreators.setTimestamp,
};

export default connect(mapStateToProps, mapDispatchToProps)(PharosTimeAdjustmentV1);
