import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getDisplayName } from 'helpers/sharedMethods';
import { Button } from 'components/atomic';
import ConfirmModal from 'components/elements/ConfirmModal/ConfirmModal';

const withTransferFile = WrappedComponent => class extends Component {

  static displayName = `WithTransferFile(${getDisplayName(WrappedComponent)})`;

  static propTypes = {
    deviceId: PropTypes.string.isRequired,
    item: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      fileTypeId: PropTypes.string.isRequired,
    }).isRequired,
    accept: PropTypes.object.isRequired,
    onTriggerFileTransfer: PropTypes.func.isRequired,
  }

  constructor() {
    super();

    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleConfirm() {
    const {
      deviceId,
      item: { name: fileName, fileTypeId },
      onTriggerFileTransfer,
    } = this.props;

    onTriggerFileTransfer(deviceId, { fileName, fileTypeId });
  }

  render() {
    const { item: { name: fileName, fileTypeId }, accept } = this.props;
    const { [fileTypeId]: { nm: fileTypeName } } = accept;

    const trigger = <WrappedComponent title="Transfer" />;

    return (
      <ConfirmModal
        trigger={trigger}
        cancelButton="Cancel"
        confirmButton={<Button negative primary={false}>Transfer File</Button>}
        content={(
          <div>
            <p>
              Are you sure you want to transfer the
              &nbsp;<strong>{fileTypeName.toLowerCase()}</strong>&nbsp; file
              &nbsp;<strong>{fileName}</strong>&nbsp; to the device?
            </p>
          </div>
        )}
        header="Confirm file transfer"
        onConfirm={this.handleConfirm}
      />
    );
  }

};

export default withTransferFile;
