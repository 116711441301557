import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { permissionsOrRedirect, frontendPermissions } from 'gateway';
import { singleProjectSelector } from 'helpers/selectors';
import { actionCreators } from './utilities';
import AutoRenewSuspended from './AutoRenewSuspended';

export const mapStateToProps = (state, { match }) => {
  const { id: siteId } = match.params;

  const { showAutoRenewalSuspended = false } = singleProjectSelector(state, siteId);

  return {
    showAutoRenewalSuspended,
    siteId,
  };
};


export const mapDispatchToProps = dispatch => ({
  onSiteRenewSuspended: args => dispatch(
    actionCreators.renewSuspendedSite(args),
  ),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  permissionsOrRedirect(frontendPermissions.AUTO_RENEW_SUSPENDED),
)(AutoRenewSuspended);
