import merge from 'lodash/merge';
import normalize from 'json-api-normalizer';

import URLParameterConverter from 'helpers/URLParameterConverter';
import { API_DOMAIN, TENANT, TOKEN } from 'storageConst';
import { API_URL } from './requests';

export const API_HEADERS = {
  'Content-Type': 'application/vnd.api+json',
  Accept: 'application/vnd.api+json',
  'cache-control': 'no-cache',
  'X-Client-Version': process.env.REACT_APP_VERSION,
};

function authorizationHeader() {
  const token = localStorage.getItem(TOKEN);
  return token ? { Authorization: `Bearer ${token}` } : {};
}

export function headers() {
  return Object.assign({}, API_HEADERS, authorizationHeader());
}

export function fulfill(isOk, data) {
  if (isOk) {
    return Promise.resolve(data);
  }

  return Promise.reject(data);
}

export function parse(response) {
  const isOk = response.ok;
  const contentType = response.headers.get('content-type');

  if (contentType && contentType.match(/application\/(vnd\.api\+)?json/)) {
    return response.json().then(fulfill.bind(null, isOk));
  }

  return fulfill(isOk, {});
}

export function saveNewToken(response) {
  const token = response
    && response.meta
    && response.meta.token;
  if (token) {
    localStorage.setItem(TOKEN, token);
    delete response.meta.token;
  }
}

export function prepareResponse(endpoint, json) {
  saveNewToken(json);

  const { data, links, identifier } = json;
  const response = data
    ? { ...normalize(json, { endpoint }) }
    : { ...json };

  return {
    response: { ...response, data, links, identifier },
  };
}

export function prepareErrorResponse(error) {
  saveNewToken(error);
  return { error };
}

export function callPath(endpoint = '/', options, { apiUrlOverride, urlParams = {} } = {}) {
  const apiUrl = apiUrlOverride || localStorage.getItem(API_DOMAIN) || API_URL;
  const tenant = (localStorage.getItem(TENANT) || '');
  const allParams = merge({ tenant }, urlParams);
  const convertedParams = new URLParameterConverter(allParams).toString;
  let url = `${apiUrl}${endpoint}`;
  if (convertedParams) {
    url += `${url.indexOf('?') !== -1 ? '&' : '?'}${convertedParams}`;
  }

  return fetch(url, options)
    .then(parse)
    .then(prepareResponse.bind(null, endpoint))
    .catch(prepareErrorResponse);
}
