import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { getDisplayName } from 'helpers/sharedMethods';

const withToggleUserSuspension = WrappedComponent => {
  const WithToggleUserSuspension = ({ onToggleSuspendUser, item: { id, suspended } }) => {
    const [suspending, setSuspending] = useState(false);
    const handleClick = async () => {
      setSuspending(true);

      await new Promise(resolveForm => onToggleSuspendUser({ id, suspended, resolveForm }));

      setSuspending(false);
    };

    const title = suspended ? 'Un-suspend' : 'Suspend';

    return (
      <WrappedComponent
        onClick={handleClick}
        disabled={suspending}
        title={title}
      />
    );
  };

  WithToggleUserSuspension.displayName = `WithToggleUserSuspension(${getDisplayName(WrappedComponent)})`;

  WithToggleUserSuspension.propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string.isRequired,
      suspended: PropTypes.bool.isRequired,
    }).isRequired,
    onToggleSuspendUser: PropTypes.func.isRequired,
  };

  return WithToggleUserSuspension;
};

export default withToggleUserSuspension;
