import React, { Component } from 'react';
import PropTypes from 'prop-types';

import formNames from 'components/form/formNames';
import { StyledEditSiteNotes } from './EditSiteNotes.style';

class EditSiteNotes extends Component {

  constructor() {
    super();

    this.handleUpdateSiteNotes = this.handleUpdateSiteNotes.bind(this);
  }

  handleUpdateSiteNotes(values) {
    const { siteId, onUpdateSiteNotes } = this.props;

    return new Promise((resolveForm, rejectForm) => {
      onUpdateSiteNotes({
        values: { id: siteId, ...values },
        resolveForm,
        rejectForm,
      });
    });
  }

  render() {
    const { notes, passesPermissions } = this.props;
    const name = 'notes';
    const form = `${formNames.changeSiteProperty}.${name}`;

    return (
      <StyledEditSiteNotes
        name={name}
        type="text"
        value={notes}
        onSubmit={this.handleUpdateSiteNotes}
        form={form}
        locked={!passesPermissions}
        allowCancelOnPristine
      />
    );
  }

}

EditSiteNotes.defaultProps = {
  notes: undefined,
};

EditSiteNotes.propTypes = {
  siteId: PropTypes.string.isRequired,
  onUpdateSiteNotes: PropTypes.func.isRequired,
  passesPermissions: PropTypes.bool.isRequired,
  notes: PropTypes.string,
};

export default EditSiteNotes;
