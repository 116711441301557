import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import { isNil } from 'lodash';

const MultiSiteName = ({ visibleName }) => {
  if (isNil(visibleName)) {
    return <Loader active inline size="tiny" />;
  }

  return <p>{visibleName}</p>;
};

MultiSiteName.defaultProps = {
  visibleName: null,
};

MultiSiteName.propTypes = {
  visibleName: PropTypes.string,
};

export default MultiSiteName;
