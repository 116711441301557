import React from 'react';
import { TextArea } from 'semantic-ui-react';

import InputTextAreaField from '../InputTextAreaField/InputTextAreaField';
import FormField from '../FormField/FormField';

const TextAreaField = props => (
  <FormField {...props}>
    <InputTextAreaField {...props} />
  </FormField>
);

TextAreaField.propTypes = {
  ...TextArea.propTypes,
};

export default TextAreaField;
