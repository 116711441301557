import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { handleIncorrectZeros } from './utilities';

import { StyledTimeInput } from './TimeInput.style';

export default function TimeInput({ onChange, value, maxDetail, format, name, ...restProps }) {
  useEffect(() => {
    handleIncorrectZeros({
      containerId: `container-${name}`,
    });
  }, [name]);

  return (
    <div id={`container-${name}`}>
      <StyledTimeInput
        autoFocus
        onChange={onChange}
        value={value}
        maxDetail={maxDetail}
        format={format}
        clearIcon={null}
        disableClock
        {...restProps}
      />
    </div>
  );
}

TimeInput.defaultProps = {
  value: null,
  maxDetail: 'second',
  format: 'HH:mm:ss',
};

TimeInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  maxDetail: PropTypes.string,
  format: PropTypes.string,
};
