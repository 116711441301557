import React from 'react';
import PropTypes from 'prop-types';

import ToggleFieldForm from 'components/form/fieldForms/ToggleFieldForm/ToggleFieldFormContainer';

const IsDemo = props => {
  const handleToggle = value => {
    const { siteId, onUpdateSiteDemo } = props;

    return new Promise((resolveForm, rejectForm) => {
      onUpdateSiteDemo({
        values: { id: siteId, ...value },
        resolveForm,
        rejectForm,
      });
    });
  };

  const { property, isDemo } = props;
  const form = `editSiteProperty.${property}`;

  return (
    <ToggleFieldForm
      name={property}
      onSubmit={handleToggle}
      form={form}
      initialValues={{
        [property]: isDemo,
      }}
    />
  );
};

IsDemo.propTypes = {
  siteId: PropTypes.string.isRequired,
  onUpdateSiteDemo: PropTypes.func.isRequired,
  property: PropTypes.string.isRequired,
  isDemo: PropTypes.bool.isRequired,
};

export default IsDemo;
