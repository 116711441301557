import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { formValueSelector } from 'redux-form';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import formNames from 'components/form/formNames';

import { extractTenantData } from '../../../../../SiteActivityLoggingTab/components/LogsFilterParams/utilities';
import { actionCreators, selectors } from '../../../../utilities';
import VoucherGeneration from './VoucherGeneration';

export const mapStateToProps = state => {
  const voucherGenerationForm = formValueSelector(formNames.voucherGeneration);

  const { tenant } = extractTenantData(state);

  return {
    isLoading: selectors.isLoadingVoucherSelector(state),
    plans: selectors.subscriptionPlansSelector(state),
    tenant: tenant || 'sixeye',
    subscriptionPlanCode: voucherGenerationForm(state, 'subscriptionPlanCode'),
    subscriptionLength: voucherGenerationForm(state, 'subscriptionLength'),
    description: voucherGenerationForm(state, 'description'),
    generatedVoucher: selectors.generatedVoucherSelector(state),
    selectedTenant: selectors.selectedTenantSelector(state),
  };
};

const mapDispatchToProps = {
  onGenerateVoucher: actionCreators.generateVoucher,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  chainedLoaderWithSaga(() => actionCreators.fetchSubscriptionPlans()),
  chainedLoaderWithSaga(() => actionCreators.clearGeneratedVoucher()),
)(VoucherGeneration);
