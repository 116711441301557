import truncate from 'lodash/truncate';
import pluralize from 'pluralize';
import { DateTime } from 'luxon';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';

import { PROJECT_SUITES_FIELD } from 'helpers/selectors';
import ApiRequestBuilder from 'helpers/ApiRequestBuilder';

export const terms = {
  MEMBER: 'member',
  OTHER: 'other',
  DEVICE: 'device',
};

export const ealing = { latitude: 51.511990, longitude: -0.304405 };

class ProjectSuiteModel {

  constructor(projectSuite, user, self = false) {
    this.id = projectSuite.id;
    this.name = projectSuite.name;
    this.createdAt = projectSuite.createdAt;
    this.updatedAt = projectSuite.updatedAt;
    this.serverLocation = {
      latitude: projectSuite.latitude,
      longitude: projectSuite.longitude,
      timezoneId: projectSuite.timezoneId,
    };
    this.geoAddress = projectSuite.geoAddress;
    this.notes = projectSuite.notes;
    this.usersCount = projectSuite.usersCount;
    this.projectsCount = projectSuite.projectsCount;
    this.logicalDevicesCount = projectSuite.logicalDevicesCount;
    this.onlineDevices = projectSuite.onlineDevices;
    this.offlineDevices = projectSuite.offlineDevices;
    this.unassociatedDevices = projectSuite.unassociatedDevices;
    this.devicesForUpgrade = projectSuite.devicesForUpgrade;
    this.primaryContactPerson = projectSuite.primaryContactPerson;
    this.primaryContactEmail = projectSuite.primaryContactEmail;
    this.primaryContactPhone = projectSuite.primaryContactPhone;
    this.technicalContactEmail = projectSuite.technicalContactEmail;
    this.subscriptionContactEmail = projectSuite.subscriptionContactEmail;
    this.commercialContactEmail = projectSuite.commercialContactEmail;
    this.dateBlockingStartTime = projectSuite.dateBlockingStartTime;

    this.currentUser = self && user;
  }

  get locationLoaded() {
    const { latitude, longitude, timezoneId } = this.serverLocation;

    return !isUndefined(latitude) && !isUndefined(longitude) && !isUndefined(timezoneId);
  }

  get locationSet() {
    const { latitude, longitude } = this.serverLocation;

    return this.locationLoaded && !isNull(latitude) && !isNull(longitude);
  }

  get timezoneSet() {
    const { timezoneId } = this.serverLocation;

    return this.locationLoaded && !isNull(timezoneId);
  }

  get latitude() {
    const { locationSet, serverLocation: { latitude } } = this;

    if (locationSet) {
      return parseFloat(latitude);
    }

    return ealing.latitude;
  }

  get longitude() {
    const { locationSet, serverLocation: { longitude } } = this;

    if (locationSet) {
      return parseFloat(longitude);
    }

    return ealing.longitude;
  }

  get timezoneId() {
    const { timezoneSet, serverLocation: { timezoneId } } = this;

    if (timezoneSet) {
      return timezoneId;
    }

    return null;
  }

  get datetime() {
    if (this.timezoneSet) {
      return DateTime.local().setZone(this.timezoneId);
    }

    return null;
  }

  get datetimeNow() {
    if (this.timezoneSet) {
      return DateTime.local().setZone(this.timezoneId);
    }

    return DateTime.local().setZone('utc');
  }

  get visibleName() {
    return truncate(this.name, {
      length: 30,
      separator: ' ',
      omission: ' ...',
    });
  }

  get team() {
    const { usersCount } = this;

    return pluralize(terms.MEMBER, usersCount, true);
  }

  get dateCreated() {
    if (!this.createdAt) {
      return null;
    }

    return DateTime.fromISO(this.createdAt).toLocaleString({
      weekday: 'short',
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  }

  static requestBody(values) {
    return new ApiRequestBuilder(PROJECT_SUITES_FIELD)
      .setAttributes(values);
  }

}

export default ProjectSuiteModel;
