import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import iro from '@jaames/iro';

const ColorWheel = ({ changeColor, changeColorFinal, formColor, ...rest }) => {
  const colorPicker = React.useRef(null);
  const el = React.createRef();

  const layout = [
    {
      component: iro.ui.Wheel,
    },
  ];

  const wheelProps = {
    ...rest,
    layout,
  };

  useEffect(() => {
    if (!el.current) {
      return;
    }

    if (!colorPicker.current) {
      colorPicker.current = new iro.ColorPicker(el.current, wheelProps);

      if (!colorPicker.current) {
        return;
      }

      colorPicker.current.on('input:change', color => {
        colorPicker.current.color.value = 100;
        changeColor(color);
      });

      colorPicker.current.on('input:end', color => {
        changeColorFinal(color);
      });
    }
  });

  useEffect(() => {
    colorPicker.current.setColors([formColor]);
  }, [formColor]);

  return <div ref={el} />;
};

ColorWheel.defaultProps = {
  changeColor: () => null,
  changeColorFinal: () => null,
  formColor: null,
};

ColorWheel.propTypes = {
  changeColor: PropTypes.func,
  changeColorFinal: PropTypes.func,
  formColor: PropTypes.object,
};

export default ColorWheel;
