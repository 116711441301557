import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { siteActionsListSelector, allActionsTypeSelector } from 'helpers/selectors';

import { selectors as schedulingSelectors } from 'application/tenant/console/site/components/structural/SiteSchedulingTab/utilities';

import {
  extractActions,
  createOptionList,
} from 'application/tenant/console/site/components/structural/SiteSchedulingTab/components/AutomatedOperations/components/AddAutomatedOperation/components/ActionsStep/components/AvailableActions/utilities';
import SelectSiteAction from './SelectSiteAction';

export const mapStateToProps = (state, { match }) => {
  const { id: projectId } = match.params;
  const getSiteActions = siteActionsListSelector(state)(projectId);
  const allActionTypes = allActionsTypeSelector(state);
  const siteActionList = extractActions(getSiteActions);
  const optionList = createOptionList(siteActionList, allActionTypes);

  return {
    projectId,
    availableActions: optionList,
    isLoading: schedulingSelectors.fetchingAvailableActionsSelector(state),
  };
};


export default compose(
  withRouter,
  connect(mapStateToProps),
)(SelectSiteAction);
