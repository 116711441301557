import styled from 'styled-components';
import { Header } from 'semantic-ui-react';

import { Button } from 'components/atomic';

export const StyledHeader = styled(Header)`
  &.ui.header {
    margin-top: 0.5em;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 0px;
`;

export default StyledHeader;
