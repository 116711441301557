export default class QuattroCreator {

  constructor(sixeyeFirmwareVersion, tabs) {
    this.model = 'Pathport QUATTRO';
    this.sixeyeFirmwareVersion = sixeyeFirmwareVersion;
    this.manufacturerSDK = 'pathway';
    this.tabs = tabs;
  }

}
