import React from 'react';
import PropTypes from 'prop-types';

import colors from 'themes/colors';
import { convertColorString } from 'helpers/sharedMethods';
import formNames from 'components/form/formNames';
import { pageNameFormFields } from './PageNameColorPickerModalForm/PageNameColorPickerModalForm';
import PageNameColorPickerModalForm from './PageNameColorPickerModalForm/PageNameColorPickerModalFormContainer';
import {
  StyledColorPreview,
  StyledColorPreviewWrapper,
} from './PageNameColorPicker.style';
import { convertObjectToRGB } from './utilities';

const PageNameColorPicker = ({
  projectId,
  pageId,
  onChangeColor,
  configure,
  nameColor,
}) => {
  if (!configure) {
    return null;
  }

  const handleColorChange = values => new Promise((resolveForm, rejectForm) => {
    onChangeColor({
      projectId,
      pageId,
      values,
      resolveForm,
      rejectForm,
    });
  });

  const nameColorObject = convertColorString(nameColor, colors.black);
  const triggerPreviewColor = convertObjectToRGB(nameColorObject);
  const trigger = (
    <StyledColorPreviewWrapper>
      <StyledColorPreview color={triggerPreviewColor} />
    </StyledColorPreviewWrapper>
  );

  return (
    <PageNameColorPickerModalForm
      trigger={trigger}
      onSubmit={handleColorChange}
      initialValues={{
        [pageNameFormFields.nameColor]: nameColorObject,
      }}
      form={`${formNames.pageNameColor}_${pageId}`}
    />
  );
};

PageNameColorPicker.defaultProps = {
  configure: false,
  nameColor: null,
  pageId: 0,
};

PageNameColorPicker.propTypes = {
  projectId: PropTypes.string.isRequired,
  pageId: PropTypes.number,
  configure: PropTypes.bool,
  nameColor: PropTypes.string,
  onChangeColor: PropTypes.func.isRequired,
};

export default PageNameColorPicker;
