import styled from 'styled-components';
import { lighten } from 'polished';
import get from 'lodash/get';

const StyledSixEyeVersionBanner = styled.div`
  padding: 20px;
  background: ${({ theme }) => lighten(0.3, get(theme, 'palette.primaryColor'))};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 25px;
  margin-top: 15px;
  border-radius: 3px;
`;

export default StyledSixEyeVersionBanner;
