import { call, put } from 'redux-saga/effects';
import isNull from 'lodash/isNull';

import { postPath } from 'data/api/requests';
import { processFinalFormResponse } from 'utilities/form';
import ApiRequestBuilder from 'helpers/ApiRequestBuilder';
import { RESET_PASSWORD_ACTION } from 'helpers/selectors';
import { TOKEN } from 'storageConst';
import { actionCreators as userActionCreators } from '../../../../../utilities/user';
import actionCreators from '../actionCreators';

function* resetPassword(action) {
  const {
    endpoint,
    passwordResetToken,
    values,
    resolveForm,
  } = action.payload;

  localStorage.setItem(TOKEN, passwordResetToken);

  const body = new ApiRequestBuilder(RESET_PASSWORD_ACTION)
    .setId(Date.now().toString())
    .setAttributes(values);

  const { response, error } = yield call(postPath, endpoint, body);
  if (error instanceof Error) { error.message = 'No connection to server. Try later.'; }

  const params = {
    response,
    error,
    resolveForm,
    successDisp: actionCreators.resetPasswordSuccess,
  };
  yield call(processFinalFormResponse, params);

  if (response) {
    const {
      meta: {
        [endpoint]: {
          meta: {
            two_factor_status: twoFactorStatus,
          },
        },
      },
    } = response;

    if (isNull(twoFactorStatus)) {
      yield put(userActionCreators.fetchCurrentUser());
    }
  }
}

export default resetPassword;
