import { compose } from 'redux';
import { connect } from 'react-redux';

import pollingActionCreators from './withPollingActionCreators';
import withPollingSaga from './withPollingSaga';

const mapDispatchToProps = {
  ...pollingActionCreators,
};

export default (...methods) => compose(
  connect(null, mapDispatchToProps),
  withPollingSaga(...methods),
);
