import React from 'react';
import { Field as FinalFormField } from 'react-final-form';

import FormField from 'components/form/FormField/FormField';

const withFinalFormField = WrappedComponent => ({ render: renderProp, ...props }) => {
  const render = fieldProps => (
    <FormField {...fieldProps}>
      <WrappedComponent {...fieldProps} />
    </FormField>
  );

  return (
    <FinalFormField
      render={render}
      {...props}
    />
  );
};

export default withFinalFormField;
