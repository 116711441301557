import { compose } from 'redux';
import { connect } from 'react-redux';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import triggersActionCreators from 'pages/Device/triggersActionCreators';
import { fetchDeviceTimelines } from 'application/tenant/console/device/utilities/actionCreators/deviceTimelines';
import { fetchDeviceScenes } from 'application/tenant/console/device/utilities/actionCreators/deviceScenes';
import { fetchDeviceGroups } from 'application/tenant/console/device/utilities/actionCreators/deviceGroups';
import { getListOfOptions } from './utilities';
import DropdownMultiActionField from './DropdownMultiActionField';

export const mapStateToProps = (state, ownProps) => {
  const {
    logicalDeviceId: deviceId,
    value: userSelectedTriggers,
    uiType,
  } = ownProps;

  const { optionList, loading } = getListOfOptions(state, uiType, deviceId, userSelectedTriggers);

  return {
    uiType,
    deviceId,
    optionList,
    loading,
  };
};

export default compose(
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    ({ deviceId, uiType }) => uiType === 'triggerIds' && triggersActionCreators.fetchDeviceTriggers(deviceId),
  ),
  chainedLoaderWithSaga(
    ({ deviceId, uiType }) => uiType === 'timelineIds' && fetchDeviceTimelines(deviceId),
  ),
  chainedLoaderWithSaga(
    ({ deviceId, uiType }) => uiType === 'sceneIds' && fetchDeviceScenes(deviceId),
  ),
  chainedLoaderWithSaga(
    ({ deviceId, uiType }) => uiType === 'groupIds' && fetchDeviceGroups(deviceId),
  ),
)(DropdownMultiActionField);
