import constants from 'dispatcherConst';

const removeResourceId = (resourceId, resourceType) => ({
  type: constants.REMOVE_RESOURCE_ID_PERMISSIONS_TABLE_REQUEST,
  payload: {
    resourceId,
    resourceType,
  },
});

export default removeResourceId;
