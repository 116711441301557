import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { PermissionsSwitch, Gateway, frontendPermissions } from 'gateway';

import { ActionsListPlaceholder } from './components';
import { ActionsList, ActionItem, StyledIcon, Container, EmptyMessage } from './AvailableActions.style';

function AvailableActions({
  isLoading,
  selectedDevice,
  availableActions,
  onActionSelected,
  resourceType,
}) {

  const permission = useMemo(() => {
    if (resourceType === 'projectSuite') {
      return frontendPermissions.PROJECT_SUITE_TASKS_ADD;
    }

    return frontendPermissions.ADD_TASK;
  }, [resourceType]);

  if (!selectedDevice) {
    return (
      <Container flex>
        <EmptyMessage>
          You must select a device.
        </EmptyMessage>
      </Container>
    );
  }

  if (isLoading) {
    return <ActionsListPlaceholder />;
  }

  if (isEmpty(availableActions)) {
    return (
      <Container flex>
        <EmptyMessage>
          No Actions found for the selected resource.
        </EmptyMessage>
      </Container>
    );
  }

  const handleSelectedAction = actionId => {
    const selectedAction = availableActions.find(({ key }) => key === actionId);

    onActionSelected({
      selectedDevice,
      selectedAction: {
        id: selectedAction.key,
        name: selectedAction.text,
      },
    });
  };

  const handleClick = actionId => {
    handleSelectedAction(actionId);
  };

  const handleKeyPress = (e, actionId) => {
    if (e.key === 'Enter') {
      handleSelectedAction(actionId);
    }
  };

  return (
    <PermissionsSwitch>
      <Gateway condition={permission}>
        <ActionsList divided verticalAlign="middle">
          {availableActions.map(({ key, text }, index) => (
            <ActionItem
              key={key}
              tabIndex={index + 1}
              onKeyPress={e => handleKeyPress(e, key)}
              onClick={() => handleClick(key)}
            >
              <p>{text}</p>

              <StyledIcon name="arrow right" color="grey" />
            </ActionItem>
          ))}
        </ActionsList>
      </Gateway>
      <Gateway fallback>
        <Container flex>
          <EmptyMessage>
            You <strong>do not have the required permissions</strong> to use Device Actions.
          </EmptyMessage>
        </Container>
      </Gateway>
    </PermissionsSwitch>
  );
}

AvailableActions.defaultProps = {
  selectedDevice: null,
  availableActions: [],
  resourceType: 'project',
};

AvailableActions.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  selectedDevice: PropTypes.object,
  availableActions: PropTypes.array,
  onActionSelected: PropTypes.func.isRequired,
  resourceType: PropTypes.oneOf(['project', 'projectSuite']),
};

export default AvailableActions;
