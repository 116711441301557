import { lang } from '../../../../../../constants';

function translateParam(type, paramKey, listObject, transObject) {
  if (type in listObject) {
    const { params } = listObject[type];

    if (Array.isArray(params)) {
      const labelsArr = params.filter(item => item.key === paramKey);
      const { label: labelKey } = labelsArr[0];

      const text = transObject[labelKey]
        ? transObject[labelKey][lang]
        : labelKey;
      return text;
    }
  }

  return 'Undefined';
}

export default translateParam;
