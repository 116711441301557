import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  USERS_FIELD,
  PROJECTS_FIELD,
  permissionsProjectOpenSelector,
  permissionsProjectUsersLinkSelector,
} from 'helpers/selectors';
import { addPagination } from 'helpers/paginationHelpers';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { PermissionsGridWrapper } from '../../../../../../../../../../components';
import permissionsActionCreators from '../../../../../../../../../../utilities/permissions/permissionsActionCreators';
import { ContextProjectPermissionsTree } from '../../tree/PermissionsTree';
import { ContextProjectPermissionsGridHeaders } from '../../headers/PermissionsGridHeaders';
import { ContextProjectPermissionsGrid } from '../../grid/PermissionsGrid';

export const mapStateToProps = (state, { projectId }) => ({
  open: permissionsProjectOpenSelector(state, { projectId }),
  usersEndpoint: permissionsProjectUsersLinkSelector(state)(projectId),
  resourceId: projectId,
  resourceTitle: 'All sites',
  PermissionsTree: ContextProjectPermissionsTree,
  PermissionsGridHeaders: ContextProjectPermissionsGridHeaders,
  PermissionsGrid: ContextProjectPermissionsGrid,
});

const mapDispatchToProps = {
  onToggleOpen: projectId => permissionsActionCreators.toggleResourceOpen(
    PROJECTS_FIELD,
    projectId,
  ),
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: USERS_FIELD, included: true },
      ({ resourceId, usersEndpoint }) => permissionsActionCreators.fetchProjectUsers(
        resourceId,
        usersEndpoint,
        {
          fields: {
            [USERS_FIELD]: ['firstName', 'lastName', 'email'],
          },
          sort: 'firstName',
        },
      ),
    ),
  ),
)(PermissionsGridWrapper);
