import merge from 'lodash/merge';

export const setZoneState = (state, action) => {
  const { response: { values: { zoneId, value } } } = action.payload;
  const level = value ? 100 : 0;

  return merge(
    {},
    state,
    {
      zones: {
        [zoneId]: {
          attributes: {
            level,
          },
        },
      },
    },
  );
};

export default setZoneState;
