import React from 'react';
import PropTypes from 'prop-types';

import { FILE_UPLOADS_FIELD } from 'helpers/selectors';
import NewUpload from 'components/NewUpload/NewUploadContainer';
import DataTable from 'components/DataTable/DataTableContainer';
import { supportedTypesType } from 'data/types';
import FileActionLinks from './FileActionLinks/FileActionLinks';

const FilesSection = ({ online, deviceId, title, labels, accept }) => (
  <DataTable
    header={title}
    labels={labels}
    actionLinks={FileActionLinks}
    createElement={<NewUpload disabled={!accept} />}
    pagination={{
      type: FILE_UPLOADS_FIELD,
      included: true,
      args: { deviceId },
    }}
    deviceId={deviceId}
    accept={accept}
    online={online}
  />
);

FilesSection.defaultProps = {
  title: '',
  accept: null,
};

FilesSection.propTypes = {
  online: PropTypes.bool.isRequired,
  deviceId: PropTypes.string.isRequired,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  title: PropTypes.string,
  accept: supportedTypesType,
};

export default FilesSection;
