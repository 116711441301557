import isEmpty from 'lodash/isEmpty';

class ApiRequestBuilder {

  constructor(type) {
    this.data = { type };
  }

  setId(value) {
    this.data.id = value;

    return this;
  }

  setAttributes(values) {
    if (!values) return this;

    const { id, ...attributes } = values;

    if (id) {
      this.setId(id);
    }

    if (!isEmpty(attributes)) {
      this.data.attributes = attributes;
    }

    return this;
  }

  setRelationship(name, attributes) {
    const { relationships = {} } = this.data;

    relationships[name] = { ...attributes };
    this.data.relationships = relationships;

    return this;
  }

}

export default ApiRequestBuilder;
