import React from 'react';
import PropTypes from 'prop-types';

import { labelsType } from 'data/types';
import DataTable from 'components/DataTable/DataTableContainer';
import { TIMELINES_FIELD, JAMBOX_TIMELINES_FIELD } from 'helpers/selectors';
import RefreshInterface from 'components/RefreshInterface/RefreshInterfaceContainer';
import { JamboxTimelineRow } from './components';

const JamboxTimelinesSection = ({
  title,
  labels,
  deviceId,
  ...rest
}) => {
  const refreshUI = (
    <RefreshInterface
      deviceId={deviceId}
      dataId="timelines"
    />
  );

  return (
    <DataTable
      header={title}
      labels={labels}
      createElement={refreshUI}
      pagination={{
        type: TIMELINES_FIELD,
        included: true,
        componentName: JAMBOX_TIMELINES_FIELD,
      }}
      customTableRow={JamboxTimelineRow}
      highlightUpdates
      {...rest}
    />
  );
};
JamboxTimelinesSection.defaultProps = {
  title: null,
  deviceId: null,
};

JamboxTimelinesSection.propTypes = {
  labels: labelsType.isRequired,
  title: PropTypes.string,
  deviceId: PropTypes.string,
};

export default JamboxTimelinesSection;
