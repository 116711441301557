import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header } from 'semantic-ui-react';

import { StyledProjectsTabWrapper, StyledGrid } from './PermissionsProjectsTab.style';
import PermissionsProjectWrapper from './PermissionsProjectWrapper/PermissionsProjectWrapperContainer';
import AddProjectButton from './AddProjectButton/AddProjectButtonContainer';
import ProjectsTable from './ProjectsTable/ProjectsTableContainer';

const PermissionsProjectsTab = ({ projectIds }) => (
  <StyledProjectsTabWrapper>
    <StyledGrid>
      <Grid.Row>
        <Grid.Column>
          <Header as="h1">Site permissions</Header>
          <Header as="h3">Add a site to view and edit its permissions</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          {projectIds && projectIds.map(projectId => (
            <PermissionsProjectWrapper key={projectId} projectId={projectId} />
          ))}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <AddProjectButton />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <ProjectsTable />
        </Grid.Column>
      </Grid.Row>
    </StyledGrid>
  </StyledProjectsTabWrapper>
);

PermissionsProjectsTab.defaultProps = {
  projectIds: null,
};

PermissionsProjectsTab.propTypes = {
  projectIds: PropTypes.array,
};

export default PermissionsProjectsTab;
