import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isObject from 'lodash/isObject';

import { simpleValueTypes } from 'data/types';
import { getDisplayName } from 'helpers/sharedMethods';

const extractValue = WrappedComponent => {
  class ExtractValue extends Component {

    static displayName = `ExtractValue(${getDisplayName(WrappedComponent)})`;

    static defaultProps = {
      value: null,
    };

    static propTypes = {
      value: PropTypes.oneOfType([
        ...simpleValueTypes,
        PropTypes.shape({
          value: PropTypes.oneOfType(simpleValueTypes),
          timestamp: PropTypes.number,
        }),
      ]),
    };

    getAdditionalProps() {
      const { value: simpleOrObject } = this.props;

      if (simpleOrObject && isObject(simpleOrObject)) {
        const { value: simple, timestamp } = simpleOrObject;

        return {
          value: simple,
          lastUpdate: timestamp,
        };
      }

      return { value: simpleOrObject };
    }

    render() {
      const additionalProps = this.getAdditionalProps();

      return <WrappedComponent {...this.props} {...additionalProps} />;
    }

  }

  return ExtractValue;
};

export default extractValue;
