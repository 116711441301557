import {
  fetchReverseGeocoding,
  fetchReverseGeocodingSuccess,
} from './fetchReverseGeocoding';
import unableToFetchLocation from './unableToFetchLocation';
import {
  updateSiteSuccess,
  updateSiteFailure,
} from './updateSite';
import updateSiteLocation from './updateSiteLocation';
import updateSiteName from './updateSiteName';
import updateSitePrimaryContact from './updateSitePrimaryContact';
import updateSitePrimaryContactEmail from './updateSitePrimaryContactEmail';
import updateSitePrimaryContactTelephone from './updateSitePrimaryContactTelephone';
import updateSiteNotes from './updateSiteNotes';
import updateSiteStreet from './updateSiteStreet';
import updateSiteCity from './updateSiteCity';
import updateSiteCountry from './updateSiteCountry';
import updateSitePostcode from './updateSitePostcode';
import updateSiteDate from './updateSiteDate';
import updateSiteDemo from './updateSiteDemo';
import updateSiteConstruction from './updateSiteConstruction';
import updateSiteCommercialContactEmail from './updateSiteCommercialContactEmail';
import updateSiteSubscriptionContactEmail from './updateSiteSubscriptionContactEmail';
import updateSiteTechnicalContactEmail from './updateSiteTechnicalContactEmail';
import updateSitePoints from './updateSitePoints';
import updateSiteSubscriptionPlanCode from './updateSiteSubscriptionPlanCode';
import updateSiteAutoRenewYears from './updateSiteAutoRenewYears';
import updateSiteAutoRenewComment from './updateSiteAutoRenewComment';
import updateSiteDateBlockingStartTime from './updateSiteDateBlockingStartTime';

export default {
  fetchReverseGeocoding,
  fetchReverseGeocodingSuccess,
  unableToFetchLocation,
  updateSiteSuccess,
  updateSiteFailure,
  updateSiteLocation,
  updateSiteName,
  updateSitePrimaryContact,
  updateSitePrimaryContactEmail,
  updateSitePrimaryContactTelephone,
  updateSiteNotes,
  updateSiteStreet,
  updateSiteCity,
  updateSiteCountry,
  updateSitePostcode,
  updateSiteDate,
  updateSiteDemo,
  updateSiteConstruction,
  updateSiteCommercialContactEmail,
  updateSiteSubscriptionContactEmail,
  updateSiteTechnicalContactEmail,
  updateSitePoints,
  updateSiteSubscriptionPlanCode,
  updateSiteAutoRenewYears,
  updateSiteAutoRenewComment,
  updateSiteDateBlockingStartTime,
};
