import { put, call, select } from 'redux-saga/effects';

import endpoints from 'data/api/endpoints';
import { putPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import { currentUserIdSelector } from 'helpers/selectors';
import UserModel from 'models/UserModel';
import { ERROR_SERVER_CONNECTION } from 'data/notifications/notificationsConst';
import { actionCreators as applicationUserActionCreators } from '../../../../../utilities/user';
import actionCreators from '../actionCreators';

function* editProfile(action) {
  const { attributes, ...rest } = action.payload;
  const id = yield select(currentUserIdSelector);
  const body = UserModel.requestBody({ id, ...attributes });
  const endpoint = endpoints.USER(id);
  const { response, error } = yield call(putPath, endpoint, body);

  if (error instanceof Error) {
    error.message = ERROR_SERVER_CONNECTION;
  }

  const params = {
    response,
    error,
    successDisp: actionCreators.editProfileSuccess,
    failDisp: actionCreators.editProfileFailure,
    ...rest,
  };
  yield call(processCall, params);

  if (response) {
    yield put(applicationUserActionCreators.fetchCurrentUser());
  }
}

export default editProfile;
