import constants from 'dispatcherConst';

export const fetchProjectSuiteReverseGeocoding = (projectSuiteId, latitude, longitude) => ({
  type: constants.FETCH_PROJECT_SUITE_REVERSE_GEOCODING_REQUEST,
  payload: { projectSuiteId, latitude, longitude },
});

export const fetchProjectSuiteReverseGeocodingSuccess = (projectSuiteId, address) => ({
  type: constants.FETCH_PROJECT_SUITE_REVERSE_GEOCODING_SUCCESS,
  payload: { projectSuiteId, address },
});
