import { connect } from 'react-redux';
import { formValueSelector, change } from 'redux-form';

import formNames from 'components/form/formNames';

import { selectors, actionCreators } from '../../../../utilities';
import ConfigurationPanel from './ConfigurationPanel';

export const mapStateToProps = state => {
  const taskSchedulerForm = formValueSelector(formNames.taskScheduler);

  return {
    priority: taskSchedulerForm(state, 'priority'),
    priorityType: taskSchedulerForm(state, 'priorityType'),
    schedulerName: selectors.schedulerNameSelector(state),
    schedulerDescription: selectors.schedulerDescriptionSelector(state),
    schedulerType: selectors.schedulerTypeSelector(state),
    taskSchedulerMode: selectors.taskSchedulerModeSelector(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onPriorityChange: value => dispatch(change(formNames.taskScheduler, 'priority', value)),
  onPriorityTypeChange: value => dispatch(change(formNames.taskScheduler, 'priorityType', value)),
  onSchedulerNameChange: value => dispatch(actionCreators.setSchedulerName(value)),
  onSchedulerDescriptionChange: value => dispatch(actionCreators.setSchedulerDescription(value)),
  onSchedulerTypeChange: value => dispatch(actionCreators.setSchedulerType(value)),
  onClearSchedulerParams: () => dispatch(actionCreators.clearSchedulerParams()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationPanel);
