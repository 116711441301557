import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DropdownMultiActionField from 'application/tenant/console/site/components/structural/SiteSchedulingTab/components/AutomatedOperations/components/AddAutomatedOperation/components/ActionsStep/components/SelectedActions/components/ActionItem/components/GenerateActionForm/components/DropdownMultiActionField/DropdownMultiActionFieldContainer';
import DropdownActionField from 'application/tenant/console/site/components/structural/SiteSchedulingTab/components/AutomatedOperations/components/AddAutomatedOperation/components/ActionsStep/components/SelectedActions/components/ActionItem/components/GenerateActionForm/components/DropdownActionField/DropdownActionField';
import PercentageActionField from 'application/tenant/console/site/components/structural/SiteSchedulingTab/components/AutomatedOperations/components/AddAutomatedOperation/components/ActionsStep/components/SelectedActions/components/ActionItem/components/GenerateActionForm/components/PercentageActionField/PercentageActionField';
import StringActionField from 'application/tenant/console/site/components/structural/SiteSchedulingTab/components/AutomatedOperations/components/AddAutomatedOperation/components/ActionsStep/components/SelectedActions/components/ActionItem/components/GenerateActionForm/components/StringActionField/StringActionField';
import ToggleActionField from 'application/tenant/console/site/components/structural/SiteSchedulingTab/components/AutomatedOperations/components/AddAutomatedOperation/components/ActionsStep/components/SelectedActions/components/ActionItem/components/GenerateActionForm/components/ToggleActionField/ToggleActionField';
import TimeTextActionField from 'application/tenant/console/site/components/structural/SiteSchedulingTab/components/AutomatedOperations/components/AddAutomatedOperation/components/ActionsStep/components/SelectedActions/components/ActionItem/components/GenerateActionForm/components/TimeTextActionField/TimeTextActionField';
import SelectMultiSite from 'application/tenant/console/site/components/structural/SiteSchedulingTab/components/AutomatedOperations/components/AddAutomatedOperation/components/ActionsStep/components/SelectedActions/components/ActionItem/components/GenerateActionForm/components/SelectMultiSite/SelectMultiSiteEditContainer';
import { StyledFieldWrapper, StyledEditButton, StyledForm } from './InlineEditParameter.style';
import { translationLabels } from '../../../../constants';
import { uiElementTypes } from './constants';

class RenderInlineField extends Component {

  constructor(props) {
    super(props);
    this.renderFormField = this.renderFormField.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    const { value, submitting } = this.props;
    const { value: nextValue, submitting: nextSubmitting } = nextProps;

    return (value !== nextValue) || (submitting !== nextSubmitting);
  }

  renderFormField(param) {
    const lang = 'en';
    const { logicalDeviceId, value, projectId } = this.props;
    const {
      label,
      tip,
      key,
      regex,
      uiType,
      list,
      gteq,
      lteq,
      maxCount,
    } = param;
    const labelText = translationLabels[label]
      ? translationLabels[label][lang]
      : param.label;
    const tipText = translationLabels[tip]
      ? translationLabels[tip][lang]
      : param.label;
    const { uiElement } = param;
    const fieldProps = {
      key,
      name: key,
      label: labelText,
      editMode: true,
    };

    switch (uiElement) {
      case uiElementTypes.INPUT:
        return (
          <StringActionField
            {...fieldProps}
            tip={tipText}
            regex={regex}
            value={value}
          />
        );

      case uiElementTypes.TOGGLE_BUTTON:
        return (
          <ToggleActionField
            {...fieldProps}
            value={value}
          />
        );

      case uiElementTypes.DROPDOWN:
        return (
          <DropdownActionField
            {...fieldProps}
            uiType={uiType}
            list={list}
            gteq={gteq}
            lteq={lteq}
          />
        );

      case uiElementTypes.DROPDOWN_MULTI:
        return (
          <DropdownMultiActionField
            {...fieldProps}
            uiType={uiType}
            maxCount={maxCount}
            logicalDeviceId={logicalDeviceId}
            tip={tipText}
            value={value}
          />
        );

      case uiElementTypes.PERCENTAGE:
        return (
          <PercentageActionField
            {...fieldProps}
            tip={tipText}
            isPercentage
          />
        );

      case uiElementTypes.TIME:
        return (
          <TimeTextActionField
            {...fieldProps}
            label={labelText}
            tip={tipText}
            gteq={gteq}
            lteq={lteq}
          />
        );

      case uiElementTypes.PROJECT_SUITE_DROPDOWN:
        return (
          <SelectMultiSite
            {...fieldProps}
            label={labelText}
            tip={tipText}
            projectId={projectId}
          />
        );

      case uiElementTypes.NOT_RENDER:
        return (
          <StringActionField
            {...fieldProps}
            tip={tipText}
            regex={regex}
            value={value}
            gteq={gteq}
            lteq={lteq}
          />
        );

      default:
        return null;
    }
  }

  render() {
    const {
      param,
      handleSubmit,
      submitting,
    } = this.props;

    return (
      <StyledForm
        ref={node => { this.node = node; }}
        onSubmit={handleSubmit}
      >
        <StyledFieldWrapper>
          {this.renderFormField(param)}
        </StyledFieldWrapper>

        <StyledEditButton
          type="submit"
          className="basic"
          size="mini"
          icon="checkmark"
          loading={submitting}
        />
      </StyledForm>
    );
  }

}

RenderInlineField.defaultProps = {
  value: null,
  projectId: '',
  logicalDeviceId: null,
};

RenderInlineField.propTypes = {
  param: PropTypes.object.isRequired,
  logicalDeviceId: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  projectId: PropTypes.string,
};

export default RenderInlineField;
