import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const fetchSubscriptionPlans = () => ({
  type: constants.FETCH_SUBSCRIPTION_PLANS_REQUEST,
  payload: {
    endpoint: endpoints.SUBSCRIPTION_PLANS,
  },
});

export const fetchSubscriptionPlansSuccess = response => ({
  type: constants.FETCH_SUBSCRIPTION_PLANS_SUCCESS,
  payload: { response },
});

export const fetchSubscriptionPlansFailure = error => ({
  type: constants.FETCH_SUBSCRIPTION_PLANS_FAILED,
  payload: { error },
});
