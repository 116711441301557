import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import DeviceModel from 'models/DeviceModel';
import { Button } from 'components/atomic';
import { Tooltip } from 'components/elements';
import CheckboxField from 'components/form/CheckboxField/CheckboxField';
import { SimpleForm } from 'components/form/Form/Form';
import SettingConfirm from 'components/SettingConfirm/SettingConfirm';
import SettingCard from 'components/SettingCard/SettingCard';

export const formFields = {
  watchdog: 'watchdog',
};

function PharosWatchdogV1Form({
  title,
  resource,
  pollingRefreshing,
  handleSubmit,
  pristine,
  submitting,
}) {
  const { online } = resource;

  const triggerDisabled = !online || pollingRefreshing || pristine || submitting;
  const fieldDisabled = !online || pollingRefreshing || submitting;

  const trigger = <span><Button type="submit" primary disabled={triggerDisabled}>Commit</Button></span>;
  const confirm = (
    <SettingConfirm
      trigger={trigger}
      header="Commit changes"
      content={(
        <p>
          This will cause the device to go offline momentarily
          while it resets. Do you want to continue?
        </p>
      )}
      onConfirm={handleSubmit}
      size="mini"
    />
  );

  const checkbox = (
    <Field
      component={CheckboxField}
      type="checkbox"
      name={formFields.watchdog}
      id={formFields.watchdog}
      label="Enable watchdog"
      disabled={fieldDisabled}
      indeterminate={!online}
    />
  );

  return (
    <SettingCard
      title={title}
      as={SimpleForm}
      trigger={confirm}
    >
      {online ? checkbox : (
        <Tooltip
          trigger={checkbox}
          content="Device is offline"
          position="top right"
        />
      )}
      <p>
        The hardware watchdog will reset the Controller automatically
        in case of a software crash as a result of either a bug or
        a random electromagnetic event such as a power brown-out
        or spike, nearby lightning strike or static discharge.
      </p>
    </SettingCard>
  );
}

PharosWatchdogV1Form.propTypes = {
  title: PropTypes.string.isRequired,
  resource: PropTypes.instanceOf(DeviceModel).isRequired,
  pollingRefreshing: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default PharosWatchdogV1Form;
