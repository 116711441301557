import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './PageDescription.style';

function PageDescription({ description }) {
  return (
    <Container>
      {description}
    </Container>
  );
}

PageDescription.propTypes = {
  description: PropTypes.string.isRequired,
};

export default PageDescription;
