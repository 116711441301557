import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withTheme } from 'styled-components';

const FaviconsProvider = ({ theme: { favicons } }) => (
  <Helmet>
    {favicons && favicons.map(({ href, sizes }) => (
      <link rel="shortcut icon" href={href} type="image/x-icon" sizes={sizes} key={sizes} />
    ))}
  </Helmet>
);

FaviconsProvider.defaultProps = {
  theme: {},
};

FaviconsProvider.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(FaviconsProvider);
