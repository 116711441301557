import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reset } from 'redux-form';

import formNames from 'components/form/formNames';

import { companiesSelector } from 'helpers/selectors';
import { actionCreators, selectors } from '../../../../utilities';

import VoucherTenantSelect from './VoucherTenantSelect';

export const mapStateToProps = state => {
  const companies = companiesSelector(state);

  return {
    companies,
    selectedTenant: selectors.selectedTenantSelector(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onSetSelectedTenant: value => dispatch(actionCreators.setSelectedTenant(value)),
  onClearSelectedTenant: () => dispatch(actionCreators.clearSelectedTenant()),
  onClearSelectedVoucher: () => dispatch(actionCreators.clearSelectedVoucher()),
  onClearGeneratedVoucher: () => dispatch(actionCreators.clearGeneratedVoucher()),
  onResetVoucherGenerationForm: () => dispatch(reset(formNames.voucherGeneration)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(VoucherTenantSelect);
