import { Menu as SemanticUIMenu } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import get from 'lodash/get';

import { mediaMax } from 'themes/mixins';
import filterProps from 'helpers/filterProps';

const FilteredProps = filterProps([
  'itemSelected',
])(SemanticUIMenu);

const TableMenu = styled(FilteredProps).attrs({
  secondary: true,
})`
  &.ui.vertical.menu {
    width: auto;
  }

  && .tableToolbarButton {
    pointer-events: none;
    color: rgba(0, 0, 0, .3) !important;
  }

  ${({ itemSelected, theme }) => itemSelected && `
    && .tableToolbarButton {
      color: ${get(theme, 'palette.primaryColor')} !important;
      pointer-events: auto; 
    }
  `}

  & .item:nth-child(2):not(.right) {
    pointer-events: none !important;
    color: rgba(0, 0, 0, .3) !important;
  }

  && .item:nth-child(2):not(.right) {
    pointer-events: none !important;
    color: rgba(0, 0, 0, .3) !important;
  }

  ${({ itemSelected, theme }) => itemSelected && `
    && .item:nth-child(2):not(.right) {
      color: ${get(theme, 'palette.primaryColor')} !important;
      pointer-events: auto !important;
    }
  `}
`;

const FilteredPropsMenuItem = filterProps([
  'isActionLinksContainer',
  'noPadding',
])(SemanticUIMenu.Item);

TableMenu.Item = styled(FilteredPropsMenuItem).attrs({
  fitted: false,
})`
  &&&& + &&&&:not(.right) {
    margin-right: 0;
  }

  ${({ noPadding }) => noPadding && css`
      padding: 0 !important;
  `}

  ${({ isActionLinksContainer }) => isActionLinksContainer && css`
    margin-bottom: 0px !important;

    & .item {
      padding-bottom: 0 !important;
      padding-left: 0 !important;
      margin-left: 0 !important;
    }

    & .item:first-child {
      padding-left: 0px !important;
    }

    h4 {
      margin-bottom: 0 !important;
    } 

    ${mediaMax.sm`
      display: flex !important;
      flex-direction: column !important;
      align-items: flex-start !important;
      padding-right: 0 !important;
      margin-right: 0 !important;

      & .item {
        padding-left: 0 !important;
        padding-top: 8px !important;
        margin-left: 0 !important;
      }

      & .item:first-child {
        padding-left: 0 !important;
      }
    `};  
  
  `} 
`;

export default TableMenu;
