import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { StyledDiv } from './SelectedProject.style';

const SelectedProject = ({
  selectedProjectId,
  resourceName,
  changeFieldValue,
  actionTypeDeviceIdKey,
  registerField,
}) => {
  useEffect(() => {
    changeFieldValue(`resourceId${actionTypeDeviceIdKey}`, selectedProjectId);
    registerField(`resourceId${actionTypeDeviceIdKey}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <StyledDiv><strong>Resource:</strong> {resourceName}</StyledDiv>;
};

SelectedProject.defaultProps = {
  selectedProjectId: null,
  resourceName: null,
  changeFieldValue: () => null,
  actionTypeDeviceIdKey: '',
  registerField: () => null,
};

SelectedProject.propTypes = {
  selectedProjectId: PropTypes.string,
  resourceName: PropTypes.string,
  changeFieldValue: PropTypes.func,
  actionTypeDeviceIdKey: PropTypes.string,
  registerField: PropTypes.func,
};

export default SelectedProject;
