import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import {
  AUTOMATED_FUNCTIONS_FIELD,
} from 'helpers/selectors';


export const fetchAutomatedFunctions = (automatedOperationId, urlParams) => ({
  type: constants.FETCH_AUTOMATED_FUNCTIONS_REQUEST,
  payload: {
    endpoint: endpoints.AUTOMATED_FUNCTIONS(automatedOperationId),
    resourceType: AUTOMATED_FUNCTIONS_FIELD,
    automatedOperationId,
    urlParams,
  },
});

export const fetchAutomatedFunctionsSuccess = response => ({
  type: constants.FETCH_AUTOMATED_FUNCTIONS_SUCCESS,
  payload: {
    response,
    resourceType: AUTOMATED_FUNCTIONS_FIELD,
  },
});

export const fetchAutomatedFunctionsFailure = error => ({
  type: constants.FETCH_AUTOMATED_FUNCTIONS_FAILED,
  payload: { error },
});
