import React from 'react';
import { Grid } from 'semantic-ui-react';

import ProjectsPermissionsTable from 'components/PermissionsTable/PermissionsTableProjectContainer';
import ProjectSuitesPermissionsTable from 'components/PermissionsTable/PermissionsTableProjectSuiteContainer';
import LogicalDevicesPermissionsTable from 'components/PermissionsTable/PermissionsTableLogicalDeviceContainer';
import UsersPermissionsTable from 'components/PermissionsTable/PermissionsTableUsersContainer';

function PortalPermissionsWrapper() {
  const commonProps = {
    parentResourceId: 'portal-projects',
    childResourceIds: ['portal-project-suites', 'portal-devices', 'portal-users'],
    context: 'tenant',
    limitedScope: ['tenant'],
    isOpen: true,
  };

  return (
    <>
      <Grid.Row>
        <Grid.Column>
          <ProjectsPermissionsTable
            projectId="portal-projects"
            childResourceIds={['portal-project-suites', 'portal-devices', 'portal-users']}
            {...commonProps}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <ProjectSuitesPermissionsTable
            projectSuiteId="portal-project-suites"
            {...commonProps}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <LogicalDevicesPermissionsTable
            projectId="portal-projects"
            logicalDeviceId="portal-devices"
            {...commonProps}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <UsersPermissionsTable
            userId="portal-users"
            {...commonProps}
          />
        </Grid.Column>
      </Grid.Row>
    </>
  );
}

export default PortalPermissionsWrapper;
