import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import {
  UNJOINED_REMOTE_DEVICES_FIELD,
} from 'helpers/selectors';


export const fetchUnjoinedRemoteDevices = deviceId => ({
  type: constants.FETCH_UNJOINED_REMOTE_DEVICES_REQUEST,
  payload: {
    endpoint: endpoints.UNJOINED_REMOTE_DEVICES(deviceId),
    resourceType: UNJOINED_REMOTE_DEVICES_FIELD,
    deviceId,
  },
});

export const fetchUnjoinedRemoteDevicesSuccess = response => ({
  type: constants.FETCH_UNJOINED_REMOTE_DEVICES_SUCCESS,
  payload: {
    response,
    resourceType: UNJOINED_REMOTE_DEVICES_FIELD,
  },
});

export const fetchUnjoinedRemoteDevicesFailure = error => ({
  type: constants.FETCH_UNJOINED_REMOTE_DEVICES_FAILED,
  payload: { error },
});
