import constants from 'dispatcherConst';

export const setRecurrenceParams = recurrenceParams => ({
  type: constants.SET_RECURRENCE_PARAMS,
  payload: {
    recurrenceParams,
  },
});

export default setRecurrenceParams;
