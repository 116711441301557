import React from 'react';
import PropTypes from 'prop-types';

import { ProjectPermissionsWrapper } from 'components/PermissionsTable/wrappers';

const ProjectPermissionsTab = ({ projectId }) => (
  <ProjectPermissionsWrapper
    projectId={projectId}
    forceOpen
  />
);

ProjectPermissionsTab.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default ProjectPermissionsTab;
