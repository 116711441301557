const transformPercentageToRGB = ({ r, g, b }) => {
  const red = parseFloat((r * 255) / 100).toFixed(0);
  const green = parseFloat((g * 255) / 100).toFixed(0);
  const blue = parseFloat((b * 255) / 100).toFixed(0);

  return {
    r: red === 'NaN' ? 0 : red,
    g: green === 'NaN' ? 0 : green,
    b: blue === 'NaN' ? 0 : blue,
  };
};

export default transformPercentageToRGB;
