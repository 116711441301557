const SCHEDULER_TYPES = {
  NONE: 'NONE',
  DATES: 'DATES',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY',
};

const DEFAULT_SCHEDULER_TYPE = SCHEDULER_TYPES.DATES;

const TASK_SCHEDULER_MODE = {
  DEFAULT: 'DEFAULT',
  ADD_SCHEDULER: 'ADD_SCHEDULER',
  EDIT_SCHEDULER: 'EDIT_SCHEDULER',
};

const RESOURCE_TYPE_PROPERTY_NAME = {
  projectSuite: 'projectSuiteId',
  project: 'projectId',
};

export {
  SCHEDULER_TYPES,
  DEFAULT_SCHEDULER_TYPE,
  TASK_SCHEDULER_MODE,
  RESOURCE_TYPE_PROPERTY_NAME,
};
