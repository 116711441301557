import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Segment } from 'semantic-ui-react';

import ProjectSuitePermissionsTable from 'components/PermissionsTable/PermissionsTableProjectSuiteContainer';

import AddProjectSuiteButton from '../PermissionsProjectSuitesTab/AddProjectSuiteButton/AddProjectSuiteButtonContainer';
import ProjectSuitesTable from '../PermissionsProjectSuitesTab/ProjectSuitesTable/ProjectSuitesTableContainer';

import { StyledProjectSuitesTabWrapper, StyledGrid } from '../PermissionsProjectSuitesTab/PermissionsProjectSuitesTab.style';

const PermissionsProjectSuitesTab = ({ projectSuiteIds }) => (
  <StyledProjectSuitesTabWrapper>
    <StyledGrid>
      <Grid.Row>
        <Grid.Column>
          <Header as="h1">Multi-site permissions</Header>
          <Header as="h3">Add a Multi-site to view and edit its permissions</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          {projectSuiteIds && projectSuiteIds.map(projectSuiteId => (
            <Segment key={projectSuiteId}>
              <ProjectSuitePermissionsTable
                projectSuiteId={projectSuiteId}
                showHeader
              />
            </Segment>
          ))}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <AddProjectSuiteButton />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <ProjectSuitesTable />
        </Grid.Column>
      </Grid.Row>
    </StyledGrid>
  </StyledProjectSuitesTabWrapper>
);

PermissionsProjectSuitesTab.defaultProps = {
  projectSuiteIds: null,
};

PermissionsProjectSuitesTab.propTypes = {
  projectSuiteIds: PropTypes.array,
};

export default PermissionsProjectSuitesTab;
