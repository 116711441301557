import React from 'react';
import isObject from 'lodash/isObject';

import removeBraces from './removeBraces';
import replaceWordsInString from './replaceWordsInString';
import replaceWordsOutputHTML from './replaceWordsOutputHTML';

const processLogText = (item, setHTML = false) => {
  if (isObject(item) && item.text) {
    const { text } = item;
    const matches = text.match(/\{(.*?)\}/g);
    const mapObject = {};

    if (matches.length > 0) {
      matches.forEach(key => {
        mapObject[key] = item.text_data[removeBraces(key)]
          && item.text_data[removeBraces(key)].name;
      });

      if (setHTML) {
        return <span>{replaceWordsOutputHTML(text, mapObject)}</span>;
      }

      return replaceWordsInString(text, mapObject);
    }

    return text;
  }

  return null;
};

export default processLogText;
