import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Form } from 'semantic-ui-react';
import pluralize from 'pluralize';
import moment from 'moment';

import { SelectedTimesView } from 'components/core/TimePicker/components';
import { WEEK_DAYS } from '../../../../../ScheduleStep/components/RecurrenceViews/utils';
import { StyledLabel, StyledRow, StyledColumn } from '../../ScheduleReview.style';

function WeeklyRecurrenceReview({
  recurrenceParams,
}) {
  const {
    everyNWeeks,
    days,
    startDate,
    startTimes,
    forNTimes,
    endTime,
  } = recurrenceParams || {};

  const renderEndingCriteria = () => {
    if (forNTimes) {
      return (
        <>
          <StyledLabel>
            Ending after
          </StyledLabel>
          {` ${forNTimes} ${pluralize('occurrence', forNTimes)}`}
        </>
      );
    }

    if (endTime) {
      return (
        <>
          <StyledLabel>
            Ending on
          </StyledLabel>
          {` ${endTime && moment(endTime).format('MMM Do YYYY')}`}
        </>
      );
    }

    return (
      <>
        <StyledLabel>
          Ending on
        </StyledLabel>
        {' Never ends'}
      </>
    );
  };

  const weekdays = useMemo(() => {
    if (!days) {
      return [];
    }

    return days.map(
      day => WEEK_DAYS.find(
        item => item.key === day,
      ).text,
    );
  }, [days]);

  return (
    <Form>
      <Grid>
        <StyledRow noPaddingBottom>
          <Grid.Column computer={4} mobile={8}>
            <Form.Group id="recur-every">
              <Form.Field>
                <StyledLabel>
                  Recur every
                </StyledLabel>
                {` ${everyNWeeks} ${pluralize('week', everyNWeeks)}`}
              </Form.Field>
            </Form.Group>
          </Grid.Column>
          <Grid.Column computer={4} mobile={8}>
            <Form.Group id="starting-on">
              <Form.Field>
                <StyledLabel>
                  Starting on
                </StyledLabel>
                {` ${startDate && moment(startDate).format('MMM Do YYYY')}`}
              </Form.Field>
            </Form.Group>
          </Grid.Column>
          <StyledColumn computer={4} mobile={16}>
            <Form.Group id="ending-criteria">
              <Form.Field>
                {renderEndingCriteria()}
              </Form.Field>
            </Form.Group>
          </StyledColumn>
        </StyledRow>
        <StyledRow noPaddingTop noPaddingBottom>
          <Grid.Column computer={16} mobile={16}>
            <Form.Group id="weekdays" widths="equal">
              <Form.Field>
                <StyledLabel>
                  Only on
                </StyledLabel>
                {` ${weekdays && weekdays.join(', ')}`}
              </Form.Field>
            </Form.Group>
          </Grid.Column>
        </StyledRow>
        <StyledRow noPaddingTop noPaddingBottom>
          <Grid.Column computer={16} mobile={16}>
            <Form.Group id="times" widths="equal">
              <Form.Field>
                <StyledLabel>
                  Times
                </StyledLabel>
                <SelectedTimesView
                  selectedTimes={startTimes}
                  renderLabel={false}
                  clearable={false}
                />
              </Form.Field>
            </Form.Group>
          </Grid.Column>
        </StyledRow>
      </Grid>
    </Form>
  );
}

WeeklyRecurrenceReview.propTypes = {
  recurrenceParams: PropTypes.object.isRequired,
};

export default WeeklyRecurrenceReview;
