import constants from 'dispatcherConst';

export const updateProjectSuite = params => ({
  type: constants.UPDATE_PROJECT_SUITE_REQUEST,
  payload: {
    params,
  },
});

export const updateProjectSuiteSuccess = response => ({
  type: constants.UPDATE_PROJECT_SUITE_SUCCESS,
  payload: {
    response,
    message: response.message,
  },
});

export const updateProjectSuiteFailure = error => ({
  type: constants.UPDATE_PROJECT_SUITE_FAILED,
  payload: { error },
});

export default updateProjectSuite;
