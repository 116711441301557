import { createSelector } from 'reselect';
import build from 'redux-object';

import { dataSelector, LOGS_ACTION_TYPES_FIELD } from 'helpers/selectors';

const logsActionTypesSelector = createSelector(
  dataSelector,
  data => build(data, LOGS_ACTION_TYPES_FIELD, null, { ignoreLinks: true }) || [],
);

export default logsActionTypesSelector;
