import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';

const updateInitialValues = (action, initializeFn) => {
  if (isEmpty(action) || isEmpty(action.params)) {
    return null;
  }

  if (!Array.isArray(action.params)) {
    return null;
  }

  const { params } = action;
  const initialValues = params.reduce((acc, item) => {
    const { key, default: defaultValue, dependentOn } = item;

    if ((defaultValue || isBoolean(defaultValue)) && !dependentOn) {
      return { ...acc, [key]: defaultValue };
    }

    return acc;
  }, {});

  if (isEmpty(initialValues)) {
    return null;
  }

  return initializeFn({ ...initialValues });
};

export default updateInitialValues;
