import isEmpty from 'lodash/isEmpty';

const getPermissionsCountByRecipientId = ({
  recipientId,
  permissionNames,
  cumulatedPermissions,
}) => {
  if (!permissionNames || isEmpty(permissionNames)) {
    return {};
  }

  if (!cumulatedPermissions || isEmpty(cumulatedPermissions)) {
    return {};
  }

  const initial = {
    allPermissionsCount: permissionNames.length,
    grantedCount: 0,
    notGrantedCount: 0,
  };

  const all = permissionNames.reduce((acc, permissionName) => {
    if (cumulatedPermissions[permissionName][recipientId]) {
      acc.grantedCount += 1;
    } else {
      acc.notGrantedCount += 1;
    }

    return acc;
  }, initial);

  return all;
};

export default getPermissionsCountByRecipientId;
