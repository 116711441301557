import React from 'react';
import PropTypes from 'prop-types';

import { getDisplayName, checkPermissionByTypeAndResource } from 'helpers/sharedMethods';

const userHasPermissionByTypeOrSuperadmin = (
  permission, permissionType, resourceId,
) => WrappedComponent => {
  const UserHasPermissionByTypeOrSuperadmin = ({
    userPermissions,
    superadmin,
    ...rest
  }) => {
    const userHasPermission = checkPermissionByTypeAndResource(
      permissionType, resourceId, userPermissions, permission,
    );
    const userHasPermissionOrSuperadmin = superadmin || userHasPermission;

    const props = {
      ...rest,
      userHasPermissionOrSuperadmin,
    };

    return <WrappedComponent {...props} />;
  };

  UserHasPermissionByTypeOrSuperadmin.defaultProps = {
    userPermissions: [],
    superadmin: false,
  };

  UserHasPermissionByTypeOrSuperadmin.propTypes = {
    userPermissions: PropTypes.array,
    superadmin: PropTypes.bool,
  };

  UserHasPermissionByTypeOrSuperadmin.displayName = `UserHasPermissionByTypeOrSuperadmin(${getDisplayName(WrappedComponent)})`;

  return UserHasPermissionByTypeOrSuperadmin;
};

export default userHasPermissionByTypeOrSuperadmin;
