import { compose } from 'redux';
import { connect } from 'react-redux';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import {
  currentUserSelector,
  COMPANIES_FIELD,
  SHOW_COMPANIES_COMPONENT,
} from 'helpers/selectors';
import { addPagination } from 'helpers/paginationHelpers';
import redirectIfNotFound from 'helpers/redirectIfNotFound';
import { actionCreators } from '../utilities';
import Companies from './Companies';

export const mapStateToProps = state => ({
  currentUser: currentUserSelector(state),
});

export default compose(
  connect(mapStateToProps),
  redirectIfNotFound('currentUser.superadmin', false),
  chainedLoaderWithSaga(
    addPagination(
      {
        resourceType: COMPANIES_FIELD,
        included: true,
        componentName: SHOW_COMPANIES_COMPONENT,
      },
      actionCreators.fetchCompanies({
        fields: {
          companies: [
            'name',
            'subdomain',
            'manufacturer',
            'origins',
          ],
        },
        sort: 'name',
      }),
    ),
  ),
)(Companies);
