import React from 'react';
import PropTypes from 'prop-types';

import ProjectModel from 'models/ProjectModel';
import { StyledProjectsRow } from './ProjectsRow.style';

const ProjectsRow = ({
  item: project,
  item: { id },
  paths,
  onAddProjectId,
  ...rest
}) => {
  const handleAddProjectId = () => onAddProjectId(id, paths);
  const projectModel = new ProjectModel(project);

  return (
    <StyledProjectsRow item={projectModel} {...rest} onClick={handleAddProjectId} />
  );
};

ProjectsRow.propTypes = {
  onAddProjectId: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  paths: PropTypes.array.isRequired,
};

export default ProjectsRow;
