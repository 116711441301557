import { compose } from 'redux';

import { connect } from 'react-redux';

import { permissionsOrRedirect, frontendPermissions } from 'gateway';

import InviteUser from './InviteUser';
import inviteUserActionCreators from './inviteUserActionCreators';

const mapDispatchToProps = {
  onInviteUser: inviteUserActionCreators.inviteUser,
};

const InviteUserProjectSuiteContainer = compose(
  connect(null, mapDispatchToProps),
  permissionsOrRedirect(frontendPermissions.PROJECT_SUITE_USERS_ADD),
)(InviteUser);

export default InviteUserProjectSuiteContainer;
