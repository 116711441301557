import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';

import { singleProjectSelector } from 'helpers/selectors';
import { validateOnSubmit } from 'components/form/formConfigs';
import EnableSiteModalForm from './EnableSiteModalForm';

export const mapStateToProps = (state, { match }) => {
  const { id: projectId } = match.params;
  const { trialPeriodDays } = singleProjectSelector(state, projectId);

  return {
    trialPeriodDays,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  reduxForm({
    enableReinitialize: true,
    ...validateOnSubmit,
  }),
)(EnableSiteModalForm);
