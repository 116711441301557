import { compose } from 'redux';
import { connect } from 'react-redux';

import { permissionsOrRedirect, frontendPermissions } from 'gateway';
import AddProject from './AddProject';
import projectActionCreators from '../projectActionCreators';

const mapDispatchToProps = {
  onAddProject: projectActionCreators.addProject,
};

export default compose(
  connect(null, mapDispatchToProps),
  permissionsOrRedirect(frontendPermissions.ADD_PROJECT),
)(AddProject);
