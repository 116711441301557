import styled, { css } from 'styled-components';
import { Pagination } from 'semantic-ui-react';

import colors from 'themes/colors';
import { mediaMax } from 'themes/mixins';
import { ThemedLinkButton } from 'components/atomic';

export const PaginationControlsWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
`;

export const PageSizeControl = styled(ThemedLinkButton)`
  height: 100%;
  padding: 1em;
  border-right: 2px solid ${colors.mercury};
  ${({ active }) => active && 'font-weight: bold;'};

  &:last-of-type {
    border-right: none;
  }
`;

export const PageNumberControls = styled(Pagination)`
  &.ui.pagination {

    &.menu .item.disabled:focus {
      outline: none;
    }

    & .item[type="pageItem"],
    & .item[type="ellipsisItem"] {
      ${mediaMax.sm`display: none;`};
    }

    ${({ hidePageNumbers }) => hidePageNumbers && css`
        & .item[type="pageItem"],
        & .item[type="ellipsisItem"] {
          display: none;
        }
        `} 

    & .item:focus {
      outline: none;
    }
  }
`;
