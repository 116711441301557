
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { RecipientLabel } from './components';
import { StyledTableColumn } from './TableHeader.style';

function TableHeader({
  recipients,
  selectedRecipients,
  context,
  resourceId,
  resourceType,
  recipientType,
  limitedScope,
  limitedFor,
  copyRecipientId,
  onChangeRecipients,
  onCopyPermissions,
  onPastePermissions,
}) {
  const recipientsMap = useMemo(() => {
    if (isEmpty(recipients)) {
      return {};
    }

    return recipients.reduce((acc, recipient) => {
      acc[recipient.key] = recipient;

      return acc;
    }, {});
  }, [recipients]);

  const handleRemoveRecipient = useCallback(removedRecipientId => {
    const newSelectedRecipients = selectedRecipients.filter(
      recipientId => recipientId !== removedRecipientId,
    );

    onChangeRecipients(newSelectedRecipients);
  }, [onChangeRecipients, selectedRecipients]);

  const copyRecipientName = useMemo(() => {
    if (!copyRecipientId || !recipientsMap) {
      return null;
    }

    const copyRecipient = recipientsMap[copyRecipientId];

    const { text, metadata } = copyRecipient || {};
    const { recipientName } = metadata || {};

    return recipientName || text;
  }, [copyRecipientId, recipientsMap]);

  return (
    <thead>
      <tr>
        <StyledTableColumn permissionNameColumn>&nbsp;</StyledTableColumn>
        {selectedRecipients.map(key => (
          <StyledTableColumn key={key} recipientColumn>
            <RecipientLabel
              context={context}
              resourceId={resourceId}
              resourceType={resourceType}
              recipientType={recipientType}
              limitedScope={limitedScope}
              limitedFor={limitedFor}
              recipient={recipientsMap[key]}
              copyRecipientId={copyRecipientId}
              copyRecipientName={copyRecipientName}
              onRemoveRecipient={handleRemoveRecipient}
              onCopyPermissions={onCopyPermissions}
              onPastePermissions={onPastePermissions}
            />
          </StyledTableColumn>
        ))}
      </tr>
    </thead>
  );
}

TableHeader.defaultProps = {
  context: null,
  selectedRecipients: [],
  recipients: [],
  copyRecipientId: null,
  recipientType: 'user',
  limitedScope: [],
  limitedFor: ['user'],
};

TableHeader.propTypes = {
  context: PropTypes.string,
  resourceId: PropTypes.string.isRequired,
  resourceType: PropTypes.oneOf([
    'project',
    'project_suite',
    'logical_device',
    'user',
    'tenant',
  ]).isRequired,
  selectedRecipients: PropTypes.arrayOf(PropTypes.string),
  recipientType: PropTypes.string,
  recipients: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      metadata: PropTypes.object,
    }).isRequired,
  ),
  copyRecipientId: PropTypes.string,
  limitedScope: PropTypes.arrayOf(
    PropTypes.string,
  ),
  limitedFor: PropTypes.arrayOf(
    PropTypes.string,
  ),
  onChangeRecipients: PropTypes.func.isRequired,
  onCopyPermissions: PropTypes.func.isRequired,
  onPastePermissions: PropTypes.func.isRequired,
};

export default TableHeader;
