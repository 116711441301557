import { select, put } from 'redux-saga/effects';

import actionCreators from 'application/tenant/components/structural/ControlUI/utilities/actionCreators';
import selectors from 'application/tenant/components/structural/ControlUI/utilities/selectors';
import { copiedButtonSelector } from '../selectors';
import { getButtonContent, updateControlPanelObject } from './utilities';

function* pasteButton(action) {
  const { projectId, pageId, buttonId, controlType, ...rest } = action.payload;

  const {
    projectId: copiedProjectId,
    pageId: copiedPageId,
    buttonId: copiedButtonId,
    controlType: copiedControlType,
  } = yield select(copiedButtonSelector);

  if (controlType !== copiedControlType) {
    return null;
  }

  if (projectId === copiedProjectId) {
    const projectControlUI = (yield select(selectors.projectControlUISelector))(projectId);

    const buttonContent = getButtonContent(
      projectControlUI,
      copiedPageId,
      copiedButtonId,
      controlType,
    );

    const updatedControlPanelObject = updateControlPanelObject({
      projectControlUI,
      pageId,
      buttonId,
      buttonContent,
      controlType,
    });

    yield put(actionCreators.updateControlUI({
      ...updatedControlPanelObject,
      ...rest,
    }));
  }

  return null;
}

export default pasteButton;
