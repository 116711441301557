import { connect } from 'react-redux';

import { uploadsProjectStatsSelector } from 'helpers/uploadsSelectors';
import UploadsStats from './UploadsStats';

const mapStateToProps = (state, { project }) => (
  uploadsProjectStatsSelector(state)(project)
);

export default connect(mapStateToProps)(UploadsStats);
