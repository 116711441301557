import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import FeedbackMessage from './FormFeedback.style';

const FormFeedback = ({ items, visible, ...rest }) => {
  const renderFeedbackMessage = content => (
    <FeedbackMessage key={content} content={content} {...rest} />
  );

  const isArray = Array.isArray(items);

  if (!visible) {
    return null;
  }

  return (
    <Fragment>
      {isArray && visible
        ? items.map(item => (item ? renderFeedbackMessage(item) : null))
        : renderFeedbackMessage(visible && items)
      }
    </Fragment>
  );
};

FormFeedback.defaultProps = {
  items: null,
};

FormFeedback.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.arrayOf(PropTypes.string).isRequired,
  ]),
  visible: PropTypes.bool.isRequired,
};

export default FormFeedback;
