import { compose } from 'redux';
import { connect } from 'react-redux';

import { actionTypeSelector } from 'helpers/selectors';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import tasksActionCreators from 'application/tenant/utilities/tasks/tasksActionCreators';
import triggersActionCreators from 'pages/Device/triggersActionCreators';
import { fetchDeviceTimelines } from 'application/tenant/console/device/utilities/actionCreators/deviceTimelines';
import { fetchDeviceScenes } from 'application/tenant/console/device/utilities/actionCreators/deviceScenes';
import { fetchDeviceGroups } from 'application/tenant/console/device/utilities/actionCreators/deviceGroups';
import {
  checkIfFetchResource,
  getDeviceResource,
} from './utilities';
import InlineEditParameter from './InlineEditParameter';
import {
  fetchTriggersActionTypes,
  fetchTimelinesActionTypes,
  fetchGroupsActionTypes,
  fetchScenesActionTypes,
  fetchConditionsArray,
} from './constants';

export const mapStateToProps = (state, ownProps) => {
  const {
    paramKey,
    typeId,
    logicalDeviceId,
    value,
  } = ownProps;

  const action = actionTypeSelector(state)(typeId);
  const { params } = action;

  let tableDropdownData = false;
  if (checkIfFetchResource(typeId, paramKey, fetchConditionsArray)) {
    tableDropdownData = getDeviceResource(state, paramKey, logicalDeviceId, value);
  }

  const filterParam = params && Array.isArray(params)
    ? params.filter(obj => obj.key === paramKey)
    : undefined;
  const param = (Array.isArray(filterParam) && filterParam[0]) || { uiElement: undefined };

  return {
    param,
    tableDropdownData,
    typeId,
  };
};

const mapDispatchToProps = {
  onEditAction: tasksActionCreators.editAction,
};

export default compose(
  chainedLoaderWithSaga(
    ({ logicalDeviceId, typeId, paramKey }) => checkIfFetchResource(
      typeId, paramKey, fetchTriggersActionTypes,
    ) && triggersActionCreators.fetchDeviceTriggers(logicalDeviceId),
    ({ logicalDeviceId, typeId, paramKey }) => checkIfFetchResource(
      typeId, paramKey, fetchTimelinesActionTypes,
    ) && fetchDeviceTimelines(logicalDeviceId),
    ({ logicalDeviceId, typeId, paramKey }) => checkIfFetchResource(
      typeId, paramKey, fetchGroupsActionTypes,
    ) && fetchDeviceGroups(logicalDeviceId),
    ({ logicalDeviceId, typeId, paramKey }) => checkIfFetchResource(
      typeId, fetchScenesActionTypes, paramKey,
    ) && fetchDeviceScenes(logicalDeviceId),
  ),
  connect(mapStateToProps, mapDispatchToProps),
)(InlineEditParameter);
