import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import uuidv4 from 'uuid/v4';
import constants from 'dispatcherConst';
import { settingTypes } from 'helpers/sharedVariables';
import SettingModel from 'models/SettingModel';
import { postPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import pollingActionCreators from 'helpers/withConfigurablePolling/pollingActionCreators';
import deviceActionCreators from 'pages/Project/deviceActionCreators';
import { DEVICE_BEACON_REFRESH } from 'layouts/Pharos/components/PharosBeaconV1/PharosBeaconV1';
import { DEVICE_WATCHDOG_REFRESH } from 'layouts/Pharos/components/PharosWatchdogV1/PharosWatchdogV1';
import { DEVICE_DISABLE_REFRESH } from 'layouts/Pathway/components/PathwayDisableClockEventsV1/PathwayDisableClockEventsV1';
import {
  pollingCurrentValueSelector,
  pollingRefreshingSelector,
} from 'helpers/selectors';
import { stopRefreshProcess } from 'helpers/withConfigurablePolling/pollingSaga';
import updateSettingsActionCreators from './updateSettingsActionCreators';

export const BEACON_TIMEOUT = 5;
export const WATCHDOG_TIMEOUT = 30;
export const DISABLE_TIMEOUT = 30;
export const DEVICE_CURRENT_TIME_PATH = 'clk.now';

export const bodyParam = (setting, value) => ({
  setting,
  meta: {
    value,
  },
});

export function* toggleBeacon(action) {
  const {
    endpoint,
    params: {
      values: { beacon },
      rejectForm,
      resolveForm,
    },
  } = action.payload;
  const body = SettingModel.requestBody(bodyParam(settingTypes.BEACON, beacon));
  const { response, error } = yield call(postPath, endpoint, body);

  const params = {
    response,
    error,
    rejectForm,
    resolveForm,
    successDisp: updateSettingsActionCreators.toggleBeaconSuccess,
    failDisp: updateSettingsActionCreators.toggleBeaconFailure,
  };
  yield call(processCall, params);
  if (!response) return;

  yield put(pollingActionCreators.fieldUpdate(
    settingTypes.BEACON,
    DEVICE_BEACON_REFRESH,
    {
      timeout: BEACON_TIMEOUT,
      currentValue: !beacon,
    },
  ));
  yield put(pollingActionCreators.setRefreshRate(
    DEVICE_BEACON_REFRESH,
    { value: 1 },
  ));
}

export function* handleBeaconStatus(action) {
  const beaconSetting = settingTypes.BEACON;

  const isBeaconRefreshing = yield select(
    pollingRefreshingSelector,
    beaconSetting,
  );
  if (!isBeaconRefreshing) return;

  const { response } = action.payload;
  const beacon = get(response, 'data.attributes.beacon');
  if (isNil(beacon)) return;

  const currentBeacon = yield select(
    pollingCurrentValueSelector,
    beaconSetting,
  );

  if (currentBeacon !== beacon) {
    yield call(stopRefreshProcess, beaconSetting);
  }
}

export function* toggleWatchdog(action) {
  const {
    endpoint,
    params: {
      values: {
        watchdog,
      },
      rejectForm,
      resolveForm,
    },
  } = action.payload;

  const body = SettingModel.requestBody(bodyParam(settingTypes.WATCHDOG, watchdog));
  const { response, error } = yield call(postPath, endpoint, body);

  const params = {
    response,
    error,
    rejectForm,
    resolveForm,
    successDisp: updateSettingsActionCreators.toggleWatchdogSuccess,
    failDisp: updateSettingsActionCreators.toggleWatchdogFailure,
  };
  yield call(processCall, params);
  if (!response) return;

  yield put(pollingActionCreators.fieldUpdate(
    settingTypes.WATCHDOG,
    DEVICE_WATCHDOG_REFRESH,
    {
      timeout: WATCHDOG_TIMEOUT,
      currentValue: !watchdog,
    },
  ));
  yield put(pollingActionCreators.setRefreshRate(
    DEVICE_WATCHDOG_REFRESH,
    { value: 1 },
  ));
}

export function* handleWatchdogStatus(action) {
  const watchdogSetting = settingTypes.WATCHDOG;

  const isWatchdogRefreshing = yield select(
    pollingRefreshingSelector,
    watchdogSetting,
  );
  if (!isWatchdogRefreshing) return;

  const { response } = action.payload;
  const watchdog = get(response, 'data.attributes.reported.hw.wd');
  if (isNil(watchdog)) return;

  const currentWatchdog = yield select(
    pollingCurrentValueSelector,
    watchdogSetting,
  );

  if (currentWatchdog !== watchdog) {
    yield call(stopRefreshProcess, watchdogSetting);
  }
}

export function* toggleDisable(action) {
  const {
    endpoint,
    params: {
      values: {
        disable,
      },
      rejectForm,
      resolveForm,
    },
  } = action.payload;

  const body = SettingModel.requestBody(bodyParam(settingTypes.DISABLE, disable));
  const { response, error } = yield call(postPath, endpoint, body);

  const params = {
    response,
    error,
    rejectForm,
    resolveForm,
    successDisp: updateSettingsActionCreators.toggleDisableSuccess,
    failDisp: updateSettingsActionCreators.toggleDisableFailure,
  };
  yield call(processCall, params);
  if (!response) return;

  yield put(pollingActionCreators.fieldUpdate(
    settingTypes.DISABLE,
    DEVICE_DISABLE_REFRESH,
    {
      timeout: DISABLE_TIMEOUT,
      currentValue: !disable,
    },
  ));
  yield put(pollingActionCreators.setRefreshRate(
    DEVICE_DISABLE_REFRESH,
    { value: 1 },
  ));
}

export function* handleDisableStatus(action) {
  const disableSetting = settingTypes.DISABLE;

  const isDisableRefreshing = yield select(
    pollingRefreshingSelector,
    disableSetting,
  );
  if (!isDisableRefreshing) return;

  const { response } = action.payload;
  const disable = get(response, 'data.attributes.reported.set.timed_ev');
  if (isNil(disable)) return;

  const currentDisable = yield select(
    pollingCurrentValueSelector,
    disableSetting,
  );

  if (currentDisable !== disable) {
    yield call(stopRefreshProcess, disableSetting);
  }
}

export function* setTimestamp(action) {
  const {
    deviceId,
    endpoint,
    params: {
      values: {
        timestamp,
      },
      rejectForm,
      resolveForm,
    },
  } = action.payload;
  const body = SettingModel.requestBody(bodyParam(settingTypes.TIME_SET, timestamp));
  const { response, error } = yield call(postPath, endpoint, body);

  const params = {
    response,
    error,
    rejectForm,
    resolveForm,
    successDisp: updateSettingsActionCreators.setTimestampSuccess,
    failDisp: updateSettingsActionCreators.setTimestampFailure,
  };
  yield call(processCall, params);

  if (response) {
    yield put(deviceActionCreators.updateDeviceReported(
      deviceId,
      DEVICE_CURRENT_TIME_PATH,
      timestamp,
    ));
  }
}

export function* setLogLevel(action) {
  const {
    endpoint,
    params: {
      values: {
        logLevel,
      },
      rejectForm,
      resolveForm,
    },
  } = action.payload;

  const body = SettingModel.requestBody(bodyParam(settingTypes.LOG_LEVEL, logLevel));
  const { response, error } = yield call(postPath, endpoint, body);

  const params = {
    response,
    error,
    rejectForm,
    resolveForm,
    successDisp: updateSettingsActionCreators.setLogLevelSuccess,
    failDisp: updateSettingsActionCreators.setLogLevelFailure,
  };
  yield call(processCall, params);
}

export function* updateSetting({ payload: { endpoint, attributes, resolveForm, rejectForm } }) {

  const body = {
    data: {
      id: uuidv4(),
      type: 'updateSetting',
      attributes,
    },
  };

  const { response, error } = yield call(postPath, endpoint, body);

  const params = {
    response,
    error,
    rejectForm,
    resolveForm,
    successDisp: updateSettingsActionCreators.updateSettingSuccess,
    failDisp: updateSettingsActionCreators.updateSettingFailed,
  };
  yield call(processCall, params);

}

function* updateSettingsSaga() {
  yield takeLatest(constants.TOGGLE_BEACON_REQUEST, toggleBeacon);
  yield takeLatest(constants.TOGGLE_WATCHDOG_REQUEST, toggleWatchdog);
  yield takeLatest(constants.TOGGLE_DISABLE_REQUEST, toggleDisable);
  yield takeLatest(constants.SET_TIMESTAMP_REQUEST, setTimestamp);
  yield takeLatest(constants.SET_LOG_LEVEL_REQUEST, setLogLevel);
  yield takeEvery(constants.FETCH_DEVICE_SUCCESS, handleBeaconStatus);
  yield takeEvery(constants.FETCH_DEVICE_SUCCESS, handleWatchdogStatus);
  yield takeEvery(constants.FETCH_DEVICE_SUCCESS, handleDisableStatus);
  yield takeEvery(constants.UPDATE_DEVICE_SETTING_REQUEST, updateSetting);
}

export default updateSettingsSaga;
