import React from 'react';
import { Form } from 'react-final-form';

const withFinalForm = WrappedComponent => props => (
  <Form
    component={WrappedComponent}
    {...props}
  />
);

export default withFinalForm;
