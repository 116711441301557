import React from 'react';
import { Grid, Header, Segment } from 'semantic-ui-react';

import { validDisplayName, validEmail } from 'components/form/formValidators';
import siteActionCreators from '../../utilities/actionCreators';
import { EditSiteNotes } from './components';
import SitePropertyInlineEdit from '../SitePropertyInlineEdit/SitePropertyInlineEditContainer';
import { StyledHeader } from './SiteInformation.style';

const SiteInformation = () => (
  <Grid>
    <Grid.Row>
      <Grid.Column width={16}>
        <Segment>
          <Header as="h3">Site information</Header>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <StyledHeader as="h5">Primary contact</StyledHeader>
              </Grid.Column>
              <Grid.Column width={8}>
                <SitePropertyInlineEdit
                  property="primaryContactPerson"
                  onChange={siteActionCreators.updateSitePrimaryContact}
                  validator={validDisplayName}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <StyledHeader as="h5">Primary contact phone</StyledHeader>
              </Grid.Column>
              <Grid.Column width={8}>
                <SitePropertyInlineEdit
                  property="primaryContactPhone"
                  onChange={siteActionCreators.updateSitePrimaryContactTelephone}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <StyledHeader as="h5">Primary contact email</StyledHeader>
              </Grid.Column>
              <Grid.Column width={8}>
                <SitePropertyInlineEdit
                  property="primaryContactEmail"
                  onChange={siteActionCreators.updateSitePrimaryContactEmail}
                  validator={validEmail}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <StyledHeader as="h5">Commercial contact email</StyledHeader>
              </Grid.Column>
              <Grid.Column width={8}>
                <SitePropertyInlineEdit
                  property="commercialContactEmail"
                  onChange={siteActionCreators.updateSiteCommercialContactEmail}
                  validator={validEmail}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <StyledHeader as="h5">Subscription contact email</StyledHeader>
              </Grid.Column>
              <Grid.Column width={8}>
                <SitePropertyInlineEdit
                  property="subscriptionContactEmail"
                  onChange={siteActionCreators.updateSiteSubscriptionContactEmail}
                  validator={validEmail}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <StyledHeader as="h5">Technical contact email</StyledHeader>
              </Grid.Column>
              <Grid.Column width={8}>
                <SitePropertyInlineEdit
                  property="technicalContactEmail"
                  onChange={siteActionCreators.updateSiteTechnicalContactEmail}
                  validator={validEmail}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={16}>
        <Segment>
          <Header as="h3">Notes</Header>
          <EditSiteNotes />
        </Segment>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export default SiteInformation;
