import { connect } from 'react-redux';

import { selectors } from '../../../../utilities';
import ScheduleReview from './ScheduleReview';

export const mapStateToProps = state => ({
  schedulerType: selectors.schedulerTypeSelector(state),
  recurrenceParams: selectors.recurrenceParamsSelector(state),
});

export default connect(mapStateToProps, null)(ScheduleReview);
