import isNil from 'lodash/isNil';

import { markerTypes } from '../components/MapWrapper/constants';

const getMarkerType = params => {
  if (isNil(params)) {
    return null;
  }

  const {
    onlineDevices,
    offlineDevices,
    unassociatedDevices,
    devicesForUpgrade,
  } = params;

  if (unassociatedDevices > 0 || devicesForUpgrade > 0) {
    return markerTypes.WARNING;
  }

  if (offlineDevices > 0 && onlineDevices > 0) {
    return markerTypes.PARTIAL_ONLINE;
  }

  if (offlineDevices > 0) {
    return markerTypes.OFFLINE;
  }

  if (onlineDevices > 0) {
    return markerTypes.ONLINE;
  }

  return null;
};

export default getMarkerType;
