import constants from 'dispatcherConst';

export const setExistingSchedule = existingSchedule => ({
  type: constants.SET_EXISTING_SCHEDULE,
  payload: {
    existingSchedule,
  },
});

export default setExistingSchedule;
