import React from 'react';

import { NOTIFICATION_EVENTS_FIELD } from 'helpers/selectors';
import DataTable from 'components/DataTable/DataTableContainer';

import { NotificationsFilter } from '..';
import { NotificationsLogTableRow } from './components';

const labels = [
  { text: 'Message', field: 'message', sortBy: 'message', width: 8 },
  { text: 'Device', field: 'deviceName', sortable: false, width: 2 },
  { text: 'Site', field: 'siteName', sortable: false, width: 2 },
  { text: 'Type', field: 'notificationType', sortBy: 'notificationType', width: 1 },
  { text: 'Occurred At', field: 'dateOccurred', sortBy: 'occurredAt', width: 3 },
];

function NotificationsLog() {
  return (
    <div>
      <DataTable
        header="Notifications"
        description="Showing notifications from the past 90 days"
        labels={labels}
        customTableRow={NotificationsLogTableRow}
        customControls={NotificationsFilter}
        pagination={{
          type: NOTIFICATION_EVENTS_FIELD,
          included: true,
        }}
      />
    </div>
  );
}

export default NotificationsLog;
