import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';
import deepEqual from 'lodash/isEqual';
import identity from 'lodash/identity';

import { deviceSelector } from 'helpers/selectors';
import PharosHardwareTPSV1 from './PharosHardwareTPSV1';

export const makeMapState = () => {
  const memoModel = memoizeOne(identity, deepEqual);
  const memoSerial = memoizeOne(identity, deepEqual);
  const memoTotalMemory = memoizeOne(identity, deepEqual);
  const memoUsedMemory = memoizeOne(identity, deepEqual);
  const memoFreeMemory = memoizeOne(identity, deepEqual);
  const memoBootReason = memoizeOne(identity, deepEqual);
  const memoLastBoot = memoizeOne(identity, deepEqual);

  return function mapStateToProps(state, ownProps) {
    const { resourceId } = ownProps;
    const device = deviceSelector(state)(resourceId);
    const { reportedWithMeta } = device;

    const {
      clk: {
        tz: {
          value: offsetValue,
        } = {},
      } = {},
      hw: {
        md: model,
        sn: serial,
        memt: totalMemory,
        memu: usedMemory,
        memf: freeMemory,
        br: bootReason,
        bl: lastBoot,
      } = {},
    } = reportedWithMeta || {};

    return {
      loading: !reportedWithMeta,
      offsetValue,
      model: memoModel(model),
      serial: memoSerial(serial),
      totalMemory: memoTotalMemory(totalMemory),
      usedMemory: memoUsedMemory(usedMemory),
      freeMemory: memoFreeMemory(freeMemory),
      bootReason: memoBootReason(bootReason),
      lastBoot: memoLastBoot(lastBoot),
    };
  };
};

export default connect(makeMapState())(PharosHardwareTPSV1);
