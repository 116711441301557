import has from 'lodash/has';

const extractIdFromResponse = response => {
  if (has(response, 'data.id')) {
    const {
      data: { id },
    } = response;

    return id;
  }

  return undefined;
};

export default extractIdFromResponse;
