import { connect } from 'react-redux';
import at from 'lodash/at';

import {
  signUpVerifyingSelector,
  specificErrorsSelector,
} from 'helpers/selectors';
import SignUp, { REQUESTER } from './SignUp';
import signUpActionCreators from './signUpActionCreators';

function getEmailFromToken(token) {
  try {
    const [base64Url] = at(token.split('.'), [1]);
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const { email } = JSON.parse(atob(base64));

    return email;
  } catch (e) {
    return '';
  }
}

export const mapStateToProps = (state, { match: { params: { invitationToken } } }) => ({
  email: getEmailFromToken(invitationToken),
  invitationToken,
  verificationError: specificErrorsSelector(state, REQUESTER),
  verifyingToken: signUpVerifyingSelector(state),
});

const mapDispatchToProps = {
  onSignUp: signUpActionCreators.signUpRequest,
  onVerifyToken: signUpActionCreators.invitationTokenVerification,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
