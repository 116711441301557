import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Responsive } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { paths } from 'navigation';
import { sizes } from 'themes/mixins';
import { StyledUserMenu } from './UserMenu.style';

const UserMenu = ({ displayName, logout, theme }) => {
  const { breadcrumbs = {} } = theme;

  const userMenuBody = (
    <Dropdown.Menu>
      <Dropdown.Item
        as={Link}
        to={paths.profile}
      >
        {breadcrumbs[paths.profile]}
      </Dropdown.Item>
      <Dropdown.Item
        name="logout"
        onClick={logout}
      >
        Sign out
      </Dropdown.Item>
    </Dropdown.Menu>
  );

  return (
    <Fragment>
      <Responsive
        minWidth={sizes.md}
        as={StyledUserMenu}
        text={displayName}
        labeled
      >
        {userMenuBody}
      </Responsive>
      <Responsive
        maxWidth={sizes.md - 1}
        as={StyledUserMenu}
        text={displayName}
      >
        {userMenuBody}
      </Responsive>
    </Fragment>
  );
};

UserMenu.defaultProps = {
  displayName: '',
};

UserMenu.propTypes = {
  displayName: PropTypes.string,
  logout: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};

export default UserMenu;
