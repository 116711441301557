import styled, { css } from 'styled-components';
import { BreadcrumbSection } from 'semantic-ui-react';

export const MAX_LENGTH = 20;

const BreadcrumbNavItemWrapper = styled(BreadcrumbSection)`
  ${({ text }) => (text.length <= MAX_LENGTH) && css`
    flex-shrink: 0;
    line-height: 2.4;
  `};
`;

export default BreadcrumbNavItemWrapper;
