import { put, select } from 'redux-saga/effects';

import selectors from '../selectors';
import actionCreators from '../actionCreators';
import { getPageAndDuplicate } from './utilities';

function* duplicatePage(action) {
  const { pageId, projectId, pageIndex, ...rest } = action.payload;
  const { pages, ...attributes } = (yield select(selectors.projectControlUISelector))(projectId);
  const newPagesList = getPageAndDuplicate(pages, pageId, pageIndex);

  yield put(actionCreators.updateControlUI({
    pages: newPagesList,
    ...attributes,
    ...rest,
  }));
}

export default duplicatePage;
