import FileComparator from 'helpers/FileComparator';

export function uploadEnqueueAtEnd(queue, { uploads }) {
  const uploadsKeys = uploads.map(({ fileKey }) => fileKey);

  return [...queue, ...uploadsKeys];
}

export function uploadEnqueueAtStart(queue, { uploads }) {
  const uploadsKeys = uploads.map(({ fileKey }) => fileKey);

  return [...uploadsKeys, ...queue];
}

export function uploadDequeue(queue, { fileKey }) {
  return queue.filter(key => key !== fileKey);
}

export function initialFileOpts(payload) {
  return {
    ...payload,
    cancelled: false,
    cancelling: false,
    completed: false,
    progress: 0,
    paused: false,
    pausing: false,
    resuming: false,
    timestamp: Date.now(),
    totalUploaded: 0,
  };
}

export function resumedFileOpts(payload, fileOpts) {
  const {
    systemFile: optsSystemFile,
    initialized,
    progress,
    totalUploaded,
    bucket: optsBucket,
    path: optsPath,
    accessKeyId: optsAccessKeyId,
  } = fileOpts;
  const {
    systemFile,
    bucket = optsBucket,
    path = optsPath,
    accessKeyId = optsAccessKeyId,
  } = payload;

  const fileComparator = new FileComparator(systemFile, optsSystemFile);
  const isSameFile = fileComparator.compareModifiedDate()
    .compareName()
    .compareSize()
    .finalize();

  return {
    bucket,
    path,
    accessKeyId,
    completed: false,
    systemFile,
    initialized: isSameFile ? initialized : false,
    paused: false,
    progress: isSameFile ? progress : 0,
    totalUploaded: isSameFile ? totalUploaded : 0,
  };
}

export function mergeFileOpts(state, payload, fileOpts = payload) {
  const { fileKey } = payload;

  return {
    ...state,
    [fileKey]: {
      ...state[fileKey],
      ...fileOpts,
    },
  };
}
