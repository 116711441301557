import snakeCase from 'lodash/snakeCase';

import { translationLabels } from 'application/tenant/console/site/components/structural/ProjectTasksTab/components/ListOfTasks/components/TaskAccordionRow/components/ActionsTable/constants';

const renderLabel = param => {
  const lang = 'en';
  const { key } = param;
  const label = snakeCase(key);

  const labelText = translationLabels[label] ? translationLabels[label][lang] : label;

  return labelText;
};

export default renderLabel;
