import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { PermissionsSwitch, Gateway, frontendPermissions } from 'gateway';
import { paths } from 'navigation';

import { MenuItem } from '..';

const MultiSitesMenuItem = ({ isSuperAdmin, projectSuites }) => {
  const hasPermission = useCallback(permissionChecker => {
    if (isSuperAdmin) {
      return true;
    }

    const isProjectOwner = permissionChecker(frontendPermissions.TENANT_MANAGER_SITES);
    const isProjectSuiteOwner = permissionChecker(frontendPermissions.PROJECT_SUITE_OWNER);
    const canAddProjectSuite = permissionChecker(frontendPermissions.PROJECT_SUITE_ADD);

    const permissions = [isProjectOwner, isProjectSuiteOwner, canAddProjectSuite];

    return permissions.some(item => item);
  }, [isSuperAdmin]);

  const isMenuVisible = useCallback(permissionChecker => {
    if (hasPermission(permissionChecker)) {
      return true;
    }

    if (isEmpty(projectSuites)) {
      return false;
    }

    return true;
  }, [hasPermission, projectSuites]);

  return (
    <PermissionsSwitch>
      <Gateway condition={isMenuVisible}>
        <MenuItem to={paths.userProjectSuites} />
      </Gateway>
    </PermissionsSwitch>
  );
};

export default MultiSitesMenuItem;

MultiSitesMenuItem.defaultProps = {
  isSuperAdmin: false,
  projectSuites: [],
};

MultiSitesMenuItem.propTypes = {
  isSuperAdmin: PropTypes.bool,
  projectSuites: PropTypes.array,
};
