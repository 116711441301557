import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  getColorPickerValues,
  calculateColorIntensity,
  transformRGBToPercentage,
  transformPercentageToRGB,
} from './utilities';
import {
  IntensitySlider,
  TemperatureSlider,
  ColorPicker,
  ColorPreview,
} from './components';
import {
  StyledWrapper,
  StyledSlidersWrapper,
  WheelBarsWrapper,
} from './ColorUI.style';

const ColorUI = ({
  formValues,
  changeFieldValue,
  actionTypeDeviceIdKey,
  scheduleMode,
}) => {
  const [color, setColor] = useState({ r: 0, g: 0, b: 0 });
  const [formColor, setFormColor] = useState({});
  const [temperature, setTemperature] = useState(50);
  const [intensity, setIntensity] = useState(100);
  const handleColorChange = colorObj => {
    const transformedColorToPercentage = transformRGBToPercentage(colorObj.rgb);
    setColor(transformedColorToPercentage);
  };
  const RGB = transformPercentageToRGB(color);
  const backgroundColor = color && `rgb(${RGB.r}, ${RGB.g}, ${RGB.b})`;
  const calculatedColor = calculateColorIntensity(RGB, intensity);

  useEffect(() => {
    const {
      red: updatedRed,
      green: updatedGreen,
      blue: updatedBlue,
      temperature: updatedTemperature,
      intensity: updatedIntensity,
    } = getColorPickerValues(formValues);

    setTemperature(parseInt(updatedTemperature, 10));
    setIntensity(parseInt(updatedIntensity, 10));
    const newColor = {
      r: parseInt(updatedRed, 10),
      g: parseInt(updatedGreen, 10),
      b: parseInt(updatedBlue, 10),
    };
    setColor({ r: newColor.r, g: newColor.g, b: newColor.b });
    const colorToUpdate = transformPercentageToRGB(newColor);
    setFormColor(colorToUpdate);
  }, [formValues]);

  return (
    <StyledWrapper>
      <ColorPreview bgColor={calculatedColor} actionTypeDeviceIdKey={actionTypeDeviceIdKey} />
      <WheelBarsWrapper>
        <ColorPicker
          width={200}
          wheelLightness={false}
          changeColor={handleColorChange}
          actionTypeDeviceIdKey={actionTypeDeviceIdKey}
          scheduleMode={scheduleMode}
          color={formColor}
        />
        <StyledSlidersWrapper>
          <IntensitySlider
            backgroundColor={backgroundColor}
            color={color}
            intensity={intensity}
            changeFieldValue={changeFieldValue}
            actionTypeDeviceIdKey={actionTypeDeviceIdKey}
          />
          <TemperatureSlider
            changeFieldValue={changeFieldValue}
            temperature={temperature}
            actionTypeDeviceIdKey={actionTypeDeviceIdKey}
          />
        </StyledSlidersWrapper>
      </WheelBarsWrapper>
    </StyledWrapper>
  );
};

ColorUI.defaultProps = {
  changeFieldValue: () => null,
  actionTypeDeviceIdKey: '',
  scheduleMode: false,
};

ColorUI.propTypes = {
  formValues: PropTypes.object.isRequired,
  changeFieldValue: PropTypes.func,
  actionTypeDeviceIdKey: PropTypes.string,
  scheduleMode: PropTypes.bool,
};

export default ColorUI;
