import { call, put, select, takeEvery, take, fork, delay } from 'redux-saga/effects';
import get from 'lodash/get';

import constants from 'dispatcherConst';
import { postPath, getPath } from 'data/api/requests';
import { messages } from 'data/notifications/notificationsConst';

import ApiRequestBuilder from 'helpers/ApiRequestBuilder';
import {
  resourceUpdateResourceLoaderSelector,
  resourceUpdateActiveResourcesSelector,
} from 'helpers/selectors';
import notificationsActionCreators from 'data/notifications/notificationsActionCreators';
import resourceUpdateActionCreators from './resourceUpdateActionCreators';
import withPollingActionCreators from '../withPollingSaga/withPollingActionCreators';
import { updatersConfig } from './resourceUpdateConst';


export function* requestUpdate(updateLoader, resourceId) {
  const { urlParams, endpoint } = updatersConfig[updateLoader](resourceId);

  const { response, error } = yield call(getPath, endpoint, {
    urlParams,
  });

  return { response, error };
}

export function* resourceCheck({ payload: { updateLoaderId, resourceId, updated } }) {
  const resources = yield select(resourceUpdateResourceLoaderSelector(updateLoaderId, resourceId));

  /* eslint-disable-next-line no-restricted-syntax */
  for (const resource of resources) {
    const { chainedLoaderId } = resource;

    if (updated.indexOf(resource.requestId) !== -1) {
      yield put(withPollingActionCreators.start(chainedLoaderId));
      yield put(resourceUpdateActionCreators.resourceUpdateSuccess({ chainedLoaderId }));
    } else if (resource.timeoutCounter >= resource.timeout) {
      yield put(notificationsActionCreators.addGeneralError(messages.ERROR_FETCH_DEVICE));
      yield put(withPollingActionCreators.setInterval(chainedLoaderId, 0));
      yield put(resourceUpdateActionCreators.resourceUpdateFailed({ chainedLoaderId }));
    }
  }
}


export function* resourceUpdateHandler() {
  while (true) {
    const loaders = yield select(state => state.resourceUpdate);
    if (!loaders || !Object.keys(loaders).length) {
      yield take(constants.RESOURCE_UPDATE_REQUEST_SUCCESS_V2);
    }

    const activeLoaders = yield select(resourceUpdateActiveResourcesSelector);

    /* eslint-disable-next-line no-restricted-syntax */
    for (const { updateLoader, resourceId } of activeLoaders) {
      const { response } = yield call(requestUpdate, updateLoader, resourceId);
      const updated = get(response, 'data.attributes.updated', []);
      yield put(resourceUpdateActionCreators.fetchDone(updateLoader, resourceId, updated));
    }

    yield delay(1000);
  }
}

export function* handleResourceUpdateRequest({ payload }) {
  const {
    endpoint,
    loader,
    chainedLoaderId,
    updateLoaderId,
    timeout,
    parameters,
  } = payload;

  const body = new ApiRequestBuilder(loader).setAttributes({
    timeout,
    parameters,
  });
  const { response, error } = yield call(postPath, endpoint, body);

  if (response) {
    yield put(resourceUpdateActionCreators.resourceUpdateRequestSuccess(
      response, chainedLoaderId, updateLoaderId));
  } else if (error) {
    yield put(resourceUpdateActionCreators.resourceUpdateRequestFailure(
      error, chainedLoaderId, updateLoaderId));
  }

  yield put(withPollingActionCreators.stop(chainedLoaderId));
  yield put(resourceUpdateActionCreators.resourceUpdateStart(chainedLoaderId));
}

function* resourceUpdateSaga() {
  yield takeEvery(constants.RESOURCE_UPDATE_REQUEST_V2, handleResourceUpdateRequest);
  yield takeEvery(constants.RESOURCE_UPDATE_LOADER_FETCH_DONE_V2, resourceCheck);
  yield call(fork, resourceUpdateHandler);
}

export default resourceUpdateSaga;
