import constants from 'dispatcherConst';

const initialState = {
  updating: false,
  isFetchingAvailableActions: false,
  isFetchingLogicalDevices: false,
  isFetchingOccurrences: false,
  showHidden: false,
  hideMultiSiteOccurrences: false,
};

const schedulingReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.UPDATE_OCCURRENCES:
      return { ...state, updating: true };

    case constants.FETCH_AUTOMATED_OPERATION_OCCURRENCES: {
      return { ...state, isFetchingOccurrences: true };
    }

    case constants.FETCH_AUTOMATED_OPERATION_OCCURRENCES_FAILED: {
      return { ...state, isFetchingOccurrences: false };
    }

    case constants.FETCH_AUTOMATED_OPERATION_OCCURRENCES_SUCCESS:
      return { ...state, updating: false, isFetchingOccurrences: false };

    case constants.FETCH_DEVICE_ACTION_TYPES_LISTS_REQUEST:
    case constants.FETCH_SITE_ACTION_TYPES_LIST_REQUEST:
      return { ...state, isFetchingAvailableActions: true };

    case constants.FETCH_DEVICE_ACTION_TYPES_LISTS_SUCCESS:
    case constants.FETCH_DEVICE_ACTION_TYPES_LISTS_FAILED:
    case constants.FETCH_DEVICE_ACTION_TYPES_LISTS_NO_DATA:
    case constants.FETCH_SITE_ACTION_TYPES_LIST_SUCCESS:
    case constants.FETCH_SITE_ACTION_TYPES_LIST_FAILED:
      return { ...state, isFetchingAvailableActions: false };

    case constants.TOGGLE_SHOW_HIDDEN_OCCURRENCES: {
      const { showHidden } = action.payload;

      return {
        ...state,
        showHidden,
      };
    }

    case constants.ADD_SELECTED_PROJECT_ID: {
      const { payload: { projectId } } = action;

      return {
        ...state,
        projectId,
      };
    }

    case constants.CLEAR_SELECTED_PROJECT_ID:
      return {
        ...state,
        projectId: null,
      };

    case constants.TOGGLE_SHOW_HIDE_MULTI_SITE_OCCURRENCES: {
      const { hideMultiSiteOccurrences } = action.payload;

      return {
        ...state,
        hideMultiSiteOccurrences,
      };
    }

    default:
      return state;
  }
};

export default schedulingReducer;
