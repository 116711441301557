import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Menu, Responsive } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { sizes } from 'themes/mixins';
import NarrowContainer from 'components/NarrowContainer/NarrowContainer.style';
import TenantLogo from 'components/TenantLogo/TenantLogo.style';
import { NotificationsDropdown } from 'components/Notifications/components';

import MainMenu from './MainMenu/MainMenuContainer';
import UserMenu from './UserMenu/UserMenuContainer';
import TenantSelect from './TenantSelect/TenantSelectContainer';
import { ServiceWorkerAlert } from './components';
import { StyledTopBar } from './TopBar.style';

class TopBar extends PureComponent {

  constructor() {
    super();

    this.state = { simple: true };

    this.handleScroll = this.handleScroll.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    document.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const { simple } = this.state;
    const changePosition = 50;

    if (simple && window.scrollY >= changePosition + 1) {
      this.setState({ simple: false });
    } else if (!simple && window.scrollY < changePosition) {
      this.setState({ simple: true });
    }
  }

  render() {
    const { simple } = this.state;
    const {
      apiDomain,
      isDomainChanged,
      currentUserId,
      isSuperAdmin,
      inControlPanelContext,
      notificationsEnabled,
      isPageNavigationOpen,
    } = this.props;

    return (
      <StyledTopBar
        simple={simple}
        padded={!!currentUserId}
        control={inControlPanelContext}
        isPageNavigationOpen={isPageNavigationOpen}
      >
        <NarrowContainer>
          {!currentUserId && (
            <TenantLogo topbar>
              <Link to="/">
                <TenantLogo.Image topbar />
              </Link>
            </TenantLogo>
          )}
          {currentUserId && (
            <Responsive maxWidth={sizes.md - 1}>
              <MainMenu />
            </Responsive>
          )}
          {isSuperAdmin && isDomainChanged && (
            <Responsive
              minWidth={sizes.md}
              as={Menu.Item}
              fitted="horizontally"
            >
              {apiDomain}
            </Responsive>
          )}
          {currentUserId && (
            <Menu.Menu position="right">
              {isSuperAdmin && (
                <>
                  {isDomainChanged && (
                    <Responsive
                      maxWidth={sizes.md - 1}
                      as={Menu.Item}
                      fitted="horizontally"
                    >
                      <StyledTopBar.Icon name="attention" size="big" />
                    </Responsive>
                  )}
                  <Responsive minWidth={sizes.md}>
                    <Menu.Item>
                      <TenantSelect />
                    </Menu.Item>
                  </Responsive>
                </>
              )}
              {notificationsEnabled && (
                <Menu.Item>
                  <NotificationsDropdown />
                </Menu.Item>
              )}
              <UserMenu />
            </Menu.Menu>
          )}
        </NarrowContainer>
        <ServiceWorkerAlert />
      </StyledTopBar>
    );
  }

}

TopBar.defaultProps = {
  currentUserId: null,
  isSuperAdmin: false,
  notificationsEnabled: false,
  isPageNavigationOpen: false,
};

TopBar.propTypes = {
  inControlPanelContext: PropTypes.bool.isRequired,
  apiDomain: PropTypes.string.isRequired,
  currentUserId: PropTypes.string,
  isSuperAdmin: PropTypes.bool,
  isDomainChanged: PropTypes.bool.isRequired,
  notificationsEnabled: PropTypes.bool,
  isPageNavigationOpen: PropTypes.bool,
};

export default TopBar;
