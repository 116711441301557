import constants from 'dispatcherConst';
import { messages } from 'data/notifications/notificationsConst';
import { FILE_UPLOADS_FIELD } from 'helpers/selectors';

const uploadsActionCreators = {
  initializeUploadState: recentUploads => ({
    type: constants.UPLOAD_INITIALIZE_STATE,
    payload: { recentUploads },
  }),
  forceUpload: fileKey => ({
    type: constants.UPLOAD_FORCE,
    payload: { fileKey },
  }),
  enqueueUploadAtEnd: uploads => ({
    type: constants.UPLOAD_ENQUEUE_AT_END,
    payload: { uploads },
  }),
  enqueueUploadAtStart: uploads => ({
    type: constants.UPLOAD_ENQUEUE_AT_START,
    payload: { uploads },
  }),
  updateProgress: (fileKey, { progress, totalUploaded, secondsLeft }) => ({
    type: constants.UPLOAD_PROGRESS_UPDATE,
    payload: { fileKey, progress, totalUploaded, secondsLeft },
  }),
  startUploadSuccess: fileKey => ({
    type: constants.UPLOAD_START_SUCCESS,
    payload: { fileKey },
  }),
  pauseUpload: fileKey => ({
    type: constants.UPLOAD_PAUSE_REQUEST,
    payload: { fileKey },
  }),
  pauseUploadSuccess: fileKey => ({
    type: constants.UPLOAD_PAUSE_SUCCESS,
    payload: { fileKey },
  }),
  pauseUploadFailure: (fileKey, error) => ({
    type: constants.UPLOAD_PAUSE_FAILED,
    payload: { fileKey, error },
  }),
  resumeUpload: fileKey => ({
    type: constants.UPLOAD_RESUME_REQUEST,
    payload: { fileKey },
  }),
  resumeUploadSuccess: fileKey => ({
    type: constants.UPLOAD_RESUME_SUCCESS,
    payload: { fileKey },
  }),
  resumeUploadFailure: (fileKey, error) => ({
    type: constants.UPLOAD_RESUME_FAILED,
    payload: { fileKey, error },
  }),
  cancelUpload: fileKey => ({
    type: constants.UPLOAD_CANCEL_REQUEST,
    payload: { fileKey },
  }),
  cancelUploadSuccess: fileKey => ({
    type: constants.UPLOAD_CANCEL_SUCCESS,
    payload: { fileKey },
  }),
  cancelUploadFailure: (fileKey, error) => ({
    type: constants.UPLOAD_CANCEL_FAILED,
    payload: { fileKey, error },
  }),
  suspendUpload: () => ({
    type: constants.UPLOAD_SUSPEND,
  }),
  continueUpload: () => ({
    type: constants.UPLOAD_CONTINUE,
  }),
  successUpload: fileKey => ({
    type: constants.UPLOAD_SUCCESS,
    payload: {
      fileKey,
      resourceType: FILE_UPLOADS_FIELD,
    },
  }),
  failUpload: error => ({
    type: constants.UPLOAD_FAILED,
    payload: { error },
  }),
  clearCompleted: () => ({
    type: constants.UPLOAD_CLEAR_COMPLETED,
  }),
  fileNotFound: fileKey => ({
    type: constants.UPLOAD_FILE_NOT_FOUND,
    payload: { fileKey },
  }),
  setUploadFetchStatus: (fileKey, fetching) => ({
    type: constants.SET_UPLOAD_FETCH_STATUS,
    payload: { fileKey, fetching },
  }),
  setUploadSuspensionStatus: suspended => ({
    type: constants.SET_UPLOAD_SUSPENSION_STATUS,
    payload: { suspended },
  }),
  setUploadWorker: (fileKey, worker) => ({
    type: constants.SET_UPLOAD_WORKER,
    payload: { fileKey, worker },
  }),
  setUploadLimit: limit => ({
    type: constants.SET_UPLOAD_LIMIT,
    payload: { limit },
  }),
  saveNewToken: token => ({
    type: constants.UPLOAD_SAVE_NEW_TOKEN,
    payload: { token },
  }),
  filesNotSupported: supportedCount => ({
    type: constants.ADD_INFO_NOTIFICATION,
    payload: {
      message: messages.FILES_NOT_SUPPORTED(supportedCount),
    },
  }),
};

export default uploadsActionCreators;
