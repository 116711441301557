import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { DEVICES_FOR_SITE_FIELD } from 'helpers/selectors';

export const fetchSiteDevices = (projectId, urlParams) => ({
  type: constants.FETCH_SITE_DEVICES_REQUEST,
  payload: {
    projectId,
    endpoint: endpoints.LOGICAL_DEVICES_PROJECT(projectId),
    resourceType: DEVICES_FOR_SITE_FIELD,
    urlParams,
  },
});

export const fetchSiteDevicesSuccess = response => ({
  type: constants.FETCH_SITE_DEVICES_SUCCESS,
  payload: {
    response,
    resourceType: DEVICES_FOR_SITE_FIELD,
  },
});

export const fetchAndSaveLogicalDevicesSuccess = response => ({
  type: constants.FETCH_AND_SAVE_LOGICAL_DEVICES_SUCCESS,
  payload: {
    response,
    resourceType: DEVICES_FOR_SITE_FIELD,
  },
});

export const fetchSiteDevicesFailure = error => ({
  type: constants.FETCH_SITE_DEVICES_FAILED,
  payload: { error },
});
