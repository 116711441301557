import getPermissionsCountByRecipientId from './getPermissionsCountByRecipientId';

const getPermissionCheckboxState = ({
  cumulatedPermissions,
  recipientId,
  permissionNames,
}) => {
  const {
    allPermissionsCount,
    grantedCount,
    notGrantedCount,
  } = getPermissionsCountByRecipientId({
    cumulatedPermissions,
    permissionNames,
    recipientId,
  });

  if (notGrantedCount === allPermissionsCount) {
    return 'unchecked';
  }

  if (grantedCount === allPermissionsCount) {
    return 'checked';
  }

  return 'partial';
};

export default getPermissionCheckboxState;
