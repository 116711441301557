import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/atomic';
import ConfirmModal from 'components/elements/ConfirmModal/ConfirmModal';
import { getDisplayName } from 'helpers/sharedMethods';

const withRemoveDevice = WrappedComponent => class extends Component {

  static displayName = `WithRemoveDevice(${getDisplayName(WrappedComponent)})`;

  static propTypes = {
    fetching: PropTypes.bool.isRequired,
    item: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    onRemoveDevice: PropTypes.func.isRequired,
    onRemoveOnlineDevice: PropTypes.func.isRequired,
  };

  constructor() {
    super();

    this.handleRemoveOnline = this.handleRemoveOnline.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  handleRemoveOnline() {
    const { item: { name }, onRemoveOnlineDevice } = this.props;

    onRemoveOnlineDevice(name);
  }

  handleRemove() {
    const { item: { id }, onRemoveDevice } = this.props;

    onRemoveDevice(id);
  }

  render() {
    const { fetching, item: { name, online } } = this.props;

    if (online) {
      return (
        <WrappedComponent
          onClick={this.handleRemoveOnline}
          disabled={fetching}
          title="Remove"
        />
      );
    }

    const trigger = <WrappedComponent title="Remove" disabled={fetching} />;

    return (
      <ConfirmModal
        trigger={trigger}
        cancelButton="Cancel"
        confirmButton={<Button negative primary={false}>Remove Device</Button>}
        content={(
          <div>
            <p>
              Are you sure you want to remove device <strong>{name}</strong>?&nbsp;
              This action cannot be undone.
            </p>
            <p>
              Enter the name of the device to confirm:
            </p>
          </div>
        )}
        header="Confirm device removal"
        onConfirm={this.handleRemove}
        validationString={name}
        validationMessage="Enter the name of the device to be removed"
      />
    );
  }

};

export default withRemoveDevice;
