class UserPermissionModel {

  constructor(userPermission = {}) {
    this.id = userPermission.id;
    this.userId = userPermission.userId;
    this.resourceType = userPermission.resourceType;
    this.resourceId = userPermission.resourceId;
    this.permissions = userPermission.permissions;
  }

}

export default UserPermissionModel;
