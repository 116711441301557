import React from 'react';
import PropTypes from 'prop-types';

import { USERS_FIELD } from 'helpers/selectors';
import DataTable from 'components/DataTable/DataTableContainer';
import UserTableRow from './UserTableRow/UserTableRowContainer';

const usersTableLabels = [
  { text: 'First name', field: 'firstName' },
  { text: 'Last name', field: 'lastName' },
  { text: 'Email', field: 'email' },
  { text: 'Status', field: 'statusIcon', sortable: false },
];

const UsersTable = ({ resourceId, resourceType }) => (
  <DataTable
    header="Select users to view their permissions"
    labels={usersTableLabels}
    resourceId={resourceId}
    resourceType={resourceType}
    customTableRow={UserTableRow}
    pagination={{
      type: USERS_FIELD,
      included: true,
    }}
  />
);

UsersTable.propTypes = {
  resourceId: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
};

export default UsersTable;
