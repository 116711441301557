import constants from 'dispatcherConst';

import endpoints from 'data/api/endpoints';

export const fetchUsers = (resourceId, resourceType, urlParams) => ({
  type: constants.CONTROL_UI_FETCH_USERS,
  payload: {
    resourceId,
    resourceType,
    urlParams,
    endpoint: resourceType === 'project'
      ? endpoints.PERMISSIONS_PROJECT_USERS(resourceId)
      : endpoints.PROJECT_SUITE_USERS(resourceId),
  },
});

export const fetchUsersSuccess = response => ({
  type: constants.CONTROL_UI_FETCH_USERS_SUCCESS,
  payload: { response },
});

export const fetchUsersFailure = error => ({
  type: constants.CONTROL_UI_FETCH_USERS_FAILURE,
  payload: { error },
});

export default fetchUsers;
