import { connect } from 'react-redux';

import SelectedProject from './SelectedProject';

export const mapStateToProps = (state, { resourceId, resourceName }) => ({
  selectedProjectId: resourceId,
  resourceName,
});

export default connect(mapStateToProps)(SelectedProject);
