import React, { useMemo, createRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Icon, Tab } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';

import { Button } from 'components/atomic';

import { TIME_PICKER_TYPES, TIME_PICKER_TYPES_INDEX, usePopup, useTabs } from './utilities';
import {
  SimpleView,
  TimeMaskView,
  RecurringView,
  AstronomicalView,
} from './components';
import { StyledPopup, TriggerContainer, StyledLabel, EmptyMessage } from './TimePicker.style';

export default function TimePicker({
  selectedTimes,
  only,
  popupPosition,
  defaultEmptyMessage,
  onChange,
  onRemoveTimeCallback,
  keepOpenedAfterSubmitFor,
  single,
}) {
  const { isOpen, onClose, onToggle } = usePopup();
  const { activeTab, onTabChange } = useTabs();
  const contextRef = createRef();

  const handleTimeChange = useCallback(values => {
    onChange(values);

    if (!keepOpenedAfterSubmitFor.includes(TIME_PICKER_TYPES_INDEX[activeTab])) {
      onClose();
    }
  }, [onChange, activeTab, keepOpenedAfterSubmitFor, onClose]);

  const commonProps = {
    selectedTimes,
    single,
    onTimeChange: handleTimeChange,
    onRemoveTimeCallback,
  };

  const tabs = useMemo(() => [
    {
      menuItem: 'Simple',
      type: TIME_PICKER_TYPES.SIMPLE,
      render: () => <SimpleView {...commonProps} />,
    },
    {
      menuItem: 'Recurring',
      type: TIME_PICKER_TYPES.RECURRING,
      render: () => <RecurringView {...commonProps} />,
    },
    {
      menuItem: 'Astronomical',
      type: TIME_PICKER_TYPES.ASTRONOMICAL,
      render: () => <AstronomicalView {...commonProps} />,
    },
    {
      menuItem: 'Time Mask',
      type: TIME_PICKER_TYPES.TIME_MASK,
      render: () => <TimeMaskView {...commonProps} />,
    },
  ], [commonProps]);

  const renderSideContent = useCallback(() => {
    if (single && !isEmpty(selectedTimes)) {
      const [firstItem] = selectedTimes;
      return (
        <StyledLabel>
          {firstItem}
        </StyledLabel>
      );
    }

    return (
      <EmptyMessage>
        {defaultEmptyMessage}
      </EmptyMessage>
    );
  }, [single, defaultEmptyMessage, selectedTimes]);

  const renderTrigger = useCallback(() => (
    <TriggerContainer>
      <span ref={contextRef}>
        <Button basic icon className="time-picker-toggle-btn" onClick={e => onToggle(e)}>
          <Icon name="clock outline" />
        </Button>
      </span>
      <div>
        {renderSideContent()}
      </div>
    </TriggerContainer>
  ), [renderSideContent, contextRef, onToggle]);

  return (
    <>
      <StyledPopup
        on="click"
        position={popupPosition}
        context={contextRef}
        open={isOpen}
        onClose={onClose}
        content={(
          <Tab
            menu={{ secondary: true, pointing: true }}
            panes={tabs.filter(tab => only.includes(tab.type))}
            activeIndex={activeTab}
            onTabChange={onTabChange}
          />
        )}
      />
      {renderTrigger()}
    </>
  );
}

export { TIME_PICKER_TYPES };

TimePicker.defaultProps = {
  selectedTimes: [],
  only: [
    TIME_PICKER_TYPES.SIMPLE,
    TIME_PICKER_TYPES.TIME_MASK,
    TIME_PICKER_TYPES.RECURRING,
    TIME_PICKER_TYPES.ASTRONOMICAL,
  ],
  popupPosition: 'bottom left',
  defaultEmptyMessage: 'No time selected.',
  keepOpenedAfterSubmitFor: [],
  onRemoveTimeCallback: null,
  single: false,
};

TimePicker.propTypes = {
  selectedTimes: PropTypes.arrayOf(PropTypes.string),
  only: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  popupPosition: PropTypes.string,
  defaultEmptyMessage: PropTypes.string,
  keepOpenedAfterSubmitFor: PropTypes.arrayOf(PropTypes.string),
  onRemoveTimeCallback: PropTypes.func,
  single: PropTypes.bool,
};
