
import { connect } from 'react-redux';
import {
  deviceSelector,
  pathwayDeviceDMXSelector,
} from 'helpers/selectors';
import performActionActionCreators from 'pages/Device/performActionActionCreators';
import PathwayPathportPortsV1Discover from './PathwayPathportPortsV1Discover';

export const mapStateToProps = (state, { element: { resourceId, id } }) => {
  const { online } = deviceSelector(state)(resourceId);
  const { [id]: port = {} } = pathwayDeviceDMXSelector(state, resourceId);
  const { dir, dmxSts, rdm, rdmDevs: rdmDevices } = port;
  const rdmActive = !(dir === 'Input' || dmxSts === 'disabled' || !rdm);

  return {
    online,
    rdmActive,
    rdmDevices,
  };
};

const mapDispatchToProps = {
  onPerformAction: performActionActionCreators.performActionRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(PathwayPathportPortsV1Discover);
