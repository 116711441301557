import styled from 'styled-components';

import DataTable from 'components/DataTable/DataTableContainer';

export const StyledTasksTableFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledDataTable = styled(DataTable)`
  td {
    font-size: 12px;
    border-top: 0px !important;
    border-bottom: solid 2px #e6e6e6 !important;
  } 
`;
