export default {
  azure: '#0080ff',
  malibu: '#88abff',
  tango: '#ef841e',
  buttercup: '#efae1e',
  black: '#000000',
  mineShaft: '#333333',
  white: '#ffffff',
  greyAlpha: 'rgba(255,255,255,.5)',
  grey: '#808080',
  mountainMeadow: '#21ba45',
  green: '#00b900',
  danube: '#4c92ce',
  newYorkPink: '#d4807a',
  stiletto: '#9f3a38',
  chablis: '#fff6f6',
  cavernPink: '#e0b4b4',
  red: '#f10000',
  mercury: '#e6e6e6',
  darkGrey: '#7f7f7f',
  silver: '#c2c2c2',
  carrot: '#f28a22',
  concrete: '#f3f3f3',
  flamingo: '#f16022',
  alizarinCrimson: '#ee2529',
  minsk: '#422f8d',
  cerulean: '#00b7dc',
  shark: '#222426',
  pathwayRuby: '#990539',
  tsldOrange: '#F15A24',
  slOrange: '#F04E23',
  alGreen: '#0DAB4C',
  bzBlue: '#1777af',
  lsYellow: '#FBCC13',
  msRed: '#FD002E',
  stBlue: '#2A6EBB',
  spBlue: '#00B5EB',
  lmGrey: '#A2AAAD',
  arYellow: '#FEED00',
  acGreen: '#62BB46',
  mlGreen: '#A9AE79',
  schrederPurple: '#432680',
  schrederBlueActive: '#00B2DB',
  schrederOrangeInactive: '#FD5D08',
  aelGreen: '#5E9D5F',
  sysPurple: '#482367',
  botPink: '#94C83D',
  lumGreen: '#17CA6C',
  // SemanticUI defaults
  positiveText: '#2c662d',
  positiveBackground: '#fcfff5',
  boxShadow: 'rgba(34,36,38,.15)',
  focus: '#85b7d9',
  semanticGrey: '#e1e2e3',
  eclipseBlue: '#0574BD',
  smartltgGreen: '#7BC044',
  achjapanRed: '#d81c2a',
  cwlightingBlue: '#1A1F4D',
  lumencloudBlue: '#4B7BFF',
  livingprojectsRed: '#DA291C',
  egeaBlue: '#005288',
  unbrandedBlack: '#4F4A4A',
  lcsGreen: '#058C05',
  mzlightBlue: '#2A81A0',
  acspecPurple: '#9900E5',
  luxlightBlack: '#000000',
  pixelumenlabAqua: '#00A6CE',
  purelightGreen: '#B9CA51',
  etcBlue: '#1191C2',
  lightBlue: '#4bA9C8',
  grandstageRed: '#94323C',
  fluviusBlue: '#004C69',
  iotdeploymentBlue: '#0869ff',
  portlightingGreen: '#0f5469',
  westviewBlue: '#1558ea',
  nanoltgGrey: '#3E3E3E',
  gslOrange: '#F15A29',
};
