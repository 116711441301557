import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import AccordionTable from 'components/AccordionTable/AccordionTable';
import PathwayVIALinkStatus from '../../../../components/PathwayVIALinkStatusV1/PathwayVIALinkStatusV1Container';
import { PathwayVIAPortsV1Status, PathwayVIAPortsV1Settings } from './components';

const Ports = ({ online, columns, data }) => (
  <AccordionTable
    columns={columns}
    action={PathwayVIALinkStatus}
    data={data}
    render={element => (
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} computer={8}>
            <PathwayVIAPortsV1Status online={online} element={element} />
          </Grid.Column>
          <Grid.Column mobile={16} computer={8}>
            <PathwayVIAPortsV1Settings online={online} element={element} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )}
    />
);

Ports.defaultProps = {
  online: false,
};

Ports.propTypes = {
  online: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default Ports;
