import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  schedulesSelector,
} from 'helpers/selectors';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import automatedOperationsActionCreators from '../../../../utilities/actionCreators';
import SchedulesTable from './SchedulesTable';
import { addTableColumns } from './utilities';
import {
  extraColumns,
} from './constants';

export const mapStateToProps = (state, { automatedOperationId }) => {
  const getSchedules = schedulesSelector(state)(automatedOperationId);
  const schedulesList = getSchedules
    ? addTableColumns(getSchedules.schedulesList, extraColumns)
    : null;

  return {
    automatedOperationId,
    schedulesList,
  };
};

export default compose(
  connect(mapStateToProps),
  chainedLoaderWithSaga(({ automatedOperationId }) => automatedOperationsActionCreators
    .fetchSchedules(automatedOperationId)),
  chainedLoaderWithSaga(({ automatedOperationId }) => automatedOperationsActionCreators
    .fetchAutomatedFunctions(automatedOperationId)),
)(SchedulesTable);
