import { lang, translationLabels } from '../../../../../../../../constants';

function createDropdownOptionForActions(type, listObject) {
  if (type in listObject) {
    const text = translationLabels[listObject[type].label]
      ? translationLabels[listObject[type].label][lang]
      : listObject[type].label;
    return {
      key: type,
      text,
      value: type,
    };
  }

  return {
    key: 'not_found',
    text: 'Undefined Action',
    value: 'not_found',
  };
}

export default createDropdownOptionForActions;
