import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { PROJECTS_FIELD } from 'helpers/selectors';
import {
  actionCreators as projectSuitesActionCreators,
  selectors as projectSuitesSelectors,
} from 'pages/ProjectSuites/utilities';

import StatusWidgets from './StatusWidgets';

export const mapStateToProps = (state, { match }) => {
  const { id: projectSuiteId } = match.params;

  return {
    projectSuiteId,
    isLoading: projectSuitesSelectors.isLoadingProjectsStatusSelector(state),
    projectsStatus: projectSuitesSelectors.projectsStatusSelector(state),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    ({ projectSuiteId }) => projectSuitesActionCreators.fetchProjectSuiteProjectsStatus(
      projectSuiteId,
      {
        fields: {
          [PROJECTS_FIELD]: [
            'name',
            'state',
            'onlineDevices',
            'offlineDevices',
            'unassociatedDevices',
            'devicesForUpgrade',
          ],
        },
      },
    ),
  ),
)(StatusWidgets);
