import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { deepEqualMemoizeOne } from 'helpers/sharedMethods';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { TASKS_FIELD } from 'helpers/selectors';
import { addPagination } from 'helpers/paginationHelpers';
import { permissionsOrRedirect, frontendPermissions } from 'gateway';
import { Unauthorized } from 'application/components';
import { tasksActionCreators } from '../../../utilities';
import { selectors, actionCreators } from './utilities';
import ControlUI from './ControlUI';

export const makeMapState = () => {
  const memoPageIds = deepEqualMemoizeOne();

  return function mapStateToProps(state, { match }) {
    const { id: projectId } = match.params;
    const { pages = [] } = selectors.projectControlUISelector(state)(projectId) || {};
    const pageIds = pages.map(({ index }) => index);

    return {
      pageIds: memoPageIds(pageIds),
      projectId,
    };
  };
};

const mapDispatchToProps = {
  onAddPage: actionCreators.addPage,
  onDeletePage: actionCreators.deletePage,
};

export default compose(
  withRouter,
  connect(makeMapState, mapDispatchToProps),
  chainedLoaderWithSaga(
    ({ projectId }) => actionCreators.fetchControlUI(projectId),
    addPagination(
      { resourceType: TASKS_FIELD, included: true },
      ({ projectId }) => tasksActionCreators.fetchTasks(projectId, {
        sort: 'name',
      }),
    ),
  ),
  permissionsOrRedirect(frontendPermissions.VIEW_CONTROL_PANEL, Unauthorized),
)(ControlUI);
