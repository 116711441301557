import isNull from 'lodash/isNull';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';

import renderTitle from './renderTitle';
import processParam from './processParam';

const renderParams = startMethodData => {
  if (isNull(startMethodData) || isEmpty(startMethodData)) {
    return [];
  }

  if (isObject(startMethodData) && startMethodData.params) {
    const { params } = startMethodData;

    if (size(params) === 1) {
      return [];
    }

    if (size(params) > 1) {
      const entries = Object.entries(params);
      const values = entries.map(item => renderTitle(`${item[0]} ${processParam(item[1])}`));

      return values;
    }
  }

  return [];
};


export default renderParams;
