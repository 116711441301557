import {
  fetchCurrentUser,
  fetchCurrentUserSuccess,
  fetchCurrentUserFailure,
} from './fetchCurrentUser';
import {
  login,
  loginSuccess,
  loginFailure,
} from './login';
import {
  logout,
  logoutError,
} from './logout';

export default {
  fetchCurrentUser,
  fetchCurrentUserSuccess,
  fetchCurrentUserFailure,
  login,
  loginSuccess,
  loginFailure,
  logout,
  logoutError,
};
