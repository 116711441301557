import endpoints from 'data/api/endpoints';
import { twoFactorStates } from './constants';

const loginSuccessReducer = (state, action) => {
  const {
    [endpoints.LOGIN]: {
      meta: {
        two_factor_status: twoFactorStatus,
      },
    },
  } = action.payload.response.meta;

  return {
    ...state,
    recentlyLoggedOut: false,
    twoFactorNeedsSetup: twoFactorStatus === twoFactorStates.NEEDS_SETUP,
    twoFactorActive: twoFactorStatus === twoFactorStates.ACTIVE,
    invitationsSuppressed: false,
  };
};

export default loginSuccessReducer;
