import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { buildObjectURL } from 'helpers/sharedMethods';
import { resourceUploadSelector } from 'components/ProjectFileUpload/projectFileUploadSelectors';
import { selectors, actionCreators } from '../../../../../../utilities';
import BackgroundImagePreview from './BackgroundImagePreview';

export const mapStateToProps = (state, { match, pageId }) => {
  const { id: projectId } = match.params;
  const fileIdentifier = `backgroundImage-${projectId}-${pageId}`;
  const { pages = [], lockVersion } = selectors.projectControlUISelector(state)(projectId) || {};
  const {
    backgroundImg,
    backgroundImgBlob,
  } = pages.filter(({ index }) => index === pageId)[0] || {};

  const {
    systemFile,
  } = resourceUploadSelector(state)(fileIdentifier) || {};
  const localURL = buildObjectURL(systemFile);

  return {
    projectId,
    pageId,
    backgroundImg,
    backgroundImgBlob,
    localURL,
    lockVersion,
  };
};

const mapDispatchToProps = {
  onLoadPageBackgroundImage: actionCreators.loadPageBackgroundImage,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(BackgroundImagePreview);
