import isString from 'lodash/isString';
import { gte, lt, coerce } from 'semver';

export const MIN_VERSION = '0.10.2';
export const MAX_VERSION = '1.0.0';

const isValidFileTransferVersion = version => {
  if (!version || !isString(version)) {
    return false;
  }

  return lt(coerce(version), MIN_VERSION) || gte(coerce(version), MAX_VERSION);
};

export default isValidFileTransferVersion;
