class SnapshotModel {

  constructor(data = {}) {
    this.id = data.id;
    this.name = data.name;
    this.active = data.active;
  }

}

export default SnapshotModel;
