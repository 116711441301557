import PropTypes from 'prop-types';
import { compose } from 'redux';

import extractValue from 'helpers/extractValue/extractValue';
import markLastUpdate from 'helpers/markLastUpdate/markLastUpdate';
import formatValue from 'helpers/formatValue/formatValue';
import withPrevious from 'helpers/withPrevious/withPrevious';
import { tempNormalizer } from '../normalizers';
import DescriptionItem from '../DescriptionItem/DescriptionItem';

const TempDescriptionItem = compose(
  extractValue,
  markLastUpdate,
  formatValue(tempNormalizer),
  withPrevious,
)(DescriptionItem);

TempDescriptionItem.defaultProps = {
  unit: null,
};

TempDescriptionItem.propTypes = {
  unit: PropTypes.oneOf(['C', 'F']),
};

export default TempDescriptionItem;
