import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { tasksActionCreators } from '../../../../../../../../../../utilities';
import withRunTask from './withRunTask';

const mapDispatchToProps = {
  onRunTask: tasksActionCreators.triggerTask,
};

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  withRunTask,
);
