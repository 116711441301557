import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { layoutPanesType } from 'data/types';
import AnimatedScroll from 'helpers/AnimatedScroll/AnimatedScroll';
import TabbedLayoutMenu from './TabbedLayoutMenu/TabbedLayoutMenuContainer';
import TabbedLayoutPanes from './TabbedLayoutPanes/TabbedLayoutPanesContainer';

const TabbedLayout = ({ menu, panes, resourceId }) => (
  <Fragment>
    <AnimatedScroll>
      <TabbedLayoutMenu menu={menu} panes={panes} />
    </AnimatedScroll>
    <TabbedLayoutPanes panes={panes} resourceId={resourceId} />
  </Fragment>
);

TabbedLayout.defaultProps = {
  menu: {
    secondary: true,
    pointing: true,
  },
  panes: [],
  resourceId: null,
};

TabbedLayout.propTypes = {
  menu: PropTypes.object,
  panes: layoutPanesType,
  resourceId: PropTypes.string,
};

export default TabbedLayout;
