import styled from 'styled-components';

import { media } from 'themes/mixins';
import { Button } from 'components/atomic';

export const FixedWidthButton = styled(Button)`
  ${media.sm`width: 100px;`};
`;

export default FixedWidthButton;
