import constants from 'dispatcherConst';

import { CP_PAGEABLE_TYPE_PROJECT } from '../../constants';
import { getControlUIEndpoint } from '..';

export const fetchControlUI = (cpPageableId, cpPageableType = CP_PAGEABLE_TYPE_PROJECT) => ({
  type: constants.FETCH_CONTROL_UI_REQUEST,
  payload: {
    cpPageableId,
    cpPageableType,
    endpoint: getControlUIEndpoint(cpPageableId, cpPageableType),
  },
});

export const fetchControlUISuccess = response => ({
  type: constants.FETCH_CONTROL_UI_SUCCESS,
  payload: { response },
});

export const fetchControlUIFailure = error => ({
  type: constants.FETCH_CONTROL_UI_FAILED,
  payload: { error },
});
