import React from 'react';
import PropTypes from 'prop-types';

import formNames from 'components/form/formNames';
import ToggleFieldForm
  from 'components/form/fieldForms/ToggleFieldForm/ToggleFieldFormContainer';

const DataTableEditableToggle = ({
  field,
  item: { id },
  item,
  onSubmit,
  preventPropagation,
}) => (
  <ToggleFieldForm
    name={field}
    onSubmit={onSubmit(id)}
    form={`${formNames.inlineToggle}_${field}_${id}`}
    initialValues={{
      [field]: item[field],
    }}
    preventPropagation={preventPropagation}
  />
);

DataTableEditableToggle.defaultProps = {
  field: null,
  item: {},
  preventPropagation: false,
};

DataTableEditableToggle.propTypes = {
  field: PropTypes.string,
  item: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  preventPropagation: PropTypes.bool,
};

export default DataTableEditableToggle;
