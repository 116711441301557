class IoModuleInstanceModel {

  constructor(ioModuleInstance = {}) {
    this.id = ioModuleInstance.id;
    this.title = ioModuleInstance.title;
    this.moduleId = ioModuleInstance.moduleId;

    const filterCondition = value => value !== 'title' && value !== 'moduleId' && value !== 'id';
    const getKeys = Object.keys(ioModuleInstance).filter(key => filterCondition(key));

    this.instances = getKeys.map((key, index) => {
      const objectToAdd = ioModuleInstance[key];
      return {
        ...objectToAdd,
        id: index,
      };
    });

  }

  addMatchedModuleTitle(modules) {
    const findModule = modules.find(obj => Number(obj.id) === Number(this.moduleId));
    const { label } = findModule;
    this.moduleTitle = label;
  }

}

export default IoModuleInstanceModel;
