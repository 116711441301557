import { connect } from 'react-redux';

import { actionCreators } from '../utilities';
import { copiedButtonSelector } from '../utilities/selectors';
import PasteButton from './PasteButton';

export const mapDispatchToProps = {
  onPasteButton: actionCreators.pasteButton,
};

export const mapStateToProps = state => ({
  buttonClipboard: copiedButtonSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasteButton);
