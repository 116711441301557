import styled from 'styled-components';

export const StyledControlUIActionGroupWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const ControlUIPageFooterWrapper = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
`;
