import { call } from 'redux-saga/effects';

import DeviceFirmwareFileModel from 'models/DeviceFirmwareFileModel';
import { postPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';

import actionCreators from '../actionCreators';

function* uploadFirmwareFile(action) {
  const {
    endpoint,
    params: {
      values,
      rejectForm,
      resolveForm,
    },
  } = action.payload;

  const { systemFiles, description } = values;
  const fileName = systemFiles[0].name;
  const updatedValues = { description, originalFileName: fileName };
  const body = DeviceFirmwareFileModel.requestBody(updatedValues);

  const { response, error } = yield call(postPath, endpoint, body);

  const enhancedResponse = {
    ...response,
    systemFiles,
    endpoint,
  };

  const params = {
    response: enhancedResponse,
    error,
    resolveForm,
    rejectForm,
    successDisp: actionCreators.uploadFirmwareFileSuccess,
    failDisp: actionCreators.uploadFirmwareFileFailure,
  };

  yield call(processCall, params);
}

export default uploadFirmwareFile;
