import constants from 'dispatcherConst';

import { LinesEnum } from 'components/LogTable/LogTable';

export const initialState = {
  fetching: false,
  lines: LinesEnum.TWO_HUNDRED,
};

const logsSectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_LOGS_REQUEST:
      return { ...state, fetching: true };

    case constants.FETCH_LOGS_SUCCESS:
    case constants.FETCH_LOGS_FAILED:
      return { ...state, fetching: false };

    case constants.LOGS_SELECT_LINES:
      return { ...state, lines: action.payload.lines };

    default:
      return state;
  }
};

export default logsSectionReducer;
