import { compose } from 'redux';
import { connect } from 'react-redux';

import { actionCreators } from '../../../../../utilities/oneTimePassword';
import OneTimePassword from './OneTimePassword';

const mapDispatchToProps = {
  onSubmitOneTimePassword: actionCreators.submitOneTimePassword,
};

export default compose(
  connect(null, mapDispatchToProps),
)(OneTimePassword);
