import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

export const StyledEditButton = styled(Button)`
  /* display: inline-block; */
  margin-left: 10px !important;

  @media only screen and (max-width: 768px) {
    border: solid 1px red;
    display: block !important;
    margin-left: 0px !important;
  }
`;

export const StyledFieldWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 0px;
  height: auto;
  min-height: 35px;

  & .ui.input.start {
    display: block;
    text-align: left;
  }
`;

export const StyledValueWrapper = styled.div`
  min-height: 35px;
  height: auto;
  display: inline-block;
  border: 1px dashed transparent;
  padding: 7px 5px;

  &:hover {
    border-color: #000000;
  }
`;

export const StyledForm = styled.form`
  display: flex; 
  align-items: center;

  && div[role="listbox"] {
    margin-top: 5px;
  }

  && input {
    margin-top: 3px;
  }

  @media only screen and (max-width: 768px) {
    display: block;
  }
`;
