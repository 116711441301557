import React from 'react';
import PropTypes from 'prop-types';

import TableMenu from 'components/DataTable/TableMenu.style';

import { TransferDeviceFirmwareActionLink } from './components';

const FirmwaresActionLinks = ({ chosenItem, ...rest }) => (
  <TableMenu.Item>
    <TableMenu.Item>
      <TransferDeviceFirmwareActionLink item={chosenItem} {...rest} />
    </TableMenu.Item>
  </TableMenu.Item>
);

FirmwaresActionLinks.defaultProps = {
  chosenItem: null,
};

FirmwaresActionLinks.propTypes = {
  chosenItem: PropTypes.object,
};

export default FirmwaresActionLinks;
