import { put, call, takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import { getPath } from 'data/api/requests';
import { STATIC_SCENES_FIELD } from 'helpers/selectors';
import { processCall } from 'helpers/sagaHelper';
import { prepareResponseData, websocketUrlParams } from 'helpers/paginationHelpersClient';
import staticScenesSectionActionCreators from './staticScenesSectionActionCreators';

export function* fetchStaticScenes({ payload }) {
  const { deviceId, endpoint, urlParams, ...rest } = payload;
  const { response, error } = yield call(getPath, endpoint, { urlParams });

  const params = {
    response,
    error,
    successDisp: staticScenesSectionActionCreators.fetchStaticScenesSuccess,
    ...rest,
  };
  yield call(processCall, params);
}

export function* paginateStaticScenes({ payload: { response } }) {
  const urlParams = websocketUrlParams({
    type: STATIC_SCENES_FIELD,
    included: true,
  });

  const enhancedResponse = yield call(prepareResponseData, response, urlParams);
  yield put(staticScenesSectionActionCreators.fetchStaticScenesSuccessPaginated(enhancedResponse));
}

function* staticScenesSectionSaga() {
  yield takeLatest(constants.FETCH_STATIC_SCENES_REQUEST, fetchStaticScenes);
  yield takeLatest(constants.FETCH_STATIC_SCENES_SUCCESS, paginateStaticScenes);
}

export default staticScenesSectionSaga;
