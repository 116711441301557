import styled from 'styled-components';

import TimeFieldLabelled from 'components/form/TimeField/TimeFieldLabelled';
import DatePickerFieldLabelled from 'components/form/DateField/DateFieldLabelled';

export const StyledTimeField = styled(TimeFieldLabelled)`
  width: 140px;
`;

export const StyledDateField = styled(DatePickerFieldLabelled)`
  width: 140px!important;
`;
