import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { singleProjectSuiteSelector, projectSuiteFetchingSelector } from 'helpers/selectors';
import redirectIfNotFound from 'helpers/redirectIfNotFound';
import { actionCreators as usersActionCreators } from 'application/tenant/console/utilities/users';

import ProjectSuite from './ProjectSuite';

export const mapStateToProps = (state, { match: { params: { id: superSiteId } } }) => {
  const { name } = singleProjectSuiteSelector(state, superSiteId) || {};
  const fetching = projectSuiteFetchingSelector(state);

  return {
    name,
    fetching,
  };
};

const mapDispatchToProps = {
  onDeleteInvitation: usersActionCreators.deleteInvitation,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  redirectIfNotFound('name'),
)(ProjectSuite);
