import { connect } from 'react-redux';

import { actionTypeSelector } from 'helpers/selectors';
import { getLockedParams } from './utilities';

import ActionParameters from './ActionParameters';

export const mapStateToProps = (state, { typeId }) => {
  const action = actionTypeSelector(state)(typeId);
  const lockedParams = getLockedParams(action.params);

  return {
    lockedParams,
  };
};

export default connect(mapStateToProps)(ActionParameters);
