import React from 'react';
import PropTypes from 'prop-types';

const DeviceSettingValue = ({ value, render }) => ((render) ? render(value) : <div>{value}</div>);

DeviceSettingValue.defaultProps = {
  render: null,
};

DeviceSettingValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
  render: PropTypes.func,
};

export default DeviceSettingValue;
