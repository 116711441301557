import { compose } from 'redux';
import { connect } from 'react-redux';

import selectors from 'pages/ProjectSuites/utilities/selectors';
import { checkMultiSitesSiteActions } from 'application/tenant/console/site/components/structural/SiteSchedulingTab/components/DayView/components';
import SelectMultiSite from './SelectMultiSite';
import { computeDropdownOptions } from './utilities';

export const mapStateToProps = (state, { resourceId, resourceName }) => {
  const parents = selectors.projectSuiteParentsSelector(state)(resourceId);
  const options = computeDropdownOptions(parents);

  return {
    selectedProjectId: resourceId,
    resourceName,
    options,
  };
};

export default compose(
  checkMultiSitesSiteActions(),
  connect(mapStateToProps),
)(SelectMultiSite);
