import React from 'react';
import PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';

import { StyledSubheader } from './PageHeader.style';

function PageHeader({ title, description, ...rest }) {
  return (
    <Header size="huge" {...rest}>
      {title}
      {description && (
        <StyledSubheader>
          {description}
        </StyledSubheader>
      )}
    </Header>
  );
}


PageHeader.defaultProps = {
  description: null,
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};


export default PageHeader;
