import React from 'react';
import { List } from 'semantic-ui-react';

import { VoucherInput, VoucherDetails } from './components';
import { Container, StyledVoucherInputListContent } from './ManageSubscription.style';

function ManageSubscription() {
  return (
    <Container>
      <List divided verticalAlign="middle">
        <List.Item>
          <StyledVoucherInputListContent>
            <div>
              <List.Header>
                Redeem voucher
              </List.Header>
              <p>If you have a voucher please validate it in the box below.</p>
            </div>
            <VoucherInput />
          </StyledVoucherInputListContent>
          <List.Content>
            <VoucherDetails />
          </List.Content>
        </List.Item>
      </List>
    </Container>
  );
}

export default ManageSubscription;
