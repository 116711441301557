import styled from 'styled-components';

export default styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  li:not(:first-of-type) {
    font-size: 11px;
  }
`;
