import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isNil from 'lodash/isNil';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';

import { actionCreators, selectors } from './utilities';
import PermissionsTable from './PermissionsTable';

export const mapStateToProps = (state, props) => {
  const {
    userId,
    showHeader,
    context = null,
    limitedScope = [],
    isOpen,
  } = props;

  return {
    isLoading: selectors.isLoadingSelector(state, userId),
    isOpen: isOpen || selectors.isOpenSelector(state, userId),
    cumulatedPermissions: selectors.cumulatedPermissionsSelector(state, userId),
    permissionsTableRows: selectors.permissionsTableRowsSelector(state, userId),
    recipients: selectors.recipientsSelector(state, userId),
    selectedRecipients: selectors.selectedRecipientsSelector(state, userId),
    copyRecipientId: selectors.copyRecipientIdSelector(state, userId),

    showHeader,
    resourceId: userId,
    resourceTitle: '',
    resourceName: '',
    resourceDescription: !isNil(context) ? 'All users' : '',
    resourceType: 'user',
    recipientType: 'user',
    limitedScope,
    limitedFor: ['user'],
    context,
    withRecipientSelect: false,
  };
};

const mapDispatchToProps = {
  onFetchCumulatedPermissions: actionCreators.fetchCumulatedPermissions,
  onGrantPermissions: actionCreators.grantMultiplePermissions,
  onRevokePermissions: actionCreators.revokeMultiplePermissions,
  onCopyPermissions: actionCreators.copyMultiplePermissions,
  onPastePermissions: actionCreators.pasteMultiplePermissions,
  onClearPermissionsTable: actionCreators.clearPermissionsTable,
  onToggleResourceOpen: actionCreators.toggleResourceOpen,
  onChangeRecipients: actionCreators.changePermissionsTableRecipients,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  chainedLoaderWithSaga(
    ({
      resourceId,
      resourceType,
      context,
    }) => (
      actionCreators.fetchPermissionsTableRecipients({
        resourceId,
        resourceType,
        context,
      })
    ),
  ),
)(PermissionsTable);
