import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/DataTable/Table.style';
import { applyVocabulary } from 'helpers/sharedMethods';
import NotificationToggle from '../NotificationToggle/NotificationToggleContainer';

const NotificationRow = ({
  notificationType,
  entity,
  userId,
  notificationsEnabled,
}) => (
  <Table.Row>
    <Table.Cell>{applyVocabulary(notificationType.notificationContext)}</Table.Cell>
    <Table.Cell>{entity.name}</Table.Cell>
    <Table.Cell>
      <NotificationToggle
        context={notificationType.notificationContext}
        entityId={entity.id}
        notificationTypeId={notificationType.id}
        userId={userId}
        frequency="instant"
      />
    </Table.Cell>
    {notificationsEnabled && (
      <>
        <Table.Cell>
          <NotificationToggle
            context={notificationType.notificationContext}
            entityId={entity.id}
            notificationTypeId={notificationType.id}
            userId={userId}
            frequency="daily"
          />
        </Table.Cell>
        <Table.Cell>
          <NotificationToggle
            context={notificationType.notificationContext}
            entityId={entity.id}
            notificationTypeId={notificationType.id}
            userId={userId}
            frequency="weekly"
          />
        </Table.Cell>
      </>
    )}
  </Table.Row>
);

NotificationRow.defaultProps = {
  notificationsEnabled: false,
};

NotificationRow.propTypes = {
  notificationType: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    notificationContext: PropTypes.string.isRequired,
  }).isRequired,
  entity: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  userId: PropTypes.string.isRequired,
  notificationsEnabled: PropTypes.bool,
};

export default NotificationRow;
