import React, { useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import pluralize from 'pluralize';
import { Form, Grid } from 'semantic-ui-react';

import { DatePicker, TimePicker } from 'components/core';
import { SelectedTimesView } from 'components/core/TimePicker/components';
import ThemedToggle from 'components/elements/ThemedToggle/ThemedToggle.style';

import { EndingCriteria } from '..';
import { AdvancedOptions } from './components';
import { SCHEDULER_TYPES } from '../../../../../constants';
import { Container, StyledLabel, StyledRow, StyledInput, ErrorMessage } from './Monthly.style';

function Monthly({
  recurrenceErrors,
  recurrenceParams,
  existingSchedule,
  onRecurrenceParamsChange,
  onEndingCriteriaChange,
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [monthInterval, setMonthInterval] = useState(1);
  const [timeInterval, setTimeInterval] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endingCriteria, setEndingCriteria] = useState({});
  const [monthlyConditions, setMonthlyConditions] = useState({});
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

  useEffect(() => {
    if (existingSchedule && !isLoaded) {
      const { type, params } = existingSchedule || {};

      if (type.toUpperCase() === SCHEDULER_TYPES.MONTHLY) {
        const {
          everyNMonths,
          startDate: existingStartDate,
          condition,
          startTimes,
        } = params;

        setMonthInterval(everyNMonths);
        setTimeInterval(startTimes);
        setStartDate(moment(existingStartDate));

        if (condition) {
          setShowAdvancedOptions(true);
        }

        setIsLoaded(true);
      }
    }
  }, [isLoaded, existingSchedule, recurrenceParams]);

  useEffect(() => {
    onRecurrenceParamsChange({
      everyNMonths: Number(monthInterval),
      startDate: startDate && startDate.format('YYYY-MM-DD'),
      startTimes: timeInterval,
      ...monthlyConditions,
      ...endingCriteria.params,
    });
  }, [
    monthInterval,
    timeInterval,
    startDate,
    monthlyConditions,
    endingCriteria,
    onRecurrenceParamsChange,
  ]);

  const monthDescription = useMemo(() => `${pluralize('month', Number(monthInterval))} at`, [monthInterval]);

  const handleEditingCriteriaChange = useCallback(value => {
    setEndingCriteria(value);

    onEndingCriteriaChange(value.type);
  }, [onEndingCriteriaChange]);

  const handleTimeSelected = useCallback(newTimes => {
    setTimeInterval(newTimes);
  }, []);

  const handleClearTimes = useCallback(() => {
    setTimeInterval([]);
  }, []);

  const handleRemoveTime = useCallback(deletedTime => {
    setTimeInterval(previousTimes => previousTimes.filter(time => time !== deletedTime));
  }, []);

  const toggleAdvancedOptions = useCallback((event, { checked }) => {
    if (!checked) {
      setMonthlyConditions({});
    }

    setShowAdvancedOptions(checked);
  }, []);

  const handleAdvancedOptionsChange = useCallback(newMonthlyCondition => {
    setMonthlyConditions(newMonthlyCondition);
  }, []);

  return (
    <Container>
      <Form size="large">
        <Grid>
          <StyledRow noPaddingBottom>
            <Grid.Column computer={6} mobile={8}>
              <Form.Field>
                <StyledLabel withCustomPadding>
                  Repeat every
                </StyledLabel>
                <StyledInput
                  name="month_interval"
                  type="number"
                  size="mini"
                  min="1"
                  value={monthInterval}
                  onChange={e => setMonthInterval(e.target.value)}
                  label={{
                    basic: true,
                    content: monthDescription,
                  }}
                  labelPosition="right"
                  required
                />
                <ErrorMessage>{recurrenceErrors.everyNMonths}</ErrorMessage>
              </Form.Field>
            </Grid.Column>
            <Grid.Column computer={10} mobile={8}>
              <Form.Field>
                <StyledLabel withCustomPadding>
                  Times
                </StyledLabel>
                <TimePicker
                  name="start_times"
                  popupPosition="right center"
                  selectedTimes={timeInterval}
                  onChange={handleTimeSelected}
                  keepOpenedAfterSubmitFor={['SIMPLE']}
                  defaultEmptyMessage="Add time(s)"
                />
                <ErrorMessage>{recurrenceErrors.startTimes}</ErrorMessage>
              </Form.Field>
            </Grid.Column>
          </StyledRow>
          <StyledRow noPaddingBottom>
            <Grid.Column computer={16} mobile={16}>
              <Form.Field>
                <SelectedTimesView
                  selectedTimes={timeInterval}
                  onClearTimes={handleClearTimes}
                  onRemoveTime={handleRemoveTime}
                  hideWhenEmpty
                />
              </Form.Field>
            </Grid.Column>
          </StyledRow>
          <StyledRow>
            <Grid.Column computer={6} mobile={8}>
              <Form.Field>
                <StyledLabel withCustomPadding>
                  Starting on
                </StyledLabel>
                <DatePicker
                  name="start_date"
                  value={startDate}
                  min={moment().toDate()}
                  onChange={value => setStartDate(value)}
                  footer={false}
                  popupPosition="left center"
                  defaultEmptyMessage="Select a date."
                />
                <ErrorMessage>{recurrenceErrors.startDate}</ErrorMessage>
              </Form.Field>
            </Grid.Column>
            <Grid.Column computer={8} mobile={8}>
              <Form.Field>
                <StyledLabel withCustomPadding>
                  Advanced Options
                </StyledLabel>
                <ThemedToggle
                  className="advanced-options-toggle"
                  checked={showAdvancedOptions}
                  onChange={toggleAdvancedOptions}
                />
              </Form.Field>
            </Grid.Column>
          </StyledRow>
          {showAdvancedOptions && (
            <AdvancedOptions
              onChange={handleAdvancedOptionsChange}
              existingSchedule={existingSchedule}
            />
          )}
          <EndingCriteria
            onChange={handleEditingCriteriaChange}
          />
        </Grid>
      </Form>
    </Container>
  );
}

Monthly.defaultProps = {
  recurrenceErrors: {},
  recurrenceParams: {},
  existingSchedule: null,
};

Monthly.propTypes = {
  recurrenceErrors: PropTypes.object,
  recurrenceParams: PropTypes.object,
  existingSchedule: PropTypes.object,
  onRecurrenceParamsChange: PropTypes.func.isRequired,
  onEndingCriteriaChange: PropTypes.func.isRequired,
};

export default Monthly;
