import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isNil from 'lodash/isNil';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { companySelector } from 'helpers/selectors';

import { actionCreators, selectors } from './utilities';
import PermissionsTable from './PermissionsTable';

export const mapStateToProps = (state, props) => {
  const {
    companyId,
    childResourceIds,
    showHeader,
    context,
    limitedScope,
    isOpen,
  } = props;

  const { name } = companySelector(state)(companyId);

  return {
    isLoading: selectors.isLoadingSelector(state, companyId),
    isOpen: isOpen || selectors.isOpenSelector(state, companyId),
    cumulatedPermissions: selectors.cumulatedPermissionsSelector(state, companyId),
    permissionsTableRows: selectors.permissionsTableRowsSelector(state, companyId),
    recipients: selectors.recipientsSelector(state, companyId),
    selectedRecipients: selectors.selectedRecipientsSelector(state, companyId),
    copyRecipientId: selectors.copyRecipientIdSelector(state, companyId),

    showHeader,
    resourceId: companyId,
    childResourceIds,
    resourceTitle: 'Company',
    resourceName: name,
    resourceDescription: !isNil(context) ? 'All companies' : '',
    resourceType: 'tenant',
    context: null,
    recipientType: 'user',
    limitedScope: limitedScope || [],
    limitedFor: ['user'],
  };
};

const mapDispatchToProps = {
  onFetchCumulatedPermissions: actionCreators.fetchCumulatedPermissions,
  onGrantPermissions: actionCreators.grantMultiplePermissions,
  onRevokePermissions: actionCreators.revokeMultiplePermissions,
  onCopyPermissions: actionCreators.copyMultiplePermissions,
  onPastePermissions: actionCreators.pasteMultiplePermissions,
  onClearPermissionsTable: actionCreators.clearPermissionsTable,
  onToggleResourceOpen: actionCreators.toggleResourceOpen,
  onChangeRecipients: actionCreators.changePermissionsTableRecipients,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  chainedLoaderWithSaga(
    ({
      resourceId,
      resourceType,
      context,
    }) => (
      actionCreators.fetchPermissionsTableRecipients({
        resourceId,
        resourceType,
        context,
      })
    ),
  ),
)(PermissionsTable);
