import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  computedLogicalDeviceDataPermissionsTreeSelector,
} from 'helpers/selectors';
import { PermissionsTree } from '../../../../components';
import { sortPaths } from '../../../../utilities';
import { DevicePermissionsTreeItem } from '../PermissionsTreeItem';

export const mapStateToProps = (state, { resourceId }) => {
  const {
    permissionCount,
    ...tree
  } = computedLogicalDeviceDataPermissionsTreeSelector(state)(resourceId);
  const paths = sortPaths(tree);

  return {
    paths,
    PermissionsTreeItem: DevicePermissionsTreeItem,
  };
};

export default compose(
  connect(mapStateToProps),
)(PermissionsTree);
