import { createSelector } from 'reselect';

import schedulingSelector from './schedulingSelector';

const schedulesUpdatingSelector = createSelector(
  schedulingSelector,
  scheduling => scheduling.updating,
);

export default schedulesUpdatingSelector;
