const getAllPermissionsWithRecipientsMap = ({
  permissionsNames,
  recipientIds,
}) => {
  const transformed = permissionsNames.reduce((acc, permissionName) => {
    acc[permissionName] = {};

    recipientIds.forEach(recipientId => {
      acc[permissionName][recipientId] = false;
    });

    return acc;
  }, {});

  return transformed;
};

export default getAllPermissionsWithRecipientsMap;
