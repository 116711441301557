import { connect } from 'react-redux';

import { actionCreators, selectors } from '../../../../utilities';
import SpecificDates from './SpecificDates';

export const mapStateToProps = state => ({
  recurrenceErrors: selectors.recurrenceErrorsSelector(state),
  recurrenceParams: selectors.recurrenceParamsSelector(state),
  existingSchedule: selectors.existingScheduleSelector(state),
});

const mapDispatchToProps = {
  onRecurrenceParamsChange: actionCreators.setRecurrenceParams,
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecificDates);
