import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { projectSelector } from 'helpers/selectors';
import automatedOperationsActionCreators from '../../utilities/actionCreators';
import AutomatedOperationsAccordionRow from './AutomatedOperationsAccordionRow';

export const mapStateToProps = (state, { item }) => {
  const { projectId } = item;
  const { name: projectName } = projectSelector(state)(projectId) || {};
  const labelName = projectName || 'Multi-site';

  return {
    labelName,
  };
};

const mapDispatchToProps = {
  onUpdateAutomatedOperation: automatedOperationsActionCreators.updateAutomatedOperation,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AutomatedOperationsAccordionRow);
