import { connect } from 'react-redux';

import { deepEqualMemoizeOne } from 'helpers/sharedMethods';
import { currentUserSelector } from 'helpers/selectors';
import { actionCreators } from '../../../../utilities/users';
import AttributesTab from './AttributesTab';

const makeMapState = () => {
  const memoAttributes = deepEqualMemoizeOne();

  return function mapStateToProps(state) {
    const attributes = currentUserSelector(state);

    return {
      attributes: memoAttributes(attributes),
    };
  };
};

const mapDispatchToProps = {
  onEditProfile: actionCreators.editProfile,
};

export default connect(makeMapState, mapDispatchToProps)(AttributesTab);
