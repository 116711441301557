import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Icon, Select } from 'semantic-ui-react';

import { selectOptionsType } from 'data/types';
import { getInlineFormName } from 'helpers/sharedMethods';
import {
  validDeviceMapping,
  validSubdomain,
  validCompanyName,
  validEmail,
  isValidPeriodDaysNumber,
} from 'components/form/formValidators';
import InlineEdit from 'components/form/InlineEdit/InlineEditContainer';
import TableMenu from 'components/DataTable/TableMenu.style';
import MailgunDomainSelect from 'components/MailgunDomainSelect/MailgunDomainSelect';
import { companyFormFields } from '../constants';
import { DetailListItem, NameInlineEdit } from './Company.style';
import {
  OriginTable,
  TwoFactorToggle,
  VouchersToggle,
  CompanyDatePickerField,
  NotificationsToggle,
} from './components';

class Company extends Component {

  constructor() {
    super();

    this.handleEditCompany = this.handleEditCompany.bind(this);
    this.handleChangeText = this.handleChangeText.bind(this);
    this.handleChangeTheme = this.handleChangeTheme.bind(this);
    this.handleChangeMailgunDomain = this.handleChangeMailgunDomain.bind(this);
    this.handleChangeTwoFactorEnabled = this.handleChangeTwoFactorEnabled.bind(this);
    this.handleChangeVouchersEnabled = this.handleChangeVouchersEnabled.bind(this);
    this.handleChangeRenewalDate = this.handleChangeRenewalDate.bind(this);
    this.handleChangeNotificationsEnabled = this.handleChangeNotificationsEnabled.bind(this);
    this.handleChangeSoldVia = this.handleChangeSoldVia.bind(this);
  }

  handleEditCompany(values) {
    const {
      companyId: id,
      deviceMapping,
      manufacturer,
      name,
      registeredName,
      email,
      tenantService,
      subdomain,
      themeName,
      mailgunDomain,
      onEditCompany,
      trialPeriodDays,
      gracePeriodDays,
      renewalReminderDays,
      vouchersEnabled,
      renewalDate,
      renewalReminderDaysTwo,
      notificationsEnabled,
      soldVia,
    } = this.props;

    return new Promise((resolveForm, rejectForm) => {
      onEditCompany({
        values: { id, ...values },
        initialValues: {
          deviceMapping,
          manufacturer,
          name,
          registeredName,
          email,
          tenantService,
          subdomain,
          themeName,
          mailgunDomain,
          trialPeriodDays,
          gracePeriodDays,
          renewalReminderDays,
          vouchersEnabled,
          renewalDate,
          renewalReminderDaysTwo,
          notificationsEnabled,
          soldVia,
        },
        resolveForm,
        rejectForm,
      });
    });
  }

  handleChangeText() {
    return inlineFormValues => this.handleEditCompany(inlineFormValues);
  }

  handleChangeTheme() {
    return (event, { value }) => this.handleEditCompany({ themeName: value });
  }

  handleChangeMailgunDomain(mailgunDomain) {
    return this.handleEditCompany({ mailgunDomain });
  }

  handleChangeTwoFactorEnabled({ twoFactorEnabled }) {
    return this.handleEditCompany({ twoFactorEnabled });
  }

  handleChangeVouchersEnabled({ vouchersEnabled }) {
    return this.handleEditCompany({ vouchersEnabled });
  }

  handleChangeRenewalDate(value) {
    return this.handleEditCompany({ renewalDate: value });
  }

  handleChangeNotificationsEnabled({ notificationsEnabled }) {
    return this.handleEditCompany({ notificationsEnabled });
  }

  handleChangeSoldVia() {
    return (event, { value }) => this.handleEditCompany({ soldVia: value });
  }

  render() {
    const {
      themes,
      companyId,
      deviceMapping,
      manufacturer,
      name,
      registeredName,
      email,
      tenantService,
      subdomain,
      themeName,
      mailgunDomain,
      twoFactorEnabled,
      trialPeriodDays,
      gracePeriodDays,
      renewalReminderDays,
      vouchersEnabled,
      renewalReminderDaysTwo,
      renewalDate,
      notificationsEnabled,
      soldVia,
      soldViaOptions,
    } = this.props;

    if (!name) {
      return null;
    }

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header as="h2">
              <NameInlineEdit
                value={name}
                name={companyFormFields.name}
                type="text"
                validator={validCompanyName}
                form={getInlineFormName(companyFormFields.name)}
                onSubmit={this.handleChangeText()}
              />
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} computer={8}>
            <TableMenu>
              <TableMenu.Item>
                <Header as="h4">Basic information</Header>
              </TableMenu.Item>
            </TableMenu>
            <DetailListItem
              title="Subdomain"
              value={subdomain}
              forwardedAs={(
                <InlineEdit
                  name={companyFormFields.subdomain}
                  type="text"
                  validator={validSubdomain}
                  form={getInlineFormName(companyFormFields.subdomain)}
                  onSubmit={this.handleChangeText()}
                />
              )}
            />
            <DetailListItem
              title="Theme"
              value={themeName}
              forwardedAs={(
                <Select
                  selectOnBlur={false}
                  selectOnNavigation={false}
                  options={themes}
                  onChange={this.handleChangeTheme()}
                />
              )}
            />
            <DetailListItem
              title="Mailgun domain"
              value={mailgunDomain}
              forwardedAs={(
                <MailgunDomainSelect onChange={this.handleChangeMailgunDomain} />
              )}
            />
            <DetailListItem
              title="Registered name"
              value={registeredName}
              forwardedAs={(
                <InlineEdit
                  name={companyFormFields.registeredName}
                  type="text"
                  validator={validCompanyName}
                  form={getInlineFormName(companyFormFields.registeredName)}
                  onSubmit={this.handleChangeText()}
                />
              )}
            />
            <DetailListItem
              title="Email"
              value={email}
              forwardedAs={(
                <InlineEdit
                  name={companyFormFields.email}
                  type="text"
                  validator={validEmail}
                  form={getInlineFormName(companyFormFields.email)}
                  onSubmit={this.handleChangeText()}
                />
              )}
            />
            <DetailListItem
              title="Tenant service"
              value={tenantService}
              forwardedAs={(
                <InlineEdit
                  name={companyFormFields.tenantService}
                  type="text"
                  validator={validCompanyName}
                  form={getInlineFormName(companyFormFields.tenantService)}
                  onSubmit={this.handleChangeText()}
                />
              )}
            />
            <DetailListItem
              title="Two Factor Authentication"
              value={twoFactorEnabled}
              forwardedAs={(
                <TwoFactorToggle
                  name={companyFormFields.twoFactorEnabled}
                  onSubmit={this.handleChangeTwoFactorEnabled}
                  checked={twoFactorEnabled}
                />
              )}
            />
            <DetailListItem
              title="Vouchers Enabled"
              value={vouchersEnabled}
              forwardedAs={(
                <VouchersToggle
                  name={companyFormFields.vouchersEnabled}
                  onSubmit={this.handleChangeVouchersEnabled}
                  checked={vouchersEnabled}
                />
              )}
            />
            <DetailListItem
              title="Notifications Enabled"
              value={notificationsEnabled}
              forwardedAs={(
                <NotificationsToggle
                  name={companyFormFields.notificationsEnabled}
                  onSubmit={this.handleChangeNotificationsEnabled}
                  checked={notificationsEnabled}
                />
              )}
            />
            <DetailListItem
              title="Trial period"
              value={trialPeriodDays}
              forwardedAs={(
                <InlineEdit
                  name={companyFormFields.trialPeriodDays}
                  type="text"
                  form={getInlineFormName(companyFormFields.trialPeriodDays)}
                  onSubmit={this.handleChangeText()}
                  validator={isValidPeriodDaysNumber}
                />
              )}
            />
            <DetailListItem
              title="Grace period"
              value={gracePeriodDays}
              forwardedAs={(
                <InlineEdit
                  name={companyFormFields.gracePeriodDays}
                  type="text"
                  form={getInlineFormName(companyFormFields.gracePeriodDays)}
                  onSubmit={this.handleChangeText()}
                  validator={isValidPeriodDaysNumber}
                />
              )}
            />
            <DetailListItem
              title="Portal Renewal Date"
              value={renewalDate}
              forwardedAs={(
                <CompanyDatePickerField
                  value={renewalDate}
                  onChange={this.handleChangeRenewalDate}
                />
              )}
            />
            <DetailListItem
              title="Renewal reminder days"
              value={renewalReminderDays}
              forwardedAs={(
                <InlineEdit
                  name={companyFormFields.renewalReminderDays}
                  type="text"
                  form={getInlineFormName(companyFormFields.renewalReminderDays)}
                  onSubmit={this.handleChangeText()}
                  validator={isValidPeriodDaysNumber}
                />
              )}
            />
            <DetailListItem
              title="Second renewal reminder days"
              value={renewalReminderDaysTwo}
              forwardedAs={(
                <InlineEdit
                  name={companyFormFields.renewalReminderDaysTwo}
                  type="text"
                  form={getInlineFormName(companyFormFields.renewalReminderDaysTwo)}
                  onSubmit={this.handleChangeText()}
                  validator={isValidPeriodDaysNumber}
                />
              )}
            />
            <DetailListItem
              title="Manufacturer"
              value={manufacturer ? 'check' : 'remove'}
              forwardedAs={<Icon />}
              valueField="name"
              center
            />
            {manufacturer && (
              <DetailListItem
                title="SDK Mapping"
                value={deviceMapping}
                forwardedAs={(
                  <InlineEdit
                    name={companyFormFields.deviceMapping}
                    type="text"
                    validator={validDeviceMapping}
                    form={getInlineFormName(companyFormFields.deviceMapping)}
                    onSubmit={this.handleChangeText()}
                  />
                )}
              />
            )}
            <DetailListItem
              title="Sold via"
              value={soldVia}
              forwardedAs={(
                <Select
                  selectOnBlur={false}
                  selectOnNavigation={false}
                  options={soldViaOptions}
                  onChange={this.handleChangeSoldVia()}
                />
              )}
            />
          </Grid.Column>
          <Grid.Column mobile={16} computer={8}>
            <OriginTable companyId={companyId} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

}

Company.defaultProps = {
  deviceMapping: null,
  manufacturer: false,
  name: null,
  registeredName: null,
  email: null,
  tenantService: null,
  subdomain: null,
  themeName: null,
  mailgunDomain: null,
  twoFactorEnabled: false,
  trialPeriodDays: null,
  gracePeriodDays: null,
  renewalReminderDays: null,
  vouchersEnabled: false,
  renewalDate: null,
  renewalReminderDaysTwo: null,
  notificationsEnabled: false,
  soldVia: '',
};

Company.propTypes = {
  companyId: PropTypes.string.isRequired,
  deviceMapping: PropTypes.string,
  manufacturer: PropTypes.bool,
  name: PropTypes.string,
  registeredName: PropTypes.string,
  email: PropTypes.string,
  tenantService: PropTypes.string,
  subdomain: PropTypes.string,
  themeName: PropTypes.string,
  mailgunDomain: PropTypes.string,
  twoFactorEnabled: PropTypes.bool,
  // eslint-disable-next-line react/no-typos
  themes: selectOptionsType.isRequired,
  onEditCompany: PropTypes.func.isRequired,
  trialPeriodDays: PropTypes.number,
  gracePeriodDays: PropTypes.number,
  renewalReminderDays: PropTypes.number,
  vouchersEnabled: PropTypes.bool,
  renewalDate: PropTypes.string,
  renewalReminderDaysTwo: PropTypes.number,
  notificationsEnabled: PropTypes.bool,
  soldVia: PropTypes.string,
  soldViaOptions: selectOptionsType.isRequired,
};

export default Company;
