import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import { FaTools } from 'react-icons/fa';

const InConstruction = ({ message, color }) => (
  <Popup
    content={message}
    trigger={(
      <FaTools size={30} color={color} />
    )}
    position="bottom right"
  />
);

InConstruction.defaultProps = {
  color: '#818181',
};

InConstruction.propTypes = {
  message: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default InConstruction;
