import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { deepEqualMemoizeOne } from 'helpers/sharedMethods';
import { permissionsStateSelector } from 'helpers/selectors';

import PermissionsProjectSuitesTab from './PermissionsProjectSuitesTab';

export const makeMapState = () => {
  const memoProjectSuiteIds = deepEqualMemoizeOne();

  return function mapStateToProps(state) {
    const { projectSuites, contexts } = permissionsStateSelector(state);

    const nonContextProjectSuites = Object.keys(projectSuites).filter(
      projectSuiteId => !(projectSuiteId in contexts),
    );

    return {
      projectSuiteIds: memoProjectSuiteIds(nonContextProjectSuites),
    };
  };
};

export default compose(
  withRouter,
  connect(makeMapState),
)(PermissionsProjectSuitesTab);
