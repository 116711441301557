import isNil from 'lodash/isNil';
import isDate from 'lodash/isDate';

const processLogsParamsError = ({ dateFrom, dateTo }) => {
  if (isNil(dateFrom) || isNil(dateTo)) {
    return false;
  }

  if (isDate(dateFrom) && isDate(dateTo)) {
    return dateFrom.setHours(0, 0, 0, 0) > dateTo.setHours(0, 0, 0, 0);
  }

  return false;
};

export default processLogsParamsError;
