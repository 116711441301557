import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import endpoints from 'data/api/endpoints';
import { deviceSelector, TRIGGERS_FIELD } from 'helpers/selectors';
import triggersActionCreators from 'pages/Device/triggersActionCreators';
import withPolling from 'helpers/withPolling';
import chainedLoader from 'helpers/chainedLoader/chainedLoaderContainerWithPagination';
import { enhanceWithParams } from 'helpers/paginationHelpers';
import TriggersCondSection from './TriggersCondSection';

export const REFRESH_RATE_IN_SECONDS = 60;

export const mapStateToProps = (state, ownProps) => {
  const { deviceId } = ownProps.match.params;
  const resource = deviceSelector(state)(deviceId);

  return {
    deviceId,
    chainedLoaderProps: {
      [TRIGGERS_FIELD]: {
        link: endpoints.LOGICAL_DEVICE_TRIGGERS(deviceId),
      },
    },
    pollingValue: REFRESH_RATE_IN_SECONDS,
    resource,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withPolling(REFRESH_RATE_IN_SECONDS, 'resource.online'),
  chainedLoader(
    { name: TRIGGERS_FIELD, included: true },
    enhanceWithParams(({ deviceId }) => triggersActionCreators.fetchTriggers(deviceId, {
      sort: 'name',
    })),
  ),
)(TriggersCondSection);
