import { take, put, select, call, takeLatest, takeEvery } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import { TENANT } from 'storageConst';
import { getPath, postPath } from 'data/api/requests';
import {
  CONTEXTS_FIELD,
  contextSelector,
  permissionsStatePermissionsLoadedSelector,
  logicalDeviceResourcePermissionPathsSelector,
  projectResourcePermissionPathsSelector,
  userResourcePermissionPathsSelector,
  permissionsSelector,
  permissionSelector,
  permissionsProjectUsersLinkSelector,
  projectSuiteResourcePermissionPathsSelector,
} from 'helpers/selectors';
import { mapResourceType } from 'helpers/sharedMethods';
import { processCall } from 'helpers/sagaHelper';
import endpoints from 'data/api/endpoints';
import { prepareResponseData } from 'helpers/paginationHelpersClient';
import { replaceUserPermissions } from './permissionsHelpers';
import permissionsActionCreators from './permissionsActionCreators';
import selectors from './selectors';
import { filterProjectRelatedPermissions } from './utilities';

export const TENANT_CONTEXT_NAME = 'Portal';
export const TENANT_CONTEXT_ID = 'rg-456-j';

export function* fetchIndexPermissions(action) {
  const { endpoint, ...rest } = action.payload;
  const { response, error } = yield call(getPath, endpoint);

  const params = {
    response,
    error,
    successDisp: permissionsActionCreators.fetchIndexPermissionsSuccess,
    failDisp: permissionsActionCreators.fetchIndexPermissionsFailure,
    ...rest,
  };
  yield call(processCall, params);
}

export function* fetchContexts(action) {
  const { urlParams, nextInChain, resourceType } = action.payload;
  const link = `/${CONTEXTS_FIELD}`;
  const tenantContext = {
    id: TENANT_CONTEXT_ID,
    attributes: {
      name: TENANT,
      displayName: TENANT_CONTEXT_NAME,
    },
    relationships: {
      users: {
        links: {
          related: endpoints.USERS,
        },
      },
    },
  };
  const response = {
    [CONTEXTS_FIELD]: {
      [TENANT_CONTEXT_ID]: tenantContext,
    },
    data: [tenantContext],
    meta: {
      [link]: {
        data: [tenantContext],
        meta: {},
      },
    },
  };

  const params = {
    response,
    error: undefined,
    successDisp: permissionsActionCreators.fetchContextsSuccess,
    failDisp: permissionsActionCreators.fetchContextsFailure,
    resourceType,
  };
  yield call(processCall, params);

  if (response) {
    const enhancedResponse = yield call(prepareResponseData, response, urlParams);
    yield put(permissionsActionCreators.fetchContextsSuccessPaginated(enhancedResponse));
  }

  if (nextInChain) {
    yield put(nextInChain);
  }
}

export function* addProjectId(action) {
  const { nextInChain } = action.payload;

  if (nextInChain) {
    yield put(nextInChain);
  }
}

export function* addProjectSuiteId(action) {
  const { nextInChain } = action.payload;

  if (nextInChain) {
    yield put(nextInChain);
  }
}

export function* addContextId(action) {
  const { contextId, nextInChain } = action.payload;

  const permissionsLoaded = yield select(permissionsStatePermissionsLoadedSelector);
  if (!permissionsLoaded) {
    yield take(constants.FETCH_INDEX_PERMISSIONS_SUCCESS);
  }

  const contextUsersLink = (yield select(permissionsProjectUsersLinkSelector))(contextId);
  const projectPaths = yield select(projectResourcePermissionPathsSelector);
  const projectSuitePaths = yield select(projectSuiteResourcePermissionPathsSelector);
  const logicalDevicePaths = yield select(logicalDeviceResourcePermissionPathsSelector);
  const userPaths = yield select(userResourcePermissionPathsSelector);

  yield put(permissionsActionCreators.addContextIdWithPaths(
    contextId,
    contextUsersLink,
    projectPaths,
    logicalDevicePaths,
    userPaths,
    projectSuitePaths,
  ));

  if (nextInChain) {
    yield put(nextInChain);
  }
}

export function* fetchProjectUsers(action) {
  const { projectId, endpoint, urlParams, ...rest } = action.payload;
  const { response, error } = yield call(getPath, endpoint, { urlParams });

  const responseWithMetadata = error ? undefined : { ...response, projectId };

  const params = {
    response: responseWithMetadata,
    error,
    successDisp: permissionsActionCreators.fetchProjectUsersSuccess,
    failDisp: permissionsActionCreators.fetchProjectUsersFailure,
    ...rest,
  };
  yield call(processCall, params);
}

export function* fetchProjectSuiteUsers(action) {
  const { projectSuiteId, endpoint, urlParams, ...rest } = action.payload;
  const { response, error } = yield call(getPath, endpoint, { urlParams });

  const responseWithMetadata = error ? undefined : { ...response, projectSuiteId };

  const params = {
    response: responseWithMetadata,
    error,
    successDisp: permissionsActionCreators.fetchProjectSuiteUsersSuccess,
    failDisp: permissionsActionCreators.fetchProjectSuiteUsersFailure,
    ...rest,
  };
  yield call(processCall, params);
}

export function* fetchProjectLogicalDevices(action) {
  const { projectId, endpoint, urlParams, ...rest } = action.payload;
  const logicalDevicePaths = yield select(logicalDeviceResourcePermissionPathsSelector);
  const { response, error } = yield call(getPath, endpoint, { urlParams });

  const responseWithMetadata = error ? undefined : { ...response, projectId, logicalDevicePaths };

  const params = {
    response: responseWithMetadata,
    error,
    successDisp: permissionsActionCreators.fetchProjectLogicalDevicesSuccess,
    failDisp: permissionsActionCreators.fetchProjectLogicalDevicesFailure,
    ...rest,
  };
  yield call(processCall, params);
}

export function* fetchUserPermissions(action) {
  const { userId, resourceType, resourceId, endpoint, ...rest } = action.payload;
  const urlParams = {
    userId,
    resourceType: mapResourceType(resourceType),
    resourceId,
  };

  const {
    response,
    error,
  } = yield call(getPath, endpoint, { urlParams });

  const responseWithMetadata = error
    ? undefined
    : {
      ...response,
      ...urlParams,
    };

  const params = {
    response: responseWithMetadata,
    error,
    successDisp: permissionsActionCreators.fetchUserPermissionsSuccess,
    failDisp: permissionsActionCreators.fetchUserPermissionsFailure,
    ...rest,
  };
  yield call(processCall, params);
}

export function* toggleUserPermission(action) {
  const { userId, resourceType, resourceId, permissionId, endpoint } = action.payload;
  const { resource, permissionName } = (yield select(permissionSelector))(permissionId);

  const body = {
    recipientId: userId,
    name: `${resource}:${permissionName}`,
    resourceId,
  };
  const { response, error } = yield call(postPath, endpoint, body);
  const responseWithMetadata = error
    ? undefined
    : {
      ...response,
      userId,
      resourceType,
      resourceId,
      permissionId,
    };

  const params = {
    response: responseWithMetadata,
    error,
    successDisp: permissionsActionCreators.toggleUserPermissionSuccess,
    failDisp: permissionsActionCreators.toggleUserPermissionFailure,
  };
  yield call(processCall, params);
}

export function* setUserPermissions(
  isAddingPermissions,
  userPermissionsList,
  path,
  permissionResourceType,
  isProject = false,
) {
  const colonPath = path.replace('.', ':');
  const names = userPermissionsList.map(({ name }) => name);

  if (isAddingPermissions) {
    const permissions = yield select(permissionsSelector);
    const permissionsWithPath = permissions.filter(
      ({
        permissionName,
        resource,
        limitedFor,
      }) => (
        permissionName.startsWith(colonPath)
        && resource === permissionResourceType
        && limitedFor.includes('user')
      ),
    );

    const namesToAdd = filterProjectRelatedPermissions(
      permissionsWithPath, permissionResourceType, isProject,
    );

    const newNames = [
      ...names,
      ...namesToAdd,
    ];

    return newNames.map(name => `${permissionResourceType}:${name}`);
  }

  return names
    .filter(name => !name.startsWith(colonPath))
    .map(name => `${permissionResourceType}:${name}`);
}

export function* toggleUserHeaderPermission(action) {
  const {
    userId,
    resourceType,
    resourceId,
    path,
    isOn,
    isPartial,
    endpoint,
    isProject,
    ...rest
  } = action.payload;
  const isAddingPermissions = !isOn || isPartial;
  const userPermissions = (yield select(selectors.userPermissionsSelector))(userId);

  const permissionResourceType = mapResourceType(resourceType);
  const { id, permissions: userPermissionsList } = userPermissions.filter(
    ({
      resourceType: userPermissionResourcetype,
      resourceId: userPermissionResourceId,
    }) => (
      userPermissionResourcetype === permissionResourceType
      && userPermissionResourceId === resourceId
    ),
  )[0];

  const permissions = yield call(
    setUserPermissions,
    isAddingPermissions,
    userPermissionsList,
    path,
    permissionResourceType,
    isProject,
  );

  const body = {
    recipientId: userId,
    permissions,
    resourceType: permissionResourceType,
    resourceId,
  };

  const { error } = yield call(postPath, endpoint, body);
  const responseWithMetadata = error
    ? undefined
    : {
      id,
      permissions,
      resourceType: permissionResourceType,
      userId,
    };

  const params = {
    response: responseWithMetadata,
    error,
    successDisp: permissionsActionCreators.toggleUserHeaderPermissionSuccess,
    failDisp: permissionsActionCreators.toggleUserHeaderPermissionFailure,
    ...rest,
  };
  yield call(processCall, params);
}

export function* fetchContextUserPermissions(action) {
  const { userId, resourceType, contextId, endpoint, ...rest } = action.payload;
  const { name: context } = (yield select(contextSelector))(contextId);
  const details = {
    userId,
    resourceType: mapResourceType(resourceType),
  };
  const urlParams = { ...details, context };
  const { response, error } = yield call(getPath, endpoint, { urlParams });

  const responseWithMetadata = error
    ? undefined
    : {
      ...response,
      ...details,
      resourceId: contextId,
    };

  const params = {
    response: responseWithMetadata,
    error,
    successDisp: permissionsActionCreators.fetchContextUserPermissionsSuccess,
    failDisp: permissionsActionCreators.fetchContextUserPermissionsFailure,
    ...rest,
  };
  yield call(processCall, params);
}

export function* toggleContextUserPermission(action) {
  const { userId, resourceType, contextId, permissionId, endpoint } = action.payload;
  (yield select(contextSelector))(contextId);
  const { resource, permissionName } = (yield select(permissionSelector))(permissionId);

  const body = {
    recipientId: userId,
    name: `${resource}:${permissionName}`,
    context: TENANT,
  };
  const { response, error } = yield call(postPath, endpoint, body);
  const responseWithMetadata = error
    ? undefined
    : {
      ...response,
      userId,
      resourceType,
      resourceId: contextId,
      permissionId,
    };

  const params = {
    response: responseWithMetadata,
    error,
    successDisp: permissionsActionCreators.toggleUserPermissionSuccess,
    failDisp: permissionsActionCreators.toggleUserPermissionFailure,
  };
  yield call(processCall, params);
}

export function* toggleContextUserHeaderPermission(action) {
  const {
    userId,
    resourceType,
    path,
    isOn,
    isPartial,
    endpoint,
    ...rest
  } = action.payload;

  const isAddingPermissions = !isOn || isPartial;
  const userPermissions = (yield select(selectors.userPermissionsSelector))(userId);

  const permissionResourceType = mapResourceType(resourceType);
  const { id, permissions: userPermissionsList } = userPermissions.filter(
    ({
      resourceType: userPermissionResourcetype,
      resourceId,
    }) => (
      userPermissionResourcetype === permissionResourceType
      && resourceId === TENANT_CONTEXT_ID
    ),
  )[0];

  const permissions = yield call(
    setUserPermissions,
    isAddingPermissions,
    userPermissionsList,
    path,
    permissionResourceType,
  );

  const body = {
    recipientId: userId,
    permissions,
    resourceType: permissionResourceType,
    context: TENANT,
  };

  const { error } = yield call(postPath, endpoint, body);
  const responseWithMetadata = error
    ? undefined
    : {
      id,
      permissions,
      resourceType: permissionResourceType,
      userId,
    };

  const params = {
    response: responseWithMetadata,
    error,
    successDisp: permissionsActionCreators.toggleContextUserHeaderPermissionSuccess,
    failDisp: permissionsActionCreators.toggleContextUserHeaderPermissionFailure,
    ...rest,
  };
  yield call(processCall, params);
}

export function* resourcePaste(action) {
  const { resourceType, resourceId, userId, copyUserId, isContext, endpoint } = action.payload;
  const userPermissions = (yield select(selectors.userPermissionsSelector))(userId);
  const copyUserPermissions = (yield select(selectors.userPermissionsSelector))(copyUserId);
  const permissionResourceType = mapResourceType(resourceType);
  const { id, permissions } = replaceUserPermissions({
    permissionResourceType,
    resourceId,
    userPermissions,
    copyUserPermissions,
  });

  const body = {
    recipientId: userId,
    permissions,
    resourceType: permissionResourceType,
    resourceId: !isContext ? resourceId : undefined,
    context: isContext ? TENANT : undefined,
  };

  const { error } = yield call(postPath, endpoint, body);
  const responseWithMetadata = error
    ? undefined
    : {
      id,
      permissions,
      resourceType: permissionResourceType,
      userId,
    };

  const params = {
    response: responseWithMetadata,
    error,
    successDisp: permissionsActionCreators.resourcePasteSuccess,
    failDisp: permissionsActionCreators.resourcePasteFailure,
  };
  yield call(processCall, params);
}

/* eslint-disable max-len */
export default function* permissionsSaga() {
  yield takeLatest(constants.FETCH_INDEX_PERMISSIONS_REQUEST, fetchIndexPermissions);
  yield takeLatest(constants.FETCH_CONTEXTS_REQUEST, fetchContexts);
  yield takeLatest(constants.PERMISSIONS_ADD_PROJECT_ID, addProjectId);
  yield takeLatest(constants.PERMISSIONS_ADD_PROJECT_SUITE_ID, addProjectSuiteId);
  yield takeLatest(constants.PERMISSIONS_ADD_CONTEXT_ID, addContextId);
  yield takeLatest(constants.PERMISSIONS_FETCH_PROJECT_USERS_REQUEST, fetchProjectUsers);
  yield takeLatest(constants.PERMISSIONS_FETCH_PROJECT_SUITE_USERS_REQUEST, fetchProjectSuiteUsers);
  yield takeLatest(constants.PERMISSIONS_FETCH_PROJECT_LOGICAL_DEVICES_REQUEST, fetchProjectLogicalDevices);
  yield takeEvery(constants.FETCH_RESOURCE_USER_PERMISSIONS_REQUEST, fetchUserPermissions);
  yield takeEvery(constants.PERMISSIONS_TOGGLE_USER_PERMISSION, toggleUserPermission);
  yield takeEvery(constants.PERMISSIONS_TOGGLE_USER_HEADER_PERMISSION, toggleUserHeaderPermission);
  yield takeEvery(constants.FETCH_CONTEXT_USER_PERMISSIONS_REQUEST, fetchContextUserPermissions);
  yield takeEvery(constants.PERMISSIONS_TOGGLE_CONTEXT_USER_PERMISSION, toggleContextUserPermission);
  yield takeEvery(constants.PERMISSIONS_TOGGLE_CONTEXT_USER_HEADER_PERMISSION, toggleContextUserHeaderPermission);
  yield takeEvery(constants.PERMISSIONS_RESOURCE_PASTE, resourcePaste);
}
