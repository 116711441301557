import React from 'react';
import PropTypes from 'prop-types';

import TaskModel from 'models/TaskModel';
import { StyledTasksTableRow } from './TaskRow.style';

const TaskRow = ({
  item: task,
  item: { id },
  onSelectTask,
  selectedTasks,
  ...rest
}) => {
  const taskModel = new TaskModel(task);
  const allSelectedTasks = selectedTasks || [];
  const selected = allSelectedTasks.includes(id);
  const handleSelectTask = () => !selected && onSelectTask({ id });

  return (
    <StyledTasksTableRow
      {...rest}
      item={taskModel}
      onClick={handleSelectTask}
      selected={selected}
    />
  );
};

TaskRow.defaultProps = {
  selectedTasks: null,
};

TaskRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  onSelectTask: PropTypes.func.isRequired,
  selectedTasks: PropTypes.array,
};

export default TaskRow;
