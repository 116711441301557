import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'semantic-ui-react';

import SelectSiteDropdown from '../SelectSiteDropdown/SelectSiteDropdownContainer';
import { SelectDeviceAction, SelectLogicalDevice, GenerateActionForm } from './components';
import { ActionName } from './components/GenerateActionForm/components';

export const actionFormFields = {
  name: 'name',
};

class DeviceActions extends Component {

  constructor(props) {
    super(props);

    this.state = {
      deviceAction: '',
      showDeviceAction: false,
      logicalDeviceId: null,
      siteId: null,
    };
    this.selectDeviceAction = this.selectDeviceAction.bind(this);
    this.selectDevice = this.selectDevice.bind(this);
    this.selectSite = this.selectSite.bind(this);
  }

  selectDevice(value) {
    const { logicalDeviceId } = this.state;
    if (value !== '') {
      this.setState({
        showDeviceAction: true,
        logicalDeviceId: value,
      });
    }

    if ((value !== '') && logicalDeviceId !== value) {
      this.setState({
        deviceAction: '',
      });
    }
  }

  selectDeviceAction(value) {
    this.setState({ deviceAction: value });
  }

  selectSite(value) {
    this.setState({
      siteId: value,
    });
  }

  render() {
    const {
      deviceAction,
      showDeviceAction,
      logicalDeviceId,
      siteId,
    } = this.state;
    const { selectDeviceAction, selectDevice, selectSite } = this;
    const { superSites } = this.props;

    return (
      <>
        {superSites && <SelectSiteDropdown selectSite={selectSite} />}
        <SelectLogicalDevice
          selectDevice={selectDevice}
          superSites={superSites}
          siteId={siteId}
        />
        {showDeviceAction && (
          <Fragment>
            <SelectDeviceAction
              selectDeviceAction={selectDeviceAction}
              logicalDeviceId={logicalDeviceId}
            />
            <Divider />
            {deviceAction && (
              <Fragment>
                <ActionName />
                <GenerateActionForm
                  actionType={deviceAction}
                  logicalDeviceId={logicalDeviceId}
                />
              </Fragment>
            )}
          </Fragment>
        )}
      </>
    );
  }

}

DeviceActions.defaultProps = {
  superSites: false,
};

DeviceActions.propTypes = {
  superSites: PropTypes.bool,
};

export default DeviceActions;
