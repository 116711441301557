import { put, race, take } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import { actionCreators as userActionCreators } from '../../../../../../../../../../utilities/users';
import actionCreators from '../actionCreators';

function* resetOTPSecret() {
  const profile = { attributes: { otpSecret: null } };
  yield put(userActionCreators.editProfile(profile));
  const { success, failure } = yield race({
    success: take(constants.EDIT_PROFILE_SUCCESS),
    failure: take(constants.EDIT_PROFILE_FAILED),
  });

  if (success) {
    yield put(actionCreators.resetOTPSecretSuccess());
  }

  if (failure) {
    yield put(actionCreators.resetOTPSecretFailure());
  }
}

export default resetOTPSecret;
