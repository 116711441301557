import isNil from 'lodash/isNil';
import { isEmpty } from 'lodash';

const computeDropdownSelection = (currentValue, values) => {
  if (isNil(currentValue)) {
    return values;
  }

  if (currentValue === 'allSites') {
    return ['allSites'];
  }

  if (currentValue === 'superSiteTasks') {
    return ['superSiteTasks'];
  }

  if (!isEmpty(currentValue)) {
    return values
      .filter(item => item !== 'allSites')
      .filter(item => item !== 'superSiteTasks');
  }

  return values;
};

export default computeDropdownSelection;
