import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Form, Button, Input, Icon } from 'semantic-ui-react';

import { Panel, ColorPicker } from 'components/core';
import { ChildPanelHeader } from 'components/core/Panel/Panel';
import ThemedToggle from 'components/elements/ThemedToggle/ThemedToggle.style';

import { configurationFormFields } from '../../utilities';

import {
  FormContainer,
  StyledLabel,
  ColorPickerContainer,
  PreviewContainer,
  HeaderContainer,
  EmptySlotPreview,
  FlexContainer,
  ControlsPreview,
  LabelPreview,
  ColorWheelPreview,
  SlidersPreview,
  StyledFormField,
  RunButtonPreview,
} from './ControlsAppearanceStep.style';

export default function ButtonAppearanceStep({
  initialValues: {
    [configurationFormFields.enabled]: initialEnabled,
    [configurationFormFields.showIntensitySlider]: initialShowIntensitySlider,
    [configurationFormFields.showColorTempSlider]: initialShowColorTempSlider,
    [configurationFormFields.labelText]: initialLabelText,
    [configurationFormFields.labelTextColor]: initialLabelTextColor,
    [configurationFormFields.runButtonText]: initialRunButtonText,
    [configurationFormFields.runButtonTextColor]: initialRunButtonTextColor,
    [configurationFormFields.runButtonBgColor]: initialRunButtonBgColor,
  },
  form,
  onControlEnabledChange,
  onShowIntensitySliderChange,
  onShowColorTempSliderChange,
  onLabelTextChange,
  onLabelTextColorChange,
  onRunButtonTextChange,
  onRunButtonTextColorChange,
  onRunButtonBgColorChange,
  onClearCopy,
}) {
  const [background, setBackground] = useState('light');

  const [enabled, setEnabled] = useState(initialEnabled);
  const [showIntensitySlider, setShowIntensitySlider] = useState(initialShowIntensitySlider);
  const [showColorTempSlider, setShowColorTempSlider] = useState(initialShowColorTempSlider);
  const [labelText, setLabelText] = useState(initialLabelText || '');
  const [labelTextColor, setLabelTextColor] = useState(initialLabelTextColor);
  const [runButtonText, setRunButtonText] = useState(initialRunButtonText || '');
  const [runButtonTextColor, setRunButtonTextColor] = useState(initialRunButtonTextColor);
  const [runButtonBgColor, setRunButtonBgColor] = useState(initialRunButtonBgColor);

  const handleControlEnabledChange = useCallback(() => {
    const isEnabled = !enabled;

    setEnabled(isEnabled);
    onControlEnabledChange(form, isEnabled);
    onClearCopy();
  }, [form, enabled, onControlEnabledChange, onClearCopy]);

  const handleShowIntensitySliderChange = useCallback(() => {
    const isVisible = !showIntensitySlider;

    setShowIntensitySlider(isVisible);
    onShowIntensitySliderChange(form, isVisible);
  }, [form, showIntensitySlider, onShowIntensitySliderChange]);

  const handleColorTempSliderChange = useCallback(() => {
    const isVisible = !showColorTempSlider;

    setShowColorTempSlider(isVisible);
    onShowColorTempSliderChange(form, isVisible);
  }, [form, showColorTempSlider, onShowColorTempSliderChange]);

  const handleLabelTextChange = useCallback(event => {
    setLabelText(event.target.value);
    onLabelTextChange(form, event.target.value);
  }, [form, onLabelTextChange]);

  const handleLabelTextColorChange = useCallback(selectedColor => {
    setLabelTextColor(selectedColor);
    onLabelTextColorChange(form, JSON.stringify(selectedColor));
  }, [form, onLabelTextColorChange]);

  const handleRunButtonTextChange = useCallback(event => {
    setRunButtonText(event.target.value);
    onRunButtonTextChange(form, event.target.value);
  }, [form, onRunButtonTextChange]);

  const handleRunButtonTextColorChange = useCallback(selectedColor => {
    setRunButtonTextColor(selectedColor);
    onRunButtonTextColorChange(form, JSON.stringify(selectedColor));
  }, [form, onRunButtonTextColorChange]);

  const handleRunButtonBgColorChange = useCallback(selectedColor => {
    setRunButtonBgColor(selectedColor);
    onRunButtonBgColorChange(form, JSON.stringify(selectedColor));
  }, [form, onRunButtonBgColorChange]);

  return (
    <Panel
      title="Configuration"
      description="Set the required configurations."
    >
      <Grid.Row>
        <Grid.Column mobile={16} computer={6}>
          <ChildPanelHeader title="Appearance" description="Set button text, colour & others." />
          <FormContainer>
            <Form>
              <Form.Group widths="equal">
                <StyledFormField
                  name="enabled"
                  label="Enabled"
                  control={ThemedToggle}
                  checked={enabled}
                  onChange={handleControlEnabledChange}
                />
              </Form.Group>
              {enabled && (
                <>
                  <FlexContainer>
                    <StyledFormField
                      name="showIntensitySlider"
                      label="Intensity Slider"
                      control={ThemedToggle}
                      checked={showIntensitySlider}
                      onChange={handleShowIntensitySliderChange}
                    />
                    <StyledFormField
                      name="showColourTempSlider"
                      label="Colour Temp Slider"
                      control={ThemedToggle}
                      checked={showColorTempSlider}
                      onChange={handleColorTempSliderChange}
                    />
                  </FlexContainer>
                  <FlexContainer>
                    <StyledFormField
                      label="Label text and colour"
                      name="labelText"
                      control={Input}
                      placeholder="Label text"
                      value={labelText}
                      onChange={handleLabelTextChange}
                    />
                    <ColorPickerContainer>
                      <ColorPicker
                        name="labelTextColor"
                        value={labelTextColor}
                        onChange={handleLabelTextColorChange}
                      />
                    </ColorPickerContainer>
                  </FlexContainer>
                  <FlexContainer>
                    <StyledFormField
                      label="Run button text and colour"
                      name="runButtonText"
                      control={Input}
                      placeholder="Run button text"
                      value={runButtonText}
                      onChange={handleRunButtonTextChange}
                    />
                    <ColorPickerContainer>
                      <ColorPicker
                        name="runButtonTextColor"
                        value={runButtonTextColor}
                        onChange={handleRunButtonTextColorChange}
                      />
                    </ColorPickerContainer>
                  </FlexContainer>
                  <ColorPickerContainer>
                    <StyledLabel>Run button background colour</StyledLabel>
                    <ColorPicker
                      name="runButtonBgColor"
                      value={runButtonBgColor}
                      onChange={handleRunButtonBgColorChange}
                    />
                  </ColorPickerContainer>
                </>
              )}
            </Form>
          </FormContainer>
        </Grid.Column>
        <Grid.Column mobile={16} computer={10}>
          <HeaderContainer>
            <ChildPanelHeader
              title="Preview"
              description="Appearance on the Control Panel."
            />
            <Button.Group basic size="mini">
              <Button active={background === 'light'} onClick={() => setBackground('light')}>Light Background</Button>
              <Button active={background === 'dark'} onClick={() => setBackground('dark')}>Dark Background</Button>
            </Button.Group>
          </HeaderContainer>
          <PreviewContainer backgroundType={background}>
            {enabled && (
              <ControlsPreview>
                <LabelPreview labelTextColor={labelTextColor}>
                  {labelText}
                </LabelPreview>
                <FlexContainer>
                  <ColorWheelPreview />
                  {showIntensitySlider && <SlidersPreview />}
                  {showColorTempSlider && <SlidersPreview />}
                </FlexContainer>
                <RunButtonPreview textColor={runButtonTextColor} bgColor={runButtonBgColor}>
                  {runButtonText}
                </RunButtonPreview>
              </ControlsPreview>
            )}
            {!enabled && (
              <EmptySlotPreview>
                <Icon name="plus" />
              </EmptySlotPreview>
            )}
          </PreviewContainer>
        </Grid.Column>
      </Grid.Row>
    </Panel>
  );
}

ButtonAppearanceStep.propTypes = {
  initialValues: PropTypes.shape({
    [configurationFormFields.enabled]: PropTypes.bool,
    [configurationFormFields.showIntensitySlider]: PropTypes.bool,
    [configurationFormFields.showColorTempSlider]: PropTypes.bool,
    [configurationFormFields.labelText]: PropTypes.string,
    [configurationFormFields.labelTextColor]: PropTypes.object,
    [configurationFormFields.runButtonText]: PropTypes.string,
    [configurationFormFields.runButtonTextColor]: PropTypes.object,
    [configurationFormFields.runButtonBgColor]: PropTypes.object,
  }).isRequired,
  form: PropTypes.string.isRequired,
  onControlEnabledChange: PropTypes.func.isRequired,
  onShowIntensitySliderChange: PropTypes.func.isRequired,
  onShowColorTempSliderChange: PropTypes.func.isRequired,
  onLabelTextChange: PropTypes.func.isRequired,
  onLabelTextColorChange: PropTypes.func.isRequired,
  onRunButtonTextChange: PropTypes.func.isRequired,
  onRunButtonTextColorChange: PropTypes.func.isRequired,
  onRunButtonBgColorChange: PropTypes.func.isRequired,
  onClearCopy: PropTypes.func.isRequired,
};
