/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { useViewport } from 'helpers/hooks';

import { convertColorString } from 'helpers/sharedMethods';
import {
  ColorPreview,
  IntensitySlider,
  TemperatureSlider,
  ColorPicker,
} from 'application/tenant/console/site/components/structural/SiteSchedulingTab/components/AutomatedOperations/components/AddAutomatedOperation/components/ActionsStep/components/SelectedActions/components/ActionItem/components/GenerateActionForm/components/ColorUI/components';
import {
  transformPercentageToRGB,
  transformRGBToPercentage,
  calculateColorIntensity,
} from 'application/tenant/console/site/components/structural/SiteSchedulingTab/components/AutomatedOperations/components/AddAutomatedOperation/components/ActionsStep/components/SelectedActions/components/ActionItem/components/GenerateActionForm/components/ColorUI/utilities';

import {
  Wrapper,
  Container,
  RgbContainer,
  SlidersAndInputsContainer,
  SlidersContainer,
  Title,
  StyledRunButton,
  Spacing,
} from './OverrideRGBControls.style';

import getOverrideRgbActions from './utilities/getOverrideRgbActions';

function OverrideRGBControls({
  formValues,
  changeFieldValue,
  pageId,
  configure,
  controlId,
  labelText,
  labelTextColor,
  runButtonText,
  runButtonTextColor,
  runButtonBgColor,
  showIntensitySlider,
  showColorTempSlider,
  groups,
  resourceType,
  resourceId,
  onExecuteActions,
}) {
  const { isMobile } = useViewport();

  const [isReady, setIsReady] = useState(false);
  const [color, setColor] = useState({ r: 255, g: 255, b: 255 });
  const [formColor, setFormColor] = useState({});

  const [temperature, setTemperature] = useState(50);
  const [intensity, setIntensity] = useState(100);

  const keyName = useMemo(
    () => `_${resourceId}_${pageId}_${controlId}`,
    [resourceId, pageId, controlId],
  );

  const RGB = transformPercentageToRGB(color);
  const backgroundColor = color && `rgb(${RGB.r}, ${RGB.g}, ${RGB.b})`;
  const calculatedColor = calculateColorIntensity(RGB, intensity);

  useEffect(() => {
    if (!isReady) {
      changeFieldValue(`enableRed${keyName}`, true);
      changeFieldValue(`enableGreen${keyName}`, true);
      changeFieldValue(`enableBlue${keyName}`, true);
      changeFieldValue(`enableIntensity${keyName}`, true);
      changeFieldValue(`enableTemperature${keyName}`, true);

      setIsReady(true);
    }
  }, [changeFieldValue, keyName, isReady]);

  const handleColorWheelChange = useCallback(colorObj => {
    const transformedColorToPercentage = transformRGBToPercentage(colorObj.rgb);

    setColor(transformedColorToPercentage);

    changeFieldValue(`red${keyName}`, transformedColorToPercentage.r);
    changeFieldValue(`green${keyName}`, transformedColorToPercentage.g);
    changeFieldValue(`blue${keyName}`, transformedColorToPercentage.b);
  }, [changeFieldValue, keyName]);


  const handleSubmit = useCallback(e => {
    e.stopPropagation();

    if (configure) {
      return;
    }

    const actions = getOverrideRgbActions(keyName, groups, formValues);

    const payload = {
      resourceId,
      resourceType,
      actions,
    };

    onExecuteActions(payload);
  }, [
    configure,
    resourceId,
    resourceType,
    groups,
    onExecuteActions,
    keyName,
    formValues,
  ]);

  const slidersFields = useMemo(() => {
    const visibleFields = [];

    if (showIntensitySlider) {
      visibleFields.push('intensity');
    }

    if (showColorTempSlider) {
      visibleFields.push('temperature');
    }

    return visibleFields;
  }, [showIntensitySlider, showColorTempSlider]);

  return (
    <Wrapper>
      <Title labelTextColor={convertColorString(labelTextColor)}>
        {labelText}
      </Title>
      <Container>
        <RgbContainer>
          <ColorPicker
            width={isMobile ? 150 : 200}
            wheelLightness={false}
            changeColor={handleColorWheelChange}
            actionTypeDeviceIdKey={keyName}
            scheduleMode={false}
            color={formColor}
          />
          <Spacing />
          <ColorPreview
            key="color-preview-rgb"
            bgColor={calculatedColor}
            actionTypeDeviceIdKey={keyName}
            fields={['red', 'green', 'blue']}
            showPreview={false}
            orientation="horizontal"
            hideCheckbox
            hideLabel
          />
        </RgbContainer>
        {!isEmpty(slidersFields) && (
          <SlidersAndInputsContainer>
            <SlidersContainer>
              {showIntensitySlider && (
                <IntensitySlider
                  backgroundColor={backgroundColor}
                  color={color}
                  intensity={intensity}
                  changeFieldValue={changeFieldValue}
                  actionTypeDeviceIdKey={keyName}
                />
              )}
              {showColorTempSlider && (
                <TemperatureSlider
                  changeFieldValue={changeFieldValue}
                  temperature={temperature}
                  actionTypeDeviceIdKey={keyName}
                />
              )}
            </SlidersContainer>
            <Spacing />
            <div>
              <ColorPreview
                key="color-preview-intensity"
                bgColor={calculatedColor}
                actionTypeDeviceIdKey={keyName}
                fields={slidersFields}
                showPreview={false}
                orientation="horizontal"
                hideCheckbox
                hideLabel
              />
            </div>
          </SlidersAndInputsContainer>
        )}
      </Container>
      <Spacing />
      <StyledRunButton
        runButtonTextColor={convertColorString(runButtonTextColor)}
        runButtonBgColor={convertColorString(runButtonBgColor)}
        onClick={handleSubmit}
      >
        {runButtonText}
      </StyledRunButton>
    </Wrapper>
  );
}

OverrideRGBControls.defaultProps = {
  labelText: null,
  labelTextColor: null,
  runButtonText: null,
  runButtonTextColor: null,
  runButtonBgColor: null,
  showIntensitySlider: false,
  showColorTempSlider: false,
  groups: [],
  formValues: {},
};

OverrideRGBControls.propTypes = {
  formValues: PropTypes.object,
  changeFieldValue: PropTypes.func.isRequired,
  pageId: PropTypes.number.isRequired,
  configure: PropTypes.bool.isRequired,
  controlId: PropTypes.number.isRequired,
  labelText: PropTypes.string,
  labelTextColor: PropTypes.string,
  runButtonText: PropTypes.string,
  runButtonTextColor: PropTypes.string,
  runButtonBgColor: PropTypes.string,
  showIntensitySlider: PropTypes.bool,
  showColorTempSlider: PropTypes.bool,
  groups: PropTypes.array,
  onExecuteActions: PropTypes.func.isRequired,
  resourceType: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
};

export default OverrideRGBControls;
