import { call } from 'redux-saga/effects';

import DeviceFirmwareModel from 'models/DeviceFirmwareModel';
import { putPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';

import actionCreators from '../actionCreators';

function* editDeviceFirmware(action) {
  const {
    endpoint,
    params: {
      values,
      rejectForm,
      resolveForm,
    },
  } = action.payload;

  const body = DeviceFirmwareModel.requestBody(values);

  const { response, error } = yield call(putPath, endpoint, body);

  const params = {
    response,
    error,
    resolveForm,
    rejectForm,
    successDisp: actionCreators.editDeviceFirmwareSuccess,
    failDisp: actionCreators.editDeviceFirmwareFailure,
  };

  yield call(processCall, params);
}

export default editDeviceFirmware;
