import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { List, Segment } from 'semantic-ui-react';
import { DateTime } from 'luxon';
import pluralize from 'pluralize';

import { Button } from 'components/atomic';

import { VoucherWarning, SiteDetailsForm } from './components';
import { StyledListContent } from './VoucherDetails.style';

function VoucherDetails({
  projectId,
  isLoading,
  selectedVoucher,
  siteProjectedSubscriptionPlanCode,
  hideProjectedRenewalDate,
  requireSiteInfo,
  siteName,
  ownerEmail,
  onRedeemVoucher,
  onFinishSiteCreation,
  hideRedeemButton,
}) {
  const {
    voucherCode,
    projectedRenewalDate = 'N/A',
    projectedSubscriptionPlanCode,
    voucherSubscriptionLength = 'N/A',
    voucherSubscriptionPlanCode = 'N/A',
  } = selectedVoucher || {};

  const redeemVoucher = useCallback(
    () => new Promise((resolveForm, rejectForm) => {
      const params = ({
        projectId,
        voucher: voucherCode,
        siteName,
        ownerEmail,
        resolveForm,
        rejectForm,
      });

      onRedeemVoucher(params);
    }),
    [projectId, voucherCode, siteName, ownerEmail, onRedeemVoucher],
  );

  const handleSubmit = useCallback(() => {
    redeemVoucher()
      .then(() => {
        if (!projectId && onFinishSiteCreation) {
          onFinishSiteCreation();
        }
      }).catch(() => {});
  }, [redeemVoucher, projectId, onFinishSiteCreation]);

  const formattedSubscriptionLength = useMemo(
    () => `${voucherSubscriptionLength} ${pluralize('month', voucherSubscriptionLength)}`,
    [voucherSubscriptionLength],
  );

  const formattedProjectedRenewalDate = useMemo(
    () => DateTime.fromISO(projectedRenewalDate).toUTC().toFormat('dd/MM/y'),
    [projectedRenewalDate],
  );

  if (!selectedVoucher) {
    return null;
  }

  return (
    <>
      <Segment>
        <VoucherWarning
          sitePlanCode={siteProjectedSubscriptionPlanCode}
          voucherPlanCode={voucherSubscriptionPlanCode}
        />
        <List verticalAlign="middle">
          <List.Item>
            <StyledListContent>
              <p>Voucher code:</p>
              <div>{voucherCode}</div>
            </StyledListContent>
            <StyledListContent>
              <p>Subscription plan:</p>
              <div>{voucherSubscriptionPlanCode}</div>
            </StyledListContent>
            <StyledListContent>
              <p>Subscription length:</p>
              <div>{formattedSubscriptionLength}</div>
            </StyledListContent>
            {!hideProjectedRenewalDate && (
              <>
                <br />
                <StyledListContent>
                  <p name="projected-renewal-date">Projected renewal date:</p>
                  <div>{formattedProjectedRenewalDate}</div>
                </StyledListContent>
                <StyledListContent>
                  <p name="projected-subscription-plan-code">Projected subscription plan:</p>
                  <div>{projectedSubscriptionPlanCode}</div>
                </StyledListContent>
              </>
            )}
          </List.Item>
        </List>
      </Segment>
      {requireSiteInfo && (
        <SiteDetailsForm onSubmit={handleSubmit} isLoading={isLoading} />
      )}
      {!hideRedeemButton && !requireSiteInfo && (
        <Button
          primary
          loading={isLoading}
          onClick={handleSubmit}
          name="btn-redeem-voucher"
        >
          Redeem
        </Button>
      )}
    </>
  );
}

VoucherDetails.defaultProps = {
  selectedVoucher: null,
  siteProjectedSubscriptionPlanCode: null,
  isLoading: false,
  projectId: null,
  hideProjectedRenewalDate: false,
  requireSiteInfo: false,
  siteName: null,
  ownerEmail: null,
  onFinishSiteCreation: null,
  hideRedeemButton: false,
};

VoucherDetails.propTypes = {
  isLoading: PropTypes.bool,
  projectId: PropTypes.string,
  selectedVoucher: PropTypes.object,
  siteProjectedSubscriptionPlanCode: PropTypes.string,
  hideProjectedRenewalDate: PropTypes.bool,
  requireSiteInfo: PropTypes.bool,
  siteName: PropTypes.string,
  ownerEmail: PropTypes.string,
  onRedeemVoucher: PropTypes.func.isRequired,
  onFinishSiteCreation: PropTypes.func,
  hideRedeemButton: PropTypes.bool,
};

export default VoucherDetails;
