import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ActionName } from '../DeviceActions/components/GenerateActionForm/components';
import { GenerateActionForm } from '../DeviceActions/components';
import { SelectSiteAction } from './components';

const SiteActions = ({ projectId, resourceName }) => {
  const [siteAction, setSiteAction] = useState(null);

  const handleSelect = value => {
    setSiteAction(value);
  };

  return (
    <>
      <SelectSiteAction handleSelect={handleSelect} />
      {siteAction && (
        <>
          <ActionName />
          <GenerateActionForm
            actionType={siteAction}
            resourceId={projectId}
            resourceName={resourceName}
          />
        </>
      )}
    </>
  );
};

SiteActions.propTypes = {
  resourceName: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default SiteActions;
