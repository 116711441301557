import React, { useState, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Segment, Grid, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { Button } from 'components/atomic';
import CompanyPermissionsTable from 'components/PermissionsTable/PermissionsTableCompanyContainer';

import { CompaniesTable } from './components';

const PermissionsCompaniesTab = ({
  companyIds,
  onAddCompanyId,
  onRemoveCompanyId,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleCompanySelected = useCallback(id => {
    if (!companyIds.includes(id)) {
      onAddCompanyId(id);
    } else {
      onRemoveCompanyId(id);
    }
  }, [companyIds, onAddCompanyId, onRemoveCompanyId]);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Header as="h1">Company permissions</Header>
          <Header as="h3">Add a company to view and edit its permissions</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          {!isEmpty(companyIds) && companyIds.map(companyId => (
            <Segment key={companyId}>
              <CompanyPermissionsTable
                companyId={companyId}
                showHeader
              />
            </Segment>
          ))}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Button onClick={() => setIsVisible(!isVisible)}>
            {!isVisible ? 'Add Company' : 'Done'}
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <CompaniesTable
            isVisible={isVisible}
            selectedCompanyIds={companyIds}
            onCompanySelected={handleCompanySelected}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

PermissionsCompaniesTab.defaultProps = {
  companyIds: [],
};

PermissionsCompaniesTab.propTypes = {
  companyIds: PropTypes.array,
  onAddCompanyId: PropTypes.func.isRequired,
  onRemoveCompanyId: PropTypes.func.isRequired,
};

export default PermissionsCompaniesTab;
