import { compose } from 'redux';
import { connect } from 'react-redux';

import { tasksActionCreators } from '../../../../../../../../../../../../../utilities';
import AddAction from './AddAction';

const mapDispatchToProps = {
  onAddAction: tasksActionCreators.addAction,
  onIncompleteForm: tasksActionCreators.addActionIncompleteForm,
};

export default compose(
  connect(null, mapDispatchToProps),
)(AddAction);
