import {
  sendPasswordResetToken,
  sendPasswordResetTokenSuccess,
  sendPasswordResetTokenFailure,
} from './sendPasswordResetToken';

export default {
  sendPasswordResetToken,
  sendPasswordResetTokenSuccess,
  sendPasswordResetTokenFailure,
};
