import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import tasksActionCreators from 'application/tenant/utilities/tasks/tasksActionCreators';
import EditTask from './EditTask';

const mapDispatchToProps = {
  onEditTask: tasksActionCreators.editTask,
};

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(EditTask);
