import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Feature from 'ol/Feature';
import Overlay from 'ol/Overlay';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';

import { getDisplayName } from 'helpers/sharedMethods';
import { StyledMapMarkerWrapper } from './mapMarker.style';

const mapMarker = (
  {
    custom = false,
    onSelect,
    onUnselect,
  } = {},
) => WrappedComponent => {
  class MapMarker extends Component {

    constructor() {
      super();

      this.markerNode = React.createRef();
      this.handleSelect = this.handleSelect.bind(this);
      this.handleUnselect = this.handleUnselect.bind(this);
    }

    componentDidMount() {
      const { map, resourceId, latitude, longitude, initiallyOpen, hidden } = this.props;

      this.coordinates = fromLonLat([longitude, latitude]);
      this.point = new Point(this.coordinates);
      this.feature = new Feature({ geometry: this.point });
      this.feature.handleSelect = this.handleSelect;
      this.feature.handleUnselect = this.handleUnselect;
      this.feature.resourceId = resourceId;
      this.layer = new VectorLayer({
        source: new VectorSource({
          features: [this.feature],
        }),
      });
      this.overlay = new Overlay({
        element: this.markerNode.current,
        autoPan: true,
        autoPanAnimation: {
          duration: 250,
        },
      });
      map.addOverlay(this.overlay);

      if (!hidden) {
        map.addLayer(this.layer);

        if (initiallyOpen) {
          this.overlay.setPosition(this.coordinates);
        }
      }
    }

    componentDidUpdate(prevProps) {
      const { map, longitude, latitude, hidden } = this.props;
      const {
        longitude: prevLongitude,
        latitude: prevLatitude,
        hidden: prevHidden,
      } = prevProps;

      if (longitude !== prevLongitude || latitude !== prevLatitude) {
        this.coordinates = fromLonLat([longitude, latitude]);
        this.point.setCoordinates(this.coordinates);
        this.overlay.setPosition(this.coordinates);
      }

      if (hidden && !prevHidden) {
        this.overlay.setPosition();
        map.removeLayer(this.layer);
      }

      if (!hidden && prevHidden) {
        this.overlay.setPosition(this.coordinates);
        map.addLayer(this.layer);
      }
    }

    handleSelect() {
      if (onSelect) {
        onSelect(this.map, this.overlay, this.coordinates);
      } else {
        this.overlay.setPosition(this.coordinates);
      }
    }

    handleUnselect() {
      if (onUnselect) {
        onUnselect(this.map, this.overlay, this.coordinates);
      } else {
        this.overlay.setPosition();
      }
    }

    render() {
      if (custom) {
        return (
          <WrappedComponent
            innerRef={this.markerNode}
            {...this.props}
          />
        );
      }

      return (
        <StyledMapMarkerWrapper ref={this.markerNode}>
          <WrappedComponent {...this.props} />
        </StyledMapMarkerWrapper>
      );
    }

  }

  MapMarker.displayName = `MapMarker(${getDisplayName(WrappedComponent)})`;

  MapMarker.defaultProps = {
    initiallyOpen: false,
    hidden: false,
  };

  MapMarker.propTypes = {
    map: PropTypes.object.isRequired,
    resourceId: PropTypes.string.isRequired,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    initiallyOpen: PropTypes.bool,
    hidden: PropTypes.bool,
  };

  return MapMarker;
};

export default mapMarker;
