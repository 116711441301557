import { call } from 'redux-saga/effects';

import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import * as actionCreators from '../../actionCreators/timelinesSection';

function* fetchTimelines({ payload }) {
  const { deviceId, endpoint, urlParams, componentName, ...rest } = payload;
  const { response, error } = yield call(getPath, endpoint, { urlParams });

  const enhancedResponse = response && { ...response, componentName };

  const params = {
    response: enhancedResponse,
    error,
    successDisp: actionCreators.fetchTimelinesSuccess,
    ...rest,
  };
  yield call(processCall, params);
}

export default fetchTimelines;
