import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/atomic';
import ConfirmModal from 'components/elements/ConfirmModal/ConfirmModal';

const RemoveBackgroundImage = ({ projectId, pageId, onRemovePageBackgroundImage }) => {
  const trigger = <Button icon="trash" />;
  const confirmButton = <Button negative primary={false}>Remove background image</Button>;
  const handleConfirm = () => onRemovePageBackgroundImage({ projectId, pageId });

  return (
    <ConfirmModal
      trigger={trigger}
      cancelButton="Cancel"
      confirmButton={confirmButton}
      content={(
        <p>
          Are you sure you want to remove the background image
          for this page?
        </p>
      )}
      header="Confirm background image removal"
      onConfirm={handleConfirm}
    />
  );
};

RemoveBackgroundImage.propTypes = {
  projectId: PropTypes.string.isRequired,
  pageId: PropTypes.number.isRequired,
  onRemovePageBackgroundImage: PropTypes.func.isRequired,
};

export default RemoveBackgroundImage;
