const filterProjectRelatedPermissions = (permissions, permissionResourceType, isProject) => {
  if (!Array.isArray(permissions)) {
    return permissions;
  }

  if (isProject && (permissionResourceType === 'project' || permissionResourceType === 'project_suite')) {
    return permissions
      .map(({ permissionName }) => permissionName)
      .filter(permissionName => permissionName !== 'self:add' && permissionName !== 'self:auto_renew');
  }

  return permissions.map(({ permissionName }) => permissionName);
};

export default filterProjectRelatedPermissions;
