import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormGroup, Grid } from 'semantic-ui-react';

import { selectOptionsType } from 'data/types';
import {
  required,
  validName3,
  validDeviceMapping,
  validCompanyName,
  validEmail,
} from 'components/form/formValidators';
import ModalForm from 'components/form/ModalForm/ModalForm';
import TextField from 'components/form/TextField/TextField';
import DropdownField from 'components/form/DropdownField/DropdownField';
import CheckboxField from 'components/form/CheckboxField/CheckboxField';
import { companyFormFields } from '../../../../../constants';
import { MailgunDomainField } from './components';

class AddCompanyModalForm extends Component {

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ isManufacturer: nextIsManufacturer }) {
    const { isManufacturer, change, untouch } = this.props;

    if (isManufacturer && !nextIsManufacturer) {
      change(companyFormFields.deviceMapping, null);
      untouch(companyFormFields.deviceMapping);
    }
  }

  render() {
    const { submitting, availableThemes, isManufacturer } = this.props;

    return (
      <ModalForm
        header="Create company"
        trigger="Create company"
        size="small"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...this.props}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} computer={8}>
              <FormGroup inline>
                <Field
                  name={companyFormFields.name}
                  component={TextField}
                  type="text"
                  loading={submitting}
                  label="Name"
                  validate={validCompanyName}
                />
              </FormGroup>
            </Grid.Column>
            <Grid.Column mobile={16} computer={8}>
              <FormGroup inline>
                <Field
                  name={companyFormFields.subdomain}
                  component={TextField}
                  type="text"
                  loading={submitting}
                  label="Subdomain"
                  validate={validName3}
                />
              </FormGroup>
            </Grid.Column>
            <Grid.Column mobile={16} computer={8}>
              <FormGroup inline>
                <Field
                  name={companyFormFields.themeName}
                  component={DropdownField}
                  loading={submitting}
                  label="Theme"
                  options={availableThemes}
                  validate={required}
                />
              </FormGroup>
            </Grid.Column>
            <Grid.Column mobile={16} computer={8}>
              <FormGroup inline>
                <Field
                  name={companyFormFields.mailgunDomain}
                  component={MailgunDomainField}
                  loading={submitting}
                  label="Mailgun domain"
                  options={[]}
                  validate={required}
                />
              </FormGroup>
            </Grid.Column>
            <Grid.Column mobile={16} computer={8}>
              <FormGroup inline>
                <Field
                  name={companyFormFields.registeredName}
                  component={TextField}
                  type="text"
                  loading={submitting}
                  label="Registered name"
                  validate={validCompanyName}
                />
              </FormGroup>
            </Grid.Column>
            <Grid.Column mobile={16} computer={8}>
              <FormGroup inline>
                <Field
                  name={companyFormFields.email}
                  component={TextField}
                  type="text"
                  loading={submitting}
                  label="Email"
                  validate={validEmail}
                />
              </FormGroup>
            </Grid.Column>
            <Grid.Column mobile={16} computer={8}>
              <FormGroup inline>
                <Field
                  name={companyFormFields.tenantService}
                  component={TextField}
                  type="text"
                  loading={submitting}
                  label="Tenant service"
                  validate={validCompanyName}
                />
              </FormGroup>
            </Grid.Column>
            <Grid.Column mobile={16} computer={8}>
              <FormGroup inline>
                <Field
                  name={companyFormFields.deviceMapping}
                  component={TextField}
                  type="text"
                  loading={isManufacturer ? submitting : false}
                  label="SDK"
                  validate={isManufacturer ? validDeviceMapping : null}
                  disabled={!isManufacturer}
                />
              </FormGroup>
            </Grid.Column>
            <Grid.Column computer={16} mobile={16}>
              <FormGroup inline>
                <Field
                  name={companyFormFields.twoFactorEnabled}
                  component={CheckboxField}
                  type="checkbox"
                  loading={submitting}
                  label="Enable Two Factor Authentication"
                  justify="end"
                />
                <Field
                  name={companyFormFields.manufacturer}
                  component={CheckboxField}
                  type="checkbox"
                  loading={submitting}
                  label="Mark as manufacturer"
                  justify="end"
                />
              </FormGroup>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ModalForm>
    );
  }

}

AddCompanyModalForm.defaultProps = {
  isManufacturer: false,
};

AddCompanyModalForm.propTypes = {
  isManufacturer: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-typos
  availableThemes: selectOptionsType.isRequired,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
};

export default AddCompanyModalForm;
