import { call } from 'redux-saga/effects';

import { USERS_FIELD } from 'helpers/selectors';
import { postPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import ApiRequestBuilder from 'helpers/ApiRequestBuilder';
import actionCreators from '../actionCreators';

function* changePassword(action) {
  const {
    endpoint,
    params: {
      currentUserId,
      values,
      resolveForm,
      rejectForm,
    },
  } = action.payload;

  const body = new ApiRequestBuilder(USERS_FIELD)
    .setId(currentUserId)
    .setAttributes(values);

  const { response, error } = yield call(postPath, endpoint, body);

  const params = {
    response,
    error,
    successDisp: actionCreators.changePasswordSuccess,
    failDisp: actionCreators.changePasswordFailure,
    resolveForm,
    rejectForm,
  };
  yield call(processCall, params);
}

export default changePassword;
