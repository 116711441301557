import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import { Panel } from 'components/core';

import {
  IdentificationReview,
  ActionsReview,
  ScheduleReview,
  StyledContainer,
} from './components';

function ReviewStep({ onStepChange }) {

  const handleRedirectToStep = step => {
    onStepChange(step);
  };

  return (
    <Panel
      title="New Task Scheduler"
      description="A Task Scheduler will be created as follows. Edit its attributes here before saving."
    >
      <Grid.Row>
        <Grid.Column width={16}>
          <StyledContainer>
            <IdentificationReview />
            <ActionsReview onStepChange={handleRedirectToStep} />
            <ScheduleReview onStepChange={handleRedirectToStep} />
          </StyledContainer>
        </Grid.Column>
      </Grid.Row>
    </Panel>
  );
}

ReviewStep.propTypes = {
  onStepChange: PropTypes.func.isRequired,
};

export default ReviewStep;
