import styled from 'styled-components';
import { Table } from 'semantic-ui-react';

import { mediaMax } from 'themes/mixins';

const StyledTableCell = styled(Table.Cell)`
  padding-left: 50px !important;

  & .dimmable {
    padding: 0;
  }

  ${mediaMax.md`
    padding-left: 10px !important;
  `};

  & .ui.column.sortable.table tr th:first-of-type {
    display: none;
  }

  & .ui.column.sortable.table tr td:first-of-type {
    display: none;
  }
`;

export default StyledTableCell;
