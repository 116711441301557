import styled, { css } from 'styled-components';

export const StyledTableColumn = styled.th`
  ${({ permissionNameColumn }) => permissionNameColumn && css`
    width: 250px;
  `}

  ${({ recipientColumn }) => recipientColumn && css`
    width: 50px;
  `}
`;

export default StyledTableColumn;
