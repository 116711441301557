import { takeEvery, take, put, race, delay } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import AsyncErrorParser from 'helpers/AsyncErrorParser';
import { socketDeviceDataMessageSelector, actionWithIdentifierSelector } from 'helpers/selectors';
import { prepareResponse, prepareErrorResponse } from 'data/api/requests-helpers';
import fixtureGroupsActionCreators from 'components/FixtureGroupsSection/fixtureGroupsActionCreators';
import * as timelinesSectionActionCreators from 'application/tenant/console/device/utilities/actionCreators/timelinesSection';
import staticScenesSectionActionCreators from 'components/StaticScenesSection/staticScenesSectionActionCreators';
import zonesActionCreators from 'layouts/Pathway/components/PathwayZonesV1/zonesActionCreators';
import snapshotsSectionActionCreators from 'components/SnapshotsSection/SnapshotsSectionActionCreators';
import { actionCreators as outputSectionActionCreators } from 'application/tenant/console/device/components/structural/OutputSection/utilities';
import deviceActionCreators from 'pages/Project/deviceActionCreators';
import logsSectionActionCreators from 'components/LogsSection/logsSectionActionCreators';
import { actionCreators as remoteDevicesActionCreators } from 'layouts/Pharos/components/PharosRemoteDevicesV1/utilities/';

const handlers = {
  fixture_groups: {
    success: fixtureGroupsActionCreators.fetchGroupsSuccess,
    failure: fixtureGroupsActionCreators.fetchGroupsFailure,
  },
  timelines: {
    success: timelinesSectionActionCreators.fetchTimelinesSuccess,
    failure: timelinesSectionActionCreators.fetchTimelinesFailure,
  },
  static_scenes: {
    success: staticScenesSectionActionCreators.fetchStaticScenesSuccess,
    failure: staticScenesSectionActionCreators.fetchStaticScenesFailure,
  },
  zones: {
    success: zonesActionCreators.fetchZonesSuccess,
    failure: zonesActionCreators.fetchZonesFailure,
  },
  snapshots: {
    success: snapshotsSectionActionCreators.fetchSnapshotsSuccess,
    failure: snapshotsSectionActionCreators.fetchSnapshotsFailure,
  },
  dmx_output: {
    success: outputSectionActionCreators.fetchOutputSuccess,
    failure: outputSectionActionCreators.fetchOutputFailure,
    skipError: true,
  },
  io_instances: {
    success: deviceActionCreators.fetchIoModulesInstancesSuccess,
    failure: deviceActionCreators.fetchIoModulesInstancesFailure,
  },
  log_messages: {
    success: logsSectionActionCreators.fetchLogsSuccess,
    failure: logsSectionActionCreators.fetchLogsFailure,
  },
  remote_devices: {
    success: remoteDevicesActionCreators.fetchRemoteDevicesSuccess,
    failure: remoteDevicesActionCreators.fetchRemoteDevicesFailure,
  },
};

export function* handleDeviceDataChannelMessage({
  payload: {
    message: {
      data,
      errors,
    },
    identifier,
  },
}) {
  const endpoint = `/logical_devices/${identifier.logicalDeviceId}/${identifier.data}`;

  if (data) {
    const { success } = handlers[identifier.data];
    const { response } = prepareResponse(endpoint, {
      data,
      meta: {},
      identifier: identifier || {},
    });

    yield put(success(response));
  } else {
    const { failure, skipError } = handlers[identifier.data];

    if (skipError) {
      const { timeout } = yield race({
        update: take(actionWithIdentifierSelector(constants.SOCKET_RECEIVED, identifier)),
        timeout: delay(5000),
      });

      if (timeout) {
        const messageErrors = new AsyncErrorParser({
          errors,
          meta: {},
        });
        const { error } = prepareErrorResponse(messageErrors);

        yield put(failure(error));
      }
    } else {
      const messageErrors = new AsyncErrorParser({
        errors,
        meta: {},
      });
      const { error } = prepareErrorResponse(messageErrors);

      yield put(failure(error));
    }
  }
}

export default function* deviceDataChannelSaga() {
  yield takeEvery(socketDeviceDataMessageSelector, handleDeviceDataChannelMessage);
}
