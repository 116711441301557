import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { StyledHeader, StyledSubheader } from './ChildPanelHeader.style';

function ChildPanelHeader({ title, description, withPadding }) {
  if (isEmpty(title) && isEmpty(description)) {
    return null;
  }

  return (
    <StyledHeader as="h4" className="child-panel-header" withPadding={withPadding}>
      {title}
      <StyledSubheader className="child-panel-description">
        {description}
      </StyledSubheader>
    </StyledHeader>
  );
}

ChildPanelHeader.defaultProps = {
  title: null,
  description: null,
  withPadding: false,
};

ChildPanelHeader.propTypes = {
  title: PropTypes.string,
  withPadding: PropTypes.bool,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
};

export default ChildPanelHeader;
