import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import { layoutColumnsType } from 'data/types';
import TabbedLayoutColumn from '../TabbedLayoutColumn/TabbedLayoutColumn';

const TabbedLayoutRow = ({ columns, resourceId }) => (
  <Grid.Row>
    {columns.map(({ id, sections, ...rest }) => (
      <TabbedLayoutColumn
        {...rest}
        key={id}
        sections={sections}
        resourceId={resourceId}
      />
    ))}
  </Grid.Row>
);

TabbedLayoutRow.defaultProps = {
  columns: [],
  resourceId: null,
};

TabbedLayoutRow.propTypes = {
  columns: layoutColumnsType,
  resourceId: PropTypes.string,
};

export default TabbedLayoutRow;
