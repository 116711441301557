import { compose } from 'redux';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { withRouter } from 'react-router-dom';

import { actionCreators } from 'application/tenant/components/structural/ControlUI/components/ControlUIPageCarousel/components/ControlUIPage/components/ControlUIButtonGroup/ControlUIButton/components/utilities';
import ButtonAppearanceStep from './ButtonAppearanceStep';

const mapDispatchToProps = dispatch => ({
  onButtonTextChange: (formName, value) => dispatch(change(formName, 'text', value)),
  onButtonColorChange: (formName, value) => dispatch(change(formName, 'buttonColor', value)),
  onButtonTextColorChange: (formName, value) => dispatch(change(formName, 'textColor', value)),
  onButtonEnabledChange: (formName, value) => dispatch(change(formName, 'enabled', value)),
  onClearCopy: () => dispatch(actionCreators.clearCopy()),
});

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(ButtonAppearanceStep);
