import React from 'react';

const ContactAdministrator = () => (
  <p>
    If you have lost access to the device you use to generate
    one-time passwords, you should contact the portal administrator
    to recover your account.
  </p>
);

export default ContactAdministrator;
