import { compose } from 'redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import formNames from 'components/form/formNames';
import { validateOnSubmit } from 'components/form/formConfigs';
import UploadFirmwareFileModalForm from './UploadFirmwareFileModalForm';
import { firmwareFileFormFields } from './constants';

const mapStateToProps = state => ({
  files: formValueSelector(formNames.uploadFirmwareFile)(state, firmwareFileFormFields.files),
});

export default compose(
  reduxForm({
    form: formNames.uploadFirmwareFile,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    ...validateOnSubmit,
  }),
  connect(mapStateToProps),
)(UploadFirmwareFileModalForm);
