import React from 'react';
import PropTypes from 'prop-types';

import { secondsPerHour } from 'helpers/sharedVariables';
import { StyledNumberInput } from './TimeInput.style';

const step = [secondsPerHour, 60, 1];
const min = [0, 0, 0];
const max = [23, 59, 59];

const TimeInput = props => {
  const { value, timezoneOffset, ...rest } = props;
  const offset = secondsPerHour * timezoneOffset;

  return (
    <StyledNumberInput
      {...rest}
      value={value}
      separator=":"
      step={step}
      min={min}
      max={max}
      offset={offset}
    />
  );
};

TimeInput.defaultProps = {
  value: null,
  timezoneOffset: 0,
};

TimeInput.propTypes = {
  value: PropTypes.number,
  timezoneOffset: PropTypes.number,
};

export default TimeInput;
