import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import { PermissionsSwitch, Gateway, frontendPermissions } from 'gateway';
import { Button } from 'components/atomic';

import { SCHEDULER_TYPES, TASK_SCHEDULER_MODE } from '../../constants';

export default function ModalTrigger({
  mode,
  disabled,
  existingSchedule,
  onClick,
  onSchedulerTypeChange,
  onRecurrenceParamsChange,
  onTaskSchedulerModeChange,
  onExistingScheduleChange,
  resourceType,
}) {
  const handleDefaultSchedulerToggle = useCallback(hasAddSchedulerPermission => {
    onClick();
    onTaskSchedulerModeChange(TASK_SCHEDULER_MODE.DEFAULT);

    if (hasAddSchedulerPermission) {
      onSchedulerTypeChange(SCHEDULER_TYPES.DATES);
    } else {
      onSchedulerTypeChange(SCHEDULER_TYPES.NONE);
    }
  },
  [
    onClick,
    onTaskSchedulerModeChange,
    onSchedulerTypeChange,
  ]);

  const handleAddSchedulerToggle = useCallback(() => {
    onClick();
    onTaskSchedulerModeChange(TASK_SCHEDULER_MODE.ADD_SCHEDULER);
    onSchedulerTypeChange(SCHEDULER_TYPES.DATES);
  }, [onClick, onTaskSchedulerModeChange, onSchedulerTypeChange]);

  const handleEditSchedulerToggle = useCallback(() => {
    const { type, params } = existingSchedule || {};

    onClick();

    const formattedType = type.toUpperCase();

    onTaskSchedulerModeChange(TASK_SCHEDULER_MODE.EDIT_SCHEDULER);
    onSchedulerTypeChange(formattedType);
    onExistingScheduleChange(existingSchedule);

    onRecurrenceParamsChange({
      ...params,
    });
  }, [
    existingSchedule,
    onSchedulerTypeChange,
    onRecurrenceParamsChange,
    onTaskSchedulerModeChange,
    onExistingScheduleChange,
    onClick,
  ]);

  if (mode === TASK_SCHEDULER_MODE.DEFAULT) {
    const renderAddSchedulerButton = hasAddSchedulerPermission => (
      <Button
        name="btn-add-task-scheduler"
        primary
        disabled={disabled}
        onClick={() => handleDefaultSchedulerToggle(hasAddSchedulerPermission)}
      >
        Create
      </Button>
    );

    const addAutomatedOperationPermission = resourceType === 'projectSuite'
      ? frontendPermissions.PROJECT_SUITE_AUTOMATED_OPERATIONS_ADD
      : frontendPermissions.ADD_AUTOMATED_OPERATION;

    const addSchedulerPermission = resourceType === 'projectSuite'
      ? frontendPermissions.PROJECT_SUITE_SCHEDULES_ADD
      : frontendPermissions.ADD_SCHEDULE;

    return (
      <PermissionsSwitch>
        <Gateway condition={addAutomatedOperationPermission}>
          <PermissionsSwitch>
            <Gateway condition={addSchedulerPermission}>
              {renderAddSchedulerButton(true)}
            </Gateway>
            <Gateway fallback>
              {renderAddSchedulerButton(false)}
            </Gateway>
          </PermissionsSwitch>
        </Gateway>
      </PermissionsSwitch>
    );
  }

  if (mode === TASK_SCHEDULER_MODE.ADD_SCHEDULER) {
    const AddSchedulerButton = () => (
      <Button name="btn-add-scheduler" primary size="small" onClick={handleAddSchedulerToggle}>
        Add Scheduler
      </Button>
    );

    const requiredPermission = resourceType === 'projectSuite'
      ? frontendPermissions.PROJECT_SUITE_SCHEDULES_ADD
      : frontendPermissions.ADD_SCHEDULE;

    return (
      <PermissionsSwitch>
        <Gateway
          condition={requiredPermission}
          component={AddSchedulerButton}
        />
      </PermissionsSwitch>
    );
  }

  if (mode === TASK_SCHEDULER_MODE.EDIT_SCHEDULER) {
    const EditButton = () => (
      <Button name="btn-edit-scheduler" icon size="mini" floated="right" onClick={handleEditSchedulerToggle}>
        <Icon name="pencil" />
      </Button>
    );

    const requiredPermission = resourceType === 'projectSuite'
      ? frontendPermissions.PROJECT_SUITE_SCHEDULES_EDIT
      : frontendPermissions.EDIT_SCHEDULE;

    return (
      <PermissionsSwitch>
        <Gateway
          condition={requiredPermission}
          component={EditButton}
        />
      </PermissionsSwitch>
    );
  }

  return null;
}

ModalTrigger.defaultProps = {
  mode: TASK_SCHEDULER_MODE.DEFAULT,
  disabled: false,
  existingSchedule: null,
  resourceType: 'project',
};

ModalTrigger.propTypes = {
  mode: PropTypes.oneOf([
    TASK_SCHEDULER_MODE.DEFAULT,
    TASK_SCHEDULER_MODE.ADD_SCHEDULER,
    TASK_SCHEDULER_MODE.EDIT_SCHEDULER,
  ]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  existingSchedule: PropTypes.object,
  onSchedulerTypeChange: PropTypes.func.isRequired,
  onRecurrenceParamsChange: PropTypes.func.isRequired,
  onTaskSchedulerModeChange: PropTypes.func.isRequired,
  onExistingScheduleChange: PropTypes.func.isRequired,
  resourceType: PropTypes.string,
};
