import React from 'react';
import PropTypes from 'prop-types';
import { BsExclamationTriangle } from 'react-icons/bs';

import { StyledDivGracePeriod, StyledPopupGrace } from './IsInGracePeriodIcon.style';

const IsInGracePeriodIcon = ({ color }) => (
  <StyledDivGracePeriod>
    <StyledPopupGrace
      content="This Site has past its renewal date and will be moved to standby soon."
      trigger={(
        <BsExclamationTriangle size={30} color={color} />
      )}
      position="top right"
    />
  </StyledDivGracePeriod>
);

IsInGracePeriodIcon.defaultProps = {
  color: '#818181',
};

IsInGracePeriodIcon.propTypes = {
  color: PropTypes.string,
};

export default IsInGracePeriodIcon;
