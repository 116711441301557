const isPharosOrEtc = (device = {}) => {
  const pharosEtc = ['pharos', 'etc'];
  const { manufacturerSDK } = device.viewSelector || {};

  if (pharosEtc.includes(manufacturerSDK)) {
    return true;
  }

  return false;
};

export default isPharosOrEtc;
