function generateIntegerRange(min, max) {
  const allOptions = [...Array(max + 1).keys()];
  const removeMinValues = allOptions.slice(min);

  return removeMinValues.map(
    item => (
      {
        key: item,
        text: item,
        value: item,
      }
    ),
  );
}

export default generateIntegerRange;
