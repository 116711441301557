const configurationFormFields = {
  enabled: 'enabled',
  showIntensitySlider: 'showIntensitySlider',
  showColorTempSlider: 'showColorTempSlider',
  labelText: 'labelText',
  labelTextColor: 'labelTextColor',
  runButtonText: 'runButtonText',
  runButtonTextColor: 'runButtonTextColor',
  runButtonBgColor: 'runButtonBgColor',
};

export default configurationFormFields;
