import { takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';

import fetchDevicesProjectSuites from './fetchDevicesProjectSuites';

function* saga() {
  yield takeLatest(constants.FETCH_DEVICES_PROJECT_SUITES_REQUEST, fetchDevicesProjectSuites);
}

export default saga;
