import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

export const StyledButton = styled(Button)`
  &.ui.button {
    margin: 2em .25em 0 0;
    max-height: 36px;
  }
`;

export default StyledButton;
