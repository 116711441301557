import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

export const StyledButtonGroup = styled(Button.Group)`
  margin-right: 8px !important;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
`;

export default StyledButtonGroup;
