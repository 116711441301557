import styled from 'styled-components';

const UploadsControlsWrapper = styled.div`
  white-space: nowrap;
  text-align: right;
  margin-top: 12px;
  
  button {
    margin-right: 8px;
  }
`;

export default UploadsControlsWrapper;
