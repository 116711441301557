import React, { createRef, Component } from 'react';
import ReactDOM from 'react-dom';

import { getDisplayName } from 'helpers/sharedMethods';

const withTextContent = WrappedComponent => {
  class WithTextContent extends Component {

    static displayName = `WithTextContent(${getDisplayName(WrappedComponent)})`;

    constructor() {
      super();

      this.elementRef = createRef();

      this.state = {
        text: '',
        elementRef: this.elementRef,
      };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      const { elementRef, text } = prevState;

      // eslint-disable-next-line react/no-find-dom-node
      const wrapperNode = ReactDOM.findDOMNode(elementRef.current);
      const textContent = (wrapperNode && wrapperNode.textContent) || '';
      if (text === textContent) return null;

      return {
        text: textContent,
      };
    }

    render() {
      const { text } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          innerRef={this.elementRef}
          text={text}
        />
      );
    }

  }

  return WithTextContent;
};

export default withTextContent;
