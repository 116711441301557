import { call, put } from 'redux-saga/effects';

import DeviceFirmwareModel from 'models/DeviceFirmwareModel';
import DeviceFirmwareFileModel from 'models/DeviceFirmwareFileModel';
import { postPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';

import { extractIdFromResponse } from './utilities';
import actionCreators from '../actionCreators';

function* addDeviceFirmware(action) {
  const {
    endpoint,
    endpointForFile,
    params: {
      values,
      rejectForm,
      resolveForm,
    },
  } = action.payload;

  const valuesForFirmware = DeviceFirmwareModel.extractValues(values);
  const body = DeviceFirmwareModel.requestBody(valuesForFirmware);

  const { response, error } = yield call(postPath, endpoint, body);

  if (response) {
    const deviceFirmwareId = extractIdFromResponse(response);
    const { systemFiles, fileDescription } = values;
    const fileName = systemFiles[0].name;
    const updatedValues = { description: fileDescription, originalFileName: fileName };
    const bodyForFile = DeviceFirmwareFileModel.requestBody(updatedValues);

    const {
      response: responseForFile,
      error: errorForFile,
    } = yield call(postPath, endpointForFile, bodyForFile);

    if (responseForFile) {
      const firmwareFileId = extractIdFromResponse(responseForFile);
      const enhancedResponse = {
        ...responseForFile,
        systemFiles,
        endpoint: endpointForFile,
      };

      yield put(actionCreators.uploadFirmwareFileAfterDevice({
        ...enhancedResponse,
      }));

      yield put(actionCreators.linkFileWithDeviceFirmware(deviceFirmwareId, firmwareFileId));
    } else {
      yield put(actionCreators.uploadFirmwareFileFailure({
        error: errorForFile,
      }));
    }
  }

  const params = {
    response,
    error,
    resolveForm,
    rejectForm,
    successDisp: actionCreators.addDeviceFirmwareSuccess,
    failDisp: actionCreators.addDeviceFirmwareFailure,
  };

  yield call(processCall, params);
}

export default addDeviceFirmware;
