import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid, Segment, Header, Accordion } from 'semantic-ui-react';

import { AccordionItem } from 'components/structural';
import { TwoFactorQRViewer } from '../../../components/structural';
import { ContactAdministrator, OneTimePassword } from './components';

const TwoFactor = ({ twoFactorNeedsSetup }) => (
  <Container>
    <Grid>
      <Grid.Column mobile={16} computer={8} floated="right">
        <Segment>
          <Header>Two Factor Authentication</Header>
          <Grid>
            {twoFactorNeedsSetup && (
              <Grid.Row>
                <Grid.Column computer={6} mobile={6}>
                  <TwoFactorQRViewer />
                </Grid.Column>
                <Grid.Column computer={10} mobile={10}>
                  Scan the QR code using your chosen application to begin generating
                  6-digit one-time passwords to complete Two Factor Authentication.
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row>
              <Grid.Column>
                <OneTimePassword />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Accordion>
                  <AccordionItem
                    title="I can't enter a code"
                    component={ContactAdministrator}
                  />
                </Accordion>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Grid.Column>
    </Grid>
  </Container>
);

TwoFactor.defaultProps = {
  twoFactorNeedsSetup: false,
};

TwoFactor.propTypes = {
  twoFactorNeedsSetup: PropTypes.bool,
};

export default TwoFactor;
