import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';

import { complexValueType } from 'data/types';
import TextDescriptionItem
  from 'components/DescriptionList/TextDescriptionItem/TextDescriptionItem';

const PathwayNetworkInterfaceV1 = ({
  title,
  loading,
  ip,
  subnet,
  gateway,
  mode,
}) => !loading && (
  <Segment>
    <Header as="h2">{title}</Header>
    <TextDescriptionItem
      title="IP Address"
      value={ip}
    />
    <TextDescriptionItem
      title="Subnet Mask"
      value={subnet}
    />
    <TextDescriptionItem
      title="Default Gateway"
      value={gateway}
    />
    <TextDescriptionItem
      title="IP Mode"
      value={mode}
    />
  </Segment>
);

PathwayNetworkInterfaceV1.defaultProps = {
  loading: true,
  ip: {},
  subnet: {},
  gateway: {},
  mode: {},
};

PathwayNetworkInterfaceV1.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  model: complexValueType(PropTypes.string),
  subnet: complexValueType(PropTypes.string),
  gateway: complexValueType(PropTypes.string),
  mode: complexValueType(PropTypes.string),
};

export default PathwayNetworkInterfaceV1;
