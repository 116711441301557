import { call, select } from 'redux-saga/effects';

import { postPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';

import actionCreators from '../actionCreators';
import selectors from '../selectors';
import setCumulatedPermissionsAllowedState from '../setCumulatedPermissionsAllowedState';
import transformGrantRevokePermissionsResponse from '../transformGrantRevokePermissionsResponse';

function* revokeMultiplePermissions(action) {
  const { endpoint, data, ...rest } = action.payload;

  const {
    names,
    recipientId,
    recipientType,
    permissionItem,
    resourceId,
    parentResourceId,
    resourceType,
    resourceName,
    context,
    limitedScope,
    limitedFor,
  } = data;

  const body = {
    recipientId,
    names,
    recipientType,
    resourceId,
    resourceType,
  };

  if (context === 'tenant') {
    body.context = context;

    delete body.resourceId;
  }

  const { response, error } = yield call(postPath, endpoint, body);

  let enhancedResponse = null;

  if (!error) {
    const allPermissions = yield select(selectors.allPermissionsSelector);
    const allRecipientIds = yield select(
      selectors.selectedRecipientsSelector, parentResourceId || resourceId,
    );
    const cumulatedPermissions = yield select(selectors.cumulatedPermissionsSelector, resourceId);

    const { relatedPermissions } = permissionItem;

    const newCumulatedPermissions = setCumulatedPermissionsAllowedState({
      cumulatedPermissions,
      permissionNames: relatedPermissions,
      recipientId,
      allowedState: false,
    });

    const transformed = transformGrantRevokePermissionsResponse({
      allPermissions,
      originalCumulatedPermissions: newCumulatedPermissions,
      resourceId,
      resourceType,
      resourceName,
      recipientIds: allRecipientIds,
      recipientType,
      limitedScope,
      limitedFor,
    });

    enhancedResponse = {
      ...response,
      resourceId,
      resourceType,
      newCumulatedPermissions: transformed.cumulatedPermissions,
      newPermissionsTableRows: transformed.permissionsTableRows,
    };
  }

  const params = {
    response: enhancedResponse,
    error,
    successDisp: actionCreators.revokeMultiplePermissionsSuccess,
    failDisp: actionCreators.revokeMultiplePermissionsFailure,
    ...rest,
  };

  yield call(processCall, params);
}

export default revokeMultiplePermissions;
