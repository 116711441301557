import { compose } from 'redux';
import { connect } from 'react-redux';

import { selectors, actionCreators } from '../../../utilities/user';
import TwoFactor from './TwoFactor';

const mapStateToProps = state => ({
  twoFactorNeedsSetup: selectors.twoFactorNeedsSetupSelector(state),
});

const mapDispatchToProps = {
  onLogin: actionCreators.login,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(TwoFactor);
