import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'ol/ol.css';
import OLMap from 'ol/Map';
import Select from 'ol/interaction/Select';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';

import { StyledMapContainer, StyledMap, StyledCopyright } from './Map.style';

class Map extends Component {

  constructor() {
    super();

    this.handleSetCenter = this.handleSetCenter.bind(this);
    this.state = {
      viewCenterReady: false,
      viewResolutionReady: false,
      mapReady: false,
      selected: [],
    };
    this.view = new View({
      center: [0, 0],
      zoom: 0,
    });
    this.view.on('change:center', () => this.setState({ viewCenterReady: true }));
    this.view.on('change:resolution', ({ target }) => {
      if (target.getZoom() === 14) {
        this.setState({ viewResolutionReady: true });
      }
    });

    this.mapNode = React.createRef();
    this.map = new OLMap({
      layers: [
        new TileLayer({
          source: new XYZ({
            url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          }),
        }),
      ],
      view: this.view,
    });
    this.map.on('rendercomplete', () => this.setState({ mapReady: true }));

    const select = new Select();
    select.on('select', ({ selected, deselected }) => {
      selected.forEach(feature => feature.handleSelect());
      this.setState({ selected });

      deselected.forEach(feature => feature.handleUnselect());
    });
    this.map.addInteraction(select);

    this.handleEmptyClick = this.handleEmptyClick.bind(this);

    this.map.on('singleclick', ({ coordinate }) => {
      const pixel = this.map.getPixelFromCoordinate(coordinate);
      const features = this.map.getFeaturesAtPixel(pixel);

      if (!features.length) {
        this.handleEmptyClick(pixel);
      }
    });
  }

  componentDidMount() {
    const { locationReady } = this.props;

    if (locationReady) {
      this.handleSetCenter();
    }
  }

  componentDidUpdate({ locationReady: prevLocationReady }) {
    const { locationReady } = this.props;

    if (!prevLocationReady && locationReady) {
      this.handleSetCenter();
    }
  }

  handleSetCenter() {
    const { target, onCalculateCenter } = this.props;
    const { center, zoom } = onCalculateCenter();

    this.map.setTarget(target);
    this.view.setCenter(center);
    this.view.setZoom(zoom);
  }

  handleEmptyClick(pixel) {
    const { onEmptyClick } = this.props;
    const { selected } = this.state;

    if (onEmptyClick) {
      onEmptyClick(this.map, pixel, selected);
    }
  }

  render() {
    const { target, locationReady, markers, markerComponent: DefaultMarkerComponent } = this.props;
    const { viewCenterReady, viewResolutionReady, mapReady } = this.state;
    const viewReady = locationReady && mapReady && viewCenterReady && viewResolutionReady;

    return (
      <StyledMapContainer>
        {locationReady && (
          <StyledMap
            id={target}
            ref={this.mapNode}
          />
        )}
        <StyledCopyright>
          <span>© </span>
          <a
            href="https://www.openstreetmap.org/copyright"
            rel="noopener noreferrer"
            target="_blank"
          >
            OpenStreetMap
          </a>
          <span> contributors</span>
        </StyledCopyright>
        {viewReady && markers.map(({ id, component: CustomMarkerComponent, ...marker }) => {
          const MarkerComponent = CustomMarkerComponent || DefaultMarkerComponent;

          return (
            <MarkerComponent
              id={id}
              key={id}
              map={this.map}
              {...marker}
            />
          );
        })}
      </StyledMapContainer>
    );
  }

}

Map.defaultProps = {
  locationReady: false,
  markers: [],
  onCalculateCenter: () => ({ center: [0, 0], zoom: 0 }),
  onEmptyClick: () => null,
};

Map.propTypes = {
  target: PropTypes.string.isRequired,
  locationReady: PropTypes.bool,
  markers: PropTypes.array,
  markerComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  onCalculateCenter: PropTypes.func,
  onEmptyClick: PropTypes.func,
};

export default Map;
