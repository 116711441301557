import styled, { css } from 'styled-components';
import { Label } from 'semantic-ui-react';
import get from 'lodash/get';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
`;

export const StyledDeviceName = styled(Label)`
  &&& {
    width: 100%;
    border-radius: 0;
    padding-left: 0;
    border: none;
    border-bottom: 1px solid rgba(34, 36, 38, .15);  
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }  
`;

export const StyledLink = styled.a`
  ${({ theme }) => css`
    color: ${get(theme, 'palette.primaryColor')} !important;
    text-decoration: underline;
    opacity: 1 !important;
    font-weight: 300;

    &:hover {
      text-decoration: underline;
      opacity: 0.8 !important;
    }
  `};
`;

export const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
`;

export const StyledListItem = styled.li`
  margin-right: 8px;
  margin-bottom: 8px;
`;

export const StyledGroupsContainer = styled.div`
  margin-top: 16px;

  ${({ isEmpty }) => isEmpty && css`
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  `}
`;

export const StyledDeviceContainer = styled.div`
  margin-bottom: 16px;
`;

export const EmptyMessage = styled.p`
  text-align: center;
  font-size: 16px;
`;


export default HeaderContainer;
