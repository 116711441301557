import { compose } from 'redux';
import { connect } from 'react-redux';

import { deepEqualMemoizeOne } from 'helpers/sharedMethods';
import {
  computedUserDataPermissionsTreeSelector,
} from 'helpers/selectors';
import { PermissionsGrid } from '../../../../../../../../../../components';
import { sortPaths } from '../../../../../../../../../../utilities';
import { ContextUserPermissionsGridRow } from '../PermissionsGridRow';

export const makeMapState = () => {
  const memoPaths = deepEqualMemoizeOne();

  return function mapStateToProps(state, { resourceId }) {
    const {
      permissionCount,
      ...tree
    } = computedUserDataPermissionsTreeSelector(state, ['tenant'])(resourceId);
    const paths = sortPaths(tree);

    return {
      paths: memoPaths(paths),
      PermissionsGridRow: ContextUserPermissionsGridRow,
    };
  };
};

export default compose(
  connect(makeMapState),
)(PermissionsGrid);
