import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import { DEVICE_FIRMWARES_FIELD } from 'helpers/selectors';

export const editDeviceFirmware = params => ({
  type: constants.EDIT_DEVICE_FIRMWARE_REQUEST,
  payload: {
    params,
    endpoint: endpoints.EDIT_DEVICE_FIRMWARE(params.values.id),
  },
});

export const editDeviceFirmwareSuccess = response => ({
  type: constants.EDIT_DEVICE_FIRMWARE_SUCCESS,
  payload: {
    response,
    message: messages.DEVICE_FIRMWARE_UPDATED(response.data.attributes.name),
    resourceType: DEVICE_FIRMWARES_FIELD,
  },
});

export const editDeviceFirmwareFailure = error => ({
  type: constants.EDIT_DEVICE_FIRMWARE_FAILED,
  payload: { error },
});

export default editDeviceFirmware;
