import constants from 'dispatcherConst';

export const MAX_UPLOAD_NUMBER = 1;

const initialState = {
  limit: MAX_UPLOAD_NUMBER,
  notFound: '',
  running: '',
  suspended: false,
};

const uploadsStatusReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case constants.SET_UPLOAD_SUSPENSION_STATUS:
      return { ...state, suspended: payload.suspended };

    case constants.SET_UPLOAD_LIMIT:
      return { ...state, limit: payload.limit };

    case constants.UPLOAD_FILE_NOT_FOUND:
      return {
        ...state,
        notFound: payload.fileKey,
      };

    case constants.UPLOAD_START_SUCCESS: {
      const { fileKey } = payload;

      return {
        ...state,
        running: fileKey,
      };
    }

    case constants.UPLOAD_ENQUEUE_AT_START: {
      const { uploads } = payload;
      const { fileKey } = uploads[0] || {};
      const { notFound } = state;

      return {
        ...state,
        notFound: fileKey === notFound ? '' : notFound,
      };
    }

    case constants.UPLOAD_CANCEL_SUCCESS: {
      const { fileKey } = payload;
      const { notFound, running } = state;

      if (notFound === fileKey) {
        return { ...state, notFound: '' };
      }

      if (running === fileKey) {
        return { ...state, running: '' };
      }

      return state;
    }

    case constants.UPLOAD_SUCCESS:
    case constants.UPLOAD_PAUSE_SUCCESS: {
      const { fileKey } = payload;
      const { running } = state;

      if (running === fileKey) {
        return { ...state, running: '' };
      }

      return state;
    }

    default:
      return state;
  }
};

export default uploadsStatusReducer;
