import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import isEmpty from 'lodash/isEmpty';

import { DateList } from './components';
import { StyledDateLabel, StyledLink } from './SelectedDatesView.style';

function SelectedDatesView({
  selectedDates,
  onClearDates,
  onRemoveDate,
  hideWhenEmpty,
  renderLabel,
  clearable,
}) {
  const dateLabel = pluralize('date', selectedDates.length);

  const clearLabel = useMemo(() => {
    if (isEmpty(selectedDates)) {
      return null;
    }

    return (
      <StyledLink className="clear-label-link" onClick={onClearDates}>
        {`Clear ${dateLabel}`}
      </StyledLink>
    );
  }, [selectedDates, dateLabel, onClearDates]);

  const datesLabel = useMemo(() => {
    if (!renderLabel) {
      return null;
    }

    return (
      <StyledDateLabel className="selected-dates-label" basic>
        Selected {dateLabel}: {clearLabel}
      </StyledDateLabel>
    );

  }, [clearLabel, renderLabel, dateLabel]);

  if (isEmpty(selectedDates) && hideWhenEmpty) {
    return null;
  }

  return (
    <>
      {datesLabel}
      <DateList dates={selectedDates} onRemoveDate={onRemoveDate} clearable={clearable} />
    </>
  );
}

SelectedDatesView.defaultProps = {
  selectedDates: null,
  onClearDates: null,
  onRemoveDate: null,
  hideWhenEmpty: false,
  renderLabel: true,
  clearable: true,
};

SelectedDatesView.propTypes = {
  selectedDates: PropTypes.arrayOf(PropTypes.string),
  onClearDates: PropTypes.func,
  onRemoveDate: PropTypes.func,
  hideWhenEmpty: PropTypes.bool,
  renderLabel: PropTypes.bool,
  clearable: PropTypes.bool,
};


export default SelectedDatesView;
