import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { StyledButton } from '../CopyButton/CopyButton.style';

const PasteButton = ({
  projectId,
  pageId,
  buttonId,
  onPasteButton,
  buttonClipboard,
  controlType,
}) => {
  const pasteButton = e => {
    e.stopPropagation();
    onPasteButton({
      projectId,
      pageId,
      buttonId,
      controlType,
    });
  };

  const displayButton = !isEmpty(buttonClipboard);

  return displayButton && (
    <StyledButton
      name="paste"
      bordered
      inverted
      color="grey"
      onClick={pasteButton}
      role="button"
      title="Paste"
    />
  );
};

PasteButton.defaultProps = {
  buttonClipboard: {},
  controlType: 'button',
};

PasteButton.propTypes = {
  buttonClipboard: PropTypes.object,
  projectId: PropTypes.string.isRequired,
  pageId: PropTypes.number.isRequired,
  buttonId: PropTypes.number.isRequired,
  onPasteButton: PropTypes.func.isRequired,
  controlType: PropTypes.string,
};

export default PasteButton;
