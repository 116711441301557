import React from 'react';
import PropTypes from 'prop-types';

import AddAction from '../AddAction/AddActionContainer';
import { AddActionButtonRowMobile, AddActionButtonCellMobile } from './AddActionButtonMobile.style';

const AddActionRowMobile = ({ taskId, superSites }) => (
  <AddActionButtonRowMobile>
    <AddActionButtonCellMobile>
      <AddAction size="mini" taskId={taskId} superSites={superSites} />
    </AddActionButtonCellMobile>
  </AddActionButtonRowMobile>
);

AddActionRowMobile.defaultProps = {
  superSites: false,
};

AddActionRowMobile.propTypes = {
  taskId: PropTypes.string.isRequired,
  superSites: PropTypes.bool,
};

export default AddActionRowMobile;
