import { connect } from 'react-redux';

import { deepEqualMemoizeOne } from 'helpers/sharedMethods';
import { deviceSelector } from 'helpers/selectors';
import PathwayNetworkInterfaceV1 from './PathwayNetworkInterfaceV1';

export const makeMapState = () => {
  const memoIp = deepEqualMemoizeOne();
  const memoSubnet = deepEqualMemoizeOne();
  const memoGateway = deepEqualMemoizeOne();
  const memoMode = deepEqualMemoizeOne();

  return function mapStateToProps(state, ownProps) {
    const { resourceId } = ownProps;
    const device = deviceSelector(state)(resourceId);
    const { online, reportedWithMeta } = device;

    const {
      net: {
        mgmt: {
          ipv4: ip,
          sub: subnet,
          gw: gateway,
          ipMode: mode,
        } = {},
      } = {},
    } = reportedWithMeta || {};

    return {
      loading: !reportedWithMeta,
      online,
      ip: memoIp(ip),
      subnet: memoSubnet(subnet),
      gateway: memoGateway(gateway),
      mode: memoMode(mode),
    };
  };
};

export default connect(makeMapState)(PathwayNetworkInterfaceV1);
