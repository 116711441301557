import { put, select, call, takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import { logicalDeviceSelector } from 'helpers/selectors';
import { prepareResponseData } from 'helpers/paginationHelpersClient';
import triggersActionCreators from './triggersActionCreators';

export function* fetchTriggers({ payload }) {
  const { deviceId, endpoint, urlParams, resourceType } = payload;
  const { response, error } = yield call(getPath, endpoint, { urlParams });

  const params = {
    response,
    error,
    successDisp: triggersActionCreators.fetchTriggersSuccess,
    failDisp: triggersActionCreators.fetchTriggersFailure,
    resourceType,
  };
  yield call(processCall, params);

  if (response) {
    const device = (yield select(logicalDeviceSelector))(deviceId);
    const enhancedResponse = yield call(prepareResponseData, response, urlParams, device);

    yield put(triggersActionCreators.fetchTriggersSuccessPaginated(enhancedResponse));
  }
}

export function* fetchDeviceTriggers({ payload }) {
  const { endpoint, resourceType, deviceId } = payload;
  const { response, error } = yield call(getPath, endpoint);
  const enhancedResponse = response ? { ...response, deviceId } : undefined;

  const params = {
    response: enhancedResponse,
    error,
    successDisp: triggersActionCreators.fetchDeviceTriggersSuccess,
    failDisp: triggersActionCreators.fetchDeviceTriggersFailure,
    resourceType,
    deviceId,
  };
  yield call(processCall, params);
}

function* triggersSaga() {
  yield takeLatest(constants.FETCH_TRIGGERS_REQUEST, fetchTriggers);
  yield takeLatest(constants.FETCH_DEVICE_TRIGGERS_REQUEST, fetchDeviceTriggers);
}

export default triggersSaga;
