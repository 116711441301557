import isEmpty from 'lodash/isEmpty';

const isActionValid = ({ resourceId, actionId, actionIndex, actionErrors }) => {
  if (!actionErrors) {
    return true;
  }

  const errorKeys = Object.keys(actionErrors);

  if (isEmpty(errorKeys)) {
    return true;
  }

  const formattedKeys = errorKeys.map(errorKey => errorKey.substring(errorKey.indexOf('_') + 1));

  const actionKey = `${actionId}_${resourceId}_${actionIndex}`;
  let isValid = true;

  formattedKeys.forEach(errorKey => {
    if (actionKey === errorKey) {
      isValid = false;
    }
  });

  return isValid;
};

export default isActionValid;
