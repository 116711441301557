import React from 'react';
import PropTypes from 'prop-types';

import { getDisplayName } from 'helpers/sharedMethods';

const withFormat = WrappedComponent => {
  const WithFormat = ({ format, value, ...rest }) => (
    <WrappedComponent value={format(value)} {...rest} />
  );

  WithFormat.displayName = `WithFormat(${getDisplayName(WrappedComponent)})`;

  WithFormat.defaultProps = {
    format: value => value,
    value: null,
  };

  WithFormat.propTypes = {
    format: PropTypes.func,
    value: PropTypes.any,
  };

  return WithFormat;
};

export default withFormat;
