import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Header, Input, Label } from 'semantic-ui-react';
import isNil from 'lodash/isNil';

import { VoucherForm } from './components';
import { StyledSegment } from './VoucherGeneration.style';

function VoucherGeneration({
  plans,
  isLoading,
  tenant,
  subscriptionPlanCode,
  subscriptionLength,
  description,
  generatedVoucher,
  selectedTenant,
  onGenerateVoucher,
}) {
  const [copied, setCopied] = useState(false);
  const inputRef = useRef(null);

  const handleSubmit = useCallback(() => {
    onGenerateVoucher({
      tenant,
      subscriptionPlanCode,
      subscriptionLength,
      description,
      resolveForm: () => {},
      rejectForm: () => {},
    });
  }, [
    tenant,
    subscriptionPlanCode,
    subscriptionLength,
    description,
    onGenerateVoucher,
  ]);

  const handleCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(generatedVoucher.voucherCode);

    if (inputRef.current) {
      inputRef.current.select();
    }

    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, [5000]);
  }, [generatedVoucher]);

  return (
    <StyledSegment padded disabled={isNil(selectedTenant)}>
      <Header as="h3">
        New voucher
      </Header>
      <p>Generate a voucher for the selected company.</p>
      <VoucherForm
        onSubmit={handleSubmit}
        isLoading={isLoading}
        plans={plans}
      />
      {generatedVoucher && (
        <>
          <Input
            id="voucher-input"
            ref={inputRef}
            fluid
            placeholder="Generated Voucher"
            value={generatedVoucher.voucherCode}
            onClick={handleCopyToClipboard}
            action={{
              icon: 'copy',
              onClick: handleCopyToClipboard,
            }}
          />
          {copied && (
            <Label htmlFor="voucher-input" pointing="above" basic color="green">
              Voucher copied to the clipboard!
            </Label>
          )}
        </>
      )}
    </StyledSegment>
  );
}

VoucherGeneration.defaultProps = {
  plans: [],
  tenant: null,
  subscriptionPlanCode: '',
  subscriptionLength: '',
  description: '',
  isLoading: false,
  generatedVoucher: null,
  selectedTenant: null,
};

VoucherGeneration.propTypes = {
  plans: PropTypes.array,
  onGenerateVoucher: PropTypes.func.isRequired,
  tenant: PropTypes.string,
  subscriptionPlanCode: PropTypes.string,
  subscriptionLength: PropTypes.string,
  description: PropTypes.string,
  isLoading: PropTypes.bool,
  generatedVoucher: PropTypes.object,
  selectedTenant: PropTypes.object,
};

export default VoucherGeneration;
