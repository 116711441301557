import uniqBy from 'lodash/uniqBy';

import constants from 'dispatcherConst';
import { mapResourceType } from 'helpers/sharedMethods';
import removeResourceType from './removeResourceType';
import reduceUserPermissions from './reduceUserPermissions';

const userPermissionsDataReducer = (state, action) => {
  switch (action.type) {

    case constants.FETCH_RESOURCE_USER_PERMISSIONS_SUCCESS:
    case constants.FETCH_CONTEXT_USER_PERMISSIONS_SUCCESS:
      return reduceUserPermissions(state, action);

    case constants.PERMISSIONS_TOGGLE_USER_PERMISSION_SUCCESS: {
      const { response: { userId, resourceType, resourceId, permissionId } } = action.payload;
      const {
        userPermissions: {
          [userId]: userUserPermissions,
          ...userPermissions
        },
        permissions: {
          [permissionId]: {
            attributes: {
              permissionName,
            },
          },
        },
      } = state;

      const matchedUserPermissionId = Object.keys(userUserPermissions).filter(userPermissionId => {
        const {
          attributes: {
            resourceId: userPermissionResourceId,
            resourceType: userPermissionResourceType,
          },
        } = userUserPermissions[userPermissionId];

        return (
          userPermissionResourceId === resourceId
          && userPermissionResourceType === mapResourceType(resourceType)
        );
      })[0];

      const {
        attributes: {
          permissions,
          ...restOfAttributes
        },
      } = userUserPermissions[matchedUserPermissionId];

      const permissionsContainsName = !!permissions.filter(({ name }) => (
        name === permissionName
      )).length;

      const newPermissionsArray = permissionsContainsName
        ? (
          permissions.filter(({ name }) => (
            name !== permissionName
          ))
        ) : (
          [...permissions, { name: permissionName }]
        );

      return {
        ...state,
        userPermissions: {
          ...userPermissions,
          [userId]: {
            ...userUserPermissions,
            [matchedUserPermissionId]: {
              id: matchedUserPermissionId,
              attributes: {
                ...restOfAttributes,
                permissions: newPermissionsArray,
              },
            },
          },
        },
      };
    }

    case constants.PERMISSIONS_TOGGLE_USER_HEADER_PERMISSION_SUCCESS:
    case constants.PERMISSIONS_TOGGLE_CONTEXT_USER_HEADER_PERMISSION_SUCCESS:
    case constants.PERMISSIONS_RESOURCE_PASTE_SUCCESS: {
      const {
        response: {
          id,
          permissions,
          resourceType,
          userId,
        },
      } = action.payload;

      const modifiedPermissions = permissions.map(
        name => ({ name: removeResourceType(name, resourceType) }),
      );
      const {
        userPermissions: {
          [userId]: {
            [id]: {
              attributes,
              ...userUserPermission
            },
            ...userUserPermissions
          },
          ...userPermissions
        },
      } = state;

      return {
        ...state,
        userPermissions: {
          ...userPermissions,
          [userId]: {
            ...userUserPermissions,
            [id]: {
              ...userUserPermission,
              attributes: {
                ...attributes,
                permissions: uniqBy(modifiedPermissions, 'name'),
              },
            },
          },
        },
      };
    }

    default:
      return state;
  }
};

export default userPermissionsDataReducer;
