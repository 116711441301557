import constants from 'dispatcherConst';

const initialState = {
  filterParam: null,
};

export const filterProjectSuiteTasksReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_EXTRA_FILTERS_TRUE: {
      const {
        resourceId, requestType = null, resourceType = null, filterParam = null,
      } = action.payload;

      return {
        ...state,
        filterParam,
        [resourceId]: {
          ...state[resourceId],
          [`requestType-${resourceType}`]: requestType,
          [`resourceType-${resourceType}`]: true,
        },
      };
    }

    case constants.SET_EXTRA_FILTERS_FALSE: {
      return {
        ...state,
        filterParam: null,
      };
    }

    case constants.SET_RESOURCE_IDS_TO_FILTER: {
      const { resourceId, projectIds = [], path, requestType } = action.payload;

      return {
        ...state,
        [resourceId]: { ...state[resourceId], [`${requestType}-${path}`]: projectIds },
      };
    }

    default:
      return state;
  }
};

export default filterProjectSuiteTasksReducer;
