import constants from 'dispatcherConst';

export const setEndingCriteria = endingCriteria => ({
  type: constants.SET_ENDING_CRITERIA,
  payload: {
    endingCriteria,
  },
});

export default setEndingCriteria;
