import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Icon } from 'semantic-ui-react';
import isNull from 'lodash/isNull';

import { StyledAccordionTitle } from './components';

const AccordionItem = ({
  title,
  component: Component,
  index,
  active,
  onClick,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const isActive = isNull(index) ? open : index === active;
  const handleClick = () => {
    if (isNull(index)) {
      setOpen(!open);
    } else {
      const newIndex = isActive ? -1 : index;

      onClick(newIndex);
    }
  };

  return (
    <Fragment>
      <Accordion.Title active={isActive} onClick={handleClick}>
        <Icon name="dropdown" />
        <StyledAccordionTitle>
          {title}
        </StyledAccordionTitle>
      </Accordion.Title>
      <Accordion.Content active={isActive}>
        {isActive && <Component {...props} />}
      </Accordion.Content>
    </Fragment>
  );
};

AccordionItem.defaultProps = {
  index: null,
  active: null,
  onClick: () => null,
};

AccordionItem.propTypes = {
  title: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  index: PropTypes.number,
  active: PropTypes.number,
  onClick: PropTypes.func,
};

export default AccordionItem;
