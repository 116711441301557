/* eslint-disable no-param-reassign */
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import orderBy from 'lodash/orderBy';

const STATUS_COLORS = {
  ACTIVE: '#6CAF8F',
  DEMO: '#666370',
  CONSTRUCTION: '#BC5265',
};

const transformToSiteStatus = projectsStatus => {
  if (isEmpty(projectsStatus)) {
    return {
      total: 0,
      items: [],
    };
  }

  const items = projectsStatus.reduce((finalArray, project) => {
    const state = project.state.toUpperCase();

    const index = finalArray.findIndex(item => item.title === state);

    if (index === -1) {
      finalArray.push({
        title: state,
        amount: 1,
        barColor: has(STATUS_COLORS, state) ? STATUS_COLORS[state] : null,
      });

    } else {
      finalArray[index].amount += 1;
    }

    return finalArray;
  }, []);

  return {
    total: projectsStatus.length,
    items: orderBy(items, 'amount', 'desc'),
  };
};

export default transformToSiteStatus;
