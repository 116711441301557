import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { validateOnSubmit } from 'components/form/formConfigs';
import EditTaskModalForm from './EditTaskModalForm';

const EditTaskModalFormContainer = reduxForm({
  enableReinitialize: true,
  ...validateOnSubmit,
})(EditTaskModalForm);

export const mapStateToProps = (state, ownProps) => {
  const { item: { name, description } } = ownProps;

  return {
    initialValues: {
      name,
      description,
    },
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
)(EditTaskModalFormContainer);
