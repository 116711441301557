import twoFactorNeedsSetupSelector from './twoFactorNeedsSetupSelector';
import twoFactorQRUriSelector from './twoFactorQRUriSelector';
import twoFactorSecretKeySelector from './twoFactorSecretKeySelector';
import twoFactorOTPFailedSelector from './twoFactorOTPFailedSelector';
import twoFactorOTPSubmittingSelector from './twoFactorOTPSubmittingSelector';
import twoFactorActiveSelector from './twoFactorActiveSelector';
import invitationsSuppressedSelector from './invitationsSuppressedSelector';
import twoFactorDisablingSelector from './twoFactorDisablingSelector';

export default {
  twoFactorNeedsSetupSelector,
  twoFactorQRUriSelector,
  twoFactorSecretKeySelector,
  twoFactorOTPFailedSelector,
  twoFactorOTPSubmittingSelector,
  twoFactorActiveSelector,
  invitationsSuppressedSelector,
  twoFactorDisablingSelector,
};
