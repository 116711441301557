import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

export const StyledButton = styled(Button)`
  &.ui.button {
    margin-bottom: 10px;
  }
`;


export default StyledButton;
