export const options = [
  {
    key: '0',
    text: '0',
    value: 0,
  },
  {
    key: '1',
    text: '1',
    value: 1,
  },
  {
    key: '2',
    text: '2',
    value: 2,
  },
  {
    key: '3',
    text: '3',
    value: 3,
  },
  {
    key: '5',
    text: '5',
    value: 5,
  },
];

export default options;
