import { connect } from 'react-redux';

import { deepEqualMemoizeOne } from 'helpers/sharedMethods';
import { deviceSelector } from 'helpers/selectors';
import SLCNetworkInterfaceV1 from './SLCNetworkInterfaceV1';

export const makeMapState = () => {
  const memoIpAddress = deepEqualMemoizeOne();
  const memoSubnetMask = deepEqualMemoizeOne();
  const memoGateway = deepEqualMemoizeOne();
  const memoMac = deepEqualMemoizeOne();

  return function mapStateToProps(state, ownProps) {
    const { resourceId } = ownProps;
    const device = deviceSelector(state)(resourceId);
    const { reportedWithMeta } = device;
    const defaultTimestamp = 1582900503.113;
    const defaultIpAddress = { value: '192.168.0.16', timestamp: defaultTimestamp };
    const defaultSubnetMask = { value: '255.255.255.0', timestamp: defaultTimestamp };
    const defaultGateway = { value: '192.168.0.1', timestamp: defaultTimestamp };
    const defaultMacAddress = { value: '00-14-22-04-25-37', timestamp: defaultTimestamp };

    const {
      net: {
        mgmt: {
          nm: title = 'Management Network Interface',
          ipv4: ipAddress = defaultIpAddress,
          sub: subnetMask = defaultSubnetMask,
          gw: gateway = defaultGateway,
          mac: macAddress = defaultMacAddress,
        } = {},
      } = {},
    } = reportedWithMeta || {};

    return {
      loading: !reportedWithMeta,
      title,
      ipAddress: memoIpAddress(ipAddress),
      subnetMask: memoSubnetMask(subnetMask),
      gateway: memoGateway(gateway),
      mac: memoMac(macAddress),
    };
  };
};

export default connect(makeMapState())(SLCNetworkInterfaceV1);
