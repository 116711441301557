import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment } from 'semantic-ui-react';

import { ProjectSuitePermissionsGridWrapper } from '../../../../../containers';

const PermissionsProjectSuiteWrapper = ({ projectSuiteId }) => (
  <Segment>
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <ProjectSuitePermissionsGridWrapper projectSuiteId={projectSuiteId} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
);

PermissionsProjectSuiteWrapper.propTypes = {
  projectSuiteId: PropTypes.string.isRequired,
};

export default PermissionsProjectSuiteWrapper;
