import styled from 'styled-components';

import { mediaMax } from 'themes/mixins';

const TableWrapper = styled('div')`
  width: 48%;
  
  dt {
    width: 60%;
  }

  dd {
    width: 40%;
  }

  ${mediaMax.md`
    width: 100%;

    &:last-of-type {
      margin-top: 20px;
    }
  `};
`;

export default TableWrapper;
