class ControlUIOverrideControlModel {

  constructor(controlUiOverrideControl = {}) {
    this.index = controlUiOverrideControl.index;
    this.labelText = controlUiOverrideControl.labelText;
    this.runButtonText = controlUiOverrideControl.runButtonText;
    this.showIntensitySlider = controlUiOverrideControl.showIntensitySlider;
    this.showColorTempSlider = controlUiOverrideControl.showColorTempSlider;
    this.enabled = controlUiOverrideControl.enabled;
    this.runButtonBgColor = controlUiOverrideControl.runButtonBgColor;
    this.runButtonTextColor = controlUiOverrideControl.runButtonTextColor;
    this.labelTextColor = controlUiOverrideControl.labelTextColor;
    this.groups = controlUiOverrideControl.groups;
  }

}

export default ControlUIOverrideControlModel;
