import URLSearchParams from 'url-search-params';

export const urlParamsProperty = 'URLSearchParams';
// eslint-disable-next-line no-unused-expressions

export function addURLParamsProperty() {
  if (!(urlParamsProperty in window)) {
    window[urlParamsProperty] = URLSearchParams;
  }
}

addURLParamsProperty();
