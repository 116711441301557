import React from 'react';
import PropTypes from 'prop-types';

import { renderValues } from '../../utilities';

const RenderParamValue = ({ param, resourceName }) => {
  const { key } = param;

  if (key === 'recipientId' || key === 'resourceId') {
    return resourceName || renderValues(param);
  }

  return <>{renderValues(param)}</>;
};

RenderParamValue.defaultProps = {
  resourceName: undefined,
};

RenderParamValue.propTypes = {
  param: PropTypes.object.isRequired,
  resourceName: PropTypes.string,
};

export default RenderParamValue;
