import { createSelector } from 'reselect';
import get from 'lodash/get';

import { argsSelector, paginationSelector } from 'helpers/selectors';

export const makePaginationParamSelector = paramName => createSelector(
  [
    paginationSelector,
    argsSelector,
  ],
  (pagination, resourceType, fallback = null) => {
    const { pathname } = pagination;

    return get(pagination[pathname], `${resourceType}.${paramName}`) || fallback;
  },
);

export const makeChainLoaderPieceSelector = paramSelectors => createSelector(
  paramSelectors,
  (error, fetching, link, override) => ({
    error,
    fetching,
    link,
    override,
  }),
);
