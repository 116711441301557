import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';

import { validateOnSubmit } from 'components/form/formConfigs';
import EditAutomatedOperationTasksModalForm from './EditAutomatedOperationTasksModalForm';

const EditAutomatedOperationModalFormContainer = reduxForm({
  enableReinitialize: true,
  ...validateOnSubmit,
})(EditAutomatedOperationTasksModalForm);

export default compose(
  withRouter,
)(EditAutomatedOperationModalFormContainer);
