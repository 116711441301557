import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';

import constants from 'dispatcherConst';

const initialState = {
  isLoading: {},
  isLoadingRecipients: {},
  isOpen: {},
  allPermissions: [],
  cumulatedPermissions: {},
  permissionsTableRows: {},
  recipients: [],
  selectedRecipients: [],
  copyRecipientId: {},
  selectedResourceIds: {
    projects: [],
    project_suites: [],
    companies: [],
  },
};

const permissionsTableReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_ALL_PERMISSIONS_SUCCESS: {
      const { response } = action.payload;
      const { data } = response || {};

      return {
        ...state,
        allPermissions: data,
      };
    }

    case constants.FETCH_CUMULATED_PERMISSIONS_REQUEST: {
      const { resourceId } = action.payload;

      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [resourceId]: true,
        },
      };
    }

    case constants.FETCH_CUMULATED_PERMISSIONS_SUCCESS: {
      const { response } = action.payload;
      const {
        resourceId,
        newCumulatedPermissions,
        newPermissionsTableRows,
      } = response || {};

      const newState = {
        ...state,
        isLoading: {
          ...state.isLoading,
          [resourceId]: false,
        },
        cumulatedPermissions: {
          ...state.cumulatedPermissions,
          [resourceId]: newCumulatedPermissions,
        },
        permissionsTableRows: {
          ...state.permissionsTableRows,
          [resourceId]: newPermissionsTableRows,
        },
      };

      return newState;
    }

    case constants.FETCH_CUMULATED_PERMISSIONS_FAILED: {
      const { resourceId } = action.payload;

      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [resourceId]: false,
        },
      };
    }

    case constants.FETCH_PERMISSIONS_RECIPIENTS_REQUEST: {
      const { resourceId } = action.payload;

      return {
        ...state,
        isLoadingRecipients: {
          ...state.isLoadingRecipients,
          [resourceId]: true,
        },
      };
    }

    case constants.FETCH_PERMISSIONS_RECIPIENTS_SUCCESS: {
      const { response } = action.payload || {};
      const { resourceId, recipients } = response;

      const defaultSelectedRecipients = recipients.slice(0, 5);

      return {
        ...state,
        isLoadingRecipients: {
          ...state.isLoadingRecipients,
          [resourceId]: false,
        },
        recipients: {
          ...state.recipients,
          [resourceId]: recipients,
        },
        selectedRecipients: {
          ...state.selectedRecipients,
          [resourceId]: defaultSelectedRecipients.map(recipient => recipient.key),
        },
      };
    }

    case constants.CHANGE_RECIPIENTS_PERMISSIONS_TABLE_REQUEST: {
      const {
        resourceId,
        parentResourceId,
        childResourceIds,
        recipientIds,
      } = action.payload || {};

      let newState = {
        ...state,
        selectedRecipients: {
          ...state.selectedRecipients,
          [parentResourceId || resourceId]: recipientIds,
        },
      };

      if (childResourceIds && !isEmpty(childResourceIds)) {
        newState = childResourceIds.reduce((acc, childResourceId) => {

          acc.selectedRecipients[childResourceId] = recipientIds;

          return acc;
        }, newState);
      }

      return newState;
    }

    case constants.GRANT_MULTIPLE_PERMISSIONS_SUCCESS:
    case constants.REVOKE_MULTIPLE_PERMISSIONS_SUCCESS: {
      const { response } = action.payload || {};

      const {
        resourceId,
        newCumulatedPermissions,
        newPermissionsTableRows,
      } = response || {};

      return {
        ...state,
        cumulatedPermissions: {
          ...state.cumulatedPermissions,
          [resourceId]: newCumulatedPermissions,
        },
        permissionsTableRows: {
          ...state.permissionsTableRows,
          [resourceId]: newPermissionsTableRows,
        },
      };
    }

    case constants.CLEAR_PERMISSIONS_TABLE_REQUEST: {
      const {
        resourceId,
        parentResourceId,
        childResourceIds,
      } = action.payload;

      let newState = {
        selectedRecipients: {
          ...state.selectedRecipients,
          [parentResourceId || resourceId]: [],
        },
        cumulatedPermissions: {
          ...state.cumulatedPermissions,
          [parentResourceId || resourceId]: {},
        },
        permissionsTableRows: {
          ...state.permissionsTableRows,
          [parentResourceId || resourceId]: [],
        },
      };

      if (childResourceIds && !isEmpty(childResourceIds)) {
        newState = childResourceIds.reduce((acc, childResourceId) => {

          acc.selectedRecipients[childResourceId] = [];
          acc.cumulatedPermissions[childResourceId] = {};
          acc.permissionsTableRows[childResourceId] = [];

          return acc;
        }, newState);
      }

      return {
        ...state,
        ...newState,
        copyRecipientId: null,
      };
    }

    case constants.COPY_MULTIPLE_PERMISSIONS_REQUEST: {
      const { recipientId, resourceId } = action.payload;

      let newCopyRecipientId = null;

      if (has(state.copyRecipientId, resourceId)) {
        newCopyRecipientId = state.copyRecipientId[resourceId] === recipientId ? null : recipientId;
      } else {
        newCopyRecipientId = recipientId;
      }

      return {
        ...state,
        copyRecipientId: {
          ...state.copyRecipientId,
          [resourceId]: newCopyRecipientId,
        },
      };
    }

    case constants.TOGGLE_RESOURCE_OPEN_PERMISSIONS_TABLE_REQUEST: {
      const { resourceId } = action.payload;

      let isResourceOpen = false;

      if (has(state.isOpen, resourceId)) {
        isResourceOpen = !state.isOpen[resourceId];
      } else {
        isResourceOpen = true;
      }

      return {
        ...state,
        isOpen: {
          ...state.isOpen,
          [resourceId]: isResourceOpen,
        },
      };
    }

    case constants.ADD_RESOURCE_ID_PERMISSIONS_TABLE_REQUEST: {
      const { resourceId, resourceType } = action.payload;

      return {
        ...state,
        selectedResourceIds: {
          ...state.selectedResourceIds,
          [resourceType]: [
            ...state.selectedResourceIds[resourceType],
            resourceId,
          ],
        },
      };
    }

    case constants.REMOVE_RESOURCE_ID_PERMISSIONS_TABLE_REQUEST: {
      const { resourceId, resourceType } = action.payload;

      const newResourceIds = state.selectedResourceIds[resourceType]
        .filter(id => id !== resourceId);

      return {
        ...state,
        selectedResourceIds: {
          ...state.selectedResourceIds,
          [resourceType]: newResourceIds,
        },
      };
    }

    default:
      return state;
  }
};

export default permissionsTableReducer;
