import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import tasksActionCreators from 'application/tenant/utilities/tasks/tasksActionCreators';
import DropdownArrow from 'components/elements/DropdownArrow/DropdownArrow';
import { WarningIcon } from 'components/atomic';
import InlineEditTaskProperty from '../../../InlineEditTaskProperty/InlineEditTaskPropertyContainer';
import { invalidAction } from '../../constants';
import ActionCell from '../ActionCell/ActionCell.style';
import ActionRow from '../ActionRow/ActionRow.style';
import { DeleteAction, ActionParameters } from './components';
import { InnerCell, HoverRow } from './ActionsTableRow.style';

const ActionsTableRow = ({
  item,
  deviceName,
  taskId,
  taskName,
  actionType,
  invalid,
  params,
  typeId,
  projectId,
}) => {
  const [open, setOpen] = useState(false);
  const { logicalDeviceId, index } = item;
  const indexToString = index.toString();

  const toggleParams = () => {
    setOpen(!open);
  };

  return (
    <ActionRow>
      <ActionCell className="borderTop" colSpan="4">
        <Table className="noHover">
          <Table.Body>
            <HoverRow onClick={toggleParams}>
              <InnerCell>
                <DropdownArrow open={open} /> {deviceName}
              </InnerCell>
              <InnerCell>
                <InlineEditTaskProperty
                  id={indexToString}
                  property="name"
                  propertyValue={item.name}
                  onChange={tasksActionCreators.updateActionProperty}
                  taskId={taskId}
                  propertyType="action"
                />
                {invalid && <WarningIcon message={invalidAction} />}
              </InnerCell>
              <InnerCell>{actionType}</InnerCell>
              <InnerCell textAlign="right">
                <DeleteAction item={item} taskId={taskId} taskName={taskName} />
              </InnerCell>
            </HoverRow>
            {open
              && (
              <ActionParameters
                typeId={typeId}
                params={params}
                logicalDeviceId={logicalDeviceId}
                taskId={taskId}
                actionId={item.index}
                projectId={projectId}
              />
              )
            }
          </Table.Body>
        </Table>
      </ActionCell>
    </ActionRow>
  );
};

ActionsTableRow.defaultProps = {
  projectId: '',
};

ActionsTableRow.propTypes = {
  item: PropTypes.object.isRequired,
  deviceName: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
  taskName: PropTypes.string.isRequired,
  actionType: PropTypes.string.isRequired,
  invalid: PropTypes.bool.isRequired,
  params: PropTypes.array.isRequired,
  typeId: PropTypes.string.isRequired,
  projectId: PropTypes.string,
};

export default ActionsTableRow;
