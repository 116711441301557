import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import controlPanelActionCreators from 'pages/ControlPanel/controlPanelActionCreators';
import { selectors } from '../../utilities';

import ControlUIPageNavigation from './ControlUIPageNavigation';

export const mapStateToProps = (state, { match }) => {
  const { id: resourceId } = match.params;
  const { pages = [] } = selectors.projectControlUISelector(state)(resourceId) || {};

  return {
    pages,
    isPageNavigationOpen: state.controlPanel.isPageNavigationOpen,
  };
};

const mapDispatchToProps = {
  onTogglePageNavigation: controlPanelActionCreators.togglePageNavigation,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ControlUIPageNavigation);
