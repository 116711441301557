import styled from 'styled-components';

export const Container = styled.div`
  min-height: 30vh;
  padding: 25px 0;
`;

export const StyledLabel = styled.label`
  font-weight: bold;
  font-size: 14px;
`;

export default Container;
