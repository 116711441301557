import { put, race, take } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import { actionCreators as resetOTPActionCreators } from '../../../../utilities';
import actionCreators from '../actionCreators';

function* disableTwoFactor() {
  yield put(resetOTPActionCreators.resetOTPSecret());
  const { success, failure } = yield race({
    success: take(constants.RESET_OTP_SECRET_SUCCESS),
    failure: take(constants.RESET_OTP_SECRET_FAILED),
  });

  if (success) {
    yield put(actionCreators.disableTwoFactorSuccess());
  }

  if (failure) {
    yield put(actionCreators.disableTwoFactorFailure());
  }
}

export default disableTwoFactor;
