import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { uploadFileType } from 'data/types';
import DataTableRow from 'components/DataTable/DataTableRow/DataTableRow';
import DataTableText from 'components/DataTable/DataTableText/DataTableText';
import DataTableProgress from 'components/DataTable/DataTableProgress/DataTableProgress';
import UploadDataTableButton from 'components/elements/UploadTableRow/UploadTableRow.style';
import { FlatIcon } from 'components/elements';

class UploadTableRow extends Component {

  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
    this.handleForce = this.handleForce.bind(this);
    this.handlePause = this.handlePause.bind(this);
    this.handleResume = this.handleResume.bind(this);
  }

  handleCancel(e) {
    const { cancelUpload } = this.context;
    const { item } = this.props;

    e.stopPropagation();
    cancelUpload(item);
  }

  handleForce(e) {
    const { forceUpload } = this.context;
    const { item } = this.props;

    e.stopPropagation();
    forceUpload(item);
  }

  handlePause(e) {
    const { pauseUpload } = this.context;
    const { item } = this.props;

    e.stopPropagation();
    pauseUpload(item);
  }

  handleResume(e) {
    const { resumeUpload } = this.context;
    const { item } = this.props;

    e.stopPropagation();
    resumeUpload(item);
  }

  render() {
    const { renderControl } = this.context;
    const {
      item: { statusFlags: { paused }, pauseResumeProps, playProps },
      item,
    } = this.props;

    return (
      <DataTableRow {...this.props}>
        {renderControl(
          item,
          <UploadDataTableButton onClick={this.handleForce} />,
          <UploadDataTableButton icon={<FlatIcon name="play_arrow" />} />,
          playProps,
        )}
        <DataTableText />
        <DataTableText />
        <DataTableText />
        <DataTableText />
        <DataTableProgress />
        {renderControl(
          item,
          <UploadDataTableButton onClick={paused ? this.handleResume : this.handlePause} />,
          <UploadDataTableButton icon={<FlatIcon name="refresh" />} />,
          pauseResumeProps,
        )}
        <UploadDataTableButton onClick={this.handleCancel} />
      </DataTableRow>
    );
  }

}

UploadTableRow.contextTypes = {
  cancelUpload: PropTypes.func.isRequired,
  forceUpload: PropTypes.func.isRequired,
  pauseUpload: PropTypes.func.isRequired,
  renderControl: PropTypes.func.isRequired,
  resumeUpload: PropTypes.func.isRequired,
};

UploadTableRow.propTypes = {
  // eslint-disable-next-line react/no-typos
  item: uploadFileType.isRequired,
};

export default UploadTableRow;
