import { connect } from 'react-redux';

import { signUpWebRedirectionSelector } from 'helpers/selectors';
import ConsoleSwitch from './ConsoleSwitch';
import { relativeWebRedirection } from './utilities';

const mapStateToProps = state => {
  const signUpWebRedirection = signUpWebRedirectionSelector(state);
  const webRedirection = relativeWebRedirection(signUpWebRedirection);

  return {
    webRedirection,
  };
};

export default connect(mapStateToProps)(ConsoleSwitch);
