import permissionsDeviceCopyUserIdSelector from './permissionsDeviceCopyUserIdSelector';
import permissionsProjectCopyUserIdSelector from './permissionsProjectCopyUserIdSelector';
import permissionsUserCopyUserIdSelector from './permissionsUserCopyUserIdSelector';
import userPermissionsSelector from './userPermissionsSelector';
import userPermissionDataSelector from './userPermissionDataSelector';
import permissionsProjectSuiteCopyUserIdSelector from './permissionsProjectSuiteCopyUserIdSelector';

export default {
  permissionsDeviceCopyUserIdSelector,
  permissionsProjectCopyUserIdSelector,
  permissionsUserCopyUserIdSelector,
  userPermissionsSelector,
  userPermissionDataSelector,
  permissionsProjectSuiteCopyUserIdSelector,
};
