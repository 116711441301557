import isEmpty from 'lodash/isEmpty';

const validateSpecificDatesView = ({ recurrenceParams }) => {
  const errors = {};

  const { specificDates, specificTimes } = recurrenceParams || {};

  if (isEmpty(specificDates)) {
    errors.dates = 'You must specify at least one date';
  }

  if (isEmpty(specificTimes)) {
    errors.times = 'You must specify at least one time';
  }

  return {
    isValid: isEmpty(Object.keys(errors)),
    errors,
  };
};

export default validateSpecificDatesView;
