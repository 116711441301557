import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import colors from 'themes/colors';
import UploadsController from 'components/UploadsWidget/UploadsController/UploadsControllerContainer';

const StyledMobileUploads = styled(UploadsController)`
  ${({ fixed }) => fixed && css`
    height: 70px;
  `};

  & .ui.accordion:nth-of-type(odd) {
    background-color: ${rgba(colors.black, 0.05)};
  }
`;

export default StyledMobileUploads;
