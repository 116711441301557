import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { deepEqualMemoizeOne, buildObjectURL } from 'helpers/sharedMethods';
import { resourceUploadSelector } from 'components/ProjectFileUpload/projectFileUploadSelectors';

import controlPanelActionCreators from 'pages/ControlPanel/controlPanelActionCreators';
import { selectors } from '../../../../utilities';
import ControlUIPage from './ControlUIPage';

export const makeMapState = () => {
  const memoButtons = deepEqualMemoizeOne();
  const memoOverrideControls = deepEqualMemoizeOne();

  return function mapStateToProps(state, { match, pageId }) {
    const { id: projectId } = match.params;
    const fileIdentifier = `backgroundImage-${projectId}-${pageId}`;
    const { pages = [] } = selectors.projectControlUISelector(state)(projectId) || {};
    const {
      name,
      pageType,
      nameColor,
      buttons,
      overrideControls,
      backgroundImg,
      backgroundImgBlob,
    } = pages.filter(({ index }) => index === pageId)[0] || {};

    const {
      systemFile,
    } = resourceUploadSelector(state)(fileIdentifier) || {};
    const localURL = buildObjectURL(systemFile);

    return {
      name,
      pageType,
      nameColor,
      buttons: memoButtons(buttons),
      overrideControls: memoOverrideControls(overrideControls),
      backgroundImg,
      backgroundImgBlob,
      localURL,
    };
  };
};

const mapDispatchToProps = {
  onTogglePageNavigation: controlPanelActionCreators.togglePageNavigation,
};

export default compose(
  withRouter,
  connect(makeMapState, mapDispatchToProps),
)(ControlUIPage);
