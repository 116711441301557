import styled from 'styled-components';

export const StyledDateWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  width: 145px;
  justify-content: space-between;
  align-items: center;
  height: auto;
  margin-right: 20px;

  @media only screen and (max-width: 396px) {
    width: 200px;
  }

  & .ui.icon.button, .ui.icon.buttons .button {
    padding: 0.7em 0.9em;
  }

  & .rw-btn.rw-btn-select {
    width: 28px;
    height: 30px;
  }
`;

export const StyledLabel = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

export default StyledDateWrapper;
