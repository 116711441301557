import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  currentUserSelector,
  settingsApiDomainSelector,
  settingsBackendVersionSelector,
  settingsServiceVersionsSelector,
} from 'helpers/selectors';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import redirectIfNotFound from 'helpers/redirectIfNotFound';
import { actionCreators } from './utilities';
import Settings from './Settings';

export const mapStateToProps = state => ({
  apiDomain: settingsApiDomainSelector(state),
  currentUser: currentUserSelector(state),
  backendVersionData: settingsBackendVersionSelector(state),
  serviceVersions: settingsServiceVersionsSelector(state),
});

export const mapDispatchToProps = {
  onChangeApiDomain: actionCreators.changeApiDomain,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  redirectIfNotFound('currentUser.superadmin', false),
  chainedLoaderWithSaga(actionCreators.fetchBackendVersion),
  chainedLoaderWithSaga(actionCreators.fetchServiceVersions),
)(Settings);
