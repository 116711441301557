import styled, { css } from 'styled-components';
import { Segment, Header, Progress } from 'semantic-ui-react';

import filterProps from 'helpers/filterProps';

export const StyledSegment = styled(Segment)`
  height: 100%;
`;

export const StyledHeader = styled(Header)`
  text-transform: uppercase !important;
  color: rgba(0,0,0,0.87) !important;
`;

const FilteredProps = filterProps([
  'barColor',
])(Progress);

export const StyledProgress = styled(FilteredProps)`
  margin-top: 4px !important;
  margin-bottom: 8px !important;

  ${({ barColor }) => barColor && css`
    div.bar {
      background-color: ${barColor} !important;
    }
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTitle = styled.small`
  display: block;
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }

  span {
    margin-left: auto;
  }  
`;

export const ItemsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2em;
`;

export default StyledHeader;
