import constants from 'dispatcherConst';

const copyMultiplePermissions = (recipientId, resourceId) => ({
  type: constants.COPY_MULTIPLE_PERMISSIONS_REQUEST,
  payload: {
    recipientId,
    resourceId,
  },
});

export default copyMultiplePermissions;
