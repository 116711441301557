import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { List, Grid } from 'semantic-ui-react';

import { Button } from 'components/atomic';

import { StyledColumn } from './InvitationItem.style';

const RESOURCE_TYPES = {
  project: 'Site',
  project_suite: 'Multi-site',
};

const InvitationItem = ({
  invitationId,
  resourceId,
  resourceName,
  resourceType,
  onFetchProject,
  onFetchProjectSuite,
  onDeleteInvitation,
}) => {
  useEffect(() => {
    if (!resourceName) {
      if (resourceType === 'project') {
        onFetchProject(resourceId);
      } else {
        onFetchProjectSuite(resourceId);
      }
    }
  }, [resourceId, resourceName, resourceType, onFetchProject, onFetchProjectSuite]);

  const handleDeleteInvitation = () => onDeleteInvitation(invitationId);

  return (
    <List.Item>
      <List.Content>
        <Grid>
          <Grid.Row>
            <StyledColumn computer={13}>
              {resourceName || 'Loading...'} ({RESOURCE_TYPES[resourceType]})
            </StyledColumn>
            <Grid.Column computer={3} textAlign="right">
              <Button onClick={handleDeleteInvitation}>Accept</Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </List.Content>
    </List.Item>
  );
};

InvitationItem.defaultProps = {
  resourceName: null,
};

InvitationItem.propTypes = {
  invitationId: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  resourceName: PropTypes.string,
  onFetchProject: PropTypes.func.isRequired,
  onFetchProjectSuite: PropTypes.func.isRequired,
  onDeleteInvitation: PropTypes.func.isRequired,
  resourceType: PropTypes.oneOf(['project', 'project_suite']).isRequired,
};

export default InvitationItem;
