import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import onMountUnmount from 'helpers/onMountUnmount';
import { NOTIFICATION_EVENTS_FIELD, LOGICAL_DEVICES_FIELD } from 'helpers/selectors';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { addPagination } from 'helpers/paginationHelpers';

import { actionCreators } from '../../utilities';
import NotificationsLog from './NotificationsLog';

export default compose(
  withRouter,
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: NOTIFICATION_EVENTS_FIELD, included: true },
      () => actionCreators.fetchNotificationEvents({
        include: ['notifiable'],
        fields: {
          [LOGICAL_DEVICES_FIELD]: [
            'id',
            'name',
          ],
        },
        sort: '-occurredAt',
      }),
    ),
  ),
  onMountUnmount(
    () => actionCreators.clearNotificationEventFilters(),
    () => actionCreators.clearNotificationEventFilters(),
  ),
)(NotificationsLog);
