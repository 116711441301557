import constants from 'dispatcherConst';

export const setProtocol = protocolId => ({
  type: constants.SET_PROTOCOL,
  payload: {
    protocolId,
  },
});

export default setProtocol;
