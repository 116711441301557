import { call } from 'redux-saga/effects';

import endpoints from 'data/api/endpoints';
import { processCall } from 'helpers/sagaHelper';
import { putPath } from 'data/api/requests';
import ProjectModel from 'models/ProjectModel';
import siteActionCreators from '../actionCreators';
import { convertKeyValueToInt } from './utilities';

function* updateSite(action) {
  const {
    values: { id: siteId },
    values,
    message,
    ...rest
  } = action.payload;
  const updatedValues = convertKeyValueToInt(values, 'autoRenewYears');
  const endpoint = endpoints.PROJECT(siteId);
  const body = ProjectModel.requestBody(updatedValues);
  const { response, error } = yield call(putPath, endpoint, body);
  const responseWithMessage = response && { ...response, message };

  const params = {
    response: responseWithMessage,
    error,
    successDisp: siteActionCreators.updateSiteSuccess,
    failDisp: siteActionCreators.updateSiteFailure,
    ...rest,
  };
  yield call(processCall, params);
  yield !!error;
}

export default updateSite;
