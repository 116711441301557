import { connect } from 'react-redux';

import { actionCreators, selectors } from '../../../../../../utilities';
import TaskName from './TaskName';

export const mapStateToProps = state => ({
  taskName: selectors.taskNameSelector(state),
});

const mapDispatchToProps = {
  onTaskNameChange: actionCreators.setTaskName,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskName);
