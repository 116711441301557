import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';

const replaceWordsInString = (string, obj) => {

  if (isString(string) && !isEmpty(obj)) {
    const regExp = new RegExp(Object.keys(obj).join('|'), 'gi');

    return string.replace(regExp, matched => (
      obj[matched.toLowerCase()]
    ));
  }

  if (isEmpty(obj)) {
    return string;
  }

  return string;
};

export default replaceWordsInString;
