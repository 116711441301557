import styled from 'styled-components';
import { ChromePicker } from 'react-color';
import { rgba } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ColorPreviewToggle = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid black;
  border-radius: 3px;
  background-color: ${({ displayColor: { r, g, b, a } }) => rgba(r, g, b, a)};
`;

export const StyledChromePicker = styled(ChromePicker)`
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 20%);
  border-radius: 3px;
`;
