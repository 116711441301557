import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';

import Nav from 'App.style';

const withLoader = WrappedComponent => (class extends Component {

  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    loginChecked: PropTypes.bool.isRequired,
  };

  constructor() {
    super();
    this.state = {
      loaderVisible: false,
    };
  }

  componentDidMount() {
    this.loaderTimeout = setTimeout(() => {
      this.setState({ loaderVisible: true });
    }, 2000);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ loginChecked: nextLoginChecked }) {
    const { loginChecked } = this.props;

    if (!loginChecked && nextLoginChecked) {
      clearTimeout(this.loaderTimeout);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.loaderTimeout);
  }

  render() {
    const { loaderVisible } = this.state;
    const { loginChecked, ...rest } = this.props;

    if (!loginChecked && loaderVisible) {
      return (
        <Nav.Dimmer active>
          <Loader size="large">Loading</Loader>
        </Nav.Dimmer>
      );
    }

    if (!loginChecked) {
      return null;
    }

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <WrappedComponent {...rest} />;
  }

});

export default withLoader;
