import { createSelector } from 'reselect';

import schedulingSelector from './schedulingSelector';

const fetchingAvailableActionsSelector = createSelector(
  schedulingSelector,
  scheduling => scheduling.isFetchingAvailableActions,
);

export default fetchingAvailableActionsSelector;
