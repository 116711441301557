import React from 'react';
import PropTypes from 'prop-types';

import { StyledAddProjectButton } from './AddProjectButton.style';

const AddProjectButton = ({ selectingProjects, onToggleSelectingProjects }) => (
  <StyledAddProjectButton onClick={onToggleSelectingProjects}>
    {selectingProjects ? 'Done' : 'Add site...'}
  </StyledAddProjectButton>
);

AddProjectButton.propTypes = {
  selectingProjects: PropTypes.bool.isRequired,
  onToggleSelectingProjects: PropTypes.func.isRequired,
};

export default AddProjectButton;
