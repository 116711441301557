import { compose } from 'redux';
import { connect } from 'react-redux';

import { allActionsTypeSelector } from 'helpers/selectors';

import { actionCreators, selectors } from '../../utilities';
import ActionsStep from './ActionsStep';

export const mapStateToProps = state => {
  const actionTypes = allActionsTypeSelector(state);

  return {
    actionTypes,
    selectedActions: selectors.selectedActionsSelector(state),
    selectedTasks: selectors.selectedTasksSelector(state),
    selectedDevice: selectors.selectedDeviceSelector(state),
    selectedProject: selectors.selectedProjectSelector(state),
  };
};

const mapDispatchToProps = {
  onDeviceSelected: actionCreators.setSelectedDevice,
  onAddSelectedAction: actionCreators.addSelectedAction,
  onRemoveSelectedAction: actionCreators.removeSelectedAction,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ActionsStep);
