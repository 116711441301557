import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid } from 'semantic-ui-react';
import sortBy from 'lodash/sortBy';
import uniqueId from 'lodash/uniqueId';

import DataAccordion from 'components/DataAccordion/DataAccordion';
import DefinitionList from '../DefinitionList/DefinitionList';

const DefinitionListAccordion = ({
  heading,
  headingText,
  labels,
  pluralDefinitionList,
  open,
  ...rest
}) => (
  <DataAccordion headerAs={heading} open={open}>
    <Grid header={headingText}>
      <Grid.Row>
        {sortBy(pluralDefinitionList, 'idx').map((definitionList, index) => (
          <Grid.Column key={uniqueId()} mobile={16} {...rest}>
            <DefinitionList data={definitionList} labels={labels} />
            {(index !== pluralDefinitionList.length - 1) && (
              <Divider />
            )}
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  </DataAccordion>
);

DefinitionListAccordion.defaultProps = {
  open: false,
  heading: undefined,
  pluralDefinitionList: undefined,
};

DefinitionListAccordion.propTypes = {
  open: PropTypes.bool,
  heading: PropTypes.string,
  headingText: PropTypes.string.isRequired,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      field: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  pluralDefinitionList: PropTypes.arrayOf(
    PropTypes.object.isRequired,
  ),
};

export default DefinitionListAccordion;
