import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import { TIMELINES_FIELD } from 'helpers/selectors';


export const fetchTimelines = (deviceId, urlParams = {}) => ({
  type: constants.FETCH_TIMELINES_REQUEST,
  payload: {
    deviceId,
    endpoint: endpoints.TIMELINES(deviceId),
    urlParams,
  },
});

export const fetchTimelinesFailure = error => ({
  type: constants.FETCH_TIMELINES_FAILED,
  payload: {
    info: !error.unauthorized && error.notFound && messages.NO_TIMELINES,
    error: !error.notFound && error,
    resourceType: TIMELINES_FIELD,
  },
});

export const fetchTimelinesSuccess = response => ({
  type: constants.FETCH_TIMELINES_SUCCESS,
  payload: { response },
});

export const fetchTimelinesSuccessPaginated = response => ({
  type: constants.FETCH_TIMELINES_PAGINATED,
  payload: { response },
});
