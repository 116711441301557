import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';

import { CONTROL_UI_PAGE_TYPES_LABELS } from 'application/tenant/components/structural/ControlUI/constants';

import {
  FloatingCard,
  Overlay,
  StyledHeader,
  Body,
  StyledListItem,
  StyledListContent,
  StyledPageInfo,
  StyledIcon,
} from './ControlUIPageNavigation.style';

function ControlUIPageNavigation({
  pages,
  isPageNavigationOpen,
  onTogglePageNavigation,
  onPageChange,
}) {
  useEffect(() => {
    if (isPageNavigationOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isPageNavigationOpen]);

  const handlePageChange = pageId => {
    onPageChange({}, { activePage: pageId + 1 });
    onTogglePageNavigation();
  };

  return (
    <div>
      <Overlay isVisible={isPageNavigationOpen} onClick={onTogglePageNavigation} />
      <FloatingCard
        isVisible={isPageNavigationOpen}
        fullHeight
        className="card"
      >
        <StyledHeader>
          <h4>Page navigation</h4>
        </StyledHeader>
        <Body>
          <List divided relaxed>
            {pages.map(page => {
              const { index, name, pageType } = page;
              const description = CONTROL_UI_PAGE_TYPES_LABELS[pageType];

              return (
                <StyledListItem key={index} onClick={() => handlePageChange(index)}>
                  <StyledListContent>
                    <StyledPageInfo>
                      <div>
                        <List.Header>
                          {name}
                        </List.Header>
                        <small>
                          {description}
                        </small>
                      </div>
                    </StyledPageInfo>
                    <StyledIcon name="arrow right" />
                  </StyledListContent>
                </StyledListItem>
              );
            })}
          </List>
        </Body>
      </FloatingCard>
    </div>
  );
}

ControlUIPageNavigation.propTypes = {
  isPageNavigationOpen: PropTypes.bool.isRequired,
  pages: PropTypes.array.isRequired,
  onTogglePageNavigation: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default ControlUIPageNavigation;
