import React from 'react';

import StaticScenesSection from 'components/StaticScenesSection/StaticScenesSectionContainer';

const labels = [
  { text: 'Number', field: 'id', width: 2 },
  { text: 'Name', field: 'name', width: 3 },
  { text: 'Status', field: 'status', width: 11 },
];

const PharosStaticScenesV1 = props => <StaticScenesSection {...props} labels={labels} />;

export default PharosStaticScenesV1;
