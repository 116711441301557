import styled from 'styled-components';

export const StyledLabel = styled.span`
  display: inline-block;
  margin-top: 3px;
`;

export const StyledToggle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 155px;
`;
