import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';

import NarrowContainer from 'components/NarrowContainer/NarrowContainer.style';
import {
  ControlPanelWrapper,
  BrandLogo,
  BrandLogoText,
  BrandLogoWrapper,
  FooterWrapper,
} from './Footer.style';

const Footer = ({ inControlPanelContext, isPageNavigationOpen }) => {
  if (inControlPanelContext) {
    return (
      <ControlPanelWrapper isPageNavigationOpen={isPageNavigationOpen}>
        <BrandLogoText>powered by</BrandLogoText>
        <BrandLogo />
      </ControlPanelWrapper>
    );
  }

  return (
    <FooterWrapper control={inControlPanelContext}>
      <Segment vertical textAlign="right">
        <NarrowContainer>
          <BrandLogoWrapper>
            <BrandLogoText>powered by</BrandLogoText>
            <BrandLogo />
          </BrandLogoWrapper>
        </NarrowContainer>
      </Segment>
    </FooterWrapper>
  );
};

Footer.propTypes = {
  inControlPanelContext: PropTypes.bool.isRequired,
  isPageNavigationOpen: PropTypes.bool.isRequired,
};

export default Footer;
