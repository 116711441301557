import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { OPERATIONS_FIELD } from 'helpers/selectors';
import DataTable from 'components/DataTable/DataTableContainer';
import {
  SchedulesTableWrapperCell,
} from '../../AutomatedOperationsAccordionRow.style';
import { OperationCustomRow } from './components';
import EditAutomatedOperationTasks from '../../../EditAutomatedOperationTasks/EditAutomatedOperationTasksContainer';

const labels = [
  { text: 'Operation', field: 'name', width: 8 },
  { text: 'Description', field: 'description', width: 6 },
  { text: '', field: '', width: 2 },
];

const OperationsTable = ({
  operationsData,
  currentItem,
  hideHeader,
  enableTaskSchedulerEdit,
}) => (
  <Table.Row>
    <Table.Cell>
      &nbsp;
    </Table.Cell>
    <SchedulesTableWrapperCell colSpan="3" hideHeader={hideHeader}>
      <DataTable
        data={operationsData}
        header="Tasks"
        labels={labels}
        customTableRow={OperationCustomRow}
        createElement={enableTaskSchedulerEdit ? EditAutomatedOperationTasks : null}
        pagination={{
          type: OPERATIONS_FIELD,
          included: true,
        }}
        currentItem={currentItem}
      />
    </SchedulesTableWrapperCell>
  </Table.Row>
);

OperationsTable.defaultProps = {
  currentItem: null,
  hideHeader: false,
  enableTaskSchedulerEdit: true,
};

OperationsTable.propTypes = {
  operationsData: PropTypes.array.isRequired,
  currentItem: PropTypes.object,
  hideHeader: PropTypes.bool,
  enableTaskSchedulerEdit: PropTypes.bool,
};

export default OperationsTable;
