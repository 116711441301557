import { compose } from 'redux';
import { connect } from 'react-redux';

import { USERS_FIELD } from 'helpers/selectors';
import { PermissionsGridItem } from '../../../../../../../../../../components';
import permissionsActionCreators from '../../../../../../../../../../utilities/permissions/permissionsActionCreators';
import { selectors } from '../../../../../../../../../../utilities/permissions';

export const makeMapState = () => (state, { resourceId, path, userId }) => {
  const {
    permissionCount,
    userCount,
    permissionId,
  } = selectors.userPermissionDataSelector(
    state,
    {
      scopes: ['tenant'],
      resourceId,
      resourceType: USERS_FIELD,
      userId,
      path,
    },
  );
  const copyUserId = selectors.permissionsUserCopyUserIdSelector(
    state,
    {
      userId: resourceId,
    },
  );

  return {
    isOn: !!userCount,
    isPartial: permissionCount && permissionCount > userCount,
    permissionId,
    copyUserId,
  };
};

const mapDispatchToProps = {
  onToggleUserPermission: (
    userId,
    resourceId,
    permissionId,
    isOn,
  ) => permissionsActionCreators.toggleContextUserPermission(
    userId,
    USERS_FIELD,
    resourceId,
    permissionId,
    isOn,
  ),
  onToggleUserHeaderPermission: (
    userId,
    resourceId,
    path,
    isOn,
    isPartial,
  ) => permissionsActionCreators.toggleContextUserHeaderPermission(
    userId,
    USERS_FIELD,
    path,
    isOn,
    isPartial,
  ),
};

export default compose(
  connect(makeMapState, mapDispatchToProps),
)(PermissionsGridItem);
