import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';

import { changePasswordSelector } from 'helpers/selectors';
import formNames from 'components/form/formNames';
import { validateOnSubmit } from 'components/form/formConfigs';
import ChangePasswordForm, { passwordFormFields } from './ChangePasswordForm';

const ChangePasswordFormContainer = reduxForm({
  form: formNames.changePassword,
  ...validateOnSubmit,
})(ChangePasswordForm);

const selector = formValueSelector(formNames.changePassword);

const mapStateToProps = state => ({
  allValues: {
    [passwordFormFields.newPassword]: selector(state, 'newPassword'),
  },
  status: changePasswordSelector(state),
});

export default connect(
  mapStateToProps,
)(ChangePasswordFormContainer);
