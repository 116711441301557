import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isNull from 'lodash/isNull';

import { selectors } from '../../utilities';
import ControlUIPageCarousel from './ControlUIPageCarousel';

export const mapStateToProps = (state, { match }) => {
  const { id: projectId } = match.params;
  const { pages = null } = selectors.projectControlUISelector(state)(projectId) || {};
  const pagesLoading = isNull(pages);

  return {
    pagesLoading,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
)(ControlUIPageCarousel);
