import React, { Component } from 'react';
import get from 'lodash/get';

const withPolling = (interval = 10, shouldPollCheckPath = '') => WrappedComponent => {
  class PollingComponent extends Component {

    constructor() {
      super();
      this.state = {
        toggle: false,
      };
    }

    componentDidMount() {
      if (!shouldPollCheckPath) {
        this.startInterval();
        return;
      }

      const shouldPoll = get(this.props, shouldPollCheckPath);
      if (shouldPoll) {
        this.startInterval();
      }
    }

    componentDidUpdate(prevProps) {
      if (!shouldPollCheckPath) return;

      const prevShouldPoll = get(prevProps, shouldPollCheckPath);
      const shouldPoll = get(this.props, shouldPollCheckPath);
      if (prevShouldPoll === shouldPoll) return;

      if (shouldPoll) {
        this.startInterval();
      } else {
        clearInterval(this.interval);
      }
    }

    componentWillUnmount() {
      clearInterval(this.interval);
    }

    startInterval() {
      this.interval = setInterval(
        () => this.setState(({ toggle }) => ({ toggle: !toggle })),
        interval * 1000,
      );
    }

    render() {
      const { toggle } = this.state;

      return <WrappedComponent {...this.props} pollingToggle={toggle} />;
    }

  }

  return PollingComponent;
};

export default withPolling;
