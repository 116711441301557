import {
  resetOTPSecret,
  resetOTPSecretSuccess,
  resetOTPSecretFailure,
} from './resetOTPSecret';

export default {
  resetOTPSecret,
  resetOTPSecretSuccess,
  resetOTPSecretFailure,
};
