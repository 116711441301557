function createDropdownOptionForTriggers({ id, name, type }) {
  if (id && name) {
    return {
      key: id,
      text: type === 'auto' ? `- ${name}` : `${id} - ${name}`,
      value: id,
    };
  }

  return {
    key: 'not_found',
    text: 'Undefined trigger',
    value: 'not_found',
  };
}

export default createDropdownOptionForTriggers;
