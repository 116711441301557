import styled, { css } from 'styled-components';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import isNull from 'lodash/isNull';

import { mediaMax } from 'themes/mixins';
import { modifierTypes } from '../../constants';
import { dayTypeStyle } from './utilities';

export const StyledDayPicker = styled(DayPicker)`
  flex: 0;

  & .DayPicker-Month {
    border-collapse: separate;

    ${mediaMax.sm`
      margin: 0 !important;
    `};
  }

  & .DayPicker-NavButton {
    top: 0em;
  }

  & .DayPicker-WeekdaysRow {
    display: flex;
  }

  & .DayPicker-Weekday {
    display: inline-block;
    width: 57px;

    ${mediaMax.sm`
      width: calc(100vw / 8) !important;
    `};
  }

  & .DayPicker-Body {
    display: block;
    min-height: 375px;

    ${mediaMax.sm`
      min-height: 310px;
    `};
    
    ${({ isLoading }) => isLoading && css`
    
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 90%;
        background-color: rgba(255,255,255,.85);
        z-index: 1001;

        ${mediaMax.sm`
          height: 80%;
        `};
      }

      &::after{
        content: '';
        position: absolute;
        top: 43%;
        left: 50%;
        margin-top: -15px;  
        margin-left: -25px;
        width: 50px;
        height: 50px;
        border: .2em solid rgba(0,0,0,.1);
        border-radius: 50px;
        border-top-color: #767676;
        animation: loading 0.8s linear infinite;
        z-index: 1002
      }

      @keyframes loading {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

    `}
  }

  & .DayPicker-Day {
    display: inline-block;
    width: 57px !important;
    height: 57px !important;
    padding: 6px;
    border-radius: 8px;
    border: 3px solid #FFF;
    z-index: 1;

    ${mediaMax.sm`
      width: calc(100vw / 8) !important;
      height: calc(100vw / 8) !important;
      padding-top: 3px;
      padding-bottom: 0;
    `};
  }

  ${dayTypeStyle(modifierTypes.DISPATCHED_ERROR, css`
    background-color: rgba(208, 2, 27, .20);

    :hover {
      background-color: rgba(208, 2, 27, .20) !important;
      opacity: 0.7;
    }

    ${({ hideDots }) => hideDots && css`
      background-color: transparent;
    `}
  `)}

  ${dayTypeStyle(modifierTypes.DISPATCHED_SUCCESS, css`
    background-color: rgba(33, 186, 69, .20);

    :hover {
      background-color: rgba(33, 186, 69, .20) !important;
      opacity: 0.7;
    }

    ${({ hideDots }) => hideDots && css`
      background-color: transparent;
    `}
  `)}

  ${dayTypeStyle(modifierTypes.ACTIVE, css`
    background-color: #F0F8FF;
    border: 2px solid #000000;

    &:hover {
      background-color: #F0F8FF !important;
      opacity: 0.7 !important;
    }
  `)}  

  ${dayTypeStyle(modifierTypes.TODAY, css`
    font-weight: 500;

    span {
      color: #FFF !important;
      background-color: red !important;
      width: 22px;
      height: 22px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-left: auto;
      padding-right: 0;

      ${mediaMax.sm`
        width: 19px;
        height: 19px;
      `};
    }
  `)}
`;

export const DayCell = styled.div`
  color: #000;  
`;

export const DayNumber = styled.span`
  font-size: 12px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
`;

export const DotsContainer = styled.div`
  margin-top: 3px;
  margin-bottom: 6px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 3px;

  ${mediaMax.sm`
    margin-top: 1px;
    margin-bottom: 0;
  `};
`;

export const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: -2px;

  ${({ index, length }) => css`
    z-index: ${length - index};
  `}

  ${({ color }) => color && css`
    background-color: ${color};
  `}

  ${({ color }) => (isNull(color) || color === '#FFFFFF') && css`
    border: 1px solid #000;
  `}

  ${mediaMax.sm`
    width: 7px !important;
    height: 7px !important;
    margin-left: -3px;
  `};

  ${({ hideDots }) => hideDots && css`
    visibility: hidden;
  `}
`;

export default StyledDayPicker;
