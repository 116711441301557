import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import {
  AUTOMATED_OPERATIONS_FIELD,
} from 'helpers/selectors';


export const fetchAutomatedOperations = (resourceId, urlParams) => ({
  type: constants.FETCH_AUTOMATED_OPERATIONS_REQUEST,
  payload: {
    endpoint: endpoints.AUTOMATED_OPERATIONS,
    resourceType: AUTOMATED_OPERATIONS_FIELD,
    resourceId,
    urlParams,
  },
});

export const fetchAutomatedOperationsSuccess = response => ({
  type: constants.FETCH_AUTOMATED_OPERATIONS_SUCCESS,
  payload: {
    response,
    resourceType: AUTOMATED_OPERATIONS_FIELD,
  },
});

export const fetchAutomatedOperationsFailure = error => ({
  type: constants.FETCH_AUTOMATED_OPERATIONS_FAILED,
  payload: { error },
});
