import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Icon, Label } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';

import { Button } from 'components/atomic';

import { ReadOnlyActionBody } from './components';
import {
  StyledAccordionTitle,
  StyledAccordionContent,
  StyledAccordionButtons,
  ActionTitle,
} from './ReadOnlyActionItem.style';

function ReadOnlyActionItem({
  index,
  id,
  name,
  params,
  activePanels,
  onActionToggle,
  projectId,
}) {
  const isActive = useMemo(() => {
    if (isEmpty(activePanels)) {
      return false;
    }

    return activePanels[id] === true;
  }, [activePanels, id]);

  const icon = useMemo(() => `chevron ${isActive ? 'down' : 'left'}`, [isActive]);

  const handleToggle = useCallback(() => {
    if (isEmpty(params)) {
      return;
    }

    onActionToggle({
      taskActionId: id,
    });
  }, [id, params, onActionToggle]);

  const renderToggleButton = () => {
    if (isEmpty(params)) {
      return null;
    }

    return (
      <Button
        basic
        size="mini"
        icon={icon}
        className="action-toggle-btn"
        onClick={handleToggle}
      />
    );
  };

  return (
    <>
      <StyledAccordionTitle active={isActive} index={index}>
        <span>
          <Icon name="check circle" color="green" />
          <ActionTitle className="action-name" onClick={handleToggle}>
            {name}
          </ActionTitle>
        </span>
        <StyledAccordionButtons>
          <Label htmlFor="task">
            Read-only
          </Label>
          {renderToggleButton()}
        </StyledAccordionButtons>
      </StyledAccordionTitle>
      <StyledAccordionContent active={isActive}>
        <ReadOnlyActionBody actionId={id} params={params} projectId={projectId} />
      </StyledAccordionContent>
    </>
  );
}

ReadOnlyActionItem.defaultProps = {
  activePanels: {},
  projectId: null,
};

ReadOnlyActionItem.propTypes = {
  index: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  params: PropTypes.array.isRequired,
  activePanels: PropTypes.object,
  onActionToggle: PropTypes.func.isRequired,
  projectId: PropTypes.string,
};

export default ReadOnlyActionItem;
