import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import { DuplicatePageConfirmation } from './components';
import { StyledButton } from './DuplicatePage.style';

const DuplicatePage = ({
  pageId,
  projectId,
  onDuplicatePage,
}) => {
  const trigger = (
    <StyledButton icon labelPosition="left" fluid>
      <Icon name="copy" />
      Duplicate page
    </StyledButton>
  );

  const handleConfirm = ({ paginationIndex: pageIndex }) => {
    onDuplicatePage({ pageId, projectId, pageIndex });
  };

  return (
    <DuplicatePageConfirmation
      trigger={trigger}
      onSubmit={handleConfirm}
    />
  );
};

DuplicatePage.defaultProps = {
  pageId: null,
};

DuplicatePage.propTypes = {
  pageId: PropTypes.number,
  projectId: PropTypes.string.isRequired,
  onDuplicatePage: PropTypes.func.isRequired,
};

export default DuplicatePage;
