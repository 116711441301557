import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { paths } from 'navigation';
import ControlPanel from 'pages/ControlPanel/ControlPanelContainer';
import ControlPanelProjectSuite from 'pages/ControlPanel/ControlPanelProjectSuiteContainer';

const ControlPanelSwitch = () => (
  <Switch>
    <Route path={paths.projectControlPanel} component={ControlPanel} />
    <Route path={paths.userProjectControlPanel} component={ControlPanel} />
    <Route path={paths.projectSuiteControlPanel} component={ControlPanelProjectSuite} />
    <Route path={paths.userProjectSuiteControlPanel} component={ControlPanelProjectSuite} />
  </Switch>
);

export default ControlPanelSwitch;
