import React from 'react';
import { Field } from 'redux-form';
import { Grid } from 'semantic-ui-react';

import * as validators from 'components/form/formValidators';
import ToggleField from 'components/form/ToggleField/ToggleField';
import DropdownField from 'components/form/DropdownField/DropdownField';
import TextField from 'components/form/TextField/TextField';
import { TipIcon } from 'components/atomic';

import { StyledHeader, StyledLabel, StyledContent } from '..';
import { SelectColor } from './components';
import { formFields, modeOptions, colorList, MODE_HELP_TEXT } from './constants';

import { StyledForm, StyledGridRow, StyledGridColumn, StyledModeLabel } from './IdentificationReview.style';

function IdentificationReview() {
  return (
    <>
      <StyledHeader>
        <StyledLabel>
          Identification
        </StyledLabel>
      </StyledHeader>
      <StyledContent>
        <StyledForm>
          <Grid>
            <StyledGridRow>
              <Grid.Column mobile={16} computer={8}>
                <Field
                  name={formFields.name}
                  component={TextField}
                  type="text"
                  fluid
                  label="Name"
                  validate={validators.validDisplayName}
                  required
                  placeholder="Name"
                />
              </Grid.Column>
              <StyledGridColumn mobile={16} computer={8}>
                <Field
                  name={formFields.occurrenceColour}
                  component={SelectColor}
                  type="text"
                  label="Colour"
                  colorList={colorList}
                  required
                  existingColor={colorList[0]}
                />
              </StyledGridColumn>
            </StyledGridRow>
            <StyledGridRow>
              <Grid.Column mobile={16} computer={8}>
                <Field
                  name={formFields.description}
                  component={TextField}
                  type="text"
                  fluid
                  label="Description"
                  placeholder="Description"
                />
              </Grid.Column>
              <StyledGridColumn mobile={16} computer={8}>
                <StyledModeLabel>Offline Device protection</StyledModeLabel>
                <TipIcon
                  floated="right"
                  message={MODE_HELP_TEXT}
                  position="bottom center"
                  editMode={false}
                />
                <Field
                  name={formFields.mode}
                  component={DropdownField}
                  options={modeOptions}
                  fluid
                  selection
                />
              </StyledGridColumn>
            </StyledGridRow>
            <Grid.Row>
              <Grid.Column mobile={16} computer={8}>
                <Field
                  name={formFields.enabled}
                  component={ToggleField}
                  type="checkbox"
                  label="Enabled"
                  hideRadioLabel
                  alignItems="center"
                />
              </Grid.Column>
              <Grid.Column mobile={16} computer={8}>
                <Field
                  name={formFields.hidden}
                  component={ToggleField}
                  type="checkbox"
                  label="Hide occurrences"
                  hideRadioLabel
                  alignItems="center"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </StyledForm>
      </StyledContent>
    </>
  );
}

export default IdentificationReview;
