import memoize from 'lodash/memoize';

import PermissionsTreeModel from 'models/PermissionsTreeModel';
import resourcePermissionsSelector from './resourcePermissionsSelector';

const resourcePermissionsTreeSelector = (state, { resourceType }) => {
  const resourcePermissions = resourcePermissionsSelector(state)(resourceType);

  return memoize(
    (scopes, limitedFor) => new PermissionsTreeModel(resourcePermissions, scopes, limitedFor),
  );
};

export default resourcePermissionsTreeSelector;
