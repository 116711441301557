import React from 'react';
import PropTypes from 'prop-types';

import { USERS_FIELD } from 'helpers/selectors';
import DataTable from 'components/DataTable/DataTableContainer';
import InviteUser from 'components/InviteUser/InviteUserContainer';
import { UserTableRow } from 'application/tenant/console/components';
import UserActionLinks from './UserActionLinks/UserActionLinks';

const usersTableLabels = [
  { text: 'First name', field: 'firstName' },
  { text: 'Last name', field: 'lastName' },
  { text: 'Email', field: 'email' },
  { text: 'Status', field: 'statusIcon', sortable: false },
];

const ProjectUsersTab = ({ projectId }) => (
  <DataTable
    labels={usersTableLabels}
    actionLinks={UserActionLinks}
    createElement={InviteUser}
    customTableRow={UserTableRow}
    projectId={projectId}
    pagination={{
      type: USERS_FIELD,
      args: {
        withFilter: true,
      },
    }}
  />
);

ProjectUsersTab.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default ProjectUsersTab;
