import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'semantic-ui-react';

import { capitalizeFirstLetter } from 'helpers/sharedMethods';

const RenderLabels = ({ labels }) => (
  <Label.Group>
    {labels.map((item, index) => {
      if (item) {
        const key = Object.keys(labels[index])[0];

        return (
          <Label
            htmlFor={`${key}-${index}`}
            key={`${key}`}
            as="a"
          >
            {capitalizeFirstLetter(key)}:
            <Label.Detail>{labels[index][key]}</Label.Detail>
          </Label>
        );
      }

      return null;
    })}
  </Label.Group>
);

RenderLabels.propTypes = {
  labels: PropTypes.array.isRequired,
};

export default RenderLabels;
