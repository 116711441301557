import { compose } from 'redux';
import { connect } from 'react-redux';
import includes from 'lodash/includes';

import {
  PROJECT_SUITES_FIELD,
  permissionsProjectSuiteActiveUserIdsSelector,
} from 'helpers/selectors';
import { permissionsActionCreators } from '../../../../../../../utilities';
import UserTableRow from './UserTableRow';

export const mapStateToProps = (state, { item: { id: userId }, resourceId }) => {
  const userIds = permissionsProjectSuiteActiveUserIdsSelector(
    state, { projectSuiteId: resourceId },
  );
  const highlighted = includes(userIds, userId);

  return {
    highlighted,
  };
};

export const mapDispatchToProps = {
  onAddUserId: (resourceId, userId) => permissionsActionCreators.resourceAddUserId(
    PROJECT_SUITES_FIELD,
    resourceId,
    userId,
  ),
  onRemoveUserId: (resourceId, userId) => permissionsActionCreators.resourceRemoveUserId(
    PROJECT_SUITES_FIELD,
    resourceId,
    userId,
  ),
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(UserTableRow);
