import isMapDataLoadingSelector from './isMapDataLoadingSelector';
import projectsForMapSelector from './projectsForMapSelector';
import projectsWithLocationSelector from './projectsWithLocationSelector';
import projectsWithoutLocationSelector from './projectsWithoutLocationSelector';
import childProjectsWithLocationSelector from './childProjectsWithLocationSelector';
import childProjectsWithoutLocationSelector from './childProjectsWithoutLocationSelector';

export default {
  isMapDataLoadingSelector,
  projectsForMapSelector,
  projectsWithLocationSelector,
  projectsWithoutLocationSelector,
  childProjectsWithLocationSelector,
  childProjectsWithoutLocationSelector,
};
