import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Responsive } from 'semantic-ui-react';

import { sizes } from 'themes/mixins';
import { Panel } from 'components/core';

import { MobileView, DesktopView } from './components';

export default function ScheduleStep({
  schedulerType,
  existingSchedule,
  resourceType,
  onSchedulerTypeSelect,
  onClearSchedulerParams,
}) {

  const commonProps = {
    schedulerType,
    existingSchedule,
    resourceType,
    onSchedulerTypeSelect,
    onClearSchedulerParams,
  };

  return (
    <Panel
      title="Schedule configuration"
      description="Set the date, time and repeat for the Scheduler."
    >
      <Responsive maxWidth={sizes.md - 1} as={Fragment}>
        <MobileView {...commonProps} />
      </Responsive>
      <Responsive minWidth={sizes.md} as={Fragment}>
        <DesktopView {...commonProps} />
      </Responsive>
    </Panel>
  );
}

ScheduleStep.defaultProps = {
  existingSchedule: null,
  resourceType: 'project',
};

ScheduleStep.propTypes = {
  schedulerType: PropTypes.string.isRequired,
  existingSchedule: PropTypes.object,
  resourceType: PropTypes.oneOf([
    'project',
    'projectSuite',
  ]),
  onSchedulerTypeSelect: PropTypes.func.isRequired,
  onClearSchedulerParams: PropTypes.func.isRequired,
};
