import constants from 'dispatcherConst';

const addResourceId = (resourceId, resourceType) => ({
  type: constants.ADD_RESOURCE_ID_PERMISSIONS_TABLE_REQUEST,
  payload: {
    resourceId,
    resourceType,
  },
});

export default addResourceId;
