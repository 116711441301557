import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { withProjectPermissions } from 'gateway';
import { TENANT } from 'storageConst';
import {
  TASKS_FIELD,
  LOGICAL_DEVICES_FIELD,
  PROJECTS_FIELD,
  singleProjectSelector,
} from 'helpers/selectors';
import { addPagination } from 'helpers/paginationHelpers';
import withPolling from 'helpers/withPollingSaga/withPollingSagaContainer';
import projectActionCreators from 'pages/Projects/projectActionCreators';
import paginationActionCreators from 'data/paginationActionCreators';
import permissionsActionCreators from 'application/tenant/console/utilities/permissions/permissionsActionCreators';
import { actionCreators } from 'pages/ProjectSuites/utilities';

import { tasksActionCreators } from '../../../../../utilities';
import SiteSchedulingTab from './SiteSchedulingTab';

const REFRESH_RATE = 60000;

export const mapStateToProps = (state, { match }) => {
  const { id: projectId } = match.params;
  const { locationSet, locationLoaded } = singleProjectSelector(state, projectId);

  return {
    projectId,
    locationSet,
    locationLoaded,
  };
};

export default compose(
  withRouter,
  withProjectPermissions,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    ({
      currentUserId,
      projectId,
    }) => permissionsActionCreators.fetchUserPermissions(
      currentUserId,
      PROJECTS_FIELD,
      projectId,
      TENANT,
    ),
  ),
  chainedLoaderWithSaga(tasksActionCreators.fetchActionTypes),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: TASKS_FIELD, included: true },
      ({ projectId }) => tasksActionCreators.fetchTasks(projectId, {
        sort: 'name',
      }),
    ),
  ),
  withPolling(REFRESH_RATE),
  chainedLoaderWithSaga(
    ({ projectId }) => projectActionCreators.fetchProject(projectId, {
      include: [
        LOGICAL_DEVICES_FIELD,
      ],
      fields: {
        [LOGICAL_DEVICES_FIELD]: [
          'name',
        ],
      },
    }),
    addPagination(
      {
        resourceType: LOGICAL_DEVICES_FIELD,
      },
      paginationActionCreators.fetchPageResource({ sort: 'name' }),
    ),
  ),
  chainedLoaderWithSaga(
    () => actionCreators.fetchAdminProjectSuites(
      {
        fields: { projectSuites: ['name'] },
        sort: 'name',
      },
    ),
  ),
)(SiteSchedulingTab);
