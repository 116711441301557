import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import onMountUnmount from 'helpers/onMountUnmount';
import websocketActionCreators from 'data/websockets/websocketsActionCreators';
import { deviceSelector, IO_MODULES_INSTANCES_FIELD } from 'helpers/selectors';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { addPagination } from 'helpers/paginationHelpers';
import deviceActionCreators from 'pages/Project/deviceActionCreators';
import PharosIOModulesV1 from './PharosIOModulesV1';

export const mapStateToProps = (state, { match }) => {
  const { deviceId } = match.params;
  const resource = deviceSelector(state)(deviceId);

  return {
    deviceId,
    resource,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    ({ deviceId }) => deviceActionCreators.fetchIoModules(deviceId, {
      sort: 'label',
    }),
    addPagination(
      { resourceType: IO_MODULES_INSTANCES_FIELD, included: true },
      ({ deviceId }) => deviceActionCreators.fetchIoModulesInstances(deviceId, {
        sort: 'title',
      }),
    ),
  ),
  onMountUnmount(
    ({ deviceId }) => websocketActionCreators.subscribeDeviceData(deviceId, 'io_instances'),
    ({ deviceId }) => websocketActionCreators.unsubscribeDeviceData(deviceId, 'io_instances'),
  ),
)(PharosIOModulesV1);
