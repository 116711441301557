import isEmpty from 'lodash/isEmpty';

function formatDigit(value) {
  return (`0${value}`).slice(-2);
}

const combineTimes = (primary, secondary, isSeconds = false) => {
  if (isEmpty(primary) || isEmpty(secondary)) {
    return [];
  }

  const res = [];

  primary.forEach(item => {
    secondary.forEach(innerItem => {
      const formatItem = isSeconds ? item : formatDigit(item);
      const formatInnerItem = formatDigit(innerItem);
      res.push(`${formatItem}:${formatInnerItem}`);
    });
  });

  return res;
};

export default combineTimes;
