import React from 'react';
import PropTypes from 'prop-types';

const customStyles = {
  height: '22px',
  width: '22px',
  borderRadius: '16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '0px 2px 6px #FFF',
  outline: 'none',
  border: 'solid 2px black',
};

const RenderThumb = ({ props, calculatedColor }) => {
  const { style } = props;

  return (
    <div
      {...props}
      style={{
        ...style,
        ...customStyles,
        backgroundColor: calculatedColor,
      }}
      role="button"
    />
  );
};

RenderThumb.defaultProps = {
  style: {},
  calculatedColor: 'rgb(255,255,255)',
};

RenderThumb.propTypes = {
  props: PropTypes.object.isRequired,
  style: PropTypes.object,
  calculatedColor: PropTypes.string,
};

export default RenderThumb;
