import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { DEVICE_FIRMWARE_FILES_FIELD } from 'helpers/selectors';

export const uploadFirmwareFile = params => ({
  type: constants.UPLOAD_FIRMWARE_FILE_REQUEST,
  payload: {
    params,
    endpoint: endpoints.DEVICE_FIRMWARE_FILES,
  },
});

export const uploadFirmwareFileSuccess = params => ({
  type: constants.UPLOAD_FIRMWARE_FILE_SUCCESS,
  payload: {
    params,
    resourceType: DEVICE_FIRMWARE_FILES_FIELD,
  },
});

export const uploadFirmwareFileAfterDevice = params => ({
  type: constants.UPLOAD_FIRMWARE_FILE_AFTER_DEVICE,
  payload: {
    params,
    resourceType: DEVICE_FIRMWARE_FILES_FIELD,
  },
});

export const uploadFirmwareFileFailure = error => ({
  type: constants.UPLOAD_FIRMWARE_FILE_FAILED,
  payload: { error },
});

export default uploadFirmwareFile;
