import { connect } from 'react-redux';

import MinutesInput from './MinutesInput';
import automatedOperationsActionCreators from '../../../../../../utilities/actionCreators';

const mapDispatchToProps = {
  invalidAstroInput: automatedOperationsActionCreators.invalidInputNotification,
};

export default connect(null, mapDispatchToProps)(MinutesInput);
