import { connect } from 'react-redux';

import InviteUser from './InviteUser';
import inviteUserActionCreators from './inviteUserActionCreators';

const mapDispatchToProps = {
  onInviteUser: inviteUserActionCreators.inviteUser,
};

const InviteUserContainer = connect(null, mapDispatchToProps)(InviteUser);
export default InviteUserContainer;
