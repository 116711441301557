import { capitalizeFirstLetter } from 'helpers/sharedMethods';

const getAstroLabelText = astroTime => {
  if (!astroTime) {
    return '';
  }

  const signal = astroTime.value > 0 ? '+' : '';
  const text = astroTime.value === 0 ? capitalizeFirstLetter(astroTime.type) : astroTime.value;
  const prefix = astroTime.value === 0 ? '' : capitalizeFirstLetter(astroTime.type);
  const suffix = astroTime.value === 0 ? '' : 'mins';

  return `${prefix} ${astroTime.value !== 0 ? signal : ''} ${text} ${suffix}`.replace(/\s+/g, ' ').trim();
};

export default getAstroLabelText;
