import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { labelsType, sortType } from 'data/types';
import { SORT_ASCENDING, SORT_DESCENDING } from 'helpers/paginationHelpers';
import { DESCENDING_PREFIX } from 'helpers/UrlParams';
import { Container, StyledLabel, StyledSelect } from './DataTableSorting.style';

class DataTableSorting extends PureComponent {

  static createSortOption({ field, order, prefix, sortBy = field, text }) {
    return {
      key: `${field}${order}`,
      text: `${text}: ${order}`,
      value: `${prefix}${sortBy}`,
    };
  }

  constructor() {
    super();

    this.handleSort = this.handleSort.bind(this);
  }

  getSortOptions() {
    const { labels } = this.props;
    const sortableLabels = labels.filter(({ sortable }) => sortable !== false);

    return sortableLabels.reduce((acc, label) => {
      acc.push(DataTableSorting.createSortOption({
        ...label,
        order: SORT_ASCENDING,
        prefix: '',
      }));
      acc.push(DataTableSorting.createSortOption({
        ...label,
        order: SORT_DESCENDING,
        prefix: DESCENDING_PREFIX,
      }));

      return acc;
    }, []);
  }

  getValue() {
    const { sort } = this.props;
    if (isEmpty(sort)) return null;

    const { field, prefix } = sort;

    return `${prefix}${field}`;
  }

  handleSort(e, { value }) {
    const { onSort } = this.props;

    onSort(value);
  }

  render() {
    const sortOptions = this.getSortOptions();
    const value = this.getValue();

    return (
      (value && !!sortOptions.length) && (
        <Container>
          <StyledLabel>Sort by:</StyledLabel>
          <StyledSelect
            onChange={this.handleSort}
            selectOnBlur={false}
            selectOnNavigation={false}
            options={sortOptions}
            value={value}
          />
        </Container>
      )
    );
  }

}

DataTableSorting.defaultProps = {
  sort: {},
};

DataTableSorting.propTypes = {
  // eslint-disable-next-line react/no-typos
  labels: labelsType.isRequired,
  sort: sortType,
  onSort: PropTypes.func.isRequired,
};

export default DataTableSorting;
