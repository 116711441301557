import combineTimes from './combineTimes';
import renderTimes from './renderTimes';
import getTimeIntervals from './getTimeIntervals';
import getSelectedValues from './getSelectedValues';
import getSelectedConfig from './getSelectedConfig';
import usePopup from './usePopup';
import useTabs from './useTabs';
import getAstroLabelText from './getAstroLabelText';

const TIME_PICKER_TYPES = {
  SIMPLE: 'SIMPLE',
  TIME_MASK: 'TIME_MASK',
  RECURRING: 'RECURRING',
  ASTRONOMICAL: 'ASTRONOMICAL',
};

const TIME_PICKER_TYPES_INDEX = {
  0: TIME_PICKER_TYPES.SIMPLE,
  1: TIME_PICKER_TYPES.TIME_MASK,
  2: TIME_PICKER_TYPES.RECURRING,
  3: TIME_PICKER_TYPES.ASTRONOMICAL,
};

export {
  combineTimes,
  renderTimes,
  getTimeIntervals,
  getSelectedValues,
  getSelectedConfig,
  usePopup,
  useTabs,
  getAstroLabelText,
  TIME_PICKER_TYPES,
  TIME_PICKER_TYPES_INDEX,
};
