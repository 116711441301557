import React from 'react';

import TriggersSection from 'components/TriggersSection/TriggersSectionContainer';

const labels = [
  { text: 'Number', field: 'id', width: 2 },
  { text: 'Name', field: 'name', width: 4 },
  { text: 'Description', field: 'description', width: 8 },
  { text: '', field: '', width: 2 },
];

const PharosTriggersV1 = props => <TriggersSection {...props} labels={labels} />;

export default PharosTriggersV1;
