import React from 'react';
import PropTypes from 'prop-types';
import { change } from 'redux-form';

import formNames from 'components/form/formNames';
import ThemedToggle from 'components/elements/ThemedToggle/ThemedToggle.style';
import { PrioritySelection } from 'components/core';

import priorityOptions from './constants';
import { Container, DateBlockingContainer, StyledLabel } from './PrioritySelect.style';

function PrioritySelect({
  priority,
  priorityType,
  dispatch,
}) {
  const handlePriorityChanged = value => {
    dispatch(change(formNames.taskScheduler, 'priority', value));
  };

  const handleDateBlockingChange = (event, { checked }) => {
    dispatch(change(formNames.taskScheduler, 'priorityType', checked ? 'dates' : 'times'));
  };

  return (
    <Container>
      <PrioritySelection
        defaultValue={priority}
        options={priorityOptions}
        onChange={handlePriorityChanged}
      />
      <DateBlockingContainer>
        <StyledLabel>Date blocking</StyledLabel>
        <ThemedToggle
          className="date-blocking-toggle"
          checked={priorityType === 'dates'}
          onChange={handleDateBlockingChange}
        />
      </DateBlockingContainer>
    </Container>
  );
}

PrioritySelect.defaultProps = {
  priority: 2,
};

PrioritySelect.propTypes = {
  priority: PropTypes.number,
  priorityType: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default PrioritySelect;
