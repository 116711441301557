import { put } from 'redux-saga/effects';

import socketConstants from 'data/websockets/constants';
import actionCreators from '../actionCreators';

function* addNotificationEventFromWebsocket(action) {
  const { identifier, message } = action.payload;

  if (identifier && message && identifier.channel === socketConstants.NOTIFICATIONS_CHANNEL) {
    yield put(actionCreators.addNotificationEventFromWebsocket(message));
  }
}

export default addNotificationEventFromWebsocket;
