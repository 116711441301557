import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  &.ui.labeled.icon.button {
    position: absolute; 
    right: 5px;
  }
`;

export default StyledButton;
