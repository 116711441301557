import { call } from 'redux-saga/effects';

import { messages } from 'data/notifications/notificationsConst';
import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import { changeApiDomainSuccess, changeApiDomainFailure } from '../actionCreators/changeApiDomain';

function* checkApiDomain(action) {
  const {
    endpoint,
    params: {
      values: { domain, domainSelect },
      resolveForm,
      rejectForm,
    },
  } = action.payload;
  const apiDomain = domain || domainSelect;

  const {
    response: apiResponse,
    error: apiError,
  } = yield call(getPath, endpoint, { apiUrlOverride: apiDomain });

  const response = apiResponse && apiResponse.data && apiDomain;
  const error = apiError || !response
    ? new Error(messages.ERROR_API_DOMAIN)
    : undefined;

  const params = {
    response,
    error,
    resolveForm,
    rejectForm,
    successDisp: changeApiDomainSuccess,
    failDisp: changeApiDomainFailure,
  };

  yield call(processCall, params);
}

export default checkApiDomain;
