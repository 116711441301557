import { call, put } from 'redux-saga/effects';

import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import * as actionCreators from '../actionCreators';

function* fetchSiteDevices(action) {
  const { endpoint, urlParams, projectId, ...rest } = action.payload;
  const { response, error } = yield call(getPath, endpoint, { urlParams });
  const responseWithProjectId = response && { ...response, projectId };

  const params = {
    response: responseWithProjectId,
    error,
    successDisp: actionCreators.fetchSiteDevicesSuccess,
    failDisp: actionCreators.fetchSiteDevicesFailure,
    ...rest,
  };

  yield put(actionCreators.fetchAndSaveLogicalDevicesSuccess(response));

  yield call(processCall, params);
}

export default fetchSiteDevices;
