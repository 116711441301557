import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import Form from 'components/form/Form/Form';
import { Button, PortalThemedLinkButton } from 'components/atomic';
import { required } from 'components/form/formValidators';
import { ResetPasswordEmailField } from './components';

const passwordResetFormFields = {
  email: 'email',
};

const PasswordResetForm = ({ handleSubmit, sending, confirmed, open, onOpen, onClose }) => {
  const trigger = (
    <PortalThemedLinkButton onClick={onOpen} type="button">
      Forgotten your password?
    </PortalThemedLinkButton>
  );

  const content = () => {
    if (confirmed) {
      return 'An email has been sent to the address you provided. Please follow the steps provided to complete your password reset.';
    }

    return (
      <Fragment>
        <p>Please enter the email you use to log in:</p>
        <Form>
          <Form.Group>
            <ResetPasswordEmailField
              name={passwordResetFormFields.email}
              validate={required}
            />
          </Form.Group>
        </Form>
      </Fragment>
    );
  };
  const actions = () => {
    if (confirmed) {
      return <Button primary onClick={onClose}>Ok</Button>;
    }

    return (
      <Fragment>
        <Button primary basic disabled={sending} onClick={onClose}>Cancel</Button>
        <Button primary disabled={sending} onClick={handleSubmit}>Reset Password</Button>
      </Fragment>
    );
  };

  return (
    <Modal
      trigger={trigger}
      open={open}
      onClose={onClose}
      size="small"
    >
      <Modal.Header>Request Password Reset</Modal.Header>
      <Modal.Content>{content()}</Modal.Content>
      <Modal.Actions>{actions()}</Modal.Actions>
    </Modal>
  );
};

PasswordResetForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  sending: PropTypes.bool.isRequired,
  confirmed: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PasswordResetForm;
