import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AddProjectModalForm from './AddProjectModalForm/AddProjectModalFormContainer';

class AddProject extends Component {

  constructor() {
    super();

    this.addProject = this.addProject.bind(this);
  }

  addProject(values) {
    const { onAddProject } = this.props;

    return new Promise((resolveForm, rejectForm) => {
      onAddProject({
        values,
        resolveForm,
        rejectForm,
      });
    });
  }

  render() {
    return (
      <AddProjectModalForm onSubmit={this.addProject} />
    );
  }

}

AddProject.propTypes = {
  onAddProject: PropTypes.func.isRequired,
};

export default AddProject;
