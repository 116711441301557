import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  PROJECTS_FIELD,
  LOGICAL_DEVICES_FIELD,
  USERS_FIELD,
  permissionsSelectingProjectsSelector,
} from 'helpers/selectors';
import { addPagination } from 'helpers/paginationHelpers';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import projectActionCreators from 'pages/Projects/projectActionCreators';
import ProjectsTable from './ProjectsTable';

export const mapStateToProps = state => ({
  selectingProjects: permissionsSelectingProjectsSelector(state),
});

export default compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: PROJECTS_FIELD },
      () => projectActionCreators.fetchAdminProjects(
        {
          fields: {
            [PROJECTS_FIELD]: ['name', USERS_FIELD, LOGICAL_DEVICES_FIELD],
          },
          sort: 'name',
        },
      ),
    ),
  ),
)(ProjectsTable);
