import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ControlUI, ControlUIProjectSuite } from 'application/tenant/components';
import { StyledControlPanelContainer } from './ControlPanel.style';

class ControlPanel extends Component {

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { onEnterControlPanelContext } = this.props;

    onEnterControlPanelContext();
  }

  componentWillUnmount() {
    const { onExitControlPanelContext } = this.props;

    onExitControlPanelContext();
  }

  render() {
    const { isMultiSite, projectsIds } = this.props;

    if (isMultiSite) {
      return (
        <StyledControlPanelContainer>
          {projectsIds && <ControlUIProjectSuite projectsIds={projectsIds} />}
        </StyledControlPanelContainer>
      );
    }

    return (
      <StyledControlPanelContainer>
        <ControlUI />
      </StyledControlPanelContainer>
    );
  }

}

ControlPanel.defaultProps = {
  isMultiSite: false,
  projectsIds: null,
};

ControlPanel.propTypes = {
  onEnterControlPanelContext: PropTypes.func.isRequired,
  onExitControlPanelContext: PropTypes.func.isRequired,
  isMultiSite: PropTypes.bool,
  projectsIds: PropTypes.array,
};

export default ControlPanel;
