import React, { useMemo, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  LOGICAL_DEVICES_FIELD,
  projectLogicalDevicesSelector,
  projectSelector,
  currentUserIdSelector,
} from 'helpers/selectors';
import { actionWithResponseDataIdSelector } from 'helpers/sagaHelper';
import constants from 'dispatcherConst';
import projectActionCreators from 'pages/Projects/projectActionCreators';

import Notifications from './NotificationsContainer';
import { context } from './notificationConsts';

export const config = (projectId, userId) => ({
  context: [context.PROJECT, context.LOGICAL_DEVICE],
  selectors: {
    [context.PROJECT]: state => [projectSelector(state)(projectId)],
    [context.LOGICAL_DEVICE]: state => (
      projectLogicalDevicesSelector(projectSelector(state)(projectId))),
  },
  filter: { userId },
  fetchResources: [
    projectActionCreators.fetchProject(projectId, {
      include: [
        LOGICAL_DEVICES_FIELD,
      ],
      fields: {
        [LOGICAL_DEVICES_FIELD]: [
          'name',
        ],
      },
    }),
  ],
  fetchResourcesSuccess: [
    actionWithResponseDataIdSelector(constants.FETCH_PROJECT_SUCCESS, projectId),
  ],
});

export const ProjectNotificationsContainer = ({
  projectId,
  currentUserId,
  ...restProps
}) => {
  const [selectedUserId, setSelectedUserId] = useState(null);

  const finalUserId = useMemo(() => {
    if (!selectedUserId) {
      return currentUserId;
    }

    return selectedUserId;
  }, [selectedUserId, currentUserId]);

  const finalConfig = useMemo(
    () => config(projectId, finalUserId),
    [projectId, finalUserId],
  );

  const handleSelectedUserChange = useCallback(newUserId => {
    setSelectedUserId(previousUserId => (previousUserId === newUserId ? null : newUserId));
  }, []);

  return (
    <Notifications
      userId={finalUserId}
      config={finalConfig}
      showWarning={selectedUserId !== null}
      onSelectedUserChange={handleSelectedUserChange}
      {...restProps}
    />
  );
};

ProjectNotificationsContainer.propTypes = {
  projectId: PropTypes.string.isRequired,
  currentUserId: PropTypes.string.isRequired,
};

export const mapStateToProps = state => ({
  currentUserId: currentUserIdSelector(state),
});

export default connect(mapStateToProps)(ProjectNotificationsContainer);
