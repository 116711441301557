import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';
import memoize from 'lodash/memoize';

import { UPLOADS } from 'storageConst';
import { EMPTY_JSON } from 'components/UploadsWidget/uploadsStorageSaga';
import UploadModel from 'models/UploadModel';

export const uploadsSelector = state => state.uploads;

export const uploadsFilesSelector = createSelector(
  uploadsSelector,
  uploads => uploads.files,
);
export const uploadsQueueSelector = createSelector(
  uploadsSelector,
  uploads => uploads.queue,
);
export const uploadsStatusSelector = createSelector(
  uploadsSelector,
  uploads => uploads.status,
);
export const uploadsWorkersSelector = createSelector(
  uploadsSelector,
  uploads => uploads.workers,
);

export const uploadsLimitSelector = createSelector(
  uploadsStatusSelector,
  status => status.limit,
);
export const uploadsNotFoundIdSelector = createSelector(
  uploadsStatusSelector,
  status => status.notFound,
);
export const uploadsRunningIdSelector = createSelector(
  uploadsStatusSelector,
  status => status.running,
);
export const uploadsSuspendedSelector = createSelector(
  uploadsStatusSelector,
  status => status.suspended,
);

export const uploadsFirstQueuedSelector = createSelector(
  uploadsFilesSelector,
  uploadsQueueSelector,
  (files, queue) => files[queue[0]],
);
export const uploadsNotFoundSelector = createSelector(
  uploadsFilesSelector,
  uploadsNotFoundIdSelector,
  (files, id) => {
    const storedUploadsStr = localStorage.getItem(UPLOADS);
    const storedUploads = JSON.parse(storedUploadsStr || EMPTY_JSON);

    const { systemFile } = storedUploads[id] || {};
    if (!systemFile) return null;

    const upload = files[id];

    return new UploadModel({ ...upload, systemFile });
  },
);
export const uploadsRunningSelector = createSelector(
  uploadsFilesSelector,
  uploadsRunningIdSelector,
  (files, id) => {
    const upload = files[id];
    if (!upload) return null;

    return new UploadModel(upload);
  },
);

export const uploadsFilesByProjectSelector = createSelector(
  uploadsFilesSelector,
  uploadsFiles => {
    const sorted = sortBy(uploadsFiles, 'timestamp');

    return sorted.reduce((acc, uploadFile) => {
      const { project, projectId } = uploadFile;
      const { files = [] } = acc[projectId] || {};

      acc[projectId] = { project, files: [...files, uploadFile] };

      return acc;
    }, {});
  },
);
export const uploadsFilesGroupedSelector = createSelector(
  uploadsFilesByProjectSelector,
  uploadsSuspendedSelector,
  (filesByProject, suspended) => Object.keys(filesByProject).map(projectId => {
    const { files, project } = filesByProject[projectId];

    return ({
      project,
      projectId,
      files: files.map(file => new UploadModel(file, suspended)),
    });
  }),
);
export const uploadsFileSelector = createSelector(
  uploadsFilesSelector,
  files => memoize(
    fileKey => files[fileKey],
  ),
);
export const uploadsWorkerSelector = createSelector(
  uploadsWorkersSelector,
  workers => memoize(
    fileKey => workers[fileKey],
  ),
);

export const uploadsProjectStatsSelector = createSelector(
  uploadsFilesByProjectSelector,
  filesByProject => memoize(
    projectId => {
      let uploadingCount = 0;
      let completedCount = 0;
      let pausedCount = 0;

      const { files } = filesByProject[projectId];
      files.forEach(({ completed, paused }) => {
        if (completed) {
          completedCount += 1;
        } else if (paused) {
          pausedCount += 1;
        } else {
          uploadingCount += 1;
        }
      });

      return {
        uploadingCount,
        completedCount,
        pausedCount,
      };
    },
  ),
);
export const uploadsStatsSelector = createSelector(
  uploadsFilesSelector,
  files => {
    let uploadingCount = 0;
    let completedCount = 0;
    let pausedCount = 0;
    let totalUploadedCount = 0;
    let sizeCount = 0;

    Object.values(files).forEach(({ completed, paused, systemFile, totalUploaded }) => {
      if (completed) {
        completedCount += 1;
      } else if (paused) {
        pausedCount += 1;
      } else {
        uploadingCount += 1;
      }

      totalUploadedCount += totalUploaded;
      sizeCount += systemFile.size;
    });

    const progressCount = (totalUploadedCount / Math.max(1, sizeCount)) * 100;

    return {
      completedCount,
      pausedCount,
      progressCount,
      uploadingCount,
    };
  },
);
export const uploadsStatusFlagsSelector = createSelector(
  uploadsFilesSelector,
  uploadsSuspendedSelector,
  (files, suspended) => {
    const filesArray = Object.values(files);

    const completedAll = filesArray.every(({ completed }) => completed);
    const fetchingAll = filesArray.some(({ fetching }) => fetching);
    const initializedAll = filesArray.some(({ initialized }) => initialized);
    const pausedAll = filesArray.every(({ paused }) => paused);

    return {
      completed: completedAll,
      fetching: fetchingAll,
      initialized: initializedAll,
      paused: pausedAll,
      suspended,
    };
  },
);
