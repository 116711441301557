import { takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import fetchDeviceScenes from './fetchDeviceScenes';

function* saga() {
  yield takeLatest(constants.FETCH_DEVICE_SCENES_REQUEST, fetchDeviceScenes);
}

export {
  saga,
};

export default saga;
