import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';

const signUpActionCreators = {
  invitationTokenVerification: (invitationToken, requester) => ({
    type: constants.INVITATION_TOKEN_VERIF,
    payload: {
      invitationToken,
      requester,
      endpoint: endpoints.SIGN_UP_VERIFY_TOKEN,
    },
  }),

  invitationTokenVerificationSuccess: response => ({
    type: constants.INVITATION_TOKEN_VERIF_SUCCESS,
    payload: { response },
  }),

  invitationTokenVerificationFailure: (error, requester) => ({
    type: constants.INVITATION_TOKEN_VERIF_FAILED,
    payload: { error, requester },
  }),

  signUpRequest: (params, invitationToken) => ({
    type: constants.SIGN_UP_REQUEST,
    payload: {
      params,
      invitationToken,
      endpoint: endpoints.SIGN_UP,
    },
  }),

  signUpRequestSuccess: response => ({
    type: constants.SIGN_UP_REQUEST_SUCCESS,
    payload: {
      response,
      message: messages.NEW_PASSWORD,
    },
  }),

  signUpRedirection: webRedirection => ({
    type: constants.SIGN_UP_REDIRECTION,
    payload: { webRedirection },
  }),
};

export default signUpActionCreators;
