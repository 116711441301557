const formatTitle = title => {
  if (!title) {
    return null;
  }

  if (!title.includes('_')) {
    return title;
  }

  return title.split('_').filter(word => !!word).join(' ');
};

export default formatTitle;
