import { call } from 'redux-saga/effects';

import { getPath } from 'data/api/requests';
import { processFinalFormResponse } from 'utilities/form';
import { TOKEN } from 'storageConst';
import actionCreators from '../actionCreators';

function* verifyPasswordResetToken(action) {
  const { endpoint, passwordResetToken, resolveForm } = action.payload;

  localStorage.setItem(TOKEN, passwordResetToken);

  const { response, error } = yield call(getPath, endpoint);
  if (error instanceof Error) { error.message = 'No connection to server. Try later.'; }

  const params = {
    response,
    error,
    resolveForm,
    successDisp: actionCreators.verifyPasswordResetTokenSuccess,
  };
  yield call(processFinalFormResponse, params);
}

export default verifyPasswordResetToken;
