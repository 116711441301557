import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

import { StyledInput, StyledValue } from './InlineEditField.style';

class InlineEditField extends Component {

  constructor() {
    super();

    this.handleFocus = this.handleFocus.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleFocus(e) {
    const { onFocusCallback, preventPropagation } = this.props;
    onFocusCallback(this.editInput);

    if (preventPropagation) {
      e.stopPropagation();
    }
  }

  handleClick(e) {
    const { preventPropagation } = this.props;

    if (preventPropagation) {
      e.stopPropagation();
    }
  }

  render() {
    const {
      input,
      meta: { submitting },
      readOnly,
      readOnlyValue,
      error,
      onFocusCallback,
      locked,
      preventPropagation,
      ...rest
    } = this.props;

    return (
      <div>
        <StyledInput
          ref={editInput => { this.editInput = editInput; }}
          {...input}
          {...rest}
          id={input.name}
          error={error}
          loading={submitting}
          iconPosition="left"
          readOnly={readOnly}
          onClick={this.handleClick}
        />
        <StyledValue
          readOnly={readOnly}
          empty={!readOnlyValue}
          onClick={this.handleFocus}
          locked={locked}
        >
          {readOnlyValue || 'empty'}
        </StyledValue>
      </div>
    );
  }

}

InlineEditField.defaultProps = {
  error: null,
  readOnlyValue: null,
  preventPropagation: false,
};

InlineEditField.propTypes = {
  ...Input.propTypes,
  error: PropTypes.bool,
  readOnly: PropTypes.bool.isRequired,
  readOnlyValue: PropTypes.any,
  onFocusCallback: PropTypes.func.isRequired,
  preventPropagation: PropTypes.bool,
};

export default InlineEditField;
