import styled from 'styled-components';

export const StyledTitle = styled.h5`
  display: inline-block;
  margin-bottom: 5px;
  cursor: pointer;
  cursor: ${({ isProjectSuite }) => isProjectSuite && 'auto;'}
`;

export const StyledP = styled.p`
  margin: 0;
  padding: 0; 
  font-size: 10px;
  margin-bottom: 5px;
`;

export default StyledTitle;
