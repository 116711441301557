import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';

import { StyledDateWrapper, StyledPlaceholder } from './InlineEditDate.style';
import { EditDate, UTCPopup } from './components';

const InlineEditDate = props => {
  const { siteId, onUpdateSiteDate, property, propertyValue, format, minDate } = props;

  const handleUpdateSiteDate = value => new Promise((resolveForm, rejectForm) => {
    const formattedDate = DateTime.fromJSDate(value).toFormat('y-MM-dd');

    onUpdateSiteDate({
      values: { id: siteId, [property]: formattedDate },
      resolveForm,
      rejectForm,
    });
  });

  const hideCalendar = isNull(propertyValue) || isUndefined(propertyValue);
  const dateValue = DateTime.fromISO(propertyValue).toUTC().toFormat(format);
  const form = `editSiteProperty.${property}`;

  if (hideCalendar) {
    return <StyledPlaceholder>Not set</StyledPlaceholder>;
  }

  return (
    <StyledDateWrapper>
      {dateValue}
      <UTCPopup />
      <EditDate
        name={property}
        form={form}
        onSubmit={handleUpdateSiteDate}
        initialValues={{
          [property]: DateTime.fromISO(propertyValue).toUTC().toFormat('y-MM-dd'),
        }}
        minDate={minDate}
      />
    </StyledDateWrapper>
  );
};

InlineEditDate.defaultProps = {
  propertyValue: undefined,
  format: 'dd/MM/y',
  minDate: undefined,
};

InlineEditDate.propTypes = {
  siteId: PropTypes.string.isRequired,
  onUpdateSiteDate: PropTypes.func.isRequired,
  property: PropTypes.string.isRequired,
  propertyValue: PropTypes.string,
  format: PropTypes.string,
  minDate: PropTypes.string,
};

export default InlineEditDate;
