import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import { SNAPSHOTS_FIELD } from 'helpers/selectors';

const SnapshotsSectionActionCreators = {
  fetchSnapshots: (deviceId, urlParams = {}) => ({
    type: constants.FETCH_SNAPSHOTS_REQUEST,
    payload: {
      deviceId,
      endpoint: endpoints.SNAPSHOTS(deviceId),
      urlParams,
    },
  }),

  fetchSnapshotsFailure: error => ({
    type: constants.FETCH_SNAPSHOTS_FAILED,
    payload: {
      info: !error.unauthorized && error.notFound && messages.NO_SNAPSHOTS,
      error: !error.notFound && error,
      resourceType: SNAPSHOTS_FIELD,
    },
  }),

  fetchSnapshotsSuccess: response => ({
    type: constants.FETCH_SNAPSHOTS_SUCCESS,
    payload: { response },
  }),

  fetchSnapshotsSuccessPaginated: response => ({
    type: constants.FETCH_SNAPSHOTS_PAGINATED,
    payload: { response },
  }),
};

export default SnapshotsSectionActionCreators;
