import React from 'react';
import PropTypes from 'prop-types';

import { statusLabels } from './constants';
import { DeviceStatusIcon, VersionStatusIcon } from './components';
import { renderIconLabel } from './utilities';

const StatusIcon = ({ deviceId, online, deviceAssigned, disableLabel }) => {
  if (!deviceAssigned) {
    return <DeviceStatusIcon {...renderIconLabel(statusLabels.UNASSOCIATED, disableLabel)} />;
  }

  return <VersionStatusIcon online={online} deviceId={deviceId} disableLabel={disableLabel} />;
};

StatusIcon.defaultProps = {
  online: false,
  deviceAssigned: null,
  disableLabel: false,
};

StatusIcon.propTypes = {
  deviceId: PropTypes.string.isRequired,
  online: PropTypes.bool,
  deviceAssigned: PropTypes.string,
  disableLabel: PropTypes.bool,
};

export default StatusIcon;
