import { takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import sendPasswordResetToken from './sendPasswordResetToken';

function* saga() {
  yield takeLatest(constants.RESET_PASSWORD_SEND_TOKEN, sendPasswordResetToken);
}

export default saga;
