import React from 'react';
import { Input } from 'semantic-ui-react';

import InputField from '../InputField/InputField';
import FormField from '../FormField/FormField';

const TextField = props => (
  <FormField {...props}>
    <InputField {...props} />
  </FormField>
);

TextField.propTypes = {
  ...Input.propTypes,
};

export default TextField;
