import React, { forwardRef, Component } from 'react';
import PropTypes from 'prop-types';

import { ScrollableMenu, ScrollableMenuWrapper } from './TabbedLayoutScrollableMenu.style';

export class TabbedLayoutScrollableMenu extends Component {

  componentDidUpdate(prevProps) {
    const { scrollLeft: prevScrollLeft } = prevProps;
    const { forwardedRef, scrollLeft } = this.props;

    if (prevScrollLeft !== scrollLeft) {
      forwardedRef.current.scrollLeft = scrollLeft;
    }
  }

  render() {
    const { children, forwardedRef, onScroll } = this.props;

    return (
      <ScrollableMenuWrapper>
        <ScrollableMenu ref={forwardedRef} testRef={forwardedRef} onScroll={onScroll}>
          {children}
        </ScrollableMenu>
      </ScrollableMenuWrapper>
    );
  }

}

TabbedLayoutScrollableMenu.propTypes = {
  children: PropTypes.node.isRequired,
  forwardedRef: PropTypes.shape({
    current: PropTypes.instanceOf(HTMLElement),
  }).isRequired,
  scrollLeft: PropTypes.number.isRequired,
  onScroll: PropTypes.func.isRequired,
};

export default forwardRef((props, ref) => (
  <TabbedLayoutScrollableMenu {...props} forwardedRef={ref} />
));
