import { compose } from 'redux';
import { connect } from 'react-redux';
import last from 'lodash/last';

import {
  PROJECTS_FIELD,
  singleProjectSelector,
  computedProjectTreePathSelector,
} from 'helpers/selectors';
import { deepEqualMemoizeOne, modifySelfWithResourceName } from 'helpers/sharedMethods';
import permissionsActionCreators from '../../../../utilities/permissions/permissionsActionCreators';
import { PermissionsTreeItem } from '../../../../components';
import { sortPaths } from '../../../../utilities';

// eslint-disable-next-line import/no-mutable-exports
let ProjectPermissionsTreeItemContainer;

export const makeMapState = () => {
  const memoPaths = deepEqualMemoizeOne();

  return function mapStateToProps(state, { path, resourceId }) {
    const { visibleName } = singleProjectSelector(state, resourceId);
    const { open, permissionCount, permissionId, ...paths } = computedProjectTreePathSelector(
      state,
      {
        projectId: resourceId,
        path,
      },
    );
    const label = modifySelfWithResourceName(last(path.split('.')), visibleName, 'Site');

    const sortedPaths = sortPaths(paths);

    return {
      open,
      label,
      paths: memoPaths(sortedPaths),
      PermissionsTreeItemContainer: ProjectPermissionsTreeItemContainer,
    };
  };
};

export const mapDispatchToProps = {
  onToggleOpen: (projectId, path) => permissionsActionCreators.toggleResourceTreeOpen(
    PROJECTS_FIELD,
    projectId,
    path,
  ),
};

ProjectPermissionsTreeItemContainer = compose(
  connect(makeMapState, mapDispatchToProps),
)(PermissionsTreeItem);

export default ProjectPermissionsTreeItemContainer;
