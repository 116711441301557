import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import { PROJECTS_FIELD, USERS_FIELD } from 'helpers/selectors';

const projectActionCreators = {
  // All users's projects
  fetchProjects: (userId, urlParams) => ({
    type: constants.FETCH_PROJECTS_REQUEST,
    payload: {
      userId,
      endpoint: endpoints.USER_PROJECTS(userId),
      resourceType: PROJECTS_FIELD,
      urlParams,
    },
  }),

  fetchAdminProjects: urlParams => ({
    type: constants.FETCH_PROJECTS_REQUEST,
    payload: {
      endpoint: endpoints.PROJECTS,
      resourceType: PROJECTS_FIELD,
      urlParams,
    },
  }),

  fetchProjectsSuccess: response => ({
    type: constants.FETCH_PROJECTS_SUCCESS,
    payload: {
      response,
      resourceType: PROJECTS_FIELD,
    },
  }),

  fetchProjectsFailure: error => ({
    type: constants.FETCH_PROJECTS_FAILED,
    payload: { error },
  }),

  // One project
  fetchProject: (projectId, urlParams = {}) => ({
    type: constants.FETCH_PROJECT_REQUEST,
    payload: {
      projectId,
      endpoint: endpoints.PROJECT(projectId),
      urlParams,
    },
  }),

  fetchProjectSuccess: response => ({
    type: constants.FETCH_PROJECT_SUCCESS,
    payload: {
      response,
      resourceType: PROJECTS_FIELD,
    },
  }),

  fetchProjectFailure: error => ({
    type: constants.FETCH_PROJECT_FAILED,
    payload: { error },
  }),

  addProject: params => ({
    type: constants.ADD_PROJECT_REQUEST,
    payload: {
      params,
      endpoint: endpoints.PROJECTS,
    },
  }),

  addProjectSuccess: response => ({
    type: constants.ADD_PROJECT_SUCCESS,
    payload: {
      response,
      message: messages.PROJECT_ADDED(response.data.attributes.name),
      resourceType: PROJECTS_FIELD,
      id: response.data.id,
    },
  }),

  addProjectFailure: error => ({
    type: constants.ADD_PROJECT_FAILED,
    payload: { error },
  }),

  deleteProject: project => ({
    type: constants.DELETE_PROJECT_REQUEST,
    payload: {
      project,
      endpoint: endpoints.PROJECT(project.id),
    },
  }),

  deleteProjectSuccess: response => ({
    type: constants.DELETE_PROJECT_SUCCESS,
    payload: {
      response,
      message: messages.PROJECT_DELETED(response.project.name),
      resourceType: PROJECTS_FIELD,
    },
  }),

  deleteProjectFailure: error => ({
    type: constants.DELETE_PROJECT_FAILED,
    payload: { error },
  }),

  removeUser: (userId, projectId) => ({
    type: constants.REMOVE_USER_FROM_PROJECT,
    payload: {
      userId,
      projectId,
      endpoint: endpoints.PROJECT_USERS(projectId),
    },
  }),

  removeUserSuccess: response => ({
    type: constants.REMOVE_USER_FROM_PROJECT_SUCCESS,
    payload: {
      response,
      message: messages.USER_REMOVED,
      resourceType: USERS_FIELD,
    },
  }),

  removeUserFailure: error => ({
    type: constants.REMOVE_USER_FROM_PROJECT_FAILED,
    payload: { error },
  }),
};

export default projectActionCreators;
