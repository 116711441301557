import styled from 'styled-components';
import { Input } from 'semantic-ui-react';

import { media } from 'themes/mixins';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;

  ${media.md`
    margin-right: 15px;
    margin-bottom: 0;
  `};
`;

export const StyledLabel = styled.span`
  flex: 1;
  flex-basis: 20%;

  ${media.md`
    display: none;
  `};
`;

export const StyledInput = styled(Input)`
  flex: 1;
  flex-basis: 80%;
`;
