import React, { useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Grid } from 'semantic-ui-react';
import moment from 'moment';
import pluralize from 'pluralize';

import { Button } from 'components/atomic';
import { DatePicker, TimePicker } from 'components/core';
import { SelectedTimesView } from 'components/core/TimePicker/components';

import { EndingCriteria } from '..';
import { WEEK_DAYS } from '../utils';
import { SCHEDULER_TYPES } from '../../../../../constants';
import {
  Container,
  WeekdaysContainer,
  StyledLabel,
  StyledRow,
  StyledInput,
  ErrorMessage,
} from './Weekly.style';

function Weekly({
  recurrenceErrors,
  recurrenceParams,
  existingSchedule,
  onRecurrenceParamsChange,
  onEndingCriteriaChange,
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [weekInterval, setWeekInterval] = useState(1);
  const [weekDays, setWeekDays] = useState([]);
  const [timeInterval, setTimeInterval] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endingCriteria, setEndingCriteria] = useState({});

  useEffect(() => {
    if (existingSchedule && !isLoaded) {
      const { type, params } = existingSchedule || {};

      if (type.toUpperCase() === SCHEDULER_TYPES.WEEKLY) {
        const {
          everyNWeeks,
          days,
          startDate: existingStartDate,
          startTimes,
        } = params;

        setWeekInterval(everyNWeeks);
        setWeekDays(days);
        setTimeInterval(startTimes);
        setStartDate(moment(existingStartDate));

        setIsLoaded(true);
      }
    }
  }, [isLoaded, existingSchedule, recurrenceParams]);

  useEffect(() => {
    onRecurrenceParamsChange({
      everyNWeeks: Number(weekInterval),
      days: weekDays,
      startDate: startDate && startDate.format('YYYY-MM-DD'),
      startTimes: timeInterval,
      ...endingCriteria.params,
    });
  }, [weekInterval, weekDays, timeInterval, startDate, endingCriteria, onRecurrenceParamsChange]);

  const weeklyDescription = useMemo(() => `${pluralize('week', Number(weekInterval))} at`, [weekInterval]);

  const handleEditingCriteriaChange = useCallback(value => {
    setEndingCriteria(value);

    onEndingCriteriaChange(value.type);
  }, [onEndingCriteriaChange]);

  const handleTimeSelected = useCallback(newTimes => {
    setTimeInterval(newTimes);
  }, []);

  const handleClearTimes = useCallback(() => {
    setTimeInterval([]);
  }, []);

  const handleRemoveTime = useCallback(deletedTime => {
    setTimeInterval(previousTimes => previousTimes.filter(time => time !== deletedTime));
  }, []);

  const handleWeekdaySelected = useCallback(selectedWeekday => {
    if (weekDays.includes(selectedWeekday)) {
      const newState = weekDays.filter(weekday => weekday !== selectedWeekday);

      setWeekDays(newState);
    } else {
      setWeekDays(previousState => [...previousState, selectedWeekday]);
    }
  }, [weekDays]);

  return (
    <Container>
      <Form size="large">
        <Grid>
          <StyledRow noPaddingBottom>
            <Grid.Column computer={6} mobile={8}>
              <Form.Field>
                <StyledLabel withCustomPadding>
                  Repeat every
                </StyledLabel>
                <StyledInput
                  name="week_interval"
                  type="number"
                  size="mini"
                  min="1"
                  value={weekInterval}
                  onChange={e => setWeekInterval(e.target.value)}
                  label={{
                    basic: true,
                    content: weeklyDescription,
                  }}
                  labelPosition="right"
                  required
                />
                <ErrorMessage>{recurrenceErrors.everyNWeeks}</ErrorMessage>
              </Form.Field>
            </Grid.Column>
            <Grid.Column computer={10} mobile={8}>
              <Form.Field>
                <StyledLabel withCustomPadding>
                  Times
                </StyledLabel>
                <TimePicker
                  name="start_times"
                  popupPosition="right center"
                  selectedTimes={timeInterval}
                  onChange={handleTimeSelected}
                  keepOpenedAfterSubmitFor={['SIMPLE']}
                  defaultEmptyMessage="Add time(s)"
                />
                <ErrorMessage>{recurrenceErrors.startTimes}</ErrorMessage>
              </Form.Field>
            </Grid.Column>
          </StyledRow>
          <StyledRow noPaddingBottom>
            <Grid.Column computer={16} mobile={16}>
              <Form.Field>
                <SelectedTimesView
                  selectedTimes={timeInterval}
                  onClearTimes={handleClearTimes}
                  onRemoveTime={handleRemoveTime}
                  hideWhenEmpty
                />
              </Form.Field>
            </Grid.Column>
          </StyledRow>
          <StyledRow noPaddingBottom>
            <Grid.Column computer={6} mobile={16}>
              <Form.Field>
                <StyledLabel withCustomPadding>
                  Starting on
                </StyledLabel>
                <DatePicker
                  name="start_date"
                  value={startDate}
                  min={moment().toDate()}
                  onChange={value => setStartDate(value)}
                  footer={false}
                  popupPosition="left center"
                  defaultEmptyMessage="Select a date."
                />
                <ErrorMessage>{recurrenceErrors.startDate}</ErrorMessage>
              </Form.Field>
            </Grid.Column>
          </StyledRow>
          <StyledRow>
            <Grid.Column computer={16} mobile={16}>
              <Form.Field>
                <StyledLabel withCustomPadding>
                  On
                </StyledLabel>
                <WeekdaysContainer>
                  {WEEK_DAYS.filter(weekday => weekday.key !== 'day').map(item => (
                    <Button
                      key={item.key}
                      basic={!weekDays.includes(item.key)}
                      size="tiny"
                      name={`btn-weekday-${item.key}`}
                      onClick={() => handleWeekdaySelected(item.key)}
                    >
                      {item.text}
                    </Button>
                  ))}
                </WeekdaysContainer>
                <ErrorMessage>{recurrenceErrors.days}</ErrorMessage>
              </Form.Field>
            </Grid.Column>
          </StyledRow>
          <EndingCriteria onChange={handleEditingCriteriaChange} />
        </Grid>
      </Form>
    </Container>
  );
}

Weekly.defaultProps = {
  recurrenceErrors: {},
  recurrenceParams: {},
  existingSchedule: null,
};

Weekly.propTypes = {
  recurrenceErrors: PropTypes.object,
  recurrenceParams: PropTypes.object,
  existingSchedule: PropTypes.object,
  onRecurrenceParamsChange: PropTypes.func.isRequired,
  onEndingCriteriaChange: PropTypes.func.isRequired,
};

export default Weekly;
