import { connect } from 'react-redux';

import { deepEqualMemoizeOne } from 'helpers/sharedMethods';
import { deviceSelector } from 'helpers/selectors';
import PathwayClockV1 from './PathwayClockV1';

const makeMapState = () => {
  const memoCurrentTime = deepEqualMemoizeOne();
  const memoOffset = deepEqualMemoizeOne();

  return function mapStateToProps(state, ownProps) {
    const { resourceId } = ownProps;
    const device = deviceSelector(state)(resourceId);
    const { online, reportedWithMeta } = device;

    const {
      clk: {
        now: currentTime,
        tz: offset = {},
        tz: {
          value: offsetValue,
        } = {},
      } = {},
    } = reportedWithMeta || {};

    return {
      loading: !reportedWithMeta,
      online,
      offsetValue,
      currentTime: memoCurrentTime(currentTime),
      offset: memoOffset(offset),
    };
  };
};

export default connect(makeMapState)(PathwayClockV1);
