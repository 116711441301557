import { connect } from 'react-redux';

import { actionCreators } from '../../utilities';
import OriginTableRow from './OriginTableRow';

const mapDispatchToProps = {
  onEditOrigin: actionCreators.editOrigin,
  onEditUrlOrigin: actionCreators.editUrlOrigin,
};

export default connect(null, mapDispatchToProps)(OriginTableRow);
