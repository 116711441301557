import { getLuminance } from 'polished';

import colors from 'themes/colors';

const contrastColor = color => {
  const luma = getLuminance(color);

  if (luma > 0.5) {
    return colors.black;
  }

  return colors.white;
};

export default contrastColor;
