import constants from 'dispatcherConst';

export const fetchReverseGeocoding = (siteId, latitude, longitude) => ({
  type: constants.FETCH_REVERSE_GEOCODING_REQUEST,
  payload: { siteId, latitude, longitude },
});

export const fetchReverseGeocodingSuccess = (siteId, address) => ({
  type: constants.FETCH_REVERSE_GEOCODING_SUCCESS,
  payload: { siteId, address },
});
