import React from 'react';

import FixtureGroupsSection from 'components/FixtureGroupsSection/FixtureGroupsSectionContainer';

const labels = [
  { text: 'Number', field: 'visibleNumber', width: 2, sortBy: 'id' },
  { text: 'Name', field: 'name', width: 3 },
  { text: 'Status', field: 'status', width: 11 },
];

const PharosFixtureGroupsV1 = props => <FixtureGroupsSection {...props} labels={labels} />;

export default PharosFixtureGroupsV1;
