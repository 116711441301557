/* eslint-disable no-param-reassign */
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';

const transformToDeviceStatus = projectsStatus => {
  if (isEmpty(projectsStatus)) {
    return {
      total: 0,
      items: [],
    };
  }

  let total = 0;

  const initialArray = [
    {
      title: 'ONLINE',
      amount: 0,
      barColor: '#6CAF8F',
    },
    {
      title: 'OFFLINE',
      amount: 0,
      barColor: '#BC5265',
    },
    {
      title: 'UNASSOCIATED',
      amount: 0,
      barColor: '#666370',
    },
    {
      title: 'UPGRADE_REQUIRED',
      amount: 0,
      barColor: '#666370',
    },
  ];

  const items = projectsStatus.reduce((finalArray, project) => {
    const {
      onlineDevices,
      offlineDevices,
      unassociatedDevices,
      devicesForUpgrade,
    } = project;

    finalArray[0].amount += onlineDevices;
    finalArray[1].amount += offlineDevices;
    finalArray[2].amount += unassociatedDevices;
    finalArray[3].amount += devicesForUpgrade;

    total += (onlineDevices + offlineDevices + unassociatedDevices + devicesForUpgrade);

    return finalArray;
  }, initialArray);

  const validItems = items.filter(item => item.amount > 0);

  return {
    total,
    items: orderBy(validItems, 'amount', 'desc'),
  };
};

export default transformToDeviceStatus;
