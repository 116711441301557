import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';
import deepEqual from 'lodash/isEqual';
import identity from 'lodash/identity';

import { deviceSelector } from 'helpers/selectors';
import { logLevels } from 'models/LogModel';
import PharosConfigurationV1 from './PharosConfigurationV1';

const makeMapState = () => {
  const memoWatchdog = memoizeOne(identity, deepEqual);
  const memoSntpAddress = memoizeOne(identity, deepEqual);
  const memoSntpOn = memoizeOne(identity, deepEqual);
  const memoLogLevel = memoizeOne(identity, deepEqual);
  const memoSysLogAddress = memoizeOne(identity, deepEqual);
  const memoSysLogOn = memoizeOne(identity, deepEqual);

  return function mapStateToProps(state, ownProps) {
    const { resourceId } = ownProps;
    const device = deviceSelector(state)(resourceId);
    const { reportedWithMeta } = device;

    const {
      hw: {
        wd: watchdog,
      } = {},
      clk: {
        ntp: {
          addr: sntpAddress,
          on: sntpOn,
        } = {},
      } = {},
      log: {
        lvl: {
          value: logLevelValue,
          timestamp: logLevelTimestamp,
        } = {},
        sys: {
          addr: syslogAddress,
          on: syslogOn,
        } = {},
      } = {},
    } = reportedWithMeta || {};

    const logLevel = {
      timestamp: logLevelTimestamp,
      value: logLevels[logLevelValue],
    };

    return {
      loading: !reportedWithMeta,
      watchdog: memoWatchdog(watchdog),
      sntpAddress: memoSntpAddress(sntpAddress),
      sntpOn: memoSntpOn(sntpOn),
      logLevel: memoLogLevel(logLevel),
      syslogAddress: memoSysLogAddress(syslogAddress),
      syslogOn: memoSysLogOn(syslogOn),
    };
  };
};

export default connect(makeMapState())(PharosConfigurationV1);
