import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import TableMenu from 'components/DataTable/TableMenu.style';

import ProjectSuiteContext from '../ProjectSuiteContext/ProjectSuiteContext';
import { processButtonList } from './utilities';

const AutomatedOperationActionLinks = ({ chosenItem, item, ...rest }) => {
  const isMultiSite = useContext(ProjectSuiteContext);
  const { projectId, name } = item;
  const actionLinks = processButtonList(isMultiSite, projectId, name);

  return (
    <TableMenu.Item>
      {actionLinks.map((ActionLink, index) => {
        const key = `${index}-${item.id}`;
        return (
          <TableMenu.Item key={key}>
            <ActionLink item={item} isMultiSite={isMultiSite} {...rest} />
          </TableMenu.Item>
        );
      })}
    </TableMenu.Item>
  );
};

AutomatedOperationActionLinks.defaultProps = {
  chosenItem: null,
};

AutomatedOperationActionLinks.propTypes = {
  chosenItem: PropTypes.object,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    projectId: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

export default AutomatedOperationActionLinks;
