import isEmpty from 'lodash/isEmpty';

import validateEndingCriteria from './validateEndingCriteria';

const validateYearlyRecurrenceView = ({ recurrenceParams, endingCriteria }) => {
  const {
    everyNYears,
    forNTimes,
    startTimes,
    startDate,
    endTime,
  } = recurrenceParams;
  let errors = {};

  if (!everyNYears) {
    errors.everyNYears = 'Required field';
  }

  if (everyNYears < 1 || everyNYears > 24) {
    errors.everyNYears = 'Value must be between 1 and 24';
  }

  if (isEmpty(startTimes)) {
    errors.startTimes = 'Required field';
  }

  if (!isEmpty(startTimes) && startTimes.length > 600) {
    errors.startTimes = 'Only up to 600 values are allowed';
  }

  if (!startDate) {
    errors.startDate = 'Required field';
  }

  errors = validateEndingCriteria({
    errors,
    startDate,
    forNTimes,
    endTime,
    endingCriteria,
    forNTimesMaxValue: 24,
  });

  return {
    isValid: isEmpty(Object.keys(errors)),
    errors,
  };
};

export default validateYearlyRecurrenceView;
