import styled from 'styled-components';
import { media } from 'themes/mixins';

export const Message = styled.article`
  ${media.sm`margin-top: 50px;`}
`;

export const Text = styled.p`
  font-size: 1.3em;
`;
