import { call, put } from 'redux-saga/effects';

import { deletePath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import { actionCreators as applicationUserActionCreators } from 'application/utilities/user';

import actionCreators from '../actionCreators';

function* deleteProjectSuite(action) {
  const { endpoint, projectSuite } = action.payload;

  let { response, error } = yield call(deletePath, endpoint);

  response = response ? { projectSuiteId: projectSuite.id, projectSuite } : undefined;

  error = error ? new Error(`Unable to delete project suite ${projectSuite.name}`) : undefined;

  const params = {
    response,
    error,
    successDisp: actionCreators.deleteProjectSuiteSuccess,
    failDisp: actionCreators.deleteProjectSuiteFailure,
  };

  yield call(processCall, params);

  if (response) {
    yield put(applicationUserActionCreators.fetchCurrentUser());
  }
}

export default deleteProjectSuite;
