import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';

import { complexValueType } from 'data/types';
import TextDescriptionItem
  from 'components/DescriptionList/TextDescriptionItem/TextDescriptionItem';
import TimeDescriptionItemIncremented
  from 'components/DescriptionList/TimeDescriptionItemIncremented/TimeDescriptionItemIncremented';
import {
  DataStorageDescriptionItem,
  UsedDiskSpaceDescriptionItem,
} from './components';

const SLCHardwareV1 = props => {
  const {
    loading,
    online,
    title,
    model,
    serial,
    firmwareVersion,
    dataStorage,
    diskUsedSpace,
    uptime,
  } = props;

  if (loading) {
    return null;
  }

  return (
    <Segment>
      <Header as="h2">{title}</Header>
      <TextDescriptionItem
        title="Model"
        value={model}
      />
      <TextDescriptionItem
        title="Serial Number"
        value={serial}
      />
      <TextDescriptionItem
        title="Firmware Version"
        value={firmwareVersion}
      />
      <DataStorageDescriptionItem
        title="Data Storage Size"
        value={dataStorage}
      />
      <UsedDiskSpaceDescriptionItem
        title="Used Disk Space"
        value={diskUsedSpace}
      />
      <TimeDescriptionItemIncremented
        title="Uptime"
        value={uptime}
        isOnline={online}
      />
    </Segment>
  );
};

SLCHardwareV1.defaultProps = {
  online: false,
  loading: false,
  model: {},
  serial: {},
  firmwareVersion: {},
  uptime: {},
  dataStorage: {},
  diskUsedSpace: {},
};

SLCHardwareV1.propTypes = {
  online: PropTypes.bool,
  loading: PropTypes.bool,
  model: complexValueType(PropTypes.string),
  title: PropTypes.string.isRequired,
  serial: complexValueType(PropTypes.string),
  firmwareVersion: complexValueType(PropTypes.string),
  uptime: complexValueType(PropTypes.number),
  dataStorage: complexValueType(PropTypes.number),
  diskUsedSpace: complexValueType(PropTypes.number),
};

export default SLCHardwareV1;
