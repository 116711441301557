import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';
import CheckboxField from 'components/form/CheckboxField/CheckboxField';
import SegmentRow from 'components/SegmentRow/SegmentRow';
import pathwayConstants from 'layouts/Pathway/constants';
import { pathwayDeviceDMXValueSelector } from 'helpers/selectors';
import DeviceSettingForm from 'pages/Device/DeviceSettingForm/DeviceSettingFormContainer';

const PathwayPathportPortsV1RDM = ({ online, element: { resourceId, id, rdmEnabled } }) => (
  <Segment>
    <Header as="h4">RDM</Header>
    <SegmentRow>
      <div>RDM Enabled:</div>
      <DeviceSettingForm
        online={online}
        resourceId={resourceId}
        port={id}
        label="RDM Enabled"
        valueSelector={pathwayDeviceDMXValueSelector(id, pathwayConstants.FIELD_RDM)}
        setting={pathwayConstants.SETTING_RDM_ENABLED}
        fieldProps={{ component: CheckboxField, type: 'checkbox' }}
        name="rdm"
      />
    </SegmentRow>
    {rdmEnabled && (
      <SegmentRow>
        <div>RDM Background Discover Enabled: </div>
        <DeviceSettingForm
          online={online}
          resourceId={resourceId}
          port={id}
          label="RDM Background Discover Enabled"
          valueSelector={pathwayDeviceDMXValueSelector(id, pathwayConstants.FIELD_RDM_BACKGROUND)}
          setting={pathwayConstants.SETTING_BACKGROUND_DISCOVERY}
          fieldProps={{ component: CheckboxField, type: 'checkbox' }}
          name="rdmBgDcvr"
        />
      </SegmentRow>
    )}
  </Segment>
);

PathwayPathportPortsV1RDM.propTypes = {
  online: PropTypes.bool.isRequired,
  element: PropTypes.shape({
    resourceId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    rdmEnabled: PropTypes.bool.isRequired,
  }).isRequired,
};

export default PathwayPathportPortsV1RDM;
