import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  USERS_FIELD,
  LOGICAL_DEVICES_FIELD,
  permissionsProjectUsersLinkSelector,
} from 'helpers/selectors';
import projectActionCreators from 'pages/Projects/projectActionCreators';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import withLoadProject from './withLoadProject';

export const mapStateToProps = (state, { projectId }) => ({
  usersEndpoint: permissionsProjectUsersLinkSelector(state)(projectId),
});

export default compose(
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    ({ projectId }) => projectActionCreators.fetchProject(projectId, {
      fields: {
        projects: [USERS_FIELD, LOGICAL_DEVICES_FIELD],
      },
    }),
  ),
  withLoadProject,
);
