import styled from 'styled-components';

const SegmentRow = styled.div`
  display: flex;
  border-bottom: 1px solid #cbcbcc;
  padding-bottom: 10px;
  margin-bottom: 10px;
  flex-flow: row wrap;
  align-items: center;

  & > * {
    margin-right: 5px;
    margin-bottom: 5px;
  }

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom:0;
  }

  & *:last-child {
    justify-self: flex-end;
    margin-left: auto;
    margin-right: 0;
  }
`;

export default SegmentRow;
