import React from 'react';
import PropTypes from 'prop-types';

import { RemoveBackgroundImage } from '../../../ControlUIPage/components/PageBackgroundImage/components';
import { UploadImageInput } from './components';
import {
  MenuWrapper,
  StyledInputHolder,
} from './BackgroundImageUrl.style';

const BackgroundImageUrl = ({ pageId, backgroundImg }) => (
  <MenuWrapper>
    <StyledInputHolder>
      <UploadImageInput pageId={pageId} />
    </StyledInputHolder>
    {backgroundImg && <RemoveBackgroundImage pageId={pageId} />}
  </MenuWrapper>
);

BackgroundImageUrl.defaultProps = {
  backgroundImg: undefined,
  pageId: 0,
};

BackgroundImageUrl.propTypes = {
  pageId: PropTypes.number,
  backgroundImg: PropTypes.string,
};

export default BackgroundImageUrl;
