import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { tasksActionCreators } from '../../../../../../../../../utilities';
import AddTask from './AddTask';

export const mapStateToProps = (state, { match }) => {
  const { id } = match.params;

  return {
    id,
  };
};

const mapDispatchToProps = {
  onAddTask: tasksActionCreators.addTask,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AddTask);
