import React, { Fragment, Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Header, Loader, Responsive } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';

import { paginationType } from 'data/types';
import { sizes } from 'themes/mixins';
import withHighlights from './withHighlights/withHighlights';
import DataTableRow from './DataTableRow/DataTableRow';
import DataTableHeader from './DataTableHeader/DataTableHeaderContainer';
import DataTableSorting from './DataTableSorting/DataTableSortingContainer';
import DataTableFilter from './DataTableFilter/DataTableFilterContainer';
import Table, { Dimmer, TableDescription } from './Table.style';
import TableMenu from './TableMenu.style';

class DataTable extends Component {

  constructor() {
    super();

    this.state = {
      chosenItem: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  componentDidMount() {
    const { header, pagination, storeTableName } = this.props;
    if (!pagination) return;

    storeTableName(pagination.type, header);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { chosenItem } = prevState;
    const { selectField, data, refreshing } = nextProps;

    if (isEmpty(chosenItem)) return null;

    if (refreshing) {
      return { chosenItem: {} };
    }

    const itemFound = (data && data.find((
      item => chosenItem[selectField] === item[selectField]
    ))) || {};

    return { chosenItem: itemFound };
  }

  handleChange(nextValue) {
    const { actionLinks, selectField } = this.props;
    const { chosenItem: { [selectField]: chosenId } } = this.state;

    const newValue = nextValue[selectField] !== chosenId ? nextValue : {};
    const nextState = actionLinks ? { chosenItem: newValue } : null;

    this.setState(nextState);
  }

  handleOpen({ id }) {
    const { history, route, searchParamsConfig } = this.props;

    if (route) {
      const pathname = `${route}/${id}`;
      const search = searchParamsConfig[pathname];

      history.push({ pathname, search });
    }
  }

  wrapTableRow(TableRow) {
    const { highlightUpdates } = this.props;

    if (highlightUpdates) {
      return withHighlights(TableRow);
    }

    return TableRow;
  }

  selectTableRow() {
    const { customTableRow: CustomTableRow } = this.props;

    if (CustomTableRow) {
      return this.wrapTableRow(CustomTableRow);
    }

    return this.wrapTableRow(DataTableRow);
  }

  renderActionLinks() {
    const {
      actionLinks: ActionLinks,
      createElement,
      customTableRow,
      data,
      header,
      labels,
      selectField,
      ...rest
    } = this.props;
    const {
      chosenItem: { [selectField]: chosenId },
      chosenItem,
    } = this.state;
    const emptyItem = { id: '', name: '', suspended: false, email: '', fileTypeId: 'fw' };

    if (isEmpty(chosenItem)) {
      return ActionLinks && <ActionLinks item={emptyItem} emptyItem {...rest} />;
    }

    return chosenId && ActionLinks && (
      <ActionLinks item={chosenItem} {...rest} />
    );
  }

  renderCreateElement() {
    const {
      actionLinks,
      createElement: CreateElement,
      customTableRow,
      data,
      header,
      labels,
      selectField,
      ...rest
    } = this.props;

    if (!CreateElement) return null;

    if (React.isValidElement(CreateElement)) {
      return cloneElement(CreateElement, rest);
    }

    return <CreateElement {...rest} />;
  }

  renderFilterElement() {
    const { pagination } = this.props;
    const { args } = pagination || {};
    const { withFilter } = args || {};

    if (withFilter) {
      return <DataTableFilter pagination={pagination} />;
    }

    return null;
  }

  renderSortingElement() {
    const { data, labels, pagination } = this.props;

    if (!data) {
      return null;
    }

    return <DataTableSorting labels={labels} pagination={pagination} />;
  }

  renderDescription() {
    const {
      description,
      hideDescriptionMobile,
    } = this.props;

    if (!description) {
      return null;
    }

    if (hideDescriptionMobile) {
      return (
        <Responsive name="responsive-container-description" minWidth={sizes.md}>
          <TableDescription>
            {description}
          </TableDescription>
        </Responsive>
      );
    }

    return (
      <TableDescription>
        {description}
      </TableDescription>
    );
  }

  renderCustomControls() {
    const {
      customControls: CustomControls,
    } = this.props;

    if (!CustomControls) return null;

    return (
      <TableMenu>
        <TableMenu.Item>
          <CustomControls />
        </TableMenu.Item>
      </TableMenu>
    );
  }

  renderTableControls() {
    const {
      header,
      actionLinks,
      createElement: CreateElement,
    } = this.props;

    const { chosenItem } = this.state;
    const itemSelected = !isEmpty(chosenItem) ? 1 : 0;

    if (header || actionLinks || CreateElement) {
      return (
        <Fragment>
          <TableMenu itemSelected={itemSelected}>
            <TableMenu.Item isActionLinksContainer>
              <div>
                <Header as="h3">
                  {header}
                  {this.renderDescription()}
                  {this.renderActionLinks()}
                </Header>
              </div>
            </TableMenu.Item>
            <TableMenu.Item position="right" noPadding>
              <Responsive minWidth={sizes.md}>
                {this.renderFilterElement()}
              </Responsive>
              {this.renderCreateElement()}
            </TableMenu.Item>
          </TableMenu>
          {this.renderCustomControls()}
          <Responsive maxWidth={sizes.md - 1}>
            <TableMenu widths={1} vertical>
              <TableMenu.Item>
                {this.renderFilterElement()}
                {this.renderSortingElement()}
              </TableMenu.Item>
            </TableMenu>
          </Responsive>
        </Fragment>
      );
    }

    return null;
  }

  renderTableHeader() {
    const { labels, pagination } = this.props;

    return (
      <Table.Row>
        <Table.HeaderCell />
        {labels.map(label => (
          <DataTableHeader
            key={label.field}
            label={label}
            pagination={pagination || undefined}
          />
        ))}
      </Table.Row>
    );
  }

  renderTableData() {
    const {
      customTableRow,
      createElement,
      header,
      history,
      data,
      labels,
      actionLinks,
      route,
      selectField,
      highlightUpdates,
      pagination,
      searchParamsConfig,
      storeTableName,
      ...rest
    } = this.props;
    const { chosenItem: { [selectField]: chosenId } } = this.state;

    return data && data.map((item, index) => {
      const key = item[selectField];
      const isActive = key === chosenId;
      const className = route ? 'interactive' : '';
      const TableRow = this.selectTableRow();

      return (
        <TableRow
          className={className}
          key={key}
          actionLinks={actionLinks}
          item={item}
          labels={labels}
          active={isActive}
          onClick={this.handleOpen}
          onChangeActive={this.handleChange}
          rowNumber={index}
          {...rest}
        />
      );
    });
  }

  render() {
    const { labels, refreshing, fixed } = this.props;

    return (
      <div>
        {this.renderTableControls()}
        <Dimmer.Dimmable dimmed={refreshing}>
          <Table columns={labels.length} sortable fixed={fixed}>
            <Table.Header>
              {this.renderTableHeader()}
            </Table.Header>

            <Table.Body>
              {this.renderTableData()}
            </Table.Body>
          </Table>

          <Dimmer inverted active={refreshing}>
            <Loader>Refreshing</Loader>
          </Dimmer>
        </Dimmer.Dimmable>
      </div>
    );
  }

}

DataTable.defaultProps = {
  actionLinks: null,
  createElement: null,
  customControls: null,
  customTableRow: null,
  data: null,
  header: null,
  pagination: null,
  route: null,
  selectField: 'id',
  highlightUpdates: false,
  refreshing: false,
  fixed: false,
  description: null,
  hideDescriptionMobile: false,
};

DataTable.propTypes = {
  actionLinks: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  createElement: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  customControls: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  customTableRow: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  data: PropTypes.arrayOf(
    PropTypes.object,
  ),
  header: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  pagination: paginationType,
  route: PropTypes.string,
  searchParamsConfig: PropTypes.objectOf(PropTypes.string).isRequired,
  selectField: PropTypes.string,
  storeTableName: PropTypes.func.isRequired,
  highlightUpdates: PropTypes.bool,
  refreshing: PropTypes.bool,
  fixed: PropTypes.bool,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  hideDescriptionMobile: PropTypes.bool,
};

export default DataTable;
