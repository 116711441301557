import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import isUndefined from 'lodash/isUndefined';

import {
  StyledControlUIActionGroupWrapper,
  ControlUIPageFooterWrapper,
} from './ControlUIPageFooter.style';

const ControlUIPageFooter = ({
  pageId,
  pageIds,
  configure,
  onPageChange,
}) => {
  const total = pageIds.length;

  const isFirstPage = useMemo(() => {
    const index = pageIds.findIndex(id => Number(id) === Number(pageId));

    return index === 0;
  }, [pageId, pageIds]);

  const isLastPage = useMemo(() => {
    const index = pageIds.findIndex(id => Number(id) === Number(pageId));

    return index === total - 1;
  }, [pageId, pageIds, total]);

  const handleNextPage = useCallback(() => {
    if (isLastPage) {
      return;
    }

    const nextPageId = pageIds.find(id => Number(id) > Number(pageId));

    if (isUndefined(nextPageId)) {
      return;
    }

    onPageChange({}, { activePage: nextPageId + 1 });
  }, [pageId, pageIds, isLastPage, onPageChange]);

  const handlePrevious = useCallback(() => {
    if (isFirstPage) {
      return;
    }

    const previousPageId = pageIds.reverse().find(id => Number(id) < Number(pageId));

    if (isUndefined(previousPageId)) {
      return;
    }

    onPageChange({}, { activePage: previousPageId + 1 });
  }, [pageId, pageIds, isFirstPage, onPageChange]);

  const renderPageControls = useCallback(() => {
    if (total === 0 || isUndefined(pageId)) {
      return null;
    }

    return (
      <Button.Group basic>
        <Button name="btn-previous" icon onClick={handlePrevious}>
          <Icon name="angle left" />
        </Button>
        <Button name="btn-next" icon onClick={handleNextPage}>
          <Icon name="angle right" />
        </Button>
      </Button.Group>
    );
  }, [
    total,
    pageId,
    handleNextPage,
    handlePrevious,
  ]);

  if (configure) {
    return (
      <StyledControlUIActionGroupWrapper>
        {renderPageControls()}
      </StyledControlUIActionGroupWrapper>
    );
  }

  return (
    <ControlUIPageFooterWrapper>
      {renderPageControls()}
    </ControlUIPageFooterWrapper>
  );
};

ControlUIPageFooter.defaultProps = {
  pageId: undefined,
};

ControlUIPageFooter.propTypes = {
  pageId: PropTypes.number,
  pageIds: PropTypes.array.isRequired,
  configure: PropTypes.bool.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default ControlUIPageFooter;
