import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';

import {
  StyledDropdown,
  StyledButton,
  StyledDiv,
} from './SelectProjectsDropdown.style';

const SelectProjectsDropdown = ({
  options,
  field,
  requestType,
  resourceId,
  existingSelectedProjects,
  onTriggerProjectSuiteTasksFiltering,
  filterParam,
  dropdownText,
}) => {
  const [selectedProjects, setSelectedProjects] = useState([]);
  const disabled = isEmpty(selectedProjects);

  useEffect(() => {
    setSelectedProjects(existingSelectedProjects);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isEmpty(options)) {
    return <Loader active inline />;
  }

  const handleProjectSelect = (e, { value }) => {
    let finalValue = value;
    if (value.includes('allSites')) {
      finalValue = options.map(({ id }) => id).filter(item => item !== 'allSites');
    }

    if (value.includes('superSiteTasks')) {
      finalValue = ['superSiteTasks'];
    }

    setSelectedProjects(finalValue);
  };

  const handleTriggerFilter = () => {
    if (selectedProjects.includes('superSiteTasks')) {
      return onTriggerProjectSuiteTasksFiltering(
        resourceId,
        [],
        filterParam,
        field,
        requestType,
      );
    }

    return onTriggerProjectSuiteTasksFiltering(
      resourceId,
      selectedProjects,
      filterParam,
      field,
      requestType,
    );
  };

  return (
    <StyledDiv>
      <StyledDropdown
        text={dropdownText}
        clearable
        options={options}
        selection
        selectOnBlur={false}
        onChange={handleProjectSelect}
        value={selectedProjects}
        multiple
        search
      />
      <StyledButton
        onClick={handleTriggerFilter}
        disabled={disabled}
      >
        Filter
      </StyledButton>
    </StyledDiv>
  );
};

SelectProjectsDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  field: PropTypes.string.isRequired,
  requestType: PropTypes.symbol.isRequired,
  existingSelectedProjects: PropTypes.array.isRequired,
  onTriggerProjectSuiteTasksFiltering: PropTypes.func.isRequired,
  resourceId: PropTypes.string.isRequired,
  filterParam: PropTypes.string.isRequired,
  dropdownText: PropTypes.string.isRequired,
};

export default SelectProjectsDropdown;
