import styled from 'styled-components';
import { rgba } from 'polished';

import colors from 'themes/colors';
import UploadsController from 'components/UploadsWidget/UploadsController/UploadsController';
import UploadsStats from 'components/UploadsWidget/UploadsStats/UploadsProjectStatsContainer';

export const StyledDesktopHeader = styled(UploadsController)`
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
`;

export const StyledUploadsStats = styled(UploadsStats)`
  margin-left: 1em;
  color: ${rgba(colors.black, 0.4)};
  font-size: 0.6em;
  font-weight: 400;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
