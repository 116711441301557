import constants from 'dispatcherConst';

const initialState = {
  fetching: false,
};

const fetchingScenesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_DEVICE_SCENES_REQUEST:
      return { ...state, fetching: true };

    case constants.FETCH_DEVICE_SCENES_SUCCESS:
      return { ...state, fetching: false };

    case constants.FETCH_DEVICE_SCENES_FAILED:
      return { ...state, fetching: false };

    default:
      return state;
  }
};

export default fetchingScenesReducer;
