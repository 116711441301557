import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'semantic-ui-react';

import DefinitionListItem from './DefinitionListItem/DefinitionListItem';

const DefinitionList = ({ data, labels, divider, isOnline }) => {
  const dataLabels = () => (
    data && Object.keys(data).map(key => ({ text: key, field: key }))
  );

  const definitionListLabels = labels || dataLabels();
  if (!definitionListLabels) return null;

  return definitionListLabels.map(({ text, field, format, autoincrement }, index) => (
    <Fragment key={field}>
      <DefinitionListItem
        title={text}
        data={data && data[field]}
        format={format}
        autoincrement={autoincrement}
        isOnline={isOnline}
      />
      {divider && (index !== definitionListLabels.length - 1) && (
        <Divider />
      )}
    </Fragment>
  ));
};

DefinitionList.defaultProps = {
  data: null,
  divider: false,
  labels: null,
  isOnline: false,
};

DefinitionList.propTypes = {
  data: PropTypes.object,
  divider: PropTypes.bool,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      field: PropTypes.string.isRequired,
    }).isRequired,
  ),
  isOnline: PropTypes.bool,
};

export default DefinitionList;
