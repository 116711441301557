import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/atomic';
import ConfirmModal from 'components/elements/ConfirmModal/ConfirmModal';
import DataTableActionButtonLink from 'components/DataTable/DataTableActionButtonLink/DataTableActionButtonLink';

const TransferDeviceFirmwareActionLink = ({ onTransferDeviceFirmware, item, ...rest }) => {
  const { id } = item;
  const { deviceId } = rest;

  const handleConfirm = useCallback(() => {
    onTransferDeviceFirmware(id, deviceId);
  }, [onTransferDeviceFirmware, id, deviceId]);

  return (
    <ConfirmModal
      trigger={<DataTableActionButtonLink title="Transfer" />}
      cancelButton="Cancel"
      confirmButton={<Button negative primary={false}>Transfer Firmware</Button>}
      content={(
        <div>
          <p>
            Are you sure you want to transfer this Firmware?
          </p>
        </div>
      )}
      header="Confirm Device Firmware Transfer"
      onConfirm={handleConfirm}
    />
  );
};

TransferDeviceFirmwareActionLink.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  deviceId: PropTypes.string.isRequired,
  onTransferDeviceFirmware: PropTypes.func.isRequired,
};

export default TransferDeviceFirmwareActionLink;
