import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import { markerTypes } from '../../../../../../../../constants';

const RenderSiteStatusIcon = ({ type }) => {
  if (type === markerTypes.ONLINE) {
    return <Icon name="circle" color="green" />;
  }

  if (type === markerTypes.PARTIAL_ONLINE) {
    return <Icon name="circle" color="orange" />;
  }

  if (type === markerTypes.OFFLINE) {
    return <Icon name="circle" color="red" />;
  }

  if (type === markerTypes.WARNING) {
    return <Icon name="warning sign" color="red" />;
  }

  return null;
};

RenderSiteStatusIcon.defaultProps = {
  type: null,
};

RenderSiteStatusIcon.propTypes = {
  type: PropTypes.string,
};

export default RenderSiteStatusIcon;
