import { compose } from 'redux';
import { connect } from 'react-redux';

import mapMarker from 'application/tenant/console/site/components/Map/mapMarker/mapMarker';

import ProjectSuiteMapMarker from './ProjectSuiteMapMarker';

const mapStateToProps = () => ({
  initiallyOpen: true,
});

export default compose(
  connect(mapStateToProps),
  mapMarker({
    custom: true,
    onSelect: () => null,
  }),
)(ProjectSuiteMapMarker);
