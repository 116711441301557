import React from 'react';
import { Container, Grid } from 'semantic-ui-react';

import { Message, Text } from './ControlUILoading.style';

const ControlUILoading = () => (
  <Container>
    <Grid centered>
      <Grid.Row>
        <Grid.Column textAlign="center" mobile={16}>
          <Message>
            <Text>
              Loading...
            </Text>
          </Message>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
);

export default ControlUILoading;
