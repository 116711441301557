import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { DatePicker } from 'components/core';

import { Container } from './CompanyDatePickerField.style';
import { formatDate } from './utilities';

function CompanyDatePickerField({ value, onChange }) {
  const formattedDate = useMemo(() => formatDate(value), [value]);

  const handleChange = useCallback(selectedDate => {
    onChange(selectedDate.format('YYYY-MM-DD'));
  }, [onChange]);

  return (
    <Container>
      <DatePicker
        value={value}
        onChange={handleChange}
        footer={false}
        defaultEmptyMessage={null}
        hideValue
      />
      <span>
        {formattedDate || 'Not set'}
      </span>
    </Container>
  );
}

CompanyDatePickerField.defaultProps = {
  value: undefined,
};

CompanyDatePickerField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default CompanyDatePickerField;
