import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import AccordionTable from 'components/AccordionTable/AccordionTable';
import PathwayPathportPortsV1Output from '../PathwayPathportPortsV1/PathwayPathportPortsV1Output';

const Path = ({ online, columns, data }) => (
  <AccordionTable
    columns={columns}
    action={() => null}
    data={data}
    render={element => (
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} computer={16}>
            <PathwayPathportPortsV1Output online={online} element={element} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )}
  />
);

Path.propTypes = {
  online: PropTypes.bool.isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default Path;
