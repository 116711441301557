import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  permissionsStateSelector,
} from 'helpers/selectors';
import permissionsActionCreators from '../../../../../../../utilities/permissions/permissionsActionCreators';
import AddContextButton from './AddContextButton';

export const mapStateToProps = state => {
  const { selectingContexts } = permissionsStateSelector(state);

  return {
    selectingContexts,
  };
};

const mapDispatchToProps = {
  onToggleSelectingContexts: permissionsActionCreators.toggleSelectingContexts,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(AddContextButton);
