import { Component } from 'react';
import PropTypes from 'prop-types';
import { getScrollPos } from 'helpers/sharedMethods';

class ScrollPos extends Component {

  constructor(props) {
    super(props);

    this.state = { position: null };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const { y } = getScrollPos();
    this.setState({ position: y });
  }

  render() {
    const { position } = this.state;
    const { children } = this.props;

    return children(position);
  }

}

ScrollPos.propTypes = {
  children: PropTypes.func.isRequired,
};

export default ScrollPos;
