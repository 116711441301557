import styled, { css } from 'styled-components';
import { Card } from 'semantic-ui-react';

import { media, iOSVersion } from 'themes/mixins';
import colors from 'themes/colors';
import sidebarConst from 'themes/variables';

const sidebarWidth = sidebarConst.width;

export const StyledUploads = styled.div`
  z-index: 101;
  position: fixed;
  width: 100vw;
  height: 110px;
  bottom: 0;
  left: 0;
  background: transparent;
  box-shadow: 0 -1px 2px 0 rgba(34,36,38,.15);
  cursor: pointer;
  transition: 500ms height ease, 1000ms background-color ease;

  ${media.md`
    z-index: 103;
    bottom: 1em;
    left: calc(${sidebarWidth.md} + 2.8571em);
    height: 65px;
    width: calc(50% - (${sidebarWidth.md} / 2));
    background: transparent;
    box-shadow: none;
  `};

  &.open {
    height: calc(100% - 61px)!important;
    box-shadow: none;
    background: white;
    transition: 500ms height ease;

    ${iOSVersion() === 11.2 && css`height: 100%!important;`};
  }
`;

export const UploadsPanel = styled(Card)`
  &.ui.card {
    width: 100%;
    margin: 0;
    box-shadow: none;
    
    &.compact {
      border-radius: 0;

      ${media.md`background: ${colors.concrete}`};
    }
  }
`;

UploadsPanel.Content = styled(Card.Content)`
  padding: 0 !important;
`;

UploadsPanel.InfoControls = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 14px;
  padding-bottom: 8px;
`;

UploadsPanel.Info = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  h3 {
    margin-bottom: 0;
  }

  span {
    margin-top: auto;
    color: rgba(0, 0, 0, 0.4);
  }
`;

UploadsPanel.Controls = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

UploadsPanel.Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

UploadsPanel.Description = Card.Description;
