import { connect } from 'react-redux';

import TimeMaskText from './TimeMaskText';
import { renderTimes } from '../../utilities';

export const mapStateToProps = (state, ownProps) => {
  const {
    hours,
    minutes,
    seconds,
  } = ownProps;
  const args = { hours, minutes, seconds };
  const { timesList, showTimes } = renderTimes(args);

  return {
    timesList,
    showTimes,
  };
};

export default connect(mapStateToProps)(TimeMaskText);
