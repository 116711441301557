import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import { TbPresentation } from 'react-icons/tb';

const IsDemoIcon = ({ color }) => (
  <div>
    <Popup
      content="This Site is in demo mode. Please contact the Site Owner for more information."
      trigger={(
        <TbPresentation size={40} color={color} />
      )}
      position="top right"
    />
  </div>
);

IsDemoIcon.defaultProps = {
  color: '#818181',
};

IsDemoIcon.propTypes = {
  color: PropTypes.string,
};

export default IsDemoIcon;
