import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledPermissionsGridRow,
  StyledItemWrapper,
  StyledChildrenWrapper,
} from './PermissionsGridRow.style';

const PermissionsGridRow = ({
  resourceId,
  path,
  open,
  userIds,
  paths,
  PermissionsGridItem,
  PermissionsGridRowContainer,
  ...rest
}) => (
  <StyledPermissionsGridRow>
    <StyledItemWrapper>
      {userIds && userIds.map(userId => (
        <PermissionsGridItem
          key={userId}
          resourceId={resourceId}
          userId={userId}
          path={path}
          {...rest}
        />
      ))}
    </StyledItemWrapper>
    <StyledChildrenWrapper>
      {open && paths.map(childPath => (
        <PermissionsGridRowContainer
          key={childPath}
          resourceId={resourceId}
          path={`${path}.${childPath}`}
          {...rest}
        />
      ))}
    </StyledChildrenWrapper>
  </StyledPermissionsGridRow>
);

PermissionsGridRow.defaultProps = {
  userIds: null,
};

PermissionsGridRow.propTypes = {
  resourceId: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  userIds: PropTypes.array,
  paths: PropTypes.array.isRequired,
  PermissionsGridItem: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  PermissionsGridRowContainer: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
};

export default PermissionsGridRow;
