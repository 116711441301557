import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { singleProjectSelector, currentUserSelector } from 'helpers/selectors';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import websocketActionCreators from 'data/websockets/websocketsActionCreators';
import { actionCreators as actionCreatorsForRequest } from '../utilities';
import {
  actionCreators,
  selectors,
  actionsListToDropdownOptions,
  extractTenantData,
} from './utilities';
import LogsFilterParams from './LogsFilterParams';

export const mapDispatchToProps = {
  onGetLogs: websocketActionCreators.getLogsAuditLogChannel,
  onSetActiveRequestId: actionCreatorsForRequest.addActiveRequestId,
};

export const mapStateToProps = (state, { match }) => {
  const { superadmin } = currentUserSelector(state);
  const { id: projectId } = match.params;
  const actionsList = actionsListToDropdownOptions(
    selectors.actionTypesListSelector(state), superadmin,
  );
  const displayActionsListDropdown = Array.isArray(actionsList) && !isEmpty(actionsList);
  const { timezoneId } = singleProjectSelector(state, projectId);
  const { companyId, tenant } = extractTenantData(state);

  return {
    projectId,
    actionsList,
    displayActionsListDropdown,
    timezoneId,
    companyId,
    tenant,
  };
};

export default compose(
  withRouter,
  chainedLoaderWithSaga(actionCreators.fetchLogsActionTypes),
  connect(mapStateToProps, mapDispatchToProps),
)(LogsFilterParams);
