import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { FETCHING_FILTER_PROJECT_SUITES_FIELD } from 'helpers/selectors';
import {
  selectors as projectSuitesSelectors,
} from 'pages/ProjectSuites/utilities';
import selectors from 'pages/ProjectSuites/utilities/selectors';
import { triggerProjectSuiteTasksFiltering } from 'pages/ProjectSuite/ProjectSuiteTasksTab/utilities/actionCreators/';
import SelectProjectsDropdown from './SelectProjectsDropdown';

export const mapStateToProps = (state, { field, match }) => {
  const { id: resourceId } = match.params;
  const parents = selectors.projectSuiteParentsSelector(state)(resourceId);
  const existingSelectedProjects = projectSuitesSelectors.selectedProjectSuitesSelector(
    state, resourceId, field,
  ) || [];
  const options = parents.map(({ id, name }) => ({ key: id, text: name, value: id, id }));
  const filterParam = FETCHING_FILTER_PROJECT_SUITES_FIELD;
  const dropdownText = 'Filter by Multi-sites';
  const allProjects = { id: 'allSites', text: 'All Multi-sites', value: 'allSites' };
  const onlySuperSiteTasks = { id: 'allSites', text: 'Only Site occurrences', value: 'superSiteTasks' };
  if (!isEmpty(options)) {
    options.unshift(allProjects);
  }
  options.unshift(onlySuperSiteTasks);

  return {
    existingSelectedProjects,
    resourceId,
    options,
    filterParam,
    dropdownText,
  };
};

const mapDispatchToProps = {
  onTriggerProjectSuiteTasksFiltering: triggerProjectSuiteTasksFiltering,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(SelectProjectsDropdown);
