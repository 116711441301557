import React, { useState, useEffect, useContext, Fragment } from 'react';
import PropTypes from 'prop-types';

import { SCHEDULES_FIELD } from 'helpers/selectors';
import DataTable from 'components/DataTable/DataTableContainer';

import { ScheduleCustomRow, StyledLoading } from './components';
import { AddAutomatedOperation } from '../../..';
import { TASK_SCHEDULER_MODE } from '../../../AddAutomatedOperation/constants';
import ProjectSuiteContext from '../../../ProjectSuiteContext/ProjectSuiteContext';

const labels = [
  { text: 'Name', field: 'name', width: 8 },
  { text: 'Description', field: 'description', width: 6 },
  { text: '', field: '', width: 2 },
];

const SchedulesTable = ({
  automatedOperationId,
  schedulesList,
}) => {
  const [disableDelete, setDisableDelete] = useState(false);
  const [schedulesListLength, setSchedulesListLength] = useState(0);
  const isMultiSite = useContext(ProjectSuiteContext);
  const resourceType = isMultiSite ? 'projectSuite' : 'project';

  const toggleDeleteButtons = () => {
    setDisableDelete(!disableDelete);
  };

  useEffect(() => {
    if (Array.isArray(schedulesList)) {
      const { length: nextLength } = schedulesList;

      if (nextLength !== schedulesListLength) {
        setDisableDelete(false);
        setSchedulesListLength(nextLength);
      }
    }
  }, [schedulesList, schedulesListLength]);

  return (
    <Fragment>
      {schedulesList
        ? (
          <DataTable
            data={schedulesList}
            header="Schedules"
            labels={labels}
            createElement={(
              <AddAutomatedOperation
                resourceType={resourceType}
                mode={TASK_SCHEDULER_MODE.ADD_SCHEDULER}
              />
            )}
            customTableRow={ScheduleCustomRow}
            pagination={{
              type: SCHEDULES_FIELD,
              included: true,
            }}
            automatedOperationId={automatedOperationId}
            disableDelete={disableDelete}
            toggleDeleteButtons={toggleDeleteButtons}
          />
        ) : <StyledLoading>Loading</StyledLoading>
      }
    </Fragment>
  );
};

SchedulesTable.defaultProps = {
  schedulesList: null,
};

SchedulesTable.propTypes = {
  automatedOperationId: PropTypes.string.isRequired,
  schedulesList: PropTypes.array,
};

export default SchedulesTable;
