import React from 'react';
import PropTypes from 'prop-types';

import { PROJECT_SUITES_FIELD } from 'helpers/selectors';
import DataTable from 'components/DataTable/DataTableContainer';
import { StyledProjectsTableWrapper } from './ProjectSuitesTable.style';
import ProjectSuitesRow from './ProjectSuitesRow/ProjectSuitesRowContainer';

const projectSuitesTableLabels = [
  { text: 'Multi-site', field: 'name', sortBy: 'name' },
];

const ProjectSuitesTable = ({ selectingProjectSuites }) => {
  if (!selectingProjectSuites) {
    return null;
  }

  return (
    <StyledProjectsTableWrapper>
      <DataTable
        header="Multi-sites"
        labels={projectSuitesTableLabels}
        customTableRow={ProjectSuitesRow}
        pagination={{
          type: PROJECT_SUITES_FIELD,
          args: { self: true },
        }}
      />
    </StyledProjectsTableWrapper>
  );
};

ProjectSuitesTable.propTypes = {
  selectingProjectSuites: PropTypes.bool.isRequired,
};

export default ProjectSuitesTable;
