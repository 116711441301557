import { connect } from 'react-redux';

import uploadsActionCreators from 'components/UploadsWidget/uploadsActionCreators';
import UploadsModal from './UploadsModal';

const mapDispatchToProps = {
  onClearCompleted: uploadsActionCreators.clearCompleted,
};

export default connect(null, mapDispatchToProps)(UploadsModal);
