import { call, put } from 'redux-saga/effects';

import { postPath } from 'data/api/requests';
import { actionCreators } from '..';

function* linkFileWithDeviceFirmware(action) {
  const {
    fileFirmwareId,
    endpoint,
  } = action.payload;

  const body = {
    data: [
      {
        type: 'device_firmware_files',
        id: fileFirmwareId,
      },
    ],
  };

  const { response, error } = yield call(postPath, endpoint, body);

  if (response) {
    yield put(actionCreators.linkFileWithDeviceFirmwareSuccess());
  }

  if (error) {
    yield put(actionCreators.linkFileWithDeviceFirmwareFailure());
  }
}

export default linkFileWithDeviceFirmware;
