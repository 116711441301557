import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Range, Direction } from 'react-range';
import isNumber from 'lodash/isNumber';

import { StyledBarWrapper } from '../../ColorUI.style';
import { STEP, MIN, MAX } from '../../constants';
import { RenderThumb } from '../index';
import { RenderTemperatureTrack } from './components';

const TemperatureSlider = ({
  changeFieldValue,
  temperature,
  actionTypeDeviceIdKey,
}) => {
  const [values, setValues] = useState([temperature]);

  useEffect(() => {
    if (isNumber(temperature) && temperature <= MAX && temperature >= MIN) {
      setValues([temperature]);
    }
  }, [temperature]);

  return (
    <StyledBarWrapper>
      <Range
        direction={Direction.Up}
        values={values}
        step={STEP}
        min={MIN}
        max={MAX}
        onChange={slideValue => {
          setValues(slideValue);
        }}
        onFinalChange={slideValue => changeFieldValue(`temperature${actionTypeDeviceIdKey}`, slideValue[0])}
        renderTrack={({ props, children }) => (
          <RenderTemperatureTrack props={props}>
            {children}
          </RenderTemperatureTrack>
        )}
        renderThumb={({ props }) => (
          <RenderThumb props={props} key={0} />
        )}
      />
    </StyledBarWrapper>
  );
};

TemperatureSlider.defaultProps = {
  changeFieldValue: () => null,
  actionTypeDeviceIdKey: '',
};

TemperatureSlider.propTypes = {
  changeFieldValue: PropTypes.func,
  temperature: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  actionTypeDeviceIdKey: PropTypes.string,
};

export default TemperatureSlider;
