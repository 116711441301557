import { call } from 'redux-saga/effects';

import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';

import { actionCreators } from '..';

function* loadVoucherPermissions(action) {
  const { userId, endpoint } = action.payload;

  const urlParams = {
    'recipientIds[]': userId,
    recipientType: 'user',
    resourceType: 'tenant',
  };

  const { response, error } = yield call(getPath, endpoint, { urlParams });

  const params = {
    response,
    error,
    successDisp: actionCreators.loadVoucherPermissionsSuccess,
    failDisp: actionCreators.loadVoucherPermissionsFailure,
  };

  yield call(processCall, params);
}

export default loadVoucherPermissions;
