import { reduxForm } from 'redux-form';

import formNames from 'components/form/formNames';
import { validateOnSubmit } from 'components/form/formConfigs';
import AddOriginModalForm from './AddOriginModalForm';

export default reduxForm({
  form: formNames.addNewOrigin,
  ...validateOnSubmit,
})(AddOriginModalForm);
