import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/atomic';
import ConfirmModal from 'components/elements/ConfirmModal/ConfirmModal';
import DataTableActionButtonLink from 'components/DataTable/DataTableActionButtonLink/DataTableActionButtonLink';

function DeleteProjectSuiteActionLink({ onDeleteProjectSuite, item }) {
  const { name } = item;

  const handleConfirm = useCallback(() => {
    onDeleteProjectSuite(item);
  }, [onDeleteProjectSuite, item]);

  return (
    <ConfirmModal
      trigger={<DataTableActionButtonLink title="Delete" />}
      cancelButton="Cancel"
      confirmButton={<Button negative primary={false}>Delete Multi-site</Button>}
      content={(
        <div>
          <p>
            Are you sure you want to delete Multi-site <strong>{name}</strong>?
            This action cannot be undone.
          </p>
          <p>
            Enter the name of the Multi-site to confirm:
          </p>
        </div>
      )}
      header="Confirm Multi-site deletion"
      onConfirm={handleConfirm}
      validationString={name}
      validationMessage="Enter the name of the Multi-site to be deleted"
    />
  );
}

DeleteProjectSuiteActionLink.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onDeleteProjectSuite: PropTypes.func.isRequired,
};

export default DeleteProjectSuiteActionLink;
