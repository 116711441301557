import constants from 'dispatcherConst';
import mapValues from 'lodash/mapValues';

export const initialState = {};

const resourceUpdateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.RESOURCE_UPDATE_REQUEST_V2: {
      return {
        ...state,
        [payload.chainedLoaderId]: {
          ...payload,
          timeoutCounter: 0,
          refreshing: true,
        },
      };
    }
    case constants.RESOURCE_UPDATE_REQUEST_SUCCESS_V2: {
      return {
        ...state,
        [payload.chainedLoaderId]: {
          ...state[payload.chainedLoaderId],
          requestId: payload.response.data.id,
          timeoutCounter: 0,
        },
      };
    }
    case constants.RESOURCE_UPDATE_START_V2: {
      return {
        ...state,
        [payload.chainedLoaderId]: {
          ...state[payload.chainedLoaderId],
          refreshing: true,
        },
      };
    }
    case constants.RESOURCE_UPDATE_LOADER_FETCH_DONE_V2: {
      const { updateLoaderId } = payload;
      return mapValues(state, loader => ({
        ...loader,
        timeoutCounter:
          (loader.updateLoader === updateLoaderId)
            ? loader.timeoutCounter + 1
            : loader.timeoutCounter,
      }));
    }
    case constants.RESOURCE_UPDATE_FAILED_V2:
    case constants.RESOURCE_UPDATE_STOP_V2:
    case constants.RESOURCE_UPDATE_SUCCESS_V2: {
      const { [payload.chainedLoaderId]: _, ...rest } = state;
      return rest;
    }
    default:
      return state;
  }
};

export default resourceUpdateReducer;
