import React, { Fragment } from 'react';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { DateTime } from 'luxon';
import { Popup, Icon, Grid } from 'semantic-ui-react';
import { FaInfo } from 'react-icons/fa';

import { Reason, RowIconWrapper, RenderDispatchedIcon } from './components';
import { getIconTooltipMessage, checkSuccess, checkFailure } from './utilities';
import { reasonNames } from './constants';

class OccurrenceModel {

  constructor(occurrence = {}) {
    this.id = occurrence.id;
    this.offset = occurrence.offset;
    this.timezoneId = occurrence.timezoneId;
    this.serverDatetime = occurrence.dateTime;
    this.automatedOperationId = occurrence.automatedOperationId;
    this.aoName = occurrence.aoName;
    this.aoColour = occurrence.aoColour;
    this.aoEnabled = occurrence.aoEnabled;
    this.createdAt = DateTime.fromISO(occurrence.createdAt);
    this.siteId = occurrence.siteId;
    this.projectSuiteId = occurrence.projectSuiteId;
    this.dispatched = occurrence.dispatched;
    this.updatedAt = DateTime.fromISO(occurrence.updatedAt);
    this.extra = occurrence.extra;
    this.status = occurrence.status;
    this.projectId = occurrence.projectId;
    this.projectSuiteId = occurrence.projectSuiteId;
  }

  get datetime() {
    if (isUndefined(this.serverDatetime)) {
      return null;
    }

    return DateTime.fromISO(this.serverDatetime).setZone(this.timezoneId);
  }

  get formattedTime() {
    if (!this.datetime) {
      return null;
    }

    return this.datetime.toFormat('HH:mm:ss');
  }

  get formattedReason() {
    if (!this.extra || isEmpty(this.extra)) {
      return null;
    }

    if (this.extra.length === 1) {
      const item = this.extra[0];

      return <Reason {...item} timezoneId={this.timezoneId} />;
    }

    const content = (
      <Grid>
        {this.extra.map(item => (
          <Grid.Row key={item.reason}>
            <Reason {...item} timezoneId={this.timezoneId} multiple />
          </Grid.Row>
        ))}
      </Grid>
    );

    return (
      <Popup
        trigger={(
          <RowIconWrapper>
            <FaInfo />
          </RowIconWrapper>
        )}
        content={content}
      />
    );
  }

  get timeDisplay() {
    return (
      <Fragment>
        {this.formattedTime}
        {this.formattedReason}
      </Fragment>
    );
  }

  get isPast() {
    const { timezoneId, datetime } = this;

    if (!timezoneId) {
      return null;
    }

    const siteTime = DateTime.local().setZone(timezoneId);

    return siteTime > datetime;
  }

  get isOverridden() {
    if (!this.extra || isEmpty(this.extra)) {
      return false;
    }

    if (this.extra.length === 1) {
      const item = this.extra[0];

      return item.reason === reasonNames.OVERRIDDEN_BY_PRIORITY;
    }

    const reasons = this.extra.map(item => item.reason);

    return reasons.includes(reasonNames.OVERRIDDEN_BY_PRIORITY);
  }

  get statusIcon() {
    const {
      dispatched,
      isPast,
      aoEnabled,
      status,
    } = this;

    if (isNull(isPast)) {
      return null;
    }

    if (isPast) {
      if (isNil(status)) {
        return <RenderDispatchedIcon dispatched={dispatched} />;
      }

      const message = getIconTooltipMessage(status);

      if (checkSuccess(status)) {
        const trigger = <Icon name="check" />;

        return <Popup trigger={trigger} content={message} position="top center" />;
      }

      if (checkFailure(status)) {
        const trigger = <Icon name="remove" />;

        return <Popup trigger={trigger} content={message} position="top center" />;
      }

      return null;
    }

    if (!aoEnabled) {
      const trigger = <Icon name="exclamation triangle" />;
      const content = `The scheduler ${this.aoName} has been disabled.`;

      return <Popup trigger={trigger} content={content} position="top center" />;
    }

    return null;
  }

  get style() {
    const { aoColour, aoEnabled } = this;
    const enabled = this.isPast || aoEnabled;

    return {
      color: aoColour,
      enabled,
    };
  }

  get isMultiSite() {
    const trigger = <Icon name="sitemap" />;
    const content = 'Event from a connected Multi-site.';

    if (this.projectSuiteId) {
      return <Popup trigger={trigger} content={content} position="top center" />;
    }

    return null;
  }

}

export default OccurrenceModel;
