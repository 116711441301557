import React, { Component, createRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import times from 'lodash/times';
import constant from 'lodash/constant';
import isUndefined from 'lodash/isUndefined';

import { keyCodes } from 'helpers/sharedVariables';
import {
  ArrowIcon,
  NumberInputActions,
  NumberInputButton,
  NumberInputContainer,
  NumberInputElement,
  NumberInputWrapper,
} from './NumberInput.style';

class NumberInput extends Component {

  static extractParts(props) {
    const { separator, step, value, offset } = props;
    if (!separator) return [value];

    const result = [];
    step.reduce((acc, singleStep) => {
      const singleValue = Math.floor(acc / singleStep);
      result.push(singleValue);

      return acc % singleStep;
    }, value + offset);

    return result;
  }

  static pad(value, max) {
    if (!max) return value;

    const charCount = String(max).length;
    const charArray = times(charCount, constant(0)).join('');

    return `${charArray}${value}`.slice(-charCount);
  }

  static preventFocus(e) {
    e.preventDefault();
  }

  constructor() {
    super();

    this.lastInput = createRef();
    this.state = {
      cachedValue: null,
      focused: null,
      parts: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleValueDown = this.handleValueDown.bind(this);
    this.handleValueUp = this.handleValueUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { cachedValue } = prevState;
    const { value } = nextProps;

    if (value === cachedValue) return null;

    return {
      cachedValue: value,
      parts: NumberInput.extractParts(nextProps),
    };
  }

  componentDidMount() {
    const { onChange, value } = this.props;

    onChange(value);
  }

  handleChange(e) {
    const { target: { value, dataset: { index } } } = e;

    this.performUpdate(value, index);
  }

  handleFocus(e) {
    const { target: { dataset: { index } } } = e;

    this.setState({ focused: index });
  }

  handleBlur() {
    this.setState({ focused: null });
  }

  handleButtonClick(difference) {
    const { focused, parts } = this.state;
    if (focused === null) {
      this.lastInput.current.focus();
    }

    const index = focused !== null ? focused : (parts.length - 1);
    const nextValue = parts[index] + difference;

    this.performUpdate(nextValue, index);
  }

  handleValueUp() {
    this.handleButtonClick(1);
  }

  handleValueDown() {
    this.handleButtonClick(-1);
  }

  handleKeyDown(event) {
    if (event.keyCode === keyCodes.UP) {
      event.preventDefault();
      this.handleButtonClick(1);
    } else if (event.keyCode === keyCodes.DOWN) {
      event.preventDefault();
      this.handleButtonClick(-1);
    }
  }

  performUpdate(nextPartString, index) {
    const { parts } = this.state;
    const {
      step: stepList,
      min: minList,
      max: maxList,
      value,
      onChange,
    } = this.props;

    const nextPart = Number(nextPartString);

    const min = minList[index];
    const max = maxList[index];
    if (!isUndefined(min) && nextPart < min) return;
    if (!isUndefined(max) && nextPart > max) return;

    const prevPart = parts[index];
    const step = stepList[index] || 1;

    onChange(value + (nextPart - prevPart) * step);
  }

  render() {
    const { focused, parts } = this.state;
    const { id, separator, min, max, className, disabled } = this.props;
    const isActive = focused !== null;

    return (
      <NumberInputWrapper className={className} disabled={disabled}>
        <NumberInputContainer active={isActive} disabled={disabled}>
          {parts.map((part, index) => {
            const subId = `${id}${index}`;
            const paddedPart = NumberInput.pad(part, max[index]);

            const isNotFirst = index > 0;
            const isLast = index + 1 === parts.length;

            return (
              <Fragment key={subId}>
                {(separator && isNotFirst) && <span>{separator}</span>}
                <NumberInputElement
                  data-index={index}
                  id={subId}
                  type="text"
                  value={paddedPart}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  onFocus={this.handleFocus}
                  onKeyDown={this.handleKeyDown}
                  max={max[index]}
                  min={min[index]}
                  ref={isLast ? this.lastInput : null}
                />
              </Fragment>
            );
          })}
          <NumberInputActions>
            <NumberInputButton
              type="button"
              onClick={this.handleValueUp}
              onMouseDown={NumberInput.preventFocus}
            >
              <ArrowIcon name="keyboard_arrow_up" />
            </NumberInputButton>
            <NumberInputButton
              type="button"
              onClick={this.handleValueDown}
              onMouseDown={NumberInput.preventFocus}
            >
              <ArrowIcon name="keyboard_arrow_down" />
            </NumberInputButton>
          </NumberInputActions>
        </NumberInputContainer>
      </NumberInputWrapper>
    );
  }

}

NumberInput.defaultProps = {
  disabled: false,
  className: '',
  separator: null,
  value: 0,
  min: [],
  max: [],
  step: [],
  offset: 0,
};

NumberInput.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  separator: PropTypes.string,
  value: PropTypes.number,
  min: PropTypes.arrayOf(PropTypes.number),
  max: PropTypes.arrayOf(PropTypes.number),
  step: PropTypes.arrayOf(PropTypes.number),
  // eslint-disable-next-line react/no-unused-prop-types
  offset: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default NumberInput;
