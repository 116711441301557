import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { renderLabel } from './utilities';
import { RenderParamValue } from './components';
import { StyledLabel } from './ReadOnlyActionBody.style';

function ReadOnlyActionBody({ actionId, params, projectId }) {
  if (isEmpty(params)) {
    return (
      <div>
        This action has no params.
      </div>
    );
  }

  return (
    <div>
      {params.map(param => (
        <div key={`${actionId}-${param.key}`}>
          <StyledLabel>{renderLabel(param)}</StyledLabel>
          <div className="read-only-values">
            <RenderParamValue param={param} projectId={projectId} />
          </div>
        </div>
      ))}
    </div>
  );
}

ReadOnlyActionBody.defaultProps = {
  projectId: undefined,
};

ReadOnlyActionBody.propTypes = {
  actionId: PropTypes.string.isRequired,
  params: PropTypes.array.isRequired,
  projectId: PropTypes.string,
};

export default ReadOnlyActionBody;
