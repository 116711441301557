import styled from 'styled-components';
import { media } from 'themes/mixins';

const Message = styled.article`
  ${media.sm`margin-top: 50px;`}
`;

Message.H1 = styled.h1`
  font-size: 8em;
`;

Message.H2 = styled.h2`
  font-size: 2em;
  font-weight: 400;
  margin: 70px 0;
`;

Message.Text = styled.p`
  font-size: 1.3em;
`;

export default Message;
