import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { PROJECT_SUITES_FIELD } from 'helpers/selectors';
import { addPagination } from 'helpers/paginationHelpers';
import { permissionsOrNotFound, frontendPermissions } from 'gateway';
import { actionCreators as copyPasteActionCreators } from 'application/tenant/components/structural/ControlUI/components/ControlUIPageCarousel/components/ControlUIPage/components/ControlUIButtonGroup/ControlUIButton/components/utilities';

import { actionCreators } from './utilities';
import ProjectSuites from './ProjectSuites';

export const mapStateToProps = () => ({
  route: '/multi-sites',
});

export default compose(
  withRouter,
  connect(mapStateToProps),
  permissionsOrNotFound(frontendPermissions.TENANT_MANAGER_SITES),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: PROJECT_SUITES_FIELD },
      () => actionCreators.fetchAdminProjectSuites(
        {
          fields: { projectSuites: ['name', 'usersCount', 'createdAt'] },
          sort: 'name',
          filter: 'name',
        },
      ),
    ),
  ),
  chainedLoaderWithSaga(() => copyPasteActionCreators.clearCopy),
)(ProjectSuites);
