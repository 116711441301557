import { call } from 'redux-saga/effects';

import { postPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';

import actionCreators from '../actionCreators';

function* archiveAllNotificationEvents(action) {
  const { endpoint, ...rest } = action.payload;

  const { response = {}, error } = yield call(postPath, endpoint);

  const params = {
    response: {
      ...response,
    },
    error,
    successDisp: actionCreators.archiveAllNotificationEventsSuccess,
    failDisp: actionCreators.archiveAllNotificationEventsFailure,
    ...rest,
  };

  yield call(processCall, params);
}

export default archiveAllNotificationEvents;
