export const lang = 'en';

export const translationLabels = {
  beacon: {
    en: 'Beacon',
  },
  enabled: {
    en: 'Choose if the setting should be enabled or not',
  },
  fire_snapshot: {
    en: 'Fire Snapshot',
  },
  snapshot_id: {
    en: 'Select a Snapshot to be fired',
  },
  format: {
    en: 'Format',
  },
  interface_poe_power_cycle: {
    en: 'Interface poe power cycle',
  },
  port_dmx_status: {
    en: 'Port DMX Status',
  },
  dmx_status: {
    en: 'DMX Status',
  },
  dmx_port_id: {
    en: 'DMX Port Id',
  },
  rdm_discover: {
    en: 'RDM Discover',
  },
  reset: {
    en: 'Reset',
  },
  set_zone_state: {
    en: 'Set Zone State',
  },
  zone_id: {
    en: 'Zone Id',
  },
  simple_triggers: {
    en: 'Select Triggers',
  },
  triggers: {
    en: 'Triggers',
  },
  trigger_ids: {
    en: 'Trigger Ids',
  },
  timeline_ids: {
    en: 'Timeline Ids',
  },
  scene_ids: {
    en: 'Scenes Ids',
  },
  timeline_id: {
    en: 'Timeline id',
  },
  trigger_ids_tip: {
    en: '12-14, 74, 2, 9, 7-10 will result in [2,7,8,9,10,12,13,14,74]',
  },
  port_id: {
    en: 'Port Id',
  },
  multi_dropdown_tip: {
    en: 'Choose one or more options',
  },
  test_conditions: {
    en: 'Test conditions',
  },
  release_secs: {
    en: 'Release time in seconds',
  },
  pause_all_timelines: {
    en: 'Pause All Timelines',
  },
  resume_all_timelines: {
    en: 'Resume All Timelines',
  },
  start_timelines: {
    en: 'Start Timelines',
  },
  pause_timelines: {
    en: 'Pause Timelines',
  },
  resume_timelines: {
    en: 'Resume Timelines',
  },
  release_timelines: {
    en: 'Release Timelines',
  },
  start_scenes: {
    en: 'Start Scenes',
  },
  toggle_timeline: {
    en: 'Toggle Timeline',
  },
  toggle_timelines: {
    en: 'Toggle Timelines',
  },
  toggle_scenes: {
    en: 'Toggle Scenes',
  },
  release_scenes: {
    en: 'Release Scenes',
  },
  release_all: {
    en: 'Release All',
  },
  set_groups_intensity: {
    en: 'Master Intensity',
  },
  set_timelines_rate: {
    en: 'Set Timelines Rate',
  },
  rate: {
    en: 'Rate (%)',
  },
  set_timelines_position: {
    en: 'Set Timeline Position',
  },
  position: {
    en: 'Position (%)',
  },
  increase_groups_intensity: {
    en: 'Increase Groups Intensity',
  },
  filter_by_groups: {
    en: 'Filter by Groups',
  },
  step: {
    en: 'Step',
  },
  intensity: {
    en: 'Intensity',
  },
  fade_secs: {
    en: 'Fade time in seconds',
  },
  delay_secs: {
    en: 'Delay time in seconds',
  },
  decrease_groups_intensity: {
    en: 'Decrease Groups intensity',
  },
  set_content_target_intensity: {
    en: 'Set content target intensity',
  },
  set_content_target_intensity_filtered: {
    en: 'Set content target intensity',
  },
  increase_content_target_intensity: {
    en: 'increase_content_target_intensity',
  },
  decrease_content_target_intensity: {
    en: 'Increase content target intensity',
  },
  decrease_content_target_intensity_filtered: {
    en: 'Increase content target intensity',
  },
  filter_by_content_target: {
    en: 'Filter by content target',
  },
  content_target: {
    en: 'Content target',
  },
  set_rgb_override: {
    en: 'Override RGB ',
  },
  clear_rgb_override: {
    en: 'Clear RGB Override',
  },
  filter_by_type: {
    en: 'Filter by type',
  },
  filter_by_group: {
    en: 'Filter by Group',
  },
  off: {
    en: 'Off',
  },
  only_group: {
    en: 'Only Group',
  },
  except_group: {
    en: 'Except Group',
  },
  playback_group: {
    en: 'Playback Group',
  },
  group_ids: {
    en: 'Groups',
  },
  target: {
    en: 'Target',
  },
  path: {
    en: 'Path',
  },
  release_others: {
    en: 'Release others',
  },
  fixtureIds: {
    en: 'Fixture ids',
  },
  fixture_ids: {
    en: 'Fixture ids',
  },
  red: {
    en: 'Red value in percentage',
  },
  green: {
    en: 'Green value in percentage',
  },
  blue: {
    en: 'Blue value in percentage',
  },
  temperature: {
    en: 'Temperature value in percentage',
  },
  path_dmx_status: {
    en: 'Path DMX Status',
  },
  dmx_path_id: {
    en: 'DMX Path Id',
  },
  release_milli_secs: {
    en: 'Release time',
  },
  fade_milli_secs: {
    en: 'Fade time in seconds',
  },
  delay_milli_secs: {
    en: 'Delay time in seconds',
  },
  enable_intensity: {
    en: 'Override Intensity',
  },
  enable_red: {
    en: 'Override Red',
  },
  enable_green: {
    en: 'Override Green',
  },
  enable_blue: {
    en: 'Override Blue',
  },
  enable_temperature: {
    en: 'Override Temperature',
  },
  interface_id: {
    en: 'Interface id',
  },
  decrease_content_target_intensity_v1: {
    en: 'Decrease content target intesity',
  },
  decrease_groups_intensity_v1: {
    en: 'Decrease Groups intensity',
  },
  increase_content_target_intensity_filtered_v1: {
    en: 'Increase content target intensity',
  },
  grant_execute_permission: {
    en: 'Enable Multi-site device actions',
  },
  revoke_execute_permission: {
    en: 'Disable Multi-site device actions',
  },
  resourceId: {
    en: 'Resource',
  },
  recipientId: {
    en: 'Multi-site recipient',
  },
  resource_id: {
    en: 'Resource',
  },
  recipient_id: {
    en: 'Multi-site recipient',
  },
};

export const invalidAction = 'This action is invalid and will not run. This may be because the device has been replaced with different hardware or the device firmware has changed and no longer supports this action.';
