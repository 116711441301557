import { call } from 'redux-saga/effects';

import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import {
  fetchBackendVersionSuccess,
  fetchBackendVersionFailure,
} from '../actionCreators/fetchBackendVersion';

function* fetchBackendVersion(action) {
  const { endpoint } = action.payload;

  const { response, error } = yield call(getPath, endpoint);

  const params = {
    response,
    error,
    successDisp: fetchBackendVersionSuccess,
    failDisp: fetchBackendVersionFailure,
  };

  yield call(processCall, params);
}

export default fetchBackendVersion;
