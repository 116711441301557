import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormGroup, Grid, Dropdown, Label } from 'semantic-ui-react';

import { uploadFileType, fileTypesMapType, systemFilesType } from 'data/types';
import { validCompanyName, sameFile, fileSelectedAndNotEmpty, required, requiredDate } from 'components/form/formValidators';
import DropdownField from 'components/form/DropdownField/DropdownField';
import DropzoneField from 'components/form/DropzoneField/DropzoneFieldContainer';
import ModalForm from 'components/form/ModalForm/ModalFormV2';
import TextField from 'components/form/TextField/TextField';
import TextAreaField from 'components/form/TextAreaField/TextAreaField';
import DateField from 'components/form/DateField/DateFieldWithFeedback';
import { firmwareFormFields } from './constants';
import { StyledLabel, StyledDiv } from './AddDeviceFirmwareModalForm.style';
import { checkIfFileExists, getFileName } from './utilities';

const AddDeviceFirmwareModalForm = ({
  manufacturersOptions,
  deviceTypeOptions,
  resetFieldValue,
  changeFieldValue,
  handleFirmwareFileStatusChange,
  ...props
}) => {
  const addAction = 'Add device firmware';
  const accept = '.app';
  const { submitting, resume, fileTypes, files } = props;
  const fileExists = checkIfFileExists(files);
  const fileName = getFileName(files);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const onDropdownChange = (e, { value }) => {
    setSelectedOptions(value);
    changeFieldValue(firmwareFormFields.deviceModels, value);
  };

  const clearDropdown = () => {
    setSelectedOptions([]);
  };

  const changeManufacturer = () => {
    clearDropdown();
    resetFieldValue(firmwareFormFields.deviceModels);
  };

  return (
    <ModalForm
      header={addAction}
      trigger="Add Device Firmware"
      size="small"
      triggerSize="mini"
      {...props}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} computer={16}>
            <FormGroup inline>
              <Field
                name={firmwareFormFields.name}
                component={TextField}
                type="text"
                loading={submitting}
                label="Name"
                validate={validCompanyName}
              />
            </FormGroup>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} computer={16}>
            <FormGroup>
              <StyledDiv>
                <StyledLabel htmlFor="input">Manufacturer</StyledLabel>
                <Field
                  name={firmwareFormFields.manufacturer}
                  placeholder="Select manufacturer"
                  component={DropdownField}
                  options={manufacturersOptions}
                  maxCount={12}
                  validate={validCompanyName}
                  onChange={changeManufacturer}
                  displayPlaceholder
                />
              </StyledDiv>
            </FormGroup>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} computer={16}>
            <FormGroup>
              <StyledDiv>
                <StyledLabel htmlFor="input">Device model</StyledLabel>
                <Dropdown
                  name={firmwareFormFields.deviceModels}
                  placeholder="Select device model"
                  options={deviceTypeOptions}
                  onChange={onDropdownChange}
                  value={selectedOptions}
                  multiple
                  selection
                  fluid
                  clearable
                />
              </StyledDiv>
            </FormGroup>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} computer={16}>
            <FormGroup inline>
              <Field
                name={firmwareFormFields.description}
                component={TextAreaField}
                type="text"
                loading={submitting}
                label="Compatibility notes"
              />
            </FormGroup>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} computer={16}>
            <FormGroup inline>
              <Field
                name={firmwareFormFields.version}
                component={TextField}
                type="text"
                loading={submitting}
                label="Version"
                validate={[required]}
              />
            </FormGroup>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} computer={16}>
            <FormGroup>
              <StyledDiv>
                <StyledLabel htmlFor="input">Date released</StyledLabel>
                <Field
                  name={firmwareFormFields.dateReleased}
                  placeholder="Select date"
                  label="Date released"
                  component={DateField}
                  footer={false}
                  validate={[requiredDate]}
                  dropUp
                />
              </StyledDiv>
            </FormGroup>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} computer={16}>
            <FormGroup inline>
              <Field
                name={firmwareFormFields.files}
                component={DropzoneField}
                loading={submitting}
                validate={[fileSelectedAndNotEmpty, sameFile]}
                multiple={false}
                accept={accept}
                resume={resume}
                onChangeFileStatus={handleFirmwareFileStatusChange}
                fileTypes={fileTypes}
              />
            </FormGroup>
          </Grid.Column>
        </Grid.Row>
        {fileExists && (
          <Grid.Row>
            <Grid.Column mobile={16} computer={16}>
              <Label.Group>
                <Label
                  htmlFor="file-upload-name"
                  as="a"
                  size="large"
                >
                  {fileName}
                </Label>
              </Label.Group>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column mobile={16} computer={16}>
            <FormGroup inline>
              <Field
                name={firmwareFormFields.fileDescription}
                component={TextField}
                type="text"
                loading={submitting}
                label="File description"
              />
            </FormGroup>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ModalForm>
  );
};

AddDeviceFirmwareModalForm.defaultProps = {
  manufacturersOptions: [],
  deviceTypeOptions: [],
  resetFieldValue: () => null,
  changeFieldValue: () => null,
  accept: '',
  resume: null,
  fileTypes: null,
  files: [],
  handleFirmwareFileStatusChange: () => null,
};

AddDeviceFirmwareModalForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  manufacturersOptions: PropTypes.array,
  deviceTypeOptions: PropTypes.array,
  resetFieldValue: PropTypes.func,
  changeFieldValue: PropTypes.func,
  accept: PropTypes.string,
  resume: uploadFileType,
  fileTypes: fileTypesMapType,
  files: systemFilesType,
  handleFirmwareFileStatusChange: PropTypes.func,
};

export default AddDeviceFirmwareModalForm;
