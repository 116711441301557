import constants from 'dispatcherConst';
import { initialFileOpts, mergeFileOpts, resumedFileOpts } from './uploadsReducerHelpers';

const initialState = {};

const uploadsFilesReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case constants.SET_UPLOAD_FETCH_STATUS: {
      const { fetching } = payload;

      return mergeFileOpts(state, payload, {
        fetching,
      });
    }

    case constants.UPLOAD_INITIALIZE_STATE: {
      const { recentUploads } = payload;

      return { ...recentUploads };
    }

    case constants.UPLOAD_ENQUEUE_AT_START:
    case constants.UPLOAD_ENQUEUE_AT_END: {
      const { uploads } = payload;

      return uploads.reduce((acc, upload) => {
        const { fileKey } = upload;
        const { [fileKey]: fileOpts } = acc;

        if (!fileOpts) {
          return mergeFileOpts(acc, upload, {
            ...initialFileOpts(upload),
            initialized: false,
            queued: true,
          });
        }

        return mergeFileOpts(acc, upload, {
          ...resumedFileOpts(upload, fileOpts),
          queued: true,
        });
      }, state);
    }

    case constants.UPLOAD_START_SUCCESS:
      return mergeFileOpts(state, payload, {
        initialized: true,
        queued: false,
        resuming: false,
      });

    case constants.UPLOAD_PROGRESS_UPDATE: {
      const { progress, secondsLeft } = payload;

      if (progress === 0 || secondsLeft === -1) return state;

      return mergeFileOpts(state, payload);
    }

    // Pause
    case constants.UPLOAD_PAUSE_REQUEST:
      return mergeFileOpts(state, payload, {
        pausing: true,
      });

    case constants.UPLOAD_PAUSE_SUCCESS:
      return mergeFileOpts(state, payload, {
        pausing: false,
        paused: true,
        queued: false,
      });

    case constants.UPLOAD_PAUSE_FAILED:
      return mergeFileOpts(state, payload, {
        pausing: false,
      });

    // Resume
    case constants.UPLOAD_RESUME_REQUEST:
      return mergeFileOpts(state, payload, {
        resuming: true,
      });

    case constants.UPLOAD_RESUME_SUCCESS:
      return mergeFileOpts(state, payload, {
        paused: false,
        resuming: false,
      });

    case constants.UPLOAD_RESUME_FAILED:
      return mergeFileOpts(state, payload, {
        resuming: false,
      });

    // Cancel
    case constants.UPLOAD_CANCEL_REQUEST:
      return mergeFileOpts(state, payload, {
        cancelling: true,
      });

    case constants.UPLOAD_CANCEL_SUCCESS: {
      const { fileKey } = payload;
      const { [fileKey]: deleted, ...newState } = state;

      return newState;
    }

    case constants.UPLOAD_CANCEL_FAILED:
      return mergeFileOpts(state, payload, {
        cancelling: false,
      });

    // Success
    case constants.UPLOAD_SUCCESS: {
      const { fileKey } = payload;
      const { [fileKey]: { systemFile: { size } } } = state;

      return mergeFileOpts(state, payload, {
        completed: true,
        progress: 1,
        secondsLeft: 0,
        totalUploaded: size,
      });
    }

    case constants.UPLOAD_CLEAR_COMPLETED: {
      return Object.keys(state).reduce((acc, key) => {
        const { completed } = acc[key];
        if (!completed) return acc;

        const { [key]: deleted, ...newState } = acc;

        return newState;
      }, state);
    }

    default:
      return state;
  }
};

export default uploadsFilesReducer;
