import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { IoMailOpenOutline, IoArchiveOutline } from 'react-icons/io5';

import {
  Container,
  Icon,
  FlexContainer,
  Title,
  Content,
  Description,
  Time,
  Option,
  ActionsContainer,
  Wrapper,
} from './NotificationItem.style';

function NotificationItem({
  id,
  message,
  notificationType,
  readAt,
  occurredAt,
  notifiable,
  onReadNotification,
  onArchiveNotification,
}) {
  const time = useMemo(() => {
    if (!occurredAt) {
      return '';
    }

    return DateTime.fromISO(occurredAt).toRelative();
  }, [occurredAt]);

  const title = useMemo(() => {
    if (!notifiable) {
      return '';
    }

    const { name } = notifiable;

    return name;
  }, [notifiable]);

  const handleMarkAsRead = useCallback(() => {
    onReadNotification(id);
  }, [id, onReadNotification]);

  const handleArchive = useCallback(() => {
    onArchiveNotification(id);
  }, [id, onArchiveNotification]);

  return (
    <Container read={readAt}>
      <Icon name="circle" type={notificationType} message={message} />
      <Wrapper>
        <Title>{title}</Title>
        <Content>
          <Description>{message}</Description>
          <FlexContainer>
            <Time>{time}</Time>
            <ActionsContainer alignRight>
              <Option name="read-button" onClick={handleMarkAsRead}>
                Read
                <IoMailOpenOutline />
              </Option>
              &nbsp;|&nbsp;
              <Option name="archive-button" onClick={handleArchive}>
                Archive
                <IoArchiveOutline />
              </Option>
            </ActionsContainer>
          </FlexContainer>
        </Content>
      </Wrapper>
    </Container>
  );
}

NotificationItem.defaultProps = {
  readAt: null,
  occurredAt: null,
  notifiable: null,
};

NotificationItem.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  readAt: PropTypes.any,
  occurredAt: PropTypes.any,
  notificationType: PropTypes.oneOf(['connection', 'error', 'warning']).isRequired,
  notifiable: PropTypes.object,
  onReadNotification: PropTypes.func.isRequired,
  onArchiveNotification: PropTypes.func.isRequired,
};

export default NotificationItem;
