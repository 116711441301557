import { compose } from 'redux';
import { connect } from 'react-redux';

import { deviceGroupsSelector } from 'helpers/selectors';
import { fetchDeviceGroups } from 'application/tenant/console/device/utilities/actionCreators/deviceGroups';
import { selectors } from 'application/tenant/console/site/components/structural/ProjectTasksTab/utilities';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';

import AvailableGroups from './AvailableGroups';

export const mapStateToProps = (state, { selectedDevice }) => {
  const { id: deviceId } = selectedDevice;

  const { groupsList = [] } = deviceGroupsSelector(state)(deviceId) || {};

  return {
    isLoading: selectors.groupsFetchingSelector(state),
    deviceId,
    selectedDevice,
    availableGroups: groupsList,
  };
};

export default compose(
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    ({ deviceId }) => fetchDeviceGroups(deviceId),
  ),
)(AvailableGroups);
