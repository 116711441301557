import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { Icon as SemanticIcon, Menu } from 'semantic-ui-react';

export const Container = styled(Menu.Item)`
  &&& {
    box-shadow: none;
    border: none;

    &.ui.vertical.menu .item {
      display: flex !important;
      justify-content: flex-start;
      align-items: center;
    }
    
    ${({ read }) => !read && css`
      background-color: #FEF7F1 !important;

      &:hover {
        background-color: ${darken(0.02, '#FEF7F1')} !important;
      }
    `}
  }
`;

export const Icon = styled(SemanticIcon)`
  margin: 0 !important;
  margin-right: 1.14286em !important;
  opacity: 0.7 !important;

  ${({ type }) => type === 'warning' && css`
    color: #FFAB00;
  `}

  ${({ type }) => type === 'error' && css`
    color: #D0021B;
  `}

  ${({ type, message }) => type === 'connection' && message.includes('disconnected') && css`
    color: #D0021B;
  `}

  ${({ type, message }) => type === 'connection' && !message.includes('disconnected') && css`
    color: #21BA45;
  `}
`;

export const FlexContainer = styled.div`
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Time = styled.small`
  font-size: 10px;
  color: rgba(0, 0, 0, 0.5);
  padding: 0;
  margin: 0;
`;

export const Title = styled.h4`
  margin-bottom: 5px;
  font-size: 14px;
  cursor: text;
  user-select: text;
`;

export const Content = styled.div`
  display: inherit;
`;

export const Description = styled.p`
  font-size: 13px;
  color: rgba(0,0,0,.6) !important;
  cursor: text;
  user-select: text;
`;

export const Option = styled.small`
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: rgba(0,0,0,.6) !important;
  
  svg {
    margin-left: 4px;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
`;
