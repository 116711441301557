import React from 'react';
import { Placeholder } from 'semantic-ui-react';

import {
  StyledTable,
  StyledTableHeaderColumn,
  StyledTableColumn,
} from './TablePlaceholder.style';

function TablePlaceholder() {

  const sizes = {
    0: 'medium',
    1: 'short',
    2: 'very short',
    3: 'short',
    4: 'medium',
    5: 'short',
    6: 'short',
    7: 'medium',
    8: 'long',
    9: 'medium',
  };

  const renderRows = () => {
    const rows = Array.from(Array(10).keys());

    return (
      <>
        {rows.map(row => (
          <tr key={row}>
            <StyledTableColumn permissionNameColumn>
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line length={sizes[row]} />
                </Placeholder.Header>
              </Placeholder>
            </StyledTableColumn>
            <StyledTableColumn recipientColumn>
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line length="long" />
                </Placeholder.Header>
              </Placeholder>
            </StyledTableColumn>
            <StyledTableColumn recipientColumn>
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line length="long" />
                </Placeholder.Header>
              </Placeholder>
            </StyledTableColumn>
            <StyledTableColumn recipientColumn>
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line length="long" />
                </Placeholder.Header>
              </Placeholder>
            </StyledTableColumn>
            <StyledTableColumn recipientColumn>
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line length="long" />
                </Placeholder.Header>
              </Placeholder>
            </StyledTableColumn>
            <StyledTableColumn recipientColumn>
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line length="long" />
                </Placeholder.Header>
              </Placeholder>
            </StyledTableColumn>
          </tr>
        ))}
      </>
    );
  };

  return (
    <StyledTable>
      <thead>
        <tr>
          <StyledTableHeaderColumn permissionNameColumn>
            <span>Column Placeholder Really Long Invisible Text</span>
          </StyledTableHeaderColumn>
          <StyledTableHeaderColumn recipientColumn>
            <span>
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line length="long" />
                </Placeholder.Header>
              </Placeholder>
            </span>
          </StyledTableHeaderColumn>
          <StyledTableHeaderColumn recipientColumn>
            <span>
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line length="long" />
                </Placeholder.Header>
              </Placeholder>
            </span>
          </StyledTableHeaderColumn>
          <StyledTableHeaderColumn recipientColumn>
            <span>
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line length="long" />
                </Placeholder.Header>
              </Placeholder>
            </span>
          </StyledTableHeaderColumn>
          <StyledTableHeaderColumn recipientColumn>
            <span>
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line length="long" />
                </Placeholder.Header>
              </Placeholder>
            </span>
          </StyledTableHeaderColumn>
          <StyledTableHeaderColumn recipientColumn>
            <span>
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line length="long" />
                </Placeholder.Header>
              </Placeholder>
            </span>
          </StyledTableHeaderColumn>
        </tr>
      </thead>
      <tbody>
        {renderRows()}
      </tbody>
    </StyledTable>
  );
}

export default TablePlaceholder;
