import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { DEVICE_FIRMWARES_FIELD } from 'helpers/selectors';

export const fetchDeviceFirmwares = urlParams => ({
  type: constants.FETCH_DEVICE_FIRMWARES_REQUEST,
  payload: {
    endpoint: endpoints.DEVICE_FIRMWARES,
    resourceType: DEVICE_FIRMWARES_FIELD,
    urlParams,
  },
});

export const fetchDeviceFirmwaresSuccess = response => ({
  type: constants.FETCH_DEVICE_FIRMWARES_SUCCESS,
  payload: {
    response,
    resourceType: DEVICE_FIRMWARES_FIELD,
  },
});

export const fetchDeviceFirmwaresFailure = error => ({
  type: constants.FETCH_DEVICE_FIRMWARES_FAILED,
  payload: { error },
});

export default fetchDeviceFirmwares;
