import React from 'react';
import PropTypes from 'prop-types';

import { DEVICE_FIRMWARES_FIELD } from 'helpers/selectors';
import { supportedTypesType } from 'data/types';
import DataTable from 'components/DataTable/DataTableContainer';
import { FirmwaresActionLinks } from './components';

const title = 'Firmware';
const labels = [
  { text: 'Version', field: 'version' },
  { text: 'Date released', field: 'dateRelease', sortable: false },
  { text: 'Compatibility notes', field: 'displayCompatibilityNotes', sortable: false },
];

const FirmwareV1 = ({ deviceId, accept, online }) => (
  <DataTable
    header={title}
    deviceId={deviceId}
    actionLinks={FirmwaresActionLinks}
    accept={accept}
    online={online}
    labels={labels}
    pagination={{
      type: DEVICE_FIRMWARES_FIELD,
      included: true,
    }}
  />
);

FirmwareV1.defaultProps = {
  accept: null,
};

FirmwareV1.propTypes = {
  deviceId: PropTypes.string.isRequired,
  online: PropTypes.bool.isRequired,
  accept: supportedTypesType,
};

export default FirmwareV1;
