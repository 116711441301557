import { compose } from 'redux';
import { connect } from 'react-redux';

import { selectors } from '../../../../utilities';
import VoucherValidation from './VoucherValidation';

export const mapStateToProps = state => ({
  selectedTenant: selectors.selectedTenantSelector(state),
});

export default compose(
  connect(mapStateToProps, null),
)(VoucherValidation);
