import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';

export const generateVoucher = ({
  tenant,
  subscriptionPlanCode,
  subscriptionLength,
  description,
  resolveForm,
  rejectForm,
}) => ({
  type: constants.GENERATE_VOUCHER_REQUEST,
  payload: {
    subscriptionPlanCode,
    subscriptionLength,
    description,
    tenant,
    endpoint: endpoints.VOUCHER_GENERATE,
    resolveForm,
    rejectForm,
  },
});

export const generateVoucherSuccess = response => ({
  type: constants.GENERATE_VOUCHER_SUCCESS,
  payload: {
    response,
    message: messages.GENERATE_VOUCHER_SUCCESS,
  },
});

export const generateVoucherFailure = error => ({
  type: constants.GENERATE_VOUCHER_FAILED,
  payload: { error },
});
