import mergeWith from 'lodash/mergeWith';

import { mergeCustomizer } from 'helpers/sharedMethods';
import getDeviceIdFromAction from './getDeviceIdFromAction';
import reduceRemoteDevicesResourceWithDeviceId from './reduceRemoteDevicesResourceWithDeviceId';

const mergeRemoteDevicesResourceToStore = (state, action, resource) => {
  const {
    [resource]: originalResource = {},
  } = action.payload.response;
  const deviceId = getDeviceIdFromAction(action);

  const remoteDevices = (
    Object
      .values(originalResource)
      .reduce(reduceRemoteDevicesResourceWithDeviceId(deviceId), {})
  );
  const resourceToMerge = { [resource]: remoteDevices };

  return mergeWith({}, state, resourceToMerge, mergeCustomizer);
};

export default mergeRemoteDevicesResourceToStore;
