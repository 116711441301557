import { put } from 'redux-saga/effects';

import {
  setFetchingTasksFilterToTrue,
  setProjectIdsToFilter,
} from '../../../ProjectSuite/ProjectSuiteTasksTab/utilities/actionCreators';

function* triggerProjectSuiteTasksFiltering(action) {
  const { resourceId, projectIds, filterParam, field, requestType } = action.payload;

  yield put(setProjectIdsToFilter(resourceId, filterParam, projectIds, field));
  yield put(setFetchingTasksFilterToTrue(
    resourceId,
    requestType,
    field,
    filterParam,
  ));
}

export default triggerProjectSuiteTasksFiltering;
