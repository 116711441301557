import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withProjectSuitePermissions, permissionsOrRedirect, frontendPermissions } from 'gateway';
import { Unauthorized } from 'application/components';
import { PROJECTS_FIELD } from 'helpers/selectors';
import { tasksActionCreators } from 'application/tenant/utilities';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';

import {
  actionCreators as projectSuiteActionCreators,
  selectors as projectSuitesSelectors,
} from 'pages/ProjectSuites/utilities';

import ProjectSuiteControlPanelTab from './ProjectSuiteControlPanelTab';

export const mapStateToProps = (state, { match }) => {
  const { id: projectSuiteId } = match.params;

  const projects = projectSuitesSelectors.projectsListSelector(state)(projectSuiteId);
  const projectsIds = projects ? projects.map(({ id }) => id) : null;

  return {
    projectSuiteId,
    projectsIds,
  };
};

export default compose(
  withRouter,
  withProjectSuitePermissions,
  connect(mapStateToProps),
  chainedLoaderWithSaga(tasksActionCreators.fetchActionTypes),
  chainedLoaderWithSaga(
    ({ projectSuiteId }) => projectSuiteActionCreators.fetchProjectSuiteProjectsFilter(
      projectSuiteId, {
        fields: {
          [PROJECTS_FIELD]: [
            'name',
          ],
        },
        sort: 'name',
      }),
  ),
  permissionsOrRedirect(frontendPermissions.PROJECT_SUITE_CONTROL_PANEL_VIEW, Unauthorized),
)(ProjectSuiteControlPanelTab);
