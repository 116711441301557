import React from 'react';

import DataTableRow from 'components/DataTable/DataTableRow/DataTableRow';
import DataTableText from 'components/DataTable/DataTableText/DataTableText';
import { LinkFileModalForm } from './components';

const FirmwareFileCustomRow = props => (
  <>
    <DataTableRow {...props}>
      <DataTableText />
      <DataTableText />
      <LinkFileModalForm />
    </DataTableRow>
  </>
);

export default FirmwareFileCustomRow;
