const WEEK_DAYS = [
  {
    key: 'day',
    value: 'day',
    text: 'Day',
    number: 0,
  },
  {
    key: 'sun',
    value: 'sun',
    text: 'Sunday',
    number: 7,
  },
  {
    key: 'mon',
    value: 'mon',
    text: 'Monday',
    number: 1,
  },
  {
    key: 'tue',
    value: 'tue',
    text: 'Tuesday',
    number: 2,
  },
  {
    key: 'wed',
    value: 'wed',
    text: 'Wednesday',
    number: 3,
  },
  {
    key: 'thu',
    value: 'thu',
    text: 'Thursday',
    number: 4,
  },
  {
    key: 'fri',
    value: 'fri',
    text: 'Friday',
    number: 5,
  },
  {
    key: 'sat',
    value: 'sat',
    text: 'Saturday',
    number: 6,
  },
];

const MONTHS = [
  {
    key: 1,
    value: 'January',
  },
  {
    key: 2,
    value: 'February',
  },
  {
    key: 3,
    value: 'March',
  },
  {
    key: 4,
    value: 'April',
  },
  {
    key: 5,
    value: 'May',
  },
  {
    key: 6,
    value: 'June',
  },
  {
    key: 7,
    value: 'July',
  },
  {
    key: 8,
    value: 'August',
  },
  {
    key: 9,
    value: 'September',
  },
  {
    key: 10,
    value: 'October',
  },
  {
    key: 11,
    value: 'November',
  },
  {
    key: 12,
    value: 'December',
  },
];

export {
  WEEK_DAYS,
  MONTHS,
};
