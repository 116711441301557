import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import { AVAILABLE_PAGE_SIZES } from 'helpers/paginationHelpers';
import {
  PageNumberControls,
  PageSizeControl,
  PaginationControlsWrapper,
} from './PaginationControls.style';

class PaginationControls extends Component {

  constructor() {
    super();

    this.removeUnnecessary = this.removeUnnecessary.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    const { total, number } = this.props;
    const { total: nextTotal, number: nextNumber } = nextProps;

    return !(number === nextNumber && total === nextTotal);
  }

  removeUnnecessary(currentSize, index, allSizes) {
    if (index === 0) return true;

    const { size, total } = this.props;

    return size * total > allSizes[index - 1];
  }

  render() {
    const { number, size, total, onPageSizeChange, onPageChange } = this.props;

    const nextItem = {
      'aria-label': 'Next item',
      content: <Icon name="angle right" />,
    };
    const prevItem = {
      'aria-label': 'Prev item',
      content: <Icon name="angle left" />,
    };
    const pageSizes = AVAILABLE_PAGE_SIZES.filter(this.removeUnnecessary);

    return (
      <PaginationControlsWrapper>
        {(pageSizes.length > 1) && (
          <div>
            {pageSizes.map(pageSize => (
              <PageSizeControl
                key={pageSize}
                active={size === pageSize}
                onClick={onPageSizeChange(pageSize)}
              >
                {pageSize}
              </PageSizeControl>
            ))}
          </div>
        )}
        {(total > 1) && (
          <PageNumberControls
            totalPages={total}
            activePage={number}
            onPageChange={onPageChange}

            firstItem={null}
            lastItem={null}
            nextItem={nextItem}
            prevItem={prevItem}
          />
        )}
      </PaginationControlsWrapper>
    );
  }

}

PaginationControls.defaultProps = {
  number: null,
  size: null,
  total: null,
};

PaginationControls.propTypes = {
  number: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
};

export default PaginationControls;
