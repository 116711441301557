import constants from 'dispatcherConst';

export const setSelectedProject = project => ({
  type: constants.SET_SELECTED_PROJECT,
  payload: {
    project,
  },
});

export default setSelectedProject;
