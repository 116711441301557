import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { singleProjectSelector } from 'helpers/selectors';
import EnableControlPanel from './EnableControlPanel';

export const mapStateToProps = (state, { match }) => {
  const { id: projectId } = match.params;
  const { isConstruction, isSuspended } = singleProjectSelector(state, projectId);

  return {
    isConstruction,
    isSuspended,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
)(EnableControlPanel);
