import styled from 'styled-components';
import { Header, Grid } from 'semantic-ui-react';

export const StyledHeader = styled(Header)`
  &.ui.header {
    margin-top: 0.5em;
  }
`;

export const StyledColumn = styled(Grid.Column)`
  padding-top: 7px;
`;

export default StyledHeader;
