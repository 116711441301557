import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

const StyledTokenButton = styled(Button)`
  &.ui.icon.button {
    height: 37px;
    font-size: 10px;
    margin-right: 3px;
    margin-bottom: 3px;
  }
`;

export default StyledTokenButton;
