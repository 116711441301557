import React from 'react';
import PropTypes from 'prop-types';

import { upperCaseFirstLetter, extractFirstLetterAndUpperCase } from 'helpers/sharedMethods';
import { InputFormField } from '..';
import { StyledDiv, StyledRGBWrapper } from './ColorPreview.style';

const ColorPreview = ({
  bgColor,
  actionTypeDeviceIdKey,
  fields,
  showPreview,
  orientation,
  hideCheckbox,
  hideLabel,
}) => (
  <StyledRGBWrapper orientation={orientation}>
    {showPreview && <StyledDiv bgColor={bgColor} />}
    {fields.map(item => (
      <InputFormField
        key={`${item}${actionTypeDeviceIdKey}`}
        name={`${item}${actionTypeDeviceIdKey}`}
        enableName={`enable${upperCaseFirstLetter(item)}${actionTypeDeviceIdKey}`}
        gteq={0}
        lteq={100}
        label={extractFirstLetterAndUpperCase(item)}
        labelPlacement={orientation === 'horizontal' ? 'bottom' : 'left'}
        hideCheckbox={hideCheckbox}
        hideLabel={hideLabel}
      />
    ))}
  </StyledRGBWrapper>
);

ColorPreview.defaultProps = {
  fields: ['red', 'green', 'blue', 'intensity', 'temperature'],
  showPreview: true,
  orientation: 'vertical',
  hideCheckbox: false,
  hideLabel: false,
};

ColorPreview.propTypes = {
  bgColor: PropTypes.string.isRequired,
  actionTypeDeviceIdKey: PropTypes.string.isRequired,
  fields: PropTypes.array,
  showPreview: PropTypes.bool,
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
  hideCheckbox: PropTypes.bool,
  hideLabel: PropTypes.bool,
};

export default ColorPreview;
