import { ACTIVITY_LOGS_FIELD } from 'helpers/selectors';

const logsDataSelector = (state, projectId) => {
  if (state.data[ACTIVITY_LOGS_FIELD]
    && state.data[ACTIVITY_LOGS_FIELD][projectId]
    && state.data[ACTIVITY_LOGS_FIELD][projectId].logs) {

    return state.data[ACTIVITY_LOGS_FIELD][projectId].logs;
  }

  return null;
};

export default logsDataSelector;
