import constants from 'dispatcherConst';

export const triggerNotificationEventFilters = ({ notificationFilters, additionalFilters }) => ({
  type: constants.TRIGGER_NOTIFICATION_EVENTS_FILTERING,
  payload: {
    notificationFilters,
    additionalFilters,
  },
});

export default triggerNotificationEventFilters;
