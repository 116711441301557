import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { WarningIcon } from 'components/atomic';
import ProjectSuiteContext from 'application/tenant/console/site/components/structural/ProjectTasksTab/components/ListOfTasks/components/ProjectSuiteContext/ProjectSuiteContext';
import { StyledTaskInformation, StyledLabel } from './TaskInformation.style';

const TaskInformation = ({
  invalidActions,
  actionsText,
  message,
  showInformation,
  isSuperSiteTask,
  projectName,
}) => {
  const superSites = useContext(ProjectSuiteContext);

  return showInformation && (
    <>
      <StyledTaskInformation>
        ({actionsText})
        {invalidActions && <WarningIcon message={message} />}
      </StyledTaskInformation>
      {isSuperSiteTask && <StyledLabel htmlFor="superSite">Multi-site</StyledLabel>}
      {superSites && projectName && <StyledLabel htmlFor="superSite">{projectName}</StyledLabel>}
    </>
  );
};

TaskInformation.defaultProps = {
  actionsText: '',
  message: '',
  invalidActions: false,
  showInformation: false,
  isSuperSiteTask: false,
  projectName: undefined,
};

TaskInformation.propTypes = {
  actionsText: PropTypes.string,
  message: PropTypes.string,
  invalidActions: PropTypes.bool,
  showInformation: PropTypes.bool,
  isSuperSiteTask: PropTypes.bool,
  projectName: PropTypes.string,
};

export default TaskInformation;
