import isNull from 'lodash/isNull';

import { applyVocabulary } from 'helpers/sharedMethods';

function sortPaths(paths) {
  if (isNull(paths)) {
    return [];
  }

  const keys = Object.keys(paths);

  return keys.sort((a, b) => {
    const aVocab = applyVocabulary(a);
    const bVocab = applyVocabulary(b);

    if (aVocab < bVocab) {
      return -1;
    }

    if (aVocab > bVocab) {
      return 1;
    }

    return 0;
  });
}

export default sortPaths;
