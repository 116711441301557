import constants from 'dispatcherConst';

const withValueOverrideActionCreators = {
  setOverride: (id, value, valueSelector) => ({
    type: constants.SET_VALUE_OVERRIDE,
    payload: {
      id,
      value,
      valueSelector,
      timeout: 15,
    },
  }),
  resetOverride: id => ({
    type: constants.RESET_VALUE_OVERRIDE,
    payload: {
      id,
    },
  }),
  overrideFailed: id => ({
    type: constants.VALUE_OVERRIDE_FAILED,
    payload: { id },
  }),
  overrideSuccess: id => ({
    type: constants.VALUE_OVERRIDE_SUCCESS,
    payload: { id },
  }),
};

export default withValueOverrideActionCreators;
