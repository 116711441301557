import {
  refreshOTPSecret,
  refreshOTPSecretSuccess,
  refreshOTPSecretFailure,
} from './refreshOTPSecret';

export default {
  refreshOTPSecret,
  refreshOTPSecretSuccess,
  refreshOTPSecretFailure,
};
