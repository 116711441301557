import React from 'react';
import PropTypes from 'prop-types';

import { StyledAddContextButton } from './AddContextButton.style';

const AddContextButton = ({ selectingContexts, onToggleSelectingContexts }) => (
  <StyledAddContextButton onClick={onToggleSelectingContexts}>
    {selectingContexts ? 'Done' : 'Add context...'}
  </StyledAddContextButton>
);

AddContextButton.propTypes = {
  selectingContexts: PropTypes.bool.isRequired,
  onToggleSelectingContexts: PropTypes.func.isRequired,
};

export default AddContextButton;
