import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import { AutomatedOperations, DayView, NoLocation } from './components';
import StyledDivider from './SiteSchedulingTab.style';

const SiteSchedulingTab = ({ projectId, locationSet, locationLoaded }) => {
  if (!locationLoaded) {
    return null;
  }

  if (!locationSet) {
    return <NoLocation projectId={projectId} />;
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <DayView />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <StyledDivider />
          <AutomatedOperations projectId={projectId} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

SiteSchedulingTab.propTypes = {
  projectId: PropTypes.string.isRequired,
  locationSet: PropTypes.bool.isRequired,
  locationLoaded: PropTypes.bool.isRequired,
};

export default SiteSchedulingTab;
