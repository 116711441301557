import styled from 'styled-components';

export const StyledLabel = styled.label`
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  margin: 0 0 .28571429rem 0;
  font-weight: 700;
`;

export default StyledLabel;
