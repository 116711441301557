import { connect } from 'react-redux';

import uploadsActionCreators from 'components/UploadsWidget/uploadsActionCreators';
import newUploadActionCreators from './newUploadActionCreators';
import NewUpload from './NewUpload';

const mapDispatchToProps = {
  onPrepareUpload: newUploadActionCreators.prepareUpload,
  onFileNotFoundResume: newUploadActionCreators.fileNotFoundResume,
  onClearCompleted: uploadsActionCreators.clearCompleted,
};

export default connect(null, mapDispatchToProps)(NewUpload);
