import React from 'react';
import PropTypes from 'prop-types';

import { StyledWrapper, StyledSliderBar } from './RenderIntensityTrack.style';

const RenderIntensityTrack = ({ props, backgroundColor, children }) => {
  const { onMouseDown, onTouchStart, style, ref } = props;

  return (
    <StyledWrapper
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      style={{ ...style }}
      tabIndex={0}
      role="button"
    >
      <StyledSliderBar ref={ref} backgroundColor={backgroundColor}>
        {children}
      </StyledSliderBar>
    </StyledWrapper>
  );
};

RenderIntensityTrack.defaultProps = {
  onMouseDown: () => null,
  onTouchStart: () => null,
  style: {},
  ref: {},
};

RenderIntensityTrack.propTypes = {
  props: PropTypes.object.isRequired,
  onMouseDown: PropTypes.func,
  onTouchStart: PropTypes.func,
  style: PropTypes.object,
  ref: PropTypes.object,
  backgroundColor: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
};

export default RenderIntensityTrack;
