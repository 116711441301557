import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import {
  OPERATIONS_FIELD,
} from 'helpers/selectors';

export const addOperation = params => ({
  type: constants.ADD_OPERATION_REQUEST,
  payload: {
    params,
    endpoint: endpoints.OPERATIONS,
  },
});

export const addOperationSuccess = response => ({
  type: constants.ADD_OPERATION_SUCCESS,
  payload: {
    response,
    message: messages.OPERATION_ADDED,
    resourceType: OPERATIONS_FIELD,
    id: response.data.id,
  },
});

export const addOperationFailure = error => ({
  type: constants.ADD_OPERATION_FAILED,
  payload: { error },
});
