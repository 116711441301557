import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';

const DataTableText = ({ field, item }) => {
  const value = item[field];

  return isUndefined(value) ? null : value;
};

DataTableText.defaultProps = {
  field: null,
  item: {},
};

DataTableText.propTypes = {
  field: PropTypes.string,
  item: PropTypes.object,
};

export default DataTableText;
