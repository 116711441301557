import { compose } from 'redux';
import { connect } from 'react-redux';

import { userInvitingSelector } from 'helpers/selectors';
import withReInviteUser from './withReInviteUser';
import inviteUserActionCreators from '../InviteUser/inviteUserActionCreators';

const mapStateToProps = state => ({
  inviting: userInvitingSelector(state),
});

const mapDispatchToProps = {
  onReInviteUser: inviteUserActionCreators.inviteUser,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withReInviteUser,
);
