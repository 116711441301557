import React from 'react';

const High = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="11"
    fill="none"
    viewBox="0 0 14 11"
  >
    <path
      stroke="#F65A34"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M1 9.087l6-3.913 6 3.913M1 4.913L7 1l6 3.913"
    />
  </svg>
);

export default High;
