import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withProjectSuitePermissions } from 'gateway';

import ProjectSuitePermissionsTab from './ProjectSuitePermissionsTab';

export const mapStateToProps = (state, { match }) => {
  const { id: projectSuiteId } = match.params;

  return {
    projectSuiteId,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withProjectSuitePermissions,
)(ProjectSuitePermissionsTab);
