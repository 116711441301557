import isNil from 'lodash/isNil';

import createDropdownOptionForActions from './createDropdownOptionForActions';

const createOptionList = (siteActionList, allActionTypes) => {
  if (isNil(siteActionList) || isNil(allActionTypes)) {
    return [];
  }

  if (Array.isArray(siteActionList)) {
    return siteActionList
      .filter(item => allActionTypes[item])
      .map(item => createDropdownOptionForActions(item, allActionTypes));
  }

  return [];
};

export default createOptionList;
