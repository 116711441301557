import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getPath } from 'data/api/requests';
import endpoints from 'data/api/endpoints';
import mapValues from 'lodash/mapValues';
import values from 'lodash/values';
import get from 'lodash/get';
import Select from './MailgunDomainSelect.style';

class MailgunDomainSelect extends Component {

  state = {
    domains: [],
  };

  componentDidMount() {
    getPath(endpoints.MAILGUN_DOMAINS).then(data => {
      const result = get(data, 'response.mailgunDomains', null);
      if (result) {
        const domains = values(mapValues(result, v => v.attributes));
        this.setState({
          domains: domains.map(domain => ({
            key: domain.name,
            text: domain.name,
            value: domain.name,
            description: (domain.state === 'active') ? null : domain.state,
          })),
        });
      }
    });
  }

  handleChange = (event, { value }) => {
    const { onChange } = this.props;
    onChange(value);
  }

  render() {
    const { domains } = this.state;
    const { value, input } = this.props;

    const isValueInDomains = domains.find(domain => domain.value === value);

    return (
      <Select
        selectOnBlur={false}
        selectOnNavigation={false}
        options={domains}
        onChange={this.handleChange}
        value={isValueInDomains ? value : null}
        placeholder={isValueInDomains ? null : value}
        {...input}
      />
    );
  }

}

MailgunDomainSelect.defaultProps = {
  onChange: () => null,
  value: null,
};

MailgunDomainSelect.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default MailgunDomainSelect;
