import React, { useState, createRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';

import { Button } from 'components/atomic';
import { convertColorString } from 'helpers/sharedMethods';

import {
  Container,
  StyledChromePicker,
  ColorPreviewToggle,
} from './ColorPicker.style';

const ColorPicker = ({
  value,
  onChange,
  name,
}) => {
  const contextRef = createRef();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState(value);

  const formattedColor = useMemo(() => convertColorString(selectedColor), [selectedColor]);

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleChange = useCallback(({ rgb }) => {
    setSelectedColor(rgb);
    onChange(rgb);
  }, [onChange]);

  const renderTrigger = useCallback(() => (
    <span ref={contextRef}>
      <Button basic icon className="color-picker-toggle-btn" onClick={handleToggle}>
        <ColorPreviewToggle name="color-picker-preview-toggle" displayColor={formattedColor} />
      </Button>
    </span>
  ), [formattedColor, handleToggle, contextRef]);

  const renderContent = useCallback(() => (
    <Container>
      <StyledChromePicker color={formattedColor} onChangeComplete={handleChange} />
    </Container>
  ), [formattedColor, handleChange]);

  return (
    <Popup
      name={name}
      on="click"
      context={contextRef}
      position="right center"
      trigger={renderTrigger()}
      content={renderContent()}
    />
  );
};

ColorPicker.defaultProps = {
  value: null,
};

ColorPicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};


export default ColorPicker;
