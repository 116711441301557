import memoize from 'lodash/memoize';

import { mapResourceType } from 'helpers/sharedMethods';
import resourcePermissionsTreeSelector from './resourcePermissionsTreeSelector';
import userPermissionsSelector from './userPermissionsSelector';

const computedResourceUserPermissionsTreeSelector = state => memoize(
  ({ resourceId, resourceType, userId, scopes, limitedFor }) => {
    const tree = resourcePermissionsTreeSelector(state, { resourceType })(scopes, limitedFor);
    const userPermissions = userPermissionsSelector(state)(userId);
    const filteredUserPermissions = userPermissions.filter(
      userPermission => (
        userPermission.resourceId === resourceId
        && userPermission.resourceType === mapResourceType(resourceType)
      ),
    );

    return tree.mergeUserPermissions(filteredUserPermissions);
  },
  ({ resourceId, resourceType, userId, scopes: scopesArray = [] }) => {
    const scopes = scopesArray.join('');

    return `${resourceId}.${resourceType}.${userId}.${scopes}`;
  },
);

export default computedResourceUserPermissionsTreeSelector;
