import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import {
  SCHEDULES_FIELD,
} from 'helpers/selectors';

export const removeSchedule = (id, lockVersion, automatedOperationId) => ({
  type: constants.REMOVE_SCHEDULE_REQUEST,
  payload: {
    id,
    lockVersion,
    automatedOperationId,
    endpoint: endpoints.DELETE_SCHEDULE(id, lockVersion, automatedOperationId),
  },
});

export const removeScheduleSuccess = response => ({
  type: constants.REMOVE_SCHEDULE_SUCCESS,
  payload: {
    response,
    message: messages.SCHEDULE_REMOVED,
    resourceType: SCHEDULES_FIELD,
  },
});

export const removeScheduleFailure = error => ({
  type: constants.REMOVE_SCHEDULE_FAILED,
  payload: { error },
});
