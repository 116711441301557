import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import DataTable from 'components/DataTable/DataTableContainer';
import { REMOTE_DEVICES_FIELD } from 'helpers/selectors';
import { RemoteDeviceAccordionRow, RefreshUI, UnjoinedRemoteDevicesTable } from './components';

const labels = [
  { text: 'Number', field: 'num', sortable: true, width: 2 },
  { text: 'Name', field: 'visibleName', sortBy: 'visibleName', width: 6 },
  { text: 'Type', field: 'type', sortable: true, width: 4 },
  { text: 'Status', field: 'status', sortable: false, width: 4 },
];
const title = 'Remote Devices';

const PharosRemoteDevicesV1 = ({ deviceId }) => {
  const [rowToDisplay, setRowToDisplay] = useState('');
  const handleToggleRow = id => {
    const rowValue = rowToDisplay === id ? '' : id;
    setRowToDisplay(rowValue);
  };

  const refreshUI = (
    <RefreshUI
      deviceId={deviceId}
    />
  );

  return (
    <Fragment>
      <DataTable
        header={title}
        labels={labels}
        createElement={refreshUI}
        customTableRow={RemoteDeviceAccordionRow}
        pagination={{
          type: REMOTE_DEVICES_FIELD,
          included: true,
        }}
        onToggleRow={handleToggleRow}
        rowToDisplay={rowToDisplay}
      />
      <UnjoinedRemoteDevicesTable />
    </Fragment>
  );
};

PharosRemoteDevicesV1.propTypes = {
  deviceId: PropTypes.string.isRequired,
};

export default PharosRemoteDevicesV1;
