import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { LOGS_ACTION_TYPES_FIELD } from 'helpers/selectors';

export const fetchLogsActionTypes = () => ({
  type: constants.FETCH_LOGS_ACTION_TYPES_REQUEST,
  payload: {
    endpoint: endpoints.ACTIVITY_LOGGING_ACTION_TYPES,
    resourceType: LOGS_ACTION_TYPES_FIELD,
  },
});

export const fetchLogsActionTypesSuccess = response => ({
  type: constants.FETCH_LOGS_ACTION_TYPES_SUCCESS,
  payload: { response },
});

export const fetchLogsActionTypesFailure = error => ({
  type: constants.FETCH_LOGS_ACTION_TYPES_FAILED,
  payload: { error },
});
