import styled from 'styled-components';

import { Button } from 'components/atomic';

const StyledButton = styled(Button)`
&.ui.primary.button {
  font-size: 12px !important; 
  margin-top: 15px !important; 
  margin-right: 15px !important;

  @media only screen and (max-width: 420px) {
    width: 44px;
    padding: 3px;
    font-size: 9px !important;
    margin-top: 15px !important; 
    margin-right: -1px !important;
  }
}`;


export default StyledButton;
