import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';

import { validEmail } from 'components/form/formValidators';
import { AttributeDescription } from './components';

const attributesConfig = [
  {
    icon: 'user',
    fieldName: 'firstName',
    label: 'First name',
  },
  {
    icon: 'user',
    fieldName: 'lastName',
    label: 'Last name',
  },
  {
    icon: 'mail',
    fieldName: 'email',
    isEditable: true,
    label: 'Email',
    validator: validEmail,
  },
  {
    icon: 'phone',
    label: 'Phone',
    fieldName: 'phoneNumber',
    type: 'tel',
  },
];

const AttributesTab = ({ attributes, onEditProfile }) => (
  <List>
    {attributesConfig.map(({
      icon,
      fieldName,
      label,
      ...rest
    }) => (
      <List.Item key={fieldName}>
        <List.Icon name={icon} />
        <List.Content>
          <List.Header>{label}</List.Header>
          <List.Description>
            <AttributeDescription
              id={attributes.id}
              name={fieldName}
              value={attributes[fieldName]}
              onEditProfile={onEditProfile}
              {...rest}
            />
          </List.Description>
        </List.Content>
      </List.Item>
    ))}
  </List>
);

AttributesTab.propTypes = {
  attributes: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onEditProfile: PropTypes.func.isRequired,
};

export default AttributesTab;
