import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  USERS_FIELD,
  permissionsProjectSuiteUsersLinkSelector,
} from 'helpers/selectors';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { actionCreators as projectSuiteActionCreators } from 'pages/ProjectSuites/utilities';

import withLoadProjectSuite from './withLoadProjectSuite';

export const mapStateToProps = (state, { projectSuiteId }) => ({
  usersEndpoint: permissionsProjectSuiteUsersLinkSelector(state)(projectSuiteId),
});

export default compose(
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    ({ projectSuiteId }) => projectSuiteActionCreators.fetchProjectSuite(projectSuiteId, {
      fields: {
        projectSuites: [
          USERS_FIELD,
        ],
      },
    }),
  ),
  withLoadProjectSuite,
);
