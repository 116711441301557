import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { USERS_FIELD } from 'helpers/selectors';

export const fetchUsers = urlParams => ({
  type: constants.FETCH_USERS_REQUEST,
  payload: {
    endpoint: endpoints.USERS,
    resourceType: USERS_FIELD,
    urlParams,
  },
});

export const fetchUsersSuccess = response => ({
  type: constants.FETCH_USERS_SUCCESS,
  payload: {
    response,
    resourceType: USERS_FIELD,
  },
});

export const fetchUsersFailure = error => ({
  type: constants.FETCH_USERS_FAILED,
  payload: { error },
});
