import { takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import renewSuspendedSite from './renewSuspendedSite';

function* saga() {
  yield takeLatest(constants.RENEW_SUSPENDED_SITE_REQUEST, renewSuspendedSite);
}

export default saga;
