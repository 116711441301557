import { call, put } from 'redux-saga/effects';

import { messages } from 'data/notifications/notificationsConst';
import { getPath } from 'data/api/requests';
import notificationsActionCreators from 'data/notifications/notificationsActionCreators';
import controlUIActionCreators from '../actionCreators';
import {
  buildCanonicalRequest,
  buildStringToSign,
  emptyStringHash,
  downloadImage,
} from '../awsCanonicalRequest';

function* loadPageBackgroundImage(action) {
  const { endpoint, projectId, pageId, url } = action.payload;
  const uriEncodedUrl = encodeURI(url);
  const {
    signedHeaders,
    canonicalRequest,
    datetimeString,
    awsDatetimeString,
  } = buildCanonicalRequest(uriEncodedUrl);
  const {
    algorithm,
    credentialScope,
    stringToSign,
  } = buildStringToSign(uriEncodedUrl, canonicalRequest, datetimeString, awsDatetimeString);
  const urlParams = {
    to_sign: stringToSign,
    datetime: awsDatetimeString,
  };
  const { response, error } = yield call(getPath, endpoint, { urlParams });

  if (error) {
    yield put(
      notificationsActionCreators.addInfoNotification(messages.CANNOT_LOAD_BACKGROUND_IMAGE),
    );
  } else {
    const {
      data: {
        attributes: {
          signature,
          accessKeyId,
        },
      },
    } = response;
    const authorizationHeaderTokens = [
      `Credential=${accessKeyId}/${credentialScope}`,
      `SignedHeaders=${signedHeaders}`,
      `Signature=${signature}`,
    ];
    const authorizationHeader = `${algorithm} ${authorizationHeaderTokens.join(',')}`;
    const headers = {
      Authorization: authorizationHeader,
      'x-amz-content-sha256': emptyStringHash(),
      'x-amz-date': awsDatetimeString,
      'content-type': 'text/plain',
    };
    const blob = yield call(downloadImage, uriEncodedUrl, headers);
    const fileURL = URL.createObjectURL(blob);

    yield put(controlUIActionCreators.loadPageBackgroundImageSuccess({
      projectId,
      pageId,
      fileURL,
    }));
  }
}

export default loadPageBackgroundImage;
