import React, { Fragment, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Icon, Dimmer, Loader } from 'semantic-ui-react';

import colors from 'themes/colors';
import formNames from 'components/form/formNames';
import { convertColorString } from 'helpers/sharedMethods';
import { CONTROL_UI_PAGE_TYPES } from 'application/tenant/components/structural/ControlUI/constants';

import ControlUIButton from './components/ControlUIButtonGroup/ControlUIButton/ControlUIButtonContainer';
import { PageBackgroundImage, ControlUIOverrideControl } from './components';
import {
  StyledControlUIPage,
  StyledControlUIPageButtonOuterWrapper,
  StyledControlUIPageButtonWrapper,
  StyledControlUIPageTitle,
  StyledControlUIBackgroundImage,
  StyledButton,
  StyledPageContainer,
  StyledControlsContainer,
} from './ControlUIPage.style';

const ControlUIPage = ({
  pageId,
  currentPageId,
  configure,
  name,
  nameColor,
  pageType,
  buttons,
  overrideControls,
  backgroundImg,
  backgroundImgBlob,
  localURL,
  onTogglePageNavigation,
}) => {
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);
  const imgSrc = localURL || backgroundImgBlob;

  const displayButtons = useMemo(() => {
    if (!configure && backgroundImg) {
      if (backgroundLoaded) {
        return true;
      }

      return false;
    }

    return true;
  }, [configure, backgroundImg, backgroundLoaded]);

  const pageTitleColor = useMemo(
    () => convertColorString(nameColor, colors.black),
    [nameColor],
  );

  const renderButtons = useCallback(() => (
    <>
      {buttons.map(({ index: buttonIndex, ...button }) => {
        const formName = `${formNames.controlUiButton}_${pageId}_${buttonIndex}`;

        return (
          <ControlUIButton
            key={buttonIndex}
            form={formName}
            pageId={pageId}
            buttonId={buttonIndex}
            configure={configure}
            {...button}
          />
        );
      })}
    </>
  ), [buttons, configure, pageId]);

  const renderOverrideControls = useCallback(() => (
    <>
      {overrideControls.map(({ index: overrideIndex, ...override }) => {
        const formName = `${formNames.controlUiOverrideControls}_${pageId}_${overrideIndex}`;

        return (
          <ControlUIOverrideControl
            key={overrideIndex}
            form={formName}
            pageId={pageId}
            controlId={overrideIndex}
            configure={configure}
            {...override}
          />
        );
      })}
    </>
  ), [configure, overrideControls, pageId]);

  const pageProps = useMemo(() => ({
    configure,
    pageType,
  }), [configure, pageType]);

  const renderPageBody = useCallback(() => {
    if (!displayButtons) {
      return (
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      );
    }

    return (
      <>
        {pageType === CONTROL_UI_PAGE_TYPES.DEFAULT && (
          <StyledControlUIPageButtonWrapper name="default-page-body" {...pageProps}>
            {renderButtons()}
          </StyledControlUIPageButtonWrapper>
        )}
        {pageType === CONTROL_UI_PAGE_TYPES.BUTTONS_OVERRIDE_CONTROLS && (
          <StyledPageContainer name="buttons-override-page-body">
            <StyledControlUIPageButtonWrapper {...pageProps}>
              {renderButtons()}
            </StyledControlUIPageButtonWrapper>
            <StyledControlsContainer>
              {renderOverrideControls()}
            </StyledControlsContainer>
          </StyledPageContainer>
        )}
        {pageType === CONTROL_UI_PAGE_TYPES.OVERRIDE_CONTROLS_BUTTONS && (
          <StyledPageContainer name="override-buttons-page-body">
            <StyledControlsContainer>
              {renderOverrideControls()}
            </StyledControlsContainer>
            <StyledControlUIPageButtonWrapper {...pageProps}>
              {renderButtons()}
            </StyledControlUIPageButtonWrapper>
          </StyledPageContainer>
        )}
        {pageType === CONTROL_UI_PAGE_TYPES.OVERRIDE_CONTROLS && (
          <StyledPageContainer name="override-controls-page-body" {...pageProps}>
            <StyledControlsContainer {...pageProps}>
              {renderOverrideControls()}
            </StyledControlsContainer>
          </StyledPageContainer>
        )}
      </>
    );
  }, [displayButtons, pageType, renderButtons, renderOverrideControls, pageProps]);

  const isActive = useMemo(
    () => currentPageId === pageId,
    [currentPageId, pageId],
  );

  if (!buttons) {
    return null;
  }

  return (
    <StyledControlUIPage active={isActive} configure={configure}>
      <StyledControlUIPageTitle pageTitleColor={pageTitleColor}>
        {name}
        <StyledButton name="toggle-page-navigation" onClick={onTogglePageNavigation}>
          <Icon name="sidebar" />
        </StyledButton>
      </StyledControlUIPageTitle>
      {isActive && (
        <Fragment>
          <StyledControlUIPageButtonOuterWrapper configure={configure}>
            {backgroundImgBlob && (
              <StyledControlUIBackgroundImage
                src={imgSrc}
                isLoaded={backgroundLoaded}
                onLoad={() => setBackgroundLoaded(true)}
              />
            )}
            {renderPageBody()}
          </StyledControlUIPageButtonOuterWrapper>
          <PageBackgroundImage pageId={pageId} configure={configure} />
        </Fragment>
      )}
    </StyledControlUIPage>
  );
};

ControlUIPage.defaultProps = {
  buttons: [],
  overrideControls: [],
  configure: false,
  backgroundImg: null,
  backgroundImgBlob: null,
  currentPageId: 0,
  nameColor: null,
  localURL: null,
  pageType: 'default',
};

ControlUIPage.propTypes = {
  pageId: PropTypes.number.isRequired,
  currentPageId: PropTypes.number,
  name: PropTypes.string.isRequired,
  nameColor: PropTypes.string,
  pageType: PropTypes.string,
  buttons: PropTypes.array,
  overrideControls: PropTypes.array,
  configure: PropTypes.bool,
  backgroundImg: PropTypes.string,
  backgroundImgBlob: PropTypes.string,
  localURL: PropTypes.string,
  onTogglePageNavigation: PropTypes.func.isRequired,
};

export default ControlUIPage;
