export default class AtlasProCreator {

  constructor(sixeyeFirmwareVersion, tabs) {
    this.model = 'Atlas Pro';
    this.sixeyeFirmwareVersion = sixeyeFirmwareVersion;
    this.manufacturerSDK = 'etc';
    this.tabs = tabs;
  }

}
