import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import { getMonthlyCondition, getOptionsFromCondition } from './utilities';
import {
  StyledLabel,
  StyledRow,
  DropdownContainer,
  StyledOccurrenceTypeDropdown,
  StyledOccurrenceDayDropdown,
} from './AdvancedOptions.style';
import {
  OCCURRENCE_TYPE,
  OCCURRENCE_TYPE_OPTIONS,
  OCCURRENCE_DAY,
  OCCURRENCE_DAY_OPTIONS,
} from '../../../constants';

function AdvancedOptions({
  existingSchedule,
  onChange,
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [occurrenceType, setOccurrenceType] = useState(OCCURRENCE_TYPE.FIRST);
  const [occurrenceDay, setOccurrenceDay] = useState(OCCURRENCE_DAY.DAY);

  useEffect(() => {
    if (existingSchedule && !isLoaded) {
      const { params } = existingSchedule || {};

      const { condition } = params;

      if (condition) {
        const options = getOptionsFromCondition(condition);

        const { occurrenceType: existingType, occurrenceDay: existingDay } = options;

        setOccurrenceType(existingType);
        setOccurrenceDay(existingDay);
        setIsLoaded(true);
      }
    }
  }, [existingSchedule, isLoaded]);

  useEffect(() => {
    onChange({
      condition: getMonthlyCondition(occurrenceType, occurrenceDay),
    });
  }, [onChange, occurrenceType, occurrenceDay]);

  const handleOccurrenceTypeChange = useCallback((e, { value }) => {
    setOccurrenceType(value);
  }, []);

  const handleOccurrenceDayChange = useCallback((e, { value }) => {
    setOccurrenceDay(value);
  }, []);


  return (
    <>
      <StyledLabel>
        On the
      </StyledLabel>
      <StyledRow>
        <Grid.Column computer={16} mobile={16}>
          <DropdownContainer>
            <StyledOccurrenceTypeDropdown
              value={occurrenceType}
              options={OCCURRENCE_TYPE_OPTIONS}
              onChange={handleOccurrenceTypeChange}
              name="occurrence_type_dropdown"
              scrolling
              fluid
              selection
            />
            <StyledOccurrenceDayDropdown
              value={occurrenceDay}
              options={OCCURRENCE_DAY_OPTIONS}
              onChange={handleOccurrenceDayChange}
              name="occurrence_day_dropdown"
              scrolling
              fluid
              selection
            />
            <StyledLabel>
              of the month
            </StyledLabel>
          </DropdownContainer>
        </Grid.Column>
      </StyledRow>
    </>
  );
}

AdvancedOptions.defaultProps = {
  existingSchedule: null,
};

AdvancedOptions.propTypes = {
  onChange: PropTypes.func.isRequired,
  existingSchedule: PropTypes.object,
};

export default AdvancedOptions;
