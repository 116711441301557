import { Grid } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import filterProps from 'helpers/filterProps';

const FilterPropsStyledRow = filterProps(['logsDataExists', 'isLoading'])(Grid.Row);

export const StyledRow = styled(Grid.Row)`
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
`;

export const StyledRowBorder = styled(FilterPropsStyledRow)`
  ${({ logsDataExists, isLoading }) => logsDataExists && !isLoading && css`
    border-top: solid 1px #dedede;
  `}
`;

export default StyledRow;
