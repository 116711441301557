import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';

import { convertColorString } from 'helpers/sharedMethods';
import FormField from 'components/form/FormField/FormField';
import {
  StyledColorSelectFieldWrapper,
  StyledColorBlock,
  StyledChromePicker,
} from './ColorSelectField.style';

class ColorSelectField extends Component {

  constructor() {
    super();

    this.state = { colorValue: null };
    this.handleSelectColor = this.handleSelectColor.bind(this);
  }

  handleSelectColor({ rgb }) {
    const {
      input: { onChange },
    } = this.props;

    onChange(JSON.stringify(rgb));
    this.setState({ colorValue: rgb });
  }

  render() {
    const {
      input,
      initialColor,
      ...rest
    } = this.props;
    const { colorValue } = this.state;
    const displayColor = colorValue || initialColor;
    const displayColorObject = convertColorString(displayColor);

    return (
      <FormField {...rest} input={input}>
        <StyledColorSelectFieldWrapper>
          <StyledColorBlock displayColor={displayColorObject} />
          <StyledChromePicker
            color={displayColorObject}
            onChangeComplete={this.handleSelectColor}
          />
        </StyledColorSelectFieldWrapper>
      </FormField>
    );
  }

}

ColorSelectField.propTypes = {
  ...Input.propTypes,
};

export default ColorSelectField;
