import { CHILD_PROJECTS_FOR_MAP_FIELD } from 'helpers/selectors';
import constants from 'dispatcherConst';
import { getMarkerType } from 'pages/Projects/components/MapView/utilities';

const multiSiteMapDataReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.FETCH_CHILD_PROJECTS_FOR_MAP_SUCCESS: {
      const { data, projectSuiteId } = action.payload.response;
      const projectsForMap = data.reduce((acc, project) => {
        const {
          id,
          attributes: {
            name,
            longitude,
            latitude,
            onlineDevices,
            offlineDevices,
            unassociatedDevices,
            devicesForUpgrade,
          },
        } = project;

        const calculateTypeParams = {
          onlineDevices,
          offlineDevices,
          unassociatedDevices,
          devicesForUpgrade,
        };
        const type = getMarkerType(calculateTypeParams);

        return {
          ...acc,
          [id]: {
            id,
            attributes: {
              name,
              longitude,
              latitude,
              type,
            },
          },
        };
      }, {});

      const { [CHILD_PROJECTS_FOR_MAP_FIELD]: childProjectsForMap = {} } = state;

      return {
        ...state,
        [CHILD_PROJECTS_FOR_MAP_FIELD]: {
          ...childProjectsForMap,
          [projectSuiteId]: projectsForMap,
        },
      };
    }

    default:
      return state;
  }
};

export default multiSiteMapDataReducer;
