import styled from 'styled-components';

export const StyledButton = styled.div`
  min-width: 40px;
`;

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
`;

export const StyledInputHolder = styled.div`
  width: 100%;
  margin-right: 4px;
`;
