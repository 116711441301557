import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { frontendPermissions, permissionsPass } from 'gateway';
import actionCreators from '../../../../../../utilities/actionCreators';
import DuplicatePage from './DuplicatePage';

export const mapStateToProps = (state, { match }) => {
  const { id: projectId } = match.params;

  return {
    projectId,
  };
};

const mapDispatchToProps = {
  onDuplicatePage: actionCreators.duplicatePage,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  permissionsPass(frontendPermissions.EDIT_CONTROL_PANEL),
)(DuplicatePage);
