import { connect } from 'react-redux';

import { actionCreators } from '../../utilities';
import AddDeviceFirmware from './AddDeviceFirmware';

export const mapDispatchToProps = {
  onAddDeviceFirmware: actionCreators.addDeviceFirmware,
};

export default connect(null, mapDispatchToProps)(AddDeviceFirmware);
