import React from 'react';
import PropTypes from 'prop-types';

import PatchModel from 'models/PatchModel';

import StyledProtocolDropdown from './ProtocolDropdown.style';

export default function ProtocolDropdown({
  isLoading,
  patches,
  selectedProtocol,
  onProcotolChange,
}) {
  const handleChange = protocol => onProcotolChange(protocol);

  return (
    <StyledProtocolDropdown
      name="protocol"
      placeholder="Select protocol"
      options={patches ? patches.map(p => p.selectorOption) : []}
      loading={isLoading}
      onChangeCallback={handleChange}
      value={String(selectedProtocol)}
    />
  );
}

ProtocolDropdown.defaultProps = {
  patches: null,
  selectedProtocol: null,
};

ProtocolDropdown.propTypes = {
  patches: PropTypes.arrayOf(PropTypes.instanceOf(PatchModel)),
  isLoading: PropTypes.bool.isRequired,
  selectedProtocol: PropTypes.number,
  onProcotolChange: PropTypes.func.isRequired,
};
