import isNumber from 'lodash/isNumber';

const computeToggle = years => {
  if (isNumber(years) && years > 0) {
    return true;
  }

  return false;
};

export default computeToggle;
