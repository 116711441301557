import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header } from 'semantic-ui-react';

import { StyledProjectsTabWrapper, StyledGrid } from './PermissionsContextsTab.style';
import { PermissionsContextWrapper } from './components';

const PermissionsContextsTab = ({ contextIds }) => (
  <StyledProjectsTabWrapper>
    <StyledGrid>
      <Grid.Row>
        <Grid.Column>
          <Header as="h1">Portal permissions</Header>
          <Header as="h3">View and edit permissions for the Portal</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          {contextIds && contextIds.map(contextId => (
            <PermissionsContextWrapper key={contextId} contextId={contextId} />
          ))}
        </Grid.Column>
      </Grid.Row>
    </StyledGrid>
  </StyledProjectsTabWrapper>
);

PermissionsContextsTab.defaultProps = {
  contextIds: null,
};

PermissionsContextsTab.propTypes = {
  contextIds: PropTypes.array,
};

export default PermissionsContextsTab;
