import React from 'react';
import PropTypes from 'prop-types';

import { PROJECT_SUITES_FIELD } from 'helpers/selectors';
import { PageHeader } from 'components/core';
import DataTable from 'components/DataTable/DataTableContainer';

import { ProjectSuiteActionLinks, AddProjectSuite } from './components';

const projectsTableLabels = [
  { text: 'Multi-site', field: 'visibleName', sortBy: 'name' },
  { text: 'Team', field: 'team', sortable: false },
  { text: 'Created', field: 'dateCreated', sortBy: 'createdAt' },
];

const ProjectSuites = ({ route }) => (
  <div>
    <PageHeader title="Multi-sites" />
    <DataTable
      labels={projectsTableLabels}
      actionLinks={ProjectSuiteActionLinks}
      route={route}
      createElement={AddProjectSuite}
      pagination={{
        type: PROJECT_SUITES_FIELD,
        args: {
          self: true,
          withFilter: true,
        },
      }}
    />
  </div>
);

ProjectSuites.propTypes = {
  route: PropTypes.string.isRequired,
};

export default ProjectSuites;
