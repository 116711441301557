import React from 'react';
import PropTypes from 'prop-types';

import DataTable from 'components/DataTable/DataTableContainer';
import { StyledDiv } from './LogsTable.style';
import { LogCustomRow } from './components';

const labels = [
  { text: 'Date', field: 'date', width: 2 },
  { text: 'Time', field: 'time', width: 2 },
  { text: 'Logs', field: 'displayHTML', width: 12 },
];

const LogsTable = ({ logsData }) => (
  <StyledDiv>
    <DataTable
      labels={labels}
      data={logsData}
      pagination={{
        args: {
          withFilter: true,
        },
      }}
      customTableRow={LogCustomRow}
    />
  </StyledDiv>
);


LogsTable.defaultProps = {
  logsData: [],
};

LogsTable.propTypes = {
  logsData: PropTypes.array,
};

export default LogsTable;
