import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import build from 'redux-object';

import { frontendPermissions } from 'gateway';
import { PORTAL_CONTEXT_ID } from 'helpers/sharedVariables';
import { dataSelector, currentUserSelector } from 'helpers/selectors';
import { checkPermissionByTypeAndResource } from 'helpers/sharedMethods';
import { getConfigName } from 'helpers/layoutSwitchHelpers';
import { selectors as permissionsSelectors } from 'application/tenant/console/utilities/permissions';
import LayoutRenderer from './LayoutRenderer';

export const mapStateToProps = (state, ownProps) => {
  const {
    resourceId,
    resourceType,
    selectionField = 'viewSelector',
    match: { params: { projectId } },
  } = ownProps;

  const data = dataSelector(state);
  const resource = build(data, resourceType, resourceId, {
    ignoreLinks: true,
  });
  const { id: userId, superadmin } = currentUserSelector(state);
  const userPermissions = permissionsSelectors.userPermissionsSelector(state)(userId);

  const hasProjectDeviceFirmwarePortalPermission = checkPermissionByTypeAndResource(
    'project', PORTAL_CONTEXT_ID, userPermissions, frontendPermissions.PROJECT_DEVICE_FIRMWARE,
  );
  const hasProjectDeviceFirmwareResourcePermission = checkPermissionByTypeAndResource(
    'project', projectId, userPermissions, frontendPermissions.PROJECT_DEVICE_FIRMWARE,
  );
  const superAdminOrHasFirmwarePermission = superadmin
    || hasProjectDeviceFirmwareResourcePermission
    || hasProjectDeviceFirmwarePortalPermission;

  return {
    configName: getConfigName(resource[selectionField]),
    superAdminOrHasFirmwarePermission,
  };
};

export default withRouter(connect(mapStateToProps)(LayoutRenderer));
