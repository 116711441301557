import { emDashedData } from 'helpers/sharedVariables';

class ZoneModel {

  constructor(data = {}) {
    this.id = data.id;
    this.name = data.name;
    this.level = data.level;
  }

  get formattedLevel() {
    const { level } = this;

    return level
      ? `${level}%`
      : emDashedData;
  }

}

export default ZoneModel;
