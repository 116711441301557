import { call } from 'redux-saga/effects';

import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import outputSectionActionCreators from '../actionCreators';

export default function* fetchPatches(action) {
  const { endpoint } = action.payload;
  const { response, error } = yield call(getPath, endpoint);

  const params = {
    response,
    error,
    successDisp: outputSectionActionCreators.fetchPatchesSuccess,
    failDisp: outputSectionActionCreators.fetchPatchesFailure,
  };
  yield call(processCall, params);
}
