import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  ORIGINS_FIELD,
  companiesAvailableThemesSelector,
  companySelector,
  currentUserSelector,
} from 'helpers/selectors';
import redirectIfNotFound from 'helpers/redirectIfNotFound';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { actionCreators } from '../utilities';
import { soldViaOptions } from './constants';
import Company from './Company';

export const mapStateToProps = (state, { match }) => {
  const { companyId } = match.params;

  const {
    deviceMapping,
    manufacturer,
    name,
    registeredName,
    email,
    tenantService,
    subdomain,
    themeName,
    mailgunDomain,
    twoFactorEnabled,
    trialPeriodDays,
    gracePeriodDays,
    renewalReminderDays,
    vouchersEnabled,
    renewalDate,
    renewalReminderDaysTwo,
    notificationsEnabled,
    soldVia,
  } = companySelector(state)(companyId) || {};
  const { superadmin } = currentUserSelector(state);

  return {
    companyId,
    deviceMapping,
    manufacturer,
    name,
    registeredName,
    email,
    tenantService,
    subdomain,
    themeName,
    mailgunDomain,
    superadmin,
    twoFactorEnabled,
    trialPeriodDays,
    gracePeriodDays,
    renewalReminderDays,
    vouchersEnabled,
    renewalDate,
    renewalReminderDaysTwo,
    notificationsEnabled,
    soldVia,
    themes: companiesAvailableThemesSelector(state),
    soldViaOptions,
  };
};

const mapDispatchToProps = {
  onEditCompany: actionCreators.editCompany,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  redirectIfNotFound('superadmin', false),
  chainedLoaderWithSaga(
    ({ companyId }) => actionCreators.fetchCompany(companyId, {
      include: [ORIGINS_FIELD],
    }),
  ),
)(Company);
