import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { USERS_FIELD } from 'helpers/selectors';
import withPolling from 'helpers/withPolling';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { addPagination } from 'helpers/paginationHelpers';
import { withProjectSuitePermissions } from 'gateway';
import { actionCreators as projectSuitesActionCreators } from 'pages/ProjectSuites/utilities';

import ProjectSuiteUsersTab from './ProjectSuiteUsersTab';

export const mapStateToProps = (state, { match }) => {
  const { id: projectSuiteId } = match.params;

  return {
    projectSuiteId,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withProjectSuitePermissions,
  withPolling(),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: USERS_FIELD },
      ({ projectSuiteId }) => projectSuitesActionCreators.fetchProjectSuiteUsers(
        projectSuiteId, {
          fields: {
            [USERS_FIELD]: [
              'firstName',
              'lastName',
              'email',
              'lastActionTime',
              'lastLoginTime',
              'superadmin',
              'suspended',
              'receivedInvitations',
            ],
            invitations: ['id', 'projectSuiteId'],
          },
          include: ['receivedInvitations'],
          sort: 'firstName',
          filter: 'name',
        }),
    ),
  ),
)(ProjectSuiteUsersTab);
