import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { PasswordResetForm } from './components';

const PasswordReset = ({ onSendPasswordResetToken }) => {
  const [sending, setSending] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSending(false);
    setConfirmed(false);
  };
  const handleSubmit = async values => {
    setSending(true);

    const errors = await new Promise(
      resolveForm => onSendPasswordResetToken({ values, resolveForm }),
    );

    setSending(false);

    if (!errors) {
      setConfirmed(true);
    }

    return errors;
  };

  return (
    <PasswordResetForm
      onSubmit={handleSubmit}
      sending={sending}
      confirmed={confirmed}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    />
  );
};

PasswordReset.propTypes = {
  onSendPasswordResetToken: PropTypes.func.isRequired,
};

export default PasswordReset;
