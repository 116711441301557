import React, { Fragment } from 'react';
import { FormGroup } from 'semantic-ui-react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import TextField from 'components/form/TextField/TextField';
import * as validators from 'components/form/formValidators';
import {
  FormFieldLabel,
  InputWrapper,
} from 'components/form/FormField/FormField.style';
import ToggleFieldInput from 'components/form/ToggleFieldInput/ToggleFieldInput';
import { TipIcon } from 'components/atomic';
import {
  StyledFormFieldWrapper,
} from '../../AddScheduleModalForm.style';
import {
  StyledField,
  StyledFormGroup,
  StyledParagraph,
} from './AdvancedTimeMode.style';
import scheduleFormFields from '../../constants';

const AdvancedTimeMode = ({ advancedMode, loading }) => (
  <Fragment>
    <FormGroup inline>
      <StyledFormFieldWrapper width={16}>
        <FormFieldLabel htmlFor="input">Time Mask</FormFieldLabel>
        <InputWrapper>
          <Field
            name="advancedMode"
            component={ToggleFieldInput}
          />
        </InputWrapper>
      </StyledFormFieldWrapper>
    </FormGroup>
    {!!advancedMode && (
      <Fragment>
        <StyledParagraph>
          The time mask will create events at every combination of hours,
          minutes and seconds entered below, for every date in this schedule.
          All fields are required.
        </StyledParagraph>
        <StyledFormGroup inline>
          <StyledField
            name={scheduleFormFields.hours}
            component={TextField}
            type="text"
            label="Hours"
            loading={loading}
            placeholder="0, 12, 16, 23"
            validate={validators.validateHours}
          />
          <TipIcon message="Enter comma-separated hours in the range 0 to 23. You may use * to match every hour." editMode={false} />
        </StyledFormGroup>
        <StyledFormGroup inline>
          <StyledField
            name={scheduleFormFields.minutes}
            component={TextField}
            type="text"
            label="Minutes"
            loading={loading}
            placeholder="0, 15, 20, 25"
            validate={validators.validateMinutes}
          />
          <TipIcon message="Enter up to 12 comma-separated minutes in the range 0 to 59." editMode={false} />
        </StyledFormGroup>
        <StyledFormGroup inline>
          <StyledField
            name={scheduleFormFields.seconds}
            component={TextField}
            type="text"
            label="Seconds"
            loading={loading}
            placeholder="0, 30"
            validate={validators.validateSeconds}
          />
          <TipIcon message="Enter up to 2 comma-separated seconds in the range 0 to 59." editMode={false} />
        </StyledFormGroup>
      </Fragment>
    )}
  </Fragment>
);

AdvancedTimeMode.defaultProps = {
  advancedMode: false,
  loading: false,
};

AdvancedTimeMode.propTypes = {
  advancedMode: PropTypes.bool,
  loading: PropTypes.bool,
};

export default AdvancedTimeMode;
