export default class LpcCreator {

  constructor(sixeyeFirmwareVersion, tabs) {
    this.model = 'LPC';
    this.sixeyeFirmwareVersion = sixeyeFirmwareVersion;
    this.manufacturerSDK = 'pharos';
    this.tabs = tabs;
  }

}
