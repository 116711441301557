import { takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';

import fetchSubscriptionPlans from './fetchSubscriptionPlans';
import fetchVoucherDetails from './fetchVoucherDetails';
import redeemVoucher from './redeemVoucher';
import generateVoucher from './generateVoucher';
import upgradeSitePlan from './upgradeSitePlan';
import loadVoucherPermissions from './loadVoucherPermissions';

function* subscriptionSaga() {
  yield takeLatest(constants.FETCH_SUBSCRIPTION_PLANS_REQUEST, fetchSubscriptionPlans);
  yield takeLatest(constants.FETCH_VOUCHER_DETAILS_REQUEST, fetchVoucherDetails);
  yield takeLatest(constants.REDEEM_VOUCHER_REQUEST, redeemVoucher);
  yield takeLatest(constants.GENERATE_VOUCHER_REQUEST, generateVoucher);
  yield takeLatest(constants.UPGRADE_SITE_PLAN_REQUEST, upgradeSitePlan);
  yield takeLatest(constants.LOAD_VOUCHER_PERMISSIONS_REQUEST, loadVoucherPermissions);
}

export default subscriptionSaga;
