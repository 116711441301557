import constants from 'dispatcherConst';
import { messages } from 'data/notifications/notificationsConst';

export const resetOTPSecret = id => ({
  type: constants.RESET_OTP_SECRET,
  payload: { id },
});

export const resetOTPSecretSuccess = response => ({
  type: constants.RESET_OTP_SECRET_SUCCESS,
  payload: {
    response,
    message: messages.TWO_FACTOR_DISABLED,
  },
});

export const resetOTPSecretFailure = error => ({
  type: constants.RESET_OTP_SECRET_FAILED,
  payload: { error },
});
