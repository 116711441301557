import React from 'react';
import PropTypes from 'prop-types';
import NotificationRow from '../NotificationRow/NotificationRow';

const NotificationTypeRows = ({
  notificationType,
  entities,
  userId,
  notificationsEnabled,
}) => (
  <React.Fragment>
    {entities.map(entity => (
      <NotificationRow
        notificationType={notificationType}
        entity={entity}
        userId={userId}
        key={entity.id}
        notificationsEnabled={notificationsEnabled}
      />
    ))}
  </React.Fragment>
);

NotificationTypeRows.defaultProps = {
  notificationsEnabled: false,
};

NotificationTypeRows.propTypes = {
  notificationType: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    notificationContext: PropTypes.string.isRequired,
  }).isRequired,
  entities: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  userId: PropTypes.string.isRequired,
  notificationsEnabled: PropTypes.bool,
};

export default NotificationTypeRows;
