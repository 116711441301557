import React from 'react';
import PropTypes from 'prop-types';

import * as validators from 'components/form/formValidators';
import { FieldWrapper } from 'components/atomic';
import {
  InputWrapper,
} from 'components/form/FormField/FormField.style';
import SelectField from 'components/form/SelectFieldInput/SelectFieldInput';
import { generateIntegerRange, optionsFromArrayOfString } from './utilities';
import { StyledField, StyledLabel } from './DropdownActionField.style';

const DropdownActionField = ({
  name,
  label,
  uiType,
  list,
  gteq,
  lteq,
  editMode,
  change,
}) => {
  const options = [];
  let validation;
  switch (uiType) {
    case 'list':
      options.push(...optionsFromArrayOfString(list));
      validation = validators.required;
      break;
    case 'range':
      options.push(...generateIntegerRange(gteq, lteq));
      validation = validators.integerRequired;
      break;
    default:
      options.push({
        key: 'not_found',
        text: 'Error. Options not found',
        value: 'not_found',
      });
  }
  return (
    <FieldWrapper>
      {!editMode && <StyledLabel htmlFor="input">{label}</StyledLabel>}
      <InputWrapper>
        <StyledField
          name={name}
          placeholder={label}
          component={SelectField}
          options={options}
          validate={validation}
          editMode={editMode}
          className="edit"
          onChangeCallback={change}
        />
      </InputWrapper>
    </FieldWrapper>
  );
};

DropdownActionField.defaultProps = {
  list: null,
  gteq: null,
  lteq: null,
  editMode: false,
  change: null,
};

DropdownActionField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  uiType: PropTypes.string.isRequired,
  list: PropTypes.array,
  gteq: PropTypes.number,
  lteq: PropTypes.number,
  editMode: PropTypes.bool,
  change: PropTypes.func,
};

export default DropdownActionField;
