import React, { Fragment } from 'react';
import { Responsive } from 'semantic-ui-react';

import { uploadsType } from 'data/types';
import { sizes } from 'themes/mixins';
import UploadsContext from './UploadsContext/UploadsContext';
import UploadsModal from './UploadsModal/UploadsModalContainer';
import UploadsMobileController from './UploadsMobileController/UploadsMobileControllerContainer';
import UploadsFileNotFound from './UploadsFileNotFound/UploadsFileNotFoundContainer';

const UploadsWidget = ({ uploads }) => (
  !uploads.length
    ? null
    : (
      <UploadsContext.Provider>
        <Fragment>
          <UploadsFileNotFound />
          <Responsive
            maxWidth={sizes.md - 1}
            as={UploadsMobileController}
            uploads={uploads}
          />
          <Responsive
            minWidth={sizes.md}
            as={UploadsModal}
            uploads={uploads}
          />
        </Fragment>
      </UploadsContext.Provider>
    )
);

UploadsWidget.defaultProps = {
  uploads: [],
};

UploadsWidget.propTypes = {
  uploads: uploadsType,
};

export default UploadsWidget;
