import { connect } from 'react-redux';

import { uploadsStatusFlagsSelector } from 'helpers/uploadsSelectors';
import UploadsControls from './UploadsControls';

const mapStateToProps = state => ({
  ...uploadsStatusFlagsSelector(state),
});

export default connect(mapStateToProps)(UploadsControls);
