import { call, select } from 'redux-saga/effects';

import { postPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';

import actionCreators from '../actionCreators';
import selectors from '../selectors';

function* generateVoucher(action) {
  const {
    subscriptionPlanCode,
    subscriptionLength,
    description,
    endpoint,
    resolveForm,
    rejectForm,
  } = action.payload;

  const { id: companyId } = (yield select(selectors.selectedTenantSelector)) || {};

  const data = {
    size: 1,
    subscriptionPlanCode,
    subscriptionLength: Number(subscriptionLength) * 12,
    description,
    companyId,
  };

  const payload = {
    data,
  };

  const { response, error } = yield call(postPath, endpoint, payload);

  const params = {
    response,
    error,
    successDisp: actionCreators.generateVoucherSuccess,
    failDisp: actionCreators.generateVoucherFailure,
    resolveForm,
    rejectForm,
  };

  yield call(processCall, params);
}

export default generateVoucher;
