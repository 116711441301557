import { DateTime } from 'luxon';

const formatDate = value => {
  if (!value) {
    return null;
  }

  if (value.includes('T')) {
    return DateTime.fromISO(value.split('T')[0]).toFormat('dd/MM/y');
  }

  return DateTime.fromISO(value).toFormat('dd/MM/y');
};

export default formatDate;
