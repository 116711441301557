import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { validDisplayName } from 'components/form/formValidators';
import { trimLeadingWhitespace } from 'components/form/formNormalizers';
import formNames from 'components/form/formNames';
import { StyledEditDevice } from './InlineEditDevice.style';

class InlineEditDevice extends Component {

  constructor() {
    super();
    this.handleEditDeviceName = this.handleEditDeviceName.bind(this);
  }

  handleEditDeviceName(values) {
    const { deviceId, onEditDeviceName } = this.props;

    return new Promise((resolveForm, rejectForm) => {
      onEditDeviceName({ values: { id: deviceId, ...values }, resolveForm, rejectForm });
    });
  }

  render() {
    const { deviceName } = this.props;
    return (
      <StyledEditDevice
        name="name"
        type="text"
        value={deviceName}
        validator={validDisplayName}
        normalize={trimLeadingWhitespace}
        onSubmit={this.handleEditDeviceName}
        form={formNames.deviceName}
        allowCancelOnPristine
      />
    );
  }

}

InlineEditDevice.propTypes = {
  deviceId: PropTypes.string.isRequired,
  onEditDeviceName: PropTypes.func.isRequired,
  deviceName: PropTypes.string,
};

InlineEditDevice.defaultProps = {
  deviceName: undefined,
};

export default InlineEditDevice;
