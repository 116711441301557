import React from 'react';
import PropTypes from 'prop-types';

import DataTableRow from 'components/DataTable/DataTableRow/DataTableRow';
import UserModel from 'models/UserModel';

const UserTableRow = ({
  item: user,
  projectId,
  projectSuiteId,
  children,
  ...rest
}) => {
  const userModel = new UserModel(user).setStatus(projectId, projectSuiteId);

  return (
    <DataTableRow item={userModel} {...rest} />
  );
};

UserTableRow.defaultProps = {
  projectId: undefined,
  projectSuiteId: undefined,
  children: null,
};

UserTableRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  projectId: PropTypes.string,
  projectSuiteId: PropTypes.string,
  children: PropTypes.node,
};

export default UserTableRow;
