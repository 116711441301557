import React from 'react';

const BelowNormal = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="6"
    fill="none"
    viewBox="0 0 14 6"
  >
    <path
      stroke="#5394FB"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M1 1.087L7 5l6-3.913"
    />
  </svg>
);

export default BelowNormal;
