import { call } from 'redux-saga/effects';

import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';

import actionCreators from '../actionCreators';

function* fetchProjectSuiteProjectsFilter(action) {
  const { endpoint, projectSuiteId, urlParams, ...rest } = action.payload;
  const { response, error } = yield call(getPath, endpoint, { urlParams });

  const enhancedResponse = { ...response, projectSuiteId };

  const params = {
    response: enhancedResponse,
    error,
    successDisp: actionCreators.fetchProjectSuiteProjectsFilterSuccess,
    failDisp: actionCreators.fetchProjectSuiteProjectsFilterFailure,
    ...rest,
  };

  yield call(processCall, params);
}

export default fetchProjectSuiteProjectsFilter;
