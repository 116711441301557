import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import DataTableText from 'components/DataTable/DataTableText/DataTableText';
import { StyledTableRow } from './LogCustomRow.style';
import { LogRadioCell, RenderLabels } from './components';

const LogCustomRow = props => {
  const [open, setOpen] = useState(false);
  const handleChange = () => {
    setOpen(!open);
  };
  const { item } = props;

  return (
    <>
      <StyledTableRow
        {...props}
        onClick={handleChange}
        customRadioButton={LogRadioCell}
        open={open}
      >
        <DataTableText />
        <DataTableText />
        <DataTableText />
      </StyledTableRow>
      {open
        && (
          <>
            <Table.Row>
              <Table.Cell>
                &nbsp;
              </Table.Cell>
              <Table.Cell colSpan="3">
                <RenderLabels item={item} />
              </Table.Cell>
            </Table.Row>
          </>
        )
      }
    </>
  );
};

LogCustomRow.propTypes = {
  item: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    displayText: PropTypes.string.isRequired,
    initiator: PropTypes.string.isRequired,
    recipient: PropTypes.string,
    actionType: PropTypes.string.isRequired,
  }).isRequired,
};

export default LogCustomRow;
