import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import { ListOfTasks } from './components';

const ProjectTasksTab = ({ projectId }) => (
  <Grid>
    <Grid.Row>
      <Grid.Column>
        <ListOfTasks projectId={projectId} />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

ProjectTasksTab.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default ProjectTasksTab;
