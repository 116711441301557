import { SCHEDULER_TYPES } from '../constants';

import validateSpecificDatesView from './validateSpecificDatesView';
import validateDailyRecurrenceView from './validateDailyRecurrenceView';
import validateWeeklyRecurrenceView from './validateWeeklyRecurrenceView';
import validateMonthlyRecurrenceView from './validateMonthlyRecurrenceView';
import validateYearlyRecurrenceView from './validateYearlyRecurrenceView';

const validateSchedulerStep = ({
  schedulerType,
  recurrenceParams,
  endingCriteria,
}) => {

  switch (schedulerType) {
    case SCHEDULER_TYPES.NONE:
      return {
        isValid: true,
        errors: {},
      };
    case SCHEDULER_TYPES.DATES:
      return validateSpecificDatesView({
        recurrenceParams,
      });
    case SCHEDULER_TYPES.DAILY:
      return validateDailyRecurrenceView({
        recurrenceParams,
        endingCriteria,
      });
    case SCHEDULER_TYPES.WEEKLY:
      return validateWeeklyRecurrenceView({
        recurrenceParams,
        endingCriteria,
      });
    case SCHEDULER_TYPES.MONTHLY:
      return validateMonthlyRecurrenceView({
        recurrenceParams,
        endingCriteria,
      });
    case SCHEDULER_TYPES.YEARLY:
      return validateYearlyRecurrenceView({
        recurrenceParams,
        endingCriteria,
      });
    default:
      return {
        isValid: false,
        errors: {},
      };
  }
};

export default validateSchedulerStep;
