import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getDisplayName } from 'helpers/sharedMethods';
import { Button } from 'components/atomic';
import ConfirmModal from 'components/elements/ConfirmModal/ConfirmModal';

const withDeleteOrigin = WrappedComponent => class extends Component {

  static displayName = `WithDeleteOrigin(${getDisplayName(WrappedComponent)})`;

  static propTypes = {
    companyId: PropTypes.string.isRequired,
    item: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    onDeleteOrigin: PropTypes.func.isRequired,
  };

  constructor() {
    super();

    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleConfirm() {
    const { companyId, item, onDeleteOrigin } = this.props;

    onDeleteOrigin(item, companyId);
  }

  render() {
    const { item: { name } } = this.props;

    const trigger = <WrappedComponent title="Delete" />;

    return (
      <ConfirmModal
        trigger={trigger}
        cancelButton="Cancel"
        confirmButton={<Button negative primary={false}>Delete Origin</Button>}
        content={(
          <div>
            <p>
              Are you sure you want to delete origin <strong>{name}</strong>?
              This action cannot be undone.
            </p>
          </div>
        )}
        header="Confirm origin deletion"
        onConfirm={this.handleConfirm}
      />
    );
  }

};

export default withDeleteOrigin;
