const getUniqueTaskIds = (taskId, taskIds) => {
  let allTaskIds = [];

  if (taskId) {
    allTaskIds.push(taskId);
  }

  if (taskIds && Array.isArray(taskIds)) {
    allTaskIds = [...allTaskIds, ...taskIds];
  }

  return [...new Set(allTaskIds)];
};

export default getUniqueTaskIds;
