import constants from 'dispatcherConst';

const invalidInputNotification = message => ({
  type: constants.INVALID_INPUT_NOTIFICATION,
  payload: {
    message,
  },
});

export default invalidInputNotification;
