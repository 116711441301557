import React from 'react';
import PropTypes from 'prop-types';

import StyledFlatIcon from './DataTableIcon.style';

const DataTableIcon = ({ name }) => (
  <StyledFlatIcon name={name} />
);

DataTableIcon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default DataTableIcon;
