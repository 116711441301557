import { call, takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import { postPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import ApiRequestBuilder from 'helpers/ApiRequestBuilder';
import { INVITE_USER } from 'helpers/selectors';
import inviteUserActionCreators from './inviteUserActionCreators';

export function* inviteUser(action) {
  const { endpoint, params: { values, rejectForm, resolveForm } } = action.payload;
  if (values.projectId === undefined) { delete values.projectId; }

  const body = new ApiRequestBuilder(INVITE_USER)
    .setId(Date.now().toString())
    .setAttributes(values);

  const { response, error } = yield call(postPath, endpoint, body);
  if (error instanceof Error) { error.message = 'No connection to server. Try later.'; }

  const params = {
    response,
    error,
    resolveForm,
    rejectForm,
    successDisp: inviteUserActionCreators.inviteUserSuccess,
    failDisp: inviteUserActionCreators.inviteUserFailure,
  };
  yield call(processCall, params);
}

function* inviteUserSaga() {
  yield takeLatest(constants.INVITE_USER_REQUEST, inviteUser);
}

export default inviteUserSaga;
