import styled from 'styled-components';
import { Select } from 'semantic-ui-react';

const StyledSelect = styled(Select)`
  width: 100%;
  word-break: break-all;

  &.ui.dropdown .menu > .item {
    display: flex;
    flex-direction: row-reverse;
    .text {
      flex: 1;
    }
  }
`;

export default StyledSelect;
