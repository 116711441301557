import React from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

import DefinitionListAccordion from '../../DefinitionListAccordion/DefinitionListAccordion';
import DefinitionList from '../DefinitionList';
import DefinitionListItemSingle from './DefinitionListItemSingle/DefinitionListItemSingle';

const DefinitionListItem = ({ data, title, format, autoincrement, isOnline }) => {
  const dataIsArray = data && isArray(data);
  const dataIsObject = data && isObject(data);

  if (dataIsArray) {
    return (
      <DefinitionListAccordion
        headingText={title}
        labels={[{ field: title }]}
        pluralDefinitionList={data.map(name => ({ [title]: name }))}
      />
    );
  }

  if (dataIsObject) {
    if (data.timestamp) {
      const { value, timestamp } = data;

      return (
        <DefinitionListItemSingle
          title={title}
          value={value}
          format={format}
          lastUpdate={timestamp}
          autoincrement={autoincrement}
          isOnline={isOnline}
        />
      );
    }

    return (
      <DefinitionList data={data} />
    );
  }

  return (
    <DefinitionListItemSingle
      title={title}
      value={data}
      format={format}
      autoincrement={autoincrement}
      isOnline={isOnline}
    />
  );
};

DefinitionListItem.defaultProps = {
  data: undefined,
  title: undefined,
  format: undefined,
  autoincrement: undefined,
  isOnline: false,
};

DefinitionListItem.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
    ).isRequired,
    PropTypes.bool.isRequired,
    PropTypes.number.isRequired,
    PropTypes.object.isRequired,
    PropTypes.string.isRequired,
  ]),
  title: PropTypes.string,
  format: PropTypes.string,
  autoincrement: PropTypes.bool,
  isOnline: PropTypes.bool,
};

export default DefinitionListItem;
