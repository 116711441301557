import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

const resetFieldFormValues = (
  stateToUpdate,
  formValues,
  resetFieldValue,
  actionTypeDeviceIdKey,
) => {
  if (isEmpty(stateToUpdate) || isEmpty(formValues)) {
    return;
  }

  const updatedFieldsKeysArray = Object.keys(stateToUpdate[Object.keys(stateToUpdate)[0]]);
  updatedFieldsKeysArray.forEach(key => {
    const checkUpdatedKeyCondition = stateToUpdate[Object.keys(stateToUpdate)[0]][key] === false;

    if (!isUndefined(formValues[key]) && checkUpdatedKeyCondition) {
      resetFieldValue(`${key}${actionTypeDeviceIdKey}`);
    }
  });
};

export default resetFieldFormValues;
