import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { COMPANIES_FIELD, companiesSelector } from 'helpers/selectors';
import { actionCreators as companyActionCreators } from 'application/tenant/console/company/utilities';

import CompaniesSelect from './CompaniesSelect';

export const mapStateToProps = state => {
  const companies = companiesSelector(state);

  return {
    companies,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, null),
  chainedLoaderWithSaga(
    () => companyActionCreators.fetchCompanies({
      fields: {
        [COMPANIES_FIELD]: [
          'name',
        ],
      },
      sort: 'name',
    }),
  ),
)(CompaniesSelect);
