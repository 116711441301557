import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';

import { mergeCustomizer } from 'helpers/sharedMethods';
import { USER_PERMISSIONS_FIELD } from 'helpers/selectors';
import removeResourceType from './removeResourceType';

const reduceUserPermissions = (state, action) => {
  const {
    userId,
    resourceType,
    resourceId,
    getResourcePermissions,
    getContextPermissions,
  } = action.payload.response;

  const userPermissions = getResourcePermissions || getContextPermissions;
  const response = Object.keys(userPermissions).reduce(
    (modifiedResponse, getPermissionId) => {
      const getPermission = userPermissions[getPermissionId];
      const { id, attributes: { permissions: modifiedPermissions = [] } } = getPermission;
      const permissions = modifiedPermissions.map(
        ({ name }) => ({ name: removeResourceType(name, resourceType) }),
      );

      return merge(
        {},
        modifiedResponse,
        {
          [USER_PERMISSIONS_FIELD]: {
            [userId]: {
              [id]: {
                id,
                attributes: {
                  permissions,
                  resourceType,
                  resourceId,
                },
              },
            },
          },
        },
      );
    },
    {},
  );

  return mergeWith({}, state, response, mergeCustomizer);
};

export default reduceUserPermissions;
