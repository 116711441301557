import { DateTime } from 'luxon';

class NotificationEventModel {

  constructor(notification = {}, user, self = false) {
    this.id = notification.id;
    this.message = notification.message;
    this.notificationType = notification.notificationType;
    this.occurredAt = notification.occurredAt;
    this.readAt = notification.readAt;
    this.archivedAt = notification.archivedAt;
    this.notifiable = notification.notifiable;
    this.project = notification.project;
    this.projectSuite = notification.projectSuite;

    this.currentUser = self && user;
  }

  get dateOccurred() {
    if (!this.occurredAt) {
      return null;
    }

    return DateTime.fromISO(this.occurredAt).toLocaleString({
      weekday: 'short',
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  }

  get deviceName() {
    if (!this.notifiable) {
      return null;
    }

    const { name } = this.notifiable;

    return name;
  }

  get siteName() {
    if (!this.project) {
      return null;
    }

    const { name } = this.project;

    return name;
  }

  get deviceUrl() {
    if (!this.notifiable || !this.project) {
      return null;
    }

    const { id: deviceId } = this.notifiable;
    const { id: siteId } = this.project;

    return `/sites/${siteId}/devices/${deviceId}`;
  }

}

export default NotificationEventModel;
