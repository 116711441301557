import { compose } from 'redux';
import { connect } from 'react-redux';

import { frontendPermissions, permissionsPass } from 'gateway';
import { actionCreators } from 'pages/ProjectSuites/utilities';

import siteMapEmptyClickBehaviour from './siteMapEmptyClickBehaviour';

const mapDispatchToProps = {
  onFetchReverseGeocoding: actionCreators.fetchProjectSuiteReverseGeocoding,
};

export default compose(
  connect(null, mapDispatchToProps),
  permissionsPass(frontendPermissions.PROJECT_SUITE_EDIT),
  siteMapEmptyClickBehaviour,
);
