import React from 'react';
import PropTypes from 'prop-types';

import EnableButton from '../EnableButton/EnableButton';

const EnableButtonForm = ({ handleSubmit }) => (
  <form>
    <EnableButton onSubmit={handleSubmit} />
  </form>
);

EnableButtonForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default EnableButtonForm;
