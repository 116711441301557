import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { complexValueType } from 'data/types';
import { enforceDateTime } from 'helpers/sharedMethods';
import { deviceTimeWithOffset, deviceTimeWithoutOffset } from './utilities';
import { formFields } from './PharosTimeAdjustmentV1Form/PharosTimeAdjustmentV1Form';
import PharosTimeAdjustmentV1Form from './PharosTimeAdjustmentV1Form/PharosTimeAdjustmentV1FormContainer';

class PharosTimeAdjustmentV1 extends Component {

  constructor() {
    super();

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit({ date, time }) {
    const { resourceId, resourceOffset, onSetTimestamp } = this.props;
    const { value: offsetValue } = resourceOffset;
    const datetime = enforceDateTime(date, offsetValue);

    const utcTimestamp = (
      datetime
        .toUTC()
        .startOf('day')
        .toMillis() / 1000
    );
    const timestamp = utcTimestamp + deviceTimeWithoutOffset(time, offsetValue);

    return new Promise((resolveForm, rejectForm) => {
      onSetTimestamp(resourceId, { values: { timestamp }, resolveForm, rejectForm });
    });
  }

  render() {
    const {
      resourceId,
      shouldDisplay,
      resourceCurrentTime,
      title,
      resourceOffset,
    } = this.props;
    if (!shouldDisplay) return null;

    const { value: currentTimeValue } = resourceCurrentTime;
    const { value: offsetValue } = resourceOffset;

    return (
      <PharosTimeAdjustmentV1Form
        onSubmit={this.handleSubmit}
        initialValues={{
          [formFields.date]: currentTimeValue * 1000,
          [formFields.time]: deviceTimeWithOffset(currentTimeValue, offsetValue),
        }}
        resourceId={resourceId}
        title={title}
      />
    );
  }

}

PharosTimeAdjustmentV1.defaultProps = {
  resourceId: null,
  resourceCurrentTime: null,
  resourceOffset: null,
};

PharosTimeAdjustmentV1.propTypes = {
  shouldDisplay: PropTypes.bool.isRequired,
  resourceId: PropTypes.string,
  resourceCurrentTime: complexValueType(PropTypes.number),
  resourceOffset: complexValueType(PropTypes.number),
  title: PropTypes.string.isRequired,
  onSetTimestamp: PropTypes.func.isRequired,
};

export default PharosTimeAdjustmentV1;
