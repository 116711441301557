import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { singleProjectSelector } from 'helpers/selectors';

import { selectors, actionCreators } from './utilities';
import AddAutomatedOperation from './AddAutomatedOperation';

export const mapStateToProps = (state, {
  match,
  scheduleId,
  automatedOperationId,
  resourceType,
}) => {
  const { id: projectId } = match.params;
  const { isConstruction, isSuspended } = singleProjectSelector(state, projectId) || {};

  return {
    resourceId: projectId,
    resourceType: resourceType || 'project',
    automatedOperationId,
    scheduleId,
    isConstruction,
    isSuspended,
    isLoading: selectors.isLoadingSelector(state),
    selectedActions: selectors.selectedActionsSelector(state),
    selectedTasks: selectors.selectedTasksSelector(state),
    taskName: selectors.taskNameSelector(state),
    selectedActionsForm: state.form.selectedActions,
    taskSchedulerForm: state.form.taskScheduler,
    schedulerType: selectors.schedulerTypeSelector(state),
    recurrenceParams: selectors.recurrenceParamsSelector(state),
    endingCriteria: selectors.endingCriteriaSelector(state),
    existingSchedule: selectors.existingScheduleSelector(state),
  };
};

const mapDispatchToProps = {
  onAddTaskScheduler: actionCreators.addTaskScheduler,
  onResetTaskScheduler: actionCreators.resetTaskScheduler,
  onRecurrenceErrorsChange: actionCreators.setRecurrenceErrors,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AddAutomatedOperation);
