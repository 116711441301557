import React from 'react';
import PropTypes from 'prop-types';

import ContextModel from 'models/ContextModel';
import { StyledContextsRow } from './ContextsRow.style';

const ContextsRow = ({
  item: context,
  item: { id },
  onAddContextId,
  ...rest
}) => {
  const handleAddContextId = () => onAddContextId(id);
  const contextModel = new ContextModel(context);

  return (
    <StyledContextsRow item={contextModel} {...rest} onClick={handleAddContextId} />
  );
};

ContextsRow.propTypes = {
  onAddContextId: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default ContextsRow;
