import styled from 'styled-components';
import { Table } from 'semantic-ui-react';
import filterProps from 'helpers/filterProps';

import DataTableRow from 'components/DataTable/DataTableRow/DataTableRow';

const FilteredProps = filterProps([
  'hideHeader',
])(Table.Cell);

export const StyledTableRow = styled(DataTableRow)`
  td {
    font-size: 12px;
    height: 30px !important;

    @media only screen and (min-width: 991px) {
      padding-top: 0.2em !important;
      padding-bottom: 0.2em !important;
    }
  }

  @media only screen and (max-width: 991px) {
    td {
      padding-top: 0.7em;
    } 
  }
`;

export const SchedulesTableWrapperCell = styled(FilteredProps)`
  padding-left: 0px !important;

  @media only screen and (max-width: 991px) {
    padding-left: 10px !important;
  }

  & .ui.secondary.menu {
    margin-bottom: 0px;
  }

  & .dimmable {
    padding: 0em;
  }

  & div:last-of-type {
    margin-top: 0px;
  }

  & .ui.column.sortable.table td {
    border-top-width: 1px;
    border-bottom-width: 0px !important;
  }

  & .ui.column.sortable.table tr th {
    display: ${({ hideHeader }) => (hideHeader ? 'none' : '')};
  }

  & .ui.column.sortable.table tr th:first-of-type {
    width: 0px;
    min-width: 0px;
    padding: 0.1em;
  }

  & .ui.column.sortable.table tr td:first-of-type {
    width: 0px;
    min-width: 0px;
    padding: 0.1em;
  }

  @media only screen and (max-width: 991px) {
    & .ui.column.sortable.table td {
      border-bottom-width: 1px !important;
    }
  }

  & .ui.menu:not(.vertical) .item>.button {
    font-size: 0.8em;
  }

  & .ui.column.sortable.table tr {
    padding-left: 0px;
  }
`;

export const StyledScheduledTaskRow = styled(DataTableRow)`
  cursor: pointer;

  @media only screen and (max-width: 991px) {
    padding-left: 75px !important;
  }
`;

export default StyledTableRow;
