import { put, call, takeLatest, select } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import noop from 'lodash/noop';
import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import { paginatedSnapshotsSelector, valueOverrideSelector } from 'helpers/selectors';
import { prepareResponseData } from 'helpers/paginationHelpersClient';
import withValueOverrideActionCreators from 'helpers/withValueOverride/withValueOverrideActionCreators';
import SnapshotsSectionActionCreators from './SnapshotsSectionActionCreators';

export function* fetchSnapshotsData(action) {
  const { endpoint, urlParams, resourceType } = action.payload;

  const { response, error } = yield call(getPath, endpoint, { urlParams });

  const params = {
    response,
    error,
    successDisp: SnapshotsSectionActionCreators.fetchSnapshotsSuccess,
    failDisp: SnapshotsSectionActionCreators.fetchSnapshotsFailure,
    resourceType,
  };
  yield call(processCall, params);

  if (response) {
    const enhancedResponse = yield call(prepareResponseData, response, urlParams);
    yield put(SnapshotsSectionActionCreators
      .fetchSnapshotsSuccessPaginated(enhancedResponse));
  }
}


export function* snapshotOverride({ payload }) {
  const { deviceId, attributes: { meta: { snapshotId } } } = payload.response.params;

  const snapshots = yield select(paginatedSnapshotsSelector);
  const overrideSelector = yield select(valueOverrideSelector);

  const activeSnapshotId = (snapshots.find(s => s.active) || {}).id;

  const triggeredSnapshot = snapshots.find(s => s.id === snapshotId);
  const triggeredSnapshotOverrides = overrideSelector(`override.snapshot.${deviceId}.${snapshotId}.active`);

  const nextActiveState = (triggeredSnapshotOverrides)
    ? !triggeredSnapshotOverrides.value : !triggeredSnapshot.active;

  yield put(withValueOverrideActionCreators.resetOverride(`override.snapshot.${deviceId}`));

  yield put(withValueOverrideActionCreators.setOverride(`override.snapshot.${deviceId}.${snapshotId}.active`, nextActiveState, noop));

  if (activeSnapshotId && activeSnapshotId !== snapshotId) {
    yield put(withValueOverrideActionCreators.setOverride(`override.snapshot.${deviceId}.${activeSnapshotId}.active`, false, noop));
  }
}

const snapshotActionSelector = ({ type, payload }) => (
  type === constants.PERFORM_ACTION_SUCCESS
  && payload.response.params
  && payload.response.params.attributes.action === 'fire_snapshot'
);

function* SnapshotsSectionSaga() {
  yield takeLatest(constants.FETCH_SNAPSHOTS_REQUEST, fetchSnapshotsData);
  yield takeLatest(snapshotActionSelector, snapshotOverride);
}

export default SnapshotsSectionSaga;
