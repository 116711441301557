import styled from 'styled-components';
import em from 'polished/lib/helpers/em';

import colors from 'themes/colors';
import { baseFontSize } from 'themes/variables';
import { defaultBorder, defaultFocus } from 'themes/mixins';
import FlatIcon from 'components/elements/FlatIcon';

// Should match height of semantic-ui input element
const height = em(36, baseFontSize);

export const NumberInputWrapper = styled.div`
  position: relative;
  font-size: 1em;

  ${({ disabled }) => disabled && 'cursor: not-allowed;'}
`;

export const NumberInputContainer = styled.div`
  ${defaultBorder};
  position: relative;
  display: inline-table;
  max-width: 400px;
  width: 100%;
  height: ${height};
  line-height: ${height};
  margin: 0;

  overflow: hidden;
  border-collapse: separate;
  background-color: ${colors.white};
  white-space: nowrap;

  ${({ active }) => active && defaultFocus};
  ${({ disabled }) => disabled && 'pointer-events: none;'}
`;

export const NumberInputElement = styled.input`
  position: relative;
  display: inline;
  height: inherit;
  margin: 0;
  padding: 0!important;

  border: none!important;
  outline: 0;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  touch-action: manipulation;

  &:first-of-type {
    margin-left: 1em!important;
  }

  &:last-of-type {
    margin-right: 1em!important;
  }
`;

export const NumberInputActions = styled.span`
  position: relative;
  display: table-cell;
  width: 1%;
  height: 100%;

  border: none;
  border-left: inherit;
  border-color: inherit;
  outline: none;
  box-shadow: none;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
`;

export const NumberInputButton = styled.button`
  position: relative;
  display: block;
  width: 1.9em;
  height: 50%;
  line-height: 50%;
  margin: 0;
  padding: 0;

  border: none;
  outline: none;
  color: inherit;
  box-shadow: none;
  background: none;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  font-size: inherit;
  touch-action: manipulation;

  &:first-of-type {
    border-bottom: 1px solid;
    border-color: inherit;
  }

  &:focus {
    border: 2px solid black;
  }
`;

export const ArrowIcon = styled(FlatIcon)`
  display: inline-block;
  font-size: 1em !important;
`;
