import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const fetchBackendVersion = () => ({
  type: constants.FETCH_BACKEND_VERSION,
  payload: { endpoint: endpoints.BACKEND_VERSION },
});

export const fetchBackendVersionSuccess = data => ({
  type: constants.FETCH_BACKEND_VERSION_SUCCESS,
  payload: { data },
});

export const fetchBackendVersionFailure = error => ({
  type: constants.FETCH_BACKEND_VERSION_FAILED,
  payload: { error },
});
