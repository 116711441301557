import { DateTime } from 'luxon';

const jsDateToDateTime = (date, zone) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return DateTime.fromObject({ year, month, day, zone });
};

export default jsDateToDateTime;
