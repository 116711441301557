import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import isBoolean from 'lodash/isBoolean';

import { AUTOMATED_OPERATIONS_FIELD } from 'helpers/selectors';
import ApiRequestBuilder from 'helpers/ApiRequestBuilder';

class AutomatedOperationModel {

  constructor(automatedOperation = {}) {
    this.id = automatedOperation.id;
    this.name = automatedOperation.name;
    this.description = automatedOperation.description;
    this.lockVersion = automatedOperation.lockVersion;
    this.projectId = automatedOperation.projectId;
    this.projectSuiteId = automatedOperation.projectSuiteId;
    this.occurrenceColour = automatedOperation.occurrenceColour;
    this.enabled = automatedOperation.enabled;
    this.hidden = automatedOperation.hidden;
    this.priority = automatedOperation.priority;
    this.priorityType = automatedOperation.priorityType;
    this.taskIds = AutomatedOperationModel.getTaskIds(automatedOperation.taskIds);
    this.mode = automatedOperation.mode;
  }

  static getTaskIds(value) {
    if (Array.isArray(value)) {
      return value;
    }

    if (isString(value) && value.length === 0) {
      return [];
    }

    if (isString(value)) {
      return value.split(',');
    }

    if (value === undefined) {
      return [];
    }

    if (isBoolean(value) || isObject(value)) {
      return [];
    }

    return value;
  }

  static requestBody(values) {
    return new ApiRequestBuilder(AUTOMATED_OPERATIONS_FIELD)
      .setAttributes(values);
  }

}

export default AutomatedOperationModel;
