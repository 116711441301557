import { connect } from 'react-redux';

import { actionCreators } from 'application/tenant/console/Firmware/utilities';

import FirmwaresActionLinks from './FirmwaresActionLinks';

const mapDispatchToProps = {
  onTransferDeviceFirmware: actionCreators.transferDeviceFirmware,
};

export default connect(null, mapDispatchToProps)(FirmwaresActionLinks);
