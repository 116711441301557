import has from 'lodash/has';

import UserModel from 'models/UserModel';

const controlPanelUsersSelector = (state, resourceId) => {
  const { controlPanelUsers } = state.data;

  if (!has(controlPanelUsers, resourceId)) {
    return [];
  }

  const users = controlPanelUsers[resourceId];

  return users.map(user => new UserModel({
    id: user.id,
    ...user.attributes,
  }));
};

export default controlPanelUsersSelector;
