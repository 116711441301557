import constants from 'dispatcherConst';

const initialState = {
  verifying: false,
  signedUp: false,
  webRedirection: undefined,
};

const signUpReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.INVITATION_TOKEN_VERIF:
      return { ...state, webRedirection: undefined, verifying: true, signedUp: false };

    case constants.INVITATION_TOKEN_VERIF_SUCCESS:
      return { ...state, verifying: false };

    case constants.INVITATION_TOKEN_VERIF_FAILED:
      return { ...state, verifying: false };

    case constants.SIGN_UP_REQUEST_SUCCESS:
      return { ...state, signedUp: true };

    case constants.SIGN_UP_REDIRECTION:
      return { ...state, webRedirection: action.payload.webRedirection };

    default:
      return state;
  }
};

export default signUpReducer;
