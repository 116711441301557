import React from 'react';
import PropTypes from 'prop-types';

import { OneTimePasswordForm } from './components';

const OneTimePassword = ({ onSubmit }) => {
  const handleSubmit = async values => new Promise(resolveForm => (
    onSubmit({ values, resolveForm })
  ));

  return <OneTimePasswordForm onSubmit={handleSubmit} />;
};

OneTimePassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default OneTimePassword;
