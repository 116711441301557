import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import StyledTokenButton from './TaskToken.style';

const TokenButton = ({ id, name, removeToken }) => {
  const handleClick = e => removeToken(e, id);
  const handlePreventClick = e => e.preventDefault();

  return (
    <StyledTokenButton
      icon
      compact
      labelPosition="right"
      onClick={handlePreventClick}
    >
      {name}
      <Icon
        name="delete"
        onClick={handleClick}
      />
    </StyledTokenButton>
  );
};

TokenButton.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  removeToken: PropTypes.func.isRequired,
};

export default TokenButton;
