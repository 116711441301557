import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

const permissionsActionCreators = {
  fetchIndexPermissions: () => ({
    type: constants.FETCH_INDEX_PERMISSIONS_REQUEST,
    payload: {
      endpoint: endpoints.INDEX_PERMISSIONS,
    },
  }),

  fetchIndexPermissionsSuccess: response => ({
    type: constants.FETCH_INDEX_PERMISSIONS_SUCCESS,
    payload: { response },
  }),

  fetchIndexPermissionsFailure: error => ({
    type: constants.FETCH_INDEX_PERMISSIONS_FAILED,
    payload: { error },
  }),

  toggleResourceOpen: (resourceType, resourceId) => ({
    type: constants.PERMISSIONS_TOGGLE_RESOURCE_OPEN,
    payload: { resourceType, resourceId },
  }),

  toggleResourceTreeOpen: (resourceType, resourceId, path) => ({
    type: constants.PERMISSIONS_TOGGLE_RESOURCE_TREE_OPEN,
    payload: { resourceType, resourceId, path },
  }),

  toggleResourceAddUsers: (resourceType, resourceId) => ({
    type: constants.PERMISSIONS_TOGGLE_RESOURCE_ADD_USERS,
    payload: { resourceType, resourceId },
  }),

  resourceAddUserId: (resourceType, resourceId, userId) => ({
    type: constants.PERMISSIONS_RESOURCE_ADD_USER_ID,
    payload: { resourceType, resourceId, userId },
  }),

  resourceRemoveUserId: (resourceType, resourceId, userId) => ({
    type: constants.PERMISSIONS_RESOURCE_REMOVE_USER_ID,
    payload: { resourceType, resourceId, userId },
  }),

  resourceCopy: ({ resourceType, resourceId, userId }) => ({
    type: constants.PERMISSIONS_RESOURCE_COPY,
    payload: { resourceType, resourceId, userId },
  }),

  resourcePaste: ({ resourceType, resourceId, userId, copyUserId, isContext }) => ({
    type: constants.PERMISSIONS_RESOURCE_PASTE,
    payload: {
      resourceType,
      resourceId,
      userId,
      copyUserId,
      isContext,
      endpoint: endpoints.SET_PERMISSIONS,
    },
  }),

  resourcePasteSuccess: response => ({
    type: constants.PERMISSIONS_RESOURCE_PASTE_SUCCESS,
    payload: { response },
  }),

  resourcePasteFailure: error => ({
    type: constants.PERMISSIONS_RESOURCE_PASTE_FAILED,
    payload: { error },
  }),

  fetchUserPermissions: (userId, resourceType, resourceId, context) => ({
    type: constants.FETCH_RESOURCE_USER_PERMISSIONS_REQUEST,
    payload: {
      userId,
      resourceType,
      resourceId,
      context,
      endpoint: endpoints.INITIATOR_PERMISSIONS,
    },
  }),

  fetchUserPermissionsSuccess: response => ({
    type: constants.FETCH_RESOURCE_USER_PERMISSIONS_SUCCESS,
    payload: { response },
  }),

  fetchUserPermissionsFailure: error => ({
    type: constants.FETCH_RESOURCE_USER_PERMISSIONS_FAILED,
    payload: { error },
  }),

  toggleUserPermission: (userId, resourceType, resourceId, permissionId, isOn) => ({
    type: constants.PERMISSIONS_TOGGLE_USER_PERMISSION,
    payload: {
      userId,
      resourceType,
      resourceId,
      permissionId,
      endpoint: isOn ? endpoints.REVOKE_PERMISSION : endpoints.GRANT_PERMISSION,
    },
  }),

  toggleUserPermissionSuccess: response => {
    const { userId, resourceId, permissionId } = response;

    return {
      type: constants.PERMISSIONS_TOGGLE_USER_PERMISSION_SUCCESS,
      payload: {
        response,
        userId,
        resourceId,
        permissionId,
      },
    };
  },

  toggleUserPermissionFailure: error => ({
    type: constants.PERMISSIONS_TOGGLE_USER_PERMISSION_FAILED,
    payload: { error },
  }),

  toggleUserHeaderPermission: (
    userId, resourceType, resourceId, path, isOn, isPartial, isProject = false,
  ) => ({
    type: constants.PERMISSIONS_TOGGLE_USER_HEADER_PERMISSION,
    payload: {
      userId,
      resourceType,
      resourceId,
      path,
      isOn,
      isPartial,
      isProject,
      endpoint: endpoints.SET_PERMISSIONS,
    },
  }),

  toggleUserHeaderPermissionSuccess: response => ({
    type: constants.PERMISSIONS_TOGGLE_USER_HEADER_PERMISSION_SUCCESS,
    payload: { response },
  }),

  toggleUserHeaderPermissionFailure: error => ({
    type: constants.PERMISSIONS_TOGGLE_USER_HEADER_PERMISSION_FAILED,
    payload: { error },
  }),

  toggleSelectingContexts: () => ({ type: constants.PERMISSIONS_TOGGLE_SELECTING_CONTEXTS }),

  fetchContexts: () => ({
    type: constants.FETCH_CONTEXTS_REQUEST,
    payload: { urlParams: {} },
  }),

  fetchContextsSuccess: response => ({
    type: constants.FETCH_CONTEXTS_SUCCESS,
    payload: { response },
  }),

  fetchContextsSuccessPaginated: response => ({
    type: constants.FETCH_CONTEXTS_PAGINATED,
    payload: { response },
  }),

  fetchContextsFailure: error => ({
    type: constants.FETCH_CONTEXTS_FAILED,
    payload: { error },
  }),

  addContextId: contextId => ({
    type: constants.PERMISSIONS_ADD_CONTEXT_ID,
    payload: { contextId },
  }),

  addContextIdWithPaths: (
    contextId,
    contextUsersLink,
    projectPaths,
    logicalDevicePaths,
    userPaths,
    projectSuitePaths,
  ) => ({
    type: constants.PERMISSIONS_ADD_CONTEXT_ID_WITH_PATHS,
    payload: {
      contextId,
      contextUsersLink,
      projectPaths,
      logicalDevicePaths,
      userPaths,
      projectSuitePaths,
    },
  }),

  removeContextId: contextId => ({
    type: constants.PERMISSIONS_REMOVE_CONTEXT_ID,
    payload: { contextId },
  }),

  fetchContextUserPermissions: (userId, resourceType, contextId) => ({
    type: constants.FETCH_CONTEXT_USER_PERMISSIONS_REQUEST,
    payload: {
      userId,
      resourceType,
      contextId,
      endpoint: endpoints.CONTEXT_PERMISSIONS,
    },
  }),

  fetchContextUserPermissionsSuccess: response => ({
    type: constants.FETCH_CONTEXT_USER_PERMISSIONS_SUCCESS,
    payload: { response },
  }),

  fetchContextUserPermissionsFailure: error => ({
    type: constants.FETCH_CONTEXT_USER_PERMISSIONS_FAILED,
    payload: { error },
  }),

  toggleContextUserPermission: (userId, resourceType, contextId, permissionId, isOn) => ({
    type: constants.PERMISSIONS_TOGGLE_CONTEXT_USER_PERMISSION,
    payload: {
      userId,
      resourceType,
      contextId,
      resourceId: contextId,
      permissionId,
      endpoint: isOn ? endpoints.REVOKE_PERMISSION : endpoints.GRANT_PERMISSION,
    },
  }),

  toggleContextUserHeaderPermission: (userId, resourceType, path, isOn, isPartial) => ({
    type: constants.PERMISSIONS_TOGGLE_CONTEXT_USER_HEADER_PERMISSION,
    payload: {
      userId,
      resourceType,
      path,
      isOn,
      isPartial,
      endpoint: endpoints.SET_PERMISSIONS,
    },
  }),

  toggleContextUserHeaderPermissionSuccess: response => ({
    type: constants.PERMISSIONS_TOGGLE_CONTEXT_USER_HEADER_PERMISSION_SUCCESS,
    payload: { response },
  }),

  toggleContextUserHeaderPermissionFailure: error => ({
    type: constants.PERMISSIONS_TOGGLE_CONTEXT_USER_HEADER_PERMISSION_FAILED,
    payload: { error },
  }),

  toggleSelectingProjects: () => ({ type: constants.PERMISSIONS_TOGGLE_SELECTING_PROJECTS }),

  toggleSelectingProjectSuites: () => (
    { type: constants.PERMISSIONS_TOGGLE_SELECTING_PROJECT_SUITES }
  ),

  fetchProjectUsers: (projectId, projectUsersEndpoint, urlParams) => ({
    type: constants.PERMISSIONS_FETCH_PROJECT_USERS_REQUEST,
    payload: {
      projectId,
      endpoint: projectUsersEndpoint,
      urlParams,
    },
  }),

  fetchProjectUsersSuccess: response => ({
    type: constants.PERMISSIONS_FETCH_PROJECT_USERS_SUCCESS,
    payload: { response },
  }),

  fetchProjectUsersFailure: error => ({
    type: constants.PERMISSIONS_FETCH_PROJECT_USERS_FAILED,
    payload: { error },
  }),

  fetchProjectSuiteUsers: (projectSuiteId, projectSuiteUsersEndpoint, urlParams) => ({
    type: constants.PERMISSIONS_FETCH_PROJECT_SUITE_USERS_REQUEST,
    payload: {
      projectSuiteId,
      endpoint: projectSuiteUsersEndpoint,
      urlParams,
    },
  }),

  fetchProjectSuiteUsersSuccess: response => ({
    type: constants.PERMISSIONS_FETCH_PROJECT_SUITE_USERS_SUCCESS,
    payload: { response },
  }),

  fetchProjectSuiteUsersFailure: error => ({
    type: constants.PERMISSIONS_FETCH_PROJECT_SUITE_USERS_FAILED,
    payload: { error },
  }),

  fetchProjectLogicalDevices: (projectId, projectLogicalDevicesEndpoint, urlParams) => ({
    type: constants.PERMISSIONS_FETCH_PROJECT_LOGICAL_DEVICES_REQUEST,
    payload: {
      projectId,
      endpoint: projectLogicalDevicesEndpoint,
      urlParams,
    },
  }),

  fetchProjectLogicalDevicesSuccess: response => ({
    type: constants.PERMISSIONS_FETCH_PROJECT_LOGICAL_DEVICES_SUCCESS,
    payload: { response },
  }),

  fetchProjectLogicalDevicesFailure: error => ({
    type: constants.PERMISSIONS_FETCH_PROJECT_LOGICAL_DEVICES_FAILED,
    payload: { error },
  }),

  addProjectId: (projectId, paths) => ({
    type: constants.PERMISSIONS_ADD_PROJECT_ID,
    payload: { projectId, paths },
  }),

  removeProjectId: projectId => ({
    type: constants.PERMISSIONS_REMOVE_PROJECT_ID,
    payload: { projectId },
  }),

  addProjectSuiteId: (projectSuiteId, paths) => ({
    type: constants.PERMISSIONS_ADD_PROJECT_SUITE_ID,
    payload: { projectSuiteId, paths },
  }),

  removeProjectSuiteId: projectSuiteId => ({
    type: constants.PERMISSIONS_REMOVE_PROJECT_SUITE_ID,
    payload: { projectSuiteId },
  }),
};

export default permissionsActionCreators;
