import React from 'react';
import PropTypes from 'prop-types';

import { StyledPermissionsTree } from './PermissionsTree.style';

const PermissionsTree = ({
  resourceId,
  paths,
  PermissionsTreeItem,
}) => (
  <StyledPermissionsTree>
    {paths && paths.map(path => (
      <PermissionsTreeItem
        key={path}
        resourceId={resourceId}
        path={path}
      />
    ))}
  </StyledPermissionsTree>
);

PermissionsTree.defaultProps = {
  paths: null,
};

PermissionsTree.propTypes = {
  resourceId: PropTypes.string.isRequired,
  paths: PropTypes.array,
  PermissionsTreeItem: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
};

export default PermissionsTree;
