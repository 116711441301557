import { DateTime } from 'luxon';

import { urlDateFormat } from '../constants';

const accountForDeprecatedFormat = (date, zone) => {
  const datetime = DateTime.fromFormat(date, urlDateFormat, { zone });

  if (datetime.isValid) {
    return datetime;
  }

  return DateTime.fromFormat(date, 'yMMdd', { zone });
};

export default accountForDeprecatedFormat;
