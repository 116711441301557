import styled, { css } from 'styled-components';
import { Accordion, Label } from 'semantic-ui-react';

import { mediaMax } from 'themes/mixins';

export const Container = styled.div`
  padding: 2px;
  height: 50vh;
  overflow: auto;

  ${({ flex }) => flex && css`
    display: flex;
    justify-content: center;
    align-items: center;
  `}

  ${mediaMax.sm`
    height: unset;
    overflow: unset;
  `};
`;

export const EmptyMessage = styled.p`
  text-align: center;
  font-size: 16px;
`;

export const StyledAccordion = styled(Accordion)`
  width: 98% !important;

  &&& {
    div.title {
      display: flex;
      align-items: center;

      > i {
        display: none !important;
      }
    }
  }
`;

export const StyledAccordionTitle = styled(Accordion.Title)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const StyledAccordionButtons = styled.div`
  button {
    &:last-child {
      margin-left: 5px
    }    
  }
`;

export const TaskContainer = styled.div`
  &:not(:first-child) {
    margin-top: 8px !important;
  }
`;

export const TaskNameLabel = styled(Label)`
  &&& {
    width: 98%;
    border-radius: 0;
    padding-left: 0;
    border: none;
    border-bottom: 1px solid rgba(34, 36, 38, .15);  
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }  
`;

export default Container;
