import { compose } from 'redux';
import { connect } from 'react-redux';

import automatedOperationsActionCreators from '../../../../../../utilities/actionCreators';
import AddSchedule from './AddSchedule';

const mapDispatchToProps = {
  onAddSchedule: automatedOperationsActionCreators.addSchedule,
};

export default compose(
  connect(null, mapDispatchToProps),
)(AddSchedule);
