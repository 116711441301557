import styled from 'styled-components';

import filterProps from 'helpers/filterProps';

const FilteredProps2 = filterProps([
  'editMode',
])('label');

export const StyledLabel = styled(FilteredProps2)`
  min-width: 28.6%;
  font-weight: 700;
`;

export const StyledDiv = styled.div`
  display: flex; 
  align-items: center;
  width: 100%;
`;

export default StyledLabel;
