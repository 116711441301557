import { call } from 'redux-saga/effects';

import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';

import actionCreators from '../actionCreators';

function* fetchDeviceFirmwaresModelTree(action) {
  const { endpoint, ...rest } = action.payload;
  const { response, error } = yield call(getPath, endpoint);

  const params = {
    response,
    error,
    successDisp: actionCreators.fetchDeviceFirmwaresModelTreeSuccess,
    failDisp: actionCreators.fetchDeviceFirmwaresModelTreeFailure,
    ...rest,
  };

  yield call(processCall, params);
}

export default fetchDeviceFirmwaresModelTree;
