import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';

import { OUTPUT_FIELD } from 'helpers/selectors';
import { Dimmer } from 'components/DataTable/Table.style';
import StyledGridChannelDisplay from './GridChannelDisplay.style';
import {
  GridChannelDisplayItem,
  GridChannelDisplayItemHighlight,
} from './components';

export default function GridChannelDisplay({
  channels,
  header,
  onStoreTableName,
  highlightUpdates,
  isRefreshing,
}) {
  useEffect(() => {
    onStoreTableName(OUTPUT_FIELD, header);
  }, [header, onStoreTableName]);

  const DisplayItem = (
    highlightUpdates
      ? GridChannelDisplayItemHighlight
      : GridChannelDisplayItem
  );

  if (!channels) {
    return null;
  }

  return (
    <Dimmer.Dimmable dimmed={isRefreshing}>
      <StyledGridChannelDisplay>
        {channels.map(({ name, value, status }) => (
          <DisplayItem key={name} name={name} value={value} status={status} />
        ))}
      </StyledGridChannelDisplay>
      <Dimmer inverted active={isRefreshing}>
        <Loader>Refreshing</Loader>
      </Dimmer>
    </Dimmer.Dimmable>
  );
}

GridChannelDisplay.defaultProps = {
  channels: [],
  highlightUpdates: false,
};

GridChannelDisplay.propTypes = {
  channels: PropTypes.array,
  header: PropTypes.string.isRequired,
  onStoreTableName: PropTypes.func.isRequired,
  highlightUpdates: PropTypes.bool,
  isRefreshing: PropTypes.bool.isRequired,
};
