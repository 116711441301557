import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Segment } from 'semantic-ui-react';

import {
  ProjectPermissionsGridWrapper,
  DevicePermissionsGridWrapper,
} from '../../../../../containers';

const PermissionsProjectWrapper = ({
  projectId,
  projectOpen,
  logicalDeviceIds,
}) => (
  <Segment>
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <ProjectPermissionsGridWrapper projectId={projectId} />
        </Grid.Column>
      </Grid.Row>
      {projectOpen && logicalDeviceIds && !!logicalDeviceIds.length && (
        <Fragment>
          <Grid.Row>
            <Grid.Column>
              <Header as="h2">Devices</Header>
            </Grid.Column>
          </Grid.Row>
          {logicalDeviceIds.map(logicalDeviceId => (
            <Grid.Row key={logicalDeviceId}>
              <Grid.Column>
                <DevicePermissionsGridWrapper
                  header="h3"
                  projectId={projectId}
                  deviceId={logicalDeviceId}
                />
              </Grid.Column>
            </Grid.Row>
          ))}
        </Fragment>
      )}
    </Grid>
  </Segment>
);

PermissionsProjectWrapper.defaultProps = {
  logicalDeviceIds: null,
};

PermissionsProjectWrapper.propTypes = {
  projectId: PropTypes.string.isRequired,
  projectOpen: PropTypes.bool.isRequired,
  logicalDeviceIds: PropTypes.array,
};

export default PermissionsProjectWrapper;
