import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { StyledHeader, StyledLabel, StyledLink, StyledContent } from '..';
import { ActionsList, ActionItem } from './ActionsReview.style';

function ActionsReview({ selectedActions, selectedTasks, onStepChange }) {
  const handleClick = () => {
    onStepChange(0);
  };

  const renderTaskActions = useCallback(() => {
    const actionsArrays = selectedTasks.map(task => task.actions || []);

    let allActions = [];

    actionsArrays.forEach(arrayOfActions => {
      allActions = [...allActions, ...arrayOfActions];
    });

    return (
      <>
        {allActions.map(action => (
          <ActionItem key={action.id}>
            {action.name}
          </ActionItem>
        ))}
      </>
    );

  }, [selectedTasks]);

  return (
    <>
      <StyledHeader>
        <StyledLabel>
          Tasks & Actions
        </StyledLabel>
        <StyledLink onClick={handleClick}>
          Edit Tasks & Actions
        </StyledLink>
      </StyledHeader>
      <StyledContent>
        <ActionsList className="selected-actions-review">
          {renderTaskActions()}
          {selectedActions.map(action => (
            <ActionItem key={`${action.actionName} - ${action.resourceName} - ${action.index}`}>
              {`${action.actionName} - ${action.resourceName}`}
            </ActionItem>
          ))}
        </ActionsList>
      </StyledContent>
    </>
  );
}

ActionsReview.defaultProps = {
  selectedActions: [],
  selectedTasks: [],
};

ActionsReview.propTypes = {
  selectedActions: PropTypes.array,
  selectedTasks: PropTypes.array,
  onStepChange: PropTypes.func.isRequired,
};

export default ActionsReview;
