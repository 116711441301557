import React from 'react';
import PropTypes from 'prop-types';

import TableMenu from 'components/DataTable/TableMenu.style';
import { DeleteProjectActionLink } from './DeleteProject';

const ProjectActionLinks = ({ chosenItem, ...rest }) => {
  const actionLinks = [DeleteProjectActionLink];

  return (
    <TableMenu.Item>
      {actionLinks.map(ActionLink => (
        <TableMenu.Item key={ActionLink.displayName}>
          <ActionLink item={chosenItem} {...rest} />
        </TableMenu.Item>
      ))}
    </TableMenu.Item>
  );
};

ProjectActionLinks.defaultProps = {
  chosenItem: null,
};

ProjectActionLinks.propTypes = {
  chosenItem: PropTypes.object,
};

export default ProjectActionLinks;
