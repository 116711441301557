import { connect } from 'react-redux';

import {
  errorNotificationsSelector,
  successNotificationsSelector,
  infoNotificationsSelector,
} from 'helpers/selectors';
import NotificationStack from './NotificationStack';

const mapStateToProps = state => ({
  notification: (
    errorNotificationsSelector(state)
      || successNotificationsSelector(state)
      || infoNotificationsSelector(state)
  ),
});

export default connect(mapStateToProps)(NotificationStack);
