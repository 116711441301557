import truncate from 'lodash/truncate';
import isEmpty from 'lodash/isEmpty';
import toString from 'lodash/toString';
import isString from 'lodash/isString';

import { remoteDeviceStatus } from 'helpers/sharedVariables';
import SubRemoteDeviceModel from './SubRemoteDeviceModel';

export const mappedLabels = {
  cc: 'Contact closure',
};

class RemoteDeviceModel {

  constructor(device = {}) {
    this.id = device.id && toString(device.id);
    this.num = device.num;
    this.name = device.name;
    this.type = device.type;
    this.deviceId = device.deviceId;
    this.devices = device.devices && device.devices.map(item => new SubRemoteDeviceModel(item));
    this.inputs = device.status && device.status.inputs && Object.values(device.status.inputs);
    this.outputs = device.status && device.status.outputs && Object.entries(device.status.outputs);
  }

  get visibleName() {
    if (isEmpty(this.name)) {
      return undefined;
    }

    return truncate(this.name, {
      length: 30,
      separator: ' ',
      omission: ' ...',
    });
  }

  get statusIcon() {
    const { name, type, devices } = this;

    if (isEmpty(name) && isEmpty(type)) {
      return undefined;
    }

    if (isEmpty(devices)) {
      return remoteDeviceStatus.OFFLINE;
    }

    if (devices.every(RemoteDeviceModel.checkIfFirmwareCorrect)) {
      return remoteDeviceStatus.ONLINE;
    }

    return remoteDeviceStatus.INCOMPATIBLE_FIRMWARE;
  }

  static checkIfFirmwareCorrect(item) {
    const {
      firmware: { correct },
    } = item;

    return correct === true;
  }

  get dataInput() {
    if (Array.isArray(this.inputs)) {
      const reducer = (acc, currentValue, index) => ({
        ...acc, [index.toString()]: currentValue.value,
      });

      return this.inputs.reduce(reducer, {});
    }

    return {};
  }

  get labelsInput() {
    if (Array.isArray(this.inputs)) {
      return this.inputs.map((item, index) => (
        { text: RemoteDeviceModel.mapLabel(item.type), field: index.toString() }),
      );
    }

    return [];
  }

  get dataOutput() {
    if (Array.isArray(this.outputs)) {
      const reducer = (acc, currentValue) => ({
        ...acc, [currentValue[0].toString()]: currentValue[1],
      });

      return this.outputs.reduce(reducer, {});
    }

    return {};
  }

  get labelsOutput() {
    if (Array.isArray(this.outputs)) {
      return this.outputs.map(item => {
        const [key] = item;
        return { text: key.toString(), field: key.toString() };
      });
    }

    return [];
  }

  get showRemoteSubDevices() {
    return Array.isArray(this.devices) && !isEmpty(this.devices);
  }

  get showInputs() {
    return !isEmpty(this.inputs);
  }

  get showOutputs() {
    return !isEmpty(this.outputs);
  }

  static mapLabel(label) {
    if (mappedLabels[label]) {
      return mappedLabels[label];
    }

    if (isString(label)) {
      return label.charAt(0).toUpperCase() + label.slice(1);
    }

    return label;
  }

}

export default RemoteDeviceModel;
