import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import actionCreators from '../../../utilities/actionCreators';
import withDeleteAutomatedOperation from './withDeleteAutomatedOperation';

const mapDispatchToProps = {
  onDeleteAutomatedOperation: actionCreators.removeAutomatedOperation,
};

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  withDeleteAutomatedOperation,
);
