import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import { StatusCard } from 'components/core';

import { transformToSiteStatus, transformToDeviceStatus } from './utilities';
import { StyledGrid } from './StatusWidgets.style';

function StatusWidgets({ isLoading, type, projectsStatus }) {

  const siteStatus = useMemo(() => {
    if (!projectsStatus) {
      return {};
    }

    return transformToSiteStatus(projectsStatus);
  }, [projectsStatus]);

  const deviceStatus = useMemo(() => {
    if (!projectsStatus) {
      return {};
    }

    return transformToDeviceStatus(projectsStatus);
  }, [projectsStatus]);


  return (
    <StyledGrid>
      <Grid.Row>
        {type === 'admin' && (
          <Grid.Column computer={5} mobile={16}>
            <StatusCard
              title="Site Status"
              total={siteStatus.total}
              items={siteStatus.items}
              isLoading={isLoading}
            />
          </Grid.Column>
        )}
        <Grid.Column computer={5} mobile={16}>
          <StatusCard
            title="Device Status"
            total={deviceStatus.total}
            items={deviceStatus.items}
            isLoading={isLoading}
          />
        </Grid.Column>
      </Grid.Row>
    </StyledGrid>
  );
}

StatusWidgets.defaultProps = {
  isLoading: false,
  projectsStatus: [],
};

StatusWidgets.propTypes = {
  isLoading: PropTypes.bool,
  projectsStatus: PropTypes.array,
  type: PropTypes.oneOf(['user', 'admin']).isRequired,
};

export default StatusWidgets;
