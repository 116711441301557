import { connect } from 'react-redux';
import { compose } from 'redux';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { currentUserSelector } from 'helpers/selectors';

import * as actionCreators from '../../utilities/actionCreators';
import MapWrapper from './MapWrapper';
import { selectors } from '../../utilities';

export const mapStateToProps = state => {
  const { id: userId } = currentUserSelector(state);
  const isLoading = selectors.isMapDataLoadingSelector(state);

  return {
    userId,
    isLoading,
  };
};

export default compose(
  connect(mapStateToProps),
  chainedLoaderWithSaga(({ userId }) => actionCreators.fetchProjectsForMap(
    userId,
    {
      fields: {
        projects: [
          'name',
          'longitude',
          'latitude',
          'onlineDevices',
          'offlineDevices',
          'unassociatedDevices',
          'devicesForUpgrade',
        ],
      },
    },
  )),
)(MapWrapper);
