import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header, Modal } from 'semantic-ui-react';

import { Button } from 'components/atomic';
import { extractFileExtension } from 'helpers/sharedMethods';
import NewUpload from 'components/NewUpload/NewUploadContainer';

class UploadsFileNotFound extends Component {

  constructor() {
    super();

    this.handleAbort = this.handleAbort.bind(this);
    this.handleRetry = this.handleRetry.bind(this);
  }

  handleAbort() {
    const { cancelUpload } = this.context;
    const { notFound } = this.props;

    cancelUpload(notFound);
  }

  handleRetry() {
    const { forceUpload } = this.context;
    const { notFound } = this.props;

    forceUpload(notFound);
  }

  render() {
    const { notFound } = this.props;

    if (!notFound) return null;

    const { device, systemFile: { name }, systemFile } = notFound;
    const fileExtension = extractFileExtension(systemFile);

    return (
      <Modal
        open={Boolean(notFound)}
        size="small"
      >
        <Header>Error</Header>
        <Modal.Content>
          <p>
            The file <strong>{name}</strong> cannot be found.
            It may have been moved or deleted. We can try again
            or you can browse to the new location of the file to continue
            the upload.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative floated="left" onClick={this.handleAbort}>
            Cancel upload
          </Button>
          <NewUpload
            deviceId={device}
            resume={notFound}
            trigger={<Button>Choose file</Button>}
            accept={fileExtension}
          />
          <Button primary onClick={this.handleRetry}>
            Retry
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

}

UploadsFileNotFound.contextTypes = {
  cancelUpload: PropTypes.func.isRequired,
  forceUpload: PropTypes.func.isRequired,
};

UploadsFileNotFound.defaultProps = {
  notFound: null,
};

UploadsFileNotFound.propTypes = {
  notFound: PropTypes.object,
};

export default UploadsFileNotFound;
