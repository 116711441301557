import styled from 'styled-components';
import { Accordion } from 'semantic-ui-react';

const StyledAccordion = styled(Accordion)`
  &.ui.accordion {
    margin-top: 0;
  }
`;

export default StyledAccordion;
