import camelCase from 'lodash/camelCase';
import isEmpty from 'lodash/isEmpty';

const computeActionTypesState = (action, formValues) => {
  if (isEmpty(action)) {
    return null;
  }

  if (!action.params || isEmpty(action.params)) {
    return null;
  }

  const { id, params } = action;
  const actionName = camelCase(id);

  const arr = [];
  params.forEach(item => {
    if (item.dependentOn && !item.apiIgnoreDependentOn) {
      item.dependentOn.forEach(element => {
        const checkDependentOnCondition = formValues[element.key] && element.acceptedValues
          .find(value => value === formValues[element.key]);

        if (checkDependentOnCondition) {
          arr.push({ [item.key]: true });
        } else {
          arr.push({ [item.key]: false });
        }
      });
    } else {
      arr.push({ [item.key]: true });
    }
  });

  return {
    [actionName]: arr.reduce((acc, item) => (
      { ...acc, [Object.keys(item)[0]]: item[Object.keys(item)[0]] }),
    ),
  };
};

export default computeActionTypesState;
