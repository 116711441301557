import styled from 'styled-components';

import { ButtonLink } from 'components/elements';

export const StyledButtonLink = styled(ButtonLink)`
  margin-left: 6px !important;
  margin-bottom: 5px !important;
`;

export default StyledButtonLink;
