import React from 'react';
import PropTypes from 'prop-types';

import { getDisplayName } from 'helpers/sharedMethods';

const checkMultiSites = () => WrappedComponent => {
  const CheckMultiSites = ({ assignedToMultiSites, ...rest }) => {
    const props = {
      ...rest,
      assignedToMultiSites,
    };

    return <WrappedComponent {...props} />;
  };

  CheckMultiSites.defaultProps = {
    assignedToMultiSites: false,
  };

  CheckMultiSites.propTypes = {
    assignedToMultiSites: PropTypes.bool,
  };

  CheckMultiSites.displayName = `CheckMultiSites(${getDisplayName(WrappedComponent)})`;

  return CheckMultiSites;
};

export default checkMultiSites;
