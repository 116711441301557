import React from 'react';
import PropTypes from 'prop-types';

import { CONTROL_UI_PAGE_TYPES_OPTIONS } from 'application/tenant/components/structural/ControlUI/constants';

import {
  Container,
  PageTypeOption,
  PageTypeIcon,
  PageTypeDescription,
} from './PageTypeSelect.style';

function PageTypeSelect({ value, onChange }) {
  return (
    <Container>
      {CONTROL_UI_PAGE_TYPES_OPTIONS.map(({ key, label }) => (
        <PageTypeOption
          key={key}
          selected={value === key}
          onClick={() => onChange(key)}
        >
          <PageTypeIcon type={key} />
          <PageTypeDescription>
            {label}
          </PageTypeDescription>
        </PageTypeOption>
      ))}
    </Container>
  );
}

PageTypeSelect.defaultProps = {
  value: 'default',
  onChange: null,
};

PageTypeSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default PageTypeSelect;
