import React from 'react';
import PropTypes from 'prop-types';

import TableMenu from 'components/DataTable/TableMenu.style';
import { RemoveFileActionLink } from './RemoveFile';
import { TransferFileActionLink } from './TransferFile';
import { DownloadFileActionLink } from './DownloadFile';

const FileActionLinks = ({ chosenItem, online, emptyItem, ...rest }) => {
  const actionLinks = [RemoveFileActionLink, DownloadFileActionLink];

  if (online && !emptyItem) {
    actionLinks.push(TransferFileActionLink);
  }

  return (
    <TableMenu.Item>
      {actionLinks.map(ActionLink => (
        <TableMenu.Item key={ActionLink.displayName}>
          <ActionLink item={chosenItem} {...rest} />
        </TableMenu.Item>
      ))}
    </TableMenu.Item>
  );
};

FileActionLinks.defaultProps = {
  chosenItem: null,
  online: false,
  emptyItem: false,
};

FileActionLinks.propTypes = {
  chosenItem: PropTypes.object,
  online: PropTypes.bool,
  emptyItem: PropTypes.bool,
};

export default FileActionLinks;
