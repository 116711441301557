import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { actionCreators, selectors } from '../../utilities';
import NotificationsFilter from './NotificationsFilter';

export const mapStateToProps = state => {
  const notificationEvents = selectors.notificationsEventsSelector(state);
  const notificationFilters = selectors.notificationFiltersSelector(state);

  return {
    notificationEvents,
    notificationFilters,
  };
};

const mapDispatchToProps = {
  onSetNotificationFilters: actionCreators.setNotificationEventFilters,
  onFetchNotificationEvents: actionCreators.fetchNotificationEvents,
  onTriggerNotificationFilters: actionCreators.triggerNotificationEventFilters,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(NotificationsFilter);
