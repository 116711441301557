import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';

const filterByResourceType = (permissions, resourceType) => permissions.filter(
  permission => permission.resource === resourceType,
);

const filterByScopes = (permissions, scopes) => {
  if (isEmpty(scopes)) {
    return permissions.filter(({ limitedScope }) => limitedScope.length === 0);
  }

  return permissions.filter(
    ({ limitedScope }) => (
      limitedScope.length === 0
      || limitedScope.some(
        scope => includes(scopes, scope),
      )
    ),
  );
};

const filterByLimitedFor = (permissions, limitedForFilter) => {
  if (limitedForFilter.length === 0) {
    return permissions;
  }

  return permissions.filter(
    ({ limitedFor = [] }) => limitedFor.some(
      limited => includes(limitedForFilter, limited),
    ),
  );
};

const filterPermissions = ({
  allPermissions,
  resourceType,
  limitedScope = [],
  limitedFor = [],
}) => {
  if (!allPermissions || isEmpty(allPermissions)) {
    return [];
  }

  let permissions = allPermissions;

  permissions = filterByResourceType(permissions, resourceType);
  permissions = filterByScopes(permissions, limitedScope);
  permissions = filterByLimitedFor(permissions, limitedFor);

  return permissions;
};

export default filterPermissions;
