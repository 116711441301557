import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import { FIXTURE_GROUPS_FIELD } from 'helpers/selectors';

const fixtureGroupsActionCreators = {
  fetchGroups: (deviceId, urlParams = {}) => ({
    type: constants.FETCH_FIXTURE_GROUPS_REQUEST,
    payload: {
      deviceId,
      endpoint: endpoints.LOGICAL_DEVICE_FIXTURE_GROUPS(deviceId),
      urlParams,
    },
  }),

  fetchGroupsSuccess: response => ({
    type: constants.FETCH_FIXTURE_GROUPS_SUCCESS,
    payload: { response },
  }),

  fetchGroupsFailure: error => ({
    type: constants.FETCH_FIXTURE_GROUPS_FAILED,
    payload: {
      info: !error.unauthorized && error.notFound && messages.NO_FIXTURE_GROUPS,
      error: !error.notFound && error,
      resourceType: FIXTURE_GROUPS_FIELD,
    },
  }),

  fetchGroupsSuccessPaginated: response => ({
    type: constants.FETCH_FIXTURE_GROUPS_PAGINATED,
    payload: { response },
  }),
};

export default fixtureGroupsActionCreators;
