import { connect } from 'react-redux';

import performActionActionCreators from 'pages/Device/performActionActionCreators';
import JamboxTimelineRow from './JamboxTimelineRow';

const mapDispatchToProps = {
  onToggleTimeline: performActionActionCreators.performActionRequest,
};

export default connect(null, mapDispatchToProps)(JamboxTimelineRow);
