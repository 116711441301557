import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Button, Responsive } from 'semantic-ui-react';

import { TipIcon } from 'components/atomic';
import DataTable from 'components/DataTable/DataTableContainer';
import { sizes } from 'themes/mixins';

import { DayViewTableRow } from './components';
import {
  StyledDayViewTable,
  StyledDescription,
  StyledButtonGroup,
} from './DayViewTable.style';

const labels = [
  { text: 'Status', field: 'statusIcon', width: 1 },
  { text: '', field: 'isMultiSite', width: 2 },
  { text: 'Time', field: 'timeDisplay', width: 3 },
  { text: 'Task Scheduler', field: 'aoName', width: 11 },
];

const DayViewTable = ({
  active,
  resourceTime,
  occurrences,
  sameTimezone,
  showHidden,
  onToggleHiddenOccurrences,
  userHasPermissionOrSuperadmin,
  hideMultiSiteOccurrences,
  onToggleShowHideMultiSiteOccurrences,
  siteHasMultiSite,
}) => {
  const HideOccurrencesButton = useCallback(() => {
    const commonProps = {
      name: 'btn-show-hidden-occurrences',
      basic: true,
      icon: showHidden ? 'eye slash' : 'eye',
      content: showHidden ? 'Hide hidden events' : 'Show hidden events',
      onClick: onToggleHiddenOccurrences,
    };

    const hideMultiSiteEventsProps = {
      name: 'btn-hide-multi-site-occurrences',
      basic: true,
      icon: hideMultiSiteOccurrences ? 'eye' : 'eye slash',
      content: hideMultiSiteOccurrences ? 'Show Multi-site events' : 'Hide Multi-site events',
      onClick: onToggleShowHideMultiSiteOccurrences,
    };

    return (
      <StyledButtonGroup basic size="mini">
        <Responsive maxWidth={sizes.md - 1}>
          {!userHasPermissionOrSuperadmin && siteHasMultiSite && (
            <Button {...hideMultiSiteEventsProps} content={null} />
          )}
          <Button
            {...commonProps}
            content={null}
          />
        </Responsive>
        <Responsive minWidth={sizes.md}>
          {!userHasPermissionOrSuperadmin && siteHasMultiSite && (
            <Button {...hideMultiSiteEventsProps} />
          )}
          <Button
            {...commonProps}
          />
        </Responsive>
      </StyledButtonGroup>
    );
  }, [
    showHidden,
    onToggleHiddenOccurrences,
    userHasPermissionOrSuperadmin,
    hideMultiSiteOccurrences,
    onToggleShowHideMultiSiteOccurrences,
    siteHasMultiSite,
  ]);

  if (!occurrences) {
    return null;
  }

  return (
    <StyledDayViewTable>
      <DataTable
        header="Events"
        description={(
          <div>
            <StyledDescription>
              {`Showing events for ${active.toLocaleString(DateTime.DATE_HUGE)}`}
            </StyledDescription>
            &nbsp;
            {!sameTimezone && (
              <TipIcon
                floated="right"
                message="Browser time and site time differ. All times are shown in the site's time zone."
                position="bottom center"
                editMode={false}
              />
            )}
          </div>
        )}
        labels={labels}
        data={occurrences}
        pagination={{}}
        customTableRow={DayViewTableRow}
        siteTime={resourceTime}
        createElement={HideOccurrencesButton}
      />
    </StyledDayViewTable>
  );
};

DayViewTable.defaultProps = {
  occurrences: null,
  showHidden: false,
  siteHasMultiSite: false,
};

DayViewTable.propTypes = {
  active: PropTypes.object.isRequired,
  resourceTime: PropTypes.object.isRequired,
  occurrences: PropTypes.array,
  sameTimezone: PropTypes.bool.isRequired,
  showHidden: PropTypes.bool,
  onToggleHiddenOccurrences: PropTypes.func.isRequired,
  userHasPermissionOrSuperadmin: PropTypes.bool.isRequired,
  onToggleShowHideMultiSiteOccurrences: PropTypes.func.isRequired,
  hideMultiSiteOccurrences: PropTypes.bool.isRequired,
  siteHasMultiSite: PropTypes.bool,
};

export default DayViewTable;
