import React, { createRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import isNull from 'lodash/isNull';
import moment from 'moment';

import { Button } from 'components/atomic';

import { usePopup, formatDate } from './utilities';
import {
  Container,
  StyledPopup,
  StyledLabel,
  EmptyMessage,
  TriggerContainer,
} from './DatePicker.style';

const DatePicker = ({
  value,
  onChange,
  format,
  defaultEmptyMessage,
  popupPosition,
  keepOpen,
  hideValue,
  ...rest
}) => {
  const { isOpen, onClose, onToggle } = usePopup();
  const contextRef = createRef();

  const renderValue = useCallback(() => {
    if (isNull(value)) {
      return <EmptyMessage className="date-picker-value">{defaultEmptyMessage}</EmptyMessage>;
    }

    return (
      <StyledLabel className="date-picker-value">
        {formatDate(value)}
      </StyledLabel>
    );
  }, [defaultEmptyMessage, value]);

  const handlePopupToggle = useCallback(e => {
    e.preventDefault();

    onToggle();
  }, [onToggle]);

  const renderTrigger = useCallback(() => (
    <TriggerContainer>
      <span ref={contextRef}>
        <Button basic icon className="date-picker-toggle-btn" onClick={handlePopupToggle}>
          <Icon name="calendar alternate outline" />
        </Button>
      </span>
      <div>
        {!hideValue && renderValue()}
      </div>
    </TriggerContainer>
  ), [contextRef, renderValue, hideValue, handlePopupToggle]);

  const handleDateSelected = useCallback(selectedDate => {
    const dateWithoutTime = moment(selectedDate).startOf('day');

    onChange(dateWithoutTime);

    if (!keepOpen) {
      onToggle();
    }
  }, [onChange, keepOpen, onToggle]);

  return (
    <>
      <StyledPopup
        on="click"
        position={popupPosition}
        context={contextRef}
        open={isOpen}
        onClose={onClose}
        content={(
          <Container>
            <DateTimePicker
              className="date-time-picker"
              onChange={handleDateSelected}
              onToggle={() => {}}
              value={value ? new Date(value) : null}
              format={format}
              time={false}
              open="date"
              defaultOpen="date"
              inputProps={{ readOnly: true }}
              {...rest}
            />
          </Container>
        )}
      />
      {renderTrigger()}
    </>
  );
};

export { formatDate };

DatePicker.defaultProps = {
  value: null,
  format: 'DD/MM/YYYY',
  defaultEmptyMessage: 'No date selected.',
  keepOpen: false,
  popupPosition: 'right center',
  hideValue: false,
};

DatePicker.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  keepOpen: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  format: PropTypes.string,
  defaultEmptyMessage: PropTypes.string,
  popupPosition: PropTypes.string,
  hideValue: PropTypes.bool,
};

export default DatePicker;
