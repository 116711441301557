import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { validateOnSubmit } from 'components/form/formConfigs';
import EditAutomatedOperationModalForm from './EditAutomatedOperationModalForm';

const EditAutomatedOperationModalFormContainer = reduxForm({
  enableReinitialize: true,
  destroyOnUnmount: false,
  ...validateOnSubmit,
})(EditAutomatedOperationModalForm);

export const mapStateToProps = (state, { item }) => {
  const {
    name,
    description,
    enabled,
    hidden,
    occurrenceColour,
    priority,
    priorityType,
    mode,
  } = item;

  return {
    initialValues: {
      name,
      description,
      occurrenceColour,
      enabled,
      hidden,
      priority,
      priorityType,
      mode,
    },
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
)(EditAutomatedOperationModalFormContainer);
