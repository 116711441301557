import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';

export const addOrigin = (params, companyId, urlParams) => ({
  type: constants.ADD_ORIGIN_REQUEST,
  payload: {
    endpoint: endpoints.ORIGINS,
    companyId,
    params,
    urlParams,
  },
});

export const addOriginSuccess = response => ({
  type: constants.ADD_ORIGIN_SUCCESS,
  payload: {
    response,
    message: messages.ORIGIN_ADDED(response.data.attributes.name),
  },
});

export const addOriginFailure = error => ({
  type: constants.ADD_ORIGIN_FAILED,
  payload: { error },
});
