import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import { DEVICE_FIRMWARES_FIELD } from 'helpers/selectors';

export const addDeviceFirmware = params => ({
  type: constants.ADD_DEVICE_FIRMWARE_REQUEST,
  payload: {
    params,
    endpoint: endpoints.DEVICE_FIRMWARES,
    endpointForFile: endpoints.DEVICE_FIRMWARE_FILES,
  },
});

export const addDeviceFirmwareSuccess = response => ({
  type: constants.ADD_DEVICE_FIRMWARE_SUCCESS,
  payload: {
    response,
    message: messages.DEVICE_FIRMWARE_ADDED(response.data.attributes.name),
    resourceType: DEVICE_FIRMWARES_FIELD,
  },
});

export const addDeviceFirmwareFailure = error => ({
  type: constants.ADD_DEVICE_FIRMWARE_FAILED,
  payload: { error },
});

export default addDeviceFirmware;
