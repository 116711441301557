import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import formNames from 'components/form/formNames';

import { selectors } from '../../../../../../utilities';
import SiteDetailsForm from './SiteDetailsForm';

const SiteDetailsFormContainer = reduxForm({
  form: formNames.siteFromVoucher,
  enableReinitialize: true,
})(SiteDetailsForm);

export const mapStateToProps = state => ({
  initialValues: {
    name: '',
    ownerEmail: '',
  },
  voucherForSiteError: selectors.voucherForSiteErrorSelector(state),
});

export default connect(mapStateToProps)(SiteDetailsFormContainer);
