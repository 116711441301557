import React from 'react';

import { COMPANIES_FIELD, SHOW_COMPANIES_COMPONENT } from 'helpers/selectors';
import DataTable from 'components/DataTable/DataTableContainer';
import { PageHeader } from 'components/core';
import { AddCompany, CompanyTableRow } from './components';

const labels = [
  { text: 'Name', field: 'name' },
  { text: 'Subdomain', field: 'subdomain' },
  { text: 'Origins', field: 'origins', sortable: false },
  { text: 'Manufacturer', field: 'manufacturerIcon', sortBy: 'manufacturer' },
];

const Companies = () => (
  <>
    <PageHeader title="Companies" />
    <DataTable
      labels={labels}
      customTableRow={CompanyTableRow}
      createElement={AddCompany}
      route="/companies"
      pagination={{
        type: COMPANIES_FIELD,
        included: true,
        componentName: SHOW_COMPANIES_COMPONENT,
      }}
    />
  </>
);

export default Companies;
