import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { AddOriginModalForm } from './components';

class AddOrigin extends Component {

  constructor() {
    super();

    this.handleAddOrigin = this.handleAddOrigin.bind(this);
  }

  handleAddOrigin(values) {
    const { companyId, onAddOrigin } = this.props;

    return new Promise((resolveForm, rejectForm) => {
      onAddOrigin({ values, resolveForm, rejectForm }, companyId, {
        fields: {
          origins: ['id', 'name'],
        },
        include: ['company.origins'],
      });
    });
  }

  render() {
    return (
      <AddOriginModalForm onSubmit={this.handleAddOrigin} />
    );
  }

}

AddOrigin.propTypes = {
  companyId: PropTypes.string.isRequired,
  onAddOrigin: PropTypes.func.isRequired,
};

export default AddOrigin;
