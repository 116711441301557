import styled from 'styled-components';
import { Segment } from 'semantic-ui-react';

export const StyledSegment = styled(Segment)`
  &&& {
    margin: 25px !important;
  }
`;

export default StyledSegment;
