import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import get from 'lodash/get';

export const StyledDiv = styled.span`
  position: absolute;
  left: 0.5em;
  bottom: 0.5em;
`;

export const StyledIcon = styled(Icon)`
  cursor: pointer;
  color: ${({ theme }) => get(theme, 'palette.primaryColor')};
`;

export default StyledDiv;
