import styled from 'styled-components';
import { List } from 'semantic-ui-react';

export const StyledListContent = styled(List.Content)`
  display: flex !important;
  align-items: center;

  &&& {
    p {
      margin-bottom: 0;
      margin-right: 4px;
      width: 190px;
    }
  }
`;

export default StyledListContent;
