import styled from 'styled-components';

const ManufacturerLabel = styled.div`
  color: rgba(0, 0, 0, 0.6) !important;
`;

export const StyledDiv = styled.div`
  display: inline-block;
  margin-right: 6px;
`;

export default ManufacturerLabel;
