import { compose } from 'redux';
import { connect } from 'react-redux';

import { currentUserSelector } from 'helpers/selectors';
import { selectors as applicationUserSelectors } from '../../../../../../../../../../utilities/user';
import TwoFactorWizard from './TwoFactorWizard';

const mapStateToProps = state => {
  const { twoFactorEnabled } = currentUserSelector(state);

  return {
    submitting: applicationUserSelectors.twoFactorOTPSubmittingSelector(state),
    disabling: applicationUserSelectors.twoFactorDisablingSelector(state),
    failed: applicationUserSelectors.twoFactorOTPFailedSelector(state),
    enabled: twoFactorEnabled,
  };
};

export default compose(
  connect(mapStateToProps),
)(TwoFactorWizard);
