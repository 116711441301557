import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import throttle from 'lodash/throttle';
import uuidv4 from 'uuid/v4';

import PathwayIdentifyV1Form from './PathwayIdentifyV1Form/PathwayIdentifyV1Form';

export const DEVICE_BEACON_REFRESH = uuidv4();

const subscribeOpts = { submitting: true };

class PathwayIdentifyV1 extends Component {

  constructor() {
    super();

    this.handleSubmit = throttle(this.handleSubmit.bind(this), 250);
  }

  handleSubmit() {
    const { resourceId, resourceBeacon, onToggleBeacon } = this.props;

    return new Promise((resolveForm, rejectForm) => {
      onToggleBeacon(resourceId, {
        values: { beacon: !resourceBeacon },
        resolveForm,
        rejectForm,
      });
    });
  }

  render() {
    const { deviceTitle, resourceBeacon, resourceOnline, title } = this.props;

    return (
      <Form
        onSubmit={this.handleSubmit}
        subscribe={subscribeOpts}
        render={PathwayIdentifyV1Form}
        resourceBeacon={resourceBeacon}
        resourceOnline={resourceOnline}
        title={title}
        deviceTitle={deviceTitle}
      />
    );
  }

}

PathwayIdentifyV1.defaultProps = {
  resourceBeacon: false,
  resourceOnline: false,
};

PathwayIdentifyV1.propTypes = {
  resourceId: PropTypes.string.isRequired,
  deviceTitle: PropTypes.string.isRequired,
  resourceBeacon: PropTypes.bool,
  resourceOnline: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onToggleBeacon: PropTypes.func.isRequired,
};

export default PathwayIdentifyV1;
