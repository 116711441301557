import React from 'react';
import PropTypes from 'prop-types';

import { MapPlaceholder } from '..';
import {
  Contributors,
  ZoomControls,
  Controls,
  Layers,
  TileLayer,
  Map,
  Markers,
  WarningMessage,
  RefreshButton,
  MarkersProjectSuite,
  WarningMessageProjectSuite,
  RefreshButtonProjectSuite,
} from './components';
import { osm } from './utilities';

const MapWrapper = ({ isLoading, isProjectSuite }) => {
  const source = osm();

  return (
    <>
      {isLoading
        ? <MapPlaceholder />
        : (
          <div style={{ position: 'relative' }}>
            <Map>
              <Layers>
                <TileLayer
                  source={source}
                  zIndex={0}
                />
                <Controls>
                  <ZoomControls />
                </Controls>
                {isProjectSuite ? <MarkersProjectSuite /> : <Markers />}
              </Layers>
            </Map>
            <Contributors />
            {isProjectSuite ? <WarningMessageProjectSuite /> : <WarningMessage />}
            {isProjectSuite ? <RefreshButtonProjectSuite /> : <RefreshButton />}
          </div>
        )
      }
    </>
  );
};

MapWrapper.defaultProps = {
  isProjectSuite: false,
};

MapWrapper.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isProjectSuite: PropTypes.bool,
};

export default MapWrapper;
