import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

export const parseMessage = message => {
  const parsedMessage = JSON.parse(message);
  return {
    ...parsedMessage,
    ...(parsedMessage.identifier ? { identifier: JSON.parse(parsedMessage.identifier) } : {}),
  };
};

export const sortedJSONStringify = data => {
  if (!isObject(data) || isArray(data) || isEmpty(data)) {
    return JSON.stringify(data);
  }

  const orderedData = Object.keys(data).sort().map(key => {
    const item = data[key];

    return { key, item };
  });

  const stringData = orderedData.reduce((string, { key, item }) => {
    const itemString = sortedJSONStringify(item);
    const dataString = `"${key}":${itemString}`;

    if (string) {
      return `${string},${dataString}`;
    }

    return dataString;
  }, null);

  return `{${stringData}}`;
};

export const stringifyMessage = ({
  identifier = {},
  data = {},
  ...rest
}) => sortedJSONStringify({
  ...rest,
  identifier: sortedJSONStringify(identifier),
  data: sortedJSONStringify(data),
});

export const identifierToString = identifier => (
  `${identifier.channel}_${identifier.logicalDeviceId || ''}_${identifier.data || ''}`
);
