import PermissionModel from 'models/PermissionModel';

const allPermissionsSelector = state => {
  const { allPermissions } = state.permissionsTable;

  const transformed = allPermissions.map(p => new PermissionModel({
    id: p.id,
    ...p.attributes,
  }));

  return transformed;
};

export default allPermissionsSelector;
