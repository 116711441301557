import PropTypes from 'prop-types';

import { SORT_ASCENDING, SORT_DESCENDING } from 'helpers/paginationHelpers';

// Device
export const complexValueType = simpleType => PropTypes.shape({
  value: simpleType,
  timestamp: PropTypes.number,
});

export const clockType = PropTypes.shape({
  now: PropTypes.number,
  tz: PropTypes.number,
  npt: PropTypes.string,
  sunr: PropTypes.number,
  suns: PropTypes.number,
});

export const configType = PropTypes.shape({
  nm: PropTypes.string,
  fn: PropTypes.string,
  sz: PropTypes.string,
  id: PropTypes.string,
  au: PropTypes.string,
  up: PropTypes.number,
  ldnum: PropTypes.number,
  ldnm: PropTypes.string,
});

export const hardwareType = PropTypes.shape({
  md: PropTypes.string,
  sn: PropTypes.string,
  memt: PropTypes.string,
  memu: PropTypes.string,
  memf: PropTypes.string,
  sd: PropTypes.string,
  br: PropTypes.string,
  bl: PropTypes.number,
  up: PropTypes.number,
  wd: PropTypes.bool,
});

export const infrastructureType = PropTypes.shape({
  dmx: PropTypes.shape({
    ccmax: PropTypes.number,
    ccuse: PropTypes.number,
  }),
  dvi: PropTypes.shape({
    out: PropTypes.arrayOf(
      PropTypes.shape({
        fmt: PropTypes.shape({
          res: PropTypes.string,
          fq: PropTypes.string,
        }).isRequired,
      }).isRequired,
    ),
    in: PropTypes.arrayOf(
      PropTypes.shape({
        sig: PropTypes.string,
        fmt: PropTypes.shape({
          res: PropTypes.string,
          fq: PropTypes.string,
        }).isRequired,
        fmtmax: PropTypes.shape({
          res: PropTypes.string,
          fq: PropTypes.string,
        }).isRequired,
      }).isRequired,
    ),
  }),
  cc: PropTypes.shape({
    out: PropTypes.arrayOf(
      PropTypes.shape({
        num: PropTypes.number,
        sts: PropTypes.bool,
      }).isRequired,
    ),
    in: PropTypes.arrayOf(
      PropTypes.shape({
        num: PropTypes.number,
        sts: PropTypes.bool,
      }).isRequired,
    ),
  }),
  anlg: PropTypes.shape({
    in: PropTypes.arrayOf(
      PropTypes.shape({
        num: PropTypes.number,
        lvl: PropTypes.string,
      }).isRequired,
    ),
  }),
});

export const infrastructureWithMetaType = PropTypes.shape({
  dmx: PropTypes.shape({
    ccmax: PropTypes.shape({
      value: PropTypes.number,
      timestamp: PropTypes.number,
    }),
    ccuse: PropTypes.shape({
      value: PropTypes.number,
      timestamp: PropTypes.number,
    }),
  }),
  dvi: PropTypes.shape({
    out: PropTypes.arrayOf(
      PropTypes.shape({
        fmt: PropTypes.shape({
          res: PropTypes.shape({
            value: PropTypes.string,
            timestamp: PropTypes.number,
          }),
          fq: PropTypes.shape({
            value: PropTypes.string,
            timestamp: PropTypes.number,
          }),
        }).isRequired,
      }).isRequired,
    ),
    in: PropTypes.arrayOf(
      PropTypes.shape({
        sig: PropTypes.shape({
          value: PropTypes.string,
          timestamp: PropTypes.number,
        }),
        fmt: PropTypes.shape({
          res: PropTypes.shape({
            value: PropTypes.string,
            timestamp: PropTypes.number,
          }),
          fq: PropTypes.shape({
            value: PropTypes.string,
            timestamp: PropTypes.number,
          }),
        }).isRequired,
        fmtmax: PropTypes.shape({
          res: PropTypes.shape({
            value: PropTypes.string,
            timestamp: PropTypes.number,
          }),
          fq: PropTypes.shape({
            value: PropTypes.string,
            timestamp: PropTypes.number,
          }),
        }).isRequired,
      }).isRequired,
    ),
  }),
  cc: PropTypes.shape({
    out: PropTypes.arrayOf(
      PropTypes.shape({
        num: PropTypes.shape({
          value: PropTypes.number,
          timestamp: PropTypes.number,
        }),
        sts: PropTypes.shape({
          value: PropTypes.bool,
          timestamp: PropTypes.number,
        }),
      }).isRequired,
    ),
    in: PropTypes.arrayOf(
      PropTypes.shape({
        num: PropTypes.shape({
          value: PropTypes.number,
          timestamp: PropTypes.number,
        }),
        sts: PropTypes.shape({
          value: PropTypes.bool,
          timestamp: PropTypes.number,
        }),
      }).isRequired,
    ),
  }),
  anlg: PropTypes.shape({
    in: PropTypes.arrayOf(
      PropTypes.shape({
        num: PropTypes.shape({
          value: PropTypes.number,
          timestamp: PropTypes.number,
        }),
        lvl: PropTypes.shape({
          value: PropTypes.string,
          timestamp: PropTypes.number,
        }),
      }).isRequired,
    ),
  }),
});

export const logType = PropTypes.shape({
  lvl: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
});

export const netType = PropTypes.arrayOf(
  PropTypes.shape({
    nm: PropTypes.string,
    idx: PropTypes.number,
    mac: PropTypes.string,
    ipv4: PropTypes.string,
    ipv6: PropTypes.string,
    sub: PropTypes.string,
    gw: PropTypes.string,
    ns: PropTypes.arrayOf(
      PropTypes.string.isRequired,
    ),
  }).isRequired,
);

export const softwareType = PropTypes.shape({
  fw: PropTypes.string,
  bl: PropTypes.string,
  os: PropTypes.string,
});

export const supportedTypesType = PropTypes.objectOf(
  PropTypes.shape({
    ex: PropTypes.arrayOf(
      PropTypes.string,
    ).isRequired,
    nm: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
);

// Upload
export const uploadStatusFlagsType = PropTypes.shape({
  pausing: PropTypes.bool.isRequired,
  paused: PropTypes.bool.isRequired,
  resuming: PropTypes.bool.isRequired,
});

export const uploadFileType = PropTypes.shape({
  device: PropTypes.string.isRequired,
  fileKey: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  statusFlags: uploadStatusFlagsType.isRequired,
  buttonProps: PropTypes.shape({
    icon: PropTypes.element.isRequired,
  }),
});

export const uploadType = PropTypes.shape({
  project: PropTypes.string,
  files: PropTypes.arrayOf(uploadFileType).isRequired,
});

export const uploadsStatusFlagsType = PropTypes.shape({
  completed: PropTypes.bool.isRequired,
  initialized: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  suspended: PropTypes.bool.isRequired,
});

export const uploadsType = PropTypes.arrayOf(uploadType);

// Common
export const selectOptionsType = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  }),
);

export const paginationType = PropTypes.shape({
  type: PropTypes.string,
  args: PropTypes.object,
});

export const labelType = PropTypes.shape({
  text: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  sortable: PropTypes.bool,
  sortBy: PropTypes.string,
});

export const labelsType = PropTypes.arrayOf(
  labelType,
);

export const sortType = PropTypes.shape({
  field: PropTypes.string,
  direction: PropTypes.oneOf([
    SORT_ASCENDING,
    SORT_DESCENDING,
  ]),
});

export const filterType = PropTypes.shape({
  field: PropTypes.string,
  query: PropTypes.string,
});

export const systemFileType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  lastModified: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
});

export const systemFilesType = PropTypes.arrayOf(
  systemFileType,
);

export const fileTypesMapType = PropTypes.objectOf(
  selectOptionsType,
);

export const resourceType = PropTypes.shape({
  id: PropTypes.string.isRequired,
});

export const simpleValueTypes = [
  PropTypes.string,
  PropTypes.bool,
  PropTypes.number,
];

// Layout
export const layoutSectionType = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  title: PropTypes.string,
};

export const layoutSectionsType = PropTypes.arrayOf(
  PropTypes.shape(layoutSectionType),
);

export const layoutColumnType = {
  id: PropTypes.string.isRequired,
  sections: layoutSectionsType,
};

export const layoutColumnsType = PropTypes.arrayOf(
  PropTypes.shape(layoutColumnType),
);

export const layoutRowType = {
  id: PropTypes.string.isRequired,
  columns: layoutColumnsType,
};

export const layoutRowsType = PropTypes.arrayOf(
  PropTypes.shape(layoutRowType),
);

export const layoutPaneType = {
  fetcher: PropTypes.func,
  id: PropTypes.string.isRequired,
  menuItem: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  path: PropTypes.string.isRequired,
  rows: layoutRowsType,
};

export const layoutPanesType = PropTypes.arrayOf(
  PropTypes.shape(layoutPaneType),
);
