import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/atomic';

const DataTableButton = ({ field, item, ...rest }) => {
  const buttonProps = item[field] || {};
  const props = { ...buttonProps, ...rest };

  return <Button {...props} />;
};

DataTableButton.defaultProps = {
  field: null,
  item: {},
};

DataTableButton.propTypes = {
  field: PropTypes.string,
  item: PropTypes.object,
};

export default DataTableButton;
