import { connect } from 'react-redux';

import { currentCompanyVouchersEnabledSelector } from 'helpers/selectors';
import Login from './Login';
import { actionCreators } from '../../../utilities/user';

export const mapStateToProps = state => ({
  vouchersEnabled: currentCompanyVouchersEnabledSelector(state),
});

const mapDispatchToProps = {
  onLogin: actionCreators.login,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
