import { call, put } from 'redux-saga/effects';

import { deletePath } from 'data/api/requests';
import endpoints from 'data/api/endpoints';
import actionCreators from '../actionCreators';

function* logoutDelete() {
  const endpoint = endpoints.LOGOUT;

  const { error } = yield call(deletePath, endpoint);

  if (error) {
    yield put(actionCreators.logoutError());
  }
}

export default logoutDelete;
