import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Grid, Label, Icon } from 'semantic-ui-react';

import { Panel } from 'components/core';
import { ChildPanelHeader } from 'components/core/Panel/Panel';
import { TasksActionsNavigation } from 'application/tenant/console/site/components/structural/SiteSchedulingTab/components/AutomatedOperations/components/AddAutomatedOperation/components/ActionsStep/components';

import {
  HeaderContainer,
  StyledDeviceName,
  StyledLink,
  StyledList,
  StyledListItem,
  StyledGroupsContainer,
  StyledDeviceContainer,
  EmptyMessage,
} from './SelectGroupsStep.style';

function SelectGroupsStep({
  resourceType,
  groups: defaultGroups,
  onChange,
}) {
  const [selectedGroups, setSelectedGroups] = useState(defaultGroups);

  const handleGroupSelected = useCallback(selectedGroup => {
    const { key } = selectedGroup;

    const selectedGroupIndex = selectedGroups.findIndex(item => item.key === key);

    if (selectedGroupIndex === -1) {
      setSelectedGroups(previous => [...previous, selectedGroup]);
    } else {
      setSelectedGroups(previous => previous.filter(
        item => item.key !== key,
      ));
    }
  }, [selectedGroups]);

  const groupedByDevice = useMemo(() => {
    const grouped = {};

    selectedGroups.forEach(selectedGroup => {
      const { logicalDevice, group } = selectedGroup;
      const { id: deviceId } = logicalDevice;

      if (!grouped[deviceId]) {
        grouped[deviceId] = {
          deviceId,
          deviceName: logicalDevice.name,
          groups: [],
        };
      }

      grouped[deviceId].groups.push(group);
    });

    return grouped;
  }, [selectedGroups]);

  const handleRemoveGroup = useCallback((deviceId, groupId) => {
    const key = `${deviceId}_${groupId}`;

    setSelectedGroups(previous => previous.filter(
      item => item.key !== key,
    ));
  }, []);

  const handleRemoveDevice = useCallback(deviceId => {
    setSelectedGroups(previous => previous.filter(group => {
      const { logicalDevice } = group;

      if (logicalDevice.id === deviceId) {
        return false;
      }

      return group;
    }));
  }, []);

  useEffect(() => {
    onChange(selectedGroups);
  }, [selectedGroups, onChange]);

  return (
    <Panel
      title="Groups configuration"
      description="Select groups that will be overriden by the RGB Control"
    >
      <Grid.Row>
        <Grid.Column computer={6} mobile={16}>
          <HeaderContainer>
            <ChildPanelHeader
              title="Devices & groups"
              description="Navigate and select groups to override"
            />
          </HeaderContainer>
          <TasksActionsNavigation
            resourceType={resourceType}
            selectedDevice={{}}
            defaultViewType={resourceType === 'project' ? 'site_devices' : 'multi_site_child_sites'}
            tabs={['site_devices']}
            mode="GROUP_SELECTION"
            selectedGroups={selectedGroups}
            onGroupSelected={handleGroupSelected}
          />
        </Grid.Column>
        <Grid.Column computer={10} mobile={16}>
          <ChildPanelHeader
            title="Selected groups"
            description="Groups selected for override"
          />
          <StyledGroupsContainer isEmpty={isEmpty(selectedGroups)}>
            {isEmpty(selectedGroups) && (
              <EmptyMessage>
                No groups selected for override. <br />
                Specify at least one group to proceed.
              </EmptyMessage>
            )}
            {Object.keys(groupedByDevice).map(deviceId => {
              const { deviceName, groups } = groupedByDevice[deviceId];

              return (
                <StyledDeviceContainer key={deviceId}>
                  <StyledDeviceName basic>
                    {deviceName}
                    <StyledLink className="clear-label-link" onClick={() => handleRemoveDevice(deviceId)}>
                      Clear all
                    </StyledLink>
                  </StyledDeviceName>
                  <StyledList>
                    {groups.map(group => (
                      <StyledListItem key={`${deviceId}-${group.id}`}>
                        <Label htmlFor="input">
                          {group.name} <Icon name="delete" onClick={() => handleRemoveGroup(deviceId, group.id)} />
                        </Label>
                      </StyledListItem>
                    ))}
                  </StyledList>
                </StyledDeviceContainer>
              );
            })}
          </StyledGroupsContainer>
        </Grid.Column>
      </Grid.Row>
    </Panel>
  );
}

SelectGroupsStep.defaultProps = {
  groups: [],
};

SelectGroupsStep.propTypes = {
  groups: PropTypes.array,
  resourceType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SelectGroupsStep;
