export default class Mscxrev1Creator {

  constructor(sixeyeFirmwareVersion, tabs) {
    this.model = 'MSC X rev1';
    this.sixeyeFirmwareVersion = sixeyeFirmwareVersion;
    this.manufacturerSDK = 'etc';
    this.tabs = tabs;
  }

}
