import React from 'react';

import { PageHeader } from 'components/core';
import { StyledDivider } from './Firmware.style';

import { DeviceFirmwaresTable, FirmwareFilesTable } from './components';

const Firmware = () => (
  <>
    <PageHeader title="Firmware" />
    <DeviceFirmwaresTable />
    <StyledDivider />
    <FirmwareFilesTable />
  </>
);

export default Firmware;
