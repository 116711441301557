import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Modal } from 'semantic-ui-react';

import { USERS_FIELD } from 'helpers/selectors';
import { Button } from 'components/atomic';
import DataTable from 'components/DataTable/DataTableContainer';

import UserTableRow from './components/UserTableRow/UserTableRow';
import { StyledAddUsersButton, StyledModalActions } from './UsersModal.style';

const usersTableLabels = [
  { text: 'First name', field: 'firstName' },
  { text: 'Last name', field: 'lastName' },
  { text: 'Email', field: 'email' },
  { text: 'Status', field: 'statusIcon', sortable: false },
];

function UsersModal({ onUserSelected }) {
  const [open, setOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const trigger = (
    <span>
      <StyledAddUsersButton onClick={handleOpen}>
        Select user...
      </StyledAddUsersButton>
    </span>
  );

  const handleSelectUser = useCallback(id => {
    setSelectedUserId(id);
  }, []);

  const handleConfirm = useCallback(() => {
    setOpen(false);
    onUserSelected(selectedUserId);
  }, [selectedUserId, onUserSelected]);

  return (
    <Modal open={open} trigger={trigger} onClose={handleClose}>
      <Modal.Header>
        Select a user
      </Modal.Header>
      <Modal.Content scrolling>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16}>
              <DataTable
                labels={usersTableLabels}
                customTableRow={UserTableRow}
                selectedUserId={selectedUserId}
                onSelectUser={handleSelectUser}
                pagination={{
                  type: USERS_FIELD,
                  included: true,
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <StyledModalActions>
        <Button name="btn-cancel" primary basic onClick={handleClose}>
          Cancel
        </Button>
        <Button name="btn-confirm" primary onClick={handleConfirm}>
          Confirm
        </Button>
      </StyledModalActions>
    </Modal>
  );
}

UsersModal.propTypes = {
  onUserSelected: PropTypes.func.isRequired,
};

export default UsersModal;
