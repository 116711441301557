import constants from 'dispatcherConst';
import { messages } from 'data/notifications/notificationsConst';

export const editProfile = ({ attributes, ...rest }) => ({
  type: constants.EDIT_PROFILE,
  payload: { attributes, ...rest },
});

export const editProfileSuccess = () => ({
  type: constants.EDIT_PROFILE_SUCCESS,
  payload: {
    message: messages.DATA_SAVED,
  },
});

export const editProfileFailure = error => ({
  type: constants.EDIT_PROFILE_FAILED,
  payload: { error },
});
