import uniq from 'lodash/uniq';

const getDependentParams = params => {
  if (!Array.isArray(params) && params.length > 0) {
    return [];
  }

  return params.reduce((acc, item) => {
    if (item.dependentOn && Array.isArray(item.dependentOn)) {
      return uniq(acc.concat(item.dependentOn.map(param => param.key)));
    }

    return acc;
  }, []);
};

export default getDependentParams;
