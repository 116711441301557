import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import { filterType } from 'data/types';
import { getDisplayName } from 'helpers/sharedMethods';
import UrlParams from 'helpers/UrlParams';

const withFilter = WrappedComponent => {
  const WithFilter = ({
    filter,
    pagination,
    location,
    history,
    onRefetch,
    ...rest
  }) => {

    const handleFilter = useCallback((query, fieldName, modificationType = 'push') => {
      const urlParams = new UrlParams({ pagination });
      urlParams.filter = query;
      const { search } = urlParams;

      history[modificationType]({ ...location, search });

      const { type, componentName } = pagination;

      const getType = componentName || type;

      onRefetch(getType, {
        filter: {
          [fieldName]: query,
        },
      });
    }, [history, location, pagination, onRefetch]);

    useEffect(() => {
      if (filter) {
        const urlParams = new UrlParams({ pagination });
        const { filter: filterParams } = urlParams;

        if (!isEqual(filter, filterParams) && Object.keys(filter) > 0) {
          const fieldName = Object.keys(filter)[0];
          const query = filter[fieldName];

          handleFilter(query, fieldName, 'replace');
        }
      }

    }, [filter, pagination, handleFilter]);

    return (
      <WrappedComponent
        {...rest}
        onFilter={handleFilter}
        filter={filter || null}
      />
    );
  };

  WithFilter.displayName = `WithFilter(${getDisplayName(WrappedComponent)})`;

  WithFilter.defaultProps = {
    pagination: { type: '' },
    filter: null,
    componentName: '',
  };

  WithFilter.propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
    pagination: PropTypes.shape({
      type: PropTypes.string,
      included: PropTypes.bool,
      componentName: PropTypes.string,
    }),
    componentName: PropTypes.string,
    filter: filterType,
    onRefetch: PropTypes.func.isRequired,
  };

  return WithFilter;
};

export default withFilter;
