import constants from 'dispatcherConst';
import { messages } from 'data/notifications/notificationsConst';

const updateSiteDate = args => ({
  type: constants.UPDATE_SITE_REQUEST,
  payload: {
    ...args,
    message: messages.SITE_DATE_UPDATED,
  },
});

export default updateSiteDate;
