import { compose } from 'redux';
import { connect } from 'react-redux';
import last from 'lodash/last';

import {
  LOGICAL_DEVICES_FIELD,
  singleLogicalDeviceSelector,
  computedLogicalDeviceTreePathSelector,
} from 'helpers/selectors';
import { deepEqualMemoizeOne, modifySelfWithResourceName } from 'helpers/sharedMethods';
import permissionsActionCreators from '../../../../utilities/permissions/permissionsActionCreators';
import { PermissionsTreeItem } from '../../../../components';
import { sortPaths } from '../../../../utilities';

// eslint-disable-next-line import/no-mutable-exports
let DevicePermissionsTreeItemContainer;

export const makeMapState = () => {
  const memoPaths = deepEqualMemoizeOne();

  return function mapStateToProps(state, { path, resourceId }) {
    const { visibleName } = singleLogicalDeviceSelector(state, resourceId);
    const { open, permissionCount, permissionId, ...paths } = computedLogicalDeviceTreePathSelector(
      state,
      {
        logicalDeviceId: resourceId,
        path,
      },
    );
    const label = modifySelfWithResourceName(last(path.split('.')), visibleName, 'Device');
    const sortedPaths = sortPaths(paths);

    return {
      open,
      label,
      paths: memoPaths(sortedPaths),
      PermissionsTreeItemContainer: DevicePermissionsTreeItemContainer,
    };
  };
};

export const mapDispatchToProps = {
  onToggleOpen: (deviceId, path) => permissionsActionCreators.toggleResourceTreeOpen(
    LOGICAL_DEVICES_FIELD,
    deviceId,
    path,
  ),
};

DevicePermissionsTreeItemContainer = compose(
  connect(makeMapState, mapDispatchToProps),
)(PermissionsTreeItem);

export default DevicePermissionsTreeItemContainer;
