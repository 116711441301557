export default class PathwayCreator {

  constructor(sixeyeFirmwareVersion, tabs) {
    this.model = 'Vignette Clock';
    this.sixeyeFirmwareVersion = sixeyeFirmwareVersion;
    this.manufacturerSDK = 'pathway';
    this.tabs = tabs;
  }

}
