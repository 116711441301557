import { Icon, MenuItem } from 'semantic-ui-react';
import styled from 'styled-components';

import colors from 'themes/colors';

const MenuSwitch = styled(MenuItem)`
  &&&& {
    padding-right: 0;
    
    &:hover {
      background: transparent;
    }
  }
`;

MenuSwitch.Icon = styled(Icon).attrs({
  name: 'sidebar',
})`
  &.icon {
    width: 1em;
    font-size: 2.5em;
    
    &:hover {
      color: ${colors.grey};
    }
  }
`;

export default MenuSwitch;
