import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';
import deepEqual from 'lodash/isEqual';
import identity from 'lodash/identity';

import { deviceSelector } from 'helpers/selectors';
import PathwayDevicePropertiesV1 from './PathwayDevicePropertiesV1';

export const makeMapState = () => {
  const memoUsage = memoizeOne(identity, deepEqual);
  const memoVlan = memoizeOne(identity, deepEqual);

  return function mapStateToProps(state, ownProps) {
    const { resourceId } = ownProps;
    const device = deviceSelector(state)(resourceId);
    const { reportedWithMeta } = device;

    const {
      hw: {
        poeU: usage,
      } = {},
      pathway: {
        vlan,
      } = {},
    } = reportedWithMeta || {};

    return {
      loading: !reportedWithMeta,
      usage: memoUsage(usage),
      vlan: memoVlan(vlan),
    };
  };
};

export default connect(makeMapState)(PathwayDevicePropertiesV1);
