import React from 'react';
import PropTypes from 'prop-types';

import { PermissionsSwitch, Gateway, frontendPermissions } from 'gateway';
import { InConstruction, SuspendedIcon } from 'components/atomic';
import { CONSTRUCTION_MESSAGE, SITE_SUSPENDED } from 'helpers/sharedVariables';
import ControlPanelButton from '../../ControlPanelButton/ControlPanelButton';

const RenderControlPanelButton = ({ stateLoaded, isConstruction, isSuspended }) => {
  if (!stateLoaded) {
    return null;
  }

  if (isConstruction) {
    return <InConstruction message={CONSTRUCTION_MESSAGE} />;
  }

  if (isSuspended) {
    return <SuspendedIcon message={SITE_SUSPENDED} />;
  }

  return (
    <PermissionsSwitch>
      <Gateway
        condition={frontendPermissions.VIEW_CONTROL_PANEL}
        component={ControlPanelButton}
      />
    </PermissionsSwitch>
  );
};

RenderControlPanelButton.propTypes = {
  isConstruction: PropTypes.bool.isRequired,
  isSuspended: PropTypes.bool.isRequired,
  stateLoaded: PropTypes.bool.isRequired,
};

export default RenderControlPanelButton;
