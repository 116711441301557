import React from 'react';
import PropTypes from 'prop-types';

import { IsInGracePeriodIcon, IsApproachingRenewalIcon, IsDemoIcon } from './components';

const RenderWarningIcon = ({
  isInGracePeriod,
  isApproachingRenewal,
  isGrace,
  isDemo,
}) => {
  if (isDemo) {
    return <IsDemoIcon />;
  }

  if (isInGracePeriod) {
    return <IsInGracePeriodIcon />;
  }

  if (isApproachingRenewal) {
    return <IsApproachingRenewalIcon />;
  }

  if (isGrace) {
    return <IsInGracePeriodIcon />;
  }

  return null;
};

RenderWarningIcon.defaultProps = {
  isInGracePeriod: false,
  isApproachingRenewal: false,
  isGrace: false,
  isDemo: false,
};

RenderWarningIcon.propTypes = {
  isInGracePeriod: PropTypes.bool,
  isApproachingRenewal: PropTypes.bool,
  isGrace: PropTypes.bool,
  isDemo: PropTypes.bool,
};

export default RenderWarningIcon;
