import constants from 'dispatcherConst';

const toggleShowHiddenOccurrences = showHidden => ({
  type: constants.TOGGLE_SHOW_HIDDEN_OCCURRENCES,
  payload: {
    showHidden,
  },
});

export default toggleShowHiddenOccurrences;
