import { darken, lighten, rgba, saturate } from 'polished';

import pharosLogo from 'assets/logos/pharos/navbar-logo.svg';
import { breadcrumbs as commonBreadcrumbs } from 'navigation';
import pharosSignInBackground from 'assets/backgrounds/pharos/sign-in-background.svg';
import colors from '../colors';

export default {
  palette: {
    primaryColor: colors.azure,
  },
  logo: {
    src: pharosLogo,
    alt: 'Pharos logo',
  },
  favicons: [
    {
      href: '//www.pharoscontrols.com/wp-content/uploads/2016/05/cropped-downloads-designer2-32x32.png',
      sizes: '32x32',
    },
  ],
  manifest: {
    short_name: 'Pharos Cloud',
    name: 'Pharos Cloud Web Client',
    icons: [
      {
        src: '/themes/pharosBlue/pharos_cloud_icon.png',
        sizes: '180x180',
        type: 'image/png',
      },
    ],
    theme_color: colors.azure,
    background_color: '#ffffff',
    splash_screens: [
      {
        src: '/themes/pharosBlue/PharosCloud-Homepageapp-1125x2436px.png',
        sizes: '1125x2436',
        type: 'image/png',
      },
      {
        src: '/themes/pharosBlue/PharosCloud-Homepageapp-1536x2048px.png',
        sizes: '1536x2048',
        type: 'image/png',
      },
      {
        src: '/themes/pharosBlue/PharosCloud-Homepageapp-2048x2732px.png',
        sizes: '2048x2732',
        type: 'image/png',
      },
      {
        src: '/themes/pharosBlue/PharosCloud-Homepageapp-750x1334px.png',
        sizes: '750x1334',
        type: 'image/png',
      },
      {
        src: '/themes/pharosBlue/PharosCloud-Homepageapp-1242x2208px.png',
        sizes: '1242x2208',
        type: 'image/png',
      },
      {
        src: '/themes/pharosBlue/PharosCloud-Homepageapp-1668x2224px.png',
        sizes: '1668x2224',
        type: 'image/png',
      },
      {
        src: '/themes/pharosBlue/PharosCloud-Homepageapp-2344x5075px.png',
        sizes: '2344x5075',
        type: 'image/png',
      },
      {
        src: '/themes/pharosBlue/PharosCloud-Homepageapp-828x1792px.png',
        sizes: '828x1792',
        type: 'image/png',
      },
      {
        src: '/themes/pharosBlue/PharosCloud-Homepageapp-1242x2688px.png',
        sizes: '1242x2688',
        type: 'image/png',
      },
      {
        src: '/themes/pharosBlue/PharosCloud-Homepageapp-1668x2388px.png',
        sizes: '1668x2388',
        type: 'image/png',
      },
      {
        src: '/themes/pharosBlue/PharosCloud-Homepageapp-640x1136px.png',
        sizes: '640x1136',
        type: 'image/png',
      },
    ],
  },
  breadcrumbs: commonBreadcrumbs,
  updateNotificationPanel: {
    background: colors.azure,
    color: colors.white,
    'border-color': colors.azure,
  },
  signInBackground: {
    background: `url(${pharosSignInBackground})`,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    'margin-top': '10vh',
    'background-size': 'contain',
    'background-repeat': 'no-repeat',
  },
  sidebar: {
    '.ui.menu': {
      '.item': {
        color: colors.grey,
      },
    },
    '.ui.menu.vertical': {
      '.active.item': {
        color: colors.azure,
      },
      md: {
        background: colors.concrete,
      },
    },
  },
  notifications: {
    '.ui.message': {
      'box-shadow': 'none',
      '&.success': {
        'background-color': saturate(0.10, lighten(0.2, colors.azure)),
        color: colors.white,
      },
      '&.error': {
        'background-color': colors.cavernPink,
        color: colors.stiletto,
      },
    },
  },
  buttons: {
    '.ui.button': {
      'border-radius': 0,
      '&.primary': {
        'background-color': colors.azure,
        color: colors.white,
        '&:hover': {
          'background-color': saturate(0.10, darken(0.05, colors.azure)),
        },
        '&:focus': {
          'background-color': saturate(0.20, darken(0.08, colors.azure)),
        },
        '&:active': {
          'background-color': darken(0.1, colors.azure),
        },
        '&.active': {
          'background-color': saturate(0.15, darken(0.05, colors.azure)),
        },
        '&.basic': {
          'background-color': colors.white,
          color: colors.azure,
          'box-shadow': `0 0 0 1px ${colors.azure} inset!important`,
          '&:hover': {
            'background-color': saturate(0.10, darken(0.05, colors.azure)),
            color: saturate(0.10, darken(0.05, colors.azure)),
          },
          '&:focus': {
            'background-color': saturate(0.20, darken(0.08, colors.azure)),
            color: saturate(0.10, darken(0.05, colors.azure)),
          },
          '&:active': {
            'background-color': darken(0.1, colors.azure),
            color: darken(0.1, colors.azure),
          },
          '&.active': {
            'background-color': saturate(0.15, darken(0.05, colors.azure)),
            color: darken(0.1, colors.azure),
          },
        },
        '&.inverted': {
          'background-color': colors.white,
          color: colors.azure,
          'box-shadow': `0 0 0 2px ${colors.azure} inset!important`,
          '&:hover': {
            'background-color': saturate(0.10, darken(0.05, colors.azure)),
            color: colors.white,
          },
          '&:focus': {
            'background-color': saturate(0.20, darken(0.08, colors.azure)),
            color: colors.white,
          },
          '&:active': {
            'background-color': darken(0.1, colors.azure),
            color: colors.white,
          },
          '&.active': {
            'background-color': saturate(0.15, darken(0.05, colors.azure)),
            color: colors.white,
          },
        },
      },
    },
  },
  tables: {
    listItems: {
      '.marked': {
        'background-color': saturate(0.10, lighten(0.2, colors.azure)),
        '& dt, dd': {
          color: colors.white,
        },
      },
    },
    statusIcons: {
      '.model-device': {
        '&.online': {
          color: colors.azure,
        },
        '&.offline': {
          color: colors.stiletto,
        },
        '&.unassociated': {
          color: colors.darkGrey,
        },
      },
      '.model-user': {
        '&.active': {
          color: colors.azure,
        },
        '&.suspended': {
          color: colors.stiletto,
        },
        '&.inactive': {
          color: colors.darkGrey,
        },
        '&.invited': {
          color: colors.carrot,
        },
      },
    },
  },
  links: {
    color: colors.azure,
    '&:hover': {
      'text-decoration': 'underline',
    },
  },
  forms: {
    '.ui.form': {
      input: {
        'border-radius': 0,
        'padding-top': '1em',
        'padding-bottom': '1em',
        '&:focus': {
          'border-radius': 0,
          'border-color': colors.azure,
        },
      },
    },
  },
  checkboxes: {
    '.ui.checkbox': {
      'line-height': '26px',
      'min-width': '26px',
      'min-height': '26px',
      label: {
        color: rgba(colors.black, 0.3),
        'padding-left': '2.5em',
        '&:before': {
          width: '26px',
          height: '26px',
          'border-radius': 0,
        },
        '&:after': {
          width: '26px',
          height: '26px',
        },
      },
      input: {
        ':focus~label': {
          color: rgba(colors.black, 0.3),
        },
        ':checked~label:after': {
          color: colors.azure,
        },
      },
    },
  },
  indeterminateCheckbox: {
    '&:after': {
      'border-left-color': colors.azure,
    },
  },
};
