import styled from 'styled-components';

export const StyledDiv = styled.div`
  width: 100%;
`;

export const StyledStrong = styled.strong`
  display: inline-block; 
  margin-right: 3px;
`;

export const StyledSpan = styled.span`
  display: inline-block; 
  margin-right: 3px;
`;

export default StyledDiv;
