import styled from 'styled-components';

import { mediaMax } from 'themes/mixins';

export const StyledWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
  margin-top: 30px;
  padding: 0 10px 10px 10px;

  ${mediaMax.sm`
    flex-wrap: wrap;
  `}
`;

export const StyledSlidersWrapper = styled('div')`
  display: flex; 
  padding-left: 25px;
`;

export const StyledBarWrapper = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 30px;
  margin: auto;
`;

export const WheelBarsWrapper = styled('div')`
  display: flex;
  
  ${mediaMax.sm`margin-left: 10px;`}
`;

export default StyledWrapper;
