import React from 'react';
import PropTypes from 'prop-types';

const withLoadPermissions = WrappedComponent => {
  const WithLoadPermissions = ({ contextId, ...rest }) => {
    if (!contextId) {
      return null;
    }

    return <WrappedComponent {...rest} />;
  };

  WithLoadPermissions.defaultProps = {
    contextId: null,
  };

  WithLoadPermissions.propTypes = {
    contextId: PropTypes.string,
  };

  return WithLoadPermissions;
};

export default withLoadPermissions;
