import styled from 'styled-components';
import { Field } from 'redux-form';

export const StyledField = styled(Field)`
  &.ui.input>input {
    max-width: 80px; 
    margin-top: 10px;
  }
`;

export default StyledField;
