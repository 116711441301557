import styled from 'styled-components';

import MinutesInput from '../MinutesInput/MinutesInput';
import { MinutesInputElement } from '../MinutesInput/MinutesInput.style';

export const StyledMinutesInput = styled(MinutesInput)`
  & ${MinutesInputElement} {
    width: 2.4em!important;
  }
`;

export default StyledMinutesInput;
