import constants from 'dispatcherConst';

const pasteButton = ({
  projectId,
  pageId,
  buttonId,
  controlType = 'button',
}) => ({
  type: constants.PASTE_BUTTON,
  payload: {
    projectId,
    pageId,
    buttonId,
    controlType,
  },
});

export default pasteButton;
