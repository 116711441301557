import React from 'react';
import { Container, Grid } from 'semantic-ui-react';

import { frontendPermissions, PermissionsSwitch, Gateway } from 'gateway';
import { Message, Text } from './ControlUIEmptyMessage.style';

const ControlUIEmptyMessage = () => (
  <Container>
    <Grid centered>
      <Grid.Row>
        <Grid.Column textAlign="center" mobile={16}>
          <Message>
            <Text>
              No pages found.
              <br />
              <PermissionsSwitch>
                <Gateway condition={frontendPermissions.ADD_PAGE_CONTROL_PANEL}>
                  Add a page to continue.
                </Gateway>
                <Gateway fallback>
                  Please contact the site administrator.
                </Gateway>
              </PermissionsSwitch>
            </Text>
          </Message>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
);

export default ControlUIEmptyMessage;
