import React from 'react';
import PropTypes from 'prop-types';

import { ListPlaceholder } from '../../..';

import {
  List,
  ListItem,
  ProjectInfo,
  StyledIcon,
} from './ProjectsList.style';

function ProjectsList({
  isLoading,
  projects,
  onProjectSelect,
}) {
  if (isLoading) {
    return <ListPlaceholder />;
  }

  return (
    <List divided verticalAlign="middle">
      {projects.map((project, index) => (
        <ListItem
          key={project.id}
          tabIndex={index + 1}
          onClick={() => onProjectSelect(project)}
        >
          <ProjectInfo>
            <p>{project.name}</p>
          </ProjectInfo>
          <StyledIcon name="chevron right" />
        </ListItem>
      ))}
    </List>
  );
}

ProjectsList.defaultProps = {
  projects: [],
  isLoading: false,
};

ProjectsList.propTypes = {
  projects: PropTypes.array,
  isLoading: PropTypes.bool,
  onProjectSelect: PropTypes.func.isRequired,
};

export default ProjectsList;
