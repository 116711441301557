const scheduleFormFields = {
  name: 'name',
  description: 'description',
  date: 'date',
  selectedDates: 'selectedDates',
  selectedTimes: 'selectedTimes',
  hours: 'hours',
  minutes: 'minutes',
  seconds: 'seconds',
  time: 'time',
  advancedMode: 'advancedMode',
  sunrise: 'sunrise',
  sunset: 'sunset',
};

export default scheduleFormFields;
