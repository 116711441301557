import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';

export const renewSuspendedSite = ({ projectId, params }) => ({
  type: constants.RENEW_SUSPENDED_SITE_REQUEST,
  payload: {
    params,
    endpoint: endpoints.RENEW_SUSPENDED_SITE(projectId),
  },
});

export const renewSuspendedSiteSuccess = response => ({
  type: constants.RENEW_SUSPENDED_SITE_SUCCESS,
  payload: {
    response,
    message: messages.SITE_RENEWAL_SUCCESS,
  },
});

export const renewSuspendedSiteFailure = () => ({
  type: constants.RENEW_SUSPENDED_SITE_FAILED,
  payload: {
    message: messages.SITE_RENEWAL_FAILED,
  },
});

export default renewSuspendedSite;
