import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { selectors, actionCreators } from '../../../../../../utilities';
import InlineEditPage from './InlineEditPage';

export const mapStateToProps = (state, { match, pageId }) => {
  const { id: projectId } = match.params;
  const { pages = [] } = selectors.projectControlUISelector(state)(projectId) || {};
  const {
    name,
    nameColor,
  } = pages.filter(({ index }) => index === pageId)[0] || {};

  return {
    projectId,
    name,
    nameColor,
  };
};

const mapDispatchToProps = {
  onEditPageName: actionCreators.renamePage,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(InlineEditPage);
