import mergeWith from 'lodash/mergeWith';

import { DEVICES_FOR_SITE_FIELD } from 'helpers/selectors';
import constants from 'dispatcherConst';
import { mergeCustomizer } from 'helpers/sharedMethods';

const dataReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.FETCH_SITE_DEVICES_SUCCESS: {
      const {
        response: {
          data,
          projectId,
        },
      } = action.payload;
      const devices = data.map(device => {
        const {
          id,
          attributes: {
            deviceAssigned,
            name,
            online,
            beacon,
          },
        } = device;

        return {
          id,
          deviceAssigned,
          name,
          online,
          beacon,
        };
      });
      const devicesPerProject = {
        [DEVICES_FOR_SITE_FIELD]: {
          [projectId]: {
            attributes: {
              devicesList: devices,
            },
          },
        },
      };

      return mergeWith({}, state, devicesPerProject, mergeCustomizer);
    }

    default:
      return state;
  }
};

export default dataReducer;
