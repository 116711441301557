import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { menuVisibleSelector, controlPanelContextSelector } from 'helpers/selectors';
import menuActionCreators from './menuActionCreators';
import MainMenu from './MainMenu';

export const mapStateToProps = state => ({
  inControlPanelContext: controlPanelContextSelector(state),
  sidebarVisible: menuVisibleSelector(state),
});

const mapDispatchToProps = {
  showMenu: menuActionCreators.showMenu,
  onHideMenu: menuActionCreators.hideMenu,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainMenu),
);
