import styled from 'styled-components';
import InlineEdit from 'components/form/InlineEdit/InlineEditContainer';

export const StyledInlineEditTaskProperty = styled(InlineEdit)`
  display: inline-block;
  
  span {
    padding: 0px 0.2em;
  }

  div {
    min-height: 0px;
  }

  & .ui.left.icon.input .ui.icon.primary.button {
    @media only screen and (max-width: 991px) {
      right: 15px;
    }
  }
`;

export default StyledInlineEditTaskProperty;
