import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormGroup, Grid } from 'semantic-ui-react';

import ModalForm from 'components/form/ModalForm/ModalForm';
import ColorSelectField from 'components/form/ColorSelectField/ColorSelectField';

export const pageNameFormFields = {
  nameColor: 'nameColor',
};

const PageNameColorPickerModalForm = props => {
  const {
    initialValues: {
      [pageNameFormFields.nameColor]: initialNameColor,
    },
  } = props;

  return (
    <ModalForm
      header="Configure page name"
      size="small"
      {...props}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} computer={8}>
            <FormGroup inline>
              <Field
                name={pageNameFormFields.nameColor}
                component={ColorSelectField}
                label="Page name colour"
                initialColor={initialNameColor}
              />
            </FormGroup>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ModalForm>
  );
};

PageNameColorPickerModalForm.propTypes = {
  initialValues: PropTypes.shape({
    [pageNameFormFields.nameColor]: PropTypes.object,
  }).isRequired,
};

export default PageNameColorPickerModalForm;
