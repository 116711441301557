import { COMPANIES_FIELD } from 'helpers/selectors';
import ApiRequestBuilder from 'helpers/ApiRequestBuilder';
import OriginModel from 'models/OriginModel';

class CompanyModel {

  constructor(company = {}) {
    this.id = company.id;
    this.name = company.name;
    this.registeredName = company.registeredName;
    this.email = company.email;
    this.tenantService = company.tenantService;
    this.subdomain = company.subdomain;
    this.themeName = company.themeName;
    this.deviceMapping = company.deviceMapping;
    this.manufacturer = company.manufacturer;
    this.mailgunDomain = company.mailgunDomain;
    this.twoFactorEnabled = company.twoFactorEnabled;
    this.vouchersEnabled = company.vouchersEnabled;
    this.trialPeriodDays = company.trialPeriodDays;
    this.gracePeriodDays = company.gracePeriodDays;
    this.renewalReminderDays = company.renewalReminderDays;
    this.renewalReminderDaysTwo = company.renewalReminderDaysTwo;
    this.renewalDate = company.renewalDate;
    this.notificationsEnabled = company.notificationsEnabled;
    this.soldVia = company.soldVia;

    const { origins = [] } = company;

    this.origins = origins.map(
      origin => new OriginModel(origin, company.urlOriginId),
    );
  }

  static requestBody(values) {
    return new ApiRequestBuilder(COMPANIES_FIELD)
      .setAttributes(values);
  }

}

export default CompanyModel;
