import React from 'react';

import {
  ProjectTasksTab,
  SiteSchedulingTab,
  SiteSubscriptionTab,
  SiteActivityLoggingTab,
} from 'application/tenant/console/site/components';
import { StyledLabel } from './Project.style';
import ProjectDevicesTab from './ProjectDevicesTab/ProjectDevicesTabContainer';
import ProjectUsersTab from './ProjectUsersTab/ProjectUsersTabContainer';
import ProjectPermissionsTabNew from './ProjectPermissionsTabNew/ProjectPermissionsTabContainer';
import ProjectSettingsTab from './ProjectSettingsTab/ProjectSettingsTabContainer';
import ProjectControlUITab from './ProjectControlUITab/ProjectControlUITabContainer';

import { ACTIVITY_TAB } from './constants';

export default [
  {
    menuItem: 'Devices',
    path: '/devices',
    rows: [
      {
        columns: [
          {
            computer: 16,
            mobile: 16,
            sections: [
              {
                component: ProjectDevicesTab,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Users',
    path: '/users',
    rows: [
      {
        columns: [
          {
            computer: 16,
            mobile: 16,
            sections: [
              {
                component: ProjectUsersTab,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Permissions',
    path: '/permissions',
    rows: [
      {
        columns: [
          {
            computer: 16,
            mobile: 16,
            sections: [
              {
                component: ProjectPermissionsTabNew,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Settings',
    path: '/settings',
    rows: [
      {
        columns: [
          {
            computer: 16,
            mobile: 16,
            sections: [
              {
                component: ProjectSettingsTab,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Tasks',
    path: '/tasks',
    rows: [
      {
        columns: [
          {
            computer: 16,
            mobile: 16,
            sections: [
              {
                component: ProjectTasksTab,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Scheduling',
    path: '/scheduling',
    rows: [
      {
        columns: [
          {
            computer: 16,
            mobile: 16,
            sections: [
              {
                component: SiteSchedulingTab,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Control Panel',
    path: '/control_panel_config',
    rows: [
      {
        columns: [
          {
            computer: 16,
            mobile: 16,
            sections: [
              {
                component: ProjectControlUITab,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: 'Subscription',
    path: '/subscription',
    rows: [
      {
        columns: [
          {
            computer: 16,
            mobile: 16,
            sections: [
              {
                component: SiteSubscriptionTab,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuItem: (
      <span>
        {ACTIVITY_TAB}
        <StyledLabel key="tab">beta</StyledLabel>
      </span>
    ),
    name: ACTIVITY_TAB,
    path: '/activity',
    rows: [
      {
        columns: [
          {
            computer: 16,
            mobile: 16,
            sections: [
              {
                component: SiteActivityLoggingTab,
              },
            ],
          },
        ],
      },
    ],
  },
];
