import has from 'lodash/has';

const recipientsSelector = (state, resourceId) => {
  const { recipients } = state.permissionsTable;

  if (!has(recipients, resourceId)) {
    return [];
  }

  return recipients[resourceId];
};

export default recipientsSelector;
