import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';

import {
  StyledLabel,
  StyledRow,
  DropdownContainer,
  StyledOccurrenceTypeDropdown,
  StyledOccurrenceDayDropdown,
  StyledOccurrenceMonthDropdown,
} from './AdvancedOptions.style';
import { getOptionsFromCondition } from '../../utilities';
import {
  OCCURRENCE_TYPE,
  OCCURRENCE_TYPE_OPTIONS,
  OCCURRENCE_DAY,
  OCCURRENCE_DAY_OPTIONS,
  OCCURRENCE_MONTH,
  OCCURRENCE_MONTH_OPTIONS,
} from '../../../constants';

function AdvancedOptions({
  existingSchedule,
  onChange,
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [occurrenceType, setOccurrenceType] = useState(OCCURRENCE_TYPE.FIRST);
  const [occurrenceDay, setOccurrenceDay] = useState(OCCURRENCE_DAY.DAY);
  const [occurrenceMonth, setOccurrenceMonth] = useState(OCCURRENCE_MONTH.JANUARY);

  useEffect(() => {
    if (existingSchedule && !isLoaded) {
      const { params } = existingSchedule || {};

      const { condition } = params;

      if (condition) {
        const options = getOptionsFromCondition(condition);

        if (!isEmpty(Object.keys(options))) {
          const {
            occurrenceType: existingType,
            occurrenceDay: existingDay,
            occurrenceMonth: existingMonth,
          } = options;

          setOccurrenceType(existingType);
          setOccurrenceDay(existingDay);
          setOccurrenceMonth(existingMonth);
        }

        setIsLoaded(true);
      }
    }
  }, [existingSchedule, isLoaded]);

  useEffect(() => {
    onChange({
      occurrenceType,
      occurrenceDay,
      occurrenceMonth,
    });
  }, [onChange, occurrenceType, occurrenceDay, occurrenceMonth]);

  const handleOccurrenceTypeChange = useCallback((e, { value }) => {
    setOccurrenceType(value);
  }, []);

  const handleOccurrenceDayChange = useCallback((e, { value }) => {
    setOccurrenceDay(value);
  }, []);

  const handleOccurrenceMonthChange = useCallback((e, { value }) => {
    setOccurrenceMonth(value);
  }, []);

  return (
    <>
      <StyledLabel>
        On the
      </StyledLabel>
      <StyledRow>
        <Grid.Column computer={16} mobile={16}>
          <DropdownContainer>
            <StyledOccurrenceTypeDropdown
              value={occurrenceType}
              options={OCCURRENCE_TYPE_OPTIONS}
              onChange={handleOccurrenceTypeChange}
              name="occurrence_type_dropdown"
              scrolling
              fluid
              selection
            />
            <StyledOccurrenceDayDropdown
              value={occurrenceDay}
              options={OCCURRENCE_DAY_OPTIONS}
              onChange={handleOccurrenceDayChange}
              name="occurrence_day_dropdown"
              scrolling
              fluid
              selection
            />
            <StyledLabel>
              of
            </StyledLabel>
            <StyledOccurrenceMonthDropdown
              value={occurrenceMonth}
              options={OCCURRENCE_MONTH_OPTIONS}
              onChange={handleOccurrenceMonthChange}
              name="occurrence_month_dropdown"
              scrolling
              fluid
              selection
            />
          </DropdownContainer>
        </Grid.Column>
      </StyledRow>
    </>
  );
}

AdvancedOptions.defaultProps = {
  existingSchedule: null,
};

AdvancedOptions.propTypes = {
  onChange: PropTypes.func.isRequired,
  existingSchedule: PropTypes.object,
};

export default AdvancedOptions;
