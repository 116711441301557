import { connect } from 'react-redux';

import { currentUserIdSelector } from 'helpers/selectors';
import ChangePassword from './ChangePassword';
import { actionCreators } from '../../../../../../utilities/users';

const mapStateToProps = state => ({
  currentUserId: currentUserIdSelector(state),
});

const mapDispatchToProps = {
  onChangePassword: actionCreators.changePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
