import { connect } from 'react-redux';

import { deepEqualMemoizeOne } from 'helpers/sharedMethods';
import { deviceSelector } from 'helpers/selectors';
import SLCClockV1 from './SLCClockV1';

export const makeMapState = () => {
  const memoCurrentTime = deepEqualMemoizeOne();
  const memoOffset = deepEqualMemoizeOne();
  const memoSntpAddress = deepEqualMemoizeOne();
  const memoSntpOn = deepEqualMemoizeOne();

  return function mapStateToProps(state, ownProps) {
    const { resourceId } = ownProps;
    const device = deviceSelector(state)(resourceId);
    const { online, reportedWithMeta } = device;

    const {
      clk: {
        ntp: {
          addr: sntpAddress,
          on: sntpOn,
        } = {},
        now: currentTime,
        tz: offset = {},
        tz: {
          value: offsetValue,
        } = {},
      } = {},
    } = reportedWithMeta || {};

    return {
      loading: !reportedWithMeta,
      online,
      offsetValue,
      currentTime: memoCurrentTime(currentTime),
      offset: memoOffset(offset),
      sntpAddress: memoSntpAddress(sntpAddress),
      sntpOn: memoSntpOn(sntpOn),
    };
  };
};

export default connect(makeMapState())(SLCClockV1);
