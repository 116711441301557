import { connect } from 'react-redux';

import { currentUserSelector } from 'helpers/selectors';
import * as actionCreators from '../../../../utilities/actionCreators';
import RefreshButton from './RefreshButton';

export const mapStateToProps = state => {
  const { id: userId } = currentUserSelector(state);

  return {
    resourceId: userId,
  };
};

const mapDispatchToProps = {
  reloadMapData: userId => actionCreators.fetchProjectsForMap(
    userId,
    {
      fields: {
        projects: [
          'name',
          'longitude',
          'latitude',
          'onlineDevices',
          'offlineDevices',
          'unassociatedDevices',
          'devicesForUpgrade',
        ],
      },
    },
  ),
};

export default connect(mapStateToProps, mapDispatchToProps)(RefreshButton);
