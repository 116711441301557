import styled, { css } from 'styled-components';
import get from 'lodash/get';
import { Label, Button } from 'semantic-ui-react';


export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
`;

export const StyledDeviceName = styled(Label)`
  &&& {
    width: 100%;
    border-radius: 0;
    padding-left: 0;
    border: none;
    border-bottom: 1px solid rgba(34, 36, 38, .15);  
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }  
`;

export const StyledLink = styled.a`
  ${({ theme }) => css`
    color: ${get(theme, 'palette.primaryColor')} !important;
    text-decoration: underline;
    opacity: 1 !important;
    font-weight: 300;

    &:hover {
      text-decoration: underline;
      opacity: 0.8 !important;
    }
  `};
`;

export const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
`;

export const StyledListItem = styled.li`
  margin-right: 8px;
  margin-bottom: 8px;
`;

export const StyledEmptyButton = styled(Button)`
  position: relative;
  flex: 1;
  margin-top: 25px !important;
  width: 90%;
  height: 100%;
  margin-bottom: 36px;

  background-color: transparent !important;
  border: 1px dashed darkgrey !important;
  color: darkgrey !important;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05) !important;
  }

  svg {
    font-size: 2.5em !important;
  }

  ${({ empty }) => empty && css`
    height: 25vh;
  `}

  ${({ invisible }) => invisible && css`
    visibility: hidden !important;
  `}
`;

export default HeaderContainer;
