import { FiSunrise, FiSunset, FiAlertCircle } from 'react-icons/fi';

const reasonNames = {
  SUNRISE: 'sunrise',
  SUNSET: 'sunset',
  OVERRIDDEN_BY_PRIORITY: 'overridden_by_priority',
  INVALID_STATE: 'invalid_state',
  OFFLINE_DEVICE_IN_ACTIONS: 'offline_device_in_actions',
  DISABLED_TASK_SCHEDULER: 'disabled_task_scheduler',
  NOT_FOUND: 'not_found',
  LIMITED_PROJECT_DEVICE_IN_ACTIONS: 'limited_project_device_in_actions',
  UNSUCCESSFUL_SERVICE_RESPONSE: 'unsuccessful_service_response',
  TIMEOUT_SERVICE_RESPONSE: 'timeout_service_response',
  SERVICE_CONNECTION_ERROR: 'service_connection_error',
  IOT_CONNECTION_ERROR: 'iot_connection_error',
  PROJECT_EXECUTION_DISABLED: 'project_execution_disabled',
};

const CONNECTION_ERROR_MESSAGE = 'Internal error occurred. Please contact us for details.';
const PROJECT_EXECUTION_DISABLED_MESSAGE = 'Disabled Multi-site device actions';

const reasons = Object.freeze({
  sunrise: {
    display: 'Sunrise',
    icon: FiSunrise,
  },
  sunset: {
    display: 'Sunset',
    icon: FiSunset,
  },
  overridden_by_priority: {
    display: 'Overriden',
    icon: FiAlertCircle,
  },
  invalid_state: {
    display: 'Invalid state',
    icon: FiAlertCircle,
  },
  disabled_task_scheduler: {
    display: 'Disabled task scheduler',
    icon: FiAlertCircle,
  },
  offline_device_in_actions: {
    display: 'There is an offline device in one of the actions',
    icon: FiAlertCircle,
  },
  not_found: {
    display: 'Task was not found',
    icon: FiAlertCircle,
  },
  limited_project_device_in_actions: {
    display: 'Failed due to the Site being in a limited state',
    icon: FiAlertCircle,
  },
  unsuccessful_service_response: {
    display: CONNECTION_ERROR_MESSAGE,
    icon: FiAlertCircle,
  },
  timeout_service_response: {
    display: CONNECTION_ERROR_MESSAGE,
    icon: FiAlertCircle,
  },
  service_connection_error: {
    display: CONNECTION_ERROR_MESSAGE,
    icon: FiAlertCircle,
  },
  iot_connection_error: {
    display: CONNECTION_ERROR_MESSAGE,
    icon: FiAlertCircle,
  },
  project_execution_disabled: {
    display: PROJECT_EXECUTION_DISABLED_MESSAGE,
    icon: FiAlertCircle,
  },
});

const connectionErrors = [
  reasonNames.UNSUCCESSFUL_SERVICE_RESPONSE,
  reasonNames.TIMEOUT_SERVICE_RESPONSE,
  reasonNames.SERVICE_CONNECTION_ERROR,
  reasonNames.IOT_CONNECTION_ERROR,
];

const occurrenceStatus = Object.freeze({
  CREATED: 'created',
  IN_QUEUE: 'in_queue',
  VALIDATION_ERROR: 'validation_error',
  IN_EXECUTION: 'in_execution',
  FAILURE: 'failure',
  SUCCESS: 'success',
  PARTIAL_SUCCESS: 'partial_success',
});

export {
  reasonNames,
  reasons,
  occurrenceStatus,
  connectionErrors,
  CONNECTION_ERROR_MESSAGE,
  PROJECT_EXECUTION_DISABLED_MESSAGE,
};
