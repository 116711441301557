import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  SNAPSHOTS_FIELD,
  deviceErrorSelector,
  deviceFetchingSelector,
  pollingRefreshingSelector,
  deviceSelector,
} from 'helpers/selectors';
import onMountUnmount from 'helpers/onMountUnmount';
import websocketActionCreators from 'data/websockets/websocketsActionCreators';
import chainedLoader from 'helpers/chainedLoader/chainedLoaderContainerWithPagination';
import { enhanceWithParams } from 'helpers/paginationHelpers';
import endpoints from 'data/api/endpoints';
import SnapshotsActionCreators from './SnapshotsSectionActionCreators';
import SnapshotsSection from './SnapshotsSection';

export const mapStateToProps = (state, { match }) => {
  const { deviceId } = match.params;
  const resource = deviceSelector(state)(deviceId);

  return {
    deviceId,
    chainedLoaderProps: {
      device: {
        fetching: deviceFetchingSelector(state),
        error: deviceErrorSelector(state),
      },
      [SNAPSHOTS_FIELD]: {
        link: endpoints.SNAPSHOTS(deviceId),
      },
    },
    refreshing: pollingRefreshingSelector(state, SNAPSHOTS_FIELD),
    resource,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoader(
    { name: SNAPSHOTS_FIELD, included: true },
    enhanceWithParams(({ deviceId }) => SnapshotsActionCreators
      .fetchSnapshots(deviceId, {
        sort: 'id',
      })),
  ),
  onMountUnmount(
    ({ deviceId }) => websocketActionCreators.subscribeDeviceData(deviceId, 'snapshots'),
    ({ deviceId }) => websocketActionCreators.unsubscribeDeviceData(deviceId, 'snapshots'),
  ),

)(SnapshotsSection);
