import { CONTROL_UI_FIELD } from 'helpers/selectors';
import ApiRequestBuilder from 'helpers/ApiRequestBuilder';
import ControlUIPageModel from 'models/ControlUIPageModel';

class ControlUIModel {

  constructor(controlUI = {}) {
    this.id = controlUI.id;
    this.projectId = controlUI.projectId;
    this.projectSuiteId = controlUI.projectSuiteId;
    this.lockVersion = controlUI.lockVersion;
    this.cpPageableId = controlUI.cpPageableId;
    this.cpPageableType = controlUI.cpPageableType;
    this.pages = (controlUI.pages || []).map(
      page => new ControlUIPageModel(page),
    );
  }

  static requestBody(values) {
    return new ApiRequestBuilder(CONTROL_UI_FIELD)
      .setAttributes(values);
  }

}

export default ControlUIModel;
