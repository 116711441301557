import isNil from 'lodash/isNil';
import range from 'lodash/range';

import { CONTROL_UI_PAGE_TYPES } from 'application/tenant/components/structural/ControlUI/constants';

const getButtonsNumberByPageType = pageType => {
  switch (pageType) {
    case CONTROL_UI_PAGE_TYPES.DEFAULT:
      return 8;
    case CONTROL_UI_PAGE_TYPES.BUTTONS_OVERRIDE_CONTROLS:
      return 4;
    case CONTROL_UI_PAGE_TYPES.OVERRIDE_CONTROLS_BUTTONS:
      return 4;
    case CONTROL_UI_PAGE_TYPES.OVERRIDE_CONTROLS:
      return 0;
    default:
      return 8;
  }
};

const getOverrideControlsNumberByPageType = pageType => {
  switch (pageType) {
    case CONTROL_UI_PAGE_TYPES.DEFAULT:
      return 0;
    case CONTROL_UI_PAGE_TYPES.BUTTONS_OVERRIDE_CONTROLS:
      return 1;
    case CONTROL_UI_PAGE_TYPES.OVERRIDE_CONTROLS_BUTTONS:
      return 1;
    case CONTROL_UI_PAGE_TYPES.OVERRIDE_CONTROLS:
      return 2;
    default:
      return 0;
  }
};

const generateNewPage = (name, pages, paginationIndex, pageType = 'default') => {
  const calculatedIndex = (isNil(paginationIndex) || paginationIndex >= pages.length + 1)
    ? pages.length
    : paginationIndex;

  const buttons = getButtonsNumberByPageType(pageType);
  const overrideControls = getOverrideControlsNumberByPageType(pageType);

  return {
    index: calculatedIndex,
    name,
    pageType,
    buttons: range(buttons).map(index => ({
      index,
      text: null,
      taskId: null,
      taskIds: null,
      enabled: false,
      buttonColor: null,
      textColor: null,
    })),
    overrideControls: range(overrideControls).map(index => ({
      index,
      labelText: null,
      runButtonText: null,
      showIntensitySlider: false,
      showColorTempSlider: false,
      enabled: false,
      runButtonBgColor: null,
      runButtonTextColor: null,
      labelTextColor: null,
      groups: [],
    })),
  };
};

export default generateNewPage;
