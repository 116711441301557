import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { TENANT } from 'storageConst';
import { withProjectPermissions } from 'gateway';
import { PROJECTS_FIELD, LOGICAL_DEVICES_FIELD, projectSelector } from 'helpers/selectors';
import { addPagination } from 'helpers/paginationHelpers';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import paginationActionCreators from 'data/paginationActionCreators';
import permissionsActionCreators from 'application/tenant/console/utilities/permissions/permissionsActionCreators';
import { tasksActionCreators } from 'application/tenant/utilities';
import projectActionCreators from 'pages/Projects/projectActionCreators';

import ProjectControlUITab from './ProjectControlUITab';

const mapStateToProps = (state, ownProps) => {
  const { id: projectId } = ownProps.match.params;
  const { name: projectName } = projectSelector(state)(projectId) || {};

  return {
    projectId,
    projectName,
  };
};

export default compose(
  withRouter,
  withProjectPermissions,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    ({
      currentUserId,
      projectId,
    }) => permissionsActionCreators.fetchUserPermissions(
      currentUserId,
      PROJECTS_FIELD,
      projectId,
      TENANT,
    ),
  ),
  chainedLoaderWithSaga(tasksActionCreators.fetchActionTypes),
  chainedLoaderWithSaga(
    ({ projectId }) => projectActionCreators.fetchProject(projectId, {
      include: [
        LOGICAL_DEVICES_FIELD,
      ],
      fields: {
        [LOGICAL_DEVICES_FIELD]: [
          'name',
        ],
      },
    }),
    addPagination(
      {
        resourceType: LOGICAL_DEVICES_FIELD,
      },
      paginationActionCreators.fetchPageResource({ sort: 'name' }),
    ),
  ),
)(ProjectControlUITab);
