import { call, put } from 'redux-saga/effects';

import { postPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import projectActionCreators from 'pages/Projects/projectActionCreators';

import actionCreators from '../actionCreators';

function* redeemVoucher(action) {
  const {
    endpoint,
    voucher,
    projectId,
    siteName,
    ownerEmail,
    resolveForm,
    rejectForm,
  } = action.payload;

  const data = {
    voucherCode: voucher,
  };

  if (!projectId) {
    data.name = siteName;
    data.ownerEmail = ownerEmail;
  }

  const payload = {
    data,
  };

  const { response, error } = yield call(postPath, endpoint, payload);

  if (!error) {
    yield put(actionCreators.clearSelectedVoucher());

    if (projectId) {
      yield put(projectActionCreators.fetchProject(projectId));
    }
  }

  const params = {
    response: (!error && !response) ? {} : response,
    error,
    successDisp: actionCreators.redeemVoucherSuccess,
    failDisp: actionCreators.redeemVoucherFailure,
    resolveForm,
    rejectForm,
  };

  yield call(processCall, params);
}

export default redeemVoucher;
