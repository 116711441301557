import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';

import { StyledButtonGroup } from './ButtonGroup.style';

const ButtonGroup = ({
  value,
  options,
  onChange,
}) => {
  if (!options || isEmpty(options) || options.length === 1) {
    return null;
  }

  return (
    <StyledButtonGroup size="mini" basic>
      {options.map(option => (
        <Button
          key={option.key}
          active={value === option.value}
          onClick={() => onChange({}, { value: option.value })}
        >
          {option.text}
        </Button>
      ))}
    </StyledButtonGroup>
  );
};

ButtonGroup.defaultProps = {
  value: null,
  options: [],
};

ButtonGroup.propTypes = {
  value: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

export default ButtonGroup;
