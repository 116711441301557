import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import tasksActionCreators from 'application/tenant/utilities/tasks/tasksActionCreators';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import {
  TASKS_FIELD,
  SELECT_TASKS,
} from 'helpers/selectors';
import { addPagination } from 'helpers/paginationHelpers';
import MultipleTasksTableField from './MultipleTasksTableField';

const mapStateToProps = (state, ownProps) => {
  const { id: projectId } = ownProps.match.params;

  return {
    projectId,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    addPagination(
      {
        resourceType: TASKS_FIELD,
        included: true,
        componentName: SELECT_TASKS,
      },
      ({ projectId }) => tasksActionCreators.fetchTasks(projectId, {
        sort: 'name',
      }),
    ),
  ),
)(MultipleTasksTableField);
