import constants from 'dispatcherConst';

export const setSchedulerDescription = schedulerDescription => ({
  type: constants.SET_SCHEDULER_DESCRIPTION,
  payload: {
    schedulerDescription,
  },
});

export default setSchedulerDescription;
