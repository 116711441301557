import { takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import fetchServiceVersions from './fetchServiceVersions';
import handleChangeApiDomain from './handleChangeApiDomain';
import checkApiDomain from './checkApiDomain';
import fetchBackendVersion from './fetchBackendVersion';

function* saga() {
  yield takeLatest(constants.CHANGE_API_DOMAIN_REQUEST, checkApiDomain);
  yield takeLatest(constants.FETCH_SERVICE_VERSIONS, fetchServiceVersions);
  yield takeLatest(constants.CHANGE_API_DOMAIN_SUCCESS, handleChangeApiDomain);
  yield takeLatest(constants.FETCH_BACKEND_VERSION, fetchBackendVersion);
}

export default saga;
