import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

import { paths, pathSets } from 'navigation';
import ContextUserPermissionsLoader from 'components/ContextUserPermissionsLoader/ContextUserPermissionsLoaderContainer';
import ConsoleSwitch from '../console/ConsoleSwitch';
import ControlPanelSwitch from '../controlPanel/ControlPanelSwitch';

const TenantSwitch = ({
  location,
  location: { state: { from } = {} },
  loginChecked,
  currentUserId,
  recentlyLoggedOut,
}) => {
  if (!loginChecked) {
    return null;
  }

  if (!currentUserId) {
    if (recentlyLoggedOut) {
      return <Redirect to={{ pathname: paths.index, state: {} }} />;
    }

    return <Redirect to={{ pathname: paths.index, state: { from: location } }} />;
  }

  if (from) {
    return <Redirect to={from} />;
  }

  return (
    <Fragment>
      <ContextUserPermissionsLoader />
      <Switch>
        <Route
          exact
          path={paths.index}
          render={() => <Redirect to={{ pathname: paths.userProjects }} />}
        />
        <Route
          path={paths.passwordReset}
          render={() => <Redirect to={{ pathname: paths.userProjects }} />}
        />
        {pathSets.controlPanel.map(path => (
          <Route path={path} component={ControlPanelSwitch} key={path} />
        ))}
        <Route component={ConsoleSwitch} />
      </Switch>
    </Fragment>
  );
};

TenantSwitch.defaultProps = {
  loginChecked: false,
  currentUserId: null,
  recentlyLoggedOut: false,
};

TenantSwitch.propTypes = {
  location: PropTypes.object.isRequired,
  loginChecked: PropTypes.bool,
  currentUserId: PropTypes.string,
  recentlyLoggedOut: PropTypes.bool,
};

export default TenantSwitch;
