import constants from 'dispatcherConst';

const paginationActionCreators = {
  fetchPageResource: urlParams => ({
    type: constants.FETCH_RESOURCE_PAGE_REQUEST,
    payload: { urlParams },
  }),

  fetchPageResourceSuccess: (response, resourceType) => ({
    type: constants.ADD_TO_DATA_STORE,
    payload: { response, resourceType },
  }),

  fetchPageResourceFailure: error => ({
    type: constants.ADD_TO_DATA_STORE_FAILED,
    payload: { error },
  }),

  changeRoute: pathname => ({
    type: constants.CHANGE_ROUTE,
    payload: { pathname },
  }),

  refetch: (resourceType, urlParams, componentName = null) => ({
    type: constants.REFETCH,
    payload: { resourceType, urlParams, componentName },
  }),

  clearActiveResource: resourceType => ({
    type: constants.CLEAR_ACTIVE_RESOURCE,
    payload: { resourceType },
  }),
};

export default paginationActionCreators;
