import outputFetchingSelector from './outputFetchingSelector';
import outputsSelector from './outputsSelector';
import patchesFetchingSelector from './patchesFetchingSelector';
import patchesSelector from './patchesSelector';
import protocolIdSelector from './protocolIdSelector';
import universeIdSelector from './universeIdSelector';

export default {
  outputFetchingSelector,
  outputsSelector,
  patchesFetchingSelector,
  patchesSelector,
  protocolIdSelector,
  universeIdSelector,
};
