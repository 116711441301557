import constants from 'dispatcherConst';

const initialState = {
  success: false,
  inviting: false,
};

const inviteUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.INVITE_USER_REQUEST:
      return { ...state, inviting: true, success: false };

    case constants.INVITE_USER_SUCCESS:
      return { ...state, inviting: false, success: true };

    case constants.INVITE_USER_FAILED:
      return { ...state, inviting: false, success: false };

    default:
      return state;
  }
};

export default inviteUserReducer;
