import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchSiteDevices } from 'pages/Projects/components/MapView/components/MapWrapper/components/Markers/components/Marker/components/MarkerDevicesTable/utilities/actionCreators/fetchSiteDevices';
import { selectors as projectSuitesSelectors } from 'pages/ProjectSuites/utilities';
import SelectSiteDropdown from './SelectSiteDropdown';

export const mapStateToProps = (state, { match }) => {
  const { id: projectSuiteId } = match.params;
  const projectsList = projectSuitesSelectors.projectsListSelector(state)(projectSuiteId) || [];
  const options = projectsList.map(({ id, name }) => ({ id, text: name, value: id }));

  return {
    options,
  };
};

export const mapDispatchToProps = dispatch => ({
  getSiteDevices: value => dispatch(fetchSiteDevices(value)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(SelectSiteDropdown);
