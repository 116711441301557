import styled from 'styled-components';

import filterProps from 'helpers/filterProps';
import { mediaOnly } from 'themes/mixins';

const FilteredProps = filterProps([
  'editMode',
])('div');

export const StyledToggleWrapper = styled(FilteredProps)`
  margin-top: ${({ editMode }) => (editMode ? '4px' : '0px')};
`;

export const StyledLabel = styled.label`
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  margin: 0 0 .28571429rem 0;
  font-weight: 700;
  min-width: 175px;
  ${mediaOnly.lg`
    min-width: 187px;
  `};
`;

export default StyledToggleWrapper;
