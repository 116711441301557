import React from 'react';
import PropTypes from 'prop-types';
import ModalForm from 'components/form/ModalForm/ModalForm';

const EnableSiteModalForm = ({ trialPeriodDays, ...rest }) => (
  <ModalForm
    header="Activate Site"
    trigger="Activate Site"
    size="small"
    {...rest}
  >
    Activating the Site is a one time action which cannot be undone.
    Doing so will finish construction mode,
    make this Site active and begin the {trialPeriodDays} day free trial period,
    after which time the annual subscription will be due.
  </ModalForm>
);

EnableSiteModalForm.defaultProps = {
  trialPeriodDays: 30,
};

EnableSiteModalForm.propTypes = {
  trialPeriodDays: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default EnableSiteModalForm;
