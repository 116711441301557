import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';

import ProjectPermissionsTable from 'components/PermissionsTable/PermissionsTableProjectContainer';
import LogicalDevicePermissionsTable from 'components/PermissionsTable/PermissionsTableLogicalDeviceContainer';

function ProjectPermissionsWrapper({
  projectId,
  logicalDeviceIds,
  showHeader,
  isOpen,
}) {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <ProjectPermissionsTable
            projectId={projectId}
            childResourceIds={logicalDeviceIds}
            showHeader={showHeader}
            isOpen={isOpen}
          />
        </Grid.Column>
      </Grid.Row>
      {!isEmpty(logicalDeviceIds) && isOpen && (
        <Grid.Row>
          <Grid.Column>
            <Header as="h2">
              Devices
            </Header>
            <br />
            {logicalDeviceIds.map(logicalDeviceId => (
              <LogicalDevicePermissionsTable
                key={`permissions-logical-device-${logicalDeviceId}`}
                projectId={projectId}
                logicalDeviceId={logicalDeviceId}
                childResourceIds={logicalDeviceIds}
                header="h3"
                showHeader
              />
            ))}
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
}

ProjectPermissionsWrapper.defaultProps = {
  projectId: null,
  logicalDeviceIds: [],
  showHeader: false,
  isOpen: null,
};

ProjectPermissionsWrapper.propTypes = {
  projectId: PropTypes.string,
  logicalDeviceIds: PropTypes.array,
  showHeader: PropTypes.bool,
  isOpen: PropTypes.bool,
};

export default ProjectPermissionsWrapper;
