import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  PROJECTS_FIELD,
  projectSelector,
  projectFetchingSelector,
} from 'helpers/selectors';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import redirectIfNotFound from 'helpers/redirectIfNotFound';
import { withProjectPermissions, permissionsOrNotFound, frontendPermissions } from 'gateway';
import projectActionCreators from 'pages/Projects/projectActionCreators';
import controlPanelActionCreators from './controlPanelActionCreators';
import ControlPanel from './ControlPanel';

export const mapStateToProps = (state, { match }) => {
  const { id: projectId } = match.params;
  const { name: projectName } = projectSelector(state)(projectId) || {};

  return {
    projectId,
    projectName,
    fetching: projectFetchingSelector(state),
  };
};

const mapDispatchToProps = {
  onEnterControlPanelContext: controlPanelActionCreators.enterControlPanelContext,
  onExitControlPanelContext: controlPanelActionCreators.exitControlPanelContext,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  chainedLoaderWithSaga(
    ({ projectId }) => projectActionCreators.fetchProject(projectId, {
      fields: {
        [PROJECTS_FIELD]: ['name'],
      },
    }),
  ),
  withProjectPermissions,
  redirectIfNotFound('projectName'),
  permissionsOrNotFound(frontendPermissions.VIEW_CONTROL_PANEL),
)(ControlPanel);
