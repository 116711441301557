import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { permissionsOrRedirect, frontendPermissions } from 'gateway';
import { singleProjectSelector } from 'helpers/selectors';
import siteActionCreators from 'application/tenant/console/site/utilities/actionCreators';
import AutoRenewYearsForm from './AutoRenewYearsForm';
import computeToggle from './utilities/computeToggle';

export const mapStateToProps = (state, { match }) => {
  const { id: siteId } = match.params;

  const { autoRenewYears = 0, showAutoRenewal = false } = singleProjectSelector(state, siteId);
  const toggleEnabled = computeToggle(autoRenewYears);

  return {
    siteId,
    toggleEnabled,
    autoRenewYears,
    showAutoRenewal,
  };
};


export const mapDispatchToProps = dispatch => ({
  onUpdateSiteAutoRenewYears: args => dispatch(siteActionCreators.updateSiteAutoRenewYears(args)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  permissionsOrRedirect(frontendPermissions.AUTO_RENEW),
)(AutoRenewYearsForm);
