import {
  Dimmer as SemanticUIDimmer,
  Table as SemanticUITable,
  Header,
} from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import colors from 'themes/colors';
import { media, mediaMax } from 'themes/mixins';

const radioTableCellWidth = '45px';
const Table = styled(SemanticUITable).attrs({
  selectable: true,
  unstackable: true,
})`
  &.ui.column.sortable.table {
    border-radius: 0;
    border: 0;
    
    ${mediaMax.md`display: block;`};
    
    tbody, td, tr {
      ${mediaMax.md`display: block;`};
    }

    ${({ fixed }) => fixed && css`
      tbody {
        display: block;
        max-height: 500px;
        overflow: auto;
      }
    `}
    
    thead th {
      background: 0;
      color: ${colors.darkGrey};
      border-bottom: 2px solid ${colors.mercury};
      border-left: 0;
      padding-top: 0.357142857em;
      padding-bottom: 0.357142857em;
    }
    
    tr {
      ${mediaMax.md`
        position: relative;
        padding-left: ${radioTableCellWidth};
        border-top: 2px solid ${colors.mercury};
      `};
      
      &.active {
        background: ${rgba(colors.black, 0.05)}!important;
      }
      
      &.interactive:hover {
        cursor: pointer;
      }
    }
    
    thead tr {
      ${mediaMax.md`
        position: absolute;
        top: -9999px;
        left: -9999px;
      `};
    }

    ${({ fixed }) => fixed && css`
      thead, tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }
    `}
    
    tr:last-of-type {
      ${mediaMax.md`
        border-bottom: 2px solid ${colors.mercury};
      `};
    }

    td {
      border-top: 2px solid ${colors.mercury};
      word-wrap: break-word;
      
      ${mediaMax.md`
        position: relative;
        width: 100%;
        min-height: 42px;
        border: 0;
        border-bottom: 1px solid ${colors.mercury};
      `};
      
      ${media.md`
        max-width: 0;
      `};
      
      &:nth-of-type(1) {
        ${mediaMax.md`
          position: absolute;
          top: 50%;
          left: 0;
          border-bottom: 0;
          transform: translateY(-50%);
        `};
      }
      
      &:last-of-type {
        ${mediaMax.md`
          border-bottom: 0;
        `};
      }
      
      &:before {
        ${mediaMax.md`
          position: absolute;
          top: 0;
          left: 0;
          width: 40%;
          padding: .78571429em .78571429em;
          color: ${colors.darkGrey};
          font-weight: 700;
          word-wrap: break-word;
        `};
      }
      
      .ui.selection.dropdown {
        ${mediaMax.md`min-width: 100%;`};
      }
      
      .ui.input {
        ${mediaMax.md`max-width: 100%;`};
      }
      
      .ui.input input {
        ${mediaMax.md`max-width: calc(100% - 38px);`};
      }
    }
    
    tr th:first-of-type,
    tr td:first-of-type {
      width: ${radioTableCellWidth};
      min-width: ${radioTableCellWidth}; 
    }
    
    tr:first-of-type td {
      border-top: 0;
    }
    
    tr:last-of-type td {
      ${media.md`
        border-bottom: 2px solid ${colors.mercury};
      `};
    }
    
    td:not(:nth-of-type(1)):not([data-content=""]) {
      ${mediaMax.md`
        padding-left: 40%;
      `};
    }
    
    td:not(:nth-of-type(1)):not([data-content=""]):before {
      ${mediaMax.md`content: attr(data-content);`};
    }
    
    .ui.radio.checkbox .box:after, 
    .ui.radio.checkbox label:after {
      background-color: ${colors.silver};
      transform: scale(1);
    }
    
    .ui.radio.checkbox input:checked~.box:after, 
    .ui.radio.checkbox input:checked~label:after {
      background-color: ${colors.silver};
    }
  }
`;

Table.Header = SemanticUITable.Header;
Table.Row = SemanticUITable.Row;
Table.HeaderCell = SemanticUITable.HeaderCell;
Table.Body = SemanticUITable.Body;
Table.Cell = SemanticUITable.Cell;

export const Dimmer = styled(SemanticUIDimmer)`
  &.ui.inverted.dimmer {
    background-color: ${rgba(colors.white, 0.6)};
    z-index: auto;
  }
`;
Dimmer.Dimmable = styled(SemanticUIDimmer.Dimmable)`
  &.dimmable {
    padding: 1em 0;
  }
`;

export const TableDescription = styled(Header.Subheader)`
  font-size: 1.05rem !important;
  margin-top: 8px !important;
`;

export default Table;
