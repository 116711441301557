import isEmail from 'validator/lib/isEmail';
import isLength from 'validator/lib/isLength';
import isFQDN from 'validator/lib/isFQDN';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import XRegExp from 'xregexp';
import FileComparator from 'helpers/FileComparator';
import { isValidNumber } from 'libphonenumber-js';
import isDate from 'lodash/isDate';

export const messages = {
  REQUIRED: 'Required',
  INVALID_EMAIL_ADDRESS: 'Invalid email address',
  MIN_CHARACTERS: min => `Must be ${min} characters or more`,
  MAX_CHARACTERS: max => `Must have fewer than ${max} characters`,
  ONLY_ALPHANUMERIC: 'Only alphanumeric characters',
  INVALID_DOMAIN: 'Invalid domain',
  INVALID_HOSTNAME: 'Invalid hostname',
  MISSING_PROTOCOL: 'Missing protocol',
  MISSING_FILES: 'You must specify at least one file',
  MUST_CONTAIN_ONE_OF: message => `Must contain at least one ${message}`,
  ONLY_ALPHANUMERIC_AND_DASHES: 'Only alphanumeric characters and dashes',
  ONLY_ALPHANUMERIC_AND_UNDERSCORES: 'Only alphanumeric characters and underscores',
  FILE_NOT_MATCH: 'Specified file doesn\'t match',
  ALPHANUMERIC_EXTENDED: 'Alphanumeric characters, including extended unicode and certain special characters (&, -, _, :, \', ", !)',
  ONLY_ALPHA_AND_SPACES: 'Must only contain alphabetic characters and spaces, but cannot start with a space.',
  ONLY_ALPHA_NUMERIC_AND_SPACES: 'Must only contain alpha numeric characters and spaces, but cannot start with a space.',
  NUMERIC_EXTENDED: 'Numeric characters and certain special characters (, -). No spaces.',
  PHONE_NUMBER: 'Must be a valid phone number.',
  TIME_INTEGERS: type => `Your ${type} list need to contain only positive integers comma separated`,
  TIME_DISTINCT: type => `Your ${type} list needs to contain distinct values comma separated`,
  TIME_RANGE: (type, range) => `The ${type} values should be between ${range} separated by a comma`,
  TIME_LIMIT: (type, limit) => `For the ${type} list you can select maximum ${limit} values`,
  TIME_SPACES: 'You are not allowed to use more than one space',
  TIME_COMMA: 'Please use space only after a comma',
  INVALID_PERIOD: 'Invalid period (0-180)',
  INVALID_SEC_RELEASE: 'Invalid release time value (e.g. 2.00)',
  INVALID_PERCENTAGE: 'Invalid percentage value (e.g. 50 or 50.25)',
  INVALID_TIME: 'Invalid time value (e.g. 2 or 2.00)',
  INVALID_VALUE: 'Invalid value (e.g. 50 or 50.25)',
  INVALID_RANGE: 'The number is greater than the accepted value',
  INVALID_RANGE_WITH_VALUES: range => `Value must be between ${range}.`,
  INVALID_INTEGER: 'Must be a valid integer',
  PAGINATION_LIMIT_REACHED: 'Pagination limit exceeded',
  PAGINATION_POSITIVE: 'Pagination value should be positive',
  FIELD_NOT_VALID: 'Field is not valid',
  INVALID_COMMENT: 'Should be less than 25 characters',
  INVALID_FILE_SIZE: 'File should not be empty',
};

export const helpers = {
  ALPHANUMERIC: 'alphanumeric character',
  LOWER_CASE_LETTER: 'lower case letter',
  UPPER_CASE_LETTER: 'upper case letter',
  NUMBER: 'number',
  SYMBOL: 'symbol: = ~ ! @ # $ % ^ & * ( ) £',
  HOURS: 'hours',
  MINUTES: 'minutes',
  SECONDS: 'seconds',
};

const combinedFormFieldValidator = validators => value => {
  const errorsArray = validators.reduce((err, validator) => err.concat(validator(value)), []);
  const filteredErrors = errorsArray.filter(el => el !== undefined);

  return (filteredErrors.length > 0 ? filteredErrors : undefined);
};

const isValidString = value => !!value && isString(value);

const isInteger = value => Number.isInteger(value);

const isBoolean = value => typeof value === 'boolean';

export const integerRequired = value => (isInteger(value) ? undefined : messages.REQUIRED);

export const booleanRequired = value => (isBoolean(value) ? undefined : messages.REQUIRED);

export const required = value => (isValidString(value) ? undefined : messages.REQUIRED);

export const requiredArray = value => (Array.isArray(value) && value.length > 0
  ? undefined
  : messages.REQUIRED
);

export const isRequired = (...validators) => value => {
  const combinedValidator = combinedFormFieldValidator(validators);
  const errorsArray = combinedValidator(value);

  const requiredMessage = required(value);
  if (requiredMessage) {
    return [requiredMessage, ...errorsArray];
  }

  return errorsArray;
};

export const isNotRequired = (...validators) => value => {
  if (value === '' || isUndefined(value)) {
    return undefined;
  }

  const combinedValidator = combinedFormFieldValidator(validators);
  const errorsArray = combinedValidator(value);

  return errorsArray;
};

const email = value => (
  isValidString(value) && isEmail(value) ? undefined : messages.INVALID_EMAIL_ADDRESS
);

const minLength = min => value => (
  isValidString(value) && isLength(value.trim(), { min })
    ? undefined
    : messages.MIN_CHARACTERS(min)
);

const maxLength = max => value => (
  isValidString(value) && isLength(value.trim(), { max })
    ? undefined
    : messages.MAX_CHARACTERS(max)
);

const matches = (pattern, message) => value => (
  (isValidString(value) && pattern.test(value)) ? undefined : message
);

const atLeastOneAlphanumeric = matches(/[a-zA-Z0-9]/, messages.MUST_CONTAIN_ONE_OF(helpers.ALPHANUMERIC));

const atLeastOneLowerCase = matches(/[a-z]/, messages.MUST_CONTAIN_ONE_OF(helpers.LOWER_CASE_LETTER));

const atLeastOneUpperCase = matches(/[A-Z]/, messages.MUST_CONTAIN_ONE_OF(helpers.UPPER_CASE_LETTER));

const atLeastOneNumber = matches(/[0-9]/, messages.MUST_CONTAIN_ONE_OF(helpers.NUMBER));

const atLeastOneSymbol = matches(/[=~!@#$%^&*()£]/, messages.MUST_CONTAIN_ONE_OF(helpers.SYMBOL));

const alphanumericOrDashes = matches(/^[-0-9a-zA-Z]+$/, messages.ONLY_ALPHANUMERIC_AND_DASHES);

const alphanumericOrUnderscore = matches(/^[_0-9a-zA-Z]+$/, messages.ONLY_ALPHANUMERIC_AND_UNDERSCORES);

const alphanumeric = matches(/^[a-zA-Z0-9]+$/, messages.ONLY_ALPHANUMERIC);

const alphanumericExtended = matches(XRegExp('^[\\pL\\s\\-_&:\'".,!0-9]+$'), messages.ALPHANUMERIC_EXTENDED);

const alphaAndSpace = matches(/^[^-\s][a-zA-Z_\s-]+$/, messages.ONLY_ALPHA_AND_SPACES);

const numericExtended = matches(/^[0-9,-]+$/, messages.NUMERIC_EXTENDED);

const alphaAndSpaceExtended = matches(/^[^-\s][a-zA-Z0-9_\s-]+$/, messages.ONLY_ALPHA_NUMERIC_AND_SPACES);

const phoneNumber = value => (
  isValidString(value) && isValidNumber(value) ? undefined : messages.PHONE_NUMBER
);

const domain = value => {
  const protocolRegex = /^http(s)?:\/\//gi;

  if (!isString(value)) {
    return messages.INVALID_DOMAIN;
  }

  if (!value.match(protocolRegex)) {
    return messages.MISSING_PROTOCOL;
  }

  const valueWithoutProtocol = value.replace(protocolRegex, '');

  return !isFQDN(valueWithoutProtocol) ? messages.INVALID_DOMAIN : undefined;
};

const hostname = value => {
  if (!isString(value)) {
    return messages.INVALID_HOSTNAME;
  }

  return !isFQDN(value) ? messages.INVALID_HOSTNAME : undefined;
};

const validName = min => isRequired(minLength(min), alphanumeric);
const validNameAlhaNumericSpace = min => isRequired(minLength(min), alphaAndSpaceExtended);
const validNameWithSpace = min => isRequired(minLength(min), alphaAndSpace);

const nonRequiredName = min => isNotRequired(minLength(min), alphanumeric);

export const validName2 = validName(2);
export const validName3 = validName(3);
export const validName2AndSpaces = validNameWithSpace(2);
export const validAlphaNumericAndSpace = validNameAlhaNumericSpace(2);

export const validCompanyName = isRequired(
  minLength(3),
  alphanumericExtended,
);

export const validSubdomain = isRequired(
  minLength(3),
  alphanumericOrDashes,
  atLeastOneAlphanumeric,
);

export const validPassword = isRequired(
  minLength(8),
  maxLength(40),
  atLeastOneLowerCase,
  atLeastOneUpperCase,
  atLeastOneNumber,
  atLeastOneSymbol,
);

export const validDisplayName = isRequired(
  minLength(3),
  alphanumericExtended,
  atLeastOneAlphanumeric,
);

export const validActionName = isRequired(
  minLength(1),
  atLeastOneAlphanumeric,
);

export const validDeviceMapping = isRequired(
  minLength(3),
  alphanumericOrUnderscore,
);

export const validEmail = isRequired(email);

export const validHostname = isRequired(hostname);

export const nonRequiredEmail = isNotRequired(email);

export const nonRequiredName3 = nonRequiredName(3);

export const nonRequiredDomain = isNotRequired(domain);

export const nonEmptyFiles = values => (
  (!values || !Array.isArray(values) || !values.length) ? messages.MISSING_FILES : undefined
);

export const sameFile = (values, allValues, { resume }) => {
  if (!values || !resume) return undefined;

  const { systemFile } = resume;

  const fileComparator = new FileComparator(systemFile, values[0]);
  const isEqual = fileComparator.compareName()
    .compareSize()
    .finalize();

  return isEqual ? undefined : messages.FILE_NOT_MATCH;
};

export const validTrigger = isRequired(
  minLength(1),
  maxLength(50),
  numericExtended,
);

export const serverSideRegex = pattern => value => {
  const re = new RegExp(pattern);

  if (isUndefined(value)) {
    return ['Field is required'];
  }

  if (re.test(value)) {
    return undefined;
  }

  return ['Field is not valid'];
};

export const validPhoneNumber = isRequired(phoneNumber);

export const validSelectedDates = ({ submitFailed, invalid, value }) => {
  if (!!submitFailed && !!invalid && isEmpty(value)) {
    return ['You need to select at least one date'];
  }

  return undefined;
};

export const validSelectedTimes = (
  {
    submitFailed,
    invalid,
    selectedTimes,
    hours,
    minutes,
    seconds,
    advancedMode,
    sunrise,
    sunset,
  }) => {
  const astroFieldsEmpty = isEmpty(sunrise) && isEmpty(sunset);
  const allTimesEmpty = isEmpty(selectedTimes) && isEmpty(hours)
    && isEmpty(minutes) && isEmpty(seconds) && astroFieldsEmpty;
  const timeMaskOff = !advancedMode && isEmpty(selectedTimes);

  if (!!submitFailed && !!invalid && allTimesEmpty) {
    return ['You need to select at least one time or use Time Mask'];
  }

  if (!!submitFailed && !!invalid && timeMaskOff && astroFieldsEmpty) {
    return ['You need to select at least one time or enable and use Time Mask'];
  }

  return undefined;
};

export const validateTimeList = (value, type) => {
  const errors = [];
  if (isString(value) && value.indexOf('  ') !== -1) {
    errors.push(messages.TIME_SPACES);
    return errors;
  }

  if (isString(value)) {
    const commas = value.replace(/[^,]/g, '').length;
    const spaces = value.replace(/[^ ]/g, '').length;
    if (!(commas >= spaces)) {
      errors.push(messages.TIME_COMMA);
      return errors;
    }
  }

  if (isEmpty(value)) {
    return undefined;
  }

  if (type === helpers.HOURS && value === '*') {
    return undefined;
  }

  if (type === helpers.HOURS && Array.isArray(value)
    && value.length === 1 && value[0] === '*') {
    return undefined;
  }

  const initialArr = Array.isArray(value) ? value : value.replace(/\s/g, '').split(',');
  const removeEmptyStr = initialArr.filter(item => !isEmpty(item));
  const valuesToNumbers = removeEmptyStr.map(item => /^\d+$/.test(item)).filter(item => item === true);
  if (removeEmptyStr.length !== valuesToNumbers.length) {
    errors.push(messages.TIME_INTEGERS(type));
    return errors;
  }

  if (!(uniq(initialArr).length === initialArr.length)) {
    errors.push(messages.TIME_DISTINCT(type));
    return errors;
  }

  const min = 0;
  const max = type === 'hours' ? 23 : 59;
  const allHours = removeEmptyStr.map(item => parseInt(item, 10));
  const checkHourRange = allHours.filter(item => item >= min && item <= max);
  if (!(checkHourRange.length === allHours.length)) {
    const range = `${min} and ${max}`;
    errors.push(messages.TIME_RANGE(type, range));
    return errors;
  }

  const limit = {
    hours: 24,
    minutes: 12,
    seconds: 2,
  };
  const maxLimit = limit[type];
  if (removeEmptyStr.length > maxLimit) {
    errors.push(messages.TIME_LIMIT(type, maxLimit));
    return errors;
  }

  return undefined;
};

export const validateHours = value => validateTimeList(value, helpers.HOURS);
export const validateMinutes = value => validateTimeList(value, helpers.MINUTES);
export const validateSeconds = value => validateTimeList(value, helpers.SECONDS);

export const syncValidation = values => {
  const errors = {};
  const {
    hours,
    minutes,
    seconds,
    selectedTimes,
    advancedMode,
    sunrise,
    sunset,
  } = values;
  const checkHours = hours && hours.length > 0;
  const checkMinutes = minutes && minutes.length > 0;
  const checkSeconds = seconds && seconds.length > 0;
  const astroFieldsEmpty = isEmpty(sunrise) && isEmpty(sunset);
  const touched = checkHours || checkMinutes || checkSeconds;
  const allFilled = checkHours && checkMinutes && checkSeconds;

  if (allFilled && advancedMode) {
    return errors;
  }

  if (touched && advancedMode) {
    errors.advancedMode = 'With Time Mask you need to fill all three fields';
  }

  if (isEmpty(selectedTimes) && !touched && astroFieldsEmpty) {
    errors.selectedTimes = 'Error fill time or Time Mask section';
  }

  if (isEmpty(selectedTimes) && !advancedMode && astroFieldsEmpty) {
    errors.selectedTimes = 'You need to select at least one time or enable and use Time Mask';
  }

  return errors;
};

export const isValidPeriodDaysNumber = value => {
  if (!Number.isNaN(value) && value >= 0 && value < 181 && !isBoolean(value)) {
    return undefined;
  }

  return messages.INVALID_PERIOD;
};

export const isValidReleaseTime = value => {
  const regExp = /^\d+\.\d{0,2}$/;
  if (regExp.test(value)) {
    return undefined;
  }

  return messages.INVALID_SEC_RELEASE;
};

export const isValidPercentage = value => {
  const regExp = /^(?:\d*\.\d{1,2}|\d+)$/;
  if (!regExp.test(value)) {
    return messages.INVALID_PERCENTAGE;
  }

  if (!(parseFloat(value) <= 100 && parseFloat(value) >= 0)) {
    return messages.INVALID_RANGE;
  }

  return undefined;
};

export const isValidTimeText = (gteq, lteq) => value => {
  const regExp = /^(?:\d*\.\d{1,2}|\d+)$/;
  if (!regExp.test(value)) {
    return messages.INVALID_TIME;
  }

  if (!(parseFloat(value) <= parseFloat(lteq) && parseFloat(value) >= parseFloat(gteq))) {
    return messages.INVALID_RANGE;
  }

  return undefined;
};

export const isValidRange = (gteq, lteq) => value => {
  const regExp = /^(?:\d*\.\d{1,2}|\d+)$/;
  if (!regExp.test(value)) {
    return messages.INVALID_VALUE;
  }

  if (!(parseFloat(value) <= parseFloat(lteq) && parseFloat(value) >= parseFloat(gteq))) {
    return messages.INVALID_RANGE;
  }

  return undefined;
};

export const isValidInteger = value => {
  if (isInteger(Number(value))) {
    return undefined;
  }

  return messages.INVALID_INTEGER;
};

export const isValidSitePoints = value => {
  const integerError = isValidInteger(value);

  if (integerError) {
    return integerError;
  }

  const rangeError = isValidRange(0, 300)(Number(value));

  if (rangeError) {
    return messages.INVALID_RANGE_WITH_VALUES('0 and 300');
  }

  return undefined;
};

export const validatePaginationIndex = totalPages => value => {
  if (value > totalPages + 1) {
    return [messages.PAGINATION_LIMIT_REACHED];
  }

  if (value <= 0) {
    return [messages.PAGINATION_POSITIVE];
  }

  if (value > 0 && value <= totalPages + 1) {
    return undefined;
  }

  return [messages.FIELD_NOT_VALID];
};

export const isValidAutoRenewYears = value => {
  const integerError = isValidInteger(value);

  if (integerError) {
    return integerError;
  }

  const rangeError = isValidRange(1, 5)(Number(value));

  if (rangeError) {
    return messages.INVALID_RANGE_WITH_VALUES('1 and 5');
  }

  return undefined;
};

export const isValidAutoRenewComment = value => {
  if (!isEmpty(value) && String(value).length > 25) {
    return messages.INVALID_COMMENT;
  }

  return undefined;
};

export const fileSelectedAndNotEmpty = filesArray => {
  if (!(Array.isArray(filesArray) && filesArray.length > 0)) {
    return messages.MISSING_FILES;
  }

  const { size } = filesArray[0];

  if (size === 0) {
    return messages.INVALID_FILE_SIZE;
  }

  return undefined;
};

export const requiredDate = value => (isDate(value) ? undefined : messages.REQUIRED);
