import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import { TwoFactorQRViewer } from '../../../../../../../../../../../../components/structural';
import { SetSecretKey, RefreshSecretKey } from './components';

const SecretKey = ({ twoFactorEnabled }) => (
  <Grid>
    <Grid.Row>
      <Grid.Column computer={8} mobile={16}>
        <TwoFactorQRViewer />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column computer={8} mobile={16}>
        Scan the QR code using your chosen application to begin generating
        6-digit one-time passwords to complete Two Factor Authentication.
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column computer={8} mobile={16}>
        {(
          twoFactorEnabled
            ? <RefreshSecretKey />
            : <SetSecretKey />
        )}
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

SecretKey.defaultProps = {
  twoFactorEnabled: false,
};

SecretKey.propTypes = {
  twoFactorEnabled: PropTypes.bool,
};

export default SecretKey;
