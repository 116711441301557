import React from 'react';
import PropTypes from 'prop-types';

import { pageSizeOptions } from './constants';
import { StyledDropdown, StyledLabel } from './PageSizeDropdown.style';

const PageSizeDropdown = ({ setPageSize }) => {
  const handlePageSize = (e, { value }) => setPageSize(value);

  return (
    <div>
      <StyledLabel>Page size</StyledLabel>
      <StyledDropdown
        options={pageSizeOptions}
        selection
        selectOnBlur={false}
        onChange={handlePageSize}
        search
        defaultValue={100}
      />
    </div>
  );
};

PageSizeDropdown.propTypes = {
  setPageSize: PropTypes.func.isRequired,
};

export default PageSizeDropdown;
