import styled, { css } from 'styled-components';
import { Message } from 'semantic-ui-react';

import filterProps from 'helpers/filterProps';

const FilteredProps = filterProps([
  'hideErrorMessage',
])(Message);

const FeedbackMessage = styled(FilteredProps)`
  &.ui.message {
    width: 100%;
    margin: 2px 0 0;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    font-size: 1rem;
    min-height: 0;

    .content {
      display:block;
    }
    
    &:first-child {
      margin: 2px 0 0;
    }
    
    p {
      margin: 0;
      line-height: 1em;
    }

    ${({ hideErrorMessage }) => hideErrorMessage && css`display: none;`}
  }
`;

export default FeedbackMessage;
