import React from 'react';
import { TextArea } from 'semantic-ui-react';

import FormField from 'components/form/FormField/FormField';
import TextEditField from './TextEditField/TextEditField';

const TextEditFormField = props => (
  <FormField {...props}>
    <TextEditField {...props} />
  </FormField>
);

TextEditFormField.propTypes = {
  ...TextArea.propTypes,
};

export default TextEditFormField;
