import constants from 'dispatcherConst';

export const syncAutomatedFunction = params => ({
  type: constants.SYNC_AUTOMATED_FUNCTION_REQUEST,
  payload: {
    params,
  },
});

export const syncAutomatedFunctionSuccess = response => ({
  type: constants.SYNC_AUTOMATED_FUNCTION_SUCCESS,
  payload: {
    response,
    message: response.message,
  },
});

export const syncAutomatedFunctionFailure = error => ({
  type: constants.SYNC_AUTOMATED_FUNCTION_FAILED,
  payload: { error },
});
