import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';

const extractScheduleIds = str => {
  if (!isString(str) || isEmpty(str)) {
    return [];
  }

  return str.split(' O ').map(item => item.substr(2, 36));
};

export default extractScheduleIds;
