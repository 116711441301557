import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

import { StyledButton } from './NoLocation.style';

const NoLocation = ({ projectId }) => (
  <Grid>
    <Grid.Row>
      <Grid.Column>
        <StyledButton disabled>Add Task Scheduler</StyledButton>
        <p>You must set the site
          location in <Link to={`/my-sites/${projectId}/settings`}>Settings</Link> before
          you can schedule tasks.
        </p>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

NoLocation.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default NoLocation;
