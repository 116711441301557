import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';

import formNames from 'components/form/formNames';
import { formFields } from './PathwayDisableClockEventsV1Form/PathwayDisableClockEventsV1Form';
import PathwayDisableClockEventsV1Form from './PathwayDisableClockEventsV1Form/PathwayDisableClockEventsV1FormContainer';

export const DEVICE_DISABLE_REFRESH = uuidv4();

class PathwayDisableClockEventsV1 extends Component {

  constructor() {
    super();

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { pollingRefreshing, onReset } = this.props;
    const { pollingRefreshing: prevPollingRefreshing } = prevProps;

    if (prevPollingRefreshing && !pollingRefreshing) {
      onReset(formNames.disableV1);
    }
  }

  handleSubmit(values) {
    const { resourceId, onToggleDisable } = this.props;

    return new Promise((resolveForm, rejectForm) => {
      onToggleDisable(resourceId, { values, resolveForm, rejectForm });
    });
  }

  render() {
    const { online, loading, timedEventsEnabled, title, pollingRefreshing } = this.props;

    return !loading && (
      <PathwayDisableClockEventsV1Form
        onSubmit={this.handleSubmit}
        initialValues={{
          [formFields.disable]: timedEventsEnabled,
        }}
        title={title}
        online={online}
        pollingRefreshing={pollingRefreshing}
      />
    );
  }

}

PathwayDisableClockEventsV1.defaultProps = {
  timedEventsEnabled: null,
};

PathwayDisableClockEventsV1.propTypes = {
  loading: PropTypes.bool.isRequired,
  resourceId: PropTypes.string.isRequired,
  online: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  timedEventsEnabled: PropTypes.bool,
  pollingRefreshing: PropTypes.bool.isRequired,
  onToggleDisable: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

export default PathwayDisableClockEventsV1;
