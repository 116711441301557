import styled from 'styled-components';
import { Popup } from 'semantic-ui-react';

const StyledDivGracePeriod = styled('div')`
  margin-bottom: 10px;
`;

const StyledPopupGrace = styled(Popup)`
  left: 5px !important;
`;

export {
  StyledDivGracePeriod,
  StyledPopupGrace,
};
