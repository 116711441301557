import { ACTIVITY_LOGS_FIELD } from 'helpers/selectors';

const logsDataExistsSelector = (state, projectId) => {
  if (state.data[ACTIVITY_LOGS_FIELD]
      && state.data[ACTIVITY_LOGS_FIELD][projectId]
      && state.data[ACTIVITY_LOGS_FIELD][projectId].logs) {

    return true;
  }

  return false;
};

export default logsDataExistsSelector;
