import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

const resourceUpdateActionCreators = {
  resourceUpdateRequest: (loader, chainedLoaderId, updateLoader, {
    timeout,
    parameters,
    resourceId,
    onSuccess,
    onFailure,
  }) => ({
    type: constants.RESOURCE_UPDATE_REQUEST_V2,
    payload: {
      endpoint: endpoints.RESOURCE_UPDATE(resourceId),
      loader,
      chainedLoaderId,
      updateLoader,
      timeout,
      parameters,
      onSuccess,
      onFailure,
      resourceId,
    },
  }),
  resourceUpdateRequestSuccess: (response, chainedLoaderId, updateLoaderId) => ({
    type: constants.RESOURCE_UPDATE_REQUEST_SUCCESS_V2,
    payload: { response, chainedLoaderId, updateLoaderId },
  }),
  resourceUpdateRequestFailure: (error, chainedLoaderId, updateLoaderId) => ({
    type: constants.RESOURCE_UPDATE_REQUEST_FAILURE_V2,
    payload: { error, chainedLoaderId, updateLoaderId },
  }),
  resourceUpdateStart: chainedLoaderId => ({
    type: constants.RESOURCE_UPDATE_START_V2,
    payload: { chainedLoaderId },
  }),
  resourceUpdateStop: chainedLoaderId => ({
    type: constants.RESOURCE_UPDATE_STOP_V2,
    payload: { chainedLoaderId },
  }),
  resourceUpdateSuccess: payload => ({
    type: constants.RESOURCE_UPDATE_SUCCESS_V2,
    payload,
  }),
  resourceUpdateFailed: payload => ({
    type: constants.RESOURCE_UPDATE_FAILED_V2,
    payload,
  }),
  fetchDone: (updateLoaderId, resourceId, updated) => ({
    type: constants.RESOURCE_UPDATE_LOADER_FETCH_DONE_V2,
    payload: { updateLoaderId, resourceId, updated },
  }),
};

export default resourceUpdateActionCreators;
