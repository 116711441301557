import moment from 'moment';

import { ENDING_CRITERIA } from '../components/ScheduleStep/components/RecurrenceViews/EndingCriteria/constants';

const validateEndingCriteria = ({
  errors,
  startDate,
  forNTimes,
  endTime,
  endingCriteria,
  forNTimesMaxValue,
}) => {
  const newErrors = {
    ...errors,
  };

  if (endingCriteria !== ENDING_CRITERIA.NEVER_ENDS) {
    if (endingCriteria === ENDING_CRITERIA.ON_DATE_TIME) {
      if (!endTime) {
        newErrors.endTime = 'Required fields';
      }

      if (endTime && (moment(endTime).isBefore(startDate))) {
        newErrors.endTime = 'End Date must be greater than Start Date';
      }
    }

    if (endingCriteria === ENDING_CRITERIA.AFTER_N_OCCURRENCES) {
      if (!forNTimes) {
        newErrors.forNTimes = 'Required field';
      }

      if (forNTimes < 0 || forNTimes > forNTimesMaxValue) {
        newErrors.forNTimes = `Value must be between 1 and ${forNTimesMaxValue}`;
      }
    }
  }

  return newErrors;
};

export default validateEndingCriteria;
