import styled from 'styled-components';
import { Form } from 'semantic-ui-react';

import filterProps from 'helpers/filterProps';
import { injectStyles } from 'themes/mixins';

const ThemedForm = styled(Form)`
  ${({ theme: { forms } }) => injectStyles(forms)};

  &.ui.form .ui.grid .row .column {
    .fields {
      margin-left: 0;
      margin-right: 0;
    }

    .field {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.ui.form .fields {
    margin-bottom: 1em;

    &.omit-error {
      margin-bottom: calc(2em + 4px);
    }
  }

  &.ui.form > .fields {
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &.ui.form .sixteen.wide.field {
    padding-right: 0;
  }

  &.ui.form .field label {
    margin-bottom: 5px;
  }

  &.ui.form .field.error .ui.dropdown .text {
    background: transparent;
  }
`;

ThemedForm.Group = styled(filterProps('omitError')(Form.Group)).attrs({
  className: ({ omitError }) => (omitError ? 'omit-error' : ''),
})``;
ThemedForm.Field = Form.Field;

export const SimpleForm = styled(Form)`
  ${({ theme: { forms } }) => injectStyles(forms)};

  &.ui.form .inline.fields {
    align-items: center !important;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &.ui.form .inline.fields > label {
    width: 50px;
    font-size: 1em;
    align-self: center;
  }
`;

export default ThemedForm;
