import { call, put, takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import { getPath } from 'data/api/requests';
import { FIXTURE_GROUPS_FIELD } from 'helpers/selectors';
import { processCall } from 'helpers/sagaHelper';
import { prepareResponseData, websocketUrlParams } from 'helpers/paginationHelpersClient';
import fixtureGroupsActionCreators from './fixtureGroupsActionCreators';

export function* fetchFixtureGroups({ payload }) {
  const { deviceId, endpoint, urlParams, ...rest } = payload;
  const { response, error } = yield call(getPath, endpoint, { urlParams });

  const params = {
    response,
    error,
    successDisp: fixtureGroupsActionCreators.fetchGroupsSuccess,
    ...rest,
  };
  yield call(processCall, params);
}

export function* paginateFixtureGroups({ payload: { response } }) {
  const urlParams = websocketUrlParams({
    type: FIXTURE_GROUPS_FIELD,
    included: true,
  });

  const enhancedResponse = yield call(prepareResponseData, response, urlParams);
  yield put(fixtureGroupsActionCreators.fetchGroupsSuccessPaginated(enhancedResponse));
}

function* fixtureGroupsSaga() {
  yield takeLatest(constants.FETCH_FIXTURE_GROUPS_REQUEST, fetchFixtureGroups);
  yield takeLatest(constants.FETCH_FIXTURE_GROUPS_SUCCESS, paginateFixtureGroups);
}

export default fixtureGroupsSaga;
