import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

const NewPasswordField = ({ input: { onChange } }) => (
  <Input
    onChange={onChange}
    placeholder="New password"
    type="password"
  />
);

NewPasswordField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }).isRequired,
};

export default NewPasswordField;
