import React, { Component } from 'react';
import { Input, Select } from 'semantic-ui-react';

import FormField from '../FormField/FormField';

class DropdownField extends Component {

  constructor() {
    super();

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { options } = this.props;
    if (options.length && options.length === 1) {
      this.handleChange(null, options[0]);
    }
  }

  handleChange(event, { value }) {
    const { input: { onChange } } = this.props;

    onChange(value);
  }

  render() {
    const { input, options, disabled, displayPlaceholder, placeholder, ...rest } = this.props;

    return (
      <FormField {...rest} input={input}>
        <Select
          {...input}
          onChange={this.handleChange}
          selectOnBlur={false}
          selectOnNavigation={false}
          options={options}
          value={input.value}
          disabled={disabled}
          fluid
          placeholder={displayPlaceholder ? placeholder : undefined}
        />
      </FormField>
    );
  }

}

DropdownField.propTypes = {
  ...Input.propTypes,
};

export default DropdownField;
