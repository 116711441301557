import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';

import processLogText from './processLogText';

const prepareLogsForTable = logs => {
  if (isNil(logs)) {
    return [];
  }

  if (!Array.isArray(logs)) {
    return [];
  }

  const processedLogs = logs.map((item, index) => {
    const processedText = processLogText(item);
    const processedTextAsHTML = processLogText(item, true);

    return {
      ...item,
      id: `index-${index}`,
      initiator: item.text_data.initiator && item.text_data.initiator.name,
      recipient: item.text_data.recipient && item.text_data.recipient.name,
      actionType: item.log_data.action_type,
      displayText: processedText,
      createdAt: DateTime.fromISO(item.log_data.created_at).toFormat('dd LLL yyyy H:mm:ss'),
      date: DateTime.fromISO(item.log_data.created_at).toFormat('dd LLL yyyy'),
      time: DateTime.fromISO(item.log_data.created_at).toFormat('H:mm:ss'),
      displayHTML: processedTextAsHTML,
    };
  });

  return processedLogs;
};

export default prepareLogsForTable;
