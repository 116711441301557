import has from 'lodash/has';

const extractDefaultValuesFromParams = params => {
  if (!Array.isArray(params) || params.length <= 0) {
    return {};
  }

  return params.reduce((acc, item) => {
    if (has(item, 'default')) {
      return {
        ...acc,
        [item.key]: item.default,
      };
    }

    return acc;
  }, {});
};

export default extractDefaultValuesFromParams;
