import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import paginationActionCreators from 'data/paginationActionCreators';

import withSorting from 'helpers/withSorting/withSorting';
import { paginationSortFieldSelector } from 'helpers/selectors';

const mapStateToProps = (state, { pagination: { type, componentName } = {} }) => {
  const getType = componentName || type;

  return {
    sort: paginationSortFieldSelector(state)(getType),
  };
};

const mapDispatchToProps = {
  onRefetch: paginationActionCreators.refetch,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withSorting,
);
