import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import { getPath } from 'data/api/requests';
import paginationActionCreators from 'data/paginationActionCreators';
import { processCall, actionList } from 'helpers/sagaHelper';
import {
  paginationLinkSelector,
  paginationActiveSelector,
  paginationChainedLoaderIdSelector,
} from 'helpers/selectors';
import notificationsActionCreators from 'data/notifications/notificationsActionCreators';
import { messages } from 'data/notifications/notificationsConst';
import chainedLoaderActionCreators from 'helpers/chainedLoader/chainedLoaderActionCreators';

export function* performCall(action) {
  const { urlParams, resourceType, ...rest } = action.payload;

  const endpoint = (yield select(paginationLinkSelector))(resourceType);
  const { response, error } = yield call(getPath, endpoint, { urlParams });

  const params = {
    response,
    error,
    successDisp: paginationActionCreators.fetchPageResourceSuccess,
    failDisp: paginationActionCreators.fetchPageResourceFailure,
    resourceType,
    ...rest,
  };

  yield call(processCall, params);
}

export function* checkVisibleResource(action) {
  const { response, resourceType } = action.payload;

  const activeResourceId = (yield select(paginationActiveSelector))(resourceType);

  if (activeResourceId) {
    yield put(paginationActionCreators.clearActiveResource(resourceType));

    const resources = response[resourceType];

    if (resources && !(activeResourceId in resources)) {
      yield put(notificationsActionCreators.addInfoNotification(messages.RELOADING));
    }
  }
}

export function* refetchResource({ payload: { resourceType, componentName } }) {
  const checkResourceType = componentName || resourceType;
  const chainedLoaderId = (yield select(paginationChainedLoaderIdSelector))(checkResourceType);

  if (chainedLoaderId) {
    yield put(chainedLoaderActionCreators.triggerChain(chainedLoaderId));
  }
}

function* paginationSaga() {
  yield takeEvery(constants.FETCH_RESOURCE_PAGE_REQUEST, performCall);

  yield takeLatest(
    actionList(
      constants.FETCH_DEVICE_SUCCESS,
      constants.FETCH_PROJECT_SUCCESS,
      constants.FETCH_PROJECT_SUITE_SUCCESS,
      constants.FETCH_COMPANIES_SUCCESS,
      constants.FETCH_PROJECTS_SUCCESS,
      constants.FETCH_USERS_SUCCESS,
      constants.ADD_TO_DATA_STORE,
      constants.FETCH_PROJECT_SUITES_SUCCESS,
      constants.FETCH_PROJECT_SUITE_PROJECTS_SUCCESS,
      constants.FETCH_PROJECT_SUITE_USERS_SUCCESS,
    ),
    checkVisibleResource,
  );

  yield takeLatest(
    actionList(
      constants.ADD_COMPANY_SUCCESS,
      constants.ADD_TASK_SUCCESS,
      constants.INVITE_USER_SUCCESS,
      constants.UPDATE_COMPANY_INFO_SUCCESS,
      constants.DELETE_PROJECT_SUCCESS,
      constants.DELETE_PROJECT_SUITE_SUCCESS,
      constants.ADD_PROJECT_SUCCESS,
      constants.ADD_PROJECT_SUITE_SUCCESS,
      constants.REMOVE_USER_FROM_PROJECT_SUCCESS,
      constants.REMOVE_FILE_REQUEST_SUCCESS,
      constants.UPLOAD_SUCCESS,
      constants.UPDATE_TASK_SUCCESS,
      constants.ADD_AUTOMATED_OPERATION_SUCCESS,
      constants.REMOVE_USER_FROM_PROJECT_SUITE_SUCCESS,
      constants.SET_EXTRA_FILTERS_TRUE,
      constants.ADD_PROJECT_TO_PROJECT_SUITE_SUCCESS,
      constants.REFETCH_NOTIFICATION_EVENTS,
      constants.ADD_DEVICE_FIRMWARE_SUCCESS,
      constants.DELETE_DEVICE_FIRMWARE_SUCCESS,
    ),
    refetchResource,
  );
}

export default paginationSaga;
