import styled from 'styled-components';

import filterProps from 'helpers/filterProps';
import InputField from 'components/form/InputField/InputField';

const FilteredProps = filterProps([
  'priorityType',
])(InputField);

export const StyledInput = styled(FilteredProps)`
  visibility: hidden;
  width: 0px;
  height: 0px;
`;

export default StyledInput;
