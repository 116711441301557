import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import { layoutSectionsType } from 'data/types';
import TabbedLayoutSection from '../TabbedLayoutSection/TabbedLayoutSection';

const TabbedLayoutColumn = ({ resourceId, sections, ...columnProps }) => (
  <Grid.Column {...columnProps}>
    {sections.map(({ id, component, title }) => (
      <TabbedLayoutSection
        key={id}
        component={component}
        resourceId={resourceId}
        title={title}
      />
    ))}
  </Grid.Column>
);

TabbedLayoutColumn.defaultProps = {
  resourceId: null,
  sections: [],
};

TabbedLayoutColumn.propTypes = {
  resourceId: PropTypes.string,
  sections: layoutSectionsType,
};

export default TabbedLayoutColumn;
