import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Menu, Icon } from 'semantic-ui-react';

import { frontendPermissions, PermissionsSwitch, Gateway } from 'gateway';

import ProjectSuiteContext from '../../../ProjectSuiteContext/ProjectSuiteContext';
import {
  PageTitle,
  DuplicatePage,
  RemovePage,
  AddPage,
  BackgroundImageUrl,
  BackgroundImagePreview,
  RestrictAccess,
} from './components';

import { StyledButton, StyledMenuItem } from './ConfigurationPanel.style';

const ConfigurationPanel = ({
  configure,
  pageId,
  onPageChange,
  adding,
  onAddPage,
  total,
  isConstruction,
  isSuspended,
}) => {
  const isMultiSite = useContext(ProjectSuiteContext);

  const disabled = isConstruction || isSuspended;

  const permission = useMemo(() => {
    if (isMultiSite) {
      return frontendPermissions.PROJECT_SUITE_CONTROL_PANEL_EDIT;
    }

    return frontendPermissions.EDIT_CONTROL_PANEL;
  }, [isMultiSite]);

  const trigger = (
    <StyledButton disabled={disabled} icon labelPosition="left" fluid>
      <Icon name="plus" />
      Add page
    </StyledButton>
  );

  const renderContent = passesPermission => (
    <>
      <StyledMenuItem disabled={!passesPermission}>
        <Menu.Header>Page Title</Menu.Header>
        <PageTitle configure={configure} pageId={pageId} />
      </StyledMenuItem>
      <StyledMenuItem disabled={!passesPermission}>
        <Menu.Header>Page Background</Menu.Header>
        <BackgroundImageUrl pageId={pageId} />
      </StyledMenuItem>
      <StyledMenuItem disabled={!passesPermission}>
        <Menu.Header>Actions</Menu.Header>
        <AddPage
          onSubmit={onAddPage}
          submitting={adding}
          total={total}
          trigger={trigger}
        />
        <DuplicatePage pageId={pageId} />
        <RestrictAccess pageId={pageId} isMultiSite={isMultiSite} />
        <RemovePage pageId={pageId} onPageChange={onPageChange} />
        <BackgroundImagePreview pageId={pageId} />
      </StyledMenuItem>
    </>
  );

  return (
    <PermissionsSwitch>
      <Gateway condition={permission}>
        <Menu vertical fluid>
          {renderContent(true)}
        </Menu>
      </Gateway>
      <Gateway fallback>
        <Menu vertical fluid>
          {renderContent(false)}
        </Menu>
      </Gateway>
    </PermissionsSwitch>
  );
};

ConfigurationPanel.defaultProps = {
  configure: false,
  pageId: null,
  total: 0,
  adding: false,
};

ConfigurationPanel.propTypes = {
  configure: PropTypes.bool,
  pageId: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onAddPage: PropTypes.func.isRequired,
  total: PropTypes.number,
  adding: PropTypes.bool,
  isConstruction: PropTypes.bool.isRequired,
  isSuspended: PropTypes.bool.isRequired,
};

export default ConfigurationPanel;
