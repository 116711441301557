import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Label, Icon } from 'semantic-ui-react';
import isNil from 'lodash/isNil';

import { camelCaseToString } from './utilities';
import { StyledDiv, StyledLabel } from './RenderAppliedFilters.style';
import { translateAction } from '../LogsFilterParams/utilities';

function RenderAppliedFilters({ filterParams, setFilterParams }) {
  const handleRemove = filter => {
    setFilterParams({
      ...filterParams,
      [filter]: null,
    });
  };

  const handleRemoveAll = () => {
    const resetFilters = {};
    Object.keys(filterParams).forEach(key => {
      resetFilters[key] = null;
    });

    setFilterParams(resetFilters);
  };

  const filtersAreEmpty = Object.keys(filterParams)
    .filter(key => !isNil(filterParams[key])).length === 0;

  return (
    <Grid.Column width={16}>
      <StyledDiv>
        {Object.keys(filterParams).map(key => filterParams[key] && (
          <StyledLabel forwardedAs="a" htmlFor={key} key={key}>
            {camelCaseToString(key)}
            <Label.Detail>{translateAction(filterParams[key])}</Label.Detail>
            <Icon name="delete" onClick={() => handleRemove(key)} />
          </StyledLabel>
        ))}
        {!filtersAreEmpty && (
          <StyledLabel forwardedAs="a" htmlFor="clearAll" color="grey" onClick={handleRemoveAll}>
            Clear all
            <Icon name="delete" />
          </StyledLabel>
        )}
      </StyledDiv>
    </Grid.Column>
  );
}

RenderAppliedFilters.propTypes = {
  filterParams: PropTypes.object.isRequired,
  setFilterParams: PropTypes.func.isRequired,
};

export default RenderAppliedFilters;
