import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Form, Message } from 'semantic-ui-react';

import * as validators from 'components/form/formValidators';
import TextField from 'components/form/TextField/TextField';
import { Button, StyledRightFormField } from 'components/atomic';

import { ErrorMessage } from './SiteDetailsForm.style';

function SiteDetailsForm({
  invalid,
  onSubmit,
  isLoading,
  voucherForSiteError,
}) {
  const [showConfirmButton, setShowConfirmButton] = useState(false);

  const handleConfirm = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <Form>
      <Field
        name="name"
        label="Site name"
        type="text"
        placeholder="Site name"
        fluid
        validate={validators.validDisplayName}
        component={TextField}
        disabled={showConfirmButton}
        required
      />
      <Field
        name="ownerEmail"
        label="First user email address"
        type="text"
        placeholder="First user email address"
        fluid
        validate={validators.validEmail}
        component={TextField}
        disabled={showConfirmButton}
        required
      />
      {!showConfirmButton && (
        <Form.Group>
          <StyledRightFormField>
            <Button disabled={invalid} primary onClick={() => setShowConfirmButton(true)}>
              Continue
            </Button>
          </StyledRightFormField>
        </Form.Group>
      )}
      {showConfirmButton && (
        <>
          <Message info>
            <Message.Header>Information</Message.Header>
            <p>
              Once confirmed, this voucher will be redeemed against this Site and cannot be removed.
            </p>
          </Message>
          {voucherForSiteError && (<ErrorMessage>{voucherForSiteError}</ErrorMessage>)}
          <Form.Group>
            <StyledRightFormField>
              <Button onClick={() => setShowConfirmButton(false)}>
                Cancel
              </Button>
              <Button primary onClick={handleConfirm} loading={isLoading} disabled={isLoading}>
                Confirm
              </Button>
            </StyledRightFormField>
          </Form.Group>
        </>
      )}
    </Form>
  );
}

SiteDetailsForm.defaultProps = {
  voucherForSiteError: null,
};

SiteDetailsForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  voucherForSiteError: PropTypes.string,
};

export default SiteDetailsForm;
