import styled from 'styled-components';
import { List } from 'semantic-ui-react';

import { PortalThemedLinkButton } from 'components/atomic';
import { mediaMax } from 'themes/mixins';

export const Container = styled.div`
  ${mediaMax.md`
    margin-top: 24px;
  `};
`;

export const StyledVoucherInputListContent = styled(List.Content)`
  padding: 8px 0;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
`;

export const StyledLink = styled(PortalThemedLinkButton)`
  display: block;
`;
