import { call } from 'redux-saga/effects';

import { putPath } from 'data/api/requests';
import { processFinalFormResponse } from 'utilities/form';
import UserModel from 'models/UserModel';
import { ERROR_SERVER_CONNECTION } from 'data/notifications/notificationsConst';
import actionCreators from '../actionCreators';

function* resetSecretKey(action) {
  const { endpoint, id, resolveForm } = action.payload;
  const body = UserModel.requestBody({ id, otpSecret: null });
  const { response, error } = yield call(putPath, endpoint, body);

  if (error instanceof Error) {
    error.message = ERROR_SERVER_CONNECTION;
  }

  const params = {
    response,
    error,
    successDisp: actionCreators.resetSecretKeySuccess,
    failDisp: actionCreators.resetSecretKeyFailure,
    resolveForm,
  };
  yield call(processFinalFormResponse, params);
}

export default resetSecretKey;
