import React, { useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Grid } from 'semantic-ui-react';
import moment from 'moment';
import pluralize from 'pluralize';

import { DatePicker, TimePicker } from 'components/core';
import { SelectedTimesView } from 'components/core/TimePicker/components';

import { EndingCriteria } from '..';
import { SCHEDULER_TYPES } from '../../../../../constants';
import { Container, StyledLabel, StyledRow, ErrorMessage } from './Daily.style';

function Daily({
  recurrenceErrors,
  recurrenceParams,
  existingSchedule,
  onRecurrenceParamsChange,
  onEndingCriteriaChange,
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [daysInterval, setDaysInterval] = useState(1);
  const [timeInterval, setTimeInterval] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endingCriteria, setEndingCriteria] = useState({});

  useEffect(() => {
    if (existingSchedule && !isLoaded) {
      const { type, params } = existingSchedule || {};

      if (type.toUpperCase() === SCHEDULER_TYPES.DAILY) {
        const {
          everyNDays,
          startDate: existingStartDate,
          startTimes,
        } = params;

        setStartDate(moment(existingStartDate));
        setDaysInterval(everyNDays);
        setTimeInterval(startTimes);

        setIsLoaded(true);
      }
    }
  }, [isLoaded, existingSchedule, recurrenceParams]);

  useEffect(() => {
    onRecurrenceParamsChange({
      everyNDays: Number(daysInterval),
      startDate: startDate && startDate.format('YYYY-MM-DD'),
      startTimes: timeInterval,
      ...endingCriteria.params,
    });
  }, [daysInterval, timeInterval, startDate, endingCriteria, onRecurrenceParamsChange]);

  const daysDescription = useMemo(() => `${pluralize('day', Number(daysInterval))} at`, [daysInterval]);

  const handleEditingCriteriaChange = useCallback(value => {
    setEndingCriteria(value);

    onEndingCriteriaChange(value.type);
  }, [onEndingCriteriaChange]);

  const handleTimeSelected = useCallback(newTimes => {
    setTimeInterval(newTimes);
  }, []);

  const handleClearTimes = useCallback(() => {
    setTimeInterval([]);
  }, []);

  const handleRemoveTime = useCallback(deletedTime => {
    setTimeInterval(previousTimes => previousTimes.filter(time => time !== deletedTime));
  }, []);

  return (
    <Container>
      <Form size="large">
        <Grid>
          <StyledRow noPaddingBottom>
            <Grid.Column computer={6} mobile={8}>
              <Form.Field>
                <StyledLabel withCustomPadding>
                  Repeat every
                </StyledLabel>
                <Input
                  name="day_interval"
                  type="number"
                  size="mini"
                  min="1"
                  value={daysInterval}
                  style={{ width: '50px' }}
                  onChange={e => setDaysInterval(e.target.value)}
                  label={{
                    basic: true,
                    content: daysDescription,
                  }}
                  labelPosition="right"
                  required
                />
                <ErrorMessage>{recurrenceErrors.everyNDays}</ErrorMessage>
              </Form.Field>
            </Grid.Column>
            <Grid.Column computer={10} mobile={8}>
              <Form.Field>
                <StyledLabel withCustomPadding>
                  Times
                </StyledLabel>
                <TimePicker
                  name="start_times"
                  popupPosition="right center"
                  selectedTimes={timeInterval}
                  onChange={handleTimeSelected}
                  keepOpenedAfterSubmitFor={['SIMPLE']}
                  defaultEmptyMessage="Add time(s)"
                />
                <ErrorMessage>{recurrenceErrors.startTimes}</ErrorMessage>
              </Form.Field>
            </Grid.Column>
          </StyledRow>
          <StyledRow noPaddingBottom>
            <Grid.Column computer={16} mobile={16}>
              <Form.Field>
                <SelectedTimesView
                  selectedTimes={timeInterval}
                  onClearTimes={handleClearTimes}
                  onRemoveTime={handleRemoveTime}
                  hideWhenEmpty
                />
              </Form.Field>
            </Grid.Column>
          </StyledRow>
          <StyledRow>
            <Grid.Column computer={6} mobile={8}>
              <Form.Field>
                <StyledLabel withCustomPadding>
                  Starting on
                </StyledLabel>
                <DatePicker
                  name="start_date"
                  value={startDate}
                  min={moment().toDate()}
                  onChange={value => setStartDate(value)}
                  footer={false}
                  popupPosition="left center"
                  defaultEmptyMessage="Select a date."
                />
                <ErrorMessage>{recurrenceErrors.startDate}</ErrorMessage>
              </Form.Field>
            </Grid.Column>
          </StyledRow>
          <EndingCriteria onChange={handleEditingCriteriaChange} />
        </Grid>
      </Form>
    </Container>
  );
}

Daily.defaultProps = {
  recurrenceErrors: {},
  recurrenceParams: {},
  existingSchedule: null,
};

Daily.propTypes = {
  recurrenceErrors: PropTypes.object,
  recurrenceParams: PropTypes.object,
  existingSchedule: PropTypes.object,
  onRecurrenceParamsChange: PropTypes.func.isRequired,
  onEndingCriteriaChange: PropTypes.func.isRequired,
};

export default Daily;
