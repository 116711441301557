import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

const DeleteSchedule = props => (
  <Button
    icon
    floated="right"
    size="mini"
    {...props}
  >
    <Icon name="delete" />
  </Button>
);

export default DeleteSchedule;
