import React from 'react';

const Normal = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="2"
    fill="none"
    viewBox="0 0 14 2"
  >
    <path
      stroke="#FFAB00"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M1 1h12"
    />
  </svg>
);

export default Normal;
