import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';

import { complexValueType } from 'data/types';
import BoolDescriptionItem from 'components/DescriptionList/BoolDescriptionItem/BoolDescriptionItem';

const PathwayVIA12DevicePropertiesV1 = ({
  title,
  vlan,
  isLoading,
}) => {
  if (isLoading) {
    return null;
  }

  return (
    <Segment>
      <Header as="h2">{title}</Header>
      <BoolDescriptionItem title="VLAN Enabled" value={vlan} />
    </Segment>
  );
};

PathwayVIA12DevicePropertiesV1.defaultProps = {
  vlan: {},
  isLoading: true,
};

PathwayVIA12DevicePropertiesV1.propTypes = {
  title: PropTypes.string.isRequired,
  vlan: complexValueType(PropTypes.bool),
  isLoading: PropTypes.bool,
};

export default PathwayVIA12DevicePropertiesV1;
