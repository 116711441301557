import isNil from 'lodash/isNil';

const openDownloadUrl = response => {
  if (isNil(response)) {
    return null;
  }

  if (isNil(response.data)) {
    return null;
  }

  if (isNil(response.data.attributes)) {
    return null;
  }

  if (isNil(response.data.attributes.presigned_url)) {
    return null;
  }

  const { data: { attributes: { presigned_url: presignedUrl } } } = response;

  return window.open(presignedUrl, '_blank');
};

export default openDownloadUrl;
