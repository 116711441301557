import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  currentUserIdSelector,
  tenantSelector,
  recentlyLoggedOutSelector,
} from 'helpers/selectors';
import ApplicationSwitch from './ApplicationSwitch';

const mapStateToProps = state => ({
  tenant: tenantSelector(state),
  currentUserId: currentUserIdSelector(state),
  recentlyLoggedOut: recentlyLoggedOutSelector(state),
});

export default compose(
  withRouter,
  connect(mapStateToProps),
)(ApplicationSwitch);
