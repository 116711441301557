import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import onMountUnmount from 'helpers/onMountUnmount';
import websocketActionCreators from 'data/websockets/websocketsActionCreators';
import { extractTenantData } from 'application/tenant/console/site/components/structural/SiteActivityLoggingTab/components/LogsFilterParams/utilities';

import { actionCreators, selectors } from '../../utilities';

import NotificationsDropdown from './NotificationsDropdown';

export const mapStateToProps = state => {
  const notificationEvents = selectors.recentNotificationEventsSelector(state) || [];

  const { tenant } = extractTenantData(state);

  return {
    isLoading: selectors.isLoadingNotificationsSelector(state),
    notificationEvents,
    displayIcon: selectors.hasUnseenNotificationsSelector(state),
    tenant,
  };
};

const mapDispatchToProps = {
  onReadNotification: actionCreators.readNotificationEvent,
  onArchiveNotification: actionCreators.archiveNotificationEvent,
  onClearNotificationIcon: actionCreators.clearNotificationEventIcon,
  onReadAllNotifications: actionCreators.readAllNotificationEvents,
  onArchiveAllNotifications: actionCreators.archiveAllNotificationEvents,
  onFetchNotificationEvents: actionCreators.fetchNotificationEventsForDropdown,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  onMountUnmount(
    ({ tenant }) => actionCreators.subscribeNotificationEventsChannel(tenant),
    ({ tenant }) => websocketActionCreators.unSubscribeNotificationsChannel(tenant),
  ),
)(NotificationsDropdown);
