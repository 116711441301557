import {
  currentUserSelector,
  tenantSelector,
  currentCompanyIdSelector,
} from 'helpers/selectors';
import UserModel from 'models/UserModel';
import { selectors } from '.';

const extractTenantData = state => {
  const sixEyeTenant = 'sixeye';
  const currentUser = currentUserSelector(state);
  const { superadmin } = new UserModel(currentUser || {});

  if (superadmin) {
    const tenant = tenantSelector(state);
    const currentTenant = tenant || sixEyeTenant;
    const company = selectors.tenantIdSelector(state)(currentTenant);
    const companyId = company && company.key;
    const computeTenant = tenant === sixEyeTenant ? '' : tenant;

    return {
      companyId,
      tenant: computeTenant,
    };
  }

  const companyIdNonSA = currentCompanyIdSelector(state);

  return {
    companyId: companyIdNonSA,
    tenant: '',
  };
};

export default extractTenantData;
