import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Grid } from 'semantic-ui-react';
import moment from 'moment';

import { DatePicker, TimePicker } from 'components/core';
import { SelectedTimesView } from 'components/core/TimePicker/components';
import { SelectedDatesView } from 'components/core/DatePicker/components';

import { SCHEDULER_TYPES } from '../../../../constants';
import { Container, StyledLabel } from './SpecificDates.style';

function SpecificDates({
  recurrenceParams,
  existingSchedule,
  onRecurrenceParamsChange,
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState([]);

  useEffect(() => {
    if (existingSchedule && !isLoaded) {
      const { type, params } = existingSchedule || {};

      if (type.toUpperCase() === SCHEDULER_TYPES.DATES) {
        const { specificDates, specificTimes } = params;

        setSelectedDates(specificDates);
        setSelectedTimes(specificTimes);

        setIsLoaded(true);
      }
    }
  }, [isLoaded, existingSchedule, recurrenceParams]);

  useEffect(() => {
    onRecurrenceParamsChange({
      specificDates: selectedDates,
      specificTimes: selectedTimes,
    });
  }, [selectedDates, selectedTimes, onRecurrenceParamsChange]);

  const handleDateSelected = useCallback(value => {
    const formattedDate = value.format('YYYY-MM-DD');

    if (selectedDates.includes(formattedDate)) {
      return;
    }

    setSelectedDates(previousState => [...previousState, formattedDate]);
  }, [selectedDates]);

  const handleTimeSelected = useCallback(newTimes => {
    setSelectedTimes(newTimes);
  }, []);

  const handleRemoveTime = useCallback(deletedTime => {
    setSelectedTimes(previousTimes => previousTimes.filter(time => time !== deletedTime));
  }, []);

  const handleRemoveDate = useCallback(deletedDate => {
    setSelectedDates(previousDates => previousDates.filter(date => date !== deletedDate));
  }, []);

  const handleClearTimes = useCallback(() => {
    setSelectedTimes([]);
  }, []);

  const handleClearDates = useCallback(() => {
    setSelectedDates([]);
  }, []);

  return (
    <Container>
      <Form size="large">
        <Grid>
          <StyledLabel>
            Select date(s) and time(s) to proceed
          </StyledLabel>
          <Grid.Row>
            <Grid.Column computer={6} mobile={8}>
              <DatePicker
                value={null}
                min={moment().toDate()}
                onChange={handleDateSelected}
                footer={false}
                popupPosition="left center"
                defaultEmptyMessage="Add date(s)"
                keepOpen
              />
            </Grid.Column>
            <Grid.Column computer={7} mobile={8}>
              <TimePicker
                popupPosition="right center"
                selectedTimes={selectedTimes}
                onChange={handleTimeSelected}
                keepOpenedAfterSubmitFor={['SIMPLE']}
                defaultEmptyMessage="Add time(s)"
                hideSelectedTime
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <SelectedDatesView
                selectedDates={selectedDates}
                onClearDates={handleClearDates}
                onRemoveDate={handleRemoveDate}
                hideWhenEmpty
              />
              <SelectedTimesView
                selectedTimes={selectedTimes}
                onClearTimes={handleClearTimes}
                onRemoveTime={handleRemoveTime}
                hideWhenEmpty
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </Container>
  );
}

SpecificDates.defaultProps = {
  existingSchedule: null,
};

SpecificDates.propTypes = {
  recurrenceParams: PropTypes.object.isRequired,
  existingSchedule: PropTypes.object,
  onRecurrenceParamsChange: PropTypes.func.isRequired,
};

export default SpecificDates;
