import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const fetchAllPermissions = (urlParams = {}) => ({
  type: constants.FETCH_ALL_PERMISSIONS_REQUEST,
  payload: {
    endpoint: endpoints.INDEX_PERMISSIONS,
    urlParams,
  },
});

export const fetchAllPermissionsSuccess = response => ({
  type: constants.FETCH_ALL_PERMISSIONS_SUCCESS,
  payload: {
    response,
  },
});

export const fetchAllPermissionsFailure = error => ({
  type: constants.FETCH_ALL_PERMISSIONS_FAILED,
  payload: { error },
});

export default fetchAllPermissions;
