import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { AddDeviceFirmwareModalForm } from './components';

const AddDeviceFirmware = ({ onAddDeviceFirmware }) => {
  const handleAddDeviceFirmware = useCallback(
    values => new Promise(
      (resolveForm, rejectForm) => {
        onAddDeviceFirmware({
          values,
          resolveForm,
          rejectForm,
        });
      }),
    [onAddDeviceFirmware],
  );

  return (
    <AddDeviceFirmwareModalForm onSubmit={handleAddDeviceFirmware} />
  );
};

AddDeviceFirmware.propTypes = {
  onAddDeviceFirmware: PropTypes.func.isRequired,
};

export default AddDeviceFirmware;
