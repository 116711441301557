import { compose } from 'redux';
import { connect } from 'react-redux';

import { deepEqualMemoizeOne } from 'helpers/sharedMethods';
import {
  computedProjectSuiteTreePathSelector,
  permissionsProjectSuiteActiveUserIdsSelector,
} from 'helpers/selectors';
import { PermissionsGridRow } from '../../../../components';
import { sortPaths } from '../../../../utilities';
import { ProjectSuitePermissionsGridItem } from '../PermissionsGridItem';

// eslint-disable-next-line import/no-mutable-exports
let ProjectSuitePermissionsGridRowContainer;

export const makeMapState = () => {
  const memoPaths = deepEqualMemoizeOne();

  return function mapStateToProps(state, { path, resourceId }) {
    const { open, permissionCount, permissionId, ...paths } = computedProjectSuiteTreePathSelector(
      state,
      {
        projectSuiteId: resourceId,
        path,
      },
    );
    const sortedPaths = sortPaths(paths);
    const userIds = permissionsProjectSuiteActiveUserIdsSelector(state, {
      projectSuiteId: resourceId,
    });

    return {
      open,
      userIds,
      paths: memoPaths(sortedPaths),
      PermissionsGridItem: ProjectSuitePermissionsGridItem,
      PermissionsGridRowContainer: ProjectSuitePermissionsGridRowContainer,
    };
  };
};

ProjectSuitePermissionsGridRowContainer = compose(
  connect(makeMapState),
)(PermissionsGridRow);

export default ProjectSuitePermissionsGridRowContainer;
