import { takeEvery } from 'redux-saga/effects';

import constants from 'dispatcherConst';

import fetchAllPermissions from './fetchAllPermissions';
import fetchCumulatedPermissions from './fetchCumulatedPermissions';
import fetchPermissionsTableRecipients from './fetchPermissionsTableRecipients';
import grantMultiplePermissions from './grantMultiplePermissions';
import revokeMultiplePermissions from './revokeMultiplePermissions';
import pasteMultiplePermissions from './pasteMultiplePermissions';

function* saga() {
  yield takeEvery(constants.FETCH_ALL_PERMISSIONS_REQUEST, fetchAllPermissions);
  yield takeEvery(constants.FETCH_CUMULATED_PERMISSIONS_REQUEST, fetchCumulatedPermissions);
  yield takeEvery(constants.FETCH_PERMISSIONS_RECIPIENTS_REQUEST, fetchPermissionsTableRecipients);
  yield takeEvery(constants.GRANT_MULTIPLE_PERMISSIONS_REQUEST, grantMultiplePermissions);
  yield takeEvery(constants.REVOKE_MULTIPLE_PERMISSIONS_REQUEST, revokeMultiplePermissions);
  yield takeEvery(constants.PASTE_MULTIPLE_PERMISSIONS_REQUEST, pasteMultiplePermissions);
}

export default saga;
