import React from 'react';

import {
  SpecificDates,
  None,
  Daily,
  Weekly,
  Monthly,
  Yearly,
} from './components';

export const SCHEDULER_TYPE_CONFIG = {
  NONE: {
    title: 'None',
    description: 'Schedule configuration can be skipped.',
    content: () => (<None />),
    separator: false,
    renderHeader: false,
  },
  DATES: {
    title: 'Specific dates',
    description: 'Runs on specific dates & times.',
    content: () => (<SpecificDates />),
    separator: true,
    renderHeader: true,
  },
  DAILY: {
    title: 'Daily',
    description: 'Set params for "Daily" recurrence.',
    content: () => (<Daily />),
    separator: true,
    renderHeader: true,
  },
  WEEKLY: {
    title: 'Weekly',
    description: 'Set params for "Weekly" recurrence.',
    content: () => (<Weekly />),
    separator: true,
    renderHeader: true,
  },
  MONTHLY: {
    title: 'Monthly',
    description: 'Set params for "Monthly" recurrence.',
    content: () => (<Monthly />),
    separator: true,
    renderHeader: true,
  },
  YEARLY: {
    title: 'Yearly',
    description: 'Set params for "Yearly" recurrence.',
    content: () => (<Yearly />),
    separator: true,
    renderHeader: true,
  },
};

export default SCHEDULER_TYPE_CONFIG;
