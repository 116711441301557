import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import isEmpty from 'lodash/isEmpty';

import { TimeList } from './components';
import { StyledDateLabel, StyledLink } from './SelectedTimesView.style';

function SelectedTimesView({
  selectedTimes,
  onClearTimes,
  onRemoveTime,
  hideWhenEmpty,
  renderLabel,
  clearable,
}) {
  const timeLabel = useMemo(() => pluralize('time', selectedTimes), [selectedTimes]);

  const clearLabel = useMemo(() => {
    if (isEmpty(selectedTimes)) {
      return null;
    }

    return (
      <StyledLink className="clear-label-link" onClick={onClearTimes}>
        {`Clear ${timeLabel}`}
      </StyledLink>
    );
  }, [selectedTimes, timeLabel, onClearTimes]);

  const timesLabel = useMemo(() => {
    if (!renderLabel) {
      return null;
    }

    return (
      <StyledDateLabel className="selected-times-label" basic>
        Selected {timeLabel}: {clearLabel}
      </StyledDateLabel>
    );

  }, [clearLabel, renderLabel, timeLabel]);

  if (isEmpty(selectedTimes) && hideWhenEmpty) {
    return null;
  }

  return (
    <>
      {timesLabel}
      <TimeList times={selectedTimes} onRemoveTime={onRemoveTime} clearable={clearable} />
    </>
  );
}

SelectedTimesView.defaultProps = {
  selectedTimes: null,
  onClearTimes: null,
  onRemoveTime: null,
  hideWhenEmpty: false,
  renderLabel: true,
  clearable: true,
};

SelectedTimesView.propTypes = {
  selectedTimes: PropTypes.arrayOf(PropTypes.string),
  onClearTimes: PropTypes.func,
  onRemoveTime: PropTypes.func,
  hideWhenEmpty: PropTypes.bool,
  renderLabel: PropTypes.bool,
  clearable: PropTypes.bool,
};

export default SelectedTimesView;
