export const extraColumns = {
  action: 'Delete',
};

export const astroText = {
  sunrise: 'Sunrise',
  sunset: 'Sunset',
};

export const scheduleFieldsArray = [
  'selectedDates',
  'selectedTimes',
  'advancedMode',
  'hours',
  'minutes',
  'seconds',
  'sunrise',
  'sunset',
];

export const astroHint = 'Enter up to 5 different values between -720 and +720.';

export const validInputError = 'Only up to 5 unique values are allowed.';

export const minRangeError = 'Minimum -720 range reached.';

export const maxRangeError = 'Maximum +720 range reached.';
