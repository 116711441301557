import BreadcrumbModel from 'models/BreadcrumbModel';
import { computePaths } from 'helpers/sharedMethods';
import {
  COMPANIES_FIELD,
  LOGICAL_DEVICES_FIELD,
  PROJECTS_FIELD,
  PROJECT_SUITES_FIELD,
} from 'helpers/selectors';
import ResourceBreadcrumb from 'components/BreadcrumbNav/ResourceBreadcrumb/ResourceBreadcrumbContainer';
import CustomBreadcrumb from 'components/BreadcrumbNav/CustomBreadcrumb/CustomBreadcrumbContainer';

export function attachBreadcrumb(acc, path) {
  const lastPart = path.split('/').pop();

  if (lastPart.startsWith(':')) {
    return acc;
  }

  return computePaths(lastPart, acc, path);
}

export const paths = {
  index: '/',
  // My Projects
  userProjects: '/my-sites',
  userProject: '/my-sites/:id',
  userProjectDevices: '/my-sites/:projectId/devices',
  userProjectDevice: '/my-sites/:projectId/devices/:deviceId',
  userProjectControlPanel: '/my-sites/:id/control_panel',
  // Projects
  projects: '/sites',
  project: '/sites/:id',
  projectDevices: '/sites/:projectId/devices',
  projectDevice: '/sites/:projectId/devices/:deviceId',
  projectControlPanel: '/sites/:id/control_panel',
  // My Project Suites
  userProjectSuites: '/my-multi-sites',
  userProjectSuite: '/my-multi-sites/:id',
  userProjectSuiteControlPanel: '/my-multi-sites/:id/control_panel',
  // Project Suites
  projectSuites: '/multi-sites',
  projectSuite: '/multi-sites/:id',
  projectSuiteControlPanel: '/multi-sites/:id/control_panel',
  // Companies
  companies: '/companies',
  company: '/companies/:companyId',
  // Other
  users: '/users',
  settings: '/settings',
  passwordReset: '/reset_password/:passwordResetToken',
  signUp: '/sign_up/:invitationToken',
  profile: '/profile',
  permissions: '/permissions',
  vouchers: '/vouchers',
  firmware: '/firmware',
};

export const pathSets = {
  public: [
    paths.index,
    paths.passwordReset,
    paths.signUp,
  ],
  tenant: [
    paths.index,
    paths.passwordReset,
    paths.signUp,
    paths.projectControlPanel,
    paths.userProjectControlPanel,
    paths.projectDevice,
    paths.userProjectDevice,
    paths.project,
    paths.userProject,
    paths.projects,
    paths.userProjects,

    paths.projectSuite,
    paths.userProjectSuite,
    paths.projectSuites,
    paths.userProjectSuites,
    paths.projectSuiteControlPanel,
    paths.userProjectSuiteControlPanel,

    paths.permissions,
    paths.profile,
    paths.users,
    paths.company,
    paths.companies,
    paths.settings,
    paths.vouchers,
    paths.firmware,
  ],
  console: [
    paths.projectDevice,
    paths.userProjectDevice,
    paths.project,
    paths.userProject,
    paths.projects,
    paths.userProjects,
    paths.permissions,
    paths.profile,
    paths.users,
    paths.company,
    paths.companies,
    paths.settings,
    paths.vouchers,
    paths.firmware,
  ],
  controlPanel: [
    paths.projectControlPanel,
    paths.userProjectControlPanel,
    paths.projectSuiteControlPanel,
    paths.userProjectSuiteControlPanel,
  ],
};

export const breadcrumbs = Object
  .values(paths)
  .reduce(attachBreadcrumb, {});

export const breadcrumbsRoutes = [
  new BreadcrumbModel(paths.index, null),
  new BreadcrumbModel(paths.userProjects, CustomBreadcrumb),
  new BreadcrumbModel(paths.userProject, ResourceBreadcrumb, { type: PROJECTS_FIELD }),
  new BreadcrumbModel(paths.userProjectControlPanel, null),
  new BreadcrumbModel(paths.userProjectDevice, ResourceBreadcrumb, { type: LOGICAL_DEVICES_FIELD }),
  new BreadcrumbModel(`${paths.userProjectDevice}/*`, null),
  new BreadcrumbModel(paths.projectControlPanel, null),
  new BreadcrumbModel(paths.projects, CustomBreadcrumb),
  new BreadcrumbModel(paths.project, ResourceBreadcrumb, { type: PROJECTS_FIELD }),
  new BreadcrumbModel(paths.projectDevice, ResourceBreadcrumb, { type: LOGICAL_DEVICES_FIELD }),
  new BreadcrumbModel(`${paths.projectDevice}/*`, null),
  new BreadcrumbModel(paths.company, ResourceBreadcrumb, { type: COMPANIES_FIELD }),
  new BreadcrumbModel(paths.userProjectSuites, CustomBreadcrumb),
  new BreadcrumbModel(paths.userProjectSuite, ResourceBreadcrumb, { type: PROJECT_SUITES_FIELD }),
  new BreadcrumbModel(paths.projectSuites, CustomBreadcrumb),
  new BreadcrumbModel(paths.projectSuite, ResourceBreadcrumb, { type: PROJECT_SUITES_FIELD }),
];
