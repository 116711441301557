import {
  fetchLogsActionTypes,
  fetchLogsActionTypesSuccess,
  fetchLogsActionTypesFailure,
} from './fetchLogsActionTypes';

export default {
  fetchLogsActionTypes,
  fetchLogsActionTypesSuccess,
  fetchLogsActionTypesFailure,
};
