import { getRemoteDeviceStatus } from '../../utilities';

class SubRemoteDeviceModel {

  constructor(device = {}) {
    this.id = device.serial;
    this.serial = device.serial;
    this.firmware = device.firmware;
  }

  get status() {
    return getRemoteDeviceStatus(this.firmware);
  }

}

export default SubRemoteDeviceModel;
