import React, { useState, useCallback, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { IoIosAdd } from 'react-icons/io';

import { MultiStepModal } from 'components/core';
import { convertColorString } from 'helpers/sharedMethods';
import { ProjectSuiteContext } from 'application/tenant/components/structural/ControlUI/components';

import { CopyButton, PasteButton, CancelButton } from '../ControlUIButtonGroup/ControlUIButton/components';
import { SelectGroupsStep, ControlsAppearanceStep, OverrideRGBControls } from './components';
import { configurationFormFields } from './utilities';
import { StyledEmptyButton } from './ControlUIOverrideControl.style';

function ControlUIOverrideControl({
  projectId,
  pageId,
  controlId,
  form,
  configure,
  enabled,
  values,
  showIntensitySlider,
  showColorTempSlider,
  labelText,
  labelTextColor,
  runButtonText,
  runButtonTextColor,
  runButtonBgColor,
  groups,
  displayCopyPasteButtons,
  onUpdateOverrideControls,
  onResetTaskScheduler,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const resourceType = useContext(ProjectSuiteContext) ? 'projectSuite' : 'project';

  const resetModal = useCallback(() => {
    onResetTaskScheduler();
    setCurrentStep(0);
  }, [onResetTaskScheduler]);

  const handleOpen = useCallback(() => {
    resetModal();

    setIsOpen(true);
  }, [resetModal]);

  const handleClose = useCallback(() => {
    resetModal();

    setIsOpen(false);
  }, [resetModal]);

  const handleSubmit = useCallback(() => {
    setIsLoading(true);

    onUpdateOverrideControls({
      projectId,
      pageId,
      controlId,
      values: {
        ...values,
        groups: selectedGroups,
      },
      resourceType,
      form,
      resolveForm: () => {
        setIsLoading(false);
        setCurrentStep(0);
        handleClose();
      },
      rejectForm: () => {
        setIsLoading(false);
        handleClose();
      },
    });
  }, [
    projectId,
    pageId,
    controlId,
    selectedGroups,
    values,
    resourceType,
    onUpdateOverrideControls,
    handleClose,
    form,
  ]);

  const handlePreviousStep = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const handleNextStep = useCallback(() => {
    const lastStep = 1;

    if (currentStep === lastStep) {
      handleSubmit();
    } else {
      setCurrentStep(currentStep + 1);
    }
  }, [currentStep, handleSubmit]);


  const commonProps = {
    form,
    initialValues: {
      [configurationFormFields.enabled]: enabled,
      [configurationFormFields.showIntensitySlider]: showIntensitySlider,
      [configurationFormFields.showColorTempSlider]: showColorTempSlider,
      [configurationFormFields.labelText]: labelText,
      [configurationFormFields.labelTextColor]: convertColorString(labelTextColor),
      [configurationFormFields.runButtonText]: runButtonText,
      [configurationFormFields.runButtonTextColor]: convertColorString(runButtonTextColor),
      [configurationFormFields.runButtonBgColor]: convertColorString(runButtonBgColor),
    },
    resourceType,
  };

  const steps = useMemo(() => [
    {
      title: 'Step 1',
      description: 'Select groups for override',
      content: () => (
        <SelectGroupsStep
          {...commonProps}
          groups={groups}
          onChange={newGroups => setSelectedGroups(newGroups)}
        />
      ),
      nextStepDisabled: isEmpty(selectedGroups),
    },
    {
      title: 'Step 2',
      description: 'Setup override controls',
      content: () => (
        <ControlsAppearanceStep {...commonProps} />
      ),
      nextStepDisabled: false,
    },
  ], [
    groups,
    commonProps,
    selectedGroups,
  ]);

  const renderTrigger = useCallback(() => {
    if (enabled && !configure) {
      return (
        <OverrideRGBControls
          controlId={controlId}
          labelText={labelText}
          labelTextColor={labelTextColor}
          runButtonText={runButtonText}
          runButtonTextColor={runButtonTextColor}
          runButtonBgColor={runButtonBgColor}
          showIntensitySlider={showIntensitySlider}
          showColorTempSlider={showColorTempSlider}
          groups={groups}
          pageId={pageId}
          configure={configure}
          resourceType={resourceType}
          resourceId={projectId}
        />
      );
    }

    if (enabled && configure) {
      return (
        <StyledEmptyButton type="button" onClick={handleOpen}>
          <OverrideRGBControls
            controlId={controlId}
            labelText={labelText}
            labelTextColor={labelTextColor}
            runButtonText={runButtonText}
            runButtonTextColor={runButtonTextColor}
            runButtonBgColor={runButtonBgColor}
            showIntensitySlider={showIntensitySlider}
            showColorTempSlider={showColorTempSlider}
            groups={groups}
            pageId={pageId}
            configure={configure}
            resourceType={resourceType}
            resourceId={projectId}
          />
          {displayCopyPasteButtons && (
            <>
              <CancelButton
                projectId={projectId}
                pageId={pageId}
                buttonId={controlId}
                controlType="overrideControls"
              />
              <CopyButton
                projectId={projectId}
                pageId={pageId}
                buttonId={controlId}
                controlType="overrideControls"
              />
            </>
          )}
        </StyledEmptyButton>
      );
    }

    return (
      <StyledEmptyButton empty invisible={!configure} type="button" onClick={handleOpen}>
        <IoIosAdd name="plus" />
        {displayCopyPasteButtons && (
          <>
            <CancelButton
              projectId={projectId}
              pageId={pageId}
              buttonId={controlId}
              controlType="overrideControls"
            />
            <PasteButton
              projectId={projectId}
              pageId={pageId}
              buttonId={controlId}
              controlType="overrideControls"
            />
          </>
        )}
      </StyledEmptyButton>
    );
  }, [
    handleOpen,
    enabled,
    controlId,
    labelText,
    labelTextColor,
    runButtonText,
    runButtonTextColor,
    runButtonBgColor,
    showIntensitySlider,
    showColorTempSlider,
    configure,
    resourceType,
    pageId,
    projectId,
    groups,
    displayCopyPasteButtons,
  ]);

  return (
    <MultiStepModal
      steps={steps}
      currentStep={currentStep}
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      onPreviousStep={handlePreviousStep}
      onNextStep={handleNextStep}
      trigger={renderTrigger()}
      submitText="Save Changes"
      isSubmitting={isLoading}
    />
  );
}

ControlUIOverrideControl.defaultProps = {
  configure: false,
  enabled: false,
  showIntensitySlider: false,
  showColorTempSlider: false,
  labelText: null,
  labelTextColor: null,
  runButtonText: null,
  runButtonTextColor: null,
  runButtonBgColor: null,
  values: null,
  groups: [],
  displayCopyPasteButtons: false,
};

ControlUIOverrideControl.propTypes = {
  projectId: PropTypes.string.isRequired,
  pageId: PropTypes.number.isRequired,
  controlId: PropTypes.number.isRequired,
  configure: PropTypes.bool,
  enabled: PropTypes.bool,
  showIntensitySlider: PropTypes.bool,
  showColorTempSlider: PropTypes.bool,
  labelText: PropTypes.string,
  labelTextColor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  runButtonText: PropTypes.string,
  runButtonTextColor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  runButtonBgColor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  form: PropTypes.string.isRequired,
  values: PropTypes.object,
  groups: PropTypes.array,
  displayCopyPasteButtons: PropTypes.bool,
  onUpdateOverrideControls: PropTypes.func.isRequired,
  onResetTaskScheduler: PropTypes.func.isRequired,
};

export default ControlUIOverrideControl;
