
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DateTime } from 'luxon';

import { singleProjectSelector } from 'helpers/selectors';
import { permissionsOrRedirect } from 'gateway';
import SiteSettings from './SiteConstructionSettings';
import { renderConstructionEndDate } from './utilities';

export const mapStateToProps = (state, { match }) => {
  const { id: siteId } = match.params;
  const { renewalDate, constructionEndDate } = singleProjectSelector(state, siteId);
  const graceEndDateMin = DateTime.fromISO(renewalDate).toISO();
  const formattedConstructionEndDate = renderConstructionEndDate(constructionEndDate);

  return {
    graceEndDateMin,
    formattedConstructionEndDate,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  permissionsOrRedirect(),
)(SiteSettings);
