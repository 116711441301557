import { put } from 'redux-saga/effects';

import { actionCreators } from '..';

function* triggerNotificationEventFilters(action) {
  const {
    notificationFilters,
    additionalFilters,
  } = action.payload;

  yield put(actionCreators.setNotificationEventFilters({
    notificationFilters,
    additionalFilters,
  }));

  yield put(actionCreators.refetchNotificationEvents({
    additionalFilters,
  }));
}

export default triggerNotificationEventFilters;
