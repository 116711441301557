import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';

import { StyledButton, StyledContent, StyledTrigger } from './components';

const UserLabel = ({
  userId,
  resourceId,
  resourceType,
  userFirstName,
  userFullName,
  userEmail,
  userLabelText,
  copyUserId,
  copyUserName,
  onRemoveUserId,
  onCopy,
  onPaste,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const canCopy = copyUserId === userId;
  const handleRemove = () => onRemoveUserId(resourceType, resourceId, userId);
  const handleCopy = () => {
    handleClose();
    onCopy({ resourceType, resourceId, userId });
  };
  const handlePaste = () => {
    handleClose();
    onPaste({ resourceType, resourceId, userId, copyUserId });
  };
  const trigger = (
    <StyledTrigger canCopy={canCopy}>
      {userLabelText}
    </StyledTrigger>
  );

  const title = userFirstName ? userFullName : userEmail;
  const copyMessage = () => {
    if (canCopy) {
      return 'Cancel copy';
    }

    return 'Copy permissions';
  };
  const content = (
    <StyledContent>
      <p><strong>{title}</strong></p>
      <StyledButton primary onClick={handleRemove}>Hide user</StyledButton>
      <StyledButton primary onClick={handleCopy}>{copyMessage()}</StyledButton>
      {copyUserId && !canCopy && (
        <StyledButton primary onClick={handlePaste}>Paste from {copyUserName}</StyledButton>
      )}
    </StyledContent>
  );

  return (
    <Popup
      trigger={trigger}
      onOpen={handleOpen}
      onClose={handleClose}
      open={open}
      content={content}
      wide
      hoverable
    />
  );
};

UserLabel.defaultProps = {
  userFirstName: null,
  userFullName: null,
  userEmail: null,
  userLabelText: null,
  copyUserId: null,
  copyUserName: null,
};

UserLabel.propTypes = {
  userId: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
  userFirstName: PropTypes.string,
  userFullName: PropTypes.string,
  userEmail: PropTypes.string,
  userLabelText: PropTypes.string,
  copyUserId: PropTypes.string,
  copyUserName: PropTypes.string,
  onRemoveUserId: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  onPaste: PropTypes.func.isRequired,
};

export default UserLabel;
