import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';

export const resetPassword = params => ({
  type: constants.RESET_PASSWORD,
  payload: {
    ...params,
    endpoint: endpoints.RESET_PASSWORD,
  },
});

export const resetPasswordSuccess = response => ({
  type: constants.RESET_PASSWORD_SUCCESS,
  payload: {
    response,
    message: messages.NEW_PASSWORD,
  },
});
