import isNull from 'lodash/isNull';

const generateLogicalExpression = scheduleIds => {
  if (isNull(scheduleIds) || !Array.isArray(scheduleIds)) {
    return null;
  }

  const reducer = (acc, item) => (`${acc} ${acc ? 'O' : ''} S{${item}}`);
  const accumulator = '';

  return scheduleIds.reduce(reducer, accumulator).trim();
};

export default generateLogicalExpression;
