import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Accordion, Button } from 'semantic-ui-react';

import { ChildPanelHeader } from 'components/core/Panel/Panel';

import { TasksActionsNavigation, SelectedActions } from '..';
import { getSelectedActionsDescription } from '../../utilities';
import { StyledAccordionTitle } from './MobileView.style';

function MobileView({
  selectedDevice,
  selectedActions,
  selectedTasks,
  visibleActions,
  resourceType,
  onActionSelected,
  onActionRemove,
  onActionToggle,
}) {
  const [activePanel, setActivePanel] = useState(0);

  const handlePanelToggle = useCallback(index => {
    const newActivePanel = activePanel === index ? -1 : index;

    setActivePanel(newActivePanel);
  }, [activePanel]);

  return (
    <Grid.Row>
      <Grid.Column width={16}>
        <Accordion exclusive>
          <StyledAccordionTitle
            active={activePanel === 0}
            index={0}
            forwardedAs={Grid.Column}
            width={16}
          >
            <ChildPanelHeader
              title="Tasks & Actions"
              description="Navigate and select Tasks/Actions"
              as={Accordion.Title}
            />
            <div>
              <Button
                circular
                icon={`chevron ${activePanel === 0 ? 'down' : 'left'}`}
                onClick={() => handlePanelToggle(0)}
                basic
                size="mini"
                name="btn-open-panel"
              />
            </div>
          </StyledAccordionTitle>
          <Accordion.Content active={activePanel === 0}>
            <TasksActionsNavigation
              resourceType={resourceType}
              selectedDevice={selectedDevice}
              onActionSelected={onActionSelected}
            />
          </Accordion.Content>
          <StyledAccordionTitle
            active={activePanel === 1}
            index={1}
            forwardedAs={Grid.Column}
            width={16}
          >
            <ChildPanelHeader
              title="Selected Tasks & Actions"
              description={getSelectedActionsDescription(selectedActions, selectedTasks)}
            />
            <Button
              circular
              icon={`chevron ${activePanel === 1 ? 'down' : 'left'}`}
              onClick={() => handlePanelToggle(1)}
              basic
              size="mini"
              name="btn-open-panel"
            />
          </StyledAccordionTitle>
          <Accordion.Content active={activePanel === 1}>
            <SelectedActions
              visibleActions={visibleActions}
              selectedActions={selectedActions}
              selectedTasks={selectedTasks}
              onActionRemove={onActionRemove}
              onActionToggle={onActionToggle}
            />
          </Accordion.Content>
        </Accordion>
      </Grid.Column>
    </Grid.Row>
  );
}

MobileView.defaultProps = {
  resourceType: 'project',
};

MobileView.propTypes = {
  selectedDevice: PropTypes.object.isRequired,
  selectedActions: PropTypes.array.isRequired,
  selectedTasks: PropTypes.array.isRequired,
  visibleActions: PropTypes.object.isRequired,
  onActionSelected: PropTypes.func.isRequired,
  onActionRemove: PropTypes.func.isRequired,
  onActionToggle: PropTypes.func.isRequired,
  resourceType: PropTypes.oneOf([
    'project',
    'projectSuite',
  ]),
};

export default MobileView;
