import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import { Dropdown } from 'semantic-ui-react';

function CompaniesSelect({
  selectedCompanyId,
  companies,
  placeholder,
  disabled,
  onChange,
}) {
  const options = useMemo(() => {
    const sorted = sortBy(companies, 'name');

    return sorted.map(company => {
      const { id, name } = company || {};

      return {
        text: name,
        value: id,
      };
    });
  }, [companies]);

  const handleChange = useCallback((e, option) => {
    const foundCompany = companies.find(company => company.id === option.value);

    onChange(foundCompany);
  }, [onChange, companies]);

  return (
    <Dropdown
      disabled={disabled}
      labeled
      placeholder={placeholder}
      value={selectedCompanyId}
      options={options}
      onChange={handleChange}
      fluid
      selection
      search
    />
  );
}

CompaniesSelect.defaultProps = {
  disabled: false,
  companies: [],
  selectedCompanyId: null,
  placeholder: 'Select company',
};

CompaniesSelect.propTypes = {
  disabled: PropTypes.bool,
  selectedCompanyId: PropTypes.string,
  companies: PropTypes.array,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default CompaniesSelect;
