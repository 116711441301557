import React from 'react';

import PermissionsSwitch from '../PermissionsSwitch/PermissionsSwitchContainer';
import Gateway from '../Gateway/Gateway';

const SimpleGateway = props => (
  <PermissionsSwitch>
    <Gateway {...props} />
  </PermissionsSwitch>
);

export default SimpleGateway;
