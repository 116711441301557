import { SubmissionError } from 'redux-form';
import { call, takeLatest, put, select, delay, race, take, all } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';
import has from 'lodash/has';

import { messages } from 'data/notifications/notificationsConst';
import endpoints from 'data/api/endpoints';
import constants from 'dispatcherConst';
import { postPath, getPath, deletePath, putPath } from 'data/api/requests';
import { schedulesSelector } from 'helpers/selectors';
import { processCall } from 'helpers/sagaHelper';
import AutomatedOperationModel from 'models/AutomatedOperationModel';
import ScheduleModel from 'models/ScheduleModel';
import AutomatedFunctionModel from 'models/AutomatedFunctionModel';
import { prepareResponseData } from 'helpers/paginationHelpersClient';
import automatedOperationsActionCreators from '../actionCreators';
import { prepareSchedulesParams, updateOccurrencesWithDelay, extractScheduleIds } from './utilities';
import generateLogicalExpression from './utilities/generateLogicalExpression';
import fetchAutomatedOperation from './fetchAutomatedOperation';

export function* addNewAutomatedOperation(action) {
  const {
    endpoint, params: {
      values, rejectForm, resolveForm,
    },
  } = action.payload;
  const data = new AutomatedOperationModel(values);
  const body = AutomatedOperationModel.requestBody(data);

  const { response, error } = yield call(postPath, endpoint, body);

  const params = {
    response,
    error,
    resolveForm,
    rejectForm,
    successDisp: automatedOperationsActionCreators.addAutomatedOperationSuccess,
    failDisp: automatedOperationsActionCreators.addAutomatedOperationFailure,
  };
  yield call(processCall, params);

}

export function* fetchAutomatedOperations(action) {
  const {
    endpoint,
    urlParams,
    resourceId,
    resourceType,
    ...rest
  } = action.payload;

  let propertyName = 'projectId';

  if (has(urlParams, 'recipientType') && urlParams.recipientType === 'projectSuite') {
    propertyName = 'projectSuiteId';
  }

  const { response, error } = yield call(getPath, endpoint, { urlParams });
  const responseWithMetadata = response && ({
    ...response,
    [propertyName]: resourceId,
  });

  const params = {
    response: responseWithMetadata,
    error,
    successDisp: automatedOperationsActionCreators.fetchAutomatedOperationsSuccess,
    failDisp: automatedOperationsActionCreators.fetchAutomatedOperationsFailure,
    resourceType,
    ...rest,
  };
  yield call(processCall, params);

  if (response) {
    const enhancedResponse = yield call(prepareResponseData, response, urlParams);

    yield put(automatedOperationsActionCreators
      .fetchAutomatedOperationsPaginated(enhancedResponse));
  }
}

export function* getScheduleInfoFromStore(automatedOperationId) {
  const {
    schedulesList,
    automatedFunctionId,
  } = (yield select(schedulesSelector))(automatedOperationId);

  return {
    automatedFunctionId,
    scheduleIds: schedulesList.map(item => item.id),
  };
}

export function* addNewSchedule(action) {
  const {
    endpoint,
    params: {
      values,
      resolveForm,
      rejectForm,
    },
  } = action.payload;
  const { automatedOperationId } = values;
  const automatedFunctionEndpoint = endpoints.AUTOMATED_FUNCTIONS(automatedOperationId);
  const {
    response: automatedFunctionResponse,
    error: automatedFunctionError,
  } = yield call(getPath, automatedFunctionEndpoint);

  if (automatedFunctionResponse) {
    const { data: { attributes: { logicalExpression, lockVersion } } } = automatedFunctionResponse;
    const { scheduleIds: localScheduleIds } = yield getScheduleInfoFromStore(automatedOperationId);
    const serverSchedulesIds = yield extractScheduleIds(logicalExpression);

    if (isEqual(localScheduleIds.sort(), serverSchedulesIds.sort())) {
      const data = new ScheduleModel(values);
      const parameters = prepareSchedulesParams(values);
      const scheduleWithParams = { ...data, params: parameters };
      const body = ScheduleModel.requestBody(scheduleWithParams);

      const {
        response: scheduleResponse,
        error: scheduleError,
      } = yield call(postPath, endpoint, body);

      const params = {
        response: scheduleResponse,
        error: scheduleError,
        resolveForm,
        rejectForm,
        successDisp: automatedOperationsActionCreators.addScheduleSuccess,
        failDisp: automatedOperationsActionCreators.addScheduleFailure,
      };

      yield call(processCall, params);

      if (scheduleResponse) {
        const { data: { id } } = scheduleResponse;
        const { automatedFunctionId } = (yield select(schedulesSelector))(automatedOperationId);
        const scheduleIdsFinal = [...localScheduleIds, id];
        const automatedFunctionString = generateLogicalExpression(scheduleIdsFinal);

        const updateAFParams = {
          id: automatedFunctionId,
          logicalExpression: automatedFunctionString,
          automatedOperationId,
          lockVersion,
          next: 0,
        };

        yield put(automatedOperationsActionCreators.updateAutomatedFunction(updateAFParams));
        yield put(automatedOperationsActionCreators.fetchSchedules(automatedOperationId));

        yield all([
          take(constants.UPDATE_AUTOMATED_FUNCTION_SUCCESS),
          take(constants.FETCH_SCHEDULES_SUCCESS),
        ]);

        yield call(updateOccurrencesWithDelay, automatedOperationId);
      }

      if (scheduleError) {
        yield put(
          automatedOperationsActionCreators
            .schedulesAFNotification(messages.ERROR_SCHEDULE_NOT_SAVED),
        );
      }
    } else {
      yield put(automatedOperationsActionCreators.fetchSchedules(automatedOperationId));
      const { success } = yield race({
        success: take(constants.FETCH_SCHEDULES_SUCCESS),
        failure: take(constants.FETCH_SCHEDULES_FAILED),
      });

      if (success) {
        const {
          scheduleIds: newLocalScheduleIds,
        } = yield getScheduleInfoFromStore(automatedOperationId);

        if (!isEqual(newLocalScheduleIds.sort(), serverSchedulesIds.sort())) {
          const params = {
            automatedOperationId,
            lockVersion,
          };

          yield put(automatedOperationsActionCreators.syncAutomatedFunction(
            params,
          ));
        }

        yield put(
          automatedOperationsActionCreators
            .schedulesAFNotification(messages.ERROR_SCHEDULES_UPDATED),
        );
      }

      rejectForm(new SubmissionError({}));
    }
  }

  if (automatedFunctionError) {
    yield put(
      automatedOperationsActionCreators.schedulesAFNotification(messages.ERROR_AF_NOT_RESPONDING),
    );
  }
}

export function* syncAutomatedFunction(action) {
  const {
    params: {
      automatedOperationId,
      lockVersion,
    },
  } = action.payload;

  const {
    scheduleIds: localScheduleIds,
    automatedFunctionId,
  } = yield getScheduleInfoFromStore(automatedOperationId);
  const logicalExpression = generateLogicalExpression(localScheduleIds);
  const expression = {
    id: automatedFunctionId,
    logicalExpression,
    lockVersion,
  };
  const expressionModel = new AutomatedFunctionModel(expression);
  const body = AutomatedFunctionModel.requestBody(expressionModel);
  const endpoint = endpoints.AUTOMATED_FUNCTION(automatedFunctionId);

  const { response, error } = yield call(putPath, endpoint, body);

  const params = {
    response,
    error,
    successDisp: automatedOperationsActionCreators.updateAutomatedFunctionSuccess,
    failDisp: automatedOperationsActionCreators.updateAutomatedFunctionFailure,
  };

  yield call(processCall, params);
}

export function* fetchSchedules(action) {
  const {
    endpoint, urlParams, nextInChain,
    stopChain, resourceType, automatedOperationId,
  } = action.payload;

  const { response, error } = yield call(getPath, endpoint, { urlParams });
  const enhancedResponse = response ? { ...response, automatedOperationId } : undefined;

  const params = {
    response: enhancedResponse,
    error,
    successDisp: automatedOperationsActionCreators.fetchSchedulesSuccess,
    failDisp: automatedOperationsActionCreators.fetchSchedulesFailure,
    nextInChain,
    stopChain,
    resourceType,
    automatedOperationId,
  };
  yield call(processCall, params);
}

export function* fetchAutomatedFunctions(action) {
  const {
    endpoint, urlParams, nextInChain,
    stopChain, resourceType, automatedOperationId,
  } = action.payload;

  const { response, error } = yield call(getPath, endpoint, { urlParams });
  const enhancedResponse = { ...response, automatedOperationId };

  const params = {
    response: enhancedResponse,
    error,
    successDisp: automatedOperationsActionCreators.fetchAutomatedFunctionsSuccess,
    failDisp: automatedOperationsActionCreators.fetchAutomatedFunctionsFailure,
    nextInChain,
    stopChain,
    resourceType,
    automatedOperationId,
  };

  yield call(processCall, params);
}

export function* removeSchedule(action) {
  const {
    endpoint,
    lockVersion,
    automatedOperationId,
  } = action.payload;
  const urlParams = {
    lockVersion,
  };
  const { response, error } = yield call(deletePath, endpoint, {}, { urlParams });

  const params = {
    response,
    error,
    successDisp: automatedOperationsActionCreators.removeScheduleSuccess,
    failDisp: automatedOperationsActionCreators.removeScheduleFailure,
  };

  yield call(processCall, params);

  if (response) {
    yield put(automatedOperationsActionCreators
      .fetchSchedules(automatedOperationId));
    yield call(updateOccurrencesWithDelay, automatedOperationId);
  }
}

export function* updateAutomatedFunctionRemoveSchedule(action) {
  const {
    id,
    lockVersion,
    automatedOperationId,
    passesPermissions,
  } = action.payload;

  if (passesPermissions) {
    const automatedFunctionEndpoint = endpoints.AUTOMATED_FUNCTIONS(automatedOperationId);

    const {
      response: { data: { attributes: { lockVersion: automatedFunctionLockVersion } } },
    } = yield call(getPath, automatedFunctionEndpoint);

    const currentSchedules = (yield select(schedulesSelector))(automatedOperationId);
    const { automatedFunctionId, schedulesList } = currentSchedules;
    const schedulesIds = schedulesList
      .filter(item => item.id !== id)
      .map(item => item.id);
    const automatedFunctionString = generateLogicalExpression(schedulesIds);

    const expression = {
      id: automatedFunctionId,
      logicalExpression: automatedFunctionString,
      lockVersion: automatedFunctionLockVersion,
    };

    const expressionModel = new AutomatedFunctionModel(expression);
    const body = AutomatedFunctionModel.requestBody(expressionModel);
    const endpoint = endpoints.AUTOMATED_FUNCTION(automatedFunctionId);

    const { response, error } = yield call(putPath, endpoint, body);

    const params = {
      response,
      error,
      successDisp: automatedOperationsActionCreators.updateAutomatedFunctionSuccess,
      failDisp: automatedOperationsActionCreators.updateAutomatedFunctionFailure,
    };
    yield call(processCall, params);

    if (response) {
      yield put(automatedOperationsActionCreators
        .removeSchedule(id, lockVersion, automatedOperationId));
    }
  } else {
    yield put(automatedOperationsActionCreators.removeSchedulePermissionFailed());
  }
}

export function* updateAutomatedFunction(action) {
  const {
    params: {
      id,
      logicalExpression,
      automatedOperationId,
      lockVersion,
      next,
    },
    endpoint,
  } = action.payload;
  const calculateNext = next + 1;
  const expression = {
    id,
    logicalExpression,
    lockVersion,
  };
  const expressionModel = new AutomatedFunctionModel(expression);
  const body = AutomatedFunctionModel.requestBody(expressionModel);

  const {
    response: putAutomatedOperationResponse,
    error: putAutomatedOperationError,
  } = yield call(putPath, endpoint, body);

  const params = {
    response: putAutomatedOperationResponse,
    error: putAutomatedOperationError,
    successDisp: automatedOperationsActionCreators.updateAutomatedFunctionSuccess,
    failDisp: automatedOperationsActionCreators.updateAutomatedFunctionFailure,
  };

  if (putAutomatedOperationResponse) {
    yield call(processCall, params);
  }

  if (putAutomatedOperationError && calculateNext < 3) {
    yield delay(1000);
    yield put(automatedOperationsActionCreators.fetchSchedules(automatedOperationId));

    const automatedFunctionsEndpoint = endpoints.AUTOMATED_FUNCTIONS(automatedOperationId);
    const {
      response: responseAutomatedFunctionsEndpoint,
      error: errorAutomatedFunctionsEndpoint,
    } = yield call(getPath, automatedFunctionsEndpoint);

    if (responseAutomatedFunctionsEndpoint) {
      yield delay(500);
      const {
        data: {
          attributes: { logicalExpression: newLogicalExpression, lockVersion: newLockVersion },
        },
      } = responseAutomatedFunctionsEndpoint;
      const {
        scheduleIds: localScheduleIds,
      } = yield getScheduleInfoFromStore(automatedOperationId);
      const serverSchedulesIds = yield extractScheduleIds(newLogicalExpression);
      const automatedFunctionString = generateLogicalExpression(localScheduleIds);

      if (isEqual(localScheduleIds.sort(), serverSchedulesIds.sort())) {
        yield put(
          automatedOperationsActionCreators.updateAutomatedFunction({
            id,
            logicalExpression: automatedFunctionString,
            automatedOperationId,
            lockVersion: newLockVersion,
            next: calculateNext,
          }),
        );
      } else {
        yield put(
          automatedOperationsActionCreators
            .schedulesAFNotification(messages.ERROR_SCHEDULES_CHANGED_CANCEL),
        );

        yield call(processCall, processCall);
      }
    }

    if (errorAutomatedFunctionsEndpoint) {
      yield put(
        automatedOperationsActionCreators.schedulesAFNotification(messages.ERROR_AF_NOT_RESPONDING),
      );
    }
  }
}

export function* removeAutomatedOperation(action) {
  const {
    id,
    endpoint,
    lockVersion,
  } = action.payload;
  const urlParams = {
    lockVersion,
  };

  const { response, error } = yield call(deletePath, endpoint, {}, { urlParams });

  const params = {
    response,
    error,
    successDisp: automatedOperationsActionCreators.removeAutomatedOperationSuccess,
    failDisp: automatedOperationsActionCreators.removeAutomatedOperationFailure,
  };
  yield call(processCall, params);

  if (response) {
    yield call(updateOccurrencesWithDelay, id);
  }
}

export function* updateAutomatedOperation(action) {
  const {
    params: {
      values,
      resolveForm,
      rejectForm,
    },
    endpoint,
  } = action.payload;
  const { id } = values;

  const body = AutomatedOperationModel.requestBody(values);

  const { response, error } = yield call(putPath, endpoint, body);

  const params = {
    response,
    error,
    resolveForm,
    rejectForm,
    successDisp: automatedOperationsActionCreators.updateAutomatedOperationSuccess,
    failDisp: automatedOperationsActionCreators.updateAutomatedOperationFailure,
  };

  yield call(processCall, params);

  if (response) {
    yield call(updateOccurrencesWithDelay, id);
  }
}

export function* updateSchedule(action) {
  const {
    params: {
      values: {
        automatedOperationId,
        ...values
      },
      rejectForm,
      resolveForm,
    },
    endpoint,
  } = action.payload;
  const data = new ScheduleModel(values);
  const parameters = prepareSchedulesParams(values);
  const scheduleWithParams = { ...data, params: parameters };
  const body = ScheduleModel.requestBody(scheduleWithParams);

  const { response, error } = yield call(putPath, endpoint, body);

  const params = {
    response,
    error,
    resolveForm,
    rejectForm,
    successDisp: automatedOperationsActionCreators.updateScheduleSuccess,
    failDisp: automatedOperationsActionCreators.updateScheduleFailure,
  };
  yield call(processCall, params);

  if (response) {
    yield put(automatedOperationsActionCreators.fetchSchedules(automatedOperationId));
    yield call(updateOccurrencesWithDelay, automatedOperationId);
  }
}

function* automatedOperationsSaga() {
  yield takeLatest(constants.ADD_AUTOMATED_OPERATION_REQUEST, addNewAutomatedOperation);
  yield takeLatest(constants.FETCH_AUTOMATED_OPERATION_REQUEST, fetchAutomatedOperation);
  yield takeLatest(constants.FETCH_AUTOMATED_OPERATIONS_REQUEST, fetchAutomatedOperations);
  yield takeLatest(constants.ADD_SCHEDULE_REQUEST, addNewSchedule);
  yield takeLatest(constants.FETCH_SCHEDULES_REQUEST, fetchSchedules);
  yield takeLatest(constants.FETCH_AUTOMATED_FUNCTIONS_REQUEST, fetchAutomatedFunctions);
  yield takeLatest(constants.REMOVE_SCHEDULE_REQUEST, removeSchedule);
  yield takeLatest(
    constants.UPDATE_AUTOMATED_FUNCTION_REMOVE_SCHEDULE_REQUEST,
    updateAutomatedFunctionRemoveSchedule,
  );
  yield takeLatest(constants.UPDATE_AUTOMATED_FUNCTION_REQUEST, updateAutomatedFunction);
  yield takeLatest(constants.REMOVE_AUTOMATED_OPERATION_REQUEST, removeAutomatedOperation);
  yield takeLatest(constants.UPDATE_AUTOMATED_OPERATION_REQUEST, updateAutomatedOperation);
  yield takeLatest(constants.UPDATE_SCHEDULE_REQUEST, updateSchedule);
  yield takeLatest(constants.SYNC_AUTOMATED_FUNCTION_REQUEST, syncAutomatedFunction);
}

export default automatedOperationsSaga;
