import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  permissionsStateSelector,
} from 'helpers/selectors';
import { permissionsActionCreators } from '../../../../../utilities';
import AddProjectButton from './AddProjectButton';

const mapStateToProps = state => {
  const { selectingProjects } = permissionsStateSelector(state);

  return {
    selectingProjects,
  };
};

const mapDispatchToProps = {
  onToggleSelectingProjects: permissionsActionCreators.toggleSelectingProjects,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(AddProjectButton);
