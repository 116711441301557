import { createSelector } from 'reselect';
import build from 'redux-object';

import { PROJECTS_FOR_MAP_FIELD, dataSelector, options } from 'helpers/selectors';

const projectsForMapSelector = createSelector(
  dataSelector,
  data => build(data, PROJECTS_FOR_MAP_FIELD, null, options),
);

export default projectsForMapSelector;
