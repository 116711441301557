import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const revokeMultiplePermissions = (data = {}) => ({
  type: constants.REVOKE_MULTIPLE_PERMISSIONS_REQUEST,
  payload: {
    endpoint: endpoints.REVOKE_PERMISSION,
    data,
  },
});

export const revokeMultiplePermissionsSuccess = response => ({
  type: constants.REVOKE_MULTIPLE_PERMISSIONS_SUCCESS,
  payload: {
    response,
  },
});

export const revokeMultiplePermissionsFailure = error => ({
  type: constants.REVOKE_MULTIPLE_PERMISSIONS_FAILED,
  payload: { error },
});

export default revokeMultiplePermissions;
