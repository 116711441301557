import { compose } from 'redux';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { withRouter } from 'react-router-dom';

import { actionCreators } from 'application/tenant/components/structural/ControlUI/components/ControlUIPageCarousel/components/ControlUIPage/components/ControlUIButtonGroup/ControlUIButton/components/utilities';
import ControlAppearanceStep from './ControlsAppearanceStep';

const mapDispatchToProps = dispatch => ({
  onControlEnabledChange: (formName, value) => dispatch(change(formName, 'enabled', value)),
  onShowIntensitySliderChange: (formName, value) => dispatch(change(formName, 'showIntensitySlider', value)),
  onShowColorTempSliderChange: (formName, value) => dispatch(change(formName, 'showColorTempSlider', value)),
  onLabelTextChange: (formName, value) => dispatch(change(formName, 'labelText', value)),
  onLabelTextColorChange: (formName, value) => dispatch(change(formName, 'labelTextColor', value)),
  onRunButtonTextChange: (formName, value) => dispatch(change(formName, 'runButtonText', value)),
  onRunButtonTextColorChange: (formName, value) => dispatch(change(formName, 'runButtonTextColor', value)),
  onRunButtonBgColorChange: (formName, value) => dispatch(change(formName, 'runButtonBgColor', value)),
  onClearCopy: () => dispatch(actionCreators.clearCopy()),
});

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(ControlAppearanceStep);
