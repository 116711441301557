import React, { cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import { extractFileExtension } from 'helpers/sharedMethods';
import NewUpload from 'components/NewUpload/NewUploadContainer';

export const DELAY = 250;

class UploadsContextProvider extends Component {

  static renderDropzone(item, trigger, controlProps) {
    const { deviceId, systemFile } = item;
    const fileExtension = extractFileExtension(systemFile);

    return (
      <NewUpload
        deviceId={deviceId}
        resume={item}
        trigger={cloneElement(trigger, { ...controlProps })}
        accept={fileExtension}
      />
    );
  }

  static renderControl(item, control, dropzoneTrigger, controlProps) {
    const { accessKeyId } = item;

    return accessKeyId
      ? cloneElement(control, { ...controlProps })
      : UploadsContextProvider.renderDropzone(item, dropzoneTrigger, controlProps);
  }

  constructor() {
    super();

    this.handleCancel = debounce(this.handleCancel.bind(this), DELAY);
    this.handleForce = debounce(this.handleForce.bind(this), DELAY);
    this.handlePause = debounce(this.handlePause.bind(this), DELAY);
    this.handleResume = debounce(this.handleResume.bind(this), DELAY);
    this.handleSuspend = debounce(this.handleSuspend.bind(this), DELAY);
    this.handleContinue = debounce(this.handleContinue.bind(this), DELAY);
  }

  getChildContext() {
    return {
      forceUpload: this.handleForce,
      cancelUpload: this.handleCancel,
      pauseUpload: this.handlePause,
      resumeUpload: this.handleResume,
      suspendUpload: this.handleSuspend,
      continueUpload: this.handleContinue,
      renderControl: UploadsContextProvider.renderControl,
    };
  }

  handleCancel({ fileKey }) {
    const { onCancelUpload } = this.props;

    onCancelUpload(fileKey);
  }

  handleForce({ fileKey }) {
    const { onForceUpload } = this.props;

    onForceUpload(fileKey);
  }

  handlePause({ fileKey }) {
    const { onPauseUpload } = this.props;

    onPauseUpload(fileKey);
  }

  handleResume({ fileKey, systemFile }) {
    const { onEnqueueAtEnd } = this.props;

    onEnqueueAtEnd([{ fileKey, systemFile }]);
  }

  handleSuspend() {
    const { onSuspendUpload } = this.props;

    onSuspendUpload();
  }

  handleContinue() {
    const { onContinueUpload } = this.props;

    onContinueUpload();
  }

  render() {
    const { children } = this.props;

    return children;
  }

}

UploadsContextProvider.childContextTypes = {
  cancelUpload: PropTypes.func,
  continueUpload: PropTypes.func,
  forceUpload: PropTypes.func,
  pauseUpload: PropTypes.func,
  renderControl: PropTypes.func,
  resumeUpload: PropTypes.func,
  suspendUpload: PropTypes.func,
};

UploadsContextProvider.defaultProps = {
  children: null,
};

UploadsContextProvider.propTypes = {
  children: PropTypes.element,
  onCancelUpload: PropTypes.func.isRequired,
  onContinueUpload: PropTypes.func.isRequired,
  onEnqueueAtEnd: PropTypes.func.isRequired,
  onForceUpload: PropTypes.func.isRequired,
  onPauseUpload: PropTypes.func.isRequired,
  onSuspendUpload: PropTypes.func.isRequired,
};

export default UploadsContextProvider;
