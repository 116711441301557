import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import { ControlUI } from 'application/tenant/components';

const ProjectControlUITab = ({ projectName }) => {
  if (!projectName) return null;

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <ControlUI configure />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

ProjectControlUITab.defaultProps = {
  projectName: null,
};

ProjectControlUITab.propTypes = {
  projectName: PropTypes.string,
};

export default ProjectControlUITab;
