import styled from 'styled-components';
import { Input, Dropdown } from 'semantic-ui-react';

export const StyledInput = styled(Input)`
  margin-right: 20px; 
  margin-bottom: 10px;
`;

export const StyledDropdown = styled(Dropdown)`
  margin-right: 20px;
  margin-bottom: 10px;
`;

export const StyledDiv = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;
