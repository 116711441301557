import { connect } from 'react-redux';

import { actionCreators } from './utilities';
import CopyButton from './CopyButton';

const mapDispatchToProps = {
  onCopyToClipboard: actionCreators.copyToClipboard,
};

export default connect(null, mapDispatchToProps)(CopyButton);
