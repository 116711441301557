import React from 'react';

import LogsSection from 'components/LogsSection/LogsSectionContainer';

export const pathwayLogLevelOptions = [
  'Normal',
].map(
  (level, index) => ({
    key: `${index}`,
    value: index,
    text: level,
  }),
);

const PathwayLogsV1 = props => (
  <LogsSection
    {...props}
    logLevelSelectOptions={pathwayLogLevelOptions}
  />
);

export default PathwayLogsV1;
