import isNull from 'lodash/isNull';
import replace from 'lodash/replace';

import { capitalizeFirstLetter } from 'helpers/sharedMethods';

const renderTitle = startMethodData => {

  if (isNull(startMethodData)) {
    return null;
  }

  const removeUnderline = replace(startMethodData, /_/g, ' ');

  return capitalizeFirstLetter(removeUnderline);
};

export default renderTitle;
