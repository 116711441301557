import styled, { css } from 'styled-components';
import { darken } from 'polished';
import get from 'lodash/get';

import { Button } from 'components/atomic';
import filterProps from 'helpers/filterProps';

const FilteredProps = filterProps(['canCopy'])(Button);

const StyledTrigger = styled(FilteredProps)`
  &.ui.button {
    border-radius: 0;
    width: 40px;
    height: 40px;
    padding: 1px 7px 2px;
    margin-bottom: 5px;
    margin-right: 5px;
    background-color: white;
    font-weight: 600;
    flex-shrink: 0;

    ${({ canCopy, theme }) => canCopy && css`
      border-radius: 50%;
      border: ${darken(0.2, get(theme, 'palette.primaryColor'))} solid 2px;
    `}
  }
`;

export default StyledTrigger;
