import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Modal } from 'semantic-ui-react';

import { Button } from 'components/atomic';

import { StyledAddUsersButton } from './AddUsersModal.style';
import UserTable from './UserTable/UserTableContainer';

function AddUsersModal({
  resourceId,
  resourceType,
}) {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const trigger = (
    <StyledAddUsersButton onClick={handleOpen}>
      Manage users...
    </StyledAddUsersButton>
  );

  return (
    <Modal
      open={open}
      trigger={trigger}
      onClose={handleClose}
    >
      <Modal.Content scrolling>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16}>
              {open && (
                <UserTable
                  resourceId={resourceId}
                  resourceType={resourceType}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          basic
          onClick={handleClose}
        >
          Done
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

AddUsersModal.propTypes = {
  resourceId: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
};

export default AddUsersModal;
