import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';

import 'semantic-ui-css/semantic.min.css';
import 'react-widgets/dist/css/react-widgets.css';

import 'assets/styles/icomoon.css';
import GlobalStyle from 'themes/grid';

import 'polyfill';
import 'raven';
import constants from 'dispatcherConst';
import appStore from './appStore';
import AppContainer from './AppContainer';
import * as serviceWorker from './serviceWorker';
import ThemeProvider from './components/ThemeProvider/ThemeProviderContainer';

moment.locale('en');
momentLocalizer();

const entryId = 'root';
const appEntry = document.getElementById(entryId);

ReactDOM.render(
  <Provider store={appStore}>
    <ThemeProvider>
      <Router>
        <AppContainer />
        <GlobalStyle />
      </Router>
    </ThemeProvider>
  </Provider>,
  appEntry,
);

serviceWorker.register({
  onUpdate: registration => appStore.dispatch({
    type: constants.SERVICE_WORKER_UPDATE, payload: registration,
  }),
  onSuccess: () => appStore.dispatch({
    type: constants.SERVICE_WORKER_INIT,
  }),
});
