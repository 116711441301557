const extractKeyValues = (arr, keys) => {
  const values = [];
  if (Array.isArray(arr) && keys.length === 2) {
    arr.forEach(item => {
      if (item[keys[0]][keys[1]]) {
        values.push(item[keys[0]][keys[1]]);
      }
    });
  }

  if (Array.isArray(arr) && keys.length === 3) {
    arr.forEach(item => {
      if (item[keys[0]] && item[keys[0]][keys[1]] && item[keys[0]][keys[1]][keys[2]]) {
        values.push(item[keys[0]][keys[1]][keys[2]]);
      }
    });
  }

  const uniqueValues = [...new Set(values)];

  return uniqueValues.map((item, index) => ({
    id: index,
    text: item,
    value: item,
  }));
};

export default extractKeyValues;
