import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import { PermissionsSwitch, Gateway, frontendPermissions } from 'gateway';
import DataTableText from 'components/DataTable/DataTableText/DataTableText';

import { StyledTableRow } from '../../../../AutomatedOperationsAccordionRow.style';
import { DeleteSchedule, DeleteScheduleProjectSuite } from '..';
import { AddAutomatedOperation } from '../../../../..';
import { TASK_SCHEDULER_MODE } from '../../../../../AddAutomatedOperation/constants';
import ProjectSuiteContext from '../../../../../ProjectSuiteContext/ProjectSuiteContext';

const ScheduleCustomRow = props => {
  const { item, automatedOperationId, disableDelete, toggleDeleteButtons } = props;
  const { item: { id } } = props;
  const isMultiSite = useContext(ProjectSuiteContext);
  const resourceType = isMultiSite ? 'projectSuite' : 'project';

  const deletePermission = useMemo(() => {
    if (isMultiSite) {
      return frontendPermissions.PROJECT_SUITE_SCHEDULES_DELETE;
    }

    return frontendPermissions.DELETE_SCHEDULE;
  }, [isMultiSite]);

  const DeleteScheduleComponent = deleteScheduleProps => {
    if (isMultiSite) {
      return <DeleteScheduleProjectSuite {...deleteScheduleProps} />;
    }

    return <DeleteSchedule {...deleteScheduleProps} />;
  };

  return (
    <StyledTableRow
      {...props}
    >
      <DataTableText />
      <DataTableText />
      <div>
        <PermissionsSwitch>
          <Gateway condition={deletePermission}>
            <DeleteScheduleComponent
              item={item}
              automatedOperationId={automatedOperationId}
              disableDelete={disableDelete}
              toggleDeleteButtons={toggleDeleteButtons}
            />
          </Gateway>
        </PermissionsSwitch>
        <AddAutomatedOperation
          mode={TASK_SCHEDULER_MODE.EDIT_SCHEDULER}
          scheduleId={id}
          automatedOperationId={automatedOperationId}
          resourceType={resourceType}
        />
      </div>
    </StyledTableRow>
  );
};

ScheduleCustomRow.defaultProps = {
  toggleDeleteButtons: null,
};

ScheduleCustomRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
  }).isRequired,
  automatedOperationId: PropTypes.string.isRequired,
  toggleDeleteButtons: PropTypes.func,
  disableDelete: PropTypes.bool.isRequired,
};

export default ScheduleCustomRow;
