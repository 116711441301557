import styled, { css } from 'styled-components';
import { Breadcrumb } from 'semantic-ui-react';
import get from 'lodash/get';

import colors from 'themes/colors';
import { injectStyles, mediaOnly } from 'themes/mixins';

export const ThemedBreadcrumbWrapper = styled.div`
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  margin-bottom: 2em;
`;

export const ThemedBreadcrumb = styled(Breadcrumb)`
  ${({ theme }) => injectStyles(theme.breadcrumbNav, css`
    &.ui.breadcrumb a {
      color: ${get(theme, 'palette.primaryColor')};
    }
  `)};
  
  &.ui.breadcrumb {
    display: inline-flex;
    max-width: 80%;
    
    .divider, .section {
      line-height: 40px;
    }
    
    .divider {
      flex-shrink: 0;
      font-size: 1em;
      color: ${colors.black};
      
      ${mediaOnly.xs`
        width: 1em;
        margin: 0;
        text-align: left;
      `};
    }
    
    .section, .section > * {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    
    .section.active {
      font-weight: normal;
    }
  }
`;
