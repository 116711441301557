import {
  resetPassword,
  resetPasswordSuccess,
} from './resetPassword';
import {
  verifyPasswordResetToken,
  verifyPasswordResetTokenSuccess,
} from './verifyPasswordResetToken';

export default {
  resetPassword,
  resetPasswordSuccess,
  verifyPasswordResetToken,
  verifyPasswordResetTokenSuccess,
};
