import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/atomic';
import { CheckboxFilter } from 'components/core';

import { transformToCheckboxFilterValues, getDefaultNotificationFilters } from '../../utilities';

function NotificationsFilter({
  notificationEvents,
  notificationFilters,
  onTriggerNotificationFilters,
}) {
  const defaultFilters = useMemo(() => {
    if (notificationFilters) {
      return notificationFilters;
    }

    const filters = getDefaultNotificationFilters(notificationEvents);

    return filters;
  }, [notificationEvents, notificationFilters]);

  const trigger = useMemo(() => {
    const filters = notificationFilters || defaultFilters;
    const label = ((!notificationFilters && defaultFilters) || Object.values(filters).length === 0) ? 'All' : Object.values(filters).length;

    return (
      <Button basic name="teste">
        Filters ({label})
      </Button>
    );
  }, [defaultFilters, notificationFilters]);

  const items = useMemo(() => {
    const transformed = transformToCheckboxFilterValues(notificationEvents);

    return transformed;
  }, [notificationEvents]);

  const handleChange = useCallback(selectedItems => {
    if (!selectedItems) {
      return;
    }

    let additionalFilters = {};

    additionalFilters = Object.values(selectedItems)
      .reduce((acc, curr) => {
        acc[curr.filterField] = curr.key;

        return acc;
      }, {});

    onTriggerNotificationFilters({
      notificationFilters: selectedItems,
      additionalFilters,
    });
  }, [onTriggerNotificationFilters]);

  return (
    <CheckboxFilter
      items={items}
      selectedItems={notificationFilters}
      trigger={trigger}
      onChange={handleChange}
      position="bottom left"
      type="radio"
    />
  );
}

NotificationsFilter.defaultProps = {
  notificationEvents: [],
  notificationFilters: null,
};

NotificationsFilter.propTypes = {
  notificationEvents: PropTypes.array,
  notificationFilters: PropTypes.object,
  onTriggerNotificationFilters: PropTypes.func.isRequired,
};

export default NotificationsFilter;
