import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Tab } from 'semantic-ui-react';

import TimeInput from 'components/core/TimeInput/TimeInput';

import { StyledButton } from './SimpleView.style';

export default function SimpleView({ selectedTimes, single, onTimeChange }) {
  const [value, onChange] = useState(null);

  const handleTimeSelected = useCallback(() => {
    if (!value) {
      return;
    }

    if (single) {
      onTimeChange([value]);
    } else {
      onTimeChange([...new Set([...selectedTimes, value])]);
    }

  }, [selectedTimes, single, value, onTimeChange]);

  const handleKeyPress = useCallback(e => {
    if (e.key === 'Enter') {
      handleTimeSelected();
    }
  }, [handleTimeSelected]);

  return (
    <Grid as={Tab.Pane}>
      <Grid.Row>
        <Grid.Column width={16}>
          <TimeInput
            autoFocus={false}
            value={value}
            onChange={onChange}
            onKeyPress={handleKeyPress}
            name="simple-view-time-input"
            fullWidth
          />
        </Grid.Column>
        <Grid.Column width={16}>
          <StyledButton icon primary className="btn-confirm-simple-view" onClick={handleTimeSelected}>
            Add
          </StyledButton>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

SimpleView.defaultProps = {
  selectedTimes: [],
  single: false,
};

SimpleView.propTypes = {
  selectedTimes: PropTypes.arrayOf(PropTypes.string),
  single: PropTypes.bool,
  onTimeChange: PropTypes.func.isRequired,
};
