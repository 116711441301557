import { reduxForm } from 'redux-form';

import formNames from 'components/form/formNames';
import { validateOnSubmit } from 'components/form/formConfigs';
import SignUpForm from './SignUpForm';

export default reduxForm({
  form: formNames.signUp,
  ...validateOnSubmit,
})(SignUpForm);
