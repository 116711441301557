import constants from 'dispatcherConst';

const tablesActionCreators = {
  storeTableName: (resourceType, name) => ({
    type: constants.STORE_TABLE_NAME,
    payload: {
      resourceType,
      name,
    },
  }),
};

export default tablesActionCreators;
