import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { DropdownArrow } from 'components/elements';
import {
  StyledPermissionsTreeItem,
  StyledHeader,
  StyledTitle,
  StyledChildrenWrapper,
} from './PermissionsTreeItem.style';

const PermissionsTreeItem = ({
  resourceId,
  path,
  open,
  label,
  paths,
  onToggleOpen,
  PermissionsTreeItemContainer,
}) => (
  <StyledPermissionsTreeItem>
    <StyledHeader onClick={() => paths && onToggleOpen(resourceId, path)}>
      {!isEmpty(paths) && <DropdownArrow open={open} />}
      <StyledTitle>{label}</StyledTitle>
    </StyledHeader>
    <StyledChildrenWrapper>
      {open && paths.map(childPath => (
        <PermissionsTreeItemContainer
          key={childPath}
          resourceId={resourceId}
          path={`${path}.${childPath}`}
        />
      ))}
    </StyledChildrenWrapper>
  </StyledPermissionsTreeItem>
);

PermissionsTreeItem.propTypes = {
  resourceId: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  paths: PropTypes.array.isRequired,
  onToggleOpen: PropTypes.func.isRequired,
  PermissionsTreeItemContainer: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
};

export default PermissionsTreeItem;
