import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, propTypes } from 'redux-form';

import ToggleField from 'components/form/ToggleField/ToggleField';

export const DELAY = 2000;

class ToggleFieldForm extends Component {

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      submitFailed: nextSubmitFailed,
    } = nextProps;
    const {
      initialize,
      initialValues,
      submitFailed,
    } = this.props;

    if (!submitFailed && nextSubmitFailed) {
      this.timeout = setTimeout(
        () => initialize(initialValues),
        DELAY,
      );
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    const { initialValues, name, handleSubmit, preventPropagation } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Field
          name={name}
          component={ToggleField}
          onChangeSubmit={handleSubmit}
          initialValue={initialValues[name]}
          preventPropagation={preventPropagation}
        />
      </form>
    );
  }

}

ToggleFieldForm.defaultProps = {
  initialValues: {},
  preventPropagation: false,
};

ToggleFieldForm.propTypes = {
  ...propTypes,
  initialValues: PropTypes.object,
  name: PropTypes.string.isRequired,
  preventPropagation: PropTypes.bool,
};

export default ToggleFieldForm;
