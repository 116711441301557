import styled from 'styled-components';
import { Header } from 'semantic-ui-react';

import { media } from 'themes/mixins';

const StyledPublicHeader = styled(Header)`
  &.ui.header {
    ${media.md`margin-bottom: 2.5rem;`};
  }
`;

export default StyledPublicHeader;
