import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import classNames from 'classnames';
import includes from 'lodash/includes';

import { fileTypesMapType } from 'data/types';
import { matchExtension, buildObjectURL } from 'helpers/sharedMethods';
import FormField from '../FormField/FormField';
import StyledDropzone, { StyledThumbnail } from './DropzoneField.style';

class DropzoneField extends Component {

  constructor() {
    super();

    this.handleDrop = this.handleDrop.bind(this);
    this.handleStartProcessing = this.handleStartProcessing.bind(this);
    this.handleStopProcessing = this.handleStopProcessing.bind(this);
  }

  groupFiles(acceptedFiles, rejectedFiles) {
    const { fileTypes } = this.props;
    if (!fileTypes) {
      return {
        supportedFiles: acceptedFiles,
        nonSupportedFiles: rejectedFiles,
      };
    }

    const nonSupportedFiles = [...rejectedFiles];
    const supportedFiles = [];

    const extensions = Object.keys(fileTypes);
    acceptedFiles.forEach(file => {
      if (matchExtension(file.name, extensions, { ignoreCase: true })) {
        supportedFiles.push(file);
        return;
      }

      nonSupportedFiles.push(file);
    });

    return {
      supportedFiles,
      nonSupportedFiles,
    };
  }

  handleDrop(acceptedFiles, rejectedFiles) {
    const {
      input: { onChange },
      onChangeFileStatus,
      onFilesNotSupported,
    } = this.props;

    const {
      supportedFiles,
      nonSupportedFiles,
    } = this.groupFiles(acceptedFiles, rejectedFiles);

    if (nonSupportedFiles.length) {
      onFilesNotSupported(supportedFiles.length);
    }
    onChange(supportedFiles);
    onChangeFileStatus(false);
  }

  handleStartProcessing() {
    const { onChangeFileStatus } = this.props;

    onChangeFileStatus(true);
  }

  handleStopProcessing() {
    const { onChangeFileStatus } = this.props;

    onChangeFileStatus(false);
  }

  render() {
    const {
      input: { name, value },
      input,
      meta: { error, touched },
      meta,
      label,
      multiple,
      accept,
      onChangeFileStatus,
      ...rest
    } = this.props;

    const showError = !!error && touched;
    const thumbnailUrl = (
      value
      && value.length
      && value.length === 1
      && includes(value[0].type, 'image')
      && buildObjectURL(value[0])
    );

    return (
      <FormField {...rest} meta={meta} input={input}>
        <StyledDropzone
          className={classNames({ error: showError })}
          label={label}
          name={name}
          onDrop={this.handleDrop}
          onDragEnter={this.handleStartProcessing}
          onDragLeave={this.handleStopProcessing}
          onClick={this.handleStartProcessing}
          onFileDialogCancel={this.handleStopProcessing}
          multiple={multiple}
          accept={accept}
          disablePreview
        >
          <StyledDropzone.Content>
            {thumbnailUrl
              ? <StyledThumbnail src={thumbnailUrl} />
              : (
                <Fragment>
                  <p>Drag and drop or click here to upload files</p>
                  <span>The following file types are supported:</span>
                  <ul>
                    {accept.split(', ').map(fileType => (
                      <li key={fileType}>{fileType}</li>
                    ))}
                  </ul>
                </Fragment>
              )
            }
          </StyledDropzone.Content>
        </StyledDropzone>
      </FormField>
    );
  }

}

DropzoneField.defaultProps = {
  accept: '',
  fileTypes: null,
  multiple: true,
};

DropzoneField.propTypes = {
  ...Input.propTypes,
  accept: PropTypes.string,
  fileTypes: fileTypesMapType,
  multiple: PropTypes.bool,
  onChangeFileStatus: PropTypes.func.isRequired,
  onFilesNotSupported: PropTypes.func.isRequired,
};

export default DropzoneField;
