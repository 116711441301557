import { createSelector } from 'reselect';
import build from 'redux-object';

import { PATCH_FIELD, dataSelector, options } from 'helpers/selectors';
import PatchModel from 'models/PatchModel';

const patchesSelector = createSelector(
  dataSelector,
  data => {
    const patches = build(data, PATCH_FIELD, null, options);

    if (!patches) {
      return null;
    }

    return patches.map(patch => new PatchModel(patch));
  },
);

export default patchesSelector;
