import { createSelector } from 'reselect';

import schedulingSelector from './schedulingSelector';

const fetchingOccurrencesSelector = createSelector(
  schedulingSelector,
  scheduling => scheduling.isFetchingOccurrences,
);

export default fetchingOccurrencesSelector;
