import { reduxForm } from 'redux-form';

import formNames from 'components/form/formNames';
import { validateOnSubmit } from 'components/form/formConfigs';
import InviteUserModalForm from './InviteUserModalForm';

export default reduxForm({
  form: formNames.inviteUser,
  ...validateOnSubmit,
})(InviteUserModalForm);
