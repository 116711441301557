import constants from 'dispatcherConst';
import { messages } from 'data/notifications/notificationsConst';

const removeSchedulePermissionFailed = () => ({
  type: constants.REMOVE_SCHEDULE_PERMISSION_FAILED,
  payload: {
    message: messages.REMOVE_SCHEDULE_PERMISSION_FAILED,
  },
});

export default removeSchedulePermissionFailed;
