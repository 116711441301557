import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { NOTIFICATION_TYPES_FIELD, NOTIFICATIONS_FIELD } from 'helpers/selectors';

import { endpoints as notificationEndpoints } from './notificationConsts';

const messages = {
  NO_NOTIFICATION_TYPES: 'No notification types avilable',
};

const notificationTypesSectionActionCreators = {
  fetchNotificationTypes: (urlParams = {}) => ({
    type: constants.FETCH_NOTIFICATION_TYPES_REQUEST,
    payload: {
      endpoint: endpoints.NOTIFICATION_TYPES,
      urlParams,
    },
  }),

  fetchNotificationTypesFailure: context => error => ({
    type: constants.FETCH_NOTIFICATION_TYPES_FAILED,
    payload: {
      context,
      info: error.notFound && messages.NO_NOTIFICATION_TYPES,
      error: !error.notFound && error,
      resourceType: NOTIFICATION_TYPES_FIELD,
    },
  }),

  fetchNotificationTypesSuccess: context => response => ({
    type: constants.FETCH_NOTIFICATION_TYPES_SUCCESS,
    payload: {
      context,
      response,
    },
  }),

  optInNotification: params => ({
    type: constants.OPT_IN_NOTIFICATION_REQUEST,
    payload: { params },
  }),

  optInNotificationSuccess: response => ({
    type: constants.OPT_IN_NOTIFICATION_SUCCESS,
    payload: { response },
  }),

  optInNotificationFailure: error => ({
    type: constants.OPT_IN_NOTIFICATION_FAILED,
    payload: {
      error,
      resourceType: NOTIFICATIONS_FIELD,
    },
  }),

  optOutNotification: params => ({
    type: constants.OPT_OUT_NOTIFICATION_REQUEST,
    payload: { params },
  }),

  optOutNotificationSuccess: notificationId => response => ({
    type: constants.OPT_OUT_NOTIFICATION_SUCCESS,
    payload: { notificationId, response },
  }),

  optOutNotificationFailure: error => ({
    type: constants.OPT_OUT_NOTIFICATION_FAILED,
    payload: {
      error,
    },
  }),

  editNotification: params => ({
    type: constants.EDIT_NOTIFICATION_REQUEST,
    payload: { params },
  }),

  editNotificationSuccess: notificationId => response => ({
    type: constants.EDIT_NOTIFICATION_SUCCESS,
    payload: {
      notificationId,
      response,
    },
  }),

  editNotificationFailure: error => ({
    type: constants.EDIT_NOTIFICATION_FAILED,
    payload: {
      error,
    },
  }),

  fetchNotifications: (context, entityId, urlParams = {}) => ({
    type: constants.FETCH_NOTIFICATIONS,
    payload: {
      endpoint: notificationEndpoints[context](entityId),
      urlParams,
    },
  }),

  fetchNotificationsSuccess: response => ({
    type: constants.FETCH_NOTIFICATIONS_SUCCESS,
    payload: {
      response,
    },
  }),

  fetchNotificationsFailure: error => ({
    type: constants.FETCH_NOTIFICATIONS_FAILED,
    payload: {
      error,
      resourceType: NOTIFICATIONS_FIELD,
    },
  }),

  fetchResourcesWithNotifications: config => ({
    type: constants.FETCH_RESOURCES_WITH_NOTIFICATIONS,
    payload: {
      config,
    },
  }),
};

export default notificationTypesSectionActionCreators;
