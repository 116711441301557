import styled from 'styled-components';
import { Header, Label } from 'semantic-ui-react';

export const StyledHeader = styled(Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;


export const StyledLabel = styled(Label)`
  display: block;
`;

export default StyledHeader;
