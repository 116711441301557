import { put, select, take, race, delay } from 'redux-saga/effects';
import isNull from 'lodash/isNull';

import constants from 'dispatcherConst';
import { singleProjectSuiteSelector } from 'helpers/selectors';
import { actionCreators } from 'pages/ProjectSuites/utilities';

export const progressiveTimings = [1, 5, 10, 30];

function* updateProjectSuiteLocationProperties(action) {
  const {
    response: {
      data: {
        id: projectSuiteId,
      },
    },
  } = action.payload;

  let error;
  let locationSet;
  let geoAddress;
  let timezoneId;
  let count = 0;

  while (true) {
    ({
      locationSet,
      geoAddress,
      timezoneId,
    } = yield select(singleProjectSuiteSelector, projectSuiteId));
    const shouldPoll = locationSet && (
      isNull(geoAddress)
      || isNull(timezoneId)
    );

    if (!shouldPoll) {
      break;
    }

    if (error) {
      yield put(actionCreators.unableToFetchProjectSuiteLocation(projectSuiteId));
      break;
    }

    yield delay(progressiveTimings[count] * 1000);
    yield put(actionCreators.fetchProjectSuite(projectSuiteId));

    ({ error } = yield race({
      error: take(constants.FETCH_PROJECT_SUITE_FAILED),
      success: take(constants.FETCH_PROJECT_SUITE_SUCCESS),
    }));

    count += 1;
    error = error || count === progressiveTimings.length;
  }
}

export default updateProjectSuiteLocationProperties;
