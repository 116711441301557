import React from 'react';
import PropTypes from 'prop-types';

import formNames from 'components/form/formNames';
import { messages } from 'data/notifications/notificationsConst';

import { StyledEditNotes } from './EditNotes.style';

function EditNotes({ projectSuiteId, notes, onUpdateProjectSuite }) {
  const name = 'notes';
  const form = `${formNames.changeProjectSuiteProperty}.${name}`;

  const handleNotesChange = values => new Promise(
    (resolveForm, rejectForm) => {
      onUpdateProjectSuite({
        values: {
          id: projectSuiteId,
          ...values,
        },
        message: messages.PROJECT_SUITE_NOTES_UPDATED,
        resolveForm,
        rejectForm,
      });
    });

  return (
    <StyledEditNotes
      type="text"
      name={name}
      form={form}
      value={notes}
      onSubmit={handleNotesChange}
      allowCancelOnPristine
    />
  );
}

EditNotes.defaultProps = {
  notes: undefined,
};

EditNotes.propTypes = {
  projectSuiteId: PropTypes.string.isRequired,
  onUpdateProjectSuite: PropTypes.func.isRequired,
  notes: PropTypes.string,
};

export default EditNotes;
