import React, { Fragment, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { attachId } from 'helpers/layoutSwitchHelpers';
import { StyledInlineEditTitle } from 'components/elements/StyledInlineEditTitle/StyledInlineEditTitle.style';
import { HeaderWrapper } from 'components/elements';
import TabbedLayout from 'components/TabbedLayout/TabbedLayout';
import { validDisplayName } from 'components/form/formValidators';
import siteActionCreators from 'application/tenant/console/site/utilities/actionCreators';
import { SitePropertyInlineEdit } from 'application/tenant/console/site/components';

import projectTabs from './projectTabs';
import { RenderControlPanelButton } from './components';
import { Container } from './Project.style';
import { setTabVisibility } from './utilities';
import { ACTIVITY_TAB } from './constants';

const tabsWithIds = attachId(projectTabs);

const Project = ({
  siteName,
  invitations,
  isConstruction,
  isSuspended,
  stateLoaded,
  onDeleteInvitation: deleteInvitationCallback,
  superadminOrProjectOwner,
}) => {
  const onDeleteInvitation = useCallback(deleteInvitationCallback, []);

  useEffect(() => {
    if (invitations) {
      invitations.forEach(({ id }) => onDeleteInvitation(id));
    }
  }, [invitations, onDeleteInvitation]);

  const tabsList = setTabVisibility(tabsWithIds, ACTIVITY_TAB, superadminOrProjectOwner);

  return (
    <Fragment>
      <HeaderWrapper>
        <Container>
          <StyledInlineEditTitle size="huge">
            {siteName && (
              <SitePropertyInlineEdit
                property="name"
                onChange={siteActionCreators.updateSiteName}
                validator={validDisplayName}
              />
            )}
          </StyledInlineEditTitle>
        </Container>
        <RenderControlPanelButton
          isConstruction={isConstruction}
          isSuspended={isSuspended}
          stateLoaded={stateLoaded}
        />
      </HeaderWrapper>
      <TabbedLayout panes={tabsList} />
    </Fragment>
  );
};

Project.defaultProps = {
  siteName: null,
  invitations: null,
  superadminOrProjectOwner: false,
};

Project.propTypes = {
  siteName: PropTypes.string,
  invitations: PropTypes.array,
  onDeleteInvitation: PropTypes.func.isRequired,
  isConstruction: PropTypes.bool.isRequired,
  isSuspended: PropTypes.bool.isRequired,
  stateLoaded: PropTypes.bool.isRequired,
  superadminOrProjectOwner: PropTypes.bool,
};

export default Project;
