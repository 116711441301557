import { call } from 'redux-saga/effects';

import { messages } from 'data/notifications/notificationsConst';
import { deletePath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import actionCreators from '../actionCreators';

function* deleteOrigin(action) {
  const { companyId, endpoint, origin } = action.payload;
  let { response, error } = yield call(deletePath, endpoint);

  response = response ? { companyId, origin } : undefined;
  error = error
    ? new Error(messages.ERROR_DELETE_ORIGIN(origin.name))
    : undefined;

  const params = {
    response,
    error,
    successDisp: actionCreators.deleteOriginSuccess,
    failDisp: actionCreators.deleteOriginFailure,
  };
  yield call(processCall, params);
}

export default deleteOrigin;
