import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import RefreshInterface from 'components/RefreshInterface/RefreshInterfaceContainer';
import { IO_MODULES_INSTANCES_FIELD } from 'helpers/selectors';
import DataTable from 'components/DataTable/DataTableContainer';
import IoModuleAccordionRow from './IoModuleAccordion/IoModuleAccordionRow';

const labels = [
  { text: 'Module', field: 'moduleTitle', sortable: false },
  { text: 'Instance (Name)', field: 'title', sortBy: 'title' },
];

const PharosIOModulesV1 = ({ title, deviceId }) => {
  const refreshUI = (
    <RefreshInterface
      deviceId={deviceId}
      dataId="io_instances"
    />
  );

  return (
    <Fragment>
      <DataTable
        header={title}
        labels={labels}
        customTableRow={IoModuleAccordionRow}
        createElement={refreshUI}
        pagination={{
          type: IO_MODULES_INSTANCES_FIELD,
          included: true,
        }}
      />
    </Fragment>
  );
};

PharosIOModulesV1.propTypes = {
  title: PropTypes.string.isRequired,
  deviceId: PropTypes.string.isRequired,
};

export default PharosIOModulesV1;
