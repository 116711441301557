import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

const newUploadActionCreators = {
  fileNotFoundResume: ({ fileKey, params }) => ({
    type: constants.UPLOAD_FILE_NOT_FOUND_RESUME,
    payload: { fileKey, params },
  }),

  prepareUpload: ({ deviceId, params }) => ({
    type: constants.UPLOAD_PREPARE_REQUEST,
    payload: {
      params,
      endpoint: endpoints.FILE_UPLOADS(deviceId),
    },
  }),

  prepareUploadSuccess: response => ({
    type: constants.UPLOAD_PREPARE_SUCCESS,
    payload: { response },
  }),
};

export default newUploadActionCreators;
