import constants from 'dispatcherConst';

export const setTaskSchedulerMode = mode => ({
  type: constants.SET_TASK_SCHEDULER_MODE,
  payload: {
    mode,
  },
});

export default setTaskSchedulerMode;
