function hostAndUriFromUrl(url) {
  const protocol = 'https://';
  const hostAndUri = url.replace(protocol, '');
  const host = hostAndUri.match(/[^/]+\.com/g)[0];
  const uri = hostAndUri.replace(host, '');

  return {
    protocol,
    host,
    uri,
  };
}

export default hostAndUriFromUrl;
