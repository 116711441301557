import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/atomic';
import ConfirmModal from 'components/elements/ConfirmModal/ConfirmModal';
import { getDisplayName } from 'helpers/sharedMethods';

const withDeleteAction = WrappedComponent => {
  const WithDeleteAction = props => {
    const {
      item: { index, name },
      taskId,
      onDeleteAction,
      taskName,
    } = props;

    const trigger = <WrappedComponent title="Delete" />;
    const handleConfirm = () => onDeleteAction(taskId, index, taskName);

    return (
      <ConfirmModal
        trigger={trigger}
        cancelButton="Cancel"
        confirmButton={<Button negative primary={false}>Delete Action</Button>}
        content={(
          <div>
            <p>
              Are you sure you want to delete action <strong>{name}</strong>?
              <br />This delete operation cannot be undone.
            </p>
          </div>
        )}
        header="Confirm action deletion"
        onConfirm={handleConfirm}
      />
    );
  };

  WithDeleteAction.propTypes = {
    item: PropTypes.shape({
      index: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    taskId: PropTypes.string.isRequired,
    onDeleteAction: PropTypes.func.isRequired,
    taskName: PropTypes.string.isRequired,
  };

  WithDeleteAction.displayName = `WithDeleteAction(${getDisplayName(WrappedComponent)})`;

  return WithDeleteAction;

};

export default withDeleteAction;
