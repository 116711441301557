import React, { useMemo, useState, useCallback } from 'react';
import { isEmpty } from 'lodash';
import { Icon } from 'semantic-ui-react';

import getPermissionCheckboxState from '../getPermissionCheckboxState';

const usePermissionsTable = ({
  resourceId,
  parentResourceId,
  resourceType,
  resourceName,
  recipientType,
  context,
  limitedScope,
  limitedFor,
  cumulatedPermissions,
  onGrantPermissions,
  onRevokePermissions,
} = {}) => {
  const [visiblePermissions, setVisiblePermissions] = useState([]);

  const handleGrantRevokePermissions = useCallback(async (recipientId, permissionItem) => {
    if (!cumulatedPermissions || isEmpty(cumulatedPermissions)) {
      return;
    }

    const { relatedPermissions } = permissionItem;

    const checkboxState = getPermissionCheckboxState({
      cumulatedPermissions,
      recipientId,
      permissionNames: relatedPermissions,
    });

    let action = null;

    if (checkboxState === 'checked') {
      action = 'revoke';
    } else {
      action = 'grant';
    }

    const params = {
      names: relatedPermissions,
      recipientId,
      recipientType,
      parentResourceId,
      resourceId,
      resourceType,
      resourceName,
      context,
      permissionItem,
      limitedScope,
      limitedFor,
    };

    if (action === 'grant') {
      onGrantPermissions(params);
    } else {
      onRevokePermissions(params);
    }
  }, [
    context,
    resourceId,
    parentResourceId,
    resourceType,
    recipientType,
    resourceName,
    limitedScope,
    limitedFor,
    cumulatedPermissions,
    onGrantPermissions,
    onRevokePermissions,
  ]);

  const isRowOpen = useCallback(permissionTableRow => {
    const { key, hasChild } = permissionTableRow;

    if (hasChild && visiblePermissions.includes(key)) {
      return true;
    }

    return false;
  }, [visiblePermissions]);

  const isRowVisible = useCallback(permissionTableRow => {
    const { level, parentName } = permissionTableRow;

    if (level === 0) {
      return true;
    }

    if (visiblePermissions.includes(parentName)) {
      return true;
    }

    return false;
  }, [visiblePermissions]);

  const handleToggleRowVisibility = useCallback(row => {
    const {
      key: permissionName,
      hasChild,
    } = row || {};

    if (!hasChild) {
      return;
    }

    setVisiblePermissions(previous => {
      let newVisiblePermissions = [];

      if (!previous.includes(permissionName)) {
        newVisiblePermissions = [...previous, permissionName];
      } else {
        newVisiblePermissions = [...previous.filter(item => !item.startsWith(permissionName))];
      }

      return newVisiblePermissions;
    });
  }, []);

  const RowIcon = useCallback(({ hasChild, isOpen }) => {
    if (hasChild && isOpen) {
      return <Icon name="caret down" />;
    }

    if (hasChild && !isOpen) {
      return <Icon name="caret right" />;
    }

    return null;
  }, []);

  const recipientSelectProps = useMemo(() => ({
    resourceId,
    resourceType,
    recipientType,
    limitedFor,
    context,
  }), [
    resourceId,
    resourceType,
    recipientType,
    limitedFor,
    context,
  ]);

  const permissionsTableProps = useMemo(() => ({
    resourceId,
    resourceType,
    recipientType,
    limitedFor,
    context,
  }), [
    resourceId,
    resourceType,
    recipientType,
    limitedFor,
    context,
  ]);

  return {
    visiblePermissions,
    isRowVisible,
    isRowOpen,
    RowIcon,
    onGrantRevokePermissions: handleGrantRevokePermissions,
    onToggleRowVisibility: handleToggleRowVisibility,
    recipientSelectProps,
    permissionsTableProps,
  };
};

export default usePermissionsTable;
