import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { fromLonLat } from 'ol/proj';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import isEmpty from 'lodash/isEmpty';

import MapContext from '../MapContext/MapContext';
import { Marker } from './components';

const Markers = ({ markers, isProjectSuite }) => {
  const { map } = React.useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    const features = [];

    markers.forEach(({ longitude, latitude }) => {
      const coordinates = fromLonLat([longitude, latitude]);
      const point = new Point(coordinates);
      const feature = new Feature({ geometry: point });

      features.push(feature);
    });

    if (!isEmpty(features)) {
      const source = new VectorSource({ features });
      const layer = new VectorLayer({ source });
      map.addLayer(layer);

      map.getView()
        .fit(source.getExtent(), {
          size: map.getSize(),
          minResolution: 4,
          padding: [40, 40, 40, 40],
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return (
    <>
      {!isEmpty(markers) && markers.map(marker => (
        <Marker
          key={marker.id}
          id={marker.id}
          lon={marker.longitude}
          lat={marker.latitude}
          type={marker.type}
          name={marker.name}
          isProjectSuite={isProjectSuite}
        />
      ))}
    </>
  );
};

Markers.defaultProps = {
  markers: [],
  isProjectSuite: false,
};

Markers.propTypes = {
  markers: PropTypes.array,
  isProjectSuite: PropTypes.bool,
};

export default Markers;
