import isNull from 'lodash/isNull';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

const processParam = startMethodData => {
  if (isNull(startMethodData)) {
    return null;
  }

  if (isString(startMethodData)) {
    return startMethodData;
  }

  if (isNumber(startMethodData)) {
    if (startMethodData > 0) {
      return `+${startMethodData} mins`;
    }

    return `${startMethodData} mins`;
  }

  return startMethodData;
};

export default processParam;
