import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { frontendPermissions } from 'gateway';
import { deepEqualMemoizeOne, checkPermissionByTypeAndResource } from 'helpers/sharedMethods';
import { singleProjectSelector, projectFetchingSelector, currentUserSelector } from 'helpers/selectors';
import redirectIfNotFound from 'helpers/redirectIfNotFound';
import { selectors as permissionsSelectors } from 'application/tenant/console/utilities/permissions';
import { actionCreators as usersActionCreators } from 'application/tenant/console/utilities/users';
import Project from './Project';

export const makeMapState = () => {
  const memoInvitations = deepEqualMemoizeOne();

  return function mapStateToProps(state, { match: { params: { id: siteId } } }) {
    const {
      name: siteName,
      isConstruction,
      isSuspended,
      stateLoaded,
      logicalDevicesCount,
    } = singleProjectSelector(state, siteId) || {};

    const { receivedInvitations, id: userId, superadmin } = currentUserSelector(state);
    const invitations = receivedInvitations.filter(
      ({ projectId }) => projectId && projectId === siteId,
    );

    const userPermissions = permissionsSelectors.userPermissionsSelector(state)(userId);
    const isProjectOwner = checkPermissionByTypeAndResource('project', siteId, userPermissions, frontendPermissions.OWNER);
    const superadminOrProjectOwner = superadmin || isProjectOwner;

    return {
      fetching: projectFetchingSelector(state),
      siteName,
      isConstruction,
      isSuspended,
      stateLoaded,
      invitations: memoInvitations(invitations),
      logicalDevicesCount,
      superadminOrProjectOwner,
    };
  };
};

const mapDispatchToProps = {
  onDeleteInvitation: usersActionCreators.deleteInvitation,
};

export default compose(
  withRouter,
  connect(makeMapState, mapDispatchToProps),
  redirectIfNotFound('siteName'),
)(Project);
