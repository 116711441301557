import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { PROJECT_SUITES_PROJECTS_FILTER_FIELD } from 'helpers/selectors';

export const fetchProjectSuiteProjectsFilter = (projectSuiteId, urlParams = {}) => ({
  type: constants.FETCH_PROJECT_SUITE_PROJECTS_FILTER_REQUEST,
  payload: {
    projectSuiteId,
    endpoint: endpoints.PROJECT_SUITE_PROJECTS(projectSuiteId),
    urlParams,
  },
});

export const fetchProjectSuiteProjectsFilterSuccess = response => ({
  type: constants.FETCH_PROJECT_SUITE_PROJECTS_FILTER_SUCCESS,
  payload: {
    response,
    resourceType: PROJECT_SUITES_PROJECTS_FILTER_FIELD,
  },
});

export const fetchProjectSuiteProjectsFilterFailure = error => ({
  type: constants.FETCH_PROJECT_SUITE_PROJECTS_FILTER_FAILED,
  payload: { error },
});

export default fetchProjectSuiteProjectsFilter;
