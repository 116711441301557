import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { uploadsFilesGroupedSelector } from 'helpers/uploadsSelectors';
import UploadsWidget from './UploadsWidget';

const mapStateToProps = state => ({
  uploads: uploadsFilesGroupedSelector(state),
});

export default compose(
  withRouter,
  connect(mapStateToProps),
)(UploadsWidget);
