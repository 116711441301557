import {
  fetchAllPermissions,
  fetchAllPermissionsSuccess,
  fetchAllPermissionsFailure,
} from './fetchAllPermissions';

import {
  fetchCumulatedPermissions,
  fetchCumulatedPermissionsSuccess,
  fetchCumulatedPermissionsFailure,
} from './fetchCumulatedPermissions';

import {
  fetchPermissionsTableRecipients,
  fetchPermissionsTableRecipientsSuccess,
  fetchPermissionsTableRecipientsFailure,
} from './fetchPermissionsTableRecipients';

import {
  grantMultiplePermissions,
  grantMultiplePermissionsSuccess,
  grantMultiplePermissionsFailure,
} from './grantMultiplePermissions';

import {
  revokeMultiplePermissions,
  revokeMultiplePermissionsSuccess,
  revokeMultiplePermissionsFailure,
} from './revokeMultiplePermissions';

import copyMultiplePermissions from './copyMultiplePermissions';

import {
  pasteMultiplePermissions,
  pasteMultiplePermissionsSuccess,
  pasteMultiplePermissionsFailure,
} from './pasteMultiplePermissions';

import clearPermissionsTable from './clearPermissionsTable';
import toggleResourceOpen from './toggleResourceOpen';
import changePermissionsTableRecipients from './changePermissionsTableRecipients';
import addResourceId from './addResourceId';
import removeResourceId from './removeResourceId';

export default {
  fetchAllPermissions,
  fetchAllPermissionsSuccess,
  fetchAllPermissionsFailure,
  fetchCumulatedPermissions,
  fetchCumulatedPermissionsSuccess,
  fetchCumulatedPermissionsFailure,
  fetchPermissionsTableRecipients,
  fetchPermissionsTableRecipientsSuccess,
  fetchPermissionsTableRecipientsFailure,
  grantMultiplePermissions,
  grantMultiplePermissionsSuccess,
  grantMultiplePermissionsFailure,
  revokeMultiplePermissions,
  revokeMultiplePermissionsSuccess,
  revokeMultiplePermissionsFailure,
  copyMultiplePermissions,
  pasteMultiplePermissions,
  pasteMultiplePermissionsSuccess,
  pasteMultiplePermissionsFailure,
  clearPermissionsTable,
  toggleResourceOpen,
  changePermissionsTableRecipients,
  addResourceId,
  removeResourceId,
};
