import constants from 'dispatcherConst';

const toggleShowHideMultiSiteOccurrences = hideMultiSiteOccurrences => ({
  type: constants.TOGGLE_SHOW_HIDE_MULTI_SITE_OCCURRENCES,
  payload: {
    hideMultiSiteOccurrences,
  },
});

export default toggleShowHideMultiSiteOccurrences;
