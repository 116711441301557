import React from 'react';
import { Duration, DateTime } from 'luxon';

import { celsiusToFahrenheit } from 'helpers/sharedMethods';
import { dateTimeFormat } from 'helpers/sharedVariables';
import DataTableIcon from 'components/DataTable/DataTableIcon/DataTableIcon';

export const booleanIcons = {
  check: 'check',
  cross: 'close',
};

export const boolNormalizer = value => (
  <DataTableIcon name={value ? booleanIcons.check : booleanIcons.cross} />
);
export const dateNormalizer = value => new Date(value * 1000).toUTCString();
export const localDateNormalizer = (value, { offset }) => (
  DateTime
    .fromMillis(value * 1000)
    .setZone(offset * 60)
    .toFormat(dateTimeFormat)
);
export const utcDateNormalizer = value => (
  DateTime
    .fromMillis(value * 1000, { zone: 'utc' })
    .toFormat(dateTimeFormat)
);
export const tempNormalizer = (value, { unit }) => {
  switch (unit) {
    case 'C':
      return `${value} \u2103`;
    case 'F':
      return `${celsiusToFahrenheit(value)} \u2109`;
    default:
      return null;
  }
};
export const timeNormalizer = value => (
  Duration
    .fromMillis(value * 1000)
    .toFormat('d\'d\' hh\'h\' mm\'m\' ss\'s\'')
);
