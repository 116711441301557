export default class Tps5Creator {

  constructor(sixeyeFirmwareVersion, tabs) {
    this.model = 'TPS 5';
    this.sixeyeFirmwareVersion = sixeyeFirmwareVersion;
    this.manufacturerSDK = 'pharos';
    this.tabs = tabs;
  }

}
