import getDefaultValueForParamType from './getDefaultValueForParamType';

const getInitialValuesForSelectedActions = (
  selectedActions = [],
  allActionTypes,
  existingValues,
) => {
  const initialValues = {};

  selectedActions.forEach(selectedAction => {
    const actionType = allActionTypes[selectedAction.actionId] || {};
    const actionParams = actionType.params || [];

    actionParams.forEach(param => {
      const key = `${param.key}_${selectedAction.actionId}_${selectedAction.resourceId}_${selectedAction.index}`;

      if (existingValues && Object.keys(existingValues).includes(key)) {
        initialValues[key] = existingValues[key];
      } else {
        if (param.dependentOn) {
          return;
        }

        initialValues[key] = getDefaultValueForParamType(param.type, param);
      }
    });
  });

  return initialValues;
};

export default getInitialValuesForSelectedActions;
