import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import { AUTOMATED_OPERATIONS_FIELD } from 'helpers/selectors';

export const addTaskScheduler = ({
  resourceId,
  resourceType,
  mode,
  scheduleId,
  automatedOperationId,
  existingSchedule,
  actions,
  actionsForm,
  tasks,
  taskName,
  taskSchedulerForm,
  schedulerType,
  recurrenceParams,
  callback,
}) => ({
  type: constants.ADD_TASK_SCHEDULER,
  payload: {
    resourceId,
    resourceType,
    mode,
    scheduleId,
    automatedOperationId,
    existingSchedule,
    actions,
    tasks,
    actionsForm,
    taskName,
    taskSchedulerForm,
    schedulerType,
    recurrenceParams,
    tasksEndpoint: endpoints.TASKS,
    callback,
  },
});

export const addTaskSchedulerSuccess = automatedOperation => ({
  type: constants.ADD_TASK_SCHEDULER_SUCCESS,
  payload: {
    message: messages.AUTOMATED_OPERATION_ADDED(automatedOperation.name),
    resourceType: AUTOMATED_OPERATIONS_FIELD,
    id: automatedOperation.id,
  },
});

export const addTaskSchedulerFailure = error => ({
  type: constants.ADD_TASK_SCHEDULER_FAILED,
  payload: { error },
});
