import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Form } from 'semantic-ui-react';
import pluralize from 'pluralize';
import moment from 'moment';

import { SelectedTimesView } from 'components/core/TimePicker/components';
import { getReadableMonthlyCondition } from '../../utilities';
import { StyledLabel, StyledRow, StyledColumn } from '../../ScheduleReview.style';

function MonthlyRecurrenceReview({
  recurrenceParams,
}) {
  const {
    everyNMonths,
    startDate,
    startTimes,
    forNTimes,
    endTime,
    condition,
  } = recurrenceParams || {};

  const renderEndingCriteria = () => {
    if (forNTimes) {
      return (
        <>
          <StyledLabel>
            Ending after
          </StyledLabel>
          {` ${forNTimes} ${pluralize('occurrence', forNTimes)}`}
        </>
      );
    }

    if (endTime) {
      return (
        <>
          <StyledLabel>
            Ending on
          </StyledLabel>
          {` ${endTime && moment(endTime).format('MMM Do YYYY')}`}
        </>
      );
    }

    return (
      <>
        <StyledLabel>
          Ending on
        </StyledLabel>
        {' Never ends'}
      </>
    );
  };

  const renderReadableCondition = useCallback(() => {
    if (!condition) {
      return '';
    }

    return getReadableMonthlyCondition(condition);
  }, [condition]);

  return (
    <Form>
      <Grid>
        <StyledRow noPaddingBottom>
          <Grid.Column computer={4} mobile={8}>
            <Form.Group id="recur-every">
              <Form.Field>
                <StyledLabel>
                  Recur every
                </StyledLabel>
                {` ${everyNMonths} ${pluralize('month', everyNMonths)}`}
              </Form.Field>
            </Form.Group>
          </Grid.Column>
          <Grid.Column computer={4} mobile={8}>
            <Form.Group id="starting-on">
              <Form.Field>
                <StyledLabel>
                  Starting on
                </StyledLabel>
                {` ${startDate && moment(startDate).format('MMM Do YYYY')}`}
              </Form.Field>
            </Form.Group>
          </Grid.Column>
          <StyledColumn computer={4} mobile={16}>
            <Form.Group id="ending-criteria">
              <Form.Field>
                {renderEndingCriteria()}
              </Form.Field>
            </Form.Group>
          </StyledColumn>
        </StyledRow>
        <StyledRow noPaddingTop noPaddingBottom>
          <Grid.Column computer={16} mobile={16}>
            <Form.Group id="condition" widths="equal">
              <Form.Field>
                <StyledLabel>
                  On the
                </StyledLabel>
                {` ${renderReadableCondition()}`}
              </Form.Field>
            </Form.Group>
          </Grid.Column>
        </StyledRow>
        <StyledRow noPaddingTop noPaddingBottom>
          <Grid.Column computer={16} mobile={16}>
            <Form.Group id="times" widths="equal">
              <Form.Field>
                <StyledLabel>
                  Times
                </StyledLabel>
                <SelectedTimesView
                  selectedTimes={startTimes}
                  renderLabel={false}
                  clearable={false}
                />
              </Form.Field>
            </Form.Group>
          </Grid.Column>
        </StyledRow>
      </Grid>
    </Form>
  );
}

MonthlyRecurrenceReview.propTypes = {
  recurrenceParams: PropTypes.object.isRequired,
};

export default MonthlyRecurrenceReview;
