export const baseFontSize = '14px';

const sidebarConst = {
  width: {
    xs: '300px',
    md: '240px',
  },
};

export default sidebarConst;
