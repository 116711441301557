import { reset } from 'redux-form';
import { call, put } from 'redux-saga/effects';

import { putPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import ControlUIModel from 'models/ControlUIModel';
import actionCreators from '../actionCreators';

function* updateControlUI(action) {
  const {
    id,
    lockVersion,
    pages,
    endpoint,
    form,
    ...rest
  } = action.payload;

  const body = ControlUIModel.requestBody({ id, lockVersion, pages });
  const { response, error } = yield call(putPath, endpoint, body);

  const params = {
    response,
    error,
    successDisp: actionCreators.updateControlUISuccess,
    failDisp: actionCreators.updateControlUIFailure,
    ...rest,
  };
  yield call(processCall, params);

  if (response && form) {
    yield put(reset(form));
  }
}

export default updateControlUI;
