import React from 'react';
import PropTypes from 'prop-types';

import { StyledP } from './LogsParamsError.style';

const LogsParamsError = ({ showError }) => {
  if (showError) {
    return <StyledP>Please select different dates</StyledP>;
  }

  return null;
};

LogsParamsError.defaultProps = {
  showError: false,
};

LogsParamsError.propTypes = {
  showError: PropTypes.bool,
};

export default LogsParamsError;
