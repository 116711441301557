import { takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import fetchSiteDevices from './fetchSiteDevices';

function* saga() {
  yield takeLatest(constants.FETCH_SITE_DEVICES_REQUEST, fetchSiteDevices);
}

export {
  saga,
};

export default saga;
