import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { StyledMenu, MenuItem } from './components';

const PrioritySelection = ({ options, defaultValue, onChange }) => {
  const [activeItem, setActiveItem] = useState(defaultValue);

  const handleClick = value => {
    setActiveItem(value);
    onChange(value);
  };

  const handleKeyPress = (e, value) => {
    if (e.key === 'Enter') {
      setActiveItem(value);
      onChange(value);
    }
  };

  return (
    <StyledMenu vertical>
      {options.map(({
        key,
        text,
        value,
        iconName,
      }) => (
        <MenuItem
          key={key}
          text={text}
          value={value}
          iconName={iconName}
          activeItem={activeItem}
          handleClick={handleClick}
          handleKeyPress={handleKeyPress}
        />
      ))}
    </StyledMenu>
  );
};

PrioritySelection.defaultProps = {
  options: [],
  defaultValue: null,
};

PrioritySelection.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default PrioritySelection;
