import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';
import deepEqual from 'lodash/isEqual';
import identity from 'lodash/identity';

import { deviceSelector } from 'helpers/selectors';
import PharosProjectTPSV1 from './PharosProjectTPSV1';

export const makeMapState = () => {
  const memoName = memoizeOne(identity, deepEqual);
  const memoFileName = memoizeOne(identity, deepEqual);
  const memoFileSize = memoizeOne(identity, deepEqual);
  const memoAuthor = memoizeOne(identity, deepEqual);
  const memoUploadedOn = memoizeOne(identity, deepEqual);

  return function mapStateToProps(state, ownProps) {
    const { resourceId } = ownProps;
    const device = deviceSelector(state)(resourceId);
    const { reportedWithMeta } = device;

    const {
      clk: {
        tz: {
          value: offsetValue,
        } = {},
      } = {},
      cfg: {
        nm: name,
        fn: fileName,
        sz: fileSize,
        up: uploadedOn,
        au: author,
      } = {},
    } = reportedWithMeta || {};

    return {
      offsetValue,
      loading: !reportedWithMeta,
      name: memoName(name),
      fileName: memoFileName(fileName),
      fileSize: memoFileSize(fileSize),
      author: memoAuthor(author),
      uploadedOn: memoUploadedOn(uploadedOn),
    };
  };
};

export default connect(makeMapState())(PharosProjectTPSV1);
