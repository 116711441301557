import React from 'react';
import { Input } from 'semantic-ui-react';

import FormField from 'components/form/FormField/FormField';
import InlineEditField from './InlineEditField/InlineEditField';

const InlineEditTextField = props => (
  <FormField {...props}>
    <InlineEditField {...props} />
  </FormField>
);

InlineEditTextField.propTypes = {
  ...Input.propTypes,
};

export default InlineEditTextField;
