import styled from 'styled-components';

import { injectStyles, media } from 'themes/mixins';

const TenantBackground = styled.div`
  ${media.md`
    ${({ theme: { signInBackground } }) => injectStyles(signInBackground)};
  `};
`;

export default TenantBackground;
