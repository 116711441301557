import styled from 'styled-components';

export const StyledMapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
`;

export const StyledMap = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledCopyright = styled.span`
  position: absolute;
  bottom: 0.5em;
  right: 0.5em;
`;
