import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react';

export const StyledDropdown = styled(Dropdown)`
  &&& {
    i {
      display: none;
    }
    
    .item:nth-child(2):not(.right) {
      pointer-events: auto !important;
    }
  }
`;

export default StyledDropdown;
