import React from 'react';
import PropTypes from 'prop-types';

import { getDisplayName } from 'helpers/sharedMethods';

const permissionsOrRedirect = Redirect => WrappedComponent => {
  const PermissionsOrRedirect = ({
    passesPermissions,
    ...rest
  }) => {
    if (passesPermissions) {
      return <WrappedComponent {...rest} />;
    }

    if (Redirect) {
      return <Redirect />;
    }

    return null;
  };

  PermissionsOrRedirect.defaultProps = {
    passesPermissions: false,
  };

  PermissionsOrRedirect.propTypes = {
    passesPermissions: PropTypes.bool,
  };

  PermissionsOrRedirect.displayName = `PermissionsOrRedirect(${getDisplayName(WrappedComponent)})`;

  return PermissionsOrRedirect;
};

export default permissionsOrRedirect;
