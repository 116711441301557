import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';

export const linkFileWithDeviceFirmware = (deviceFirmwareId, fileFirmwareId) => ({
  type: constants.LINK_FILE_WITH_DEVICE_FIRMWARE_REQUEST,
  payload: {
    deviceFirmwareId,
    fileFirmwareId,
    endpoint: endpoints.LINK_FIRMWARE_FILE(deviceFirmwareId),
  },
});

export const linkFileWithDeviceFirmwareSuccess = response => ({
  type: constants.LINK_FILE_WITH_DEVICE_FIRMWARE_SUCCESS,
  payload: {
    response,
    message: messages.FILE_LINKED_WITH_FIRMWARE_SUCCESS,
  },
});

export const linkFileWithDeviceFirmwareFailure = error => ({
  type: constants.LINK_FILE_WITH_DEVICE_FIRMWARE_FAILED,
  payload: { error },
});

export default linkFileWithDeviceFirmware;
