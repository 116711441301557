import { connect } from 'react-redux';

import Markers from './Markers';
import { selectors } from '../../../../utilities';

export const mapStateToProps = state => {
  const markers = selectors.projectsWithLocationSelector(state);

  return {
    markers,
  };
};

export default connect(mapStateToProps)(Markers);
