import React from 'react';
import { Grid } from 'semantic-ui-react';

import {
  ProjectSuiteInformation,
  ProjectSuiteMapSection,
  ProjectSuiteLinkedProjects,
} from './components';

const ProjectSuiteSettingsTab = () => (
  <Grid>
    <Grid.Row>
      <Grid.Column mobile={16} computer={8}>
        <ProjectSuiteInformation />
      </Grid.Column>
      <Grid.Column mobile={16} computer={8}>
        <ProjectSuiteMapSection />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column mobile={16} computer={8}>
        <ProjectSuiteLinkedProjects />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export default ProjectSuiteSettingsTab;
