import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { deepEqualMemoizeOne } from 'helpers/sharedMethods';
import { validateOnSubmit } from 'components/form/formConfigs';
import EditDate from './EditDate';
import { getMinDate } from './utilities';

const EditDateContainer = reduxForm({
  enableReinitialize: true,
  ...validateOnSubmit,
})(EditDate);

export const makeMapState = () => {
  const memoizeMinDate = deepEqualMemoizeOne();

  return function mapStateToProps(state, { minDate: originalMinDate }) {
    const minDate = getMinDate(originalMinDate);

    return {
      minDate: memoizeMinDate(minDate),
    };
  };
};

export default connect(
  makeMapState,
)(EditDateContainer);
