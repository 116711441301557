import { compose } from 'redux';
import { connect } from 'react-redux';

import { uploadsStatsSelector } from 'helpers/uploadsSelectors';
import filterProps from 'helpers/filterProps';
import ThemedProgress from 'components/elements/ThemedProgress/ThemedProgress.style';

const mapStateToProps = state => {
  const { progressCount } = uploadsStatsSelector(state);

  return {
    percent: progressCount,
  };
};

export default compose(
  connect(mapStateToProps),
  filterProps('dispatch'),
)(ThemedProgress);
