import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { singleProjectSelector } from 'helpers/selectors';
import SiteActions from './SiteActions';

export const mapStateToProps = (state, { match }) => {
  const { id: projectId } = match.params;
  const { name } = singleProjectSelector(state, projectId);

  return {
    projectId,
    resourceName: name,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
)(SiteActions);
