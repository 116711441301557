import styled from 'styled-components';
import { Table } from 'semantic-ui-react';

import { mediaMax } from 'themes/mixins';

const StyledTableRowAccordion = styled(Table.Row)`
  td {
    &:nth-of-type(1) {
      ${mediaMax.md`
        position: relative !important;
        transform: none !important;;
      `};
    }

    &:last-of-type {
      ${mediaMax.md`
        width: 100% !important;
      `};
    }
  }
`;

export default StyledTableRowAccordion;
