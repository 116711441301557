import { connect } from 'react-redux';
import transform from 'lodash/transform';
import { deviceSelector, pathwayDeviceNETSelector } from 'helpers/selectors';
import { PathwayVIAPortsV1 } from '../../../components';

const mapStateToProps = (state, { resourceId }) => {
  const { online } = deviceSelector(state)(resourceId);
  const dmx = pathwayDeviceNETSelector(state, resourceId);

  const data = transform(dmx, (result, value, key) => {

    result.push({
      id: key,
      resourceId,
      nameLabel: `${value.nm} (Port ${key})`,
      lldpPartnerLabel: `LLDP Partner: ${value.lldpNm}`,
      isPoeCapable: Number(key) !== 9 && Number(key) !== 10,
    });
    return result;
  }, []);

  return {
    online,
    columns: ['nameLabel', 'lldpPartnerLabel'],
    data,
  };
};

export default connect(mapStateToProps)(PathwayVIAPortsV1);
