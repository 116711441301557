import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { TENANT } from 'storageConst';
import { Button } from 'components/atomic';
import { ResetButtonWrapper, StyledSelect } from './TenantSelect.style';

class TenantSelect extends Component {

  constructor() {
    super();

    this.handleChangeTenant = this.handleChangeTenant.bind(this);
    this.handleResetTenant = this.handleResetTenant.bind(this);
  }

  componentDidMount() {
    const tenant = localStorage.getItem(TENANT);
    const { onChangeTenant } = this.props;

    if (tenant) {
      onChangeTenant(tenant);
    }
  }

  handleChangeTenant(event, { value }) {
    const { tenant, currentUserId, onChangeTenant } = this.props;

    if (value !== tenant) {
      onChangeTenant(value, currentUserId);
    }
  }

  handleResetTenant() {
    const {
      currentCompany,
      currentUserId,
      onChangeTenant,
    } = this.props;

    onChangeTenant(currentCompany, currentUserId);
  }

  render() {
    const {
      currentCompany,
      currentCompanyName,
      tenant,
      tenantChanged,
      tenants,
      fetching,
    } = this.props;

    return (
      <Fragment>
        {tenantChanged && (
          <ResetButtonWrapper>
            <Button onClick={this.handleResetTenant} primary>
              Back to {currentCompanyName}
            </Button>
          </ResetButtonWrapper>
        )}
        <StyledSelect
          onChange={this.handleChangeTenant}
          selectOnBlur={false}
          selectOnNavigation={false}
          placeholder="Choose tenant"
          options={tenants}
          value={tenant || currentCompany}
          loading={fetching}
          disabled={!tenants.length || !currentCompany}
          search
        />
      </Fragment>
    );
  }

}

TenantSelect.defaultProps = {
  currentCompany: null,
  currentCompanyName: null,
  tenant: null,
  tenantChanged: false,
};

TenantSelect.propTypes = {
  currentCompany: PropTypes.string,
  currentCompanyName: PropTypes.string,
  currentUserId: PropTypes.string.isRequired,
  fetching: PropTypes.bool.isRequired,
  tenant: PropTypes.string,
  tenantChanged: PropTypes.bool,
  tenants: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChangeTenant: PropTypes.func.isRequired,
};

export default TenantSelect;
