import styled from 'styled-components';
import { Accordion } from 'semantic-ui-react';

import { mediaMax } from 'themes/mixins';

export const StyledAccordionTitle = styled(Accordion.Title)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  span {
    display: flex;
    flex: 1;
    align-items: center;

    i {
      margin-bottom: 0.25rem;
    }
  }
`;

export const StyledAccordionContent = styled(Accordion.Content)`
  ${mediaMax.sm`
    padding: 15px !important;
  `};
`;
export const StyledAccordionButtons = styled.div`
  button {
    &:last-child {
      margin-left: 5px
    }    
  }
`;

export const ActionTitle = styled.span`
  margin-left: 10px;
  flex: 1;
  padding-top: 15px;
  padding-bottom: 15px;
`;

export default StyledAccordionTitle;
