import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  logicalDeviceSelector,
  allActionsTypeSelector,
} from 'helpers/selectors';
import { getActionType } from '../../utilities';
import ActionsTableRow from './ActionsTableRow';

export const mapStateToProps = (state, ownProps) => {
  const {
    item: {
      logicalDeviceId,
      typeId,
      invalid,
      params,
    },
  } = ownProps;
  const allActionTypes = allActionsTypeSelector(state);
  const { name } = logicalDeviceSelector(state)(logicalDeviceId) || {};
  const deviceName = name || 'Device not found!';
  const actionType = getActionType(typeId, allActionTypes);

  return {
    deviceName,
    actionType,
    invalid,
    params,
    typeId,
  };
};

export default compose(
  connect(mapStateToProps),
)(ActionsTableRow);
