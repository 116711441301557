import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Form, Icon } from 'semantic-ui-react';
import isNull from 'lodash/isNull';

import { Button, TipIcon } from 'components/atomic';

import AstroInput from './components/AstroInput/AstroInput';
import { StyledRow, StyledLabel, StyledColumn } from './AstroFormRow.style';

export default function AstroFormRow({ label, type, onAdd }) {
  const [value, setValue] = useState(0);
  const [error, setError] = useState(null);

  const renderTipIcon = () => (
    <TipIcon
      floated="right"
      message="Enter up to 5 different values between -720 and +720."
      editMode={false}
    />
  );

  const handleChange = newValue => {
    if (isNull(newValue)) {
      return;
    }

    setError(null);
    setValue(newValue);
  };

  const handleError = message => {
    setError(message);
  };

  const handleAdd = e => {
    e.preventDefault();

    onAdd(value, type);
  };

  const handleEnterPressed = e => {
    e.preventDefault();

    onAdd(value, type);
  };

  return (
    <StyledRow noPaddingBottom>
      <Grid.Column width={13}>
        <Form.Field>
          <StyledLabel htmlFor="input">
            {label} {renderTipIcon()}
          </StyledLabel>
          <AstroInput
            id={`${label}-offset`}
            type="text"
            value={value}
            onEnterPressed={handleEnterPressed}
            onChange={handleChange}
            onError={handleError}
          />
          <small>{error}</small>
        </Form.Field>
      </Grid.Column>
      <StyledColumn hasError={!!error} width={3}>
        <Button icon onClick={handleAdd}>
          <Icon as="i" name="plus" />
        </Button>
      </StyledColumn>
    </StyledRow>
  );
}

AstroFormRow.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
};
