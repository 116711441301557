import React from 'react';
import PropTypes from 'prop-types';

import { StyledSegment } from './StoryPreview.style';

function StoryPreview({ children }) {
  return (
    <StyledSegment>
      {children}
    </StyledSegment>
  );
}

StoryPreview.defaultProps = {
  children: null,
};

StoryPreview.propTypes = {
  children: PropTypes.node,
};

export default StoryPreview;
