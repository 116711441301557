import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';
import {
  AUTOMATED_OPERATIONS_FIELD,
} from 'helpers/selectors';

export const updateAutomatedOperation = params => ({
  type: constants.UPDATE_AUTOMATED_OPERATION_REQUEST,
  payload: {
    params,
    endpoint: endpoints.UPDATE_AUTOMATED_OPERATION(params.values.id),
  },
});

export const updateAutomatedOperationSuccess = response => ({
  type: constants.UPDATE_AUTOMATED_OPERATION_SUCCESS,
  payload: {
    response,
    message: messages.AUTOMATED_OPERATION_UPDATED,
    resourceType: AUTOMATED_OPERATIONS_FIELD,
    id: response.data.id,
  },
});

export const updateAutomatedOperationFailure = error => ({
  type: constants.UPDATE_AUTOMATED_OPERATION_FAILED,
  payload: { error },
});
