import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  PROJECTS_FIELD,
  LOGICAL_DEVICES_FIELD,
} from 'helpers/selectors';
import withPolling from 'helpers/withPolling';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { addPagination } from 'helpers/paginationHelpers';
import paginationActionCreators from 'data/paginationActionCreators';
import { withProjectPermissions } from 'gateway';
import projectActionCreators from 'pages/Projects/projectActionCreators';
import ProjectDevicesTab from './ProjectDevicesTab';

const mapStateToProps = (state, { match }) => {
  const {
    params: { id: projectId },
    url: projectURL,
  } = match;

  return {
    projectURL,
    projectId,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withProjectPermissions,
  withPolling(),
  chainedLoaderWithSaga(
    ({ projectId }) => projectActionCreators.fetchProject(projectId, {
      fields: {
        [PROJECTS_FIELD]: ['name', 'state', LOGICAL_DEVICES_FIELD],
      },
    }),
    addPagination(
      { resourceType: LOGICAL_DEVICES_FIELD },
      paginationActionCreators.fetchPageResource({
        sort: 'name',
      }),
    ),
  ),
)(ProjectDevicesTab);
