import { compose } from 'redux';
import { connect } from 'react-redux';

import selectors from 'pages/ProjectSuites/utilities/selectors';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import actionCreators from 'pages/ProjectSuites/utilities/actionCreators';
import MultiSiteName from './MultiSiteName';

export const mapStateToProps = (state, { projectId, value }) => {
  const parents = selectors.projectSuiteParentsSelector(state)(projectId);
  const multiSite = Array.isArray(parents) && parents.filter(item => item.id === value);
  const name = multiSite && multiSite[0].name;

  return {
    visibleName: name || null,
    projectId,
  };
};

export default compose(
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    ({ projectId }) => actionCreators.fetchProjectProjectSuiteParents(projectId),
  ),
)(MultiSiteName);
