import constants from 'dispatcherConst';

const dataActionCreators = {
  resourceNotFound: (id, type) => ({
    type: constants.RESOURCE_NOT_FOUND,
    payload: {
      id,
      type,
    },
  }),
};

export default dataActionCreators;
