import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import { BsExclamation } from 'react-icons/bs';

const IsApproachingRenewal = ({ color }) => (
  <div>
    <Popup
      content="This Site is approaching its renewal date. Please contact the Site Owner for more information."
      trigger={(
        <BsExclamation size={40} color={color} />
      )}
      position="top right"
    />
  </div>
);

IsApproachingRenewal.defaultProps = {
  color: '#818181',
};

IsApproachingRenewal.propTypes = {
  color: PropTypes.string,
};

export default IsApproachingRenewal;
