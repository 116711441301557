import React from 'react';
import PropTypes from 'prop-types';

import * as validators from 'components/form/formValidators';
import { FieldWrapper } from 'components/atomic';
import SelectField from 'components/form/SelectFieldInput/SelectFieldInput';

import { StyledField, StyledLabel } from './SelectMultiSite.style';

const SelectMultiSite = ({
  name,
  label,
  editMode,
  options,
}) => {
  const displayLabel = options.length === 0 ? 'No options available' : label;
  const labelText = editMode ? '' : displayLabel;

  return (
    <FieldWrapper>
      <StyledLabel editMode={editMode} htmlFor="input">{labelText}</StyledLabel>
      <StyledField
        name={name}
        placeholder={displayLabel}
        component={SelectField}
        options={options}
        validate={validators.required}
        editMode={editMode}
      />
    </FieldWrapper>
  );
};

SelectMultiSite.defaultProps = {
  name: '',
  label: '',
  editMode: false,
  options: [],
};

SelectMultiSite.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  editMode: PropTypes.bool,
  options: PropTypes.array,
};

export default SelectMultiSite;
