import React from 'react';
import PropTypes from 'prop-types';

import { ColorWheel } from 'components/core';
import { transformRGBToPercentage } from '../../utilities';

const ColorPicker = ({
  changeColor,
  actionTypeDeviceIdKey,
  updateRGB,
  updateRGBNewAction,
  scheduleMode,
  color,
  width,
}) => {
  const updateForm = colorObj => {
    const percentageColor = transformRGBToPercentage(colorObj.rgb);
    if (scheduleMode) {
      updateRGB(percentageColor.r, percentageColor.g, percentageColor.b, actionTypeDeviceIdKey);
    } else {
      updateRGBNewAction(
        percentageColor.r, percentageColor.g, percentageColor.b, actionTypeDeviceIdKey,
      );
    }
  };

  return (
    <ColorWheel
      width={width}
      wheelLightness={false}
      changeColor={changeColor}
      changeColorFinal={updateForm}
      formColor={color}
    />
  );
};

ColorPicker.defaultProps = {
  actionTypeDeviceIdKey: '',
  scheduleMode: false,
  color: {
    r: 0,
    g: 0,
    b: 0,
  },
  width: 200,
};

ColorPicker.propTypes = {
  width: PropTypes.number,
  changeColor: PropTypes.func.isRequired,
  actionTypeDeviceIdKey: PropTypes.string,
  updateRGB: PropTypes.func.isRequired,
  updateRGBNewAction: PropTypes.func.isRequired,
  scheduleMode: PropTypes.bool,
  color: PropTypes.object,
};

export default ColorPicker;
