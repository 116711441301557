import { compose } from 'redux';
import { connect } from 'react-redux';

import { fileRemovingSelector } from 'helpers/selectors';
import withRemoveFile from './withRemoveFile';
import filesSectionActionCreators from '../../filesSectionActionCreators';

const mapStateToProps = state => ({
  removing: fileRemovingSelector(state),
});

const mapDispatchToProps = {
  onRemoveFile: filesSectionActionCreators.removeFile,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRemoveFile,
);
