import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';

export const upgradeSitePlan = ({ projectId, planCode }) => ({
  type: constants.UPGRADE_SITE_PLAN_REQUEST,
  payload: {
    projectId,
    planCode,
    endpoint: endpoints.UPGRADE_SITE_PLAN(projectId),
  },
});

export const upgradeSitePlanSuccess = response => ({
  type: constants.UPGRADE_SITE_PLAN_SUCCESS,
  payload: {
    response,
    message: messages.UPGRADE_SITE_PLAN_SUCCESS,
  },
});

export const upgradeSitePlanFailure = error => ({
  type: constants.UPGRADE_SITE_PLAN_FAILED,
  payload: { error },
});
