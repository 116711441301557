import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

export const StyledWarningIcon = styled(Icon)`
  position: absolute;
  z-index: 999 !important;
  font-size: 14px !important;
  display: inline-block !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
`;

export default StyledWarningIcon;
