import constants from 'dispatcherConst';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case constants.COPY_BUTTON: {
      const {
        projectId,
        pageId,
        buttonId,
        controlType,
      } = action.payload;

      return {
        projectId,
        pageId,
        buttonId,
        controlType,
      };
    }

    case constants.CLEAR_COPY: {
      return {};
    }

    default:
      return state;
  }
};

export default reducer;
