import styled from 'styled-components';
import { Table } from 'semantic-ui-react';

const ActionCell = styled(Table.Cell)`
  border: 0 !important;

  &:first-of-type {
    @media only screen and (max-width: 991px) {
      display: block !important;
      position: relative !important;
      transform: translateY(0px) !important;
      width: 100% !important;
      border-bottom: 0px solid #e6e6e6 !important;
      font-size: 12px !important;
      text-align: inherit !important;
    }

    @media only screen and (max-width: 767px) {
      margin-left: -9px !important;
    }
  }

  @media only screen and (max-width: 991px) {
    padding-left: 0px !important;
    font-size: 12px;
  }

  @media only screen and (max-width: 767px) {
    margin-left: -9px !important;
  }

  &:nth-of-type(1) {
    width: 23% !important;

    @media only screen and (max-width: 991px) {
      width: 100% !important;
    }
  }

  &:nth-of-type(2) {
    width: 30% !important;

    @media only screen and (max-width: 991px) {
      width: 100% !important;
    }
  }

  &:nth-of-type(3) {
    width: 17% !important;

    @media only screen and (max-width: 991px) {
      width: 100% !important;
    }
  }

  &.borderTop {
    border-bottom: 3px solid #e6e6e6 !important;
  }
`;

export default ActionCell;
