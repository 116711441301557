import constants from 'dispatcherConst';

const toggleResourceOpen = resourceId => ({
  type: constants.TOGGLE_RESOURCE_OPEN_PERMISSIONS_TABLE_REQUEST,
  payload: {
    resourceId,
  },
});

export default toggleResourceOpen;
