import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { deviceSelector } from 'helpers/selectors';
import formNames from 'components/form/formNames';
import { validateOnSubmit } from 'components/form/formConfigs';
import updateSettingsActionCreators from 'pages/Device/updateSettingsActionCreators';
import withValueOverride from 'helpers/withValueOverride/withValueOverrideContainer';
import withValueOverrideActionCreators from 'helpers/withValueOverride/withValueOverrideActionCreators';
import DeviceSettingForm from './DeviceSettingForm';

export const onSubmit = (args, _, props) => (
  new Promise((resolveForm, rejectForm) => {
    const {
      resourceId, setting, port, name, updateSetting,
      reset, payload, setOverride, valueSelector, overrideId,
    } = props;
    const value = args[name];
    const payloadValues = (payload) ? payload(value) : {};

    updateSetting(resourceId, {
      setting,
      portId: parseInt(port, 10),
      value,
      resolveForm: () => {
        resolveForm();
        setOverride(overrideId, { [name]: value }, valueSelector);
      },
      rejectForm: () => { rejectForm(); reset(); },
      ...payloadValues,
    });
  })
);

const DeviceSettingFormContainer = reduxForm({
  ...validateOnSubmit,
  enableReinitialize: true,
  onSubmit,
})(DeviceSettingForm);

export const mapStateToProps = (state, { resourceId, port, valueSelector, name, setting }) => {
  const value = valueSelector(state, resourceId);
  const resource = deviceSelector(state)(resourceId);
  return {
    [name]: value,
    initialValues: { [name]: value },
    form: formNames.deviceSettingForm(setting, port),
    overrideId: `override.${resourceId}.${port}.${name}`,
    overrideKey: 'initialValues',
    isOnline: resource.online,
  };
};

const mapDispatchToProps = {
  updateSetting: updateSettingsActionCreators.updateSetting,
  setOverride: withValueOverrideActionCreators.setOverride,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withValueOverride,
)(DeviceSettingFormContainer);
