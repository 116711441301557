import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Range, Direction } from 'react-range';
import isNumber from 'lodash/isNumber';

import {
  calculateColorIntensity,
  transformPercentageToRGB,
} from '../../utilities';
import { StyledBarWrapper } from '../../ColorUI.style';
import { STEP, MIN, MAX, INITIAL_INTENSITY_VALUE } from '../../constants';
import { RenderThumb } from '../index';
import { RenderIntensityTrack } from './components';

const IntensitySlider = ({
  color,
  backgroundColor,
  intensity,
  changeFieldValue,
  actionTypeDeviceIdKey,
}) => {
  const [values, setValues] = useState([INITIAL_INTENSITY_VALUE]);
  const intensityValue = values[0];
  const RGB = transformPercentageToRGB(color);
  const calculatedColor = calculateColorIntensity(RGB, intensityValue);

  useEffect(() => {
    if (isNumber(intensity) && intensity <= MAX && intensity >= MIN) {
      setValues([intensity]);
    }
  }, [intensity]);

  return (
    <StyledBarWrapper>
      <Range
        direction={Direction.Up}
        values={values}
        step={STEP}
        min={MIN}
        max={MAX}
        onChange={slideValue => {
          setValues(slideValue);
        }}
        onFinalChange={slideValue => changeFieldValue(`intensity${actionTypeDeviceIdKey}`, slideValue[0])}
        renderTrack={({ props, children }) => (
          <RenderIntensityTrack props={props} backgroundColor={backgroundColor}>
            {children}
          </RenderIntensityTrack>
        )}
        renderThumb={({ props }) => (
          <RenderThumb props={props} calculatedColor={calculatedColor} key={0} />
        )}
      />
    </StyledBarWrapper>
  );
};

IntensitySlider.defaultProps = {
  backgroundColor: 'rgb(255, 0, 0)',
  color: { r: 255, g: 0, b: 0 },
  changeFieldValue: () => null,
  actionTypeDeviceIdKey: '',
};

IntensitySlider.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.object,
  changeFieldValue: PropTypes.func,
  intensity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  actionTypeDeviceIdKey: PropTypes.string,
};

export default IntensitySlider;
