import React from 'react';
import PropTypes from 'prop-types';

import { RenderValue } from './components';
import ActionRow from '../../../../ActionRow/ActionRow.style';
import { ParamCell } from './RenderParameter.style';

const RenderParameter = ({ labelText, ...rest }) => (
  <ActionRow>
    <ParamCell>{labelText}</ParamCell>
    <ParamCell>
      <RenderValue {...rest} />
    </ParamCell>
  </ActionRow>
);

RenderParameter.propTypes = {
  labelText: PropTypes.string.isRequired,
};

export default RenderParameter;
