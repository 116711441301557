import React from 'react';

import FilesSection from 'components/FilesSection/FilesSectionContainer';

const labels = [
  { text: 'Name', field: 'name', width: 4 },
  { text: 'Uploaded', field: 'uploadedAt', width: 4, sortBy: 'lastModified' },
  { text: 'Size (MB)', field: 'readableSize', width: 3 },
  { text: 'File Type', field: 'fileType', width: 5 },
];

const PathwayFilesV1 = props => <FilesSection {...props} labels={labels} />;

export default PathwayFilesV1;
