import constants from 'dispatcherConst';

const withPollingActionCreators = {
  init: (id, interval = 5000, timeout = 0) => ({
    type: constants.POLLING_INIT_V2,
    payload: { id, interval, timeout },
  }),

  start: id => ({
    type: constants.POLLING_START_V2,
    payload: { id },
  }),

  stop: id => ({
    type: constants.POLLING_STOP_V2,
    payload: { id },
  }),

  terminate: id => ({
    type: constants.POLLING_TERMINATE_V2,
    payload: { id },
  }),

  setInterval: (id, interval) => ({
    type: constants.POLLING_SET_INTERVAL_V2,
    payload: { id, interval },
  }),

};

export default withPollingActionCreators;
