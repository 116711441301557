import { call, put, select, takeLatest } from 'redux-saga/effects';
import merge from 'lodash/merge';

import constants from 'dispatcherConst';
import { messages } from 'data/notifications/notificationsConst';
import { uploadsFilesSelector } from 'helpers/uploadsSelectors';
import { postPath } from 'data/api/requests';
import uploadsActionCreators from 'components/UploadsWidget/uploadsActionCreators';
import { processCall } from 'helpers/sagaHelper';
import { projectSelector, projectSuiteSelector } from 'helpers/selectors';
import notificationsActionCreators from 'data/notifications/notificationsActionCreators';
import projectFileUploadActionCreators from './projectFileUploadActionCreators';

export function generateUploadRequestBody(systemFile, fileTypeId) {
  const { name: fileName } = systemFile;
  const data = [
    {
      id: '0',
      fileName,
      fileTypeId,
    },
  ];

  return { data };
}

export function enhanceUploadResponse(
  response,
  {
    systemFile,
    projectId,
    fileIdentifier,
    fileTypeId,
    resourceType,
  },
) {
  if (!response) {
    return undefined;
  }

  const {
    [response.data.type]: {
      [response.data.id]: {
        attributes,
      },
    },
  } = response;

  return merge(
    {},
    response,
    {
      [response.data.type]: {
        [fileIdentifier]: {
          id: fileIdentifier,
          attributes: {
            ...attributes,
            systemFile,
          },
        },
      },
      projectId,
      systemFile,
      fileTypeId,
      resourceType,
    },
  );
}

export function* prepareUpload({ payload }) {
  const {
    endpoint,
    projectId,
    params: { systemFile, fileIdentifier, fileTypeId, resolveForm, rejectForm },
    resourceType,
  } = payload;

  const body = yield call(generateUploadRequestBody, systemFile, fileTypeId);
  const { response, error } = yield call(postPath, endpoint, body);
  const enhancedResponse = yield call(
    enhanceUploadResponse,
    response,
    {
      systemFile,
      projectId,
      fileIdentifier,
      fileTypeId,
      resourceType,
    },
  );

  const params = {
    response: enhancedResponse,
    error,
    resolveForm,
    rejectForm,
    successDisp: projectFileUploadActionCreators.prepareUploadSuccess,
    failDisp: notificationsActionCreators.addGeneralError,
  };
  yield call(processCall, params);
}

export function attachAttributes(file, awsAttributes) {
  const { bucket, path, systemFile } = file;
  const { accessKeyId, projectName } = awsAttributes;

  return {
    fileKey: `${bucket}/${path}`,
    accessKeyId,
    bucket,
    path,
    systemFile,
    device: 'N/A',
    project: projectName,
  };
}

export function* filterOutRunningUploads({ fileKey }) {
  const { [fileKey]: runningUpload } = yield select(uploadsFilesSelector);

  if (runningUpload) {
    return true;
  }

  return false;
}

export function* prepareUploadSuccess(action) {
  const {
    response: {
      data: {
        attributes: {
          files,
          ...attributes
        },
      },
      projectId,
      systemFile,
      fileTypeId,
      resourceType,
    },
  } = action.payload;

  const selector = resourceType === 'project' ? projectSelector : projectSuiteSelector;

  const { name: projectName } = (yield select(selector))(projectId);
  const fileWithAttributes = attachAttributes(
    { ...files[0], projectId, systemFile },
    { ...attributes, projectName },
  );
  const running = yield call(filterOutRunningUploads, fileWithAttributes);
  if (running) {
    yield put(
      notificationsActionCreators.addInfoNotification(
        messages.UPLOAD_OMITTED([fileWithAttributes]),
      ),
    );
  }

  yield put(uploadsActionCreators.enqueueUploadAtEnd([{
    ...fileWithAttributes,
    fileTypeId,
  }]));
}

function* newUploadSaga() {
  yield takeLatest(constants.UPLOAD_PROJECT_PREPARE_REQUEST, prepareUpload);
  yield takeLatest(constants.UPLOAD_PROJECT_PREPARE_SUCCESS, prepareUploadSuccess);
}

export default newUploadSaga;
