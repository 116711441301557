import styled from 'styled-components';
import { Label, Input } from 'semantic-ui-react';

export const Container = styled(Label)`
  &&& {
    width: 98%;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    border: none;
    border-bottom: 1px solid rgba(34, 36, 38, .15);  
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }  
`;

export const StyledInput = styled(Input)`
  width: 100% !important;

  &&& {
    &.ui.input > input {
      border: 1px dashed rgba(0,0,0, 0.6) !important;
      padding: 0 !important;
      font-weight: bold !important;
      padding-top: 8px !important;
      padding-bottom: 8px !important;
      padding-left: 8px !important;
    }
  }
`;

export default Container;
