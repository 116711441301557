import { all, call, put, select, takeEvery, takeLatest, race, take, delay } from 'redux-saga/effects';
import difference from 'lodash/difference';
import filter from 'lodash/fp/filter';
import flow from 'lodash/flow';
import get from 'lodash/get';
import groupBy from 'lodash/fp/groupBy';
import map from 'lodash/fp/map';
import mapValues from 'lodash/fp/mapValues';

import constants from 'dispatcherConst';
import { postPath } from 'data/api/requests';
import { messages } from 'data/notifications/notificationsConst';
import { processCall } from 'helpers/sagaHelper';
import {
  pollingRefreshLoaderSelector,
  pollingSelector,
  pollingTimeoutSelector,
  pollingTimeoutCounterSelector,
  pollingUpdatingSelector,
  tableNameSelector,
} from 'helpers/selectors';
import ApiRequestBuilder from 'helpers/ApiRequestBuilder';
import notificationsActionCreators from 'data/notifications/notificationsActionCreators';
import { RateEnum } from './PollingInterface/PollingInterface';
import pollingActionCreators from './pollingActionCreators';

const mapValuesWithKey = mapValues.convert({ cap: false });

export function* notifyAboutUpdateSwitchOff(resourceType) {
  const tableName = yield select(tableNameSelector, {
    resourceType,
  });

  yield put(notificationsActionCreators.addInfoNotification(
    messages.TABLE_UPDATE_SWITCH_OFF(tableName)),
  );
}

export function* triggerResourceUpdate({ payload }) {
  const {
    endpoint,
    loader,
    refreshLoader,
    timeout,
    parameters,
  } = payload;

  const body = new ApiRequestBuilder(loader).setAttributes({
    timeout,
    parameters,
  });
  const { response, error } = yield call(postPath, endpoint, body);

  const params = {
    response,
    error,
    successDisp: pollingActionCreators.resourceUpdateSuccess,
    failDisp: pollingActionCreators.resourceUpdateFailure,
  };
  yield call(processCall, params);

  if (response) {
    yield put(pollingActionCreators.setRefreshRate(refreshLoader, { value: 1 }));
  } else {
    yield put(pollingActionCreators.stopRefreshing(loader));
    yield call(notifyAboutUpdateSwitchOff, loader);
  }
}

export function* handleResourceFetchError({ payload }) {
  const { resourceType } = payload;

  yield put(pollingActionCreators.setRefreshRate(resourceType, {
    value: 0,
    uiValue: RateEnum.OFF,
  }));
  yield call(notifyAboutUpdateSwitchOff, resourceType);
}

export function* stopRefreshProcess(loader) {
  const refreshLoader = yield select(pollingRefreshLoaderSelector, loader);
  if (!refreshLoader) return;

  yield put(pollingActionCreators.setRefreshRate(refreshLoader, {
    value: 0,
  }));
  yield put(pollingActionCreators.stopRefreshing(loader));
}

export function* handleDeviceFetchError() {
  const updating = yield select(pollingUpdatingSelector);
  yield all(updating.map(loader => call(stopRefreshProcess, loader)));
}

export function* stopRefreshLoaderIfNoPending(loader) {
  const polling = yield select(pollingSelector);
  const pendingRequests = flow(
    mapValuesWithKey((o, key) => ({ ...o, key })),
    Object.values,
    filter(o => o.refreshing),
  )(polling);

  const refreshLoader = yield select(pollingRefreshLoaderSelector, loader);
  const groupedRequests = groupBy('refreshLoader')(pendingRequests);
  const refreshLoaderRequests = groupedRequests[refreshLoader];

  if (refreshLoaderRequests.length === 1) {
    yield put(pollingActionCreators.setRefreshRate(refreshLoader, {
      value: 0,
    }));
  }
}

export function* checkIfReachedTimeout(loader) {
  const timeoutCounter = yield select(pollingTimeoutCounterSelector, loader);
  const timeout = yield select(pollingTimeoutSelector, loader);
  if (timeoutCounter < timeout) return;

  yield call(stopRefreshLoaderIfNoPending, loader);
  yield put(pollingActionCreators.stopRefreshing(loader));
  yield put(pollingActionCreators.timeout(loader));
  yield put(notificationsActionCreators.addGeneralError(messages.ERROR_FETCH_DEVICE));
}

export function* stopRefreshLoaderIfUpdatesFulfilled(updated, [refreshLoader, requestIds]) {
  const fulfilled = difference(requestIds, updated).length === 0;
  if (!fulfilled) return;

  yield put(pollingActionCreators.setRefreshRate(refreshLoader, {
    value: 0,
  }));
}

export function* updateLoadersState(updated) {
  const polling = yield select(pollingSelector);
  const pendingRequests = flow(
    mapValuesWithKey((o, key) => ({ ...o, key })),
    Object.values,
    filter(o => (o.updated || o.refreshing) && o.requestId),
  )(polling);

  const groupedRequests = flow(
    groupBy('refreshLoader'),
    mapValues(map(item => item.requestId)),
  )(pendingRequests);

  const requestsEntries = Object.entries(groupedRequests);
  yield all(requestsEntries.map(entry => (
    call(stopRefreshLoaderIfUpdatesFulfilled, updated, entry)
  )));

  const updatedRequests = filter(o => !o.refreshing)(pendingRequests);
  yield all(updatedRequests.map(resourceInfo => (
    put(pollingActionCreators.stopRefreshing(resourceInfo.key))
  )));
}

export function* handleDeviceFetchSuccess({ payload }) {
  const updated = get(payload, 'response.data.attributes.updated');
  if (!updated) return;

  const updating = yield select(pollingUpdatingSelector);
  if (!updating.length) return;

  yield all(updating.map(loader => call(checkIfReachedTimeout, loader)));
  yield call(updateLoadersState, updated);
}


export function* initPolling(action) {
  const {
    loader,
    refreshRate,
    initRefresh,
    timeout,
    parameters,
    resourceId,
    refreshLoader,
  } = action.payload;

  yield put(pollingActionCreators.setRefreshRate(loader, refreshRate));


  if (initRefresh) {
    yield put(pollingActionCreators.resourceUpdate(loader, refreshLoader, {
      timeout,
      parameters,
      resourceId,
    }));
    yield put(pollingActionCreators.startRefreshing(loader));

    const { failure } = yield race({
      success: delay(60 * 1000),
      failure:
        take(a => a.type === constants.POLLING_TIMEOUT && a.payload.loader === loader),
    });

    if (failure) {
      yield put(pollingActionCreators.setRefreshRate(loader, { value: 0, uiValue: 'off' }));
      yield put(pollingActionCreators.setRefreshRate(refreshLoader, { value: 0 }));

    }
  }


}

function* pollingSaga() {
  yield takeEvery(constants.POLLING_INIT, initPolling);
  yield takeEvery(constants.POLLING_RESOURCE_UPDATE_REQUEST, triggerResourceUpdate);
  yield takeLatest(constants.FETCH_OUTPUT_FAILED, handleResourceFetchError);
  yield takeEvery(constants.FETCH_DEVICE_FAILED, handleDeviceFetchError);
  yield takeEvery(constants.FETCH_DEVICE_SUCCESS, handleDeviceFetchSuccess);
}

export default pollingSaga;
