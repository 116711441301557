import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { deepEqualMemoizeOne } from 'helpers/sharedMethods';
import {
  singleProjectSelector,
  singleProjectSuiteSelector,
  siteAutomatedOperationsOccurrencesDateRangeSelector,
  superSiteAutomatedOperationsOccurrencesDateRangeSelector,
} from 'helpers/selectors';
import { calculateModifiers } from './utilities';
import { monthDateRange, selectors } from '../../../../utilities';

import CalendarView from './CalendarView';

export const makeMapState = () => {
  const memoSiteTime = deepEqualMemoizeOne();
  const memoModifiers = deepEqualMemoizeOne();
  const memoOccurrences = deepEqualMemoizeOne();

  return function mapStateToProps(state, { match, active, resourceType }) {
    const SINGLE_RESOURCE_SELECTOR = {
      project: singleProjectSelector,
      projectSuite: singleProjectSuiteSelector,
    };

    const OCCURRENCES_SELECTOR = {
      project: siteAutomatedOperationsOccurrencesDateRangeSelector,
      projectSuite: superSiteAutomatedOperationsOccurrencesDateRangeSelector,
    };

    const { id: resourceId } = match.params;

    const resourceSelector = SINGLE_RESOURCE_SELECTOR[resourceType];
    const occurrencesSelector = OCCURRENCES_SELECTOR[resourceType];

    const { datetime: siteTime } = resourceSelector(state, resourceId);
    const { start, end } = monthDateRange(active);
    const occurrences = occurrencesSelector(state, resourceId, start, end);

    const modifiers = calculateModifiers(state, resourceId, siteTime, active, occurrencesSelector);

    const { fetchingOccurrencesSelector, schedulesUpdatingSelector } = selectors;
    const isLoading = fetchingOccurrencesSelector(state) && !schedulesUpdatingSelector(state);

    return {
      siteTime: memoSiteTime(siteTime),
      modifiers: memoModifiers(modifiers),
      occurrences: memoOccurrences(occurrences),
      isLoading,
    };
  };
};

export default compose(
  withRouter,
  connect(makeMapState),
)(CalendarView);
