import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Responsive } from 'semantic-ui-react';
import classNames from 'classnames';

import { sizes } from 'themes/mixins';
import UploadsStats from 'components/UploadsWidget/UploadsStats/UploadsStatsContainer';
import UploadsControls from 'components/UploadsWidget/UploadsControls/UploadsControlsContainer';
import UploadsOverallProgress
  from 'components/UploadsWidget/UploadsOverallProgress/UploadsOverallProgressContainer';
import { StyledUploads, UploadsPanel } from './UploadsController.style';

const UploadsController = ({ className, children, open, onClick, onClearCompleted }) => {
  const iconDirection = open ? 'down' : 'up';

  const handleClose = () => {
    onClearCompleted();
  };

  return (
    <StyledUploads className={classNames({ open, [className]: !!className })}>
      <UploadsPanel className={classNames({ compact: !open })}>
        <UploadsPanel.Content>
          <UploadsPanel.InfoControls>
            <UploadsPanel.Info onClick={onClick}>
              <h3>File upload progress</h3>
              <UploadsStats />
            </UploadsPanel.Info>
            <UploadsPanel.Controls>
              <UploadsPanel.Icons>
                <Responsive maxWidth={sizes.md - 1}>
                  <Icon name={`chevron ${iconDirection}`} circular link onClick={onClick} />
                </Responsive>
                { !open && <Icon name="close" circular link onClick={handleClose} /> }
              </UploadsPanel.Icons>
              {open && <UploadsControls />}
            </UploadsPanel.Controls>
          </UploadsPanel.InfoControls>
          <div>
            <UploadsPanel.Description>
              <UploadsOverallProgress
                className={classNames({ micro: open })}
                size="tiny"
              />
            </UploadsPanel.Description>
          </div>
        </UploadsPanel.Content>
      </UploadsPanel>
      {open && children}
    </StyledUploads>
  );
};

UploadsController.defaultProps = {
  className: '',
  children: null,
  open: false,
  onClick: null,
};

UploadsController.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool,
  onClick: PropTypes.func,
  onClearCompleted: PropTypes.func.isRequired,
};

export default UploadsController;
