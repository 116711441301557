import isNil from 'lodash/isNil';

import { dropdownSelects } from '../constants';
import computeFilterKeyValue from './computeFilterKeyValue';

const computeFiltersObject = (value, filterParams, text) => {
  if (isNil(text)) {
    return computeFilterKeyValue(value, filterParams, 'search');
  }

  if (!isNil(text)) {
    switch (text) {
      case dropdownSelects.ACTION_TYPES: {
        return computeFilterKeyValue(value, filterParams, 'actionType');
      }

      case dropdownSelects.INITIATORS: {
        return computeFilterKeyValue(value, filterParams, 'initiator');
      }

      case dropdownSelects.RECIPIENTS: {
        return computeFilterKeyValue(value, filterParams, 'recipient');
      }

      case dropdownSelects.RESOURCES: {
        return computeFilterKeyValue(value, filterParams, 'resource');
      }

      default:
        return { ...filterParams };
    }
  }

  return {};
};

export default computeFiltersObject;
