import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';

export const grantMultiplePermissions = (data = {}) => ({
  type: constants.GRANT_MULTIPLE_PERMISSIONS_REQUEST,
  payload: {
    endpoint: endpoints.GRANT_PERMISSION,
    data,
  },
});

export const grantMultiplePermissionsSuccess = response => ({
  type: constants.GRANT_MULTIPLE_PERMISSIONS_SUCCESS,
  payload: {
    response,
  },
});

export const grantMultiplePermissionsFailure = error => ({
  type: constants.GRANT_MULTIPLE_PERMISSIONS_FAILED,
  payload: { error },
});

export default grantMultiplePermissions;
