import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import onMountUnmount from 'helpers/onMountUnmount';
import websocketActionCreators from 'data/websockets/websocketsActionCreators';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import {
  REMOTE_DEVICES_FIELD,
} from 'helpers/selectors';
import { addPagination } from 'helpers/paginationHelpers';
import PharosRemoteDevicesV1 from './PharosRemoteDevicesV1';
import { actionCreators as remoteDevicesActionCreators } from './utilities';

export const mapStateToProps = (state, { match }) => {
  const { deviceId } = match.params;

  return {
    deviceId,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: REMOTE_DEVICES_FIELD, included: true },
      ({ deviceId }) => remoteDevicesActionCreators.fetchRemoteDevices(deviceId),
    ),
  ),
  onMountUnmount(
    ({ deviceId }) => websocketActionCreators.subscribeDeviceData(deviceId, 'remote_devices'),
    ({ deviceId }) => websocketActionCreators.unsubscribeDeviceData(deviceId, 'remote_devices'),
  ),
)(PharosRemoteDevicesV1);
