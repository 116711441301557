import constants from 'dispatcherConst';

const copyButton = ({
  projectId,
  pageId,
  buttonId,
  controlType = 'button',
}) => ({
  type: constants.COPY_BUTTON,
  payload: {
    projectId,
    pageId,
    buttonId,
    controlType,
  },
});

export default copyButton;
