import { compose } from 'redux';
import { getFormValues, change, registerField, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import formNames from 'components/form/formNames';
import tasksActionCreators from 'application/tenant/utilities/tasks/tasksActionCreators';

import OverrideRGBControls from './OverrideRGBControls';

const OverrideRGBControlsContainer = reduxForm({
  form: formNames.quickActionOverrideRGB,
  enableReinitialize: true,
  validate: () => {},
})(OverrideRGBControls);

export const mapStateToProps = state => ({
  formValues: getFormValues(formNames.quickActionOverrideRGB)(state),
});

export const mapDispatchToProps = dispatch => ({
  resetFieldValue: field => {
    dispatch(change(formNames.quickActionOverrideRGB, field, null));
  },
  changeFieldValue: (field, value) => {
    dispatch(change(formNames.quickActionOverrideRGB, field, value));
  },
  registerField: field => {
    dispatch(registerField(formNames.quickActionOverrideRGB, field, 'Field'));
  },
  onExecuteActions: args => {
    dispatch(tasksActionCreators.executeActions(args));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(OverrideRGBControlsContainer);
