import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';

import { complexValueType } from 'data/types';
import TextDescriptionItem
  from 'components/DescriptionList/TextDescriptionItem/TextDescriptionItem';
import BoolDescriptionItem
  from 'components/DescriptionList/BoolDescriptionItem/BoolDescriptionItem';

const PharosConfigurationV1 = props => {
  const { loading } = props;
  if (loading) return null;

  const {
    title,
    watchdog,
    sntpAddress,
    sntpOn,
    logLevel,
    syslogAddress,
    syslogOn,
  } = props;

  return (
    <Segment>
      <Header as="h2">{title}</Header>
      <BoolDescriptionItem
        title="Watchdog on"
        value={watchdog}
      />
      <BoolDescriptionItem
        title="Sync time with SNTP"
        value={sntpOn}
      />
      <TextDescriptionItem
        title="SNTP Server IP address"
        value={sntpAddress}
      />
      <TextDescriptionItem
        title="Log level"
        value={logLevel}
      />
      <BoolDescriptionItem
        title="Remote logging via Syslog"
        value={syslogOn}
      />
      <TextDescriptionItem
        title="Syslog Server IP address"
        value={syslogAddress}
      />
    </Segment>
  );
};

PharosConfigurationV1.defaultProps = {
  loading: false,
  watchdog: {},
  sntpAddress: {},
  sntpOn: {},
  logLevel: {},
  syslogAddress: {},
  syslogOn: {},
};

PharosConfigurationV1.propTypes = {
  loading: PropTypes.bool,
  watchdog: complexValueType(PropTypes.bool),
  sntpAddress: complexValueType(PropTypes.string),
  sntpOn: complexValueType(PropTypes.bool),
  logLevel: complexValueType(PropTypes.string),
  syslogAddress: complexValueType(PropTypes.string),
  syslogOn: complexValueType(PropTypes.bool),
  title: PropTypes.string.isRequired,
};

export default PharosConfigurationV1;
