import has from 'lodash/has';

const copyRecipientIdSelector = (state, resourceId) => {
  const { copyRecipientId } = state.permissionsTable;

  if (!has(copyRecipientId, resourceId)) {
    return null;
  }

  return copyRecipientId[resourceId];
};

export default copyRecipientIdSelector;
