import isEmpty from 'lodash/isEmpty';
import pluralize from 'pluralize';

const getDefaultTaskName = selectedActions => {
  if (isEmpty(selectedActions)) {
    return '';
  }

  const actionNames = [...new Set(selectedActions.map(action => action.actionName))];
  const deviceNames = [...new Set(selectedActions.map(action => action.deviceName))];

  const formattedActionNames = actionNames.slice(0, 3).join(', ');
  const formattedDeviceNames = deviceNames.slice(0, 3).join(', ');
  const deviceString = pluralize('device', deviceNames.length);

  return `${formattedActionNames} ${deviceString} ${formattedDeviceNames}`;
};

export default getDefaultTaskName;
