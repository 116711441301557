import constants from 'dispatcherConst';
import endpoints from 'data/api/endpoints';
import { messages } from 'data/notifications/notificationsConst';

export const editOrigin = params => ({
  type: constants.EDIT_ORIGIN_REQUEST,
  payload: {
    endpoint: endpoints.ORIGIN(params.values.id),
    params,
  },
});

export const editOriginSuccess = response => ({
  type: constants.EDIT_ORIGIN_SUCCESS,
  payload: {
    message: messages.DATA_SAVED,
    response,
  },
});

export const editOriginFailure = error => ({
  type: constants.EDIT_ORIGIN_FAILED,
  payload: { error },
});
