import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Dropdown, FormGroup } from 'semantic-ui-react';

import * as validators from 'components/form/formValidators';
import TextField from 'components/form/TextField/TextField';
import ModalForm from 'components/form/ModalForm/ModalForm';
import ToggleField from 'components/form/ToggleField/ToggleField';
import DropdownField from 'components/form/DropdownField/DropdownField';
import { ButtonLink } from 'components/elements';

import { automatedOperationFormFields, colorList, priorities, modeOptions } from '../../../../constants';
import SelectColor from '../../../SelectColor/SelectColor';
import PriorityType from '../../../PriorityType/PriorityType';

const EditAutomatedOperationModalForm = ({
  submitting,
  change,
  item: {
    name,
    occurrenceColour,
    priorityType,
  },
  mobileVersion,
  ...rest
}) => {
  const header = `Edit ${name}`;
  const trigger = mobileVersion
    ? <Dropdown.Item>Edit</Dropdown.Item>
    : <ButtonLink>Edit</ButtonLink>;

  return (
    <ModalForm
      header={header}
      trigger={trigger}
      size="small"
      submitting={submitting}
      {...rest}
    >
      <FormGroup inline>
        <Field
          name={automatedOperationFormFields.name}
          component={TextField}
          type="text"
          loading={submitting}
          label="Name"
          validate={validators.validDisplayName}
        />
      </FormGroup>
      <FormGroup inline>
        <Field
          name={automatedOperationFormFields.description}
          component={TextField}
          type="text"
          loading={submitting}
          label="Description"
        />
      </FormGroup>
      <FormGroup
        inline
      >
        <Field
          name={automatedOperationFormFields.occurrenceColor}
          component={SelectColor}
          type="text"
          loading={submitting}
          label="Future Occurrence Colour"
          colorList={colorList}
          existingColor={occurrenceColour}
        />
      </FormGroup>
      <FormGroup inline>
        <Field
          name={automatedOperationFormFields.priority}
          component={DropdownField}
          loading={submitting}
          label="Priority"
          options={priorities}
        />
      </FormGroup>
      <FormGroup inline>
        <Field
          name={automatedOperationFormFields.mode}
          component={DropdownField}
          loading={submitting}
          label="Offline Device protection"
          options={modeOptions}
        />
      </FormGroup>
      <FormGroup inline>
        <Field
          name={automatedOperationFormFields.priorityType}
          component={PriorityType}
          type="checkbox"
          label="Date Blocking"
          priorityType={priorityType}
        />
      </FormGroup>
      <FormGroup inline>
        <Field
          name={automatedOperationFormFields.hidden}
          component={ToggleField}
          type="checkbox"
          loading={submitting}
          label="Hide Occurrences"
          hideRadioLabel
          alignItems="center"
        />
      </FormGroup>
      <FormGroup inline>
        <Field
          name={automatedOperationFormFields.enabled}
          component={ToggleField}
          type="checkbox"
          loading={submitting}
          label="Enabled"
          hideRadioLabel
          alignItems="center"
        />
      </FormGroup>
    </ModalForm>
  );
};

EditAutomatedOperationModalForm.defaultProps = {
  editMode: false,
  mobileVersion: false,
  change: () => null,
};

EditAutomatedOperationModalForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  editMode: PropTypes.bool,
  mobileVersion: PropTypes.bool,
  change: PropTypes.func,
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    occurrenceColour: PropTypes.string,
    priorityType: PropTypes.string,
  }).isRequired,
};

export default EditAutomatedOperationModalForm;
