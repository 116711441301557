import React from 'react';
import PropTypes from 'prop-types';

import InlineEdit from 'components/form/InlineEdit/InlineEditContainer';
import { StyledWrapper, StyledLabel } from './DataTableEditableWithLabel.style';

const DataTableEditableWithLabel = ({
  field,
  item: { id },
  item,
  validator,
  type,
  onSubmit,
  preventPropagation,
  labelName,
}) => (
  <>
    <StyledWrapper>
      <InlineEdit
        name={field}
        value={item[field]}
        onSubmit={onSubmit(id)}
        validator={validator}
        type={type}
        form={`inlineEdit_${field}_${id}`}
        preventPropagation={preventPropagation}
      />
    </StyledWrapper>
    <StyledLabel htmlFor="superSite">{labelName}</StyledLabel>
  </>
);

DataTableEditableWithLabel.defaultProps = {
  field: null,
  item: {},
  type: undefined,
  validator: null,
  preventPropagation: false,
  labelName: '',
};

DataTableEditableWithLabel.propTypes = {
  field: PropTypes.string,
  item: PropTypes.object,
  validator: PropTypes.func,
  type: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  preventPropagation: PropTypes.bool,
  labelName: PropTypes.string,
};

export default DataTableEditableWithLabel;
