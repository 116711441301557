import constants from 'dispatcherConst';

const clearPermissionsTable = ({
  resourceId,
  parentResourceId,
  childResourceIds,
}) => ({
  type: constants.CLEAR_PERMISSIONS_TABLE_REQUEST,
  payload: {
    resourceId,
    parentResourceId,
    childResourceIds,
  },
});

export default clearPermissionsTable;
