export default {
  // Common
  RESOURCE_NOT_FOUND: Symbol('resourceNotFound'),

  // Theme
  FETCH_CURRENT_COMPANY_REQUEST: Symbol('fetchCurrentCompanyRequest'),
  FETCH_CURRENT_COMPANY_SUCCESS: Symbol('fetchCurrentCompanySuccess'),
  FETCH_CURRENT_COMPANY_FAILED: Symbol('fetchCurrentCompanyFailed'),

  // Login
  LOGIN: Symbol('login'),
  LOGIN_SUCCESS: Symbol('loginSuccess'),
  LOGIN_FAILED: Symbol('loginFailed'),
  LOGIN_REQUEST: 'loginRequest',
  LOGOUT: Symbol('logout'),
  FETCH_CURRENT_USER: Symbol('fetchCurrentUser'),
  FETCH_CURRENT_USER_FAILED: Symbol('fetchCurrentUserFailed'),
  FETCH_CURRENT_USER_SUCCESS: Symbol('fetchCurrentUserSuccess'),
  LOGOUT_ERROR: Symbol('logoutError'),

  // Two factor authentication
  FETCH_TWO_FACTOR_QR_CODE: Symbol('fetchTwoFactorQRCode'),
  FETCH_TWO_FACTOR_QR_CODE_SUCCESS: Symbol('fetchTwoFactorQRCodeSuccess'),
  FETCH_TWO_FACTOR_QR_CODE_FAILED: Symbol('fetchTwoFactorQRCodeFailed'),
  SUBMIT_ONE_TIME_PASSWORD: Symbol('submitOneTimePassword'),
  SUBMIT_ONE_TIME_PASSWORD_SUCCESS: Symbol('submitOneTimePasswordSuccess'),
  SUBMIT_ONE_TIME_PASSWORD_FAILED: Symbol('submitOneTimePasswordFailed'),
  RESET_OTP_SECRET: Symbol('resetOTPSecret'),
  RESET_OTP_SECRET_SUCCESS: Symbol('resetOTPSecretSuccess'),
  RESET_OTP_SECRET_FAILED: Symbol('resetOTPSecretFailed'),
  REFRESH_OTP_SECRET: Symbol('refreshOTPSecret'),
  REFRESH_OTP_SECRET_SUCCESS: Symbol('refreshOTPSecretSuccess'),
  REFRESH_OTP_SECRET_FAILED: Symbol('refreshOTPSecretFailed'),
  DISABLE_TWO_FACTOR: Symbol('disableTwoFactor'),
  DISABLE_TWO_FACTOR_SUCCESS: Symbol('disableTwoFactorSuccess'),
  DISABLE_TWO_FACTOR_FAILED: Symbol('disableTwoFactorFailed'),

  // Forgot password
  RESET_PASSWORD_SEND_TOKEN: Symbol('resetPasswordSendToken'),
  RESET_PASSWORD_SEND_TOKEN_SUCCESS: Symbol('resetPasswordSendTokenSuccess'),
  RESET_PASSWORD_SEND_TOKEN_FAILED: Symbol('resetPasswordSendTokenFailed'),

  RESET_PASSWORD_VERIFY_TOKEN: Symbol('resetPasswordVerifyToken'),
  RESET_PASSWORD_VERIFY_TOKEN_SUCCESS: Symbol('resetPasswordVerifyTokenSuccess'),

  RESET_PASSWORD: Symbol('resetPassword'),
  RESET_PASSWORD_SUCCESS: Symbol('resetPasswordSuccess'),

  // Profile
  CHANGE_PASSWORD_REQUEST: Symbol('changePasswordRequest'),
  CHANGE_PASSWORD_REQUEST_SUCCESS: Symbol('changePasswordRequestSuccess'),
  CHANGE_PASSWORD_REQUEST_FAILED: Symbol('changePasswordRequestFailed'),

  // Sign up
  INVITATION_TOKEN_VERIF: 'invitationTokenVerification',
  INVITATION_TOKEN_VERIF_SUCCESS: 'invitationTokenVerificationSuccess',
  INVITATION_TOKEN_VERIF_FAILED: 'invitationTokenVerificationFailed',

  SIGN_UP_REQUEST: 'signUpRequest',
  SIGN_UP_REQUEST_SUCCESS: 'signUpRequestSuccess',
  SIGN_UP_REDIRECTION: 'signUpRedirection',

  // Sidebar
  SHOW_SIDEBAR: Symbol('showSidebar'),
  HIDE_SIDEBAR: Symbol('hideSidebar'),
  BLOCK_SIDEBAR: Symbol('blockSidebar'),
  UNBLOCK_SIDEBAR: Symbol('unblockSidebar'),

  // Projects
  FETCH_PROJECTS_REQUEST: Symbol('fetchProjectsRequest'),
  FETCH_PROJECTS_SUCCESS: Symbol('fetchProjectsSuccess'),
  FETCH_PROJECTS_FAILED: Symbol('fetchProjectsFailed'),

  FETCH_PROJECT_REQUEST: Symbol('fetchProjectRequest'),
  FETCH_PROJECT_SUCCESS: Symbol('fetchProjectSuccess'),
  FETCH_PROJECT_FAILED: Symbol('fetchProjectFailed'),

  ADD_PROJECT_REQUEST: Symbol('addProjectRequest'),
  ADD_PROJECT_SUCCESS: Symbol('addProjectSuccess'),
  ADD_PROJECT_FAILED: Symbol('addProjectFailed'),

  DELETE_PROJECT_REQUEST: Symbol('deleteProjectsRequest'),
  DELETE_PROJECT_SUCCESS: Symbol('deleteProjectSuccess'),
  DELETE_PROJECT_FAILED: Symbol('deleteProjectFailed'),

  REMOVE_USER_FROM_PROJECT: Symbol('removeUserFromProject'),
  REMOVE_USER_FROM_PROJECT_SUCCESS: Symbol('removeUserFromProjectSuccess'),
  REMOVE_USER_FROM_PROJECT_FAILED: Symbol('removeUserFromProjectFailed'),

  FETCH_REVERSE_GEOCODING_REQUEST: Symbol('fetchReverseGeocodingRequest'),
  FETCH_REVERSE_GEOCODING_SUCCESS: Symbol('fetchReverseGeocodingSuccess'),

  UPDATE_SITE_REQUEST: Symbol('updateSiteRequest'),
  UPDATE_SITE_LOCATION_REQUEST: Symbol('updateSiteLocationRequest'),
  UPDATE_SITE_SUCCESS: Symbol('updateSiteSuccess'),
  UPDATE_SITE_FAILED: Symbol('updateSiteFailed'),

  UNABLE_TO_FETCH_LOCATION: Symbol('unableToFetchLocation'),

  FETCH_AUTOMATED_OPERATION_OCCURRENCES: Symbol('fetchAutomatedOperationOccurrences'),
  FETCH_AUTOMATED_OPERATION_OCCURRENCES_SUCCESS: Symbol('fetchAutomatedOperationOccurrencesSuccess'),
  FETCH_AUTOMATED_OPERATION_OCCURRENCES_FAILED: Symbol('fetchAutomatedOperationOccurrencesFailed'),

  UPDATE_OCCURRENCES: Symbol('updateOccurrences'),

  TOGGLE_SHOW_HIDDEN_OCCURRENCES: Symbol('toggleShowHiddenOccurrences'),
  TOGGLE_SHOW_HIDE_MULTI_SITE_OCCURRENCES: Symbol('toggleShowHideMultiSiteOccurrences'),

  FETCH_PROJECTS_STATUS_REQUEST: Symbol('fetchProjectsStatusRequest'),
  FETCH_PROJECTS_STATUS_SUCCESS: Symbol('fetchProjectsStatusSuccess'),
  FETCH_PROJECTS_STATUS_FAILED: Symbol('fetchProjectsStatusFailed'),

  // TASKS
  FETCH_TASKS_REQUEST: Symbol('fetchTasksRequest'),
  FETCH_TASKS_SUCCESS: Symbol('fetchTasksSuccess'),
  FETCH_TASKS_FAILED: Symbol('fetchTasksFailed'),
  FETCH_TASKS_PAGINATED: Symbol('fetchTasksPaginated'),

  ADD_TASK_REQUEST: Symbol('addTaskRequest'),
  ADD_TASK_SUCCESS: Symbol('addTaskSuccess'),
  ADD_TASK_FAILED: Symbol('addTaskFailed'),

  REMOVE_TASK_REQUEST: Symbol('removeTaskRequest'),
  REMOVE_TASK_SUCCESS: Symbol('removeTaskSuccess'),
  REMOVE_TASK_FAILED: Symbol('removeTaskFailed'),
  REMOVE_TASK_FAILED_LOCK_VERSION: Symbol('removeTaskFailedLockVersion'),

  EDIT_TASK_REQUEST: Symbol('editTaskRequest'),

  TRIGGER_TASK_REQUEST: Symbol('triggerTaskRequest'),
  TRIGGER_TASK_SUCCESS: Symbol('triggerTaskSuccess'),
  TRIGGER_TASK_FAILED: Symbol('triggerTaskFailed'),

  TRIGGER_MULTIPLE_TASKS_REQUEST: Symbol('triggerMultipleTasksRequest'),
  TRIGGER_MULTIPLE_TASKS_SUCCESS: Symbol('triggerMultipleTasksSuccess'),
  TRIGGER_MULTIPLE_TASKS_FAILED: Symbol('triggerMultipleTasksFailed'),

  UPDATE_TASK_PROPERTY_REQUEST: Symbol('updateTaskPropertyRequest'),
  UPDATE_ACTION_PROPERTY_REQUEST: Symbol('updateActionPropertyRequest'),

  // ACTIONS
  ADD_ACTION_REQUEST: Symbol('addActionRequest'),
  ADD_ACTION_SUCCESS: Symbol('addActionSuccess'),
  ADD_ACTION_FAILED: Symbol('addActionFailed'),
  ADD_ACTION_INCOMPLETE_FORM: Symbol('addActionIncompleteForm'),
  REMOVE_ACTION_REQUEST: Symbol('removeActionRequest'),
  REMOVE_ACTION_SUCCESS: Symbol('removeActionSuccess'),
  REMOVE_ACTION_FAILED: Symbol('removeActionFailed'),
  SELECTION_REACHED_MAX: Symbol('selectionReachedMax'),
  EDIT_ACTION_REQUEST: Symbol('editActionRequest'),
  EDIT_ACTION_SUCCESS: Symbol('editActionSuccess'),
  EDIT_ACTION_FAILED: Symbol('editActionFailed'),
  UPDATE_TASK_REQUEST: Symbol('updateTaskRequest'),
  UPDATE_TASK_SUCCESS: Symbol('updateTaskSuccess'),
  UPDATE_TASK_FAILED: Symbol('updateTaskFailed'),
  EXECUTE_ACTIONS_REQUEST: Symbol('executeActionsRequest'),
  EXECUTE_ACTIONS_SUCCESS: Symbol('executeActionsSuccess'),
  EXECUTE_ACTIONS_FAILED: Symbol('executeActionsFailed'),

  // AUTOMATED OPERATIONS
  FETCH_AUTOMATED_OPERATION_REQUEST: Symbol('fetchAutomatedOperationRequest'),
  FETCH_AUTOMATED_OPERATION_SUCCESS: Symbol('fetchAutomatedOperationSuccess'),
  FETCH_AUTOMATED_OPERATION_FAILED: Symbol('fetchAutomatedOperationFailed'),
  FETCH_AUTOMATED_OPERATIONS_REQUEST: Symbol('fetchAutomatedOperationsRequest'),
  FETCH_AUTOMATED_OPERATIONS_SUCCESS: Symbol('fetchAutomatedOperationsSuccess'),
  FETCH_AUTOMATED_OPERATIONS_FAILED: Symbol('fetchAutomatedOperationsFailed'),
  FETCH_AUTOMATED_OPERATIONS_PAGINATED: Symbol('fetchAutomatedOperationsPaginated'),
  ADD_AUTOMATED_OPERATION_REQUEST: Symbol('addAutomatedOperationRequest'),
  ADD_AUTOMATED_OPERATION_SUCCESS: Symbol('addAutomatedOperationSuccess'),
  ADD_AUTOMATED_OPERATION_FAILED: Symbol('addAutomatedOperationFailed'),
  ADD_SCHEDULE_REQUEST: Symbol('addScheduleRequest'),
  ADD_SCHEDULE_SUCCESS: Symbol('addScheduleSuccess'),
  ADD_SCHEDULE_FAILED: Symbol('addScheduleFailed'),
  FETCH_SCHEDULES_REQUEST: Symbol('fetchSchedulesRequest'),
  FETCH_SCHEDULES_SUCCESS: Symbol('fetchSchedulesSuccess'),
  FETCH_SCHEDULES_FAILED: Symbol('fetchSchedulesFailed'),
  ADD_OPERATION_REQUEST: Symbol('addOperationRequest'),
  ADD_OPERATION_SUCCESS: Symbol('addOperationSuccess'),
  ADD_OPERATION_FAILED: Symbol('addOperationFailed'),
  REMOVE_AUTOMATED_OPERATION_REQUEST: Symbol('removeAutomatedOperationRequest'),
  REMOVE_AUTOMATED_OPERATION_SUCCESS: Symbol('removeAutomatedOperationSuccess'),
  REMOVE_AUTOMATED_OPERATION_FAILED: Symbol('removeAutomatedOperationFailed'),
  REMOVE_SCHEDULE_REQUEST: Symbol('removeScheduleRequest'),
  UPDATE_AUTOMATED_FUNCTION_REMOVE_SCHEDULE_REQUEST: Symbol('updateAutomatedFunctionRemoveScheduleRequest'),
  REMOVE_SCHEDULE_SUCCESS: Symbol('removeScheduleSuccess'),
  REMOVE_SCHEDULE_FAILED: Symbol('removeScheduleFailed'),
  REMOVE_OPERATION_REQUEST: Symbol('removeOperationRequest'),
  REMOVE_OPERATION_SUCCESS: Symbol('removeOperationSuccess'),
  REMOVE_OPERATION_FAILED: Symbol('removeOperationFailed'),
  FETCH_AUTOMATED_FUNCTIONS_REQUEST: Symbol('fetchAutomatedFunctionsRequest'),
  FETCH_AUTOMATED_FUNCTIONS_SUCCESS: Symbol('fetchAutomatedFunctionsSuccess'),
  FETCH_AUTOMATED_FUNCTIONS_FAILED: Symbol('fetchAutomatedFunctionsFailed'),
  UPDATE_AUTOMATED_FUNCTION_REQUEST: Symbol('updateAutomatedFunctionRequest'),
  UPDATE_AUTOMATED_FUNCTION_SUCCESS: Symbol('updateAutomatedFunctionSuccess'),
  UPDATE_AUTOMATED_FUNCTION_FAILED: Symbol('updateAutomatedFunctionFailed'),
  UPDATE_AUTOMATED_OPERATION_REQUEST: Symbol('updateAutomatedOperationRequest'),
  UPDATE_AUTOMATED_OPERATION_SUCCESS: Symbol('updateAutomatedOperationSuccess'),
  UPDATE_AUTOMATED_OPERATION_FAILED: Symbol('updateAutomatedOperationFailed'),
  UPDATE_AUTOMATED_OPERATION_ENABLED_REQUEST: Symbol('updateAutomatedOperationEnabledRequest'),
  UPDATE_AUTOMATED_OPERATION_ENABLED_SUCCESS: Symbol('updateAutomatedOperationEnabledSuccess'),
  UPDATE_AUTOMATED_OPERATION_ENABLED_FAILED: Symbol('updateAutomatedOperationEnabledFailed'),
  UPDATE_SCHEDULE_REQUEST: Symbol('updateScheduleRequest'),
  UPDATE_SCHEDULE_SUCCESS: Symbol('updateScheduleSuccess'),
  UPDATE_SCHEDULE_FAILED: Symbol('updateScheduleFailed'),
  OPEN_EDIT_SCHEDULE_MODAL: Symbol('openEditScheduleModal'),
  OPEN_EDIT_AUTOMATED_OPERATION_MODAL: Symbol('openEditAutomatedOperationModal'),
  RESET_EDIT_MODAL: Symbol('resetEditModal'),
  REMOVE_SCHEDULE_PERMISSION_FAILED: Symbol('removeSchedulePermissionFailed'),
  INVALID_INPUT_NOTIFICATION: Symbol('invalidInputNotification'),
  SYNC_AUTOMATED_FUNCTION_REQUEST: Symbol('syncAutomatedFunctionRequest'),
  SYNC_AUTOMATED_FUNCTION_SUCCESS: Symbol('syncAutomatedFunctionSuccess'),
  SYNC_AUTOMATED_FUNCTION_FAILURE: Symbol('syncAutomatedFunctionFailure'),

  // TASK SCHEDULER
  SET_SELECTED_DEVICE: Symbol('setSelectedDeviceTaskScheduler'),
  ADD_SELECTED_ACTION: Symbol('addSelectedActionTaskScheduler'),
  REMOVE_SELECTED_ACTION: Symbol('removeSelectedActionTaskScheduler'),
  SET_SCHEDULER_TYPE: Symbol('setSchedulerTypeTaskScheduler'),
  SET_SCHEDULER_NAME: Symbol('setSchedulerNameTaskScheduler'),
  SET_SCHEDULER_DESCRIPTION: Symbol('setSchedulerDescriptionTaskScheduler'),
  SET_RECURRENCE_PARAMS: Symbol('setRecurrenceParamsTaskScheduler'),
  SET_RECURRENCE_ERRORS: Symbol('setRecurrenceErrorsTaskScheduler'),
  SET_ENDING_CRITERIA: Symbol('setEndingCriteriaTaskScheduler'),
  CLEAR_SCHEDULER_PARAMS: Symbol('clearSchedulerParamsTaskScheduler'),
  ADD_TASK_SCHEDULER: Symbol('addTaskSchedulerRequest'),
  ADD_TASK_SCHEDULER_SUCCESS: Symbol('addTaskSchedulerSuccess'),
  ADD_TASK_SCHEDULER_FAILED: Symbol('addTaskSchedulerFailed'),
  RESET_TASK_SCHEDULER: Symbol('resetTaskScheduler'),
  SET_SELECTED_TASK: Symbol('setSelectedTaskTaskScheduler'),
  ADD_SELECTED_TASK: Symbol('addSelectedTaskTaskScheduler'),
  SET_TASK_NAME: Symbol('setTaskNameTaskScheduler'),
  SET_TASK_SCHEDULER_MODE: Symbol('setModeTaskScheduler'),
  SET_EXISTING_SCHEDULE: Symbol('setExistingScheduleTaskScheduler'),
  SET_SELECTED_PROJECT: Symbol('setSelectedProjectTaskScheduler'),

  TASK_SCHEDULER_FETCH_PROJECT_DEVICES_REQUEST: Symbol('taskSchedulerFetchProjectDevicesRequest'),
  TASK_SCHEDULER_FETCH_PROJECT_DEVICES_SUCCESS: Symbol('taskSchedulerFetchProjectDevicesSuccess'),
  TASK_SCHEDULER_FETCH_PROJECT_DEVICES_FAILED: Symbol('taskSchedulerFetchProjectDevicesFailed'),

  // ACTION TYPES
  FETCH_ACTION_TYPES_REQUEST: Symbol('fetchActionTypesRequest'),
  FETCH_ACTION_TYPES_SUCCESS: Symbol('fetchActionTypesSuccess'),
  FETCH_ACTION_TYPES_FAILED: Symbol('fetchActionTypesFailed'),

  // DEVICE ACTION TYPES LISTS
  FETCH_DEVICE_ACTION_TYPES_LISTS_REQUEST: Symbol('fetchDeviceActionTypesListsRequest'),
  FETCH_DEVICE_ACTION_TYPES_LISTS_SUCCESS: Symbol('fetchDeviceActionTypesListsSuccess'),
  FETCH_DEVICE_ACTION_TYPES_LISTS_FAILED: Symbol('fetchDeviceActionTypesListsFailed'),
  FETCH_DEVICE_ACTION_TYPES_LISTS_NO_DATA: Symbol('fetchDeviceActionTypesListsNoData'),

  // Control UI
  FETCH_CONTROL_UI_REQUEST: Symbol('fetchControlUI'),
  FETCH_CONTROL_UI_SUCCESS: Symbol('fetchControlUISuccess'),
  FETCH_CONTROL_UI_FAILED: Symbol('fetchControlUIFailed'),
  LOAD_BACKGROUND_IMAGE_CONTROL_UI_PAGE: Symbol('loadBackgroundImageControlUIPage'),
  LOAD_BACKGROUND_IMAGE_CONTROL_UI_PAGE_SUCCESS: Symbol('loadBackgroundImageControlUIPageSuccess'),
  LOAD_BACKGROUND_IMAGE_CONTROL_UI_PAGE_FAILED: Symbol('loadBackgroundImageControlUIPageFailed'),
  UPDATE_CONTROL_UI_REQUEST: Symbol('updateControlUI'),
  UPDATE_CONTROL_UI_SUCCESS: Symbol('updateControlUISuccess'),
  UPDATE_CONTROL_UI_FAILED: Symbol('updateControlUIFailed'),
  ADD_CONTROL_UI_PAGE: Symbol('addControlUiPage'),
  RENAME_CONTROL_UI_PAGE: Symbol('renameControlUiPage'),
  SET_BACKGROUND_CONTROL_UI_PAGE: Symbol('setBackgroundControlUiPage'),
  REMOVE_BACKGROUND_CONTROL_UI_PAGE: Symbol('removeBackgroundControlUiPage'),
  SET_NAME_COLOR_CONTROL_UI_PAGE: Symbol('setNameColorControlUiPage'),
  DELETE_CONTROL_UI_PAGE: Symbol('deleteControlUiPage'),
  UPDATE_CONTROL_UI_BUTTON: Symbol('updateControlUiButton'),
  UPDATE_CONTROL_UI_OVERRIDE_CONTROLS: Symbol('updateControlUiOverrideControls'),
  ENTER_CONTROL_PANEL_CONTEXT: Symbol('enterControlPanelContext'),
  EXIT_CONTROL_PANEL_CONTEXT: Symbol('exitControlPanelContext'),
  DUPLICATE_CONTROL_PANEL_PAGE: Symbol('duplicatePage'),
  REMOVE_BACKGROUND_IMAGE_UPLOAD_CONTROL_UI_PAGE: Symbol('removeBackgroundImageUploadControlUiPage'),
  COPY_BUTTON: Symbol('copyButtoControlUiPage'),
  PASTE_BUTTON: Symbol('pasteButtonControlUiPage'),
  CLEAR_COPY: Symbol('clearCopyControlUiPage'),
  SET_PAGE_ALLOWED_USER_IDS: Symbol('setPageAllowedUserIds'),

  CONTROL_UI_FETCH_USERS: Symbol('controlUiFetchUsers'),
  CONTROL_UI_FETCH_USERS_SUCCESS: Symbol('controlUiFetchUsersSuccess'),
  CONTROL_UI_FETCH_USERS_FAILURE: Symbol('controlUiFetchUsersFailure'),

  CONTROL_UI_TOGGLE_PAGE_NAVIGATION: Symbol('controlUiTogglePageNavigation'),
  CONTROL_UI_SET_PAGE_NAVIGATION_OPEN: Symbol('controlUiSetPageNavigationOpen'),
  CONTROL_UI_SET_PAGE_NAVIGATION_CLOSED: Symbol('controlUiSetPageNavigationClosed'),

  // Project uploads
  UPLOAD_PROJECT_FILE_NOT_FOUND_RESUME: Symbol('uploadProjectFileNotFoundResume'),
  UPLOAD_PROJECT_PREPARE_REQUEST: Symbol('uploadProjectPrepareRequest'),
  UPLOAD_PROJECT_PREPARE_SUCCESS: Symbol('uploadProjectPrepareSuccess'),

  // Invitations
  DELETE_INVITATION_REQUEST: Symbol('deleteInvitationRequest'),
  DELETE_INVITATION_SUCCESS: Symbol('deleteInvitationSuccess'),
  DELETE_INVITATION_FAILED: Symbol('deleteInvitationFailed'),
  SUPPRESS_INVITATIONS: Symbol('suppressInvitations'),

  // General Errors
  ADD_GENERAL_ERROR: Symbol('addGeneralError'),

  // General Messages
  ADD_GENERAL_NOTIFICATION: Symbol('addGeneralNotification'),

  // Info Messages
  ADD_INFO_NOTIFICATION: Symbol('addInfoNotification'),

  // Specific Errors
  CLEAR_REQUESTER_ERRORS: Symbol('clearRequesterErrors'),
  ADD_REQUESTER_ERROR: Symbol('addRequesterError'),

  // General data store
  ADD_TO_DATA_STORE: Symbol('addToDataStore'),
  ADD_TO_DATA_STORE_FAILED: Symbol('addToDataStoreFailed'),

  // FILE UPLOAD
  UPLOAD_INITIALIZE_STATE: Symbol('uploadInitializeState'),
  UPLOAD_PREPARE_REQUEST: Symbol('uploadPrepareRequest'),
  UPLOAD_PREPARE_SUCCESS: Symbol('uploadPrepareSuccess'),
  UPLOAD_ENQUEUE_AT_END: Symbol('uploadEnqueueAtEnd'),
  UPLOAD_ENQUEUE_AT_START: Symbol('uploadEnqueueAtStart'),
  UPLOAD_FORCE: Symbol('uploadForce'),
  UPLOAD_CLEAR_COMPLETED: Symbol('uploadClearCompleted'),
  UPLOAD_SUSPEND: Symbol('uploadSuspend'),
  UPLOAD_CONTINUE: Symbol('uploadContinue'),
  UPLOAD_FILE_NOT_FOUND: Symbol('uploadFileNotFound'),
  UPLOAD_FILE_NOT_FOUND_RESUME: Symbol('uploadFileNotFoundResume'),
  SET_UPLOAD_FETCH_STATUS: Symbol('setUploadFetchStatus'),
  SET_UPLOAD_SUSPENSION_STATUS: Symbol('setUploadSuspensionStatus'),
  SET_UPLOAD_WORKER: Symbol('setUploadWorker'),
  SET_UPLOAD_LIMIT: Symbol('setUploadLimit'),
  // Worker
  UPLOAD_SAVE_NEW_TOKEN: 'uploadSaveNewToken',
  UPLOAD_PROGRESS_UPDATE: 'uploadProgressUpdate',
  UPLOAD_START_SUCCESS: 'uploadStartSuccess',
  UPLOAD_PAUSE_REQUEST: 'uploadPauseRequest',
  UPLOAD_PAUSE_SUCCESS: 'uploadPauseSuccess',
  UPLOAD_PAUSE_FAILED: 'uploadPauseFailure',
  UPLOAD_RESUME_REQUEST: 'uploadResumeRequest',
  UPLOAD_RESUME_SUCCESS: 'uploadResumeSuccess',
  UPLOAD_RESUME_FAILED: 'uploadResumeFailure',
  UPLOAD_CANCEL_REQUEST: 'uploadCancelRequest',
  UPLOAD_CANCEL_SUCCESS: 'uploadCancelSuccess',
  UPLOAD_CANCEL_FAILED: 'uploadCancelFailure',
  UPLOAD_SUCCESS: 'uploadSuccess',
  UPLOAD_FAILED: 'uploadFailed',

  // ADMIN
  // Users
  FETCH_USERS_REQUEST: Symbol('fetchUsersRequest'),
  FETCH_USERS_SUCCESS: Symbol('fetchUsersSuccess'),
  FETCH_USERS_FAILED: Symbol('fetchUsersFailed'),
  TOGGLE_SUSPEND_USER: Symbol('toggleSuspendUser'),
  TOGGLE_SUSPEND_USER_SUCCESS: Symbol('toggleSuspendUserSuccess'),
  TOGGLE_SUSPEND_USER_FAILED: Symbol('toggleSuspendUserFailed'),
  RESET_SECRET_KEY: Symbol('resetSecretKey'),
  RESET_SECRET_KEY_SUCCESS: Symbol('resetSecretKeySuccess'),
  RESET_SECRET_KEY_FAILED: Symbol('resetSecretKeyFailed'),

  INVITE_USER_REQUEST: Symbol('inviteUserRequest'),
  INVITE_USER_SUCCESS: Symbol('inviteUserSuccess'),
  INVITE_USER_FAILED: Symbol('inviteUserFailed'),

  EDIT_PROFILE: Symbol('editProfile'),
  EDIT_PROFILE_SUCCESS: Symbol('editProfileSuccess'),
  EDIT_PROFILE_FAILED: Symbol('editProfileFailed'),

  // Devices
  ADD_DEVICE_REQUEST: Symbol('addDeviceRequest'),
  ADD_DEVICE_SUCCESS: Symbol('addDeviceSuccess'),
  ADD_DEVICE_FAILED: Symbol('addDeviceFailed'),
  CLEAR_DEVICE_KEY: Symbol('clearDeviceKey'),
  FETCH_DEVICE_REQUEST: Symbol('fetchDeviceRequest'),
  FETCH_DEVICE_SUCCESS: Symbol('fetchDeviceSuccess'),
  FETCH_DEVICE_FAILED: Symbol('fetchDeviceFailed'),

  REPLACE_DEVICE_TOKEN_REQUEST: Symbol('replaceDeviceTokenRequest'),
  REPLACE_DEVICE_TOKEN_SUCCESS: Symbol('replaceDeviceTokenSuccess'),
  REPLACE_DEVICE_TOKEN_FAILED: Symbol('replaceDeviceTokenFailed'),
  UPDATE_DEVICE_REPORTED: Symbol('updateDeviceReported'),
  REMOVE_DEVICE_REQUEST: Symbol('removeDeviceRequest'),
  REMOVE_DEVICE_SUCCESS: Symbol('removeDeviceSuccess'),
  REMOVE_DEVICE_FAILED: Symbol('removeDeviceFailed'),

  NEW_UPLOAD: Symbol('newUpload'),

  RENAME_DEVICE: Symbol('renameDevice'),
  RENAME_DEVICE_SUCCESS: Symbol('renameDeviceSuccess'),
  RENAME_DEVICE_FAILED: Symbol('renameDeviceFailure'),

  // IO Modules
  FETCH_IO_MODULES: Symbol('fetchIOModules'),
  FETCH_IO_MODULES_SUCCESS: Symbol('fetchIOModulesSuccess'),
  FETCH_IO_MODULES_FAILED: Symbol('fetchIOModulesFailed'),
  FETCH_IO_MODULES_INSTANCES: Symbol('fetchIOModulesInstances'),
  FETCH_IO_MODULES_INSTANCES_SUCCESS: Symbol('fetchIOModulesInstancesSuccess'),
  FETCH_IO_MODULES_INSTANCES_FAILED: Symbol('fetchIOModulesInstancesFailed'),
  FETCH_IO_MODULES_INSTANCES_PAGINATED: Symbol('fetchIOModulesInstancesPaginated'),

  // Triggers
  PERFORM_TRIGGERS_REQUEST: Symbol('performTriggersRequest'),
  PERFORM_TRIGGERS_SUCCESS: Symbol('performTriggersSuccess'),
  PERFORM_TRIGGERS_FAILED: Symbol('performTriggersFailed'),
  FETCH_TRIGGERS_REQUEST: Symbol('fetchTriggersRequest'),
  FETCH_TRIGGERS_SUCCESS: Symbol('fetchTriggersSuccess'),
  FETCH_TRIGGERS_FAILED: Symbol('fetchTriggersFailed'),
  FETCH_TRIGGERS_PAGINATED: Symbol('fetchTriggersPaginated'),
  FETCH_DEVICE_TRIGGERS_REQUEST: Symbol('fetchDeviceTriggersRequest'),
  FETCH_DEVICE_TRIGGERS_SUCCESS: Symbol('fetchDeviceTriggersSuccess'),
  FETCH_DEVICE_TRIGGERS_FAILED: Symbol('fetchDeviceTriggersFailed'),
  PERFORM_TRIGGERS_COND_REQUEST: Symbol('performTriggersCondRequest'),

  // Companies
  CHANGE_TENANT: Symbol('changeTenant'),

  FETCH_COMPANIES_REQUEST: Symbol('fetchCompaniesRequest'),
  FETCH_COMPANIES_SUCCESS: Symbol('fetchCompaniesSuccess'),
  FETCH_COMPANIES_FAILED: Symbol('fetchCompaniesFailed'),

  FETCH_COMPANY_REQUEST: Symbol('fetchCompanyRequest'),
  FETCH_COMPANY_SUCCESS: Symbol('fetchCompanySuccess'),
  FETCH_COMPANY_FAILED: Symbol('fetchCompanyFailed'),

  ADD_COMPANY_REQUEST: Symbol('addCompanyRequest'),
  ADD_COMPANY_SUCCESS: Symbol('addCompanySuccess'),
  ADD_COMPANY_FAILED: Symbol('addCompanyFailed'),

  UPDATE_COMPANY_INFO_REQUEST: Symbol('updateCompanyInfoRequest'),
  UPDATE_COMPANY_INFO_SUCCESS: Symbol('updateCompanyInfoSuccess'),
  UPDATE_COMPANY_INFO_FAILED: Symbol('updateCompanyInfoFailed'),

  // Settings
  CHANGE_API_DOMAIN_REQUEST: Symbol('changeApiDomainRequest'),
  CHANGE_API_DOMAIN_SUCCESS: Symbol('changeApiDomainSuccess'),
  CHANGE_API_DOMAIN_FAILED: Symbol('changeApiDomainFailure'),
  FETCH_BACKEND_VERSION: Symbol('fetchBackendVersion'),
  FETCH_BACKEND_VERSION_SUCCESS: Symbol('fetchBackendVersionSuccess'),
  FETCH_BACKEND_VERSION_FAILED: Symbol('fetchBackendVersionFailure'),
  FETCH_SERVICE_VERSIONS: Symbol('fetchServiceVersions'),
  FETCH_SERVICE_VERSIONS_SUCCESS: Symbol('fetchServiceVersionsSuccess'),
  FETCH_SERVICE_VERSIONS_FAILED: Symbol('fetchServiceVersionsFailed'),

  // Origins
  ADD_ORIGIN_REQUEST: Symbol('addOriginRequest'),
  ADD_ORIGIN_SUCCESS: Symbol('addOriginSuccess'),
  ADD_ORIGIN_FAILED: Symbol('addOriginFailed'),

  EDIT_ORIGIN_REQUEST: Symbol('editOriginRequest'),
  EDIT_URL_ORIGIN_REQUEST: Symbol('editUrlOrigin'),
  EDIT_ORIGIN_SUCCESS: Symbol('editOriginSuccess'),
  EDIT_ORIGIN_FAILED: Symbol('editOriginFailed'),

  DELETE_ORIGIN_REQUEST: 'deleteOriginRequest',
  DELETE_ORIGIN_SUCCESS: 'deleteOriginSuccess',
  DELETE_ORIGIN_FAILED: 'deleteOriginFailed',

  // Files
  FETCH_FILES_REQUEST: Symbol('fetchFilesRequest'),
  FETCH_FILES_SUCCESS: Symbol('fetchFilesSuccess'),
  FETCH_FILES_FAILED: Symbol('fetchFilesFailed'),
  FETCH_FILES_PAGINATED: Symbol('fetchFilesPaginated'),
  REMOVE_FILE_REQUEST: Symbol('removeFileRequest'),
  REMOVE_FILE_REQUEST_SUCCESS: Symbol('removeFileSuccess'),
  REMOVE_FILE_REQUEST_FAILED: Symbol('removeFileFailed'),
  TRIGGER_FILE_TRANSFER_REQUEST: Symbol('triggerFileTransferRequest'),
  TRIGGER_FILE_TRANSFER_REQUEST_SUCCESS: Symbol('triggerFileTransferSuccess'),
  TRIGGER_FILE_TRANSFER_REQUEST_FAILED: Symbol('triggerFileTransferFailed'),
  DOWNLOAD_FILE_REQUEST: Symbol('downloadFileRequest'),
  DOWNLOAD_FILE_REQUEST_SUCCESS: Symbol('downloadFileSuccess'),
  DOWNLOAD_FILE_REQUEST_FAILED: Symbol('downloadFileFailed'),

  // Groups
  FETCH_FIXTURE_GROUPS_REQUEST: Symbol('fetchGroupsRequest'),
  FETCH_FIXTURE_GROUPS_SUCCESS: Symbol('fetchGroupsSuccess'),
  FETCH_FIXTURE_GROUPS_FAILED: Symbol('fetchGroupsFailed'),
  FETCH_FIXTURE_GROUPS_PAGINATED: Symbol('fetchGroupsPaginated'),
  FETCH_DEVICE_GROUPS_REQUEST: Symbol('fetchDeviceGroupsRequest'),
  FETCH_DEVICE_GROUPS_SUCCESS: Symbol('fetchDeviceGroupsSuccess'),
  FETCH_DEVICE_GROUPS_FAILED: Symbol('fetchDeviceGroupsFailed'),

  // Pagination
  FETCH_RESOURCE_PAGE_REQUEST: Symbol('fetchResourcePage'),
  CHANGE_ROUTE: Symbol('routeChange'),
  REFETCH: Symbol('refetch'),
  CLEAR_ACTIVE_RESOURCE: Symbol('clearActiveResource'),

  // Timelines
  FETCH_TIMELINES_REQUEST: Symbol('fetchTimelinesRequest'),
  FETCH_TIMELINES_FAILED: Symbol('fetchTimelinesFailed'),
  FETCH_TIMELINES_SUCCESS: Symbol('fetchTimelinesSuccess'),
  FETCH_TIMELINES_PAGINATED: Symbol('fetchTimelinesPaginated'),
  FETCH_DEVICE_TIMELINES_REQUEST: Symbol('fetchDeviceTimelinesRequest'),
  FETCH_DEVICE_TIMELINES_SUCCESS: Symbol('fetchDeviceTimelinesSuccess'),
  FETCH_DEVICE_TIMELINES_FAILED: Symbol('fetchDeviceTimelinesFailed'),

  // StaticScenes
  FETCH_STATIC_SCENES_REQUEST: Symbol('fetchStaticScenesRequest'),
  FETCH_STATIC_SCENES_FAILED: Symbol('fetchStaticScenesFailed'),
  FETCH_STATIC_SCENES_SUCCESS: Symbol('fetchStaticScenesSuccess'),
  FETCH_STATIC_SCENES_PAGINATED: Symbol('fetchStaticScenesPaginated'),
  FETCH_DEVICE_SCENES_REQUEST: Symbol('fetchDeviceScenesRequest'),
  FETCH_DEVICE_SCENES_SUCCESS: Symbol('fetchDeviceScenesSuccess'),
  FETCH_DEVICE_SCENES_FAILED: Symbol('fetchDeviceScenesFailed'),

  // Output
  FETCH_PATCHES: Symbol('fetchPatches'),
  FETCH_PATCHES_SUCCESS: Symbol('fetchPatchesSuccess'),
  FETCH_PATCHES_FAILED: Symbol('fetchPatchesFailed'),
  FETCH_OUTPUT: Symbol('fetchOutput'),
  FETCH_OUTPUT_SUCCESS: Symbol('fetchOutputSuccess'),
  FETCH_OUTPUT_FAILED: Symbol('fetchOutputFailed'),
  SET_PROTOCOL: Symbol('setProtocol'),
  SET_UNIVERSE: Symbol('setUniverse'),

  // Beacon
  TOGGLE_BEACON_REQUEST: Symbol('toggleBeacon'),
  TOGGLE_BEACON_SUCCESS: Symbol('toggleBeaconSuccess'),
  TOGGLE_BEACON_FAILED: Symbol('toggleBeaconFailed'),

  // Watchdog
  TOGGLE_WATCHDOG_REQUEST: Symbol('toggleWatchdog'),
  TOGGLE_WATCHDOG_SUCCESS: Symbol('toggleWatchdogSuccess'),
  TOGGLE_WATCHDOG_FAILED: Symbol('toggleWatchdogFailed'),

  // Disable
  TOGGLE_DISABLE_REQUEST: Symbol('toggleDisable'),
  TOGGLE_DISABLE_SUCCESS: Symbol('toggleDisableSuccess'),
  TOGGLE_DISABLE_FAILED: Symbol('toggleDisableFailed'),

  // Timestamp
  SET_TIMESTAMP_REQUEST: Symbol('setTimestamp'),
  SET_TIMESTAMP_SUCCESS: Symbol('setTimestampSuccess'),
  SET_TIMESTAMP_FAILED: Symbol('setTimestampFailed'),

  // Polling
  POLLING_INIT: Symbol('pollingInit'),
  POLLING_SET_REFRESH_RATE: Symbol('pollingSetRefreshRate'),
  POLLING_REFRESHING_START: Symbol('pollingRefreshingStart'),
  POLLING_REFRESHING_STOP: Symbol('pollingRefreshingStop'),
  POLLING_RESOURCE_UPDATE_REQUEST: Symbol('pollingResourceUpdateRequest'),
  POLLING_RESOURCE_UPDATE_SUCCESS: Symbol('pollingResourceUpdateSuccess'),
  POLLING_RESOURCE_UPDATE_FAILED: Symbol('pollingResourceUpdateFailed'),
  POLLING_FIELD_UPDATE: Symbol('pollingFieldUpdate'),
  POLLING_TIMEOUT: Symbol('pollingTimeout'),

  // Polling v2
  POLLING_INIT_V2: Symbol('pollingInitV2'),
  POLLING_START_V2: Symbol('pollingStartV2'),
  POLLING_STOP_V2: Symbol('pollingStopV2'),
  POLLING_TERMINATE_V2: Symbol('pollingTerminateV2'),
  POLLING_SET_INTERVAL_V2: Symbol('pollingSetIntervalV2'),

  // Chained Loader
  START_CHAIN: Symbol('startChain'),
  STOP_CHAIN: Symbol('stopChain'),
  NEXT_IN_CHAIN: Symbol('nextInChain'),
  TRIGGER_CHAIN: Symbol('triggerChain'),
  COMPLETED_CHAIN: Symbol('completedChain'),
  INTERRUPTED_CHAIN: Symbol('interruptedChain'),
  TERMINATE_CHAIN: Symbol('terminateChain'),

  // Resource update
  RESOURCE_UPDATE_REQUEST_V2: Symbol('resourceUpdateRequestV2'),
  RESOURCE_UPDATE_REQUEST_SUCCESS_V2: Symbol('resourceUpdateRequestSuccessV2'),
  RESOURCE_UPDATE_REQUEST_FAILURE_V2: Symbol('resourceUpdateRequestFailureV2'),
  RESOURCE_UPDATE_START_V2: Symbol('resourceUpdateStartV2'),
  RESOURCE_UPDATE_STOP_V2: Symbol('resourceUpdateStopV2'),
  RESOURCE_UPDATE_LOADER_FETCH_V2: Symbol('resourceUpdateLoaderFetchV2'),
  RESOURCE_UPDATE_LOADER_FETCH_DONE_V2: Symbol('resourceUpdateLoaderFetchDoneV2'),
  RESOURCE_UPDATE_SUCCESS_V2: Symbol('resourceUpdateSuccessV2'),
  RESOURCE_UPDATE_FAILED_V2: Symbol('resourceUpdateFailedV2'),

  // Tables
  STORE_TABLE_NAME: Symbol('storeTableName'),

  // Logs
  FETCH_LOGS_REQUEST: Symbol('fetchLogsRequest'),
  FETCH_LOGS_FAILED: Symbol('fetchLogsFailed'),
  FETCH_LOGS_SUCCESS: Symbol('fetchLogsSuccess'),
  LOGS_SELECT_LINES: Symbol('logsSelectLines'),

  // Format
  PERFORM_FORMAT_REQUEST: Symbol('performFormat'),
  PERFORM_FORMAT_SUCCESS: Symbol('performFormatSuccess'),
  PERFORM_FORMAT_FAILED: Symbol('performFormatFailed'),

  // Reset
  PERFORM_RESET_REQUEST: Symbol('performReset'),
  PERFORM_RESET_SUCCESS: Symbol('performResetSuccess'),
  PERFORM_RESET_FAILED: Symbol('performResetFailed'),

  // Log
  SET_LOG_LEVEL_REQUEST: Symbol('setLogLevelRequest'),
  SET_LOG_LEVEL_SUCCESS: Symbol('setLogLevelSuccess'),
  SET_LOG_LEVEL_FAILED: Symbol('setLogLevelFailed'),

  // Permissions
  FETCH_INDEX_PERMISSIONS_REQUEST: Symbol('fetchIndexPermissions'),
  FETCH_INDEX_PERMISSIONS_SUCCESS: Symbol('fetchIndexPermissionsSuccess'),
  FETCH_INDEX_PERMISSIONS_FAILED: Symbol('fetchIndexPermissionsFailed'),

  PERMISSIONS_RESOURCE_ADD_USER_ID: Symbol('permissionsResourceAddUserId'),
  PERMISSIONS_RESOURCE_REMOVE_USER_ID: Symbol('permissionsResourceRemoveUserId'),

  PERMISSIONS_RESOURCE_COPY: Symbol('permissionsResourceCopy'),
  PERMISSIONS_RESOURCE_PASTE: Symbol('permissionsResourcePaste'),
  PERMISSIONS_RESOURCE_PASTE_SUCCESS: Symbol('permissionsResourcePasteSuccess'),
  PERMISSIONS_RESOURCE_PASTE_FAILED: Symbol('permissionsResourcePasteFailed'),

  PERMISSIONS_TOGGLE_SELECTING_CONTEXTS: Symbol('permissionsToggleSelectingContexts'),
  PERMISSIONS_TOGGLE_SELECTING_PROJECTS: Symbol('permissionsToggleSelectingProjects'),
  PERMISSIONS_TOGGLE_SELECTING_PROJECT_SUITES: Symbol('permissionsToggleSelectingProjectSuites'),

  PERMISSIONS_ADD_CONTEXT_ID: Symbol('permissionsAddContextId'),
  PERMISSIONS_ADD_CONTEXT_ID_WITH_PATHS: Symbol('permissionsAddContextIdWithPaths'),
  PERMISSIONS_REMOVE_CONTEXT_ID: Symbol('permissionsRemoveContextId'),

  PERMISSIONS_FETCH_CONTEXT_USERS_REQUEST: Symbol('permissionsFetchContextUsers'),
  PERMISSIONS_FETCH_CONTEXT_USERS_SUCCESS: Symbol('permissionsFetchContextUsersSuccess'),
  PERMISSIONS_FETCH_CONTEXT_USERS_FAILED: Symbol('permissionsFetchContextUsersFailed'),

  PERMISSIONS_ADD_PROJECT_ID: Symbol('permissionsAddProjectId'),
  PERMISSIONS_REMOVE_PROJECT_ID: Symbol('permissionsRemoveProjectId'),

  PERMISSIONS_FETCH_PROJECT_USERS_REQUEST: Symbol('permissionsFetchProjectUsers'),
  PERMISSIONS_FETCH_PROJECT_USERS_SUCCESS: Symbol('permissionsFetchProjectUsersSuccess'),
  PERMISSIONS_FETCH_PROJECT_USERS_FAILED: Symbol('permissionsFetchProjectUsersFailed'),

  PERMISSIONS_ADD_PROJECT_SUITE_ID: Symbol('permissionsAddProjectSuiteId'),
  PERMISSIONS_REMOVE_PROJECT_SUITE_ID: Symbol('permissionsRemoveProjectSuiteId'),

  PERMISSIONS_FETCH_PROJECT_SUITE_USERS_REQUEST: Symbol('permissionsFetchProjectSuiteUsers'),
  PERMISSIONS_FETCH_PROJECT_SUITE_USERS_SUCCESS: Symbol('permissionsFetchProjectSuiteUsersSuccess'),
  PERMISSIONS_FETCH_PROJECT_SUITE_USERS_FAILED: Symbol('permissionsFetchProjectSuiteUsersFailed'),

  PERMISSIONS_FETCH_PROJECT_LOGICAL_DEVICES_REQUEST: Symbol('permissionsFetchProjectLogicalDevices'),
  PERMISSIONS_FETCH_PROJECT_LOGICAL_DEVICES_SUCCESS: Symbol('permissionsFetchProjectLogicalDevicesSuccess'),
  PERMISSIONS_FETCH_PROJECT_LOGICAL_DEVICES_FAILED: Symbol('permissionsFetchProjectLogicalDevicesFailed'),

  FETCH_RESOURCE_USER_PERMISSIONS_REQUEST: Symbol('fetchResourceUserPermissions'),
  FETCH_RESOURCE_USER_PERMISSIONS_SUCCESS: Symbol('fetchResourceUserPermissionsSuccess'),
  FETCH_RESOURCE_USER_PERMISSIONS_FAILED: Symbol('fetchResourceUserPermissionsFailed'),

  FETCH_CONTEXT_USER_PERMISSIONS_REQUEST: Symbol('fetchContextUserPermissions'),
  FETCH_CONTEXT_USER_PERMISSIONS_SUCCESS: Symbol('fetchContextUserPermissionsSuccess'),
  FETCH_CONTEXT_USER_PERMISSIONS_FAILED: Symbol('fetchContextUserPermissionsFailed'),

  PERMISSIONS_TOGGLE_RESOURCE_OPEN: Symbol('permissionsToggleResourceOpen'),
  PERMISSIONS_TOGGLE_RESOURCE_TREE_OPEN: Symbol('permissionsToggleResourceTreeOpen'),

  PERMISSIONS_TOGGLE_USER_PERMISSION: Symbol('permissionsToggleUserPermission'),
  PERMISSIONS_TOGGLE_USER_PERMISSION_SUCCESS: Symbol('permissionsToggleUserPermissionSuccess'),
  PERMISSIONS_TOGGLE_USER_PERMISSION_FAILED: Symbol('permissionsToggleUserPermissionFailed'),

  PERMISSIONS_TOGGLE_USER_HEADER_PERMISSION: Symbol('permissionsToggleUserHeaderPermission'),
  PERMISSIONS_TOGGLE_USER_HEADER_PERMISSION_SUCCESS: Symbol('permissionsToggleUserHeaderPermissionSuccess'),
  PERMISSIONS_TOGGLE_USER_HEADER_PERMISSION_FAILED: Symbol('permissionsToggleUserHeaderPermissionFailed'),

  PERMISSIONS_TOGGLE_CONTEXT_USER_PERMISSION: Symbol('permissionsToggleContextUserPermission'),
  PERMISSIONS_TOGGLE_CONTEXT_USER_PERMISSION_SUCCESS: Symbol('permissionsToggleContextUserPermissionSuccess'),
  PERMISSIONS_TOGGLE_CONTEXT_USER_PERMISSION_FAILED: Symbol('permissionsToggleContextUserPermissionFailed'),

  PERMISSIONS_TOGGLE_CONTEXT_USER_HEADER_PERMISSION: Symbol('permissionsToggleContextUserHeaderPermission'),
  PERMISSIONS_TOGGLE_CONTEXT_USER_HEADER_PERMISSION_SUCCESS: Symbol('permissionsToggleContextUserHeaderPermissionSuccess'),
  PERMISSIONS_TOGGLE_CONTEXT_USER_HEADER_PERMISSION_FAILED: Symbol('permissionsToggleContextUserHeaderPermissionFailed'),

  // Contexts
  FETCH_CONTEXTS_REQUEST: Symbol('fetchContextsRequest'),
  FETCH_CONTEXTS_SUCCESS: Symbol('fetchContextsSuccess'),
  FETCH_CONTEXTS_FAILED: Symbol('fetchContextsFailed'),
  FETCH_CONTEXTS_PAGINATED: Symbol('fetchContextsPaginated'),

  // Notification types
  FETCH_NOTIFICATION_TYPES_REQUEST: Symbol('fetchNotificationTypesRequest'),
  FETCH_NOTIFICATION_TYPES_FAILED: Symbol('fetchNotificationTypesFailed'),
  FETCH_NOTIFICATION_TYPES_SUCCESS: Symbol('fetchNotificationTypesSuccess'),
  FETCH_NOTIFICATION_TYPES_PAGINATED: Symbol('fetchNotificationTypesPaginated'),

  OPT_IN_NOTIFICATION_REQUEST: Symbol('optInNotificationRequest'),
  OPT_IN_NOTIFICATION_SUCCESS: Symbol('optInNotificationSuccess'),
  OPT_IN_NOTIFICATION_FAILED: Symbol('optInNotificationFailed'),
  OPT_OUT_NOTIFICATION_REQUEST: Symbol('optOutNotificationRequest'),
  OPT_OUT_NOTIFICATION_SUCCESS: Symbol('optOutNotificationSuccess'),
  OPT_OUT_NOTIFICATION_FAILED: Symbol('optOutNotificationFailed'),

  EDIT_NOTIFICATION_REQUEST: Symbol('editNotificationRequest'),
  EDIT_NOTIFICATION_SUCCESS: Symbol('editNotificationSuccess'),
  EDIT_NOTIFICATION_FAILED: Symbol('editNotificationFailed'),

  FETCH_NOTIFICATIONS: Symbol('fetchNotificationsRequest'),
  FETCH_NOTIFICATIONS_SUCCESS: Symbol('fetchNotificationsSuccess'),
  FETCH_NOTIFICATIONS_FAILED: Symbol('fetchNotificationsFailed'),
  FETCH_RESOURCES_WITH_NOTIFICATIONS: Symbol('fetchResourcesWithNotifications'),

  SCHEDULES_AF_NOTIFICATION: Symbol('schedulesAFNotification'),

  // Notification Events

  FETCH_NOTIFICATION_EVENTS: Symbol('fetchNotificationEventsRequest'),
  FETCH_NOTIFICATION_EVENTS_SUCCESS: Symbol('fetchNotificationEventsSuccess'),
  FETCH_NOTIFICATION_EVENTS_FAILED: Symbol('fetchNotificationEventsFailed'),
  FETCH_NOTIFICATION_EVENTS_PAGINATED: Symbol('fetchNotificationEventsPaginated'),
  REFETCH_NOTIFICATION_EVENTS: Symbol('refetchNotificationEvents'),

  FETCH_NOTIFICATION_EVENTS_FOR_DROPDOWN: Symbol('fetchNotificationEventsForDropdownRequest'),
  FETCH_NOTIFICATION_EVENTS_FOR_DROPDOWN_SUCCESS: Symbol('fetchNotificationEventsForDropdownSuccess'),
  FETCH_NOTIFICATION_EVENTS_FOR_DROPDOWN_FAILED: Symbol('fetchNotificationEventsForDropdownFailed'),

  ARCHIVE_NOTIFICATION_EVENT: Symbol('archiveNotificationEventRequest'),
  ARCHIVE_NOTIFICATION_EVENT_SUCCESS: Symbol('archiveNotificationEventSuccess'),
  ARCHIVE_NOTIFICATION_EVENT_FAILED: Symbol('archiveNotificationEventFailed'),

  ARCHIVE_ALL_NOTIFICATION_EVENTS: Symbol('archiveAllNotificationEventsRequest'),
  ARCHIVE_ALL_NOTIFICATION_EVENTS_SUCCESS: Symbol('archiveAllNotificationEventsSuccess'),
  ARCHIVE_ALL_NOTIFICATION_EVENTS_FAILED: Symbol('archiveAllNotificationEventsFailed'),

  READ_NOTIFICATION_EVENT: Symbol('readNotificationEventRequest'),
  READ_NOTIFICATION_EVENT_SUCCESS: Symbol('readNotificationEventSuccess'),
  READ_NOTIFICATION_EVENT_FAILED: Symbol('readNotificationEventFailed'),

  READ_ALL_NOTIFICATION_EVENTS: Symbol('readAllNotificationEventsRequest'),
  READ_ALL_NOTIFICATION_EVENTS_SUCCESS: Symbol('readAllNotificationEventsSuccess'),
  READ_ALL_NOTIFICATION_EVENTS_FAILED: Symbol('readAllNotificationEventsFailed'),

  ADD_NOTIFICATION_EVENT_FROM_WEBSOCKET: Symbol('addNotificationEventFromWebsocketRequest'),
  CLEAR_NOTIFICATION_EVENT_ICON: Symbol('clearNotificationEventIcon'),
  SUBSCRIBE_NOTIFICATION_EVENTS_CHANNEL: Symbol('subscribeNotificationsChannelRequest'),

  SET_NOTIFICATION_EVENT_FILTERS: Symbol('setNotificationEventFilters'),
  CLEAR_NOTIFICATION_EVENT_FILTERS: Symbol('clearNotificationEventFilters'),

  // Zones
  FETCH_ZONES_REQUEST: Symbol('fetchZonesRequest'),
  FETCH_ZONES_FAILED: Symbol('fetchZonesFailed'),
  FETCH_ZONES_SUCCESS: Symbol('fetchZonesSuccess'),
  FETCH_ZONES_PAGINATED: Symbol('fetchZonesPaginated'),

  // Snapshots
  FETCH_SNAPSHOTS_REQUEST: Symbol('fetchSnapshotsRequest'),
  FETCH_SNAPSHOTS_FAILED: Symbol('fetchSnapshotsFailed'),
  FETCH_SNAPSHOTS_SUCCESS: Symbol('fetchSnapshotsSuccess'),
  FETCH_SNAPSHOTS_PAGINATED: Symbol('fetchSnapshotsPaginated'),

  // Device Settings
  UPDATE_DEVICE_SETTING_REQUEST: Symbol('updateDeviceSettingRequest'),
  UPDATE_DEVICE_SETTING_SUCCESS: Symbol('updateDeviceSettingSuccess'),
  UPDATE_DEVICE_SETTING_FAILED: Symbol('updateDeviceSettingFailed'),

  // Device Action
  PERFORM_ACTION_REQUEST: Symbol('performActionRequest'),
  PERFORM_ACTION_SUCCESS: Symbol('performActionSuccess'),
  PERFORM_ACTION_FAILED: Symbol('performActionFailed'),

  // Value override
  SET_VALUE_OVERRIDE: Symbol('setValueOverride'),
  RESET_VALUE_OVERRIDE: Symbol('resetValueOverride'),
  VALUE_OVERRIDE_FAILED: Symbol('valueOverrideFailed'),
  VALUE_OVERRIDE_SUCCESS: Symbol('valueOverrideSuccess'),

  // websockets
  SOCKET_OPEN: Symbol('socketOpen'),
  SOCKET_CLOSED: Symbol('socketClosed'),
  SOCKET_ERROR: Symbol('socketError'),
  SOCKET_RECEIVED: Symbol('socketReceived'),
  SOCKET_SEND: Symbol('socketSend'),
  SOCKET_SUBSCRIBE: Symbol('socketSubscribe'),
  SOCKET_SUBSCRIBE_SUCCESS: Symbol('socketSubscribeSuccess'),
  SOCKET_SUBSCRIBE_FAILED: Symbol('socketSubscribeFailed'),
  SOCKET_UNSUBSCRIBE: Symbol('socketUnSubscribe'),
  SOCKET_REFRESH: Symbol('socketRefresh'),
  SOCKET_REFRESH_DONE: Symbol('socketRefreshDone'),
  SOCKET_RESET_CONNECTION: Symbol('socketResetConnection'),

  // Service Worker
  SERVICE_WORKER_INIT: Symbol('swInit'),
  SERVICE_WORKER_UPDATE: Symbol('swUpdate'),

  // Remote Devices
  FETCH_REMOTE_DEVICES_REQUEST: Symbol('fetchRemoteDevicesRequest'),
  FETCH_REMOTE_DEVICES_SUCCESS: Symbol('fetchRemoteDevicesSuccess'),
  FETCH_REMOTE_DEVICES_FAILED: Symbol('fetchRemoteDevicesFailed'),
  FETCH_REMOTE_DEVICES_PAGINATED: Symbol('fetchRemoteDevicesPaginated'),

  // Unjoined Remote Devices
  FETCH_UNJOINED_REMOTE_DEVICES_REQUEST: Symbol('fetchUnjoinedRemoteDevicesRequest'),
  FETCH_UNJOINED_REMOTE_DEVICES_SUCCESS: Symbol('fetchUnjoinedRemoteDevicesSuccess'),
  FETCH_UNJOINED_REMOTE_DEVICES_FAILED: Symbol('fetchUnjoinedRemoteDevicesFailed'),
  FETCH_UNJOINED_REMOTE_DEVICES_PAGINATED: Symbol('fetchUnjoinedRemoteDevicesPaginated'),

  // Perform Sync Firmware
  PERFORM_SYNC_FIRMWARE_REQUEST: Symbol('performSyncFirmwareRequest'),
  PERFORM_SYNC_FIRMWARE_SUCCESS: Symbol('performSyncFirmwareSuccess'),
  PERFORM_SYNC_FIRMWARE_FAILED: Symbol('performSyncFirmwareFailed'),

  // Update Color Picker
  UPDATE_RGB_VALUES_REQUEST: Symbol('updateRGBValuesRequest'),
  UPDATE_RGB_VALUES_NEW_ACTION_REQUEST: Symbol('updateRGBValuesNewActionRequest'),

  // Projects for Map
  FETCH_PROJECTS_FOR_MAP_REQUEST: Symbol('fetchProjectsForMapRequest'),
  FETCH_PROJECTS_FOR_MAP_SUCCESS: Symbol('fetchProjectsForMapSuccess'),
  FETCH_PROJECTS_FOR_MAP_FAILED: Symbol('fetchProjectsForMapFailed'),

  // Devices for Site
  FETCH_SITE_DEVICES_REQUEST: Symbol('fetchSiteDevicesRequest'),
  FETCH_SITE_DEVICES_SUCCESS: Symbol('fetchSiteDevicesSuccess'),
  FETCH_SITE_DEVICES_FAILED: Symbol('fetchSiteDevicesFailed'),
  FETCH_AND_SAVE_LOGICAL_DEVICES_SUCCESS: Symbol('fetchSaveLogicalDevicesSuccess'),

  // Vouchers & Subscription Plans
  SET_TYPED_VOUCHER_REQUEST: Symbol('setTypedVoucherRequest'),
  FETCH_VOUCHER_DETAILS_REQUEST: Symbol('fetchVoucherDetailsRequest'),
  FETCH_VOUCHER_DETAILS_SUCCESS: Symbol('fetchVoucherDetailsSuccess'),
  FETCH_VOUCHER_DETAILS_FAILED: Symbol('fetchVoucherDetailsFailed'),
  CLEAR_SELECTED_VOUCHER: Symbol('clearSelectedVoucherRequest'),
  CLEAR_GENERATED_VOUCHER: Symbol('clearGeneratedVoucherRequest'),
  REDEEM_VOUCHER_REQUEST: Symbol('redeemVoucherRequest'),
  REDEEM_VOUCHER_SUCCESS: Symbol('redeemVoucherSuccess'),
  REDEEM_VOUCHER_FAILED: Symbol('redeemVoucherFailed'),
  FETCH_SUBSCRIPTION_PLANS_REQUEST: Symbol('fetchSubscriptionPlansRequest'),
  FETCH_SUBSCRIPTION_PLANS_SUCCESS: Symbol('fetchSubscriptionPlansSuccess'),
  FETCH_SUBSCRIPTION_PLANS_FAILED: Symbol('fetchSubscriptionPlansFailed'),
  GENERATE_VOUCHER_REQUEST: Symbol('generateVoucherRequest'),
  GENERATE_VOUCHER_SUCCESS: Symbol('generateVoucherSuccess'),
  GENERATE_VOUCHER_FAILED: Symbol('generateVoucherFailed'),
  UPGRADE_SITE_PLAN_REQUEST: Symbol('upgradeSitePlanRequest'),
  UPGRADE_SITE_PLAN_SUCCESS: Symbol('upgradeSitePlanSuccess'),
  UPGRADE_SITE_PLAN_FAILED: Symbol('upgradeSitePlanFailed'),
  SET_SELECTED_TENANT_REQUEST: Symbol('setSelectedTenantForVoucherRequest'),
  CLEAR_SELECTED_TENANT_REQUEST: Symbol('clearSelectedTenantForVoucherRequest'),
  LOAD_VOUCHER_PERMISSIONS_REQUEST: Symbol('loadVoucherPermissionsRequest'),
  LOAD_VOUCHER_PERMISSIONS_SUCCESS: Symbol('loadVoucherPermissionsSuccess'),
  LOAD_VOUCHER_PERMISSIONS_FAILED: Symbol('loadVoucherPermissionsFailed'),

  // Project Suites
  FETCH_PROJECT_SUITES_REQUEST: Symbol('fetchProjectSuitesRequest'),
  FETCH_PROJECT_SUITES_SUCCESS: Symbol('fetchProjectSuitesSuccess'),
  FETCH_PROJECT_SUITES_FAILED: Symbol('fetchProjectSuitesFailed'),

  DELETE_PROJECT_SUITE_REQUEST: Symbol('deleteProjectSuiteRequest'),
  DELETE_PROJECT_SUITE_SUCCESS: Symbol('deleteProjectSuiteSuccess'),
  DELETE_PROJECT_SUITE_FAILED: Symbol('deleteProjectSuiteFailed'),

  ADD_PROJECT_SUITE_REQUEST: Symbol('addProjectSuiteRequest'),
  ADD_PROJECT_SUITE_SUCCESS: Symbol('addProjectSuiteSuccess'),
  ADD_PROJECT_SUITE_FAILED: Symbol('addProjectSuiteFailed'),

  FETCH_PROJECT_SUITE_REQUEST: Symbol('fetchProjectSuiteRequest'),
  FETCH_PROJECT_SUITE_SUCCESS: Symbol('fetchProjectSuiteSuccess'),
  FETCH_PROJECT_SUITE_FAILED: Symbol('fetchProjectSuiteFailed'),

  FETCH_PROJECT_SUITE_PROJECTS_REQUEST: Symbol('fetchProjectSuiteProjectsRequest'),
  FETCH_PROJECT_SUITE_PROJECTS_SUCCESS: Symbol('fetchProjectSuiteProjectsSuccess'),
  FETCH_PROJECT_SUITE_PROJECTS_FAILED: Symbol('fetchProjectSuiteProjectsFailed'),

  FETCH_PROJECT_SUITE_PROJECTS_STATUS_REQUEST: Symbol('fetchProjectSuiteProjectsStatusRequest'),
  FETCH_PROJECT_SUITE_PROJECTS_STATUS_SUCCESS: Symbol('fetchProjectSuiteProjectsStatusSuccess'),
  FETCH_PROJECT_SUITE_PROJECTS_STATUS_FAILED: Symbol('fetchProjectSuiteProjectsStatusFailed'),

  REMOVE_PROJECT_FROM_PROJECT_SUITE_REQUEST: Symbol('removeProjectFromProjectSuiteRequest'),
  REMOVE_PROJECT_FROM_PROJECT_SUITE_SUCCESS: Symbol('removeProjectFromProjectSuiteSuccess'),
  REMOVE_PROJECT_FROM_PROJECT_SUITE_FAILED: Symbol('removeProjectFromProjectSuiteFailed'),

  FETCH_PROJECT_SUITE_USERS_REQUEST: Symbol('fetchProjectSuiteUsersRequest'),
  FETCH_PROJECT_SUITE_USERS_SUCCESS: Symbol('fetchProjectSuiteUsersSuccess'),
  FETCH_PROJECT_SUITE_USERS_FAILED: Symbol('fetchProjectSuiteUsersFailed'),

  REMOVE_USER_FROM_PROJECT_SUITE_REQUEST: Symbol('removeUserFromProjectSuiteRequest'),
  REMOVE_USER_FROM_PROJECT_SUITE_SUCCESS: Symbol('removeUserFromProjectSuiteSuccess'),
  REMOVE_USER_FROM_PROJECT_SUITE_FAILED: Symbol('removeUserFromProjectSuiteFailed'),

  UPDATE_PROJECT_SUITE_REQUEST: Symbol('updateProjectSuiteRequest'),
  UPDATE_PROJECT_SUITE_SUCCESS: Symbol('updateProjectSuiteSuccess'),
  UPDATE_PROJECT_SUITE_FAILED: Symbol('updateProjectSuiteFailed'),

  FETCH_PROJECT_SUITE_REVERSE_GEOCODING_REQUEST: Symbol('fetchProjectSuiteReverseGeocodingRequest'),
  FETCH_PROJECT_SUITE_REVERSE_GEOCODING_SUCCESS: Symbol('fetchProjectSuiteReverseGeocodingSuccess'),

  PROJECT_SUITE_UNABLE_TO_FETCH_LOCATION: Symbol('projectSuiteUnableToFetchLocation'),
  FETCH_PROJECT_SUITE_PROJECTS_TASKS_REQUEST: Symbol('fetchProjectSuiteProjectsTasksRequest'),
  FETCH_PROJECT_SUITE_PROJECTS_TASKS_SUCCESS: Symbol('fetchProjectSuiteProjectsTasksSuccess'),
  FETCH_PROJECT_SUITE_PROJECTS_TASKS_FAILED: Symbol('fetchProjectSuiteProjectsTasksFailed'),
  FETCH_PROJECT_SUITE_PROJECTS_TASKS_PAGINATED: Symbol('fetchProjectSuiteProjectsTasksPaginated'),

  FETCH_PROJECT_SUITE_PROJECTS_FILTER_REQUEST: Symbol('fetchProjectSuiteProjectsFilterRequest'),
  FETCH_PROJECT_SUITE_PROJECTS_FILTER_SUCCESS: Symbol('fetchProjectSuiteProjectsFilterSuccess'),
  FETCH_PROJECT_SUITE_PROJECTS_FILTER_FAILED: Symbol('fetchProjectSuiteProjectsFilterFailed'),

  ADD_PROJECT_TO_PROJECT_SUITE_REQUEST: Symbol('addProjectToProjectSuiteRequest'),
  ADD_PROJECT_TO_PROJECT_SUITE_SUCCESS: Symbol('addProjectToProjectSuiteSuccess'),
  ADD_PROJECT_TO_PROJECT_SUITE_FAILED: Symbol('addProjectToProjectSuiteFailed'),

  PROJECT_SUITE_CONTROL_PANEL_TASK_ADDED: Symbol('projectSuiteControlPanelTaskAddedRequest'),

  FETCH_PROJECT_PROJECT_SUITE_PARENTS_REQUEST: Symbol('fetchProjectProjectSuiteParentsRequest'),
  FETCH_PROJECT_PROJECT_SUITE_PARENTS_SUCCESS: Symbol('fetchProjectProjectSuiteParentsSuccess'),
  FETCH_PROJECT_PROJECT_SUITE_PARENTS_FAILED: Symbol('fetchProjectProjectSuiteParentsFailed'),

  // Active project id for logs
  ADD_ACTIVE_PROJECT_ID: Symbol('addActiveProjectId'),
  CLEAR_ACTIVE_PROJECT_ID: Symbol('clearActiveProjectId'),

  // Active request id for logs
  ADD_ACTIVE_REQUEST_ID: Symbol('addActiveRequestId'),
  CLEAR_ACTIVE_REQUEST_ID: Symbol('clearActiveRequestId'),

  // Action types for activity logging
  FETCH_LOGS_ACTION_TYPES_REQUEST: Symbol('fetchLogsActionTypesRequest'),
  FETCH_LOGS_ACTION_TYPES_SUCCESS: Symbol('fetchLogsActionTypesSuccess'),
  FETCH_LOGS_ACTION_TYPES_FAILED: Symbol('fetchLogsActionTypesFailed'),

  // Copy to clipboard
  COPY_ACTIVITY_LOGS_TO_CLIPBOARD: Symbol('copyActivityLogsToClipboard'),

  // Set extra filters
  SET_EXTRA_FILTERS_TRUE: Symbol('setExtraFiltersTrue'),
  SET_EXTRA_FILTERS_FALSE: Symbol('setExtraFiltersFalse'),
  SET_RESOURCE_IDS_TO_FILTER: Symbol('setResourceIdsToFilter'),
  TRIGGER_PROJECT_SUITE_TASKS_FILTERING: Symbol('triggerProjectSuiteTasksFiltering'),
  TRIGGER_NOTIFICATION_EVENTS_FILTERING: Symbol('triggerNotificationEventsFiltering'),

  // Fetch devices project suites
  FETCH_DEVICES_PROJECT_SUITES_REQUEST: Symbol('fetchDevicesProjectSuites'),
  FETCH_DEVICES_PROJECT_SUITES_SUCCESS: Symbol('fetchDevicesProjectSuitesSuccess'),
  FETCH_DEVICES_PROJECT_SUITES_FAILED: Symbol('fetchDevicesProjectSuitesFailure'),

  // Child Projects for Map
  FETCH_CHILD_PROJECTS_FOR_MAP_REQUEST: Symbol('fetchChildProjectsForMapRequest'),
  FETCH_CHILD_PROJECTS_FOR_MAP_SUCCESS: Symbol('fetchChildProjectsForMapSuccess'),
  FETCH_CHILD_PROJECTS_FOR_MAP_FAILED: Symbol('fetchChildProjectsForMapFailed'),

  // ProjectSuite Id Context
  ADD_PROJECT_SUITE_ID_CONTEXT: Symbol('addProjectSuiteIdContext'),
  CLEAR_PROJECT_SUITE_ID_CONTEXT: Symbol('clearProjectSuiteIdContext'),

  // SITE ACTION TYPES LISTS
  FETCH_SITE_ACTION_TYPES_LIST_REQUEST: Symbol('fetchSiteActionTypesListRequest'),
  FETCH_SITE_ACTION_TYPES_LIST_SUCCESS: Symbol('fetchSiteActionTypesListSuccess'),
  FETCH_SITE_ACTION_TYPES_LIST_FAILED: Symbol('fetchSiteActionTypesListFailed'),

  // Add selected projectId
  ADD_SELECTED_PROJECT_ID: Symbol('addSelectedProjectId'),
  CLEAR_SELECTED_PROJECT_ID: Symbol('clearSelectedProjectId'),

  // New Permissions Table
  FETCH_ALL_PERMISSIONS_REQUEST: Symbol('fetchAllPermissions'),
  FETCH_ALL_PERMISSIONS_SUCCESS: Symbol('fetchAllPermissionsSuccess'),
  FETCH_ALL_PERMISSIONS_FAILED: Symbol('fetchAllPermissionsFailed'),

  FETCH_CUMULATED_PERMISSIONS_REQUEST: Symbol('fetchCumulatedPermissions'),
  FETCH_CUMULATED_PERMISSIONS_SUCCESS: Symbol('fetchCumulatedPermissionsSuccess'),
  FETCH_CUMULATED_PERMISSIONS_FAILED: Symbol('fetchCumulatedPermissionsFailed'),

  FETCH_PERMISSIONS_RECIPIENTS_REQUEST: Symbol('fetchPermissionsTableRecipients'),
  FETCH_PERMISSIONS_RECIPIENTS_SUCCESS: Symbol('fetchPermissionsTableRecipientsSuccess'),
  FETCH_PERMISSIONS_RECIPIENTS_FAILED: Symbol('fetchPermissionsTableRecipientsFailed'),

  GRANT_MULTIPLE_PERMISSIONS_REQUEST: Symbol('grantMultiplePermissions'),
  GRANT_MULTIPLE_PERMISSIONS_SUCCESS: Symbol('grantMultiplePermissionsSuccess'),
  GRANT_MULTIPLE_PERMISSIONS_FAILED: Symbol('grantMultiplePermissionsFailed'),

  REVOKE_MULTIPLE_PERMISSIONS_REQUEST: Symbol('revokeMultiplePermissions'),
  REVOKE_MULTIPLE_PERMISSIONS_SUCCESS: Symbol('revokeMultiplePermissionsSuccess'),
  REVOKE_MULTIPLE_PERMISSIONS_FAILED: Symbol('revokeMultiplePermissionsFailed'),

  COPY_MULTIPLE_PERMISSIONS_REQUEST: Symbol('copyMultiplePermissions'),

  PASTE_MULTIPLE_PERMISSIONS_REQUEST: Symbol('pasteMultiplePermissions'),
  PASTE_MULTIPLE_PERMISSIONS_SUCCESS: Symbol('pasteMultiplePermissionsSuccess'),
  PASTE_MULTIPLE_PERMISSIONS_FAILED: Symbol('pasteMultiplePermissionsFailed'),

  CLEAR_PERMISSIONS_TABLE_REQUEST: Symbol('clearPermissionsTableRequest'),
  TOGGLE_RESOURCE_OPEN_PERMISSIONS_TABLE_REQUEST: Symbol('toggleResourceOpenPermissionsTableRequest'),
  CHANGE_RECIPIENTS_PERMISSIONS_TABLE_REQUEST: Symbol('changeRecipientsPermissionsTableRequest'),
  ADD_RESOURCE_ID_PERMISSIONS_TABLE_REQUEST: Symbol('addResourceIdPermissionsTableRequest'),
  REMOVE_RESOURCE_ID_PERMISSIONS_TABLE_REQUEST: Symbol('removeResourceIdPermissionsTableRequest'),

  // Device firmwares
  ADD_DEVICE_FIRMWARE_REQUEST: Symbol('addDeviceFirmwareRequest'),
  ADD_DEVICE_FIRMWARE_SUCCESS: Symbol('addDeviceFirmwareSuccess'),
  ADD_DEVICE_FIRMWARE_FAILED: Symbol('addDeviceFirmwareFailed'),
  FETCH_DEVICE_FIRMWARES_REQUEST: Symbol('fetchDeviceFirmwaresRequest'),
  FETCH_DEVICE_FIRMWARES_SUCCESS: Symbol('fetchDeviceFirmwaresSuccess'),
  FETCH_DEVICE_FIRMWARES_FAILED: Symbol('fetchDeviceFirmwaresFailed'),
  FETCH_DEVICE_FIRMWARES_MODEL_TREE_REQUEST: Symbol('fetchDeviceFirmwaresModelTreeRequest'),
  FETCH_DEVICE_FIRMWARES_MODEL_TREE_SUCCESS: Symbol('fetchDeviceFirmwaresModelTreeSuccess'),
  FETCH_DEVICE_FIRMWARES_MODEL_TREE_FAILED: Symbol('fetchDeviceFirmwaresModelTreeFailed'),
  UPLOAD_FIRMWARE_FILE_REQUEST: Symbol('uploadFirmwareFileRequest'),
  UPLOAD_FIRMWARE_FILE_SUCCESS: Symbol('uploadFirmwareFileSuccess'),
  UPLOAD_FIRMWARE_FILE_FAILED: Symbol('uploadFirmwareFileFailed'),
  UPLOAD_FIRMWARE_FILE_AFTER_DEVICE: Symbol('uploadFirmwareFileAfterDevice'),
  FETCH_FIRMWARE_FILES_REQUEST: Symbol('fetchFirmwareFilesRequest'),
  FETCH_FIRMWARE_FILES_SUCCESS: Symbol('fetchFirmwareFilesSuccess'),
  FETCH_FIRMWARE_FILES_FAILED: Symbol('fetchFirmwareFilesFailed'),
  DELETE_DEVICE_FIRMWARE_REQUEST: Symbol('deleteDeviceFirmwareRequest'),
  DELETE_DEVICE_FIRMWARE_SUCCESS: Symbol('deleteDeviceFirmwareSuccess'),
  DELETE_DEVICE_FIRMWARE_FAILED: Symbol('deleteDeviceFirmwareFailed'),
  LINK_FILE_WITH_DEVICE_FIRMWARE_REQUEST: Symbol('linkFileWithDevice'),
  LINK_FILE_WITH_DEVICE_FIRMWARE_SUCCESS: Symbol('linkFileWithDeviceSuccess'),
  LINK_FILE_WITH_DEVICE_FIRMWARE_FAILED: Symbol('linkFileWithDeviceFailed'),
  EDIT_DEVICE_FIRMWARE_REQUEST: Symbol('editDeviceFirmwareRequest'),
  EDIT_DEVICE_FIRMWARE_SUCCESS: Symbol('editDeviceFirmwareSuccess'),
  EDIT_DEVICE_FIRMWARE_FAILED: Symbol('editDeviceFirmwareFailed'),
  TRANSFER_DEVICE_FIRMWARE_REQUEST: Symbol('transferDeviceFirmwareRequest'),
  TRANSFER_DEVICE_FIRMWARE_SUCCESS: Symbol('transferDeviceFirmwareSuccess'),
  TRANSFER_DEVICE_FIRMWARE_FAILED: Symbol('transferDeviceFirmwareFailed'),

  // Renew site when suspended or in grace period
  RENEW_SUSPENDED_SITE_REQUEST: Symbol('renewSuspendedSiteRequest'),
  RENEW_SUSPENDED_SITE_SUCCESS: Symbol('renewSuspendedSiteSuccess'),
  RENEW_SUSPENDED_SITE_FAILED: Symbol('renewSuspendedSiteFailed'),
};
