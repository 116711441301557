import styled, { css } from 'styled-components';
import { List } from 'semantic-ui-react';

import filterProps from 'helpers/filterProps';

const FilteredProps = filterProps([
  'noPaddingTop',
  'noPaddingBottom',
])(List.Content);

export const StyledListContent = styled(FilteredProps)`
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ noPaddingTop }) => noPaddingTop && css`
    padding-top: 0px;
  `}

  ${({ noPaddingBottom }) => noPaddingBottom && css`
    padding-bottom: 0px;
  `}
`;

export const ListLabel = styled.span`
  margin-right: 4px;
`;

export default StyledListContent;
