import { takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import addDeviceFirmware from './addDeviceFirmware';
import fetchDeviceFirmwares from './fetchDeviceFirmwares';
import fetchDeviceFirmwaresModelTree from './fetchDeviceFirmwaresModelTree';
import uploadFirmwareFile from './uploadFirmwareFile';
import uploadFirmwareFileSuccess from './uploadFirmwareFileSuccess';
import fetchFirmwareFiles from './fetchFirmwareFiles';
import uploadFirmwareFileAfterDevice from './uploadFirmwareFileAfterDevice';
import deleteDeviceFirmware from './deleteDeviceFirmware';
import linkFileWithDeviceFirmware from './linkFileWithDeviceFirmware';
import editDeviceFirmware from './editDeviceFirmware';
import transferDeviceFirmware from './transferDeviceFirmware';

function* saga() {
  yield takeLatest(constants.ADD_DEVICE_FIRMWARE_REQUEST, addDeviceFirmware);
  yield takeLatest(constants.FETCH_DEVICE_FIRMWARES_REQUEST, fetchDeviceFirmwares);
  yield takeLatest(
    constants.FETCH_DEVICE_FIRMWARES_MODEL_TREE_REQUEST, fetchDeviceFirmwaresModelTree,
  );
  yield takeLatest(constants.UPLOAD_FIRMWARE_FILE_REQUEST, uploadFirmwareFile);
  yield takeLatest(constants.UPLOAD_FIRMWARE_FILE_SUCCESS, uploadFirmwareFileSuccess);
  yield takeLatest(constants.FETCH_FIRMWARE_FILES_REQUEST, fetchFirmwareFiles);
  yield takeLatest(constants.UPLOAD_FIRMWARE_FILE_AFTER_DEVICE, uploadFirmwareFileAfterDevice);
  yield takeLatest(constants.DELETE_DEVICE_FIRMWARE_REQUEST, deleteDeviceFirmware);
  yield takeLatest(constants.LINK_FILE_WITH_DEVICE_FIRMWARE_REQUEST, linkFileWithDeviceFirmware);
  yield takeLatest(constants.EDIT_DEVICE_FIRMWARE_REQUEST, editDeviceFirmware);
  yield takeLatest(constants.TRANSFER_DEVICE_FIRMWARE_REQUEST, transferDeviceFirmware);
}

export default saga;
