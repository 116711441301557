import { compose } from 'redux';
import { connect } from 'react-redux';

import ProjectModel from 'models/ProjectModel';
import { projectSelector, projectFetchingSelector } from 'helpers/selectors';
import mapMarker from '../../../../../../Map/mapMarker/mapMarker';
import siteActionCreators from '../../../../../../../utilities/actionCreators';
import TempSiteMarker from './TempSiteMarker';

export const mapStateToProps = (state, { siteId }) => {
  const site = projectSelector(state)(siteId) || {};
  const { tempAddress } = new ProjectModel(site);

  return {
    tempAddress,
    updating: projectFetchingSelector(state),
  };
};

export const mapDispatchToProps = {
  onUpdateSiteLocation: siteActionCreators.updateSiteLocation,
};

export default compose(
  mapMarker({
    onSelect: () => null,
    onUnselect: () => null,
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(TempSiteMarker);
