import difference from 'lodash/difference';
import { isEmpty } from 'lodash';

const getCurrentSelectedValue = (currentValues, prevValues) => {
  if (!Array.isArray(currentValues) || !Array.isArray(prevValues)) {
    return null;
  }

  if (isEmpty(currentValues) && isEmpty(prevValues)) {
    return null;
  }

  return difference(currentValues, prevValues)[0];
};

export default getCurrentSelectedValue;
