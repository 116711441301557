import constants from 'dispatcherConst';
import { NOTIFICATION_EVENTS_FIELD } from 'helpers/selectors';

export const refetchNotificationEvents = additionalFilters => ({
  type: constants.REFETCH_NOTIFICATION_EVENTS,
  payload: {
    resourceType: NOTIFICATION_EVENTS_FIELD,
    additionalFilters,
  },
});

export default refetchNotificationEvents;
