import { connect } from 'react-redux';

import performActionActionCreators from 'pages/Device/performActionActionCreators';
import TriggerTableRow from 'components/elements/TriggerTableRow/TriggerTableRow';

const mapDispatchToProps = {
  onTrigger: performActionActionCreators.performTriggersCond,
};

export default connect(null, mapDispatchToProps)(TriggerTableRow);
