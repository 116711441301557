import React from 'react';
import { fieldPropTypes } from 'redux-form';
import isNumber from 'lodash/isNumber';

import TimeInput from '../TimeInput/TimeInput';

const TimeField = ({ input: { value, onChange }, meta, ...rest }) => (
  <TimeInput
    {...rest}
    value={isNumber(value) ? value : null}
    onChange={onChange}
  />
);

TimeField.propTypes = {
  ...fieldPropTypes,
};

export default TimeField;
