import mergeWith from 'lodash/mergeWith';
import omit from 'lodash/omit';
import { DateTime } from 'luxon';

import constants from 'dispatcherConst';
import socketConstants from 'data/websockets/constants';
import { mergeCustomizer } from 'helpers/sharedMethods';
import { NOTIFICATION_EVENTS_DROPDOWN_FIELD } from 'helpers/selectors';

const initialState = {
  hasUnseenNotifications: false,
  notificationFilters: null,
  additionalFilters: {},
  isLoadingNotifications: false,
};

const notificationEventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SOCKET_RECEIVED: {
      const { identifier, message } = action.payload;

      if (identifier && message && identifier.channel === socketConstants.NOTIFICATIONS_CHANNEL) {
        return {
          ...state,
          hasUnseenNotifications: true,
        };
      }

      return {
        ...state,
      };
    }
    case constants.READ_NOTIFICATION_EVENT_SUCCESS: {
      const { notificationEventId } = action.payload.response;

      if (!notificationEventId || !state[NOTIFICATION_EVENTS_DROPDOWN_FIELD]) {
        return state;
      }

      const modifiedNotification = state[NOTIFICATION_EVENTS_DROPDOWN_FIELD][notificationEventId];

      modifiedNotification.attributes = {
        ...modifiedNotification.attributes,
        readAt: DateTime.utc().toISO(),
      };

      const notificationEventsState = {
        [NOTIFICATION_EVENTS_DROPDOWN_FIELD]: {
          [notificationEventId]: {
            ...modifiedNotification,
          },
        },
      };

      return mergeWith({}, state, notificationEventsState, mergeCustomizer);
    }
    case constants.ARCHIVE_NOTIFICATION_EVENT_SUCCESS: {
      const { notificationEventId } = action.payload.response;

      if (!notificationEventId) {
        return state;
      }

      return {
        ...state,
        [NOTIFICATION_EVENTS_DROPDOWN_FIELD]: omit(
          state[NOTIFICATION_EVENTS_DROPDOWN_FIELD], notificationEventId,
        ),
      };
    }
    case constants.ADD_NOTIFICATION_EVENT_FROM_WEBSOCKET: {
      const { notificationEvent } = action.payload;

      if (!notificationEvent) {
        return state;
      }

      const { data } = notificationEvent;

      return {
        ...state,
        [NOTIFICATION_EVENTS_DROPDOWN_FIELD]: {
          [data.id]: {
            ...data,
          },
          ...state[NOTIFICATION_EVENTS_DROPDOWN_FIELD],
        },
      };
    }
    case constants.CLEAR_NOTIFICATION_EVENT_ICON: {
      return {
        ...state,
        hasUnseenNotifications: false,
      };
    }
    case constants.SET_NOTIFICATION_EVENT_FILTERS: {
      const { notificationFilters, additionalFilters } = action.payload;

      return {
        ...state,
        notificationFilters,
        additionalFilters,
      };
    }

    case constants.FETCH_NOTIFICATION_EVENTS_FOR_DROPDOWN: {
      return {
        ...state,
        isLoadingNotifications: true,
      };
    }
    case constants.FETCH_NOTIFICATION_EVENTS_FOR_DROPDOWN_SUCCESS: {
      const { response } = action.payload || {};
      const { data = [] } = response || {};

      const formattedData = data.reduce((acc, notificationEvent) => {
        acc[notificationEvent.id] = {
          ...notificationEvent,
        };

        return acc;
      }, {});

      const notificationsForDropdown = {
        [NOTIFICATION_EVENTS_DROPDOWN_FIELD]: formattedData,
        isLoadingNotifications: false,
      };

      return mergeWith({}, state, notificationsForDropdown, mergeCustomizer);
    }
    case constants.FETCH_NOTIFICATION_EVENTS_FOR_DROPDOWN_FAILED: {
      return {
        ...state,
        isLoadingNotifications: false,
      };
    }

    case constants.READ_ALL_NOTIFICATION_EVENTS_SUCCESS: {
      const notificationEventsDropdown = {};

      const notificationEvents = state[NOTIFICATION_EVENTS_DROPDOWN_FIELD];

      Object.keys(notificationEvents).reduce(
        (acc, notificationEventId) => {
          const modifiedNotification = notificationEvents[notificationEventId];

          modifiedNotification.attributes = {
            ...modifiedNotification.attributes,
            readAt: DateTime.utc().toISO(),
          };

          acc[notificationEventId] = {
            ...modifiedNotification,
          };

          return acc;
        }, notificationEventsDropdown);

      const newState = {
        notificationEventsDropdown,
      };

      return mergeWith({}, state, newState, mergeCustomizer);
    }

    case constants.ARCHIVE_ALL_NOTIFICATION_EVENTS_SUCCESS: {
      const notificationEventsDropdown = {};

      const notificationEvents = state[NOTIFICATION_EVENTS_DROPDOWN_FIELD];

      Object.keys(notificationEvents).reduce(
        (acc, notificationEventId) => {
          const modifiedNotification = notificationEvents[notificationEventId];

          modifiedNotification.attributes = {
            ...modifiedNotification.attributes,
            archivedAt: DateTime.utc().toISO(),
          };

          acc[notificationEventId] = {
            ...modifiedNotification,
          };

          return acc;
        }, notificationEventsDropdown);

      const newState = {
        notificationEventsDropdown,
      };

      return mergeWith({}, state, newState, mergeCustomizer);
    }

    default:
      return state;
  }
};

export default notificationEventsReducer;
