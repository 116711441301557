import styled from 'styled-components';
import Dropzone from 'react-dropzone';

import colors from 'themes/colors';
import filterProps from 'helpers/filterProps';

export const StyledThumbnail = styled.img`
  width: 100%;
`;

const StyledDropzone = styled(filterProps('error')(Dropzone))`
  position: relative;
  height: 300px;
  width: 100%;

  font-size: 1.1em;
  border: 2px dashed black;

  &.error {
    border-color: ${colors.stiletto};
    color: ${colors.stiletto};
  }
`;

StyledDropzone.Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 50%;
  transform: translate(-50%, -50%);

  & > span {
    word-break: break-word;
  }
`;

export default StyledDropzone;
