import React from 'react';
import { Input } from 'semantic-ui-react';

import ToggleField from '../ToggleField/ToggleField';
import FormField from '../FormField/FormField';

const ToggleFieldInput = props => (
  <FormField {...props}>
    <ToggleField {...props} />
  </FormField>
);

ToggleFieldInput.propTypes = {
  ...Input.propTypes,
};

export default ToggleFieldInput;
