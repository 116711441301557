import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { UNJOINED_REMOTE_DEVICES_FIELD } from 'helpers/selectors';
import { addPagination } from 'helpers/paginationHelpers';
import UnjoinedRemoteDevicesTable from './UnjoinedRemoteDevicesTable';
import { actionCreators as remoteDevicesActionCreators } from '../../utilities';

export const mapStateToProps = (state, { match }) => {
  const { deviceId } = match.params;

  return {
    deviceId,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: UNJOINED_REMOTE_DEVICES_FIELD, included: true },
      ({ deviceId }) => remoteDevicesActionCreators.fetchUnjoinedRemoteDevices(deviceId),
    ),
  ),
)(UnjoinedRemoteDevicesTable);
