import { connect } from 'react-redux';
import { compose } from 'redux';

import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { currentUserSelector } from 'helpers/selectors';

import { actionCreators, selectors } from './utilities';
import StatusWidgets from './StatusWidgets';

export const mapStateToProps = (state, { type }) => {
  const { id: userId } = currentUserSelector(state);

  return {
    userId,
    type,
    isLoading: selectors.isLoadingProjectsStatusSelector(state),
    projectsStatus: selectors.projectsStatusSelector(state),
  };
};

export default compose(
  connect(mapStateToProps),
  chainedLoaderWithSaga(({ userId, type }) => actionCreators.fetchProjectsStatus(
    userId,
    type,
    {
      fields: {
        projects: [
          'name',
          'state',
          'onlineDevices',
          'offlineDevices',
          'unassociatedDevices',
          'devicesForUpgrade',
        ],
      },
    },
  )),
)(StatusWidgets);
