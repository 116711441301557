import isFunction from 'lodash/isFunction';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

import createUserPermissionMatcher from './createUserPermissionMatcher';

const permissionsMatchRequirements = requirements => {
  const {
    condition,
    superadmin,
    userPermissions,
    ...rest
  } = requirements || {};

  if (superadmin) {
    return true;
  }

  if (!userPermissions) {
    return false;
  }

  const containsPermission = createUserPermissionMatcher({ userPermissions, ...rest });

  if (isFunction(condition)) {
    return condition(containsPermission);
  }

  if (isArray(condition)) {
    return condition.every(containsPermission);
  }

  if (isObject(condition)) {
    return containsPermission(condition);
  }

  return containsPermission({ name: condition });
};

export default permissionsMatchRequirements;
