import styled from 'styled-components';
import { Button } from 'components/atomic';

export const StyledButton = styled(Button)`
  &&& {
    margin-top: 15px;
  }  
`;

export default StyledButton;
