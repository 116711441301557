import uniqueId from 'lodash/uniqueId';

import constants from 'dispatcherConst';
import { ERROR_LEVEL, SUCCESS_LEVEL, INFO_LEVEL } from './notificationsConst';

export const ID_PREFIX = 'notification_';
const initialState = {
  error: undefined,
  specificErrors: {},
  success: undefined,
  info: undefined,
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {

    // Specific errors
    case constants.ADD_REQUESTER_ERROR: {
      const error = { [action.payload.requester]: action.payload.errors };
      return { ...state, specificErrors: error };
    }

    case constants.CLEAR_REQUESTER_ERRORS: {
      const newState = { ...state };
      delete newState.specificErrors[action.payload.requester];
      return newState;
    }

    // General errors
    case constants.ADD_GENERAL_ERROR: {
      const { error: message } = action.payload;

      return {
        ...state,
        error: message && {
          id: uniqueId(ID_PREFIX),
          message,
          level: ERROR_LEVEL,
          duration: 25,
        },
        success: undefined,
        info: undefined,
      };
    }

    // General notifications
    case constants.ADD_GENERAL_NOTIFICATION: {
      const { message } = action.payload;

      return {
        ...state,
        error: undefined,
        success: message && {
          id: uniqueId(ID_PREFIX),
          message,
          level: SUCCESS_LEVEL,
          duration: 10,
        },
        info: undefined,
      };
    }

    case constants.ADD_INFO_NOTIFICATION: {
      const { message } = action.payload;

      return {
        ...state,
        error: undefined,
        success: undefined,
        info: message && {
          id: uniqueId(ID_PREFIX),
          message,
          level: INFO_LEVEL,
          duration: 10,
        },
      };
    }

    default:
      return state;
  }
};

export default notificationsReducer;
