import { connect } from 'react-redux';

import { actionCreators } from '../../utilities';
import UploadFirmwareFile from './UploadFirmwareFile';

export const mapDispatchToProps = {
  onFirmwareFileUpload: actionCreators.uploadFirmwareFile,
};

export default connect(null, mapDispatchToProps)(UploadFirmwareFile);
