import {
  addOrigin,
  addOriginSuccess,
  addOriginFailure,
} from './addOrigin';
import {
  deleteOrigin,
  deleteOriginSuccess,
  deleteOriginFailure,
} from './deleteOrigin';
import {
  editOrigin,
  editOriginSuccess,
  editOriginFailure,
} from './editOrigin';
import editUrlOrigin from './editUrlOrigin';

export default {
  addOrigin,
  addOriginSuccess,
  addOriginFailure,
  deleteOrigin,
  deleteOriginSuccess,
  deleteOriginFailure,
  editOrigin,
  editOriginSuccess,
  editOriginFailure,
  editUrlOrigin,
};
