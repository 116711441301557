import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import {
  USERS_FIELD,
  groupedSortedNotificationTypesSelector,
  permissionsProjectUsersLinkSelector,
  userSelector,
  currentCompanyNotificationsEnabledSelector,
} from 'helpers/selectors';
import { addPagination } from 'helpers/paginationHelpers';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { permissionsActionCreators } from 'application/tenant/console/utilities';
import notificationTypesActionCreators from 'components/NotificationTypes/notificationTypesActionCreators';

import Notifications from './Notifications';

export const mapStateToProps = (state, { match, userId, config }) => {
  const { id: resourceId } = match.params || {};
  const { firstName, lastName } = userSelector(state)(userId);

  return {
    userName: `${firstName} ${lastName}`,
    resourceId,
    userId,
    usersEndpoint: permissionsProjectUsersLinkSelector(state)(resourceId),
    groupedNotificationTypes: groupedSortedNotificationTypesSelector(state)(config.context),
    notificationsEnabled: currentCompanyNotificationsEnabledSelector(state),
  };
};

const mapDispatchToProps = {
  onFetchNotificationTypes: notificationTypesActionCreators.fetchNotificationTypes,
  onFetchResourcesWithNotification: notificationTypesActionCreators.fetchResourcesWithNotifications,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: USERS_FIELD, included: true },
      ({ resourceId, usersEndpoint }) => permissionsActionCreators.fetchProjectUsers(
        resourceId,
        usersEndpoint,
        {
          fields: {
            [USERS_FIELD]: [
              'firstName',
              'lastName',
              'email',
              'lastLoginTime',
            ],
          },
          sort: 'firstName',
        },
      ),
    ),
  ),
)(Notifications);
