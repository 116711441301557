import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import { validDisplayName, validActionName } from 'components/form/formValidators';
import InlineEditTaskProperty from './InlineEditTaskProperty';

export const mapStateToProps = (state, { property, propertyType }) => {
  const validatorFunc = propertyType === 'action' ? validActionName : validDisplayName;
  const validator = isEqual(property, 'name') ? validatorFunc : null;

  return {
    validator,
  };
};

const mapDispatchToProps = (dispatch, { onChange }) => ({
  onChangeProperty: args => dispatch(onChange(args)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(InlineEditTaskProperty);
