import mergeWith from 'lodash/mergeWith';

import constants from 'dispatcherConst';
import { mergeCustomizer } from 'helpers/sharedMethods';
import { PROJECT_PROJECT_SUITE_PARENTS_FIELD } from 'helpers/selectors';

const initialState = {};

export const projectProjectSuiteParentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_PROJECT_PROJECT_SUITE_PARENTS_SUCCESS: {
      const { response: { data, projectId } } = action.payload;
      const parentsList = Array.isArray(data) && data.map(
        ({ id, attributes: { name } }) => ({ id, name }),
      );

      const projectProjectSuiteParentsList = {
        [PROJECT_PROJECT_SUITE_PARENTS_FIELD]: {
          [projectId]: {
            attributes: {
              projectSuiteParentsList: Array.isArray(parentsList) ? [...parentsList] : [],
            },
          },
        },
      };

      return mergeWith({}, state, projectProjectSuiteParentsList, mergeCustomizer);
    }

    default:
      return state;
  }
};

export default projectProjectSuiteParentsReducer;
