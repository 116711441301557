import constants from 'dispatcherConst';

const initialState = {
  isLoading: false,
  projectsStatus: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_PROJECTS_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
        projectsStatus: [],
      };
    case constants.FETCH_PROJECTS_STATUS_SUCCESS: {
      const { response } = action.payload;
      const { data } = response;

      return {
        ...state,
        projectsStatus: data.map(({ id, attributes }) => ({
          id,
          ...attributes,
        })),
        isLoading: false,
      };
    }
    case constants.FETCH_PROJECTS_STATUS_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default reducer;
