import { takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import fetchRemoteDevices from './fetchRemoteDevices';
import fetchUnjoinedRemoteDevices from './fetchUnjoinedRemoteDevices';

function* saga() {
  yield takeLatest(constants.FETCH_REMOTE_DEVICES_REQUEST, fetchRemoteDevices);
  yield takeLatest(constants.FETCH_UNJOINED_REMOTE_DEVICES_REQUEST, fetchUnjoinedRemoteDevices);
}

export default saga;
