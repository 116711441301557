import {
  fetchTwoFactorQRCode,
  fetchTwoFactorQRCodeSuccess,
  fetchTwoFactorQRCodeFailure,
} from './fetchTwoFactorQRCode';

export default {
  fetchTwoFactorQRCode,
  fetchTwoFactorQRCodeSuccess,
  fetchTwoFactorQRCodeFailure,
};
