import styled, { css } from 'styled-components';
import { Popup, Label } from 'semantic-ui-react';
import get from 'lodash/get';

export const StyledPopup = styled(Popup)`
  ${({ theme }) => css`
    &&& {
      padding: 0;

      &.ui.popup {
        max-width: unset;
      }

      .ui.menu {
        margin-bottom: 0 !important;
        overflow-x: auto;
        overflow-y: hidden;
        scrollbar-width: thin;
        -ms-overflow-style: none;
        -webkit-overflow-scrolling: touch;
      }

      .ui.menu::-webkit-scrollbar {
        height: 4px;
      }

      @media only screen and (max-width: 768px) {
        &.ui.wide.popup {
          min-width: calc(83vw) !important;
          max-width: calc(83vw) !important;
        }       

        .ui.menu {
          scrollbar-width: none;
        }

        .ui.menu::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
      }

      .ui.segment {
        margin-top: 0 !important;
        border-top: 0 !important;
      }

      .ui a.item {
        color: #000;
        opacity: 20%;
      }

      .ui a.active {
        color: ${get(theme, 'palette.primaryColor')} !important;
        border-color: ${get(theme, 'palette.primaryColor')} !important;
        opacity: 100%;
      }
    }
  `}
`;

export const TriggerContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLabel = styled(Label)`
  &&& {
    margin-left: 8px !important;
  }  
`;

export const EmptyMessage = styled.p`
  margin-left: 8px;
  font-size: 14px;
`;

export default StyledPopup;
