import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  USERS_FIELD,
  PROJECTS_FIELD,
  singleProjectSelector,
  permissionsProjectOpenSelector,
  permissionsProjectUsersLinkSelector,
} from 'helpers/selectors';
import { addPagination } from 'helpers/paginationHelpers';
import chainedLoaderWithSaga from 'helpers/chainedLoader/chainedLoaderWithSagaContainer';
import { AddUsersModal, PermissionsGridWrapper } from '../../../../components';
import permissionsActionCreators from '../../../../utilities/permissions/permissionsActionCreators';
import { ProjectPermissionsTree } from '../../tree/PermissionsTree';
import { ProjectPermissionsGridHeaders } from '../../headers/PermissionsGridHeaders';
import { ProjectPermissionsGrid } from '../../grid/PermissionsGrid';

export const mapStateToProps = (state, { projectId }) => {
  const { name: projectName } = singleProjectSelector(state, projectId);

  return {
    open: permissionsProjectOpenSelector(state, { projectId }),
    usersEndpoint: permissionsProjectUsersLinkSelector(state)(projectId),
    resourceId: projectId,
    resourceTitle: 'Site',
    resourceName: projectName,
    PermissionsTree: ProjectPermissionsTree,
    PermissionsGridHeaders: ProjectPermissionsGridHeaders,
    PermissionsGrid: ProjectPermissionsGrid,
    AddUsersButton: AddUsersModal,
    isProject: true,
  };
};

export const mapDispatchToProps = {
  onToggleOpen: projectId => permissionsActionCreators.toggleResourceOpen(
    PROJECTS_FIELD,
    projectId,
  ),
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  chainedLoaderWithSaga(
    addPagination(
      { resourceType: USERS_FIELD, included: true },
      ({ resourceId, usersEndpoint }) => permissionsActionCreators.fetchProjectUsers(
        resourceId,
        usersEndpoint,
        {
          fields: {
            [USERS_FIELD]: ['firstName', 'lastName', 'email'],
          },
          sort: 'firstName',
        },
      ),
    ),
  ),
)(PermissionsGridWrapper);
