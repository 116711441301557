import { call, takeEvery, takeLatest, put, select } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import DeviceModel from 'models/DeviceModel';
import { ERROR_SERVER_CONNECTION } from 'data/notifications/notificationsConst';
import { deletePath, getPath, postPath, putPath } from 'data/api/requests';
import { LOGICAL_DEVICES_FIELD, logicalDeviceSelector } from 'helpers/selectors';
import { processCall } from 'helpers/sagaHelper';
import { prepareResponseData } from 'helpers/paginationHelpersClient';
import deviceActionCreators from './deviceActionCreators';

export function* addNewDevice(action) {
  const {
    endpoint,
    params: { values, resolveForm, rejectForm },
  } = action.payload;
  const { response, error } = yield call(postPath, endpoint, { meta: values });

  const params = {
    response,
    error,
    resolveForm,
    rejectForm,
    successDisp: deviceActionCreators.addDeviceSuccess,
    failDisp: deviceActionCreators.addDeviceFailure,
  };
  yield call(processCall, params);
}

export function* replaceDeviceToken(action) {
  const { endpoint, params: { values } } = action.payload;
  const { response, error } = yield call(postPath, endpoint, { meta: values });

  const params = {
    response,
    error,
    successDisp: deviceActionCreators.replaceDeviceTokenSuccess,
    failDisp: deviceActionCreators.replaceDeviceTokenFailure,
  };
  yield call(processCall, params);
}

export function* fetchDevice(action) {
  const { endpoint, urlParams } = action.payload;
  const { response, error } = yield call(getPath, endpoint, {
    urlParams,
  });

  const params = {
    response,
    error,
    successDisp: deviceActionCreators.fetchDeviceSuccess,
    failDisp: deviceActionCreators.fetchDeviceFailure,
  };
  yield call(processCall, params);
}

export function* removeDevice(action) {
  const { endpoint } = action.payload;
  const { response, error } = yield call(deletePath, endpoint);

  const params = {
    response,
    error,
    successDisp: deviceActionCreators.removeDeviceSuccess,
    failDisp: deviceActionCreators.removeDeviceFailure,
  };
  yield call(processCall, params);
}

export function* editDeviceName(action) {
  const {
    endpoint,
    params: { values, resolveForm, rejectForm },
  } = action.payload;
  const body = DeviceModel.requestBody(values);
  const paramsForFetch = {
    fields: {
      [LOGICAL_DEVICES_FIELD]: [
        'name',
      ],
    },
  };

  const { response, error } = yield call(putPath, endpoint, body);
  if (error instanceof Error) { error.message = ERROR_SERVER_CONNECTION; }

  const params = {
    response,
    error,
    resolveForm,
    rejectForm,
    successDisp: deviceActionCreators.renameDeviceSuccess,
    failDisp: deviceActionCreators.renameDeviceFailure,
  };
  yield call(processCall, params);

  if (response) { yield put(deviceActionCreators.fetchDevice(values.id, paramsForFetch)); }
}

export function* fetchIOModules(action) {
  const { endpoint, deviceId, ...rest } = action.payload;
  const { response, error } = yield call(getPath, endpoint);

  const params = {
    response,
    error,
    successDisp: deviceActionCreators.fetchIoModulesSuccess,
    failDisp: deviceActionCreators.fetchIoModulesFailure,
    ...rest,
  };
  yield call(processCall, params);
}

export function* fetchIOModulesInstances(action) {
  const { endpoint, urlParams, deviceId, ...rest } = action.payload;
  const { response, error } = yield call(getPath, endpoint, { urlParams });

  const params = {
    response,
    error,
    successDisp: deviceActionCreators.fetchIoModulesInstancesSuccess,
    failDisp: deviceActionCreators.fetchIoModulesInstancesFailure,
    ...rest,
  };

  yield call(processCall, params);

  if (response) {
    const device = (yield select(logicalDeviceSelector))(deviceId);
    const enhancedResponse = yield call(prepareResponseData, response, urlParams, device);

    yield put(deviceActionCreators.fetchIoModulesInstancesPaginated(enhancedResponse));
  }
}

function* addDeviceSaga() {
  yield takeLatest(constants.ADD_DEVICE_REQUEST, addNewDevice);
  yield takeLatest(constants.REPLACE_DEVICE_TOKEN_REQUEST, replaceDeviceToken);
  yield takeEvery(constants.FETCH_DEVICE_REQUEST, fetchDevice);
  yield takeLatest(constants.REMOVE_DEVICE_REQUEST, removeDevice);
  yield takeLatest(constants.RENAME_DEVICE, editDeviceName);
  yield takeLatest(constants.FETCH_IO_MODULES, fetchIOModules);
  yield takeLatest(constants.FETCH_IO_MODULES_INSTANCES, fetchIOModulesInstances);
}

export default addDeviceSaga;
