import isNill from 'lodash/isNil';

const normalizeTime = setState => (value, prevValue) => {
  const valueLimit = 999999999000;
  if (isNill(value)) {
    setState(false);
    return null;
  }

  if (value === '') {
    return 0;
  }

  if (!Number.isFinite(parseFloat(value))) {
    return prevValue;
  }

  if ((parseFloat(value) * 1000) > valueLimit) {
    return prevValue;
  }

  if (value.toString().indexOf('.') === -1) {
    setState(-1);

    return (parseFloat(value) * 1000).toFixed(0);
  }

  const valueArr = value.split('.');

  if (valueArr[1].length === 0) {
    setState(0);

    return `${parseFloat(value) * 1000}.`;
  }

  if (valueArr[1] && valueArr[1].length === 1) {
    setState(1);

    return parseFloat(value).toFixed(1) * 1000;
  }

  if (valueArr[1] && valueArr[1].length === 3) {
    setState(3);

    return prevValue;
  }

  if (prevValue === parseFloat(value * 1000).toFixed(0)) {
    return prevValue;
  }

  setState(2);

  return (parseFloat(value).toFixed(2) * 1000).toFixed(0);
};

export default normalizeTime;
