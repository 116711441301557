import { takeLatest } from 'redux-saga/effects';

import constants from 'dispatcherConst';
import fetchProjectsStatus from './fetchProjectsStatus';

function* saga() {
  yield takeLatest(constants.FETCH_PROJECTS_STATUS_REQUEST, fetchProjectsStatus);
}

export {
  saga,
};

export default saga;
