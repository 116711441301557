import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  USERS_FIELD,
  permissionsUserOpenSelector,
} from 'helpers/selectors';
import { PermissionsGridWrapper } from '../../../../../../../../../../components';
import permissionsActionCreators from '../../../../../../../../../../utilities/permissions/permissionsActionCreators';
import { ContextUserPermissionsTree } from '../../tree/PermissionsTree';
import { ContextUserPermissionsGridHeaders } from '../../headers/PermissionsGridHeaders';
import { ContextUserPermissionsGrid } from '../../grid/PermissionsGrid';

export const mapStateToProps = (state, { wrapperUserId }) => ({
  open: permissionsUserOpenSelector(state, { wrapperUserId }),
  resourceId: wrapperUserId,
  resourceTitle: 'All users',
  PermissionsTree: ContextUserPermissionsTree,
  PermissionsGridHeaders: ContextUserPermissionsGridHeaders,
  PermissionsGrid: ContextUserPermissionsGrid,
});

const mapDispatchToProps = {
  onToggleOpen: wrapperUserId => permissionsActionCreators.toggleResourceOpen(
    USERS_FIELD,
    wrapperUserId,
  ),
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(PermissionsGridWrapper);
