import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

import filterProps from 'helpers/filterProps';

const FilteredProps = filterProps([
  'editMode',
])(Icon);

const StyledTipIcon = styled(FilteredProps)`
  position: relative;

  &.icon {
    font-size: 14px;
    display: inline-block;
    display: ${({ editMode }) => (editMode ? 'none' : 'inline-block')};
    margin: ${({ editMode }) => (editMode ? '0.45em' : '-0.35em')} 0.25em 0 0;
  }
`;

export default StyledTipIcon;
