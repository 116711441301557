import { call, put } from 'redux-saga/effects';

import { postPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import CompanyModel from 'models/CompanyModel';
import OriginModel from 'models/OriginModel';
import { originFormFields } from '../../constants';
import actionCreators from '../actionCreators';

function* addOrigin(action) {
  const {
    companyId,
    endpoint,
    params: { values, rejectForm, resolveForm },
    urlParams,
  } = action.payload;

  const { [originFormFields.url]: url, ...restValues } = values;

  const companyBody = CompanyModel.requestBody().setId(companyId);
  const body = (
    OriginModel
      .requestBody(restValues)
      .setRelationship('company', companyBody)
  );

  const { response, error } = yield call(postPath, endpoint, body, {
    urlParams,
  });

  const params = {
    response,
    error,
    successDisp: actionCreators.addOriginSuccess,
    failDisp: actionCreators.addOriginFailure,
    resolveForm,
    rejectForm,
  };
  yield call(processCall, params);

  if (response && url) {
    const editParams = {
      values: { urlOriginId: response.data.id },
      rejectForm,
    };

    yield put(actionCreators.editUrlOrigin(editParams, companyId));
  }
}

export default addOrigin;
