import { compose } from 'redux';
import { connect } from 'react-redux';

import { actionCreators } from '../../../../utilities';
import withDeleteOrigin from './withDeleteOrigin';

const mapDispatchToProps = {
  onDeleteOrigin: actionCreators.deleteOrigin,
};

export default compose(
  connect(null, mapDispatchToProps),
  withDeleteOrigin,
);
