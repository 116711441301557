import isNull from 'lodash/isNull';

const displayPercentage = value => {
  if (isNull(value)) {
    return 'No data';
  }

  return `${value} %`;
};

export default displayPercentage;
