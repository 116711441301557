import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { ListPlaceholder } from '../../..';

import {
  List,
  ListItem,
  DeviceInfo,
  StyledIcon,
  Container,
  EmptyMessage,
} from './DevicesList.style';

function DevicesList({
  isLoading,
  devices,
  onDeviceSelected,
}) {
  if (isLoading) {
    return <ListPlaceholder />;
  }

  if (isEmpty(devices)) {
    return (
      <Container flex>
        <EmptyMessage>
          No Devices were found for this site.
        </EmptyMessage>
      </Container>
    );
  }

  return (
    <>
      <List divided verticalAlign="middle">
        {devices.map((device, index) => (
          <ListItem
            key={device.id}
            tabIndex={index + 1}
            onClick={() => onDeviceSelected(device)}
          >
            <DeviceInfo>
              <p>{device.name}</p>
              <small>{`${device.manufacturerName} - ${device.model}`}</small>
            </DeviceInfo>
            <StyledIcon name="chevron right" />
          </ListItem>
        ))}
      </List>
    </>
  );
}

DevicesList.defaultProps = {
  devices: [],
  isLoading: false,
};

DevicesList.propTypes = {
  isLoading: PropTypes.bool,
  devices: PropTypes.array,
  onDeviceSelected: PropTypes.func.isRequired,
};

export default DevicesList;
