import { race, take, put, takeEvery, call, select } from 'redux-saga/effects';
import merge from 'lodash/merge';
import isFunction from 'lodash/isFunction';
import has from 'lodash/has';

import constants from 'dispatcherConst';
import { actionWithIdSelector } from 'helpers/sagaHelper';
import {
  resourceFilterSelector,
  extraFilterParamSelector,
  requestTypeFilterSelector,
  filterIsEnabledSelector,
} from 'helpers/selectors';
import { applyNotificationEventsFilters } from 'components/Notifications/utilities';

import chainedLoaderActionCreators from './chainedLoaderActionCreators';

export function* checkIfApplyFilter(method) {
  const { payload: { resourceType = '', resourceId = '' } = {} } = method;
  const isFetching = (yield select(filterIsEnabledSelector))(resourceType, resourceId);
  const filterParam = (yield select(extraFilterParamSelector));
  const type = (yield select(requestTypeFilterSelector))(resourceType, resourceId);
  const hasParams = has(method, ['payload', 'urlParams', filterParam]);
  const isSuperSiteRequest = has(method, ['payload', 'urlParams', 'projectSuiteIds[]']);
  const conditionType = method && method.type === type;

  if (isFetching && conditionType && hasParams && isSuperSiteRequest) {
    return yield filterParam;
  }

  return false;
}

export function* applyFilters(method) {
  const filterParam = yield checkIfApplyFilter(method);

  if (filterParam) {
    const { resourceType, resourceId } = method.payload;
    const resourceIds = (yield select(resourceFilterSelector))(
      resourceType, filterParam, resourceId,
    );
    const updatedMethod = { ...method };
    updatedMethod.payload.urlParams[filterParam] = [...resourceIds];

    return updatedMethod;
  }

  switch (method.type) {
    case constants.FETCH_NOTIFICATION_EVENTS: {
      const updatedMethod = yield applyNotificationEventsFilters(method);

      return updatedMethod;
    }
    default:
      return method;
  }
}

export function* processChain(action) {
  const { id, methods } = action.payload;

  const stopChain = chainedLoaderActionCreators.stopChain(id);
  const nextInChain = chainedLoaderActionCreators.nextInChain(id);

  // eslint-disable-next-line no-restricted-syntax
  for (let method of methods) {
    method = isFunction(method) ? method() : method;
    const methodWithFilters = yield applyFilters(method);

    const methodWithChainActions = merge(
      {},
      methodWithFilters,
      {
        payload: {
          stopChain,
          nextInChain,
          chainedLoaderId: id,
        },
      },
    );

    yield put(methodWithChainActions);
    const { stop } = yield race({
      stop: take(actionWithIdSelector(constants.STOP_CHAIN, id)),
      next: take(actionWithIdSelector(constants.NEXT_IN_CHAIN, id)),
    });

    if (stop) {
      yield put(chainedLoaderActionCreators.chainInterrupted(id));
      return;
    }
  }

  yield put(chainedLoaderActionCreators.chainCompleted(id));
}

export function* startChain(action) {
  const { id } = action.payload;

  while (true) {
    yield call(processChain, action);

    const { unmount } = yield race({
      trigger: take(actionWithIdSelector(constants.TRIGGER_CHAIN, id)),
      unmount: take(actionWithIdSelector(constants.TERMINATE_CHAIN, id)),
    });

    if (unmount) {
      break;
    }
  }
}

function* chainedLoaderSaga() {
  yield takeEvery(constants.START_CHAIN, startChain);
}

export default chainedLoaderSaga;
