import { call } from 'redux-saga/effects';

import { getPath } from 'data/api/requests';
import { processCall } from 'helpers/sagaHelper';
import * as actionCreators from '../../actionCreators/deviceScenes';

function* fetchDeviceScenes({ payload }) {
  const { deviceId, endpoint, ...rest } = payload;
  const { response, error } = yield call(getPath, endpoint);
  const enhancedResponse = response ? { ...response, deviceId } : response;

  const params = {
    response: enhancedResponse,
    error,
    successDisp: actionCreators.fetchDeviceScenesSuccess,
    failDisp: actionCreators.fetchDeviceScenesFailure,
    ...rest,
  };
  yield call(processCall, params);
}

export default fetchDeviceScenes;
